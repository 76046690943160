import {useState} from "react";

import CpDashboardFilter from "./CpDashboardFilter";
import CpActionButton from "../_components/CpActionButton";
import CpActionButtonComponent from "../_components/CpActionButtonComponent";

import { SIZE } from "../_components/CpExPFConst";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpShareOffcanvas from "../_components/CpShareOffcanvas";
import { useUILang } from "../../AppExPf/utils/useUILang";

const CpDashboardFilterCTA = (props) => {
    const [ t ] = useUILang();
    const [open, setOpen] = useState(false);
    const [activeKey, setActiveKey] = useState("0");

    const handleOpen = () => { return setOpen(true) };
    const handleSearch = () => { return setOpen(false) };
    const handleClose = () => { return setOpen(false) };
    const handleActive = (id) => { setActiveKey(prev => (prev === id) ? "" : id) };

    const subProps = {...props};
    return <><span className={"position-relative d-block d-lg-none"}>
        <CpActionButtonComponent variant={"gray-exercise-third-btn"} size={SIZE.XS}
            icon={<CpIco src={IconList.general.filterAdvance}/>} onClick={() => handleOpen()}/>
        </span>
        <CpShareOffcanvas show={open} placement={"end"} disableHeader={true} disableFooter={true}>
            <div className={"d-flex flex-column justify-content-between h-100 overflow-hidden"}>
                <div className={"overflow-auto h-100"}>
                    <div className={`exercise-filter d-flex flex-column w-100 h-100 ${activeKey !== "" ? "justify-content-between" : ""}`}>
                      <span role={"button"} onClick={() => handleActive("1")}
                            className={`bg-exercise-third-btn text-light searchExercises w-100 py-1 px-3 d-flex semi-bold fs-5 accordion-button collapse`}>{t("search-questions-search-from")}</span>
                        <div className={`d-flex flex-column p-3 gap-3 d-lg-none h-100 pt-3 overflow-auto flex-grow-1}`}>
                            <CpDashboardFilter {...subProps} />
                        </div>
                    </div>
                </div>
                <div className={"d-flex flex-column mt-3 mt-sm-0 p-3 gap-2 justify-content-center"}>
                    <div className={"d-flex flex-row gap-2 justify-content-between align-items-center"}>
                        <span className={"d-flex"}>
                            <CpActionButton title={t("cancel")}
                                className={"text-dim-400 gap-2 rounded semi-bold user-select-none btn"}
                                hiddenText={false} onClick={handleClose}/>
                        </span>
                        <span className={"d-flex gap-2"}>
                            {/*Nothing to clear <CpActionButton title={t("clear")}
                                className={"btn-outline-dim-400 gap-2 rounded semi-bold user-select-none btn"}
                                hiddenText={false}/>*/}
                            <CpActionButton title={t("search")}
                                className={"btn-exercise-third-btn gap-2 rounded semi-bold user-select-none btn"}
                                hiddenText={false} onClick={handleSearch}/>
                        </span>
                    </div>
                </div>
            </div>
        </CpShareOffcanvas>
    </>
};

export default CpDashboardFilterCTA;