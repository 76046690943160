import { useEffect, useMemo, useState } from "react";
import { BtnPopDev, clickUrl, preJS } from "../../AppExPf/AppUtil";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { isEn2Lang, useCtnLang, useUILang, validLang } from "../../AppExPf/utils/useUILang";
import { _pathOupEx } from "../../consts/ATConstReact";
import { aryMapObj, objEntries, objKeys, objMapObj, objVals, strCmp, toAry, toStr } from "../../libs/libType";
import * as UI from '../../libs/libUI';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { useStateGST } from "../../saga/globalState.saga";
import { ReduxBind } from "../../saga/ReduxState";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { subjMetaCombo } from "../EPAssign/TabAssignmentSettingClass";
import TabExercises from "../EPExercise/TabExercises";
import CpDropdown, { CpDropdownTest } from "../_components/CpDropdown";
import CpIco from "../_components/CpIco";
import CpLanguageSwitcher from "../_components/CpLanguageSwitcher";
import { timeStampNowGMT } from "../../libs/libTime";


const TabLibOup = ReduxBind(props => {
    const {dispatch, cLang, ESets, ESetId, EId, subjMetas,} = props;

    const [t, uiEn, UILang, setUILang, ut] = useUILang(); //app global level UI Language

    const [cl, setCL] = useStateGST(props, 'TabLibOupCL', UILang);
    const [showEn, ct, _filpCtnL, ctnL, setCtnL] = useCtnLang(cLang||cl||UILang); //compoenet Level Content Language
    //    const [showEn, ct, _filpCtnL, ctnL, setCtnL] = useCtnLang(cl||cLang); //compoenet Level Content Language
    const filpCtnL = () => {
        const fLang = isEn2Lang(!showEn);
        setCL(fLang);
        _filpCtnL();
    ;}

    useEffect(() => { 
        dispatch(urlPush_Replace(_pathOupEx(isEn2Lang(showEn), ESetId, EId))); 
    }, [showEn]);

    const subjs = useMemo( ()=> ( { '*':{t:t('all')}, ...subjMetaCombo(subjMetas, ut) }  ), [subjMetas, uiEn]);
    const [_subj, setSubj] = useStateGST(props, 'TabLibOupSubj');
    const subj = _subj || objKeys(subjs)[0];

    const visESets = useMemo(() => {
        const anySj = (!subj) || subj==='*' || !subjs || !subjs[subj];
        return objVals(ESets).
            filter(s => (anySj || toAry(s.ESMSjIds).includes(subj)) && (showEn? s.ESLangEn: s.ESLangCt)).
            sort((a, b) => b.dateMod - a.dateMod || strCmp(a.ESetId, b.ESetId));
    }, [ESets, showEn, subj]);

    

    if(ESetId){
        const baseUrl = _pathOupEx(ctnL, ESetId);
        return <TabExercises {...{...props, baseUrl, showEn, ctnL}} />;
    }

    const clickOupESet = ESetId => clickUrl(dispatch, _pathOupEx(ctnL, ESetId));
    return <>
        <div>{objVals(ESets).map((eset, idx)  => <BtnPopDev key={idx} txt={'eset '+idx} >{preJS(eset,3)}</BtnPopDev> )}</div>
        <div className={"px-3 d-flex align-items-center gap-3"} >
            <div className={"exercise-library-filter-container d-flex flex-column flex-md-row justify-content-start justify-content-md-between align-items-md-end w-100"}>
                <span className={"title d-flex align-items-end semi-bold"}>{t("please-select")}:</span>
                <span className={"d-flex justify-content-between justify-content-md-end align-items-center gap-4"}>
                        <span className={"d-flex"} onClick={e => {UI.stopEvent(e); filpCtnL();}}>
                            <CpLanguageSwitcher en={showEn} flipLang={filpCtnL} style={"simple-switch-quick-action-theme"}/>
                        </span>
                        <span className={"d-flex justify-content-end align-items-center gap-3 flex-grow-1 w-100"}>
                            <span className={"semi-bold"}>{t("subject")}:</span>
                            <span className={"d-flex flex-grow-1"}>
                                <CpDropdown items={subjs} idx={subj} cb={setSubj} className={"w100 dropdown-library-subject-theme"}/>
                            </span>
                        </span>
                    </span>
            </div>
        </div>
        <hr className={"my-2 mx-3"}/>
        <div className={"library-list-thumbnail-container d-grid gap-3 px-3 pb-3 overflow-y-md-auto"} key={ctnL + subj}>
            {toAry(visESets).map((eset, idx) => <CpLibraryThumbnail key={idx} {...{eset, showEn, click:clickOupESet}} />)}
        </div>
    </>
});
export default TabLibOup;


export const isOUPCnewExSet = (eset) => {
    //const isOupEx = ex.schoolId? 0: 1;
    const isLess60d = timeStampNowGMT()-eset.dateMod < 1*1000*60*60*24;     // 60*1000*60*60*24;
    //const isOUPCnewEx = isOupEx && isLess60d;
    
    return isLess60d;
};

const fallBack = (...ary) => ary.find(x => x);//(a,b,c,d,e,f) => a||b||c||d||e||f;
const CpIcoNew = <CpIco src={IconList.tagging.newTag}/>;
const CpLibraryThumbnail = ReduxBind(props => {
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, props.dispatch, 0);
    const {eset, showEn, click} = props;
    
    const {e0, e1, e2, c0, c1, c2} = eset.ESThumbs;
    const t0 = showEn? fallBack(e0, e1, e2, c0, c1, c2): fallBack(c0, c1, c2, e0, e1, e2);
    const t1 = showEn? fallBack(e1, e0, e2, c1, c0, c2): fallBack(c1, c0, c2, e1, e0, e2);
    const t2 = showEn? fallBack(e2, e1, e0, c2, c1, c0): fallBack(c2, c1, c0, e2, e1, e0);
    const tdls = getMediaDLs([t0, t1, t2]);
    const s0 = tdls[t0]?.dl;
    const s1 = tdls[t1]?.dl;
    const s2 = tdls[t2]?.dl;

    const isNew = 1;

    return  <span role={"button"} className={"library-list-thumbnail position-relative"} onClick={click(eset.ESetId)}>
            {isOUPCnewExSet(eset)? <div className={"library-list-thumbnail-new position-absolute top-0 start-0 fs-2"}>{CpIcoNew}</div>:''}
            <div className={"library-list-thumbnail-image d-none d-lg-block"} style={{backgroundImage: `url(${s0})`}}/>
            <div className={"library-list-thumbnail-image d-none d-lg-none d-md-block"} style={{backgroundImage: `url(${s1})`}}/>
            <div className={"library-list-thumbnail-image d-md-none d-sm-block"} style={{backgroundImage: `url(${s2})`}}/>
        </span>
});

const CpLibraryThumbnailDummy = props => {
    const {thumbnail, clickOupESet, eset} = props
    const {mobile, tablet, desktop} = thumbnail

    const click = e => {
        
        clickOupESet('ESet001')(e);
    };

    const isNew = 1;
    return <span role={"button"} className={"library-list-thumbnail position-relative"} onClick={click}>
        {isOUPCnewExSet(eset)? <div className={"library-list-thumbnail-new position-absolute top-0 start-0 fs-2"}>
            <CpIco src={IconList.tagging.newTag}/>
        </div>:''}
        <div className={"library-list-thumbnail-image d-none d-lg-block"} style={{backgroundImage: `url(${desktop})`}}/>
        <div className={"library-list-thumbnail-image d-none d-lg-none d-md-block"} style={{backgroundImage: `url(${tablet})`}}/>
        <div className={"library-list-thumbnail-image d-md-none d-sm-block"} style={{backgroundImage: `url(${mobile})`}}/>
    </span>;
};

