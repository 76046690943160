import React, { useEffect, useRef, useState } from 'react';
import { _ATSysQTypes } from '../../consts/ATSysQType';
import { toAry, toStr } from '../../libs/libType';
import { aTErrDiv1, aTErrTxt1, aTUIMust, ATUIText } from "../AppUtil";
import * as UI from '../../libs/libUI';
import CpATSubjectTickList, { aTTickItem } from '../components/CpATSubjectTickList';
import { useUILang } from '../utils/useUILang';

const SQTs = _ATSysQTypes();
const TabGeneral = (props => {
  const { setField, setTick, setQSId, fields, fieldErrs, QId, opts, showPub, qtn, lock } = props;
  const [ t ] = useUILang();
  const atss = opts?.ATSubjects || [];
  const qsids = qtn?.QATSubjIds || [];

  return <>
  <div className='flexRowBetween f14'>
      <div className='flexRowStart'>Question ID {aTUIMust} 
        {ATUIText(qtn?.QId, setField('QId'), 'tqid', lock || (!qtn?.isNew), 'Please Input Qusetion ID')}
        {showPub? '': aTErrTxt1(fieldErrs?.QId, t)}
      </div>
    </div>
    <div><br />
      <div className='f14'>Enable for Subject(s) in AT {aTUIMust}</div>
      <div className='subjectItemContainer f14'>
        {CpATSubjectTickList(toAry(opts?.ATSubjects), toAry(opts?.mySjIds), toAry(qtn?.QATSubjIds), setQSId, lock)}
        {showPub? '': aTErrDiv1(fieldErrs?.QATSubjIds, t)}
      </div>
    </div><br />
    <div className='f14'>Enable for exercise mode(s) {aTUIMust}</div>
    <div className='flexRowStart f14'>
      {aTTickItem(qtn?.QModeScroll,  setTick('QModeScroll'), ' Question on Same Page (code:scroll) ', lock )}
      {aTTickItem(qtn?.QModeSlide,  setTick('QModeSlide'), ' One question per page (code:slide) ', lock )}
      {showPub? '': aTErrDiv1(fieldErrs?.QMode, t)}
    </div>
  </>;
});
export default TabGeneral;

