import React from "react";
import CpIco from "../_components/CpIco";
import { IconList } from '../../consts/ATIconListToUsePoc';

export const CpStateTxtBox = (props) => { // base on poc ValueStatusBox.tsx
    const {status, txt} = props;
    const clsStatus = status? 'status-'+status: '';
    const body = status === 'm'? <CpIco src={IconList.general.alert}/>: txt;
    return <div className={'CpStateTxtBox field field-marks text-center d-flex justify-content-center align-items-center semi-bold '+clsStatus} >{body}</div>
};

const CpValueStatusBox = (props) => { // base on poc ValueStatusBox.tsx
console.log('todo CpValueStatusBox to CpStateTxtBox');
    const {studentData, type} = props;
    if (type === "metadata") return <div className={`CpValueStatusBox field field-marks text-center`}></div>;
    if (!studentData) return <div className={`CpValueStatusBox field field-marks text-center status-n`}></div>;

    const {value} = studentData;
    const status = (studentData?.status) || "n";

    //if (type === "metadata") return <div className={`CpValueStatusBox field field-marks text-center`}></div>; 
    //if (!studentData)  return <div className={`CpValueStatusBox field field-marks text-center status-n`}></div>;
    //const body = status === "m"? <CpIco src={IconList.general.alert}/>: value;
    const body = status === "m"? <CpIco src={IconList.general.alert}/>: (status === "n"?"":value);
    return <div className={`CpValueStatusBox field field-marks d-flex justify-content-center align-items-center semi-bold status-${status}`}>{body}</div>;
};

export default CpValueStatusBox;
//return <div className={`field field-marks d-flex justify-content-center align-items-center status-${(status==="")?'n':status} semi-bold`}>{((status === "n" && value === 0) || status==="") ? "" : value}</div>


const CpValueStatusBox_new = (props) => { // base on poc ValueStatusBox.tsx
console.log('todo CpValueStatusBox to CpStateTxtBox');
    const {studentData, type} = props;
    const {value} = studentData;
    const status = (studentData.status) || "n";

    if (type === "metadata") return <div className={`CpValueStatusBox field field-marks text-center`}></div>; 
    if (!studentData)  return <div className={`CpValueStatusBox field field-marks text-center status-n`}></div>;
    const body = status === "m"? <CpIco src={IconList.general.alert}/>: value;
    return <div className={`CpValueStatusBox field field-marks d-flex justify-content-center align-items-center semi-bold status-${status}`}>{body}</div>;    
};

const CpValueStatusBox_org = (props) => { // base on poc ValueStatusBox.tsx
    const {studentData, type} = props;
    if (type === "metadata") { return <div className={`field field-marks text-center`}></div>; }
    
    //if (!studentData)  return <div className={`field field-marks text-center status-n`}></div>;
    
    const {value} = studentData;
    const status = (studentData.status) || "n";
    if (status === "m") {
        return <div className={`field field-marks d-flex justify-content-center align-items-center status-${status} semi-bold`}>
            <CpIco src={IconList.general.alert}/></div>;
    };
    return <div className={`field field-marks d-flex justify-content-center align-items-center status-${status} semi-bold`}>
        {status === 'n'? '': value}</div>;
    
};