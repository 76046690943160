import React, {useMemo, useState} from "react";
import {useCtnLang, useUILang } from "../../AppExPf/utils/useUILang";

import CpValueStatusBox from "./CpValueStatusBox";
import ChartMiniBar from "./CpChartMinibar";

import { toInt, toAry, toObj, toStr, toUniAry, toFaction1, toPercentF, toPercent1 } from "../../libs/libType";
import { _WST_SUBMIT, _QST_SUB_WRONG, _QST_SUB_CORR, _QST_SUB_ATT, _QST_SUB_NOTMARK, _QST_SUB_NOTATT } from "../../consts/ATConstsAssignment";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";
import { autoId, BtnDev, preJS } from "../../AppExPf/AppUtil";
import { _ATMetaTypeByCode, _ATMetaType_SU } from "../../consts/ATMetaTypes";
import { toFloat } from "../../libs/libType";
import { qResps } from "../EPAsmWork/utilAsmWork";
import { ansState } from "./TabPerformanceDetailChart";
import { strCmpTrimLow } from "../EPAssign/TabTeacherAssignmentStatus";

//import {Data, Metadata, PerformanceMetadataSetsChartType} from "../../../../type/ChartType";

import { IconList } from '../../consts/ATIconListToUsePoc';
import CpIco from "../_components/CpIco";
//import QuestionBox from "../../poc/screens/shared/includes/chart/shared/QuestionBox";
import CpQuestionBox from "./CpQuestionBox";
import { MSetFullTree, rptMName } from "../EPReports/data/dataUtil";
import { toUniIdAry } from "../../consts/ATValidate";
import { qAutoMark, qHasCorrect } from "../../consts/ATValidateQ";
import { normalizeSMIds } from "../../consts/ATValidateMetaSet";
import CpMJX from "../_components/CpMJX";

export const sortRptSWMs = (stuIds, stus, works, marks, ut) => {
    const ret = toAry(stuIds).map(i => {
        const s = toObj(stus)[i];
        const work = toObj(works)[i];
        const mark = toObj(marks)[i];
        return s && {
            id: i,
            no: s.classNo,
            name: ut(s.nameEng, s.nameChi),
            submitted: (work?.WState === _WST_SUBMIT),// && (marks[i]?.MState === _MST_MARKED),
            work, mark,
            nameEng: s.nameEng,
        }
    }).filter(s => s);
    ret.sort((a, b) => strCmpTrimLow(a.no, b.no) || strCmpTrimLow(a.nameEng, b.nameEng))
    return ret;
};

export const statThrAsmRptQs = (QIds, qtns, asm, SWMs) => {
    return QIds.map((qid, qIdx) => {
        const q = toObj(qtns[qid]);
        const qMetas = normalizeSMIds(q.QDisplayMetas);
        const mark = q.score;
        const qHasRight = qHasCorrect(q);
        const qIsAutoMark = qAutoMark(q);
        const qMark = toInt(q.score);

        const si = { sumSubmit:0, sumRight:0, avgRight:0, sumMark:0, avgMark:0 };
        //const uMarks = studentIds.map((sid, idx) => { const swm = toObj(SWMs[idx]);
        const uMarks = SWMs.map(swm => { 
            const [s, m, w] = [swm.student, swm.mark, swm.work];
            const sta = {mark:0, right:0, status:'', allMarked:0, hasAnswered:0};
            if (m && w) {
                const mr = qResps(m.resp, asm.Exer); // filter qtn resp only
                const wr = qResps(w.resp, asm.Exer); // filter qtn resp only
                const {aScore, mScore, opAttempt, isOP, isRight, allMarked, hasAnswered} = mr[qIdx];
                const subed = w.WState === _WST_SUBMIT;
                if (allMarked) {
                    if (subed) si.sumSubmit = si.sumSubmit + 1;

                    sta.mark = subed?(toInt(aScore) + toInt(mScore)):0;
                    if (subed) si.sumMark = si.sumMark + sta.mark;

                    sta.right = isRight;
                    if (subed) si.sumRight = si.sumRight + (sta.right?1:0);
                };
                sta.status = ansState(q, subed, hasAnswered, allMarked, isRight);   
                Object.assign(sta, {allMarked, hasAnswered} );
            };
            return sta;
        }) 
        si.avgRight = toPercentF(si.sumRight, si.sumSubmit);
        si.avgMark = toPercentF(si.sumMark, (si.sumSubmit * mark));
        return { qIdx, qid, qMetas, qMark, qIsAutoMark, qHasRight, ...si, uMarks };
    })
};

export const qstat2list = qstats => toAry(qstats).map(s => ({id: 'q*'+s.qIdx+'*'+s.qid, no: (s.qIdx+1), qIdx: s.qIdx}));

const mixQ = (list) => {
    const cnt = list.length;
    const mix = {right:0, mark:0, cntHasRight: 0, cnt};
    if (list.length){ 
        list.forEach(s => {
            if(s.qHasRight) mix.cntHasRight++;
            if(s.qHasRight) mix.right += toFloat(s.avgRight); //fix EX-1535
            mix.mark += toFloat(s.avgMark); 
        });
        mix.right = toFaction1(mix.right, mix.cntHasRight);
        mix.mark = toFaction1(mix.mark, cnt);
    };
    return mix;
};
export const list2Data = (id, list) => {
    const {right, mark, cnt, cntHasRight} = mixQ(list);
    return ({
        referenceId: id,
        numCorrectly: { value: right, total: cnt },
        isCorrectness: cntHasRight,
        correctness: right,
        averageScore: mark,
    });
};
export const qStats2QData = (qStats, SWMs) => {
    return qStats.map(s => {
        const {qid, qIdx, qMark, qIsAutoMark, qHasRight, sumMark, sumRight, sumSubmit,} = toObj(s);
        return {
            qIdx: qIdx,
            referenceId: 'q*'+qIdx+'*'+qid,
            isCorrectness: qHasRight, 
            numCorrectly: qHasRight? { value: sumRight, total: sumSubmit, }: undefined,
            correctness: qHasRight? toPercent1(sumRight, sumSubmit): undefined,
            averageScore: toPercent1(sumMark, sumSubmit * qMark),
            student: SWMs.map((w, i) => {
                const { mark, status, right, allMarked, hasAnswered } = toObj(s.uMarks[i]);
                const { id, } = toObj(w);
                return {id, value: mark, status, right, allMarked, hasAnswered};
            }) 
        };
    });
};

const filterSortMSetForRpt = (mset, qStats, SWMs, ut, t2, uiEn) => {
    const qdata = qStats2QData(qStats, SWMs);
    const data = [...qdata]; //[qstats, mstats]

    const msid = toStr(mset?.metaSetId);
    const metas = toObj(mset?.metas);
    const [ MRoot, MBranchs] = MSetFullTree(metas); 

	const mkNode = mid => {
        const id = msid+','+mid;
        const children = mkNodes(MBranchs[mid]);
        const subQIdxs = toUniAry([].concat(...children.map(c => c._qIdxs)));
        const dirQs = qStats.filter(q => q && (!subQIdxs.includes(q.qIdx)) && q.qMetas.includes(id));
        const total = dirQs.length +  subQIdxs.length;
        if(total){
            const fullList = [...subQIdxs.map(i => qStats[i]), ...dirQs].filter(s => s);
            data.push(list2Data('m*'+id, fullList));
        }
        const list = qstat2list(dirQs);
        
        return total && {
            id: 'm*'+id,           
            title: rptMName(metas[mid], ut, mid),
            question: {  total, list },
            children,            
            _qIdxs: toUniAry([...subQIdxs, ...dirQs.map(s => s.qIdx)]),
        };
    };
    const mkNodes = ids => toUniIdAry(ids).map(mkNode).filter(n => n);

    const lv1s = mkNodes(MRoot);
    const metadata = [...lv1s]; //[...MSet_Lv1s, uncats] 

    const catIdxs = toUniAry([].concat(...lv1s.map(i => i._qIdxs)));
    const uncatqs = qStats.filter(q => q && (!catIdxs.includes(q.qIdx)))
	if(uncatqs.length){
        const title =  t2('report-performance-uncategorized', uiEn);
        const id = '*muncat'; 
        metadata.push({ id, title, question: {  total: uncatqs.length, list: qstat2list(uncatqs) }, });
        data.push(list2Data(id, uncatqs));
    }
    return {metadata, data};
};

export const ExpandBtn = props => {
    const {open, ...btnprops} = props
    return <span role={"button"} className={`text-exercise-third-btn metadata-filter-toggle fs-5`} {...props}>
        <CpIco src={open? IconList.general.openNode: IconList.general.closeNode}/>
    </span>;
};

const TabPerformanceMetadataSetsChart = props => { //PerformanceMetadataSetsChart
    const {miniLayout = false, miniTitle = ""} = props
    const [t, uiEn, lang, setUILang, ut, t2] = useUILang();

    const { rptJ, rptMType, rptMSId, QIds, typeMSets } = props;
    const {asm, Exer, qtns, works, marks} = props;
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(asm?.ALang);
    
    const rptSWMs = useMemo(() => sortRptSWMs(asm?.studentIds, props.students, works, marks, ct), [asm, props.students, works, marks, showEn]); 
    const qStats = useMemo(() => statThrAsmRptQs(QIds, qtns, asm, rptSWMs), [QIds, qtns, asm, rptSWMs]);
    
    const mset = typeMSets?.[rptMType]?.[rptMSId];
    const msStats = useMemo(() => filterSortMSetForRpt(mset, qStats, rptSWMs, ct, t2, showEn), [mset, qtns, qStats, rptSWMs, showEn]);

    const student = rptSWMs;
    const {metadata, data } = msStats; 
    
    //const [student, metadata, data] = debug();
    //const {student, metadata, data } = PerformanceMetadataSetsData; 
    //const {student, metadata, data } = PerformanceMetadataSetsData2; 
// Renders ================= ================= ================= ================= ================= ================= ================= ================= ================= =================
    const StudentHeaders = () => {
        return <>{student.map(stu => <th key={stu.id} className={`${miniLayout? "": "text-rotate"} avatar-th`}>
            <div><span className={stu.submitted ? "" : "text-dim-350"}>{stu.no} {stu.name}</span></div>
        </th>)}</>;
    };

    const getStatus = (stuId, data) => data?.student?.find(stu => stu?.id === stuId);

    const StudentData = (props) => { // { id: string, stuId: string, type: "metadata" | "question" }) => {
        const {id, stuId, type} = props;
        const found = data?.find(d => d.referenceId === id);
        return <CpValueStatusBox studentData={getStatus(stuId, found)} type={type}/>;
    };
   
    const AverageScoreContainer = (props) => {
        const {id} = props;
        const found = data?.find(d => d.referenceId === id)
        if (found) {
            const {averageScore} = found
            if (averageScore !== 0) {
                return <div className={"AverageScoreContainer field"}><ChartMiniBar marks={{percentage: averageScore}} type={"average"}/></div>;
            }
        }
        return <div className={"AverageScoreContainer field"}><ChartMiniBar type={"average"}/></div>;
    };

    const CorrectnessContainer = (props) => {
        const {id} = props;
        const found = data?.find(d => d.referenceId === id)
        if (found) {
            const {isCorrectness, correctness} = found;
            if (isCorrectness) {
                return <div className={"CorrectnessContainer field"}><ChartMiniBar marks={{percentage: correctness}} type={"correctness"}/></div>;
            } else {
                return <div className={"CorrectnessContainer field"}></div>;
            }
        }
        return <div className={"CorrectnessContainer field"}><ChartMiniBar type={"correctness"}/></div>;
    };

    const NumCorrectlyContainer = (props) => {// { id: string, type: "metadata" | "question" }) => {
        const {id, type} = props;
        const found = data?.find(d => d.referenceId === id);
        if (type === "metadata") {
            return <div className={"NumCorrectlyContainer field"}></div>;
        }
        if (found) {
            const {isCorrectness, numCorrectly} = found
            const {value, total} = numCorrectly || {}
            if (isCorrectness) {
                return <div className={"NumCorrectlyContainer field d-flex justify-content-center align-items-center semi-bold"}>{value}/{total}</div>;
            } else {
                return <div className={"NumCorrectlyContainer field"}>-</div>;
            }
        }
        return <div className={"NumCorrectlyContainer field"}>-</div>;
    }

    const MetadataContainer = props => {
        const {metadata, indent = 0} = props
        const {id, title, question, children = []} = metadata
        const {total, list = []} = question
        const [open, setOpen] = useState(true)
        const handleOpen = () => { setOpen(prev => !prev)};
        return <>
            <tr id={id} className="MetadataContainer">
                <td className={`MetadataContainer sticky-header header-qno`}>
                    <div style={{paddingLeft: `${indent}rem`}}
                         className={"d-flex justify-content-between gap-3 semi-bold"}>
                        <div className={"overflow-auto py-2"}>
                            <span className={`d-flex gap-2 ${children.length === 0 ? "ps-4" : ""}`}>
                                {children.length > 0 && <ExpandBtn open={open} onClick={() => handleOpen()}/>}
                                <CpMJX key={autoId()} dynamic={true}><span>{title}</span></CpMJX>
                            </span>
                            <div className={"d-flex gap-0 ps-4"}>
                                {miniLayout && list.map(li => <CpQuestionBox key={li.id} num={`${t("q")}${li.no}`} className={"mx-0"}/>)}
                            </div>
                        </div>
                        <div>
                            {total > 1 && <div className={"field d-flex justify-content-end align-items-center px-3 text-nowrap"}>{total}{t("q")}</div>}
                            {!miniLayout && list.map(li => <CpQuestionBox key={li.id} num={li.no}/>)}
                        </div>
                    </div>
                </td>
                {!miniLayout && <td className={"text-center"}>
                    {total > 1 && <NumCorrectlyContainer id={id} type={"metadata"}/>}
                    {list.length > 0 && list.map(li => <NumCorrectlyContainer key={li.id} id={li.id} type={"question"}/>)}
                </td>}
                {!miniLayout && <td>
                    {total > 1 && <CorrectnessContainer id={id}/>}
                    {list.length > 0 && list.map(li => <CorrectnessContainer key={li.id} id={li.id}/>)}
                </td>}
                <td>
                    {((!miniLayout && total > 1) || (miniLayout && total > 0)) &&
                        <AverageScoreContainer id={id}/>}
                    {!miniLayout && list.map(li => <AverageScoreContainer key={li.id} id={li.id}/>)}
                </td>
                {!miniLayout && student.map(stu => <td className={"px-0"} key={stu.id}>
                        {total > 1 && <StudentData id={id} stuId={stu.id} type={"metadata"}/>}
                        {list.map(li => <StudentData key={li.id} id={li.id} stuId={stu.id} type={"question"}/>)}
                    </td>
                )}
                <td className={"w-100"}></td>
            </tr>
            {open && children.map(meta => <MetadataContainer key={meta.id} metadata={meta} indent={indent + 1}/> )}
        </>
    };

    return <>
	{preJS({rptJ, rptMType, rptMSId,})}
	<BtnDev txt="TPMC-metadata">{preJS({metadata}, 3)}</BtnDev>
	<BtnDev txt="TPMC-data">{preJS({data}, 3)}</BtnDev>
	
        <table className={`TabPerformanceMetadataSetsChart report performance-metadata-sets-chart ${miniLayout? "mini-layout": ""}`}>
            <thead><tr>
                {!miniLayout?
                    <th className={`text-rotate question-no-th ${lang} sticky-header header-qno`}>
                        <div className={"ms-auto me-0"}>
                            <span>{t("report-performance-question-no")}</span>
                        </div>
                    </th> : <th className={`question-no-th ${lang} sticky-header header-qno`}>
                        <div className={"d-flex justify-content-between "}>
                            <div className={""}><span>{miniTitle}</span></div>
                            <div className={"pe-4"}><span>{t("report-performance-question-no")}</span></div>
                        </div>
                    </th>}
                {!miniLayout && <th className={`text-rotate correctly-th`}>
                    <div><span>{t("report-performance-correctly")}</span></div>
                </th>}
                {!miniLayout &&
                    <th className={`text-rotate correctness-th  ${lang}`}>
                        <div><span>{t("report-performance-correctness-percentage")}</span></div>
                    </th>}
                <th className={`${miniLayout? "ps-3 wider w-100": "text-rotate"} average-th ${lang}`}>
                    <div><span>{t("report-performance-average-percentage")}</span></div>
                </th>
                {!miniLayout && <StudentHeaders/>}
                <th className={"w-100"}></th>
            </tr></thead>
            <tbody>{metadata.map(meta => <MetadataContainer key={meta.id} metadata={meta}/> )}</tbody>
        </table>
    </>;
}
export default TabPerformanceMetadataSetsChart;

export const PerformanceMetadataSetsData = {
"student": [
    {
    "id": "s-001",
    "no": "01",
    "name": "Eric Yau",
    "submitted": true
    },
    {
    "id": "s-002",
    "no": "02",
    "name": "Wing Tang",
    "submitted": false
    },
    {
    "id": "s-003",
    "no": "03",
    "name": "Anson Bean",
    "submitted": true
    }
],
"metadata": [
    {
    "id": "m-001",
    "title": "Compulsory Part",
    "question": {
        "total": 10
    },
    "children": [
        {
        "id": "m-001-001",
        "title": "Number and Algebra Strand",
        "question": {
            "total": 10,
            "list": [
            {
                "id": "q-001",
                "no": 1
            }
            ]
        },
        "children": [
            {
            "id": "m-001-001-001",
            "title": "1. Quadratic equations in one unknown",
            "question": {
                "total": 5,
                "list": [
                {
                    "id": "q-002",
                    "no": 2
                },
                {
                    "id": "q-003",
                    "no": 3
                }
                ]
            },
            "children": [
                {
                "id": "m-001-001-001-001",
                "title": "1.3 solve the equation ax2 + bx + c = 0 by plotting the graph of the parabola y = ax2 + bx + c and reading the x-intercepts",
                "question": {
                    "total": 2,
                    "list": [
                    {
                        "id": "q-004",
                        "no": 4
                    },
                    {
                        "id": "q-005",
                        "no": 5
                    }
                    ]
                }
                },
                {
                "id": "m-001-001-001-002",
                "title": "1.6 solve problems involving quadratic equations",
                "question": {
                    "total": 1,
                    "list": [
                    {
                        "id": "q-006",
                        "no": 6
                    }
                    ]
                }
                }
            ]
            }
        ]
        }
    ]
    },
    {
    "id": "m-002",
    "title": "report-performance-uncategorized",
    "question": {
        "total": 2,
        "list": [
        {
            "id": "q-007",
            "no": 7
        },
        {
            "id": "q-011",
            "no": 11
        },
        {
            "id": "q-012",
            "no": 12
        }
        ]
    }
    }
],
"data": [
    {
    "referenceId": "m-001",
    "numCorrectly": {
        "value": 0,
        "total": 0
    },
    "isCorrectness": true,
    "correctness": 63.8,
    "averageScore": 54.5,
    "student": [
    ]
    },
    {
    "referenceId": "m-001-001",
    "numCorrectly": {
        "value": 0,
        "total": 0
    },
    "isCorrectness": true,
    "correctness": 60.0,
    "averageScore": 52.5,
    "student": [
    ]
    },
    {
    "referenceId": "q-001",
    "numCorrectly": {
        "value": 8,
        "total": 10
    },
    "isCorrectness": true,
    "correctness": 80.0,
    "averageScore": 60.0,
    "student": [
        {
        "id": "s-001",
        "value": 5,
        "status": "p"
        },
        {
        "id": "s-002",
        "value": 0,
        "status": "n"
        },
        {
        "id": "s-003",
        "value": 5,
        "status": "p"
        }
    ]
    },
    {
    "referenceId": "m-001-001-001",
    "numCorrectly": {
        "value": 0,
        "total": 0
    },
    "isCorrectness": true,
    "correctness": 56.0,
    "averageScore": 51.0,
    "student": [
    ]
    },
    {
    "referenceId": "q-002",
    "numCorrectly": {
        "value": 4,
        "total": 10
    },
    "isCorrectness": true,
    "correctness": 40.0,
    "averageScore": 30.0,
    "student": [
        {
        "id": "s-001",
        "value": 5,
        "status": "p"
        },
        {
        "id": "s-002",
        "value": 0,
        "status": "n"
        },
        {
        "id": "s-003",
        "value": 5,
        "status": "p"
        }
    ]
    },
    {
    "referenceId": "q-003",
    "numCorrectly": {
        "value": 6,
        "total": 10
    },
    "isCorrectness": true,
    "correctness": 60.0,
    "averageScore": 70.0,
    "student": [
        {
        "id": "s-001",
        "value": 2,
        "status": "p"
        },
        {
        "id": "s-002",
        "value": 0,
        "status": "n"
        },
        {
        "id": "s-003",
        "value": 5,
        "status": "p"
        }
    ]
    },
    {
    "referenceId": "m-001-001-001-001",
    "numCorrectly": {
        "value": 0,
        "total": 0
    },
    "isCorrectness": true,
    "correctness": 50.0,
    "averageScore": 40.0,
    "student": [
    ]
    },
    {
    "referenceId": "m-001-001-001-002",
    "numCorrectly": {
        "value": 0,
        "total": 0
    },
    "isCorrectness": true,
    "correctness": 50.0,
    "averageScore": 40.0,
    "student": [
    ]
    },
    {
    "referenceId": "q-004",
    "numCorrectly": {
        "value": 4,
        "total": 10
    },
    "isCorrectness": true,
    "correctness": 40,
    "averageScore": 30,
    "student": [
        {
        "id": "s-001",
        "value": 0,
        "status": "f"
        },
        {
        "id": "s-002",
        "value": 0,
        "status": "n"
        },
        {
        "id": "s-003",
        "value": 5,
        "status": "p"
        }
    ]
    },
    {
    "referenceId": "q-005",
    "numCorrectly": {
        "value": 6,
        "total": 10
    },
    "isCorrectness": true,
    "correctness": 60,
    "averageScore": 50,
    "student": [
        {
        "id": "s-001",
        "value": 5,
        "status": "p"
        },
        {
        "id": "s-002",
        "value": 0,
        "status": "n"
        },
        {
        "id": "s-003",
        "value": 5,
        "status": "p"
        }
    ]
    },
    {
    "referenceId": "q-006",
    "numCorrectly": {
        "value": 8,
        "total": 10
    },
    "isCorrectness": true,
    "correctness": 80.0,
    "averageScore": 75.0,
    "student": [
        {
        "id": "s-001",
        "value": 2,
        "status": "p"
        },
        {
        "id": "s-002",
        "value": 0,
        "status": "n"
        },
        {
        "id": "s-003",
        "value": 2,
        "status": "p"
        }
    ]
    },
    {
    "referenceId": "m-002",
    "numCorrectly": {
        "value": 0,
        "total": 0
    },
    "isCorrectness": true,
    "correctness": 75.8,
    "averageScore": 57.5,
    "student": [
    ]
    },
    {
    "referenceId": "q-007",
    "numCorrectly": {
        "value": 0,
        "total": 0
    },
    "isCorrectness": false,
    "correctness": 0,
    "averageScore": 60.0,
    "student": [
        {
        "id": "s-001",
        "value": 3,
        "status": "a"
        },
        {
        "id": "s-002",
        "value": 0,
        "status": "n"
        },
        {
        "id": "s-003",
        "value": 8,
        "status": "a"
        }
    ]
    },
    {
    "referenceId": "q-011",
    "numCorrectly": {
        "value": 0,
        "total": 0
    },
    "isCorrectness": false,
    "correctness": 0,
    "averageScore": 30.0,
    "student": [
        {
        "id": "s-001",
        "value": 0,
        "status": "f"
        },
        {
        "id": "s-002",
        "value": 0,
        "status": "n"
        },
        {
        "id": "s-003",
        "value": 5,
        "status": "p"
        }
    ]
    },
    {
    "referenceId": "q-012",
    "numCorrectly": {
        "value": 6,
        "total": 10
    },
    "isCorrectness": true,
    "correctness": 60.0,
    "averageScore": 50.0,
    "student": [
        {
        "id": "s-001",
        "value": 5,
        "status": "p"
        },
        {
        "id": "s-002",
        "value": 0,
        "status": "n"
        },
        {
        "id": "s-003",
        "value": 5,
        "status": "p"
        }
    ]
    }
]
};
  
export const PerformanceMetadataSetsData2 = {
    "student": [
      {
        "id": "s-001",
        "no": "01",
        "name": "Eric Yau",
        "submitted": false
      },
      {
        "id": "s-002",
        "no": "02",
        "name": "Wing Tang",
        "submitted": false
      },
      {
        "id": "s-003",
        "no": "03",
        "name": "Anson Bean",
        "submitted": false
      }
    ],
    "metadata": [
      {
        "id": "m-001",
        "title": "Compulsory Part",
        "question": {
          "total": 10
        },
        "children": [
          {
            "id": "m-001-001",
            "title": "Number and Algebra Strand",
            "question": {
              "total": 10,
              "list": [
                {
                  "id": "q-001",
                  "no": 1
                }
              ]
            },
            "children": [
              {
                "id": "m-001-001-001",
                "title": "1. Quadratic equations in one unknown",
                "question": {
                  "total": 5,
                  "list": [
                    {
                      "id": "q-002",
                      "no": 2
                    },
                    {
                      "id": "q-003",
                      "no": 3
                    }
                  ]
                },
                "children": [
                  {
                    "id": "m-001-001-001-001",
                    "title": "1.3 solve the equation ax2 + bx + c = 0 by plotting the graph of the parabola y = ax2 + bx + c and reading the x-intercepts",
                    "question": {
                      "total": 2,
                      "list": [
                        {
                          "id": "q-004",
                          "no": 4
                        },
                        {
                          "id": "q-005",
                          "no": 5
                        }
                      ]
                    }
                  },
                  {
                    "id": "m-001-001-001-002",
                    "title": "1.6 solve problems involving quadratic equations",
                    "question": {
                      "total": 1,
                      "list": [
                        {
                          "id": "q-006",
                          "no": 6
                        }
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "id": "m-002",
        "title": "report-performance-uncategorized",
        "question": {
          "total": 2,
          "list": [
            {
              "id": "q-007",
              "no": 7
            },
            {
              "id": "q-011",
              "no": 11
            },
            {
              "id": "q-012",
              "no": 12
            }
          ]
        }
      }
    ]
};

const fakeResp_Unuse = {
	"auth": {
		"APIVer": "23.10.11.a",
		"apiCounter": 64,
		"svrTime": {
			"str": "24 05 08-21 58 44",
			"GMTSec": 1715205524
		},
		"uid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
		"ATUserId": "",
		"sessionId": "71c5de05-d47f-4373-a098-c92977b131d9",
		"maintenanceMode": "",
		"maintenanceMsg": [],
		"maintenanceId": 0,
		"_timing": [
			[
				"Extract SessId",
				1715205524271,
				0
			],
			[
				"SessId Locked",
				1715205524280,
				9
			],
			[
				"SessId Loaded",
				1715205524291,
				20
			],
			[
				"-token check start...",
				1715205524291,
				20
			],
			[
				"-token check checked",
				1715205524380,
				109
			],
			[
				"API Core enter",
				1715205524380,
				109
			],
			[
				"API Core return",
				1715205524460,
				189
			],
			[
				"Session Saved",
				1715205524483,
				212
			]
		]
	},
	"errs": [],
};

const fakeResp = {
	"works": [
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "2703e768-b78b-40b1-939e-90aaa939e9f4",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "37460f3e-5421-456d-9d66-e68636fc38b9",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"msUsed": 10033,
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"isTimeUp": 0,
			"lastHB": 1715050183355,
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"startTime": 1715050173322,
			"code": "AWK",
			"resp": [
				{
					"qresp": [
						"0,0",
						"2,2"
					],
					"sequence": [
						0,
						1,
						2,
						3
					],
					"qDone": 1
				},
				{
					"qresp": [
						"0,0",
						"2,2"
					],
					"sequence": [
						0,
						1,
						2,
						3
					],
					"qDone": 1
				},
				{
					"respType": "t",
					"qresp": {
						"wcInfo": {
							"words": 1,
							"chars": 2
						},
						"value": "<p>abc</p>"
					},
					"qDone": 1
				},
				{
					"respType": "t",
					"qresp": {
						"value": "<p>abc</p>"
					},
					"qDone": 1
				}
			],
			"timeUpType": 0,
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "e9e84d0a-b6de-4a96-bd6f-051aeab0dbef",
			"WState": "wSub",
			"dateMod": 1715050183355,
			"modUid": "e9e84d0a-b6de-4a96-bd6f-051aeab0dbef",
			"dateSub": 1715050183355
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "d1a03aaa-6de6-4fdd-8d3a-ef1fdc6a4ca5",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "6375bb34-1ec1-4b73-9b42-cfcda9231d68",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "b01b878e-8775-4a45-831c-851cf3a16aa8",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "400fb248-e65f-4385-9816-b7e8c4bb9f1c",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "4d0a9c11-db18-4440-9cb5-18ed09ca26c2",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "e6c55d3b-e8e3-41ef-964a-4f05e66e85d5",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"msUsed": 80078,
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"isTimeUp": 0,
			"lastHB": 1715050152622,
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"startTime": 1715050072544,
			"code": "AWK",
			"resp": [
				{
					"qresp": [
						"0,0",
						"2,2"
					],
					"sequence": [
						0,
						1,
						2,
						3
					],
					"qDone": 1
				},
				{
					"qresp": [
						"0,0",
						"2,2"
					],
					"sequence": [
						0,
						1,
						2,
						3
					],
					"qDone": 1
				},
				{
					"respType": "t",
					"qresp": {
						"wcInfo": {
							"words": 1,
							"chars": 2
						},
						"value": "<p>abc</p>"
					},
					"qDone": 1
				},
				{
					"respType": "t",
					"qresp": {
						"wcInfo": {
							"words": 1,
							"chars": 2
						},
						"value": "<p>abc</p>"
					},
					"qDone": 1
				}
			],
			"timeUpType": 0,
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "85493b4a-0089-4e8c-b0b3-b6ae0e5800e2",
			"WState": "wSub",
			"dateMod": 1715050152622,
			"modUid": "85493b4a-0089-4e8c-b0b3-b6ae0e5800e2",
			"dateSub": 1715050152622
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "a2601d56-7ff8-4f2e-b886-702569f6830e",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "4e17c3ab-d49c-4ada-93c4-e717d2b11579",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "e6b63f90-ca5c-4a3e-ba13-cc1cdcd734ff",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "18b85bad-3656-4e16-ba14-13d219bf917f",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "a22ab31b-b004-495d-87e6-c4e5e7ad764a",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "4adf2521-fcd6-401f-b420-6293937c1346",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "60718591-5138-435c-93ed-00d20b355139",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "2474a578-703b-489f-9dd7-55c31ea57588",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "0578ab57-848b-4412-b2dc-ce020e8ab7b8",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AWK",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050056939,
			"userId": "7d1c4c89-10ef-4853-b2a6-f670ad2cd043",
			"dateMod": 1715050056939,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		}
	],
	"marks": [
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "85493b4a-0089-4e8c-b0b3-b6ae0e5800e2",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AMK",
			"resp": [
				{
					"isRight": 1,
					"allMarked": 1,
					"isAutoMark": 1,
					"aCount": 1,
					"mScore": 0,
					"opAttempt": 0,
					"mCount": 0,
					"aScore": 2,
					"hasAnswered": 1,
					"isOP": 0,
					"isQtn": 1
				},
				{
					"isRight": 1,
					"allMarked": 1,
					"isAutoMark": 1,
					"aCount": 1,
					"mScore": 0,
					"opAttempt": 0,
					"mCount": 0,
					"aScore": 2,
					"hasAnswered": 1,
					"isOP": 0,
					"isQtn": 1
				},
				{
					"aCount": 0,
					"mScore": 4,
					"opAttempt": 1,
					"mCount": 0,
					"isRight": 1,
					"allMarked": 1,
					"isAutoMark": 0,
					"aScore": 0,
					"hasAnswered": 1,
					"isOP": 1,
					"mCorrect": 0,
					"isQtn": 1,
					"mMark": 4
				},
				{
					"isRight": 1,
					"allMarked": 1,
					"isAutoMark": 0,
					"aCount": 0,
					"mScore": 4,
					"opAttempt": 1,
					"mCount": 0,
					"aScore": 0,
					"hasAnswered": 1,
					"isOP": 1,
					"isQtn": 1,
					"mMark": 4
				}
			],
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050072566,
			"MState": "mark",
			"userId": "85493b4a-0089-4e8c-b0b3-b6ae0e5800e2",
			"MStat": {
				"aMarked": 2,
				"mMarked": 2,
				"aCount": 2,
				"mAnsCnt": 2,
				"mScore": 8,
				"mCount": 0,
				"allAnswered": 4,
				"stScore": 12,
				"aAnsCnt": 2,
				"totalQtn": 4,
				"allMarked": 4,
				"aScore": 4,
				"totalAutoMQ": 2,
				"hasAnswered": 4,
				"aQtnCount": 2,
				"aQtnScore": 4,
				"mQtnScore": 8,
				"mQtnCount": 2
			},
			"dateMod": 1715050508503,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		},
		{
			"teacherId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"addUid": "e9e84d0a-b6de-4a96-bd6f-051aeab0dbef",
			"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
			"code": "AMK",
			"resp": [
				{
					"isRight": 1,
					"allMarked": 1,
					"isAutoMark": 1,
					"aCount": 1,
					"mScore": 0,
					"opAttempt": 0,
					"mCount": 0,
					"aScore": 2,
					"hasAnswered": 1,
					"isOP": 0,
					"isQtn": 1
				},
				{
					"isRight": 1,
					"allMarked": 1,
					"isAutoMark": 1,
					"aCount": 1,
					"mScore": 0,
					"opAttempt": 0,
					"mCount": 0,
					"aScore": 2,
					"hasAnswered": 1,
					"isOP": 0,
					"isQtn": 1
				},
				{
					"aCount": 0,
					"mScore": 4,
					"opAttempt": 1,
					"mCount": 1,
					"isRight": 1,
					"allMarked": 1,
					"isAutoMark": 0,
					"aScore": 0,
					"hasAnswered": 1,
					"isOP": 1,
					"mCorrect": 1,
					"isQtn": 1,
					"mMark": 4
				},
				{
					"isRight": 1,
					"allMarked": 1,
					"isAutoMark": 0,
					"aCount": 0,
					"mScore": 4,
					"opAttempt": 1,
					"mCount": 0,
					"aScore": 0,
					"hasAnswered": 1,
					"isOP": 1,
					"isQtn": 1,
					"mMark": 4
				}
			],
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"_attemp": 1,
			"dateAdd": 1715050173328,
			"MState": "mark",
			"userId": "e9e84d0a-b6de-4a96-bd6f-051aeab0dbef",
			"MStat": {
				"aMarked": 2,
				"mMarked": 2,
				"aCount": 2,
				"mAnsCnt": 2,
				"mScore": 8,
				"mCount": 1,
				"allAnswered": 4,
				"stScore": 12,
				"aAnsCnt": 2,
				"totalQtn": 4,
				"allMarked": 4,
				"aScore": 4,
				"totalAutoMQ": 2,
				"hasAnswered": 4,
				"aQtnCount": 2,
				"aQtnScore": 4,
				"mQtnScore": 8,
				"mQtnCount": 2
			},
			"dateMod": 1715050508505,
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5"
		}
	],
	"asm": {
		"Stat": {
			"ttl": 20,
			"submit": 2,
			"mark": 2,
			"sumMark": 24
		},
		"minsToGo": 0,
		"ALang": "en",
		"passUnit": "p",
		"AState": "aIP",
		"attTimes": 0,
		"ARand": 0,
		"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
		"attempt": 0,
		"AMode": "SCROLL_MODE",
		"classId": "01046a25-07e7-4b15-91b7-9e1a8a2ae5fe",
		"code": "ASM",
		"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
		"dateAdd": 1715050056939,
		"userId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
		"pubResults": 0,
		"EId": "Ay6sbpN9fHbRXkYzALcYKz",
		"showScores": "pub",
		"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
		"passValue": 60,
		"timeEnd": 0,
		"studentIds": [
			"0578ab57-848b-4412-b2dc-ce020e8ab7b8",
			"18b85bad-3656-4e16-ba14-13d219bf917f",
			"2474a578-703b-489f-9dd7-55c31ea57588",
			"2703e768-b78b-40b1-939e-90aaa939e9f4",
			"37460f3e-5421-456d-9d66-e68636fc38b9",
			"400fb248-e65f-4385-9816-b7e8c4bb9f1c",
			"4adf2521-fcd6-401f-b420-6293937c1346",
			"4d0a9c11-db18-4440-9cb5-18ed09ca26c2",
			"4e17c3ab-d49c-4ada-93c4-e717d2b11579",
			"60718591-5138-435c-93ed-00d20b355139",
			"6375bb34-1ec1-4b73-9b42-cfcda9231d68",
			"7d1c4c89-10ef-4853-b2a6-f670ad2cd043",
			"85493b4a-0089-4e8c-b0b3-b6ae0e5800e2",
			"a22ab31b-b004-495d-87e6-c4e5e7ad764a",
			"a2601d56-7ff8-4f2e-b886-702569f6830e",
			"b01b878e-8775-4a45-831c-851cf3a16aa8",
			"d1a03aaa-6de6-4fdd-8d3a-ef1fdc6a4ca5",
			"e6b63f90-ca5c-4a3e-ba13-cc1cdcd734ff",
			"e6c55d3b-e8e3-41ef-964a-4f05e66e85d5",
			"e9e84d0a-b6de-4a96-bd6f-051aeab0dbef"
		],
		"AResume": "resume",
		"datePub": 0,
		"time2beStart": 1715050051889,
		"qtns": [
			{
				"shuffleAnswer": 0,
				"addLogin": "phy_approver1",
				"code": "|ASM|QU2|",
				"QMetaIDStr": "subjects, PHY;DSEPHY, DSEPHY_1_1_1.2;difficulty, EASY",
				"SQType": "SQST_MCS",
				"respFile": 0,
				"psk": "|69Q4oknXkQ6NKVdRjFcqw7|DSEPHY101QC1Q1|",
				"maxFile": 1,
				"dp": "p",
				"QId": "DSEPHY101QC1Q1",
				"score": 2,
				"dateAdd": 1704359072378,
				"pk2": "|ASM|QU2|",
				"respURL": 0,
				"pk4": "|ASM|QU2|",
				"addUid": "7K2RyhM3qVpGiouzUP5HUh",
				"pk3": "|ASM|QU2|",
				"sk3": "|01046a25-07e7-4b15-91b7-9e1a8a2ae5fe|",
				"QEn": {
					"keywords": [
						"Which of the following are the fixed points on the Celsius temperature scale? "
					],
					"qData": "<p><span style=\"color:rgb(32,33,36);\">Which of the following are the fixed points on the Celsius temperature&nbsp;scale?&nbsp;</span></p><p><span style=\"color:rgb(32,33,36);\">(You may choose more than one option.)</span></p>",
					"qAnswers": [
						{
							"data": "<p>Steam point</p>",
							"type": "ATG",
							"correct": 1
						},
						{
							"data": "<p>Boiling point</p>",
							"type": "ATG",
							"correct": 0
						},
						{
							"data": "<p>Ice point</p>",
							"type": "ATG",
							"correct": 1
						},
						{
							"data": "<p>Melting point</p>",
							"type": "ATG",
							"correct": 0
						}
					]
				},
				"sk2": "|eef620b8-837d-4cb6-adee-bbbd2e10f5c5|",
				"sk4": "|DSEPHY101QC1Q1|",
				"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
				"QDisplayMetas": [
					"DSEPHY,DSEPHY",
					"DSEPHY,DSEPHY_1",
					"DSEPHY,DSEPHY_1_1",
					"subjects,PHY",
					"DSEPHY,DSEPHY_1_1_1.2",
					"difficulty,EASY"
				],
				"autoMark": 1,
				"multiAnswer": 1,
				"correctness": 0,
				"QCt": {
					"qData": "<p><span style=\"color:rgb(32,33,36);\">下列哪個是攝氏温標的定點？</span></p><p><span style=\"color:rgb(32,33,36);\">（可選多於一項。）</span></p>",
					"ansKeys": 0,
					"keywords": [
						"下列哪個是攝氏温標的定點？"
					],
					"qAnswers": [
						{
							"data": "<p>汽點</p>",
							"type": "ATG",
							"correct": 1
						},
						{
							"data": "<p>沸點</p>",
							"type": "ATG",
							"correct": 0
						},
						{
							"data": "<p>冰點</p>",
							"type": "ATG",
							"correct": 1
						},
						{
							"data": "<p>熔點</p>",
							"type": "ATG",
							"correct": 0
						}
					]
				},
				"QLangCt": 1,
				"modUid": "7K2RyhM3qVpGiouzUP5HUh",
				"caseSensitive": 0,
				"SQTGroup": "SQT_MCQ",
				"QATSubjIds": [
					"phy"
				],
				"QVerId": "9gSm1bUtncxdzXcF1UdZxb",
				"ppk": "|ASM|QU2|",
				"wordLimit": 1,
				"allowTeaCopyEdit": 1,
				"dateMod": 1704359072378,
				"QModeScroll": 1,
				"QVer": "3.0",
				"respText": 0,
				"respImage": 0,
				"ansChoice": "ACG",
				"QLangEn": 1,
				"QModeSlide": 1,
				"drpu": "P",
				"modLogin": "phy_approver1",
				"respDrawing": 0
			},
			{
				"shuffleAnswer": 0,
				"code": "|ASM|QU2|",
				"QMetaIDStr": "subjects, PHY;DSEPHY, DSEPHY_1_1_1.2;difficulty, EASY",
				"SQType": "SQST_MCS",
				"respFile": 0,
				"psk": "|69Q4oknXkQ6NKVdRjFcqw7|MsFBZDBwRqekE6FitA3ee5|",
				"maxFile": 1,
				"QId": "MsFBZDBwRqekE6FitA3ee5",
				"score": 2,
				"dateAdd": 1715049411546,
				"pk2": "|ASM|QU2|",
				"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
				"respURL": 0,
				"pk4": "|ASM|QU2|",
				"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
				"pk3": "|ASM|QU2|",
				"QMetaPros": [],
				"sk3": "|01046a25-07e7-4b15-91b7-9e1a8a2ae5fe|",
				"QEn": {
					"keywords": [
						"Which of the following are the fixed points on the Celsius temperature scale? "
					],
					"qData": "<p><span style=\"color:rgb(32,33,36);\">Which of the following are the fixed points on the Celsius temperature&nbsp;scale?&nbsp;</span></p><p><span style=\"color:rgb(32,33,36);\">(You may choose more than one option.)</span></p>",
					"qAnswers": [
						{
							"data": "<p>Steam point</p>",
							"type": "ATG",
							"correct": 1
						},
						{
							"data": "<p>Boiling point</p>",
							"type": "ATG",
							"correct": 0
						},
						{
							"data": "<p>Ice point</p>",
							"type": "ATG",
							"correct": 1
						},
						{
							"data": "<p>Melting point</p>",
							"type": "ATG",
							"correct": 0
						}
					]
				},
				"sk2": "|eef620b8-837d-4cb6-adee-bbbd2e10f5c5|",
				"sk4": "|MsFBZDBwRqekE6FitA3ee5|",
				"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
				"QDisplayMetas": [
					"DSEPHY,DSEPHY",
					"DSEPHY,DSEPHY_1",
					"DSEPHY,DSEPHY_1_1",
					"subjects,PHY",
					"difficulty,EASY",
					"DSEPHY,DSEPHY_1_1_1.1"
				],
				"QMetaPres": [],
				"autoMark": 1,
				"multiAnswer": 1,
				"correctness": 0,
				"QCt": {},
				"QLangCt": 0,
				"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
				"caseSensitive": 0,
				"SQTGroup": "SQT_MCQ",
				"QATSubjIds": [],
				"QVerId": "MsFBZJ28iJGRpWCYohc3nh",
				"ppk": "|ASM|QU2|",
				"wordLimit": 1,
				"userId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
				"allowTeaCopyEdit": 1,
				"dateMod": 1715049411546,
				"QModeScroll": 1,
				"QVer": "1.0",
				"respText": 0,
				"respImage": 0,
				"ansChoice": "ACG",
				"QLangEn": 1,
				"QModeSlide": 1,
				"respDrawing": 0
			},
			{
				"shuffleAnswer": 0,
				"code": "|ASM|QU2|",
				"QMetaIDStr": "subjects, SCII; junsci_book, JUNSCI_1A_1_1.2; junsci_skill, JUNSCI_identify_variable; junsci_skill, JUNSCI_design_test",
				"scoreMethod": "PBK",
				"SQType": "SQST_OEG",
				"respFile": 1,
				"psk": "|69Q4oknXkQ6NKVdRjFcqw7|MtTFdnkHQvZwxdi8NTPGqo|",
				"maxFile": 5,
				"QId": "MtTFdnkHQvZwxdi8NTPGqo",
				"score": 4,
				"dateAdd": 1715049411829,
				"pk2": "|ASM|QU2|",
				"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
				"respURL": 0,
				"pk4": "|ASM|QU2|",
				"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
				"pk3": "|ASM|QU2|",
				"QMetaPros": [],
				"sk3": "|01046a25-07e7-4b15-91b7-9e1a8a2ae5fe|",
				"QEn": {
					"ansHints": "",
					"qData": "<p>Lucy likes drinking bubble tea. She wants to find out whether a glass cup or a plastic cup can keep the tea cold for longer. She designs an experiment as follows:</p><p><strong>Steps</strong></p><ol><li>Prepare two cups of iced bubble tea as shown. Use a thermometer to measure the temperature of the tea in each cup.</li><li>Leave the two cups at room temperature. Measure the temperature of the tea in each cup after one hour.</li></ol><p>&nbsp; &nbsp; &nbsp;<img class=\"image_resized\" style=\"width:50%;\" src=\"\" oupid=\"S4BsEcXU5JhBMsZ8FYF4i8\"></p><p><strong>a</strong> State the following variables in Lucy's experiment.&nbsp;(2 marks)<br><strong>i&nbsp;</strong> Independent variable<br><strong>ii</strong> Dependent variable</p><p><strong>b</strong> Suggest TWO reasons why Lucy's experiment is NOT a fair test. (2 marks)</p>",
					"ansKeys": 1,
					"AKText": [
						{
							"text": "<p>Qai: type of cup (1)</p><p>Qaii: temperature of the tea after one hour (1)</p><p>Qb: The size of the cup is different. (1)<br>The amount of bubble tea in each cup is different. (1)</p>"
						}
					],
					"AKLink": [],
					"keywords": [
						"independent",
						"dependent",
						"variable",
						"fair test"
					],
					"AKFile": [],
					"qAnswers": [],
					"tAnswers": []
				},
				"sk2": "|eef620b8-837d-4cb6-adee-bbbd2e10f5c5|",
				"sk4": "|MtTFdnkHQvZwxdi8NTPGqo|",
				"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
				"QDisplayMetas": [
					"junsci_skill,JUNSCI_identify_variable",
					"subjects,SCII",
					"junsci_skill,JUNSCI_design_test",
					"DSEPHY,DSEPHY_1_1_1.1",
					"DSEPHY,DSEPHY_1_1",
					"DSEPHY,DSEPHY_1",
					"DSEPHY,DSEPHY"
				],
				"QMetaPres": [],
				"autoMark": 0,
				"multiAnswer": 0,
				"correctness": 1,
				"QCt": {},
				"QLangCt": 0,
				"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
				"caseSensitive": 0,
				"SQTGroup": "SQT_OPE",
				"QATSubjIds": [],
				"QVerId": "MtTFdsaUhPBdZ3exHzwfzR",
				"ppk": "|ASM|QU2|",
				"wordLimit": 0,
				"userId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
				"allowTeaCopyEdit": 1,
				"dateMod": 1715049411829,
				"QModeScroll": 1,
				"QVer": "1.0",
				"respText": 1,
				"respImage": 1,
				"QLangEn": 1,
				"QModeSlide": 1,
				"respDrawing": 1
			},
			{
				"shuffleAnswer": 0,
				"code": "|ASM|QU2|",
				"QMetaIDStr": "subjects, SCII; junsci_book, JUNSCI_1A_1_1.2; junsci_skill, JUNSCI_identify_variable; junsci_skill, JUNSCI_design_test",
				"scoreMethod": "PBK",
				"SQType": "SQST_OEG",
				"respFile": 1,
				"psk": "|69Q4oknXkQ6NKVdRjFcqw7|MudbBvUHECE6whYDenLtmF|",
				"maxFile": 5,
				"QId": "MudbBvUHECE6whYDenLtmF",
				"score": 4,
				"dateAdd": 1715049412105,
				"pk2": "|ASM|QU2|",
				"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
				"respURL": 0,
				"pk4": "|ASM|QU2|",
				"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
				"pk3": "|ASM|QU2|",
				"QMetaPros": [],
				"sk3": "|01046a25-07e7-4b15-91b7-9e1a8a2ae5fe|",
				"QEn": {
					"ansHints": "",
					"qData": "<p>Lucy likes drinking bubble tea. She wants to find out whether a glass cup or a plastic cup can keep the tea cold for longer. She designs an experiment as follows:</p><p><strong>Steps</strong></p><ol><li>Prepare two cups of iced bubble tea as shown. Use a thermometer to measure the temperature of the tea in each cup.</li><li>Leave the two cups at room temperature. Measure the temperature of the tea in each cup after one hour.</li></ol><p>&nbsp; &nbsp; &nbsp;<img class=\"image_resized\" style=\"width:50%;\" src=\"\" oupid=\"S4BsEcXU5JhBMsZ8FYF4i8\"></p><p><strong>a</strong> State the following variables in Lucy's experiment.&nbsp;(2 marks)<br><strong>i&nbsp;</strong> Independent variable<br><strong>ii</strong> Dependent variable</p><p><strong>b</strong> Suggest TWO reasons why Lucy's experiment is NOT a fair test. (2 marks)</p>",
					"ansKeys": 1,
					"AKText": [
						{
							"text": "<p>Qai: type of cup (1)</p><p>Qaii: temperature of the tea after one hour (1)</p><p>Qb: The size of the cup is different. (1)<br>The amount of bubble tea in each cup is different. (1)</p>"
						}
					],
					"AKLink": [],
					"keywords": [
						"independent",
						"dependent",
						"variable",
						"fair test"
					],
					"AKFile": [],
					"qAnswers": [],
					"tAnswers": []
				},
				"sk2": "|eef620b8-837d-4cb6-adee-bbbd2e10f5c5|",
				"sk4": "|MudbBvUHECE6whYDenLtmF|",
				"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
				"QDisplayMetas": [
					"junsci_skill,JUNSCI_identify_variable",
					"subjects,SCII",
					"junsci_skill,JUNSCI_design_test",
					"DSEPHY,DSEPHY_1_1",
					"DSEPHY,DSEPHY_1",
					"DSEPHY,DSEPHY",
					"DSEPHY,DSEPHY_1_1_1.2"
				],
				"QMetaPres": [],
				"autoMark": 0,
				"multiAnswer": 0,
				"correctness": 0,
				"QCt": {},
				"QLangCt": 0,
				"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
				"caseSensitive": 0,
				"SQTGroup": "SQT_OPE",
				"QATSubjIds": [],
				"QVerId": "MudbC1JUWeqnY7V3aKuHus",
				"ppk": "|ASM|QU2|",
				"wordLimit": 0,
				"userId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
				"allowTeaCopyEdit": 1,
				"dateMod": 1715049412105,
				"QModeScroll": 1,
				"QVer": "1.0",
				"respText": 1,
				"respImage": 1,
				"QLangEn": 1,
				"QModeSlide": 1,
				"respDrawing": 1
			}
		],
		"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
		"EQIds": [],
		"time2beEnd": 1715050051889,
		"Exer": {
			"addLogin": "",
			"EId": "Ay6sbpN9fHbRXkYzALcYKz",
			"aQCnt": 2,
			"ENameEn": "Assignment Report Test",
			"EModeScroll": 1,
			"EKwCt": "",
			"ELangEn": 1,
			"dateAdd": 1713937725931,
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"EModeSlide": 0,
			"EMetaIDStr": "",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"EShare": 0,
			"EMSubjId": "subjects,SCII",
			"mQCnt": 2,
			"ENexts": [],
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"mScore": 8,
			"EKwEn": "",
			"ELangCt": 0,
			"userId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"ENameCt": "",
			"ERemes": [],
			"dateMod": 1715049412466,
			"ECtns": [
				{
					"type": "q",
					"ct": "",
					"QIds": "MsFBZDBwRqekE6FitA3ee5"
				},
				{
					"type": "q",
					"ct": "",
					"QIds": "DSEPHY101QC1Q1"
				},
				{
					"type": "q",
					"ct": "",
					"QIds": "MtTFdnkHQvZwxdi8NTPGqo"
				},
				{
					"type": "q",
					"ct": "",
					"QIds": "MudbBvUHECE6whYDenLtmF"
				}
			],
			"aScore": 4,
			"ESubjIds": [],
			"EMetaDisplays": [],
			"modLogin": ""
		},
		"AHints": 0,
		"limitMin": 0,
		"year": "2023-2024",
		"timeStart": 0,
		"dateMod": 1715050508549,
		"APass": 1
	},
	"answerVis": 1,
	"qtns": [
		{
			"QCt": {
				"qData": "<p><span style=\"color:rgb(32,33,36);\">下列哪個是攝氏温標的定點？</span></p><p><span style=\"color:rgb(32,33,36);\">（可選多於一項。）</span></p>",
				"ansKeys": 0,
				"keywords": [
					"下列哪個是攝氏温標的定點？"
				],
				"qAnswers": [
					{
						"data": "<p>汽點</p>",
						"type": "ATG",
						"correct": 1
					},
					{
						"data": "<p>沸點</p>",
						"type": "ATG",
						"correct": 0
					},
					{
						"data": "<p>冰點</p>",
						"type": "ATG",
						"correct": 1
					},
					{
						"data": "<p>熔點</p>",
						"type": "ATG",
						"correct": 0
					}
				]
			},
			"shuffleAnswer": 0,
			"addLogin": "phy_approver1",
			"QVerId": "9gSm1bUtncxdzXcF1UdZxb",
			"respFile": 0,
			"QModeScroll": 1,
			"score": 2,
			"dp": "p",
			"QATSubjIds": [
				"phy"
			],
			"respDrawing": 0,
			"multiAnswer": 1,
			"code": "|ASM|QU2|",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"dateAdd": 1704359072378,
			"allowTeaCopyEdit": 1,
			"respImage": 0,
			"respURL": 0,
			"QModeSlide": 1,
			"caseSensitive": 0,
			"SQType": "SQST_MCS",
			"QDisplayMetas": [
				"DSEPHY,DSEPHY",
				"DSEPHY,DSEPHY_1",
				"DSEPHY,DSEPHY_1_1",
				"subjects,PHY",
				"DSEPHY,DSEPHY_1_1_1.2",
				"difficulty,EASY"
			],
			"modUid": "7K2RyhM3qVpGiouzUP5HUh",
			"autoMark": 1,
			"ansChoice": "ACG",
			"QVer": "3.0",
			"drpu": "P",
			"QMetaIDStr": "subjects, PHY;DSEPHY, DSEPHY_1_1_1.2;difficulty, EASY",
			"QId": "DSEPHY101QC1Q1",
			"addUid": "7K2RyhM3qVpGiouzUP5HUh",
			"respText": 0,
			"QLangEn": 1,
			"maxFile": 1,
			"correctness": 0,
			"wordLimit": 1,
			"modLogin": "phy_approver1",
			"QEn": {
				"keywords": [
					"Which of the following are the fixed points on the Celsius temperature scale? "
				],
				"qData": "<p><span style=\"color:rgb(32,33,36);\">Which of the following are the fixed points on the Celsius temperature&nbsp;scale?&nbsp;</span></p><p><span style=\"color:rgb(32,33,36);\">(You may choose more than one option.)</span></p>",
				"qAnswers": [
					{
						"data": "<p>Steam point</p>",
						"type": "ATG",
						"correct": 1
					},
					{
						"data": "<p>Boiling point</p>",
						"type": "ATG",
						"correct": 0
					},
					{
						"data": "<p>Ice point</p>",
						"type": "ATG",
						"correct": 1
					},
					{
						"data": "<p>Melting point</p>",
						"type": "ATG",
						"correct": 0
					}
				]
			},
			"SQTGroup": "SQT_MCQ",
			"QLangCt": 1,
			"dateMod": 1704359072378
		},
		{
			"QCt": {},
			"shuffleAnswer": 0,
			"QVerId": "MsFBZJ28iJGRpWCYohc3nh",
			"respFile": 0,
			"QModeScroll": 1,
			"score": 2,
			"QMetaPres": [],
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"QATSubjIds": [],
			"respDrawing": 0,
			"multiAnswer": 1,
			"code": "|ASM|QU2|",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"dateAdd": 1715049411546,
			"allowTeaCopyEdit": 1,
			"userId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"respImage": 0,
			"respURL": 0,
			"QModeSlide": 1,
			"caseSensitive": 0,
			"SQType": "SQST_MCS",
			"QDisplayMetas": [
				"DSEPHY,DSEPHY",
				"DSEPHY,DSEPHY_1",
				"DSEPHY,DSEPHY_1_1",
				"subjects,PHY",
				"difficulty,EASY",
				"DSEPHY,DSEPHY_1_1_1.1"
			],
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"autoMark": 1,
			"ansChoice": "ACG",
			"QVer": "1.0",
			"QMetaIDStr": "subjects, PHY;DSEPHY, DSEPHY_1_1_1.2;difficulty, EASY",
			"QId": "MsFBZDBwRqekE6FitA3ee5",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"respText": 0,
			"QLangEn": 1,
			"QMetaPros": [],
			"maxFile": 1,
			"correctness": 0,
			"wordLimit": 1,
			"QEn": {
				"keywords": [
					"Which of the following are the fixed points on the Celsius temperature scale? "
				],
				"qData": "<p><span style=\"color:rgb(32,33,36);\">Which of the following are the fixed points on the Celsius temperature&nbsp;scale?&nbsp;</span></p><p><span style=\"color:rgb(32,33,36);\">(You may choose more than one option.)</span></p>",
				"qAnswers": [
					{
						"data": "<p>Steam point</p>",
						"type": "ATG",
						"correct": 1
					},
					{
						"data": "<p>Boiling point</p>",
						"type": "ATG",
						"correct": 0
					},
					{
						"data": "<p>Ice point</p>",
						"type": "ATG",
						"correct": 1
					},
					{
						"data": "<p>Melting point</p>",
						"type": "ATG",
						"correct": 0
					}
				]
			},
			"SQTGroup": "SQT_MCQ",
			"QLangCt": 0,
			"dateMod": 1715049411546
		},
		{
			"QCt": {},
			"shuffleAnswer": 0,
			"QVerId": "MtTFdsaUhPBdZ3exHzwfzR",
			"respFile": 1,
			"QModeScroll": 1,
			"score": 4,
			"QMetaPres": [],
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"QATSubjIds": [],
			"respDrawing": 1,
			"multiAnswer": 0,
			"code": "|ASM|QU2|",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"dateAdd": 1715049411829,
			"allowTeaCopyEdit": 1,
			"userId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"respImage": 1,
			"respURL": 0,
			"QModeSlide": 1,
			"caseSensitive": 0,
			"SQType": "SQST_OEG",
			"QDisplayMetas": [
				"junsci_skill,JUNSCI_identify_variable",
				"subjects,SCII",
				"junsci_skill,JUNSCI_design_test",
				"DSEPHY,DSEPHY_1_1_1.1",
				"DSEPHY,DSEPHY_1_1",
				"DSEPHY,DSEPHY_1",
				"DSEPHY,DSEPHY"
			],
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"autoMark": 0,
			"QVer": "1.0",
			"QMetaIDStr": "subjects, SCII; junsci_book, JUNSCI_1A_1_1.2; junsci_skill, JUNSCI_identify_variable; junsci_skill, JUNSCI_design_test",
			"QId": "MtTFdnkHQvZwxdi8NTPGqo",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"respText": 1,
			"QLangEn": 1,
			"scoreMethod": "PBK",
			"QMetaPros": [],
			"maxFile": 5,
			"correctness": 1,
			"wordLimit": 0,
			"QEn": {
				"ansHints": "",
				"qData": "<p>Lucy likes drinking bubble tea. She wants to find out whether a glass cup or a plastic cup can keep the tea cold for longer. She designs an experiment as follows:</p><p><strong>Steps</strong></p><ol><li>Prepare two cups of iced bubble tea as shown. Use a thermometer to measure the temperature of the tea in each cup.</li><li>Leave the two cups at room temperature. Measure the temperature of the tea in each cup after one hour.</li></ol><p>&nbsp; &nbsp; &nbsp;<img class=\"image_resized\" style=\"width:50%;\" src=\"\" oupid=\"S4BsEcXU5JhBMsZ8FYF4i8\"></p><p><strong>a</strong> State the following variables in Lucy's experiment.&nbsp;(2 marks)<br><strong>i&nbsp;</strong> Independent variable<br><strong>ii</strong> Dependent variable</p><p><strong>b</strong> Suggest TWO reasons why Lucy's experiment is NOT a fair test. (2 marks)</p>",
				"ansKeys": 1,
				"AKText": [
					{
						"text": "<p>Qai: type of cup (1)</p><p>Qaii: temperature of the tea after one hour (1)</p><p>Qb: The size of the cup is different. (1)<br>The amount of bubble tea in each cup is different. (1)</p>"
					}
				],
				"AKLink": [],
				"keywords": [
					"independent",
					"dependent",
					"variable",
					"fair test"
				],
				"AKFile": [],
				"qAnswers": [],
				"tAnswers": []
			},
			"SQTGroup": "SQT_OPE",
			"QLangCt": 0,
			"dateMod": 1715049411829
		},
		{
			"QCt": {},
			"shuffleAnswer": 0,
			"QVerId": "MudbC1JUWeqnY7V3aKuHus",
			"respFile": 1,
			"QModeScroll": 1,
			"score": 4,
			"QMetaPres": [],
			"schoolId": "17e17ec3-e548-45b6-92c4-03cf8d185ad8",
			"QATSubjIds": [],
			"respDrawing": 1,
			"multiAnswer": 0,
			"code": "|ASM|QU2|",
			"assignId": "69Q4oknXkQ6NKVdRjFcqw7",
			"dateAdd": 1715049412105,
			"allowTeaCopyEdit": 1,
			"userId": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"respImage": 1,
			"respURL": 0,
			"QModeSlide": 1,
			"caseSensitive": 0,
			"SQType": "SQST_OEG",
			"QDisplayMetas": [
				"junsci_skill,JUNSCI_identify_variable",
				"subjects,SCII",
				"junsci_skill,JUNSCI_design_test",
				"DSEPHY,DSEPHY_1_1",
				"DSEPHY,DSEPHY_1",
				"DSEPHY,DSEPHY",
				"DSEPHY,DSEPHY_1_1_1.2"
			],
			"modUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"autoMark": 0,
			"QVer": "1.0",
			"QMetaIDStr": "subjects, SCII; junsci_book, JUNSCI_1A_1_1.2; junsci_skill, JUNSCI_identify_variable; junsci_skill, JUNSCI_design_test",
			"QId": "MudbBvUHECE6whYDenLtmF",
			"addUid": "eef620b8-837d-4cb6-adee-bbbd2e10f5c5",
			"respText": 1,
			"QLangEn": 1,
			"scoreMethod": "PBK",
			"QMetaPros": [],
			"maxFile": 5,
			"correctness": 0,
			"wordLimit": 0,
			"QEn": {
				"ansHints": "",
				"qData": "<p>Lucy likes drinking bubble tea. She wants to find out whether a glass cup or a plastic cup can keep the tea cold for longer. She designs an experiment as follows:</p><p><strong>Steps</strong></p><ol><li>Prepare two cups of iced bubble tea as shown. Use a thermometer to measure the temperature of the tea in each cup.</li><li>Leave the two cups at room temperature. Measure the temperature of the tea in each cup after one hour.</li></ol><p>&nbsp; &nbsp; &nbsp;<img class=\"image_resized\" style=\"width:50%;\" src=\"\" oupid=\"S4BsEcXU5JhBMsZ8FYF4i8\"></p><p><strong>a</strong> State the following variables in Lucy's experiment.&nbsp;(2 marks)<br><strong>i&nbsp;</strong> Independent variable<br><strong>ii</strong> Dependent variable</p><p><strong>b</strong> Suggest TWO reasons why Lucy's experiment is NOT a fair test. (2 marks)</p>",
				"ansKeys": 1,
				"AKText": [
					{
						"text": "<p>Qai: type of cup (1)</p><p>Qaii: temperature of the tea after one hour (1)</p><p>Qb: The size of the cup is different. (1)<br>The amount of bubble tea in each cup is different. (1)</p>"
					}
				],
				"AKLink": [],
				"keywords": [
					"independent",
					"dependent",
					"variable",
					"fair test"
				],
				"AKFile": [],
				"qAnswers": [],
				"tAnswers": []
			},
			"SQTGroup": "SQT_OPE",
			"QLangCt": 0,
			"dateMod": 1715049412105
		}
	]
}
