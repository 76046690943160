import {ButtonProps, Stack} from "react-bootstrap";
import {ReactNode} from "react";

type align = "start" | "end"

interface Props extends ButtonProps {
    title?: string
    hiddenTitle?: boolean
    iconAlign?: align
    children?: ReactNode
}

const IconGroup = (props: Props) => {
    const {title = "", hiddenTitle = false, iconAlign = "start", children} = props
    return <Stack direction={"horizontal"} gap={2}
                  className={`d-inline-flex justify-content-center ${iconAlign === "end" ? "" : "flex-row-reverse"}`}>
        {title !== "" && <span className={hiddenTitle ? "d-none d-md-block" : ""}>{title}</span>}
        {children}
    </Stack>
}

export default IconGroup