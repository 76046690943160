import { useEffect, useMemo } from 'react';

import { ATDo_ESet, _ATCanDo } from '../../consts/ATRoleRights';
import { objKeys, strCmp, toAry, toObj, toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';


import { ReduxBind } from '../../saga/ReduxState';

import { _ATRoot } from '../../consts/ATConstReact';
import { apiCallLoad_ } from '../../libs/awsFuncs';
import { urlPush_Replace } from '../../saga/urlPush.saga';
import { ATErrsDiv, aTUITime, clickUrl, langDisplay } from '../AppUtil';
import { cpATIcoBtn_, svgIcon2 } from '../components/CpATIcoBtn';
import { QDPBtn } from '../components/CpDraftPubBtns';
import { useDraftPubList } from '../components/CpPubDraftList';

const PageATESets = ReduxBind((props) => { 
	const dispatch = props.dispatch;
	const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
	const letESet =_ATCanDo(role, ATDo_ESet); 

	const [ESets, setESets, err2d, setErr2d, ticks, cntTick, setTicks, setTick, tickAll, setTickAll, clickTickAll, 
		onLoad, onPub, confirmCall, ESetIds] = useDraftPubList('ESetId', dispatch );

		
		

	const sortIds = useMemo(()=> {
		const sorts = ESetIds.map( id => {
			const {draft:d, pub:p} = ESets[id]; 
			return toObj(d? d: p);
		}); 
		sorts.sort((a, b) => (b.dateMod - a.dateMod) || strCmp(a.ESetId, b.ESetId));
		return sorts.map(s => s.ESetId); 
	}, [ESets]);

	const reload = () => apiCallLoad_( dispatch, '/getATESets', onLoad, {});
	useEffect(() => { letESet? reload(): dispatch(urlPush_Replace(_ATRoot)); }, []);
	
	const clickPub = letESet && cntTick && confirmCall('Please confirm publish', '/putATESetsPublish', onPub, {ticks} );
	const clickUnpub = letESet && cntTick && confirmCall('Please confirm unpublish', '/putATESetsUnpublish', onLoad, {ticks} );
	const clickDel = letESet && cntTick && confirmCall('Please confirm delete', '/deleteESets', onLoad, {ticks} );

	const clickReload = letESet && UI.stopEventThen(reload);
	const clickNew = letESet && clickUrl(dispatch, _ATRoot + 'exerciseset/new');
	const clickEdit = letESet && (cntTick===1) && clickUrl(dispatch, _ATRoot + 'exerciseset/edit/'+ticks[0]);
	const clickClone = letESet && (cntTick===1) && clickUrl(dispatch, _ATRoot + 'exerciseset/new/'+ticks[0]);

	return <div className="adminAccountTop">
		<div className="flexColStart w100 p8" style={{height:'100%'}}>
    	{/*<div className='w100' style={{overflow:'auto', marginBottom:'50px'}}> */}
		<div className='w100' style={{overflow:'auto'}}> 
		<div className="f16">Exercises / Exercise Sets</div>
		<div className="adminToolsContainer"><div style={{display:"flex"}}>
			<div className="adminTools1">
				{letESet? cpATIcoBtn_('labelDiagram/add', 'New', clickNew): ''}
				{letESet? cpATIcoBtn_('general/edit', 'Edit', clickEdit): ''}
				{letESet? cpATIcoBtn_('general/copy', 'Duplicate', clickClone): ''}
				{letESet? cpATIcoBtn_('general/delete', 'Delete', clickDel): ''}
				{letESet? cpATIcoBtn_('general/publish', 'Publish', clickPub): ''}
				{letESet? cpATIcoBtn_('general/unpublish', 'Unpublish', clickUnpub): ''}
				{letESet? cpATIcoBtn_('general/reload', 'Reload', clickReload): ''}
			</div>
			<div className="adminTools2" onClick={UI.stopEvent} >
				{svgIcon2("general/filter","#2995cd")}Filter: ({ESetIds.length})
			</div>
		</div></div>
			<div className="execItemRowSHeader f12">
			<div className="execSItem1">{UI.CheckBox0(tickAll, clickTickAll, 'chkall')}All</div>
			<div className="execSItem2">Exercise Set ID</div>
			<div className="execSItem3">ESAS series code</div>
			<div className="execSItem4">Description</div>
			<div className="execSItem5">Languages</div>
			<div className="execSItem6">No. Of Exercises</div>
			<div className="execSItem7">Version</div>
			<div className="execSItem8">Last Modified</div>
        </div>            
        <div className="metaListContainer">
			{sortIds.map( ESetId => (CpExerItem(ESetId, ESets[ESetId], err2d[ESetId], ticks, setTick))) }         
        </div>		
	</div></div></div>
});
export default PageATESets;

const CpExerItem = (ESetId, eset, errs, ticks, setTick ) => {
	const {draft:d, pub:p} = eset; 
	const dp = toObj(d? d: p);
	const on = ticks?.includes(ESetId);
	const _key = ESetId+'_'+(on?'Y':'N');
	
	return <label key={'eset_'+_key} style={{width:'100%'}}><div className="itemRowBlk">
		<div className="adminItemRow f12">
		<div className="execSItem1">{UI.CheckBox0(on, setTick(ESetId), 'chk'+_key)}</div>
		<div className="execSItem2">{dp.ESetId}</div>
		<div className="execSItem3">{dp.ESASStr}</div>
		<div className="execSItem4">{dp.ESNameEn}<br/>{dp.ESNameCt}</div>
		<div className="execSItem5">
			{d? langDisplay(d.ESLangEn, d.ESLangCt):(p? langDisplay(p.ESLangEn, p.ESLangCt): '')}
		</div><div className="execSItem6">
			{UI.centerBox(d? toAry(d.ESEIds).length:'',p? toAry(p.ESEIds).length:'')} 
		</div><div className="execSItem7">
			{UI.centerBox(d? DPDisplay(d): '',p? DPDisplay(p): '')}
		</div><div className="execSItem8"><div>{aTUITime(dp.dateMod)}</div><div>{dp.modLogin}</div>
		</div>
		<div></div>
    </div>
	{ATErrsDiv(errs)}</div>
	</label>;
};
const DPDisplay = q => { return (q ? <div>{QDPBtn(q, 'ESVer', UI.stopEvent, 0)}</div> : ''); };
//{d? langDisplay(d.ESLangEn, d.ESLangCt): ''} | {p? langDisplay(p.ESLangEn, p.ESLangCt): ''}
