import { useEffect, useMemo, useState } from 'react';
import { ReduxBind } from "../../saga/ReduxState";

import {stopEvent} from '../../libs/libUI';
import { _ATRoot } from '../../consts/ATConstReact';

//--------------------------------------------------------
import {Button, Col, Container, Row, Accordion, CloseButton} from "react-bootstrap";
import CpSharedModalHeader from '../../AppExPFUser/_components/CpSharedModalHeader';
import { CpSettingModalTitle, CpSettingModalSection, CpSettingModalTabContainer } from '../../AppExPFUser/_components/CpSettingModalTabCps';
import { useUILang } from '../utils/useUILang';
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpIco from '../../AppExPFUser/_components/CpIco';
import CpSharedModal from '../../AppExPFUser/_components/CpSharedModal';
import { getTeacherName} from './TabExerContent';
import { _ERule_Conditions, } from './LayerEditRule';
import { aryLen, objEntries, toAry, toInt, toObj, toStr } from '../../libs/libType';
import LayerEditReme, { usePickremeMQs } from './LayerEditReme';
import { atErrDiv, atErrNoDot, aTErrTxt1, BtnPopDev, jsStr, preJS } from '../AppUtil';
import { langTxt } from '../../AppExPFUser/EPLibrary/PageLibrary';
import { _ATMetaTransByCode } from '../../consts/ATMetaTypes';
import { CpCardMetaTag, CpEPTagMetaList } from '../../AppExPFUser/EPExercise/CpExerciseCard';
import { toUniIdAry } from '../../consts/ATValidate';
import { RemeLvPre, RemeLvPro, RemeLvSame } from '../../consts/ATValidateReme';

// follow poc
import CpExerciseAccordionOpenCTA from '../components/CpExerciseAccordionOpenCTA';
import { onlyEndMIds, onlyEndSMIds } from '../../AppExPFUser/EPReports/data/dataUtil';
import { MIdsByMSId, splitSMId } from '../../consts/ATValidateMetaSet';
import TagRemeLv, { remeLvTxt, TagMetaReme } from './TagRemeLv';
import { isDupReme } from '../../consts/ATValidateExer';
import { fakeIsMobile } from '../utils/useWinResize';

const EDIT_ACTION = 'EDIT_ACTION';
const REMOVE_ACTION = 'REMOVE_ACTION';

const copyNext = ENext => ({...ENext});

const CpExReme = ReduxBind(props => {
    const myUId = props.userId();
    const {dispatch, isAT, lock, lockReme, showEn, useGetCache, cache2d, getMediaDLs, index, idx, ex,  
        REx, addEReme, replaceEReme, clickDelERemeI,} = props;
    const {tagMSets, useTagMSets, } = props;
    const {ERemes, remeQCnts,} = props;
    const {edRIdx, setEdRIdx, edReme, setEdReme} = props;

    const [t, uiEn, UILang, setUILang, ut, t2, t3] = useUILang();
    const ct = (e, c) => langTxt(showEn, e, c);

    const ruleName = showEn? REx.ruleNameEn: REx.ruleNameCt;

    const enuid = REx.modUid;
    const thrNames = useGetCache('teachers', '*'); 
    const owner = isAT? enuid:  (getTeacherName(enuid, thrNames, ut, t) || t("oupc-name"));

    const [action, setAction] = useState("");
    const close = () => {setAction(''); setEdReme(); setEdRIdx(-1); };
    const handleClose = e => { stopEvent(e); close();};

    const clickPopEdit = e => { stopEvent(e); setAction(EDIT_ACTION); setEdReme({...copyNext(REx), modUid:myUId}); setEdRIdx(idx); };
    const clickPopDelete = e => { stopEvent(e); setAction(REMOVE_ACTION); };

    const clickUpdate = e => { stopEvent(e); replaceEReme(idx, edReme); close();};
    const clickDelete = e => { clickDelERemeI(idx)(e); close(); };

    const msid = toStr(REx?.MSetId);

    const mset = useTagMSets([msid])[msid];
    const remeQNum = toInt(props.remeQNum);

    const [mids, remeMIds, A_RemeMIds, sameMIds] = useQCntLvRemes(remeQNum, REx?.rmLv, msid, mset, props.EQSum, remeQCnts); 
    
    const _MSTName = mset && t2(_ATMetaTransByCode(mset.MSType), showEn);
    const _MSName = mset && ct(mset?.MSNameEn, mset?.MSNameCt);
    const errDup = useMemo(() => toAry(ERemes).some((b, i) => (i !== idx) && isDupReme(REx, b)), [ERemes, REx]);

    const [isEdit, isDel] = [action === EDIT_ACTION, action === REMOVE_ACTION];

    return <div key={'reme'+idx} className={"CpExReme d-flex flex-column w-100 border rounded-light user-select-none sequenceListAnimation"}>
{preJS({isAT, lock, lockReme})}
    <div className={"semi-bold fs-6 d-flex justify-content-between px-3 py-2 bg-dim-100"}>
        <span className={"d-flex flex-column flex-md-row gap-2 text-truncate"} style={{alignItems:"center"}}>
            <div className="d-flex gap-2">
                <span>{index}.</span><span className={"text-truncate"}>{ruleName}</span>   
            </div>
            <div className="d-flex flex-wrap gap-2"><TagRemeLv status={REx.rmLv} /></div>
        </span>            
        <span className={"d-flex fs-6"}>
            {(lock || lockReme)?'':
            <span role="button" className="btn btn-borderless-exercise-third-btn"
                onClick={clickPopEdit}><CpIco src={IconList.general.edit}/></span>}
            {lock? '':
            <span role="button" className="btn btn-borderless-exercise-third-btn"
                onClick={clickPopDelete}><CpIco src={IconList.general.trash}/></span>}
        </span>
    </div>
    {/*<Container fluid={true} className={"pt-3 pb-5 px-3"}>*/}
    <Container fluid={true} className={"p-3"}>
        <Row>
            <Col xs={12} lg={5}>
<BtnPopDev txt="Rex">{preJS(REx, 3)}</BtnPopDev>
            <div className={"d-flex flex-column align-items-start gap-3 w-100"}>
                <div className={"semi-bold text-exercise-third-btn fs-5"}>{t("exercise-edit-tab-next-steps-when")}</div>
                <div className={`semi-bold text-lowercase d-flex flex-wrap ${showEn ? "gap-2" : ""}`}>
                    <div className={"semi-bold mb-2 w-100"}>
                        {t("exercise-edit-tab-next-steps-student-attempted").replace("%s",t(
                            REx.rmAB === 'a'? 'exercise-edit-tab-details-attempted-score-higher-follow-up-exercise':
                            REx.rmAB === 'b'? 'exercise-edit-tab-details-attempted-score-lower-follow-up-exercise':
                            '')
                        ).replace("%s",toInt(REx.rmMark) + " %")}
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="fs-8 d-flex gap-2">
                        <span className="text-nowrap semi-bold">{t("exercise-edit-tab-details-metadata-follow-up-exercise")}:</span>
                        <span>{toStr(_MSTName)}</span>
                    </div>
                    <div className="fs-8 d-flex gap-2">
                        <span className="text-nowrap semi-bold">{t("exercise-edit-tab-details-metadata-set-follow-up-exercise")}: </span>
                        <span>{toStr(_MSName)}</span>
                    </div>
                </div>
                <div className={"d-flex justify-content-between rounded-light border bg-white mb-3 w-100"}>
                    <Accordion defaultActiveKey={fakeIsMobile()?"":"0"} style={{ width: "100%" }}>
                        <div className={"d-flex justify-content-between align-items-center gap-3 p-2 semi-bold w-100 bg-dim-100"} style={{ borderRadius: "4px" }}>
                            <CpExerciseAccordionOpenCTA eventKey="0">{t("exercise-edit-tab-details-assessed-categories-follow-up-exercise")}</CpExerciseAccordionOpenCTA>
                        </div>
                        <Accordion.Collapse eventKey="0"><div>
                            <hr className={"mt-0 mb-2 border-dim-400"} />
                            <div className='p-2 pt-0 fs-8 ' {...{'data-same-mids':sameMIds}}>
                                {aryLen(sameMIds)? '': t('exercise-edit-tab-details-not-assessed-categories-follow-up-exercise')}
                                <CpEPTagMetaList {...{ct, msid, mids: sameMIds, useTagMSets, tagMSets, CpTag:TagMetaReme}} />
                            </div>
                        </div></Accordion.Collapse>
                    </Accordion>
                </div>     
            </div>                 
            </Col>

            <Col xs={12} lg={2}><div className={"d-flex h-100 flex-column align-items-center justify-content-center py-5"}>
                <div className={"align-items-center gap-3 fs-3 text-exercise-third-btn  d-none d-lg-flex"}>
                    <CpIco src={IconList.general.nextStepRight}/></div>
                <div className={"align-items-center gap-3 fs-3 text-exercise-third-btn  d-lg-none d-flex "}>
                    <CpIco src={IconList.general.nextStepBottom}/></div>
            </div></Col>

            <Col xs={12} lg={5}>
            <div className={"d-flex flex-column align-items-start gap-3 w-100"}>
                <div className={"semi-bold text-exercise-third-btn fs-5"}>{t("exercise-edit-tab-next-steps-action")}</div>
                <div className={"semi-bold text-lowercase"}>
                    {t("exercise-edit-tab-next-steps-provide-the-student-follow-up-exercise")}
                </div>
                {/*<div className={"semi-bold mb-2"}>{t("student-can-retry-until-attaining-master-score")} </div>*/}
                <div className="d-flex flex-column gap-2">
                    <div className="fs-8 d-flex gap-2">
                        <span className="text-nowrap semi-bold">{t("exercise-edit-tab-details-question-scope-follow-up-exercise")}:</span>
                        {/*<span>{toInt(REx.rmPass)} %</span>*/}
                        <span>{remeLvTxt(REx.rmLv, t)}</span>
                    </div>                                            
                    <div className="fs-8 d-flex gap-2">
                        <span className="text-nowrap semi-bold">{t("exercise-edit-tab-details-no-of-questions-follow-up-exercise")}:</span>
                        <span>{remeQNum}</span>
                    </div>
                </div>
                <div className={"d-flex justify-content-between rounded-light border bg-white mb-3 w-100"}>
                    <Accordion defaultActiveKey={fakeIsMobile()?"":"0"} style={{ width: "100%" }}>
                        <div className={"d-flex justify-content-between align-items-center gap-3 p-2 semi-bold w-100 bg-dim-100"} style={{ borderRadius: "4px" }}>
                            <CpExerciseAccordionOpenCTA eventKey="0">{t("exercise-edit-tab-details-exercise-detail-follow-up-exercise")}</CpExerciseAccordionOpenCTA>
                        </div>
                        <Accordion.Collapse eventKey="0"><div>
                            <hr className={"mt-0 mb-2 border-dim-400"} />
                            <div className='p-2 pt-0' {...{'data-a_rememids':A_RemeMIds}}>
                                {atErrNoDot(errDup && 'error.error_at_repeated_question_scope', t)}
                                {aryLen(A_RemeMIds)? '': <span className="fs-8" style={{color:"#545454"}}>{t('exercise-edit-tab-details-not-follow-up-categories-follow-up-exercise')}</span>}
                                <CpEPTagMetaList {...{ct, msid, mids:A_RemeMIds, useTagMSets, tagMSets, CpTag:TagMetaReme}} />
                            </div>
                        </div></Accordion.Collapse>
                    </Accordion>
                </div> 
            </div>               
            </Col>
        </Row>
    </Container>

    <div className={"d-flex gap-2 semi-bold px-3 py-2 bg-dim-100 fs-8"}>
        <span className={"d-none d-md-block"}>{t("exercise-edit-by")} :</span>
        <span className={"d-flex align-items-center gap-2 text-truncate"}>
            <span className={"fs-8"}><CpIco src={owner === t("oupc-name") ? IconList.brand.oupc : IconList.general.avatar}/></span>
            <span key={owner} className={"text-truncate"}>{owner}</span>
        </span>
    </div>
    {isEdit? <LayerEditReme key={'newNextStep'} index={0} {...{...props, idx, clickCloseEx: handleClose, clickSaveEx: clickUpdate, REx: edReme, setREx: setEdReme}} />
    :isDel? <CpSharedModal className="" show={isDel} scrollable={true} 
        header={<CpSharedModalHeader {...{title: t("exercise-edit-tab-next-steps-delete-follow-up-exercise"), iconPath: IconList.general.trash, handleClose}} />}
        footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
            <Button variant="gray-body-color w-100 border" onClick={handleClose}>{t("cancel")}</Button>
            <Button variant={`danger btn-block w-100`} onClick={clickDelete}>{t("delete")}</Button>
        </div>} >
        <CpSettingModalTabContainer gap={3}><CpSettingModalSection>
                <CpSettingModalTitle>
                    {lockReme?<div dangerouslySetInnerHTML={{__html: t('warning.warning_delete_follow_up_exercise_after_published')}} />
                    :t('warning.warning_delete_follow_up_exercise_before_published')}
                </CpSettingModalTitle>
        </CpSettingModalSection></CpSettingModalTabContainer>
    </CpSharedModal>        
    :''}     
    </div>;
});
export default CpExReme; 

export const pickRemeMetas = (rmLv, SMIds, preSMIds, proSMIds) => (rmLv === RemeLvPre)? preSMIds: (rmLv === RemeLvPro)? proSMIds: SMIds;

export const q2dForLog = q2d => Object.fromEntries(objEntries(q2d).map(([m,qs]) => [m, toAry(qs).join(', ')])); 

export const AddTitle = (props) => {
    const { handleClose=()=>{}, icon, title } = props;
    return <div className={"d-flex justify-content-between align-items-center w-100 p-2 px-3 bg-dim-100 fs-5 user-select-none"}>
        <span className={"d-flex gap-2 align-items-center fs-5 user-select-none"}>
            <span><CpIco src={icon} /></span>
            <span>{title}</span>
            {props.children}
        </span>
        <CloseButton onClick={() => handleClose()} />
    </div>
};

export const useQCntRemes = (remeQNum, msid, mset, EQSum, remeQCnts) => {
    const [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore, EQMetas, EQMetaPres, EQMetaPros, minQs, ] = toAry(EQSum);
    const mids = useMemo(() => MIdsByMSId(EQMetas, msid), [EQMetas, msid]);
    const sameMIds = useMemo(() => onlyEndMIds(mset, mids, 0), [mset, mids]);
    const sameSMIds = useMemo(() => toUniIdAry(sameMIds).map(mid => msid+','+mid), [sameMIds]);
    const RemeLVANs = useMemo(() => {
        const rqcs = toObj(remeQCnts); 
        const pfx = msid+',';
        const splitRemeANs = smids => {
            const A_smids = toUniIdAry(smids).filter(smid => rqcs[smid] >= remeQNum);
            const N_smids = toUniIdAry(smids).filter(smid => !A_smids.includes(smid));
            return {smids, A_smids, N_smids};
        };
        return {
            [RemeLvSame]: splitRemeANs(sameSMIds),
            [RemeLvPre]: splitRemeANs(toUniIdAry(EQMetaPres).filter(smid => toStr(smid).trimEnd().startsWith(pfx))),
            [RemeLvPro]: splitRemeANs(toUniIdAry(EQMetaPros).filter(smid => toStr(smid).trimEnd().startsWith(pfx))),
        }
    }, [sameSMIds, EQMetaPres, EQMetaPros, remeQNum, remeQCnts]);
    return RemeLVANs;
};

export const useQCntLvRemes = (remeQNum, rmLv, msid, mset, EQSum, remeQCnts) => {
    const [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore, EQMetas, EQMetaPres, EQMetaPros, minQs, ] = toAry(EQSum);
    const mids = useMemo(() => MIdsByMSId(EQMetas, msid), [EQMetas, msid]);
    const sameMIds = useMemo(() => onlyEndMIds(mset, mids, 0), [mset, mids]);
    const sameSMIds = useMemo(() => toUniIdAry(sameMIds).map(mid => msid+','+mid), [sameMIds]);
    const remeMIds = useMemo(() => MIdsByMSId(pickRemeMetas(rmLv, sameSMIds, EQMetaPres, EQMetaPros), msid), [rmLv,  sameSMIds, EQSum, msid]);
    const A_RemeMIds = useMemo(() => {
        const rqcs = toObj(remeQCnts); 
        return toUniIdAry(remeMIds).filter(mid => toInt(rqcs[msid+','+mid]) >= remeQNum) 
    }, [remeQNum, remeMIds, remeQCnts]);
    return [mids, remeMIds, A_RemeMIds, sameMIds];
};
