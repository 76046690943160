import { toAry, toInt, toStr, isNum } from "../../libs/libType";

import { __MCAT_ATG, __MCAT_ATI } from '../../consts/ATQtnAnsTypeMCQ';
import { __FIBA_ADV, __FIBA_EQU, __FIBA_NUM, __FIBA_TXT, __FIBS_PBK, __FIBT_DRD, __FIBT_TXT } from '../../consts/ATQtnAnsTypeFIB';

const QOptType = { 
    general: __MCAT_ATG, img: __MCAT_ATI, //MC 
    blank: __FIBT_TXT, dropdown: __FIBT_DRD, //FIB 
};
export const optsToLangAns = (langEn, langCt, qopts, hcopts, isQTypes, medias, errs) => {
    const [AEs, ACs] = [{}, {}]
    const [isMC, isFIB, isLBD, isOED, isOPE, isESS, isPOL] = isQTypes; //expf2
    Object.entries(qopts).map(([k, v], idx) => {
        const hc = hcopts[k];
        const [e, c, mk, ain] = [v.ae, v.ac, v.amark, v.ain];
        const t = toStr(isLBD? 'blank': v.atype);
        const type = QOptType[ t.toLowerCase() ];
        if((type === __MCAT_ATG)){
            langEn && (AEs[k] = { type, data: toCkStr(e, '_a_e_'+k, medias, 0, errs, hc.ae.key )} );
            langCt && (ACs[k] = { type, data: toCkStr(c, '_a_c_'+k, medias, 0, errs, hc.ac.key )} );
        }else if((type === __MCAT_ATI)){
            langEn && (AEs[k] = { type, data: toAnsImg(e, '_a_e_'+k, medias, errs, hc.ae.key )} );
            langCt && (ACs[k] = { type, data: toAnsImg(c, '_a_c_'+k, medias, errs, hc.ac.key )} );
        }else if(type && (isFIB || isPOL)){
            langEn && (setIf(AEs, k, toFIBOpt(isQTypes, medias, hc, idx, k, t, type, ain, mk, e, errs, hc.ae)));
            langCt && (setIf(ACs, k, toFIBOpt(isQTypes, medias, hc, idx, k, t, type, ain, mk, c, errs, hc.ac)));
            
        }else if(isLBD){
            if(e+c+mk){
                langEn && (setIf(AEs, k, toFIBOpt(isQTypes, medias, hc, idx, k, t, type, ain, mk, e, errs, hc.ae)));
                langCt && (setIf(ACs, k, toFIBOpt(isQTypes, medias, hc, idx, k, t, type, ain, mk, c, errs, hc.ac)));
            }
        }else if(!t){
            langEn && setIf(AEs, k, loadLabel(k, e, '_a_e_'+k, errs, hc.ae.key));
            langCt && setIf(ACs, k, loadLabel(k, c, '_a_c_'+k, errs, hc.ac.key));
        }else if(t){
            errs['_a_t_'+k] = 'Invalid '+hc.atype.key+': '+t;
        }
    })
    
    
    
    return [AEs, ACs];
};


const OOptInType = { txt: __FIBA_TXT, number: __FIBA_NUM, equation: __FIBA_EQU };
const toFIBOpt = (isQTypes, medias, hc, idx, k, t, qtype, ain, mk, langVal, errs, hc_a) => {
    const [isMC, isFIB, isLBD, isOED, isOPE, isESS, isPOL] = isQTypes; //expf2
    //const atype = (isLBD || isPOL)? __FIBT_TXT: OOptInType[ain.toLowerCase()];
    const ainLow = toStr(ain).toLowerCase();
    const ctype = (isLBD || isPOL)? __FIBT_TXT: OOptInType[ainLow];
    const btype = (isPOL)? __FIBT_TXT: OOptInType[ainLow];
    const atype = (btype)?btype:ctype;

    const errlbl = '_a_i_'+k;
    if((qtype === __FIBT_TXT) || (qtype === __FIBT_DRD)){//TXT //DRD
        const o = {
            oupansid: idx + 1,
            qtype: qtype,
            atype: '',
        };
        if(qtype === __FIBT_TXT){
            if(![__FIBA_TXT, __FIBA_NUM, __FIBA_EQU, __FIBA_ADV].includes(atype))
                errs[errlbl] = 'Invalid '+hc.ain.key+': "'+ain+'"';
            o.atype = atype;
        }else if(qtype === __FIBT_DRD){
            o.atype = __FIBA_TXT;
        }
        o.score = toInt(mk)
        o.qvalue = toFIBAnss(medias, atype, langVal, errs, hc_a.key);

        (o.qvalue) && o.qvalue.forEach((ans)=>{
            if(ans.kount>1){
                
                errs[errlbl] = 'not allow multiple correct answers for Dropdown';   
            } 
        });   
        if((ain==="number") && !isNum(langVal)){
            errs[errlbl] = 'Type and content mismatch: {'+hc.atype.key+', '+hc_a.key+'}';   
        }

        if(isLBD){
            o.rect = {top: 0.1 + (0.05 * idx), left: 0.6 };
            o.cir = {top: 0.1 + (0.05 * idx), left: 0.1};
        }
        return o;
    }else if(t){
        errs['_a_t_'+k] = 'Invalid '+hc.type.key+': "'+t+'"';
    }
    errs[errlbl] = 'Invalid !!';
    return;
}

const toFIBAnss = (medias, atype, datas, errs, errlbl) => {
    const type = (atype === __FIBA_EQU)? __FIBA_EQU: __FIBA_TXT;
    const opts = toStr(datas).split('||');
    var kount = 0;
    return opts.map(opt => {
        const correct = opt.startsWith('**');
        if(correct) kount=kount+1;
        
        const rawdata = correct? opt.slice(2): opt;
        const data = toCkStr(rawdata, 'toFIBAnss_'+errlbl, medias, 0, errs, errlbl);
        
        return {type, data, correct, kount};
        
    });
      
};
const loadLabel = (k, v, key, errs, errLabel) => {
    //errs[key] = ['loadLabel', k, v, key, errLabel].join('; '); 
    if(!v) return;
    const K = k.toUpperCase();
    if (v.toUpperCase() === K) return {type:'label', data:''};
    errs[key] = 'Invalid Label '+errLabel+': '+v+' expect '+K;
};

const _toMedia = (fname, key, medias, errs, errLbl) => {
    const _fname = fname.trim().toLowerCase();
    if(!_fname){
        errs[key] = 'Invalid Media '+errLbl+': '+ (fname||'(empty)');
    }else{
        medias.push(_fname);
    } 
    return _fname;
}
export const toBGImg = (fname, key, medias, errs, errLbl) => _toMedia(fname, key, medias, errs, errLbl);

const toAnsImg = (fname, key, medias, errs, errLbl) => {
    const _fname = _toMedia(fname, key, medias, errs, errLbl);
    return {oupid:_fname, url:'' };
};

export const setIf = (o, k, v) => (v && (o[k] = v));

const fibb = (i, oupid, id, type, lbl) => 
    (type === __FIBT_TXT)? '<span class="oupanswer" oupansid="'+oupid+'" qtype="TXT" qvalue="'+
        lbl+'" qindex="'+i+'"><span style="background-color:#0C8CE9;color:white;">&nbsp;'+
        id+'&nbsp;</span>'+lbl+'</span>':
    (type === __FIBT_DRD)?  '<span class="oupanswer" oupansid="'+oupid+'" qtype="DRD" qvalue="'+
        lbl+'" qindex="'+i+'"><span style="background-color:green;color:white;">&nbsp;'+
        id+'&nbsp;</span>'+lbl+'</span>':
    "";
export const mkFibbs = os => {
    return os.map( (o, i) => {
        return fibb(i, o.oupansid, i+1, o.qtype, toAry(o.qvalue)[0]?.data);
    }); 
};
const okeys = [ '<Ans 1>','<Ans 2>','<Ans 3>','<Ans 4>','<Ans 5>','<Ans 6>','<Ans 7>','<Ans 8>','<Ans 9>','<Ans 10>',];
export const mapFIBB = (qdata, qAnswers) => {
    var qd = toStr(qdata);
    const fibbs = mkFibbs(toAry(qAnswers)).map((b,i) => [okeys[i], b]).filter(([k, b]) => (k && b)) ; 
    fibbs.forEach(([k, b]) => {
        qd = qd.replace(k, b);
    });
    
    return qd;
};

 const toCkStr = (v, key, medias, canBlank, errs, errLabel) => {
    if(!(v || canBlank)) setIf(errs, key, 'require '+errLabel);
    //return toStr(v).replace(/[>]/g,'])').replace(/[<]/g,'(['); 
    medias.push(...ckOmgsSplit(v));
    return toStr(v);
 };
export const toQData = (v, key, medias, canBlank, errs, errLabel) => toCkStr(v, key, medias, canBlank, errs, errLabel);

const splitImgName = seg => {
    const [img, ...t1] = seg.split('>');
    return [img, t1.join('>')];
};
export const ckOmgsSplit = str => {
    //const str = 'Which  <img=heart.jpg> <Ans 1> correct? <img=gg.jpg> <Ans 2>' ;        
    const [head, ...segs] = str.split('<img=');
    return segs.map(seg => (seg.split('>')[0]).trim().toLowerCase());
};

export const ckImgsSet = (str, mediaIds) => {
    const oupImg = i => {
        const o = mediaIds[i.toLowerCase()];
        if(!o) console.error('ckImgsSet', i, mediaIds); 
        return o? '<img class="image_resized" style="width:100%;" src="" oupid="'+o.upid+'" />': '(media:'+i+')';
    };
    //const str = 'Which  <img=heart.jpg> <Ans 1> correct? <img=gg.jpg> <Ans 2>' ;        
    const [head, ...segs] = str.split('<img=');
    const isegs = segs.map(splitImgName);
    const i2segs = isegs.map( ([img, seg]) => oupImg(img)+seg );
    return [head, ...i2segs].join('');
};
