export const DetectBlobString = async (blobObjectOrBlobString) => {
    
    if (!blobObjectOrBlobString)
     return 0;
    const blobType = blobObjectOrBlobString.type;
    //lj({blobType});
    if(blobType)
        return {type:blobType, data:(await BlobToBase64Raw(blobObjectOrBlobString))};
    const isStr = (Object.prototype.toString.call(blobObjectOrBlobString) === "[object String]");
    if(isStr){
        //lj({isStr});
        const parts = blobObjectOrBlobString.split(';', 2);
        const type = parts[0].split(':', 2)[1];
        const b64 = parts[1].split(',', 2);
        //lj({type, b64});
        if(type && (b64[0] === 'base64'))
            return {type, data:b64[1]}; 
    }
    return 0;
}

//Blob to ... #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### ####
export const BlobToBuffer = async (audioBlob) => {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onloadend = () => {
            resolve(fileReader.result);
        };
        fileReader.readAsArrayBuffer(audioBlob);
    });
}
export const BlobToBinString = async (blob) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function () {resolve(reader.result);}
        reader.readAsBinaryString(blob);
    });
}
export const BlobToBase64 = async (blob) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function () {resolve(reader.result);}
        reader.readAsDataURL(blob); 
    });
}
export const BlobToBase64Raw = async (blob) => {
    return window.btoa(await BlobToBinString(blob)); //Base64 String, no mime header, fast
}

//Buffer to ... #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### ####
export const dataURI2File = async (src, fileName, mimeType) => {
    const res = await fetch(src);
    const buf = await res.arrayBuffer();
    return new File([buf], fileName, {type:mimeType});
};

//Buffer to ... #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### ####
export const BufferToBase64 = (buffer) => {
    var binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
};

/*
const blob2buf = async (blob) => {
    const ret = await new Promise(res => {
        const reader = new FileReader()
        const onLoadEnd = e => {
            reader.removeEventListener('loadend', onLoadEnd, false);
            (e.error)? res(e.error): res(null, Buffer.from(reader.result))
        }
        reader.addEventListener('loadend', onLoadEnd, false)
        reader.readAsArrayBuffer(blob);
    });
    return ret;
};

const dataURI2Blob = dataURI => {
    //dataURI = 'data:image/jpeg;base64,/9j/4A.....
    const [meta, b64] = dataURI.split(',');

    // write the bytes of the string to an ArrayBuffer
    // create a view into the buffer
    const byteString = atob(b64);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // separate out the mime component
    const mime = meta.split(':')[1].split(';')[0]
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mime});
    return blob;
};
*/