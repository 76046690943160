import SortingIcon from "./SortingIcon";
import React, {ReactNode, useState} from "react";
import {SORT_ASC, SORT_DESC} from "../../../../../type/ChartType";
import Tips from "./Tips";

interface Props {
    title: string
    type: string
    tips?: ReactNode
    sorting?: boolean
}

const TableHeaderSortingButton = (props: Props) => {
    const {title, type, tips, sorting = true} = props
    const [sort, setSort] = useState({type, direction: ""})

    const handleSorting = () => {
        setSort(prev => ({...prev, direction: prev.direction !== SORT_DESC ? SORT_DESC : SORT_ASC}))
    }

    return <span role={sorting ? "button" : "none"}
                 className="d-flex justify-content-center align-items-center gap-2"
                 onClick={() => handleSorting()}>
        <span className={"user-select-none"}>{title}</span>
        {tips !== undefined && <Tips>{tips}</Tips>}
        {sorting === true && <SortingIcon type={type} sort={sort}/>}
    </span>
}

export default TableHeaderSortingButton