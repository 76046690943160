import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { asyncApiCallLoad_, asyncApiCall_ } from '../../libs/awsFuncs';
import { fileExt, humanFileSize, StrShorten } from '../../libs/libFormat';
import { objEntries, objKeys, objVals, toAry, toInt, toObj, toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';

import { ReduxBind } from '../../saga/ReduxState';

import { _ATRoot } from '../../consts/ATConstReact';

import { ATDo_QImp, _ATCanDo } from '../../consts/ATRoleRights';
import { __SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_MCS, __SYSQSubType_OED, __SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_POL, hasDrawQ } from '../../consts/ATSysQType';



const PageATQMetaEdit = ReduxBind((props) => {
  const { dispatch } = props;
  const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
  const canQImp = _ATCanDo(role, ATDo_QImp);

  return <div className="atpage atrow" >
    <div className="atpagehead f16">Questions / Batch Update Metadata</div>
    <div className="atsect f14">
    </div>
  </div>;
});

export default PageATQMetaEdit;
