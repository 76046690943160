import React, { useEffect, useState } from 'react';
import { _ATRoot } from '../../consts/ATConstReact';
import { isAry } from '../../libs/libType';
import * as UI from "../../libs/libUI";
import { expf2 } from '../../consts/ATSysQType';

const metaDataIcon = UI.imgSrc('svg/metadata.svg');
const metaDataIconSel = UI.imgSrc('svg/metadataSelected.svg');
const questionsIcon = UI.imgSrc('svg/questions.svg');
const questionsIconSel = UI.imgSrc('svg/questionsSelected.svg');
const exercisesIcon = UI.imgSrc('svg/exercises.svg');
const exercisesIconSel = UI.imgSrc('svg/exercisesSelected.svg');
const adminIcon = UI.imgSrc('svg/admin.svg');
const adminIconSel = UI.imgSrc('svg/adminSelected.svg');
const expandUpIcon = UI.imgSrc('svg/expand_up.svg');
const expandDownIcon = UI.imgSrc('svg/expand_down.svg');
//<img className="clickable" src={iconHelp} style={{width:"42px", height:"40px", zIndex:2}} onClick={toggleSide} alt=""/>
/*
_ATRoleNames {
ATURS:SysADmin  s
ATURA:Approver  a
ATURB:Basic     b
}
*/
const rights={ATURS:"s", ATURA:"a",ATURB:"b",SUPER:"x"};

// do: path pass to do function, key: path string use to search for auto expand
const metaDataSub = [
	{ name: "Metadata Sets", do: "metas", key: _ATRoot + "meta", allow:"a", child: [] },
];
const subjectSettingsSub = [
	{ name: "Follow-up Exercise Setting", do: "subjectfollowups", key: _ATRoot + "subjectfollowups", allow:"a,b", child: [] },
];
const questionsSub = [
	{ name: "Questions", do: "questions", key: _ATRoot + "question",allow:"a,b", child: [] },
    { name: "Questions Sets", do: "questionsets", key: _ATRoot + "questionset", allow:"a", child: [] },
    { name: "Batch Import", do: "questions/import", key: _ATRoot + "questions/import",allow:"a,b", child: [] },
    expf2 && { name: "Batch Update Metadata", do: "questions/metas", key: _ATRoot + "questions/metas",allow:"a,b", child: [] },
    { name: "(Dev)", do: "question/dev", key: _ATRoot + "question/dev", allow:"s", child: [] },
].filter(i => i);
const execrisesSub = [
	{ name: "Exercises", do: "exercises", key: _ATRoot + "exercise", allow:"a,b", child: [] },
    { name: "Exercise Sets", do: "exercisesets", key: _ATRoot + "exerciseset", allow:"a", child: [] },
];
const adminSub = [
	{ name: "Subject List", do: "subjects", key: _ATRoot + "subject", allow:"s",child: [] },
    { name: "Accounts", do: "accounts", key: _ATRoot + "account", allow:"s",child: [] },
];
const expfMenuData = [
	{ name: "Metadata", icon:[metaDataIcon,metaDataIconSel], do: "", key: "M1", allow:"a", child: metaDataSub },
    expf2 && { name: "Subjects", icon:[questionsIcon, questionsIconSel], do: "", key: "S1", allow:"a", child: subjectSettingsSub },
    { name: "Questions", icon:[questionsIcon,questionsIconSel], do: "", key: "Q1", allow:"a,b", child: questionsSub },
    { name: "Execrises", icon:[exercisesIcon,exercisesIconSel], do: "", key: "E1", allow:"a,b", child: execrisesSub },
    { name: "Admin", icon:[adminIcon,adminIconSel], do: "", key: "A1", allow:"s",child: adminSub },
].filter(i => i);

const hasChild = obj => (obj?.child?.length > 0); 

const defaultExpand = false;
const setViewFlag = (data, gotoPath) => {
    let viewFlag = {};
    let gotoIndex = "";
    const _setViewFlag = (arr, baseii) => {
        if (!isAry(arr)) return [];
        for (let ii = 0; ii < arr.length; ii++) {
            if (hasChild(arr[ii])) {
                _setViewFlag(arr[ii].child, baseii + ii);
            };
            
            if ( arr[ii].key !== "" && gotoPath && gotoPath.startsWith(arr[ii].key) ) { 
            	
            	gotoIndex = baseii + "" + ii;
            };
            viewFlag[baseii + "" + ii] = defaultExpand;
        };
    };
    _setViewFlag(data, "");

    const fk = Object.keys(viewFlag);
    for (let ii = 0; ii < fk.length; ii++) {
        viewFlag[fk[ii]] = gotoIndex.startsWith(fk[ii]);
        if (gotoIndex === fk[ii]) break;
    };    
    return {viewFlag: viewFlag, gotoIndex: gotoIndex};
};

const MenuExpf = (props) => {
    const {gotoPath, goFunc, myRole} = props;
    const [redraw, setRedraw] = useState(false);
    const [viewInfo, setViewInfo] = useState(setViewFlag(expfMenuData, gotoPath));

    const expandClick = (e, ii) => {
        UI.stopEvent(e);
        viewInfo.viewFlag[ii] = !viewInfo.viewFlag[ii];
        setRedraw(!redraw);
    };

    const menuItemClick = (e, doPath, index) => {
        goFunc(doPath)(e);
        viewInfo.gotoIndex = index;
    };

    useEffect(()=>{
        
        setViewInfo(setViewFlag(expfMenuData, gotoPath));
        setRedraw(!redraw);
    },[gotoPath]);

    const createRow = (obj, index, hasChild) => {
        
        //if ((rights[myRole] !== 's') && !obj.allow.includes(rights[myRole])) return;
        if (!obj.allow.includes(rights[myRole])) return;
        const selected = viewInfo.gotoIndex.startsWith(index);
        let result = [];
        const isSubItem = (!obj.icon && !hasChild);
        if (isSubItem) result.push(divVBar(index));
        result.push(
        <div className={"f14 menuRowContainer" + (isSubItem?"2":"") + ((obj.do !=="" || hasChild)?" clickable":"")} key={"m"+index} style={{color:selected?"#333333":"#949494"}} 
                onClick={obj.do!==""? e => menuItemClick(e, obj.do, index):
                    (hasChild?(e)=>expandClick(e, index):()=>{})}>
            <div className="menuRowItem1">
            {obj.icon && <img src={obj.icon[selected?1:0]} className="menuIcon" alt=""/>}
            {isSubItem && divCircle(selected)}
            {obj.name}
            </div>
            {hasChild &&
                <div className="menuRowItem1">
                    <img src={viewInfo.viewFlag[index]? expandUpIcon:expandDownIcon} className="menuIcon2" alt=""/>
                </div>
            }
            {!hasChild && <div></div>}
        </div>);
        return result;
    };
    
    const buildDisplay = (data, baseii) => {
        if (!isAry(data)) return <div>not an array</div>;
        if (data.length === 0) return <div>no element</div>;
        let result = [];
        
        
        const _buildDisplay = (arr, bii) => {
            for (let ii=0;ii<arr.length;ii++) {
                const _hasChild = hasChild(arr[ii]);
                const _myIndex = bii+ii;
                //result.push(<div key={"m"+bii+ii}>{bii===""?arr[ii].icon:"|"}{arr[ii].name}{_hasChild?"+":""}</div>);
                result.push(createRow(arr[ii], _myIndex, _hasChild));
                if (viewInfo.viewFlag[_myIndex] && _hasChild) {
                    _buildDisplay(arr[ii].child, _myIndex);
                };
            };
        };
        _buildDisplay(data, baseii);
        return result;
    };
    
    return (
        <div className="expfMenuContainer">
            {buildDisplay(expfMenuData, "")}
        </div>
    );    
};
export default MenuExpf;


const divVBar = index => <div key={"vb"+index} className="menuRowContainer2 unclickable"><div className="divVBar"></div></div>;
const divCircle = selected => <div className="divCircle" style={{backgroundColor:selected?"#0C8CE9":"#DBDBDB"}}></div>;
