import React, {} from "react";

import { deepCopy, } from "../../AppExPf/AppUtil";
import { Loader, stopEvent } from "../../libs/libUI";
import { draw2Blob, localDrawId, mediaUpFile, } from "../../AppExPf/utils/useMediaCache";
import { isAry, objEntries, objKeys, objVals, to01, toAry, toInt, toObj, toStr, toUniAry } from "../../libs/libType";
import { exerMapMedias, exerUniQIds, } from "../../consts/ATValidateExer";

import { arrayForEachAsync } from "../../libs/libArray";
import { ProgressBar } from "react-bootstrap";
import { toUniIdAry } from "../../consts/ATValidate";
import { __MCAT_ATG, __MCAT_ATI } from "../../consts/ATQtnAnsTypeMCQ";
import { mapAtt, mapTagAtt } from "../../consts/ATValidateQ";

import { CpMediaModal, CpSubmitModal } from "../EPAsmWork/CpAssignmentEndingPage";
import { qtnMapMedias } from "../../consts/qtnMedias";


export const LayerUpMedia = (props => {
    const {upMsg, mini=0, head, transBD=0} = props;    
    const _upMsg = toObj(upMsg);
    const msg = toStr(_upMsg.msg);
    const ttl = Math.max(1, toInt(_upMsg.ttl));
    const idx = toInt(_upMsg.idx);
    
    return (!upMsg)? '':<>
    {mini?<CpMediaModal show={1} progress={Math.floor((idx - 0.5) * 100 / ttl)} miniLayout={mini} transBD={transBD}/>
    :<CpSubmitModal show={1} complete={0} withBar={1} progress={Math.floor((idx - 0.5) * 100 / ttl)} />}
    </>
});
export default LayerUpMedia;
//CpMediaModal = show, complete=0, progress=100, miniLayout=1
/*
    <div className="screenMaskLoader" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={stopEvent} >
      <div className='batchImportSavingTop'>
        <div className='batchImportContent'>
          <span className='f18 semi-bold'>{toStr(head)}</span><p/>
          {Loader('50px', {display:'inline-block', verticalAlign:'middle'})}<p/>
          <h1>{toStr(msg)}</h1>
          <p/>
          <span className='f16'>{''+idx+' of Total '+ttl}</span>
          <div style={{width:'80%'}}>
            <ProgressBar animated variant="info" now={Math.floor((idx - 0.5) * 100 / ttl)}/>
          </div>
        </div>
      </div>
    </div>;
*/

export const localMediaIds = mids => toUniAry(mids).filter(i => (i.startsWith('*LM_') || i.startsWith('draw_')));
export const localMedias = (mids, getMediaDLs) => getMediaDLs(localMediaIds(mids));

export const upLocalMedias = async (dispatch, mids, getMediaDLs, setUpMsg, extraBlobs = {}) => {
  return await upEMedias(dispatch, {...localMedias(mids, getMediaDLs), ...extraBlobs}, setUpMsg);
};

export const upEMedias = async (dispatch, medias, setUpMsg) => {
    const mMap = {};
    const ms = objEntries(medias);
    if(ms.length){
      console.log('upEMedias()', objKeys(medias));
      await arrayForEachAsync(ms, async ([mk, m], idx) => {
        setUpMsg({msg:'Uploading media file...', ttl: ms.length, idx: (idx + 1)});
        const {file, name, size} = m;
        const save = file && name && size; 
        const testId = 'LocalM' + name;
        const fields = { name, size, testId };
        const upid = save && await mediaUpFile(dispatch, fields, file, '');
        if(save) mMap[mk] = upid;
      });
    }
    setUpMsg('');
    return mMap;
};

export const mapExQtnsMedias = (ex, qtns, mMap) => {
  const eQIds = toUniIdAry(exerUniQIds(ex));
  const eQs = eQIds.map(i => qtns[i]).filter(q => q); //useCacheQtns(eQIds);
  const QMs2d = eQs.map(q => qtnMapMedias(q, mMap, draw2Blob, localDrawId));
  return QMs2d;
};

