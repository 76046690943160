export const autoScrollByDD = ({container, dropdown, options}) => { // follow poc autoScrollByDropdown.tsx
    try {
        let containerElement = null;
        const dropdownElement = dropdown.current;
        const optionsElement = options.current;


        if (dropdownElement) {
            containerElement =
                typeof container === "string"
                    ? dropdownElement.closest(container)
                    : container.current;


            if (containerElement && dropdownElement && optionsElement) {
                
                const containerRect = containerElement.getBoundingClientRect();
                const dropdownRect = dropdownElement.getBoundingClientRect();
                const optionsRect = optionsElement.getBoundingClientRect();

                // const scrollPosition =
                //     dropdownRect.top -
                //     containerRect.top +
                //     containerElement.scrollTop -
                //     containerElement.offsetHeight * 0.3 +
                //     dropdownRect.height / 2;

                const buffer = 0.3; // or don't need the buffer = stick to top
                const bottomBuffer = 10; //the gap between container bottom and options bottom
                const containerScrollTop = containerElement.scrollTop;
                const containerHeight = containerRect.height;
                const containerScrollBottom = containerScrollTop + containerHeight;

                const containerBufferHeight = containerHeight * buffer;
                const containerBufferPoisition = containerScrollTop + containerBufferHeight;

                const dropdownScrollTop = dropdownRect.top - containerRect.top + containerScrollTop;
                const dropdownHeight = dropdownRect.height;

                const optionsScrollTop = optionsRect.top - containerRect.top + containerScrollTop;
                const optionsHeight = optionsRect.height;
                const optionsScrollBottom = optionsScrollTop + optionsHeight;

                let scrollTo = containerScrollTop;

                const isExpandedDropdownLargerThanContainer = containerHeight < dropdownHeight + optionsHeight;

                const isDropdownNotEnoughSpaceToShow = containerScrollBottom < optionsScrollBottom;

                // console.clear()
                // console.table([{
                //     containerScrollTop,
                //     containerBufferHeight,
                //     containerScrollBottom,
                //     dropdownScrollTop,
                //     optionsScrollTop,
                //     optionsScrollBottom
                // }])


                if (isExpandedDropdownLargerThanContainer) {

                    //if below the buffer line
                    if (dropdownScrollTop > containerBufferPoisition) {
                        // To buffer
                        
                        scrollTo = dropdownScrollTop - containerBufferHeight
                    }
                } else {

                    //is the options is outside, can't show the full list
                    if (isDropdownNotEnoughSpaceToShow) {

                        if (dropdownScrollTop > containerBufferPoisition) {
                            //To fit the options bottom
                            
                            scrollTo = Math.min((optionsScrollBottom - containerHeight), dropdownScrollTop - containerBufferHeight)
                        }
                    } else {

                    };
                };

                if (isExpandedDropdownLargerThanContainer || isDropdownNotEnoughSpaceToShow) {
                    if (dropdownScrollTop > containerBufferPoisition) {
                        scrollTo = scrollTo + bottomBuffer
                        
                        containerElement.scroll({top: scrollTo, behavior: "smooth"});
                    };
                };
            } else {
                
            };
        };
    } catch (e) {
    };
};