import {_ATRoleApprover, _ATRoleRBasic, _ATRoleSuper} from './ATConsts';

export const _ATNav_Qtn =    [_ATRoleApprover, _ATRoleRBasic,];
export const _ATNav_QSet = [_ATRoleApprover,];
export const _ATNav_Ex =     [_ATRoleApprover, _ATRoleRBasic,];
export const _ATNav_ExSet =  [_ATRoleApprover,];
export const _ATNav_Meta =   [_ATRoleApprover,];
export const _ATNav_Subj =   [_ATRoleSuper, ];
export const _ATNav_Acct =   [_ATRoleSuper, ];
export const _ATCanNav = (navSection, role) => navSection.includes(role);

export const ATDo_QImp = [_ATRoleApprover, _ATRoleRBasic, ];

export const ATDo_QClone = [_ATRoleApprover, _ATRoleRBasic, ];
export const ATDo_QDraf = [_ATRoleApprover, _ATRoleRBasic, ];
export const ATDo_QDraftR2P = [_ATRoleRBasic, ];
export const ATDo_DelQDraft = [_ATRoleApprover, _ATRoleRBasic, ];
export const ATDo_QPub = [_ATRoleApprover ];
export const ATDo_QUnpub = [_ATRoleApprover ];
export const ATDo_QDelUnPub = [_ATRoleApprover, ];

export const ATDo_EClone = [_ATRoleApprover, _ATRoleRBasic, ];
export const ATDo_EDraf = [_ATRoleApprover, _ATRoleRBasic, ];
export const ATDo_EDraftR2P = [_ATRoleRBasic, ];
export const ATDo_DelE = [_ATRoleApprover, _ATRoleRBasic, ];
export const ATDo_EPub = [_ATRoleApprover ];
export const ATDo_EUnpub = [_ATRoleApprover ];


export const ATDo_Meta = [_ATRoleApprover ];
export const ATDo_Qtn = [_ATRoleApprover, _ATRoleRBasic, ];
export const ATDo_QSet = [_ATRoleApprover ];
export const ATDo_Exer = [_ATRoleApprover, _ATRoleRBasic, ];
export const ATDo_ESet = [_ATRoleApprover ];
export const ATDo_User = [_ATRoleSuper, ];
export const ATDo_Subj = [_ATRoleSuper, ];

export const _ATCanDo = (role, act) => act.includes(role);
export const _ATCanDos = (role, acts) => acts.map(act => act.includes(role));


