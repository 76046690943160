/*
Store for Global States (e.g. cached data), [redux] Only, does not need [Saga]

actions / application:
    GS_set('init', 1); // set initialization is done after initial loading 
    GS_unset('cache_metatree_[mtid]'); // clear-cache data for (lazy) (re)loading later

state: examples:
{ init:1, cache_metatree_abc:{....}, cache_question_qid1:{...}, ... } 
*/

import { isFunc } from "../libs/libType";

// ==== ==== Types; Type of Dispatch/Put Actions ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== 
const GST_SET = 'GST_SET'; //command set Value to state
const GST_MERGE = 'GST_MERGE'; //command set Value to state
const GST_SETFUNC = 'GST_SETFUNC'; //command Update Value with Function
const GST_CLEARALL = 'GST_CLEARALL'; //command
const GST_PUSHALERT = 'GST_PUSHALERT'; //command
const GST_POPALERT = 'GST_POPALERT'; //command
const GST_POPALLALERT = 'GST_POPALLALERT'; //command
// ==== ==== action; to be dispatch() By UI / put() by Services ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const useStateGST = (props, key, init) => {
    const o = props._saga.gst[key];
    const setter = newVal => {
        props.dispatch(gst_setFunc(key, newVal, init)); 
    };
    return [o? o[0]: init, setter];
};

 
export const gst_merge = (gsts) => { return {type: GST_MERGE, gsts}; };
export const gst_set = (key, value) => { return {type: GST_SET, key, value}; };
export const gst_setFunc = (key, newVal, init) => { return {type: GST_SETFUNC, key, newVal, init}; };
export const gst_clearAll = () => { return {type: GST_CLEARALL}; };
export const gst_pushAlert = (alertJSX) => { return {type: GST_PUSHALERT, alertJSX}; };
export const gst_popAlert = (alertJSX) => { return {type: GST_POPALERT, alertJSX}; };
export const gst_popAllAlert = () => { return {type: GST_POPALLALERT}; };


// ==== ==== reducer; to update State per action ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
const initState = {}; // 'Loading' list
export const globalStateReducer = (state = initState, action) => {
    switch (action.type) {
        case GST_MERGE:{
            return { ...state, ...action.gsts };
        }
        case GST_SET:{
            //lj({GS_SET:action});
            if(state[action.key] === action.value) 
                return state;
            return { ...state, [action.key]:action.value };
        }
        case GST_SETFUNC:{
            const {key, newVal, init} = action;
            const o = state[key];
            const v1 = o? o[0] :init;
            const v = isFunc(newVal)? newVal(v1): newVal;
            return (v === v1)? state: { ...state, [key]:[v]};
        }
        case GST_CLEARALL:{
            //lj({LOADING_UNSET:action});
            return {};
        }
        case  GST_POPALERT:{ 
            const alert = action.alertJSX;
            const _alerts = state?.alerts||[];
            const hasA = (_alerts.includes(alert));
            const alerts = hasA? _alerts.filter(a => (a !== alert)): _alerts;
            return {...state, alerts};
        }
        case  GST_PUSHALERT:{ 
            const alert = action.alertJSX;
            const _alerts = state?.alerts||[];
            const alerts = (_alerts.includes(alert))?_alerts.filter(a => (a !== alert)):_alerts;
            alerts.push(alert);
            return {...state, alerts};
        }
        case  GST_POPALLALERT:{ 
            const alerts = state?.alerts||[];
            alerts.length = 0;
            return {...state, alerts};
        }
        default:
            return state;
    }
};


// ==== ==== saga; watcher saga watch for action / action saga call() service, put() action= === ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
// export function* watchAuthSaga() {
//     yield takeEvery(LOAD_SET, loadSetSaga);
//     yield takeEvery(LOAD_UNSET, loadUnsetSaga);
// };
// export function* loadSetSaga(action) {};
// export function* loadUnsetSaga(action) {};

// ==== ==== supporting services: to be call by saga ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====






