import { getMiniRangeBar } from "../../../poc/helper/getMiniRangeBar";
//import {DistributionRange, Marks, Status} from "../../../../type/ChartType";
//import TooltipsComponent from "../tooltips/TooltipsComponent";

const CpDividedBarChart = (props) => {
    const {
        segments = [],
        averageScore,
        passingScore,
        statusScore,
        status,
        indicatorOutside = false,
        dotAverage = false,
        parentLine,
        sticky = {top: false, bottom: false},
    } = props;

    const valueBarMinWidth = 8;
    const dotWidth = 12;

    const lineLeft = (percentage=0) => {
        if (percentage === 0) {
            return `0%`
        }
        return `calc(${percentage}% - 1.5px)`
    }

    const statusLeft = dotAverage ? `calc(${statusScore?.percentage}% - ${dotWidth / 2}px)` : lineLeft(statusScore?.percentage);

    return <><div className={`CpDividedBarChart field score-percentage-field position-relative ${sticky.top ? "mt-0" : ""} ${sticky.bottom ? "mb-0" : ""}`}>
            <div className="minibar-container d-flex">
                <div>{/*<span className="bg-value value-bottom semi-bold text-nowrap opacity-0">65.9%</span>*/}
                    {segments.map((segment, idx) => {
                        // cases
                        // max + min = range bar
                        // max = normal bar
                        // no max + no min = dot bar
                        if (segment.maxPercentage === undefined && segment.minPercentage === undefined) return;
                        const {width, left} = getMiniRangeBar({
                            maxPercentage: segment.maxPercentage || 0,
                            minPercentage: segment.minPercentage || 0,
                            minWidth: segments.length === 1 ? (segment.maxPercentage === segment.minPercentage ? 1.5 : 0.1) : 0.1
                        });
                        return <div key={idx} className={`correctness-average-bar ${segment.status}-bar ${parseInt(width) < valueBarMinWidth ? "overflow-unset" : ""}`}
                                style={{
                                    left: `${segments.length === 1 ? (Math.min(parseFloat(left), 100 - 1.5)) : parseFloat(left)}%`,
                                    width: `${segments.length === 1 ? (Math.max(parseFloat(width), 1.5)) : parseFloat(width)}%`
                                }}>
                            {((segment.value !== undefined && segment.maxPercentage >= 5) && (segment.value !== undefined && parseInt(width) >= valueBarMinWidth)) &&
                                <span className="bg-value value-top semi-bold text-nowrap justify-content-center divided-value">{segment.value}</span>
                            }
                            {/*If there is too small width, show the hover*/}
                            {segment.value !== undefined && indicatorOutside === true && parseInt(width) < valueBarMinWidth &&
                                <span className={`position-absolute w-100 h-100 d-flex align-items-center text-status-${segment.status} ${segment.status === "f" ? "justify-content-end text-align-end" : ""}`}
                                    style={{[segment.status === "f" ? "left" : "right"]: `${segment.status === "f" ? "calc(-100% + -5px)" : "calc(-100% - 5px)"}`}}>{segment.value}</span>
                            }
                        </div>
                    })}
                </div>
            </div>
            {averageScore !== undefined &&
                <div className={`average-line`}
                    style={{left: lineLeft(averageScore.percentage)}}></div>}
            {passingScore !== undefined &&
                <div className={`passing-line line-status-${status}`}
                    style={{left: lineLeft(passingScore.percentage)}}></div>}
            {statusScore !== undefined &&
                <div className={`status-line ${dotAverage ? "dot" : "line"}-status-${status} ${dotAverage ? `dot-line` : ""}`}
                    style={{ left: `${statusLeft}` }}></div>}
            {parentLine !== undefined &&
                <div className={`status-line dash-line line-status-n`}
                    style={{left: lineLeft(parentLine.percentage)}}></div>}
        </div>
    </>
};

export default CpDividedBarChart;

const DividedBarChart_org2 = (props) => { // base on poc DividedBarChart
    const {
        segments = [],
        averageScore,
        passingScore,
        statusScore,
        status,
        indicatorOutside = false,
        dotAverage = false,
        parentLine,
        sticky = {top: false, bottom: false}
    } = props;

    const minWidth = 8;
    const dotWidth = 12;

    const statusLeft = dotAverage ? `calc(${statusScore?.percentage}% - ${dotWidth / 2}px)` : `calc(${statusScore?.percentage}% - 1.5px)`;
    return <><div className={`field score-percentage-field position-relative ${sticky.top ? "mt-0" : ""} ${sticky.bottom ? "mb-0" : ""}`}>
            <div className="minibar-container d-flex">
                <div>{/*<span className="bg-value value-bottom semi-bold text-nowrap opacity-0">65.9%</span>*/}
                    {segments.map((segment, idx) => {
                        const {width, left} = getMiniRangeBar({
                            maxPercentage: segment.maxPercentage || 0,
                            minPercentage: segment.minPercentage || 0,
                            minWidth: 0.1
                        })
                        return <div key={idx} className={`correctness-average-bar ${segment.status}-bar ${parseInt(width) < minWidth ? "overflow-unset" : ""}`}
                            style={{ left: `${left}`, width: `${width}` }}>
                            {((segment.value !== undefined && segment.maxPercentage >= 5) && (segment.value !== undefined && parseInt(width) >= minWidth)) &&
                                <span className="bg-value value-top semi-bold text-nowrap justify-content-center divided-value">{segment.value}</span>
                            }
                            {/*If there is too small width, show the hover*/}
                            {segment.value !== undefined && indicatorOutside === true && parseInt(width) < minWidth &&
                                <span className={`position-absolute w-100 h-100 d-flex align-items-center text-status-${segment.status} ${segment.status === "f" ? "justify-content-end text-align-end" : ""}`}
                                    style={{[segment.status === "f" ? "left" : "right"]: `${segment.status === "f" ? "calc(-100% + -5px)" : "calc(-100% - 5px)"}`}}>{segment.value}</span>
                            }
                        </div>
                    })}
                </div>
            </div>
            {averageScore !== undefined &&
                <div className={`average-line`}
                    style={{left: `calc(${averageScore.percentage}% - 1.5px)`}}></div>}
            {passingScore !== undefined &&
                <div className={`passing-line line-status-${status}`}
                    style={{left: `calc(${passingScore?.percentage}% - 1.5px)`}}></div>}
            {statusScore !== undefined &&
                <div className={`status-line ${dotAverage ? "dot" : "line"}-status-${status} ${dotAverage ? `dot-line` : ""}`}
                    style={{ left: `${statusLeft}` }}></div>}
            {parentLine !== undefined &&
                <div className={`status-line dash-line line-status-n`}
                    style={{left: `calc(${parentLine?.percentage}% - 1.5px)`}}></div>}
        </div>
    </>
};

const ChartDividedBar_org = (props) => { // base on poc DividedBarChart
    const {segments = [], averageScore, passingScore, statusScore, status} = props
    const minWidth = 8;
    return <><div className="field score-percentage-field position-relative">
        <div className="minibar-container d-flex">
            <div>{/*<span className="bg-value value-bottom semi-bold text-nowrap opacity-0">65.9%</span>*/}
                {segments.map((segment, idx) => {
                    const {width, left} = getMiniRangeBar({
                        maxPercentage: segment.maxPercentage || 0,
                        minPercentage: segment.minPercentage || 0,
                        minWidth: 1,
                    })
                    const hasVal = segment.value !== undefined;
                    const isF = segment.status==="f";
                    const wild = parseInt(width) >= minWidth;
                    return <div key={idx} className={`correctness-average-bar ${segment.status}-bar ${wild? '': 'overflow-unset'}`}
                                style={{ left: `${left}`, width: `${width}` }} >
                        {(hasVal && (segment.maxPercentage >= 5) && (wild)) &&
                            <span className="bg-value value-top semi-bold text-nowrap justify-content-center divided-value">{segment.value}</span>
                        }
                        {                            /*If there is too small width, show the hover*/
                        hasVal && (!wild) &&
                            <span className={`position-absolute w-100 h-100 d-flex align-items-center text-status-${segment.status} ${isF? "justify-content-end text-align-end": ""}`}
                                style={{[isF? "left": "right"]: `${isF? "calc(-100% + -5px)": "calc(-100% - 5px)"}`}}>{segment.value}</span>
                        }
                    </div>
                })}
            </div>
        </div>
        {averageScore !== undefined &&
            <div className="average-line" style={{left: `${averageScore.percentage}%`}}></div>}
        {passingScore !== undefined &&
            <div className={`passing-line line-status-${status}`} style={{left: `${passingScore?.percentage}%`}}></div>}
        {statusScore !== undefined &&
            <div className={`status-line line-status-${status}`} style={{left: `${statusScore?.percentage}%`}}></div>}
    </div></>;

};

