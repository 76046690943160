import { useEffect, useState} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpIco from "../_components/CpIco";
import { ReduxBind } from "../../saga/ReduxState";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { _pathAssign } from "../../consts/ATConstReact";

const PageAssignmentEmptyContent = ReduxBind(props => {    //base on poc AssignmentEmptyContent
    const {dispatch} = props;
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const [seconds, setSeconds] = useState(5);
    
    const handleBack = () => { dispatch(urlPush_Replace(_pathAssign(''))); };

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 1) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(myInterval);
                handleBack();
            }
        }, 1000);
        return () => { clearInterval(myInterval); };
    }, [seconds]);
//no-access-right-exercise
    const secTxt = t("back-in").replace('%s', seconds.toString()).replace('seconds', seconds === 1 ? "second" : "seconds"); 
    return <div className={"d-flex flex-column flex-wrap justify-content-center align-items-center p-5 border dash-container gap-3 text-dim-400 h-100 flex-grow-1 fade-in-container m-3"}>
        <div className={`fs-2`}>{t("no-access-right-assignment")}</div>
        <div role={"button"} className={`d-flex align-items-center gap-2 fs-5 border rounded-light px-3 py-1`} onClick={() => handleBack()}>
            <CpIco src={IconList.general.previous}/>{secTxt}
        </div>
    </div>
});
export default PageAssignmentEmptyContent;