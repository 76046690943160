import React, { useRef, useEffect, useState } from 'react';
import * as UI from '../../../libs/libUI';
import {iconButton } from '../CpATIcoBtn';
import {secondToHHMMSS} from '../../../libs/libTime';
import { useUILang } from '../../utils/useUILang';
/*
    play(): void
    pause(): void
    stop(): void

    seekTo(sec?: number): void
    speedRate(rate?: number): void
    setVolume(sec?: number): void
    setMuted(): void

    getTarget(): any | null

    isPlay(): boolean
    isMuted(): boolean

    getCurrentTime(): number
    getDuration(): number

    seek
    <input type={"range"} onChange={seekTo} value={controlValues.time} min={0}
            max={Math.trunc(controlValues.duration)} step={0.01}
            style={{backgroundSize: `${controlValues.time / controlValues.duration * 100}% 100%`}}/>

    speed
    <input type={"range"} className={"m-0"}
    onChange={speedRate}
    value={controlValues.speedRate} {...RANGE_SPEEDRATE}
    style={{backgroundSize: `${(controlValues.speedRate - RANGE_SPEEDRATE.min) / (RANGE_SPEEDRATE.max - RANGE_SPEEDRATE.min) * 100}% 100%`}}/>

    volume
    <input type={"range"} className={"mb-2"} onChange={setVolume}
            value={isMute ? 0 : controlValues.volume} {...RANGE_VOLUME}
            style={{backgroundSize: `${controlValues.volume / RANGE_VOLUME.max * 100}% 100%`}}/>

    export const RANGE_SPEEDRATE = {min: 70, max: 130}
    export const RANGE_VOLUME = {min: 0, max: 100}
    export const defaultControlValues: IControlValues = {
        time: 0,
        volume: 100,
        speedRate: 100,
        duration: 0
    }    

    const seekTo = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value)
        media.seekTo(value)
        setControlValues(prev => ({...prev, time: value}))
    }

    const setSpeedRate = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value)
        media.speedRate(value / 100)
        setControlValues(prev => ({...prev, speedRate: value}))

    }

    const setVolume = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value)
        media.setVolume(value / 100)
        setControlValues(prev => ({...prev, volume: value}))
    }

*/

const CpMediaUI = (props) => {
    const {id, play, pause, stop, playing, isMuted,
        seekTo, setSpeedRate, setVolume, setMuted, mini=0,
        currentTime, speedRate, volume, duration, mode} = props;
    const [ t ] = useUILang();        
    const [vis, setVis] = useState(false);
    const srRange = useRef([]);
    const voRange = useRef([]);
    const srBubble = useRef([]);
    const voBubble = useRef([]);

    const showAdv = (e) => {       
        setVis(!vis);
    };

    const _play = (e) => {
        //if (mode==='button' && playing) return;
        play(e);
    };
   
    /*
    const setBubble = (range, bubble, rangeValue) => {
        if (range && bubble) {
            
            const val = rangeValue;
            const min = Number(range.min ? range.min : 0);
            const max = Number(range.max ? range.max : 100);
            const newVal = Number(((val - min) * 100) / (max - min));
            const delta = (rangeValue >= ((max+min)/2)) ? -33:28;
            bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15 + delta}px))`;        
        };
    };
    */
    const setBubble = (range, bubble, rangeValue) => {
        /*
        if (value === 0) return 0
        return value / 2 + (45 / 2 * value * 0.6 / 100)
        */
        if (range && bubble) {
            
            const val = rangeValue;
            const min = Number(range.min ? range.min : 0);
            const max = Number(range.max ? range.max : 100);
            const newVal = Number(((val - min) * 100) / (max - min));
            const factor = (rangeValue >= ((max+min)/2))?-1:1;
            const delta = newVal-50;
            const adj = delta*40*factor/100; // 40 bubble length
            bubble.style.left = `calc(${newVal}% + (${factor===1?Math.abs(adj):adj}px))`; 
            //bubble.style.left = `calc(${tmp}% + (${8 - tmp * 0.15}px))`; 
            //bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`; 
        };
    };   

    useEffect(()=>{
        setBubble(srRange.current[0], srBubble.current[0], speedRate);
        setBubble(voRange.current[0], voBubble.current[0], volume);
        setBubble(srRange.current[1], srBubble.current[1], speedRate);
        setBubble(voRange.current[1], voBubble.current[1], volume);
    },[]);

    useEffect(()=>{
        setBubble(srRange.current[0], srBubble.current[0], speedRate);
        setBubble(srRange.current[1], srBubble.current[1], speedRate);
    },[speedRate]);

    useEffect(()=>{
        setBubble(voRange.current[0], voBubble.current[0], volume);
        setBubble(voRange.current[1], voBubble.current[1], volume);
    },[volume]);
    //const [isPlaying, setIsPlaying] = useState(false);
    //const [isMuted, setIsMuted] = useState(false);
    
    //{flag?'':iconButton('',vis?'player/arrowup':'player/arrowdown','white','',showAdv,true,'cpMediaUIHide','',{transform:"scale(1.5)"})}
    const player = (flag) => {
        return <>
            {mode==='button'?<div className={'cpMediaUIRowInline' + (mini?' mini':'')} style={{ maxWidth:'150px'}}>
                <div className='flexColCenterFit' style={{borderRadius:'50%', backgroundColor:'white',width:'40px',height:'40px'}}>
                {iconButton('',playing?'player/pause':'player/start','#fdb832','',_play,true,{transform:"scale(1.8)"})}
                </div>
            </div>:''}
            {mode!=='button'?<><div className={'cpMediaUIRow ' + (vis?' vis':'') + (mini?' mini':'')}>
                <div className='flexColCenterFit' style={{borderRadius:'50%', backgroundColor:'white',
                    width:'40px',height:'40px',margin:'0 20px 0 10px'}}>
                {iconButton('',playing?'player/pause':'player/start','#fdb832','',play,true,{transform:"scale(1.8)"})}
                </div>
                <div className='flexColCenter' style={{paddingTop:'10px'}}>
                    <input type={"range"} onChange={seekTo} value={currentTime} min={0}
                    max={(Math.floor(duration * 100) / 100).toFixed(2)} step={0.01}
                    style={{backgroundSize: (currentTime / duration * 100)+'% 100%'}}/>
                    <div className='flexRowBetween f14' style={{marginTop:'5px'}}>
                        <div>{mmss(currentTime)}</div><div>{mmss(duration)}</div>
                    </div>
                </div>
                {flag?'':iconButton('',vis?'player/arrowup':'player/arrowdown','white','',showAdv,true,{transform:"scale(1.5)"})}
            </div>
            <div className={'cpMediaUIRow2 ' + (mini?' mini':'')} style={{display:(vis || flag)?'flex':'none'}}>
                <div className={'cpMediaUIRow3' + (mini?' mini':'')}>
                    <div style={{whiteSpace:'nowrap',marginRight:'10px'}}>{t('exercise.content.audio.speed')+':'}</div>
                    <div className='flexColCenter f14' style={{position:'relative', paddingTop:'10px'}}>
                        <input ref={(el)=>srRange.current[flag]=el} type={"range"} onChange={setSpeedRate} value={speedRate} min={70} max={130}
                        style={{backgroundSize: ((speedRate - 70) / 60 * 100) + '% 100%'}}/>
                        <div className='mediaBubble unclickable' ref={(el)=>srBubble.current[flag]=el}>{Math.trunc(speedRate)+'%'}</div>
                        <div className='flexRowBetween unclickable' style={{paddingTop:'5px'}}>
                            <div>{'70%'}</div><div>{'130%'}</div>
                        </div>                    
                    </div>
                </div>
                <div className={'f14 cpMediaUIRow3 ' + (mini?' mini':'')} style={{position:'relative'}}>
                    {iconButton('',isMuted?'player/volumemute':'player/volume','white','',setMuted,true,{transform:"scale(1.8)"})}
                    <input ref={(el)=>voRange.current[flag]=el} type={"range"} onChange={setVolume} value={volume} min={0} max={100}
                    style={{backgroundSize: ((volume / 100) * 100)+'% 100%'}}/>
                    {false && <div className='mediaBubble' ref={(el)=>voBubble.current[flag]=el}>{Math.trunc(volume)+'%'}</div>}
                </div>
            </div></>:''}
        </>;
    }

    return <>
        <div className={'cpMediaUIContainer cpMediaUI1' + (mini?' mini':'')} style={{flexDirection:'column'}}>{player(0)}</div>
        <div className={'cpMediaUIContainer cpMediaUI2' + (mini?' mini':'')} style={{flexDirection:'row'}}>{player(1)}</div>
    </>;
};
//<input ref={voRange} type={"range"} onChange={setVolume} value={isMuted ? 0 : volume} min={0} max={100}
//Math.trunc(duration) 
export default CpMediaUI;

const mmss = (s) => {return secondToHHMMSS(Math.round(s)).substring(3, 8)};

//{mode!=='button'?<><div className='cpMediaUIRow' style={{borderRadius:vis?"5px 5px 0px 0px":"5px"}}>
//<div className='cpMediaUIRow' style={{display:vis?"flex":"none", borderRadius:vis?"0px 0px 5px 5px":""}}>