import axios from 'axios';
import FormData from 'form-data';
//https://medium.com/@fakiolinho/handle-blobs-requests-with-axios-the-right-way-bb905bdb1c04

export const HttpGet = async (url, useCookie = true, formFields = {}, fileOrBlobs = {}, progressCB = false, extraHeaders = {}) => { //simple Http Get Request, JSON response
  return _Http(url, useCookie, 'get', 'application/x-www-form-urlencoded', objToFormData({...formFields, ...fileOrBlobs}), progressCB, extraHeaders);
};

export const HttpPost = async (url, useCookie = true, formFields = {}, fileOrBlobs = {}, progressCB = false, extraHeaders = {}) => { //simple Http Post Request, JSON response
  return _Http(url, useCookie, 'post', 'application/x-www-form-urlencoded', formFields, progressCB, extraHeaders);
};

export const HttpPostMP = async (url, useCookie = true, formFields = {}, fileOrBlobs = {}, progressCB = false, extraHeaders = {}) => { //simple Http Post MultiPart (file/Blob) Request, JSON response
  const contentType='multipart/form-data'; //'multipart/form-data; boundary=customizedBoundary'
  return _Http(url,  useCookie, 'post', contentType, objToFormData({...formFields, ...fileOrBlobs}), progressCB, extraHeaders);
};

//formData.append('my_buffer', new Buffer(10));
//formData.append('LocalFSFile', fs.createReadStream('/foo/bar.jpg'));
//formData.append("HTMLFormFile", fileInputElement.files[0]);// HTML file input, chosen by user
//var blob = new Blob(['binary string'], { type: "text/xml"});
//formData.append("blobAsFile", blob);
export const  objToFormData = (obj, formData = new FormData()) => Object.keys(obj).reduce( (fd, key) => { fd.set(key, obj[key]); return fd; }, formData);

// Private Supporting Functions ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
const _Http = async (url, useCookie, method, contentType, formFields, progressCB = false, extraHeaders = {}) => {
  var settings = {
    method: method,
    headers: {
        'Content-Type': contentType,
        'Accept': 'application/json, text/plain, */*',
        ...extraHeaders,
    },
    url: url,
    data: formFields,
  };
  if(progressCB){
    settings.onUploadProgress = (progressEvent) => {
      const fullLength = progressEvent.lengthComputable 
      ? progressEvent.total 
      : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
      //lj("onUploadProgress", progressEvent.loaded, '/', fullLength);
      progressCB(progressEvent.loaded, fullLength)
    }
  } 
if(useCookie)
    settings.withCredentials = true; //use cookies, the server must set CORS with exact Origin, conflict with Origin '*'
  return axios(settings);
};

