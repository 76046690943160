import React from 'react';
import { useUILang } from '../../../AppExPf/utils/useUILang';
import { toAry, toFaction1, toFactionF, toObj, toPercent1 } from '../../../libs/libType';
/*
export const SCHOOL_YEARS = {
    1: '2021-22',
    2: '2022-23',
    3: '2023-24',
} as const;

export const getColorForYear = (year: SchoolYearKey): string => {
    return YEAR_COLORS[year] || '#000000';
};
export const YEAR_COLORS = {
    1: CHART_COLORS[2], // '#ffb55a' (e.g. 2021-22)
    2: CHART_COLORS[1], // '#fd7f6f' (e.g. 2022-23)
    3: CHART_COLORS[0], // '#64a3a3' (e.g. 2023-24)
} as const;
*/

const CpKeyPopBox = (props) => { // poc AverageScoreProgressPopupBox
    const [t] = useUILang();
    const {stuNo, sutName, metaName, KSYears, ksDataAvg, ksData, ksBGC=()=>{}} = props;
/*
    {toAry(KSYears).map((y, i) => {
        const {cnt, markp} = toObj(ksData[y]);
        return cnt && _numBox('nb'+i, markp, cnt, i);
    }).filter(jsx => jsx)}

    key(number): 1  year: '2021-22'
    classAverages = [ { year: 'key'} ]
    scores = [ { year: 'key', scorePercentage: number }]
*/
    const renderTitle = metaName? <span className='title-index'><span className='index'>{metaName}</span></span>: '';
    return <div className="progress-chart-popup semi-bold">
        <div className='title-container'><h3 className='title'>{renderTitle}</h3></div>
        <h4>{sutName? stuNo+" "+sutName: t('report-overall')}</h4>
        <hr/>
        <p>{t('report-performance-average-percentage')}
            {sutName?<p className="class-average-label"> ({t('report-performance-class-average-percentage')}) </p>: ''}
        </p>            
        <ul>{toAry(KSYears).map((y, i) => {
            const {cnt, markp} = toObj(ksData[y]);
            if (!cnt) return '';
            const avg = toObj(ksDataAvg[y]);
            return <li key={i}>
                <span className="color-box" style={ksBGC(i)}></span>
                <span className="year-label">{y}</span>
                <span className="score">{toFaction1(markp, cnt)}%</span>
                {(avg.cnt && sutName)?<span className="class-average">({toFaction1(avg.markp, avg.cnt)}%)</span>:""}                    
            </li>
        })}</ul>
    </div>;
};

export default CpKeyPopBox;


/*
            <ul>{Object.entries(SCHOOL_YEARS).map(([key, year]) => {
                    const score = scores.find(s => s.year === Number(key));
                    const classAverage = classAverages?.find(ca => ca.year === Number(key));
                    if (!score) return null;
                    return ( <li key={key}>
                        <span className="color-box" style={{ backgroundColor: getColorForYear(Number(key)) }}></span>
                        <span className="year-label">{year}</span>
                        <span className="score">{score.scorePercentage.toFixed(1)}%</span>
                        {classAverage && (<span className="class-average">
                            ({classAverage.scorePercentage.toFixed(1)}%)
                        </span>)}
                    </li> );
            })}</ul>
*/