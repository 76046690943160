import React from 'react';

import { QP_D, QP_DR, QP_P, QP_PP, QP_PU } from "../../consts/ATConsts";
import { toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';
import { aTUITime, QDPCol, QDPTxt } from '../AppUtil';
import { svgIcon2 } from '../components/CpATIcoBtn';

const CpDraftPubBtns = ({saved, draft, showPub, pub, click, verKey}) => {
    return <>
    {draft ? <div className='draftBtn'>
      { QDPBtn(draft, verKey, click(QP_D), !showPub) }
      {saved ? draft.modLogin : '--'}<br />
      {saved ? aTUITime(draft.dateMod) : 'unsaved'}
    </div> : <div className="draftBtn"></div>}
    <div style={{height:'32px', width:'1px', backgroundColor:'#000', margin:'2px'}}></div>
    {pub ? <div className="pubBtn">
      { QDPBtn(pub, verKey, click(QP_P), showPub) }
      {pub.modLogin}<br />
      {aTUITime(pub.dateMod)}
    </div> : <div className="pubBtn"></div>}
  </>
};
export default CpDraftPubBtns;  
  
export const QDPBtn = (Q, verKey, onClick, hi) => {
    const [pub, r2p, unp] = [Q.dp === QP_P, Q.drpu === QP_DR, Q.drpu === QP_PU];
    return <div className='flexRowCenter'>{hi? svgIcon2('player/start','#2995cd') :''}
        {UI.Button0( QDPTxt(pub, r2p, unp) +' v'+toStr(Q?.[verKey]), onClick, 
        'btn'+Q.QId+Q.pd+Q.prdu, 'btnqdp', {backgroundColor:QDPCol(pub, r2p, unp)})}</div>; 
};

export const PubDraftStates = (view, draft, pub, verKey) => {
  const hasDraft = draft? 1 : 0;
  const saved = (draft?.dateMod)? 1 : 0;
  const r2p = (draft?.drpu === QP_DR)? 1: 0;
  const unpubed = (pub && (pub.drpu !== QP_PP))? 1 : 0;
  const pubed = (pub && (pub.drpu !== QP_PU))? 1 : 0;
  const showPub = pub && (view === QP_P);
  const lock = showPub || (!hasDraft);
  const lockDraft = (lock || pub)? 1: 0; 
  return { hasDraft, saved, r2p, pubed, unpubed, showPub, lock, lockDraft };
};

