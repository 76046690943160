import React, {useCallback, useMemo, useRef, useState} from "react";

import CpIco from "./CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { _ExCtType_CK, _ExCtType_Img, _ExCtType_Lnk, _ExCtType_Mp3, _ExCtType_Qtn, _ExCtType_Txt, _ExCtType_Video } from '../../consts/ATValidateEcnts';
import CpCreateQuestionContainer from "../EPQuestion/CpCreateQuestionContainer";
import CpSearchQuestionContainer from "../EPQuestion/CpSearchQuestionContainer";
import { toStr } from "../../libs/libType";
import { stopEvent } from "../../libs/libUI";
/*
    edit bar 
    add qtn
        AT       - add by input qtn no, clickAdd
        frontend - add by create a new qtn, createQtn (CpCreateQuestionContainer)
    search qtn
        frontend only - onAddQ
*/
const CpExEditToolBar = (props) => { // base on poc ExerciseEditToolBar.tsx
    const {clickAdd, createQtn, addedQIds, onAddQ, bgColor, btnClass, barClass, isAT, showEn} = props;

    const [visSearchQ, setVisSearchQ] = useState(0);
    const [visCreateQu, setVisCreateQu] = useState(0);
    const [t, isUIEn, UILang, setUILang] = useUILang();

    const exitFindQ = () => setVisSearchQ(0);
    const clickBrowseQ = e => {stopEvent(e); setVisSearchQ(1); }
    const clickNewQ = isAT? clickAdd(_ExCtType_Qtn): e => {stopEvent(e); setVisCreateQu(1); };

    const bClass = "exercise-edit-toolbar-item d-flex justify-content-center align-items-center " + toStr(btnClass);
    const renderContentType = useMemo(() => {
        const contentTypes = [
            {title: t("tooltips-list.floatMenu.add-new-question"), iconPath: IconList.general.add, handle: clickNewQ},
            ...(onAddQ? [{title: t("tooltips-list.floatMenu.search-question"), iconPath: IconList.general.search, handle: clickBrowseQ}]: []),
            {title: t("tooltips-list.floatMenu.add-section"), iconPath: IconList.contentType.rubric, handle: clickAdd(_ExCtType_Txt)},
            {title: t("tooltips-list.floatMenu.add-image"), iconPath: IconList.contentType.image, handle: clickAdd(_ExCtType_Img)},
            {title: t("tooltips-list.floatMenu.add-text"), iconPath: IconList.contentType.text, handle: clickAdd(_ExCtType_CK)},
            {title: t("tooltips-list.floatMenu.add-video"), iconPath: IconList.contentType.video, handle: clickAdd(_ExCtType_Video)},
            {title: t("tooltips-list.floatMenu.add-audio"), iconPath: IconList.contentType.audio, handle: clickAdd(_ExCtType_Mp3)},
            {title: t("tooltips-list.floatMenu.add-hyperlink"), iconPath: IconList.contentType.link, handle: clickAdd(_ExCtType_Lnk)}
        ];
        return <>{contentTypes.map(content => <CpTooltipsComponent key={content.title} title={content.title} size={"no-wrap"} className={"p-x-3"}>
                <span role="button" className={bClass} onClick={() => content.handle()}>
                    <CpIco src={content.iconPath}/>
                </span>
            </CpTooltipsComponent>)}</>
    }, [UILang]);

    const exStyle = bgColor? {backgroundColor:bgColor}: {};
    return <>
        <div className={"d-inline-flex flex-nowrap gap-2 gap-md-3 p-2 justify-content-center align-items-start exercise-edit-toolbar-container rounded-pill shadow-card " 
            + toStr(barClass)} style={exStyle}>
            {renderContentType}
        </div>
        {isAT? '': <CpCreateQuestionContainer {...{...props, show:visCreateQu, onClose:() => setVisCreateQu(0), createQtn}} />}
        {onAddQ? <CpSearchQuestionContainer {...{...props, show:visSearchQ, exitFindQ, addedQIds, onAddQ}} />:''}
    </>
};

export default CpExEditToolBar;

const CpTooltipsComponent = (props) => {
    const {size = "sm", position = "top", className = ""} = props
    const {children, title} = props
    return <span className={`t-${position} t-${size} bordered ${className}`} data-tooltip={title}>
        {children}
    </span>
};

/*
    const renderContentType = useMemo(() => {
        const contentTypes = [
            {title: t("question"), iconPath: IconList.general.add, handle: clickNewQ},
            ...(_onAddQ? [{title: t("search"), iconPath: IconList.general.search, handle: clickBrowseQ}]: []),
            {title: t("rubric"), iconPath: IconList.contentType.rubric, handle: clickAdd(_ExCtType_Txt)},
            {title: t("image"), iconPath: IconList.contentType.image, handle: clickAdd(_ExCtType_Img)},
            {title: t("text"), iconPath: IconList.contentType.text, handle: clickAdd(_ExCtType_CK)},
            {title: t("video"), iconPath: IconList.contentType.video, handle: clickAdd(_ExCtType_Video)},
            {title: t("audio"), iconPath: IconList.contentType.audio, handle: clickAdd(_ExCtType_Mp3)},
            {title: t("link"), iconPath: IconList.contentType.link, handle: clickAdd(_ExCtType_Lnk)}
        ];
        return <>{contentTypes.map(content => 
            <span role="button" key={content.title} className={bClass} onClick={content.handle}>
                <CpIco src={content.iconPath}/>
            </span>)}</>
    }, []);
*/

/*
        <CreateQuestionCTA className={"exercise-edit-toolbar-item"} onClick={() => {
            handleOpen(CREATE_QUESTION)}}>
            <CpIco src={IconList.general.add}/>
        </CreateQuestionCTA>

        <SearchContentCTA className={"exercise-edit-toolbar-item"} onClick={() => {
            handleOpen('SEARCH_QUESTION') }}>
            <CpIco src={IconList.general.search}/>
        </SearchContentCTA>
*/