import React, { useRef, useState, useEffect, useMemo } from 'react';
//import { fabric } from 'fabric-with-erasing';
import { _WebRoot } from '../../consts/ATConstReact';
//import { useMSetCache } from '../ATQtnEdit/PageATQtns';
const defaultAR = 3 / 4;
const defaultW = 1024;
const defaultH = 768;

const initFabricFunc = () => {
  if (window.fabric) {
      //console.log('initFabricFunc CpLDCanvas');
      const fabric = window.fabric;
      var defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
      fabric.util.object.extend(fabric.Canvas.prototype, {
        _onTouchStart: function (e) {
            var target = this.findTarget(e);
            // if allowTouchScrolling is enabled, no object was at the
            // the touch position and we're not in drawing mode, then
            // let the event skip the fabricjs canvas and do default
            // behavior
            if (this.allowTouchScrolling && !target && !this.isDrawingMode) {
                // returning here should allow the event to propagate and be handled
                // normally by the browser
                return;
            }
            // otherwise call the default behavior
            defaultOnTouchStartHandler.call(this, e);
        }
    });
  };
};

const DrawingCanvas = (props) => {
  const { id, hasQtnBG=0, isTest = 0, isOverLay=0, BGImgSrc=null } = props;
  const myID = (isOverLay?'Over':'') + (id ? id : 'myID');
  //const [myCont, setmyCont] = useState(null);
  const myCont = useRef(null);
  const myCanvas = useRef(null);
  const aspectRatio = useRef(defaultAR);
  const containerID = 'canvasBaseCont' + myID;
  const isScriptReady = useFabric();
  const doInit = useRef(0);
  //console.log('hasQtnBG', hasQtnBG);
  const resizeCanvas = () => {
    //console.log('do resize', myID);
    if (myCont.current) {
      const size = {width:myCont.current.clientWidth, height:myCont.current.clientHeight};
      //const width = myCont.current.clientWidth;
      //const pheight = myCont.current.clientHeight;
      const height = size.width * aspectRatio.current;
      myCont.current.style.height = isOverLay?'100%':(height + 'px');
      if (isOverLay) {
        const overlayParent = myCont.current?.parentElement?.parentElement?.parentElement;
        if (overlayParent) {
          //console.log('overlayParent', overlayParent.id, size);
          size.height = overlayParent.clientHeight;
          size.width = overlayParent.clientWidth;
        };
      };      
      //console.log('resizeCanvas:', width, height, width/height);
      if (myCanvas.current) {
        if (isOverLay) {
          myCanvas.current.setDimensions({ width: size.width, height: size.height }, { cssOnly: false });
        } else {         
          myCanvas.current.setDimensions({ width: "100%", height: "100%" }, {
            //myCanvas.current.setDimensions({ width: width, height: height }, {
            cssOnly: true
          });
          myCanvas.current._initRetinaScaling();
          myCanvas.current.calcOffset();          
        };
        myCanvas.current.requestRenderAll();
      }; //else console.error('myCanvas is null');
    }; //else console.error('myCont.current is null: ', containerID);
  };


  const createCanvas = () => {
    if (!myCanvas.current && window.fabric && myCont.current) {
      const { setCanvas, updateResp, mouseUp } = props;     
      //const cp = document.getElementById(containerID);
      const cp = myCont.current;
      const size = {width:defaultW, height:defaultH};
      if (hasQtnBG) aspectRatio.current = cp.clientHeight/cp.clientWidth;
      size.height = hasQtnBG? (size.width * aspectRatio.current):defaultH;
      if (isOverLay) {
        const overlayParent = myCont.current?.parentElement?.parentElement?.parentElement;
        if (overlayParent) {
          //console.log('overlayParent', overlayParent.id);
          size.height = overlayParent.clientHeight;
          size.width = overlayParent.clientWidth;
        };
      };

      //const width = hasQtnBG? (cp ? cp.clientWidth : defaultW):defaultW;
      //const height = hasQtnBG? (Math.round(width * aspectRatio.current)):defaultH;
      //console.log('hasQtnBG, aspectRatio.current', hasQtnBG, aspectRatio.current);
      //const height = (width * aspectRatio.current);
      //console.log('createCanvas:', width, height, width/height)
      //myCont.current = cp ? cp : null;
      const canvas = new window.fabric.Canvas(myID, {
        width: size.width,
        height: size.height,
        selection: true,
        //backgroundColor: 'white',
        //backgroundColor: (isTest || hasQtnBG) ? 'transparent' : 'white',
        backgroundColor: 'transparent',
        allowTouchScrolling: true,
        //uniformScaling: true,
        //uniScaleKey: null
      });

      if (canvas) {
        myCanvas.current = canvas;
        if (typeof setCanvas === 'function') setCanvas(canvas);
        canvas.on('object:added', (opt) => { updateResp && updateResp(canvas, opt) });
        canvas.on('object:removed', (opt) => { updateResp && updateResp(canvas, opt) });
        canvas.on('object:modified', (opt) => { updateResp && updateResp(canvas, opt) });
        canvas.on('object:skewing', (opt) => { updateResp && updateResp(canvas, opt) });
        canvas.on('erasing:end', (opt) => { updateResp && updateResp(canvas, opt) });
        canvas.on('mouse:up', (opt) => { mouseUp && mouseUp(canvas) });
        if (isTest) {
          const circle = new window.fabric.Circle({
            radius: 50,
            fill: 'yellow',
            stroke: 'green',
            strokeWidth: 3
          });
          canvas.add(circle);
        };
      };
    };
    resizeCanvas();
  };

  useEffect(() => {
    //createCanvas();
    //window.addEventListener('resize', resizeCanvas);
    return () => {
      //window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  useEffect(()=>{
    let resize_ob;
    if (isScriptReady && myCont.current && !doInit.current) {
      doInit.current = 1;
      //initFabricFunc();
      createCanvas();
      resize_ob = new ResizeObserver(()=>{
        resizeCanvas();
      });
      resize_ob.observe(myCont.current);
      doInit.current = 0;
    };
    return () => {
      resize_ob && resize_ob.disconnect();
    };
  },[isScriptReady, myCont.current]);

  const exStyle = isOverLay?{backgroundColor:'transparent', overflow:'hidden'}:{};
  return isScriptReady?<div id={containerID} ref={myCont} className={hasQtnBG?'DrawCanvasBaseContBG':'DrawCanvasBaseCont'}
    style={exStyle}>
    <canvas id={myID}></canvas>
  </div>:<></>;
};

export default DrawingCanvas;

export const FABRIC_SRC = _WebRoot + 'static/js/fabric.min.js';

const chkScriptObjExist = (src) => {
  if (src === FABRIC_SRC) {
    const exist = window.fabric?1:0;
    //console.log('fabric exist check:',exist);
    return exist;
  };
  return 0;
};
/*
export const useFabric = (src = FABRIC_SRC) => {
  const [scriptReady, setScriptReady] = useState(chkScriptObjExist(src));

  useEffect(() => {
    if (!src || scriptReady) return;
    const exist = document.querySelector(`script[src="${src}"]`);
    if (!exist) {
      //console.log(`Creating script: ${src}`);

      // Create script
      const script = document.createElement("script");
      script.src = src;
      script.type = "text/javascript";
      script.async = true;
      document.body.appendChild(script);
    };

    const setStateFromEvent = (event) => {
      //console.log('script event', event.type);
      setScriptReady(event.type === "load" ? 1 : 0);
    };

    const _script = document.querySelector(`script[src="${src}"]`);
    if (!exist && _script) {
      _script.addEventListener("load", setStateFromEvent);
      _script.addEventListener("error", setStateFromEvent);
    } else if (exist) {
      //console.log(`script exist: ${src}`);
      if (chkScriptObjExist(src)) {
        setScriptReady(1);
      };
    };

    return () => {
      if (_script) {
        _script.removeEventListener("load", setStateFromEvent);
        _script.removeEventListener("error", setStateFromEvent);
      };
    };
  }, [src]);

  return scriptReady;
};
*/
export const useFabric = (src = FABRIC_SRC) => {
  const [status, setStatus] = useState(src ? "loading" : "idle");
  const [scriptReady, setScriptReady] = useState(0);

  useEffect(()=>{
    if (status === 'ready') {
      setScriptReady(1);
    };
  }, [status]);

  useEffect(() => {
      if (!src) { setStatus("idle"); return; };

      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        //console.log(`Creating script: ${src}`);
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.setAttribute("data-status", "loading");
        //document.body.appendChild(script);
        document.head.appendChild(script);

        const setAttributeFromEvent = (event) => {
          script.setAttribute( "data-status", event.type === "load" ? "ready" : "error");
        };

        script.addEventListener("load", setAttributeFromEvent, { once: true });
        script.addEventListener("error", setAttributeFromEvent);
      } else {
        //console.log(`Using existing script with status: ${script.getAttribute("data-status")}`);
        setStatus(script.getAttribute("data-status"));
      };

      // add status load check to avoid script tag exist but still loading
      const setStateFromEvent = (event) => {
        setStatus(event.type === "load" ? "ready" : "error");
      };

      script.addEventListener("load", setStateFromEvent);
      script.addEventListener("error", setStateFromEvent);

      return () => {
        if (script) {
          script.removeEventListener("load", setStateFromEvent);
          script.removeEventListener("error", setStateFromEvent);
        };
      };
    },[src]);

  return scriptReady;
};

export const addCanvasImg = (url, cv) => {
  if (!cv) return;
  window.fabric.Image.fromURL(url, (oImg, isError) => {
    cv.add(oImg);
  });
};

export const cavHasSelected = (cv) => {
  const activeObjects = cv.getActiveObjects();
  if (activeObjects && (activeObjects.length > 0)) return true
  else return false;
};

export const delCavObj = (cv) => {
  const activeObjects = cv.getActiveObjects();
  if (activeObjects) {
    activeObjects.forEach((object) => {
      cv.remove(object);
      cv.discardActiveObject();
    });
  };
};

export const centerCavObj = (cv) => {
  const activeObjects = cv.getActiveObjects();
  if (activeObjects) {
    const as = new window.fabric.ActiveSelection(activeObjects);
    cv.centerObject(as);
    as.destroy();
  };
};

export const setCanvasBGImg = (url, cv) => {
  if (!cv) return;
  window.fabric.Image.fromURL(url, (img, isError) => {
    if (img) {
      const center = cv.getCenter();
      const wzoom = (cv.width / img.width);
      const hzoom = (cv.height / img.height);
      const finalZoom = Math.min(wzoom, hzoom);
      img.set({
        scaleX: finalZoom,
        scaleY: finalZoom,
        top: center.top,
        left: center.left,
        originX: 'center',
        originY: 'center'
      });
      cv.setBackgroundImage(img, cv.renderAll.bind(cv));
    };
  });
};

/*todo*/
