import React, {useState, useEffect} from 'react';

import {ReduxBind} from '../saga/ReduxState';

import * as UI from '../libs/libUI';
import { apiCallLoad } from '../libs/awsFuncs';
import { aTUITime, clickUrl } from './AppUtil';
import { isAry, toAry, toStr } from '../libs/libType';
import { _ATRoot, _ATRoleNames } from '../consts/ATConstReact';
import { _ATRoleApprover, _ATRoleRBasic } from '../consts/ATConsts';
import { arrayAddOrDelete_ } from '../libs/libArray';

import CpATIcoBtn from './components/CpATIcoBtn';
import { popConfirm } from './components/CpPopup';
import { ATDo_User, _ATCanDo } from '../consts/ATRoleRights';
import { urlPush_Replace } from '../saga/urlPush.saga';

const filterIcon = UI.imgSrc('svg/filter.svg');
const newIcon = UI.imgSrc('svg/newPlus.svg');
//const cbCheckedIcon = UI.imgSrc('svg/cbChecked.svg');
//const cbUncheckedIcon = UI.imgSrc('svg/cbUnchecked.svg');
const editEnableIcon = UI.imgSrc('svg/editBlue.svg');
//const editDisableIcon = UI.imgSrc('svg/editGrey.svg');
const delEnableIcon = UI.imgSrc('svg/deleteBlue.svg');
//const delDisableIcon = UI.imgSrc('svg/deleteGrey.svg');


const PageATAccounts = ReduxBind((props) => { 
    const dispatch = props.dispatch;
    const [ATUsers, setATUsers] = useState(); 
    const [ticks, setTicks] = useState();

    const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
    const letUser = _ATCanDo(role, ATDo_User);

    const onLoad = (res, err) => {
        //if(err) window.alert(JSON.stringify(err));
        const ATSubjectMap = {};
        isAry(res?.ATSubjects) && res.ATSubjects.forEach(s => { ATSubjectMap[s.ATSubjectId] = s.ATSubjectName } );
        const ATUsers =  toAry(res?.ATUsers).map(u => {
            const USStr = toAry(u.USubjIds).map(sid => ATSubjectMap[sid]).filter(sn => toStr(sn).trim()).join(' | ');
            return {...u, USStr}; 
        });
        //lj("atuser:",ATUsers);   
        setATUsers(ATUsers);
        setTicks([]);
     };

    useEffect( () => { 
        letUser? apiCallLoad( dispatch, '/getATUsers', onLoad, {}): dispatch(urlPush_Replace(_ATRoot));
    }, []);

    const tickU = uid => on => setTicks(ts => arrayAddOrDelete_(ts, uid, on));
    
    const clickNew = clickUrl(dispatch, _ATRoot + 'account/new');
    const clickEdit = ticks && (clickUrl(dispatch, _ATRoot + 'account/edit/'+ticks[0]));

    const clickDelOk = () => apiCallLoad( dispatch, '/deleteATUsers', onLoad, {ATUserIds:ticks});
    const clickDeletes = UI.stopEventThen( e => popConfirm(dispatch, 0, 'Please Confirm Delete', clickDelOk ) );

    const sel = (ticks?.length);
    const AUs = isAry(ATUsers)?ATUsers:[]

    return <div className="PageATAccounts adminAccountTop">
        <div className="flexColStart w100 p8" style={{height:'100%'}}>
    	<div className='w100' style={{overflow:'auto', marginBottom:'50px'}}> 
            <div className="f16">Admin / Accounts</div>
            <div className="adminToolsContainer"><div style={{display:"flex"}}>
                <div className="adminTools1">
                    <CpATIcoBtn onClick={clickNew} src="labelDiagram/add" txt='New' />
                    <CpATIcoBtn onClick={(sel === 1) && clickEdit} src="general/edit" txt='Edit' />
                    <CpATIcoBtn onClick={(sel > 0) && clickDeletes} src="general/delete" txt='Delete' />
                </div>
                <CpATIcoBtn src="general/filter" txt={'Filter: '+((AUs?.length)||'0')+' Users'} onClick={toDo} />
            </div></div>
            <div className="adminItemRowHeader">
                <div className="adminItem1"></div>
                <div className="adminItem2">Account</div>
                <div className="adminItem3">Role</div>
                <div className="adminItem4">Last Modified</div>
                <div className="adminItem5">Access Subjects</div>
            </div>            
            <div className="adminListContainer">
                {AUs.map((u, i) => userItem2(u, i, ticks, tickU))}                
            </div>
        </div></div>
    </div>;    
});
export default PageATAccounts;

const toDo = () => { alert("TBC") };

const userItem2 = (u, i, ticks, tickU) => {
    
    const id = u.ATUserId;
    const on = (ticks && ticks.includes(id))?1:0;
    return <div key={'ATU'+i+'_'+on} className="accItemRow">
        <div className="adminItem1">
        {UI.CheckBox0(ticks && ticks.includes(id), tickU(id), 'chk'+id, 0, '', {})}
        </div>
        <div className="adminItem2 f15">{u.ATLogin}</div>
        <div className="adminItem3 f15">{_ATRoleNames[u.ATRole]}</div>
        <div className="adminItem4 f15">{aTUITime(u.dateMod)}</div>
        <div className="adminItem5 f15">{u.USStr}</div>
    </div>;
};





    /*
    return <>
            <h1>Admin / Accounts</h1>
            <div style={{display:'flex'}}>
                {UI.Button0('+ New', clickGo('account/new'), 'btnNew', '')} 
                <div style={{flexGrow:1, textAlign:'right'}}>Filter: {AUs.length} Users</div>
            </div>
            <div>{AUs.map((u, i) => userItem(u, i, clickGo, clickDelete))}</div>
    </>;
    */
// sel account role modified accessSubjects
// ATLogin, ATUserId, ATUserSubjects:Array, USStr, dateAdd, dateMod, modTimeHK, role
/*
export const formatDateString = (txt) => {
    // 01234567890123
    // 20221007113807
    if (txt.length !== 14) return txt;
    return txt.substring(0,4)+"-"+txt.substring(4,6)+"-"+txt.substring(6,8)+" "
        +txt.substring(8,10)+":"+txt.substring(10,12)+":"+txt.substring(12,14)
};
*/