import React from 'react';

import { preJS } from '../AppUtil';
import { toObj, toStr } from '../../libs/libType';
import { subState } from '../utils/useChain';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import { _alignClass } from './ViewExOptions';
import { exerTitle, viewExLabel, exATUIText } from './ViewExCommon';
import { useUILang } from '../utils/useUILang';

const ViewExCtnCK = props => {
    const {lock, showEn, ECtn, replaceECtn, setUpFlag, setOnAddMedia, PVMode, 
        setEctnModified, mediaDLs, getMediaDLs, idx} = props; 
    const [ t ] = useUILang();
    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
    
    const lang = showEn?'en':'ct';
    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));

    const setCKField = L => val => {
        
        replaceECtn(subState(L, subState("ckData", val)))
    };

    const ckData = toStr(ec.ckData);
    const ck_updateMediaID = (arr) => { return getMediaDLs([...arr]); };
    const ckImageConfig = {
        setOnAddMedia,
        updateMediaID: ck_updateMediaID,
    };
    const edProps = {mediaDLs, getMediaDLs, setOnAddMedia};
    const title = toStr(ec.title);
    if(lock || PVMode >=0) {
        return <div className={'ViewExCtnItemLock ' + (PVMode!==1?' Single':' List')}>
            <div id={'exCtnContent'+idx} className='ViewExCtnItemLockContent'>
                <div className='flexRowStart semi-bold' style={{maxWidth:'1024px'}}>
                    {PVMode===1?exerTitle(title):title}</div>
                    {'' && preJS(ckData,3)}
                <div className='ViewExCtnCKReadOnly' style={{maxWidth:'1024px'}}><QEditorReadOnly key={'ck'+ckData} data={ckData} {...edProps}/></div>
            </div>
        </div>;
    };

    const ckDataEn = toStr(ECtn?.en?.ckData);
    const ckDataCt = toStr(ECtn?.ct?.ckData);
    const enStyle = lang==='ct'?{display:'none'}:{};
    const ctStyle = lang==='en'?{display:'none'}:{};
    const exStyle = lock?{paddingTop:0}:{};
    return <div className='ViewExCtnItemQtn' style={exStyle}>
        <p/>
        <div className='flexRowBetween' >
            {viewExLabel(t('exercise.content.text.title'),'title')}{exATUIText(ec.title, setTmpField('title'), 'vid')}
        </div><p/>
        <div className='flexRowCenter' style={enStyle}><Ckeditor5Base editorType="question" data={ckDataEn} setData={setCKField('en')}
            enable={!lock} debug={false} showStatus={true} noDragDrop={true} ckImageConfig={ckImageConfig}
            {...edProps} setUpFlag={setEctnModified}/>
        </div>
        <div className='flexRowCenter' style={ctStyle}><Ckeditor5Base editorType="question" data={ckDataCt} setData={setCKField('ct')}
            enable={!lock} debug={false} showStatus={true} noDragDrop={true} ckImageConfig={ckImageConfig}
            {...edProps} setUpFlag={setEctnModified}/>
        </div>
    </div>
};
export default ViewExCtnCK; 
