import React from 'react';
import {toInt, toStr, isStr, isObj, isAry, } from './libType';
import { logMode } from '../saga/ReduxState';
import querystring from 'querystring';

//String Formatting Functions

//Convert Error Object(s) or Error String(s), return (Readable Error String Array (now joined)) or (empty string)
export const errMsgs = (e) => {
    if(isAry(e)){
        var ret = [];
        e.forEach( e1 => {
            const et1 = errMsgs(e1);
            if(et1) ret.push(et1);
        });
        return ret.length?ret.join(', '):'';
    }
    const e1 = errMsg(e);
    return e1;//?[e1]:'';
};
//Convert Error Object or Error String to Readable Error String
export const errMsg = (e) => {
    if(!e)
        return '';
    if(isStr(e)) return e;
    if(e.stack) return e.stack
    if(e.message) return e.message
    if(isObj(e)){
        return JSON.stringify(e);
    }
    return toStr(e);  
};
//convert byte to readable KB,MB,GB etc
export const humanFileSize = (s) => {
    const KB = 1024, MB = KB*1024, GB = MB*1024;
    if(s > GB){
        return Math.round(s * 100 / GB) / 100 +'GB';
    }else if(s > MB){
        return Math.round(s * 100 / MB) / 100 +'MB';
    }else if(s > KB){
        return Math.round(s * 100 / KB) / 100 +'KB';
    }
    return s+'Byte';
}


export const urlEncode = (s) => { return querystring.escape(s); };
export const urlDecode = (s) => { return querystring.unescape(s); };

export const htmlLines = (s) => {
    const lines = toStr(s).trim().split('\n');
    return lines.map((l, i) => <div id={'L'+i} key={'L'+i}>{l}<br/></div>);
}

export const addBr = (s) => {
    if(!s) return '';
    const lines = toStr(s).split('\n');
    const ps = lines.map(l => '<p>'
    +l /* .replace(/ /g, '<figure>\t</figure>').replace(/ /g, '<figure>\t</figure>') */  
    +'</p>\n').join('');
    return ps;
}

//Trim Long String preserver partial head [and paitial tail]
export const StrShorten = (n, maxLen=40, headLen=18, tailLen=18, conCater='...') => {
    const l = n.length;
    return l > maxLen?(n.substr(0, headLen) + conCater + n.substr(l-tailLen)):n;
}

export const fileExt = (n) => { return toStr(n).trim().split('.').pop().toLowerCase(); }

export const obj2KVs = (o) => o?Object.keys(o).map( key => { return {key, val:o[key]}; }):[];
export const obj2Table = (o) => rows2Table(obj2KVs(o));
export const rows2Table = (rows) => {
    if(rows && !isAry(rows)) return rows2Table([rows]);
    if(!rows || !rows.length) return (<table className="debugTable"><tbody><tr><td>Empty Rows</td></tr></tbody></table>);
    const keys = Object.keys(rows[0]);
    const headRow = (<tr>{keys.map( (k, i) => <td key={i}>{k}</td>)}</tr>);
    const bodyRows = rows.map ( (r, j)  => <tr key={j} >{keys.map( (k, i) => <td key={i}>{r[k]}</td>)}</tr> );
    return <table className="debugTable">
    <thead>{headRow}</thead><tbody>{bodyRows}</tbody></table>;
};
export const array2table = (a) => {
    if(a && !isAry(a)) return array2table([a]);
    if(!a || !a.length) return (<table className="debugTable"><tbody><tr><td>Empty</td></tr></tbody></table>);
    const bodyRows = a.map ( (r, j)  => <tr key={j} ><td>{r}</td></tr> );
    return <table border="1" cellPadding="2" cellSpacing="0"><tbody>{bodyRows}</tbody></table>;
};


// Convert Array to Object by a selected Key
export const rows2obj = (rows, key) => {
    const ret = {};
    rows.map( r => ret[r[key]] = r);
    return ret;
};
export const findByKey = (rows, key, find) => {
    if(!find || !rows) return 0;
    const l = toInt(rows && rows.length);
    if(l) for(var i = 0; i < l; i++) if(rows[i][key] === find) return rows[i];
    return 0;
};
export const findIndexByKey = (rows, key, find) => {
    if(!find || !rows) return -1;
    const l = toInt(rows && rows.length);
    if(l) for(var i = 0; i < l; i++) if(rows[i][key] === find) return i;
    return -1;
};

export const lj = (...jos) => logMode() && jos.forEach(jo => console.log(JSON.stringify(jo)));
export const dlj = (...jos) => logMode() && jos.forEach(jo => console.log(JSON.stringify(jo)));
