import {useEffect, useState, useMemo} from "react";

export const winSize = () => ({w:window.innerWidth, h:window.innerHeight});

export const useResizeMemo = () => {
    const wSize = winSize();
    const [wh, setwh] = useState([wSize.w, wSize.h]); 
    useResizeEffect((w, h) => { setwh([w,h]); });
    return wh;
};

export const useResizeEffect = callback => {
    const updateSize = () => {
        
        callback(window.innerWidth, window.innerHeight);
    }
    const delayCB = debounce(updateSize, 100);
    useEffect(() => {
        window.addEventListener("resize", delayCB);
        updateSize();
        return () => window.removeEventListener("resize", delayCB);
    }, []);
};

const debounce = (func, delayMS=400) => {
    var timer;
    return event => {
        
        if (timer) clearTimeout(timer)
        timer = setTimeout(func, delayMS, event);
    }
};

// match bootstrap window size
// sm - 576, md - 768, lg - 992, xl - 1200, xxl - 1400
// just check once, no need to use resize event
const mobileWidth = 768;
export const fakeIsMobile = () => {
    return window.innerWidth <= mobileWidth;
};
