import { delay, put, select, /* call, all, takeLeading, takeEvery,*/  takeLatest } from 'redux-saga/effects';
import { loading_set, loading_unset } from './loading.saga';
import { timeStampNowGMT } from '../libs/libTime';

// ==== ==== Types; Type of Dispatch/Put Actions ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== 
const URLPUSH_STATE = 'URLPUSH_STATE'; //command (request receive state, may start doing, put Learn Answer // do)
const URLPUSH_REPLACE = 'URLPUSH_REPLACE'; //command (request receive state, may start doing, put Learn Answer // do)

// ==== ==== reducer; to update State per action ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
const initState = {times:[]};
export const urlPushReducer = (state = initState, action) => {
    if(action.type === URLPUSH_STATE){
        return {...state, times: action.times};
    }
    return state;
};

// ==== ==== action; to be dispatch() By UI / put() by Services ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const urlPush_Replace = (path) => ( { type:URLPUSH_REPLACE, path } );
export const urlPush_state = (times) => ( { type:URLPUSH_STATE, times } );


// ==== ==== saga; watcher saga watch for action / action saga call() service, put() action= === ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export function* watchUrlPushSaga() {
    yield  takeLatest(URLPUSH_REPLACE, urlPushSaga); //new start will run, parallel with many running
    //yield takeLeading(APICB_LEADING, ApiCBSaga); //new start is ignored, until running one is finish
    //yield takeLatest(APICB_LATEST, ApiCBSaga); //new start will run, running one will be stop on next yield
};

export function* urlPushSaga(action) {
    //lj({'urlPushSaga':action});
    return yield _urlPush(action.path);
}

// ==== Saga Supporting Services ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====  
export function* _urlPush(path) {
    //lj('_urlPush:'+path);
    try {
        if(window.location.pathname === path)
            return;

        const urlPush = yield select(s => s.urlPush); // get back the saga state
        const times = urlPush.times; 

        const time = timeStampNowGMT();
        const cut = time - (30 * 1000);
        const times2 = times.filter(t => (t > cut));
        times2.push(time);

        const cnt = times2.length;
        //lj({last30sec:cnt});
        const t = (cnt === 45)?1000
            :(cnt === 46)?3000
            :(cnt === 47)?5000
            :(cnt > 48)?(500 + times2[0] - cut)
            :0;
/*        const t = (cnt === 80)?1000
            :(cnt === 85)?3000
            :(cnt === 90)?5000
            :(cnt > 95)?(500 + times2[0] - cut)
            :0;*/

        if(t>0){
            //lj({delay:t});
            yield put(loading_set('urlPush', 1));
            yield delay(t);
            yield put(loading_unset('urlPush'));
        }

        
        window.history.replaceState({},'', path); //yield put(replace(path)); 
        window.history.pushState({}, '', path); //yield put(push(path));
        //window.history.deleteAll();

        //const genRet = yield pushPath(param)); // await a generator
        //const mwUser = yield call(mw.getCurrentUser); // await async call
        yield put( urlPush_state(times2) ); //call a saga 
        return times; //return response/Error to API Caller
    } catch(e) {
        console.error(e);
    }
};
