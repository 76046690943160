import { maxVal, objEntries, objVals, toAry, toFix1, toFloat, toInt, toObj, toPercentF, toStr } from "../../../libs/libType";
import { addAsmSum, rptisJ, rptisMonth, } from "./dataUtil";


export const toClsDetailData1_ = (rptOvers, rptOverStus, j, rptM, ) => {
  
  const os = Object.fromEntries(toAry(rptOvers).map(o => [o.assignId, o]));
  const sumStus = {};
  toAry(rptOverStus).forEach(r => {
    const {assignId, students, AMetaDisplays, ym} = toObj(r);
    
    if(!(rptisMonth(r, rptM) && rptisJ(r, j))) return;

    const o = toObj(os[assignId]);
    //const m = ym2m(r.ym);
    objEntries(students).forEach( ([stu, rs]) => {
      sumStus[stu] = addAsmSum(sumStus[stu], o.APass, o.Exer?.eScore, rs.att, rs.pass, rs.mark);
    });
  });
  return {sumStus};
};

/*
export const toClsDetailData1 = (clsDetailData_, clsStudents, ut) => {
  const sumStus = toObj(clsDetailData_?.sumStus);
  const student = objEntries(clsStudents).map(([id, s]) => {
    const sss = sumStus[id];
    const { att, natt, pass, fail, markp, maxp, minp, } = toObj(sss);
    return {
      id,
      no: toStr(s.classNo),
      name: ut(s.nameEng, s.nameChi),
      submitted: sss? true: false,   
      _nameEn: s.nameEng,
      _nameCt: s.nameChi,
      _avgMp: toFloat(markp),  
      _sub: toInt(natt + att + pass + fail),
    };
  });

  const sumCls = {ttl: 0, markp: 0,};
  objVals(sumStus).forEach(rs => {
    const cnt = rs.pass + rs.fail + rs.att;
    if(cnt){
      sumCls.ttl += cnt;
      const p = rs.markp; //toPercentF(rs.markp, cnt * 100);
      sumCls.markp += p;
    }
    //sumCls.markps.push([rs.markp, rs.ttl]);
  });

  const cs = toObj(sumCls);
  const clsAvgp = toPercentF(cs.markp, cs.ttl * 100);
  const data = objEntries(sumStus).map(([stu, s]) => {
    const cnt = s.pass + s.fail + s.att;
    const ttl =  cnt + s.natt;
    const avgp = toPercentF(s.markp, cnt * 100);
    const ret = {
      referenceId: stu,
      assignment: [
        s.pass && { score: {value: s.pass, percentage: toPercentF(s.pass, ttl) }, status: "p", },
        s.fail && { score: {value: s.fail, percentage: toPercentF(s.fail, ttl) }, status: "f", },
        s.att && { score: {value: s.att, percentage: toPercentF(s.att, ttl) }, status: "a", },
        s.natt && { score: {value: s.natt, percentage: toPercentF(s.natt, ttl) }, status: "n", },
      ].filter(s => s),
      classAverageScore: { percentage: clsAvgp }
    }
    if(cnt) ret.scoreRange = { minPercentage: toFix1(s.minp), maxPercentage: toFix1(s.maxp), status: (clsAvgp > avgp)? 'f': 'p' };
    if(cnt) ret.averageScore = { percentage: toFix1(avgp) };
    return ret;
  });
  const clsDetailData1 = { student, data }; //ProgressByMonthByStudentSharedData
  return clsDetailData1;
  //return ClassPerformanceDetailsData; 
};
*/

export const toClsDetailData1v2 = (clsDetailData_v2, rptM, clsStudents, ut) => {
  const m = toInt(toStr(rptM).split('_')[1]);
  
  const sumStus = toObj(m? (clsDetailData_v2?.sumStuM2d?.[m]): (clsDetailData_v2?.sumStus));

  const student = objEntries(clsStudents).map(([id, s]) => {
    const sss = sumStus[id];
    const { att, natt, pass, fail, markp, maxp, minp, } = toObj(sss);
    return {
      id,
      no: toStr(s.classNo),
      name: ut(s.nameEng, s.nameChi),
      submitted: sss? true: false,   
      _nameEn: s.nameEng,
      _nameCt: s.nameChi,
      _avgMp: toFloat(markp),  
      _sub: toInt(natt + att + pass + fail),
    };
  });

  const sumCls = {ttl: 0, markp: 0,};
  objVals(sumStus).forEach(rs => {
    const cnt = rs.pass + rs.fail + rs.att;
    if(cnt){
      sumCls.ttl += cnt;
      const p = rs.markp; //toPercentF(rs.markp, cnt * 100);
      sumCls.markp += p;
    }
    //sumCls.markps.push([rs.markp, rs.ttl]);
  });
  const cs = toObj(sumCls);

  const clsAvgp = toPercentF(cs.markp, cs.ttl * 100);
  const data = objEntries(sumStus).map(([stu, s]) => {
    const cnt = s.pass + s.fail + s.att;
    const ttl =  cnt + s.natt;
    const avgp = toPercentF(s.markp, cnt * 100);
    const ret = {
      referenceId: stu,
      assignment: [
        s.pass && { score: {value: s.pass, percentage: toPercentF(s.pass, ttl) }, status: "p", },
        s.fail && { score: {value: s.fail, percentage: toPercentF(s.fail, ttl) }, status: "f", },
        s.att && { score: {value: s.att, percentage: toPercentF(s.att, ttl) }, status: "a", },
        s.natt && { score: {value: s.natt, percentage: toPercentF(s.natt, ttl) }, status: "n", },
      ].filter(s => s),
      classAverageScore: { percentage: clsAvgp }
    }
    if(cnt) ret.scoreRange = { minPercentage: toFix1(s.minp), maxPercentage: toFix1(s.maxp), status: (clsAvgp > avgp)? 'f': 'p' };
    if(cnt) ret.averageScore = { percentage: toFix1(avgp) };
    return ret;
  });

  const clsDetailData1 = { student, data }; //ProgressByMonthByStudentSharedData
  
  return clsDetailData1;
  //return ClassPerformanceDetailsData; 
};


const ClassPerformanceDetailsData = {
  "student": [
    {"id": "s-001","no": "01","name": "Eric Yau","submitted": true},
    {"id": "s-002","no": "02","name": "Wing Tang","submitted": true},
    {"id": "s-003","no": "03","name": "Anson Bean","submitted": true},
    {"id": "s-004","no": "04","name": "Edan Lui","submitted": false}
  ],
  "data": [{
      "referenceId": "s-001",
      "assignment": [
        {"score": { "value": 10, "percentage": 50 }, "status": "p" },
        {"score": { "value": 6, "percentage": 30 }, "status": "f" },
        {"score": { "value": 3, "percentage": 15 }, "status": "a" },
        {"score": { "value": 1, "percentage": 5 }, "status": "n" }
      ],
      "scoreRange": { "minPercentage": 40, "maxPercentage": 70, "status": "p" },
      "averageScore": { "percentage": 68.3 },
      "classAverageScore": { "percentage": 54 }
    },{
      "referenceId": "s-002",
      "assignment": [
        { "score": { "value": 10, "percentage": 50 }, "status": "p" },
        { "score": { "value": 6, "percentage": 30 }, "status": "f" },
        { "score": { "value": 3, "percentage": 15 }, "status": "a" },
        { "score": { "value": 1, "percentage": 5 }, "status": "n" }
      ],
      "scoreRange": { "minPercentage": 30, "maxPercentage": 50, "status": "f" },
      "averageScore": { "percentage": 40 },
      "classAverageScore": { "percentage": 54 }
    },{
      "referenceId": "s-003",
      "classAverageScore": { "percentage": 54 }
    },{
      "referenceId": "s-004",
      "assignment": [
        { "score": { "value": 10, "percentage": 50 }, "status": "p" },
        { "score": { "value": 6, "percentage": 30 }, "status": "f" },
        { "score": { "value": 3, "percentage": 15 }, "status": "a" },
        { "score": { "value": 1, "percentage": 5 }, "status": "n" }
      ],
      "scoreRange": { "minPercentage": 20, "maxPercentage": 90, "status": "p" },
      "averageScore": { "percentage": 55 },
      "classAverageScore": { "percentage": 54 }
    }
  ]
};