import React, {HTMLProps, ReactNode, useContext, useEffect, useMemo, useRef, useState} from "react";
import {mode} from "mathjs";
import {Button} from "react-bootstrap";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpIco from "./CpIco";
import CpActionButton from "./CpActionButton";
import CpSharedModal from "./CpSharedModal";
import { useUILang } from "../../AppExPf/utils/useUILang";

const CpHeaderTitle = (props) => {
    const {title, iconPath, titleIconPath, animation = false, editable = false, onClick, ...HeaderProps} = props;
    const [t] = useUILang();
    const [enableEdit, setEnableEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const editInputRef = useRef(null);

    const handleClose = () => { setOpen(false); }
    const clickEdit = () => {
        if (editInputRef.current) {
            if (editInputRef.current.getBoundingClientRect().width < 200) {
                setOpen(true);
            } else {
                setEnableEdit(true);
            };
        };
    };

    useEffect(() => { setEnableEdit(false); }, [mode]);

    return <>
        <span key={title} className={`d-flex justify-content-start semi-bold user-select-none`}>
            <div className={`${animation ? "overflow-hidden" : ""} `}>
                <div className={`d-flex align-items-center gap-1 text-truncate`}>
                    <span className={`c000 fs-5 ${onClick? "btn" : ""}`} onClick={onClick}><CpIco src={iconPath}/></span>
                    <span className={`d-flex align-items-center gap-2 text-truncate`}>
                        {titleIconPath}
                        {(!enableEdit) &&
                            <span className={`fs-6 text-truncate ${animation ? "animation-fade-up" : ""}`} ref={editInputRef}>{title}</span>}
                        {enableEdit &&
                            <input className={"d-flex flex-grow-1 text-truncate vw-100"} defaultValue={title}/>}
                        {<>
                            {editable && !enableEdit  &&
                                <CpActionButton iconPath={IconList.general.edit}
                                              className={"exercise-small-action-btn edit"}
                                              onClick={() => clickEdit()}/>}
                            {editable && enableEdit &&
                                <CpActionButton iconPath={IconList.general.cancelFull}
                                              className={"exercise-small-action-btn cancel"}
                                              onClick={() => setEnableEdit(false)}/>}
                            {editable && enableEdit &&
                                <CpActionButton iconPath={IconList.general.confirmFull}
                                              className={"exercise-small-action-btn confirm"}
                                              onClick={() => setEnableEdit(false)}/>}
                        </>}
                    </span>
                </div>
            </div>
        </span>
        <CpSharedModal show={open} handleClose={handleClose} 
            footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center"}>
                <Button variant="gray-body-color w-100 border" onClick={handleClose}>{t("cancel")}</Button>
                <Button variant="sidenav-primary w-100" onClick={handleClose}>{t("confirm")}</Button>
            </div>}>
            <div className={"px-3 bg-dim-100"}>
                <span className={"fs-5 semi-bold d-flex justify-content-start align-items-center gap-2 mb-3"}>
                    <CpIco src={IconList.general.edit}/>{t("rename")}</span>
                {t("rename-message")}
                <input className={"d-flex flex-grow-1 text-truncate w-100"} defaultValue={title}/>
            </div>
        </CpSharedModal>
    </>
};

export default CpHeaderTitle;