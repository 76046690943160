import CpIco from "./CpIco";

const CpMessageBox = (props) => { // base on poc MessageBox.tsx
    const {item, t=0, className = ""} = props;
    const {mode, style, content, iconPath = "", styleClass = ""} = item;

    const _t = v => t? t(v): v;

    const txtOnlyStyle = { padding: "0 !important"};
    const renderStyle = (mode === "warning")? "warning": (mode === "error")? "error": '';

    if (style === "divTxt") {
        return <div className={`CpMessageBox message-box rounded-light text-${renderStyle} ${className} ${styleClass}`} style={txtOnlyStyle}>
            {_t(content.title)}
        </div>
    } else if (style === "spanTxt") {
        return <span className={`CpMessageBox message-box rounded-light text-${renderStyle} ${className} ${styleClass}`} style={txtOnlyStyle}>
            {_t(content.title)}
        </span>
    } else if (style === "unstyle") {
        return <div className={`CpMessageBox message-box rounded-light semi-bold ${renderStyle} ${className} ${styleClass}`}>
            {_t(content.title)}
        </div>
    } else if (style === "dot") {
        return <div className={`CpMessageBox message-box rounded-light semi-bold ${renderStyle} ${className} ${styleClass}`}>
            <ul><li>{_t(content.title)}</li></ul>
        </div>
    } else if (style === "list") {
        return <div className={`CpMessageBox message-box rounded-light ${renderStyle} ${className} ${styleClass}`}>
            {content.title ? <span className={"ps-3 semi-bold"}>{_t(content.title)}</span>:''}
            <ul>{content?.list?.map((child, idx) => <li key={idx}>{_t(child)}</li>)}</ul>
        </div>
    } else if (style === "noborder") {
        return <div className={`message-box rounded-light text-${renderStyle} ${className} ${styleClass}`}>
            {content?.list === undefined && _t(content.title)}
            {content?.list !== undefined && <span className={"semi-bold"}>{_t(content.title)}</span>}
            {content?.list !== undefined && <ul>
                {content?.list?.map((child, idx) => <li key={idx}>{_t(child)}</li>)}
            </ul>}
        </div>
    } else if (style === "icon") {
        return <div className={`message-box rounded-light semi-bold d-flex align-items-center gap-2 ${renderStyle} ${className} ${styleClass}`}>
            <CpIco src={iconPath}/><span>{_t(content.title)}</span>
        </div>
    };
    return <></>
};

export default CpMessageBox;