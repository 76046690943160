import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
//import * as serviceWorker from './serviceWorker';
//import 'bootstrap/dist/css/bootstrap.css';

import App from './App/AppExPf';
//import App from './AppCard/AppMiddleWare';//

import './index.scss';
import './poc/screens/schema/basic/Typography.scss';

//ReactDOM.render(<App prop1="prop one"/>, document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//!!!! DO NOT NEVER EVER USE REACT STRICT MODE !!!!! 
//  <React.StrictMode>
<App />
//  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PW
//serviceWorker.unregister();
