import React, { useState, useEffect } from 'react';
import { _ATRoot } from '../consts/ATConstReact';

import { apiCallLoad } from '../libs/awsFuncs';
import { errMsg, lj } from '../libs/libFormat';
import * as UI from '../libs/libUI';

import { ReduxBind } from '../saga/ReduxState';
import { urlPush_Replace } from '../saga/urlPush.saga';
import { toStr } from '../libs/libType';
import { clickUrl } from './AppUtil';
import CpATIcoBtn from './components/CpATIcoBtn';
import { popAlert } from './components/CpPopup';

import { ATDo_Subj, _ATCanDo } from '../consts/ATRoleRights';

const PageATSubjects = ReduxBind((props) => {
    const dispatch = props.dispatch;
    const [ATSubjects, setATSubjects] = useState();

    const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
    const letSubject = _ATCanDo(role, ATDo_Subj);

    useEffect(() => { 
        letSubject?apiCallLoad(dispatch, '/getATSubjects', onLoad, {}): dispatch(urlPush_Replace(_ATRoot));
    }, []);

    const onLoad = (res, err) => {
        if (err) popAlert(dispatch, 0, errMsg(err));
        setATSubjects(res?.ATSubjects);
    };

    const clickDelete = ATSubjectId => e => { UI.stopEvent(e); apiCallLoad(dispatch, '/deleteATSubject', onLoad, { ATSubjectId }); };

    const clickGo = path => clickUrl(dispatch, _ATRoot + path);

    return <div className="PageATSubjects adminSubjectTop">
        <div className="flexColStart w100 p8" style={{height:'100%'}}>
    	<div className='w100' style={{overflow:'auto', marginBottom:'50px'}}> 
        <div className="f16">Admin / Subject List (for AT only)</div>
        <div className="adminToolsContainer"><div style={{display:"flex"}}>
            <div className="adminTools1">
                <CpATIcoBtn src="labelDiagram/add" txt='New' onClick={clickGo('subject/new')} />
            </div>
            <div className="adminTools2" style={{ color: "#48494A" }}>
                Total: {(ATSubjects?.length)||0}
            </div>
        </div></div>
        <div className="subjectItemContainer">
            { ATSubjects?.map((ats, i) => ATSubjectItem2(ats, i, clickGo, clickDelete) ) }
        </div>
    </div></div></div>;
});
export default PageATSubjects;

const ATSubjectsItemCSS = { margin: '2px', padding: '2px', border: '2px solid #88f', width: '24%', display: 'inline-block', verticalAlign: 'top' };

const ATSubjectItem2 = (s, i, go, del) => {
    const id = s.ATSubjectId;
    return <div key={'ATS' + i} className="subjectItem">
            <div className='flexRowCenterFit' style={{minWidth:'140px'}}>
            <CpATIcoBtn src="general/edit" txt='Edit' onClick={go('subject/edit/' + id)} />
            <CpATIcoBtn src="general/delete" txt='Delete' onClick={del(id)}  />
            </div>
        {s.ATSubjectName}
    </div>;    
};
