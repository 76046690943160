import { toStr } from "../libs/libType";


export const expf2 = ['dev', 'uat', 'offline', 'local' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const rpt_keyStg = expf2;
export const ep2_ppEdit = expf2 && ['dev', 'uat', 'offline', 'local' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const ep2_ppImp = expf2 && ['offline', 'local' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const ep2_ppPatch = expf2 && ['offline', 'local' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const ep2_qset = expf2 && ['offline', 'local' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const ep2_remeEdit = expf2 && ['offline', 'local' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));

export const hasDrawQ = () => expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));

export const showDrawQOpt = expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const showDrawQOptThr = expf2 && ['offline', 'local', 'dev', 'uat'].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const showQtnDrawPreview = expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const showQtnDrawEx = expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const showFrontQtnDrawEx = expf2 && ['offline', 'local', 'dev', 'uat'].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const countDown = expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const hasFontFamily = expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const drawUndoRedo = expf2 && ['offline', 'local' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const newDrawBar = expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const newHintsPop = expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const NoMaskHints = expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const hasOverlapDraw = expf2 && ['offline', 'local', 'dev', 'uat'].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));
export const newDrawFunc = expf2 && ['offline', 'local', 'dev', 'uat' ].includes(toStr(process.env.cfg||process.env.REACT_APP_CFG));


/*
export const expf2 = 1;
export const ep2_ppEdit = 1;
export const ep2_ppImp = 0;
export const ep2_ppPatch = 0;
export const ep2_qset = 0;
export const ep2_remeEdit = 0;

export const hasDrawQ = () => 0;

export const showDrawQOpt = 1;
export const showDrawQOptThr = 1;
export const showQtnDrawPreview = 1;
export const showQtnDrawEx = 0;
export const countDown = 0;
export const hasFontFamily = 0;
export const drawUndoRedo = 0;
export const newDrawBar = 1;
export const newHintsPop = 1;
export const NoMaskHints = 0;
export const hasOverlapDraw = 0;
export const newDrawFunc = 0;
*/

/*
export const expf2 = 0;
export const ep2_ppEdit = 0;
export const ep2_ppImp = 0;
export const ep2_ppPatch = 0;
export const ep2_qset = 0;
export const ep2_remeEdit = 0;

export const hasDrawQ = () => 0;

export const showDrawQOpt = 0;
export const showDrawQOptThr = 0;
export const showQtnDrawPreview = 0;
export const showQtnDrawEx = 0;
export const countDown = 0;
export const hasFontFamily = 0;
export const drawUndoRedo = 0;
export const newDrawBar = 0;
export const newHintsPop = 0;
export const NoMaskHints = 0;
export const hasOverlapDraw = 0;
export const newDrawFunc = 0;
*/

export const __SYSQType_MCQ = 'SQT_MCQ';
    export const __SYSQSubType_MCS = 'SQST_MCS'; // mc standard
    export const __SYSQSubType_MCT = 'SQST_MCT'; // mc table
export const __SYSQType_FIB = 'SQT_FIB';
    export const __SYSQSubType_FIB = 'SQST_FIB'; // fib
export const __SYSQType_LBD = 'SQT_LBD';
    export const __SYSQSubType_LBT = 'SQST_LBT'; // label diagram Text
    export const __SYSQSubType_LBD = 'SQST_LBD'; // label diagram Dropdown
export const __SYSQType_OPE = 'SQT_OPE';
    export const __SYSQSubType_OEG = 'SQST_OEG'; // open general
    export const __SYSQSubType_OEE = 'SQST_OEE'; // open end essay
    export const __SYSQSubType_OED = 'SQST_OED'; // open end drawing (copy from __SYSQSubType_OEG)
export const __SYSQType_POL = 'SQT_POL';
    export const __SYSQSubType_POL = 'SQST_POL'; // polling
//export const __SYSQType_QGP = 'SQT_QGP'; group

const __SYSQTypeCodes = [  __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_FIB, __SYSQSubType_LBT,
    __SYSQSubType_LBD, __SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_OED, __SYSQSubType_POL ];
export const _ATSysQTypeCodes = () => [...__SYSQTypeCodes];

export const __SYSQTypes = {
    [__SYSQType_MCQ]: 'Multiple choice',
    [__SYSQType_FIB]: 'Fill in the blanks',
    [__SYSQType_LBD]: 'Label the Diagram',
    [__SYSQType_OPE]: 'Open-ended',
    [__SYSQType_POL]: 'Polling',  
};

export const __SYSQTrans = {
    [__SYSQSubType_MCS]: 'multiple-choice',
    [__SYSQSubType_MCT]: 'multiple-choice',
    [__SYSQSubType_FIB]: 'fill-in-the-blanks',
    [__SYSQSubType_LBT]: 'label-the-diagram',
    [__SYSQSubType_LBD]: 'label-the-diagram',
    [__SYSQSubType_OEG]: 'open-ended',
    [__SYSQSubType_OEE]: 'open-ended',
    [__SYSQSubType_OED]: 'open-ended', //open-ended
    [__SYSQSubType_POL]: 'polling',
};

const mapUIPrefix = 'at-setting.question-type-menu.';
export const __UISYSQTypes = {
    [__SYSQType_MCQ]: mapUIPrefix+'mcq',
    [__SYSQType_FIB]: mapUIPrefix+'fib',
    [__SYSQType_LBD]: mapUIPrefix+'label-the-diagram',
    [__SYSQType_OPE]: mapUIPrefix+'open-ended',
    [__SYSQType_POL]: mapUIPrefix+'polling',  
};

export const __SYSQTypeGroup = {
    [__SYSQSubType_MCS]: __SYSQType_MCQ,
    [__SYSQSubType_MCT]: __SYSQType_MCQ,
    [__SYSQSubType_FIB]: __SYSQType_FIB,
    [__SYSQSubType_LBT]: __SYSQType_LBD,
    [__SYSQSubType_LBD]: __SYSQType_LBD,
    [__SYSQSubType_OEG]: __SYSQType_OPE,
    [__SYSQSubType_OEE]: __SYSQType_OPE,
    [__SYSQSubType_OED]: __SYSQType_OPE,
    [__SYSQSubType_POL]: __SYSQType_POL,
};

export const _ATSysQTypeGroup = qt => (__SYSQTypeGroup[qt] || qt);
export const _ATSysQTGroups = () => [...[__SYSQType_MCQ, __SYSQType_FIB, __SYSQType_LBD, __SYSQType_OPE, __SYSQType_POL]];

export const _ATSysQTypes = () => ( {...__SYSQTypes} );
export const _ATSysQTypeName = t => __SYSQTypes[t];

export const _ATSYSQTypeFullName = (t) => __SYSQTypeMappedName[t];

export const __SYSQTypesMapping = {
    [__SYSQSubType_MCS]: __SYSQType_MCQ,
    [__SYSQSubType_MCT]: __SYSQType_MCQ,

    [__SYSQSubType_FIB]: __SYSQType_FIB,

    [__SYSQSubType_LBT]: __SYSQType_LBD,
    [__SYSQSubType_LBD]: __SYSQType_LBD,

    [__SYSQSubType_OEG]: __SYSQType_OPE,
    [__SYSQSubType_OEE]: __SYSQType_OPE,
    [__SYSQSubType_OED]: __SYSQType_OPE,

    [__SYSQSubType_POL]: __SYSQType_POL, 
};

export const __SYSQSTypes = {
    [__SYSQSubType_MCS]: 'Standard',
    [__SYSQSubType_MCT]: 'Match table',

    [__SYSQSubType_FIB]: 'Fill in the blanks',

    [__SYSQSubType_LBT]: 'Blank',
    [__SYSQSubType_LBD]: 'Dropdown',

    [__SYSQSubType_OEG]: 'General',
    [__SYSQSubType_OEE]: 'Essay',
    [__SYSQSubType_OED]: 'Drawing',

    [__SYSQSubType_POL]: 'Polling', 
};

export const __UISYSQSTypes = {
    [__SYSQSubType_MCS]: mapUIPrefix+'mcq-standard',
    [__SYSQSubType_MCT]: mapUIPrefix+'mcq-match-table',

    [__SYSQSubType_FIB]: mapUIPrefix+'fib',

    [__SYSQSubType_LBT]: mapUIPrefix+'label-the-diagram-text',
    [__SYSQSubType_LBD]: mapUIPrefix+'label-the-diagram-drop-down',

    [__SYSQSubType_OEG]: mapUIPrefix+'open-ended-general',
    [__SYSQSubType_OEE]: mapUIPrefix+'open-ended-essay',
    [__SYSQSubType_OED]: mapUIPrefix+'open-ended-canvas-drawing',

    [__SYSQSubType_POL]: mapUIPrefix+'polling', 
};

export const __SYSQTypeMappedName = {
    [__SYSQSubType_MCS]: __SYSQTypes[__SYSQTypesMapping[__SYSQSubType_MCS]]+' - '+__SYSQSTypes[__SYSQSubType_MCS],
    [__SYSQSubType_MCT]: __SYSQTypes[__SYSQTypesMapping[__SYSQSubType_MCT]]+' - '+__SYSQSTypes[__SYSQSubType_MCT],

    [__SYSQSubType_FIB]: __SYSQSTypes[__SYSQSubType_FIB],

    [__SYSQSubType_LBT]: __SYSQTypes[__SYSQTypesMapping[__SYSQSubType_LBT]]+' - '+__SYSQSTypes[__SYSQSubType_LBT],
    [__SYSQSubType_LBD]: __SYSQTypes[__SYSQTypesMapping[__SYSQSubType_LBD]]+' - '+__SYSQSTypes[__SYSQSubType_LBD],

    [__SYSQSubType_OEG]: __SYSQTypes[__SYSQTypesMapping[__SYSQSubType_OEG]]+' - '+__SYSQSTypes[__SYSQSubType_OEG],
    [__SYSQSubType_OEE]: __SYSQTypes[__SYSQTypesMapping[__SYSQSubType_OEE]]+' - '+__SYSQSTypes[__SYSQSubType_OEE],
    [__SYSQSubType_OED]: __SYSQTypes[__SYSQTypesMapping[__SYSQSubType_OED]]+' - '+__SYSQSTypes[__SYSQSubType_OED],

    [__SYSQSubType_POL]: __SYSQSTypes[__SYSQSubType_POL],
};
// Sub Sub Sub.........Typesss ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== 

export const _ATSysQSTypes = () => ( {...__SYSQSTypes} );
export const _ATSysQSTypeName = t => __SYSQSTypes[t];

export const __SYSQTSubCodes = [ __SYSQSubType_MCS,  __SYSQSubType_MCT, __SYSQSubType_FIB,
    __SYSQSubType_LBT, __SYSQSubType_LBD, __SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_OED, __SYSQSubType_POL];


//---------------------------------------------------------------------------

export const __SYSQTSS_MTC = 'MTC';
export const __SYSQTSS_MTR = 'MTR';
export const __SYSQTSS_MTL = 'MTL';

export const __SYSQTSSNames = {
    [__SYSQTSS_MTC]: 'Table CheckBox',
    [__SYSQTSS_MTR]: 'Table Radio',
    [__SYSQTSS_MTL]: 'Table List',
};

//----------------------------------------------------------------------------------------

export const __RESP_TXT = 't';
export const __RESP_DRAW = 'd';
export const __RESP_FILE = 'f';
export const __RESP_IMAGE = 'i';
export const __RESP_URL = 'h';
