import {Accordion} from "react-bootstrap";
import React from "react";

const CpAccordionItem = (props) => { // base on poc AccordionItem.tsx
    const {id, title, headerSize = "fs-6", children} = props

    return <Accordion.Item eventKey={id} className={``}>
        <Accordion.Header
            className={"semi-bold p-0 d-block"}>
            <span className={`${headerSize} m-0 px-2`}>{title}</span>
        </Accordion.Header>
        <Accordion.Body className={"p-0 flex-grow-1 overflow-hidden mb-3"}>
            {children}
        </Accordion.Body>
    </Accordion.Item>
};

export default CpAccordionItem;