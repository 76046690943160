import React, {useMemo, useEffect, useState} from "react";
import { aTErrDiv1, preJS, PreJSDiv } from "../../AppExPf/AppUtil";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { objEntries, objKeys, objMap, objVals, toAry, toInt, toObj } from "../../libs/libType";
import { stopEvent } from "../../libs/libUI";

import { CpSettingModalSection, CpSettingModalTitle
    ,CpSettingModalTabContainer, CpSettingModalText } from "../_components/CpSettingModalTabCps";

import { SIZE, CHECKBOX_VARIANT } from "../_components/CpExPFConst";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpDatePicker from "../_components/CpDatePicker";
import CpDropdown, { ary2DropdownItem, ent2DropdownItem, obj2DropdownItem } from "../_components/CpDropdown";
import CpCheckboxComponent from "../_components/CpCheckboxComponent";
import CpSwitchComponent from "../_components/CpSwitchComponent";
import { EPUITime3 } from "../_components/CpEpTabHead";
import { CpWarnBoxAsmMark } from "./TabTeacherAssignmentSettings";
import { timeStampNowGMT } from "../../libs/libTime";

const TabAssignmentSettingTime = (props => {
    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();
    const {vis, setField, fieldErrs} = props;
    
    const fs = toObj(props.fields);
    const Exer = toObj(props.Exer);  
    
    const {ELangEn, ELangCt, EModeScroll, EModeSlide, aQCnt, mQCnt, aScore, mScore} = Exer; 
    const hasA = (aQCnt || aScore); 
    const hasM = (mQCnt || mScore); 

    const [timeStart, setTimeStart] = [fs.timeStart, setField('timeStart')];
    const [time2beStart, setTime2beStart] =  [fs.time2beStart, setField('time2beStart')];
    const [timeEnd, setTodateTime] =  [fs.timeEnd, setField('timeEnd')];
    const [time2beEnd, setTime2beEnd] =  [fs.time2beEnd, setField('time2beEnd')];
    const [limitMin, setLimitMin] = [fs.limitMin, setField('limitMin')];
    const [minsToGo, setMinsToGo] =  [fs.minsToGo, setField('minsToGo')];
    const [attempt, setAttempt] = [fs.attempt, setField('attempt')];
    const [attTimes, setAttTimes] =  [fs.attTimes, setField('attTimes')];
    const [pubResults, setPublishResults] = [fs.pubResults, setField('pubResults')];
    const [showResults, setShowResults] = [fs.showResults, setField('showResults')];
    const [showScores, setShowScores] = [fs.showScores, setField('showScores')];

    const onlyA = hasA && (!hasM);
    const hasAM = hasA && hasM;
    const attMany = (attempt) && (toInt(attTimes) > 1);
    const att1 = (attempt) && (toInt(attTimes) <= 1);
    const isDie = timeEnd;
    const initTime = isDie? time2beEnd: 0; //timeStampNowGMT();

    const [canAutoPub, PubRSoptions] = useMemo(() => {
        const ents = [
            onlyA && isDie && ['dead', t("assignment-settings-options-deadline")], 
            onlyA && att1 && ['sub', t("assignment-settings-options-all-student-submitted")],
        ].filter(o => o);
        const canAuto = ents.length;
        return [canAuto, ent2DropdownItem(ents)];
    }, [isUIEn, onlyA, isDie, att1]);

    useEffect(() => { 
        if(!canAutoPub) {
            if(pubResults) setPublishResults(0);
        }else{
            if(!PubRSoptions[showResults]) setShowResults(objKeys(PubRSoptions)[0]);
        }
    }, [PubRSoptions]);

    const SCoptions = useMemo(() => ent2DropdownItem([
        ['pub', t("assignment-settings-options-all-result-published")],
        hasM && ['mark', t("assignment-settings-options-each-assignment-manually-marked")],
        onlyA && ['sub', t("assignment-settings-options-student-submission")], 
        onlyA && attMany && ['att', t("assignment-settings-options-all-attempts-completed")],
        onlyA && isDie && ['dead', t("assignment-settings-options-deadline")], 
    ].filter(o => o)), [isUIEn, onlyA, hasM, attMany, isDie]);

    useEffect(() => { 
        
        if(!SCoptions[showScores]) setShowScores(objKeys(SCoptions)[0]);
    }, [SCoptions]);

    const handleToDateTime = e => {  stopEvent(e);  setTodateTime(prev => !prev); };

    useEffect(()=>{ if(!limitMin){ setMinsToGo(''); } }, [limitMin]);
    useEffect(()=>{ if(!attempt){ setAttTimes(''); } }, [attempt]);

    const {Exer:de, isNew, edited, assignId, ALang, AMode, classId, studentIds, EQIds, AState, ...debfs} = fs;
    if(!vis) return '';
    return <CpSettingModalTabContainer submitting={0}>
                    {preJS({lang, ELangEn, ELangCt, EModeScroll, EModeSlide, hasA, hasM, onlyA, hasAM, att1, attMany, Tstr:EPUITime3(time2beStart), Tend:EPUITime3(initTime), isDie})}

        <CpSettingModalSection className={"flex-column flex-lg-row align-items-lg-center"}>
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-from")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column flex-md-row gap-3 gap-md-5 align-items-start align-items-md-center flex-wrap ms-3 ms-lg-0"}>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"}
                    onClick={() => setTimeStart(false)}>
                    <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={!timeStart} checkIcon={false}
                        className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-now")}</CpSettingModalText>
                </span>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"}
                      onClick={() => setTimeStart(true)}>
                    <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={timeStart}
                        checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpDatePicker {...{dateTime:(time2beStart), cb:setTime2beStart}} title={t("datepicker-select-a-day-time")}
                        iconPath={IconList.general.calendar} disable={!timeStart} showEmpty={!timeStart} />
                </span>
            </div>
        </CpSettingModalSection>
        <CpSettingModalSection className={"flex-column flex-lg-row align-items-lg-start"}>
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-to")}:</CpSettingModalTitle>
            <span className={"d-flex flex-column gap-2"}>
                 <span className={"d-flex gap-2 semi-bold user-select-none"} role={"button"} onClick={handleToDateTime}>
                    <CpSwitchComponent variant={"exercise-third-btn pe-none"} size={SIZE.XS} 
                        checked={timeEnd} label={t("assignment-settings-deadline")}/>
                </span>
                <span className={"d-flex"}>
                    <CpDatePicker {...{dateTime:(initTime), cb:setTime2beEnd, behind2min:(isDie?0:1)}} title={t("datepicker-select-a-day-time")} iconPath={IconList.general.calendar}
                        disable={!timeEnd} showEmpty={!timeEnd}/>
                </span>{aTErrDiv1(fieldErrs.endTimeXb4Str)}{aTErrDiv1(fieldErrs.endTimeXb4Now)}
            </span>
        </CpSettingModalSection>
        <CpSettingModalSection className={"flex-column flex-lg-row align-items-lg-center"}>
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-time-limit")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column flex-md-row gap-3 gap-md-5 align-items-start align-items-md-center flex-wrap ms-3 ms-lg-0"}>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={() => setLimitMin(prev => false)}>
                    <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={!limitMin} checkIcon={false}
                        className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-unlimited")}</CpSettingModalText>
                 </span>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={() => setLimitMin(prev => true)}>
                    <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={limitMin} checkIcon={false}
                        className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <input onChange={(e) => {setMinsToGo(e.currentTarget.value) }} value={minsToGo} type={"number"} min={0} className={`border ${limitMin ? "bg-white" : "bg-dim-100"}`}
                        style={{width: "4rem", height: "34px"}} disabled={!limitMin}/>
                    <CpSettingModalText>{t("assignment-settings-minutes")}</CpSettingModalText>
                 </span>
            </div>{aTErrDiv1(fieldErrs.limitMin)}{aTErrDiv1(fieldErrs.mins2Go)}
        </CpSettingModalSection>
        {hasM? '':
        <CpSettingModalSection className={"flex-column flex-lg-row align-items-lg-center"}>
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-no-of-attempt")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column flex-md-row gap-3 gap-md-5 align-items-start align-items-md-center flex-wrap ms-3 ms-lg-0"}>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={() => setAttempt(prev => false)}>
                    <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={!attempt} checkIcon={false}
                        className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-unlimited")}</CpSettingModalText>
                </span>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={() => setAttempt(prev => true)}>
                    <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={attempt} checkIcon={false}
                        className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <input onChange={(e) => {setAttTimes(e.currentTarget.value) }} value={attTimes} type={"number"} min={0} className={`border ${attempt ? "bg-white" : "bg-dim-100"}`} style={{width: "4rem", height: "34px"}} disabled={!attempt}/>
                    <CpSettingModalText>{t("assignment-settings-times")}</CpSettingModalText>
                </span>
            </div>{aTErrDiv1(fieldErrs.timesAtt)}{aTErrDiv1(fieldErrs.times2Att)}
        </CpSettingModalSection>
        }       
        <CpSettingModalSection className={"flex-column flex-lg-row align-items-lg-start"}>
            {hasA? <div className={"w-100"}><div className={"d-flex mw-100"}><CpWarnBoxAsmMark {...{hasM}} /></div></div> :""}
            <CpSettingModalTitle style={{width: "300px"}} className={"mt-0 mt-lg-2 semi-bold fs-6 user-select-none"}>
                    {t("assignment-settings-publish-all-results")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column gap-2 flex-wrap ms-3 ms-lg-0"}>
                {canAutoPub?
                <span role={"button"} className={"d-flex align-items-center gap-3"} onClick={() => setPublishResults(1)}>
                    <span>
                        <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={pubResults} checkIcon={false} 
                        className={"exercise-radiobox"} size={SIZE.XXXS} />
                    </span>
                    <span className={"d-flex flex-wrap align-items-center"}>
                        <span className={"text-nowrap me-3 user-select-none"}>{t("assignment-settings-automatically-after")}</span>
                        <span style={{width: "300px"}}>
                            <CpDropdown className="w100" {...{className:`${pubResults? "": "bg-dim-100 pe-none"}`,  items:PubRSoptions, idx:showResults, cb:setShowResults, minItem:1}} />
                        </span>
                    </span>
                 </span>:''}
                <span role={"button"} className={"d-flex align-items-center gap-3"} onClick={() => setPublishResults(0)}>
                    <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={!pubResults} checkIcon={false}
                        className={"exercise-radiobox " + (canAutoPub?"":" disable-option ")} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-manually")}</CpSettingModalText>
                 </span>
            </div>
        </CpSettingModalSection>
        <CpSettingModalSection className={"flex-column flex-lg-row align-items-lg-center"}>
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-display-scores-and-answers")}:</CpSettingModalTitle>
            <span className={"d-flex gap-3 align-items-center"}>
            {lang==="en"?<span className={"semi-bold user-select-none"}>{t("assignment-settings-after")}</span>:''}
                <span style={{width: "300px"}}><CpDropdown className="w100" {...{items:SCoptions, idx:showScores, cb:setShowScores, minItem:1}} /></span>
                {lang==="zh"?<span className={"semi-bold user-select-none"}>{t("assignment-settings-after")}</span>:''}
            </span>
            {preJS(debfs,3)}

        </CpSettingModalSection>
{/*
        <SettingModalSection className={"flex-column flex-lg-row align-items-lg-center"} hideBorder={true}>
            <SettingModalTitle style={{width: "300px"}}>{t("assignment-settings-display-answers-and-answer-keys")}:</SettingModalTitle>
            <span className={"d-flex gap-3 align-items-center"}>
                <span className={"semi-bold user-select-none"}>{t("assignment-settings-after")}</span>
                <span style={{width: "300px"}}><CpDropdown className="w100" items={ANSoptions} idx={showAnswers} cb={setShowAnswers}/></span>
            </span>
        </SettingModalSection>
*/}
        <div><br/><br/></div>
    </CpSettingModalTabContainer>;
});
export default TabAssignmentSettingTime;

export const filteredList = (source, factor) => {
    const selectedList=Object.keys(source)
        .filter(key => factor.includes(key))
        .reduce((obj, key) => {
            obj[key] = source[key];
            return obj;
        }, {});
    return selectedList;
}