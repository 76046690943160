import React, {useContext, useMemo, useRef, useEffect, useState } from 'react';
//import Dropdown from '../../poc/screens/pages/included/Dropdown';

import { _WebRoot, _ATRoot, _LibraryRoot, _ExerciseRoot, _CollectionRoot, _pathEcol } from '../../consts/ATConstReact';
 
import { useUILang } from '../../AppExPf/utils/useUILang';

import CpIco, { icoSrc } from '../_components/CpIco';
import SVG from 'react-inlinesvg';


import { _EPSECT_ecol, _EPSECT_lib, _EPSECT_my, _EPSECT_oup, _EPSECT_school } from '../AppRouteEP';

import useDetectOverflow from '../../poc/hooks/useDetectOverflow';
import { toObj, toStr } from '../../libs/libType';
import CpDropdown, { ent2DropdownItem } from './CpDropdown';
import { IconList } from '../../consts/ATIconListToUsePoc';


const CpStickyNavTab = (props => {
    const [t] = useUILang();
    const {items, active, handleActive, className, ...htmlProps} = props;
    const ref = useRef(null);
    
    const overflow = false;// {overflow} = useDetectOverflow(ref, "tab-item");
   
    const activeTitle = items.filter(item => item.id === active)[0]?.title || ""
    const containerStyle = `invlisible d-flex flex-nowrap gap-4 w-100 semi-bold fs-5 ${className} ` + (overflow ? "invisible h-0" : "")

    return <><div className="CpStickyNavTab position-relative w-100" style={{overflow: 'visible'}}>
        <div className={containerStyle} {...htmlProps} ref={ref}>
            {items.map( item => {
                const {id, title, className, iconPath,  badge} = item;
                const b = toObj(badge);
                const cur = active === id;
                const count = toStr(item.count);

                const icoCss = cur? (item.icoCss || 'bg-sidenav-primary'): "bg-dim-350";
                //const bcss = cur? (b.css || 'bg-sidenav-primary'): "bg-dim-350";
                const bcss = cur? (b.css || 'bg-tag-info'): "bg-dim-350";

                return <span id={id} key={id} role="button" onClick={() => { handleActive(id) }}  
                    className={`CpStickyNavTabItem tab-item pb-1 mt-1 d-flex align-items-center gap-2 flex-nowrap ${cur? "active": ""} ${toStr(className)}`} >
                    { iconPath? <span><CpIco src={iconPath}/></span>: ''}
                    { title? <span className={"text-nowrap"}>{t(title)}</span> : '' }
                    { count && <span className={`rounded-pill text-light tac ${icoCss}`} style={{minWidth: '24px'}}>{count}</span>}

                    { badge? <div className={`rounded-pill svg-icon ${bcss}`} >
                        { b.txt? <span className='text-light fs-7 px-2'>{b.txt}</span>: ''}
                        { b.ico && <span className='svg-icon' style={{color:'#fff'}}><CpIco src={b.ico} style={{margin:'2px', width:'18px', height:'18px'}} /></span> }
                    </div>: ''}
               </span>
            })}
        </div>
    </div></>;
/*
        {overflow && '' && <CpDropdown id="DDTab" {...{items:TabDDI, idx:tabDD, cb:setTabDD}} /> }
        {overflow && <>
            <div className='simple-dropdown'>
            <input className="dropdown-toggle" type="text" readOnly={true}/>
            <div className="dropdown-text overflow-hidden text-truncate"><span>{t(activeTitle)}</span></div>
            <ul className="dropdown-content">{items.map( item => { 
                const {id, title, css, ico} = item;
                const count = toStr(item.count);
                return <li id={id} key={id} role="button" onClick={() => handleActive(id) }
                    className={css}>
                    { ico? <span><CpIco src={ico}/></span>: '' }
                    { title? <span className={"text-nowrap"} >{t(title)}</span> : '' }
                    { count && <span className={`rounded-pill fs-7 px-2 ${active === id? "bg-sidenav-primary text-light": "bg-dim-350 text-light"}`}>{count}</span>}
                </li>;})
            }</ul>
            </div>
        </>}
*/
});
export default CpStickyNavTab;