import { useState, useMemo } from "react";
import { Collapse, } from "react-bootstrap";

import * as UI from '../../libs/libUI';

import { mapTMSets,} from "../../AppExPf/ATQtnEdit/PageATQtns";
import { QListMSTs } from "../../AppExPf/components/CpQViewExt";
import { _ATMetaTransByCode, _ATMetaTypeByCode } from "../../consts/ATMetaTypes";
import { apiCall_, asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { arrayAddOrDelete_ } from "../../libs/libArray";
import { objEntries, strCmp, toAry, toObj, toStr } from "../../libs/libType";

import CpActionButton from "../_components/CpActionButton";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { ReduxBind } from "../../saga/ReduxState";
import CpIco from "../_components/CpIco";
import { eqcnt, escore } from "../../AppExPf/ATESetEdit/TabESetExers";
import { langTxt } from "../EPLibrary/PageLibrary";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { toUniIdAry } from "../../consts/ATValidate";
import { autoId, jsStr } from "../../AppExPf/AppUtil";
import { timeStampNowGMT } from "../../libs/libTime";
import { strCmpTrimLow } from "../EPAssign/TabTeacherAssignmentStatus";
import { exerHasMark } from "../EPAssign/Tags/TagMarkingTag";
import CpMJX from "../_components/CpMJX";
import { splitSMId,  } from "../../consts/ATValidateMetaSet";


export const useExLikes = (dispatch) => {
    const [eLikes, setELikes] = useState([]);
    const loadELikes = async () => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getEColEIds', {bm:1});
        setELikes(toAry(res?.eLikes));
    };
    return { eLikes, setELikes, loadELikes};
};

export const isOUPCnewEx = (ex) => {
    const isOupEx = ex.schoolId? 0: 1;
    const isLess60d = timeStampNowGMT()-ex.dateMod < 1*1000*60*60*24;  // 60*1000*60*60*24;
    const isOUPCnewEx = isOupEx && isLess60d;
    
    return isOUPCnewEx;
};


export const CpExerciseCard = ReduxBind(props => { //base on poc <ExerciseCard /> 
    const userId = props.userId();// 'teacher'; //searchParams.get("role") || "teacher"
    const [ cache2d, useGetCache ] = useCaches(props);

    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const {dispatch, ESetId, tagMSets, useTagMSets, ctnL, eLikes, setELikes, setParaNew, setParaDel, reload, pickEx, cur, idx, mini, nano } = props;

    const ex = toObj(props.ex);
    const showEn = (props.showEn && ex.ELangEn)? 1: (ex.ELangCt? 0: 1); 
    const ct = (e, c) => langTxt(showEn, e, c);
    const title = ct(ex.ENameEn, ex.ENameCt);
    const EId = toStr(ex.EId);
    const isCur = (cur === idx);
    
    const isOupEx = ex.schoolId? 0: 1;
    const isMyEx = (ex.modUid === userId);
    const canDel = isMyEx;

    const bm = toAry(eLikes).includes(EId);
    const clickBME = async e => { UI.stopEvent(e);  
        const on = bm?0:1;
        apiCall_(dispatch, '/putEBookMark', ()=>{},  {EId, on});
        setELikes(ls => arrayAddOrDelete_(ls, EId, on));
    };
    
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(ex);
    const EQnum = eqcnt(ex);

    const [openMore, setOpenMore] = useState(false)
    
    const handleCollection = e => { UI.stopEvent(e); setParaNew({ESetId, EId}); }; //setModalTitle(t("collection")); 
    const handleTrash = e => { UI.stopEvent(e); setParaDel({EId, onDel:(()=>reload()) }); }; //setModalTitle(t("delete")); 
    const handleMore = e => { UI.stopEvent(e); setOpenMore(prev => !prev); };

    const thrNames = useGetCache('teachers', '*'); 
    const owner = exTeacherName(ex, thrNames, ut, t);
    const full = !mini;

    const isNewTag = isOUPCnewEx(ex);
    
    return <div role={"button"} onClick={e => { UI.stopEvent(e); pickEx(idx); } }
        className={`exercise-card d-flex flex-column w-100 rounded border border-2 ${
            (mini && !nano)? "px-3 py-1 p-md-2  justify-content-center align-items-center justify-content-md-between align-items-md-center": "p-2"} ${
            (isCur || nano)? "border-exercise-third-btn-hover bg-exercise-third-btn-hover-light": ""}`} >
        <div className={((full || nano)? "d-flex": "d-none d-md-flex")+" flex-row justify-content-between gap-2 w-100"}>
            <span className={"d-flex flex-column justify-content-between"}>
                <span className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn mt-1"}
                    style={{width: "1.5rem", height: "1.5rem"}}>
                   <CpIco src={ct(IconList.language.en, IconList.language.zh)}/>
                </span>
                {/*<BtnPopDev txt='ex' >{preJS({ex, QDate}, 3)}</BtnPopDev>*/}
                {isNewTag? <span className={"d-flex justify-content-center align-items-start rounded fs-4 text-light mt-1"} 
                    style={{width: "1.5rem", height: "1.5rem"}}>
                    <CpIco src={IconList.tagging.newTag}/>
                </span>:''}
            </span>
            <span className={"d-flex flex-column justify-content-between flex-grow-1"}>
                <span className={"mb-2"}>
                    <div className={"semi-bold d-flex justify-content-between align-items-start fs-6 mt-1 mb-2"}>
                        <span>{title}</span>
                        <span role={"button"} onClick={clickBME} 
                            className={`fs-5 px-1 card-favourite-btn ${bm? "active" : ""}`}>
                            <CpIco src={bm? IconList.general.likeFill: IconList.general.like}/>
                        </span>
                    </div>
                    {full && <>
                        <div className={"d-flex flex-column justify-content-start gap-1"}>
                            <span className={"d-flex flex-wrap justify-content-start align-items-center pe-1 fs-8"}>
                                <span className={"semi-bold pe-2"}>{t("questions")}:</span>
                                <span className={"me-1"}>{EQnum}</span>
                                <span>(<span
                                    className={"pe-2"}>{t("auto-marked")}: {aQCnt}</span> <span className={"text-nowrap"}>{t("manualmark")}: {mQCnt}</span>)</span>
                                {/*<span className={"fs-9 d-none d-md-flex"}>(<span
                                    className={"pe-2"}>{t("auto-marked")}: {-88}</span> <span>{t("manualmark")}: {-88}</span>)</span>*/}
                            </span>
                            <span className={"me-3 fs-8"}>
                                <span className={"semi-bold pe-2"}>{t("marks")}:</span><span>{escore(ex)}</span>
                            </span>
                        </div>
                        <span className={"d-flex flex-wrap justify-content-start align-items-center pe-1 fs-8"}>
                </span>
                    </>}
                </span>
                <div className={"d-flex flex-wrap-reverse overflow-hidden gap-0"}>
                    <div role={"button"} className={`sidenav-icon d-flex flex-row justify-content-start align-items-center gap-2 mx-0 mt-2 me-4`}>
                        <span className={`${isOupEx? "fs-5" :"fs-6"}`}><CpIco src={isOupEx? IconList.brand.oupc: IconList.general.avatar}/></span>
                        <span className={"title semi-bold fs-8"}>{owner}</span>
                    </div>
                   {/*} <span className={"mt-2 me-3 fs-8"}>
                        {isOupEx?  '': <><span className={"semi-bold pe-2"}>{t('assigned-times')}:</span>{0}</>}
                        </span>*/}
                </div>
            </span>
            {full &&
                <span className={"d-flex flex-column justify-content-between gap-3 fs-5 text-dim-400"}>
                <span className={"d-flex flex-column gap-2"}>
                    <span role={"button"} className={"margin-top-03 card-collection-btn"} onClick={handleCollection} ><CpIco src={IconList.general.collection}/></span>
                    {canDel && <span role={"button"} className={"card-trash-btn"} onClick={handleTrash} ><CpIco src={IconList.general.trash}/></span>}
                </span><span>
                     <span role={"button"} className={"card-more-btn"} onClick={handleMore} ><CpIco src={IconList.general.moreHorizontal}/></span>
                </span>
            </span>}
        </div>
        {(mini && !nano)?<div className={"d-flex d-md-none user-select-none justify-content-center align-items-center"}>
            <span className={"text-exercise-third-btn semi-bold"}> {idx+1}</span>
        </div>:''}
        <Collapse in={openMore}><div>
            <hr/>
            <CpEPTagMetas {...{ex, tagMSets, useTagMSets, ct, showEn}}/>
        </div>
        </Collapse>
    </div>;
});


export const CpEPTagMetas = (props => { //todo deeplink load
    const {ex, tagMSets, useTagMSets, ct, showEn} = props;
    //const EMeta1s = EMetas.map(m => m.metaSetId+','+m.metaId); 
    //const EMeta2s = EMetas.map(m => {const {metaSetId, metaId} = m; return  {metaSetId, metaId}; } ); 

    const SMIds = useMemo(() => {
        const {EMetaDisplays, EMSubjId} = ex;
        return toUniIdAry([...toAry(EMetaDisplays), EMSubjId]).map(splitSMId);
    }, [ex]);
    const MSIds = useMemo(() => toUniIdAry(SMIds.map( sm => sm[0])), [SMIds]);
    const tagmss = useTagMSets(MSIds);
    const [typeTags, SMId2d] = useMemo(() => mapTMSets(SMIds, tagmss), [SMIds, tagMSets]); 

    return <CpEPTagMSetsList {...{ct, showEn, typeTags, useTagMSets, CpTag:CpCardMetaTag }} />;
});

export const CpEPTagMetaList = (props => {
    const {msid, mids, useTagMSets, tagMSets, CpTag} = props;
    //console.log('CpEPTagMetaList', {msid, mids});

    const key = useMemo(() => autoId(), []);
    const MSets = useTagMSets([msid]);
    const ms = MSets?.[msid]?.metas;

    if(!ms) return '';
    const tags = toAry(mids).map(mid => [mid, ms[mid]]).filter(([i, m]) => (m && m.pub))
        .sort(([i, m], [j, n]) => (m.rnum - n.rnum) || strCmp(i, j))
        .map(([mid, meta]) => {
        return <CpTag  {...{key:msid+','+mid, id:msid+','+mid, ...props, msid, mid, meta}} /> 
    })
    return tags.length? <div key={key} {...{'data-js':jsStr(mids)}} className={"CpEPTagMetaList d-flex flex-wrap gap-2"}>{tags}</div>: ''; 
});

export const CpEPTagMSetsList = (props => {
    const {showEn, typeTags, useTagMSets, tagMSets, CpTag} = props;
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();

    //<div>{preJS({msid, mids, ms},3)}</div>
    //<tr><td colSpan={2}>{preJS({typeTags, showEn}, 3)}</td></tr>
    return <table className={"table-fit-column metadata-table p-3 w-100 fs-8"}><tbody className={"align-top"}>
        {QListMSTs.map(mst => [mst, typeTags[mst]]).filter(([k,v]) => v).map(([mst, mss]) => {
            const xs = objEntries(mss).map(([msid, mids]) => {
                return <CpEPTagMetaList key={msid} {...{...props, msid, mids}} />;
            });
            return xs && <tr key={mst} /*className="d-inline-block d-md-table-row"*/ > 
                <td className={"semi-bold" /*d-inline-block d-md-table-cell text-dim-400 */}>{t2(_ATMetaTransByCode(mst), showEn)}</td>
                <td>{xs}</td>
            </tr>}
        ).filter(x => x)}
    </tbody></table>
});

export const CpCardMetaTag = (props => {
    const {ct, meta, id} = props;
    const title = <CpMJX>{[toStr(meta.LVNum), toStr(ct(meta.nameEn, meta.nameCt))].filter(s=>s).join(' ')}</CpMJX>; //preJS(m);
    // EX-1274 remove hover effect
    return <CpActionButton {...{key:id, id, title}} className={"CpCardMetaTag badge-btn fs-9 semi-bold rounded pe-none "}/> 
    //return <CpActionButton key={msid+','+metaId} title={title} className={"badge-btn fs-9 semi-bold rounded"}/> 
});

export const exTeacherName = (ex, thrNames, ut, t) => {
    const isOupEx = ex.schoolId?0:1;
    if(isOupEx) return t('oupc-name'); 
    const tname = thr => (thr? (ut(thr.nameEng, thr.nameChi) || ex.modUid): '');  
    return tname(toObj(thrNames)[ex.modUid]);
};  

