import { deepCopy } from "../libs/libHtml";
import { isAry, isObj, isStr, objEntries } from "../libs/libType";
import { __RESP_TXT } from "./ATSysQType";
import { rmQtnMediaSrcs } from "./qtnMedias";
import {WIRIS_CFG_STR_v841, WIRIS_CFG_STR_v871, WIRIS_CFG_STR_latest} from './wirisConfig_consts';

const current_Wiris = WIRIS_CFG_STR_latest;

export const DO_PACK_Q = true;
export const DO_PACK_W = true;
export const DO_PACK_M = true;

export const unpackWork = work => {
    if((!DO_PACK_W) || !isObj(work)) return work;
    const w = deepCopy(work); // dont modifiy src
    if(isAry(w.resp)) { w.resp = w.resp.map(rr => {
        const {qresp, sequence, respType} = rr; 
        if (!sequence && qresp) { // sequence exist, mc table, ignore
            if (respType === __RESP_TXT && qresp.value) { // op, resp text
                rr.qresp.value = unpackQData(qresp.value);
            } else { // fib, lbd
                for (const [key, value] of objEntries(qresp)) {
                    rr.qresp[key] = unpackQData(value);
                };
            };
        }
       return rr;
    }); }
    return w;
};
export const packWork = work => {
    if((!DO_PACK_W) || !isObj(work)) return work;
    const w = deepCopy(work); // dont modifiy src
    if(isAry(w.resp)) { w.resp = w.resp.map(rr => { 
        const {qresp, sequence, respType} = rr; 
        if (!sequence && qresp) { // sequence exist, mc table, ignore
            if (respType === __RESP_TXT && qresp.value) { // op, resp text
                rr.qresp.value = packQData(qresp.value);
            } else { // fib, lbd
                for (const [key, value] of objEntries(qresp)) {
                    rr.qresp[key] = packQData(value);
                };
            };
        }
        return rr;
    }); }
    return w;
};

export const unpackMark = mark => {
    if((!DO_PACK_M) || !isObj(mark)) return mark;
    const m = deepCopy(mark); // dont modifiy src
    if (isAry(m.resp)) { // mark.resp[].comments
        m.resp.map( rr => {
            if (rr.comments) rr.comments = unpackQData(rr.comments);
            return rr;
        });
    };    
    return m;
};
export const packMark = mark => {
    if((!DO_PACK_M) || !isObj(mark)) return mark;
    const m = deepCopy(mark); // dont modifiy src
    if (isAry(m.resp)) { // mark.resp[].comments
        m.resp.map( rr => {
            if (rr.comments) rr.comments = packQData(rr.comments);
            return rr;
        });
    };
    return m;
};


export const unpackQtn = fullQ => {
    if((!DO_PACK_Q) || !isObj(fullQ)) return fullQ;
    const outQ = deepCopy(fullQ); // dont modifiy src
    return rmQtnMediaSrcs({...outQ, 
        QEn: unpackQLang(outQ, outQ.QEn),
        QCt: unpackQLang(outQ, outQ.QCt),
    }); 
};

export const packQtn = fullQ => { //todo combine rmQtnMediaSrcs()
    if((!DO_PACK_Q) || !isObj(fullQ)) return fullQ;
    const outQ = deepCopy(fullQ); // dont modifiy src
    return {...outQ, 
        QEn:  packQLang(outQ, outQ.QEn),
        QCt:  packQLang(outQ, outQ.QCt),
    }; 
}; 

const unpackQLang = (fullQ, QL) => {
    if(!isObj(QL)) return QL;
    const {QId, SQType, SQTGroup} = fullQ;
    const {qData, qAnswers, ansHints, AKText, tAnswers} = QL;
    const ret = {...QL}; 
    ret.qData = unpackQData(qData);
    ret.ansHints = unpackQData(ansHints);
    if(isAry(qAnswers)){ // qAnswers[].data
        ret.qAnswers = qAnswers.map(aa => {
            const {data, qvalue} = aa;
            const rr = {data, qvalue};
            if (data) rr.data = unpackQData(data);
            if (isAry(qvalue)) {
                rr.qvalue = qvalue.map(qq => {
                    const {data} = qq;
                    const r2 = {data};
                    if (data) r2.data = unpackQData(data);
                    return {...qq, data: r2.data};
                });
            };
            return {...aa, data: rr.data, qvalue: rr.qvalue};
        });        
    };
    if(isAry(tAnswers)){ // tAnswers[].[].data
        ret.tAnswers = tAnswers.map(tt => {
            const rr = {tt};
            if (isAry(tt)) {
                rr.tt = tt.map(cc => {
                    const {data} = cc;
                    const r2 = {data};
                    if (data) r2.data = unpackQData(data);
                    return {...cc, data: r2.data};
                });
            };
            return rr.tt;
        });
    };     
    if(isAry(AKText)){ // AKText[].text
        ret.AKText = AKText.map(aa => {
            const {text} = aa;
            const rr = {text};
            if (text) rr.text = unpackQData(text);
            return {...aa, text: rr.text};
        });
    };         
    return ret;
};

const packQLang = (fullQ, QL) => {
    if(!isObj(QL)) return QL;
    const {QId, SQType, SQTGroup} = fullQ;
    const {qData, qAnswers, ansHints, AKText, tAnswers} = QL;
    const ret = {...QL}; 
    ret.qData = packQData(qData);
    ret.ansHints = packQData(ansHints);
    if(isAry(qAnswers)){ // qAnswers[].data
        ret.qAnswers = qAnswers.map(aa => {
            const {data, qvalue} = aa;
            const rr = {data, qvalue};
            if (data) rr.data = packQData(data);
            if (isAry(qvalue)) {
                rr.qvalue = qvalue.map(qq => {
                    const {data} = qq;
                    const r2 = {data};
                    if (data) r2.data = packQData(data);
                    return {...qq, data: r2.data};
                });
            };
            return {...aa, data: rr.data, qvalue: rr.qvalue};
        });
    };
    if(isAry(tAnswers)){ // tAnswers[].[].data
        ret.tAnswers = tAnswers.map(tt => {
            const rr = {tt};
            if (isAry(tt)) {
                rr.tt = tt.map(cc => {
                    const {data} = cc;
                    const r2 = {data};
                    if (data) r2.data = packQData(data);
                    return {...cc, data: r2.data};
                });
            };
            return rr.tt;
        });
    };    
    if(isAry(AKText)){ // AKText[].text
        ret.AKText = AKText.map(aa => {
            const {text} = aa;
            const rr = {text};
            if (text) rr.text = packQData(text);
            return {...aa, text: rr.text};
        });
    };     
    return ret;
};

const packQData = qData => {  //if(Q.qData) Q.qData = Q.qData.replace(/\n\r|\n|\r\n/gi, '<br/>'); //to move to unpack/pack qbody
    if(!isStr(qData)) return qData;
    //return qData;
    return qData.replaceAll(WIRIS_CFG_STR_v841, WIRIS_CFG_STR_latest).replaceAll(WIRIS_CFG_STR_v871, WIRIS_CFG_STR_latest)
        .split(WIRIS_CFG_STR_latest);
};  

const unpackQData = qData => {
    if(!isAry(qData)) return qData;
    //console.log('unpackQData', qData);
    //return qData.join("");
    //return qData.join("").replaceAll("<semantics>","").replaceAll("</semantics>","");
    //return qData.join("").replaceAll("<semantics>","").replaceAll("</semantics>","").replaceAll("<annotation>","").replaceAll("</annotation>","");
    return qData.join("<annotation></annotation>");
    //return qData.join(current_Wiris);
};
