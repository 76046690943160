import React, {useMemo, useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import FileExtensionIcon from "../../AppExPf/components/CpFileExtensionIcon";
import CpIco from "../_components/CpIco";
import { langIsEn, useUILang } from "../../AppExPf/utils/useUILang";
import { preJS } from "../../AppExPf/AppUtil";
import { ReduxBind } from "../../saga/ReduxState";
import { asyncDL, useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { _WST_SUBMIT } from "../../consts/ATConstsAssignment";
import { toAry, toInt, toObj, toStr } from "../../libs/libType";
import { myNextPara } from "../EPAssign/PageAssignmentStudent";
import { prefixURL } from "../../AppExPf/ATExerEdit/ViewExCommon";
import { toIdStr } from "../../consts/ATValidate";
import { xapiPost } from "../EPLibrary/PageLibrary";
import CpMessageBox from "../_components/CpMessageBox";
import { IconList } from '../../consts/ATIconListToUsePoc';

const TabStudentAssignmentReportNextSteps = ReduxBind(props => {
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const { asm, Exer, work, mark, myNexts } = props;
    const { dispatch } = props;

    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);

    const showEn = langIsEn(asm.ALang); 
    const ct = (e, c) => showEn? (e||c): (c||e);
    const nextIsFile = n => (n.dl === 'f');
    const nextMediaId = n => (n.dl === 'f') && ct(n.mediaEn, n.mediaCt)
    
    const mediaIds = useMemo(()=> myNexts.map(nextMediaId).filter(i => i), [myNexts]);
    const medias = getMediaDLs(mediaIds);

    const items = useMemo(() => toAry(myNexts).map(n => {
        const mediaId = nextMediaId(n);
        const media = toObj( mediaId && medias[mediaId]);
        const isfile = nextIsFile(n); 
        return {
            title: ct(n.nameEn, n.nameCt),
            isFile: isfile,
            type: isfile? 'file': 'link',
            url: ct(n.lnkEn, n.lnkCt),
            mediaId, 
            extension: (isfile && media.ext) || '', 
        }
    }), [myNexts]);
    
    const asmId = toIdStr(asm?.assignId);
    const handleLink = (next) => {
        xapiPost(dispatch, 'xENext', {asmId, next}); //"attachmentType": "exercises",
        if(next.isFile){
            asyncDL(dispatch, getMediaDLs, next.mediaId);
        }else{
            window.open(prefixURL(toStr(next.url)), '_blank');
        };
    };
    return <>
        <Container fluid={true} className={"TabStudentAssignmentReportNextSteps fade-in-container mt-3 mb-5"}>
            <Row className="d-flex flex-column flex-lg-row align-items-stretch gap-2">
                <Col className="flex-grow-1">
                    <div className={"p-3"}>
                        {items.length?<>
                        <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3"}>
                            <h4 className={"m-0"}>{t("report-next-steps-resources")}</h4>
                        </div>
                        <div className={"d-flex gap-2 flex-wrap"}>
                            {items.map((item, idx) => 
                                <div className={"semi-bold d-flex align-items-center gap-2 border px-3 py-2 rounded-light btn btn-gray-exercise-third-btn"}
                                    key={idx} role={"button"} onClick={() => handleLink(item)}>
                                <span className={"fs-3"}>
                                {item.type === "file" ?
                                    <FileExtensionIcon name={item.extension}/>: <CpIco src={IconList.file.link}/>}
                                </span>
                                    <span className={"text-truncate"}>{item.title}</span>
                                </div>)}
                        </div></>:
                        <CpMessageBox item={{ mode:"warning", style:"icon",
                            content:{ title: t("warning.warning_no_follow_up_resources")},
                            iconPath: IconList.general.alert,
                        }}/>}
                    </div>
                </Col>
            </Row>
            {preJS(myNextPara(asm, Exer, work, mark))}
            {preJS(myNexts, 3)}
        </Container>
    </>
});
export default TabStudentAssignmentReportNextSteps;

const Xitems = [{
    url: "",
    type: "link",
    title: "Exercise remedial materials"
},{
    url: "",
    extension: "pdf",
    type: "file",
    title: "Revision tutorial for Chapter 1"
}
]
