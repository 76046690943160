import React, { useRef, useEffect, useState, useMemo } from 'react';
import YouTube from 'react-youtube';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import CpMediaUI from './CpMediaUI';
import { toInt, toNum, toStr } from '../../../libs/libType';
//import './media.css';

const mediaPatterns = {
    // 5 = youtube video id
    'youtube': ['^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$'],
    // 2 = userId, 3 = player theme id, 4 = video id
    'brightcove': ['^((?:https?:)?\\/\\/)?players.brightcove.net\\/(\\w+)\\/(\\w+)_default\\/index\\.html\\?videoId=(\\w+)'],
    // 0 = url, 2 = extension
    //'html': ['^(https|http):?\\/\\/.*(mp4|mp3)$'],
    'html': ['^(https|http):?\\/\\/*'],
};
const extractMediaInfo = (type, url) => {
    //const regex = new RegExp(`${item.regex}`, 'm')
    if (type==='html' && toStr(url).startsWith('blob:')) { return {input:url}};
    const regex = new RegExp(mediaPatterns[type], 'm');
    return regex.exec(url);
};

export const HTMLPlayer = (props) => {
    const {id, url, onSuccess, mode, isAudio, noCheck=false, mini=0, idx, setPlayed} = props;
    const [playTo, setPlayTo] = useState(0);
    const [canRender, setCanRender] = useState(true);
    // html player volume: 0 to 1
    // playbackrate: default 1
    const [vol, setVol] = useState(1); // 1
    const [pRate, setpRate] = useState(1); // 1
    const [dur, setDur] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(false);
    const myRef = useRef(null);
    const info = extractMediaInfo('html', url);
    const myid = 'htmlplayer' + idx;
    
    const updateMediaData = () => {
        const ref = myRef && myRef.current;
        if (ref) { setDur(ref.duration); };
    };

    const updatePlayTime = () => {
        const ref = myRef && myRef.current;
        if (ref) { setPlayTo(ref.currentTime); };
    };    

    const stop = () => {
        if (myRef.current) {
            myRef.current.pause();
            myRef.current.currentTime = 0;
            setPlaying(false);          
        };
    };

    const onerror = (e) => { console.error(e); };
    const onplay = (e) => { setPlayed && setPlayed(idx); };

    useEffect(() => {
        if ( myRef && myRef.current ) {
            if (onSuccess) onSuccess(myRef.current, 'html'+id);
            myRef.current.ontimeupdate = updatePlayTime;
            myRef.current.onloadedmetadata = updateMediaData;
            myRef.current.onended = stop;
            myRef.current.onerror = onerror;
            myRef.current.onplay = onplay;
        } else { console.error('html player not ready !') };
    }, []);

    useEffect(()=>{
        if(myRef && myRef.current){
            myRef.current.src = info?.input;
        };
    },[url]);

    //if (!info) return <div></div>;

    const play = () => {
        const ref = myRef.current;
        if (ref) { 
            if (playing) {
                pause();
                setPlaying(false);
            } else {
                ref.play();
                setPlaying(true);
            };
        };
    };

    const pause = () => {
        if (myRef.current) myRef.current.pause();
    };

    const seekTo = (e) => {
        const value = parseFloat(e.target.value);
        
        const ref = myRef.current;
        if (ref) ref.currentTime = value;
    };

    const setPlayerMuted = () => {
        const ref = myRef.current;
        if (ref) {
            ref.muted = !ref.muted
            setMuted(!muted);
        };  
    };

    const isMuted = () => {
        if (myRef.current) return myRef.current.muted;
    };
    
    const setVolume = (e) => {
        const value = parseFloat(e.target.value)/100;
        
        if (myRef.current) myRef.current.volume=value;
        setVol(value);
    };

    const setSpeedRate = (e) => {
        const value = parseFloat(e.target.value)/100;
        
        if (myRef.current) myRef.current.playbackRate=value;
        setpRate(value);
    };

    const isPlay = () => { if (myRef.current) return !myRef.current.paused; };
    const getCurrentTime = () => { if (myRef.current) return myRef.current.currentTime; };
    const getDuration = () => { if (myRef.current) return myRef.current.duration; };

    const myPlayerUI = (mode) => {
        return <CpMediaUI id={id} play={play} pause={pause} stop={stop} mini={mini}
            isMuted={isMuted()} seekTo={seekTo} setSpeedRate={setSpeedRate} 
            setVolume={setVolume} setMuted={setPlayerMuted} playing={playing}
            currentTime={playTo} speedRate={pRate*100} volume={Math.round(vol*100)} duration={dur}
            mode={mode}
        />;
    };
    
    //audio/x-m4a  mpeg
    return <div key={'html'+id} className='flexColCenter'>
        <div key={'html2'+id} className='htmlPlayerContainer' style={{display:(mode==='video')?'flex':'none'}}>
            {isAudio?
            <audio id={myid} ref={myRef} preload="metadata" playsInline> 
                <source src={info?.input} type="audio/mp3"/>
                <source src={info?.input} type="audio/mp4"/>
                <source src={info?.input} type="audio/aac"/>
                <source src={info?.input} type="audio/x-m4a"/>
            </audio>:            
            <video id={myid} ref={myRef} preload="metadata" width={"100%"} controls playsInline>
                <source src={info?.input} type="video/mp4"/>
            </video>}
        </div>
        {mode==='audioPlayer'?myPlayerUI('player'):''}
        {mode==='audioButton'?myPlayerUI('button'):''}
    </div>;
};

    /*
    return <div key={'html'+id} className='flexColCenter'>
    <div key={'html2'+id} className='htmlPlayerContainer' style={{display:(mode==='video')?'flex':'none'}}>
        {info[2]==='mp4'?
        <video ref={myRef} preload="metadata" width={"100%"} controls>
            <source src={info[0]} type="video/mp4"/>
        </video>:
        <audio ref={myRef} preload="metadata">
            <source src={info[0]} type="audio/mp3"/>
        </audio>}
    </div>
    {mode==='audioPlayer'?myPlayerUI('player'):''}
    {mode==='audioButton'?myPlayerUI('button'):''}       
    </div>;    
    */

const youtubeOpts = {
    //height: '100%', //width: '100%',
    playerVars: { autoplay: 0,
        // https://developers.google.com/youtube/player_parameters
    },
};

//player.addEventListener(event:String, listener:String):Void
//player.removeEventListener(event:String, listener:String):Void
//onStateChange 1-playing
export const YTPlayer = (props) => {    
    //const {videoId, opts, onReady, id} = props;
    const {id, url, onSuccess, mode, mini = 0, idx, setPlayed} = props;    
    const myRef = useRef(null);
    useEffect(() => {
        if (window.YT) {
            myRef.current = new window.YT.Player('ytif' + id, {
                events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
                }
            });
        };
    },[]);

    const onPlayerReady = e => {};
    const onPlayerStateChange = e => { if (e?.data === 1) { setPlayed && setPlayed(idx); }; };
    const info = extractMediaInfo('youtube', url);   

    if (!info) return <div></div>;

    //https://developers.google.com/youtube/iframe_api_reference#Events
    return <div key={'yt'+id} className='flexColCenter' >
        <div key={'yt2'+id} className='youtubeContainer' style={{display: (mode === 'video'? 'flex': 'none')}}>
        <div style={{position: 'relative', paddingBottom: '100%', height: 0, paddingBottom: '56.2493%'}}>
            <iframe id={'ytif'+id} src={"https://www.youtube.com/embed/"+info[5]+"?enablejsapi=1&html5=1&playsinline=1"}
                style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0}}
                frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen referrerPolicy="strict-origin-when-cross-origin">
            </iframe>
        </div>
        </div>
    </div>;
};

const youtubeOpts2 = {
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        enablejsapi: 1,
        autoplay: 0,
        html5: 1,
        playsinline: 1
    },
};

export const YTPlayer2 = (props) => {    
    //const {videoId, opts, onReady, id} = props;
    const {id, url, onSuccess, mode, mini=0, idx, setPlayed} = props;
    const [playTo, setPlayTo] = useState(0);
    //youtube volume: 0 to 100,default 100
    // playbackrate: 0 to 2,default 1
    const [vol, setVol] = useState(100);
    const [pRate, setpRate] = useState(1);
    const [dur, setDur] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(false);    
    const [timer, setTimer] = useState(null);

    const contRef = useRef(null);
    const myRef = useRef(null);
    const forcePlayFlag = useRef(false);

    const info = extractMediaInfo('youtube', url);
    
    useEffect(()=>{
        if (contRef.current) {
            
            const _iframe = contRef.current?.firstChild?.firstChild;
            if (_iframe) {
                _iframe.setAttribute('referrerPolicy','strict-origin-when-cross-origin');
            };
        };
        return ()=>{
            if (timer) clearInterval(timer);
        };
    },[]);

    if (!info) return <div></div>;

    const updatePlayTime = () => {
        const ref = myRef && myRef.current;
        if (ref && ref.getPlayerState() === 1) {
            setPlayTo(ref.getCurrentTime());
        };
    };

    const _onReady = (e) => {
        if (onSuccess) onSuccess(e.target, 'yt'+id);
        myRef.current = e.target;
        // force play and stop, to get the duration value
        //myRef.current.playVideo();
        //myRef.current.stopVideo();
        forceFirstPlay();

        setTimer(setInterval(()=>updatePlayTime(),500));
        
        //    myRef.current.getPlaybackRate());
    };

/*
    getPlayerState():
    -1 – unstarted
    0 – ended
    1 – playing
    2 – paused
    3 – buffering
    5 – video cued
*/
    const forceFirstPlay = () => {
        if (!forcePlayFlag.current) {
            myRef.current.setVolume(0);
            myRef.current.playVideo();
            //myRef.current.mute();
            forcePlayFlag.current=true;
        };
    };

    const _onStateChange = (e) => {
        
        if (forcePlayFlag.current && e.data === 1) {
            myRef.current.stopVideo();
            //myRef.current.unMute();
            myRef.current.setVolume(100);
            //setVol(100);
            forcePlayFlag.current = false;
            updateDuration();
            myRef.current.onStateChange = undefined;
        };
        /*
        if ((e.data === 5 || e.data === 1) && dur === 0) {
            updateDuration();
        };
        */
    }; 

    const updateDuration = () => setDur(toNum(myRef.current.getDuration(), 0));

    const play = () => {
        const ref = myRef.current;
        if (!ref) return;
        if (playing) {
            pause();
        } else {
            ref.playVideo();
            !forcePlayFlag.current && setPlayed && setPlayed(idx);
        };
        setPlaying(!playing);
    };

    const stop = () => {
        if (!myRef.current) return;
        myRef.current.stopVideo();
        setPlaying(false);
    };    

    const pause = () => { if (myRef.current) myRef.current.pauseVideo(); };    

    const seekTo = (e) => {
        const value = parseFloat(e.target.value);
        const ref = myRef.current;
        if (ref) ref.seekTo(value);
        const state = ref.getPlayerState();
        if (state !== 1) setPlayTo(value);
    };    

    const setPlayerMuted = () => {
        if (myRef.current.isMuted()) {
            myRef.current.unMute();
            setMuted(false);
        } else {
            myRef.current.mute();
            setMuted(true);
        };
    };    
    const isMuted = () => { if (myRef.current) return myRef.current.isMuted(); };

    const setVolume = (e) => {
        const value = parseFloat(e.target.value);
        if (myRef.current) myRef.current.setVolume(value);
        setVol(value);
    };    

    const setSpeedRate = (e) => {
        const value = parseFloat(e.target.value)/100;
        if (myRef.current) myRef.current.setPlaybackRate(value);
        setpRate(value);
    };

    const isPlay = () => { if (myRef.current) return myRef.current.getPlayerState() === 1; };
    const getCurrentTime = () => { if (myRef.current) return myRef.current.getCurrentTime(); };
    const getDuration = () => { if (myRef.current) return myRef.current.getDuration(); };    

    const myPlayerUI = (mode) => {
        return <CpMediaUI id={id} play={play} pause={pause} stop={stop} mini={mini}
            isMuted={isMuted()} seekTo={seekTo} setSpeedRate={setSpeedRate} 
            setVolume={setVolume} setMuted={setPlayerMuted} playing={playing}
            currentTime={playTo} speedRate={pRate*100} volume={Math.round(vol)} duration={dur}
            mode={mode}
        />;
    };

    //https://developers.google.com/youtube/iframe_api_reference#Events
    return <div key={'yt'+id} className='flexColCenter' >
        <div ref={contRef} key={'yt2'+id} className='youtubeContainer' style={{display:(mode==='video'?'flex':'none')}}>
        <YouTube videoId={info[5]} opts={youtubeOpts2} onReady={_onReady}
            onStateChange={_onStateChange}
            />
        </div>
        {mode==='audioPlayer'?myPlayerUI('player'):''}
        {mode==='audioButton'?myPlayerUI('button'):''}
    </div>;
};

//https://player.support.brightcove.com/styling/responsive-sizing-brightcove-player.html    
export const BCPlayer = (props) => {
    const {id, url, onSuccess, mode, mini=0, idx, setPlayed} = props;
    const [playTo, setPlayTo] = useState(0);
    const [vol, setVol] = useState(1);
    const [pRate, setpRate] = useState(1);
    const [dur, setDur] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(false);

    const myRef = useRef(null);
    
    // 2 = userId, 3 = player theme id, 4 = video id
    const info = extractMediaInfo('brightcove', url);

    const updatePlayTime = () => {
        const ref = myRef && myRef.current;
        if (ref) { setPlayTo(ref.currentTime()); };        
    };

    const updateDuration = () => {
        let dur = myRef.current.duration();
        dur = isNaN(dur) ? 0 : dur;
        
        setDur(dur);
    };    

    useEffect( () => {
        return () => {
            if (!myRef.current) return;
            myRef.current.off('timeupdate',updatePlayTime);
            myRef.current.off('play',BCPlay);
            myRef.current.off('canplay',updateDuration);
            myRef.current.off('loadedmetadata',updateDuration);                
        };
    },[])

    const _onSuccess = (e) => {
        if (onSuccess) onSuccess(e.ref, 'bc'+id);
        myRef.current = e.ref;
        myRef.current.on('timeupdate',updatePlayTime);
        myRef.current.on('play',BCPlay);
        myRef.current.on('canplay',updateDuration);
        myRef.current.on('loadedmetadata',updateDuration);    
        
    };

    const BCPlay = () => { setPlayed && setPlayed(idx); };

    const play = () => {
        const ref = myRef.current;
        if (ref) {
            if (playing) {
                pause();
                setPlaying(false);
            } else {
                ref.play();
                setPlaying(true);
            };
        };
    };

    const stop = () => {
        if (myRef.current) {
            myRef.current.pause();
            myRef.current.seekTo(0);
            setPlaying(false);
        };
    };

    const pause = () => { if (myRef.current) myRef.current.pause(); };
    const seekTo = (e) => { if (myRef.current) myRef.current.currentTime(parseFloat(e.target.value)); };

    const setPlayerMuted = () => {
        if(!myRef.current) return;
        myRef.current.muted(!myRef.current.muted());
        setMuted(!muted);
    };

    const isMuted = () => { if (myRef.current) return myRef.current.muted(); };

    const setVolume = (e) => {
        const value = parseFloat(e.target.value)/100;
        if (myRef.current) myRef.current.volume(value);
        setVol(value);
    };

    const setSpeedRate = (e) => {
        const value = parseFloat(e.target.value)/100;
        if (myRef.current) myRef.current.playbackRate(value);
        setpRate(value);
    };

    const isPlay = () => { if (myRef.current) return !myRef.current.paused(); };
    const getCurrentTime = () => { if (myRef.current) return myRef.current.currentTime(); };
    const getDuration = () => { if (myRef.current) return myRef.current.duration(); };

    const myPlayerUI = (mode) => {
        return <CpMediaUI id={id} play={play} pause={pause} stop={stop} mini={mini}
            isMuted={isMuted()} seekTo={seekTo} setSpeedRate={setSpeedRate} 
            setVolume={setVolume} setMuted={setPlayerMuted} playing={playing}
            currentTime={playTo} speedRate={pRate*100} volume={Math.round(vol*100)} duration={dur}
            mode={mode}
        />;
    };

    if (!info) return <div></div>;
    return <div key={'bc'+id} className='flexColCenter'>
        <div key={'bc2'+id} className='flexColCenter' style={{display:(mode==='video'?'flex':'none'),
            width:'100%', backgroundColor:'green'}}>
            <ReactPlayerLoader accountId={info[2]} videoId={info[4]} themeId={3}
            embedOptions={{ responsive: true}} onSuccess={_onSuccess} options={{playsinline:true}}
            attrs={{className:'brightCoveContainer'}}/>
        </div>
        {mode==='audioPlayer'?myPlayerUI('player'):''}
        {mode==='audioButton'?myPlayerUI('button'):''}
    </div>;
};

/*
    const {id, play, pause, stop, isPlaying, isMuted,
        seekTo, setSpeedRate, setVolume,
        currentTime, speedRate, volume, duration}

        switch (media.provider) {
            case STREAMPROVIDER.YOUTUBE:
                return {
                    onStateChange: isPlay,
                    onReady: durationUpdate
                }
                break;
            case STREAMPROVIDER.BRIGHTCOVE:
                return {
                    playing: isPlay,
                    pause: isPlay,
                    // stop: isPlay,
                    ended: isPlay,
                    seeking: isSeeking,
                    seeked: isSeekend,
                    timeupdate: timeUpdate,
                    canplay: durationUpdate,
                    loadedmetadata: durationUpdate,
                }
                break;
            case STREAMPROVIDER.LOCAL:
            default:
                return {
                    playing: isPlay,
                    pause: isPlay,
                    stop: isPlay,
                    ended: isPlay,
                    seeking: isSeeking,
                    seeked: isSeekend,
                    timeupdate: timeUpdate,
                    canplay: durationUpdate,
                    canplaythrough: durationUpdate
                }
                break;
        }        
*/