import {useMemo} from "react";
import {Button, ButtonGroup} from "react-bootstrap";
import { SIZE } from "./CpExPFConst";
import ActionButtonComponent from "./CpActionButtonComponent";
import { stopEvent } from "../../libs/libUI";

const CpBadgeComponent = (props) => { // base on poc BadgeComponent.tsx
    const {children, className, variant = "", title, remove = "", size = SIZE.MD, ...badgeProps} = props

    const handleChildClick = (e) => stopEvent(e);;

    const badgeStyle = useMemo(() => {
        return [ "py-0", "fs-8", "text-badge",
            className].filter(item => item).join(' ')
    }, []);

    if (remove === "") {
        return <Button variant={variant} className={badgeStyle} {...badgeProps}>
            {title}
            {children}
        </Button>
    };

    return <ButtonGroup className={"mw-100"}>
        <ActionButtonComponent size={SIZE.XS} title={title} variant={variant} className={`pe-none pe-1 ${badgeStyle}`}/>
        <div className={"vr"}/>
        <ActionButtonComponent size={SIZE.XS} variant={variant} className={`px-1 ${badgeStyle}`}
                               onClick={handleChildClick} {...badgeProps}>
            {remove}
        </ActionButtonComponent>
    </ButtonGroup>
};

export default CpBadgeComponent;