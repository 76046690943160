import {useTranslation} from "react-i18next";
import {HTMLProps, useContext} from "react";
import ActionButton from "../../ActionButton";
import {IconList} from "../../../../../static/icons/IconList";
import {PageActionKind, PageContext} from "../../../../../state/PageProvider";
import {PAGE} from "../../../../../type/Page";
import {StudentAssignment, TeacherAssignment} from "../../../../../type/Assignment";
import {AssignmentActionKind, AssignmentContext} from "../../../../../state/AssignmentProvider";

interface Props extends HTMLProps<HTMLSpanElement> {
    item?: TeacherAssignment | StudentAssignment
}

const DetailsCTA = (props: Props) => {
    const {t} = useTranslation(['schema']);
    const {item, ...btnProps} = props
    const {state, dispatch} = useContext(PageContext)
    const {state: assignmentState, dispatch: assignmentDispatch} = useContext(AssignmentContext)

    const handleEnterDetail = () => {
        assignmentDispatch({type: AssignmentActionKind.INIT_ASSIGNMENT, payload: item})
        dispatch({type: PageActionKind.UPDATE_PAGE, payload: PAGE.ASSIGNMENT_EDIT_PAGE})
    }

    return <ActionButton title={t("assignment-cta-details")} iconPath={IconList.assignment.details}
                         className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
                         onClick={() => handleEnterDetail()} {...btnProps}>
    </ActionButton>
}

export default DetailsCTA