import React, { useEffect, useState, useRef } from 'react';

import { isStr, objEntries, objKeys, str2Blob, toAry, toInt, toObj, toStr } from '../../libs/libType';
import { apiCallLoad_, asyncApiCallLoad_, asyncApiCall_ } from '../../libs/awsFuncs';
import { timeStampNowGMT } from '../../libs/libTime';
import { useStateGST } from '../../saga/globalState.saga';
import { HttpGet, HttpPostMP } from '../../libs/libHttp';
import { downloadUrl } from '../../libs/libDownload';
import { loading_add } from '../../saga/loading.saga';
import { toUniIdAry } from '../../consts/ATValidate';
import { addIfMiss } from './useTagMSetCache';
import { fileExt } from '../../libs/libFormat';
import { autoId } from '../AppUtil';
import { Buffer } from 'buffer';
import { extToMime } from '../../consts/MimeTypes';
import axios from 'axios';


const _GLOBAL_DRAWS = {};

const appendM = (qs, news, que) => {
    const ids = Array.from(new Set([...que, ...objKeys({...qs, ...news})]));
    const mix = Object.fromEntries(ids.map(id => [id, {...toObj(qs[id]), ...toObj(news[id]) }] ));
    return mix;
};
const markDLExp = (mediaDLs) => {
    const ex = timeStampNowGMT() + (60 * 55 * 1000);
    //return Object.fromEntries(Object.entries(toObj(mediaDLs)).map( ([id, dl]) => [id, {dl, ex}])); //Object.fromEntries( toAry(res?.mediaDLs).map( m  => [m.mediaId, m]));
    return Object.fromEntries(objEntries(mediaDLs).map( ([id, m]) => [id, {...m, ex}] ));
};

export const useMediaCacheEffect = (props, dispatch) => {
    const [ mediaDLs, setMediaDLs ] = useStateGST(props, 'MediaCache', {});
    const [que, setQue] = useStateGST(props, 'MediaQue', []);
    const [load, setLoad] = useState(0);

    const onLoad = (res, err, que) => {
        const newm = markDLExp(res?.mediaDLs);
        const loaded = [...que, ...Object.keys({...mediaDLs, ...newm})];
        setMediaDLs(ms => appendM(ms, newm, que));
        setQue(qu => toAry(qu).filter(id => !loaded.includes(id)));
        setLoad(0);
    };
    useEffect(()=>{
        if(!load && que.length){
            setLoad(1); 
            apiCallLoad_(dispatch, '/getATMediaDLs', (res, err) => onLoad(res, err, que), { mediaIds:que, });
        };
    }, [toUniIdAry(que).join(','), load]);
};

export const draw2Blob = (mediaId, drawData) => {
    const raw = drawData;
    const blob = str2Blob(drawData);//rr?.qresp);
    const file = new File([blob], mediaId, {type: "text/html"}); 
    console.log('draw2Blob', {mediaId, fileSize: file.size, blobSize: blob.size});
    const ret ={
        mediaId,
        file,
        size: blob.size,
        name: mediaId,
        ext: 'draw',
        raw, 
    };
    _GLOBAL_DRAWS[mediaId] = raw;
    return ret;
};

const file2LocalMedia = file => {
    const {name, size} = file;
    return {
        mediaId:'*LM_'+autoId()+'_'+name, 
        file, size, name, ext:fileExt(name), 
        dl:URL.createObjectURL(file), 
    };
};
export const useMediaCache = (props, dispatch, isAT) => {
    const [localDLs, setLocalDLs] = useStateGST(props, 'MediaCacheLocal', {});
    const [ mediaDLs, setMediaDLs ] = useStateGST(props, 'MediaCache', {});
    const [que, setQue] = useStateGST(props, 'MediaQue', []);

    const addLocalMedias = (filesOrDrawBlobs, isDraw) => {
        const addEnt = isDraw 
            ?objEntries(filesOrDrawBlobs)
            :toAry(filesOrDrawBlobs).map(file => {
                const m = file2LocalMedia(file);
                return [m.mediaId, m];
            });

        const ret = Object.fromEntries(addEnt);
        if(!addEnt.length) return {};

if(isDraw) console.log('addLocalMedias()', {isDraw}, objKeys(ret));
        Object.assign(localDLs, ret) //hack setState ASAP
        Object.assign(mediaDLs, ret) //hack setState ASAP
        setLocalDLs(ms => ({...toObj(ms), ...ret}));
        setMediaDLs(ms => ({...toObj(ms), ...ret}));
        return ret;
    };

    const getMediaDLs = mediaIds => {
        const hits = mediaIds.map(id => mediaDLs[id]).filter(m => m);
        _queMiss(mediaIds, hits);
        return Object.fromEntries(hits.map(m => [m.mediaId, m]));
    };
    const _queMiss = (needs, hits) => {
        const now = timeStampNowGMT();
        const liveIdSet = new Set(hits.filter(m => m.ex > now).map(m => m.mediaId));
        const miss = needs.filter(id => (id && (!isLocalMedia(id)) && (!liveIdSet.has(id)) && (!que.includes(id))));
        if(miss.length){
            setQue(q => addIfMiss(q, miss));
        } 
    };
    return [ mediaDLs, getMediaDLs, localDLs, addLocalMedias];
};

export const mediaAryToObj = ms => Object.fromEntries(toAry(ms).map(m => [m.mediaId, m]));

export const mediaUpFile = async (dispatch, getFields, file, loadFlag='MediaUp') => {
    if(!file) 
        return;
    const up = async () => {
        try{
            const {name, size/*, path, type*/} = file;
            const apiFields = { name, ...getFields, size};

            const [r1, e1] = await asyncApiCall_(dispatch, '/getATMediaUplink', apiFields );  
            const {mediaId, mediaLink} = toObj(r1);
            const {url, fields} = mediaLink||{}; 

            if(!(mediaId && mediaLink && url && fields)){
                console.error({r1, e1});
                return '';
            }
            const delay = (process.env.REACT_APP_CFG === 'local');
            if(delay){
                console.error('mediaUpFile() : delay for debug!!!', {mediaId, name} );
                await new Promise((res,rej) => { setTimeout(()=>{res();}, 5000)});
            }
            const resUp = await HttpPostMP(url, false, fields, {file});//, onUpProgress);
    
            const [r2, e2] = await asyncApiCall_(dispatch, '/putATMediaUpDone', {mediaId} );  
            if(e2) console.error({r2, e2});

            return mediaId;
        }catch(e){
            console.error(e);
        }
        return '';
    };

    loadFlag && dispatch(loading_add(loadFlag, 1)); 
    const upResult = await up();
    loadFlag && dispatch(loading_add(loadFlag, -1));
    return upResult;
};

const mediaUpData = async (dispatch, getFields, filename, buf, loadFlag='MediaUp') => {
    if(!(filename && buf)) 
        return;
    const up = async () => {
        try{
            const [name, size/*, path, type*/] = [filename, buf.length + 1];
            const apiFields = { name, size, ...getFields};
   
            const [r1, e1] = await asyncApiCall_(dispatch, '/getATMediaUplink', apiFields );  
            const {mediaId, mediaLink} = toObj(r1);
            const {url, fields} = mediaLink||{}; 
            
            if(!(mediaId && mediaLink && url && fields)){
                console.error({r1, e1});
                return '';
            }
            const resUp = await HttpPostMP(url, false, fields, {file:buf});//, onUpProgress);
            
    
            const [r2, e2] = await asyncApiCall_(dispatch, '/putATMediaUpDone', {mediaId} );  
            if(e2) console.error({r2, e2});

            return mediaId;
        }catch(e){
            console.error(e);
        }
        return '';
    };

    loadFlag && dispatch(loading_add(loadFlag, 1)); 
    const upResult = await up();
    loadFlag && dispatch(loading_add(loadFlag, -1));
    return upResult;
};

export const localDrawId = idbody => 'draw_'+toStr(idbody)+'_'+autoId()+'.draw';

const isLocalPfx = s => s.startsWith('*LM_') || s.startsWith('draw_');
export const isLocalMedia = id => isStr(id) && isLocalPfx(toStr(id).trim());

export const asyncGetDraw = async (dispatch, getMediaDLs, drawId)  => {
    const raw = _GLOBAL_DRAWS[drawId];
    if(raw){
        //console.log('asyncGetDraw GLOBAL', {drawId, raw:debugTrimDraw(raw)});
        return raw;
    } 
    const obj = await asyncDL(dispatch, getMediaDLs, drawId, 1);
    //console.log('asyncGetDraw', drawId, obj);
    return obj && JSON.stringify(obj);
};    


export const fetchBase64 = async (src) => {
    const response = await fetch(
        src,//`https://www.wiris.net/demo/editor/render.svg`,
        {
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            //responseType: 'json'
        }
    )
    console.log('_fetch',{response});
    const ab = await response.arrayBuffer();
    const bb = Buffer.from(ab, 'binary');
    const s64 = bb.toString('base64');
    return s64;
};
export const web2Base64 = async (src) => {
    const response = await axios.get(src, {
        responseType: 'arraybuffer', 
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json, text/plain, */*',
            'Cache-Control': 'no-cache',
        },
    });
    console.log('web2Base64',{response});
    const ab = await response.data; //arrayBuffer();
    const bb = Buffer.from(ab, 'binary');
    const s64 = bb.toString('base64');
    return s64;
};

export const s3toImgSrc = async (dispatch, mediaId, getMediaDLs) => {
    if(isLocalMedia(mediaId) && getMediaDLs){
        //console.warn('todo: LocalId & getData', mediaId);
        const f = getMediaDLs([mediaId])[mediaId];
        //console.log('getMediaDLs', f);
        return toStr(f?.raw);
    };

    const [res, err] = await asyncApiCallLoad_(dispatch, '/getATMediaDLs', { mediaIds:[mediaId], fname:1, isDirect:1 });
    const m = toObj(res?.mediaDLs)[mediaId];
    const {dl, mime, ext, fname} = m;
    console.log(m);
    
    const s64 = await fetchBase64(dl);
    //const s64 = await web2Base64(dl);
    
    //return 'data:'+mime+';base64,'+s64;
    return 'data:'+extToMime(toStr(m.ext).trim().toLowerCase())+';base64,'+s64;
    
};

export const asyncDL = async (dispatch, getMediaDLs, mediaId, getData) =>{
    if(isLocalMedia(mediaId) && getData){
        //console.warn('todo: LocalId & getData', mediaId);
        const f = getMediaDLs([mediaId])[mediaId];
        //console.log('getMediaDLs', f);
        return toStr(f?.raw);
    };
    const getM = async () => {
        console.log('asyncDL getM', {mediaId, getRespData: getData, isLocal:isLocalMedia(mediaId)});
        if(isLocalMedia(mediaId)){
            return getMediaDLs([mediaId])[mediaId];
        }
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getATMediaDLs', { mediaIds:[mediaId], fname:1, isDirect:1 });
        const m = toObj(res?.mediaDLs)[mediaId]; 
        err && console.error(err);
        (!m) && console.error(res);
        return m; 
    };

    const m = await getM();
    const url = m?.dl;
    if(url){
        if (getData) {
            return await getMediaData(url);
            //return await dataURI2UTF8(url);
        } else {
            downloadUrl(url, 0, toStr(m.name).trim() || 1);
        };
    }else{
        alert('Donwload File Error: ' + mediaId);
    }
};
export const getMediaData = async s3URL => {
    //{ withCredentials: false }
    //const resp = await HttpGet(s3URL,false,{},{},0,{ withCredentials: false });
    const resp = await HttpGet(s3URL, false);
    console.log('getMediaData', {resp});
    if (resp) return resp.data;
};

const dataURI2UTF8 = async (url) => {
    //https://developer.mozilla.org/en-US/docs/Web/API/Response/text
    try {
        const res = await fetch(url);
        const txt = await res.text(); // default UTF-8
        //const blob = await res.blob();
        //const txt = await blob.text(); // default UTF-8
        return txt;
    } catch (err) {
        console.error('dataURI2UTF8:', err);
    };
};