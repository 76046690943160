import React, { useState, useEffect } from 'react';
import * as UI from "../../libs/libUI";
import { svgIcon2 } from '../components/CpATIcoBtn';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';

/*
    opts: [
        {id:"", jsx:<div>text</div>},
        ...
    ]
    dropdown
    {svgIcon2(id+",DDImgIcon","general/dragCusHorizontal","white")}
*/

const CpATPullDownMenu = (props) => {
    // sel = Q.fieldName
    // setSel = setQ("fieldName")
    const { sel, setSel, opts, headerClass, containerClass, right, enable,
        menuStyle, headerStyle, containerStyle, rowStyle, arrowColor} = props;
    const [show, setShow] = useState(false);
    //const [myID, setMyID] = useState("pMenu"+timeStampNowGMT());
    const getHead = () => {
        const xx = opts.find((opt, ii) => {return opt.id === sel});
        return xx ? xx.jsx(true, true) : opts[0].jsx(true, true);
    };

    const onBlur = (e) => {
        UI.stopEvent(e);
        
        setShow(false);
    };

    const optClick = (e, id) => {
        UI.stopEvent(e);
        
        setSel(id);
        setShow(false);
    };

    const clickShow = (e) => {
        UI.stopEvent(e);
        setShow(!show);
    };
    
    return <>
        <div className='ATPDMenu f14 ' tabIndex={0} onBlur={onBlur} style={menuStyle?menuStyle:{}}>
            <div className={'clickable ATPDHeader gap-2 ' + (show?' show ':'') + (headerClass?headerClass:"")} onClick={clickShow}
                 style={headerStyle?headerStyle:{}}>
                {getHead()}<CpIco src={IconList.general.dropdown} width="0.6em" />
            </div>
            {show && (opts && opts.length > 0) && <div className={'ATPDContainer' + (right?"Right ":"Left ") + (containerClass?containerClass:"")}
                style={containerStyle?containerStyle:{}}>
                {opts.map((opt, ii) => {
                    return <div key={"opt" + opt.id + ii} className='ATPDrow clickable' 
                    onClick={(e)=>optClick(e, opt.id)} style={rowStyle?rowStyle:{}}>{opt.jsx()}</div>
                })}
            </div>}
        </div>
    </>;
};
/*
    {getHead()}{svgIcon2("general/dropdown",arrowColor?arrowColor:"#2995cd",{width:"10px"})}

        <div className={'clickable ATPDHeader'+(show?'Show':'')} onClick={setShow(!show)}>
            {getHead()}
        </div>
        {false && show && (opts && opts.length > 0) && <div className='ATPDContainer'>
            {opts.map((opt, ii)=>{
                return <div id={"opt"+opt.id+ii} className='ATPDrow' onClick={()=>{}}>{opt.jsx}</div>
            })}
        </div>}
*/
export default CpATPullDownMenu;