import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import { toAry, toObj, toStr } from '../../../libs/libType';
import { extToMime, __MediaTypes } from '../../../consts/MimeTypes';

const mediaIcon = '<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="m2.11,6.16l-.04-.92c0-1.17.95-2.12,2.12-2.12h3.89c.56,0,1.1.22,1.5.62l.88.88c.4.4.93.62,1.5.62h4.21c1.17,0,2.12.95,2.12,2.12,0,.06,0,.13,0,.19l-.67,7.41c-.1,1.09-1.01,1.92-2.11,1.92H4.53c-1.09,0-2.01-.83-2.11-1.92l-.67-7.41c-.05-.49.08-.98.36-1.39h0Zm1.75.14c-.58,0-1.06.47-1.06,1.06,0,.03,0,.06,0,.1l.67,7.41c.05.54.51.96,1.05.96h10.95c.55,0,1-.42,1.05-.96l.67-7.41c.05-.58-.38-1.1-.96-1.15-.03,0-.06,0-.1,0H3.85Zm4.96-1.81c-.2-.2-.47-.31-.75-.31h-3.89c-.58,0-1.05.46-1.06,1.04v.15c.23-.08.48-.13.73-.13h5.71l-.75-.75h0Z"/><path d="m12.96,11.53l-4.32,2.51c-.37.21-.84-.04-.84-.47v-5.01c0-.43.47-.69.84-.47l4.32,2.51c.26.15.35.48.2.74-.05.08-.12.15-.2.2Z"/></svg>';

const addVideo = (editor,id,url,mime,isVideo) => {
    if (!editor) return;
    editor.model.change((writer) => {
        const modelFragment = _getMediaModel(editor,id,url,mime,isVideo);
        editor.model.insertContent( modelFragment, editor.model.document.selection );
    });
};

const _getMediaModel = (editor,id,url,mime,isVideo) => {
    const tagName = (isVideo?'video':'audio');
    const viewFragment = editor.data.processor.toView(
        '<' + tagName + ' width="600" preload="metadata" controls' + (isVideo?'="" ':'')
        +' class="full-width" data-id="12345"'
        +' oupid="' + toStr(id) + '" '
        +' style="width: 1600px;max-width: 100%" playsinline >'
        +' <source src="' + toStr(url) +'" type="'+ mime +'"/>'
        +' Your browser does not support HTML '+tagName+'.'
        +' </'+tagName+'>');
    const modelFragment = editor.data.toModel(viewFragment);    
    return modelFragment;
};

export default class CpCkInsertMedia extends Plugin {
    static get pluginName() {
        return 'CpCkInsertMedia';
    };    

    init() {
        const editor = this.editor;
        const { ui: { componentFactory }, } = editor;
        const config = toObj(editor.config.get('CpCkInsertMedia'));
        const setOnAddMedia = config.setOnAddMedia;
        const uit = config.uit;
        const showEn = config.showEn;
        this.requestMediaPoolUpdate = false;
        this.updatedURL = [];        

        if (typeof config.updateMediaID === 'function') {
            this.updateMediaID = config.updateMediaID;
        };

        componentFactory.add( 'CpCkInsertMedia', locale => {
            const view = new ButtonView( locale );

            view.set( {
                //label: uit('exercise.content.image.media-pool'), //'Media Pool',
                label: showEn?'Insert local video/audio':'新增本機影片/音訊',
                icon: mediaIcon,
                tooltip: true
            } );

            view.on("execute", () => {
                //const pp = editor.model.document.selection.getFirstPosition();
                setOnAddMedia && setOnAddMedia({onAdd:(medias => {
                    const ms = toAry(medias);
                    if (ms.length) {
                        editor.model.change(writer => {
                            ms.forEach(m => {
                                
                                const src = toStr(m.dl);
                                const mediaId = toStr(m.mediaId);
                                const mime = extToMime(m.ext);
                                //const name = m.name;
                                const isVideo = __MediaTypes.video.includes(m.ext);
                                addVideo(editor, mediaId, src, mime, isVideo);
                            });
                        });
                    }
                    setOnAddMedia(0);
                }), maxMedia:10, mimes:['video','audio']});
                editor.focus();
            });
            return view;            
        });  
        
        this.editor.model.document.on('change:data', event => {
            this.onChange();
        },{ priority: 'high' });        
    };

    updateMedia(mds = {}) {
        this.onChange(mds ? mds : {});
    };

    onChange(retmds = []) {
        this.editor.model.change(writer => {
            const root = this.editor.model.document.getRoot();
            const range = writer.createRangeIn(root);
            let pEle = [];
            let mdIDarr = [];

            //htmlAttributes
            //htmlAudioAttributes
            //htmlVideoAttributes
            for (const value of range.getWalker()) {
                if ((value.item.name === 'htmlAudio' || value.item.name === 'htmlVideo') && value.type === 'elementStart') {
                    pEle.push({ item: value.item, type: value.item.name });
                    const cid = value.item.getAttribute(attType[value.item.name]).attributes.oupid;
                    if (cid && !this.updatedURL[cid]) {
                        this.updatedURL[cid]=false;
                        mdIDarr.push(cid);
                    };
                };
            };

            if (pEle.length === 0) return;
            const allDone = Object.keys(this.updatedURL).findIndex((id)=>{return !this.updatedURL[id]}) < 0;
            if (allDone) return;
            const _updateMedia = (mds={}) => {
                if (mds && Object.keys(mds).length > 0) { 
                    pEle.forEach((obj, ii) => {
                        const id = obj.item.getAttribute(attType[obj.type]).attributes.oupid;
                        let pp;
                        const parentisP = obj.item.parent.name==='paragraph';
                        if (parentisP) // <p><video>
                            pp = writer.createPositionBefore(obj.item.parent);
                        else pp = writer.createPositionBefore(obj.item); 
                        const mediaInfo = mds[id];
                        if (mediaInfo) {
                            const isVideo = (obj.type==='htmlVideo')?1:0;
                            const mime = extToMime(mediaInfo.ext);
                            const newContent = ' <source src="' + toStr(mediaInfo.dl) +'" type="'+ mime +'"/>'
                                +' replace Your browser does not support HTML '+(isVideo?'video':'audio')+'.';
                            this.updatedURL[id]=true;
                            writer.setAttribute('htmlContent', newContent, obj.item);
                            const modelFragment = _getMediaModel(this.editor,id,toStr(mediaInfo.dl),mime,isVideo);
                            if (parentisP) writer.remove(obj.item.parent);
                                else writer.remove(obj.item);
                            writer.insert(modelFragment, pp);                            
                        };
                    });
                    pEle = [];
                };
            };

            if (!this.requestMediaPoolUpdate && this.updateMediaID) {
                const result = this.updateMediaID([...mdIDarr]);
                _updateMedia(result);
                this.requestMediaPoolUpdate = true;
            };

            _updateMedia(retmds);
        });
    };    
};

const attType = {
    'htmlAudio':'htmlAudioAttributes',
    'htmlVideo':'htmlVideoAttributes',
};