import { put, takeLeading, takeLatest /*, call, all, takeEvery */ } from 'redux-saga/effects'

import { tryParseJson, toStr, isAry, toObj } from '../libs/libType';

import libSessionId from '../libs/libSessionId';

import { apiSaga } from './api.saga';
import { loading_set, loading_unset } from './loading.saga';

// ==== ==== Types; Type of Dispatch/Put Actions ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== 
const AUTH_LOGIN = 'AUTH_LOGIN'; //command
const AUTH_LOGOUT = 'AUTH_LOGOUT'; //command
const AUTH_LOGIN_STATE = 'AUTH_LOGIN_STATE'; //event (share logout_state)

// ==== ==== reducer; to update State per action ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
const keys = ['sessionId', 'uid', 'user', 'ATUserId', 'ATUser', 'entitle', 'signedOut', 
    'maintenanceMode', 'maintenanceId', 'maintenanceMsg'];//, 'loginErrs'];
const map = ['sessionId', 'uid', 'user',  'ATUserId', 'ATUser', 'entitle', 'signedOut', 
    'maintenanceMode', 'maintenanceId', 'maintenanceMsg'];//, 'errs'];
const keyDeps = {
    uid: 'user', //basic Authen
    ATUserId: 'ATUser', //back end (Authoring Tool ) Authen
};
const initState = {sessionId:'', uid:'', user:0, ATUserId:'', ATUser:0};
export const authReducer = (state = initState, action) => {
    switch (action.type) {
        case AUTH_LOGIN_STATE:
            //lj({'authReducer':action});
            const delta = {};    
            const auth = toObj(action.auth);
            const res = action.res||{};
            libSessionId.save(toStr(auth.sessionId));
            keys.forEach( (k, i) => { if (k in auth) delta[map[i]] = auth[k]; } );
            if (Object.keys(delta).length === 0)
                return state; // Not Change
            delta.maintenanceMsg = split_maintenanceMsg(delta.maintenanceMsg);
            //lj({authRdr:delta});
            Object.keys(keyDeps).forEach(key => {
                if(!delta[key]){ //if Key not found
                    delta[key] = false; // clear Key
                    delta[keyDeps[key]] = false;// clear dep Key  
                }else if(delta[key] !== state[key]){
                    delta[keyDeps[key]] = res[keyDeps[key]] || false; // force dep key renew
                }
            });
            //lj({authRdr:delta});
            //lj({delta});
            return {...state, ...delta};
        default:
            return state;
    }
};

// ==== ==== action; to be dispatch() By UI / put() by Services ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const auth_login = (loginId, password) => { return { type: AUTH_LOGIN, loginId, password }; };
export const auth_logout = (/*reason*/) => { return { type: AUTH_LOGOUT, }; };
export const auth_login_state = (auth, res={}) => { return { type:AUTH_LOGIN_STATE, auth, res }; };

// ==== ==== saga; watcher saga watch for action / action saga call() service, put() action= === ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export function* watchAuthSaga() {
    yield takeLeading(AUTH_LOGIN, authLoginSaga); //takeLeading: no new login, until current is complete
    yield takeLatest(AUTH_LOGOUT, authLogoutSaga);
};

export function* authLoginSaga(action) {
    yield put(loading_set('login', 1));
    const noAws = 1;
    if(noAws){
        yield put(auth_login_state({
            sessionId: 'sid1234', uid: 'u'+action.loginId,
            maintenanceMode: 0, maintenanceMsg: '', maintenanceId: 0,
        }, {
            user: 'u'+action.loginId,
        } ));
        yield put(loading_unset('login'));
        return;
    }
    
    /*const { res, err } = */ yield apiSaga('/dummyLogin', 0, {l:action.loginId, p:action.password}, {}, );
    
    //No need to handle login , api_call always auto handle login/logout
    //if(res) yield put(auth_login_state());
    //if(err) yield put(auth_login_state());

    yield put(loading_unset('login'));
};

export function* authLogoutSaga(action) {
    yield put(loading_set('logout', 1));
    
    /*const { res, err } =*/ yield apiSaga('/logout', 0, {}, {}, );
    
    //yield put(auth_login_state());
    //if(res) yield put(auth_login_state(sa));
    yield put(loading_unset('logout'));
};

// ==== ==== supporting services: to be call by saga ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
const split_maintenanceMsg = (msg) => {
    if(!msg) return ['',''];
    const a = tryParseJson(msg);
    const [isA, msgStr] = [isAry(a), toStr(msg)];
    return [((isA && toStr(a[0])) || msgStr), ((isA && toStr(a[1])) || msgStr)];
};

export const expfRoles = props => { const r = props?._saga?.auth?.user?.role; return [r === 'teacher', r === 'student', r]; };




