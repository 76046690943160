import React, { useEffect, useState, useMemo } from 'react';

import { apiCallLoad_ } from '../../libs/awsFuncs';
import { errMsg } from '../../libs/libFormat';
import { toAry, toObj, toStr, toUniAry } from '../../libs/libType';
import * as UI from '../../libs/libUI';

import { ReduxBind } from '../../saga/ReduxState';
import { ATErrsDiv, ATErrsDiv2, aTErrTxt1, aTUIMust, BtnPopDev, clickConfirm, clickUrl, preJS } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';
import { QP_P, QP_D, langCodeEn } from '../../consts/ATConsts'
import { urlPush_Replace } from '../../saga/urlPush.saga';
import { popAlert } from '../components/CpPopup';
import { cpATIcoBtn_ } from '../components/CpATIcoBtn';
import CpTabHead from '../components/CpTabHead';
import { ATDo_DelE, ATDo_EDraftR2P, ATDo_EPub, ATDo_EUnpub, _ATCanDo } from '../../consts/ATRoleRights';
import CpDraftPubBtns, { PubDraftStates } from '../components/CpDraftPubBtns';
import { detectIsNew, normalizeEdit, useEditDraftPub, useLangView } from '../utils/useEditDraftPub';
import TabExerSetting, { mkEQSumFake } from './TabExerSetting';
import TabExerContent from './TabExerContent';
import {TabExerNextStep} from './TabExerNextStep';
import { exerUniQIds, normalizedEditExer, validEditExer, validPubExer } from '../../consts/ATValidateExer';
import { makeClickSavePub, ViewSetLang } from '../ATQtnEdit/PageATQtnEdit';
import { useMediaCache } from '../utils/useMediaCache';
import { useQtnCache } from '../utils/useQtnCache';
import LayerMediaPool from '../ATMediaPool/LayerMediaPool';
import { fixeECnts  } from '../../consts/ATValidateEcnts';
import { mRows2CanShows, mSets2Rows } from '../components/CpMetaTickList';
import { useTagMSetCache } from '../utils/useTagMSetCache';
import { useUILang } from '../utils/useUILang';

const draftMetaOpts = (draftMetaSets, draftQMs, draftQMPres, draftQMPros) => {
  const draftMSetRows = mSets2Rows(draftMetaSets);
  const draftMetaCanShows = mRows2CanShows(draftMSetRows); 
  const draftQMRows = mSets2Rows(draftQMs);
  const draftQMPreRows = mSets2Rows(draftQMPres);
  const draftQMProRows = mSets2Rows(draftQMPros);
  return {draftMSetRows, draftMetaCanShows, draftQMRows, draftQMPreRows, draftQMProRows };
}; 

const backPath = _ATRoot + 'exercises';
const PageATExerEdit = ReduxBind( props => {
  const { dispatch } = props;
  const [t] = useUILang();
  const role = toStr(props?._saga?.auth?.ATUser?.ATRole);

  const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts, draft, setDraft, pub, setPub] = useEditDraftPub();
  const setESubjId = setTickAry('ESubjIds');
  const setDisplay = setTickAry('EMetaDisplays');

  const [isNew, EId] = detectIsNew(props.match?.params, pub, draft, 'EId');
  useEffect(() => { EId && dispatch(urlPush_Replace( _ATRoot + 'exercise/edit/'+EId)) }, [EId]);

  const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 1);
  const [onAddMedia, setOnAddMedia] = useState();

  const [qtns, useCacheQtns, addNewQtn, ] = useQtnCache(dispatch, 1);
  const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 

  const [LSel, setLSel] = useState(isNew);

  const [tab, setTab] = useState(0);

  
  // then Choose Pub/Draft 
  const [view, setView] = useState(QP_P);
  const {hasDraft, saved, r2p, pubed, unpubed, showPub, lock } = PubDraftStates(view, draft, pub, 'QVer'); 
  const ex = showPub ? pub : fields;
  
  const uniQIds = useMemo(() => exerUniQIds(ex), [ex]);
  useCacheQtns(uniQIds);
  const EQSum = useMemo(() => mkEQSumFake(ex, qtns), [ex, qtns]);

  const [lang, setLang, clickLang, showEn, hasEn, hasCt, safeLang] = useLangView(ex?.ELangEn, ex?.ELangCt, langCodeEn);

  const safeSetView = v => UI.stopEventThen(e => setView((!pub)? QP_D: (!hasDraft)? QP_P: v));

  const onLoad = (res, err) => {
    if (err) popAlert(dispatch, 0, errMsg(err));
    const { draftQMs, draftQMPres, draftQMPros, pubQMs, pubQMPres, pubQMPros, draftMetaSets, pubMetaSets, draft, pub, fieldErrs, ATSubjects, mySjIds } = toObj(res);
    setFetchedDraft(draft);
    setPub(fixeECnts(pub))
    const [ pubMSetRows, pubQMRows, pubQMPreRows, pubQMProRows ] = [mSets2Rows(pubMetaSets), mSets2Rows(pubQMs), mSets2Rows(pubQMPres), mSets2Rows(pubQMPros)];
    setOpts({ pubMSetRows, pubQMRows, pubQMPreRows, pubQMProRows, ATSubjects, mySjIds, ...draftMetaOpts(draftMetaSets, draftQMs, draftQMPres, draftQMPros) });
    setFieldErrs(fieldErrs);
    setView(v => ((!pub)? QP_D: (!draft)? QP_P: v));
  };
  const onLoadDraft = (res, err) => {
    if (err) popAlert(dispatch, 0, errMsg(err));
    if (res) {
      const { draftQMs, draftQMPres, draftQMPros, draftMetaSets, draft, } = toObj(res);
      const d = setFetchedDraft(draft);
      setOpts(opts => ({ ...toObj(opts), ...draftMetaOpts(draftMetaSets, draftQMs, draftQMPres, draftQMPros)}) );
      setFieldErrs({});
      setView(d? QP_D: QP_P);
    }
  };
  const setFetchedDraft = draft => {
    const fixDraft = fixeECnts(draft);
    setDraft(fixDraft);
    setFields(toATExDraft(fixDraft));
    return fixDraft;
  };

  const back = () => dispatch(urlPush_Replace(backPath));
  const onDelete = (res, err) => { back(); }
  
  useEffect(() => { apiCallLoad_(dispatch, '/getATExer', onLoad, { EId, isNew }); }, []);

  const onPutExDraft = (res, err) => {
    if ((res?.state) === 'ok') {
      back(); //onLoad(res, err);
    } if ((res?.state) === 'retry') {
      const {fields:fs, fieldErrs:fes} = toObj(res);
      if (fs) setFields(fs);
      if (fes) setFieldErrs(fes);
    } else {
      back();
    }
  };
 
   //const normalizePV = (fs) => normalizeEdit(fields, {...opts, qtns}, [], normalizedEditExer, validEditExer, setFields, setFieldErrs, fs);
   const normalize = () => normalizeEdit(fields, {...opts, qtns}, [], normalizedEditExer, validEditExer, setFields, setFieldErrs);
   const save = (doPub, doR2p, fields) => apiCallLoad_(dispatch, '/putATExerDraft', onPutExDraft, { fields, doPub, doR2p });
  const clickSaveATExer = makeClickSavePub(dispatch, normalize, save);

  const letClone = pub; //_ATCanDo(role, ATDo_QClone) 
	const letR2P = _ATCanDo(role, ATDo_EDraftR2P);
  const letPub = _ATCanDo(role, ATDo_EPub);
	const letUnpub = _ATCanDo(role, ATDo_EUnpub);
	const letDelete = _ATCanDo(role, ATDo_DelE);

  const clickEndExEd = clickUrl(dispatch, backPath);
  const clickClone = letClone && pub && UI.stopEventThen(e => apiCallLoad_(dispatch, '/getATExerDraft', onLoadDraft, { EId }));
  const clickPublish = letPub && (!hasDraft) && unpubed && clickConfirm(dispatch, 'Confirm Publish', () => apiCallLoad_(dispatch, '/putATExersPublish', onLoad, { EId }));
  const clickSavePublish = letPub && hasDraft && clickSaveATExer(1, 0); 
	const clickR2P = letR2P && hasDraft && (!r2p) && clickSaveATExer(0, 1);
  const clickUnpublish = letUnpub && pubed && clickConfirm(dispatch, 'Confirm Unpublish', () => apiCallLoad_(dispatch, '/putATExersUnpublish', onLoad, { EId }));
  const clickDelete = letDelete && hasDraft && clickConfirm(dispatch, 'Confirm Delete', () => isNew? back(): apiCallLoad_(dispatch, '/deleteExers', onDelete, { EId }));

  const tapTab = i => UI.stopEventThen(e => setTab(i));
  const tabKey = (showPub?'Pu':'Dr')+showEn;

  const tabProps = {ex, draft, pub, fieldErrs, setFieldErrs, fields, setFields, setField, setTick, setESubjId, setDisplay, opts, EQSum, setOpts, 
    mediaDLs, getMediaDLs, onAddMedia, setOnAddMedia, addLocalMedias, qtns, useCacheQtns, tagMSets, useTagMSets, showEn, hasEn, hasCt, clickLang, showPub, lock, isAT:1,
    normalize};

  
  return <div className="adminQuesTop" >
    <div className="f16">Exercises / Exercise / {isNew ? 'New' : 'Edit'}</div>
    {ATErrsDiv2(fieldErrs,'',t)}
    {'' && preJS({showPub, lock, showEn, hasEn, hasCt, hasDraft, isNew, saved, r2p, pubed, LSel, })}
    
    {LSel ? <ViewSetLang {...{ fields, setTick, ekey: 'ELangEn', ckey: 'ELangCt', setLSel, clickBack: clickEndExEd }} /> : <>
      <div className="adminToolsContainer"><div style={{display:"flex"}}>
        <div className="adminTools1">
        {showPub ? <>
          {letPub && cpATIcoBtn_('general/publish', 'Publish', clickPublish)}
          {letUnpub && cpATIcoBtn_('general/unpublish', 'Unpublish', clickUnpublish)}
          {letClone && cpATIcoBtn_('general/copy', 'Duplicate as Draft', clickClone)}
        </> : <>
          {cpATIcoBtn_('general/save', 'Save', clickSaveATExer(0, 0))}
          {cpATIcoBtn_('general/cancel', 'Cancel', clickEndExEd)}
          {letDelete && cpATIcoBtn_('general/delete', 'Delete', clickDelete)}
          {letR2P && cpATIcoBtn_('general/publish', 'Save & Request', clickR2P)}
          {letPub && cpATIcoBtn_('general/publish', 'Save & Publish', clickSavePublish)}
        </>}          
        </div>
      <div className="adminTools2"><CpDraftPubBtns {...{saved, draft, showPub, pub, click:safeSetView, verKey:'EVer' }} /></div>
    </div></div>
    {showPub? '': aTErrTxt1(fieldErrs?.QLang)}
    {CpTabHead(tab, tapTab, [<>Settings {aTUIMust}</>, <>Content {aTUIMust}</>, <>Next Step {aTUIMust}</>], <>
      <BtnPopDev txt='ex'>{preJS(ex,3)}</BtnPopDev>
      <BtnPopDev txt='fields'>{preJS(fields,3)}</BtnPopDev>
      <BtnPopDev txt='opts'>{preJS(opts,3)}</BtnPopDev>
    </>)}
    {
      (tab===0)? <TabExerSetting key={tabKey} {...tabProps} />:
      (tab===1)? <TabExerContent key={tabKey} {...tabProps} />:
      <TabExerNextStep key={tabKey} {...tabProps} />
    }
    </>}
    <LayerMediaPool {...onAddMedia} clickClose={()=>setOnAddMedia(0)} {...{setOnAddMedia, mediaDLs, getMediaDLs}} />
  </div>;
});
export default PageATExerEdit;

//const mediaAryToObj = ms => Object.fromEntries(toAry(ms).map(m => [m.mediaId, m]));
const toATExDraft = d => {
  return  {...toObj(d), isAT:1 };
} 

