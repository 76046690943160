import React, {useMemo} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";

import CardStudentAssignmentCard from "./CardStudentAssignmentCard";
import CpTeacherAssignmentCard from "./CardTeacherAssignmentCard";
import { ReduxBind } from "../../saga/ReduxState";
import { expfRoles } from "../../saga/auth.saga";
import { _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED } from "../../consts/ATConstsAssignment";
import { toObj, toStr } from "../../libs/libType";
import { EPUITime3 } from "../_components/CpEpTabHead";
import { _pathAssign } from "../../consts/ATConstReact";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { TabIdReme, TabIdReport } from "./PageAssignmentStudent";

export const CpAssignmentList = ReduxBind(props => { //base on poc AssignmentList
    const {dispatch, asms, allAsms, fields, noHr = 0} = props; 
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const [isThr, isStt, uRole ] = expfRoles(props);
        
    const clickAsm = asmId => dispatch(urlPush_Replace(_pathAssign(asmId)));
    const clickAsmRpt = asmId => dispatch(urlPush_Replace(_pathAssign(asmId, TabIdReport)));
    const clickAsmReme = asmId => dispatch(urlPush_Replace(_pathAssign(asmId, TabIdReme)));
   
    const cardProps = {...props, clickAsm, clickAsmRpt, clickAsmReme, layout:'list'} 
    return <>
        {noHr?'':<hr className={"mt-2 mb-3 mx-3"}/>}
        <div key={UILang} className={"CpAssignmentList table-data-container d-flex gap-2 flex-grow-1"}>
            <div className={"d-flex flex-column gap-2 gap-md-3 w-100 overflow-auto px-3 pb-5 pb-md-3"}>{
                isThr? asms.map((asm, idx) => <CpTeacherAssignmentCard {...{...cardProps, key:aKey(asm, idx), asm, }} />): 
                isStt? asms.map((asm, idx) => <CardStudentAssignmentCard {...{...cardProps, key:aKey(asm, idx), asm, }} />):
                'Invalid user Role'+uRole
            }</div>
        </div>
    </>
});
const aKey = (asm, idx) => idx + '_' + toStr(asm?.assignId);

export const getT2bSrt = (asm) =>{
    const t2s = asm?.time2beStart;
    const ASrtTime = (asm?.timeStart)?(t2s):(asm?.dateAdd);
    
    return EPUITime3(ASrtTime);
};
export const getT2bEnd = (asm) =>{
    const t2e = asm?.time2beEnd;
    const AEndTime = (asm?.timeEnd)?(EPUITime3(t2e)):'--';
    
    return AEndTime;
};
