import React from 'react';
import ATSwitch from '../components/Switch0';
import CpValueAdder from '../components/CpValueAdder';
import CpATPullDownMenu from '../components/CpATPullDownMenu';
import CpQtnTypeMenu from '../components/CpQtnTypeMenu';
import { toInt } from '../../libs/libType';
import { aTErrTxt1} from '../AppUtil';
import * as SQT from '../../consts/ATSysQType';
import * as FIBC from "../../consts/ATQtnAnsTypeFIB";
import { popConfirm } from '../components/CpPopup';
import { useUILang } from '../utils/useUILang';

export const MTSetting_LBD = (props) => {
    const { fullQ, setFullQ, editable, clearQAnswers, setShowEditor, showPub, fieldErrs, pub, dispatch, isAT=1 } = props
    const [ t, uiEn, UILang, setUILang, ut, t2, t3 ] = useUILang();

    const SQType=fullQ.SQType?fullQ.SQType:SQT.__SYSQSubType_LBD;
    const caseSensitive = toInt(fullQ.caseSensitive);
    const scoreMethod = fullQ.scoreMethod?fullQ.scoreMethod:FIBC.__FIBS_PBK;
    const score = toInt(fullQ.score);
    
    const allowTeaCopyEdit = toInt(fullQ.allowTeaCopyEdit);
    
    const prevScoreMethod = scoreMethod;
    const lock = !editable;//pub;

    const _setFullQ = (k) => (v) => setFullQ(k,v);  

    const smOpts = [
        { id: FIBC.__FIBS_PBK, jsx: () => <div>{t(FIBC.__UIFIBScodeName(FIBC.__FIBS_PBK))}</div> },
        { id: FIBC.__FIBS_PQU, jsx: () => <div>{t(FIBC.__UIFIBScodeName(FIBC.__FIBS_PQU))}</div> },
    ];

    const scoreMethodClick = (sm) => {
        (prevScoreMethod !== sm) && (!lock) && popConfirm(dispatch, 0, t('warning.warning_change_score_method'), () => {
            _setFullQ('scoreMethod')(sm);
        });
    };    

    return <div key='lbdSet' className={'VMenuTab ' + (editable?'':' unclickable')}>
        <div className="vstack gap-3 minBreakpoint-xs"> 
            <CpQtnTypeMenu {...{pub, dispatch}} sel={SQType} setSel={_setFullQ('SQType')} clearQAnswers={clearQAnswers} setShowEditor={setShowEditor}/>
            {SQType===SQT.__SYSQSubType_LBT?<><div className='flexRowBetween'>
                <div>{t('at-setting.fib.case-sensitive')}</div>
                <div><ATSwitch id='SWcaseSens' leftText='' rightText='' disabled={false}
                    selected={caseSensitive} leftValue={0} rightValue={1}
                    setter={_setFullQ('caseSensitive')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
            </div></>:''}
            <div className='flexRowBetween'>
                <div>{t('at-setting.fib.scoring-method')}</div>
                <CpATPullDownMenu sel={scoreMethod} setSel={scoreMethodClick} opts={smOpts}
                    containerStyle={{ width: "auto", whiteSpace:'nowrap', top:'35px' }} right={true}
                    menuStyle={{ justifyContent: "flex-end" }} headerStyle={{ width: "auto", justifyContent: "center" }} />
            </div>
            <div className='flexRowBetween'>
                <div>{t('at-setting.fib.totalMark')}</div>
                {scoreMethod===FIBC.__FIBS_PBK?<div style={{border:"1px solid lightgrey", padding:"2px 4px 2px 4px"}}>{score}</div>
                :<CpValueAdder value={score} setValue={_setFullQ('score')} min={1}/>}
            </div>
            {showPub?'':aTErrTxt1(fieldErrs?.score)}                        
            {isAT?<div className='flexRowBetween'>
                <div>{t3('qtn-opt-allow-teacher-to-copy-edit')}</div>
                <div><ATSwitch id='SWTeaAllowCopy' leftText='' rightText='' disabled={false}
                    selected={allowTeaCopyEdit} leftValue={0} rightValue={1}
                    setter={_setFullQ('allowTeaCopyEdit')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
            </div>:''}            
        </div>
    </div>
};

export default MTSetting_LBD;