import React, {useState, useRef} from "react";
import * as UI from '../../libs/libUI';

import { CpATBtn, readUploadText } from "../AppUtil";
import { ReduxBind } from "../../saga/ReduxState";
import { loading_add } from "../../saga/loading.saga";

// Allowed extensions for input file
const allowedExtensions = ["csv"];
const csvParseOpts = {
    header: true
};

const CpATUploadButton = ReduxBind(props => {
    const {dispatch, text, onLoad} = props;
    const refFile = useRef(null);
 
    const clickFile = e => { UI.stopEvent(e); refFile?.current?.click(); };

    const handleFileChange = async (e) => {
        if (!(e?.target?.files?.length)) 
            return;

        const file = e?.target?.files[0];
        if(!file)
            return;

        const fileExtension = file.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
            onLoad(false, 'Please input a csv file');
            return;
        }

        dispatch(loading_add('select CSV', 1));
        const [csvTxt, err] = await readUploadText(file);
        e.target.value = null;
        //await new Promise(res => setTimeout(res, 1000));
        onLoad(csvTxt, err);
        dispatch(loading_add('select CSV', -1));
    };

    return <>
            {CpATBtn(text, 'btnfile', clickFile)}
            <input type='file' onChange={handleFileChange} id='audioFile' ref={refFile} style={{ display: 'none' } } /> 
    </>;
});
export default CpATUploadButton;


