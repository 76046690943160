import React from "react";
import {CloseButton} from "react-bootstrap";
import CpIco from "./CpIco";

const CpSharedModalHeader = (props) => { // base on poc SharedModalHeader.tsx
    const {title, iconPath, handleClose} = props
    return <span className={"pt-2 semi-bold d-flex justify-content-between align-items-center gap-2 px-3 pb-2 bg-dim-100 "}>
        <span className={"d-flex gap-2 align-items-center fs-5 user-select-none"}>
            <span><CpIco src={iconPath}/></span>{title}
        </span>
        <CloseButton onClick={handleClose}/>
    </span>
};
export default CpSharedModalHeader;