import react from 'react';

const CpTabHead = (tab, clickTab, tabLabels, nonTabs) => <CpTabHeadJSX {...{tab, clickTab, tabLabels, nonTabs}} />
const CpTabHeadJSX = ({tab, clickTab, tabLabels, nonTabs}) => {
//const CpTabHead = (tab, clickTab, tabLabels, nonTabs) => {
    const childs = [];
    const last = tabLabels.length-1;
    for(var i = 0; i <= last; i++){
        childs.push(<div key={'key'+i} className={(tab === i)? 'ATTab': 'ATTabBg'} onClick={clickTab(i)} >{tabLabels[i]}</div>);
        if(i < last)
            childs.push(<div key={'tablgap'+i} className="ATTabGap"></div>);
    }
    return <div className="flexRowStart f14" style={{alignItems:'flex-end', margin:'0 0 8px 0'}}>
        {childs}
        <div className="ATTabLast">        {nonTabs}
</div>
    </div>;
};
export default CpTabHead;
