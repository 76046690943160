//new Date(); ms from 1970 1 1
//new Date(1000); => 1000ms after 1970 1 1 0 0 0;
//new Date(2019, 12, -1, 12, 30, 45); => (Y, (M-1), D, h, m, s) !! month is 0 base
//Date.get/set Date,Hours, Minutes, Seconds

export const timeStampGMT = (year, month, day, hour = 0, minute = 0, second = 0, mills = 0) => {
    var date = new Date();
    date.setFullYear(year);
    date.setMonth(month-1);
    date.setDate(day);
    date.setHours(hour, minute, second, mills);
    return date.getTime();// - only setUTCXXX need fix offset (date.getTimezoneOffset() * 60 * 1000); 
};

export const _minTimeStampStr = '0000000000000';
export const _maxTimeStampStr = '9999999999999';
export const invtimeStamp = ts => (9999999999999 - ts);

export const timeStampNowGMT = () => {
    return Date.now();// + (new Date().getTimezoneOffset() * 60 * 1000);//GMT = SysTime + SysTime.TZ_Ooffset
};
export const nowStr = (format, local=false) => formatDate(timeStampNowGMT(), format, local);

//TimeStampOsToHk sho Use For Display only!!!
export const timeStampGMTToHk = (timeStampGMT) => (timeStampGMT + (8 * 60 * 60 * 1000)); //HKT = GMT+8
//timestampGMTfromHK Uses For Custom Time Input!!! (e.g. select datetime in DatePicker)
export const timestampGMTfromHK  = (timeStampGMT) => (timeStampGMT - (8 * 60 * 60 * 1000)); //HKT = GMT+8

export const formatTimeStamp = (timestamp, format='YYMMDD-HHmmss', local=false) => {
    return timestamp?formatDate(timestamp, format, local):'(n/a)';
};
export const formatTimeStampHKWith8 = (timeStampGMT, format='YYYY-MM-DD HH:mm:ss') => {
    return formatDate(timeStampGMTToHk(timeStampGMT), format, false)+'+08:00';
};
export const formatTimeStampHK = (timeStampGMT, format='YYYY-MM-DD HH:mm:ss') => {
    return formatDate(timeStampGMTToHk(timeStampGMT), format, false);
};

const ii = (i, len = 2) => {
    var s = i + "";
    while (s.length < len) s = "0" + s;
    return s;
};

export const formatDate = (gmt, format='YYMMDD-HHmmss', local=false) => {


    
    const date = new Date(gmt);
    const utc = !local;

    var MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    var y = utc ? date.getUTCFullYear() : date.getFullYear();
    format = format.replace(/(^|[^\\])YYYY+/g, "$1" + y);
    format = format.replace(/(^|[^\\])YY/g, "$1" + y.toString().substr(2, 2));
    format = format.replace(/(^|[^\\])Y/g, "$1" + y);

    var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
    format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
    format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
    format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
    format = format.replace(/(^|[^\\])M/g, "$1" + M);

    var d = utc ? date.getUTCDate() : date.getDate();
    format = format.replace(/(^|[^\\])DDDD+/g, "$1" + dddd[0]);
    format = format.replace(/(^|[^\\])DDD/g, "$1" + ddd[0]);
    format = format.replace(/(^|[^\\])DD/g, "$1" + ii(d));
    format = format.replace(/(^|[^\\])D/g, "$1" + d);

    var H = utc ? date.getUTCHours() : date.getHours();
    format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
    format = format.replace(/(^|[^\\])H/g, "$1" + H);

    var h = H > 12 ? H - 12 : H === 0 ? 12 : H;
    format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
    format = format.replace(/(^|[^\\])h/g, "$1" + h);

    var m = utc ? date.getUTCMinutes() : date.getMinutes();
    format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
    format = format.replace(/(^|[^\\])m/g, "$1" + m);

    var s = utc ? date.getUTCSeconds() : date.getSeconds();
    format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
    format = format.replace(/(^|[^\\])s/g, "$1" + s);

    var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])f/g, "$1" + f);

    var T = H < 12 ? "AM" : "PM";
    format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
    format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

    var t = T.toLowerCase();
    format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
    format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

    var tz = -date.getTimezoneOffset();
    var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
        tz = Math.abs(tz);
        var tzHrs = Math.floor(tz / 60);
        var tzMin = tz % 60;
        K += ii(tzHrs) + ":" + ii(tzMin);
    }
    format = format.replace(/(^|[^\\])K/g, "$1" + K);

    var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
    format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
    format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

    format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

    format = format.replace(/\\(.)/g, "$1");

    return format;
};

export const secondToHHMMSS = (sec) => {
    var hours   = Math.floor(sec / 3600);
    var minutes = Math.floor((sec - (hours * 3600)) / 60);
    var seconds = sec - (hours * 3600) - (minutes * 60);

    if (hours   < 10) (hours   = "0"+hours);
    if (minutes < 10) (minutes = "0"+minutes);
    if (seconds < 10) (seconds = "0"+seconds);
    return hours+':'+minutes+':'+seconds;
};

export const daysDiff = (d1, d2) => {
    const diffTime = Math.abs(d1 - d2);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

export const daysDiff2 = (d1, d2) => {
    const diffTime = (d2 - d1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

export const daysDiff_2min = (d1, d2) => {
    const diffTime = (d2 - d1);
    const diffDays = (diffTime / (1000 * 60));
    return diffDays;
};

export const daysDiff_2day = (d1, d2, format='YYYY-MM-DD') => {
    const De1 = formatDate(timeStampGMTToHk(d1), format, false);
    const De2 = formatDate(timeStampGMTToHk(d2), format, false);
    const diffTime = (new Date(De2).getTime() - new Date(De1).getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

const day2ms = 24 * 60 * 60 * 1000; 
export const HKDayDiff = (gmt1, gmt2) => {
    /*
    const format='YYYY-MM-DD';
    const De1 = formatDate(timeStampGMTToHk(d1), format, false);
    const De2 = formatDate(timeStampGMTToHk(d2), format, false);
    const diffTime = (new Date(De2).getTime() - new Date(De1).getTime());
    return diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    */

    const hkday1 = (timeStampGMTToHk(gmt1) / day2ms);
    const hkday2 = (timeStampGMTToHk(gmt2) / day2ms);
    return Math.floor(hkday2) - Math.floor(hkday1);
};