import React, { Fragment, useMemo, useEffect, useState } from "react";
import CpAccordionItem from "./CpAccordionItem";
import { stopEvent, EpInputTxt0 } from "../../libs/libUI";
import CpCheckbox from "./CpCheckbox";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpIco from "../_components/CpIco";
import { _ATMetaTypeByCode, _ATMetaTransByCode, _ATMetaType_SU, __ATMetaTypes } from "../../consts/ATMetaTypes";
import { isObj, objEntries, objKeys, objMap, strCmp, toAry, toInt, toObj, toStr } from "../../libs/libType";
import { MSetGroupType, buildTagMetaEnts } from "../EPLibrary/LayerExerFilter";
import { QListMSTs } from "../../AppExPf/components/CpQViewExt";
import { preJS, autoId } from "../../AppExPf/AppUtil";
import { useUILang } from "../../AppExPf/utils/useUILang";
import CpMJX from "./CpMJX";

const CpEpTypeMSetTrees = (props => {
    const {FilterMSets, metas, setMeta, opens, setOpen, ct} = props;
    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();

    const typeMSets = useMemo(() => MSetGroupType( buildTagMetaEnts(FilterMSets)), [FilterMSets]); 
    const [ticks, setTick] = [metas, setMeta];
    
    return QListMSTs.map(typeId => { 
        const msets = (typeId !== _ATMetaType_SU) && typeMSets[typeId];
        const sortmsets = objEntries(msets).sort((a, b) => strCmp(a[0], b[0]));
        const msetX = sortmsets.map(([msid, MSet]) => <CpMSRoot key={msid} {...{msid, MSet, ticks, setTick, opens, setOpen, ct}} />).filter(x => x);
        return msetX.length?
        <Fragment key={typeId}>
            <CpAccordionItem id={typeId} title={t3(_ATMetaTransByCode(typeId))} headerSize={"fs-6"}>{msetX}</CpAccordionItem>
            <div className={"mx-2 split-line"}></div>
        </Fragment>:'';
    }).filter(x => x);
});
export default CpEpTypeMSetTrees;

export const toSetMetaId = sm => {
    const [s, m] = toStr(sm).split(',');
    return [s.trim(), toStr(m).trim()];
}

const buildNonFulls = (msid, mset, ticks) => {  
    //if(msid !== 'mstb1') return new Set();

    const metas = mset?.metas;
    if(!isObj(metas)) return;
    
    const dieSet = new Set();
    const Kill = mid => {
        if(!mid) return;
        dieSet.add(msid+','+mid);
        Kill(metas[mid]?.pMeta);
    }
    const toDie = key => ticks.includes(key) || dieSet.has(key)
    const KillDead = mid => {if (!toDie(msid+','+mid)) Kill(mid); };
    objKeys(metas).forEach(KillDead);

    //const am = new Set(objKeys(metas).map(mid => msid+','+mid));
    
    

    
    

    return new Set(Array.from(dieSet).filter(t => ticks.includes(t)));
};

const buildPartials = (msid, metas, ticks) => {  
    const ps = new Set();
    const addP = mid => {
        const m = metas[mid];
        if(!m){ 
            return;
        }
        const id = msid+','+mid;
        const ticked = ticks.includes(id); 
        
        if(ticked){ 
            
            return;
        }
        
        ps.add(id);
        addP(m.pMeta);
    };
    ticks.forEach(t => {
        const [s, m] = toSetMetaId(t);
        
        (s === msid) && metas[m] && addP(metas[m].pMeta); 
    });
    
    return ps;
};
export const CpMSRoot = (props => {
    const {MSet, msid, ticks, setTick, opens, setOpen, ct, tickP} = props
    //const open = 1; //opens.includes(msid);
    //const clickOpen = e => { stopEvent(e); setOpen(msid)(!open)};
    const partials = tickP
        ? buildNonFulls(msid, MSet, toAry(ticks))
        : buildPartials(msid, toObj(MSet.metas), toAry(ticks)); 
    return <>{MSet.mRoot.map(mid =>{
            return <div key={mid} id={msid+','+mid} className={"ms-4"} ><CpMTreeTickNode  {...{key:mid, partials, MSet, msid, mid, ticks, setTick, opens, setOpen, ct, tickP}} /></div>;
    })}</>;
})

const CpMTreeTickNode = (props => {
    const {partials, MSet, msid, mid, ct, ticks, setTick, opens, setOpen, tickP} = props;

    const id = msid+','+mid;

    const meta = MSet.metas[mid];
    const title = <CpMJX>{(toStr(meta.LVNum)+' '+ ct(meta.nameEn, meta.nameCt)).trim() || mid}</CpMJX>;

    const branch = MSet.MBranchs[mid];
    const hasChild = branch?.length;

    const open = opens.includes(id) && hasChild;
    const clickOpen = e => { stopEvent(e); hasChild && setOpen(id)(!open)};

    const on = ticks.includes(id);
    const partial = partials.has(id);
    const onTick = e => { 
        //stopEvent(e); 
        setTick(id)(!on); 
    };
    
    return <div {...{id, className: "TMStree-CpMetadataFilter metadata-filter-container" }} >
        <div className={"d-flex justify-content-start overflow-auto text-wrap ms-3 my-2 gap-1"}>
                <span role={"button"} className={"text-exercise-third-btn metadata-filter-toggle"} onClick={clickOpen}>
                {hasChild  && <CpIco src={open? IconList.general.openNode: IconList.general.closeNode}/>}
            </span>
            <div role={"button"} className={"d-flex gap-2 user-select-none"}>
                <CpCheckbox onChange = {onTick} checked={on||partial} partial={partial} />
                <span {...{id, onClick: onTick}}>{title}</span>
            </div>
        </div>
        {open && hasChild && branch.map(mid => <div key={mid} className={"ms-4"}><CpMTreeTickNode {...{...props, mid}} /></div>)}
    </div>
});

