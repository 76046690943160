import React, {useState, useEffect} from 'react';

import * as UI from '../libs/libUI';
import { apiCallLoad } from '../libs/awsFuncs';

import {ReduxBind} from '../saga/ReduxState';
import {urlPush_Replace} from '../saga/urlPush.saga';
import { lj } from '../libs/libFormat';
import { aTErrTxt1, aTUIMust, clickUrl } from './AppUtil';
import { validATSubjectId, validEditATSubject } from '../consts/ATValidate';
import { _ATRoot } from '../consts/ATConstReact';
import { normalizeEditATSubj, validEditATSubj } from '../consts/ATValidateATSubj';
import CpATIcoBtn, { cpATIcoBtn_ } from './components/CpATIcoBtn';
import { toStr } from '../libs/libType';
import { hasErr } from './utils/useEditDraftPub';
import { ATDo_Subj, _ATCanDo } from '../consts/ATRoleRights';

const backPath = _ATRoot + 'subjects'; 

const PageATSubjectEdit = ReduxBind((props) => { 
    const dispatch = props.dispatch;
    const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
    const letSubject = _ATCanDo(role, ATDo_Subj);

    const ATSubjectId = toStr(props.match?.params?.ATSubjectId);
    const isNew = ATSubjectId? 0: 1; // new create, or exist edit
 
    const [fields, setFields] = useState({ATSubjectId, isNew});
    const [fieldErrs, setFieldErrs] = useState({});
    
    useEffect(() => { 
        letSubject? apiCallLoad(dispatch, '/getATSubject', onLoad, {ATSubjectId}): dispatch(urlPush_Replace(_ATRoot));
    }, []);

	const onLoad = (res, err) => {
        //if(err) window.alert(JSON.stringify(err));
        if(res){
			setFieldErrs(res.fieldErrs||{});
			const {ATSubjectId, ATSubjectName} = res.ATSubject||{};
			setFields({ATSubjectId, ATSubjectName});
		}
	};

    const setATSubjectId = (v) => { setFields(f => ({ ...(f || {}), ATSubjectId: v.slice(0, 40) })) };
    const SetATSubjectName = (v) => { setFields(f => ({ ...(f || {}), ATSubjectName: v.slice(0, 50) })) };

    const clickCancel = clickUrl(dispatch, backPath);

    const onPutATSubj = (res, err) => {
        //if(err) window.alert(JSON.stringify(err));
        const fes = res?.fieldErrs;
        if(fes) 
            setFieldErrs(fes);
        if(res?.state !== 'retry')
            dispatch(urlPush_Replace(backPath));
    };

    const clickSaveATSubj = e => { UI.stopEvent(e);
        const fs = normalizeEditATSubj(fields);
        setFields(fs);
        const fieldErrs = validEditATSubj(fields);
        setFieldErrs(fieldErrs);
        if(!hasErr(fieldErrs)){
            apiCallLoad(dispatch, '/putATSubject', onPutATSubj, {...fields, isNew});
        }
    };

    /*
<div className="adminToolItem clickable" onClick={clickSave}>
    <img className="adminToolIcon" src={saveIcon} alt="" /><span style={{color:"#0C8CE9"}}>Save</span>
</div>
<div className="adminToolItem clickable" onClick={clickCancel}>
    <img className="adminToolIcon" src={cancelIcon} alt="" />Cancel
</div>
    */
    return <div className="PageATSubjectEdit adminSubjectTop">
        <div className="f16">Admin / Subject / {isNew ? 'New' : 'Edit'}</div>
            <div className="adminEditToolsContainer"><div style={{display:"flex"}}>
                <div className="adminTools1">
				    <CpATIcoBtn onClick={clickSaveATSubj} src="general/save" txt='Save' />
				    <CpATIcoBtn onClick={clickCancel} src="general/cancel" txt='Cancel' />                    
                </div>
            </div></div>      
            <div className="inputInfoContainer"> 
                <table className="inputInfoTable f14"><tbody>
                    <tr>
                        <td className="subjectInputLabel">Subject ID{aTUIMust}</td>
                        <td>{isNew?UI.EpInputTxt0(fields.ATSubjectId, setATSubjectId, 'txtSubJd', '', {width:"200px"}, 'Please enter Subject Id here'):ATSubjectId}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{aTErrTxt1(fieldErrs?.ATSubjectId)}</td>
                    </tr>
                    <tr>
                        <td className="subjectInputLabel">Subject Name{aTUIMust}</td>
                        <td>{UI.EpInputTxt0(fields.ATSubjectName, SetATSubjectName, 'txtSubName', '', {width:"250px"}, 'Please enter Subject Name here')}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{aTErrTxt1(fieldErrs?.ATSubjectName)}</td>
                    </tr>                    
                    </tbody></table>
            </div>
    </div>;
});
export default PageATSubjectEdit;

/*
                {isNew?<>
                    New SubjectId: {UI.EpInputTxt0(fields.ATSubjectId, setATSubjectId, 'txtSubJd', '', {}, 'input Subject Id')}
                    {aTErrTxt1(fieldErrs?.ATSubjectId)}
                    Existing SubjectId: {ATSubjectId}
                </>}
                Subject Name: {UI.EpInputTxt0(fields.ATSubjectName, SetATSubjectName, 'txtSubName', '', {}, 'input Subject Name')}
                {aTErrTxt1(fieldErrs?.ATSubjectName)}
*/


