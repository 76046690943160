import React, {useState, useEffect} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import {Button, ProgressBar} from "react-bootstrap";
import CpSharedModal from "../_components/CpSharedModal";
import { iconButton } from "../../AppExPf/components/CpATIcoBtn";
import { stopEvent } from "../../libs/libUI";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpIco from "../_components/CpIco";
import * as UI from "../../libs/libUI";
import { gst_popAllAlert } from "../../saga/globalState.saga";
//import { alertHolder } from "../../AppExPf/components/CpPopup";
// to do
// check time up, disable back button, only submit button
// need pause timer ?

const CpAssignmentEndingPage = (props) => { // base on poc AssignmentEndingPage.tsx
    const { dispatch, wSubmit, asmTimeUp, gotoQ, close, hasReme, isReme } = props;
    const [msgVis, setMsgVis] = useState(false);
    const [t] = useUILang();

    const respInfo = props?.respInfo;

    useEffect(()=>{
        if (!asmTimeUp) return;
        dispatch(gst_popAllAlert());
        clickSubmit();
    },[asmTimeUp]);

    const jumpToQ = (idx) => { if ((!asmTimeUp) && gotoQ) gotoQ(idx); };

    const clickSubmit = async(e) => { stopEvent(e);
        await wSubmit(asmTimeUp? 'timeup_submit': 'normal_submit');
        setMsgVis(true);
    };

    const closeMsg = e => { stopEvent(e);
        setMsgVis(false);
        close && close(1);
    };

    const clickBack = e => { stopEvent(e); if(close) close( asmTimeUp? 1: 0 ); };

    return <div className='ATQtnSingleContainer sequenceListAnimation' style={{paddingTop:0}}>
        <div className='ATQtnHeader'><div className='flexRowBetween'>
            {asmTimeUp?<div/>:<div className='flexRowStartFit f20'>
                {iconButton('','general/previous','white','transparent',clickBack, true, {transform:'scale(1.5)'})}
            </div>}
            <div/>
        </div></div>   
        <div className={"d-flex flex-column justify-content-center gap-3 h100"}>
            <div className={"d-block fs-4 semi-bold text-center"}>
                {asmTimeUp?'':<><span>{t("assignment-submit-message")}</span><br/>
                <span>{t("assignment-submit-message-2")}</span></>}
            </div>
            <div className={"d-flex gap-4 justify-content-around flex-wrap fs-5"}>
                <span className={"d-flex gap-2 align-items-center"}>
                    <div className={"bg-preview-primary rounded-light"} style={{width: "2rem", height: "2rem"}}></div>
                    {t("assignment-submit-answered")}
                </span>
                <span className={"d-flex gap-2 align-items-center"}>
                    <div className={"bg-exercise-third-btn-hover rounded-light"}
                         style={{width: "2rem", height: "2rem"}}></div>
                    {t("assignment-submit-partly-answered")}
                </span>                
                <span className={"d-flex gap-2 align-items-center"}>
                    <div className={"bg-dim-350 rounded-light"} style={{width: "2rem", height: "2rem"}}></div>
                    {t("assignment-submit-not-yet-answered")}
                </span>
            </div><br/><br/>
            <span id={"question-selector-container"} className={"d-flex gap-3 flex-wrap justify-content-center"}>
               {respInfo.map((item, idx) => {
                   const color = (item.status === "answered")? "bg-mcq-active"
                    :(item.status === "partly")? "bg-exercise-third-btn-hover":
                    "bg-dim-350";
                   return <span key={idx} role={"button"} onClick={()=>jumpToQ(item.edx)}
                      className={`d-flex justify-content-center align-items-center gap-3 flex-nowrap number-indicator rounded-circle ${color} text-white`}>
                   <span className="semi-bold text">{item.title}</span>
                </span>
               })}
            </span><br/><br/>
            <div className={"text-center"}>{asmTimeUp
                ?<Button className={"btn btn-lg btn-exercise-third-btn px-5"} onClick={clickBack}>{t("confirm")}</Button>
                :<Button className={"btn btn-lg btn-exercise-third-btn px-5"} onClick={clickSubmit}>{t("submit")}</Button>
            }</div>
        </div>
        <CpSubmitModal {...{show: msgVis, closeClick: closeMsg, asmTimeUp, hasReme, isReme}} />
    </div>
};
export default CpAssignmentEndingPage;

const HeaderElement = (props) => {
    const [ t ] = useUILang();
    const {title = "", complete = 1, isFail = 0, asmTimeUp = 0 } = props;
    return <>
        <div className={"d-flex justify-content-between"}>
            {complete ?
                <span className={"d-flex justify-content-center text-exercise-third-btn fs-1 w-100"}>
                <CpIco src={isFail ? IconList.status.cancel : (asmTimeUp ? IconList.general.time : IconList.status.finish )}/></span>
                :<div className={"d-flex flex-wrap justify-content-center align-items-center p-3  gap-5 fs-1 text-dim-400"}>
                    <CpIco src={IconList.contentType.file}/>
                    <CpIco src={IconList.contentType.image}/>
                    <CpIco src={IconList.contentType.video}/>
                    <CpIco src={IconList.contentType.audio}/>
                </div>}
        </div>
        <div className={"d-flex w-100 fs-4 semi-bold justify-content-center user-select-none"}>
            {!complete?<span>{t("submit-wait")}</span>:''}
            {complete?<span>{title}</span>:''}
        </div>
    </>
};

const ProgressbarComponent = (props) => {
    const {percentage = 10, variant = ""} = props
    return <ProgressBar id={"progressbar"} animated variant={variant} now={percentage}/>
};

export const ProgressElement = (props) => {
    const [ t ] = useUILang();
    const {progress, miniLayout = 0} = props;
    if (miniLayout) {
        return <div className={"d-flex gap-3 align-items-center"}>
            <span className={"fs-7"}>{t("saving")}</span>
            <div className={"d-flex flex-column align-items-start"}><span className={"upload-progress-bar-container"}>
                <ProgressbarComponent variant={"preview-secondary"} percentage={progress}/>
            </span></div>
        </div>
    };
    return <><span className={"fs-5"}>{progress}%</span>
        <span className={"upload-progress-bar-container"}>
            <ProgressbarComponent variant={"preview-secondary"} percentage={progress}/>
        </span>
        <span>{t("warning.warning_uploading")}</span>
    </>
};

export const CpMediaModal = (props) => {
    const { show, complete=0, progress=100, miniLayout=0, transBD=0 } = props;
    useEffect(()=>{
        if (transBD) {
            const bd = document.getElementsByClassName('modal-backdrop');
            if (bd?.length > 0) { bd[0].style.opacity = 0; };
        };
    },[]);
    return <CpSharedModal show={show} scrollable={false} overflowBody={false}
        className={(miniLayout?"bg-media-upload":"")} centered={miniLayout?false:true}>        
        <div className={`px-3 pt-2 bg-dim-100 rounded`}><div className={`d-flex flex-column gap-4`}>
            <div className={`d-flex flex-column align-items-center gap-3 mb-2 px-3 semi-bold text-center ${complete?"fade-in-container":""}`}>
                {!complete && <ProgressElement progress={progress} miniLayout={miniLayout}/>}
            </div>
        </div></div>
    </CpSharedModal>
};

// complete - submit finish, sfail = submit fail, mfail = media fail
export const CpSubmitModal = (props) => { // base on poc AssignmentEndingPage.tsx
    const { show, complete=1, sfail, mfail, isExpire, withBar, progress=100, 
        retryClick, closeClick, allowRetry, asmTimeUp, msg='', hasReme, isReme } = props;
    const [ t ] = useUILang();

    const _retryClick = (e) => {UI.stopEvent(e); retryClick && retryClick() };
    const _closeClick = (e) => {UI.stopEvent(e); closeClick && closeClick() };

    const afail = sfail || mfail;
    const showRemeResultMsg = isReme && complete && !afail;
    const showRemeFollowMsg = hasReme && complete && !afail;

    const footerClick = allowRetry?_retryClick:_closeClick;
    const titleTxt = afail?
        (sfail?t("assignment-submit-issue-title"):t("assignment-upload-issue-title"))
        :(asmTimeUp?t("times-up"):t("submit-done"));
    const contentTxt = (complete && !afail) ? t(showRemeResultMsg?"assignment-submitted-message2":"assignment-submitted-message1")
        :sfail ? t("assignment-submit-issue")
        :mfail ? t("assignment-upload-issue")
        :isExpire ? t("assignment-expire-issue")
        :msg;
    const remeMsgTrans = showRemeResultMsg ? "assignment-submitted-message-submit-follow-up-exercise":"assignment-submitted-message-added-follow-up-exercise";
    const renderFooter = () => { 
        return <div className={"d-flex gap-2 justify-content-center w-100"}>
            <Button variant="exercise-third-btn btn-block w-100" onClick={footerClick}>
                {allowRetry?t("assignment-cta-retry"):t("close")}
            </Button>
        </div>
    };
    
    return <CpSharedModal show={show} scrollable={false} overflowBody={false} footer={complete?renderFooter():<></>}
            className={""} centered={true}>
        <div className={`px-3 pt-4 bg-dim-100 rounded-top`}><div className={`d-flex flex-column gap-4`}>
            <div className={`d-flex flex-column align-items-center gap-3 mb-2 px-3 semi-bold text-center ${complete ? "fade-in-container" : ""}`}>
                <HeaderElement complete={complete} isFail={afail} asmTimeUp={asmTimeUp} title={titleTxt}/>
                {(!complete && withBar)?<ProgressElement progress={progress}/>:''}
                <span>{contentTxt}</span>
                {(showRemeResultMsg || showRemeFollowMsg)?<><span>
                    <span className='text-danger p-1'>{t("*")}</span>{t(remeMsgTrans)}
                </span></>:''}
            </div>
        </div></div>
    </CpSharedModal>
};

/*
handleOpen ([ass or media], start:1/0, {uploadIssue:,expireIssue,submitIssue})
<h1>Scenarios (Normal)</h1>
<div className={"d-flex flex-wrap gap-2 mb-5"}>
    <button type="button"
            className="d-flex justify-content-center align-items-center gap-3 flex-nowrap cus-btn-md btn btn-preview-secondary">
        <span className="semi-bold text text-nowrap"
                onClick={() => handleOpen(["media"], true)}>Upload media(Background)</span></button>
    <button type="button"
            className="d-flex justify-content-center align-items-center gap-3 flex-nowrap cus-btn-md btn btn-preview-secondary">
        <span className="semi-bold text text-nowrap"
                onClick={() => handleOpen(["assignment"], false)}>Assignment submit</span></button>
    <button type="button"
            className="d-flex justify-content-center align-items-center gap-3 flex-nowrap cus-btn-md btn btn-preview-secondary">
        <span className="semi-bold text text-nowrap"
                onClick={() => handleOpen(["media", "assignment"], true)}>Assignment Submit + Upload media</span>
    </button>
</div>
<h1>Scenarios (Fail)</h1>
<div className={"d-flex flex-wrap gap-2"}>
    <button type="button"
            className="d-flex justify-content-center align-items-center gap-3 flex-nowrap cus-btn-md btn btn-preview-secondary">
        <span className="semi-bold text text-nowrap"
                onClick={() => handleOpen(["media"], true, {uploadIssue: true})}>Upload media(Background, Fail Upload)</span>
    </button>
    <button type="button"
            className="d-flex justify-content-center align-items-center gap-3 flex-nowrap cus-btn-md btn btn-preview-secondary">
        <span className="semi-bold text text-nowrap"
                onClick={() => handleOpen(["media", "assignment"], true, {uploadIssue: true})}>Assignment Submit + Upload media(Fail Upload)</span>
    </button>
    <button type="button"
            className="d-flex justify-content-center align-items-center gap-3 flex-nowrap cus-btn-md btn btn-preview-secondary">
        <span className="semi-bold text text-nowrap"
                onClick={() => handleOpen(["media", "assignment"], true, {submitIssue: true})}>Assignment Submit + Upload media(Fail Submit)</span>
    </button>
    <button type="button"
            className="d-flex justify-content-center align-items-center gap-3 flex-nowrap cus-btn-md btn btn-preview-secondary">
        <span className="semi-bold text text-nowrap"
                onClick={() => handleOpen(["media", "assignment"], true, {expireIssue: true})}>Assignment Submit + Upload media(Expired)</span>
    </button>
</div>
*/