import React from 'react';
import { toObj, toStr } from '../../libs/libType';
import { subState } from '../utils/useChain';
import CpATImage from '../components/CpATImage';
import { _alignClass } from './ViewExOptions';
import { exerTitle, exerBlock, viewExLabel, exATUIText, exTextArea } from './ViewExCommon';
import { useUILang } from '../utils/useUILang';

const ViewExCtnImg = props => {
    const { lock, showEn, idx, ECtn, replaceECtn, setOnAddMedia, PVMode, mediaDLs, getMediaDLs } = props; 
    const [ t ] = useUILang();        
    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
    const img = toObj(ec.img);
    const lang = showEn?'en':'ct';
    const edProps = {dispatch:props?.dispatch, mediaDLs, getMediaDLs, setOnAddMedia};
    const myWidth = toStr(ec.width);
    const myHeight = toStr(ec.height);
    const myAlign = toStr(ec.align);
    const displayTitle = ec.displayTitle?ec.displayTitle:0;    
    const enlarge = ec.enlarge?ec.enlarge:0;
    
    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));   
    const title = toStr(ec.title);
    const desc = toStr(ec.desc);

    if(lock || PVMode >=0) {
        let exStyle={}, exClass='';    
        if (myWidth) exStyle = {...exStyle, width:myWidth+'px'};
        if (myHeight) exStyle = {...exStyle, height:myHeight+'px'};
        if (myAlign) exClass = (_alignClass[myAlign]?_alignClass[myAlign]:_alignClass['center']);        
        return <div className={'ViewExCtnItemLock ' + (PVMode!==1?' Single':' List')}>
            <div id={'exCtnContent'+idx} className='ViewExCtnItemLockContent'>
                {displayTitle?<div className={'flexRowStart semi-bold ' + exClass}>
                    {PVMode===1?exerTitle(title):title}</div>:''}
                <div className={'flexRowStart ' + exClass}>
                <div><CpATImage id={"img"+idx} media={img} editable={false} style={{maxWidth:"100%", ...exStyle}} 
                    noZoom={!enlarge} {...edProps}/></div></div>
                <div className={'flexRowStart ' + exClass}>{PVMode>=0?exerBlock(desc):desc}</div>
            </div>
        </div>;    
    };   

    return <div className='ViewExCtnItem'>
        <p/>
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.image.title'),'titleen')}{exATUIText(ec.title, setTmpField('title'), 'vid')}
        </div>
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.image.desc'),'descen')}{exTextArea(ec.desc, setTmpField('desc'), 'desc')}
        </div>
        <div className='flexRowStart'>
            {viewExLabel('','hidden')}
            <div style={{width:'100%',marginTop:'4px'}}>
            <CpATImage id={"img"+idx} media={img} {...edProps} style={{maxWidth:"100%"}}
                editable={true} setData={(d) => setTmpField('img')(d)} />
            </div>                    
        </div>
    </div>
};
export default ViewExCtnImg;
