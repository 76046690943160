import ActionButtonComponent from "./CpActionButtonComponent";
import {ReactNode, useState} from "react";
import {Button, CloseButton, Modal} from "react-bootstrap";
import { sanitize } from "dompurify";
import { toStr } from "../../libs/libType";
import CpSharedModal from "./CpSharedModal";
import CpSharedModalHeader from "./CpSharedModalHeader";
import { IconList } from "../../consts/ATIconListToUsePoc";

import { CpSettingModalTabContainer, CpSettingModalSection } from "./CpSettingModalTabCps";

const CpPromptButtonComponent = (props) => { // base on poc PromptButtonComponent.jsx
    const {dispatch, cb, promptTitle, promptDesc, promptCancel, promptConfirm, ...promptButtonComponent} = props

    const [show, setShow] = useState(false);
    
    const iconPath = IconList.general.alert;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePrompt = () => { handleShow() };
    const handleConfirm = () => { 
        if (cb) { cb(); handleClose(); }
    };

    return <><ActionButtonComponent {...promptButtonComponent} onClick={() => handlePrompt()}/>
        <CpSharedModal show={show} scrollable={true} 
            header={<CpSharedModalHeader title={promptTitle} iconPath={iconPath} handleClose={handleClose} />} 
            footer={<div className={"d-flex gap-2 justify-content-center w-100"}>
                <Button variant={`gray-body-color w-100 border`} onClick={handleClose} >
                    {promptCancel}
                </Button>
                <Button variant={`at-primary btn-block w-100`} onClick={handleConfirm}>
                    {promptConfirm}
                </Button>
            </div>} 
        >
            <CpSettingModalTabContainer gap={3}>
                <CpSettingModalSection className={"flex-column"}>
                    <div className={"semi-bold"} dangerouslySetInnerHTML={{__html: sanitize(toStr(promptDesc))}}></div>
                </CpSettingModalSection>
            </CpSettingModalTabContainer>
        </CpSharedModal>
    </>
};
export default CpPromptButtonComponent;


/*
const CpPromptButtonComponent_org = (props) => { // base on poc PromptButtonComponent.jsx
    const {cb, promptTitle, promptDesc, promptCancel, promptConfirm, ...promptButtonComponent} = props

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePrompt = () => { handleShow() };
    const handleConfirm = () => { 
        if (cb) { cb(); handleClose(); }
    };

    return <>
        <ActionButtonComponent {...promptButtonComponent} onClick={() => handlePrompt()}/>
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header className={"bg-at-primary text-white py-2"}>
                <Modal.Title className={"text-white fs-5"}>{promptTitle}</Modal.Title>
                <CloseButton variant="white" onClick={() => handleClose()}/>
            </Modal.Header>
            <Modal.Body>
                <div className={"semi-bold"} dangerouslySetInnerHTML={{__html: sanitize(toStr(promptDesc))}}></div>
            </Modal.Body>
            <Modal.Footer className={"py-2"}>
                <div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center"}>
                    <Button variant="transparent-at-primary border w-100" onClick={handleClose}>
                        {promptCancel}
                    </Button>
                    <Button variant="at-primary w-100" onClick={handleConfirm}>
                        {promptConfirm}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    </>
};
*/