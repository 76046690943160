import React, {useMemo} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import CpValueStatusBox from "./CpValueStatusBox";
import CpQuestionBox from "./CpQuestionBox";
import CpChartMiniBar from "./CpChartMinibar";
import { preJS, autoId } from "../../AppExPf/AppUtil";
import CpMJX from "../_components/CpMJX";

const ChartPerformanceMetadataSets = (props) => {
    const [t, isUIEn, lang] = useUILang();
    const {student = [], metadata, data, miniLayout = false, miniTitle = ""} = props;

    const StudentHeaders = () => {
        return <>{student?.map(stu => <th key={stu.id} className={`${miniLayout === true ? "" : "text-rotate"} avatar-th`}>
            <div><span className={stu.submitted ? "" : "text-dim-350"}>{stu.no}{stu.name}</span></div>
        </th>)}</>
    };
    const getStatus = (stuId, data) => {
        const found = data?.student?.find(stu => stu.id === stuId);
        return found || null;
    };
    const StudentData = (props) => {
        const {id, stuId, type} = props;
        const found = data?.find(d => d.referenceId === id);
        return <CpValueStatusBox studentData={getStatus(stuId, found)} type={type}/>
    };

    const AverageScoreContainer = (props) => {
        const {id} = props;
        const found = data?.find(d => d.referenceId === id);
        //return preJS(found);
        if (found) {
            const {averageScore} = found;
            if (averageScore !== 0) {
                return <div className={"field"}><CpChartMiniBar marks={{percentage: averageScore}} type={"average"}/></div>;
            }
        }
        return <div className={"field"}><CpChartMiniBar type={"correctness"}/></div>;
    };
    const CorrectnessContainer = (props) => {
        const {id} = props;
        const found = data?.find(d => d.referenceId === id);
        if (found) {
            const {isCorrectness, correctness} = found;
            if (isCorrectness) {
                return <div className={"field"}><CpChartMiniBar marks={{percentage: correctness}} type={"correctness"}/></div>
            } else {
                return <div className={"field"}></div>;
            }
        }
        return <div className={"field"}><CpChartMiniBar type={"correctness"}/></div>
    };
    const NumCorrectlyContainer = (props) => {
        const {id, type} = props;
        const found = data?.find(d => d.referenceId === id);
        if (type === "metadata") { return <div className={"field"}></div> };
        if (found) {
            const {isCorrectness, numCorrectly} = found;
            const {value, total} = numCorrectly || {};
            if (isCorrectness) {
                return <div className={"field d-flex justify-content-center align-items-center semi-bold"}>{value}/{total}</div>
            } else {
                return <div className={"field"}>-</div>
            }
        }
        return <div className={"field"}>-</div>
    };

    const MetadataContainer = (props) => {
        const {metadata, indent = 0} = props;
        const {id, title, question, children = []} = metadata;
        const {total, list = []} = question;
        const over1 = (total > 1);
        const over0 = (total > 0);
        return <><tr>
                <td className={`sticky-header header-qno`}>
                    <div style={{paddingLeft: `${indent}rem`}} className={"d-flex justify-content-between gap-3 semi-bold"}>
                        <div className={"overflow-auto py-2"}>
                            <span><CpMJX>{title}</CpMJX></span>
                            <div className={"d-flex gap-0"}>
                                {miniLayout? list.map(li => <CpQuestionBox key={li.id} num={`${t("q")}${li.no}`} className={"mx-0"}/>): ''}
                            </div>
                        </div>
                        <div>
                            {over0 && <div className={"field d-flex justify-content-end align-items-center px-3 text-nowrap"}>{total}{t("q")}</div>}
                            {miniLayout? '': list.map(li => <CpQuestionBox key={li.id} num={li.no}/>)}
                        </div>
                    </div>
                </td>
                {miniLayout? '': <td className={"text-center"}>
                    {over1 && <NumCorrectlyContainer id={id} type={"metadata"}/>}
                    {list.map(li => <NumCorrectlyContainer key={li.id} id={li.id} type={"question"}/>)}
                </td>}
                {miniLayout? '': <td>
                    {over1 && <CorrectnessContainer id={id}/>}
                    {list.map(li => <CorrectnessContainer key={li.id} id={li.id}/>)}
                </td>}
                <td>
                    {over0 && <AverageScoreContainer id={id}/>}
                    {miniLayout? '': list.map(li => <AverageScoreContainer key={li.id} id={li.id}/>)}
                </td>
                {miniLayout? '': student.map(stu => <td className={"px-0"} key={stu.id}>
                        {over1 && <StudentData id={id} stuId={stu.id} type={"metadata"}/>}
                        {list.map(li => <StudentData key={li.id} id={li.id} stuId={stu.id} type={"question"}/>)}
                    </td>
                )}
                <td className={"w-100"}></td>
            </tr>
            {children.filter(m => m).map(meta =>
                <MetadataContainer key={meta.id} metadata={meta} indent={indent + 1}/>
            )}
        </>
    };

    return <><table className={`ChartPerformanceMetadataSets report performance-metadata-sets-chart ${miniLayout? "mini-layout" : ""}`}>
            <thead><tr>
                {!miniLayout?
                    <th className={`text-rotate question-no-th ${lang} sticky-header header-qno`}>
                        <div className={"ms-auto me-0"}><span>{t("report-performance-question-no")}</span></div>                        
                    </th> : <th className={`question-no-th ${lang} sticky-header header-qno`}>
                        <div className={"d-flex justify-content-between "}>
                            <div className={""}><span>{miniTitle}</span></div>
                            <div className={"pe-4"}><span>{t("report-performance-question-no")}</span></div>
                        </div>
                    </th>}
                {miniLayout? '': <th className={`text-rotate correctly-th`}><div><span>{t("report-performance-correctly")}</span></div></th>}
                {miniLayout? '': <th className={`text-rotate correctness-th  ${lang}`}><div><span>{t("report-performance-correctness-percentage")}</span></div></th>}
                <th className={`${miniLayout? "ps-3 wider": "text-rotate"} average-th  ${lang}`}><div><span>{t("report-performance-average-percentage")}</span></div></th>
                {miniLayout? '': <StudentHeaders/>}
                <th className={"w-100"}></th>
            </tr></thead>
            <tbody>{metadata.filter(m => m).map(meta =><MetadataContainer key={meta.id} metadata={meta}/>)}</tbody>
        </table></>;
};

export default ChartPerformanceMetadataSets;


