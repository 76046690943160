import {useEffect, useState} from "react";
import {useDebounce} from "./useDebounce";

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<{ width: number | undefined, height: number | undefined }>({
        width: undefined,
        height: undefined,
    });

    const updateWindowSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    const debouncedUpdateWindowSize = useDebounce(updateWindowSize, 100);

    useEffect(() => {
        // Handler to call on window resize


        // Add event listener
        window.addEventListener("resize", debouncedUpdateWindowSize);
        // Call handler right away so state gets updated with initial window size
        updateWindowSize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", debouncedUpdateWindowSize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}