import React, {useMemo, Fragment, useState, useEffect, useRef} from "react";

import { useUILang } from "../../../AppExPf/utils/useUILang";
import CpIco from "../../_components/CpIco";
import { IconList } from '../../../consts/ATIconListToUsePoc';

import CpTableHeaderSortingButton from "./CpTableHeaderSortingButton";
import { preJS, BtnPopDev, autoId, jsStr, BtnDev } from "../../../AppExPf/AppUtil";
import { LegendType } from "../../../poc/type/LegendType";
import ReportLegend from "../../../poc/screens/shared/includes/chart/legend/ReportLegend";
import { useVisDetAll, useVisDetSub } from "../../EPExercise/PageExerciseEdit";
import { stopEvent } from "../../../libs/libUI";
import { objEntries, objKeys, toAry, toInt, toObj, toPercent1, toStr } from "../../../libs/libType";
import CpMJX from "../../_components/CpMJX";
import CpActionButton from "../../_components/CpActionButton";
import { rptMetaSubTree } from "../data/dataUtil";
import { SIZE } from "../../_components/CpExPFConst";
import CpSwitchComponent from "../../_components/CpSwitchComponent";
import { debugMode } from "../../../saga/ReduxState";
import CpKeyPopBox from "./CpKeyPopBox";

export const ChartMetadataKeyStage = (props) => { //poc MetadataProgressChart (v2)
    const [t, uiEn, UILang, setUILang, ut, t2, t3] = useUILang();
    const {id, type, items = [], data} = props
    const { rptClsKs } = props;
    const {clsStuEnts, msets, ms} = props;
    const stuId = props.st;
    const is1Stu = stuId && (stuId !== '*');
    const is1StuName = props?.is1StuName;
    const hasSub = !is1Stu;

    const {SetMetaStuYr3d} = toObj(props.stuMetaDataKS_);
    const {SetMetaStuYr4d} = toObj(props.clsMetaDataKS_);
    const {SetMetaYr3d} = toObj(hasSub? props.clsMetaDataKS_: props.stuMetaDataKS_);

    const rptKeyStage = toObj(props.rptKeyStage);
    const {KSCls2d, KSYears:ksys , KSStus, ..._rptKeyStage} = toObj(rptKeyStage);
    const KSYears = FakeKSMeta? ['2020-2021', '2021-2022', '2022-2023', '2023-2024']: toAry(ksys); 

    const msTrees = useMemo(() => objEntries(SetMetaYr3d).map(([msid, m3d]) => [msid, rptMetaSubTree(toObj(msets), msid, objKeys(m3d))]), [msets, SetMetaYr3d]);

    const [visMark, setVisMark] = useState(1);
    const [visAvg, setVisAvg] = useState(1); 

    const allDVis1 = useVisDetAll(0, 'x 1');
    const [ClickVisAllD, visAllDCmd, setVisAllD, visAllD, scanSub] = allDVis1;
    const clickExp = e => { ClickVisAllD(); }   

    const allDVis2 = useVisDetAll(0, 'x 02');
    const [ClickVisAllD2, visAllDCmd2, setVisAllD2, visAllD2, scanSub2] = allDVis2;
    const clickExp2 = e => { ClickVisAllD2(); }   

    const getLengend = useMemo(() => ( rptClsKs)? LegendType.KEY_STAGE_REPORT_SELECTED_CLASS
        : LegendType.KEY_STAGE_REPORT_SELECTED_STUDENT
    , [type]);
    const getLengend_org = useMemo(() => ( 'a' === type)? LegendType.REPORT_ALL_CLASSES
        :( 'sc' === type)? LegendType.REPORT_SELECTED_CLASS
        :/*( 's' === type)?*/LegendType.REPORT_SELECTED_STUDENT
    , [type]);

    const mset = toObj(msets?.[ms]);
    const title = ut(mset.MSNameEn, mset.MSNameCt) || toStr(ms);

    return <>
<div>
<BtnPopDev txt='misc'>{preJS({stuId, hasSub}, 2)}</BtnPopDev>
<BtnPopDev txt='rptKeyStage'>{preJS(rptKeyStage,2)}</BtnPopDev>

<BtnPopDev txt='SetMetaStuYr3d'>{preJS(SetMetaStuYr3d, 2)}</BtnPopDev>
<BtnPopDev txt='SetMetaStuYr4d'>{preJS(SetMetaStuYr4d, 2)}</BtnPopDev>
<BtnPopDev txt='SetMetaYr3d'>{preJS(SetMetaYr3d, 2)}</BtnPopDev>

<BtnPopDev txt='msets'>{preJS(msets, 2)}</BtnPopDev>
<BtnPopDev txt='msTrees'>{preJS(msTrees, 2)}</BtnPopDev>
<BtnPopDev txt='clsStuEnts'>{preJS(clsStuEnts, 2)}</BtnPopDev>
</div>
{/*<div className={"tar ms-3 ms-lg-0"}>
{ toAry(KSYears).map((y, i) => <div className='w mx8 px4' {...{key:i, style:{color:ksColor(i), borderRadius:'6px', border:'2px solid '+ksColor(i) }}}>{y}</div>) }
</div>*/}
{/*<div className={"tar ms-3 ms-lg-0"}>*/}
<div id={toStr(id)} className="ChartMetadataKeyStage d-flex flex-column align-items-start justify-content-start flex-lg-row justify-content-lg-end gap-3 p-2"> 
    <CpSwitch {...{id:'sw_vis_mark', setter:setVisMark, val:visMark, txt:t("report-view-score") }} />
    <CpSwitch {...{id:'sw_vis_avg', setter:setVisAvg, val:visAvg, txt:t("report-view-class-average-score") }} />
</div>
    {/*<div className={"flow-container scroll-padding"} style={{overflow: "visible"}}>*/}
    <div id="keyChartCont" className={"flow-container scroll-padding"} style={{overflow: "auto", paddingBottom:poph+"px"}}>
        <table className={"report report-table-progress-chart metadata-progress-chart semi-bold"}>
            <thead><tr className="flexRowStart gap-2" style={{paddingLeft:"0.825rem", borderBottom:"1px solid #8c939e"}}>
                {/*<th style={{paddingLeft:0}}>
                    {false && <Button className={expBtnCls} onClick={clickExp}>{t(visAllD?'report-collapse-all': 'report-expand-all')}</Button>}
                </th>*/}
                {/*<th className={"col-metadata"} style={{paddingLeft:"0.825rem", paddingRight:0}}><span>*/}
                <th className={"col-fix-metadata flexRowStart"} style={{overflow:"hidden", paddingLeft:0, paddingRight:0, border:"none"}}>
                    {expf2ExpandBtn(visAllD, clickExp, t)}      
                    <div className="flexRowCenter"><CpTableHeaderSortingButton {...{title, type:"metadata", sorting:false}} /></div>              
                </th>
                {/*selectedStudentCase && <th className={"col-no-of-question-answered"}><span></span></th>*/}
                <th className={"col-fix-average-score-percentage-2"} style={{paddingLeft:0, alignItems:"center", border:"none"}}>
                    <div className="col-average-score-title">
                        {(hasSub)? expf2ExpandBtn(visAllD2, clickExp2, t) :''}
                    </div>                    
                    <div className="flexRowCenter"><CpTableHeaderSortingButton title={t("report-average-score-percentage")} type={"report-average-score-percentage"}
                        tips={<ReportLegend legend={getLengend}/>} sorting={false}/>
                    </div>
                </th>
            </tr></thead>
            <tbody><tr><td colSpan={2 + (rptClsKs? 1: 0)}>
                <div className="flexRowEnd" style={{gap:"15px"}}>
                    { toAry(KSYears).map((y, i) => squareYear(i, ksColor(i), y))}
                </div>
               {_rowMetaKSHead(rptClsKs)}
                {toAry(msTrees).map(([msid, msTree]) => <Fragment {...{key: msid}}>{toAry(msTree.MRoot).map(mid => 
                    <RowMetaKS {...{key:mid, rptClsKs, hasSub, visMark, visAvg, stuId, is1StuName, ut, indent:0, 
                        KSYears, msid, mid, clsStuEnts, SetMetaStuYr3d, SetMetaStuYr3d, SetMetaStuYr4d, SetMetaYr3d, msets, msTree, allDVis1, allDVis2 }} />
                )}</Fragment>)}
               {_rowMetaKSFoot(rptClsKs)}
            </td></tr></tbody>
        </table>      
    </div>
</>;
};

const _rowMetaKSHead = (rptClsKs) => {
    return <div className={"d-flex gap-2 w-100 gap-2"}>
        <div className={"col-fix-metadata mt-2"} style={{paddingLeft: `0rem`}}></div>
        <div className={`col-fix-average-score-percentage-2 selected-class flex-column`}>
            <div className={"d-flex gap-2"}>
                {rptClsKs? <div className="col-average-score-title" ></div>:''}
                <div style={{...cssKS1}}>{[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(i => 
                    <div {...{key: i, style: {position:'absolute', left:(((cssKSBarW * i)/ 100) + cssKSMarX)+'px'}}} >
                        <div {...{key:i, style:{position:'relative', left:'-50%'}}} >{i}</div>
                    </div>)}
                    <div style={{height:'100%', width: (cssKSBarW+2)+'px', marginLeft: (cssKSMarX-1)+'px', borderBottom:'2px solid #8c939e'}} />
                </div>
            </div>
        </div>
    </div>;
};
const _rowMetaKSFoot = (rptClsKs) => {
    return <div className={"d-flex gap-2 w-100 gap-2"}>
        <div className={"col-fix-metadata mt-2"} style={{paddingLeft: `0rem`}}></div>
        <div className={`col-fix-average-score-percentage-2 selected-class flex-column`}>
            <div className={"d-flex gap-2"}>
                {rptClsKs? <div className="col-average-score-title" ></div>:''}
                <div style={{...cssKS1}}>
                    <div style={{width: (cssKSBarW+2)+'px', marginLeft: (cssKSMarX-1)+'px', borderTop:'2px solid #8c939e'}} />
                </div>
            </div>
        </div>
    </div>;
};

const RowMetaKS = (props) => {
    const { ut, indent, rptClsKs, hasSub, visMark, visAvg, stuId, is1StuName, KSYears, msid, mid, clsStuEnts, SetMetaStuYr3d, SetMetaStuYr4d, SetMetaYr3d, msets, msTree, allDVis1, allDVis2} = props;
    const [ClickVisAllD, visAllDCmd, setVisAllD, visAllD, scanSub, setscanSub2, setOnP2, rescan2] = toAry(allDVis2);

    const mset = toObj(msets[msid]);
    const meta = toObj(mset.metas?.[mid]);
    const title = <CpMJX>{meta.nameEn || meta.nameCt }</CpMJX>;

    const MBranchs = msTree?.MBranchs;
    const branch = toAry(MBranchs?.[mid]);

    const canOpen = (branch.length)? 1 :0;
    const [open, setOpen] = useVisDetSub(allDVis1, !canOpen);
    const handleOpen = e => { stopEvent(e); setOpen(!open); rescan2();};

    const [ksData, ksDataAvg] = hasSub
        ? [SetMetaYr3d?.[msid]?.[mid], {}]
        : [SetMetaStuYr3d?.[msid]?.[mid], SetMetaYr3d?.[msid]?.[mid]] ;
    const ksDataSub = SetMetaStuYr4d?.[msid]?.[mid]; //Class KS Stu Datas
    return <>
        <div id={toStr(msid)+','+toStr(mid)} className={"RowMetaKS d-flex gap-2 w-100 gap-2"}>
            <div className={"col-fix-metadata mt-2 align-items-start"} style={{paddingLeft: `${indent * 3}rem`, paddingTop:visMark?"20px":0}}>
                <span className={`${(indent)? "col-header-expand": "" } ${canOpen || (indent)? "me-3": ""}`} style={{minWidth:"20px"}}>
                    {canOpen? <CpExpandBtn open={open} onClick={() => handleOpen()}/>: ''}
                </span>
                <span className={"text-wrap overflow-auto"}>{title}</span>
            </div>
            {/*<div className={"col-fix-no-of-question-answered"}></div>*/}
            <div className={`col-fix-average-score-percentage-2 ${hasSub? "selected-class": ""} flex-column`}>
                <SubRowsMetaKS {...{...props, ut, rptClsKs, visMark, visAvg, hasSub, stuId, is1StuName, KSYears, clsStuEnts, ksData, ksDataAvg, ksDataSub, metaName:title }} />
            </div>
        </div>
        {open? toAry(branch).map(mid => 
            <RowMetaKS {...{key: mid, ut, indent:indent+1, rptClsKs, visMark, visAvg, hasSub, stuId, is1StuName, KSYears, msid, mid, clsStuEnts, SetMetaStuYr3d, SetMetaStuYr4d, SetMetaYr3d, msets, msTree, allDVis1, allDVis2 }} />
        ):''}
    </>
}

const SubRowsMetaKS = (props) => {
    const {ut, hasSub, isSub, stuId, stuInf, is1StuName, rptClsKs, KSYears, visMark, visAvg, isLast, msid, mid, clsStuEnts, ksData, ksDataAvg, ksDataSub, allDVis2, metaName} = props;

    const stuNo = toStr(stuInf?.classNo);
    const sutName = toStr(stuInf?  ut(stuInf.nameEng, stuInf.nameChi) || stuId: '');  

    const sEnt = toAry(ksDataSub && clsStuEnts).map(([stuId, stuInf, ]) => {
        const stData = ksDataSub[stuId];
        return stData && {stuId, stuInf, stData};
    }).filter(o => o);
//console.log('SubRowsMetaKS() sEnt!!', {sEnt, clsStuEnts, ksDataSub});

    const canOpen = hasSub && objKeys(ksDataSub).length;
    const [_subOpen, setSubOpen] = useVisDetSub(allDVis2, !canOpen);
    const subOpen = _subOpen && canOpen;
    const handleSubOpen = e => { stopEvent(e); setSubOpen(!subOpen); };

    const lastS = sEnt.length - 1;
    return <>
        <div id={toStr(msid)+','+toStr(mid)+';'+toStr(stuId)} className={"SubRowsMetaKS d-flex gap-2"} style={{height:"100%"}}>
            {rptClsKs?
            <div {...{className:'col-average-score-title '/*col-average-score-title mini-title*/}}>
                {canOpen
                ?<span className={"col-expand mt-2"}>
                    <span className={"col-header-expand"}><CpExpandBtn open={subOpen} onClick={() => handleSubOpen()}/></span>
                </span>
                :<span className={"col-mini-student text-truncate"} style={{textAlign:"right"}}>
                    <span className={"col-mini-student-no"}>{stuNo}</span>
                    <span className={"col-mini-student-name ms-2"}>{sutName}</span>
                </span>
                }
            </div>
            :''}
            <BarRptKS {...{ut, hasSub, isSub, isLast, visMark, visAvg, clsStuEnts, KSYears, ksData, ksDataAvg, ksDataSub, subOpen, stuNo, sutName, is1StuName, metaName}}/> 
        </div>
        {subOpen? sEnt.map(({stuId, stuInf, stData}, sidx) => 
            <SubRowsMetaKS {...{...props, key:sidx, isLast: (lastS === sidx), visMark, visAvg, isSub:1, clsStuEnts, stuId, stuInf, ksData: stData, ksDataAvg: ksData, ksDataSub:0 }} />
        ): ''}
    </>
}

const cssKSBarW = 600;
const cssKSMarX = 8;
const cssKS1 = {
    //borderLeft: 'solid 2px #999', borderRight: 'solid 2px #999',
    minHeight:'32px', height:'100%',
    width: ((cssKSMarX * 2) + cssKSBarW)+'px',
    position:'relative',
};
const p2px = pc => ((cssKSBarW * pc / 100) + cssKSMarX);


const BarRptKS = (props) => {
    const {hasSub, isSub, isLast, visMark, visAvg, subOpen, stuNo, sutName, is1StuName, metaName} = props;
    const [showPop, setShowPop] = useState(0);    
    const barRef = useRef(null);
    const scrollBarw = 50;
    const isOver = (e) => { 
        if (e) {
            let fx = e.clientX;
            let fy = e.clientY;
            if (barRef.current) {
                //const tb = document.getElementById("keyChartCont");
                //const st = tb ? tb.scrollTop : 0;
                //const ww = window.innerWidth - scrollBarw - (st?scrollBarw:0);
                const ww = window.innerWidth - scrollBarw;
                const wh = window.innerHeight - 25;
                if ((fx + popw) > ww) { fx = ww - popw; }
                //if ((fy + poph) > wh) { fy = fy - poph; }
                const rect = barRef.current.getBoundingClientRect();
                fx = fx - rect.x;
                fy = fy - rect.y;
            };
            setShowPop({x:fx, y:fy});
        } else setShowPop(0);
    };
    //useEffect(()=>{ if (showPop) console.log({x:showPop.x, y:showPop.y}); },[showPop]);

    const KSYears = toAry(props.KSYears);
    const ksData = {...toObj(props.ksData), ...(isSub? FakeKSMeta1: FakeKSMetaAvg), };
    const ksDataAvg = {...toObj(props.ksDataAvg), ...FakeKSMetaAvg, };

    const visDotLine = visAvg && (isSub? hasSub: subOpen);

    const guide = 1;
    
    const deb = jsStr({isSub, hasSub, subOpen, visAvg});

    return <>
<BtnDev txt="BarRptKS">{preJS({sutName, is1StuName, ksDataAvg, ksData,},3)}</BtnDev>            
    <div ref={barRef} className={'BarRptKS '+deb} style={cssKS1}>
        {guide? [10, 20, 30, 40, 50, 60, 70, 80, 90].map(i => _vLine('gvl'+i, i, 1, {border:'1px solid #ebebeb'})):''}
        {guide? [0, 100].map(i => _vLine('gvl2'+i, i, 1, {border:'1px solid #8c939e'})):''}

        {visDotLine? _vDotLines(KSYears, isSub? ksDataAvg: ksData, visMark, isSub? 0 :1, isLast): ''} 
        {/*(visAvg && (!hasSub))? _vLine2s(KSYears, isSub? ksDataAvg: ksData, visMark): ''*/} 

        {/*{visMark? <div style={{position:'relative', marginTop:'6px', height:'32px', }}>*/}
        {visMark? <div style={{position:'relative', marginTop:'6px', height:'28px', }}>
           {toAry(KSYears).map((y, i) => {
                const {cnt, markp} = toObj(ksData[y]);
                return cnt && _numBox('nb'+i, markp, cnt, i);
            }).filter(jsx => jsx)}
        </div>: ''}
        {''?<div style={{position:'relative', height:'32px', }}>{[
//preJS({ksData, ksDataAvg}),
//preJS({KSYears}),
    _vLine2(1, 86, 0),_vLine2(2, 95, 1),_vLine2(3, 90, 2),_vDotLine(4, 90, 2),
        _dot(5, 74, 0),_dot(6, 86, 1),
        _ArrowLines(KSYears, ksData),
]}</div>:''}
        <div className='BarRptKS' style={{position:'relative', height:'24px', marginBottom:'8px'}}>
            {(sutName || is1StuName)?_ArrowLines(KSYears, ksData):''}
            {(isSub && (!visAvg))? _vLine2s(KSYears, ksDataAvg, 0): ''}
            {(sutName || is1StuName)?'':_ArrowLines(KSYears, ksData)}
            {_Dots(KSYears, ksData)}
        </div>
        {_maskBox(KSYears, ksData, isOver)}
        {showPop?<div style={{position:"absolute", top:(showPop.y + 10)+"px", left:showPop.x+"px", zIndex:500}}>
            <CpKeyPopBox {...{stuNo, sutName:(sutName || is1StuName), metaName, KSYears, ksDataAvg, ksData, ksBGC}}/></div>:""}
        {false?<div style={{position:"absolute", top:(showPop.y + 10)+"px", left:showPop.x+"px",
            backgroundColor:"lightgrey", width:popw+"px", height:poph+"px", zIndex:1000}}>
        {"show Pop !!"}{metaName}{sutName}</div>:""}     
    </div>
    </>
};

const _vLine = (key, percent, think, css) => {
    return<div {...{key, className:'_vLine', style:{position:'absolute', width:0, height:'100%', top:0, left:(((cssKSBarW * percent)/ 100) + cssKSMarX - think)+'px', ...css }}} /> 
};

const _vDotLines = (KSYears, ksData, fatRow, isFirst, isLast) => {
    return toAry(KSYears).map((y, i) => {
        const {cnt, markp} = toObj(ksData[y]);
        //return cnt && _vDotLine('vl3'+i, markp / cnt, i, isFirst? (fatRow? 77: 48): 0, isFirst? (fatRow? 28: 55): isLast? (fatRow? 80: 55): 100); 
        return cnt && _vDotLine('vl3'+i, markp / cnt, i, isFirst?(fatRow?'54px':'16px'):'0', isLast? '19px':'0'); 
    }).filter(jsx => jsx);
};
const _vDotLine = (key, percent, colIdx, top, bottom) => {
    const thick = 2;
//    return<div {...{key, style:{position:'absolute', width:0, top: top+'%', height: height+'%', left:(((cssKSBarW * percent)/ 100) + cssKSMarX - toInt(thick/2))+'px', 
//        borderLeft: thick+'px dotted '+ksColor(colIdx), borderTop:'none',borderbottom:'none',borderRight:'none',}}} /> 
    return<div {...{key, className:'Cp_vDotLine', style:{position:'absolute', width:0, top, bottom, left:(((cssKSBarW * percent)/ 100) + cssKSMarX - toInt(thick/2))+'px', 
        borderLeft: thick+'px dotted '+ksColor(colIdx), borderTop:'none',borderbottom:'none',borderRight:'none',}}} /> 
};
const _vLine2s = (KSYears, ksData, fatRow) => {
    return toAry(KSYears).map((y, i) => {
        const {cnt, markp} = toObj(ksData[y]);
        return cnt && _vLine2('vl2'+i, markp / cnt, i, fatRow); 
    }).filter(jsx => jsx);
};
const _vLine2 = (key, percent, colIdx, fatRow) => {
    const thick = 4; //5
    //return<div {...{key, className:'Cp_vLine2', style:{position:'absolute', width:thick+'px', top:fatRow? '15%': '15%', height: fatRow? '70%': '70%', left:(((cssKSBarW * percent)/ 100) + cssKSMarX - toInt(thick/2))+'px', ...ksBGC(colIdx) }}} /> 
    return<div {...{key, className:'Cp_vLine2', style:{position:'absolute', width:thick+'px', top:fatRow? '10%': '10%', height: fatRow? '80%': '80%', left:(((cssKSBarW * percent)/ 100) + cssKSMarX - toInt(thick/2))+'px', ...ksBGC(colIdx) }}} /> 
};

const _Dots = (KSYears, ksData) => {
    return toAry(KSYears).map((y, i) => {
        const {cnt, markp} = toObj(ksData[y]);
        return cnt && _dot('dt'+i, markp / cnt, i); 
    }).filter(jsx => jsx);
}
const _dot = (key, percent, colIdx) => {
    return <div {...{key, className:'Cp_dot', style:{ position: 'absolute', top: '50%', left: p2px(percent)+'px', }}} >
        {/*<div style={{position: 'relative', top: '-7px', left: '-7px', height: '14px', width: '14px', borderRadius: '50%', ...ksBGC(colIdx)}} />*/}
        <div style={{position: 'relative', top: '-6px', left: '-6px', height: '12px', width: '12px', borderRadius: '50%', ...ksBGC(colIdx)}} />
    </div>
};

const _numBox = (key, val, ttl, colorIdx) => {
    const p = toPercent1(val, ttl * 100);
    const color = ksColor(colorIdx);
    return <div {...{key, className:'Cp_numBox', style:{ position: 'absolute', top: '0', left: p2px(p)+'px', }}} >
        {/*<div className='w mx8 px4' style={{position: 'relative', left:'-50%', color, borderRadius:'6px', backgroundColor:'#fff', border:'1px solid ' + color }}>{p}</div>*/}
        <div className='w mx8 px8 py2' style={{position: 'relative', left:'-50%', color, borderRadius:'6px', backgroundColor:'#fff', border:'1px solid ' + color }}>{p}</div>
    </div>;
};

const _ArrowLines = (KSYears, ksData) => {
    const points = [];//FakeKSMeta? [[0, 20], [1, 80], [2, 40]]: [];
    KSYears.forEach((y, i) => {
        const {cnt, markp} = toObj(ksData[y]);
        if(cnt) points.push([i, markp/cnt]);
    });
    const seg = points.length - 1;
    if(seg < 1) return '';
    return <div className='Cp_ArrowLins' style={{height:0, top:'50%', position:'absolute', width:'100%'}}>
    {points.map((p, i) => {
            if(i >= seg) return false;
            const [a, b] = [points[i+1][1], p[1]];
            const w = a > b? a - b: b - a;
            const p1 = (a > b)? b: a;
            const t = -1 - (i * 0);
            //return <div {...{key:i, style:{ position: 'absolute', top: t+'px', height:'3px', ...ksBGC(i),
            return <div {...{key:i, style:{ position: 'absolute', top: t+'px', height:'2px', ...ksBGC(i),
            left: p2px(p1)+'px', width: (w * cssKSBarW / 100)+'px',
        } }} />;
    }).filter(jsx=>jsx)}
    {points.map((p, i) => {
            if(i >= seg) return false;
            const [a, b] = [points[i+1][1], p[1]];
            const s = (a > b)? 1: -1;
            const left = (p2px(a) - 6 - (s * 11))+'px';
            //const l2 = (p2px(a) - 6 - (s * -11))+'px';
            const col = ksBGC(i);
//                <div {...{key:i, style:{ position: 'absolute', ...col, borderRadius:'3px', height:'3px', width:'12px', top:((t)-w)+'px', left:l2, transform: 'rotate('+(s*0.125)+'turn)', } }} />
//                <div {...{key:i, style:{ position: 'absolute', ...col, borderRadius:'3px', height:'3px', width:'12px', top:((-t)-w)+'px', left:l2, transform: 'rotate('+(s*0.375)+'turn)', } }} />
            return <Fragment key={i}>
                {/*<div {...{className:'Cp_ArrowLines', style:{ position: 'absolute', ...col, borderRadius:'3px', height:'3px', width:'12px', top:'-4px', left, transform: 'rotate('+(s*-0.375)+'turn)', } }} />*/}
                <div {...{className:'Cp_ArrowLines', style:{ position: 'absolute', ...col, borderRadius:'3px', height:'2px', width:'9px', top:'-5px', left, transform: 'rotate('+(s*-0.375)+'turn)', } }} />
                <div {...{className:'Cp_ArrowLines', style:{ position: 'absolute', ...col, borderRadius:'3px', height:'2px', width:'9px', top:'3px', left, transform: 'rotate('+(s*-0.125)+'turn)', } }} />
            </Fragment>
            }).filter(jsx=>jsx)}
    </div> 
};

const _maskBox = (KSYears, ksData, isOver=()=>{}) => {
    const info = {max:-1, min:101};
    //const p = toPercent1(val, ttl * 100);
    toAry(KSYears).forEach((y, i) => {
        const {cnt, markp} = toObj(ksData[y]);
        if (cnt) {
            const vv = toPercent1(markp, cnt * 100);
            if (vv > info.max) info.max = vv;
            if (vv < info.min) info.min = vv;
        };
    });
    //{info.min},{info.max} p2px
    const dLeft = 15;
    const dRight = 40;
    const _left = (p2px(info.min) - dLeft) +'px';
    const _width = (p2px(info.max) - p2px(info.min) + dRight) +'px';
    //return <div>{info.min},{info.max},{_left},{_width}</div>
    return <div className='BarRptKSMask' style={{position:'absolute', top:0, height:'100%',
        left:_left, width:_width}} onMouseOver={(e)=>{isOver(e)}} onMouseLeave={(e)=>{isOver(0)}}
        onMouseMove={(e)=>{isOver(e)}}>
    </div>
    /*return <div className='BarRptKSMask' style={{position:'absolute', top:0, height:'100%',
    left:_left, width:_width, border:'1px solid rgba(0,0,255,0.2)'}} onMouseOver={()=>{console.log('mouse over !!')}}>
    </div>*/
};

const expf2ExpandBtn = (visAllD, clickExp, t) => {
    return <><div className="Cp_expf2ExpandBtn btn768Show">
    <CpActionButton title={t(visAllD?'report-collapse-all': 'report-expand-all')} iconPath={visAllD?IconList.general.collapse:IconList.general.expand}
    className={`btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none text-nowrap`}
    onClick={clickExp} hiddenText={true}></CpActionButton>
    </div><div className="btn768Hide"><CpExpandBtn open={visAllD} onClick={clickExp}/></div>
    </>
};

const squareYear = (key, color, txt="") => {
    return <div {...{key, className:"Cp_squareYear flexRowCenterFit", style:{color:"black", gap:"2px"} }}>
        <div style={{width:"15px", height:"15px", backgroundColor:color}}></div><span className="f14">{txt}</span>
    </div>
};

const FakeKSMeta = 1 && debugMode();
const FakeKSMeta1 = FakeKSMeta? {
    '2020-2021': {cnt:1, markp:100},
    '2021-2022': {cnt:1, markp:70},
    '2022-2023': {cnt:1, markp:50},
    '2023-2024': {cnt:1, markp:30}, 
}:{}; 
const FakeKSMetaAvg = FakeKSMeta? {
    '2020-2021': {cnt:1, markp:65},
    '2021-2022': {cnt:1, markp:55},
    '2022-2023': {cnt:1, markp:35},
    '2023-2024': {cnt:1, markp:0}, 
}:{};
const ksColors = [ '#65A3A3', '#FD7F6F', '#FFB55A'];
const ksColor = (i) => ksColors[toInt(i) % 3];
const ksBGC = (i) => ({backgroundColor: ksColor(i)});
const popw = 230;
const poph = 300;

const expBtnCls = 'btn-exercise-third-btn';
const CpExpandBtn = props => <span role={"button"} className={`text-exercise-third-btn metadata-filter-toggle fs-5`} {...props}>
    <CpIco src={props.open? IconList.general.openNode: IconList.general.closeNode}/>
</span>;

export const CpSwitch = (props) => {
    const {id, lock, setter, val, txt} = props;
    const onClick = lock? undefined:  (e => setter(!val));
      //`switch-component d-flex justify-content-center align-items-center`,
    //gapStyle,
    //`${variant ? `cus-switch-${variant}` : ""}`,
    //PREFIX.SWITCH + size,
    //className].filter(item => item).join(' ')
    //<Form.Check type="switch" className={'vam dib mx4 switch-component cus-switch-3 exercise-third-btn' } readOnly={true} />
    //<span className='vam'>{txt}</span>
    return <span id={toStr(id)} className={"CpSwitch vam w mx4 semi-bold user-select-none"} role={"button"} onClick={onClick}>
        <CpSwitchComponent variant={"exercise-third-btn"} size={SIZE.XS} readOnly={true} checked={val} label={txt} />
    </span>
};

/*
<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>SVG Line Example</title>
</head>
<body>
<img src="data:image/svg+xml;utf8,<svg width='100' height='50' xmlns='http://www.w3.org/2000/svg'><line x1='20' y1='25' x2='80' y2='25' stroke='black' stroke-width='3' stroke-linecap='round' /></svg>" alt="Horizontal line with rounded ends">
</body>
</html>
*/