import React, {useState, useMemo} from "react";
import {Button} from "react-bootstrap";

import CpIco from "../_components/CpIco";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { stopEvent } from "../../libs/libUI";
import LayerExNew from "../EPExercise/LayerExNew";
import { ReduxBind } from "../../saga/ReduxState";
import { usePopExer } from "../EPLibrary/PageLibrary";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { toAry } from "../../libs/libType";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { _pathAssign, _pathMyExer, _pathOupEx } from "../../consts/ATConstReact";

const CpShortcutCTA = ReduxBind(props => { // base on poc ShortcutCTA
    const { dispatch } = props;
    const {title, iconPath, type} = props;
    const [ t ] = useUILang();

    const [ cache2d, useGetCache ] = useCaches(props);
    const _subjMetas = useGetCache('subjMetas', '*');
    const subjMetas = useMemo(() => Object.fromEntries(toAry(_subjMetas).map(s => [s.metaSetId+','+s.metaId, s])), [_subjMetas]);  

    const {popExer, setPopExer, goPopExer}= usePopExer(props);
    const [newSrc, setNewSrc] = useState();
    const clickCreate = e => {stopEvent(e); setNewSrc({})};

    const handleOpenShortCut = e => {
        
        //window.location.href is just a demo, it should be switched the route instead of replace the link
        if(type === "check-assignments"){
            dispatch(urlPush_Replace(_pathAssign()));
            //window.location.href = "index.html?type=frontend&frontend=assignment-teacher"
        }else if(type === "explore-exercise-library"){
            dispatch(urlPush_Replace(_pathOupEx()));
            //window.location.href = "index.html?type=frontend&frontend=library&role=teacher"
        }else if(type === "new-exercise"){
            clickCreate(e);
        }else if(type === "view-my-own-exercises"){
            dispatch(urlPush_Replace(_pathMyExer()));
            //window.location.href = "index.html?type=frontend&frontend=exercise&role=teacher"
        };
    };

    return <div role={"button"} className={"shortcut-item"} onClick={() => handleOpenShortCut()}>
        <h4 className={"text-item mb-0 mb-md-3"}>{title}</h4>
        <span className={"icon-item"}><CpIco src={iconPath}/></span>
        <LayerExNew {...{goPopExer, newSrc, setNewSrc, isCopy:0, subjMetas }} />
{/*
        <CpSharedModal show={open} scrollable={true}
            header={<SharedModalHeader title={t("create-new-exercise")} iconPath={IconList.general.add} handleClose={handleClose}/>} 
            footer={<div className={"d-flex gap-2 justify-content-center w-100"}>
                <Button variant="gray-body-color w-100 border" onClick={handleClose}>{t("cancel")}</Button>
                <Button variant="exercise-third-btn btn-block w-100" onClick={handleConfirm}>{t("create")}</Button>
            </div>} 
        >
            <CpExerciseCreator/>
        </CpSharedModal>
*/}
    </div>
});
export default CpShortcutCTA;