import { isAry, toObj, toStr } from "../libs/libType";
import { QP_PP, _MaxLenIds, _MaxLenQId, _MaxLenTodo, _MinLenQId } from "./ATConsts";
import { toUniIdAry, toUniIdsStr, validESASIDStr, validFields, validIdCharsMinMax, validMinMax } from "./ATValidate";

export const normalizedEditATQSet = (fields, opts, trace) => {
    //const track = o => trace && trace.push(o);
    //track('-- normalizedEditATQSet --');

    const fs = toObj(fields);
    
    return {
        //Settings
        QSetId: toStr(fs.QSetId).trim(),
        ESASStr: toUniIdsStr(fs.ESASStr),
        QSLangCt: fs.QSLangCt?1:0,
        QSLangEn: fs.QSLangEn?1:0,
        QSNameEn: toStr(fs.QSNameEn).trim(),
        QSNameCt: toStr(fs.QSNameCt).trim(),
        QSSubjIds: toUniIdAry(fs.QSSubjIds),
        QSKwEn: toUniIdsStr(fs.QSKwEn), 
        QSKwCt: toUniIdsStr(fs.QSKwCt), 

        //Questionss
        QSQIds: toUniIdAry(fs.QSQIds),

        //Meta From Q
        QSMSIds: toUniIdAry(fs.QSMSIds),
        QSMetaFilters: toUniIdAry(fs.QSMetaFilters), 

        QSMSPreIds: toUniIdAry(fs.QSMSPreIds),
        QSMSProIds: toUniIdAry(fs.QSMSProIds),

        QSNoFind: fs.QSReme? 1: 0,
        QSReme: fs.QSReme? 1: 0,

        isNew : fs.isNew?1:0,
    };
};

export const validEditATQSet = (fs, opts, trace) => {
    const track = o => trace && trace.push(o);
    track('-- validEditATQSet --');

    const v = validFields(fs, {});
    //Settings
    v.setIf('QSetId', validIdCharsMinMax(fs.QSetId, _MinLenQId , _MaxLenQId, 'Question Set ID')?
        'The question set id should be within 2 - 50 characters (Alphanumeric or Underscore).':'');
    v.setIf('ESASStr', validESASIDStr(fs.ESASStr)); //validIdCharsMinMax(fs.ESASStr, _MinLenQId , _MaxLenIds, 'ESAS'));
    v.setIf('QSLang', (!(fs.QSLangCt || fs.QSLangEn)) && 'Missing Language');
    v.setIf('QSNameEn', fs.QSLangEn && validMinMax(fs.QSNameEn, 1, _MaxLenTodo, 'Name'));
    v.setIf('QSNameCt', fs.QSLangCt && validMinMax(fs.QSNameCt, 1, _MaxLenTodo, 'Name'));
    v.setIf('QSSubjIds', (!(fs.QSSubjIds?.length)) && 'Missing Subject'); 
    v.setIf('QSKwEn', validMinMax(fs.QSKwEn, 0 , _MaxLenIds, 'Keywords'));
    v.setIf('QSKwCt', validMinMax(fs.QSKwCt, 0 , _MaxLenIds, 'Keywords'));

    //Questionss
    v.setIf('QSQIds', (!fs.QSQIds.length) && 'Missing Questions');

    //Meta From Q
    v.setIf('QSMetaFilters', (!isAry(fs.QSMetaFilters)) && 'Missing Filters');

    track('-- validEditATQSet end --');
    return v.fieldErrs;
};


export const QSetPubErrs = (qset, qsqs) => {
    const v = validFields(qset, {});
    
    //const eqs = qsqs.filter(sq => (sq.p?.drpu !== QP_PP)).map(sq => sq.QId);
    //v.setIf('QSQIds', eqs.length && 'Question Not Published: '+eqs.join(', ') );
    qsqs.forEach(q => {
        const err = QSQErrs(qset, q);
        err && v.set('QSQ'+q.QId, 'Question '+q.QId+': '+err);
    });
    //.filter(e => e).join(', ');
    //v.setIf('QSetId', 'reject publish for dev');
    return v.fieldErrs;
};

export const QSQErrs = (qset, qtn) => {
    const {QSLangEn:en, QSLangCt:ct} = qset;
    const autoOnly = qset.QSReme;
    const p = qtn?.p;
    const err = qtn?(p? [
            (p.drpu !== QP_PP) && 'Not Published',
            ((en && !p.QLangEn) || (ct && !p.QLangCt)) && 'Language mismatch',
            (autoOnly && (!p.autoMark)) && 'Automark Only', 
         ].filter(e => e).join(', ')
        : 'Not Published'
        ): 'Not Exists'; 
    return err;
};
