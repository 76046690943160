import React, {HTMLProps, useEffect, useState} from "react";
import { ReduxBind } from "../../saga/ReduxState";
import { stopEvent } from "../../libs/libUI";
import { Button, CloseButton } from "react-bootstrap";
import CpIco from "../_components/CpIco";
import CpActionButton from "../_components/CpActionButton";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { lang2t, useUILang } from "../../AppExPf/utils/useUILang";
import CpSharedModal from "../_components/CpSharedModal";
import { SIZE, CHECKBOX_VARIANT } from "../_components/CpExPFConst";
import { CpSettingModalTitle, CpSettingModalText } from "../_components/CpSettingModalTabCps";
import CpDatePicker from "../_components/CpDatePicker";
import { formatTimeStamp, formatTimeStampHK, formatTimeStampHKWith8, timeStampGMTToHk, timeStampNowGMT } from '../../libs/libTime';
import CpCheckboxComponent from "../_components/CpCheckboxComponent";
import { actBtnProps } from "./LayerAssignmentEnd";
import { toObj } from "../../libs/libType";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { aTErrDiv1, preJS } from "../../AppExPf/AppUtil";
import { toIdStr } from "../../consts/ATValidate";
import { hasErr } from "../../AppExPf/utils/useEditDraftPub";

export const useAssignmentDead = () => {
    const [deadAsm, setDeadAsm] = useState();
    return {deadAsm, setDeadAsm};
};

export const CpChangeDeadlineCTA = ReduxBind(props => { //base on ChangeDeadlineCTA
    const {dispatch, useAsmDead, asm, reload, ...btnProps} = props;
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {setDeadAsm} = useAsmDead; 
    const clickAsmDead = e => { 
        
        stopEvent(e); setDeadAsm({asm, reload}); 
    };
    return <CpActionButton title={t("assignment-cta-change-deadline")} iconPath={IconList.assignment.changeDeadline}
        className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={clickAsmDead} {...actBtnProps(btnProps)} />;
});

const DEADLINE = "DEADLINE"; // todo remove dup define
const END_NOW = "END_NOW"; // todo remove dup define
const NO_DEADLINE = "NO_DEADLINE"; // todo remove dup define

const LayerAssignmentChange = ReduxBind(props => { //base on ChangeDeadlineCTA
    const {dispatch, useAsmDead, ...btnProps} = props;
    const [fieldErrs, setFieldErrs] = useState({});
    const [t, uiEn, lang, setLang, ut] = useUILang();
    
    const {deadAsm:_deadAsm, setDeadAsm}  = useAsmDead; 
    const {asm: deadAsm, reload} = toObj(_deadAsm);

    const [deadLine, setDeadLine] = useState(DEADLINE);
    const [deadDGMT, setDeadGMT] = useState(0);

    const ex = toObj(deadAsm?.Exer);
    const assignId = toIdStr(deadAsm?.assignId);
    const AName =  lang2t(deadAsm?.ALang, ex.ENameEn, ex.ENameCt);
    
    const isDie = deadAsm?.timeEnd;
    const nowTime = timeStampNowGMT();   //timeStampNowGMT(); //new Date().getTime();
    const initTime = isDie? deadAsm?.time2beEnd: 0;

    useEffect(()=>{
        setFieldErrs({});
        if(deadLine === DEADLINE && deadDGMT){
            
            //setDeadGMT[deadASm..... or now] 1 initDate deadAsm
            if (deadDGMT < nowTime)
                setFieldErrs(es => ({...toObj(es), ErrDeadTime:t("error.error_invalid_deadline")})); // 2 move to check before submit
        }
    }, [deadDGMT,deadLine]);

  useEffect(()=>{
        if(deadAsm){
            setDeadLine(isDie? DEADLINE: NO_DEADLINE);
            //setDeadGMT[deadASm..... or now] 1 initDate deadAsm
            //setFieldErrs(es => ({...toObj(es), ErrDeadTime:'Deadline cannot before StartTime' })); // 2 move to check before submit
        }
    }, [deadAsm]);

    const clickAsmDead = async e => { stopEvent(e);
        //const errs = {};
        //errs.ErrDeadTime = 'Deadline cannot before StartTime again';
        //setFieldErrs(errs);     
        if(hasErr(fieldErrs)) return;
        
        const [res, err] = await asyncApiCallLoad_(dispatch, '/putAssignmentUpdate', {assignId, deadLine, deadDGMT });
        reload(); //onDeadAsm();
        setDeadAsm(0);
    };

    const clickClose = e => { stopEvent(e); setDeadAsm(0); setFieldErrs({}); setDeadLine("");};
    const clickChgDdline = e => {
        
        stopEvent(e); setDeadLine(DEADLINE); };
    const clickChgEndNow = e => {
        
        stopEvent(e); setDeadLine(END_NOW); setDeadGMT(nowTime); };
    const clickChgNoDdline = e => {
        
        stopEvent(e); setDeadLine(NO_DEADLINE); setDeadGMT(nowTime); };
    
    const [die_now, die_line, die_no] = [ deadLine === END_NOW, deadLine === DEADLINE, deadLine === NO_DEADLINE];
    return <CpSharedModal show={deadAsm} scrollable={false} overflowBody={false}
        footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
            <Button variant="gray-body-color w-100 border" onClick={clickClose}>{t("cancel")}</Button>
            <Button variant={`exercise-third-btn btn-block w-100`} onClick={clickAsmDead}>{t("update")}</Button>
        </div>}>
        <div className={"px-3 pt-4 bg-dim-100 rounded-top"}>
            <div className={"d-flex flex-column gap-4"}>
                <div className={"d-flex flex-column gap-3 w-100"}>
                    <div className={"d-flex justify-content-between"}>
                    <span className={"d-flex justify-content-end opacity-0 pe-none"}><CloseButton onClick={clickClose}/></span>
                        <span className={"d-flex justify-content-center text-exercise-third-btn fs-1"}>
                            <CpIco src={IconList.assignment.changeDeadline}/></span>
                        <span className={"d-flex justify-content-end"}><CloseButton onClick={clickClose}/></span>
                    </div>
                    <div className={"d-flex w-100 fs-4 semi-bold justify-content-center user-select-none text-center"}>{AName}</div>
                </div>
                <div className={"d-flex flex-column align-items-start gap-3 mb-4 px-3"}>
                    <CpSettingModalTitle><span>{t("assignment-change-deadline-message")}:</span></CpSettingModalTitle>
                    <div className={"d-flex flex-column gap-3 align-items-start flex-wrap ms-3 ms-lg-0 w-100"}>
                        <span role={"button"} className={"d-flex flex-column justify-content-center align-items-center gap-3"} onClick={clickChgDdline}>
                            <div className={`d-flex w-100 align-items-center gap-3`}>
                                <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={die_line}
                                    checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                                {t("assignment-settings-options-deadline")}
                            </div>
                            <div className={`d-flex w-100 align-items-center gap-3`}>
                                <span>{t("assignment-settings-to")}:</span>
                                <CpDatePicker {...{dateTime:(initTime),title:t("datepicker-select-a-day-time"), cb: setDeadGMT, iconPath:IconList.general.calendar,
                                    disable: (!die_line), showEmpty:(!die_line), behind2min:(isDie?0:1)}} />
                            </div>
                            {aTErrDiv1(fieldErrs?.ErrDeadTime)}
                        </span>
                        <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={clickChgEndNow}>
                                <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={die_now}
                                checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                            <CpSettingModalText>{t("assignment-settings-options-end-now")}</CpSettingModalText>
                        </span>
                        <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={clickChgNoDdline}>
                                <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={die_no}
                                checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                            <CpSettingModalText>{t("assignment-settings-options-no-deadline")}</CpSettingModalText>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </CpSharedModal>
});

export default LayerAssignmentChange;

