import { maxVal, minVal, toInt, toObj, toStr } from "../libs/libType";
import { _MaxLenTodo, langCodeCt } from "./ATConsts";
import { toIdStr, validFields, validMinMax } from "./ATValidate";
import { validMSId } from "./ATValidateMetaSet";

export const RemeLvSame = 'ML_SAME';
export const RemeLvPre = 'ML_PRE';
export const RemeLvPro = 'ML_PRO';
export const RemeLvALL = 'ML_*';

export const normalizeRemeRule = fields => {
    const {errs, ...fs} = toObj(fields);
    return {
        //...fs,
        //"isAdd": 1,
        ruleNameEn: toStr(fs.ruleNameEn).trim(),
        ruleNameCt: toStr(fs.ruleNameCt).trim(),

        rmLv: [RemeLvSame, RemeLvPre, RemeLvPro].includes(fs.rmLv)? fs.rmLv: RemeLvSame,
        MSetId: toIdStr(fs.MSetId),
        rmMark: toInt(fs.rmMark),

        rmAB: ['a','b'].includes(fs.rmAB)? fs.rmAB: 'a',
        rmPass: toInt(fs.rmPass),

        modUid: toIdStr(fs.modUid),
    };
};

const validRRName = (str) => validMinMax(toStr(str), 1, 300, 'Metadata Set Name');

export const validateRemeRule = (fs, opts, trace) => {
    const track = o => trace && trace.push(o);
    track('-- validEditATMetaSet --');
    const v = validFields(fs, {});

    (opts?.showEn)?
        v.setIf('ruleNameEn', validRRName(fs.ruleNameEn) && 'error.error_no_rule_name'):
        v.setIf('ruleNameCt', validRRName(fs.ruleNameCt) && 'error.error_no_rule_name');

    v.setIf('MSetId', validMSId(fs.MSetId));// && 'error.error_no_metadata');   

    //error.error_no_question_score ?
    v.setIf('rmMark', (minVal(100, maxVal(0, fs.rmMark)) !== fs.rmMark) && 'error.error_no_attempted_score');   
    v.setIf('rmPass', (minVal(100, maxVal(0, fs.rmPass)) !== fs.rmPass) && 'error.error_no_mastery_score');   
   
    track('-- validEditATMetaSet end --');
    return v.fieldErrs;
};