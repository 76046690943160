import { isAry, maxVal, minVal, objEntries, objKeys, objVals, toAry, toFaction1, toFactionF, toFix1, toFloat, toInt, toObj, toPercent1, toStr } from "../../../libs/libType";
import { addAsmSum, rptMetaFullTree, rptMetaSubTree, rptMName, sortRptMs, } from "./dataUtil";

export const toClsMetaData_ = (rptStuMetas, _mj, _mtype, _msid) => {
  
  const stuMset2d = {};
  toAry(rptStuMetas).forEach(rsm => {
    const loop1 = (sid, ms2d) => { 
      const msets = stuMset2d[sid] || {};
      const looplb = (msid, ms) => {
        const mset = msets[msid] || {};
        objEntries(ms).map(([mid, s]) => {
          const d = mset[mid] || {cnt:0, markp:0, maxp:0, minp:100};
          d.cnt += s.cnt;
          d.markp += s.markp;
          d.maxp = maxVal(d.maxp, s.maxp);
          d.minp = minVal(d.minp, s.minp);
          mset[mid] = d; 
        }); 
        msets[msid] = mset;
      };
      
      //objEntries(ms2d).map(([i,s]) => loop1b(i,s));
      looplb(_msid, ms2d[_msid]);
      stuMset2d[sid] = msets;
    };

    const sm2d = rsm?.stuMset2d;
    objEntries(sm2d).forEach(([i, s]) => loop1(i, s));
  });
  

  const clsMsets = {}; 
  objEntries(stuMset2d).forEach(([sid, ms2d]) => { 
    const loop2 = (msid, ms) => { 
      const mset = clsMsets[msid] || {};
      objEntries(ms).map(([mid, s]) => {
        const d = mset[mid] || {cnt:0, markp:0, maxp:0, minp:100};
        const markp = toFaction1(s.markp, s.cnt);
        d.cnt++;
        d.markp += markp;
        d.maxp = maxVal(d.maxp, markp);
        d.minp = minVal(d.minp, markp);
        mset[mid] = d; 
      }); 
      clsMsets[msid] = mset;
    };
    //objEntries(ms2d).map(([i,s]) => loop2(i,s));
    loop2(_msid, ms2d[_msid]);
  });
  
  return { clsMsets, stuMset2d }; 
};


export const toClsMetaData1 = (clsMetaData_, clsStudents,  _mj, _mtype, _msid, tagMSets, ut) => {
  const clsMsets = toObj(clsMetaData_?.clsMsets);
  const stuMset2d = toObj(clsMetaData_?.stuMset2d); //const stuEnts = objEntries(stuMset2d);
  const stuNameEnts = objEntries(clsStudents).sort(([i, a],[j, b]) => (toInt(a?.classNo) - toInt(b?.classNo))); 

//console.log('toClsMetaData1()a', {clsMsets, stuMset2d, stuNameEnts});

  //const msids = objKeys(clsMsets);
  const msets = toObj(tagMSets); //! toObj(>useTagMSets(((msids));
  const mset = toObj(msets[_msid]);

  const d2d = objEntries(clsMsets).map(([msid, mSums]) => {
    //const { mset, mids, metas, MRoot, MBranchs } = rptMetaFullTree(msets, msid); 
    const { mset, mids, metas, MRoot, MBranchs } = rptMetaSubTree(msets, msid, objKeys(mSums));
//console.log('toClsMetaData1()b', {mset, mids, metas, MRoot, MBranchs});
  
    const makeNode = mid => {
        const msum = toObj(mSums[mid]);
        const {cnt, markp, minp, maxp} = msum;
        const m = metas[mid];
        const avgP2 = toFactionF(markp, cnt);
        const subAverageScore 
        = stuNameEnts.map(([stuId, sname], i) => { 
          const msum = stuMset2d?.[stuId]?.[msid]?.[mid];
          if(!msum) return {
            id: stuId,//refId+','+msid+','+mid,//"m-001-01", 
            referenceId: stuId,
            _rnum: i,
          };
          const {cnt, markp, maxp, minp} = msum;
          const avgP = toFactionF(markp, cnt);
          return {
            id: stuId, //refId+','+msid+','+mid,//"m-001-01", 
            referenceId: stuId,
            _rnum: i,
            scoreRange: { 
              //minPercentage: toFix1(minp), 
              //maxPercentage: toFix1(maxp), 
              status: (avgP2 > avgP)? 'f': 'p' ///MYavg > GPavg  
            },
            averageScore: { percentage: toFix1(avgP)},
            _solid: 1,
          }
        }).filter(s => s);
        const _subSolid = subAverageScore.some(s => s._solid)
        const mret = {
            id: msid+','+mid,
            title: rptMName(m, ut, mid),
            _rnum: toInt(m.rnum),
            subAverageScore: subAverageScore,
            _subSolid
        };
        if(_subSolid){
          Object.assign(mret, {
            scoreRange: { minPercentage: toFix1(minp), maxPercentage: toFix1(maxp), status: "s" },
            averageScore: { percentage: toFix1(avgP2) },
          });
        }
        if(MBranchs[mid]?.length) mret.children = sortRptMs(toAry(MBranchs[mid]).map(makeNode)); 
        return mret;
    };
    return sortRptMs(MRoot.map(makeNode));
  });

  const items = objEntries(clsStudents).map(([id, s]) => {
    return { id, no: toStr(s.classNo), title: ut(s.nameEng, s.nameChi), name: ut(s.nameEng, s.nameChi), };
  });
  return {
    id: '*mset id',
    title: ut(mset.MSNameEn, mset.MSNameCt), 
    type: 'sc',
    data: [].concat(...d2d),
    items,
  };
  //return MetadataProgressBySelectedClassTeacherData; 
};


export const toClsMetaData1v1 = (clsMetaData_, clsStudents, j, mt, ms, tagMSets, ut) => {
  const clsMsets = toObj(clsMetaData_?.clsMsets);
  const stuMset2d = toObj(clsMetaData_?.stuMset2d);
  
  //const msids = objKeys(clsMsets);
  const msets = tagMSets; //>useTagMSets(((msids);

  const d2d = objEntries(clsMsets).map(([msid, mSums]) => {
    const { mset, mids, metas, MRoot, MBranchs } = rptMetaSubTree(msets, msid, objKeys(mSums));
  
    const makeNode = mid => {
        const msum = toObj(mSums[mid]);
        const {cnt, markp, minp, maxp} = msum;
        const m = toObj(metas[mid]);
        const avgP = toFloat(toPercent1(markp, cnt));
        const mret = {
            id: msid+','+mid,
            title: rptMName(m, ut, mid),
            scoreRange: {
              minPercentage: toFix1(minp),
              maxPercentage: toFix1(maxp),
              status: "s"
            },
            averageScore: { percentage: avgP },
        };
        if(MBranchs[mid]?.length) mret.children = (MBranchs[mid]).map(makeNode); 
        return mret;
    };

    return MRoot.map(makeNode);
  });
  const data = [].concat(...d2d);

  const items = objEntries(clsStudents).map(([id, s]) => {
    return { id, no: toStr(s.classNo), name: ut(s.nameEng, s.nameChi), };
  });

  //return MetadataProgressBySelectedClassTeacherData; 
  return {
    items,
    id: '*mstop',
    title: 'mset type',
    type: 'sc',
    data
  };
};


export const MetadataProgressBySelectedClassTeacherData = {
  "items": [
    {
      "id": "s-001",
      "title": "Eric Yau",
      "no": "01"
    },
    {
      "id": "s-002",
      "title": "Wing Tang",
      "no": "02"
    }
  ],
  "id": "m-001",
  "title": "Curriculum",
  "type": "sc",
  "data": [
    {
      "id": "m-001",
      "title": "Compulsory",
      "scoreRange": {
        "minPercentage": 20,
        "maxPercentage": 80,
        "status": "s"
      },
      "averageScore": {
        "percentage": 35
      },
      "children": [
        {
          "id": "m-001-01-01",
          "title": "Number and Algebra Strand",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "s"
          },
          "averageScore": {
            "percentage": 35
          },
          "children": [
            {
              "id": "m-001-01-01-01",
              "title": "1. Quadratic equation in one unknown",
              "scoreRange": {
                "minPercentage": 20,
                "maxPercentage": 80,
                "status": "s"
              },
              "averageScore": {
                "percentage": 35
              },
              "children": [
                {
                  "id": "m-001-01-01-01-01",
                  "title": "1.1 solve quadratic equations by the factor method",
                  "scoreRange": {
                    "minPercentage": 20,
                    "maxPercentage": 80,
                    "status": "s"
                  },
                  "averageScore": {
                    "percentage": 35
                  },
                  "children": [
                    {
                      "id": "m-001-01-01-01-01-01",
                      "scoreRange": {
                        "minPercentage": 20,
                        "maxPercentage": 80,
                        "status": "p"
                      },
                      "averageScore": {
                        "percentage": 35
                      },
                      "referenceId": "s-001"
                    },
                    {
                      "id": "m-001-01-01-01-01-02",
                      "scoreRange": {
                        "minPercentage": 20,
                        "maxPercentage": 80,
                        "status": "f"
                      },
                      "averageScore": {
                        "percentage": 35
                      },
                      "referenceId": "s-002"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "m002",
      "title": "Module 1",
      "scoreRange": {
        "minPercentage": 20,
        "maxPercentage": 80,
        "status": "p"
      },
      "averageScore": {
        "percentage": 35
      }
    },
    {
      "id": "m003",
      "title": "Module 2",
      "scoreRange": {
        "minPercentage": 20,
        "maxPercentage": 80,
        "status": "s"
      },
      "averageScore": {
        "percentage": 35
      },
      "children": [
        {
          "id": "m003-01",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "f"
          },
          "averageScore": {
            "percentage": 35
          },
          "referenceId": "s-001"
        },
        {
          "id": "m003-02",
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "p"
          },
          "averageScore": {
            "percentage": 35
          },
          "referenceId": "s-002"
        }
      ]
    }
  ]
};