import React, { useEffect, useState, useMemo } from 'react';
import {secondToHHMMSS} from '../../libs/libTime';
import { toInt } from '../../libs/libType';
import { timeStampNowGMT } from '../../libs/libTime';
import CpActionButtonComponent from '../../AppExPFUser/_components/CpActionButtonComponent';
import { countDown } from '../../consts/ATSysQType';
//import { previewRemainTime } from '../../consts/ATConsts'; 

const CpQtnTimer = (props) => {
    //timeUsed no use right now
    const {hasTimeLimit=0, startTime=0, timeUsed=0, remainTime=-1} = props;
    const showCountDown = useMemo(()=> {
        return (countDown && hasTimeLimit && (remainTime > 0))
    },[hasTimeLimit,remainTime]);
    const [count, setCount] = useState(
        showCountDown?(toInt(remainTime/1000))
        :(startTime?toInt((timeStampNowGMT()-startTime+timeUsed)/1000):0)
    );
    
    useEffect(()=>{
        //console.log('countDown, startTime, remainTime, timeUsed',countDown, startTime, remainTime, timeUsed);
        //console.log("------ timer info:",{hasTimeLimit, startTime, timeUsed, remainTime});        
        const nowT = timeStampNowGMT();
        const st = (startTime <= 0)?nowT:startTime;
        //const interval = setInterval(() => setCount(prevCount => prevCount + 1), 1000);
        //count up
        let doInterval;
        //console.log("showCountDown",{showCountDown, st, startTime, remainTime});
        if (showCountDown) {
            //count down
            doInterval = setInterval(() => {
                //const rr = toInt((st+remainTime-timeStampNowGMT())/1000);
                const rr = toInt((nowT+remainTime-timeStampNowGMT())/1000);
                //console.log("rr", rr);
                setCount(rr > 0 ? rr : 0);
            }, 1000);
        } else {
            doInterval = setInterval(() => setCount(toInt((timeStampNowGMT()-st+timeUsed)/1000)), 1000);
        };
        return () => clearInterval(doInterval);
    },[])
   
    return <><CpActionButtonComponent variant={""}
        className={"text-preview-primary bg-white pe-none"}
        title={secondToHHMMSS(count)} truncate={false}/></>
};

export default CpQtnTimer;
/*
    return <div className='QtnTimerContainer'>
        {secondToHHMMSS(count)}
    </div>
*/
/*
export const useQtnTimer = (sCount) => {
    const [timerCount, setTimerCount] = useState(toInt(sCount));
    //const [myTimer, setMyTimer] = useState(null);

    useEffect(()=>{
        
    },[timerCount])

    const startTimer = (startTime) => {
        const timer = setInterval(() => {
            const tt = sCount+toInt((timeStampNowGMT()-startTime)/1000);
            
            setTimerCount(tt)}, 1000);
            //setCount(prevCount => prevCount + 1)}, 1000);
        return timer;
    };

    const stopTimer = (timer) => { 
        if (timer) {
            
            clearInterval(timer);
        };
    };

    const currentTimeDisplay = (t) => {return <div className='QtnTimerContainer'>
        {secondToHHMMSS(toInt(t))}</div>};

    //const currentTime = () => {return count};
    return [timerCount, currentTimeDisplay, startTimer, stopTimer];
};
*/