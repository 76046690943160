import React, { useMemo, useState, useEffect } from "react";
import { CloseButton, Collapse, Modal } from "react-bootstrap";

import * as UI from '../../libs/libUI';

import { useCtnLang, useUILang } from "../../AppExPf/utils/useUILang";
import { useResizeEffect, useResizeMemo, winSize } from "../../AppExPf/utils/useWinResize";
import { _ATMetaTypeByCode } from "../../consts/ATMetaTypes";
import { objEntries, objKeys, objVals, toAry, toInt, toObj, toStr, tryParseJson } from "../../libs/libType";
import { ReduxBind } from "../../saga/ReduxState";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import TabExercise from "./TabExercise";
import { CpExerciseCard } from "./CpExerciseCard";
import CpDropdown, { ary2DropdownItem } from "../_components/CpDropdown";
import CpExerciseTabletListFilterCTA from "../EPLibrary/CpExerciseTabletListFilterCTA";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";

const TabExercises = ReduxBind(props => { //base on <SavedCollectionSearchPage >> MyExerciseTab
    const {dispatch, tabTitle, showFilter, setParaDel, setParaNew, 
        sect, ESharerIds, ESetId, EId, eset, pageEIds, exers, 
        reload, ESTagMSets, tagMSets, useTagMSets, 
        exFilters, setExFilters, jItems, jFilter, setJFilter, 
        qtns, useCacheQtns,  
        mediaDLs, getMediaDLs, 
        eLikes, setELikes,
    } = props;

    const [t, uiEn, UILang, setLang, ut] = useUILang();
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(props.ctnL||UILang);

    const [ww, wh] = useResizeMemo(); 
    const bigWin = (ww >= 768); 
    
    const baseUrl = toStr(props.baseUrl);  
    const goEId = EId => dispatch( urlPush_Replace(baseUrl+'/'+EId)); 
    const cur = EId;
    const pickEx = EId => goEId(EId);
    const clickEx = EId => e => {UI.stopEvent(e); goEId(EId); };

    const [ex2, setEx2] = useState();
    const ex = exers[EId] || (ex2?.EId === EId)? ex2: 0;
    const title = ct(ex?.ENameEn, ex?.ENameCt);

    const loadPeekEx = async () => {
        if(ex || (!EId)) return; 
        
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getExers', { EIds: [EId], caller: 'TabExersPeek', });
        setEx2(res?.exers?.[0]);
    };
    useEffect(()=>{ EId && loadPeekEx() }, [EId]);

    //case PAGE.LIBRARY_PAGE:
    const tabProps = {
        ESTagMSets, exFilters, setExFilters, reload,
        tagMSets, useTagMSets, 
        t, ct, ctnL, showEn,  
        sect, ESharerIds, ESetId, eset,  
        EId, cur, pickEx, clickEx, 
        qtns, useCacheQtns, mediaDLs, getMediaDLs, 
        exers, 
        eLikes, setELikes, 
        setParaDel, setParaNew
         };

    const FilterBar = <div className={"exercise-library-filter-container px-3 d-flex flex-column flex-md-row justify-content-between align-items-md-end w-100"}>
        <span className={"title d-flex flex-column justify-content-end semi-bold"}>{tabTitle?toStr(tabTitle)+':':''}</span>
        <span className={"text-start text-md-end d-flex flex-row justify-content-center align-items-center gap-2"}>
            <CpDropdown items={jItems} idx={jFilter} cb={setJFilter} className={"w100 dropdown-exercise-subject-theme"}/>
            <CpExerciseTabletListFilterCTA  {...tabProps} />
        </span>
    </div>;

    const onHidePreview = () => { goEId(""); }
    const clickCloseModal = e => { UI.stopEvent(e); goEId(""); };
    const peekExView = EId && <TabExercise {...{...props, ...tabProps, ex}} />
    
    
    return <>
        {showFilter? FilterBar: ''}
        {showFilter? <hr className={"mt-2 mb-3 mx-3"}/>: ''} 
        <div className={"table-data-container d-flex gap-2 px-3 flex-grow-1"}>
            <div className={"table-list-container d-flex flex-column gap-2 gap-md-3 pb-md-3 scroll-padding"}>
                {pageEIds.map(EId => {
                    const ex = exers[EId];
                    return ex && <CpExerciseCard key={EId} {...{ex, idx:EId, ...tabProps }} />;
                }).filter(x => x)}
            </div>
            { bigWin?
            <div key={EId} className={`table-preview-container d-none d-md-flex flex-column overflow-hidden border border-2 rounded ${EId? "border-exercise-third-btn" : ""}`} >
                {peekExView}
            </div>:
            <Modal show={EId} fullscreen={true} onHide={onHidePreview} className={"side-from-bottom p-0"}>
                <Modal.Header className={`text-white py-2 bg-sidenav-primary`}>
                    <Modal.Title as={"h5"} className={"text-truncate"}>{title}</Modal.Title>
                    <div className={"d-flex justify-content-center align-items-center gap-3"}><CloseButton variant="white" onClick={clickCloseModal}/></div>
                </Modal.Header>
                <Modal.Body className={"h-100 overflow-hidden p-0"}>{peekExView}</Modal.Body>
            </Modal>    
            }
        </div>
    </>
});
export default TabExercises; // MyExerciseTab

