import {useState} from "react";

import ActionButtonComponent from "./CpActionButtonComponent";
import { useUILang } from "../../AppExPf/utils/useUILang";
import CpModalComponent from "./CpModalComponent";
import { SIZE } from "./CpExPFConst";

const CpPreviewButtonComponent = (props) => { // base on poc PreviewButtonComponent.jsx
    const {
        className = "",
        wideMode = true,
        children,
        title = "",
        icon = "",
        variant,
        size = SIZE.MD,
        downloadSource = "",
        ...buttonProps
    } = props;
    const [t] = useUILang();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const openModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShow(prev => !prev);
    };

    return <>
        <ActionButtonComponent variant={variant} title={title} icon={icon} size={size}
            truncate={false} onClick={(e) => {
            e.preventDefault(); e.stopPropagation(); openModal(e);
        }} className={className}/>
        {show? children(handleClose): ''}
    </>
};
export default CpPreviewButtonComponent;

const CpPreviewButtonComponent2 = (props) => { // base on poc PreviewButtonComponent.jsx
    const {
        className = "",
        wideMode = true,
        children,
        title = "",
        icon = "",
        variant,
        size = SIZE.MD,
        downloadSource = "",
        ...buttonProps
    } = props;
    const [t] = useUILang();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const openModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShow(prev => !prev);
    };

    return <>
        <ActionButtonComponent variant={variant} title={title} icon={icon} size={size}
            truncate={false} onClick={(e) => {
            e.preventDefault(); e.stopPropagation(); openModal(e);
        }} className={className}/>
        <CpModalComponent title={t('preview')} wideMode={wideMode} show={show} onHide={handleClose}
            handleClose={handleClose} titleColor={"bg-preview-primary"} downloadSource={downloadSource}>
            {children}
        </CpModalComponent>
    </>
};
