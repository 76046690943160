import React, { useState, useEffect } from "react";
import { toInt, toStr } from "../../libs/libType";
import * as UI from '../../libs/libUI';
import { useStateGST } from "../../saga/globalState.saga";
import { cpATIcoBtn_ } from "../components/CpATIcoBtn";

const normailzPaging = (val, totalRow) => {
  var {rpp, page} = val;
  
  rpp = toInt((rpp > 1)? rpp: 1);
  totalRow = toInt((totalRow > 0)? totalRow: 0);
  var totalPage = Math.ceil(totalRow / rpp); 
  page = toInt((page < 2)? 1: (page > totalPage)? totalPage: page);
  

  const res1 = maxVal(0, minVal((1 + ((page - 1) * rpp)), totalRow));
  const res2 = minVal(res1 + rpp - 1,  totalRow);
  
  return {rpp, totalPage, page, res1, res2};
};

export  const usePaging = (totalRow, rpp, page) => {
  
  const [_paging, _setPaging] = useState(({rpp, page}));
  const setPaging = (val) => {
    const newPage = ( {..._paging, ...val, totalRow})  
    _setPaging(newPage); 
    return newPage;
  };
  
  return {...normailzPaging(_paging,  totalRow), totalRow, setPaging};
};

export  const usePagingGST = (props, gstKey, totalRow, rpp, page) => {
  const [_paging, _setPaging] = useStateGST(props, gstKey, ({ rpp, page}));
  const setPaging = (val) => {
    const newPage = ( {..._paging, ...val, totalRow});
    _setPaging(newPage);
    return newPage
  };
  return {...normailzPaging(_paging, totalRow), setPaging};
};


const cssBlueBox = {borderRadius:'2px', border:'2px solid #48c', padding:'2px', margin:'2px'};
const cssTPage = {height:'26px', border:0, outline:'2px solid #48c', display:'inline-block', width:'60px', padding:'2px', margin:'2px'};

export const ATPaging = (props) => {
    const paging = props.paging;
    const {page, totalPage, rpp, totalRow, setPaging} = paging;
    const [tpage, setTPage] = useState(toStr(page));
    
    useEffect(()=>{setTPage(page)}, [page] );
  
    const newRpp = UI.stopEventThen( e => setPaging({rpp: toInt(e.currentTarget.value)}) );
  
    const [notFirst, notLast] = [(page > 1), (page < totalPage)]; 
    return (
    <div className='flexRowEndFit'>
      <select style={{margin:'0 4px'}} value={rpp} onChange={newRpp} >
        <option value="10" >10</option>
        <option value="25" >25</option>
        <option value="50" >50</option>
        <option value="100" >100</option>
      </select>
      <span style={{margin:'0 4px 0 0'}}>Results / Page</span>
      <div style={cssBlueBox}>{cpATIcoBtn_('', '|<', (notFirst) && UI.stopEventThen( e => {setPaging({page: 1})} ) )}</div>
      <div style={cssBlueBox}>{cpATIcoBtn_('', '<',  (notFirst) && UI.stopEventThen( e => {setPaging({page: page-1})} ) )}</div>
      <div>page{UI.EpInputTxt0(tpage, setTPage, 'txtPage', '', cssTPage)}{' / '}{totalPage}</div>
      <div style={cssBlueBox}>{cpATIcoBtn_('', 'Go', UI.stopEventThen( e => {setPaging({page: toInt(tpage)})} ) )}</div>
      <div style={cssBlueBox}>{cpATIcoBtn_('', '>',  (notLast) && UI.stopEventThen( e => {setPaging({page: page + 1})} ))}</div>
      <div style={cssBlueBox}>{cpATIcoBtn_('', '>|', (notLast) && UI.stopEventThen( e => {setPaging({page: totalPage})} )  )}</div>
    </div> );
};


const minVal = (a, b) => ((a > b)?b:a);
const maxVal = (a, b) => ((a < b)?b:a);

  
