import {CloseButton, Modal, ModalProps} from "react-bootstrap";
import React, {ReactNode} from "react";
import { toStr } from "../../libs/libType";

import * as UI from "../../libs/libUI";
/*
interface Props extends ModalProps {
    title?: string
    handleClose?: (e: React.SyntheticEvent) => void
    children: ReactNode
    header?: ReactNode
    footer?: ReactNode
}
*/

const CpSharedModal = (props) => { // base on poc SharedModal.tsx
    const {title = "", handleClose = undefined, header, footer,
        children,  animation=true, overflowBody = true, ...modalProps} = props
    const className = toStr(props.className);
    const handleExit = (e) => { e.stopPropagation(); };
    return <span className={className+' d-none noAnmiation '} onClick={handleExit} >
    <Modal className={""} {...modalProps} centered animation={animation} enforceFocus={false} tabIndex={-1}> 
        {handleClose && <Modal.Header className={`bg-dim-100 border-0 my-2`}>
            <Modal.Title as={"h5"}>{title}</Modal.Title>
            <div className={"d-flex justify-content-center align-items-center gap-3"}>
                <CloseButton className={"text-dim-400"} onClick={handleClose}/>
            </div>
        </Modal.Header>}
        {header}
        <Modal.Body className={`scroll-container p-0 ${overflowBody === true ? "overflow-auto " : ""} border-0`} style={{zIndex: 1}}>
            {children}
        </Modal.Body>
        {footer && <Modal.Footer className={`pt-2 pb-2 bg-dim-100 border-0`}>
            {footer}
        </Modal.Footer>}
    </Modal>
    </span>    
};

// <Modal className={" noAnmiation "} {...modalProps} centered
// onBackdropClick={onBackdropClick}>
//     </Modal>
//<span className={"d-none noAnmiation "} onClick={handleExit}>
//</span>
export default CpSharedModal;
/*
    return show?UI.ScreenMask(<div style={{zIndex:99999, backgroundColor:'white'}}>
        {handleClose && <Modal.Header className={`bg-dim-100 border-0 my-2`}>
            <Modal.Title as={"h5"}>{title}</Modal.Title>
            <div className={"d-flex justify-content-center align-items-center gap-3"}>
                <CloseButton className={"text-dim-400"} onClick={handleClose}/>
            </div>
        </Modal.Header>}
        {header}
        <Modal.Body className={`p-0 overflow-auto border-0`} style={{zIndex: 1}}>
            {children}
        </Modal.Body>
        {footer && <Modal.Footer className={`pt-2 pb-2 bg-dim-100 border-0`}>
            {footer}
        </Modal.Footer>}
    </div>):'';


    return <span className={"d-none noAnmiation "} onClick={handleExit} >
    <Modal className={""} {...modalProps} centered animation={animation}>
        {handleClose && <Modal.Header className={`bg-dim-100 border-0 my-2`}>
            <Modal.Title as={"h5"}>{title}</Modal.Title>
            <div className={"d-flex justify-content-center align-items-center gap-3"}>
                <CloseButton className={"text-dim-400"} onClick={handleClose}/>
            </div>
        </Modal.Header>}
        {header}
        <Modal.Body className={`p-0 overflow-auto border-0`} style={{zIndex: 1}}>
            {children}
        </Modal.Body>
        {footer && <Modal.Footer className={`pt-2 pb-2 bg-dim-100 border-0`}>
            {footer}
        </Modal.Footer>}
    </Modal>
    </span>
*/

