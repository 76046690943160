import React, { useState, useEffect } from 'react';
import { apiCall, apiCall_ } from '../libs/awsFuncs';
import { ReduxBind } from "../saga/ReduxState";

//import MySwitch from '../_components/Switch0';
//import '../css/UIAdmin.css';
import * as UI from '../libs/libUI';
import { toStr, toInt } from '../libs/libType';
import { errMsgs } from '../libs/libFormat';
import { formatTimeStampHK } from '../libs/libTime';
import ATSwitch from '../AppExPf/components/Switch0';
import { Button, Form } from 'react-bootstrap';
import PageATLogin from './PageATLogin';
import { _ATRoleSuper } from '../consts/ATConsts';
import { preJS } from './AppUtil';
/*
      <Form.Check
        type="switch"
        id="toggle-switch"
        label="Toggle"
        checked={isChecked}
        onChange={handleToggle}
      />
*/

const adminSwitch = (id, isChecked, cb) => {
  return <ATSwitch id={id} leftText='N' rightText='Y' disabled={false}
    selected={isChecked} leftValue={false} rightValue={true}
    setter={cb} width='40' fontSize='16' color={'#2995cd'} leftColor={'#AFAFAF'} />  
  /*
  return <Form.Check type="switch" id={id} label={isChecked?"On":"Off"}
    checked={isChecked} onChange={cb} />;
  */
};

const bool2Text = bool => bool?'true':'false';
const text2Bool = txt => (txt === 'true');

const errDiv = (id, e) => {
  if (!e) return false;
  //return <div id={id} className="small-12 cell margin-top-1 callout alert">{e}</div>;
  return <div id={id} className="message-box rounded-light semi-bold warning mt-2" style={{alignSelf:"flex-start"}}>{e}</div>;
  //return <div id={id} className="f14 usel adminAlert">{e}</div>;
  //<div className="adminErrDiv">{e}</div>
}

const gap = (num) => <div style={{ width: num + "px", height: num + "px" }} />;

const colorButton = (label, onClick, submit = true) => {
  return <Button variant={submit?"primary":"secondary"} onClick={onClick} >{label}</Button>
  //<div className={"f14 usel clickable " + (submit ? "transSubmitButton" : "transRefreshButton")} onClick={onClick}>{label}</div>;
};

const headerTxt = (txt, exClass='') => {
  return <div className={"f20 bold " + (exClass?exClass:'')}>{txt}</div>
};

const PageAdmin = ReduxBind(props => {
  const dispatch = props.dispatch;
  const role = toStr(props._saga?.auth?.ATUser?.ATRole);
  const canAdmin = (_ATRoleSuper === role); 

  const [state, setState] = useState(false);

  useEffect(() => {
    reset();
    apiCall_(dispatch, '/admin/init', cbLoad, {});
  }, [dispatch]);

  const reset = () => {
    //debugLog('PageAdmin.reset()');
    setState(s => ({}));
  };
  const cbLoad = (res, err) => {
    const a = (res && res.adminSSM);
    const c = (a && a.cfgs) || {};
    const e = (a && a.errs) || {};
    setState(s => {
      const cfgs = { ...((s && s.cfgs) || {}), ...c };
      const errs = { ...((s && s.errs) || {}), ...e };
      return { cfgs, errs, loading: 0, ret: a, };
    });
    const errPop = errMsgs(err) || errMsgs(res && res.errs);
    if (errPop)
      alert(errPop);
  };

  if (!state) return <div></div>;
  if (!canAdmin) return <PageATLogin {...{ATSect:'ATAdmin'}} />;  

  const s = state || {};
  const { loading } = s;
  const errs = (s.errs) || {};
  const cfgs = (s.cfgs) || {};
  //const ret = (s.ret) || {};

  const load = (e, act, form) => {
    UI.stopEvent(e);
    setState(s => ({ ...s, loading: 1 }));
    apiCall_(dispatch, act, cbLoad, form);
  }

  const { accessKeyId, secretAccessKey, 
    admin_maintenanceMode, admin_maintenanceMsgDateTime, admin_maintenanceMsgCt, admin_maintenanceMsgEn, 
    schoolYear, houseKeep, houseKeepYear, 
    admin_agreementEn, admin_agreementCt,
  } = cfgs;
  const setTxt = (k, v) => {
    setState(s => {
      const cfgs = { ...(s && s.cfgs), [k]: v };
      return { ...s, cfgs };
    })
  };
  const updateAdmin_maintenanceMode = on => setTxt('admin_maintenanceMode', bool2Text(on));
  const updateAdmin_maintenanceMsgDateTime = on => setTxt('admin_maintenanceMsgDateTime', bool2Text(on));

  const clickLogin = (e) => { load(e, '/admin/init', { accessKeyId, secretAccessKey }); };
  const clickRefresh = (e) => { load(e, '/admin/get', {}); };
  const clickMMode = (e) => { load(e, '/admin/put', { ssmKey: 'admin_maintenanceMode', ssmValue: admin_maintenanceMode }); };
  const clickMTime = (e) => { load(e, '/admin/put', { ssmKey: 'admin_maintenanceMsgDateTime', ssmValue: admin_maintenanceMsgDateTime }); };
  const clickMTxt = (e) => { load(e, '/admin/put', { ssmKey: 'admin_maintenanceMsg', ssmValue: [admin_maintenanceMsgEn, admin_maintenanceMsgCt] }); };
  const clickSchYr = (e) => { load(e, '/admin/put', { ssmKey: 'schoolYear', ssmValue: schoolYear }); };
  const clickReset = (e) => {
    reset();
    load(e, '/admin/init', { accessKeyId: '', secretAccessKey: '' });
    e.preventDefault();
  };
  const clickHouseKeep = (e) => { load(e, '/admin/startHouseKeep', { year:houseKeepYear }); };

  if (loading)
    return UI.ScreenMaskLoader('96px', {backgroundColor:'rgba(255,255,255,0.75)'});

  const runKeep = houseKeep && houseKeep.running;

  return (<div className="adminContainer">
  <div className="adminBody">
    <div className="adminBlock">
      <div className="f30 bold">AWS Credentials</div>

      <div className="adminLogin">
        <div className="adminColClass">
          {headerTxt('AWS Access Key ID',' f18 fw-normal ')}
          <div className="f18">{UI.Text0(toStr(accessKeyId), v => setTxt('accessKeyId', v), 'txtAccessKeyId', 'adminInput', { padding: "5px" }, 'Access Key ID')}</div>
        </div>
        <div></div>
        <div className="adminColClass">
          {headerTxt('AWS Secret Access Key',' f18 fw-normal ')}
          <div className="f18">{UI.Pass0(toStr(secretAccessKey), v => setTxt('secretAccessKey', v), 'txtSecretAccessKey', 'adminInput', { padding: "5px" }, 'Secret Access Key')}</div>
        </div>
        <div></div>
      </div>
      {gap(20)}
      <div className="adminRowClass">
        {colorButton("Submit", clickLogin)}
        {gap(15)}
        {colorButton("Reset", clickReset)}
      </div>
    </div>
    {gap(15)}
    <div className="adminRowClass">
      {colorButton("Refresh", clickRefresh, false)}
    </div>
    {gap(15)}
    {false && errDiv('Eadmin_maintenanceMode', 'Testing warning message')}
    <div className="adminRowClass">
      <div className="f30 bold">Maintenance</div>
    </div>
    {gap(5)}
    <div className="adminBlock">
      {headerTxt('Maintenance State')}
      {gap(5)}
      {preJS({admin_maintenanceMode})}
      <div>{adminSwitch('swmode', text2Bool(admin_maintenanceMode), updateAdmin_maintenanceMode)}</div>
      {gap(15)}
      {colorButton("Submit", clickMMode)}
      {errDiv('Eadmin_maintenanceMode', errs.admin_maintenanceMode)}
    </div>
    {gap(15)}
    <div className="adminBlock">
      {headerTxt('Show Maintenance Announcement Message')}
      {gap(5)}
      {preJS({admin_maintenanceMsgDateTime})}
      <div>{adminSwitch('swdate', text2Bool(admin_maintenanceMsgDateTime), updateAdmin_maintenanceMsgDateTime)}</div>
      {gap(15)}
      {colorButton("Submit", clickMTime)}
      {errDiv('Eadmin_maintenanceMode', errs.admin_maintenanceMsgDateTime)}
    </div>
    {gap(15)}
    <div className="adminBlock">
      {headerTxt('Maintenance Announcement Message')}
      {gap(5)}
      <div className="f18"> e.g. 本平台將於20xx年xx月xx日 - 20xx年xx月xx日更新和維護，期間無法登入，敬請留意。如有任何查詢，歡迎聯絡我們。 </div>
      {gap(15)}
      <div className="f18"> English: </div>
      {gap(15)}
      {preJS({admin_maintenanceMsgEn})}
      <div className="f18 w100"> {UI.Text0(admin_maintenanceMsgEn, v => setTxt('admin_maintenanceMsgEn', v), 'txtAdmin_maintenanceMsgEn', 'adminInput', { padding: "5px", width: "98%" })} </div>
      {gap(15)}
      <div className="f18"> Chinese: </div>
      {gap(15)}
      {preJS({admin_maintenanceMsgCt})}
      <div className="f18 w100"> {UI.Text0(admin_maintenanceMsgCt, v => setTxt('admin_maintenanceMsgCt', v), 'txtAdmin_maintenanceMsgCt', 'adminInput', { padding: "5px", width: "98%" })} </div>
      {gap(15)}
      {colorButton("Submit", clickMTxt)}
      {errDiv('EAdmin_maintenanceMode', errs.admin_maintenanceMsg)}
    </div>
    {gap(15)}
    <div className="adminBlock">
      {headerTxt('Housekeep')}
      {gap(5)}
      <div className="f18"> Current School Year: </div>
      {gap(15)}
      <div className="f18 w100"> {UI.Text0(schoolYear, v => setTxt('schoolYear', v), 'txtSchoolYear', 'adminInput', { padding: "5px", width: "98%" })} </div>
      {gap(15)}
      {colorButton("Submit", clickSchYr)}
      {errDiv('ESchoolYear', errs.schoolYear)}
    </div>
    {gap(5)}
    <div className="adminBlock">
      {headerTxt('Start housekeeping job')}
      {gap(5)}
      {(houseKeep)?<>
        HouseKeep last started: {formatTimeStampHK(houseKeep.startTime, 'YYYY-MM-DD HH:mm:ss')}<br/>
        state: {runKeep?'Running':'Not Running'}<br/>
        HouseKeep school year: {toStr(houseKeep.schoolYear)}<br/>
        HouseKeep back year: n{-(toInt(houseKeep.cutoff))}<br/>
        HouseKeep step: {toInt(houseKeep.step)}<br/>
        {gap(10)}
        </>:''}
      {(true || !runKeep)?<>
        <div className="f18"> Clear Users Not Login in X school years: </div>
        {gap(10)}
        <div className="f18 w100"> {UI.Text0(houseKeepYear, v => setTxt('houseKeepYear', v), 'txtHouseKeepYear', 'adminInput', { padding: "5px", width: "98%" })} </div>
        {gap(15)}
        {colorButton("Submit", clickHouseKeep)}
      </>:''}
      {errDiv('EHouseKeep', errs.houseKeep)}
    </div>
    {gap(15)}
    <div className="adminBlock">
      {headerTxt('End User Licence Agreement')}
      {gap(5)}
      <div className="f18"> DIV content (not full html). Accepts HTML</div>
      {gap(15)}
      <div className="f18"> English: </div>
      {gap(15)}
      <div className="f18 w100"> {UI.TextArea0(admin_agreementEn, v => setTxt('admin_agreementEn', v), 'txtAdmin_agreementCt', 'adminInput', { padding: "5px", width: "98%" })} </div>
      {gap(15)}
      <div className="f18"> Chinese: </div>
      {gap(15)}
      <div className="f18 w100"> {UI.TextArea0(admin_agreementCt, v => setTxt('admin_agreementCt', v), 'txtAdmin_agreementCt', 'adminInput', { padding: "5px", width: "98%" })} </div>
      {gap(15)}
      {colorButton("Submit", 
        e => load(e, '/admin/put', { ssmKey: 'admin_agreement', ssmValue: {En: admin_agreementEn, Ct: admin_agreementCt} })
      )}
      {errDiv('EAdmin_agreement', errs.admin_agreement)}
    </div>

  </div></div>);
});

export default PageAdmin;

