//system consts:
export const langCodeEn = 'en';
export const langCodeCt = 'ct';
export const isEn2Lang = isEn => isEn? langCodeEn: langCodeCt;
export const langIsEn = lang => langCodeCt !== lang;
export const allLangCodes = [langCodeEn, langCodeCt];

//Authoring Tool User Roles
export const ATUserIdSysAdmin = 'ATSysAdmin';

export const _ATRoleSuper = 'ATURS';
export const _ATRoleApprover = 'ATURA';
export const _ATRoleRBasic = 'ATURB';
export const _ATAllRoles = [_ATRoleApprover, _ATRoleRBasic];

//Question Version State
export const QP_D = 'd'; //draft
export const QP_P = 'p'; //publised
export const QP_DD = 'D'; //draft - draft
export const QP_DR = 'R'; //draft - request to pub
export const QP_PP = 'P'; //pub- published
export const QP_PU = 'U'; //pub- unpublished

export const QP_S = 'S'; // pubStateSchool;

//Field Length Limits
export const _MaxLenIds = 1000; // default max len //todo replace all use
export const _MaxLenTodo = 100; // default max len //todo replace all use
export const _MaxExName = 120;
export const _MinLenTodo = 2; // default min len //todo replace all use

export const _MinLenATLogin = 2;
export const _MaxLenATLogin = 50;
export const _MinLenATPass = 3;
export const _MaxLenATPass = 50;

export const _MinLenSubId = 3;
export const _MaxLenSubId = 50;
export const _MinLenATSubName = 3;
export const _MaxLenATSubName = 80;

export const _MinLenMetaSetId = 2;
export const _MaxLenMetaSetId = 50;

export const _MaxLenQId = 50;
export const _MinLenQId = 2;

// for preview countdown default time
export const previewRemainTime = 86400000;

