import { useUILang } from "../../AppExPf/utils/useUILang";
import { Button } from "react-bootstrap";
import CpSharedModal from "./CpSharedModal";
import { CpSettingModalTabContainer, CpSettingModalSection } from "./CpSettingModalTabCps";
import SharedModalHeader from "./CpSharedModalHeader";

const CpMsgbox = (props) => {
    const {vis=0, closeMsg,cancelMsg,iconPath, title, msg, confirm} = props;
    const [t] = useUILang();

    const _closeMsg = (e) => { closeMsg && closeMsg(e); };
    const _cancelMsg = (e) => { cancelMsg && cancelMsg(e); };

    const renderHeader = () => {
        return <SharedModalHeader title={t(title)} iconPath={iconPath} handleClose={_cancelMsg}/>
    };
    const renderFooter = () => {
        return <div className={"d-flex gap-2 justify-content-center w-100"}>
            <Button variant="gray-body-color w-100 border" onClick={_cancelMsg}>
                {t("cancel")}
            </Button>
            <Button variant={`${confirm === "delete" ? "danger" : "exercise-third-btn"} btn-block w-100`}
                    onClick={_closeMsg}>
                {t(confirm)}
            </Button>
        </div>
    };
    return <><CpSharedModal show={vis} header={renderHeader()} footer={renderFooter()} scrollable={true}>
            <CpSettingModalTabContainer gap={3}>
                <CpSettingModalSection className={"flex-column"}>
                    <div className={"semi-bold"}>{t(msg)}</div>
                </CpSettingModalSection>
            </CpSettingModalTabContainer>
        </CpSharedModal>
    </>
};

export default CpMsgbox;