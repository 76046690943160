import React, {useMemo, useEffect, useState} from "react";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { _AST_IN_PROGRESS, _AST_PUBLISHED, _AST_PENDING, _WST_SUBMIT } from "../../consts/ATConstsAssignment";
import { exerHasMark } from "./Tags/TagMarkingTag";
import { objEntries, strCmp, toAry, toInt, toObj, toStr } from "../../libs/libType";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { asyncApiCallLoad_, } from "../../libs/awsFuncs";

import { useMemoClsName } from "../_components/CpAssignToTag";
import { stopEvent } from "../../libs/libUI";
import { preJS } from "../../AppExPf/AppUtil";
import { EPUITime3 } from "../_components/CpEpTabHead";
import { ReduxBind } from "../../saga/ReduxState";
import PageAsmMark from "../EPAsmWork/PageAsmMark";
import { CpSettingModalSection, CpSettingModalTabContainer } from "../_components/CpSettingModalTabCps";
import { secondToHHMMSS } from "../../libs/libTime";
import { CpSortingIcon, SortingArrows } from "../EPReport/TabPerformanceDetailChart";
import { asmPassMark } from "../EPReports/data/dataUtil";
import { xapiPost } from "../EPLibrary/PageLibrary";
import { toIdStr } from "../../consts/ATValidate";

const TabTeacherAssignmentStatus = ReduxBind(props => { //base on poc TeacherAssignmentStatus 
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const { dispatch, reload } = props; 
    const { qtns, asm, ex, works, marks} = props;

    const asmMarking = props.asmMarking;
    const {classId, SWMs, marking, clickMark} = asmMarking;

    const [ sorts, setSorts, addSort, flipSort] = useSortList({classNo:'asc'});
    
    const asmId = toIdStr(asm?.assignId);
    useEffect(() => { asmId && xapiPost(dispatch, 'openAsm', {assignId: asmId}); }, [asmId]);

    const aPub = (asm?.AState) === _AST_PUBLISHED;
    
    const _SWMs = useMemo(()=>{
        // add attributes for sorting
        return SWMs.map((swm)=>{
            const subed = swm.WSubmit;
            //const  = subed?((subed && !aPub && !swm.marked && swm.MMQtn && !swm.aPend)?1:0):-1;
            const _marked = subed && (aPub || swm.marked)?1:0;
            //const showAlert = subed?(swm.marked?1:0):-1;
            const sortCols = {
                showAlert: subed? (!_marked): -1,
                name: ut(swm.nameEng, swm.nameChi),
                eTime: subed? toInt(swm.dateSub): -1,
                score: subed? toInt(swm.aScore) + toInt(swm.mScore): -1,
                attemp: subed? toInt(swm.work._attemp): -1,
                aMark: subed? toInt(swm.aScore): -1,
                mMark: subed? toInt(swm.mScore): -1,
                ansTime: subed? fbUsedTime(swm.msUsed,swm.endTime-swm.startTime): -1,
                //marked:((aPub && subed)|| swm.marked)?1:0,
                marked: subed? _marked: -1,
            };
            return {...swm, ...sortCols};
        });
    },[marking, SWMs]);
    
    
    const sortSWMs = useMemo(() => marking? []: sortWorks(_SWMs, sorts), [_SWMs, sorts, marking]);

    const AState = asm?.AState;
    const AIP = AState === _AST_IN_PROGRESS;

    const [ cache2d, useGetCache ] = useCaches(props);
    
    const yearClass2d = useGetCache('yearClass2d', '*');
    const clsName = useMemoClsName(yearClass2d, classId, ut, uiEn);

    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(ex);
    const pMark = asmPassMark(asm.passUnit, asm.passValue, (aScore + mScore));

    return <CpSettingModalTabContainer paddingX={0}>
        <CpSettingModalSection paddingX={0} className={"w-100"}>
            {preJS({sorts})}
            <div className={"overflow-auto w-100"}>
                <table className={"assignment-table-status w-100"}><tbody>
                    <tr>
                        <th align={"center"}>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            <SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'showAlert'}} />
                            </span>
                        </th><th align={"center"}>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            {t("assignment-status-class")}
                            </span>
                        </th><th>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            {t("assignment-status-class-no")} <SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'classNo'}} />
                            </span>
                        </th><th>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            {t("assignment-status-student-name")}<SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'name'}} />
                            </span>
                        </th><th>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            {t("assignment-status-submission-date")}<SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'eTime'}} />
                            </span>
                        </th><th>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            {t("assignment-status-total-score")}<SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'score'}} />
                            </span>
                        </th>
                        {(hasM)?<th>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            {t("assignment-status-auto-marked")}<SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'aMark'}} />
                            </span>
                        </th>:''}
                        {(hasM)?<th>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            {t("assignment-status-manual-marked")}<SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'mMark'}} />
                            </span>
                        </th>:<th>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            {t("assignment-status-attempt")}<SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'attemp'}} />
                            </span>
                        </th>}
                        <th>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                            {t("assignment-status-answer-time")}<SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'ansTime'}} />
                            </span>
                        </th><th>
                            <span className={"d-flex gap-2 justify-content-center align-items-center user-select-none"}>
                                {t("assignment-status-status")}<SortingArrows {...{sorts, addSort, flip:flipSort, sortKey:'marked'}} />
                            </span>
                        </th>
                    </tr>
                    <StudentRows {...{clsName, asm, ex, sortSWMs, clickMark, hasM, pMark}} />
                    </tbody>
                </table>
            </div>
        </CpSettingModalSection>
    </CpSettingModalTabContainer>;
});
export default TabTeacherAssignmentStatus;

const StudentRows = (props => {
    const { clsName, asm, ex, sortSWMs, clickMark, hasM, pMark } = props;
    const aPub = asm?.AState === _AST_PUBLISHED;
    return toAry(sortSWMs).map((swm, idx) => {
        return <StudentRow key={idx} {...{idx, clickMark, clsName, asm, ex, swm, hasM, aPub, pMark}} />
    });
});

const StudentRow = (props => {
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const {idx, clsName, asm, ex, swm, clickMark, /*hasM,*/ aPub, pMark} = props;
    const {marked, swmIdx, startTime, endTime, dateSub, msUsed, MMQtn, aPend } = swm;

    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(asm?.Exer);
    const subed = swm.WSubmit;    
    const ansTime = (subed)? secondToHHMMSS(Math.floor(fbUsedTime(msUsed, endTime - startTime) / 1000)): undefined;
    const showAlert = subed?(!marked):0;
    const stScore = swm.aScore + swm.mScore;
    const scoreClass = marked? ((asm?.APass)? ((stScore >= pMark)? 'text-tag-success': 'text-tag-danger'): ''): 'text-dim-350';
    
    // "text-tag-success" "text-tag-danger" text-dim-350
    return <tr className={`semi-bold ${subed? "": "text-dim-350"}`}> 
        <td>{showAlert?pendingMarkIcon():''}</td> 
        <td>{clsName}</td>
        <td>{swm.classNo}</td>
        <td>{ut(swm.nameEng, swm.nameChi)}</td>
        <td>{dateSub? EPUITime3(dateSub): '---'}</td>
        <td>{subed?(<><span className={scoreClass}>{stScore}</span><span>{'/ ' + (aScore + mScore)}</span></>):''}</td>
        {(hasM)?<td>{subed?(swm.aScore + '/ ' + aScore):''}</td>:''}
        {(hasM)?
            <td><span className={"d-flex justify-content-center align-items-center gap-2"}>
            {showAlert?pendingMarkIcon():''}{subed?(swm.mScore +'/ '+ mScore):''}
        </span></td>:<td>{subed?swm.work._attemp:''}</td>}
        <td>{subed?(ansTime?ansTime: '---'):'---'}</td>
        <td>{subed?<AssignmentPendingToMark {...{aPub, marked, onClick:clickMark(swmIdx)}}/>:''}</td>
    </tr>;     
});
const AssignmentPendingToMark = (props => {
    const {onClick, aPub} = props;
    const [t] = useUILang();
    const marked = (props?.marked) || aPub;
    return <span className={(marked?"":"text-exercise-third-btn") + " fs-6 d-flex gap-2 justify-content-center align-items-center"}
        role="button" onClick={onClick}>
        <CpIco src={marked?IconList.general.search:IconList.general.edit}/>
        <span>{t(marked? "assignment-status-marked": "assignment-status-pending-to-mark")}</span>
    </span>;
});
/*
    return <span className={(marked?"":"text-exercise-third-btn") + " fs-6 d-flex gap-2 justify-content-center align-items-center"}
        role="button" onClick={onClick}>
*/

export const strCmpTrimLow = (a, b) => strCmp(toStr(a).trim().toLowerCase(), toStr(b).trim().toLowerCase() );

const sort1 = (a, b, kv) => {
    if(!kv) return;
    const [key, so] = kv;
    const isAsc = so !== 'desc';
    //if (isAsc && (b[key] === -1)) return 0;
    //if (!isAsc && (a[key] === -1)) return 0;
    if(key === 'name') return isAsc? strCmpTrimLow(a.name, b.name): strCmpTrimLow(b.name, a.name);
    return isAsc? a[key] - b[key]: b[key] - a[key];
};

const sortWorks = (worksums, sorts) => {
    
    const ss = objEntries(sorts); 
    const ret = [...worksums]; 
    ret.sort( (a, b) => 
        sort1(a, b, ss[0]) || sort1(a, b, ss[1]) || sort1(a, b, ss[2]) || 
        sort1(a, b, ss[3]) || sort1(a, b, ss[4]) || sort1(a, b, ss[5]) ||
        sort1(a, b, ss[6]) || (ss[7]&&sort1(a, b, ss[7])) || (ss[8]&&sort1(a, b, ss[8])));
    return ret; 
};


/*
const work = {
    WUseMS: 60000,
};

const mark = {
    MAScore: 10,  //Mark Auto Score
    MAQtn: 2,  //Mark Auto Question 
    MMScore: 20,  //Mark Menual Score
    MMQtn: 5,  //Mark Menual Question 
};
*/


export const useSortList = (init) => {
    const [sorts, setSorts] = useState(toObj(init));
    const addSort = (key, isAsc) => {
        
        setSorts(s => {
            const ret = {[key]:isAsc, ...toObj(s), [key]:isAsc, }; //pust key in the front, and overwirte value
            return ret;
        });
    };
    const filpSort = (key) => e => {
        
        addSort(key, (toObj(sorts)[key] === 'asc')? 'desc': 'asc');
    };
    return [ sorts, setSorts, addSort, filpSort];
};

const pendingMarkIcon = () => <span className={"text-exercise-third-btn"}><CpIco src={IconList.general.alert}/></span>

const renderTextStyle = (value = 0, total = 0, passingGrade = 0, isMarked = true, APub) => {
    //Dummy, there would be two types score and %
    if (isMarked) {
        if (value > passingGrade) {
            return "text-tag-success"
        } else {
            return "text-tag-danger"
        }
    } else {
        if (APub) return ""
        return "text-dim-350"
    }
};

// fallback used time
export const fbUsedTime = (t1, t2) => {
    if (t1 !== undefined) return toInt(t1);
    if (t2 !== undefined) return toInt(t2);
    return undefined;
};