import React, {useState, useMemo, useEffect }from "react";
import {Col, Container, Row} from "react-bootstrap";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { CpATQtnDisplayOnly } from "../../AppExPf/qtnDo/CpATQtnDisplayOnly";
import { toAry, toStr, toObj, objKeys } from "../../libs/libType";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";
import { toIdStr } from "../../consts/ATValidate";
import { preJS } from "../../AppExPf/AppUtil";
import { langIsEn, useUILang } from "../../AppExPf/utils/useUILang";
import { SIZE } from "../_components/CpExPFConst";
import CpActionButtonComponent from "../_components/CpActionButtonComponent";
import CpActionButton from "../_components/CpActionButton";
import { IconList } from "../../consts/ATIconListToUsePoc";
import ChartMiniBar from "./CpChartMinibar";
import { __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_LBD,
    __SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_POL,
    __RESP_TXT, __RESP_DRAW, __RESP_FILE, __RESP_IMAGE, __RESP_URL, __SYSQTrans, __SYSQSubType_OED } from "../../consts/ATSysQType";
import { __MCTT_TTB, __MCTT_TTC } from "../../consts/ATQtnAnsTypeMCQ";
import { _WST_SUBMIT } from "../../consts/ATConstsAssignment";
import { BtnPopDev } from "../../AppExPf/AppUtil";
import { qtnAnsStat } from "../../consts/ATQtnScore";
import { toRptFix } from "../EPReports/PageReports";
//import { getStudentName } from "../../AppExPf/ATExerEdit/TabExerContent";
//import { atan } from "mathjs";

const TabQuestionPerformance = props => {
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {asm, Exer, qtns, works, marks, dispatch} = props;
    const classId = toStr(asm.classId);
    const [ cache2d, useGetCache ] = useCaches(props);
    const students = useGetCache('classStudents', classId);
    // for question display media content
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);

    // change from return 'QId' to {QId, idx}, requre ectn index to map with work and mark object
    const QIds = toAry(Exer?.ECtns).map((c, idx) => (c.type === _ExCtType_Qtn) && {QId:toIdStr(c.QIds), idx}).filter(i => i);
    return <CpReportQuestionContainer {...{asm, QIds, qtns, works, marks, students, mediaDLs, getMediaDLs, dispatch}} />
    /*
    const {data, student} = questionPerformanceData;
    const dummyQuestion = Array.from({length: 7}, (_, i) => ({id: `q-00${i + 1}`}));
    const renderReport = (id) => {
        //Find the question id in data
        const found = data.find(d => d.id === id);
        return (found)? <QuestionPerformanceChart key={found.id} student={student} data={found}/>: <></>;
    }
    */
}
export default TabQuestionPerformance;


const CpReportQuestionContainer = (props => {
    const {asm, QIds, qtns, works, marks, students, mediaDLs, getMediaDLs, dispatch} = props;
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const showEn = langIsEn(asm.ALang); 

    const [sd, setSd] = useState();
    const [pocData, setPocData] = useState();
    // works[key].resp
    // marks[key].resp
    //const datasource = "answers";   //searchParams.get("datasource") || "answers";
    //const dummyData = t(`dummy.mcq.list.${datasource}`, {returnObjects: true});
    //const dummyData: IMCQAnswer[] = t(`dummy.mcq.list.${datasource}`, {returnObjects: true});

    //const {data, student} = questionPerformanceData;
    //const found = data[0];
    const qtnProps = { mediaDLs, getMediaDLs, dispatch };

    useEffect(()=>{ _qtnAnsStat(); }, [])
    useEffect(()=>{ maptoPOC(); },[sd]);

    const _qtnAnsStat = () => {
        const result = qtnAnsStat(QIds, qtns, works, marks, showEn);
        setSd(result);
    };

    const student = useMemo(() => {
        const result = [];
        objKeys(works).forEach(k => {
            if (works[k].WState === _WST_SUBMIT) {
                const st = toObj(students[k]);
                result.push({id:k, no:st.classNo, name:ut(st.nameEng, st.nameChi)});
            };
        });
        return result;
    },[students, works]);
    
/*
    {
      "id": "q-008",
      "questionType": "multiple-choice",
      "matchTable": "single", //"multiple"
      "markingType": "auto-marked",
      "averageScore": {
        "value": 5,
        "percentage": 45.5,
        "total": 11
      },
      "systemOptionTitle": true,
      "showOptionFail": false      

*/
    const maptoPOC = () => {
        if (!sd) return;
        const result = [];
        toAry(QIds).map((Qobj, idx) => { 
            const qtn = qtns[Qobj.QId];
            const sdd = sd[idx];
            const qt = qtn.SQType;
            const questionType = __SYSQTrans[qt];
            const isMultipleAns = (qtn?.multiAnswer)?1:0;
            const matchTable = isMultipleAns?'multiple':'single';            
            const isMCT = [__SYSQSubType_MCT].includes(qt);
            const isMCTLabel = isMCT && ((qtn?.ansChoice)===__MCTT_TTB);
            const markingType = ([__SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_LBT, 
                __SYSQSubType_LBD, __SYSQSubType_POL ].includes(qt) || (qt === __SYSQSubType_FIB && qtn.autoMark))?
                'auto-marked':'manual-marked';
            const averageScore = {value:toRptFix(sdd.stgotTScore/sdd.QtotalAtt,5,2),
                percentage:toRptFix(sdd.stgotTScore/sdd.QtotalAtt/qtn.score*100),total:toRptFix(qtn.score,5,2)};
            const correctness = {value:sdd.QCorrCnt, percentage:toRptFix(sdd.QCorrCnt/sdd.QtotalAtt*100),total:sdd.QtotalAtt};
            
            const systemOptionTitle = ([__SYSQSubType_MCS,__SYSQSubType_FIB,__SYSQSubType_POL].includes(qt)||isMCTLabel)?false:true;
            const showOptionFail = [__SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_POL].includes(qt)?false
                :([__SYSQSubType_FIB,__SYSQSubType_LBT,__SYSQSubType_LBD].includes(qt)?true
                :([__SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_OED].includes(qt)&&qtn.correctness?true:false));
            
            const hasAveData = qt !== __SYSQSubType_POL;
            const hasCorrData = [__SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_LBT, __SYSQSubType_LBD,
                __SYSQSubType_FIB].includes(qt) || ([__SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_OED].includes(qt)&&qtn.correctness);

            
            const options = sdd.ansStat.map((ast,ii)=>{
                const sl = toAry(ast.stList);
                const cl = toAry(ast.corrList);
                const wl = toAry(ast.wrongList);
                const stCnt = sl.length;
                const coCnt = cl.length;
                const wrCnt = wl.length;
                if ((qt === __SYSQSubType_MCS) || isMCTLabel) {
                    const status = (ast?.isCorrAns)?"p":"f";
                    return {id:'o'+ii,title:String.fromCharCode(65 + ii),
                        marks:{value: stCnt, total: sdd.QtotalAtt, percentage: toRptFix(stCnt / sdd.QtotalAtt * 100)},
                        status: status,
                        student: sl.map((st)=>{return{id:st, status:status}})
                    };
                } else if (qt === __SYSQSubType_MCT) {
                    // id = 'o'+r+c, group id = 'o'+r
                    const status = (ast?.isCorrAns)?"p":"f";
                    const title = isMultipleAns?((ast?.isCorrAns)?"correct":"incorrect"):"column";
                    return {id:'o-'+ast.row+'-'+ast.col,groupId:'o-'+(ast.row),title:title,
                        marks:{value: stCnt,total: sdd.QtotalAtt, percentage: toRptFix(stCnt / sdd.QtotalAtt * 100)},
                        status:status,
                        student:sl.map((st)=>{return{id:st, status:status}})
                    };                    
                } else if (qt === __SYSQSubType_FIB) {
                    const status = "p";
                    return {id:'o'+ii,title:""+(ii+1),
                        marks:{value: coCnt, total: sdd.QtotalAtt, percentage: toRptFix(coCnt / sdd.QtotalAtt * 100)},
                        status:status,
                        student:cl.map((st)=>{return{id:st, status:"p"}}).concat(
                                wl.map((st)=>{return{id:st, status:"f"}}))
                    };
                } else if ([__SYSQSubType_LBT, __SYSQSubType_LBD].includes(qt)) {
                    const status = "p";
                    return {id:'o'+ii,title:"label",
                        marks:{value: coCnt, total: sdd.QtotalAtt, percentage: toRptFix(coCnt / sdd.QtotalAtt * 100)},
                        status:status,
                        student:cl.map((st)=>{return{id:st, status:"p"}}).concat(
                                wl.map((st)=>{return{id:st, status:"f"}}))
                    };
                } else if ([__SYSQSubType_OEG, __SYSQSubType_OED].includes(qt)) {                  
                    const status = hasCorrData?((coCnt===0 && wrCnt === 0)? "n": "p"):(stCnt===0? "n": "a");
                    const cnt = hasCorrData?coCnt:stCnt;
                    const stl = hasCorrData?cl.map((st)=>{return{id: st, status: "p"}}).concat(
                            wl.map((st)=>{return{id:st, status: "f"}}))
                        :sl.map((st)=>{return{id:st, status:status}});
                    const title = (qt === __SYSQSubType_OED)? "canvasdrawing"
                        :(ast?.respType === __RESP_TXT)? "text"
                        :(ast?.respType === __RESP_FILE)? "uploadfile"
                        :(ast?.respType === __RESP_IMAGE)? "image"
                        :(ast?.respType === __RESP_DRAW)? "drawing"
                        :(ast?.respType === __RESP_URL)? "hyperlink"
                        :"text";
                    return {id:'o'+ii, title,
                        marks:{value: cnt, total: sdd.QtotalAtt, percentage: toRptFix(cnt / sdd.QtotalAtt * 100)},
                        status, student: stl,
                        ...(hasCorrData) && {failMarks: {value: wrCnt, percentage: toRptFix(wrCnt / sdd.QtotalAtt * 100)}},
                    };                    
                } else if (qt === __SYSQSubType_OEE) { // essay text only
                    const status = hasCorrData?((coCnt===0 && wrCnt === 0)?"n":"p"):(stCnt===0?"n":"a");
                    const cnt = hasCorrData?coCnt:stCnt;
                    const stl = hasCorrData?cl.map((st)=>{return{id:st, status: "p"}}).concat(
                            wl.map((st)=>{return{id:st, status: "f"}}))
                        :sl.map((st)=>{return{id:st, status}});
                    const title = (ast?.respType === __RESP_TXT) ? "text"
                        :"text";
                    return {id:'o'+ii, title,
                        marks:{value: cnt, total: sdd.QtotalAtt, percentage:toRptFix( cnt / sdd.QtotalAtt * 100)},
                        status, student: stl
                    };
                } else if (qt === __SYSQSubType_POL) {
                    const status = "a";
                    return {id:'o'+ii, title: String.fromCharCode(65 + ii),
                        marks:{value: stCnt, total: sdd.QtotalAtt, percentage: toRptFix( stCnt / sdd.QtotalAtt * 100)},
                        status, student: sl.map((st)=>{return{id:st, status}})
                    };
                }
            });           

            result.push({id:Qobj.QId,questionType,markingType,systemOptionTitle,showOptionFail,
                ...(hasAveData) && {averageScore},
                ...(hasCorrData) && {correctness},
                matchTable:(isMCT && !isMCTLabel)?matchTable:0,
                options
            });
        });
        setPocData(result);
    };

    return <div className={"bg-preview-bg-color mx-0 disable-presentation-area"}>
    <div className='flexRowStart'>
        <BtnPopDev txt='qtnAnsStat'>{preJS({sd}, 3)}</BtnPopDev>
    </div> {toAry(QIds).map((Qobj, idx) => { 
        const qtn = qtns[Qobj.QId];
        const Q = showEn?(qtn?.QEn):(qtn?.QCt);
        if (!Q) return '';
        return <Row key={idx} className={"mx-0"}>
            <Col xs={12} lg={6} className={"px-0"}>
                <Container fluid className={"py-4 mb-2"} id={`anchor-question-${idx + 1}`}>
                    <MaxWidthContainer><QuestionbarComponent><span className={"d-flex flex-row gap-2"}>
                        <CpActionButtonComponent variant={"outline-preview-primary pe-none  bg-white"} size={SIZE.SM} className={"position-relative"} title={`${t("question")} ${idx + 1}`}/>
                    </span></QuestionbarComponent></MaxWidthContainer>
                    <Row><Col><PresentationConatiner variant={"bg-white p-4 shadow-200 content-ui"}>
                        <CpATQtnDisplayOnly {...{...qtnProps, fullQ:qtn, Q, mini:1}}/> 
                    </PresentationConatiner></Col></Row>
                </Container>
            </Col>
            <Col xs={12} lg={6} className={"px-0"}><Container fluid className={"py-4 mb-2"}><Row><Col>
                <PresentationConatiner variant={"content-ui"}> 
                    {(pocData&&pocData[idx])?<CpQuestionPerformanceChart key={idx} student={student} data={pocData[idx]}/>:''}
                </PresentationConatiner>
            </Col></Row></Container></Col>
        </Row>;
    })}</div>;
});

const CpQuestionPerformanceChart = (props => {
    const [t] = useUILang();
    const {student, data} = props;
    const {id, questionType, markingType, averageScore, correctness, systemOptionTitle, 
        options, showOptionFail, matchTable } =  data;

    return <div className={"report question-performance-chart w-100"}>
        <div className={"d-flex align-items-center gap-2 mb-3"}>
            <CpActionButtonComponent variant={"color-preview-primary pe-none"} size={SIZE.SM} className={"position-relative"} title={`${t(questionType)}`}/>
            {markingType && <CpActionButtonComponent variant={"color-preview-primary pe-none"} size={SIZE.SM} className={"position-relative"} title={`${t(markingType)}`}/>}
        </div>
        <div className={"d-flex flex-column gap-4  w-100 bg-white p-4 shadow-200"}>
            {averageScore && <AverageBar marks={averageScore}/>}
            {correctness && <CorrectnessBar marks={correctness}/>}
            {options && <CpOptionsBar matchTable={matchTable} options={options} student={student} averageScore={averageScore} correctness={correctness} systemOptionTitle={systemOptionTitle} showOptionFail={showOptionFail}/>}
        </div>
    </div>
});

const AverageBar = (props) => {
    const [t] = useUILang();
    const {marks} = props
    const {value, total, percentage} = marks

    return <div>
        <div className={"d-flex justify-content-between semi-bold mb-3"}>
            <span>{t("report-average")}</span>
            <span>{value} / {total} ({percentage}%)</span>
        </div>
        <div className={"d-flex gap-2"}>
            <span className={"text-nowrap semi-bold text-status-avg align-items-center side-percentage"}>{percentage}%</span>
            <ChartMiniBar marks={{...marks, percentage: percentage}} type={"average"} status={"avg"} showSubIndicator={true} indicator={false}/>
        </div>
    </div>
}

const CorrectnessBar = (props) => {
    const [t] = useUILang();
    const {marks} = props
    const {value, total, percentage} = marks
    return <div>
        <div className={"d-flex justify-content-between semi-bold mb-3"}>
            <span>{t("report-correctness")}</span>
            <span>{value} / {total} ({percentage}%)</span>
        </div>
        <div className={"d-flex gap-2"}>
            <span className={"text-nowrap semi-bold text-status-p align-items-center side-percentage"}>{percentage}%</span>
            <ChartMiniBar marks={{...marks, percentage: percentage}} type={"correctness"} status={"p"}
                showFail={total>0} showSubIndicator={true} indicator={false}/>
        </div>
    </div>
}

const CpOptionsBar = (props) => {
    const [ t ] = useUILang();
    const {options, student, averageScore, correctness, matchTable, systemOptionTitle = false, showOptionFail = false} = props;
    //const translateLabel = "label";
    const [showStudentList, setShowStudentList] = useState(false);   
    const translateLabels = ["label", "column", "correct", "incorrect"];

    const currentMatchTable = {groupId: "", rowIndex: 0, columnIndex: 0};

    return <div><div className={"d-flex justify-content-between align-items-center semi-bold mb-3"}>
            {averageScore && correctness && <span>{t("report-options-correctness")}</span>}
            {averageScore && !correctness && <span>{t("report-responses")}</span>}
            {!averageScore && <span>{t("report-options")}</span>}
            <span><CpActionButton iconPath={IconList.general.avatar}
                title={t(showStudentList ? "hide-details" : "show-details")}
                className={`btn btn-gray-body-color border gap-2`} 
                onClick={() => {setShowStudentList(prev => !prev)}}/>
            </span>
        </div>
        <table className={"w-100"}><tbody>
            {options?.map((option, idx) => {
                const {id, groupId, title, marks, status, failMarks, student: studentData} = option;
                const {value, total, percentage} = marks || {};
                const showFail = showOptionFail && (total>0);
                const textTitleStatus = showOptionFail ? "" : `text-status-${status}`;
                const textPercentageStatus = showOptionFail ? `text-status-${status}` : "";
                const statusSet = new Set(studentData.map(obj => obj.status));
                const statusSetArr = [...statusSet];
                if (matchTable) {
                    const isNewRow = currentMatchTable.groupId !== groupId;
                    currentMatchTable.groupId = groupId || "";
                    if (isNewRow) {
                        currentMatchTable.rowIndex += 1
                        currentMatchTable.columnIndex = 1
                    } else {
                        currentMatchTable.columnIndex += 1
                    };
                    return <React.Fragment key={idx}>
                        {isNewRow && <tr>
                            <td></td>
                            <td colSpan={2} className={`semi-bold pb-2`}>{t("report-performance-row")} {currentMatchTable.rowIndex}</td>
                        </tr>}
                        <tr>
                            <td className={"pb-3 semi-bold text-nowrap align-text-top max-title"}>
                                <span className={`me-3 ${textTitleStatus}`}>{systemOptionTitle ? `${t(`report-performance-${title}`)}${translateLabels.includes(title) && matchTable === "single" ? ` ${currentMatchTable.columnIndex}` : ""}` : title}</span>
                            </td>
                            <td></td>
                            <td className={`pb-3 w-100 align-text-top`}><div className={"d-flex gap-2"}>
                                {showOptionFail === true && <span className={`text-nowrap semi-bold ${textPercentageStatus} side-percentage`}>{percentage}%</span>}
                                <ChartMiniBar type={type(status)} marks={marks} status={status} showFail={showFail}
                                    showSubIndicator={true} indicator={!showOptionFail}/>
                            </div></td>
                        </tr>
                        {showStudentList === true && <tr>
                            <td></td>
                            <td colSpan={2}><div className={`d-flex flex-column w-100 gap-2 ${studentData.length ? "mb-5" : ""}`}>
                                {statusSetArr.map(st =><CpStudentList status={st} students={student} studentData={studentData}/>)}
                            </div></td>
                        </tr>}
                    </React.Fragment>
                };
                return <React.Fragment key={idx}>
                    <tr>
                        <td className={"pb-3 semi-bold text-nowrap align-text-top max-title"}>
                            <span className={`me-3 ${textTitleStatus}`}>{systemOptionTitle ? `${t(`report-performance-${title}`)}${translateLabels.includes(title) ? ` ${idx + 1}` : ""}` : title}</span>
                        </td>
                        <td></td>
                        <td className={`pb-3 w-100 align-text-top`}><div className={"d-flex gap-2"}>
                            {showOptionFail === true && <span className={`text-nowrap semi-bold ${textPercentageStatus} side-percentage`}>{percentage}%</span>}
                            <ChartMiniBar type={type(status)} marks={marks} status={status} showFail={showFail}
                                showSubIndicator={true} indicator={!showOptionFail} failMarks={failMarks}/>
                        </div></td>
                    </tr>
                    {showStudentList === true && <tr>
                        <td></td>
                        <td colSpan={2}><div className={`d-flex flex-column w-100 gap-2 ${studentData.length ? "mb-5" : ""}`}>
                            {statusSetArr.map((st, index) =><CpStudentList status={st} students={student} studentData={studentData} index={index} />)}
                        </div></td>
                    </tr>}
                </React.Fragment>
            })}
            </tbody>
        </table>
    </div>
};

const CpStudentList = (props) => {
    const {status, students = [], studentData = [], index} = props
    const findStudent = id => students.find(stu => stu.id === id);

    const filteredStudentData = studentData.filter(stuD => stuD.status === status)
    if (filteredStudentData.length) {
        return <div key={index} className={`position-relative status-${status}-light py-2 px-3 rounded-light`}>
            <div className={"d-flex flex-wrap gap-3 fs-9 w-100 overflow-auto"}>
                {filteredStudentData.map(fStuD => {
                    const student = findStudent(fStuD.id)
                    return <span key={fStuD.id} style={{color:'blue'}}>{student?.no} {student?.name}</span>
                })}
            </div>
        </div>
    }
    return <></>
};
//return <a key={fStuD.id} href={""} onClick={goToStudent}>{student?.no} {student?.name}</a>

const type = (status) => {
    return (status === "f")? "fail"
        :(status === "a")? "attempted"
        :(status === "m")? "manual"
        ://(status === "p") || (status === "n")?  
        "correctness";
};

const PreventionContainer = (props) => { // base on poc PreventionContainer.tsx
    const {children, className, ...preventionProps} = props;
    return <div {...preventionProps} className={`prevention-area ${props.className || ""}`}>
        {children}
    </div>
};

const PresentationConatiner = (props) => { // base on poc PresentationConatiner
    const {children, variant = ""} = props;
    return <Container className={"presentation-area"}><div className={`presentation-board ${variant}`}>
        <PreventionContainer>{children}</PreventionContainer>
    </div></Container>
};

const MaxWidthContainer = (props) => {
    const {children, ...containerProps} = props;
    return <Container className={"presentation-area"} {...containerProps}>{children}</Container>
};

const QuestionbarComponent = (props) => {
    const {children, ...questionbarProps} = props;
    return <div className={"question-bar-container"} {...questionbarProps}>
        <div className={"d-flex gap-3 mb-3 flex-row justify-content-between flex-wrap"}>{children}</div>
    </div>
};

