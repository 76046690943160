import { Array2Object } from "../../libs/libArray";
import { isAry, isObj, toAry, toObj, toStr } from "../../libs/libType";
import { walkTreeFunc } from "./utilWalkTree";

const [a, o, s] = [toAry, toObj, toStr];
const mrow = m => { 
    const {metaId, LVNum, nameEn, nameCt} = o(m);
    return [s(metaId), s(LVNum), s(nameEn)+'|'+s(nameCt)]; 
};

export const encodeeMSTree = (heads, tree, list) => {
    
    const rows = [];
    rows.push([].concat('action', ...a(heads).map(mrow)));
    const mlist = m => {
        if(!m) return;
        const p = list[m.pMeta]; 
        return p? [].concat(mlist(p), mrow(m)): mrow(m);
    }
    const sortRows = [];
    const func = (list, val, key, lv, id) => {
        const row = [].concat('', mlist(list[key]));
        sortRows.push([list[key].rnum, row])
        //rows.push(row);
        return val;
    };
    walkTreeFunc(list, tree, '', 0, func);
    sortRows.sort((a, b) => a[0] - b[0]);
    
    
    return [...rows, ...sortRows.map(s => s[1])];
};

export const metaListPack = metas => {
    if (!isObj(metas)) return 0;
    return Object.keys(metas).map( mid => { 
        const {childs, ...ms_} = metas[mid];
        return ms_; 
    });
};

const walkMetaTree = (list, branch, pMeta, depth) => {
    if(depth > 10) return; // depth limit
    if(Object.keys(list).length > 5000) return; //to many node
    const keys = isObj(branch) && Object.keys(branch);
    keys && keys.forEach( metaId => {
        const node = list[metaId]||{};
        const pub = node.pub?1:0;
        list[metaId] = {...node, metaId, pMeta, pub, wasPub:pub, childs:branch[metaId]};
        walkMetaTree(list, branch[metaId], metaId, depth+1);
    });
    return list;
};

export const metaListUnpack = (metas, tree) => {
    if(!isAry(metas)) return [];
    const sortms = metas.sort((a, b) => (a.rnum - b.rnum));
    const list = walkMetaTree(Array2Object(sortms, 'metaId'), tree, '', 0);
    const stree = metas2Tree(sortms, list);
    
    return [list, stree];
};

const metas2Tree = (metas, list) => {
    const mRoot = {};
    const MBranchs = Object.fromEntries(metas.map(m => [m.metaId, {}]));
    metas.forEach(m => {
        const {pMeta, metaId} = m;
        const pMeta_ = list[m.metaId].pMeta
        const p = (pMeta_ && MBranchs[pMeta_]) || mRoot;
        p[metaId] = MBranchs[metaId];
    });
    return mRoot;
};

const MSHeadRow = row => {
    const cols = removeEmptyTail(row); 
    const ttlL = Math.ceil(((cols.length) - 1)/3);
    
    const nodes = [];
    for(var l = 0; l < ttlL; l++){
        const c0 = 1 + (l * 3);
        const cs = cols.slice(c0, c0+3);
        const [metaId, LVNum, name2] = [toStr(cs[0]).trim(), toStr(cs[1]).trim(), toStr(cs[2]).trim()];
        const [e, c] = toStr(name2).split('|');
        nodes.push({metaId, LVNum, nameEn:toStr(e).trim(), nameCt:toStr(c).trim()});
    }
    
    return nodes;
};

const removeEmptyTail = r => {
    const last = lastSolidIndex(r);
    return (last >= 0) ? r.slice(0, last + 1) : [];
}

const lastSolidIndex = r => {
    const last = r.length-1;
    for(var c = last; c >= 0; c--){
        if(r[c].trim())
            return c;
    }
    return -1;
};

export const decodeMSTreeV2 = (rows, _oldList, _pubList) => {
    const [ oldList, pubList ] = [toObj(_oldList), toObj(_pubList)];
    const nor = rows.length;
    const inNodes = []; 
    for(var r = 1; r < nor; r++)
        pushIfDef(inNodes, MSTreeRowV2(rows[r], r));
    
    const fullList = node2FullList(inNodes, oldList, pubList);
    
    const heads = (nor>0)?MSHeadRow(rows[0]):[];
    const tree = metaNode2Tree(fullList);
    const list = treeKeepList(tree, fullList); 
    const errs = ValidateMetaCSV(oldList, fullList, []);
    
    return {heads, tree, list, errs};
};

const pushIfDef = (ary, item) => item && ary.push(item);
const MSTreeRowV2 = (row, r) => {
    const act = toStr(row[0]).trim().toLocaleLowerCase();
    const nodes = MSHeadRow(row);
    const lv = nodes.length - 1;
    if(lv < 0) return;
    const node = nodes[lv];
    const pMeta = nodes[lv-1]?.metaId;
    return {...node, rnum: (r+1), act, pMeta, childs:{}};
};

const node2FullList = (inNodes, oldList, pubList ) => {
    return Object.fromEntries(inNodes.map( n => {
        const {metaId, pMeta, rnum, childs, act} = n;
        const [o, p] = [toObj(oldList[metaId]), toObj(pubList[metaId])];
        const pub = (p?.wasPub) ?1: 0;
        const node = (act === '')? {...n, ...o}: {...o, ...n};
        return [ metaId, { ...node, pub, wasPub:pub, metaId, pMeta, rnum, childs } ];
    }));
};

const metaNode2Tree = fulllist => {
    const tree = {};
    Object.values(fulllist).forEach( n => {
        if(n.act !== 'delete'){
            const {pMeta, metaId, childs} = n;
            const pc = fulllist[pMeta]?.childs;
            if(pMeta && pc){
                pc[metaId] = childs;
            }else if(!pMeta){
                tree[metaId] = childs;
            }
        }
    });
    return tree;
};


const ValidateMetaCSV = (oldList, fullList, errs=[]) => {
    const oldIds = Object.keys(oldList);
    const newIds = Object.keys(fullList);
    const miss = oldIds.filter(i => !newIds.includes(i));
    if(miss.length) errs.push('missing metadata Ids: ('+miss.join(', ')+')');
    Object.values(fullList).forEach( n => {
        const {act, metaId, rnum, pMeta} = n;
        if(act === 'create'){
            if(oldIds.includes(metaId))errs.push('create "'+metaId+'" already added, row '+rnum);
        }else if(act === 'delete'){
            if(!oldIds.includes(metaId))errs.push('delete "'+metaId+'" not exsits, row '+rnum);
        }else if(act === 'edit'){
            if(!oldIds.includes(metaId))errs.push('edit "'+metaId+'" not exsits, row '+rnum);
        }else if(act === ''){
            if(!oldIds.includes(metaId))errs.push('unchange "'+metaId+'" not exsits, row '+rnum);
        }else{
            errs.push('Unknown Action ('+act+') Row '+rnum);
        }
        const p = pMeta && fullList[pMeta];
        if(p){
            if((act !== 'delete') && (p.act === 'delete')){
                errs.push('not allow '+act+' "'+metaId+'" under delete "'+pMeta+'", row '+rnum);
            }
        }else if(pMeta){               
            errs.push('parent  "'+pMeta+'" not exsits, row '+rnum);
        }
    });
    return errs;
};


const treeKeepList = (tree, list) => {
    const keep = {};
    const k = branch => {
        Object.entries(branch).forEach(([id, childs]) => {
            keep[id] = list[id];
            k(childs);
        });
    }
    k(tree);
    return keep;
}

