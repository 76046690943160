import React, { useEffect, useInsertionEffect, useState } from 'react';
import { toStr, toAry } from '../../libs/libType';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import { svgIcon2 } from '../components/CpATIcoBtn';
import { compareStr, compareMath, compareMath2Str } from '../../libs/libAnsCompare';
import CpATAnsDDResult from './CpATAnsDDResult';

// for fib and lbd text type
/*
                        if ( showCK ) { // use math3Str
                            cfound = compareMath(aoptopt[ii]?.math3Str,smObj[aopt.oupansid]);
                        } else { // use data
                            cfound = compareStr(aoptopt[ii].data,sqObj[aopt.oupansid],caseSensitive);
                        };
*/
export const checkResult = async (arr, ans, stMathStr=[], eqCheck, caseSensitive=false, setIdx) => {
    let idx=-1;
    for (let ii=0;ii<arr.length;ii++) {
        //const compareFunc = eqCheck?compareMath:compareStr;
        let result = 0;
        if (eqCheck) {
            if (stMathStr.length === 3) {
                result = compareMath(arr[ii]?.math3Str, stMathStr);
            } else {
                result = compareMath2Str(arr[ii].data, ans);
            };
        } else {
            result = compareStr(arr[ii].data, ans, caseSensitive);
        };
        //const result = await compareFunc(arr[ii].data, ans, caseSensitive);
        if (result) {idx = ii;break;}
    };
    setIdx(idx);
};

// correct drop down
export const AnsDD = (props) => {
    const {showCK, arr, edProps, idx, correctIndex, autoMark=0, mCorrect=0, finalAnsDisplay,
        menuStyle={}, scrollRef, showOptions=1} = props;
    const ansOpts = [];

    const formatDisplay = (str) => { return showCK?(()=><QEditorReadOnly {...props} 
        data={str} {...edProps} exStyle={{backgroundColor:'transparent',
        padding:'0px 10px 0px 10px'}}/>)
        :(()=><div style={{padding:'0px 10px 0px 10px'}}>{str}</div>);
    };

    if (!autoMark && finalAnsDisplay) { // manual mark, add one more option
        ansOpts.push({id:-1, jsx:formatDisplay(finalAnsDisplay)});
    };

    arr.forEach((an, kk)=>{
        const displayStr = toStr(an.data)?toStr(an.data):(showCK?'<p/>':'<blank>');
        ansOpts.push({id:kk, jsx:formatDisplay(displayStr)});
    });
    return <CpATAnsDDResult key={'ans'+idx} opts={ansOpts} sel={correctIndex} right={false}
        menuStyle={menuStyle} mCorrect={mCorrect} domQuesRef={scrollRef} showOptions={showOptions} />;
};

// use by FIB, student only
export const ResultAnsBlk = (props) => {
    const {showCK, studentAns, stMathStr, arr, edProps, eqCheck, idx, caseSensitive, correctAns,
        asmView=0, isTeacher=0, showResult=0, showCorr=0, autoMark=0, mCorrect=-1, ansCorr=0,
        setResultRef, appendResultInfo, scrollRef, menuStyle={}} = props;
    const [correctIndex, setCorrectIndex] = useState(-2);
    //const [afterInit, setAfterInit] = useState(0);

    const _arr = toAry(arr);
    useEffect(()=>{
        //console.log("showResult || showCorr", {showResult,showCorr});
        if (showResult || showCorr) {
            if (autoMark) {
                if (showCorr) {
                    setCorrectIndex(-1);
                } else {
                    checkResult(_arr, studentAns, stMathStr, eqCheck, caseSensitive, setCorrectIndex); 
                };
            } else { setCorrectIndex(-1) };            
        };
    },[]);

    /*
    useEffect(()=>{
        if (correctIndex !== -2) setAfterInit(1);
        //appendResultInfo && appendResultInfo(idx);
    },[correctIndex]);

    useEffect(()=>{
        if (afterInit) { 
            //console.log("after !");
            //appendResultInfo && appendResultInfo(idx);
        };
    },[afterInit]);
    */

    // todo corr
    const isCorrect = ansCorr?1:(autoMark?(correctIndex >=0):(mCorrect===1));
   
    let displayStr = studentAns;
    if (!displayStr) displayStr = (showCK?'<p> </p>':' ');    

    const exStyle = showCK?{padding:0}:((showResult || showCorr)?{padding:'0 0 0 15px'}:{padding:'0 15px 0 15px'});
    const asmStyle = isTeacher?{width:'auto'}:{backgroundColor:'rgb(232, 230, 250)', border:'1px #5d52ce solid'};
    const asmClass = mCorrect === 0 ? ' wrong':(mCorrect === 1? ' correct':'');
    const finalAnsDisplay = showCK?<QEditorReadOnly {...props} data={displayStr} {...edProps} noBackground={true}/>
        :<div>{displayStr}</div>;
    return <>{(asmView && !showResult)?<div className={'ansInputContainer ' + asmClass} style={{...exStyle,...asmStyle}}>
            {finalAnsDisplay}
        </div>        
        :(correctIndex<-1?'loading':
        (!isCorrect?
            <><div className={'ansInputContainer wrong'} style={exStyle}>
                {finalAnsDisplay}
                {svgIcon2("general/incorrect","#e94929",
                    {minWidth:'30px',minHeight:'30px',width:'30px',height:'30px'})}
            </div>
            {(correctAns && showResult)?<div ref={(el)=>setResultRef(el)} className='flexRowCenterFit ' style={{display:'inline-flex', marginLeft:'5px',
                marginBottom:'5px', verticalAlign:'middle'}}>
                    <AnsDD {...{correctIndex}} {...props} arr={_arr}/>
            </div>:''}
            </>
        :<div className='flexRowCenterFit'><AnsDD {...{correctIndex, mCorrect, autoMark}} {...props}
            arr={_arr} finalAnsDisplay={displayStr} showOptions={showResult} /></div>
        ))}</>;
};

// use by LBD, student, display one answer block only
export const ResultAnsSingleBlk = (props) => {
    const {showCK, studentAns, stMathStr, arr, edProps, eqCheck, caseSensitive,
        asmView=0, showResult=0, showCorr=0, ansCorr=0, isReme=0} = props;
    const [correctIndex, setCorrectIndex] = useState(-2);

    const _arr = toAry(arr);
    useEffect(()=>{
        //checkResult(_arr, studentAns, stMathStr, eqCheck, caseSensitive, setCorrectIndex);
        if (showResult || showCorr) {
            if (showCorr) {
                setCorrectIndex(-1);
            } else {
                checkResult(_arr, studentAns, stMathStr, eqCheck, caseSensitive, setCorrectIndex); 
            };
        };
    },[]);

    //const isCorrect = correctIndex >=0;
    const isCorrect = ansCorr?1:(correctIndex >=0);
  
    let displayStr = studentAns;
    if (!displayStr) displayStr = (showCK?'<p> </p>':' ');    
    const exStyle = showCK?{}:{};

    const finalAnsDisplay = showCK?<QEditorReadOnly {...props} data={displayStr} {...edProps} noBackground={true}/>
        :<div>{displayStr}</div>;    

    return <>{(asmView && !showResult && !showCorr)?<div className={'ansInputContainer '}>{finalAnsDisplay}</div>   
        :correctIndex<-1?'loading':
        (isCorrect?
            <div className={'ansInputContainer correct'} style={exStyle}>
                {showCK?<QEditorReadOnly {...props} data={displayStr} {...edProps} 
                    noBackground={true} exStyle={{padding:'0 !important'}}/>
                :<div>{displayStr}</div>}
                {svgIcon2("general/correct","#4eae84",
                    {minWidth:'25px',minHeight:'25px',width:'25px',height:'25px'})}
            </div>
            :
            <div className={'ansInputContainer wrong'} style={exStyle}>
                {showCK?<QEditorReadOnly {...props} data={displayStr} {...edProps}
                    noBackground={true} exStyle={{padding:'0 !important'}}/>
                :<div>{displayStr}</div>}
                {svgIcon2("general/incorrect","#e94929",
                    {minWidth:'30px',minHeight:'30px',width:'30px',height:'30px'})}
            </div>
        )}</>;
};
