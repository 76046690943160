import React, { useEffect, useState } from 'react';
import { objKeys } from '../../libs/libType';
import * as UI from '../../libs/libUI';
import { autoId } from '../AppUtil';
import CpMJX from '../../AppExPFUser/_components/CpMJX';

const expandMinusIcon = UI.imgSrc('svg/expandMinus.svg');
const expandPlusIcon = UI.imgSrc('svg/expandPlus.svg');
const zoomOutIcon = UI.imgSrc('svg/zoomOut.svg');
const zoomInIcon = UI.imgSrc('svg/zoomIn.svg');

const CpMetasTree = (props => {
    const {treeData, dataArr, drawItem, } = props;
    const [ticks, setTick, setTicks, tickAll, setTickAll, clearTicks] = props.treeTicks; 
    
    const [zoom, setZoom] = useState(1);

    let mounted = 1; useEffect(() => { return (() => {mounted = 0; }); }, []); // Avoid Async Call Back Error
   
    const zoomClick = delta => setZoom(clamp(zoom + delta, 0.1, 2));

    return <div className="CpMetasTree treeContainer">
        <div className="treeZoomContainer">
            <img src={zoomOutIcon} className="zoomIcon" alt="" onClick={()=>zoomClick(0.1)} />
            <img src={zoomInIcon} className="zoomIcon" alt="" onClick={()=>zoomClick(-0.1)} />
        </div>
        <div id="mathDisplay" className="treeBody" style={{transform: "scale("+zoom+")"}}>
            {[buildDisplay(treeData, dataArr, -1, ticks, setTick, drawItem)]}
        </div>
    </div>
});
export default CpMetasTree;

// 0 => top "T",  1 => middle "|-",  2 => tail "|_",  3 => one --
const drawLine = ww => {
    const pos = ['middle', 'middle', 'tail', 'tail'][ww];
    return pos?<div className='DLcontainer'><div className={'DL'+pos+'1'}></div><div className={'DL'+pos+'2'}></div></div>:'';
};

const blockDisplay = (obj, hasChild, cc, ii, tick, whereami, setTick, drawItem) => {
    // first div make the full height, second div make height content fit
    const keySeed = obj.MetaId + "" + ii;
    
    const click = e => { UI.stopEvent(e); setTick(!tick); };
    return <div key={'block'+keySeed} className="treeEleContainer0">
        {(whereami >= 0) && <div className="w" style={{width:'20px'}}></div>}
        {(whereami >= 0) && drawLine(whereami)}
        <div className="treeEleContainer1">
            <div className="treeEleContainer2">
                {hasChild && <div className="treeExpand" onClick={click}> 
                    <img src={tick?expandMinusIcon:expandPlusIcon} className="menuIcon" alt=""/>
                </div>}                
                {drawItem(obj)}
            </div>
            {tick && cc && <div className="treeEleContentSub">{cc}</div>}
        </div>
    </div>;
};

const buildDisplay = (branch, list, baseii, ticks, setTick, drawItem) => {
    const arr = objKeys(branch);
    const nbr = arr.length;
    if (!nbr) 
        return <div key={'block'+baseii}></div>;

    return arr.map((metaId, ii) => {
        const bii = ''+baseii+'.'+ii;
        const hasChild = (Object.keys(branch[metaId])?.length > 0);
        const cc = hasChild ? buildDisplay(branch[metaId], list, bii, ticks, setTick, drawItem) : '';
        const line = ((baseii === -1) ? -1 : ((nbr === 1) ? 3 : ((ii === 0) ? 0 : ((ii === (nbr-1))) ? 2 : 1)));
        return <div key={"arr" + bii} style={{ width: "100%" }}>
            {blockDisplay(list[metaId] || dummyObj, hasChild, cc, bii, ticks.includes(metaId), line, setTick(metaId), drawItem)}</div>;
    });
};

const clamp = (v, min, max) => (min > v)? min :((max < v)? max : v);

const mathml_str1 = "<math mode='display'><munderover><mo>&sum;</mo><mrow><mi>n</mi><mo>=</mo><mn>100</mn></mrow><mn>1000</mn></munderover><mo>(</mo><mfrac><mrow><mn>10</mn><msqrt><mi>n</mi></msqrt></mrow><mi>n</mi></mfrac><mo>)</mo></math>";
//const mathml_str2 = "<math mode='display'><mfenced open='[' close=']'><mrow><mi>a</mi><mi>a</mi></mrow></mfenced></math>";
//const mathml_str3 = "<math xmlns='http://www.w3.org/1998/Math/MathML'><mstack stackalign='right' charalign='center'><mn>11</mn><msrow><mo>+</mo><mn>22</mn></msrow><msline>&nbsp;</msline><mn>33</mn></mstack></math>";

const dummyObj = {
    MetaId: "dummyID", MetaNameCt: "測試姓名", MetaNameEn: "Dummy Name",
    MetaCode: "NCDUMMY", MetaType: "Dummy Series", MetaLang: ['En', 'Ct'], 
    MeteVerDrafe: "D 1.1", MetaVer: "P 1.0", modDate: "2023-07-10 11:59:59", modUser: "dummy_approver",
    mathml: mathml_str1
};
