export function stringify(object) {
  if (typeof object !== 'object') {
    throw new Error('querystring.stringify expects object');
  }

  const params = new URLSearchParams();
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'object' && value.length != null && typeof value.forEach === 'function') {
      value.forEach(v => {
        params.append(`${key}[]`, v);
      });
    } else {
      params.append(key, value);
    }
  });
  return params;
}
