import {useEffect, useMemo, useRef, useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import CpIco from "./CpIco";
import ActionButtonComponent from "./CpActionButtonComponent";
import BadgeComponent from "./CpBadgeComponent";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { SIZE } from "./CpExPFConst";
import { PREFIX } from "./CpPrefix";

const CpBadgeInputComponent = (props) => { // base on poc BadgeInputComponent.tsx
    const {
        size = SIZE.MD,
        errorMessage = "",
        enableEdit = true,
        inputVariant = "at-primary",
        inputStyle = "",
        errorStyle = "",
        badgeVariant = "color-light-badge",
        badgeContainerStyle = "",
        badgeStyle = "",
        defaultBadges = [],
        updateItems,
        lock = true,
    } = props;
    const [items, setItems] = useState(defaultBadges);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const inputRef = useRef(null);
    const hasErrorMessage = errorMessage !== "" && showErrorMessage;
    const badgeInputStyle = useMemo((() => {
        return [`badge-input-component`,
            `${inputStyle ? inputStyle : (hasErrorMessage || items.length === 0 ? "" : "mb-3")}`,
            PREFIX.INPUT + size].filter(item => item).join(' ');
    }), [hasErrorMessage, items]);
    useEffect(()=>{
        updateItems && updateItems(items);
    },[items]);
    const handleEnter = (e) => { if (e.key === 'Enter') { handleItems(); }};
    const handleItems = () => {
        setShowErrorMessage(false);
        if (inputRef.current !== null) {
            const value = inputRef.current.value;
            if (value.replaceAll(' ', '')) {
                setItems(prev => [...new Set([value, ...prev])])
                inputRef.current.value = "";
            } else {
                setShowErrorMessage(true);
            }
            inputRef.current.focus();
        };
    };
    const handleRemove = (item) => { setItems(prev => prev.filter(ite => ite !== item));};
    return <>
        {enableEdit === true && <><InputGroup className={badgeInputStyle}>
            <Form.Control ref={inputRef} onKeyPress={handleEnter}/>
            <ActionButtonComponent size={size} icon={<CpIco src={IconList.general.add}/>} variant={inputVariant}
                                   onClick={handleItems}/>
        </InputGroup>
            {hasErrorMessage && <div className={`${errorStyle ? errorStyle : "mb-3"}`}><small
                className={"text-danger"}>{errorMessage}</small></div>}
        </>
        }
        {/*variant={"gray-preview-primary rounded"}*/}
        <div className={`d-flex flex-row flex-wrap gap-1 ${badgeContainerStyle}`}>
            {items.map((item, idx) => <BadgeComponent key={idx} variant={badgeVariant} title={item}
                                                      className={badgeStyle ? badgeStyle : "text-badge"}
                                                      remove={!lock?<CpIco src={IconList.general.close}/>:''
                                                      } onClick={!lock?() => handleRemove(item):''}>
            </BadgeComponent>)}
        </div>
    </>
};

export default CpBadgeInputComponent;