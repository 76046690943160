import { e } from "mathjs";

export const tryParseJson = (json) => { try{ return JSON.parse(json); }catch(e){}; return false; };

export const toStr = (input, defaultValue = '') => {
    const t = (typeof input);
    if(t === 'object'){
        return defaultValue;
    }else if(t === 'function'){
        return defaultValue;
    }else if(t === 'undefined'){
        return defaultValue;
    }else if(input === false){
        return defaultValue;
    }else if(input === null){
        return defaultValue;
    }else if(input === undefined){
        return defaultValue;
    }
    return (''+input) || defaultValue;
};

export const str2Blob = (str) => new Blob([str], { type: "text/html" });
export const txtByteSize = (str) => new Blob([str], { type: "text/html" }).size;
export const jsonByteSize = (obj) => new Blob([JSON.stringify(obj, null, 2)], { type: "application/json" }).size;

export const strCmp = (a, b) => ((a > b)? 1: ((a < b)? -1 : 0)); 
export const toStrLower = (t, defaultValue = '') => toStr(t, defaultValue).toLowerCase();
export const toStrTrim = (t, defaultValue = '') => toStr(t, defaultValue).trim();
export const toStrTrimLower = (t, defaultValue = '') => toStr(t, defaultValue).trim().toLowerCase();

export const min_max = (a, b) => (a < b)?[a, b]:[b, a];
export const minVal = (a, b) => (a < b? a: b);
export const maxVal = (a, b) => (a > b? a: b);

export const toInt = (input, defaultValue = 0) => toNum(parseInt(input), defaultValue);
export const toFloat = (input, defaultValue = 0) => toNum(parseFloat(input), defaultValue);
export const toFix = (input, dig, defaultValue = 0) => toNum(parseFloat(input), defaultValue).toFixed(dig);
export const toNum = (n, defaultValue = 0) => isNaN(n)? defaultValue: n;

//export const toPercent1 = (val, ttl) => toFloat(ttl? toFloat(((toFloat(val) * 100) / toFloat(ttl)).toFixed(6)).toFixed(1):'0.0');
export const toPercent1 = (val, ttl) => toFloat(ttl? toFloat(((toFloat(val) * 100) / toFloat(ttl)).toFixed(6)).toFixed(1):'0.0');
//export const toPercentF = (val, ttl) => toFloat(ttl)? ((toFloat(val) * 100) / toFloat(ttl)): 0;
  export const toPercentF = (val, ttl) => toFloat(ttl)? ((toFloat(val) * 100) / toFloat(ttl)): 0;
//export const toFaction1 = (val, ttl) => toFloat(ttl? toFloat(((toFloat(val)) / toFloat(ttl)).toFixed(6)).toFixed(1):'0.0');
  export const toFaction1 = (val, ttl) => toFloat(ttl? toFloat(((toFloat(val)) / toFloat(ttl)).toFixed(6)).toFixed(1):'0.0');
//export const toFactionF = (val, ttl) => toFloat(ttl)? ((toFloat(val)) / toFloat(ttl)): 0;
  export const toFactionF = (val, ttl) => toFloat(ttl)? ((toFloat(val)) / toFloat(ttl)): 0;
//export const toFix1 = (val) => toFloat(toFloat(toFloat(val).toFixed(6)).toFixed(1));
  export const toFix1 = (val) => toFloat(toFloat(toFloat(val).toFixed(6)).toFixed(1));

export const isFunc = f => f && (typeof(f) === 'function');
export const isNum = n => toStr(toNum(n)) === toStr(n);
export const isStr = o => (((typeof o) === 'string') || (o instanceof String));
export const isObj = v => (v && (typeof v === 'object') && (v.constructor === Object));
//const _obj = {}; // todo remove un const
export const toObj = v => isObj(v)? v: {};//_obj;
export const isAry = a => Array.isArray(a);
//const _ary = []; // todo remove un const 
export const toAry = a => Array.isArray(a)? a: [];//_ary;
export const toUniAry = a => Array.from(new Set(toAry(a)));
export const to01 = b => b? 1: 0;

export const objVals = o => Object.values(toObj(o)); 
export const objKeys = o => Object.keys(toObj(o)); 
export const objEntries = o => Object.entries(toObj(o)); 
export const objFilter = (o, entryFilterFunc) => Object.fromEntries(objEntries(o).map(entryFilterFunc)); 

export const objMapObj = (o, cb) => Object.fromEntries(objEntries(o).map(cb));
export const objSort = o => isObj(o)? Object.fromEntries(objEntries(o).sort(([a,x], [b, y]) => strCmp(a.toLowerCase(),  b.toLowerCase()))): o;
export const objMap = (o, cb) => objEntries(o).map(cb);
export const objLen = o => objKeys(o).length;

export const aryMap = (a, cb) => toAry(a).map(cb);
export const aryLen = a => toAry(a).length;
export const aryMapObj = (a, cb) => Object.fromEntries(toAry(a).map(cb));

export const nestStripEmpty = o => {
    if(!o){
       return undefined;
    }else if(isObj(o)){
      const es = Object.entries(o)
        .map(([k, v]) => [k, nestStripEmpty(v)])
        .filter(([k, v]) => v); 
      return es.length? Object.fromEntries(es): undefined;
    }else if(isAry(o)){
      const so = o.map(v => nestStripEmpty(v)).filter(v => v);
      return so.length? so: undefined;
    }
    return o;
};
export const nestFlaten = o => {
    if(!o){
        return o;
    }else if(isObj(o)){
        return [].concat(...Object.values(o).map(v => nestFlaten(v))); 
    }else if(isAry(o)){
        return [].concat(...o.map(v => nestFlaten(v))); 
    }
    return [o];
};
/*
const xx = {
a1: [1,2],
a2: {
	b1: [2,3],
    b2: [4,5],
	},
a3: [
	6,
    [
    	7,
        {
        	a:[8,9],
            b:[10,11,12]
        },
        [12,13],
    ],
	],
};
*/
export const nestFlaten1 = x => {
    if ( !x ) {
        return;
    } else if(isObj(x)){
        return [].concat(...Object.values(x).map(v => nestFlaten1(v)));
    } else if(isAry(x)){
        const hasAry = x.findIndex(aa => isAry(aa));
        if (hasAry >= 0) {  
            return [].concat(...x.map(aa => nestFlaten1(aa)));
        } else { return [x] };
    } else {
        return [x];
    };
};

export const nestSolid = o => {
    if(isObj(o)){
        return Object.values(o).some(nestSolid); 
    }else if(isAry(o)){
        return o.some(nestSolid); 
    }
    return o? 1: 0;
};

const testnestSolid = () => {
    const a = [{}];
    const o = {a:{}};
    
    
};
//testnestSolid();
