import CpIco from "./CpIco";
import { CloseButton, Toast } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { getUUID } from "./CpGetUUID";
// poc SharedToast.tsx
const toastType = ["success","error","warning"];

const toastClass = {
  success:"text-notification-success",
  error:"text-notification-error",
  warning:"text-notification-warning",
};

const toastIcon = {
  success:IconList.status.finish,
  error:IconList.status.cancel,
  warning:IconList.general.alert,
};

const borderClass = {
  success:" border-notification-success",
  error:" border-notification-error",
  warning:" border-notification-warning",
};

const titleTxt = {
  success:"notification.success",
  error:"notification.error",
  warning:"notification.warning",
};

const CpSharedToast = (props) => {
  const { myID, msgType, body, handleClose, autoClose=1 } = props;
  const [t, uiEn, UILang, setUILang, ut, t2] = useUILang(); 
  const _msgType = toastType[msgType];
  //const myID = getUUID();
  useEffect(() => {
    console.log("ready !!", myID);
    if (autoClose) { setTimeout(() => { 
      console.log("close !!", myID);
      handleClose && handleClose();
    }, 3000); };
  },[myID]);
  useEffect(() => { }, []); // [] cannot trigger event inside push pop
  
  return <div id={"toast"+myID} className="position-fixed end-0 bottom-0" style={{ zIndex: "1090" }}> 
    <Toast show={true} onClose={handleClose} className={"me-2 me-lg-4 mb-2 mb-lg-4 bg-dim-100 " + borderClass[_msgType]}>
      <Toast.Body className={`p-3 d-flex justify-content-between align-items-center gap-3 w-100 h-100`}>
        <span className={"d-flex gap-3 align-items-center fs-5 user-select-none"}>
          <span className={toastClass[_msgType]}><CpIco src={toastIcon[_msgType]} /></span>
          <div className="d-flex flex-column gap-2 fs-6">
            <span className="semi-bold">{t(titleTxt[_msgType])}</span>
            <span>{t(exToastMsg[body] || body)}</span>
          </div>
        </span>
        <span className="align-self-start"><CloseButton onClick={handleClose} /></span>
      </Toast.Body>
    </Toast>
    </div>
};

export default CpSharedToast;

export const exToastMsg = {
  copyQ:"notification.notification_question_duplicate_success",
  delQ:"notification.notification_question_delete_success",
  addQ:"notification.notification_question_add_succes",
  copyNextRes:"notification.notification_resource_duplicate_success",
  thrSaved:"notification.notification_marking_save_success",
};

/*
const CpSharedToast = (props) => {
  const { open, msgType, body, handleClose, autoClose=1 } = props;
  const [show, setShow] = useState(open);
  const [t, uiEn, UILang, setUILang, ut, t2] = useUILang(); 
  const _msgType = toastType[msgType];
  useEffect(() => {setShow(open)}, [open]);
  useEffect(() => {
    console.log(show);
    if (show && autoClose) { setTimeout(() => { 
      handleClose && handleClose();
      setShow(false);
    }, 3000); };
  }, [show]);

  return <div className="position-fixed end-0 bottom-0" style={{ zIndex: "1031" }}>    
    <Toast show={show} onClose={() => setShow(false)} className={"me-2 me-lg-4 mb-2 mb-lg-4 bg-dim-100 " + borderClass[_msgType]}>
      <Toast.Body className={`p-3 d-flex justify-content-between align-items-center gap-3 w-100 h-100`}>
        <span className={"d-flex gap-3 align-items-center fs-5 user-select-none"}>
          <span className={toastClass[_msgType]}><CpIco src={toastIcon[_msgType]} /></span>
          <div className="d-flex flex-column gap-2 fs-6">
            <span className="semi-bold">{t(titleTxt[_msgType])}</span>
            <span>{t(exToastMsg[body] || body)}</span>
          </div>
        </span>
        <span className="align-self-start"><CloseButton onClick={handleClose} /></span>
      </Toast.Body>
    </Toast>
    </div>
};
*/