import React, {useEffect, useRef, useState} from "react";
import { Button } from "react-bootstrap";
import { aTErrDiv1, ATErrsDiv2, ATErrsDiv, deepCopy, preJS } from "../../AppExPf/AppUtil";
import { normalizeEdit, useEditDraftPub } from "../../AppExPf/utils/useEditDraftPub";
import { useCtnLang, useUILang } from "../../AppExPf/utils/useUILang";
import { objEntries, objKeys, toAry, toObj, toStr } from "../../libs/libType";
import { stopEvent, EpInputTxt0 } from "../../libs/libUI";

import { CpSettingModalText, CpSettingModalTitle, CpSettingModalTabContainer, CpSettingModalSection } from "../_components/CpSettingModalTabCps";
import CpSharedModalHeader from "../_components/CpSharedModalHeader";
import { SIZE } from "../_components/CpExPFConst";
import { IconList } from '../../consts/ATIconListToUsePoc';
import { CHECKBOX_VARIANT } from "../_components/CpExPFConst";
import { subjMetaCombo } from "../EPAssign/TabAssignmentSettingClass";
import CpDropdown, { ary2DropdownItem, obj2DropdownItem } from "../_components/CpDropdown";
import CpIco from "../_components/CpIco";
import CpSharedModal from "../_components/CpSharedModal";
import { normalizedEditExer, validPubExer } from "../../consts/ATValidateExer";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { _WebRoot, _pathMyExer } from "../../consts/ATConstReact";
import { hasErr } from "../../AppExPf/utils/useEditDraftPub";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { ReduxBind } from "../../saga/ReduxState";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { _ATMetaType_SU } from "../../consts/ATMetaTypes";
import { toUniIdAry } from "../../consts/ATValidate";
import CpCheckboxComponent from "../_components/CpCheckboxComponent";

const LayerExNew = ReduxBind(props => {
    const {dispatch, newSrc, setNewSrc, goPopExer, isCopy, subjMetas} = props; 
    const inEn = props.en;

    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const [fields, setFields, setField,  setFieldTick, setTickAry, fieldErrs, setFieldErrs, 
        opts, setOpts, draft, setDraft, pub, setPub] = useEditDraftPub(newSrc, newExDraft(newSrc, isCopy) );
    
    const [ cache2d, useGetCache ] = useCaches(props);
    const rawallTagMSets = useGetCache('allTagMSets', '*');
    useEffect(() => { setFields(fs => fixExMSubj(fs, rawallTagMSets, subjMetas)); }, [rawallTagMSets, subjMetas]);

    const clickClose = e => { stopEvent(e); setNewSrc(0); };

    const clickGo = async e => {stopEvent(e); 
        const [fs, fes] = normalizeEdit(fields, {}, [], normalizedEditExer, validPubExer, setFields, setFieldErrs);
        if (hasErr(fes)) 
            return;
        const [_res, err] = await asyncApiCallLoad_(dispatch, '/putExer', {fields:fs});
        const res = toObj(_res);
        if(res.state === 'retry'){
            setFieldErrs(toObj(res.fieldErrs));
            setFields(toObj(res.fields));
        }else if(res.state === 'ok') {
            const ex = res.pub;
            dispatch(urlPush_Replace( _pathMyExer(ex.EId)));
            setNewSrc(0); goPopExer(ex, deepCopy(ex), ex.ELangEn, 0, 1); 
        }
    };

    const exSrc = toObj(newSrc);
    const [hasE, hasC, canRoll, canPage] = isCopy? [ exSrc.ELangEn, exSrc.ELangCt, exSrc.EModeScroll, exSrc.EModeSlide ]: [1, 1, 1, 1];

    const initE = hasE? 1: hasC? 0: 1;//(!hasC) || (inEn && hasE)? 1: 0; 
    const [en, setEn] = useState(initE);
    useEffect( () => setFields(fs => ({...fs, ELangEn:en, ELangCt:en?0:1})), [en]);

    const clickEn = _en => e => { stopEvent(e); setEn( (_en && hasE)? 1: hasC? 0: 1 ); };
    useEffect(()=>{ clickEn(en)(); }, [hasE, hasC]);

    const [roll, setRoll] = useState(canRoll? 1: 0);
    useEffect( () => setFields(fs => ({...fs, EModeScroll:roll, EModeSlide:roll?0:1})), [roll]);

    const clickRoll = _r => e => { stopEvent(e); setRoll( (_r && canRoll)? 1: canPage? 0: 1); };
    useEffect(()=>{ clickRoll(roll)(); }, [canPage, canRoll]);

    const nKey = en? 'ENameEn': 'ENameCt';
    const [title, setTitle] = [ toStr(fields?.[nKey]), t => setFields(fs => ({...toObj(fs), ENameEn:t, ENameCt:t})) ];
    
    const subjDDI = { '':{t:t('please-select')}, ...subjMetaCombo(subjMetas, ut) }; 
    const msj = j => subjDDI[j]? j: objKeys(subjDDI)[0];  
    const _EMSubjId = msj(fields.EMSubjId);
    
    const shareOptions = obj2DropdownItem({ s0: t("share-options-private"),  s1: t("share-options-share"), });
    const clickShare = s => { setField('EShare')(s==='s0'? 0: 1); };

    //{preJS({EId, hasE, hasC, canPage, canRoll})}
    //{preJS({fields})}
    
    const {ENameEn:ee, ENameCt:ec, EMSubjId:esj } = toObj(fieldErrs);
    return  <CpSharedModal className="LayerExNew" scrollable={true} show={newSrc} 
        header={<CpSharedModalHeader handleClose={clickClose} iconPath={isCopy? IconList.general.edit: IconList.general.add}
            title={t(isCopy? "copy-existing-exercise": "create-new-exercise")}/>
        } 
        footer={<div className={"d-flex gap-2 justify-content-center w-100"}>
            <Button variant="gray-body-color w-100 border" onClick={clickClose}>{t("cancel")}</Button>
            <Button variant="exercise-third-btn btn-block w-100" onClick={clickGo}>{t(isCopy? "copy": "create")}</Button>
        </div>}>
        <CpSettingModalTabContainer gap={3}>
            {ATErrsDiv2(fieldErrs, '', t)}
            <CpSettingModalSection className={"flex-column"}>
                <CpSettingModalTitle>{t("exercise-title")}</CpSettingModalTitle>
                <div className={"w-100 position-relative"}>
                    <div>{EpInputTxt0(title, setTitle, '', 'w-100 border p-1 bg-white d-flex flex-column gap-2 overflow-auto',
                        {minHeight: '50px', maxHeight: '100px'})} </div>
                    <small className={"exercise-edit-counting d-flex justify-content-end text-dim-400 end-0"}>{title.length}/120</small>
                    {aTErrDiv1(ee, t)}
                    {aTErrDiv1(ec, t)}
                </div>
            </CpSettingModalSection>
            <CpSettingModalSection className={"flex-column"}>
                <CpSettingModalTitle>{t("language")}</CpSettingModalTitle>
                <div className={"d-flex w-100 flex-column flex-md-row gap-3 gap-md-5 align-items-start align-items-md-center flex-wrap ms-3 ms-lg-0"}>
                    {hasE? <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={clickEn(1)}>
                        <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={en} checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS}/>
                        <CpSettingModalText>{t("language-en")}</CpSettingModalText>
                    </span>: ''}
                    {hasC? <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={clickEn(0)}>
                        <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={!en} checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS}/>
                    <CpSettingModalText>{t("language-zh")}</CpSettingModalText>
                </span>: ''}
                </div>
            </CpSettingModalSection>
            <CpSettingModalSection className={"flex-column"}>
                <CpSettingModalTitle>{t("exercise-mode")}</CpSettingModalTitle>
                <div className={"d-flex w-100 gap-3 align-items-start align-items-md-center flex-wrap"}>
                    {canRoll? <span role={"button"} onClick={clickRoll(1)} style={{width: "130px"}}
                        className={`d-flex flex-column gap-2 justify-content-center align-items-center p-3 uiAnimation border rounded-light  ${
                            roll? "text-exercise-third-btn border-exercise-third-btn" : "text-dim-350"}`}>
                        <span className={"fs-3"}><CpIco src={IconList.presentation.assessmentMode}/></span>
                        <span className={"fs-8 semi-bold"}><CpSettingModalText>{t("assignment-settings-scroll-mode")}</CpSettingModalText></span>
                    </span>:''}
                    {canPage? <span role={"button"} onClick={clickRoll(0)} style={{width: "130px"}}
                        className={`d-flex flex-column gap-2 justify-content-center align-items-center p-3 uiAnimation border rounded-light  ${
                            roll? "text-dim-350": "text-exercise-third-btn border-exercise-third-btn"}`}>
                        <span className={"fs-3"}><CpIco src={IconList.presentation.practiceMode}/></span>
                        <span className={"fs-8 semi-bold"}><CpSettingModalText>{t("assignment-settings-slide-mode")}</CpSettingModalText></span>
                    </span>:''}
                </div>
            </CpSettingModalSection>
            <CpSettingModalSection className={"flex-column flex-lg-row align-items-lg-start"}>
                <CpSettingModalTitle>{t("subject")}</CpSettingModalTitle>
                <span className={"d-flex w-100 flex-wrap align-items-center"}>
                    <span style={{width: "300px"}}><CpDropdown className="w100" items={subjDDI} idx={_EMSubjId} cb={setField('EMSubjId')} scrollTo={1}/></span>
                </span>
                {aTErrDiv1(esj, t)}
            </CpSettingModalSection>
            <CpSettingModalSection className={"flex-column flex-lg-row align-items-lg-start"} hideBorder={true}>
                <CpSettingModalTitle>{t("share-private")}</CpSettingModalTitle>
                <span className={"d-flex w-100 flex-wrap align-items-center"}>
                    <span style={{width: "300px"}}><CpDropdown className="w100" items={shareOptions} idx={(fields.EShare)?'s1':'s0'} cb={clickShare} scrollTo={1}/></span>
                </span>
            </CpSettingModalSection>
        </CpSettingModalTabContainer>
    </CpSharedModal>;
});
export default LayerExNew;

const newExDraft = (pub, isCopy) => {
    const p = toObj(pub);
    const {ENameEn: e, ENameCt: c} = p; 
    const ENameEn = toStr(e && 'Copy - ' + e);    
    const ENameCt = toStr(c && 'Copy - ' + c);    
    return { ...deepCopy(p), ENameEn, ENameCt, isCopy, isNew:1 };
}; 

const fixExMSubj = (ex, rawallTagMSets, subjMetas) => {
    const emds = toUniIdAry(ex.EMetaDisplays); 
    const jAry = objKeys(subjMetas);
    const jSet = new Set(jAry);

    const EMSubjId = (toUniIdAry([ex.EMSubjId, ...emds]).filter(j => jSet.has(j))[0]);// || jAry[0]; 
    const MJs = objEntries(rawallTagMSets).filter(([msid, mset]) => mset.MSType === _ATMetaType_SU);

    const MJIds = MJs.map(i => i[0]+',');

    const EMetaDisplays = emds.filter(m => !MJIds.some(j => m.startsWith(j)));
    return {...ex, EMetaDisplays, EMSubjId};
};




