import React, { useState, useEffect } from 'react';

import * as SQT from '../../consts/ATSysQType';
import CpQEditorMCQ from './CpQEditorMCQ';
import CpQEditorFIB from './CpQEditorFIB';
import CpQEditorLBD from './CpQEditorLBD';
import CpQEditorOPE from './CpQEditorOPE';
import CpQEditorDraw from './CpQEditorDraw';
import { clearNoUseWiris } from '../components/ckeditor5/Ckeditor5Base';
//import CpQEditorTAG from './CpQEditorTAG';
//import CpQEditorQGP from './CpQEditorQGP';

const CpQEditor = (props) => {
    const {fullQ, Q, editable, myRole, QLang, showPub, fieldErrs, setQtnModified } = props;

    const [afterInit, setAfterInit] = useState(0);
    useEffect(()=>{ 
        setAfterInit(1); 
        return(()=>{ clearNoUseWiris(); })
    },[]);
    const setQ = k => v => {
        if (afterInit) {
            const _f = props.setQ;
            _f && _f(k)(v);
        };
    }
    const setFullQ = (k, v) => {
        if (afterInit) {
            const _f = props.setFullQ;
            _f && _f(k,v);
        };
    };

    if(!fullQ) return <div>Question View Missing Question Metadata.</div>;
    if(!Q) return <div>Question View No Question To Show or Edit.</div>;
  
    const qt = fullQ?.SQType || '';
    
    const Editor = 
        //(qt === SQT.__SYSQType_MCQ)?CpQEditorMCQ:
        (qt === SQT.__SYSQSubType_MCS)?CpQEditorMCQ:
        (qt === SQT.__SYSQSubType_MCT)?CpQEditorMCQ:
        (qt === SQT.__SYSQSubType_FIB)?CpQEditorFIB:
        //(qt === SQT.__SYSQType_LBD)?CpQEditorLBD:
        (qt === SQT.__SYSQSubType_LBT)?CpQEditorLBD:
        (qt === SQT.__SYSQSubType_LBD)?CpQEditorLBD:
        //(qt === SQT.__SYSQType_OPE)?CpQEditorOPE:
        (qt === SQT.__SYSQSubType_OED)?CpQEditorDraw:
        (qt === SQT.__SYSQSubType_OEG)?CpQEditorOPE:
        (qt === SQT.__SYSQSubType_OEE)?CpQEditorOPE:
        (qt === SQT.__SYSQSubType_POL)?CpQEditorOPE:
        //CpQEditorOPE;
        (props => <div>
            <h1>Question Editor</h1>
            Question View Unknown Question Type: '{qt}'.
        </div>);
        // {showPub? '':aTErrTxt1(fieldErrs?.[(QLang==="En"?"Q_E_":"Q_C_")+"qData"])}
    return <Editor {...props} setQ={setQ} setFullQ={setFullQ} afterInit={afterInit} 
        myRole={myRole} setUpFlag={setQtnModified} />;
};
export default CpQEditor;


