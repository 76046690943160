import React from 'react';
import {useTranslation} from "react-i18next";
import * as UI from '../../libs/libUI';

const CpLanguageSwitcher = (props) => { //base on poc <LanguageSwitcher>  
    const {en, flipLang, style} = props
    const {t} = useTranslation(['schema']);
    const onClick = e => { UI.stopEvent(e); flipLang(); };

    return <span role={"button"} className={`simple-switch-container d-flex align-items-center gap-2 bold ${style}`} onClick={onClick}>
        <span className={`me-1 pe-none ${!en? "active" : ""}`}>{t("language-zh")}</span>
        <span className={"simple-switch d-flex justify-content-center px-2"}>
            <input type="checkbox" checked={en} className={"position-relative"} readOnly/>
            <label></label>
        </span>
        <span className={`ms-1 pe-none  ${en? "active" :""}`}>{t("language-en")}</span>
    </span>
}

export default CpLanguageSwitcher;