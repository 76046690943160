import React, {useMemo} from "react";
import { toStr } from "../../../libs/libType";
import { _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED, _AST_WAIT_RESULT, _WST_SUBMIT } from "../../../consts/ATConstsAssignment";
import { useUILang } from "../../../AppExPf/utils/useUILang";

/*
const tagNotYet = (t) => { return {
    circle: "bg-tag-none",
    style: "bg-white border-tag-none text-tag-none",
    title: t("assignment-tag-not-yet-started")
}};
const tagInProg = (t) => { return {
        circle: "bg-tag-warning",
        style: "bg-white border-tag-warning text-tag-warning",
        title: t("assignment-tag-in-progress")
}};
*/
const tagPendManMArk = (t) => { return {
    circle: "bg-tag-warning",
    style: "bg-white border-tag-warning text-tag-warning",
    title: t("assignment-tag-pending-manual-mark")
}};
const tagNone = undefined; 

export const TagProgressTag = (props) => { // base on poc ProgressTag.tsx
    const [t, lang] = useUILang();
    const {AState, WState, hasM} = props;
/*
    const tagNotYet = {
        circle: "bg-tag-none",
        style: "bg-white border-tag-none text-tag-none",
        title: t("assignment-tag-not-yet-started")
    };
    const tagInProg = {
            circle: "bg-tag-warning",
            style: "bg-white border-tag-warning text-tag-warning",
            title: t("assignment-tag-in-progress")
    };
*/    
    const item = useMemo(() => {
        return (!AState || (AState === _AST_PENDING))? 
            tagNone
            :(WState === _WST_SUBMIT)?(
                (AState === _AST_PUBLISHED)?{
                    circle: "bg-tag-success",
                    style: "bg-white border-tag-success text-tag-success",
                    title: t("assignment-tag-result-published")
                }:{
                    circle: "bg-tag-warning",
                    style: "bg-white border-tag-warning text-tag-warning",
                    title: t("assignment-tag-pending-result")
                })
            :(AState === _AST_IN_PROGRESS)? 
                tagNone 
                :{
                    circle: "bg-tag-warning",
                    style: "bg-white border-tag-danger text-tag-danger", 
                    title: t("assignment-tag-expired")
                };
        }, [lang, AState]);

    return <CpStuTag {...{item}} />
};

export const TagStuProgressTag = (props) => {
    const [t, lang] = useUILang();
    const {AState, WState, hasM} = props;
    //const state = asm?.AState;
    return <>
        <TagProgressTag AState={AState} WState={WState} hasM={hasM}/>
        {((AState && (AState !== _AST_PUBLISHED)) && (WState === _WST_SUBMIT) && hasM)? <CpStuTag {...{item: tagPendManMArk(t)} }  />: '' }
    </>;
};

const CpStuTag = (props) => {
    const item = props.item;
    return item? <small
        className={`semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light ${item.style}`}><span
        className={` d-flex align-items-center ${item.circle} rounded-circle`}
        style={{width: "0.5rem", height: "0.5rem"}}></span>{item.title}
    </small>: '';
};

