import { toStr, isAry, toInt } from './libType';
import axios from "axios";
import { createCanvas } from "canvas";
//import { mjAPI } from "mathjax-node";
/*
import { mathjax } from 'mathjax-full/js/mathjax.js';
import MathML from 'mathjax-full/js/input/mathml.js';
import SVG from 'mathjax-full/js/output/svg.js';
import liteAdaptor from 'mathjax-full/js/adaptors/liteAdaptor.js';
import { RegisterHTMLHandler } from 'mathjax-full/js/handlers/html.js';
*/

export const getFIBAnsID = (data) => {
    const idx = [];
    const container = document.createElement("div");
    container.innerHTML = data;    
    const AllAns = container.getElementsByClassName('oupanswer');
    for (let ans of AllAns) {
        const ansid = toInt(ans.getAttribute("oupansid"));
        idx.push(ansid);
    };
    return idx;
};

const unwrap = (el) => {
    if (el && el.parentNode) {
        // move all children out of the element
        while (el.firstChild) {
            el.parentNode.insertBefore(el.firstChild, el);
        };
        // remove the empty element
        el.remove();
    };
};

const handleSpicalTrimMethod = (data) => {
    //Remove mstyle
    const container = document.createElement("div");
    container.innerHTML = data;
    const mstyles = container.getElementsByTagName('mstyle');
    for (let mstyle of mstyles) { unwrap(mstyle); };
    //Remove all attribute
    const parsed = new DOMParser().parseFromString(container.innerHTML, 'text/html');
    parsed.body.querySelectorAll('*').forEach(elem => [...elem.attributes].forEach(attr => elem.removeAttribute(attr.name)));
    return parsed.body.innerHTML;
};

const trimmedSpaceAndRow = (data) => {
    const trimmedSpace = data.replaceAll("<mo>&nbsp;</mo>", "");
    // setTrimmedSpaceValue(trimmedSpace)
    if (trimmedSpace !== null) {
        const trimmedSpaceRow = trimmedSpace.replaceAll("<mrow>", "").replaceAll("</mrow>", "");
        return handleSpicalTrimMethod(trimmedSpaceRow);
    } else return '';
};
// ckeditor math str to mml str by wiris
const getMML = async (mathStr) => {
    return new Promise((resolve, reject) => {
        if (!mathStr) {
            
            resolve("");
        } else {

        
        const params = new URLSearchParams();
        params.append('mml', mathStr);      
        
        axios.post(`https://www.wiris.net/demo/editor/mathml2content`, params,
            { headers:{'Content-Type': 'application/x-www-form-urlencoded'}})
        .then(response => {
            try {
                if (response.data.includes("<merror>")) {
                    resolve('mathError');
                    
                } else {
                    
                    resolve(response.data);
                };
            } catch (e) {
                
                resolve('mathError');
            } })
        .catch( e => {
            
            resolve('mathError');
        } ); };
    });    
};

// extract math tag from ckeditor return string
export const getMathTagStrOnly = (str) => {
    let result = '';
    let div = document.createElement('div');
    div.innerHTML = str;
    const annotations = div.querySelectorAll("annotation");
    if (annotations.length) {
        for (let annotation of annotations) {
            annotation.remove();
        };
    };

    const semanticsContainers = div.querySelectorAll("semantics");
    if (semanticsContainers.length) {
        for (let semantics of semanticsContainers) {
            semantics.outerHTML = semantics.innerHTML;
        };
    };

    const values = div.querySelectorAll("math");

    if (values.length) result = values[0].outerHTML;
    return result;
};

export const rmMathConfigTag = (str) => {
    let result = '';
    let div = document.createElement('div');
    div.innerHTML = str;
    const annotations = div.querySelectorAll("annotation");
    if (annotations.length) {
        for (let annotation of annotations) {
            annotation.remove();
        };
    };

    const semanticsContainers = div.querySelectorAll("semantics");
    if (semanticsContainers.length) {
        for (let semantics of semanticsContainers) {
            semantics.outerHTML = semantics.innerHTML;
        };
    };

    const values = div.querySelectorAll("math");

    if (values.length) result = values[0].outerHTML;
    return result;
};

export const compareStr = (_str1, _str2, caseSensitive=false) => {
    const convert = (str) => {
        const tmp = toStr(str).trim();
        return caseSensitive?tmp:tmp.toUpperCase();
    };
    const str1 = convert(_str1);
    const str2 = convert(_str2);
    return (str1 === str2);
};

export const compareInt = (_int1, _int2) => {
    
    return compareStr(_int1, _int2);
};

// parameter from equation ckeditor 
export const compareMath2Str = (_mm1, _mm2) => {
    //The fallback checking are 1->2->3
    //presentation(p) -> content mathML(c) -> trimmed space and row (t)
    
    const p1 = getMathTagStrOnly(toStr(_mm1));
    const p2 = getMathTagStrOnly(toStr(_mm2));
    if (!p1 || !p2) return false; // nothing to compare, non-math tag string, return false
    if (p1===p2) return true;
    //const c1 = await getMML(p1);
    const t1 = trimmedSpaceAndRow(p1);
    const t2 = trimmedSpaceAndRow(p2);
    
    if (t1===t2) return true;

    // all checkings are wrong
    return false;
};

export const math3Str = async (_mm1) => {
    const p1 = getMathTagStrOnly(toStr(_mm1));
    const c1 = await getMML(p1);
    const t1 = trimmedSpaceAndRow(p1);
    return [p1, c1, t1];
};

// _mm1, _mm2 raw data from equation ckeditor (equation)
export const compareMath = (ms1, ms2) => {
    //console.log('ms1, ms2', ms1, ms2);
    if (!isAry(ms1) || !isAry(ms2)) return false;
    if ((ms1.length !== 3) || (ms2.length !== 3)) return false;
    if ((ms1[0] === ms2[0]) || (ms1[1] === ms2[1]) || (ms1[2] === ms2[2])) return true;
    return false;
};

export const calTxtLen = (txt) => {
    //const { createCanvas, registerFont } = require("canvas");

    // Register a font (if necessary)
    // registerFont('path/to/font.ttf', { family: 'FontName' });
    
    // Create a canvas
    const canvas = createCanvas(200, 100);
    const context = canvas.getContext("2d");
    
    // Set the font properties
    const fontSize = 16;
    // const fontFamily = 'FontName';
    //context.font = `${fontSize}px`; // ${fontFamily}
    //context.font = `${fontSize}em`; // ${fontFamily}
    //Lato
    //context.font = '0.0625em Arial';
    //context.font = '18px Arial';
    // 1 rem = 16 px
    context.font = '1rem Arial';
    
    // Define the letters to compare
    //const letterW = "W";
    //const letterX = "X";
    //const letterM = "M";
    
    // Calculate the width of each letter
    const widthTxt = context.measureText(txt).width;
    //const widthW = context.measureText(letterW).width;
    //const widthX = context.measureText(letterX).width;
    //const widthM = context.measureText(letterM).width;
    
    
    return (widthTxt/16); // rem 
};

export const calMathLen = (value) => {
    const {mathjax} = require('mathjax-full/js/mathjax.js');
    const {MathML} = require('mathjax-full/js/input/mathml.js');
    const {SVG} = require('mathjax-full/js/output/svg.js');
    const {liteAdaptor} = require('mathjax-full/js/adaptors/liteAdaptor.js');
    const {RegisterHTMLHandler} = require('mathjax-full/js/handlers/html.js');

//
//  Create DOM adaptor and register it for HTML documents
//
    const adaptor = liteAdaptor();
    const handler = RegisterHTMLHandler(adaptor);
//
//  Create input and output jax and a document using them on the content from the HTML file
//
    const mml = new MathML();
    const svg = new SVG({fontCache: ('none')});
    const html = mathjax.document('', {InputJax: mml, OutputJax: svg});

//
//  Typeset the math from the command line
//
    const mathOnly = getMathTagStrOnly(value);
    const node = html.convert(mathOnly);
    
    let result = adaptor.innerHTML(node);
    const {width, height} = getSVGDimensions(result);

    const widthInPx = parseFloat(width) * 0.5 * 16;
    const heightInPx = parseFloat(height) * 0.5 * 16;
    const widthInEm = widthInPx / 16;
    const heightInEm = heightInPx / 16;

    
    return widthInEm;
    /*
    res.send({
        data: value,
        svg: result,
        widthInPx,
        heightInPx,
        widthInEm,
        heightInEm
    });*/
};

const getSVGDimensions = (svgString) => {
    const widthMatch = svgString.match(/width="([^"]+)"/);
    const heightMatch = svgString.match(/height="([^"]+)"/);

    const width = widthMatch ? parseFloat(widthMatch[1]) : 0;
    const height = heightMatch ? parseFloat(heightMatch[1]) : 0;

    return {width, height};
};