//import intersect from 'fast_array_intersect'

import { ReduxBind } from '../../saga/ReduxState';

import * as UI from '../../libs/libUI';
import { svgIcon2 } from '../components/CpATIcoBtn';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { normailizeFilter } from '../../consts/ATValidateQFilter';
import { objKeys, toObj, toStr } from '../../libs/libType';
import CpMetasTree from '../components/CpMetasTree';
import { walkTreeFunc } from '../utils/utilWalkTree';

import { arrayAddOrDelete_ } from '../../libs/libArray';
import { useStateGST } from '../../saga/globalState.saga';
import { CpTypeMetaTrees, SysQTypeTree } from '../components/CpTypeMetaTree';
import VMenu from '../components/CpVMenu';

import { _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, _ATMetaType_EX, _ATMetaType_GR, _ATMetaType_QT, _ATMetaType_SU, _ATMetaType_TB } from '../../consts/ATMetaTypes';
import { ATHr, ATUICheckBox0, ATUIText } from '../AppUtil';
import CpMJX from '../../AppExPFUser/_components/CpMJX';
import CpDropImg from '../components/CpDropImg';
import CpDropdown, { ent2DropdownItem } from '../../AppExPFUser/_components/CpDropdown';
import { useEffect, useMemo } from 'react';

const initQFTs = {
    q1: 1, qgroup: 1, langCt: 1, langEn: 1, langAll: 1, qunpub: 1, qpub: 1, qr2p: 1, qdraft: 1,
    uLoginStr: '',
    metaIds: [], SQTs: [],
};
export const useStateGSTQFTs = props => useStateGST(props, 'QFilters', initQFTs);

const LayerATQtnFilter = ReduxBind(props => {
    const {close, dispatch, setQids, metaSets, typeMetaSets, QFTs, setQFTs, loadQFTs, onLoadQFTs } = props;

    const setField = key => val => setQFTs(fs => ({ ...fs, [key]: val }));
    const setChk = key => on => setQFTs(fs => ({ ...fs, [key]: (on ? 1 : 0) }));

    const sqtChks = QFTs.SQTs;
    const setSqtChk = mid => on => setQFTs(fs => ({ ...fs, SQTs:arrayAddOrDelete_(fs.SQTs, mid, on)}));

    const metaTicks = QFTs.metaIds;
    const setMetaTick = mid => on => setQFTs(fs => ({ ...fs, metaIds:arrayAddOrDelete_(fs.metaIds, mid, on)}));

    const onLoad = (res, err) => {
        onLoadQFTs(res, err);
        close();
    };
    const tapGo = e => { UI.stopEvent(e);
        const fs = normailizeFilter(QFTs || {}); 
        setQFTs(fs);
        loadQFTs(fs, onLoad);
    };
    const tapReset = UI.stopEventThen(e => setQFTs(initQFTs));

    const menuArr = [
        { name: 'Filters 1', jsx: <FilterOptions1 {...{QFTs, setField, setChk}}/> },
        { name: 'Filters 2', jsx: <FilterOptions2 {...{typeMetaSets, metaTicks, setMetaTick, sqtChks, setSqtChk}} />},
    ];
    //{ name: 'Filters 3', jsx: <FilterOptions3 /> },

    //<BtnPopDev>{preJS(QFTs, 3)}</BtnPopDev>

    return UI.ScreenMask(<>
        <div className="screenMask2"></div>
        <div className="ATQtnFilterContainer">
            <div className="ATQtnFilterContent">
                <div className="filterRow1"><div></div><div className='f18'>Search & Filter</div>
                    <div className='clickable' onClick={close}>{svgIcon2("general/close", "white", { width: "30px" })}</div>
                </div>
                <VMenu arr={menuArr} width={"90%"} height={"100%"} expands={[0,1]}/>
                <div className='ATfilterLine' />
                <div className='flexRowEnd'>
                    {UI.colorButton('clearBtn', 'Reset', tapReset, '#e3f5ff', '100px', { margin: '10px', color: '#2995cd' })}
                    {UI.colorButton('searchBtn', 'Search', tapGo, '#e3f5ff', '100px', { margin: '10px', color: '#2995cd' })}
                </div>
                {'' && <><div><MetaSetOptTree {...{ metaSets }} /></div><div>{MSSetsTree(metaSets)}</div></>}
            </div>
        </div>
    </>);
});
export default LayerATQtnFilter;


const FilterOptions1 = (props => { 
    const {setField, setChk} = props;
    const QFTs = toObj(props.QFTs);
    const sortQDDI = useMemo(() => ent2DropdownItem([
        ['qs_NEW', 'Modified Newest'],
        ['qs_OLD', 'Modified Oldest'],
        ['qs_IDAZ', 'QID (A-Z)'],
        ['qs_IDZA', 'QID (Z-A)'],
    ]), []);
    const [sortQ, setSortQ] = [QFTs.sortQ, setField('sortQ')];
    useEffect(() => {
        const sqk = objKeys(sortQDDI);
        if(!sqk.includes(sortQ)) setSortQ(sqk[0]);
    }, [sortQ]);

return <div className='VMenuTab'>
<div className="ATfilterOptions f15">
    <div className='ATfilterCol'>
        <table className="ATfilterTable"><tbody>
            <tr><td className="ATfilterLabel">Sorting: </td>
                <td><CpDropdown className="w" id="DDSortQ" {...{items: sortQDDI, idx: sortQ, cb: setSortQ}} /></td>
            </tr>
            <tr><td className="ATfilterLabel">Languages</td>
                <td><div className='ATfilterRow'>
                    <label className="m4">{ATUICheckBox0(QFTs?.langEn, setChk('langEn'), 'che')}[En]</label>
                    <label className="m4">{ATUICheckBox0(QFTs?.langCt, setChk('langCt'),  'chc')}[繁中]</label>
                    <label className="m4">{ATUICheckBox0(QFTs?.langAll, setChk('langAll'), 'chkec')}[En 繁中]</label>
                </div></td>
            </tr><tr>
            {/*<td className="ATfilterLabel">Question</td><td><div className='ATfilterRow'>
                    <div className='ATfilterRow'><label>{UI.CheckBox0(QFTs?.q1, setChk('q1'), 'chkq1')} Individual Question </label></div>
                    <div className='ATfilterRow'><label>{UI.CheckBox0(QFTs?.qgroup, setChk('qgroup'), 'chkqg')} Question group </label></div>
                </div></td>
            </tr><tr>*/}
                <td className="ATfilterLabel">Mark Range</td>
                <td><div className='ATfilterRow'>{ATUIText(QFTs?.s1, setField('s1'), 'txts1')} - {ATUIText(QFTs?.s2, setField('s2'), 'txts2')} </div></td>
            </tr><tr>
                <td className="ATfilterLabel">Keyword</td>
                <td><div className='ATfilterRow'>{ATUIText(QFTs?.kw, setField('kw'), 'txtk')} </div></td>
            </tr><tr>
                <td className="ATfilterLabel">Created/Modified By</td>
                <td><div className='ATfilterRow'>
                    <div className='flexRowStartFit' style={{flexShrink:1, marginRight:'4px'}}>
                    <label className="m4">{ATUICheckBox0(QFTs?.usrme, setChk('usrme'), 'chkum')}Me</label>
                    <label className="m4">{ATUICheckBox0(QFTs?.usr, setChk('usr'), 'chku')}Users</label>
                    </div>
                    <div className='flexRowStartFit' style={{flex:1}}>{ATUIText(QFTs?.uLoginStr, setField('uLoginStr'), 'txtl')}</div>
                    </div></td>
            </tr>
            <tr><td className="ATfilterLabel" colSpan={2}><label className="m4">{ATUICheckBox0(QFTs?.bm, setChk('bm'), 'chkbm')} Only Show Bookmarked</label></td></tr>
        </tbody></table>
    </div>
    <div className='ATfilterCol'>
        <table className="ATfilterTable"><tbody>
            <tr><td className="ATfilterLabel2">Version</td>
                <td><div className='ATfilterRow'>
                    <label className="m4">{ATUICheckBox0(QFTs?.qdraft, setChk('qdraft'), 'chkqdraft')}Draft</label>
                    <label className="m4">{ATUICheckBox0(QFTs?.qpub, setChk('qpub'), 'chkqpub')}Published</label>
                    <label className="m4">{ATUICheckBox0(QFTs?.qunpub, setChk('qunpub'), 'chkqunpub')}Unpublished</label></div>
                </td></tr>
            <tr><td></td>
                <td><div className='ATfilterRow'><label className="m4">{ATUICheckBox0(QFTs?.qr2p, setChk('qr2p'), 'chkqr2p')}Requested to Publish</label></div></td>
            </tr>
            <tr><td className="ATfilterLabel2">Question ID</td>
                <td><div className='ATfilterRow'>{ATUIText(QFTs?.QId, setField('QId'), 'txtqid')} </div>
                </td></tr>
            <tr><td className="ATfilterLabel2">Modified Within</td>
                <td><div className='ATfilterRow2'><div style={{ width: "50px" }}>from</div><DatePicker selected={QFTs?.d2} onChange={setField('d2')} /></div>
                </td></tr>
            <tr><td></td>
                <td><div className='ATfilterRow2'><div style={{ width: "50px" }}>to</div><DatePicker selected={QFTs?.d1} onChange={setField('d1')} /></div>
                </td></tr>
        </tbody></table>
    </div>
</div>
</div>;
});

const FilterOptions2 = ReduxBind( props => {
    const {dispatch, typeMetaSets, metaTicks, setMetaTick, sqtChks, setSqtChk} = props;
    const [opens, setOpens] = useStateGST(props, 'QFindTreOps', []);
    const setOpen = (id, o) => e => { UI.stopEvent(e); setOpens(os => arrayAddOrDelete_(os, id, o)) };  

    const para = {ticks:metaTicks, setTick:setMetaTick, opens, setOpen};
    
    return <div style={{textAlign:'left', padding:"10px 20px 10px 20px"}}>{typeMetaSets && <>
        <CpTypeMetaTrees typeName="subject" metaSets={typeMetaSets[_ATMetaType_SU]} {...para} />
        <CpTypeMetaTrees typeName="Textbook" metaSets={typeMetaSets[_ATMetaType_TB]} {...para} />
        <ATHr />
        <CpTypeMetaTrees typeName="Curriculum" metaSets={typeMetaSets[_ATMetaType_CU]} {...para}  />
        <ATHr />
        <SysQTypeTree typeName="System Question Category" {...{sqtChks, setSqtChk, opens, setOpens}} />
        <CpTypeMetaTrees typeName="Question Category" metaSets={typeMetaSets[_ATMetaType_QT]} {...para} />
        <CpTypeMetaTrees typeName="Grades" metaSets={typeMetaSets[_ATMetaType_GR]} {...para} />
        <ATHr />
        <CpTypeMetaTrees typeName="Copyright" metaSets={typeMetaSets[_ATMetaType_CO]} {...para} />
        <CpTypeMetaTrees typeName="Difficulty" metaSets={typeMetaSets[_ATMetaType_DI]} {...para} />
        <CpTypeMetaTrees typeName="Examination Types" metaSets={typeMetaSets[_ATMetaType_EX]} {...para} />
    </>}</div>;
});

const drawTest = obj => {
    return <div className="treeEleContent2">
        {UI.CheckBox0(0, UI.stopEvent, 'chk')}
        <div className="treeEleContent3">
            <CpMJX><div>{toStr(obj.LVNum || obj.nameEn || obj.nameCt)}</div></CpMJX>
        </div>
    </div>;
};

const MSSetsTree = sets => {
    const arr = Object.keys(sets);
    let result = [];
    arr.forEach((ms, ii) => {
        
        result.push(<CpMetasTree treeData={sets[ms].tree} dataArr={sets[ms].metas} drawItem={drawTest} />);
    });
    return result;
};

const MetaSetOptTree = (props) => {
    const rows = [];
    Object.entries(props.metaSets).forEach(([msid, ms]) => {
        const result = { rows, metas: ms.metas };
        walkTreeFunc(result, ms.tree, msid, 0, metasToRows);
    });
    return <>{rows}</>;
};
const metasToRows = (result, branch, id, lv, pId) => {
    const meta = result.metas[id];
    result.rows.push(<MetaRow {...{ meta, lv }} />);
};

const MetaRow = props => {
    const { meta, lv } = props;
    return <div style={{ margin: '0 0 0 ' + lv * 12 + 'px' }} >
        <label>{UI.CheckBox0(0, UI.stopEvent, 'chk')}{meta.metaId} - {meta.nameCt} + ' - ' - {meta.nameEn}</label>
    </div>;
}


