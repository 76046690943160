import React, { useState, useEffect, useRef } from 'react';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import DDAnswerContainer from '../components/CpMCAnswers';
import CpATPullDownMenu from '../components/CpATPullDownMenu';
import { svgIcon2, iconButton } from '../components/CpATIcoBtn';
import * as SQT from '../../consts/ATSysQType';
import * as MCAC from '../../consts/ATQtnAnsTypeMCQ';
import * as UI from '../../libs/libUI';
import MCChk from '../components/CpMCTblChk';
import { ReduxBind } from '../../saga/ReduxState';
import CpATImage from '../components/CpATImage';
import { useUILang } from '../utils/useUILang';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpActionButtonComponent from '../../AppExPFUser/_components/CpActionButtonComponent';
import { SIZE } from '../../AppExPFUser/_components/CpExPFConst';
import { isAry } from '../../libs/libType';

const maxAns = 10;
const minAns = 2;

const cssClass = {
    'atview': ' atView', // at preview
    'atedit': ' atEdit', // at edit
    'teview': ' teView', // teacher preview
    'teedit': ' teEdit', // teacher edit
    //'stview':' stView', // student view result
    //'stedit':' stEdit'  // student take the test
};

//const _newAns = { data: "<p>Please input answer here</p>", type: MCAC.__MCAT_ATG, correct: 0 };
const _newAns = { data: "", type: MCAC.__MCAT_ATG, correct: 0 };

/* cell = {data:"<p>data</p>", type:MCAC.__MCAT_ATG, correct: 0}
[
    [cell, cell],
    [cell, cell],
]
*/
const newCell = (_type)=> {return {data:"", type:_type, correct: 0, width:'', height:''}};
const newRow = (colCount=2, type=MCAC.__MCTT_TTC) => {
	const row = [];
	for(let ii=0;ii<colCount;ii++) {
		row.push({...newCell(type)});
	};
	return row;
	//return new Array(colCount).fill({...newCell});
};
const _newTAns = (_type) => [newRow(2, _type),newRow(2, _type),newRow(2, _type)];
//const _newTAns = (type) => {return [...Array(2)].map(e => Array(2).fill({...newCell(type)}))};
const CpQEditorMCQ = ReduxBind(props => {
    const { isAT, Qpd, fullQ, Q, setQ, setFullQ, afterInit, QErrs, editable, setUpFlag,
        setOnAddMedia, mediaDLs, getMediaDLs,
        QLang, showPub, qerrTxt1, dispatch } = props;
    //const [ansArr, setAnsArr] = useState(Q?.qAnswers || (editable ? [{ ..._newAns }] : []));
    //const [tansArr, setTAnsArr] = useState(Q?.tAnswers || (editable ? _newTAns : []));
    const [ t ] = useUILang();
    const [checkOver, setCheckOver] = useState(-1);
    const [mode, setMode] = useState(editable ? 'atedit' : 'atview');
    const [myClass, setMyClass] = useState(editable ? 'atEdit' : 'atView');
    const [myChoice, setMyChoice] = useState(fullQ.ansChoice);

    const [scrollArrows, setScrollArrows] = useState({left:false, right:false});
    const [visOpt, setVisOpt] = useState(0);
    const ansEditor = useRef([]);

    
    //const mcChoice = fullQ.multiChoice?fullQ.multiChoice:SQT.__SYSQSubType_MCS;
    const mcChoice = fullQ.SQType?fullQ.SQType:SQT.__SYSQSubType_MCS;
    // check for new question, switch from mc to mctable, the answer choice may still be mc's
    const ansChoice = fullQ.ansChoice?((fullQ.SQType===SQT.__SYSQSubType_MCT && !MCAC.__MCTTcodes.includes(fullQ.ansChoice)) ? MCAC.__MCTT_TTC:fullQ.ansChoice)
        :(mcChoice===SQT.__SYSQSubType_MCS?MCAC.__MCAC_ACG:MCAC.__MCTT_TTC);
    const multiAnswer = fullQ.multiAnswer?fullQ.multiAnswer:0;

    const qData = Q?.qData || "";
    const ansArr = Q?.qAnswers || (editable ? [{ ..._newAns},{..._newAns }] : []);
    const tansArr = Q?.tAnswers || (editable ? [..._newTAns(ansChoice)] : []);
    
    const refTansDiv = useRef(null); // for control scrolling
    const refCK = useRef(null);
    //const mode = editable?'edit':'view'; // view, edit, test
    const updateQ = (func, d) => { func && func(d) };
    
    /*
    if (Q.ansChoice === undefined) {
        if (Q.multiChoice === SQT.__SYSQSubType_MCS) updateQ(setQ('ansChoice'),MCAC.__MCAC_ACG)
        else if (Q.multiChoice === SQT.__SYSQSubType_MCT) updateQ(setQ('ansChoice'),MCAC.__MCTT_TTC);
    };
    */
    useEffect(()=>{
        checkScrollArrow();
    },[]);

    useEffect(()=>{
        
        if (afterInit && MCAC.__MCTTcodes.includes(fullQ.ansChoice) && myChoice !== fullQ.ansChoice) {
            
            //reset answer data
            
            setMyChoice(fullQ.ansChoice);
            updateQ(setQ("tAnswers"), [..._newTAns(ansChoice)]);
        };
    }, [fullQ.ansChoice]);

    useEffect(()=>{
        
        if (afterInit && !fullQ.multiAnswer) {
            
            resetAns();
        };
    }, [fullQ.multiAnswer]);    

    useEffect(() => {
        setMyClass(cssClass[mode]);
        //setEditable(!(mode.substring(2,6) === 'view'));
    }, [mode]);

    useEffect(()=>{
        //check show arrow or not
        /*
            scrollLeft <= 0, no left arrow
            scrollLeft >= scrollLeftMax no right arrow
            scrollLeftMax = 0 , no arrow
        */
       checkScrollArrow();
    },[tansArr]);

    const checkScrollArrow = () => {
        
        let right = false, left = false;
        const rr = refTansDiv.current;
        if (rr) {
            
            //rr.clientWidth, rr.scrollWidth, rr.scrollLeftMax, rr.offsetLeft, rr.scrollLeft);        
            if (rr.scrollLeft > 0) left = true;
            //if (rr.scrollLeft >=0 && rr.scrollLeft < rr.scrollLeftMax) right = true;
            if ((rr.scrollLeft + rr.clientWidth) < rr.scrollWidth) right = true;
            setScrollArrows({left, right});
        };
    };

    const scrollTDiv = (delta) => {
        const factor =200;
        const rr = refTansDiv.current;
        if (rr) {
            const tmp = rr.scrollLeft + (delta * factor);
            rr.scrollLeft = tmp;
            
            //checkScrollArrow();
        };
    };    

    const resetAns = () => {
        let found = false;
        ansArr.forEach((ans)=>{if (ans.correct) {if (!found) { found=true } else ans.correct=0; }; });
        updateQ(setQ("qAnswers"), [...ansArr]);
        if (ansChoice === MCAC.__MCTT_TTC) {
            tansArr.forEach((row)=>{found = false;
                row.forEach((col)=>{if (col.correct) {if (!found) { found=true } else col.correct=0; };});
            });
        };
        if (ansChoice === MCAC.__MCTT_TTB) {
            found=false;
            tansArr.forEach((row)=>{
                row.forEach((col)=>{if (col.correct) {if (!found) { found=true } else col.correct=0; };});
            });
        };
        updateQ(setQ("tAnswers"), [...tansArr]);
    };

    const onEditorReady = (editor) => { };

    //const clickCloseOpt = UI.stopEventThen(e => setVisOpt(0));
    const clickOpt = UI.stopEventThen(e => setVisOpt(1));
    const clickReset = (e) => {
        UI.stopEvent(e);
        updateQ(setQ("tAnswers"), [..._newTAns(ansChoice)]);
    };

    const newAns = () => {
        
        if (ansArr.length === maxAns) return;
        updateQ(setQ("qAnswers"), [...ansArr, { ..._newAns }]);
        //setAnsArr([...ansArr, { ..._newAns }])
    };

    const ddClick = (e, ii) => {
        UI.stopEvent(e);
        
        if (multiAnswer) ansArr[ii].correct = ansArr[ii].correct ? 0 : 1;
        else ansArr.forEach((ans, jj) => { ans.correct = (ii === jj ? 1 : 0) });
        updateQ(setQ("qAnswers"), [...ansArr]);
        //setAnsArr([...ansArr]);
    };

    const tAnsRowHeadClick = (e,ii) => {
        UI.stopEvent(e);
        
        if (multiAnswer) tansArr[ii][0].correct = tansArr[ii][0].correct ? 0 : 1;
        else tansArr.forEach((row, jj) => { row[0].correct = (ii === jj ? 1 : 0) });
        updateQ(setQ("tAnswers"), [...tansArr]);
    };

    const setAnsType = (t, ii) => {
        if (t === ansArr[ii].type) return;
        ansEditor.current[ii] = null; // reset editor ref
        // only keep data for general if answer type changed
        //if (t !== MCAC.__MCAT_ATG) ansArr[ii].data = "";
        ansArr[ii].data = "";
        ansArr[ii].type = t;
        
        updateQ(setQ("qAnswers"), [...ansArr]);
        //setAnsArr([...ansArr]);
    };

    const setAnsData = (data, ii) => {
        ansArr[ii].data = data;
        updateQ(setQ("qAnswers"), [...ansArr]);
        //setAnsArr([...ansArr]);
    };

    const deleteAns = (ii) => {
        if (ansArr.length <= minAns) return;
        ansArr.splice(ii, 1);
        updateQ(setQ("qAnswers"), [...ansArr]);
        //setAnsArr([...ansArr]);
    };

    const setOver = (ii) => {
        setCheckOver(parseInt(ii));
    };

    const setTAnsData = (d, ii, kk) => {
        tansArr[ii][kk].data = d;
        updateQ(setQ("tAnswers"), [...tansArr]);
        //setTAnsArr([...tansArr]);
    };

    const setCellCorrect = (v,ii,kk) => {
        if (!multiAnswer) tansArr[ii].forEach((ans)=>{ans.correct=false});
        tansArr[ii][kk].correct = v;
        updateQ(setQ("tAnswers"), [...tansArr]);
        //setTAnsArr([...tansArr]);
    };

    // set radio select to each row's cell[0].correct
    // ans = column index
    const setRadioCorrect = (v, ii, kk) => {
        tansArr[ii][0].correct = v;
        updateQ(setQ("tAnswers"), [...tansArr]);
        //setTAnsArr([...tansArr]);
    };

    const addAnsCol = (index) => {
        if (tansArr[0].length >= (maxAns + 1)) return;
        tansArr.forEach((row, ii)=>{
            adjArr(row,index,0,{...newCell(ansChoice)});
        });
    };

    const delAnsCol = (index) => {
        // at least 2 columns
        if (tansArr[0].length <= (minAns)) return;
        tansArr.forEach((row, ii)=>{
            adjArr(row,index,1);
        });
    };

    const addAnsRow = (index) => {
        if (tansArr.length >= (maxAns + 1)) return;
        adjArr(tansArr, index, 0, newRow(tansArr[0].length,ansChoice));
    };

    const delAnsRow = (index) => {
        // at least two rows
        if (tansArr.length <= (minAns+1)) return;
        adjArr(tansArr, index, 1);
    };

    const adjArr = (arr, index, cnt = 0, obj = undefined) => {
        
        if (Array.isArray(arr)) {
            if (obj) {
                
                arr.splice(index, cnt, obj);
            } else {
                
                arr.splice(index, cnt);
            };
        };	
        updateQ(setQ("tAnswers"), [...tansArr]);
        //setTAnsArr([...tansArr]);
    };

    //const ck_updateMediaID = (arr) => {updateQ(setQ("refreshMediaIdURL"),[...arr])};
    const ck_updateMediaID = (arr) => { return getMediaDLs([...arr]); };
    const ckImageConfig = {
        setOnAddMedia,
        updateMediaID: ck_updateMediaID,
    };
    const edProps = {isAT, dispatch, mediaDLs, getMediaDLs, setOnAddMedia};

    //<table key={"DDtb" + ii} className={"DDcontentTbl" + (editable?"":"ReadOnly") + myClass}>
    // obj.correct for at and teacher
    // <td className="DDtdGap" />

    //const showQuesEdit = (editable && mode.substring(0,2) !== "st")   
    
    const customRender = (obj, ii) => {
        if (ansChoice === MCAC.__MCAC_ACB) {
            return <div className={"f32 bold DDQtnLabel" + (obj.correct? " Sel" : "")}
                    onClick={(e) => ddClick(e, ii)}>
                {String.fromCharCode(65 + ii)}
                { editable && <div className="DDQtnLabelTrash">
                    <CpActionButtonComponent variant={"gray-at-secondary-dim-dark p-2"} size={SIZE.XS}
                        icon={<CpIco src={IconList.general.trash}/>} onClick={() => deleteAns(ii)}/>                    
                </div>}
            </div>;
        };

        const focusEditor = (ii) => {if (ansEditor?.current[ii]) ansEditor.current[ii].focus();};
        
        const editor = (edType, ii) => <React.Fragment key={edType+ii}>
            {edType === "image"? <CpATImage id={"img"+obj.data.oupid+ii} media={obj.data} {...edProps}
                editable={editable} setData={(d) => setAnsData(d, ii)} />:
            <Ckeditor5Base  {...props} editorType={edType} data={obj.data} setData={(d) => setAnsData(d, ii)}
                enable={editable} debug={false} showStatus={false} ckImageConfig={ckImageConfig} 
                setAnsEditor={(obj)=>ansEditor.current[ii]=obj} {...edProps} setUpFlag={setUpFlag}/>}
        </React.Fragment>;

        
        return <table key={"DDtb" + ii} className={"DDcontentTbl" + (editable ? "" : " unclickable") + (obj.correct? " Sel" : "")}>
            <tbody><tr>
                {(ansChoice === MCAC.__MCAC_ACL) && <td className="DDtd0">
                    <div className="flexColCenter" style={{width:"20px",height:"20px",borderRadius:"50%",
                    backgroundColor:(obj.correct?"#4EAE84":"#e2e2e2")}}>
                    {svgIcon2('general/confirm','white')}
                    </div>
                </td>}                      
                <td className={"DDtd1 " + myClass + (checkOver === ii ? " Over" : (obj.correct? " Sel" : ""))} onClick={(e) => ddClick(e, ii)}>                         
                    <div className="DDCharHead">{String.fromCharCode(65 + ii)}</div>
                    {(ansChoice === MCAC.__MCAC_ACG) && <div className="flexColCenter" 
                        style={{width:"20px",height:"20px",borderRadius:"50%", 
                        backgroundColor:(obj.correct?"#FFFFFF":"#e2e2e2")}}>
                        {svgIcon2('general/confirm',(obj.correct?'#4eae84':'white'))}
                    </div>}
                </td>
                <td className={"DDtd2 "}><div className="DDQtnEditRow" key={"QEdit" + ii} onClick={()=>focusEditor(ii)}>
                    {obj.type===MCAC.__MCAT_ATI?editor("image",ii):editor("inputAnswer",ii)}
                    <div className='DDQtnEditBtn gap-1 '>
                        {(ansChoice !== MCAC.__MCAC_ACB) && editable && <PopMenuBtn obj={obj} setAnsType={(t)=>setAnsType(t, ii)} />}
                        {editable && <CpActionButtonComponent variant={"borderless-at-secondary-dim-dark p-2"} size={SIZE.SM}
                            icon={<CpIco src={IconList.general.trash}/>} onClick={() => { deleteAns(ii) }}/>}
                    </div>
                </div></td>
            </tr></tbody>
        </table>;
    };
    //{obj.type===MCAC.__MCAT_ATE?editor("equation",ii):(obj.type===MCAC.__MCAT_ATI?editor("image",ii):editor("inputAnswer",ii))}
/*
                    {(ansChoice === MCAC.__MCAC_ACL) && <td className="DDtd0">
                        <div className="flexColCenter" style={{width:"20px",height:"20px",borderRadius:"50%",
                            backgroundColor:(obj.correct?"#4EAE84":"#e2e2e2")}}>
                            {svgIcon2('ddtick','general/confirm','white')}
                        </div>
                    </td>}
            <React.Fragment key={edType+ii+kk}>
                <Ckeditor5Base editorType={edType} data={cell.data} setData={(d) => setTAnsData(d, ii, kk)}
                enable={editable} debug={false} showStatus={false} 
                containerStyle={{borderRadius:"6px", border:"1px solid lightgray", overflow:"hidden"}}/>
            </React.Fragment>;                    
*/
    const tableAnsRender = (_arr) => {
        const editor = (edType, cell, ii, kk) => {
            return <div key={edType+ii+kk} style={{width:"100%",borderRadius:"6px", border:"1px solid lightgray", overflow:"visible"}}>
                <Ckeditor5Base  {...props} editorType={edType} data={cell.data} setData={(d) => setTAnsData(d, ii, kk)}
                enable={editable} debug={false} showStatus={false} ckImageConfig={ckImageConfig} setUpFlag={setUpFlag}
                {...edProps}/>
            </div>;
        };        
        //row 0, add col buttons
        //col tail, add row buttons
        /*
            <div className='flexColStartFit f12'>
                {false && UI.colorButton('','+',()=>addAnsCol(kk),'#4EAE84','20px',{width:"20px",height:"20px"})}
                {UI.colorButton('','-',()=>delAnsCol(kk),'#4EAE84','20px',{width:"20px",height:"20px"})}
            </div> 
        */
        // * row rendering
        const _tblHead = (row, ii) => {
            return <tr key={'tr'+ii}>
                {row.map((cell, kk)=>{
                    const index = ii+'-'+kk;
                    if (ii===0 && kk===0 && ansChoice === MCAC.__MCTT_TTB) 
                        return <th className={'ATQtnTableAnsth1 fixedCol' + (scrollArrows.left?' rightShadow':'')} key={'th'+index} style={{border:'none'}}></th>; // top left cell, empty
                    //<div className={'flexRowCenter' + (ansChoice === MCAC.__MCTT_TTB?' ansListCell':'')}>
                    return <th className={'ATQtnTableAnsth2 ' + (kk===0?' fixedCol':'')  + (scrollArrows.left?' rightShadow':'')} key={'th'+index}>
                        <div className='flexColCenter' style={{position:'relative'}}>
                            {editor('inputAnswer', cell, ii, kk)}
                            {(ii!==0 || kk!==0) ? <div style={{alignSelf:"flex-end",bottom:"2px",right:"2px"}}>
                                {false && UI.colorButton('','-',()=>delAnsCol(kk),'#4EAE84','20px',{width:"20px",height:"20px"})}
                                {iconButton("", "general/trash", "#b4b4b4", "white", ()=>delAnsCol(kk), true,
                                    { transform: "scale(1.0)", width:"15px"}, 
                                    { width:"25px",height:"25px", padding:"2px", borderRadius:"6px"})}
                            </div>:<div style={{minHeight:"25px"}}></div>}
                    </div></th>
                })}
                <th style={{maxWidth:"35px",minWidth:"35px", width:"35px", padding:0, backgroundColor:'white'}}>
                    <div className='flexColStart'>
                    {false && UI.colorButton('','+',()=>addAnsCol(row.length),'#4EAE84','20px',{width:"20px",height:"20px"})}
                    {UI.colorButton('','+',()=>addAnsCol(row.length),'transparent','25px',
                    {border:'1px solid #0c8ce9',color:'#0c8ce9', width:"25px",height:"25px", marginTop:"10px"})}
                </div></th>
            </tr>;
        };

        const _tblRow = (row, ii) => {
            return <tr key={'tr'+ii}>
                {row.map((cell, kk)=>{
                    const index = ii+'-'+kk;
                    const firstCol = (kk===0);
                    // char start from 65 - 1, row 1
                    if (firstCol && ansChoice === MCAC.__MCTT_TTB) { 
                        return <td className={'ATQtnTableAnsLabel fixedCol' + (scrollArrows.left?' rightShadow':'')} key={'td'+index}>
                            <div className='flexRowCenter'>
                                <div className='flexRowCenter clickable' onClick={(e) => tAnsRowHeadClick(e, ii)}>                                 
                                    <div className="flexColCenter" style={{width:"20px",height:"20px",borderRadius:"50%",
                                        backgroundColor:(cell.correct ?"#4EAE84":"#e2e2e2")}}>
                                        {svgIcon2('general/confirm','white')}
                                    </div>
                                    <div className={"flexColCenter AnsLabelCharHead " + (cell.correct?"Sel":"")}>
                                        {String.fromCharCode(64 + ii)}</div>
                                </div>
                                {editable && iconButton("", "general/trash", "#b4b4b4", "white", ()=>delAnsRow(ii), true,
                                    { transform: "scale(1.0)",width:"15px" }, { width:"25px",height:"25px", padding:"2px", marginRight:"5px", borderRadius:"6px"})}
                            </div>
                        </td>;
                    };
                    //{!firstCol && ansChoice === MCAC.__MCTT_TTR && UI.radio0('row-'+index,(v)=>setRadioCorrect(v,ii,kk),row[0].correct,'qrow'+ii)}
                    //<div className={'ATQtnTableAnsCell' + (ansChoice === MCAC.__MCTT_TTB?' ansListCell':'')}></div>
                    return <td className={'ATQtnTableAnstd ' + (firstCol&&(ansChoice!==MCAC.__MCTT_TTB)?' fixedCol':'') + (scrollArrows.left?' rightShadow':'')} key={'td'+index}
                        style={(firstCol&&(ansChoice!==MCAC.__MCTT_TTB)?{backgroundColor:'white', zIndex:2}:{})}>
                        <div className='ATQtnTableAnsCell'>
                        {(firstCol || ansChoice === MCAC.__MCTT_TTB) && 
                            <div className='flexColCenter' style={{position:"relative"}}>
                            {editor('inputAnswer', cell, ii, kk)}
                            {editable && (ansChoice !== MCAC.__MCTT_TTB) && 
                                <div style={{alignSelf:"flex-end",bottom:"2px",right:"2px"}}>
                                {iconButton("", "general/trash", "#b4b4b4", "white", ()=>delAnsRow(ii), true,
                                    { transform: "scale(1.0)",width:"15px"},
                                    { width:"25px",height:"25px", padding:"2px", borderRadius:"6px"})}
                                </div>}                            
                            </div>
                        }
                        {!firstCol && ansChoice === MCAC.__MCTT_TTC && 
                            MCChk(cell.correct,(v)=>setCellCorrect(v,ii,kk),'ttchk'+index,editable,'#4eae84','#4eae84', false, multiAnswer)}
                        {!firstCol && ansChoice === MCAC.__MCTT_TTR && UI.radio1(kk+"",(v)=>setRadioCorrect(v,ii,kk),row[0].correct,'qrow'+ii,editable)}
                        {false && index}
                    </div></td>})
                }
                <td style={{maxWidth:"35px", minWidth:"35px", width:"35px", padding:0}}>
                    {false && UI.colorButton('','+',()=>addAnsRow(ii),'transparent','25px',{border:'1px solid #0c8ce9',color:'#0c8ce9', width:"25px",height:"25px"})}
                    {false && UI.colorButton('','-',()=>delAnsRow(ii),'#0c8ce9','20px',{width:"20px",height:"20px"})}
                </td>
            </tr>
        };

        return <div className='ATQtnTableContainer2' ref={refTansDiv} onScroll={checkScrollArrow}>
            <table className='ATQtnTableAns'><tbody>{isAry(_arr)
                ?_arr.map((row, ii) => (ii===0)? _tblHead(row, ii): _tblRow(row, ii) )
                :[]
            }</tbody></table>
        </div>;
    };
    
    return <>
        <div className={'tabQContent ' + (editable ? "" : "ReadOnly") + myClass}>
            {false && editable && <div className='flexRowEnd f12'>
                {UI.colorButton('idpreview', "Preview", clickOpt, "#0c8ce9")}
            </div>}
            {false && editable && <div className='flexRowEnd f12'>
                {UI.colorButton('idreset', "reset anwer", clickReset, "#0c8ce9")}
            </div>}            
            <Ckeditor5Base  {...props} editorType="question" data={qData} setData={setQ("qData")}
                enable={editable} setEditor={refCK} debug={false} showStatus={true} 
                onEditorReady={onEditorReady} noDragDrop={true} ckImageConfig={ckImageConfig}
                {...edProps} setUpFlag={setUpFlag}/>
            <p></p>
            {showPub?'':qerrTxt1("qData")}
            {showPub?'':((fullQ.SQType===SQT.__SYSQSubType_MCS)?qerrTxt1("qAnswers"):'')}
            {showPub?'':((fullQ.SQType===SQT.__SYSQSubType_MCT)?qerrTxt1("tAnswers"):'')}
            {(mcChoice===SQT.__SYSQSubType_MCS) && ansArr && <DDAnswerContainer dataArr={ansArr} setDataArr={setQ("qAnswers")} editable={editable}
                customRender={customRender} setOver={setOver} onClick={ddClick} ansChoice={ansChoice} />}
            {(mcChoice===SQT.__SYSQSubType_MCT) && tansArr && <div className='ATQtnTableContainer'
                style={{padding:'0 20px 0 20px'}}>
                {tableAnsRender(tansArr)}
                <div className='flexRowCenter'>
                    {UI.colorButton('','+',()=>addAnsRow(tansArr.length),'transparent','25px',
                    {border:'1px solid #0c8ce9',color:'#0c8ce9', width:"25px",height:"25px", marginTop:"10px"})}
                </div>
                {scrollArrows.right&&<div className='rightTArrow'>{scrollBtn('general/scrollRight',()=>scrollTDiv(1))}</div>}
                {scrollArrows.left&&<div className='leftTArrow' >{scrollBtn('general/scrollLeft',()=>scrollTDiv(-1),false)}</div>}
            </div>}
            {editable && (mcChoice===SQT.__SYSQSubType_MCS) && <div className='flexRowCenterFit'><CpActionButtonComponent variant={"at-primary"} 
                className={"px-5"} icon={<CpIco src={IconList.general.add}/>} title={t('add-an-option')} 
                onClick={newAns}/></div>}
        </div>
    </>;
});

export default CpQEditorMCQ;

const scrollBtn = (icon, cb, right=true) => {
    return iconButton("", icon, "black", "#cfcfcf", cb, true,
    {}, { height:"50px", padding: "5px 0 5px 0", borderRadius:right?"6px 0 0 6px":"0 6px 6px 0"});
};

//<CpMCAnswersTbl dataArr={ansArr} setDataArr={setAnsArr} editable={editable}
//customRender={tableAnsRender} setOver={setOver} onClick={ddClick} tableType={'AT3'} />
//const popMenuBtn = (obj, setAnsType) => {
export const PopMenuBtn = (props) => {
    const {obj, setAnsType} = props;
    const [t] = useUILang();
    const atOpts = MCAC.__MCATcodes.map((id, ii)=>{
        return {
            id: id, 
            jsx: (sel = false, head=false) => <div className='flexRowStart'>
            <CpIco src={toIconList[MCAC.__MCATcodeIcon(id)]} width={'1.4em'} height={'1.4em'}/>
            {!head&&<div style={{ marginLeft: "5px" }}>{t(MCAC.__UIMCATcodeName(id))}</div>}
            </div>
        };
    });
    return <CpATPullDownMenu sel={obj.type} setSel={setAnsType} opts={atOpts}
        containerStyle={{ width: "120px", borderColor:"rgba(113, 131, 144, 1)" }} right={true} headerClass={'ATPDHeader2'}
        menuStyle={{ justifyContent: "flex-end" }} headerStyle={{ height:"33px", justifyContent: "center" }} />
};

const toIconList = {
    'inputType/generalEditor':IconList.inputType.generalEditor,
    'inputType/imageEditor':IconList.inputType.imageEditor,
};

/*
{iconButton("", "general/trash", "white", "#749ab3", () => { deleteAns(ii) },
    true, { transform: "scale(1)", width:"20px" }, { width: "35px", height: "35px"})}

{editable && iconButton("", "general/trash", "white", "#749ab3", () => { deleteAns(ii) }, true,
    { transform: "scale(1)", width:"20px" }, { width:"35px",height:"35px",marginLeft:"5px",borderRadius:"8px"})}

iconButton(t('add-an-option'), "labelDiagram/add", "white", "#2995cd", newAns, true,
    {}, { width: "300px", marginTop: "20px", padding: "8px 10px 8px 10px" })

return {
    id: id, 
    jsx: (sel = false, head=false) => <div className='flexRowStart'>
    {svgIcon2(MCAC.__MCATcodeIcon(id), sel ? "black" : "black", { width: "20px"})}
    {!head && <div style={{ marginLeft: "5px" }}>{t(MCAC.__UIMCATcodeName(id))}</div>}
    </div>
};
*/