import {Stack} from "react-bootstrap";
import CpActionButtonComponent from "../../AppExPFUser/_components/CpActionButtonComponent";
import CpIco from "../../AppExPFUser/_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { ALIGN } from "../../AppExPFUser/_components/CpExPFConst";
import {useEffect, useMemo, useRef, useState} from "react";
import CpPopoverComponent from "./CpPopoverComponent";

/*
const quesiontList = [
    {variant: "mcq-dim"},
    {variant: "mcq-dim"},
    {variant: "mcq-dim"},
    {variant: "mcq-dim"},
    {variant: "mcq-dim"},
    {variant: "mcq-active group first done"},
    {variant: "mcq-dim group"},
    {variant: "mcq-dim group"},
    {variant: "mcq-dim group last"},
    {variant: "mcq-dim"},
]

const quesiontResultList = [
    {variant: "mcq-correct"},
    {variant: "mcq-incorrect"},
    {variant: "mcq-attempted"},
    {variant: "mcq-correct"},
    {variant: "mcq-correct"},
    {variant: "mcq-correct group first"},
    {variant: "mcq-correct group"},
    {variant: "mcq-incorrect group"},
    {variant: "mcq-correct group last"},
    {variant: "mcq-correct"}
]
*/
const CpQuestionSelector = (props) => { // base on poc QuestionSelector
    const {title, progress, setProgress, view, vis, setVis, container='body', selectorPanel=''} = props
    const [offset, setOffset] = useState(0)
    const ref = useRef(null);
    const handleClick = (e) => {
        if (ref.current) {
            ref.current?.handleShowEvent(e)
        };
    };

    useEffect(() => {
        const expfhead = document.getElementById("ATQtnHeader");
        //const nav = document.querySelector("#navbar");
        //const progressbar = document.querySelector("#progressbar");
        const expfHeight = expfhead?.getBoundingClientRect().height || 0;
        //const navHeight = nav?.getBoundingClientRect().height || 0;
        //const progressbarHeight = progressbar?.getBoundingClientRect().height || 0;
        setOffset(expfHeight);
        //setOffset(navHeight + progressbarHeight + expfHeight);
    }, [ref]);
    
    return <CpPopoverComponent ref={ref} content={selectorPanel} vis={vis} setVis={setVis}
            fixedPosition={true} placement={"top"} scroll={true} backdrop={false} container={container}
            style={{marginTop: offset, zIndex: 1010}}>
        <CpActionButtonComponent variant="hover-dark-preview-primary border-select-question" title={title} 
            icon={<div className={"fs-8"}><CpIco src={IconList.general.dropdown}/></div>}
            iconAlign={ALIGN.END} className={"fs-5 semi-bold"} onClick={handleClick}/>
    </CpPopoverComponent>
};

export default CpQuestionSelector;