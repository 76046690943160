import { isAry, isObj, toAry, toObj, toStr } from "../libs/libType";
import { _MaxLenMetaSetId, _MaxLenTodo, _MinLenMetaSetId, _MinLenTodo } from "./ATConsts";
import { _ATMetaTypeCodes, _ATMetaTypeReport, _ATMetaTypeReportSubj } from "./ATMetaTypes";
import { toUniIdAry, toUniIdsStr, validFields, validIdCharsMinMax, validMinMax } from "./ATValidate";

export const splitSetMetaIds2D = pairStrs => {
    const mss = {};
    const ms = toUniIdAry(pairStrs).map(splitSMIdPairStr);
    ms.forEach(m => {
        const {metaSetId, metaId} = m;
        if(!(metaSetId && metaId))
            return;
        if(!mss[metaSetId]) 
            mss[metaSetId] = new Set();
        if(mss[metaSetId].has(metaId))
            return;
        mss[metaSetId].add(metaId);
    });
    return mss;
};
export const splitSMIdPairStr = pairStr => {
    const [s, m] = toStr(pairStr).split(',');
    return {metaSetId: s.trim(), metaId: toStr(m).trim()};
};

export const MIdsByMSId = (mids, msid) => toAry(mids).map(i => {
    const [s,m] = splitSMId(i);
    return s === msid? m: '';
}).filter(i => i);

export const splitSMId = smid => { 
    const [s, m] = toStr(smid).split(','); 
    return [ toStr(s).trim(), toStr(m).trim() ]; 
}; 
export const normalizeSMIds = smids => toUniIdAry(toAry(smids).map(smid => { 
    const [s, m] = splitSMId(smid);
    return s && m && s+','+m; 
}));
export const normalizeSMIdStr = smidStr => normalizeSMIds(toStr(smidStr).split(';')); 


export const validMSId = (str) => validIdCharsMinMax(toStr(str), _MinLenMetaSetId , _MaxLenMetaSetId, 'Meta Set ID');
export const validMSName = (str) => validMinMax(toStr(str), _MinLenTodo, _MaxLenTodo, 'Metadata Set Name');

export const normalizeMSHeads = hs => {
    if(!isAry(hs)) return [];
    const keys = ['metaId', 'LVNum', 'nameEn', 'nameCt']; 
    return hs.map( h => {
        const ret = {};
        keys.forEach(k => { ret[k] = toStr(h[k]).trim(); } );
        ret.LVNum = ret.LVNum || ret.metaId;
        return ret;
    });
};

const mstcodes = _ATMetaTypeCodes();

export const normalizedEditATMetaSet = (fields, opts, trace) => {
    
    const fs = toObj(fields);
    const ret = {
        //...fs,
        metaSetId: toStr(fs.metaSetId).trim(),
        MSNameCt: toStr(fs.MSNameCt).trim(),
        MSNameEn: toStr(fs.MSNameEn).trim(),

        MSType: mstcodes.includes(fs.MSType)?fs.MSType:mstcodes[0],

        MSLangEn: fs.MSLangEn?1:0,
        MSLangCt: fs.MSLangCt?1:0,
        MSSubjIds: toUniIdAry(fs.MSSubjIds),

        doReport:fs.doReport?1:0,
        MSReports: toUniIdsStr(fs.MSReports),
        
        heads: normalizeMSHeads(fs.heads),
        tree: isObj(fs.tree)?fs.tree:0,

        isNew: fs.isNew?1:0,
    };
    
    return ret;
};

export const validEditATMetaSet = (fs, opts, trace) => {
    const track = o => trace && trace.push(o);
    track('-- validEditATMetaSet --');

    const v = validFields(fs, {});
    v.setIf('metaSetId', validMSId(fs.metaSetId)?'The metadata set id should be within 2 - 50 characters (Alphanumeric or Underscore).':'');
    v.setIf('MSLang', (!(fs.MSLangCt || fs.MSLangEn)) && 'Missing Language');

    v.setIf('MSNameCt', fs.MSLangCt && validMSName(fs.MSNameCt));
    v.setIf('MSNameEn', fs.MSLangEn && validMSName(fs.MSNameEn));

    v.setIf('MSType', mstcodes.includes(fs.MSType)?'':'Invalid Meta Type');
    
    
    v.setIf('MSSubjIds', (!(fs.MSSubjIds?.length)) && 'Missing Subject'); 

    const [canRpt, SubjRpt, mustRpt] = MSMustRpt(fs); 
    //const rpt = toAry(opts.draftRpt);
    //v.setIf('MSReports', mustRpt && (!rpt.length) && 'Report Subject metadata not Loaded'); 
    v.setIf('MSReports', mustRpt && (!fs.MSReports.length) && 'Subject metadata Code(s) is mandatory'); 

    track('-- validEditATMetaSet end --');
    return v.fieldErrs;
};

export const MSMustRpt = ms => {
    const canRpt = _ATMetaTypeReport(ms.MSType); 
    const SubjRpt = _ATMetaTypeReportSubj(ms.MSType);
    const mustRpt = canRpt && (!SubjRpt) && (ms.doReport);
    return [canRpt, SubjRpt, mustRpt];
};
