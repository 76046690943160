import { toObj, toStr } from "../libs/libType";
import { toIdStr, validFields, validMinMax } from "./ATValidate";

export const normalizeNextRule = fields => {
    const {errs, ...fs} = toObj(fields);
    return {
        ruleNameEn: toStr(fs.ruleNameEn).trim(),
        ruleNameCt: toStr(fs.ruleNameCt).trim(),

        att: ['-','y','n'].includes(fs.att)? fs.att: '-',
        
        passt: fs.passt ? true: false,
        avgt: fs.avgt ? true: false,
        
        pass: ['a','b'].includes(fs.pass) ? fs.pass: 'a',
        avg: ['a','b'].includes(fs.avg) ? fs.avg: 'a',
        
        dl: ['l','f'].includes(fs.dl) ? fs.dl: 'l',
        nameEn: toStr(fs.nameEn).trim(),
        nameCt: toStr(fs.nameCt).trim(),
        mediaEn: toStr(fs.mediaEn).trim(),
        mediaCt: toStr(fs.mediaCt).trim(),
        lnkEn: toStr(fs.lnkEn).trim(),
        lnkCt: toStr(fs.lnkCt).trim(),

        modUid: toIdStr(fs.modUid),
    };
};

const validRRName = (str) => validMinMax(toStr(str), 1, 300, 'Exercise next rule name');

export const validateNextRule = (fs, opts, trace) => {
    const track = o => trace && trace.push(o);
    track('-- validateNextRule --');
    const v = validFields(fs, {});

    if (opts?.showEn) {
        v.setIf('ruleNameEn', validRRName(fs.ruleNameEn) && 'error.error_no_rule_name');
        v.setIf('nameEn', validRRName(fs.nameEn) && 'error.error_at_no_title');
        if (fs.dl === 'f') { v.setIf('mediaEn', (!fs.mediaEn) && 'error.error_no_file'); }
        else { v.setIf('lnkEn', validRRName(fs.lnkEn) && 'error.error_no_url'); };
    } else {
        v.setIf('ruleNameCt', validRRName(fs.ruleNameCt) && 'error.error_no_rule_name');
        v.setIf('nameCt', validRRName(fs.nameCt) && 'error.error_at_no_title');
        if (fs.dl === 'f') { v.setIf('mediaCt', (!fs.mediaCt) && 'error.error_no_file'); }
        else { v.setIf('lnkCt', validRRName(fs.lnkCt) && 'error.error_no_url'); };
    };
  
    track('-- validateNextRule end --');
    return v.fieldErrs;
};
