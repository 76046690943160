import React, { useMemo } from "react";
import { preJS } from "../../AppExPf/AppUtil";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { strCmp, toAry } from "../../libs/libType";
import CpCollectionNewThumb from "./CpCollectionNewThumb";
import CpCollectionThumb from "./CpCollectionThumb";
import { strCmpTrimLow } from "../EPAssign/TabTeacherAssignmentStatus";


const TabCollection = (props => { //base on poc SavedCollectionTab 
    const { ECols, setParaNew, reload } = props;
    const [t, isUIEn, UILang, setUILang] = useUILang();

const cmpAName = (a, b) => strCmpTrimLow((a.ECName), (b.ECName));
const sorted = ECols.sort((b, a) => cmpAName(b,a) || (a.dateMod - b.dateMod  ));

    return <>
        <div className={"exercise-library-filter-container px-3 d-flex flex-column flex-md-row justify-content-between align-items-md-end w-100"}>
            <span className={"title d-flex flex-column justify-content-end semi-bold"}>{t("please-select")}</span>
        </div>
        <hr className={"my-2 mx-3"}/>
        <div key={UILang} className={"library-list-thumbnail-container d-grid gap-3 px-3 pb-3 w-100 overflow-y-md-auto"}>
            <CpCollectionNewThumb {...{setParaNew}} />
            {toAry(ECols).map((ECol, idx) => <CpCollectionThumb key={idx} {...{idx, ECol, setParaNew, reload}} />)} {/* added 'setParaNew' */}
        </div>
    </>;
});
export default TabCollection;
