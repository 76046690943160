import React, { useEffect, useState, } from 'react';
import { Nav } from 'react-bootstrap';
import { BtnPopDev, preJS, deepCopy } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';
import { _ATSysQTypes } from '../../consts/ATSysQType';
import { _ATMetaTypes } from '../../consts/ATMetaTypes';

import * as UI from '../../libs/libUI';
import { toObj } from '../../libs/libType';
//import CpQEditorReadOnly from './editors/CpQEditorReadOnly';
import CpQEditor from '../editors/CpQEditor';
import CpATQtnDo from '../qtnDo/CpATQtnDo';
import CpATQtnSingle from '../qtnDo/CpATQtnSingle';
import TabQtnOptions from '../qtnOptions/TabQtnOptions';
import { ReduxBind } from '../../saga/ReduxState';
import { subState } from '../utils/useChain';
import * as SQT from '../../consts/ATSysQType';
import * as MCAC from '../../consts/ATQtnAnsTypeMCQ';
import * as FIBC from '../../consts/ATQtnAnsTypeFIB';
import { ATErrsDiv2, ATErrsDiv3, aTErrTxt1, atErrsDiv} from '../AppUtil';
import ActionButtonComponent from '../../AppExPFUser/_components/CpActionButtonComponent';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { useUILang } from '../utils/useUILang';
import { SIZE } from '../../AppExPFUser/_components/CpExPFConst';
import CpIco from '../../AppExPFUser/_components/CpIco';
import CpPromptButtonComponent from '../../AppExPFUser/_components/CpPromptButtonComponent';
import CpPreviewButtonComponent from '../../AppExPFUser/_components/CpPreviewButtonComponent';
import { debugMode } from '../../saga/ReduxState';
import { rmFullQCorrAns } from '../../consts/ATQtnScore';
import { setEditQMask } from '../../AppExPFUser/EPExercise/TabExerEdContent';
import { preSaveQtn, saveQtnDrawAsLocal } from './PageATQtnEdit';

const TabQtnEdit = ReduxBind( props => {
  const { dispatch } = props;
  const { _MaxMediaSize } = toObj(props.settings());
  const { editable, showPub, QLang, showEn, tabQCpLang, fullQ, Q, fieldErrs, QErrs,
    showQPrev, tapShowQPreV, mediaDLs, getMediaDLs, addLocalMedias, setFullQ, qtnModified=1, setQtnModified,
    isAT, onClose, onSaveQEdit, exModified=1 } = props

  const [showAssPV, setShowAssPV] = useState(0);
  const [showEd, setShowEd] = useState(1);
  //const showEditor = useRef(1);

  const [ t ] = useUILang();
  const qerrTxt1 = (key) => aTErrTxt1(fieldErrs?.[((QLang==="En")? "Q_E_": "Q_C_")+key], t);
  const edProps = {...props, qerrTxt1} //, mediaDLs, getMediaDLs};

  // useEffect(()=>{ console.log('showEditor?',showEditor); },[showEditor]);
  
  const setShowEditor = (v) => {
    //showEditor.current = v ? 1: 0;
    setShowEd(0);
    setTimeout(()=>{setShowEd(1)},50);
  };

  // use by frontend
  const clickSave = async () => {
    if (onSaveQEdit) { 
      const newFullQ = await preSaveQtn(dispatch, fullQ, getMediaDLs, addLocalMedias);
/* please rumove after check
      await addMath3StrToAns(fullQ, Q);
      addMathMaxAnsWidth(fullQ, Q);
      // save draw, local, then map by validation
      saveQtnDrawAsLocal(fullQ, fullQ?.QCt, _MaxMediaSize, addLocalMedias);
      saveQtnDrawAsLocal(fullQ, fullQ?.QEn, _MaxMediaSize, addLocalMedias);
*/
      onSaveQEdit(newFullQ.QId, newFullQ);
    }
  };

  const closeClick = () => onClose && onClose();
  const _setShowAssPV = () => setShowAssPV(1);

  useEffect(()=>{
    //todo temp fix for new qtn, set mc standard type default score to 1
    const QType = fullQ?.QType || SQT.__SYSQSubType_MCS;
    if (props.isNew && (QType === SQT.__SYSQSubType_MCS) && !fullQ.score ) {
      setFullQ('score',1);
      setFullQ('allowTeaCopyEdit',1);
    };
    setEditQMask(dispatch, 0, 'Enterd <TabQtnEdit ');
  },[]);

  const clearQAnswers = (QType, all=true, prevSQType="") => {
    //const _newAns = { data: "<p>Please input answer here</p>", type: MCAC.__MCAT_ATG, correct: 0 };
    //const newCell = (_type)=> {return {data:"<p>data</p>", type:_type, correct: 0, width:'', height:''}};
    const _newAns = { data: "", type: MCAC.__MCAT_ATG, correct: 0 };
    const newCell = (_type)=> {return {data:"", type:_type, correct: 0, width:'', height:''}};
    const newRow = (colCount=2, type=MCAC.__MCTT_TTC) => {
      const row = [];
      for(let ii=0;ii<colCount;ii++) {
        row.push({...newCell(type)});
      };
      return row;
    };

    // 3 rows, 2 columns, include header
    const _newTAns = (_type) => [newRow(2, _type),newRow(2, _type),newRow(2, _type)];    
    
    const setAllLang = (key, value) => {
      //setFullQ('QEn', subState(key, deepCopy(value)));
      //setFullQ('QCt', subState(key, deepCopy(value)));
      setFullQ('QEn', subState(key, deepCopy(value)));
      setFullQ('QCt', subState(key, deepCopy(value)));
    };

    if (all) { // false for MCQ ansChoice use only
      setFullQ('allowTeaCopyEdit',1);
      setAllLang('qData','');
      setAllLang('ansHints','');
      setAllLang('ansKeys',0);
      setAllLang('AKText',[]);
      setAllLang('AKLink',[]);
      setAllLang('AKFile',[]);
    };
      
    if (([SQT.__SYSQSubType_LBT, SQT.__SYSQSubType_LBD].includes(QType) && (prevSQType === SQT.__SYSQSubType_OED))
      || ((QType === SQT.__SYSQSubType_OED) && ([SQT.__SYSQSubType_LBT, SQT.__SYSQSubType_LBD].includes(prevSQType)))) {
      setAllLang('BGMediaID',null);
    };

    const ansChoice = QType===SQT.__SYSQSubType_MCS?MCAC.__MCAC_ACG
      :(QType===SQT.__SYSQSubType_MCT?MCAC.__MCTT_TTC
      :(QType===SQT.__SYSQSubType_POL?MCAC.__MCAC_ACG:'')
      );  
    switch (QType) {
      case SQT.__SYSQSubType_MCS:
      case SQT.__SYSQSubType_MCT:
        setFullQ('ansChoice',ansChoice);
        setAllLang('qAnswers',[{ ..._newAns},{..._newAns }]);
        setAllLang('tAnswers',[..._newTAns(ansChoice)]);
        setFullQ('score',1);
        break;
      case SQT.__SYSQSubType_LBT:
      case SQT.__SYSQSubType_LBD:           
        setAllLang('BGSrc',null);
        setAllLang('LDMode',null);
      case SQT.__SYSQSubType_OED:
        setAllLang('BGSrc',null);
      case SQT.__SYSQSubType_OEG:
      case SQT.__SYSQSubType_OEE:
        setFullQ('respText',1);
        setFullQ('respImage',0);
        setFullQ('respFile',0);
        setFullQ('respDrawing',0);
        setFullQ('respURL',0);
        setFullQ('wordLimit',0);
        setFullQ('maxFile',5);
      case SQT.__SYSQSubType_FIB:
        setAllLang('qAnswers',[]);
        setAllLang('tAnswers',[]);
        setFullQ('score',1);     
        setFullQ('scoreMethod',FIBC.__FIBS_PBK);
        break;
      case SQT.__SYSQSubType_POL:
        setFullQ('ansChoice',ansChoice);
        setAllLang('qAnswers',[{ ..._newAns},{..._newAns }]);
        setAllLang('tAnswers',[]);
        setFullQ('score',0);
        break;
      default:
        break;
    };
  };

  const _noAnsQ = rmFullQCorrAns(fullQ);
  const cplang = (editable && (showEn? fullQ.QLangCt: fullQ.QLangEn)) || '';
  const hasErr = Object.keys(toObj(fieldErrs)).length > 0;
  return <>
    <div className='flexColStart'>
      {isAT?<div className='flexRowEnd f12' style={{gap:'4px'}}>
        {false && preJS({ showEn, showQPrev, editable, QLang, showPub, })}
        {false && cplang && UI.colorButton('btnCPL', 'Copy To ' + (showEn? 'Chinese': 'English'), tabQCpLang(showEn), "#0c8ce9")}
        {false && UI.colorButton('btnQPV', showQPrev? 'Edit Mode': 'Preview Mode', tapShowQPreV, "#0c8ce9", undefined, {marginRight:"5px"})}
        {cplang && <ActionButtonComponent variant={"at-primary semi-bold hidden-action-btn-text-md "}
          title={t('Copy To ' + (showEn? 'Chinese': 'English'))} truncate={false} icon={<CpIco src={IconList.general.copy}/>}
          size={SIZE.SM} onClick={tabQCpLang(showEn)} />}
        <PreviewBtn {...edProps} size={SIZE.SM} t={t} PVMode={0}
          variant={"at-primary semi-bold hidden-action-btn-text-md "}/>
      </div>:
      <div className='ExQtnEditTop'>
        <div className='py-0 py-md-1 d-flex align-items-center flex-nowrap gap-1' >
        <Nav className="flex flex-row gap-2 justify-content-end align-items-center flex-nowrap" >
            <CpPromptButtonComponent variant={"at-primary-dim semi-bold hidden-action-btn-text-md"}
              title={t('cancel')} icon={<CpIco src={IconList.general.delete}/>}
              truncate={false}
              cb={closeClick} promptTitle={t("warning.warning_tips")}
              promptDesc={t("warning.warning_confirm_without_saving")}
              promptCancel={t("cancel")}
              promptConfirm={t("confirm")} size={SIZE.MD} dispatch={dispatch}/>
            <PreviewBtn {...edProps} t={t}/>
            <ActionButtonComponent variant={"at-primary semi-bold hidden-action-btn-text-md "
              + (qtnModified?"":" disableBtn disable-option ")}
              title={t('save')} truncate={false} icon={<CpIco src={IconList.general.save}/>}
              size={SIZE.MD} onClick={clickSave} />
        </Nav>             
      </div></div>}
      <div className={'tabQContainer' + (!editable ? "ReadOnly" : "")} >
        <div className='flexColStart' style={{width:'70%', flex:1}} >
          <div className='flexRowStart' >
            <BtnPopDev txt='fullq'>{preJS(fullQ,3)}</BtnPopDev>
            <BtnPopDev txt='fullq no ans'>{preJS(_noAnsQ,3)}</BtnPopDev>
            {false&&debugMode()?UI.colorButton('btnQPV', 'Assignment Preview', _setShowAssPV, "#0c8ce9", undefined, {marginRight:"5px"}):''}
            {isAT ? '' : (hasErr?atErrsDiv(fieldErrs, '', t):'')}
            {/*isAT ? '' : ATErrsDiv3(fieldErrs, hasErr?'ATErrorMsgBlock':'', t)*/}
          </div>
        {showQPrev?<CpATQtnSingle close={tapShowQPreV} {...edProps} doWhat="preview"/>
          :showAssPV? <CpATQtnSingle close={()=>setShowAssPV(0)} {...edProps} isThr={1} isStt={0} doWhat="asmView"/>
          :editable?(showEd?<CpQEditor {...edProps}/>:'')
          :<div className='flexColStart' style={{paddingBottom:'5px', overflow:'auto'}}>
            <CpATQtnDo {...edProps} fullPage={true} overflow={false}/></div>
        }
        </div>
        {!showQPrev? <TabQtnOptions {...edProps} clearQAnswers={clearQAnswers} setShowEditor={setShowEditor}/>:''}
      </div>
    </div>
  </>;
});
export default TabQtnEdit;

const PreviewBtn = props => {
  const {t} = props;
  return <CpPreviewButtonComponent title={t('preview')} icon= {<CpIco src={IconList.general.preview}/>} size= {props.size || SIZE.MD}
      variant= {props.variant || "outline-at-primary semi-bold hidden-action-btn-text-md"} >
      {(handleClose) =>(<CpATQtnPreview {...props} close={handleClose} PVMode={props.PVMode === 0? 0: 3} exClass='p-4 flexColCenter'/>)}
  </CpPreviewButtonComponent>;
};

export const CpATQtnPreview = props => <CpATQtnSingle {...props} close={props.close} doWhat="preview"/>;
