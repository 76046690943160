import React, { useState } from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";

import { _WebRoot, _ATRoot } from '../consts/ATConstReact';
import { ReduxBind } from '../saga/ReduxState';

import { IconList } from '../consts/ATIconListToUsePoc';
import CpIco from './_components/CpIco';
import { useStateGST } from '../saga/globalState.saga';
import CpSideBar from './_components/CpSideBar';
import { useResizeEffect } from '../AppExPf/utils/useWinResize';

export const useLayout = (props) => {
    const [visLB, setVisLB] = useStateGST(props, 'layoutVisLB', 1);
    const clickLB = () => setVisLB(!visLB);
    return [visLB, setVisLB, clickLB];
};
export const PageEXPFLayout = ReduxBind(props => { //base on poc Dashboard
        const {sideIdx} = props;
        const {menuTitle = "PageEXPFLayoutmenuTitle", menuActionBar = "PageEXPFLayoutmenuActionBar", header = "PageEXPFLayoutheader", 
            children = "PageEXPFLayoutchildren", subBody = "PageEXPFLayoutsubBody", footer = "PageEXPFLayoutfooter"} = props;
        const [open, setOpen] = useState(false);
        const [position, setPosition] = useState(""); //<"top" | "left" | "">
        useResizeEffect((w, h) => setPosition((w < 768)? "top": "left")); 

        if (position === "") return <></>
        return <div className={"dashboard d-flex flex-row w-100"}>
            <CpSideBar {...{open, position, sideIdx}} />
            <div className={`page-section d-flex flex-column vh-100 overflow-hidden flex-grow-1`} style={{zIndex:11}}>
                {menuTitle && <Navbar expand="lg">
                    <Container fluid={true} className={"py-0 py-md-1 d-flex align-items-center flex-nowrap gap-1"}>
                        <Nav className={"d-flex flex-grow-1"} style={{minWidth: 0}}> {menuTitle} </Nav>
                        <Nav className="flex flex-row gap-0 gap-md-3 justify-content-end align-items-center flex-nowrap">
                            {menuActionBar}
                            <span className={"btn d-block d-md-none fs-4"} onClick={() => setOpen(prev => !prev)}><CpIco src={IconList.general.menu}/></span>
                        </Nav>
                    </Container>
                </Navbar>}
                <main className={`login-section d-flex flex-column flex-grow-1 overflow-hidden`}>
                    <div className={"d-flex flex-column justify-content-between align-items-center flex-grow-1 overflow-hidden"}>
                        <>{header && <div className={"w-100"}>{header}</div>}
                        <div className={`bg-white d-flex flex-column flex-grow-1 overflow-hidden w-100 ${subBody? "mb-2": ""}`}>{children}</div>
                        {subBody && <div className={`d-flex justify-content-start w-100 gap-3 ${footer? "mb-3": ""}`}>{subBody}</div>}
                        {footer && <div className={"d-flex w-100 justify-content-end gap-3"}>{footer}</div>}
                    </>
                </div>
            </main>
            </div>
        </div>
    }
);
export default PageEXPFLayout;

/*
export const useLayout = (props) => {
    const [visLB, setVisLB] = useStateGST(props, 'layoutVisLB', 1);
    const clickLB = () => setVisLB(!visLB);
    return [visLB, setVisLB, clickLB];
};
const PageEXPFLayout = ReduxBind(props => {
    const [visLB, setVisLB, clickLB] = useLayout(props);
    const path = window.location && window.location.pathname;
return <>        
    <CpTopBar setVisLB={clickLB} />
    <CpLeftBar vis={visLB} gotoPath={path} path={path} />
        {props.children}
    </>;
});
export default PageEXPFLayout;
*/