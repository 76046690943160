import React from 'react'
import { svgIcon } from '../../libs/libUI';
import * as UI from '../../libs/libUI';

const _MCChk0 = (props) => {
    const {id, className, style, onClick, enable, iconColor, CBDisplay} = props;
    //const size = "18px";
    const checked = props.checked?true:false;
    //const exStyle = {transform: (checked?'scale(2.8)':'scale(1.5)')};
    const myClick = (e) => { UI.stopEvent(e); onClick(!checked) };

    const exStyle = checked?{backgroundColor:iconColor,borderColor:iconColor}:{};
    return <div name={id} id={id} className={'flexRowCenter ' + (enable?"clickable ":"unclickable ") + className} 
        style={{...style}} onClick={myClick}>
        <div className={(checked?'checkedStyle':'uncheckedStyle')+(CBDisplay?'':' radio')} style={exStyle}></div>
    </div>
};

const _MCChk = (props) => {
    const {id, myClass, style, onClick, enable, iconColor, iconColor2, dotted, CBDisplay} = props;
    //const size = "18px";
    const checked = props.checked?true:false;
    //const exStyle = {transform: (checked?'scale(2.8)':'scale(1.5)')};
    const myClick = (e) => { UI.stopEvent(e); onClick(!checked) };
    
    const exStyleA = checked?{borderColor:(dotted?iconColor2:iconColor), borderStyle:(dotted?'dashed':'solid')}:{};
    const exStyleB = checked?{backgroundColor:iconColor,borderColor:iconColor}:{};
    
    return <div name={id} id={id} className={'flexRowCenterFit ' + (enable?"clickable ":"unclickable ") + myClass} 
        style={{...style}} onClick={myClick}>
        <div className={(checked?'checkedStyle2A':'uncheckedStyle2A')+(CBDisplay?'':' radio')} 
            style={exStyleA}>
            <div className={(checked?'checkedStyle2B':'uncheckedStyle2B')+(CBDisplay?'':' radio')}
            style={exStyleB}>
        </div></div>
    </div>
};
/*
        {svgIcon("", checked?'player/stop':'draw/rectangle', enable?iconColor:'#b4b4b4', "",
        {width:size,height:size, ...exStyle})}
*/
//svgIcon("iBsvg", icon, iconColor, "", iconStyle)

const MCChk = (checked, onClick, id, enable=true, iconColor='#4eae84', iconColor2='#4eae84', 
    dotted=false, CBDisplay=true, myClass='', style={}) => {
    return <_MCChk cb id={id} checked={checked} onClick={onClick} myClass={myClass} dotted={dotted}
        style={style} enable={enable} iconColor={iconColor} iconColor2={iconColor2} CBDisplay={CBDisplay}/>
};

export default MCChk;