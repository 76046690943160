import React from 'react';
import * as UI from '../../libs/libUI';
import { svgIcon2 } from '../components/CpATIcoBtn';
import { toInt } from '../../libs/libType';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';

const CpValueAdder = (props) => {
    const {value, setValue, disable, min} = props;
    const minV = toInt(min);

    const validv = (xx) => (xx < minV) ? minV : xx;
    const setv = (xx) => setValue(validv(parseInt(xx)));
    const onBlur = () => (!value) && setValue(minV);

    const valueAdj = (delta, cb) => {
        let xx = parseInt(value ? value : minV) + delta;
        cb(validv(xx));
    };
//+ enable?'':' unclickable'
    const exStyle = disable?{color:'lightgrey', borderColor:'lightgrey'}:{}
    return <div className={'adjustValueButton1 '} style={exStyle}>
        <div className='flexRowCenter clickable ATIcon ' style={{height:'28px', ...exStyle}} onClick={() => valueAdj(-1, setValue)}>
            <CpIco src={IconList.general.minus} />
        </div>
        <div className='adjustValueButton2 f14'>{UI.EpInputInt(value ? value : 0, setv, 'value', '',
            {color:disable?'lightgrey':'black',textAlign: "center", width: "40px", padding:0, margin:0, outline: "none", border: "none" },
            onBlur)}</div>
        <div className='flexRowCenter clickable ATIcon ' style={{height:'28px', ...exStyle}} onClick={() => valueAdj(1, setValue)}>
            <CpIco src={IconList.labelDiagram.add} />
        </div>
    </div>;
};

export default CpValueAdder;
/*
{svgIcon2("general/minus", disable?"lightgrey":"#2995cd")}
{svgIcon2("labelDiagram/add", disable?"lightgrey":"#2995cd")}
*/