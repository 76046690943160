import {createContext, Dispatch, ReactNode, useReducer} from "react";
import {AssignmentListPresentationMode} from "../type/PresentationMode";

type PageStateType = {
    page: string;
    tab: string;
    history: any;
    presentationMode: AssignmentListPresentationMode | ""
}

export enum PageActionKind {
    UPDATE_PAGE = 'UPDATE_PAGE',
    UPDATE_TAB = 'UPDATE_TAB',
    PREVIOUS_PAGE = 'PREVIOUS_PAGE',
    PRESENTATION_MODE = 'PRESENTATION_MODE'
}

interface PageActionType {
    type: PageActionKind;
    payload?: any;
}

const PageState: PageStateType = {page: "", tab: "", history: [], presentationMode: ""}

const PageContext = createContext<{
    state: PageStateType;
    dispatch: Dispatch<PageActionType>;
}>({
    state: PageState,
    dispatch: () => null
})

const PageReducer = (state: PageStateType, action: PageActionType): PageStateType => {

    const history = {...state}.history
    switch (action.type) {
        case PageActionKind.UPDATE_PAGE:
            return {...state, page: action.payload, history: [...history, action.payload]}
            break;
        case PageActionKind.UPDATE_TAB:
            return {...state, tab: action.payload}
            break;
        case PageActionKind.PREVIOUS_PAGE:
            if (history.length > 1) {
                history.pop()
                return {...state, page: history[history.length - 1], history: history}
            } else {
                return {...state}
            }
            break;
        case PageActionKind.PRESENTATION_MODE:
            return {...state, presentationMode: action.payload}
            break;
        default: {
            return state
        }
    }
}

const PageProvider = (props: { children: ReactNode }) => {
    const {children} = props
    const [state, dispatch] = useReducer(PageReducer, PageState)
    const value = {state, dispatch}

    return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export {PageProvider, PageContext}