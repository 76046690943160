import { useUILang } from "../../AppExPf/utils/useUILang";
import { objVals, toInt, toObj, toStr } from "../../libs/libType";
import { unuseMemo } from "../../AppExPf/AppUtil";

const CpAssignToTag = (props) => { // base on poc AssignToTag.tsx
    const {asm, yearClass2d} = props;
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const classId = toStr(asm?.classId);
    const clsName = useMemoClsName(yearClass2d, classId, ut, uiEn); // Bon useMemoe, because Bon know it should
    const stCount = toInt(asm?.Stat?.ttl);
    //t("whole-class")
    const countTitle = ' ('+stCount+') '+t(stCount>1?'students':'student');
   
    return <small className={`semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light bg-tag-info border-tag-info text-white`}>
        {clsName}{countTitle}
    </small>
};
export default CpAssignToTag;

export const useMemoClsName = (yearClass2d, classId, ut, uiEn) => unuseMemo(() => getYearClassName(yearClass2d, classId, ut), [yearClass2d, classId, uiEn]);
export const getYearClassName = (yearClass2d, classId, ut) => {
    const classs = Object.assign({}, ...objVals(yearClass2d));
    const c = toObj(classs[classId]);
    const ret = ut(c.nameEng, c.nameChi);
    
    return ret;
};
