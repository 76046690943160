import React, { useState } from 'react';

import { apiCallLoad } from '../libs/awsFuncs';
import { lj } from '../libs/libFormat';
import * as UI from '../libs/libUI';

import { ReduxBind } from '../saga/ReduxState';
import { aTErrTxt1 } from './AppUtil';
import {debugMode} from "../saga/ReduxState";
import PageTest from './components/ckeditor5/PageTest';
import PageAudioTest from './components/media/PageAudioTest';

const PageATQtnDev = ReduxBind((props) => {
  const dispatch = props.dispatch;

  return <div className='PageATQtnDev flexColStart'>
    <h1>(PageATQtnDev.jsx) Authoring Question Editing Page </h1>
    <h2>Question view test</h2>
    {debugMode()?<PageTest {...props} />:<div></div>}
    {debugMode()?<PageAudioTest {...props} />:<div></div>}
    </div>;
});
export default PageATQtnDev;


