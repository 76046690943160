import React, { useEffect, useMemo, useState } from 'react';
import { useUILang } from '../utils/useUILang';

import * as UI  from '../../libs/libUI';
import {stopEvent} from '../../libs/libUI';
import { _ATRoot } from '../../consts/ATConstReact';

import { eNextIsFile, exerMapNextMedia } from '../../consts/ATValidateExer';

import { CpSettingModalTitle, CpSettingModalSection, CpSettingModalTabContainer } from '../../AppExPFUser/_components/CpSettingModalTabCps';
import CpDropdown, { obj2DropdownItem, obj2DropdownItemT } from '../../AppExPFUser/_components/CpDropdown';
import CpActionButton from '../../AppExPFUser/_components/CpActionButton';
import { ATUIText } from '../AppUtil';
import { atErrsDiv } from '../AppUtil';
import { toObj } from '../../libs/libType';

const LayerEditRule = (props) => {
    const {lock, ENx, setENx, setOnAddMedia, showEn, getMediaDLs, fieldErrs} = props;

    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const fes = toObj(fieldErrs);
    const dlf = eNextIsFile(ENx);
    const mediaId = eNextIsFile(ENx) && (showEn? ENx.mediaEn: ENx.mediaCt);  
    const media = getMediaDLs([mediaId])[mediaId];
    const lnkDis = showEn?ENx.lnkEn:ENx.lnkCt;
    const NameDis = showEn?ENx.nameEn:ENx.nameCt;
    
    const ruleNameF = showEn?'ruleNameEn':'ruleNameCt';
    const NameF = showEn?'nameEn':'nameCt';
    const mediaF = showEn?'mediaEn':'mediaCt';
    const lnkF = showEn?'lnkEn':'lnkCt';

    const conditionDDI = useMemo(() => obj2DropdownItemT(t, _ERule_Conditions), [uiEn]); 
    const mtype = { 
        f:t("exercise-edit-tab-next-steps-options-type-file"),
        l:t("exercise-edit-tab-next-steps-options-type-link")
    };      

    const setKV = key => val => {setENx({...ENx, [key]:val})};
    const clearMedia = key => e => { stopEvent(e); setKV(key)(''); };
    const clickMedia = key => e => { stopEvent(e);
        setOnAddMedia({onAdd:(medias => {
            const m = medias[0];
            setKV(key)(m.mediaId);
            setOnAddMedia(0);
        }), maxMedia:1 }); 
    };

    const passItems = obj2DropdownItemT(t, passOrNot);
    const averItems = obj2DropdownItemT(t, aveAorB);
    const typeItems = obj2DropdownItem(mtype);
    const showErr = Object.keys(toObj(fes)).length > 0;
    return <>{showErr?<div className="w100">{atErrsDiv(fes, '', t)}</div>:''}
        <CpSettingModalTitle>{t("exercise-edit-tab-next-steps-rule-name")}:</CpSettingModalTitle>
        {ATUIText(ENx?.[ruleNameF], setKV(ruleNameF), 'txtTE',lock,'',"w-100 border mb-3 m-0")}
        <div className={"LayerEditRule semi-bold text-exercise-third-btn fs-5 w-100"}>{t("exercise-edit-tab-next-steps-when")}</div>
        <div className={"border p-3 d-flex flex-column flex-lg-column gap-3 w-100 align-items-end align-items-lg-center"}>
            <CpDropdown className="w100" items={conditionDDI} idx={ENx?.att} cb={setKV('att')} />
            {((ENx?.att) === 'y') ? <><div className="d-flex flex-row w-100 gap-2 align-items-lg-center">
                {UI.CheckBox0(ENx?.passt, setKV('passt'), 'tp')}
                <CpDropdown className="w100" items={passItems} idx={ENx?.pass} cb={setKV('pass')} enable={ENx?.passt} /></div>
                <div className="d-flex flex-row w-100 gap-2 align-items-lg-center">
                {UI.CheckBox0(ENx?.avgt, setKV('avgt'), 'ta')}
                <CpDropdown className="w100" items={averItems} idx={ENx?.avg} cb={setKV('avg')} enable={ENx?.avgt} /></div></>
            :''}
        </div>
        <div className={"semi-bold text-exercise-third-btn fs-5 w-100"}>{t("exercise-edit-tab-next-steps-action")}</div>
        <div className={"border p-3 w-100 d-flex flex-column gap-2"}>
            <div className={"d-flex gap-2 align-items-center mb-3"}>
                <span className={"semi-bold pb-1"}>{t("exercise-edit-tab-next-steps-provide-the-student")}</span>
                <span><CpDropdown className="w100" items={typeItems} idx={ENx?.dl} cb={setKV('dl')}/></span>
            </div>
            <table className={"w-100"}>
                <tbody><tr>
                    <td className={"semi-bold mb-2"}>{t("exercise-edit-tab-next-steps-title")}:</td>
                    <td>{ATUIText(NameDis, setKV(NameF),'txtTE',lock,'',"w-100 border d-flex flex-grow-1 mb-2 m-0")}</td>
                </tr>
                <tr><td className={"semi-bold"}>{t((ENx?.dl === 'l')?"exercise-edit-tab-next-steps-url"
                        :"exercise-edit-tab-next-steps-file")}:</td>
                    <td className={"d-flex gap-2"}>
                    {(dlf)?<div className="d-flex flex-row w-100 gap-2 align-items-lg-center"><CpExNxMedia m={media} t={t}/>
                        {media?<CpActionButton className={`d-flex btn exercise-action-btn py-0 semi-bold align-item-center`}
                            title={t("delete")} onClick={clearMedia(mediaF)}></CpActionButton>:''}
                        <CpActionButton className={`d-flex btn exercise-action-btn py-0 semi-bold align-item-center`}
                            title={t("browse")} onClick={clickMedia(mediaF)}></CpActionButton>
                        </div>
                        :ATUIText(lnkDis, setKV(lnkF), 'txtLE',lock,'',"w-100 border d-flex flex-grow-1 mb-2 m-0")}
                </td></tr></tbody>
            </table>
        </div></>
};
export default LayerEditRule; 

const CpExNxMedia = ({m, t}) => m?<>{(m.name)} (size: {m.size} bytes)</>:'('+t('please-select-media')+')';

export const _ERule_Conditions = { 
    '-': "exercise-edit-tab-next-steps-options-condition-result-published",
    y: "exercise-edit-tab-next-steps-options-condition-student-attempted",
    n: "exercise-edit-tab-next-steps-options-condition-student-not-attempted" 
};

export const passOrNot = {a:'exercise-verb-passed',b:'exercise-verb-did-not-pass'};
export const aveAorB = {a:'exercise-edit-tab-next-steps-options-verb-greater-than-equal-to',
    b:'exercise-edit-tab-next-steps-options-verb-lower-than-equal-to'};
