import React, {HTMLProps, useState} from "react";
import { ReduxBind } from "../../saga/ReduxState";
import { stopEvent } from "../../libs/libUI";
import { Button, CloseButton } from "react-bootstrap";
import CpIco from "../_components/CpIco";
import CpActionButton from "../_components/CpActionButton";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { lang2t, useUILang } from "../../AppExPf/utils/useUILang";
import CpSharedModal from "../_components/CpSharedModal";
import { toInt, toObj } from "../../libs/libType";
import { actBtnProps } from "./LayerAssignmentEnd";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { toIdStr } from "../../consts/ATValidate";
import { preJS } from "../../AppExPf/AppUtil";
import { popAlert, popConfirm } from "../../AppExPf/components/CpPopup";
import { errMsg, errMsgs } from "../../libs/libFormat";

export const useAssignmentPub = () => {
    const [pubAsm, setPubAsm] = useState();
    return {pubAsm, setPubAsm };
};
export const CpPublishCTA = ReduxBind(props => { //base on PublishResultsCTA
    const {dispatch, asm, reload, useAsmPub, ...btnProps} = props;
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {setPubAsm} = useAsmPub; 

    const clickAsmPub = e => { stopEvent(e); setPubAsm({asm, reload}); };
    return <CpActionButton title={t("assignment-cta-publish-results")} iconPath={IconList.assignment.publishResults}
    className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0 text-truncate`} onClick={clickAsmPub} {...actBtnProps(btnProps)} />;
});

const LayerAssignmentPublish = ReduxBind(props => { //base on PublishResultsCTA
    const [t, uiEn, lang, setLang, ut, t2, t3, tht] = useUILang();
    const {dispatch, useAsmPub} = props; 
    const {pubAsm:_pubAsm, setPubAsm} = useAsmPub; 
    const {asm: pubAsm, reload } = toObj(_pubAsm); 

    const {ttl, mark, submit} = toObj(pubAsm?.Stat);
    const canPub = pubAsm && (mark === submit);

    const assignId = toIdStr(pubAsm?.assignId);
    const ex = toObj(pubAsm?.Exer);
    const AName =  lang2t(pubAsm?.ALang, ex.ENameEn, ex.ENameCt);

    const clickPubAsm = async e => { stopEvent(e); 
        if(canPub){
            const [res, err] = await asyncApiCallLoad_(dispatch, '/putAssignmentUpdate', {assignId, pub:1});
            if(err){
                popAlert(dispatch, 0, errMsgs(err), reload);
            }else{
                reload(); //onPubAsm(pubAsm);
            }
        }
        setPubAsm(0);
    };
    const clickClose = e => { stopEvent(e); setPubAsm(0);};
    return <>
        <CpSharedModal show={pubAsm} scrollable={false} 
            footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
                {canPub?<Button variant="gray-body-color w-100 border" onClick={clickClose}>{t("cancel")}</Button>:''}
                {canPub
                    ?<Button variant={`exercise-third-btn btn-block w-100`} onClick={clickPubAsm}>{t("confirm")}</Button>
                    :<Button variant={`exercise-third-btn btn-block w-100`} onClick={clickClose}>{t("cancel")}</Button>}
            </div>}>
            <div className={"px-3 pt-4 bg-dim-100 rounded-top"}>
                <div className={"d-flex flex-column gap-4"}>
                    <div className={"d-flex flex-column gap-3 w-100"}>
                        <div className={"d-flex justify-content-between"}>
                            <span className={"d-flex justify-content-end opacity-0 pe-none"}><CloseButton onClick={clickClose}/></span>
                            <span className={"d-flex justify-content-center text-exercise-third-btn fs-1"}><CpIco src={IconList.assignment.publishResults}/></span>
                            <span className={"d-flex justify-content-end"}><CloseButton onClick={clickClose}/></span>
                        </div>
                        <div className={"d-flex justify-content-center text-exercise-third-btn fs-4 semi-bold"}>{t("assignment-cta-publish-results")}</div>
                        <div className={"d-flex w-100 fs-4 semi-bold justify-content-center user-select-none text-center"}>{AName}</div>
                    </div>
                    <div className={"d-flex flex-column align-items-start gap-3 mb-4 px-3"}>
                        {
                            //{preJS(pubAsm.Stat)}
                        canPub
                            ?tht("warning.warning_confirm_publish_assignment_result")
                            :t("assignment-publish-results-warning-message")

}
                        {/* This is another warning :"assignment-publish-results-warning-message"*/}
                    </div>
                </div>
            </div>
        </CpSharedModal>
    </>
});

export default LayerAssignmentPublish;

