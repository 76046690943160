import React, { useMemo } from "react";
import { useUILang } from "../utils/useUILang";
import { RemeLvPre, RemeLvPro, RemeLvSame } from "../../consts/ATValidateReme";
import CpIco from "../../AppExPFUser/_components/CpIco";
import CpMJX from "../../AppExPFUser/_components/CpMJX";
import { toStr } from "../../libs/libType";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { toUniIdAry } from "../../consts/ATValidate";
import { preJS } from "../AppUtil";



export const TagMetaReme___ = (className, props, icoClass, src) => { //ref: CpCardMetaTag
    const {ct, meta, id} = props;
    return <small {...{key:id, id, className}} >
        <CpMJX>{[toStr(meta.LVNum), toStr(ct(meta.nameEn, meta.nameCt))].filter(s=>s).join(' ')}</CpMJX>
        {src? <span className={icoClass}><CpIco {...{src}}/></span>: ''}
    </small>;
};
export const TagMetaRemeNo = props => TagMetaReme___(//ExerciseTag
    `TagMetaRemeNo exercise-tag px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 rounded-light bg-dim-200 border-tag-danger text-black`,
    props,'text-danger', IconList.general.discard);
export const TagMetaRemeYes = props => TagMetaReme___( //ExerciseTag
    `TagMetaRemeYes exercise-tag px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 rounded-light exercise-available-tag text-black`,
    props,'text-success', IconList.general.confirm );
export const TagMetaReme = props => TagMetaReme___( //ExerciseTag
    `TagMetaRemeYes exercise-tag px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 rounded-light exercise-available-tag text-black`,
    props,);

export const TagStuRemePass = props => { //POC FollowUpExerciseTag
    const {t, fill, pass} = props;
    
    const color = pass? 'tag-success': 'tag-warning';

    const style = 'border-'+color+' bg-'+(fill? color: 'white')+' text-'+(fill? 'white': color);
    //const style = hasFailReme? `bg-tag-warning border-tag-warning text-white`:  `bg-tag-success border-tag-success text-white`;
    //const title = t(hasFailReme? 'assignment-tag-follow-up-uncompleted': 'assignment-tag-follow-up-complete');
    //const styleX = pass? `bg-tag-success border-tag-success text-white`: `bg-tag-warning border-tag-warning text-white`;

    const title = t(pass? 'assignment-tag-follow-up-complete': 'assignment-tag-follow-up-uncompleted');

    const circle = '';//: "bg-tag-warning",
    //<span className={` d-flex align-items-center ${circle} rounded-circle`} style={{width: "0.5rem", height: "0.5rem"}}></span>

    return <small className={`semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light ${style}`}>
        {title}
    </small>
};


export const TagRemeLvs = props => { 
    const remeLvs = toUniIdAry(props.remeLvs);
    //return preJS({remeLvs});
    return [RemeLvSame, RemeLvPre, RemeLvPro].filter(l => remeLvs.includes(l)).map(l => <TagRemeLv key={l} status={l} />);
};

const TagRemeLv = props => { //POC FollowUpExerciseTag
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();    
    const { status } = props;

    const style = useMemo(() => ({
        [RemeLvSame]: `bg-white border-tag-info text-tag-info`,
        [RemeLvPre]:  `bg-white border-tag-danger text-tag-danger`,
        [RemeLvPro]:  `bg-white border-tag-primary text-tag-primary`,
    }[status] || '' ),[status]);
  
    const title = useMemo(() => remeLvTxt(status, t),[uiEn, status]);
    
    return <small className={`TagRemeLv semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light ${style}`}>
        {title}
    </small>
  };
  
  export default TagRemeLv;

  export const remeLvTxt = (status, t) => {
    return ({
        [RemeLvSame]: t("assignment-tag-follow-up-same"),
        [RemeLvPre]:  t("assignment-tag-follow-up-pre"),
        [RemeLvPro]:  t("assignment-tag-follow-up-pro"),
    }[status] || '(remelv: '+status+'?)');
  };