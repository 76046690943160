import React, { useCallback, useMemo, useRef, useEffect, useReducer, memo, CSSProperties } from 'react';
import { calculatePositions, Position } from './AverageScorePositionUtils';
import AverageScoreProgressPopupBox from './AverageScoreProgressPopupBox';
import {
    YearlyScore,
    getColorForYear,
    MetadataProgressItemType,
} from "../../../../type/ChartType";

// Constants
const MIN_PERCENTAGE_DIFFERENCE_FOR_ARROW = 3;
const MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX = 6.5;
const DOT_LINE_OFFSET_ZERO = '5.5px';
const DOT_LINE_OFFSET_HUNDRED = '6.5px';
const CLASS_AVERAGE_LINE_OFFSET = '2px';
const CLASS_AVERAGE_LINE_OFFSET_HUNDRED = '2.5px';
const DOT_LINE_Z_INDEX = 10;
const HORIZONTAL_LINE_BASE_Z_INDEX = 5;
const SCORE_BOX_SIDE_OFFSET = '10px';
const THREE_SCORES_THRESHOLD = 3;
const BORDER_WIDTH = '1.5px';
const SCORE_BOX_PADDING_VERTICAL = '2px';
const SCORE_BOX_PADDING_HORIZONTAL = '8px';
const SCORE_BOX_BORDER_RADIUS = '6px';
const SCORE_BOX_FONT_SIZE = '16px';
const SMALL_OFFSET_THRESHOLD = 10;
const LEFT_OFFSET_DEFAULT = '27px';
const LEFT_OFFSET_SMALL = '20px';
const SCORE_BOX_Z_INDEX = 20;
const SCORE_BOX_TOP_POSITION = '-12px';
const SCORE_BOX_SIDE_POSITION = '12px';
const SCORE_BOX_WIDTH = 52;
const CHART_WIDTH = 800;
const HOVER_AREA_EXTENSION = 2;
const HOVER_AREA_TOP_OFFSET = '-14px';
const HOVER_AREA_Z_INDEX = 20;

// Types
interface Props {
    isSelectedStudentCase?: boolean;
    averageScore?: YearlyScore[];
    parentLine?: YearlyScore[];
    parentTitleIndex?: string;
    parentTitle?: string;
    studentInfo?: MetadataProgressItemType;
    sticky?: { top: boolean; bottom: boolean };
    isFirstRender?: boolean;
    scoreVisible?: boolean;
    classAverageVisible?: boolean;
    titleIndex?: string;
    title?: string;
    onRowHover: (rowId: string) => void;
    isHovered: boolean;
    rowId: string;
    selectedStudentInfo?: MetadataProgressItemType;
}

interface PopupData {
    titleIndex: string;
    title: string;
    parentTitleIndex?: string;
    parentTitle?: string;
    scores: YearlyScore[];
    classAverages?: YearlyScore[];
    isStudent: boolean;
    studentInfo: MetadataProgressItemType | undefined;
}

type PopupState = {
    showPopup: boolean;
    popupData: PopupData | null;
    popupPosition: { x: number; y: number };
    isPositionCalculated: boolean;
    currentRowId: string | null;
};

type PopupAction =
    | { type: 'SHOW_POPUP'; payload: { data: PopupData; rowId: string } }
    | { type: 'HIDE_POPUP' }
    | { type: 'UPDATE_POSITION'; payload: { x: number; y: number } }
    | { type: 'SET_POSITION_CALCULATED'; payload: boolean };

// Helper functions
const lineLeft = (percentage: number = 0, dot: boolean = false, classAverageline: boolean = false): string => {
    if (dot) {
        if (percentage === 0) return `calc(${percentage}% - ${DOT_LINE_OFFSET_ZERO})`;
        if (percentage === 100) return `calc(${percentage}% - ${DOT_LINE_OFFSET_HUNDRED})`;
    }

    if (classAverageline) {
        if (percentage === 0) return '0%';
        if (percentage === 100) return `calc(${percentage}% - ${CLASS_AVERAGE_LINE_OFFSET_HUNDRED})`;
        return `calc(${percentage}% - ${CLASS_AVERAGE_LINE_OFFSET})`;
    }

    if (percentage === 0) return '0%';
    return `calc(${percentage}% - ${DOT_LINE_OFFSET_HUNDRED})`;
};

const YearlyScores: React.FC<{ scores: YearlyScore[] }> = memo(({ scores }) => {
    const sortedScores = useMemo(() => [...scores].sort((a, b) => a.year - b.year), [scores]);
    const shouldShowArrow = useCallback((start: YearlyScore, end: YearlyScore, allScores: YearlyScore[]): boolean => {
        const distance = Math.abs(end.scorePercentage - start.scorePercentage);
        if (distance < MIN_PERCENTAGE_DIFFERENCE_FOR_ARROW) return false;

        const startIndex = allScores.indexOf(start);
        const endIndex = allScores.indexOf(end);

        // Special case: Don't show arrow between first and third year
        if (allScores.length === 3 && startIndex === 0 && endIndex === 2) {
            return false;
        }

        // Check if there's a score between start and end that's closer to end
        for (let i = Math.min(startIndex, endIndex) + 1; i < Math.max(startIndex, endIndex); i++) {
            if (Math.abs(allScores[i].scorePercentage - end.scorePercentage) < MIN_PERCENTAGE_DIFFERENCE_FOR_ARROW) {
                return false;
            }
        }

        // Special case: Don't show yellow arrow when red % > green % and they're too close
        if (allScores.length === 3 && start.year < end.year) {
            const [yellowScore, redScore, greenScore] = allScores.sort((a, b) => a.year - b.year);
            if (start === yellowScore && end === redScore) {
                if (redScore.scorePercentage > greenScore.scorePercentage &&
                    Math.abs(greenScore.scorePercentage - redScore.scorePercentage) < MIN_PERCENTAGE_DIFFERENCE_FOR_ARROW) {
                    return false;
                }
            }
        }

        // Special case: Don't show red arrow when green % > yellow % and they're too close
        if (allScores.length === 3 && start.year === 2 && end.year === 3) {
            const [yellowScore, redScore, greenScore] = allScores.sort((a, b) => a.year - b.year);
            if (greenScore.scorePercentage > yellowScore.scorePercentage &&
                Math.abs(greenScore.scorePercentage - yellowScore.scorePercentage) < MIN_PERCENTAGE_DIFFERENCE_FOR_ARROW &&
                redScore.scorePercentage < yellowScore.scorePercentage &&
                redScore.scorePercentage < greenScore.scorePercentage) {
                return false;
            }
        }

        // Special case: Don't show red arrow when green % < yellow % and they're too close
        if (allScores.length === 3 && start.year === 2 && end.year === 3) {
            const [yellowScore, redScore, greenScore] = allScores.sort((a, b) => a.year - b.year);
            if (greenScore.scorePercentage < yellowScore.scorePercentage &&
                Math.abs(greenScore.scorePercentage - yellowScore.scorePercentage) < MIN_PERCENTAGE_DIFFERENCE_FOR_ARROW &&
                redScore.scorePercentage > yellowScore.scorePercentage &&
                redScore.scorePercentage > greenScore.scorePercentage) {
                return false;
            }
        }

        return true;
    }, []);

    return (
        <>
            {sortedScores.map((score, index) => (
                <React.Fragment key={score.year}>
                    <div
                        className={`status-line dot-line`}
                        style={{
                            left: lineLeft(score.scorePercentage, true),
                            backgroundColor: getColorForYear(score.year),
                            zIndex: DOT_LINE_Z_INDEX + index
                        }}
                    />
                    {index < sortedScores.length - 1 && sortedScores.slice(index + 1).map((endScore, endIndex) => {
                        const startPercentage = Math.min(score.scorePercentage, endScore.scorePercentage);
                        const endPercentage = Math.max(score.scorePercentage, endScore.scorePercentage);
                        return (
                            <div
                                key={`${score.year}-${endScore.year}`}
                                className={`horizontal-line`}
                                style={{
                                    left: lineLeft(startPercentage),
                                    width: `${endPercentage - startPercentage}%`,
                                    backgroundColor: getColorForYear(score.year),
                                    zIndex: HORIZONTAL_LINE_BASE_Z_INDEX + index
                                }}>
                                {shouldShowArrow(score, endScore, sortedScores) && (
                                    <div
                                        className={`arrow ${score.scorePercentage > endScore.scorePercentage ? 'left' : 'right'}
                                    ${score.scorePercentage === 0 ? 'from-zero' : ''}
                                    ${endScore.scorePercentage === 0 ? 'to-zero' : ''}`}
                                        style={{
                                            borderColor: getColorForYear(score.year)
                                        }}
                                    />
                                )}
                            </div>
                        );
                    })}
                </React.Fragment>
            ))}
        </>
    );
});

const ClassAverageLines = memo(({ parentLine, classAverageVisible, isSelectedStudentCase }: { parentLine?: YearlyScore[], classAverageVisible: boolean, isSelectedStudentCase?: boolean }) => {
    if (!parentLine || parentLine.length === 0) return null;

    return (
        <>
            {parentLine.map((score) => (
                <div
                    key={score.year}
                    className={`status-line dash-line line-status-n class-average-line ${classAverageVisible ? 'show' : ''} ${isSelectedStudentCase ? 'selected-student' : ''}`}
                    style={{
                        left: lineLeft(score.scorePercentage, false, true),
                        borderColor: getColorForYear(score.year),
                        backgroundColor: isSelectedStudentCase ? getColorForYear(score.year) : 'transparent',
                    }}
                />
            ))}
        </>
    );
});

const ScoreBoxes: React.FC<{ scores: YearlyScore[], scoreVisible: boolean }> = memo(({ scores, scoreVisible }) => {
    const positionMap = useMemo(() => calculatePositions(scores, {
        MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX,
        THREE_SCORES_THRESHOLD
    }), [scores]);

    const createBaseStyle = useMemo(() => (score: YearlyScore): React.CSSProperties => ({
        border: `${BORDER_WIDTH} solid ${getColorForYear(score.year)}`,
        color: getColorForYear(score.year),
        backgroundColor: 'white',
        padding: `${SCORE_BOX_PADDING_VERTICAL} ${SCORE_BOX_PADDING_HORIZONTAL}`,
        borderRadius: SCORE_BOX_BORDER_RADIUS,
        position: 'absolute',
        fontSize: SCORE_BOX_FONT_SIZE,
        zIndex: SCORE_BOX_Z_INDEX,
    }), []);

    const getPositionStyle = (position: Position, score: YearlyScore): React.CSSProperties => {
        const leftOffset = score.scorePercentage < SMALL_OFFSET_THRESHOLD ? LEFT_OFFSET_SMALL : LEFT_OFFSET_DEFAULT;
        switch (position) {
            case Position.Top:
                return {
                    left: `calc(${score.scorePercentage}% - ${leftOffset})`,
                    top: SCORE_BOX_TOP_POSITION,
                };
            case Position.Left:
                return {
                    right: `calc(100% - ${score.scorePercentage}% + ${SCORE_BOX_SIDE_OFFSET})`,
                    top: SCORE_BOX_SIDE_POSITION,
                };
            case Position.Right:
                return {
                    left: `calc(${score.scorePercentage}% + ${SCORE_BOX_SIDE_OFFSET})`,
                    top: SCORE_BOX_SIDE_POSITION,
                };
        }
    };

    return (
        <>
            {scores.map((score) => {
                const position = positionMap.get(score.year) || Position.Top;
                const style: React.CSSProperties = {
                    ...createBaseStyle(score),
                    ...getPositionStyle(position, score)
                };

                return (
                    <div
                        key={score.year}
                        className={`score-box ${scoreVisible ? 'show' : ''}`}
                        style={style}
                    >
                        {score.scorePercentage.toFixed(1)}
                    </div>
                );
            })}
        </>
    );
});

// Custom hooks
const usePopup = (isHovered: boolean, rowId: string) => {
    const popupReducer = (state: PopupState, action: PopupAction): PopupState => {
        switch (action.type) {
            case 'SHOW_POPUP':
                return {
                    ...state,
                    showPopup: true,
                    popupData: action.payload.data,
                    isPositionCalculated: false,
                    currentRowId: action.payload.rowId
                };
            case 'HIDE_POPUP':
                return { ...state, showPopup: false, isPositionCalculated: false, currentRowId: null, popupData: state.popupData };
            case 'UPDATE_POSITION':
                return { ...state, popupPosition: action.payload, isPositionCalculated: true };
            case 'SET_POSITION_CALCULATED':
                return { ...state, isPositionCalculated: action.payload };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(popupReducer, {
        showPopup: false,
        popupData: null,
        popupPosition: { x: -1000, y: -1000 },
        isPositionCalculated: false,
        currentRowId: null
    });

    useEffect(() => {
        if (!isHovered || state.currentRowId !== rowId) {
            dispatch({ type: 'HIDE_POPUP' });
        }
    }, [isHovered, rowId, state.currentRowId]);

    return { state, dispatch };
};

// Main component
const AverageScoreProgressChart: React.FC<Props> = (props) => {
    const {
        isSelectedStudentCase,
        averageScore,
        parentLine,
        sticky = { top: false, bottom: false },
        isFirstRender = false,
        scoreVisible = false,
        classAverageVisible = false,
        titleIndex,
        title,
        onRowHover,
        isHovered,
        rowId,
        selectedStudentInfo,
    } = props;

    const chartRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const { state: popupState, dispatch: popupDispatch } = usePopup(isHovered, rowId);

    const interactiveAreaStyle = useMemo((): CSSProperties => {
        if (!averageScore || averageScore.length === 0) return {};

        let minLeft = Math.min(...averageScore.map(s => s.scorePercentage));
        let maxRight = Math.max(...averageScore.map(s => s.scorePercentage));

        if (scoreVisible) {
            const positionMap = calculatePositions(averageScore, {
                MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX,
                THREE_SCORES_THRESHOLD
            });
            const scoreBoxWidthPercentage = (SCORE_BOX_WIDTH / CHART_WIDTH) * 100;

            averageScore.forEach(score => {
                const position = positionMap.get(score.year);

                if (position === Position.Left) {
                    minLeft = Math.min(minLeft, score.scorePercentage - scoreBoxWidthPercentage);
                } else if (position === Position.Right) {
                    maxRight = Math.max(maxRight, score.scorePercentage + scoreBoxWidthPercentage);
                } else { // Position.Top
                    minLeft = Math.min(minLeft, score.scorePercentage - scoreBoxWidthPercentage / 2);
                    maxRight = Math.max(maxRight, score.scorePercentage + scoreBoxWidthPercentage / 2);
                }
            });
        }

        // Ensure the hover area extends slightly beyond the elements
        minLeft = Math.max(0, minLeft - HOVER_AREA_EXTENSION);
        maxRight = Math.min(100, maxRight + HOVER_AREA_EXTENSION);

        return {
            position: 'absolute',
            left: `${minLeft}%`,
            width: `${maxRight - minLeft}%`,
            height: '100%',
            top: scoreVisible ? HOVER_AREA_TOP_OFFSET : 0,
            cursor: 'pointer',
            pointerEvents: 'auto',
            zIndex: HOVER_AREA_Z_INDEX,
        };
    }, [averageScore, scoreVisible]);

    const calculatePopupPosition = useCallback(
        (mouseX: number, mouseY: number) => {
            if (!chartRef.current || !popupRef.current) return { x: 0, y: 0 };

            const chartRect = chartRef.current.getBoundingClientRect();
            const popupRect = popupRef.current.getBoundingClientRect();

            // Calculate the mouse position as a percentage of the chart width
            const mousePercentageX = ((mouseX - chartRect.left) / chartRect.width) * 100;

            // Calculate the popup width as a percentage of the chart width
            const popupWidthPercentage = (popupRect.width / chartRect.width) * 100;

            // Determine the left position of the popup
            let leftPercentage = mousePercentageX + 1;

            // Ensure the popup stays within the 0-100% range
            leftPercentage = Math.max(0, Math.min(100 - popupWidthPercentage, leftPercentage));

            // Calculate the top position, ensuring it doesn't go above the chart
            const topPixels = Math.max(0, mouseY - chartRect.top - popupRect.height - 10);

            return {
                x: leftPercentage,
                y: topPixels
            };
        },
        []
    );

    const handleMouseEnter = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            const isStudentRow = !!props.studentInfo;

            popupDispatch({
                type: 'SHOW_POPUP',
                payload: {
                    data: {
                        titleIndex: titleIndex || '',
                        title: isStudentRow ? '' : (title || ''),
                        parentTitleIndex: isStudentRow ? props.parentTitleIndex : '',
                        parentTitle: isStudentRow ? props.parentTitle : '',
                        scores: averageScore || [],
                        classAverages: parentLine,
                        isStudent: isStudentRow,
                        studentInfo: isStudentRow ? props.studentInfo : undefined,
                    },
                    rowId: rowId
                }
            });

            setTimeout(() => {
                const newPosition = calculatePopupPosition(event.clientX, event.clientY);
                popupDispatch({ type: 'UPDATE_POSITION', payload: newPosition });
            }, 50);

            onRowHover(rowId);
        },
        [averageScore, parentLine, props.parentTitleIndex, props.parentTitle, titleIndex, title, calculatePopupPosition, onRowHover, rowId, props.studentInfo, popupDispatch]
    );

    const handleMouseLeave = useCallback(() => {
        popupDispatch({ type: 'HIDE_POPUP' });
        onRowHover('');  // Pass an empty string or null to indicate no row is hovered
    }, [onRowHover, popupDispatch]);

    return (
        <div
            ref={chartRef}
            className={`field score-percentage-field position-relative
                    ${sticky.top ? "mt-0" : ""}
                    ${sticky.bottom ? "mb-0" : ""}
                    ${isFirstRender ? "first-render" : ""}
                    ${scoreVisible ? "score-visible-on" : ""}`
            }
        >
            {averageScore && averageScore.length > 0 && <YearlyScores scores={averageScore} />}
            <ClassAverageLines parentLine={parentLine} classAverageVisible={classAverageVisible} isSelectedStudentCase={isSelectedStudentCase} />
            {averageScore && <ScoreBoxes scores={averageScore} scoreVisible={scoreVisible} />}

            <div
                className="hover-area"
                style={interactiveAreaStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />

            <div
                ref={popupRef}
                className={`score-popup-wrapper ${popupState.showPopup && popupState.isPositionCalculated ? 'show' : ''}`}
                style={{
                    left: `${popupState.popupPosition.x}%`,
                    top: `${popupState.popupPosition.y}px`,
                }}
            >
                <AverageScoreProgressPopupBox
                    titleIndex={popupState.popupData?.titleIndex || ''}
                    title={popupState.popupData?.title || ''}
                    parentTitleIndex={popupState.popupData?.parentTitleIndex}
                    parentTitle={popupState.popupData?.parentTitle}
                    scores={popupState.popupData?.scores || []}
                    classAverages={popupState.popupData?.classAverages}
                    isStudent={popupState.popupData?.isStudent || false}
                    studentInfo={popupState.popupData?.studentInfo || undefined}
                    selectedStudentInfo={selectedStudentInfo}
                    classAverageVisible={classAverageVisible}
                />
            </div>
        </div>
    );
};

export default memo(AverageScoreProgressChart);