import {FormCheckProps} from "react-bootstrap";

interface Props extends FormCheckProps {
    disableOnClick?: boolean
    partial?: boolean
}

const Checkbox = (props: Props) => {
    const {disableOnClick = false, partial = false, ...checkboxProps} = props

    return <span className={"simple-checkbox position-relative"}>
        <label className={`control control--checkbox ${disableOnClick ? "pe-none" : ""}`}>
        <input type="checkbox" {...checkboxProps}/>
        <div className={`control__indicator ${partial ? "partial" : ""}`}></div>
    </label>
    </span>

}

export default Checkbox