import { useEffect, useState } from 'react';
import { toUniIdAry } from '../../consts/ATValidate';

//import TabQMC from './TabQMC';
import { apiCallLoad_ } from '../../libs/awsFuncs';
import { toAry, toObj } from '../../libs/libType';
import { addIfMiss } from './useTagMSetCache';
import { unpackQtn } from '../../consts/wirisConfig';

const appendQ = (qs, newq, que) => {
    const a = Object.fromEntries(que.map(qid => [qid, {}] ));
    const appQ = { ...a, ...qs, ...newq }; 
    
    
    return appQ;
};

/*
const setWaiting = (q) => [...q];
asyncGetQ = (qids) => {}
return new Promise(res => {
    setWaiting(qs => [...qs,   [qids, res]]);
});
const onLoad = () => {
    setWaiting(ws => {
        ws.filter(w => qids.includeAll(w.qids));


    });
});
*/

export const useQtnCache = (dispatch, isAT) => {
    /*
    const [qMSets, setqMsets] = useState({})
    const addMSets = MSets => {
        setqMsets(qMSets => {
            const ret = { ...qMSets, ...Object.fromEntries(objEntries(MSets).map( ([metaSetId, mset]) => {
                const metas = {...toObj( qMSets[metaSetId]?.metas ), ...toObj(mset.metas)};
                return [metaSetId, {...mset, metas}]; 
            } ) ) };
            
            return ret;
        });
    };
    const [qMSets, setqMsets] = useState({})
    */
    const [qtns, setQtns] = useState({});
    const [load, setLoad] = useState(0);
    const [que, setQue] = useState([]);

    const addNewQtn = (QId, qtn) => {
        setQtns(qs => ({...toObj(qs), [QId]: qtn}));
    };
    
    const _queMiss = (miss) => {
		const missStr = miss.sort().join('|');
        useEffect(() => {
            if(miss.length) setQue(q => {
                const realMiss = miss.filter(m => !q.includes(m));
                return realMiss.length? [ ...q, ...realMiss]: q;
            });
        }, [missStr]);
	};
    const useCacheQtns = qids => {
        const hits = Object.fromEntries(qids.map(qid => {const q = qtns[qid]; return q? [qid, q]: 0;} ).filter(pair => pair));
        const miss = qids.filter(qid => (!hits[qid])).filter(qid => !que.includes(qid));
        _queMiss(miss);
        return hits;
    };
    const onLoadQs = (res, err, que) => {
        const _res = toObj(res);
        //const {QIds, qMs, MSets} = _res;
        const qtns = toAry(_res.qtns);
        const newq = Object.fromEntries(qtns.map( q  => [q.QId, unpackQtn(q)]));
        const loaded = [...que, ...Object.keys({...qtns, ...newq})];
        //const {MSets} = _res;
        //addMSets(MSets);
        setQtns(qs => appendQ(qs, newq, que));
        setQue(qu => toAry(qu).filter(qid => !loaded.includes(qid)));
        setLoad(0);
    };
    useEffect(()=>{
        if(!load && que.length){
            setLoad(_load => {
                return 1; 
            });
            apiCallLoad_(dispatch, '/getATQtnPubs', (res, err) => onLoadQs(res, err, que), { QIds:que, isAT });
        };
    }, [toUniIdAry(que).join(','), load]);

    return [qtns, useCacheQtns, addNewQtn];
};
