import React, {useEffect, useState} from 'react';
import queryString from 'query-string';

import {ReduxBind} from '../saga/ReduxState';

import {lj} from '../libs/libFormat';
import * as mw from '../libs/libMidWare';

import { apiCallLoad_ } from '../libs/awsFuncs';

import PageLogin from './PageLogin';
import { toAry, toStr } from '../libs/libType';
import { _EXPFRoot, _WebRoot } from '../consts/ATConstReact';
import { urlPush_Replace } from '../saga/urlPush.saga';
import { useStateGST } from '../saga/globalState.saga';
import { useUILang } from '../AppExPf/utils/useUILang';

export const setAWSLogin = (dispatch, res, err) => dispatch({type:'SETAWSLOGIN', res, err});

const redirect = (dispatch, path) => dispatch(urlPush_Replace(path));
//const redirect = (dispatch, path) => {props.history.push({path}); props.history.push({path}); };

const langingPath = p => (['redirect'].includes(p)? _EXPFRoot: p) || _EXPFRoot; 
const redirPath = state => {
    const [statePath, stateQS] = splitQS(mw.MLState2Path(state));
    const pathname = langingPath(statePath);
    
    return pathname;
};

const PageRedirect = ReduxBind( props => {
    const {dispatch} = props;
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const [visLogin, setVisLogin] = useState(false);

    const [loginDev, setLoginDev] = useStateGST(props, 'loginDev', {});
    const onLogin = (res, err, state) => {
        if(err){ lj({err}); }
        if(res){
            setLoginDev({res, err});
            setAWSLogin(props.dispatch, res, err);
            const noEnt = toAry(res.errs).includes('No Entitlement');
            redirect(dispatch, noEnt? _WebRoot+'noentitlement': redirPath(state));
        }
    };
/*
user={
access_token: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6Im9wZW5pZCIsImdyYW50X3R5cGUiOiJhdXRob3JpemF0aW9uX2NvZGUiLCJpYXQiOjE1ODg5MjgxMDIsImV4cCI6MTU4ODkzMTcwMiwiYXVkIjoiZmxhc2hjYXJkIiwiaXNzIjoiaHR0cHM6Ly9hY3FyeTlkZTAzLmV4ZWN1dGUtYXBpLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb20vbmlnaHRseSIsInN1YiI6ImVzYXMrMDRmODNlKzZvSGRhMjdSZ3AifQ.Y_EgP73unjmEhz7guwMyn9OgvY4-3pzTBvbaP7iGItMhgqrCT3Qq3oVXSap-R1mCgsy6n3nDKvLS_ZMHeBveBHxqMwOAwHEPpFvvsDFcD48_DHELXAg1ERPNRdRJOBGyu0oOEmt-NlSrC2dIAsGktv0UnDhGQvaao7a18qDF_FxOt6DGfB08rI0rYDlBLSPWtXeqUHm02-Dph-SYE7roXb3hrnwghZQ-ubjxMY1sPEQ4NJVlCY9vu6ML9M5oYR48UyrKf8y6p7TlXCw5FaA3-lq_FEvHHw2BtJnicor5LsMNZO0bHaIyvTSCqKmDAAMxm0rNRamqrB9vUWJgfMefWg"
expires_at: 1588931704
id_token: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiJlc2FzKzA0ZjgzZSs2b0hkYTI3UmdwIiwiaHR0cHM6Ly9vdXBjaGluYS5jb20uaGsvc2Nob29sX2lkIjoibXBvYyIsImh0dHBzOi8vb3VwY2hpbmEuY29tLmhrL3NjaG9vbCNlbiI6Im1pZGRsZWxheWVycG9jIiwiaHR0cHM6Ly9vdXBjaGluYS5jb20uaGsvc2Nob29sI3poLUhhbnQiOiJtaWRkbGVsYXllcnBvYyIsImh0dHBzOi8vb3VwY2hpbmEuY29tLmhrL3VzZXJfdHlwZSI6InRlYWNoZXIiLCJodHRwczovL291cGNoaW5hLmNvbS5oay9uYW1lI3poLUhhbnQiOiJUZWFjaGVyIiwiaHR0cHM6Ly9vdXBjaGluYS5jb20uaGsvbmFtZSNlbiI6IlRlYWNoZXIiLCJodHRwczovL291cGNoaW5hLmNvbS5oay90ZWFjaGVyX3Bvc2l0aW9uIjoiT3RoZXJzIiwiaWF0IjoxNTg4OTI4MTAyLCJleHAiOjE1ODg5MzE3MDIsImF1ZCI6ImZsYXNoY2FyZCIsImlzcyI6Imh0dHBzOi8vYWNxcnk5ZGUwMy5leGVjdXRlLWFwaS5hcC1zb3V0aGVhc3QtMS5hbWF6b25hd3MuY29tL25pZ2h0bHkiLCJzdWIiOiJlc2FzKzA0ZjgzZSs2b0hkYTI3UmdwIn0.CWg97AegFtzbxnOtCd-B6b4BNQJtKonuvm6LQK9cXvxlTKFDAJ2TaOuzU9NG10GUHrPQB6RMvJcWWdqnuqaJO8FoYpeLlxqEoRXZ8HTYhMEtMWUCrUADwMD2wJgTGePoNLyCpsVNBptNsKbEE2zmBBAtePlnA5ScheQSdYYUn6Un41tnYN5rA-zFcU6fTeUEEb221yxp8rdlPH98_rkqM7uOyj0it_iqRPn7yXzt94dcCxLOHIS3iVRByiBcFKmNEkbrd9rkG8icTrC5ere61aeKaB2xMRr7hj38f-PpPFdD3j-qR57tzsGnKuWlx-DxQdfWZvFcyCPB6cNsdlVBdg"
profile: {
    "https://oupchina.com.hk/name#en": "Teacher"
    "https://oupchina.com.hk/name#zh-Hant": "Teacher"
    "https://oupchina.com.hk/school#en": "middlelayerpoc"
    "https://oupchina.com.hk/school#zh-Hant": "middlelayerpoc"
    "https://oupchina.com.hk/school_id": "mpoc"
    "https://oupchina.com.hk/teacher_position": "Others"
    "https://oupchina.com.hk/user_type": "teacher"
    sub: "esas+04f83e+6oHda27Rgp"
    userID: "esas+04f83e+6oHda27Rgp"
}                ​​
refresh_token: undefined
scope: "openid"
session_state: undefined
state: undefined
token_type: "bearer"
}
*/
    useEffect(() => {(async () => {try{
        const search = window?.location?.search;
        const path = window?.location?.pathname;
        if((path==='/logout')){
            setVisLogin(true);
        }else{
            const qs = queryString.parse(search);
            const qsState = toStr(qs?.state);
            const signinParams = {state:{path, search}, pathSate:{path, search}};
            const settings = props.settings();
            const token = qs.token;
            
            if(token){
                mw.redirectESASTokenSignin(uiEn, settings, signinParams);
            }else if(qsState){
                const user = await mw.callbackSignin(settings, qsState);
                const {id_token, access_token, state} = user || {};
                apiCallLoad_(props.dispatch, '/tokenLogin', (res, err) => onLogin(res, err, state), {UILang, id_token, access_token});
            }else{
                const so = props.signedOut(); 
                
                if(!so){
                    if(path==='/') signinParams.prompt = 'none';
                    mw.redirectSignin(uiEn, settings, signinParams);
                }
            }
        }
        //(process.env.REACT_APP_CFG === 'local')
    }catch(e){
        if('user+login+required'===e?.message){
            redirect(dispatch, _WebRoot); 
        }
        setVisLogin(true);
    };})(); }, []);

    if(visLogin)
        return <PageLogin />;
    return (<div>Redirecting...</div>);
});
export default PageRedirect;

const splitQS = url => toStr(url).split('?', 2); 