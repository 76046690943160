import { isAry, isStr, toAry, toObj } from "../libs/libType";
import { toUniIdAry } from "./ATValidate";
import { deepCopy } from "../AppExPf/AppUtil";
import { _WST_RESUME, _ARS_CLOSE_SUBMIT } from "./ATConstsAssignment";
import { mapTagAtt } from "../libs/libHtml";
import { __RESP_DRAW, __RESP_FILE, __RESP_IMAGE, __RESP_TXT } from "./ATSysQType";
import { draw2Blob, localDrawId } from "../AppExPf/utils/useMediaCache";


export const debugTrimResp = resp => toAry(resp).map(r => {
    const {qresp, ..._r} = toObj(r);
    return {..._r, qresp: debugTrimDraw(qresp, 50)};
});

export const debugTrimDraw = (s, len = 60) => {
    if(!isStr(s)) return s;
    const ts = s.trim().slice(0, len);
    const lenStr = ts? ('( len = '+ s.length +' )'): '';
    const ret = ts? (ts + '.....' + lenStr + ''): '';
    return ret;
}; 

export const mapDrawMedia = (r, mapM, drawBlobs) => {
    if(r.qresp && (!r.qrespS3Id)){ //empty id, with draw data need map
        r.qrespS3Id = localDrawId('mapDraw'); // assign id to unmapped data
//console.warn('mapDrawMedia()', r.qrespS3Id, debugTrimDraw(r.qresp));

        drawBlobs[r.qrespS3Id] = draw2Blob(r.qrespS3Id, r.qresp);
        r.qresp = ''; //clear mapped local Data  
    } 
    r.qrespS3Id = mapM(r.qrespS3Id); //map the id anyway, regareless local or not
};

export const workMapMedias = (_work, mMap, addLocalMedias) => {
//console.log('workMapMedias()', _work.resp);
    const mSrcs = [];
    const drawBlobs = {};
    const mapM = src => { 
        src && mSrcs.push(src); 
        return (src && mMap[src]) || src;
    };
    const mapCK = ckhtml => {
        const [ckmedias, chhtmlOut] = mapTagAtt(ckhtml, 'oupid', mMap);
        mSrcs.push(...ckmedias);
        return chhtmlOut;
    };

    const work = deepCopy(_work);
    if (isAry(work?.resp) && work.resp.length > 0) {
        work.resp = toAry(work.resp).map((rr, idx) => {
            const respType = rr?.respType;
            if ([__RESP_TXT].includes(respType)) { // CK
                if (rr.qresp?.value) rr.qresp.value = mapCK(rr.qresp.value);
            }
            if ([__RESP_IMAGE, __RESP_FILE].includes(respType)) { // image, file
                if (isAry(rr.qresp) && rr.qresp.length > 0) {
                    rr.qresp.forEach(qq => {
                        qq.mediaId = mapM(qq.mediaId);
                    });
                };
            };
            if (respType === __RESP_DRAW) { // drawing
                mapDrawMedia(rr, mapM, drawBlobs); 
/*
                if (rr.qrespS3Id === '' ) rr.qrespS3Id = 'draw_'+idx+'_'+autoId()+'.draw'; // data Changed Assign a new Id
                if (toStr(rr.qrespS3Id).startsWith('draw_')){ // is Local Draw Id, 
                    if(!drawBlobs[rr.qrespS3Id]){ //always store the newest
                        const bb = str2Blob(rr?.qresp);
                        drawBlobs[rr.qrespS3Id] = {
                            file: bb,
                            size: bb.size,
                            name: rr.qrespS3Id,
                        };
                    }
                }
                rr.qrespS3Id = mapM(rr.qrespS3Id);
                if(mMap[rr.qrespS3Id]) rr.qresp = '';
*/
                _work.resp[idx] = {...rr}; //Hack...
            };
            if (rr.overlay) { // overlay draw
                if (work.WState === _WST_RESUME) {
                    mapDrawMedia(rr.overlay, mapM, drawBlobs);
                    _work.resp[idx].overlay = {...rr.overlay};
                } else {
                    _work.resp[idx].overlay = {};
                };
            };
            return rr;
        });
    };
    addLocalMedias(drawBlobs, 1);
//console.debug('workMapMedias ret', {mSrcs, mMap, wResp: work.resp, drawBlobs});
    return [work, toUniIdAry(mSrcs), drawBlobs];
};

/*
const saveDataToS3 = (str) => {
    return 'S3_ID';
};

if (rr?.respType === 'd' && rr?.qrespS3Id === '' ) { // drawing
    const dataToS3 = toStr(rr?.qresp);
    rr.qrespS3Id = saveDataToS3(dataToS3); // aysnc? save one by one ?
    rr.qresp = ''; // can clear draw data, as we got the S3 id now
};
*/  

/* data sample
    work.resp = [ 
        {
            "respType": "t",
            "qDone": 1,
            "qresp": {
            "value": "<p>hello</p>",
            "wcInfo": {
                "chars": 5,
                "words": 1
            }
            }
        },        
        {
            "respType": "f",
            "qDone": 1,
            "qresp": [
            {
                "mediaId": "",
                "size": "491B",
                "name": "metadata_simple_math.csv",
                "ext": "csv"
            }
            ]
        }, ...

      {
         "respType": "i",
         "qresp": [
            {
               "dl": "blob:http://localhost:3042/4b0bf60f-93bb-4743-9d91-a8caa3ac72e0",
               "name": "plantCell.jpg",
               "ext": "jpg",
               "file": {
                  "path": "plantCell.jpg"
               },
               "mediaId": "*LM_i424_plantCell.jpg",
               "size": 5520
            },
            {
               "dl": "blob:http://localhost:3042/738594f7-4e3c-48b4-bc37-1e72290e0985",
               "name": "plantCell.jpg",
               "ext": "jpg",
               "file": {
                  "path": "plantCell.jpg"
               },
               "mediaId": "*LM_i742_plantCell.jpg",
               "size": 5520
            }
         ],
         "qDone": 1
      },        
    ]
*/