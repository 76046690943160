import { objEntries, toAry, toFloat, toInt, toObj, toPercent1, toPercentF, toStr } from "../../../libs/libType";
import { strCmpTrimLow } from "../../EPAssign/TabTeacherAssignmentStatus";

export const toOverData1a = (overData1a_, clsNames) => {
  const clsSum = toObj(overData1a_);

  const data = objEntries(clsSum).map(([id, sum]) => {
      return { id, title: toStr(clsNames[id]), score: { percentage: toPercent1(sum.sumAvgP, sum.count * 100) } };
  });  
  data.sort((a, b) => strCmpTrimLow(a.title, b.title)); 
  const sum = { avgP:0 };
  data.forEach(d => { sum.avgP += toFloat(d.score.percentage); });
  const cntCls = data.length;
  const sumAvgP = toPercent1(sum.avgP, cntCls * 100); 
  
  const ret = { //SubjectOverviewByAllClassesTeacherData
      teacher: { overallScore: {percentage: sumAvgP }, data }
  };
  return ret;
};

export const toOverData1c = (toOverData1c_, stu, stuName) => {
  const sum = toObj(toOverData1c_);
  const stuOverDataA = { //SubjectOverviewByStudentSharedData
    student: { 
      id: stu, 
      title: stuName,
      attempted: [ 
        sum.p &&{ record: { value: sum.p, percentage: toPercentF(sum.p, sum.ttl) }, status: "p" },
        sum.f &&{ record: { value: sum.f, percentage: toPercentF(sum.f, sum.ttl) }, status: "f" },
        sum.a &&{ record: { value: sum.a, percentage: toPercentF(sum.a, sum.ttl) }, status: "a" },
        sum.n &&{ record: { value: sum.n, percentage: toPercentF(sum.n, sum.ttl) }, status: "n" },
      ].filter(r => r),
      overallScore: { percentage: toPercent1(sum.markP, sum.pfa*100) },
      averageScore: { percentage: toPercent1(sum.avgP, sum.pfa*100) }
    }
  };
  
  //return SubjectOverviewByStudentSharedData;
  return stuOverDataA;
};
  
  const SubjectOverviewByStudentSharedData = {
    "student": {
      "id": "s-001",
      "title": "Chan Tai Man",
      "attempted": [
        {
          "record": {
            "value": 8,
            "percentage": 53
          },
          "status": "p"
        },
        {
          "record": {
            "value": 3,
            "percentage": 20
          },
          "status": "f"
        },
        {
          "record": {
            "value": 3,
            "percentage": 20
          },
          "status": "a"
        },
        {
          "record": {
            "value": 1,
            "percentage": 7
          },
          "status": "n"
        }
      ],
      "overallScore": {
        "percentage": 78.3
      },
      "averageScore": {
        "percentage": 44.7
      }
    }
  }