import React, { useState, useEffect, useRef } from 'react';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import * as UI from '../../libs/libUI';
import { ReduxBind } from '../../saga/ReduxState';
import { useUILang } from '../utils/useUILang';
import DrawComponentV5 from '../qtnDo/CpQDoDrawToolsV5';
import { autoId } from '../AppUtil';
import { asyncDL, asyncGetDraw } from '../utils/useMediaCache';
import { byteToMB, validDrawSize } from '../utils/utilQtnAns';
import { popAlert } from '../components/CpPopup';
import { vLine, cavBtn } from './CpQEditorLBD';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { toStr, toAry } from '../../libs/libType';
//import { isCanvasBlank2 } from '../components/CpDrawingCanvas';

const cssClass = {
    'atview': ' atView', // at preview
    'atedit': ' atEdit', // at edit
    'teview': ' teView', // teacher preview
    'teedit': ' teEdit', // teacher edit
    //'stview':' stView', // student view result
    //'stedit':' stEdit'  // student take the test
};

const CpQEditorDraw = ReduxBind( props => {
    const { dispatch, isAT, Qpd, fullQ, setQ, setFullQ, afterInit, Q, QErrs, editable, setUpFlag,
        setOnAddMedia, mediaDLs, getMediaDLs, addLocalMedias, isTeacher, showEn} = props;
    const [ t ] = useUILang();
    //const mode = (editable ? 'atedit' : 'atview');
    //const [myClass, setMyClass] = useState(editable ? 'atEdit' : 'atView');
    const [qtnData, setQtnData] = useState(Q.qDrawData);
    const [doDrawReset, setDoDrawReset] = useState(0);
    const [drawResponse, setDrawResponse] = useState(null);

    const myMediaID =toStr(Q?.BGMediaID);
    const [myBG, setMyBG] = useState(null);

    const qData = Q?.qData || "";
    const qDrawS3Id = Q?.qDrawS3Id || 0;
    const drawInit = useRef(qDrawS3Id);
    const refCK = useRef(null);
    const updateQ = (func, d) => { func && func(d) };
    const myBGReady = myMediaID ? (myBG?1:0):1;
    const drawDataReady = myBGReady && (qDrawS3Id ? (qtnData ? 1:0):1);

    const LDMode = Q.LDMode?Q.LDMode:'ftw';
    const hasBG = myBG?1:0;
    //console.log('edit draw: drawDataReady, qDrawS3Id, qtnData:', drawDataReady, qDrawS3Id, qtnData?1:0);

    const updateQtnData = async () => {
        const qdata = await asyncGetDraw(dispatch, getMediaDLs, qDrawS3Id); 
        setQtnData(qdata);
    };
    
    //const updateResp = (v) => {updateQ(setQ("qDrawData"), v)};
    const [canvas, setCanvas] = useState(null);

    useEffect(()=>{
        if (myMediaID !== '' && getMediaDLs) getMediaDLs([myMediaID]);
    },[]);

    useEffect(()=>{ 
        if (mediaDLs && mediaDLs[myMediaID]) {
            setMyBG(mediaDLs[myMediaID].dl);
        };
    },[mediaDLs]); 

    useEffect(()=>{
        const qtnDataLoaded = qtnData?1:0;
        if (qDrawS3Id && !qtnDataLoaded) updateQtnData();
    },[qDrawS3Id]);

    useEffect(()=>{
        if (qtnData) {
            if (!drawInit.current) {
                /* todo */
                updateQ(setQ("qDrawData"), qtnData);
                updateQ(setQ("qDrawS3Id"), "");
            } else { drawInit.current = false; }
        };
    },[qtnData]);

    useEffect(()=>{ // local check
        if (drawResponse) {            
            const [isValid, drawSize] =  canvas ? validDrawSize(canvas,isTeacher?'te':'st'):[1,0];
            if (isValid) { 
                //setDrawResp(drawResponse);
                setQtnData(drawResponse);
                setDoDrawReset(0);
            } else { 
                //warning.warning_drawing_total_size_student
                //warning.warning_drawing_total_size_teacher
                //t('').replace("%s", string)
                setDoDrawReset(1);
                popAlert(dispatch, 1, t(isTeacher?'warning.warning_drawing_total_size_teacher'
                :'warning.warning_drawing_total_size_student').replace("%s", byteToMB(drawSize)));
            };    
        };
    },[drawResponse]);    

    const onEditorReady = (editor) => { };

    const ck_updateMediaID = (arr) => { return getMediaDLs([...arr]); };
    const ckImageConfig = {
        setOnAddMedia,
        updateMediaID: ck_updateMediaID,
    };
    const edProps = {isAT, mediaDLs, getMediaDLs, setOnAddMedia};
    const id = autoId();
    const myClass = cssClass[editable ? 'atedit' : 'atview'];

    const _setMyBG = (src) => {
        setMyBG(null);
        setTimeout(() => {
            setMyBG(src);
        }, 10);        
    };

    const doAddBG = (e) => { UI.stopEvent(e);
        if (setOnAddMedia) {
            setOnAddMedia({onAdd:(medias => {
                const ms = toAry(medias);
                
                if (ms.length > 0) {
                    const src = toStr(ms[0].dl);
                    const mediaId = toStr(ms[0].mediaId);
                    updateQ(setQ("BGMediaID"), mediaId);
                    if (src !== '') _setMyBG(src);
                };
                setOnAddMedia(0); //stop receiveMedia
            }),maxMedia:1, mimes:['image']});
        };
    }; 

    const doRemoveBG = (e) => {
        UI.stopEvent(e);
        updateQ(setQ("BGMediaID"), "");
        updateQ(setQ("LDMode"), 'ftw');
        setMyBG(null);
        
    };

    const setLDMode = (v) => {
        updateQ(setQ("LDMode"), v);
        const bg = myBG;
        // force canvas redraw
        setMyBG(null);
        setTimeout(() => {
            setMyBG(bg);
        }, 10);  
    };
    
    const exRes = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('link:',t("resource-link."+showEn?"en":"zh"));
        window.open(t("resource-link."+(showEn?"en":"zh")), "_blank");
    };

    return <>
        <div className={'tabQContent ' + (editable ? "" : "ReadOnly") + myClass}>          
            <Ckeditor5Base {...props} editorType="question" data={qData} setData={setQ("qData")}
                enable={editable} setEditor={refCK} debug={false} showStatus={true}
                onEditorReady={onEditorReady} noDragDrop={true} ckImageConfig={ckImageConfig}
                {...edProps} setUpFlag={setUpFlag}/>
            <div className='LDCavBtnCont'>
                <div className='LDCavBtnContent'>
                    {hasBG?cavBtn(t('remove-background'),IconList.canvasDrawing.trash, doRemoveBG,'LDToolBtn remove ','LDMiniTxt remove',true,'red')
                        :cavBtn(t('insert-background'),IconList.labelDiagram.image, doAddBG,'LDToolBtn add ','LDMiniTxt',true)}
                    {vLine()}
                    {cavBtn(t('fit-to-width'),IconList.labelDiagram.fitWidth, (e) => setLDMode('ftw'),
                        'LDToolBtn other ' + (LDMode==='ftw'?' sel ':''), 'LDMiniTxt', hasBG)}
                    {cavBtn(t('actual-size'),IconList.canvasDrawing.actualSize, (e) => setLDMode('fth'), 
                        'LDToolBtn other ' + (LDMode==='fth'?' sel ':''), 'LDMiniTxt', hasBG)}
                    {vLine()}
                    {cavBtn(t("add-external-resource"),IconList.canvasDrawing.link, exRes, 'LDToolBtn add ', 'LDAlwayTxt',true)}
                </div>
            </div>
            {drawDataReady?<DrawComponentV5 {...edProps} {...{dispatch, isTeacher, doEdit:1}} 
                setResponse={setDrawResponse} drawData={qtnData}
                doReset={doDrawReset} setDoReset={setDoDrawReset} setParentCanvas={setCanvas} 
                editQtn={1} BGImgID={myMediaID} BGImgSrc={myBG} LDMode={LDMode} bgBtn={0}
            />:<div>{'loading'}</div>}
        </div>
    </>;
});
export default CpQEditorDraw;
