import React from 'react';
import * as UI from '../../libs/libUI';
import { toStr } from '../../libs/libType';
import { humanFileSize } from '../../libs/libFormat';
import { svgIcon2 } from '../components/CpATIcoBtn';
import { asyncDL } from '../utils/useMediaCache';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import FileExtensionIcon from './CpFileExtensionIcon';
import { prefixURL } from '../ATExerEdit/ViewExCommon';

const AnsKeyContent = (props => {
    const {dispatch, AKText, AKFile, AKLink, edProps, getMediaDLs} = props;
    
    const downloadFile = (e, mediaId) => {
        UI.stopEvent(e);
        asyncDL(dispatch, getMediaDLs, mediaId);
    };

    //{svgIcon2('','file/extension','black','',{transform:'scale(1.2)'})}
    const blockFile = (kk, ii) => {
        return <div key={'bfile'+ii} className='kekFileItem2 f12 clickable'>
            <FileExtensionIcon name={kk.ext} myStyle={{width:'30px',minWidth:'30px'}}/>
            <div className='flexColStartFit' style={{marginLeft:'5px'}}
                onClick={(e)=>downloadFile(e,kk.mediaId)}>
                <div>{kk.name}</div><div>{humanFileSize(kk.size)}</div>
            </div>
        </div>;
    };

    
    return <div className='flexColStart'>
        {AKText.map((tt,ii)=> {return <QEditorReadOnly key={'hint'+ii} {...props} data={tt.text} {...edProps} />})}
        <div className='flexRowStart' style={{flexWrap:'wrap'}}>
            {AKFile.map((ff)=>{
                return ff.file.map((kk, ii)=>{
                    return blockFile(kk, ii);
                })
            })}
            {AKLink.map((kk, ii)=>{return blockLink(kk, ii);})}
        </div>
    </div>;
});
export default AnsKeyContent;

export const blockLink = (kk, ii) => {
    return <div key={'link'+ii} className='kekFileItem2 f12'> 
        <a target="_blank" href={prefixURL(toStr(kk.URL))} rel="noopener noreferrer"
            style={{marginLeft:'5px'}}><div className='flexRowStartFit'>{svgIcon2('file/link','black',{transform:'scale(1.2)'})}{kk.caption?kk.caption:toStr(kk.URL)}</div></a>
    </div>;
};

/*
export const prefixURL = (url) => {
    let result = url.trim();
    if (!result.startsWith('http://') && !result.startsWith('https://')) {
        result = 'https://'+result;
    };
    
    return result;
};
*/
