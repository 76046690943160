import { fileExt, humanFileSize } from "../libs/libFormat";
import { objEntries, objKeys, toInt, toStr } from "../libs/libType";

const _MB = 1;//1024 * 1000;
export const _MaxMediaSize = {
    at:{
        video: 100 * _MB,
        audio: 100 * _MB,
        image: 100 * _MB,
        pdf: 100 * _MB,
        office: 100 * _MB,
        drawing: 120 * _MB,
    },
    ep:{
        video: 80 * _MB,
        audio: 50 * _MB,
        image: 50 * _MB,
        pdf: 50 * _MB,
        office: 50 * _MB,
        drawing: 120 * _MB,
    }
};
export const maxMediaSizeByExt = (limits, isAT, ext) =>  maxMediaSizeByType(limits, isAT, mediaTypeByExt(ext));
export const maxMediaSizeByType = (limits, isAT, type) => {
    const pf = limits?.[isAT? 'at': 'ep'];
    return toInt(pf[type]);
};

const __ExtMime = {
    mp4:'video/mp4',
    mov:'video/mov',

    mp3:'audio/mp3',
    wav:'audio/wav',
    aac:'audio/aac',
    //m4a:'audio/x-m4a',
    
    jpeg:'image/jpeg',
    jpg:'image/jpg',
    png:'image/pmg',
    gif:'image/gif',
    heic:'image/heic',
    //svg:'image/svg',
    svg:'image/svg+xml',

    doc:'application/octet-stream',
    docx:'application/octet-stream',
    xls:'application/octet-stream',
    xlsx:'application/octet-stream',
    ppt:'application/octet-stream',
    pptx:'application/octet-stream',

    txt:'application/octet-stream',
    //csv:'application/octet-stream',

    pdf:'application/octet-stream',
    draw:'application/octet-stream',
};
export const extToMime = ext => ext && __ExtMime[ext];

export const __MediaExts = objKeys(__ExtMime);
export const __MediaTypes = {
    video: ['mp4', 'mov'],
    audio: ['mp3', 'wav', 'aac', 'm4a' ],
    image: ['jpeg', 'jpg', 'gif', 'png', 'svg', 'heic'],
    pdf: ['pdf'],
    office: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv'],
    drawing: ['draw'],    
};

export const mediaTypeByExt = (ext) => {
    const e = toStr(ext).toLowerCase();
    const t = objEntries(__MediaTypes).find(([type, exts]) => exts.includes(e)); 
    return (t && t[0]) || '';
};

export const validToUpload = (file, isAT, maxSizes) => {
    const name = toStr(file?.name);
    if(!name) return 'invalid file';
    const size = toInt(file?.size);
    if(!size) return name+' not allow empty file';
    const ext = fileExt(file?.name);
    const maxSize = maxMediaSizeByExt(maxSizes, isAT, ext);
    if(!maxSize) return name+' invalid file type';
    if(size > maxSize) return name+' exceed size limit: '+humanFileSize(maxSize);
    return '';
};
