import * as SQT from '../../consts/ATSysQType';
import { isAry, toStr } from "../../libs/libType";
import { __FIBA_EQU, __FIBT_TXT } from "../../consts/ATQtnAnsTypeFIB";
import { math3Str, calTxtLen, calMathLen } from "../../libs/libAnsCompare";
import { _ExCtType_Qtn } from '../../consts/ATValidateEcnts';
import { txtByteSize } from '../../libs/libType';
import { debugMode } from '../../saga/ReduxState';

// for qtn type fib, lbd only
    /* under Q
    "qAnswers": [
      {
         "qvalue": [
            {
               "data": "<p><math xmlns=\"http://www.w3.org/1998/Math/MathML\"><semantics><msqrt><mi>a</mi></msqrt><annotation encoding=\"application/vnd.wiris.mtweb-params+json\">{\"language\":\"en\",\"checkSyntax\":\"true\",\"cleancachegui\":\"true\"}</annotation></semantics></math></p>",
               "type": "EQU",
               "math3Str": ["...","...","..."]
            }
         ],
         "score": 1,
         "oupansid": 1,
         "qtype": "TXT",
         "atype": "EQU"
      },    

      qAnswers[].qvalue[].data
      qAnswers[].atype

    data = work.resp[idx].qresp['oupansid']
   "resp": [
      {
         "qresp": {
            "2": "hh",
            "3": 0
         },
         "math3Str":{
            "2":["...","...","..."],
            "3":["...","...","..."],
         },
         "qDone": 0
      },
    */
export const addMath3StrToAns = async (fullQ, Q) => {
    // direct add math str properties to qvalue
    if(!(fullQ || Q)) return;
    if(![SQT.__SYSQSubType_FIB,SQT.__SYSQSubType_LBT].includes(fullQ.SQType)) return;
    const qAns = Q.qAnswers;
    if (isAry(qAns)) {
        for ( let ii=0; ii < qAns.length; ii++) {
            const ans = qAns[ii];
            if (ans?.atype === __FIBA_EQU) {
                const qvals = ans?.qvalue;
                if (isAry(qvals)) {
                    for (let jj=0; jj < qvals.length; jj++ ) {
                        qvals[jj].math3Str = await math3Str(qvals[jj].data);
                    };
                };
            };
        };
    };
};

export const addMath3StrToFullQ = async (fullQ) => {
    if(!fullQ) return 0;
    if(![SQT.__SYSQSubType_FIB,SQT.__SYSQSubType_LBT].includes(fullQ.SQType)) return 0;

    if(fullQ.QLangEn) await addMath3StrToAns(fullQ, fullQ.QEn);
    if(fullQ.QLangCt) await addMath3StrToAns(fullQ, fullQ.QCt);
    return 1; // qtn need to convert math        
};

export const addWorkMath3Str = async (qtns, wresp, showEn) => {
    if ( !isAry(qtns) || !isAry(wresp) ) return;
    for (let idx = 0; idx < qtns.length; idx++) {
        const qtn = qtns[idx];          
        const Q = (qtn && (qtn.type === _ExCtType_Qtn))?(showEn?qtn.QEn:qtn.QCt):0;
        const fullQ = Q? (qtn || 0) : 0;
        if(!(fullQ && Q)) continue;
        if(![SQT.__SYSQSubType_FIB,SQT.__SYSQSubType_LBT].includes(fullQ.SQType)) continue;
        if (!wresp[idx]?.qresp) continue;
        const qAns = Q?.qAnswers;
        if (isAry(qAns)) {
            wresp[idx].math3Str = {};
            for ( let ii=0; ii < qAns.length; ii++) {
                const ans = qAns[ii];
                //if (ans?.atype !== __FIBA_EQU) break;
                if (ans?.atype === __FIBA_EQU) {
                    const oupansid = toStr(ans?.oupansid);
                    const tmp = wresp[idx].qresp[oupansid];
                    if (tmp) { wresp[idx].math3Str[oupansid] = await math3Str(wresp[idx].qresp[oupansid]); };
                };
            };
        };
    };
};

export const addMaxAnsWidthToFullQ = (fullQ) => {
    if ([SQT.__SYSQSubType_FIB].includes(fullQ?.SQType)) {
        addMathMaxAnsWidth(fullQ, fullQ?.QEn);
        addMathMaxAnsWidth(fullQ, fullQ?.QCt);
        return 1; // qtn need to calculate max ans width    
    };
    return 0;
};

export const addMathMaxAnsWidth = (fullQ, Q) => {
    if ( (fullQ?.SQType === SQT.__SYSQSubType_FIB) && Q) {
        const qAns = Q?.qAnswers;
        if (isAry(qAns)) {
            for ( let ii=0; ii < qAns.length; ii++) {
                const ans = qAns[ii];
                if (ans.qtype === __FIBT_TXT) {
                    const tFunc = (ans?.atype === __FIBA_EQU) ? calMathLen : calTxtLen;
                    const qvals = ans?.qvalue;
                    if (isAry(qvals)) {
                        let maxWidth = 0;
                        for (let jj=0; jj < qvals.length; jj++ ) {                        
                            const tmpW = tFunc(toStr(qvals[jj].data));
                            if (tmpW > maxWidth) maxWidth = tmpW;
                        };
                        ans.dWidth = maxWidth;
                    };
                };
            };
        };        
    };
};


/* 2mb = 2000000; 50mb = 50000000; */
// op resp draw & draw qtn
const checkDrawSize = {
    st: 120000 * 1024,
    te: 80000 * 1024,
    ov: 3000 * 1024,
    std: 50000 * 1024,
};

const drawSize = (cv) => {
    if (cv) {
        //return txtByteSize(JSON.stringify(canvas.toJSON(['selectable','evented']))); 
        return txtByteSize(JSON.stringify(cv.toJSON(['selectable', 'evented', 'transparentCorners', 'objectCaching', 'crossOrigin'])));
    } else { return 0; }
};

//export const validDrawSize = (cv, isTeacher) => {
export const validDrawSize = (cv, whoami) => {
    const ds = drawSize(cv);
    const checkSize = debugMode()?2048000:(checkDrawSize[whoami]);
    //const checkSize = debugMode()?5000:(checkDrawSize[whoami]);
    //console.log('ds:',ds);
    return [(ds <= checkSize), ds];
};

export const byteToMB = (size) => {
    //return toFix((size / 1024),2);
    return toFixNoTail0((size / 1024),2);
};

const toFixNoTail0 = (num, dec) => {
    return parseFloat(parseFloat(num).toFixed(dec))
};