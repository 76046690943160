// fill in the blank (FIB) score method vvvvvvvvvvvvvvvvvvvvvvvvvvvv
export const __FIBS_PBK = 'PBK'; //per blank
export const __FIBS_PQU = 'PQU'; //per question

export const __FIBSTypes = {
    [__FIBS_PBK]:'Per blank',
    [__FIBS_PQU]:'Per question',
};

const mapUIPrefix = 'at-setting.fib.';
export const __UIFIBSTypes = {
    [__FIBS_PBK]:mapUIPrefix+'per-blank',
    [__FIBS_PQU]:mapUIPrefix+'per-question',
};

export const __FIBScodes = [__FIBS_PBK, __FIBS_PQU];
export const __FIBScodeName = (SCCode) => __FIBSTypes[SCCode];
export const __UIFIBScodeName = (SCCode) => __UIFIBSTypes[SCCode];
// fill in the blank (FIB) score method ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

// fill in the blank (FIB) type of answer vvvvvvvvvvvvvvvvvvvvvvvvvvvv
export const __FIBT_TXT = 'TXT'; //per blank
export const __FIBT_DRD = 'DRD'; //per question

export const __FIBTTypes = {
    [__FIBT_TXT]:'answer_text_field',//'Text field',
    [__FIBT_DRD]:'answer_dropdown',//'Drop down',
};
export const __UIFIBTTypes = {
    [__FIBT_TXT]:mapUIPrefix+'blank',
    [__FIBT_DRD]:mapUIPrefix+'dropdown',
};
// svg icon path
export const __FIBTIcons = {
    [__FIBT_TXT]:'questionType/fillInTheBlanks',
    [__FIBT_DRD]:'presentation/assessmentMode',
};

export const __FIBTcodes = [__FIBT_TXT, __FIBT_DRD];
export const __FIBTcodeName = (ATCode) => __FIBTTypes[ATCode];
export const __UIFIBTcodeName = (ATCode) => __UIFIBTTypes[ATCode];
export const __FIBTcodeIcon = (ATCode) => __FIBTIcons[ATCode];
// fill in the blank (FIB) type of answer ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

// fill in the blank (FIB) answer input type vvvvvvvvvvvvvvvvvvvvvvvvvvvv
export const __FIBA_TXT = 'TXT'; //text ABC + 123 + chinese
export const __FIBA_NUM = 'NUM'; //number
export const __FIBA_EQU = 'EQU'; //equation
export const __FIBA_ADV = 'ADV'; //advanced, ABC + 123 + Equation

export const __FIBATypes = {
    [__FIBA_TXT]:'Text',
    [__FIBA_NUM]:'Number',
    [__FIBA_EQU]:'Equation',
    [__FIBA_ADV]:'Advanced',    
};
export const __UIFIBATypes = {
    [__FIBA_TXT]:'editor.text',
    [__FIBA_NUM]:'editor.number',
    [__FIBA_EQU]:'editor.equation',
    [__FIBA_ADV]:'editor.advanced',    
};

// svg icon path
export const __FIBAIcons = {
    [__FIBA_TXT]:'inputType/generalEditor',
    [__FIBA_NUM]:'inputType/numberEditor',
    [__FIBA_EQU]:'inputType/equationEditor',
    [__FIBA_ADV]:'inputType/maxEditor', 
};
//export const __FIBAcodes = [__FIBA_TXT, __FIBA_NUM, __FIBA_EQU, __FIBA_ADV];
export const __FIBAcodes = [__FIBA_TXT, __FIBA_NUM, __FIBA_EQU];
export const __FIBAcodeName = (AICode) => __FIBATypes[AICode];
export const __UIFIBAcodeName = (AICode) => __UIFIBATypes[AICode];
export const __FIBAcodeIcon = (AICode) => __FIBAIcons[AICode];
// fill in the blank (FIB) answer input type ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^