import React, { useState, useRef, useEffect } from 'react';
import * as UI from "../../libs/libUI";
import * as MCAC from '../../consts/ATQtnAnsTypeMCQ';
import * as FIBC from "../../consts/ATQtnAnsTypeFIB";
import { svgIcon2 } from '../components/CpATIcoBtn';
import { deepCopy } from '../AppUtil';
// 40 x 40  24 x 11  #959595 fonts 30 bold
// border: 1px solid #C6C6C6;  #0C8CE9 border-radius: 5px
// gap 10px
//const ddIcon = UI.imgSrc('svg/dragDropGrey.svg');
//import {ReactComponent as DDicon} from './ckeditor5/dragCusHorizontal.svg';
//const ddIcon = UI.imgSrc('static/icon/general/dragCusHorizontal.svg');
const ddDragImage = UI.imgSrc('svg/dragImage.svg');
//import '../css/UIcommon.css';
/*
export const DDOption = (props) => {
    return (
        <>{props.jsxElement}</>
    );
};
*/
// add blank area for drop, insert or swap when drop
const dragImage = () => {
    const img = new Image();
    img.crossorigin = "anonymous";
    img.src = ddDragImage;
    return img;
};

const _ansClass = {
    [MCAC.__MCAC_ACG]:'Grid', //grid
    [MCAC.__MCAC_ACL]:'List', //list
    [MCAC.__MCAC_ACB]:'Label', //label
    [FIBC.__FIBT_DRD]:'List', // fib, dropdown
};

// require async func to call this
const delay = ms => new Promise(resolve => {
    
    setTimeout(resolve, ms);
});

const DDAnswerContainer = (props) => {
    //const {sequence, setSequence, sel } = props;
    const { dataArr, setDataArr, getAnsData, customRender, sel, setOver, editable, ansChoice} = props;
    //const [selected, setSelected] = useState(sel);
    //const [sequence, setSequence] = useState(props.initArray);
    const [dragID, setDragID] = useState(undefined);
    //const dragIDRef = useRef(undefined);
    const [dragEleID, setDragEleID] = useState(undefined);
    const [overID, setOverID] = useState(undefined);
    const [dragImg, setDragImg] = useState(dragImage());
    //const [allowUpdate, setAllowUpdate] = useState(true);
    const allowUpdate = useRef(true);

    //const ansClass = _ansClass[ansChoice];
    const ansClass = ansChoice?(_ansClass[ansChoice]?_ansClass[ansChoice]:_ansClass[MCAC.__MCAC_ACG]):_ansClass[MCAC.__MCAC_ACG];

    
    //useEffect(() => {
        //setSelected(_selected ? _selected : '');
        //let tmp = [];
        //setSequence(tmp);     
    //}, []);

    /*
    useEffect(() => {
        setSelected(props.selected ? props.selected : '');
    }, [props.selected]);
    */

    const myRS = (ele, ii) => {
        //const show = (dragID !== undefined) && dragID !== ii && ansChoice === MCAC.__MCAC_ACL;
        const info = overID ? overID.split(','):["",""];
        const checkOverID = overID ? parseInt(info[0]) : undefined;
        const checkOver = (checkOverID === ii) && (info[1]!=="extra");
        
        return <>
            <div id={ii+",DDtop"} className="flexColCenter" onDragOver={onDragOver} onDragLeave={onDragLeave}
                    onDragStart={onDragStart} onDrop={onDrop} onDragEnd={onDragEnd}>
                {editable && (ansChoice !== MCAC.__MCAC_ACB) && <div id={ii+",DDIcon"} className={"grabbable DDIconContainer" + (checkOver?" Over":"")}
                    onDragOver={onDragOver} draggable={true}>
                    {svgIcon2("general/dragCusHorizontal","white")}
                </div>}
                {ele}
                {(dragID !== undefined) && <div id={ii+",DDover"} onDragOver={onDragOver2} className={dragID===ii?"DDBlockDisplay":"DDOverDisplay"}></div>}
            </div>            
        </>        
    };
    //{(dragID===ii || overID)?dragDisplay(ele, ii, checkOver):ele}
    //{(dragID !== undefined)?dragDisplay(ele, ii):ele}
    const optionContainer = (optionElement, ii) => {
        // the second element used to mask the original dragging element
        // set onDragOver(but do nothing) make the cursor show "allow drop"
        // {(id === dragID) && <div onDragOver={onDragOver2} className="DDBlockDisplay"></div>}
        const show = (dragID !== undefined) && dragID !== ii
                && (ansChoice === MCAC.__MCAC_ACL || ansChoice === FIBC.__FIBT_DRD);
        //const show = (dragID !== undefined) && ansChoice === MCAC.__MCAC_ACL;
        //<React.Fragment key={"ddoc"+ii}>
        return <div key={"ddoc"+ii} className={"DDoptionContainer " + ansClass} style={{ marginBottom: dragID !== undefined?"0px":"10px"}}>
            {optionElement}
            {show && <div id={ii+",dropArea"} onDragOver={onDragOver} onDrop={onDrop} 
            onDragLeave={onDragLeave} className="DDAreaForDrop"></div>}
        </div>;
    };
/*
<div key={"ddoc"+ii} className={"DDoptionContainer " + ansClass} style={{ marginBottom: dragID !== undefined?"0px":"10px"}}>
                {optionElement}
                {show && <div id={ii+",dropArea"} onDragOver={onDragOver} onDrop={onDrop} 
                    onDragLeave={onDragLeave} className="DDAreaForDrop"></div>}
            </div>;
*/
    /*
        <React.Fragment key={"ddoc"+ii}>
            {false && show && (dragID !== (ii - 1)) && extraBar(ii)}
            <div className={"DDoptionContainer " + ansClass} style={{ marginBottom: dragID !== undefined?"0px":"10px"}}>
                {optionElement}
                {show && <div id={ii+",dropArea"} onDragOver={onDragOver} onDrop={onDrop} 
                    onDragLeave={onDragLeave} className="DDAreaForDrop"></div>}
            </div>
            {false && show && (ii === (dataArr.length - 1)) && (ii !== dragID) && extraBar(ii+1)}
            </React.Fragment>;    
    */

    // if change extra bar height, remember to update block area drop area css top value too
    const extraBar = (ii) => {
        const cid = ii + ",extra";
        return <div id={cid} className={"DDextraBar" + (cid === overID?" Over":"")}
            onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}></div>
    };

    /*
    const dragDisplay = (ele, ii) => {
        return <div id={ii+",DDover1"} className="dragStyle">
            {ele}
            <div id={ii+",DDover2"} onDragOver={onDragOver2} className={dragID===ii?"DDBlockDisplay":"DDOverDisplay"}></div>
        </div>;
    };
    */

    const onHandleClick = (e, value) => {
        //if (value === selected) return;
        const { onClick } = props;
        if (typeof onClick === 'function') {
            onClick(e, value);
        };
        //setSelected(value ? value : '');
    };

    const onDragEnd = (ev) => {
        
        ev.preventDefault();
        if (dragID !== undefined) setDragID(undefined);
        if (dragEleID !== undefined) setDragEleID(undefined);
        if (overID !== undefined) setOverID(undefined);
        setOver && setOver(-1);
        
    };

    const onDragOver2 = (ev) => {
        
        ev.preventDefault();
    };

    const onDragOver_new = (ev) => {
        
        ev.preventDefault();
        const tid = ev.target.id;
        
        if (dragID === undefined) return;
        if (tid && (tid !== overID) && allowUpdate.current) {
            allowUpdate.current = false;
            
            const info = tid.split(',');
            setOverID(tid);
            setOver && setOver(info[0]);

            const toIndex = parseInt(info[0]);
            const fromIndex = parseInt(dragID);
                
            let fData = dataArr[fromIndex];
            // only do insert now
            // if (info[1] === "extra") { // insert  
                
            if (toIndex !== fromIndex) {
                if (toIndex > fromIndex) { // insert after, then delete
                    
                    if (toIndex === dataArr.length) dataArr.push({...fData});
                    else dataArr.splice(toIndex+1, 0, {...fData});
                    dataArr.splice(fromIndex, 1);
                } else { // delete then insert before
                    
                    dataArr.splice(fromIndex, 1);
                    dataArr.splice(toIndex, 0, {...fData});
                    
                };
                setDragID(toIndex);
                setDataArr && setDataArr([...dataArr]);
            };
            //setTimeout(()=>{setAllowUpdate(true)}, 10); // give time for dragID and dataArr update
            setTimeout(()=>{
                
                allowUpdate.current=true;}, 10); // give time for dragID and dataArr update
        };
    };
    
    const onDragOver = (ev) => {
        
        ev.preventDefault();
        const tid = ev.target.id;
        
        if (tid !== overID) {
            const info = tid.split(',');
            setOverID(tid);
            setOver && setOver(info[1]==="extra"?"-2":info[0]);
        };
    };

    const onDragLeave = (ev) => {
        
        ev.preventDefault();
        setOverID(undefined);
        setOver && setOver(-1);
    };

    const onDragStart = (ev) => {
        
        //ev.dataTransfer.setDragImage(dragImg, 60, 2);
        
        //ev.dataTransfer.setData("text", ev.target.id);
        const tmp = ev.target.id.split(',');
        // time delay require for fixing safari drag drop bug
        setTimeout(() => {
            setDragEleID(ev.target.id);
            setDragID(parseInt(tmp[0]));
        }, 10);
    };

    const onDrop_new = (ev) => {
        ev.preventDefault();
        //const ele = document.getElementById(ev.target.id).getBoundingClientRect(); // x , y, width, height
        // ** clientX - x, clientY - y = mouse within drop area coordinates
        //checkPos(ev.clientX, ev.clientY, ele.x, ele.y, ele.width, ele.height);
        setDragID(undefined);
        setOverID(undefined);
        setOver && setOver(-1);
        allowUpdate.current = true;
        
        //setDataArr && setDataArr([...dataArr]);
    };


    const onDrop = (ev) => {
        ev.preventDefault();
        //const ele = document.getElementById(ev.target.id).getBoundingClientRect(); // x , y, width, height
        // ** clientX - x, clientY - y = mouse within drop area coordinates
        //checkPos(ev.clientX, ev.clientY, ele.x, ele.y, ele.width, ele.height);

        if (dragID >= 0) {
            const info = ev.target.id.split(',');
            const toIndex = parseInt(info[0]);
            const fromIndex = parseInt(dragID);
            if (ev.target.id === "" || ev.target.id === undefined ||
                (fromIndex === toIndex && info[1] !== "extra")) return;
            //const fromIndex = sequence.findIndex((id) => { return id === dragID });
            

            let fData = dataArr[fromIndex];
            // only do insert now
            // if (info[1] === "extra") { // insert  
                
                if (toIndex > fromIndex) { // insert then delete
                    
                    
                    if (toIndex === dataArr.length) dataArr.push({...fData});
                    else dataArr.splice(toIndex, 0, {...fData});
                    
                    dataArr.splice(fromIndex, 1);
                } else { // delete then insert
                    
                    dataArr.splice(fromIndex, 1);
                    dataArr.splice(toIndex, 0, {...fData});
                };
            /*
            } else { //swap
                
                const tmp = dataArr[fromIndex];
                dataArr[fromIndex] = dataArr[toIndex];
                dataArr[toIndex] = tmp;
            };
            */
        };

        setDragID(undefined);
        setOverID(undefined);
        setOver && setOver(-1);
        
        //setDataArr && setDataArr([...dataArr]);
    };

    const MyChildren = () => {
        return dataArr.map((obj, ii) => {
            //const sel = (obj.correct);
            return optionContainer(myRS(customRender(obj,ii), ii), ii);
            //else return optionContainer(myRO(customRender(obj,ii), ii), ii);
        });
    };

    
    return <div className={"DDTopContainer " + ansClass + (editable?"":" unclickable")}>
        {MyChildren()}
    </div>;
};

export default DDAnswerContainer;