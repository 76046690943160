import {memo, useMemo} from "react";
import {Button, ButtonProps} from "react-bootstrap";
//import {SIZE, Size} from "../../../../../../type/Size";
//import {ALIGN, Align} from "../../../../../../type/Align";
//import {PREFIX} from "../../../../../../type/Prefix";
import { SIZE, ALIGN } from "./CpExPFConst";
import { PREFIX } from "./CpPrefix";

const ActionButtonComponent = (props) => { // from poc ActionButtonComponent.tsx
    const {
        children,
        size = SIZE.MD,
        icon = "",
        iconAlign = ALIGN.START,
        animation = false,
        variant = "",
        title = "",
        breakpoint = "",
        className = "",
        truncate = true,
        gap = 2,
        ...buttonProps
    } = props;

    const customClassName = useMemo(() => {
        const breakpointStyle = breakpoint ? PREFIX.BTN_BREAKPOINT + breakpoint : "";
        const alignStyle = iconAlign !== ALIGN.START ? "flex-row-reverse" : "";
        const animationStyle = animation ? "animate" : "";
        const gapStyle = (size === SIZE.XS || size === SIZE.SM || size === SIZE.MD) ? `gap-${gap}` : `gap-${gap}`;
        const truncateStyle = truncate ? `mw-100 overflow-hidden` : "flex-nowrap";

        return [
            `d-flex`,
            `justify-content-center`,
            `align-items-center`,
            gapStyle,
            truncateStyle,
            PREFIX.BTN + size,
            breakpointStyle,
            alignStyle,
            animationStyle,
            className].filter(item => item).join(' ');
    }, []);

    return <Button className={customClassName}
                   variant={variant} {...buttonProps}>
        {icon}
        {title !== "" &&
            <span className={`semi-bold text ${truncate ? "text-truncate" : ""}`}>{title}</span>}
        {children}
    </Button>;
};

export default memo(ActionButtonComponent);