import React, {useState} from "react";
import {SORT_ASC, SORT_DESC} from "../../EPReport/ChartTypeConst";
import CpTips from "./CpTips";
import { CpSortingIcon } from "../../EPReport/TabPerformanceDetailChart";

const CpTableHeaderSortingButton = (props) => { // base on poc TableHeaderSortingButton
    const {title, type, tips, sorting = true} = props
    const [sort, setSort] = useState({type, direction: ""})

    const handleSorting = () => {
        setSort(prev => ({...prev, direction: prev.direction !== SORT_DESC ? SORT_DESC : SORT_ASC}))
    };

    return <span role={sorting ? "button" : "none"}
                 className="d-flex justify-content-center align-items-center gap-2 user-select-none"
                 onClick={() => handleSorting()}>
        {title}
        {tips !== undefined && <CpTips>{tips}</CpTips>}
        {sorting === true && <CpSortingIcon type={type} sort={sort}/>}
    </span>
};

export default CpTableHeaderSortingButton;