import { useEffect, useMemo, useState } from 'react';
import { toUniIdAry } from '../../consts/ATValidate';

//import TabQMC from './TabQMC';
import { apiCallLoad_ } from '../../libs/awsFuncs';
import { isObj, toAry, toObj } from '../../libs/libType';


export const addIfMiss = (a, miss) => {
    const aa = toAry(a);
    const realmiss = toAry(miss).filter(m => m && !aa.includes(m));
    return realmiss.length? Array.from(new Set([...aa, ...realmiss])): a;
}
const _append = (olds, news, newIds) => {
    const newbyIds = Object.fromEntries(newIds.map(id => [id, {}] ));
    const fulls = { ...newbyIds, ...olds, ...news }; 
    return fulls;
};
export const useTagMSetCache = (dispatch) => {
    const [que, setQue] = useState([]); //que for next batch load
    const [load, setLoad] = useState(0); //loading flag
    const [tagMSets, setTagMSets] = useState({}); //loaded cache store

    const _queMiss = (miss) => {
		const missStr = miss.sort().join('|');
        useEffect(() => {
//console.log('_queMiss', {missStr, miss});
            if(miss.length) setQue(q => {
                const realMiss = miss.filter(m => !q.includes(m));
//console.log('_queMiss setQue', {missStr, miss, q});
                return realMiss.length? [ ...q, ...realMiss]: q;
            });
        }, [missStr]);
	};
	
	const useTagMSets = ids => {
		const hits = Object.fromEntries(ids.map(id => [id, tagMSets[id]]).filter(([k, v]) => v));
        const miss = toUniIdAry(ids.filter(id => (!hits[id])).filter(id => !que.includes(id)));
		_queMiss(miss);
		const idStr = toAry(ids).sort().join('|');
//console.log('useTagMSets', {hits, ids, idStr, miss, });
		return useMemo(() => hits, [idStr, tagMSets]); //return last update memo
    };
	
    const onLoad = (res, err, que) => {
        if(isObj(res)){
            const loads = toObj(res?.tagMSets);
            setTagMSets(olds => _append(olds, loads, que));
            const loaded = [...que, ...Object.keys({...tagMSets, ...loads})];
            setQue(qu => toAry(qu).filter(qid => !loaded.includes(qid)));
        }
        setLoad(0);
    };
    
    useEffect(()=>{
        if((!load) && que.length){
            setLoad(1);
            apiCallLoad_(dispatch, '/getTagMSets', (res, err) => onLoad(res, err, que), { metaSetIds:toUniIdAry(que)});
        };
    }, [toUniIdAry(que).sort().join(','), load]); //todo use que
    
    return [tagMSets, useTagMSets];
};


const fakes = () => (
{
	"tagMSets": {
		"rs_q_diff": {
			"MSType": "di",
			"MSNameEn": "Question difficulty",
			"dateAdd": 1677226821183,
			"MSLangCt": 1,
			"doReport": 0,
			"modUid": "2YveiVo81QvUZCgy1C6ZBr",
			"MSLangEn": 1,
			"drpu": "P",
			"metaSetId": "rs_q_diff",
			"MSNameCt": "題目難度",
			"addUid": "2YveiVo81QvUZCgy1C6ZBr",
			"heads": [
				{
					"LVNum": "Index - Level 1",
					"nameEn": "Difficulty",
					"nameCt": "難度",
					"metaId": "ID - Level 1"
				}
			],
			"dateMod": 1677228254512,
			"tree2": {
				"DI_EASY": {},
				"DI_HARD": {},
				"DI_MEDIUM": {}
			},
			"metas": {
				"DI_EASY": {
					"metaSetId": "rs_q_diff",
					"nameEn": "Easy",
					"rnum": 2,
					"nameCt": "容易",
					"pub": true
				},
				"DI_HARD": {
					"metaSetId": "rs_q_diff",
					"nameEn": "Hard",
					"rnum": 4,
					"nameCt": "困難",
					"pub": true
				},
				"DI_MEDIUM": {
					"metaSetId": "rs_q_diff",
					"nameEn": "Medium",
					"rnum": 3,
					"nameCt": "中等",
					"pub": true
				}
			}
		},
		"rs_junsci23": {
			"MSType": "tb",
			"MSReports": "subjects, SCII",
			"MSNameEn": "Junior Secondary Science Mastering Concepts and Skills_2023",
			"dateAdd": 1710082747890,
			"MSLangCt": 1,
			"doReport": 1,
			"modUid": "RpnDDuUBRNsrSzoPKmsY1y",
			"MSLangEn": 1,
			"drpu": "P",
			"metaSetId": "rs_junsci23",
			"MSNameCt": "初中科學基礎概念與技能_2023",
			"addUid": "RpnDDuUBRNsrSzoPKmsY1y",
			"heads": [
				{
					"LVNum": "Index - Level 1",
					"nameEn": "Series",
					"nameCt": "系列",
					"metaId": "ID - Level 1"
				},
				{
					"LVNum": "Index - Level 2",
					"nameEn": "Book",
					"nameCt": "冊次",
					"metaId": "ID - Level 2"
				},
				{
					"LVNum": "Index - Level 3",
					"nameEn": "Unit",
					"nameCt": "課次",
					"metaId": "ID - Level 3"
				},
				{
					"LVNum": "Index - Level 4",
					"nameEn": "Section",
					"nameCt": "章節",
					"metaId": "ID - Level 4"
				}
			],
			"dateMod": 1710082747890,
			"tree2": {
				"JUNSCI": {
					"JUNSCI_1B": {
						"JUNSCI_1B_1": {}
					},
					"JUNSCI_1A": {
						"JUNSCI_1A_1": {
							"JUNSCI_1A_1_1.4": {},
							"JUNSCI_1A_1_1.3": {},
							"JUNSCI_1A_1_1.2": {},
							"JUNSCI_1A_1_1.1": {}
						},
						"JUNSCI_1A_2": {
							"JUNSCI_1A_2_2.5": {},
							"JUNSCI_1A_2_2.6": {},
							"JUNSCI_1A_2_2.3": {},
							"JUNSCI_1A_2_2.4": {},
							"JUNSCI_1A_2_2.1": {},
							"JUNSCI_1A_2_2.2": {}
						},
						"JUNSCI_1A_3": {
							"JUNSCI_1A_3_3.2": {},
							"JUNSCI_1A_3_3.3": {},
							"JUNSCI_1A_3_3.4": {}
						}
					}
				}
			},
			"metas": {
				"JUNSCI_1A_1_1.1": {
					"nameEn": "Section 1.1 Learning about science",
					"rnum": 5,
					"pMeta": "JUNSCI_1A_1",
					"nameCt": "章節 1.1 學習科學",
					"metaSetId": "rs_junsci23",
					"pub": 0
				},
				"JUNSCI_1A_1_1.2": {
					"nameEn": "Section 1.2 Practice of science",
					"rnum": 6,
					"pMeta": "JUNSCI_1A_1",
					"nameCt": "章節 1.2 科學的實踐",
					"metaSetId": "rs_junsci23",
					"pub": 0
				},
				"JUNSCI_1A_1_1.3": {
					"nameEn": "Section 1.3 Safety in the laboratory",
					"rnum": 7,
					"pMeta": "JUNSCI_1A_1",
					"nameCt": "章節 1.3 實驗室安全",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_1_1.4": {
					"nameEn": "Section 1.4 Laboratory apparatus and basic practical skills",
					"rnum": 8,
					"pMeta": "JUNSCI_1A_1",
					"nameCt": "章節 1.4 實驗室儀器及基本實驗技巧",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_1": {
					"nameEn": "Unit 1 Introducing science",
					"rnum": 4,
					"pMeta": "JUNSCI_1A",
					"nameCt": "課次 1 科學入門",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_2_2.1": {
					"nameEn": "Section 2.1 Change in states of water",
					"rnum": 10,
					"pMeta": "JUNSCI_1A_2",
					"nameCt": "章節 2.1 水的物態變化",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_2_2.2": {
					"nameEn": "Section 2.2 Water cycle",
					"rnum": 11,
					"pMeta": "JUNSCI_1A_2",
					"nameCt": "章節 2.2 水循環",
					"metaSetId": "rs_junsci23",
					"pub": 0
				},
				"JUNSCI_1A_2_2.3": {
					"nameEn": "Section 2.3 Dissolving",
					"rnum": 12,
					"pMeta": "JUNSCI_1A_2",
					"nameCt": "章節 2.3 溶解",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_2_2.4": {
					"nameEn": "Section 2.4 Water purification",
					"rnum": 13,
					"pMeta": "JUNSCI_1A_2",
					"nameCt": "章節 2.4 水的淨化",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_2_2.5": {
					"nameEn": "Section 2.5 Further treatment of drinking water",
					"rnum": 14,
					"pMeta": "JUNSCI_1A_2",
					"nameCt": "章節 2.5 食水的進一步處理",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_2_2.6": {
					"nameEn": "Section 2.6 Water conservation and pollution",
					"rnum": 15,
					"pMeta": "JUNSCI_1A_2",
					"nameCt": "章節 2.6 節約用水和水污染",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_2": {
					"metaSetId": "rs_junsci23",
					"nameEn": "Water",
					"rnum": 9,
					"LVNum": "2",
					"pMeta": "JUNSCI_1A",
					"nameCt": "水",
					"pub": 1
				},
				"JUNSCI_1A_3_3.2": {
					"nameEn": "Section 3.2 Grouping of living things",
					"rnum": 17,
					"pMeta": "JUNSCI_1A_3",
					"nameCt": "章節 3.2 生物的分類",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_3_3.3": {
					"nameEn": "Section 3.3 Identification key",
					"rnum": 18,
					"pMeta": "JUNSCI_1A_3",
					"nameCt": "章節 3.3 檢索表",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_3_3.4": {
					"nameEn": "Section 3.4 Biodiversity",
					"rnum": 19,
					"pMeta": "JUNSCI_1A_3",
					"nameCt": "章節 3.4 生物多樣性",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A_3": {
					"nameEn": "Unit 3 Looking at living things",
					"rnum": 16,
					"pMeta": "JUNSCI_1A",
					"nameCt": "課次 3 觀察生物",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1A": {
					"nameEn": "Book 1A",
					"rnum": 3,
					"pMeta": "JUNSCI",
					"nameCt": "課本 1A",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI_1B_1": {
					"metaSetId": "rs_junsci23",
					"nameEn": "Introducing science_1B",
					"rnum": 21,
					"LVNum": "1",
					"pMeta": "JUNSCI_1B",
					"nameCt": "科學入門_1B",
					"pub": 1
				},
				"JUNSCI_1B": {
					"nameEn": "Book 1B",
					"rnum": 20,
					"pMeta": "JUNSCI",
					"nameCt": "課本 1B",
					"metaSetId": "rs_junsci23",
					"pub": 1
				},
				"JUNSCI": {
					"nameEn": "Junior Secondary Science Mastering Concepts and Skills",
					"rnum": 2,
					"nameCt": "初中科學基礎概念與技能",
					"metaSetId": "rs_junsci23",
					"pub": 1
				}
			}
		},
		"rs_grade": {
			"MSType": "gr",
			"MSNameEn": "Grade",
			"dateAdd": 1677229453291,
			"MSLangCt": 1,
			"doReport": 0,
			"modUid": "2YveiVo81QvUZCgy1C6ZBr",
			"MSLangEn": 1,
			"drpu": "P",
			"metaSetId": "rs_grade",
			"MSNameCt": "年級",
			"addUid": "2YveiVo81QvUZCgy1C6ZBr",
			"heads": [
				{
					"LVNum": "Index - Level 1",
					"nameEn": "School",
					"nameCt": "學校",
					"metaId": "ID - Level 1"
				},
				{
					"LVNum": "Index - Level 2",
					"nameEn": "Key Stage",
					"nameCt": "學習階段",
					"metaId": "ID - Level 2"
				},
				{
					"LVNum": "Index - Level 3",
					"nameEn": "Level",
					"nameCt": "程度",
					"metaId": "ID - Level 3"
				},
				{
					"LVNum": "Index - Level 4",
					"nameEn": "Grade",
					"nameCt": "年級",
					"metaId": "ID - Level 4"
				}
			],
			"dateMod": 1678346788335,
			"tree2": {
				"SEC": {
					"SEC_KS3": {
						"SEC_KS3_JunSec": {
							"SEC_KS3_JunSec_S1": {},
							"SEC_KS3_JunSec_S3": {},
							"SEC_KS3_JunSec_S2": {}
						}
					},
					"SEC_KS4": {
						"SEC_KS4_SenSec": {
							"SEC_KS3_SenSec_S5": {},
							"SEC_KS3_SenSec_S6": {},
							"SEC_KS3_SenSec_S4": {}
						}
					}
				},
				"KIN": {
					"KIN_ECE": {
						"KIN_ECE_K2": {},
						"KIN_ECE_K3": {},
						"KIN_ECE_N": {},
						"KIN_ECE_K1": {}
					}
				},
				"PRI": {
					"PRI_KS1": {
						"PRI_KS1_JunPri": {
							"PRI_KS1_JunPri_P1": {},
							"PRI_KS1_JunPri_P2": {},
							"PRI_KS1_JunPri_P3": {}
						}
					},
					"PRI_KS2": {
						"PRI_KS2_SenPri": {
							"PRI_KS2_SenPri_P5": {},
							"PRI_KS2_SenPri_P6": {},
							"PRI_KS2_SenPri_P4": {}
						}
					}
				}
			},
			"metas": {
				"KIN_ECE_K1": {
					"metaSetId": "rs_grade",
					"nameEn": "K1",
					"rnum": 5,
					"pMeta": "KIN_ECE",
					"nameCt": "幼兒班",
					"pub": true
				},
				"KIN_ECE_K2": {
					"metaSetId": "rs_grade",
					"nameEn": "K2",
					"rnum": 6,
					"pMeta": "KIN_ECE",
					"nameCt": "低班",
					"pub": true
				},
				"KIN_ECE_K3": {
					"metaSetId": "rs_grade",
					"nameEn": "K3",
					"rnum": 7,
					"pMeta": "KIN_ECE",
					"nameCt": "高班",
					"pub": true
				},
				"KIN_ECE_N": {
					"metaSetId": "rs_grade",
					"nameEn": "Nursery Class",
					"rnum": 4,
					"pMeta": "KIN_ECE",
					"nameCt": "學前班",
					"pub": true
				},
				"KIN_ECE": {
					"metaSetId": "rs_grade",
					"nameEn": "PreSchool",
					"rnum": 3,
					"LVNum": "ECE",
					"pMeta": "KIN",
					"nameCt": "學前教育",
					"pub": true
				},
				"KIN": {
					"metaSetId": "rs_grade",
					"nameEn": "Kingergarten",
					"rnum": 2,
					"nameCt": "幼稚園",
					"pub": true
				},
				"PRI_KS1_JunPri_P1": {
					"metaSetId": "rs_grade",
					"nameEn": "P1",
					"rnum": 11,
					"pMeta": "PRI_KS1_JunPri",
					"nameCt": "小一",
					"pub": true
				},
				"PRI_KS1_JunPri_P2": {
					"metaSetId": "rs_grade",
					"nameEn": "P2",
					"rnum": 12,
					"pMeta": "PRI_KS1_JunPri",
					"nameCt": "小二",
					"pub": true
				},
				"PRI_KS1_JunPri_P3": {
					"metaSetId": "rs_grade",
					"nameEn": "P3",
					"rnum": 13,
					"pMeta": "PRI_KS1_JunPri",
					"nameCt": "小三",
					"pub": true
				},
				"PRI_KS1_JunPri": {
					"metaSetId": "rs_grade",
					"nameEn": "Junior Primary",
					"rnum": 10,
					"pMeta": "PRI_KS1",
					"nameCt": "初小",
					"pub": true
				},
				"PRI_KS1": {
					"metaSetId": "rs_grade",
					"nameEn": "Key Stage 1",
					"rnum": 9,
					"LVNum": "KS1",
					"pMeta": "PRI",
					"nameCt": "第一學習階段",
					"pub": true
				},
				"PRI_KS2_SenPri_P4": {
					"metaSetId": "rs_grade",
					"nameEn": "P4",
					"rnum": 16,
					"pMeta": "PRI_KS2_SenPri",
					"nameCt": "小四",
					"pub": true
				},
				"PRI_KS2_SenPri_P5": {
					"metaSetId": "rs_grade",
					"nameEn": "P5",
					"rnum": 17,
					"pMeta": "PRI_KS2_SenPri",
					"nameCt": "小五",
					"pub": true
				},
				"PRI_KS2_SenPri_P6": {
					"metaSetId": "rs_grade",
					"nameEn": "P6",
					"rnum": 18,
					"pMeta": "PRI_KS2_SenPri",
					"nameCt": "小六",
					"pub": true
				},
				"PRI_KS2_SenPri": {
					"metaSetId": "rs_grade",
					"nameEn": "Senior Primary",
					"rnum": 15,
					"pMeta": "PRI_KS2",
					"nameCt": "高小",
					"pub": true
				},
				"PRI_KS2": {
					"metaSetId": "rs_grade",
					"nameEn": "Key Stage 2",
					"rnum": 14,
					"LVNum": "KS2",
					"pMeta": "PRI",
					"nameCt": "第二學習階段",
					"pub": true
				},
				"PRI": {
					"metaSetId": "rs_grade",
					"nameEn": "Primary School",
					"rnum": 8,
					"nameCt": "小學",
					"pub": true
				},
				"SEC_KS3_JunSec_S1": {
					"metaSetId": "rs_grade",
					"nameEn": "S1",
					"rnum": 22,
					"pMeta": "SEC_KS3_JunSec",
					"nameCt": "中一",
					"pub": true
				},
				"SEC_KS3_JunSec_S2": {
					"metaSetId": "rs_grade",
					"nameEn": "S2",
					"rnum": 23,
					"pMeta": "SEC_KS3_JunSec",
					"nameCt": "中二",
					"pub": true
				},
				"SEC_KS3_JunSec_S3": {
					"metaSetId": "rs_grade",
					"nameEn": "S3",
					"rnum": 24,
					"pMeta": "SEC_KS3_JunSec",
					"nameCt": "中三",
					"pub": true
				},
				"SEC_KS3_JunSec": {
					"metaSetId": "rs_grade",
					"nameEn": "Junior Secondary",
					"rnum": 21,
					"pMeta": "SEC_KS3",
					"nameCt": "初中",
					"pub": true
				},
				"SEC_KS3_SenSec_S4": {
					"metaSetId": "rs_grade",
					"nameEn": "S4",
					"rnum": 27,
					"pMeta": "SEC_KS4_SenSec",
					"nameCt": "中四",
					"pub": true
				},
				"SEC_KS3_SenSec_S5": {
					"metaSetId": "rs_grade",
					"nameEn": "S5",
					"rnum": 28,
					"pMeta": "SEC_KS4_SenSec",
					"nameCt": "中五",
					"pub": true
				},
				"SEC_KS3_SenSec_S6": {
					"metaSetId": "rs_grade",
					"nameEn": "S6",
					"rnum": 29,
					"pMeta": "SEC_KS4_SenSec",
					"nameCt": "中六",
					"pub": true
				},
				"SEC_KS3": {
					"metaSetId": "rs_grade",
					"nameEn": "Key Stage 3",
					"rnum": 20,
					"LVNum": "KS3",
					"pMeta": "SEC",
					"nameCt": "第三學習階段",
					"pub": true
				},
				"SEC_KS4_SenSec": {
					"metaSetId": "rs_grade",
					"nameEn": "Senior Secondary",
					"rnum": 26,
					"pMeta": "SEC_KS4",
					"nameCt": "高中",
					"pub": true
				},
				"SEC_KS4": {
					"metaSetId": "rs_grade",
					"nameEn": "Key Stage 4",
					"rnum": 25,
					"LVNum": "KS4",
					"pMeta": "SEC",
					"nameCt": "第四學習階段",
					"pub": true
				},
				"SEC": {
					"metaSetId": "rs_grade",
					"nameEn": "Secondary School",
					"rnum": 19,
					"nameCt": "中學",
					"pub": true
				}
			}
		},
		"subjects": {
			"MSType": "su",
			"MSNameEn": "Subjects",
			"dateAdd": 1690513256264,
			"MSLangCt": 1,
			"doReport": 0,
			"modUid": "QvgUR8G5bNdDvRxkKggqxZ",
			"MSLangEn": 1,
			"drpu": "P",
			"metaSetId": "subjects",
			"MSNameCt": "科目",
			"addUid": "QvgUR8G5bNdDvRxkKggqxZ",
			"heads": [
				{
					"LVNum": "Index - Level 1",
					"nameEn": "Subject",
					"nameCt": "科目類別",
					"metaId": "ID - Level 1"
				}
			],
			"dateMod": 1690513273294,
			"tree2": {
				"PHY": {},
				"PTH": {},
				"ELT_SS": {},
				"ECE": {},
				"BIO": {},
				"MATH_JS": {},
				"SCIC": {},
				"ELT_P": {},
				"GEO_JS": {},
				"CLT_P": {},
				"ELT_JS": {},
				"CLT_JS": {},
				"SCII": {},
				"GEO_SS": {},
				"MATH_P": {},
				"MATH_SS": {},
				"CLT_SS": {}
			},
			"metas": {
				"BIO": {
					"nameEn": "Biology",
					"rnum": 15,
					"nameCt": "生物",
					"metaSetId": "subjects",
					"pub": 0
				},
				"CLT_JS": {
					"nameEn": "Junior Secondary Chinese Langauge",
					"rnum": 3,
					"nameCt": "初中中國語文",
					"metaSetId": "subjects",
					"pub": 0
				},
				"CLT_P": {
					"nameEn": "Primary Chinese Langauge",
					"rnum": 2,
					"nameCt": "小學中國語文",
					"metaSetId": "subjects",
					"pub": 0
				},
				"CLT_SS": {
					"nameEn": "Senior Secondary Chinese Langauge",
					"rnum": 4,
					"nameCt": "高中中國語文",
					"metaSetId": "subjects",
					"pub": 0
				},
				"ECE": {
					"nameEn": "Early Childhood Education",
					"rnum": 18,
					"nameCt": "學前教育",
					"metaSetId": "subjects",
					"pub": 0
				},
				"ELT_JS": {
					"nameEn": "Junior Secondary English Language",
					"rnum": 7,
					"nameCt": "初中英國語文",
					"metaSetId": "subjects",
					"pub": 0
				},
				"ELT_P": {
					"nameEn": "Primary English Langauge",
					"rnum": 6,
					"nameCt": "小學英國語文",
					"metaSetId": "subjects",
					"pub": 0
				},
				"ELT_SS": {
					"nameEn": "Senior Secondary English Language",
					"rnum": 8,
					"nameCt": "高中英國語文",
					"metaSetId": "subjects",
					"pub": 0
				},
				"GEO_JS": {
					"nameEn": "Junior Secondary Geography",
					"rnum": 16,
					"nameCt": "初中地理",
					"metaSetId": "subjects",
					"pub": 0
				},
				"GEO_SS": {
					"nameEn": "Senior Secondary Geography",
					"rnum": 17,
					"nameCt": "高中地理",
					"metaSetId": "subjects",
					"pub": 0
				},
				"MATH_JS": {
					"nameEn": "Junior Secondary Mathematics",
					"rnum": 10,
					"nameCt": "初中數學",
					"metaSetId": "subjects",
					"pub": 1
				},
				"MATH_P": {
					"nameEn": "Primary Mathematics",
					"rnum": 9,
					"nameCt": "小學數學",
					"metaSetId": "subjects",
					"pub": 0
				},
				"MATH_SS": {
					"nameEn": "Senior Secondary Mathematics",
					"rnum": 11,
					"nameCt": "高中數學",
					"metaSetId": "subjects",
					"pub": 1
				},
				"PHY": {
					"nameEn": "Physics",
					"rnum": 14,
					"nameCt": "物理",
					"metaSetId": "subjects",
					"pub": 1
				},
				"PTH": {
					"nameEn": "Primary Putonghau",
					"rnum": 5,
					"nameCt": "小學普通話",
					"metaSetId": "subjects",
					"pub": 0
				},
				"SCIC": {
					"nameEn": "Science: Combined Science",
					"rnum": 13,
					"nameCt": "科學 : 組合科學",
					"metaSetId": "subjects",
					"pub": 1
				},
				"SCII": {
					"nameEn": "Junior Science",
					"rnum": 12,
					"nameCt": "初中科學",
					"metaSetId": "subjects",
					"pub": 1
				}
			}
		},
		"my_values_01": {
			"MSType": "va",
			"MSReports": "subjects, SCII; subjects, SCIC; subjects, PHY",
			"MSNameEn": "MY Values 01",
			"dateAdd": 1691651059445,
			"MSLangCt": 1,
			"doReport": 1,
			"modUid": "QvgUR8G5bNdDvRxkKggqxZ",
			"MSLangEn": 1,
			"drpu": "P",
			"metaSetId": "my_values_01",
			"MSNameCt": "MY 定值 01",
			"addUid": "QvgUR8G5bNdDvRxkKggqxZ",
			"heads": [
				{
					"LVNum": "Index - Level 1",
					"nameEn": "Value L1",
					"nameCt": "定值 L1",
					"metaId": "ID - Level 1"
				},
				{
					"LVNum": "Index - Level 2",
					"nameEn": "Value L2",
					"nameCt": "定值 L2",
					"metaId": "ID - Level 2"
				},
				{
					"LVNum": "Index - Level 3",
					"nameEn": "Value L3",
					"nameCt": "定值 L3",
					"metaId": "ID - Level 3"
				}
			],
			"dateMod": 1691751102567,
			"tree2": {
				"V6": {
					"V6_1": {
						"V6_1_2": {},
						"V6_1_1": {}
					}
				},
				"V7": {
					"V7_1": {
						"V7_1_2": {},
						"V7_1_1": {}
					}
				},
				"V1": {
					"V1_2": {
						"V1_2_2": {},
						"V1_2_1": {}
					},
					"V1_1": {
						"V1_1_1": {},
						"V1_1_9": {},
						"V1_1_8": {},
						"V1_1_7": {},
						"V1_1_6": {},
						"V1_1_5": {},
						"V1_1_4": {},
						"V1_1_3": {},
						"V1_1_2": {}
					}
				},
				"V2": {
					"V2_2": {
						"V2_2_1": {},
						"V2_2_2": {}
					},
					"V2_1": {
						"V2_1_2": {},
						"V2_1_1": {}
					}
				},
				"V3": {
					"V3_1": {
						"V3_1_2": {},
						"V3_1_1": {}
					}
				},
				"V4": {
					"V4_1": {
						"V4_1_2": {},
						"V4_1_1": {}
					}
				},
				"V5": {
					"V5_1": {
						"V5_1_1": {},
						"V5_1_2": {}
					}
				}
			},
			"metas": {
				"V1_1_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1-1",
					"rnum": 4,
					"LVNum": "1.1.1",
					"pMeta": "V1_1",
					"nameCt": "定值 1-1-1",
					"pub": true
				},
				"V1_1_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1-2",
					"rnum": 5,
					"LVNum": "1.1.2",
					"pMeta": "V1_1",
					"nameCt": "定值 1-1-2",
					"pub": true
				},
				"V1_1_3": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1-3",
					"rnum": 6,
					"LVNum": "1.1.3",
					"pMeta": "V1_1",
					"nameCt": "定值 1-1-3",
					"pub": true
				},
				"V1_1_4": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1-4",
					"rnum": 7,
					"LVNum": "1.1.4",
					"pMeta": "V1_1",
					"nameCt": "定值 1-1-4",
					"pub": true
				},
				"V1_1_5": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1-5",
					"rnum": 8,
					"LVNum": "1.1.5",
					"pMeta": "V1_1",
					"nameCt": "定值 1-1-5",
					"pub": true
				},
				"V1_1_6": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1-6",
					"rnum": 9,
					"LVNum": "1.1.6",
					"pMeta": "V1_1",
					"nameCt": "定值 1-1-6",
					"pub": true
				},
				"V1_1_7": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1-7",
					"rnum": 10,
					"LVNum": "1.1.7",
					"pMeta": "V1_1",
					"nameCt": "定值 1-1-7",
					"pub": true
				},
				"V1_1_8": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1-8",
					"rnum": 11,
					"LVNum": "1.1.8",
					"pMeta": "V1_1",
					"nameCt": "定值 1-1-8",
					"pub": true
				},
				"V1_1_9": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1-9",
					"rnum": 12,
					"LVNum": "1.1.9",
					"pMeta": "V1_1",
					"nameCt": "定值 1-1-9",
					"pub": true
				},
				"V1_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-1",
					"rnum": 3,
					"LVNum": "1.1",
					"pMeta": "V1",
					"nameCt": "定值 1-1",
					"pub": true
				},
				"V1_2_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-2-1",
					"rnum": 14,
					"LVNum": "1.2.1",
					"pMeta": "V1_2",
					"nameCt": "定值 1-2-1",
					"pub": 1
				},
				"V1_2_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-2-2",
					"rnum": 15,
					"LVNum": "1.2.2",
					"pMeta": "V1_2",
					"nameCt": "定值 1-2-2",
					"pub": 1
				},
				"V1_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1-2",
					"rnum": 13,
					"LVNum": "1.2",
					"pMeta": "V1",
					"nameCt": "定值 1-2",
					"pub": 1
				},
				"V1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 1",
					"rnum": 2,
					"LVNum": "1",
					"nameCt": "定值 1",
					"pub": true
				},
				"V2_1_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 2-1-1",
					"rnum": 18,
					"LVNum": "2.1.1",
					"pMeta": "V2_1",
					"nameCt": "定值 2-1-1",
					"pub": 0
				},
				"V2_1_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 2-1-2",
					"rnum": 19,
					"LVNum": "2.1.2",
					"pMeta": "V2_1",
					"nameCt": "定值 2-1-2",
					"pub": 0
				},
				"V2_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 2-1",
					"rnum": 17,
					"LVNum": "2.1",
					"pMeta": "V2",
					"nameCt": "定值 2-1",
					"pub": 0
				},
				"V2_2_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 2-2-1",
					"rnum": 21,
					"LVNum": "2.2.1",
					"pMeta": "V2_2",
					"nameCt": "定值 2-2-1",
					"pub": 0
				},
				"V2_2_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 2-2-2",
					"rnum": 22,
					"LVNum": "2.2.2",
					"pMeta": "V2_2",
					"nameCt": "定值 2-2-2",
					"pub": 0
				},
				"V2_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 2-2",
					"rnum": 20,
					"LVNum": "2.2",
					"pMeta": "V2",
					"nameCt": "定值 2-2",
					"pub": 0
				},
				"V2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 2",
					"rnum": 16,
					"LVNum": "2",
					"nameCt": "定值 2",
					"pub": 1
				},
				"V3_1_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 3-1-1",
					"rnum": 25,
					"LVNum": "3.1.1",
					"pMeta": "V3_1",
					"nameCt": "定值 3-1-1",
					"pub": 0
				},
				"V3_1_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 3-1-2",
					"rnum": 26,
					"LVNum": "3.1.2",
					"pMeta": "V3_1",
					"nameCt": "定值 3-1-2",
					"pub": 0
				},
				"V3_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 3-1",
					"rnum": 24,
					"LVNum": "3.1",
					"pMeta": "V3",
					"nameCt": "定值 3-1",
					"pub": 0
				},
				"V3": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 3",
					"rnum": 23,
					"LVNum": "3",
					"nameCt": "定值 3",
					"pub": 1
				},
				"V4_1_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 4-1-1",
					"rnum": 29,
					"LVNum": "4.1.1",
					"pMeta": "V4_1",
					"nameCt": "定值 4-1-1",
					"pub": 0
				},
				"V4_1_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 4-1-2",
					"rnum": 30,
					"LVNum": "4.1.2",
					"pMeta": "V4_1",
					"nameCt": "定值 4-1-2",
					"pub": 0
				},
				"V4_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 4-1",
					"rnum": 28,
					"LVNum": "4.1",
					"pMeta": "V4",
					"nameCt": "定值 4-1",
					"pub": 0
				},
				"V4": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 4",
					"rnum": 27,
					"LVNum": "4",
					"nameCt": "定值 4",
					"pub": 1
				},
				"V5_1_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 5-1-1",
					"rnum": 33,
					"LVNum": "5.1.1",
					"pMeta": "V5_1",
					"nameCt": "定值 5-1-1",
					"pub": 0
				},
				"V5_1_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 5-1-2",
					"rnum": 34,
					"LVNum": "5.1.2",
					"pMeta": "V5_1",
					"nameCt": "定值 5-1-2",
					"pub": 0
				},
				"V5_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 5-1",
					"rnum": 32,
					"LVNum": "5.1",
					"pMeta": "V5",
					"nameCt": "定值 5-1",
					"pub": 0
				},
				"V5": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 5",
					"rnum": 31,
					"LVNum": "5",
					"nameCt": "定值 5",
					"pub": 1
				},
				"V6_1_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 6-1-1",
					"rnum": 37,
					"LVNum": "6.1.1",
					"pMeta": "V6_1",
					"nameCt": "定值 6-1-1",
					"pub": 0
				},
				"V6_1_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 6-1-2",
					"rnum": 38,
					"LVNum": "6.1.2",
					"pMeta": "V6_1",
					"nameCt": "定值 6-1-2",
					"pub": 0
				},
				"V6_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 6-1",
					"rnum": 36,
					"LVNum": "6.1",
					"pMeta": "V6",
					"nameCt": "定值 6-1",
					"pub": 0
				},
				"V6": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 6",
					"rnum": 35,
					"LVNum": "6",
					"nameCt": "定值 6",
					"pub": 1
				},
				"V7_1_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 7-1-1",
					"rnum": 41,
					"LVNum": "7.1.1",
					"pMeta": "V7_1",
					"nameCt": "定值 7-1-1",
					"pub": 1
				},
				"V7_1_2": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 7-1-2",
					"rnum": 42,
					"LVNum": "7.1.2",
					"pMeta": "V7_1",
					"nameCt": "定值 7-1-2",
					"pub": 1
				},
				"V7_1": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 7-1",
					"rnum": 40,
					"LVNum": "7.1",
					"pMeta": "V7",
					"nameCt": "定值 7-1",
					"pub": 1
				},
				"V7": {
					"metaSetId": "my_values_01",
					"nameEn": "Value 7",
					"rnum": 39,
					"LVNum": "7",
					"nameCt": "定值 7",
					"pub": 1
				}
			}
		},
		"NCMATH2E_001": {
			"MSType": "tb",
			"MSReports": "subjects, MATH_SS; subjects, MATH_JP",
			"MSNameEn": "New Century Mathematics (2nd Edition) S4-S6",
			"dateAdd": 1684205116105,
			"MSLangCt": 1,
			"doReport": 1,
			"modUid": "XhhqJdo7c7oFZ4U5TbtPZt",
			"MSLangEn": 1,
			"drpu": "P",
			"metaSetId": "NCMATH2E_001",
			"MSNameCt": "新世代數學（第二版）S4-S6",
			"addUid": "XhhqJdo7c7oFZ4U5TbtPZt",
			"heads": [
				{
					"LVNum": "Index - Level 1",
					"nameEn": "Series",
					"nameCt": "系列",
					"metaId": "ID - Level 1"
				},
				{
					"LVNum": "Index - Level 2",
					"nameEn": "Book",
					"nameCt": "本書",
					"metaId": "ID - Level 2"
				},
				{
					"LVNum": "Index - Level 3",
					"nameEn": "Chapter",
					"nameCt": "章節",
					"metaId": "ID - Level 3"
				},
				{
					"LVNum": "Index - Level 4",
					"nameEn": "Section",
					"nameCt": "部份",
					"metaId": "ID - Level 4"
				},
				{
					"LVNum": "Index - Level 5",
					"nameEn": "Sub-Section",
					"nameCt": "次部份",
					"metaId": "ID - Level 5"
				}
			],
			"dateMod": 1684205174739,
			"tree2": {
				"NCMATH2E": {
					"NCMATH2E_4A": {
						"NCMATH2E_4A_1": {
							"NCMATH2E_4A_1_1.1": {
								"NCMATH2E_4A_1_1.1_D": {},
								"NCMATH2E_4A_1_1.1_A": {},
								"NCMATH2E_4A_1_1.1_B": {},
								"NCMATH2E_4A_1_1.1_C": {}
							},
							"NCMATH2E_4A_1_1.3": {
								"NCMATH2E_4A_1_1.3_B": {},
								"NCMATH2E_4A_1_1.3_C": {},
								"NCMATH2E_4A_1_1.3_A": {}
							},
							"NCMATH2E_4A_1_1.2": {
								"NCMATH2E_4A_1_1.2_A": {},
								"NCMATH2E_4A_1_1.2_B": {}
							}
						},
						"NCMATH2E_4A_2": {
							"NCMATH2E_4A_2_2.1": {
								"NCMATH2E_4A_2_2.1_F": {},
								"NCMATH2E_4A_2_2.1_A": {},
								"NCMATH2E_4A_2_2.1_B": {},
								"NCMATH2E_4A_2_2.1_C": {},
								"NCMATH2E_4A_2_2.1_D": {},
								"NCMATH2E_4A_2_2.1_E": {}
							},
							"NCMATH2E_4A_2_2.0": {},
							"NCMATH2E_4A_2_2.2": {}
						},
						"NCMATH2E_4A_3": {
							"NCMATH2E_4A_3_3.1": {},
							"NCMATH2E_4A_3_3.0": {},
							"NCMATH2E_4A_3_3.7": {
								"NCMATH2E_4A_3_3.7_B": {},
								"NCMATH2E_4A_3_3.7_A": {}
							},
							"NCMATH2E_4A_3_3.6": {},
							"NCMATH2E_4A_3_3.5": {},
							"NCMATH2E_4A_3_3.4": {},
							"NCMATH2E_4A_3_3.3": {},
							"NCMATH2E_4A_3_3.2": {}
						},
						"NCMATH2E_4A_4": {
							"NCMATH2E_4A_4_4.1": {},
							"NCMATH2E_4A_4_4.3": {
								"NCMATH2E_4A_4_4.3_A": {},
								"NCMATH2E_4A_4_4.3_C": {},
								"NCMATH2E_4A_4_4.3_B": {}
							},
							"NCMATH2E_4A_4_4.2": {
								"NCMATH2E_4A_4_4.2_B": {},
								"NCMATH2E_4A_4_4.2_A": {},
								"NCMATH2E_4A_4_4.2_C": {}
							}
						},
						"NCMATH2E_4A_5": {
							"NCMATH2E_4A_5_5.1": {
								"NCMATH2E_4A_5_5.1_A": {},
								"NCMATH2E_4A_5_5.1_B": {}
							},
							"NCMATH2E_4A_5_5.2": {
								"NCMATH2E_4A_5_5.2_D": {},
								"NCMATH2E_4A_5_5.2_C": {},
								"NCMATH2E_4A_5_5.2_B": {},
								"NCMATH2E_4A_5_5.2_A": {}
							}
						},
						"NCMATH2E_4A_6": {
							"NCMATH2E_4A_6_6.1": {
								"NCMATH2E_4A_6_6.1_A": {},
								"NCMATH2E_4A_6_6.1_B": {},
								"NCMATH2E_4A_6_6.1_C": {}
							},
							"NCMATH2E_4A_6_6.0": {},
							"NCMATH2E_4A_6_6.3": {
								"NCMATH2E_4A_6_6.3_B": {},
								"NCMATH2E_4A_6_6.3_A": {}
							},
							"NCMATH2E_4A_6_6.2": {}
						}
					},
					"NCMATH2E_4B": {
						"NCMATH2E_4B_7": {
							"NCMATH2E_4B_7_7.2": {
								"NCMATH2E_4B_7_7.2_A": {},
								"NCMATH2E_4B_7_7.2_B": {}
							},
							"NCMATH2E_4B_7_7.1": {
								"NCMATH2E_4B_7_7.1_A": {},
								"NCMATH2E_4B_7_7.1_B": {},
								"NCMATH2E_4B_7_7.1_C": {}
							},
							"NCMATH2E_4B_7_7.0": {}
						},
						"NCMATH2E_4B_8": {
							"NCMATH2E_4B_8_8.3": {
								"NCMATH2E_4B_8_8.3_B": {},
								"NCMATH2E_4B_8_8.3_A": {}
							},
							"NCMATH2E_4B_8_8.4": {
								"NCMATH2E_4B_8_8.4_A": {},
								"NCMATH2E_4B_8_8.4_B": {}
							},
							"NCMATH2E_4B_8_8.5": {
								"NCMATH2E_4B_8_8.5_C": {},
								"NCMATH2E_4B_8_8.5_B": {},
								"NCMATH2E_4B_8_8.5_A": {}
							},
							"NCMATH2E_4B_8_8.1": {
								"NCMATH2E_4B_8_8.1_C": {},
								"NCMATH2E_4B_8_8.1_B": {},
								"NCMATH2E_4B_8_8.1_A": {}
							},
							"NCMATH2E_4B_8_8.2": {}
						},
						"NCMATH2E_4B_9": {
							"NCMATH2E_4B_9_9.0": {},
							"NCMATH2E_4B_9_9.3": {
								"NCMATH2E_4B_9_9.3_B": {},
								"NCMATH2E_4B_9_9.3_A": {}
							},
							"NCMATH2E_4B_9_9.1": {},
							"NCMATH2E_4B_9_9.2": {
								"NCMATH2E_4B_9_9.2_A": {},
								"NCMATH2E_4B_9_9.2_B": {}
							}
						},
						"NCMATH2E_4B_12": {
							"NCMATH2E_4B_12_12.0": {},
							"NCMATH2E_4B_12_12.1": {
								"NCMATH2E_4B_12_12.1_A": {},
								"NCMATH2E_4B_12_12.1_B": {},
								"NCMATH2E_4B_12_12.1_C": {},
								"NCMATH2E_4B_12_12.1_D": {},
								"NCMATH2E_4B_12_12.1_E": {}
							},
							"NCMATH2E_4B_12_12.2": {
								"NCMATH2E_4B_12_12.2_B": {},
								"NCMATH2E_4B_12_12.2_A": {}
							},
							"NCMATH2E_4B_12_12.3": {
								"NCMATH2E_4B_12_12.3_A": {},
								"NCMATH2E_4B_12_12.3_B": {},
								"NCMATH2E_4B_12_12.3_C": {}
							},
							"NCMATH2E_4B_12_12.4": {
								"NCMATH2E_4B_12_12.4_A": {},
								"NCMATH2E_4B_12_12.4_B": {},
								"NCMATH2E_4B_12_12.4_C": {}
							}
						},
						"NCMATH2E_4B_11": {
							"NCMATH2E_4B_11_11.5": {},
							"NCMATH2E_4B_11_11.4": {},
							"NCMATH2E_4B_11_11.3": {
								"NCMATH2E_4B_11_11.3_B": {},
								"NCMATH2E_4B_11_11.3_A": {},
								"NCMATH2E_4B_11_11.3_C": {}
							},
							"NCMATH2E_4B_11_11.2": {},
							"NCMATH2E_4B_11_11.1": {
								"NCMATH2E_4B_11_11.1_B": {},
								"NCMATH2E_4B_11_11.1_A": {}
							}
						},
						"NCMATH2E_4B_10": {
							"NCMATH2E_4B_10_10.3": {
								"NCMATH2E_4B_10_10.3_A": {},
								"NCMATH2E_4B_10_10.3_B": {}
							},
							"NCMATH2E_4B_10_10.4": {
								"NCMATH2E_4B_10_10.4_B": {},
								"NCMATH2E_4B_10_10.4_A": {}
							},
							"NCMATH2E_4B_10_10.1": {
								"NCMATH2E_4B_10_10.1_A": {},
								"NCMATH2E_4B_10_10.1_B": {}
							},
							"NCMATH2E_4B_10_10.2": {
								"NCMATH2E_4B_10_10.2_A": {},
								"NCMATH2E_4B_10_10.2_B": {},
								"NCMATH2E_4B_10_10.2_C": {}
							},
							"NCMATH2E_4B_10_10.0": {}
						}
					}
				}
			},
			"metas": {
				"NCMATH2E_4A_1_1.1_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Integers",
					"rnum": 6,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_1_1.1",
					"nameCt": "函數的記號：\\(f\\left(x\\right)\\)",//"nameCt": "整數",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.1_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Rational Numbers",
					"rnum": 7,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_1_1.1",
					"nameCt": "函數的記號：\\(f\\left(x\\right)\\)",//"nameCt": "有理數",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.1_C": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Irrational Numbers",
					"rnum": 8,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4A_1_1.1",
					"nameCt": "無理數",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.1_D": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Real Numbers",
					"rnum": 9,
					"LVNum": "D",
					"pMeta": "NCMATH2E_4A_1_1.1",
					"nameCt": "實數",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.1": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "The Real Number System",
					"rnum": 5,
					"LVNum": "1.1",
					"pMeta": "NCMATH2E_4A_1",
					"nameCt": "實數系",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.2_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Imaginary Numbers",
					"rnum": 11,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_1_1.2",
					"nameCt": "虛數",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.2_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Complex Numbers",
					"rnum": 12,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_1_1.2",
					"nameCt": "複數",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.2": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Complex Numbers",
					"rnum": 10,
					"LVNum": "1.2",
					"pMeta": "NCMATH2E_4A_1",
					"nameCt": "複數",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.3_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Operations of Imaginary Numbers",
					"rnum": 14,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_1_1.3",
					"nameCt": "虛數的運算",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.3_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Operations of Complex Numbers",
					"rnum": 15,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_1_1.3",
					"nameCt": "複數的運算",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.3_C": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Equality of Complex Numbers",
					"rnum": 16,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4A_1_1.3",
					"nameCt": "相等的複數",
					"pub": 1
				},
				"NCMATH2E_4A_1_1.3": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Operations of Complex Numbers",
					"rnum": 13,
					"LVNum": "1.3",
					"pMeta": "NCMATH2E_4A_1",
					"nameCt": "複數的運算",
					"pub": 1
				},
				"NCMATH2E_4A_1": {
					"metaSetId": "NCMATH2E_001",
					"nameE": "EEEENNN \\(f\\left(x\\right)\\)",//"nameEn": "Number Systems",
					"rnum": 4,
					"LVNum": "1",
					"pMeta": "NCMATH2E_4A",
					"nameCt": "函數的記號：\\(f\\left(x\\right)\\)",//"nameCt": "數系",
					"pub": 1
				},
				"NCMATH2E_4A_2_2.0": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Review",
					"rnum": 18,
					"LVNum": "2",
					"pMeta": "NCMATH2E_4A_2",
					"nameCt": "基本知識重温",
					"pub": 1
				},
				"NCMATH2E_4A_2_2.1_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Introduction to Equations of Straight Lines",
					"rnum": 20,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_2_2.1",
					"nameCt": "直線方程的簡介",
					"pub": 1
				},
				"NCMATH2E_4A_2_2.1_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Two-point Form",
					"rnum": 21,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_2_2.1",
					"nameCt": "兩點式",
					"pub": 1
				},
				"NCMATH2E_4A_2_2.1_C": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Point-slope Form",
					"rnum": 22,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4A_2_2.1",
					"nameCt": "點斜式",
					"pub": 1
				},
				"NCMATH2E_4A_2_2.1_D": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Slope-intercept Form",
					"rnum": 23,
					"LVNum": "D",
					"pMeta": "NCMATH2E_4A_2_2.1",
					"nameCt": "斜截式",
					"pub": 1
				},
				"NCMATH2E_4A_2_2.1_E": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Intercept Form",
					"rnum": 24,
					"LVNum": "E",
					"pMeta": "NCMATH2E_4A_2_2.1",
					"nameCt": "截距式",
					"pub": 1
				},
				"NCMATH2E_4A_2_2.1_F": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Equations of Special Straight Lines",
					"rnum": 25,
					"LVNum": "F",
					"pMeta": "NCMATH2E_4A_2_2.1",
					"nameCt": "特殊直線的方程",
					"pub": 1
				},
				"NCMATH2E_4A_2_2.1": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Equations of Straight Lines",
					"rnum": 19,
					"LVNum": "2.1",
					"pMeta": "NCMATH2E_4A_2",
					"nameCt": "直線的方程",
					"pub": 1
				},
				"NCMATH2E_4A_2_2.2": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Number of Points of Intersection of Two Straight Lines",
					"rnum": 26,
					"LVNum": "2.2",
					"pMeta": "NCMATH2E_4A_2",
					"nameCt": "兩直線的交點數目",
					"pub": 1
				},
				"NCMATH2E_4A_2": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Equations of Straight Lines",
					"rnum": 17,
					"LVNum": "2",
					"pMeta": "NCMATH2E_4A",
					"nameCt": "直線的方程",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.0": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Review",
					"rnum": 28,
					"LVNum": "3",
					"pMeta": "NCMATH2E_4A_3",
					"nameCt": "基本知識重温",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.1": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Solving Quadratic Equations by the Factor Method",
					"rnum": 29,
					"LVNum": "3.1",
					"pMeta": "NCMATH2E_4A_3",
					"nameCt": "以因式分解法解二次方程",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.2": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Solving Quadratic Equations by the Graphical Method",
					"rnum": 30,
					"LVNum": "3.2",
					"pMeta": "NCMATH2E_4A_3",
					"nameCt": "以圖解法解二次方程",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.3": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Solving Quadratic Equations by the Quadratic Formula",
					"rnum": 31,
					"LVNum": "3.3",
					"pMeta": "NCMATH2E_4A_3",
					"nameCt": "以二次公式解二次方程",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.4": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Relations between the Discriminant and the Nature of Roots",
					"rnum": 32,
					"LVNum": "3.4",
					"pMeta": "NCMATH2E_4A_3",
					"nameCt": "判別式與根的性質之關係",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.5": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Practical Problems Leading to Quadratic Equations",
					"rnum": 33,
					"LVNum": "3.5",
					"pMeta": "NCMATH2E_4A_3",
					"nameCt": "二次方程的應用題",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.6": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Forming Quadratic Equations",
					"rnum": 34,
					"LVNum": "3.6",
					"pMeta": "NCMATH2E_4A_3",
					"nameCt": "建立二次方程",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.7_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Sum and Product of Two Roots",
					"rnum": 36,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_3_3.7",
					"nameCt": "兩根的和與兩根的積",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.7_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Use the Relations between Roots and Coefficients to Form Quadratic Equations",
					"rnum": 37,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_3_3.7",
					"nameCt": "利用根與係數的關係建立二次方程",
					"pub": 1
				},
				"NCMATH2E_4A_3_3.7": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Relations between Roots and Coefficients",
					"rnum": 35,
					"LVNum": "3.7",
					"pMeta": "NCMATH2E_4A_3",
					"nameCt": "根與係數的關係",
					"pub": 1
				},
				"NCMATH2E_4A_3": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Quadratic Equations in One Unknown",
					"rnum": 27,
					"LVNum": "3",
					"pMeta": "NCMATH2E_4A",
					"nameCt": "一元二次方程",
					"pub": 1
				},
				"NCMATH2E_4A_4_4.1": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Basic Concept of Functions",
					"rnum": 39,
					"LVNum": "4.1",
					"pMeta": "NCMATH2E_4A_4",
					"nameCt": "函數的基本概念",
					"pub": 1
				},
				"NCMATH2E_4A_4_4.2_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Tabular Method",
					"rnum": 41,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_4_4.2",
					"nameCt": "表列方法",
					"pub": 1
				},
				"NCMATH2E_4A_4_4.2_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Algebraic Method",
					"rnum": 42,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_4_4.2",
					"nameCt": "代數方法",
					"pub": 1
				},
				"NCMATH2E_4A_4_4.2_C": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Graphical Method",
					"rnum": 43,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4A_4_4.2",
					"nameCt": "圖像方法",
					"pub": 1
				},
				"NCMATH2E_4A_4_4.2": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Representations of Functions",
					"rnum": 40,
					"LVNum": "4.2",
					"pMeta": "NCMATH2E_4A_4",
					"nameCt": "以函數的表示方法",
					"pub": 1
				},
				"NCMATH2E_4A_4_4.3_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Notation of Functions",
					"rnum": 45,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_4_4.3",
					"nameCt": "函數的記法",
					"pub": 1
				},
				"NCMATH2E_4A_4_4.3_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Domain and Co-domain of a Function",
					"rnum": 46,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_4_4.3",
					"nameCt": "函數的定義域和上域",
					"pub": 1
				},
				"NCMATH2E_4A_4_4.3_C": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Values of a Function",
					"rnum": 47,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4A_4_4.3",
					"nameCt": "函數的值",
					"pub": 1
				},
				"NCMATH2E_4A_4_4.3": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Notation of a Function: \\(f\\left(x\\right)\\)",
					"rnum": 44,
					"LVNum": "4.3",
					"pMeta": "NCMATH2E_4A_4",
					"nameCt": "函數的記號：\\(f\\left(x\\right)\\)",
					"pub": 1
				},
				"NCMATH2E_4A_4": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Basic Knowledge of Functions",
					"rnum": 38,
					"LVNum": "4",
					"pMeta": "NCMATH2E_4A",
					"nameCt": "函數的基本認識",
					"pub": 1
				},
				"NCMATH2E_4A_5_5.1_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "The Graph of \\(y=ax^2\\)",
					"rnum": 50,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_5_5.1",
					"nameCt": "\\(y=ax^2\\) 的圖像",
					"pub": 1
				},
				"NCMATH2E_4A_5_5.1_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "The Graph of \\(y=ax^2+bx+c\\)",
					"rnum": 51,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_5_5.1",
					"nameCt": "\\(y=ax^2+bx+c\\) 的圖像",
					"pub": 1
				},
				"NCMATH2E_4A_5_5.1": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Features of the Graphs of Quadratic Functions",
					"rnum": 49,
					"LVNum": "5.1",
					"pMeta": "NCMATH2E_4A_5",
					"nameCt": "二次函數圖像的特徵",
					"pub": 1
				},
				"NCMATH2E_4A_5_5.2_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Maximum or Minimum Value of the Quadratic Function \\(y=a{(x-h)}^2+k\\)",
					"rnum": 53,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_5_5.2",
					"nameCt": "二次函數 \\(y=a{(x-h)}^2+k\\) 的極大值或極小值",
					"pub": 1
				},
				"NCMATH2E_4A_5_5.2_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Method of Completing the Square",
					"rnum": 54,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_5_5.2",
					"nameCt": "配方法",
					"pub": 1
				},
				"NCMATH2E_4A_5_5.2_C": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Exploring the Properties of Quadratic Functions Using the Method of Completing the Square",
					"rnum": 55,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4A_5_5.2",
					"nameCt": "利用配方法探究二次函數的性質",
					"pub": 1
				},
				"NCMATH2E_4A_5_5.2_D": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Solving Application Problems Using the Method of Completing the Square",
					"rnum": 56,
					"LVNum": "D",
					"pMeta": "NCMATH2E_4A_5_5.2",
					"nameCt": "利用配方法解答應用題",
					"pub": 1
				},
				"NCMATH2E_4A_5_5.2": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Finding the Maximum or Minimum Values of Quadratic Functions by the Algebraic Method",
					"rnum": 52,
					"LVNum": "5.2",
					"pMeta": "NCMATH2E_4A_5",
					"nameCt": "以代數方法求二次函數的極大值或極小值",
					"pub": 1
				},
				"NCMATH2E_4A_5": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Quadratic Functions",
					"rnum": 48,
					"LVNum": "5",
					"pMeta": "NCMATH2E_4A",
					"nameCt": "二次函數",
					"pub": 1
				},
				"NCMATH2E_4A_6_6.0": {
					"nameEn": "Review",
					"rnum": 58,
					"LVNum": "6",
					"pMeta": "NCMATH2E_4A_6",
					"nameCt": "基本知識重温",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A_6_6.1_A": {
					"nameEn": "Dividing a Polynomial by a Monomial",
					"rnum": 60,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_6_6.1",
					"nameCt": "多項式除以單項式",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A_6_6.1_B": {
					"nameEn": "Dividing a Polynomial by a Polynomial",
					"rnum": 61,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_6_6.1",
					"nameCt": "多項式除以多項式",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A_6_6.1_C": {
					"nameEn": "Division Algorithm",
					"rnum": 62,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4A_6_6.1",
					"nameCt": "除法算式",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A_6_6.1": {
					"nameEn": "Division of Polynomials",
					"rnum": 59,
					"LVNum": "6.1",
					"pMeta": "NCMATH2E_4A_6",
					"nameCt": "多項式的除法",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A_6_6.2": {
					"nameEn": "Remainder Theorem",
					"rnum": 63,
					"LVNum": "6.2",
					"pMeta": "NCMATH2E_4A_6",
					"nameCt": "餘式定理",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A_6_6.3_A": {
					"nameEn": "Factor Theorem and Its Applications",
					"rnum": 65,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4A_6_6.3",
					"nameCt": "因式定理及其應用",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A_6_6.3_B": {
					"nameEn": "Factorization of Polynomials with Integral Coefficients (without Known Factors)",
					"rnum": 66,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4A_6_6.3",
					"nameCt": "整數係數多項式的因式分解( 未有已知因式)",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A_6_6.3": {
					"nameEn": "Factor Theorem",
					"rnum": 64,
					"LVNum": "6.3",
					"pMeta": "NCMATH2E_4A_6",
					"nameCt": "因式定理",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A_6": {
					"nameEn": "More about Polynomials",
					"rnum": 57,
					"LVNum": "6",
					"pMeta": "NCMATH2E_4A",
					"nameCt": "續多項式",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4A": {
					"rnum": 3,
					"LVNum": "4A",
					"pMeta": "NCMATH2E",
					"metaSetId": "NCMATH2E_001",
					"pub": 1
				},
				"NCMATH2E_4B_10_10.0": {
					"nameEn": "Review",
					"rnum": 103,
					"LVNum": "10",
					"pMeta": "NCMATH2E_4B_10",
					"nameCt": "基本知識重温",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.1_A": {
					"nameEn": "Basic Concepts of a Circle",
					"rnum": 105,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_10_10.1",
					"nameCt": "圓的基本概念",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.1_B": {
					"nameEn": "Special Circles",
					"rnum": 106,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_10_10.1",
					"nameCt": "特別的圓",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.1": {
					"nameEn": "Basic Knowledge about Circle",
					"rnum": 104,
					"LVNum": "10.1",
					"pMeta": "NCMATH2E_4B_10",
					"nameCt": "圓的基本知識",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.2_A": {
					"nameEn": "Perpendiculars to Chords",
					"rnum": 108,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_10_10.2",
					"nameCt": "弦的垂線",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.2_B": {
					"nameEn": "Equal Chords",
					"rnum": 109,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_10_10.2",
					"nameCt": "等弦",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.2_C": {
					"nameEn": "Circle Passing through Three Non-collinear Points",
					"rnum": 110,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4B_10_10.2",
					"nameCt": "通過不共線三點的圓",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.2": {
					"nameEn": "Properties of Chords of a Circle",
					"rnum": 107,
					"LVNum": "10.2",
					"pMeta": "NCMATH2E_4B_10",
					"nameCt": "圓上的弦的性質",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.3_A": {
					"nameEn": "Angles at the Centre and Angles at the Circumference",
					"rnum": 112,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_10_10.3",
					"nameCt": "圓心角和圓周角",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.3_B": {
					"nameEn": "Angles in the Same Segment",
					"rnum": 113,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_10_10.3",
					"nameCt": "同弓形內的圓周角",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.3": {
					"nameEn": "Angle Properties of a Circle",
					"rnum": 111,
					"LVNum": "10.3",
					"pMeta": "NCMATH2E_4B_10",
					"nameCt": "圓上的角的性質",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.4_A": {
					"nameEn": "Equal Relations among Angles, Arcs and Chords",
					"rnum": 115,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_10_10.4",
					"nameCt": "角、弧和弦的相等性質",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.4_B": {
					"nameEn": "Proportion Relations between Angles and Arcs",
					"rnum": 116,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_10_10.4",
					"nameCt": "角與弧的比例性質",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10_10.4": {
					"nameEn": "Relationships among Angles, Arcs and Chords",
					"rnum": 114,
					"LVNum": "10.4",
					"pMeta": "NCMATH2E_4B_10",
					"nameCt": "圓上的角、弧和弦的關係",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_10": {
					"nameEn": "Basic Properties of Circles",
					"rnum": 102,
					"LVNum": "10",
					"pMeta": "NCMATH2E_4B",
					"nameCt": "圓的基本性質",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.1_A": {
					"nameEn": "Opposite Angles of a Cyclic Quadrilateral",
					"rnum": 119,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_11_11.1",
					"nameCt": "圓內接四邊形的對角",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.1_B": {
					"nameEn": "Exterior Angles of a Cyclic Quadrilateral",
					"rnum": 120,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_11_11.1",
					"nameCt": "圓內接四邊形的外角",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.1": {
					"nameEn": "Properties of Cyclic Quadrilaterals",
					"rnum": 118,
					"LVNum": "11.1",
					"pMeta": "NCMATH2E_4B_11",
					"nameCt": "圓內接四邊形的性質",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.2": {
					"nameEn": "Tests for Cyclic Quadrilaterals",
					"rnum": 121,
					"LVNum": "11.2",
					"pMeta": "NCMATH2E_4B_11",
					"nameCt": "圓內接四邊形的判定",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.3_A": {
					"nameEn": "Basic Properties of Tangents to Circles",
					"rnum": 123,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_11_11.3",
					"nameCt": "切線的基本性質",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.3_B": {
					"nameEn": "Tangents from an External Point",
					"rnum": 124,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_11_11.3",
					"nameCt": "從圓外一點到圓的切線",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.3_C": {
					"nameEn": "Common Tangent",
					"rnum": 125,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4B_11_11.3",
					"nameCt": "公切線",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.3": {
					"nameEn": "Tangents to Circles",
					"rnum": 122,
					"LVNum": "11.3",
					"pMeta": "NCMATH2E_4B_11",
					"nameCt": "圓的切線",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.4": {
					"nameEn": "Angles in the Alternate Segments",
					"rnum": 126,
					"LVNum": "11.4",
					"pMeta": "NCMATH2E_4B_11",
					"nameCt": "內錯弓形的圓周角",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11_11.5": {
					"nameEn": "Geometric Proofs on Circles",
					"rnum": 127,
					"LVNum": "11.5",
					"pMeta": "NCMATH2E_4B_11",
					"nameCt": "與圓有關的幾何證明",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_11": {
					"nameEn": "More about Basic Properties of Circles",
					"rnum": 117,
					"LVNum": "11",
					"pMeta": "NCMATH2E_4B",
					"nameCt": "續圓的基本性質",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.0": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Review",
					"rnum": 129,
					"LVNum": "12",
					"pMeta": "NCMATH2E_4B_12",
					"nameCt": "基本知識重温",
					"pub": 1
				},
				"NCMATH2E_4B_12_12.1_A": {
					"nameEn": "Angles of Rotation and Quadrants",
					"rnum": 131,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_12_12.1",
					"nameCt": "旋轉角及象限",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.1_B": {
					"nameEn": "Definitions of Trigonometric Ratios of Any Angle",
					"rnum": 132,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_12_12.1",
					"nameCt": "任意角三角比的定義",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.1_C": {
					"nameEn": "Signs of Trigonometric Ratios",
					"rnum": 133,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4B_12_12.1",
					"nameCt": "三角比值的正負號",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.1_D": {
					"nameEn": "Patterns of the Values of Trigonometric Ratios",
					"rnum": 134,
					"LVNum": "D",
					"pMeta": "NCMATH2E_4B_12_12.1",
					"nameCt": "三角比的數值規律",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.1_E": {
					"nameEn": "Finding Trigonometric Ratios of Any Angle Using a Calculator",
					"rnum": 135,
					"LVNum": "E",
					"pMeta": "NCMATH2E_4B_12_12.1",
					"nameCt": "使用計算機求任意角的三角比",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.1": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Trigonometric Ratios of Any Angle",
					"rnum": 130,
					"LVNum": "12.1",
					"pMeta": "NCMATH2E_4B_12",
					"nameCt": "任意角的三角比",
					"pub": 1
				},
				"NCMATH2E_4B_12_12.2_A": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Relations among Trigonometric Ratios of the Same Angle",
					"rnum": 137,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_12_12.2",
					"nameCt": "同角三角比的關係",
					"pub": 1
				},
				"NCMATH2E_4B_12_12.2_B": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Simplifying Trigonometric Ratios of \\(\\cdot90^\\circ\\pm\\theta\\) to Those of \\(\\theta\\)",
					"rnum": 138,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_12_12.2",
					"nameCt": "化 \\(n\\cdot90^\\circ\\pm\\theta\\) 的三角比為 \\(\\theta\\) 的三角比",
					"pub": 1
				},
				"NCMATH2E_4B_12_12.2": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Trigonometric Identities",
					"rnum": 136,
					"LVNum": "12.2",
					"pMeta": "NCMATH2E_4B_12",
					"nameCt": "三角恆等式",
					"pub": 1
				},
				"NCMATH2E_4B_12_12.3_A": {
					"nameEn": "The Graph of y = sin x",
					"rnum": 140,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_12_12.3",
					"nameCt": "y = sin x 的圖像",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.3_B": {
					"nameEn": "The Graph of y = cos x",
					"rnum": 141,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_12_12.3",
					"nameCt": "y = cos x 的圖像",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.3_C": {
					"nameEn": "The Graph of y = tan x",
					"rnum": 142,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4B_12_12.3",
					"nameCt": "y = tan x 的圖像",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.3": {
					"nameEn": "Graphs of Trigonometric Functions",
					"rnum": 139,
					"LVNum": "12.3",
					"pMeta": "NCMATH2E_4B_12",
					"nameCt": "三角函數的圖像",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.4_A": {
					"nameEn": "Finding the Corresponding Angles from Given Trigonometric Ratios",
					"rnum": 144,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_12_12.4",
					"nameCt": "從已知三角比求對應的角",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.4_B": {
					"nameEn": "Solving Simple Trigonometric Equations",
					"rnum": 145,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_12_12.4",
					"nameCt": "解簡單的三角方程",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.4_C": {
					"nameEn": "Solving Other Trigonometric Equations",
					"rnum": 146,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4B_12_12.4",
					"nameCt": "解其他的三角方程",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12_12.4": {
					"nameEn": "Trigonometric Equations",
					"rnum": 143,
					"LVNum": "12.4",
					"pMeta": "NCMATH2E_4B_12",
					"nameCt": "三角方程",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_12": {
					"metaSetId": "NCMATH2E_001",
					"nameEn": "Basic Trigonometry",
					"rnum": 128,
					"LVNum": "12",
					"pMeta": "NCMATH2E_4B",
					"nameCt": "基本三角學",
					"pub": 1
				},
				"NCMATH2E_4B_7_7.0": {
					"nameEn": "Review",
					"rnum": 69,
					"LVNum": "7",
					"pMeta": "NCMATH2E_4B_7",
					"nameCt": "基本知識重温",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_7_7.1_A": {
					"nameEn": "The Root of a Number",
					"rnum": 71,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_7_7.1",
					"nameCt": "數的方根",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_7_7.1_B": {
					"nameEn": "Rational Indices",
					"rnum": 72,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_7_7.1",
					"nameCt": "有理數指數",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_7_7.1_C": {
					"nameEn": "Laws of Rational Indices",
					"rnum": 73,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4B_7_7.1",
					"nameCt": "有理數指數定律",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_7_7.1": {
					"nameEn": "Rational Indices",
					"rnum": 70,
					"LVNum": "7.1",
					"pMeta": "NCMATH2E_4B_7",
					"nameCt": "有理數指數",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_7_7.2_A": {
					"nameEn": "Exponential Functions and Their Graphs",
					"rnum": 75,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_7_7.2",
					"nameCt": "指數函數及其圖像",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_7_7.2_B": {
					"nameEn": "Applications of Exponential Functions",
					"rnum": 76,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_7_7.2",
					"nameCt": "指數函數的應用",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_7_7.2": {
					"nameEn": "Exponential Functions",
					"rnum": 74,
					"LVNum": "7.2",
					"pMeta": "NCMATH2E_4B_7",
					"nameCt": "指數函數",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_7": {
					"nameEn": "Exponential Functions",
					"rnum": 68,
					"LVNum": "7",
					"pMeta": "NCMATH2E_4B",
					"nameCt": "指數函數",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.1_A": {
					"nameEn": "Definition of Logarithms",
					"rnum": 79,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_8_8.1",
					"nameCt": "對數的定義",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.1_B": {
					"nameEn": "Properties of Logarithms",
					"rnum": 80,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_8_8.1",
					"nameCt": "對數的性質",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.1_C": {
					"nameEn": "Finding the Values of Logarithms Using a Calculator",
					"rnum": 81,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4B_8_8.1",
					"nameCt": "使用計算機求對數的值",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.1": {
					"nameEn": "Meaning of Logarithms",
					"rnum": 78,
					"LVNum": "8.1",
					"pMeta": "NCMATH2E_4B_8",
					"nameCt": "對數的意義",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.2": {
					"nameEn": "Logarithmic Functions and Their Graphs",
					"rnum": 82,
					"LVNum": "8.2",
					"pMeta": "NCMATH2E_4B_8",
					"nameCt": "對數函數及其圖像",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.3_A": {
					"nameEn": "Exponential Equations",
					"rnum": 84,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_8_8.3",
					"nameCt": "指數方程",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.3_B": {
					"nameEn": "Logarithmic Equations",
					"rnum": 85,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_8_8.3",
					"nameCt": "對數方程",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.3": {
					"nameEn": "Exponential Equations and Logarithmic Equations",
					"rnum": 83,
					"LVNum": "8.3",
					"pMeta": "NCMATH2E_4B_8",
					"nameCt": "指數方程和對數方程",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.4_A": {
					"nameEn": "Richter Scale",
					"rnum": 87,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_8_8.4",
					"nameCt": "黎克特制地震震級",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.4_B": {
					"nameEn": "Intensity Level of Sound",
					"rnum": 88,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_8_8.4",
					"nameCt": "聲音的音量",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.4": {
					"nameEn": "Applications of Logarithms",
					"rnum": 86,
					"LVNum": "8.4",
					"pMeta": "NCMATH2E_4B_8",
					"nameCt": "指數方程和對數方程",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.5_A": {
					"nameEn": "Logarithm Table",
					"rnum": 90,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_8_8.5",
					"nameCt": "對數表",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.5_B": {
					"nameEn": "Antilogarithm Table",
					"rnum": 91,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_8_8.5",
					"nameCt": "逆對數表",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.5_C": {
					"nameEn": "Slide Rule",
					"rnum": 92,
					"LVNum": "C",
					"pMeta": "NCMATH2E_4B_8_8.5",
					"nameCt": "計算尺",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8_8.5": {
					"nameEn": "History of Logarithm Tables and Slide Rules",
					"rnum": 89,
					"LVNum": "8.5",
					"pMeta": "NCMATH2E_4B_8",
					"nameCt": "對數表和計算尺的歷史",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_8": {
					"nameEn": "Logarithmic Functions",
					"rnum": 77,
					"LVNum": "8",
					"pMeta": "NCMATH2E_4B",
					"nameCt": "對數函數",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_9_9.0": {
					"nameEn": "Review",
					"rnum": 94,
					"LVNum": "9",
					"pMeta": "NCMATH2E_4B_9",
					"nameCt": "基本知識重温",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_9_9.1": {
					"nameEn": "Highest Common Factor and Least Common Multiple",
					"rnum": 95,
					"LVNum": "9.1",
					"pMeta": "NCMATH2E_4B_9",
					"nameCt": "最大公因式和最小公倍式",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_9_9.2_A": {
					"nameEn": "Definition of Rational Functions",
					"rnum": 97,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_9_9.2",
					"nameCt": "有理函數的定義",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_9_9.2_B": {
					"nameEn": "Multiplication and Division of Rational Functions",
					"rnum": 98,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_9_9.2",
					"nameCt": "有理函數的乘法和除法運算",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_9_9.2": {
					"nameEn": "Multiplication and Division of Rational Functions",
					"rnum": 96,
					"LVNum": "9.2",
					"pMeta": "NCMATH2E_4B_9",
					"nameCt": "有理函數的乘法和除法運算",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_9_9.3_A": {
					"nameEn": "Addition and Subtraction of Rational Functions",
					"rnum": 100,
					"LVNum": "A",
					"pMeta": "NCMATH2E_4B_9_9.3",
					"nameCt": "有理函數的加法和減法運算",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_9_9.3_B": {
					"nameEn": "Mixed Arithmetic Operations of Rational Functions",
					"rnum": 101,
					"LVNum": "B",
					"pMeta": "NCMATH2E_4B_9_9.3",
					"nameCt": "有理函數的四則混合計算",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_9_9.3": {
					"nameEn": "Addition and Subtraction of Rational Functions",
					"rnum": 99,
					"LVNum": "9.3",
					"pMeta": "NCMATH2E_4B_9",
					"nameCt": "有理函數的加法和減法運算",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B_9": {
					"nameEn": "Rational Functions",
					"rnum": 93,
					"LVNum": "9",
					"pMeta": "NCMATH2E_4B",
					"nameCt": "有理函數",
					"metaSetId": "NCMATH2E_001",
					"pub": 0
				},
				"NCMATH2E_4B": {
					"rnum": 67,
					"LVNum": "4B",
					"pMeta": "NCMATH2E",
					"metaSetId": "NCMATH2E_001",
					"pub": 1
				},
				"NCMATH2E": {
					"nameE": "EEEENNN \\(f\\left(x\\right)\\)",//"nameEn": "New Century Mathematics (2nd Edition) S4-S6",
					"rnum": 2,
					"nameCt": "函數的記號：\\(f\\left(x\\right)\\)",//"nameCt": "新世代數學（第二版）S4-S6",
					"metaSetId": "NCMATH2E_001",
					"pub": 1
				}
			}
		}
	}
});