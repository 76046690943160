import { nestSolid, objKeys, toStr } from "../../libs/libType";
import CpIco from "./CpIco";

const CpActionButton = (props) => { // base on poc ActionButton.tsx
    const {
        id, title, iconPath, onClick,
        iconSize = "fs-6", hiddenText = false,
        className:clsN, ...badProps
    } = props;
    if(objKeys(badProps).length) console.error('CpActionButton bad props', badProps);

    //const tooltipTitle = title;
    const className = 'CpActionButton action-btn d-flex justify-content-center align-items-center ' + toStr(clsN);
    return <span {...{id, className, role:'button', onClick,} }>
        {!iconPath? '': <span className={iconSize}><CpIco src={iconPath}/></span>}
        {!title? '': <span className={"title fs-8 " + (hiddenText? "d-none d-md-block": "")}>{title}</span>}
    </span>
};

export default CpActionButton;