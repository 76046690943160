import React, { useEffect, useState, useRef } from 'react';

import * as UI from '../../libs/libUI';

import { aTErrDiv1, ATHr, aTUIMust, preJS } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';

//import TabQMC from './TabQMC';
import { toAry, toObj, toStr } from '../../libs/libType';
import { ReduxBind } from '../../saga/ReduxState';
import { _ATMetaTypeByCode, _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, _ATMetaType_EX, _ATMetaType_GR, _ATMetaType_OT, _ATMetaType_QT, _ATMetaType_SU, _ATMetaType_TB } from '../../consts/ATMetaTypes';

const QSEdMTCol1 = {width:'260px', textAlign:'right', marginRight:'40px'};
const QSMSCol = {width:'180px', verticalAlign:'top'};
const QSEdMTColT = {width:'80px', textAlign:'center'};

const QSMSTMusts = [ _ATMetaType_QT, _ATMetaType_SU, _ATMetaType_TB, _ATMetaType_CU, _ATMetaType_GR, _ATMetaType_CO, _ATMetaType_DI,];  
//const QSMSTOpts = [ _ATMetaType_EX, _ATMetaType_OT ];
const CpMetaLvTickList = props => {
  const {lock, tickLbls, tickss, setterss, MSMusts, MSOpts, } = props;
  const CpMustQSMTRows = (typeName, code) => CpQSMTypeRows(_ATMetaTypeByCode(code) + '['+code+']', 1, MSMusts[code], tickss, setterss, lock);
  const CpOptQMTRows = (code, mset) => CpQSMTypeRows(_ATMetaTypeByCode(code), 0, mset, tickss, setterss, lock);

  return <>
    <div className="CpMetaLvTickList fxRow f12">
    <div className="QSEdMTCol1" style={QSEdMTCol1}>Metadata Type</div>
    <div style={QSMSCol}>Metadata Set Code</div>
    {tickLbls.map((l, i) => <div key={i} style={QSEdMTColT}><b>{l}</b></div>)}
    <div className="fxGrow" >Level</div>
  </div>
  <ATHr/>
  {CpMustQSMTRows('Question Category [qt]', _ATMetaType_QT)}
  {CpMustQSMTRows('Subject(s) [su]', _ATMetaType_SU)}
  {CpMustQSMTRows('Textbook Series [tb]', _ATMetaType_TB)}
  {CpMustQSMTRows('Curriculum [cu]', _ATMetaType_CU)}
  {CpMustQSMTRows('Grade(s) [gr]', _ATMetaType_GR)}
  {CpMustQSMTRows('Copyright(s) [co]', _ATMetaType_CO)}
  {CpMustQSMTRows('Diffculty(s) [di]', _ATMetaType_DI )}
  {Object.entries(MSOpts).map(([mstype, msets]) => CpOptQMTRows(mstype, msets)) }
  </>;
};
export default CpMetaLvTickList;

const CpQSMTypeRows = (mstypename, must, MSets, tickss, setterss, lock) => {
  return <div key={mstypename} className="CpQSMTypeRows fxRow f12" style={{whiteSpace: "nowrap", margin:'0 0 12px 0'}}>
    <div className="CpQSMTypeRows" style={QSEdMTCol1}><b>{mstypename}</b>{must? aTUIMust: ''}</div>
    <div className="fxGrow">
      {MSets && MSets.map(MSet => CpQSMSetRows(MSet, tickss, setterss, lock))}
    </div>
  </div>;
}; 
const CpQSMSetRows = (mset, tickss, setterss, lock) => {
  const msid = toStr(mset.metaSetId);
  return <div key={msid} id={msid} className="CpQSMSetRows fxRow">
    <div className="w" style={QSMSCol} >{msid}</div>
    <div className="w fxGrow ">
      {toAry(mset?.rows).map((row, lv) => CpQSMLvRows(mset, row, lv, tickss, setterss, lock))}
    </div>
  </div>;
};
const CpQSMLvRows = (mset, row, lv, tickss, setterss, lock) => {
  const id = mset.metaSetId+','+lv;
  return <div key={id} id={id} className="CpQSMLvRows fxRow" style={{margin:'0 0 4px 0'}}>
    {tickss.map((ts, i) => 
      <div key={i} id={id} style={QSEdMTColT}>{setterss[i]? UI.CheckBox0(ts.includes(id), setterss[i](id), 'chkD'+i+id, lock): ''}</div>)
    }
    <div className="fxGrow"> {toStr(row.nameEn)} | {toStr(row.nameCt)} </div>
  </div>;
};

//Metadata Prepare for Render ====== ====== ====== ====== ====== ====== ====== ======
export const QSetMSetPerType = QSetMSs => {
    const MSsPerTypes = {};
    toAry(QSetMSs).forEach( mset => {
        const {MSType} = mset;
        MSsPerTypes[MSType] = [...MSsPerTypes[MSType]||[], heads2Rows(mset)];
    });
    
    const [MSMusts, MSOpts] = [{}, {}];
    Object.entries(MSsPerTypes).forEach(([MSType, MSets]) => {
        (QSMSTMusts.includes(MSType)? MSMusts: MSOpts)[MSType] = MSets;
    });
    return {MSMusts, MSOpts};
}; 
const heads2Rows = mset => {
    const {metaSetId, heads} = mset;
    const rows = heads.map((h, i) => ( {id:metaSetId+','+i, ...h } ) ); 
    return {...mset, rows};
} 