import React, { useState } from 'react';
import preval from 'preval.macro'

import { apiCallLoad } from '../libs/awsFuncs';
import { errMsgs, lj } from '../libs/libFormat';
import * as UI from '../libs/libUI';

import { ReduxBind } from '../saga/ReduxState';
import { aTErrTxt1, preJS } from './AppUtil';
import { hasErr } from './utils/useEditDraftPub';
import { useUILang } from './utils/useUILang';
import { toAry } from '../libs/libType';
import { webAppVer } from '../libs/libAppVer';

const isdev = ['local','dev'].includes(process.env.REACT_APP_CFG); 
const formdefault = { ATLogin: (isdev?'testa':''), pass: (isdev?'123':'') };

const PageATLogin = ReduxBind((props) => {
  const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();
  const dispatch = props.dispatch;
  const ATSect = props.ATSect;

  const [fields, setFields] = useState(formdefault);
  const [fieldErrs, setFieldErrs] = useState({});

  const setATLogin = (v) => { setFields(f => ({ ...(f || {}), ATLogin: v.slice(0, 20) })) };
  const setPass = (v) => { setFields(f => ({ ...(f || {}), pass: v.slice(0, 20) })) };

  const onLogin = (res, err) => {
    //lj({ onLoginres: res }); 
    if (err) lj({onLoginErr: err});
    const errs = toAry(res.errs);
    if(errs.length) window.alert(errMsgs(errs));
    const fieldErrs = res.fieldErrs;
    setFieldErrs(fieldErrs || {});
  };

  const clickLogin = (e) => {
    UI.stopEvent(e);
    //todo local validate
    //const fieldErrs = validEditATUser(fields);
    //setFieldErrs(fieldErrs);
    if (!hasErr(fieldErrs)) {
      apiCallLoad (dispatch, '/postATLogin', onLogin, {...fields, ATSect});
    }
  };
  
  //<pre>saga.auth:{JSON.stringify(props?._saga?.auth, undefined, 2)}</pre>
  return <div className='PageATLogin w100 h100 tac'>
    <div className='w0 h100 vam'></div>
    <div className='w vam tac'>
      <h1 className='m8 p8' >Authoring Tool Login Page</h1>
      <div className='fxRow box1 br3 m8 p8' style={{justifyContent:'center'}}>
        <div className=' tar p4'>
          <div className='p8' >User : </div>
          <div className='p8' >Password : </div>
        </div>
        <div className=' tal p4'>
          <div className='p8' >{UI.Text1(fields.ATLogin, setATLogin, e => { }, 'txtUser', 'f14', {border:"1px solid grey"}, 'input user name')}
            {aTErrTxt1(fieldErrs?.ATLogin)}
          </div>
          <div className='p8' >
            {UI.Pass0(fields.pass, setPass, 'txtPass', 'f14', {border:"1px solid grey"}, 'input password')}
            {aTErrTxt1(fieldErrs?.pass)}
          </div>
          <div className='p8' > {UI.Button0('Login', clickLogin, 'btnLogin', '')} </div>
        </div>
      </div>
      <div><pre className='m4 p0'>Web App Ver: {webAppVer}</pre></div>
      <div><pre className='m4 p0'>BUILD TIME (GMT): {preval`module.exports = new Date().toLocaleString();`}</pre></div>
    </div>
  </div>;
});
export default PageATLogin;

