import React, { useState } from 'react';
import { _ATRoot, _WebRoot } from '../../consts/ATConstReact';
import { _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, _ATMetaType_EX, _ATMetaType_GR, _ATMetaType_QT, _ATMetaType_TB } from '../../consts/ATMetaTypes';

import * as UI from '../../libs/libUI';
import { ReduxBind } from '../../saga/ReduxState';
import { aTErrDiv1} from '../../AppExPf/AppUtil';
import { useMediaCache } from '../../AppExPf/utils/useMediaCache';
import { toObj } from '../../libs/libType';
import CpDropImg, { useDropImgState } from '../../AppExPf/components/CpDropImg';
import { asyncApiCallLoad_ } from '../../libs/awsFuncs';
import { HttpPostMP } from '../../libs/libHttp';
import { dataURI2File,  } from '../../libs/libEncode';
import { localDownload } from '../../libs/libDownload';


const testId = 'testESetThumb';

const PageDevMP1 = ReduxBind(props => {
    const imgState = useDropImgState();
    const onDropImg = (file, data, err) => {console.log(file); imgState.update({file, data, err});}
    const onRmImg = e => onDropImg(0, 0, ''); 
    const imgSrcE = imgState.data || imgState.url || '';

    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, props.dispatch);
    const [ mediaIds, setMediaIds ] = useState([]); 

    const hits = getMediaDLs(mediaIds);
    console.log({mediaIds});
    console.log({hits});

    const lock = false;
    const allow = !(lock);

    const clickTest = async e => { UI.stopEvent(e); 
        //testDL1('data:text/plain;base64,aGVsbG8gd29ybGQ=', 'hello.txt', 'text/plain');
        //testDL1(imgState.data, 'hello.jpg', 'image/jpg');
        const resUp = imgState?.data && await mediaUp1(props.dispatch, imgState.data);
        console.log({resUp});
        if(resUp) setMediaIds([testId]);        
        console.log('clickTest Ended')
    };

    return <div className="PageDevMP1 m8 p8">
        {UI.Button0('test apis', clickTest, 'bt')}
        {Object.entries(hits).map(([key, val]) => { 
            

        } )}
        <CpDropImg className='dropImg' imgClassName='dropImgIn' {...{onDropImg, allow, validate:validateDrop}} >
            {imgSrcE ? <img className='dropImgImg' alt="Preview" src={imgSrcE} /> : '(No Image Selected)'}
        </CpDropImg>
        <div>{UI.Button0('Delete Img', onRmImg, 'bd', 'b4 m4')}</div>
        {aTErrDiv1(imgState.err)}
    </div>;
});
export default PageDevMP1;

export const mediaUp1 = async (dispatch, dataURI) => {
    const [mtype, ESetId, thumbId, mime] = ['esetthumb', 'estdummy', 'te0', 'image/jpeg'];
    const up = async (fname, dataURI) => {
        try{
            const file = dataURI2File(dataURI, fname, mime);
            console.log(file);
            const apiFields = { name:fname, size:file.size, hide:1, mtype, ESetId, thumbId, testId };
    
            const [r1, e1] = await asyncApiCallLoad_(dispatch, '/getATM@@@@ediaUplink', apiFields );  
            const {mediaId, mediaLink} = toObj(r1);
            console.log({mediaLink});
            
            const onUpProgress = (prog, total) => {console.log({prog, total}); };
            const {url, fields} = mediaLink||{}; 
            const resUp = await HttpPostMP(url, false, fields, {file}, onUpProgress);
            console.log({resUp});
    
            const [r2, e2] = await asyncApiCallLoad_(dispatch, '/putATMediaUpDone', {mediaId} );  
            console.log({r2, e2});
            return mediaId;
        }catch(e){
            console.error(e);
        }
    };
    return await up('test.jpg', dataURI);
};

const validateDrop = file => {
    //lj({ validateDrop: file });
    if (!file) return ('msg_missingDropFile');
    if (file.size > (3 * 1000 * 1000)) return ('msg_fileSizeE1000k');
    return '';
};

const testDL1 = async (dataURI, fname, mime) => {
    const f = await dataURI2File(dataURI, fname, mime);
    console.log({f});
    localDownload(f, fname, mime); 
};