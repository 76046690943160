import React, {useMemo} from "react";
import {
    DONUT_ATTEMPTED_COLOR,
    DONUT_AVG_LINE_COLOR,
    DONUT_BODY_COLOR,
    DONUT_CORRECT_COLOR,
    DONUT_DEFAULT_SEGMENT_COLOR,
    DONUT_INCORRECT_COLOR,
    DONUT_NO_ATTEMTED_COLOR,
    DONUT_TEXT_COLOR
} from "../EPReport/ChartTypeConst";

import ChartRadialSlider from "./ChartRadialSlider";
import ChartRadial from "./ChartRadial";
import ChartDonut from "../EPReport/ChartDonut";
import { useUILang } from "../../AppExPf/utils/useUILang";

import {groupData} from "../../poc/helper/groupByNumberArray";
import { toFix1 } from "../../libs/libType";


const ChartSubjectOverview = (props) => { // base on poc SubjectOverviewChart
    const [ t, uiLang ] = useUILang();

    const {teacher, student} = props;
    const {overallScore: teacherOverallScore, data = []} = teacher || {};
    const {attempted = [], overallScore: studentOverallScore, averageScore} = student || {};

    const average = averageScore?.percentage || 0;
    const overall = studentOverallScore?.percentage || 0;
    const teacherData = student === undefined;
    const groupedData = groupData(data);

    const attemptedValue = attempted.reduce((accumulator, currentValue) => {
        const {record, status = "n"} = currentValue
        return accumulator + (["p", "f", "a"].includes(status) ? (record?.value || 0) : 0);
    }, 0);

    const attemptedTotal = attempted.reduce((accumulator, currentValue) => {
        const {record, status = "n"} = currentValue;
        return accumulator + (record?.value || 0);
    }, 0);

    const getStatusInfo = (status) => {
        let color, title, className;
        switch (status) {
            case "p":
                color = DONUT_CORRECT_COLOR
                title = t("report-status-passed")
                className = "correct"
                break;
            case "f":
                color = DONUT_INCORRECT_COLOR
                title = t("report-status-failed")
                className = "incorrect"
                break;
            case "a":
                color = DONUT_ATTEMPTED_COLOR
                title = t("report-status-completed")
                className = "attempted"
                break;
            case "n":
            default:
                color = DONUT_NO_ATTEMTED_COLOR
                title = t("report-status-expired")
                className = "not-attempted"
                break;
        };

        return { color,title,className };
    };

    const attemptedData = useMemo(() => {
        const segments = attempted.map(attempt => {
            const {record, status} = attempt;
            let color = getStatusInfo(status).color;
            return {
                color,
                value: record.percentage || 0
            };
        });

        return {
            "title": {
                color: DONUT_BODY_COLOR,
                value: <tspan textAnchor="middle">
                    <tspan fontSize={10}>{attemptedValue}</tspan>
                    <tspan fontSize={6} fill={DONUT_TEXT_COLOR}>/{attemptedTotal}</tspan>
                </tspan>
            },
            "subTitle": {
                color: DONUT_TEXT_COLOR,
                //value: <tspan x="50%" y={"24.5"} textAnchor="middle">{t("report-attempted")}</tspan>
                value: <tspan x="50%" y={"24.5"} textAnchor="middle">{t("report-result-published")}</tspan>
            },
            segments
        };
    }, [uiLang]);

    const classAverageScoreData = useMemo(() => {
        const avgp1 = toFix1(average);
        return {
            line: [{
                    color: DONUT_AVG_LINE_COLOR,
                    value: avgp1,
                    title: `${t("report-class-avg-score")}(${avgp1}%)`,
                    type: "avg"
            }],
            "title": {
                color: DONUT_DEFAULT_SEGMENT_COLOR,
                value: <>{overall}%</>
            },
            "subTitle": {
                color: DONUT_TEXT_COLOR,
                value: <tspan x="50%" y={"24.5"} textAnchor="middle">{t("report-overall-score-percentage")}</tspan>
            },
            "segments": [{
                    color: DONUT_DEFAULT_SEGMENT_COLOR,
                    value: overall,
            }]
        }
    }, [uiLang]);

    if (teacherData) { return <>
        {/*<div className={"radial-chart-container d-flex align-items-center max-size-chart-500 max-width m-auto"}>*/}
        <div className={"radial-chart-container d-flex align-items-center max-size-chart-400 max-width m-auto"}>
            <ChartRadialSlider dataLength={groupedData.length}>
                {groupedData.map((gData, idx) => <section key={idx}><ChartRadial
                    label={t("report-overall-average-score-percentage")} overallScore={teacherOverallScore}
                    data={gData}/>
                </section>)}
                {groupedData.length === 0 && <section>
                    <ChartRadial label={t("report-overall-average-score-percentage")}/>
                </section>}
            </ChartRadialSlider>
        </div></>
    };

    return <div className={"subject-overview-container"}>
        <div className={"col d-flex flex-column justify-content-center align-items-center gap-3"}>
            <div className={"d-flex px-3"}>
                <ChartDonut {...attemptedData}/>
            </div>
            <div className="d-flex subject-overview-status flex-column flex-xl-row gap-3 flex-wrap justify-content-center align-items-start semi-bold fs-6">
                {attempted.map((attempt, idx) => {
                    const info = getStatusInfo(attempt.status)
                    const {className, title} = info
                    return <span className={"sample-line"} key={idx}>
                        <span className={`sample-${className}-cube`}></span>
                        {title} ({attempt?.record?.value})
                    </span>
                })}
            </div>
        </div>
        <div className={"col d-flex flex-column justify-content-center align-items-center gap-3"}>
            <div className={"d-flex px-3"}>
                <ChartDonut {...classAverageScoreData}/>
            </div>
            <div className="d-flex subject-overview-status flex-column gap-3 flex-wrap justify-content-center align-items-start semi-bold fs-6">
                <span className={"sample-line"}>
                    <span className={`sample-avg-line`}></span>
                    <span className={"text-wrap"}>
                        <span>{t("report-class-avg-score")}</span> <span>({average}%)</span>
                    </span>
                </span>
            </div>
        </div>
    </div>
};

export default ChartSubjectOverview;