import React, { useCallback, useMemo, useState, HTMLProps } from "react";
import { useTranslation } from "react-i18next";
import {
    WholeKeyStageMetadataProgress,
    WholeKeyStageMetadataProgressChartType,
    MetadataProgressItemType,
    ProgressType,
    YearlyScore,
} from "../../../../type/ChartType";
import TableHeaderSortingButton from "./shared/TableHeaderSortingButton";
import AverageScoreVisibilityToggle from "./AverageScoreVisibilityToggle";
import AverageScoreProgressLegend from "./AverageScoreProgressLegend";
import AverageScoreProgressChart from "./AverageScoreProgressChart";
import IconComponent from "../../../schema/components/custom/icon/IconComponent";
import { IconList } from "../../../../static/icons/IconList";
import ReportLegend from "./legend/ReportLegend";
import { LegendType } from "../../../../type/LegendType";

const longlongtitle = 'longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle longlongtitle ';
const bonTrue = true;


interface Props extends WholeKeyStageMetadataProgressChartType {
    selectedStudentInfo?: MetadataProgressItemType;
}

interface BtnProps extends HTMLProps<HTMLSpanElement> {
    open: boolean
}

const ExpandBtn = (props: BtnProps) => {
    const { open } = props
    return <span role={"button"}
        className={`text-exercise-third-btn metadata-filter-toggle fs-5`} {...props}>
        <IconComponent src={open ? IconList.general.openNode : IconList.general.closeNode} />
    </span>
}

const AverageScore: React.FC<{
    type: ProgressType,
    rowData: WholeKeyStageMetadataProgress,
    referenceItems: MetadataProgressItemType[],
    index?: number,
    parentLine?: YearlyScore[],
    parentTitleIndex?: string,
    parentTitle?: string,
    sticky?: { top: boolean, bottom: boolean },
    isFirstRender?: boolean,
    scoreVisible?: boolean,
    classAverageVisible?: boolean,
    onRowHover: (rowId: string) => void,
    isHovered: boolean,
    hoveredRowId: string | null,
    selectedStudentInfo?: MetadataProgressItemType,
}> = (props) => {
    const {
        type, rowData, referenceItems = [], index = 0,
        parentLine, parentTitleIndex, parentTitle, sticky = { top: false, bottom: false },
        isFirstRender = false,
        scoreVisible, classAverageVisible,
        onRowHover, isHovered, hoveredRowId,
        selectedStudentInfo
    } = props;

    const {
        titleIndex,
        title,
        averageScore,
        subAverageScore = [],
        children = [],
        referenceId,
        classAverages
    } = rowData;

    const [subOpen, setSubOpen] = useState(bonTrue);

    const handleSubOpen = () => {
        setSubOpen(!subOpen);
    };

    const studentInfo = (type === "sc" && subAverageScore.length === 0)
        ? referenceItems?.find(referenceItem => referenceItem.id === referenceId)
        : undefined;

    const isSelectedStudentCase = type === "s";

    return (
        <>
            {scoreVisible && isFirstRender && (
                <div className="score-on-first-row-gap"></div>
            )}
            <div className={"d-flex gap-3 average-score-wrapper"}>
                <div className={`${isSelectedStudentCase ? "col-average-score-title-tmp-space" : "col-average-score-title"}`}>
                    {subAverageScore.length > 0 && type === "sc" && (
                        <span className={"col-expand mt-2"}>
                            <span className={"col-header-expand"}>
                                <ExpandBtn open={subOpen} onClick={handleSubOpen} />
                            </span>
                        </span>
                    )}
                    {type === "sc" && subAverageScore.length === 0 && studentInfo && (
                        <span className={"col-mini-student text-truncate"}>
                            <span className={"col-mini-student-no"}>{studentInfo.no}</span>
                            <span className={"col-mini-student-name ms-2"}>{studentInfo.title+longlongtitle}</span>
                        </span>
                    )}
                </div>

                <AverageScoreProgressChart
                    isSelectedStudentCase={isSelectedStudentCase}
                    averageScore={averageScore}
                    parentLine={isSelectedStudentCase ? classAverages : parentLine}
                    parentTitleIndex={parentTitleIndex}
                    parentTitle={parentTitle}
                    studentInfo={studentInfo}
                    sticky={{
                        ...sticky,
                        bottom: sticky?.bottom || (subAverageScore.length > 0 || children.length > 0)
                    }}
                    isFirstRender={isFirstRender}
                    scoreVisible={scoreVisible}
                    classAverageVisible={classAverageVisible}
                    titleIndex={titleIndex || ''}
                    title={title+longlongtitle || ''}
                    onRowHover={onRowHover}
                    isHovered={isHovered}
                    rowId={rowData.id}
                    selectedStudentInfo={selectedStudentInfo}
                />
            </div>

            {type === "sc" && subOpen && subAverageScore.map((child, idx) => (
                <AverageScore
                    key={child.id}
                    type={type}
                    rowData={child}
                    referenceItems={referenceItems}
                    index={index + 1}
                    parentLine={averageScore}
                    parentTitleIndex={titleIndex}
                    parentTitle={title+longlongtitle}
                    sticky={{ top: true, bottom: idx < subAverageScore.length - 1 }}
                    scoreVisible={scoreVisible}
                    classAverageVisible={classAverageVisible}
                    onRowHover={onRowHover}
                    isHovered={hoveredRowId === child.id}
                    hoveredRowId={hoveredRowId}
                    selectedStudentInfo={selectedStudentInfo}
                />
            ))}
        </>
    );
};

const RowData = (props: {
    type: ProgressType,
    rowData: WholeKeyStageMetadataProgress,
    referenceItems: MetadataProgressItemType[],
    index?: number,
    parentLine?: YearlyScore[],
    parentTitleIndex?: string,
    parentTitle?: string,
    sticky?: { top: boolean, bottom: boolean },
    isFirstRender?: boolean,
    scoreVisible?: boolean,
    classAverageVisible?: boolean,
    onRowHover: (rowId: string) => void,
    isHovered: boolean,
    hoveredRowId: string | null,
    selectedStudentInfo?: MetadataProgressItemType
}) => {
    const {
        type, rowData, referenceItems = [], index = 0,
        parentLine, parentTitleIndex, parentTitle, sticky = { top: false, bottom: false },
        isFirstRender = false,
        scoreVisible, classAverageVisible,
        onRowHover,
        isHovered,
        hoveredRowId,
        selectedStudentInfo,
    } = props

    const {
        titleIndex,
        title,
        children = [],
    } = rowData

    const [open, setOpen] = useState(bonTrue || (index < 1))

    const handleOpen = () => {
        const newOpen = !open;
        setOpen(newOpen);
    }

    return <>
        <div className={`d-flex gap-2 w-100 average-score-chart-row ${isHovered ? 'hovered' : ''}`}>
            <div className={`col-fix-metadata ${scoreVisible && isFirstRender ? 'mt-4' : 'mt-2'}`} style={{ paddingLeft: `${index * 3}rem` }}>
                <div className="left-wrapper">
                    <span
                        className={`${index === 0 ? "" : "col-header-expand"} ${(children.length > 0 && children[0].title !== undefined) || index !== 0 ? "me-3" : ""}`}>
                        {
                            children.length > 0 && children[0].title !== undefined && <>
                                {
                                    <ExpandBtn open={open} onClick={() => handleOpen()} />
                                }
                            </>
                        }
                    </span>
                    <span className='text-wrap title-index'>
                        <span className='index'>{titleIndex || ''}</span>
                    </span>
                </div>
                <div className="right-wrapper">
                    <span className={"text-wrap overflow-auto"}>{title+longlongtitle}</span>
                </div>
            </div>

            <div className={`col-fix-average-score-percentage-2 col-whole-key-stage ${type === "sc" ? "selected-class" : ""} flex-column`}>
                <AverageScore
                    type={type}
                    rowData={rowData}
                    referenceItems={referenceItems}
                    index={index}
                    parentLine={type === "s" ? rowData.classAverages : parentLine}
                    parentTitleIndex={parentTitleIndex}
                    parentTitle={parentTitle}
                    sticky={sticky}
                    isFirstRender={isFirstRender}
                    scoreVisible={scoreVisible}
                    classAverageVisible={classAverageVisible}
                    onRowHover={onRowHover}
                    isHovered={isHovered}
                    hoveredRowId={hoveredRowId}
                    selectedStudentInfo={selectedStudentInfo}
                />
            </div>
        </div>

        {/*Metadata node*/}
        {open && children.map((child, idx) => {
            return <RowData
                key={child.id}
                type={type}
                rowData={child}
                referenceItems={referenceItems}
                index={index + 1}
                scoreVisible={scoreVisible}
                classAverageVisible={classAverageVisible}
                onRowHover={onRowHover}
                isHovered={hoveredRowId === child.id}
                hoveredRowId={hoveredRowId}
                selectedStudentInfo={selectedStudentInfo}
            />
        })}
    </>
}

const WholeKeyStageMetadataProgressChart = (props: Props) => {
    const { t } = useTranslation(['schema']);
    const { title, type, items = [], data = [], selectedStudentInfo } = props;

    const [scoreVisible, setScoreVisible] = useState(bonTrue);
    const [classAverageVisible, setClassAverageVisible] = useState(bonTrue);
    const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

    const handleScoreToggle = (isVisible: boolean) => {
        setScoreVisible(isVisible);
    };

    const handleClassAverageToggle = (isVisible: boolean) => {
        setClassAverageVisible(isVisible);
    };

    const handleRowHover = useCallback((rowId: string) => {
        setHoveredRowId(rowId);
    }, []);

    const renderSpans = (count: number) => {
        return Array.from({ length: count }).map((_, index) => <span key={index}></span>);
    };

    const lineLeft = (percentage: number = 0) => {
        if (percentage === 0) return '0%'

        return `calc(${percentage}% - 1.5px)`
    }

    const renderXAxisNumbers = () => {
        return Array.from({ length: 11 }, (_, i) => (
            <div
                key={i}
                className="x-axis-number"
                style={{
                    left: lineLeft(i * 10),
                    position: 'absolute',
                    top: '-15px',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                {i * 10}
            </div>
        ));
    }

    const renderData = () => {
        return (
            <tr>
                <td colSpan={2} style={{ paddingTop: '30px' }}>
                    <div className="row-data-wrapper">
                        <div className="graph-bg">
                            <div className="graph-x-axis-wrapper">
                                {renderXAxisNumbers()}
                            </div>
                            <div className="graph-grid-lines-wrapper">
                                {renderSpans(11)}
                            </div>
                        </div>
                        {data?.map((d, index) => (
                            <RowData
                                key={d.id}
                                type={type}
                                rowData={d}
                                referenceItems={items}
                                isFirstRender={index === 0}
                                scoreVisible={scoreVisible}
                                classAverageVisible={classAverageVisible}
                                onRowHover={handleRowHover}
                                isHovered={hoveredRowId === d.id}
                                hoveredRowId={hoveredRowId}
                                selectedStudentInfo={selectedStudentInfo}
                            />
                        ))}
                    </div>
                </td>
            </tr>
        );
    };

    const getLegend = useMemo(() => {
        return type === "sc" ? LegendType.KEY_STAGE_REPORT_SELECTED_CLASS : LegendType.KEY_STAGE_REPORT_SELECTED_STUDENT;
    }, [type]);

    return (
        <div className={"flow-container scroll-padding whole-key-stage"}>
            <AverageScoreVisibilityToggle
                onScoreToggle={handleScoreToggle}
                onClassAverageToggle={handleClassAverageToggle}
                scoreVisible={scoreVisible}
                classAverageVisible={classAverageVisible}
            />
            <table className={"report report-table-progress-chart metadata-progress-chart whole-key-stage semi-bold"}>
                <thead>
                    <tr>
                        <th className={"col-metadata"}>
                            <span>
                                <TableHeaderSortingButton title={title+longlongtitle} type={"metadata"} sorting={false} />
                            </span>
                        </th>
                        <th className={"col-average-score-percentage-2"}>
                            <span>
                                <TableHeaderSortingButton
                                    title={t("report-average-score-percentage")}
                                    type={"report-average-score-percentage"}
                                    tips={<ReportLegend legend={getLegend} />}
                                    sorting={false}
                                />
                            </span>
                        </th>
                    </tr>
                    <tr className="tr-legend"><td><AverageScoreProgressLegend /></td></tr>
                </thead>
                <tbody>
                    {renderData()}
                </tbody>
            </table>
        </div>
    );
};

export default WholeKeyStageMetadataProgressChart;