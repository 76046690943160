const FileExtensionIcon = (props) => { // base on poc FileExtensionIcon.tsx
    const {name, myStyle} = props

    if (["jpg", "png", "bmp", "jpeg","svg"].includes(name.toLowerCase())) {
        return <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeMiterlimit="10"
            viewBox="0 0 16 16"
            style={myStyle}
        >
            <g stroke="#000">
                <path
                    fill="#fff"
                    fillRule="nonzero"
                    d="M11.75 4.25v10.208c0 .575-.467 1.042-1.042 1.042H1.542A1.043 1.043 0 01.5 14.458V1.542C.5.967.967.5 1.542.5H8l3.75 3.75z"
                    transform="translate(1.875)" stroke={"currentColor"}
                ></path>
                <g fill="none" transform="translate(1.875)">
                    <path
                        d="M11.75 4.25H9.206C8.54 4.25 8 3.71 8 3.044V.5M.5 10.993h10.796M.5 11.302l4.35-3.836 2.289 2.697"
                        stroke={"currentColor"}></path>
                    <path d="M6.122 9.384l2.991-3.082 2.514 2.628" stroke={"currentColor"}></path>
                    <circle
                        cx="-1.25"
                        r="1.25"
                        transform="matrix(0 -1 -1 0 4 3.052)" stroke={"currentColor"}
                    ></circle>
                </g>
            </g>
            <text
                x="6"
                y="13.474"
                fontFamily="Helvetica-Bold, Helvetica"
                fontSize="4"
                fontWeight="700"
                transform="translate(1.875)"
                dominantBaseline="middle"
                textAnchor="middle"
            >
                {name}
            </text>
        </svg>
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeMiterlimit="10"
            viewBox="0 0 16 16"
            style={myStyle}
        >
            <g fill="none" stroke="currentColor">
                <path
                    d="M14.125 4.25v10.208c0 .575-.467 1.042-1.042 1.042H3.917a1.043 1.043 0 01-1.042-1.042V1.542C2.875.967 3.342.5 3.917.5h6.458l3.75 3.75z"></path>
                <path d="M14.125 4.25h-2.544c-.666 0-1.206-.54-1.206-1.206V.5"></path>
            </g>
            <path
                fillRule="nonzero"
                d="M10.906 12.802H2.843a.97.97 0 01-.968-.969V8.771a.97.97 0 01.968-.969h8.063a.97.97 0 01.969.969v3.062a.97.97 0 01-.969.969"
                fill="currentColor"
            ></path>
            <text
                x="6.791"
                y="11"
                fill="#fff"
                fontFamily="Helvetica-Bold, Helvetica"
                fontSize="4"
                fontWeight="700"
                dominantBaseline="middle"
                textAnchor="middle"
            >
                {name}
            </text>
        </svg>
    );
}

export default FileExtensionIcon