import React from 'react';
import { toObj, toStr } from '../../libs/libType';
import { subState } from '../utils/useChain';
import {_alignClass } from './ViewExOptions';
import { viewExLabel, exATUIText, exTextArea} from './ViewExCommon';
import { useUILang } from '../utils/useUILang';

const ViewExCtnTxt = props => {
    const {lock, showEn, ECtn, replaceECtn, PVMode, idx } = props; 
    const [ t ] = useUILang();
    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 

    const lang = showEn?'en':'ct';
    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));
    const myAlign = toStr(ec.align);
    const title = toStr(ec.title);
    const desc = toStr(ec.desc);
    
    const showContent = (title, desc ) => {
        let exClass='';
        if (myAlign) exClass = (_alignClass[myAlign]?_alignClass[myAlign]:_alignClass['center']);
    
        return <div className={'ViewExCtnItemLock ' + (PVMode!==1?' Single':' List')}>
            <div id={'exCtnContent'+idx} className='ViewExCtnItemLockContent'>
                <div className={'flexRowStart semi-bold '+exClass+(PVMode>=0?' f40':'')}>{title}</div>
                <div className={'flexRowStart '+exClass+(PVMode>=0?' f28':'')}>
                    <div className='flexRowCenterFit' style={{whiteSpace: 'pre-wrap'}}>{desc}</div>
                </div>
            </div>
        </div>;
    };

    if (lock || PVMode >=0) {
        return showContent(title,desc);
    };

    return <div className='ViewExCtnItem'>
        <p/>
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.section.title'),'title')}{exATUIText(ec.title, setTmpField('title'), 'vid')}
        </div>
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.section.desc'),'desc')}{exTextArea(ec.desc, setTmpField('desc'), 'desc')}
        </div>
    </div>
};
export default ViewExCtnTxt; 
