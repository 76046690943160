import React from "react";

const CpQuestionBox = (props) => { // base on poc QuestionBox.tsx
    const {num, miniLayout = false, className = "" } = props
    if (miniLayout) {
        return <span className={"border rounded-light px-3 text-nowrap"}>{num}</span>
    }
    return <div className={`field ${className}`}>
        <div className={"field d-flex justify-content-end align-items-center"}>
            <span className={"border rounded-light px-3 text-nowrap"}>{num}</span>
        </div>
    </div>
};

export default CpQuestionBox;