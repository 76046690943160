import React, { useRef, useState, useEffect, useMemo } from 'react';
import { toObj, toStr } from "../../../libs/libType";
import { autoId } from "../../AppUtil";
import { rmMediaSrc } from "../../../libs/libHtml";
import { MathJax } from 'better-react-mathjax';
import CpMJX from '../../../AppExPFUser/_components/CpMJX';

import 'ckeditor5/ckeditor5.css';
import '../../../css/ckeditor.css';

const medias = ["img","video","audio"];

//<DrawingCanvas id={id} setCanvas={setCanvas} updateResp={updateResp} mouseUp={mouseUp}/>
const QEditorReadOnly = (props) => {
    const myRef = useRef(null);
    const getAllEle = tag => myRef.current.getElementsByTagName(tag);
    //const [mjReady, setMjReady] = useState(0);
    const [mjReady, setMjReady] = useState(0);

    const {noBackground, mediaDLs, getMediaDLs, exStyle, callBack=0, canVis=0} = props;

    useEffect(()=>{ 
        if (myRef.current && getMediaDLs && mjReady) {
            const keys=[];
            medias.forEach(med =>{
                Array.from(getAllEle(med)).forEach(obj=> {
                    if (obj.hasAttribute('oupid')) keys.push(obj.getAttribute('oupid'));
                });
            });
            getMediaDLs(keys);
        };
    },[myRef.current, mjReady]);

    useEffect(()=>{ 
        if (myRef.current && mjReady) {
            medias.forEach(med =>{
                Array.from(getAllEle(med)).forEach((obj, ii)=>{
                    if (obj.hasAttribute('oupid') && mediaDLs[obj.getAttribute('oupid')]) {
                        //obj.setAttribute('crossorigin', 'anonymous');
                        obj.setAttribute('src', mediaDLs[obj.getAttribute('oupid')].dl);
                    };
                });
            });
        };
    },[myRef.current, mjReady, mediaDLs]);

    //const typeSetInit = () => { console.log('typeSet init !!'); };
    //const typeSetDone = () => { console.log('typeSet finish !!'); };
    //onInitTypeset={typeSetInit} onTypeset={typeSetDone} >    
    const exS = exStyle? exStyle :{};
    const data = rmMediaSrc(toStr(props.data));
    const minH = data? {} :{minHeight:'35px'};
    const myKey = useMemo(() => autoId(),[data]);
    //cb={callBack}
    const _callBack = () => {
        setMjReady(1);
        callBack && callBack();
        //console.log('onTypeset');
    };

    return <><CpMJX dynamic={false} onTypeset={_callBack}>
        <div ref={myRef} className={"ck-content EditorReadOnly " + (noBackground? 'noBackground ': '')}
        dangerouslySetInnerHTML={{ __html:data}} style={{...toObj(exS), ...toObj(minH)}}/>
    </CpMJX></>;

    /*    
    return <React.Fragment key={myKey}><MathJax dynamic={false} onTypeset={_callBack}>
        <div ref={myRef} className={"ck-content EditorReadOnly " + (noBackground? 'noBackground ': '')}
        dangerouslySetInnerHTML={{ __html:data}} style={{...toObj(exS), ...toObj(minH)}}/>
    </MathJax></React.Fragment>;
    */    
};
export default QEditorReadOnly;

/*
const QEditorReadOnly_test = (props) => {
    const myRef = useRef(null);
    const getAllEle = tag => myRef.current.getElementsByTagName(tag);

    const {noBackground, mediaDLs, getMediaDLs, exStyle, canVis=0} = props;
    const exS = exStyle? exStyle :{};
    const data = rmMediaSrc(toStr(props.data));
    const minH = data? {} :{minHeight:'35px'};    
    const myKey = useMemo(() => autoId(),[data]);

    const mjContext = useContext(MathJaxBaseContext);
    const doTypeSet = () => {
        if (myRef.current && mjContext) {
            console.log('div and mj ready');
            mjContext.promise.then(async mj => {
                const xx = document.getElementById('ckr'+myKey);
                if (xx) {
                    console.log('1 do typeset start....', xx);
                    const ret = await mj.typesetPromise();
                    console.log('2 do typeset done....', ret);
                } else { console.log('cannot find div', 'ckr'+myKey)};
            });
        };
    };
    useEffect(()=>{ 
        if (myRef.current && getMediaDLs) {
            const keys=[];
            medias.forEach(med =>{
                Array.from(getAllEle(med)).forEach(obj=> {
                    if (obj.hasAttribute('oupid')) keys.push(obj.getAttribute('oupid'));
                });
            });
            getMediaDLs(keys);
        };
        doTypeSet();
    },[myRef.current]);

    useEffect(()=>{ 
        if (myRef.current) {
            medias.forEach(med =>{
                Array.from(getAllEle(med)).forEach((obj, ii)=>{
                    if (obj.hasAttribute('oupid') && mediaDLs[obj.getAttribute('oupid')]) {
                        obj.setAttribute('src', mediaDLs[obj.getAttribute('oupid')].dl);
                    };
                });
            });
        };
    },[myRef.current, mediaDLs]);

    //const typeSetInit = () => { console.log('typeSet init !!'); };
    //const typeSetDone = () => { console.log('typeSet finish !!'); };
    //onInitTypeset={typeSetInit} onTypeset={typeSetDone} >    
    
    return <React.Fragment key={myKey}>
        <div id={'ckr'+myKey} ref={myRef} className={"ck-content EditorReadOnly " + (noBackground? 'noBackground ': '')}
        dangerouslySetInnerHTML={{ __html:data}} style={{...toObj(exS), ...toObj(minH)}}/>
    </React.Fragment>; 
};
*/