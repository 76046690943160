import * as UI from '../../libs/libUI';

import { arrayAddOrDelete_ } from '../../libs/libArray';


import { _ATSysQTypes } from '../../consts/ATSysQType';
import { preJS } from '../AppUtil';

const SQTs = _ATSysQTypes();
export const SysQTypeTree = props => {
    const {sqtChks, setSqtChk, opens, setOpens} = props;
    const setOpen = (id, o) => e => { UI.stopEvent(e); setOpens(os => arrayAddOrDelete_(os, id, o)) };  

    const id = 'SQT';
    const open = opens.includes(id);

    return <div>
        <div><b>System Question Category </b><B1 {...{id, open, setOpen}} /></div>
        {open ?
        <div style={{margin:'0 0 6px 16px'}}>{Object.entries(SQTs).map(([sqtCode, sqtName])=>{
            return <div key={sqtCode}>
                <label><T1 id={sqtCode} setTick={setSqtChk} ticks={sqtChks}/>
                {sqtName}</label>
            </div>;
       })}</div>:''}
    </div>;
};

export const CpTypeMetaTrees = props => {
    const {typeName, metaSets, } = props;
    return <div>
        <div><b>{typeName}</b></div>
        {metaSets &&
        <div style={{margin:'0, 0, 0, 24px'}}>{Object.entries(metaSets).map(([msid, metaSet]) =>  
            <TypeMetaTree key={'MTT'+msid} {...{metaSet}}  {...props}  />
        )}</div>
         }
    </div>;
};

const cssB1 = {cursor:'pointer', display:'inline-block', borderRadius:'12px', border:'2px solid #bbb', 
    padding:'6px 0 8px 0', minWidth:'20px', margin:'0 0 0 12px', 
    fontSize:'16px', lineHeight:'0px', color:'#bbb', textAlign:'center' }
const B1 = ({id, open, setOpen}) => {
    return <div onClick={setOpen(id, !open)} style={cssB1} ><b>{open? '-': '+'}</b></div>;            
};

const T1 = props => {
    const {id, ticks, setTick} = props;
    return <>{ UI.CheckBox0(ticks.includes(id), setTick(id), 'chkt')}  </>;
};

const TypeMetaTree = props => {
    const {metaSets, metaSet, ticks, setTick, opens, setOpen, ...prop} = props;
    const para = {...props, opens, setOpen};

    const {MSNameCt, MSNameEn, metaSetId, tree} = metaSet;
    
    const hasChild = Object.keys(tree).length;
    const open = hasChild && opens.includes(metaSetId);
    return <div style={{margin:'0 0 6px 8px'}}>
        <div>
            {metaSetId}.{MSNameEn} - {MSNameCt}
            {hasChild?<B1 id={metaSetId} open={open} setOpen={setOpen} />:''}
        </div>
        {open ?
        <div style={{margin:'0 0 6px 16px'}}>{Object.entries(tree).map(([mtid, node])=>{
            const meta = metaSet.metas[mtid];
            return <CpMetaTree2Node key={'MTN'+mtid} {...para} meta={meta} lv={0} />
        })}</div>:''}
    </div>;
};

const joinId = (ms, m) => ms+','+m;
const CpMetaTree2Node = props => {
    const { ticks, setTick, opens, setOpen, meta, lv} = props;
    const { metaSets, metaSet } = props;

    const { metaSetId, metaId, childs, LVNum, nameEn, nameCt }  = meta;
    const id = joinId(metaSetId, metaId);

    const hadChild = Object.keys(childs).length;
    const open = hadChild && opens.includes(id);
    return <div> 
        <div className='flexRowStartFit'>
            <label>
            <T1 id={id} {...props} />
            {LVNum} {nameEn} - {nameCt}</label>
            {hadChild?<B1 id={id} open={open} setOpen={setOpen} />:''}
        </div>
        {open ?
        <div style={{margin:'0 0 0 16px'}}>{Object.entries(childs).map(([cMetaId, node]) => {
            const cMeta = metaSet.metas[cMetaId]; 
            return <CpMetaTree2Node key={cMetaId} {...props} lv={lv+1} meta={cMeta}/>
        })}</div>:''}
    </div>;

};

