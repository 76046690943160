import Ajv from 'ajv';
import axios from 'axios';
import { ValidationError, APIError } from './error';
import * as qs from './querystring';
import camelKeysToUnderscore from './func/camelKeysToUnderscore';

function urlWithOptions(url, options) {
  if (options == null || typeof options !== 'object' || Object.keys(options).length === 0) {
    return url;
  }

  const queryParams = camelKeysToUnderscore(options);

  return `${url}?${qs.stringify(queryParams)}`;
}

/**
 * MiddleLayerClient <br/>
 * 1.) is an API client for calling Middle Layer Data API and <br/>
 * 2.) provides helper function for integration with oidc client library.
 *
 * @class
 */
class MiddleLayerClient {
  /**
   *  MiddleLayerClient constructor.
   *
   * @param {Object} config - MiddleLayerClient configuration object.
   * @param {string} config.dataEndpoint - The Middle Layer server data API endpoint.
   * @param {string} config.authEndpoint - The Middle Layer server auth API endpoint.
   * @param {string} config.apiKey - The API Key to use for calling Middle Layer API.
   * @param {string} config.language - The preferred language of the response, can be 'en' or 'zh-hant'
   *
   * @example
   * import { MiddleLayerClient } from '@oup/oupc-middle-layer-client';
   *
   * const mlClient = new MiddleLayerClient({
   *   dataEndpoint: 'https://accounts.oupchina.com.hk/data/v1',
   *   authEndpoint: 'https://accounts.oupchina.com.hk/auth/v1',
   *   apiKey: '****'
   * });
   */
  constructor(config) {
    this.axios = axios.create();
    this.config = config;
    this._schemasByID = {};

    /**
     * <p>
     * Setting a valid accessToken is required to call Middle Layer API successfully.
     * When the MiddleLayerClient makes API call to server the accessToken is put
     * in the HTTP request header <code>Authorization</code> as a <code>Bearer</code> type token.
     * </p>
     *
     * <p>
     * If {@link MiddleLayerClient#linkOIDCUserManager|linkOIDCUserManager} is called, developers do not need to
     * set the accessToken manully. MiddleLayerClient would set / remove the accessToken automatically according
     * to user events.
     * </p>
     *
     * @type {string}
     *
     * @example
     * // set accessToken after user login to Middle Layer
     * mlClient.accessToken = '****';
     *
     * // remove accessToken after user logout
     * mlClient.accessToken = null;
     */
    this.accessToken = null;

    /**
     * @private
     */
    this.ajv = new Ajv();
    this.ajv.addSchema({
      $id: 'http://example.com/schemas/defs.json',
      definitions: {
        arrayOfString: {
          type: 'array',
          items: {
            type: 'string'
          }
        }
      }
    });
  }

  /**
   * The Middle Layer server data API endpoint.
   * @type {string}
   */
  get dataEndpoint() {
    return this.config.dataEndpoint;
  }

  /**
   * The Middle Layer server auth API endpoint.
   * @type {string}
   */
  get authEndpoint() {
    return this.config.authEndpoint;
  }

  /**
   * The API Key to use for calling Middle Layer API.
   * @type {string}
   */
  get apiKey() {
    return this.config.apiKey;
  }

  /**
   * The preferred language of responses
   * @type {string}
   */
  get language() {
    return this.config.language;
  }

  /**
   * Creates a HTTP request to Middle Layer for current user info
   *
   * @returns {Promise<module:AuthResponse~UserInfo>}
   *
   * @throws {APIError}
   *
   * @example
   * // fetch all entitlements by school id
   * const userInfo = await mlClient.fetchUserInfo();
   *
   * console.log(userInfo);
   * &#47;*
   * {
   *   "clientID": "demo/esas-auth"
   *   "sub": "teacher_0001"
   *   "userID": "teacher_001"
   *   "https://oupchina.com.hk/user_type": "teacher"
   *   "https://oupchina.com.hk/academic_year": "2019-2020"
   *   "https://oupchina.com.hk/identity_provider": "esas"
   *   "https://oupchina.com.hk/identity_provider_user_data": {
   *     "loginID": "0001@iSolution.com"
   *     "usertoken": "0001"
   *   }
   *   "https://oupchina.com.hk/initial_login_client_id": "demo/esas-auth"
   *   "https://oupchina.com.hk/last_login_client_id": "demo/esas-auth"
   *   "https://oupchina.com.hk/keep_me_signed_in": false
   *   "https://oupchina.com.hk/name#en": "Test Teacher"
   *   "https://oupchina.com.hk/name#zh-Hant": "Test Teacher"
   *   "https://oupchina.com.hk/school#en": "Test School"
   *   "https://oupchina.com.hk/school#zh-Hant": "Test School"
   *   "https://oupchina.com.hk/school_id": "1000"
   *   "https://oupchina.com.hk/teacher_position": "Teacher"
   * }
   * *&#47;
   */
  async fetchUserInfo() {
    const requestConfig = {
      baseURL: this.authEndpoint,
      method: 'GET',
      url: `/oauth/userinfo`,
      headers: {}
    };

    if (this.accessToken != null) {
      requestConfig.headers.Authorization = `Bearer ${this.accessToken}`;
    }

    if (this.language != null) {
      requestConfig.headers['accept-language'] = `${this.language}`;
    }

    try {
      const resp = await this.axios.request(requestConfig);
      return resp.data;
    } catch (error) {
      this._handleError(error);
    }
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching entitlement data of a school
   *
   * @param {string} schoolID - School ID or "me" for own school.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.masterCodes] - Filter by list of master codes in series code
   * @param {string[]} [options.books] - Filter by a list of books in series code
   * @param {string[]} [options.categories] - Filter by list of categories in series code
   * @param {string[]} [options.editions] - Filter by list of editions in series code
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   * @param {string} [options.groupBy] - Group result by field, only accept `grade`, `code`
   *
   * @returns {Promise<module:RestfulObject~Entitlement[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all entitlements by school id
   * const entitlements = await mlClient.fetchEntitlementsBySchoolID('me', {
   *   // All following parameters are optional
   *   masterCodes: ['NPPTH'],
   *   books: ['1A'],
   *   categories: ['D1'],
   *   editions: ['SB']
   * });
   *
   * console.log(entitlements.length); // 1
   * console.log(entitlements);
   * &#47;*
   * [
   *   {
   *      code: 'NPPTH#1A#SB#D1',
   *      masterCode: 'NPPTH',
   *      book: '1A',
   *      category: 'D1',
   *      edition: 'SB',
   *      grade: 'P1',
   *      classID: '2019-2020+SAP001+nYiGqgmglq',
   *      schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchEntitlementsBySchoolID(schoolID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          masterCodes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          books: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          categories: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          editions: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          groupBy: {
            type: 'string'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchEntitlementsBySchoolID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/schools/${schoolID}/entitlements`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching entitlement data of a class
   *
   * @param {string} classID - Class ID or "me" for own class. "me" could only be used when logged in as student.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.masterCodes] - Filter by list of master codes in series code
   * @param {string[]} [options.books] - Filter by a list of books in series code
   * @param {string[]} [options.categories] - Filter by list of categories in series code
   * @param {string[]} [options.editions] - Filter by list of editions in series code
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~Entitlement[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all entitlements by class id
   * const entitlements = await mlClient.fetchEntitlementsByClassID('me', {
   *   // All following parameters are optional
   *   masterCodes: ['NPPTH'],
   *   books: ['1A'],
   *   categories: ['D1'],
   *   editions: ['SB']
   * });
   *
   * console.log(entitlements.length); // 1
   * console.log(entitlements);
   * &#47;*
   * [
   *   {
   *      code: 'NPPTH#1A#SB#D1',
   *      masterCode: 'NPPTH',
   *      book: '1A',
   *      category: 'D1',
   *      edition: 'SB',
   *      grade: 'P1',
   *      classID: '2019-2020+SAP001+nYiGqgmglq',
   *      schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchEntitlementsByClassID(classID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          masterCodes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          books: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          categories: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          editions: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchEntitlementsByClassID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/classes/${classID}/entitlements`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching entitlement data of a user.
   *
   * @param {string} userID - Student or teacher user ID, can be "me" for my entitlements.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.masterCodes] - Filter by list of master codes in series code
   * @param {string[]} [options.books] - Filter by a list of books in series code
   * @param {string[]} [options.categories] - Filter by list of categories in series code
   * @param {string[]} [options.editions] - Filter by list of editions in series code
   * @param {string[]} [options.groupBy] - Group result by field, only accept `grade`, `code`
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~Entitlement[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all entitlements by user id
   * const entitlements = await mlClient.fetchEntitlementsByUserID('me', {
   *   // All following parameters are optional
   *   masterCodes: ['NPPTH'],
   *   books: ['1A'],
   *   categories: ['D1'],
   *   editions: ['SB']
   * });
   *
   * console.log(entitlements.length); // 1
   * console.log(entitlements);
   * &#47;*
   * [
   *   {
   *      code: 'NPPTH#1A#SB#D1',
   *      masterCode: 'NPPTH',
   *      book: '1A',
   *      category: 'D1',
   *      edition: 'SB',
   *      grade: 'P1',
   *      classID: '2019-2020+SAP001+nYiGqgmglq',
   *      schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchEntitlementsByUserID(userID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          masterCodes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          books: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          categories: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          editions: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          groupBy: {
            type: 'string'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchEntitlementsByUserID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/users/${userID}/entitlements`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical entitlement data of a school
   *
   * @param {string} schoolID - School ID or "me" for own school.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.masterCodes] - Filter by list of master codes in series code
   * @param {string[]} [options.books] - Filter by a list of books in series code
   * @param {string[]} [options.categories] - Filter by list of categories in series code
   * @param {string[]} [options.editions] - Filter by list of editions in series code
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is 'ALL' if not specified.
   *    Accept value `ALL` to query for all years.
   * @param {string} [options.groupBy] - Group result by field, only accept `grade`, `code`
   *
   * @returns {Promise<module:RestfulObject~Entitlement[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch historical entitlements by school id
   * const entitlements = await mlClient.fetchHistoricalEntitlementsBySchoolID('me', {
   *   // All following parameters are optional
   *   masterCodes: ['NPPTH'],
   *   books: ['1A'],
   *   categories: ['D1'],
   *   editions: ['SB']
   * });
   *
   * console.log(entitlements.length); // 1
   * console.log(entitlements);
   * &#47;*
   * [
   *   {
   *      code: 'NPPTH#1A#SB#D1',
   *      masterCode: 'NPPTH',
   *      book: '1A',
   *      category: 'D1',
   *      edition: 'SB',
   *      grade: 'P1',
   *      classID: '2019-2020+SAP001+nYiGqgmglq',
   *      schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchHistoricalEntitlementsBySchoolID(schoolID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          masterCodes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          books: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          categories: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          editions: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          groupBy: {
            type: 'string'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchHistoricalEntitlementsBySchoolID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/schools/${schoolID}/entitlements`, { year: 'ALL', ...options })
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical entitlement data of a class
   *
   * @param {string} classID - Class ID or "me" for own class. "me" could only be used when logged in as student.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.masterCodes] - Filter by list of master codes in series code
   * @param {string[]} [options.books] - Filter by a list of books in series code
   * @param {string[]} [options.categories] - Filter by list of categories in series code
   * @param {string[]} [options.editions] - Filter by list of editions in series code
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is 'ALL' if not specified.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~Entitlement[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all historical entitlements by class id
   * const entitlements = await mlClient.fetchHistoricalEntitlementsByClassID('me', {
   *   // All following parameters are optional
   *   masterCodes: ['NPPTH'],
   *   books: ['1A'],
   *   categories: ['D1'],
   *   editions: ['SB']
   * });
   *
   * console.log(entitlements.length); // 1
   * console.log(entitlements);
   * &#47;*
   * [
   *   {
   *      code: 'NPPTH#1A#SB#D1',
   *      masterCode: 'NPPTH',
   *      book: '1A',
   *      category: 'D1',
   *      edition: 'SB',
   *      grade: 'P1',
   *      classID: '2019-2020+SAP001+nYiGqgmglq',
   *      schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchHistoricalEntitlementsByClassID(classID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          masterCodes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          books: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          categories: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          editions: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchHistoricalEntitlementsByClassID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/classes/${classID}/entitlements`, { year: 'ALL', ...options })
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical entitlement data of a user.
   *
   * @param {string} userID - Student or teacher user ID, can be "me" for my entitlements.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.masterCodes] - Filter by list of master codes in series code
   * @param {string[]} [options.books] - Filter by a list of books in series code
   * @param {string[]} [options.categories] - Filter by list of categories in series code
   * @param {string[]} [options.editions] - Filter by list of editions in series code
   * @param {string[]} [options.groupBy] - Group result by field, only accept `grade`, `code`
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is 'ALL' if not specified.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~Entitlement[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all historical entitlements by user id
   * const entitlements = await mlClient.fetchHistoricalEntitlementsByUserID('me', {
   *   // All following parameters are optional
   *   masterCodes: ['NPPTH'],
   *   books: ['1A'],
   *   categories: ['D1'],
   *   editions: ['SB']
   * });
   *
   * console.log(entitlements.length); // 1
   * console.log(entitlements);
   * &#47;*
   * [
   *   {
   *      code: 'NPPTH#1A#SB#D1',
   *      masterCode: 'NPPTH',
   *      book: '1A',
   *      category: 'D1',
   *      edition: 'SB',
   *      grade: 'P1',
   *      classID: '2019-2020+SAP001+nYiGqgmglq',
   *      schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchHistoricalEntitlementsByUserID(userID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          masterCodes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          books: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          categories: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          editions: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          groupBy: {
            type: 'string'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchHistoricalEntitlementsByUserID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/users/${userID}/entitlements`, { year: 'ALL', ...options })
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching series codes of a school
   *
   * @param {string} schoolID - School ID or "me" for own school.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.codes] - Filter by list of series codes
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~SeriesCode[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all series codes by school id
   * const seriesCodes = await mlClient.fetchSeriesCodesBySchoolID('me', {
   *   // All following parameters are optional
   *   codes: ['NPPTH']
   * });
   *
   * console.log(seriesCodes.length); // 1
   * console.log(seriesCodes);
   * &#47;*
   * [
   *   {
   *     code: 'NPPTH',
   *     grade: 'P1',
   *     classID: '2019-2020+SAP001+nYiGqgmglq',
   *     schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchSeriesCodesBySchoolID(schoolID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          codes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchSeriesCodesBySchoolID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/schools/${schoolID}/series_codes`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching series codes of a class
   *
   * @param {string} classID - Class ID or "me" for own class. "me" could only be used when logged in as student.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.codes] - Filter by list of series codes
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~SeriesCode[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all series codes by class id
   * const seriesCodes = await mlClient.fetchSeriesCodesByClassID('me', {
   *   // All following parameters are optional
   *   codes: ['NPPTH']
   * });
   *
   * console.log(seriesCodes.length); // 1
   * console.log(seriesCodes);
   * &#47;*
   * [
   *   {
   *     code: 'NPPTH',
   *     grade: 'P1',
   *     classID: '2019-2020+SAP001+nYiGqgmglq',
   *     schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchSeriesCodesByClassID(classID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          codes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchSeriesCodesByClassID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/classes/${classID}/series_codes`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching series codes of a user
   *
   * @param {string} userID - User ID or "me" for my series codes.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.codes] - Filter by list of series codes
   * @param {string[]} [options.groupBy] - Group result by field, only accept `grade`, `code`
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~SeriesCode[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all series codes by user id
   * const seriesCodes = await mlClient.fetchSeriesCodesByUserID('me', {
   *   // All following parameters are optional
   *   codes: ['NPPTH'],
   * });
   *
   * console.log(seriesCodes.length); // 1
   * console.log(seriesCodes);
   * &#47;*
   * [
   *   {
   *     code: 'NPPTH',
   *     grade: 'P1',
   *     classID: '2019-2020+SAP001+nYiGqgmglq',
   *     schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchSeriesCodesByUserID(userID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          codes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          groupBy: {
            type: 'string'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchSeriesCodesByUserID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/users/${userID}/series_codes`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical series codes of a school
   *
   * @param {string} schoolID - School ID or "me" for own school.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.codes] - Filter by list of series codes
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is 'ALL' if not specified.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~SeriesCode[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all historical series codes by school id
   * const seriesCodes = await mlClient.fetchHistoricalSeriesCodesBySchoolID('me', {
   *   // All following parameters are optional
   *   codes: ['NPPTH']
   * });
   *
   * console.log(seriesCodes.length); // 1
   * console.log(seriesCodes);
   * &#47;*
   * [
   *   {
   *     code: 'NPPTH',
   *     grade: 'P1',
   *     classID: '2019-2020+SAP001+nYiGqgmglq',
   *     schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchHistoricalSeriesCodesBySchoolID(schoolID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          codes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchHistoricalSeriesCodesBySchoolID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/schools/${schoolID}/series_codes`, { year: 'ALL', ...options })
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical series codes of a class
   *
   * @param {string} classID - Class ID or "me" for own class. "me" could only be used when logged in as student.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.codes] - Filter by list of series codes
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is 'ALL'.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~SeriesCode[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all historical series codes by class id
   * const seriesCodes = await mlClient.fetchHistoricalSeriesCodesByClassID('me', {
   *   // All following parameters are optional
   *   codes: ['NPPTH']
   * });
   *
   * console.log(seriesCodes.length); // 1
   * console.log(seriesCodes);
   * &#47;*
   * [
   *   {
   *     code: 'NPPTH',
   *     grade: 'P1',
   *     classID: '2019-2020+SAP001+nYiGqgmglq',
   *     schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchHistoricalSeriesCodesByClassID(classID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          codes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchHistoricalSeriesCodesByClassID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/classes/${classID}/series_codes`, { year: 'ALL', ...options })
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical series codes of a user
   *
   * @param {string} userID - User ID or "me" for my series codes.
   * @param {Object} [options] - Optional parameters.
   * @param {string[]} [options.codes] - Filter by list of series codes
   * @param {string[]} [options.groupBy] - Group result by field, only accept `grade`, `code`
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is 'ALL'.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~SeriesCode[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all historical series codes by user id
   * const seriesCodes = await mlClient.fetchHistoricalSeriesCodesByUserID('me', {
   *   // All following parameters are optional
   *   codes: ['NPPTH'],
   * });
   *
   * console.log(seriesCodes.length); // 1
   * console.log(seriesCodes);
   * &#47;*
   * [
   *   {
   *     code: 'NPPTH',
   *     grade: 'P1',
   *     classID: '2019-2020+SAP001+nYiGqgmglq',
   *     schoolID: 'SAP001'
   *   }
   * ]
   * *&#47;
   */
  async fetchHistoricalSeriesCodesByUserID(userID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          codes: {
            $ref: 'defs.json#/definitions/arrayOfString'
          },
          groupBy: {
            type: 'string'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchHistoricalSeriesCodesByUserID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/users/${userID}/series_codes`, { year: 'ALL', ...options })
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching school data.
   *
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.type] - Filter school by type.
   * @param {string} [options.district] - Filter school by district.
   * @param {string} [options.region] - Filter school by region.
   * @param {string} [options.band] - Filter school by band.
   *
   * @returns {Promise<module:RestfulObject~School[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch all schools of type
   * const schools = await mlClient.fetchSchools({
   *   // All following parameters are optional
   *   type: 'Primary'
   *   district: 'Eastern',
   *   region: 'KLN',
   *   band: 'Band 1'
   * });
   *
   * console.log(schools.length); // 2
   * console.log(schools);
   * &#47;*
   * [
   *   {
   *     schoolID: 'xxx'
   *     nameEng: 'ABC school',
   *     nameChi: 'ABC學校',
   *     type: 'Primary',
   *     initial: 'abcsc',
   *     district: 'Eastern',
   *     region: 'KLN',
   *     band: 'Band 1'
   *   },
   *   {
   *     schoolID: 'xxx'
   *     nameEng: 'BCD school',
   *     nameChi: 'BCD學校',
   *     type: 'Primary',
   *     initial: 'bcdsc',
   *     district: 'Eastern',
   *     region: 'KLN',
   *     band: 'Band 1'
   *   }
   * ]
   * *&#47;
   */
  async fetchSchools(options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          type: {
            type: 'string'
          },
          district: {
            type: 'string'
          },
          region: {
            type: 'string'
          },
          band: {
            type: 'string'
          }
        }
      },
      'fetchSchools'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/schools`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching school data by id.
   *
   * @param {string} schoolID - School ID to fetch.
   *
   * @returns {Promise<module:RestfulObject~School>}
   *
   * @throws {APIError}
   *
   * @example
   * // fetch school
   * const schoolID = 'xxx';
   * const school = await mlClient.fetchSchoolByID(schoolID);
   *
   * console.log(school);
   * &#47;*
   * {
   *   schoolID: 'xxx'
   *   nameEng: 'BCD school',
   *   nameChi: 'BCD學校',
   *   type: 'Primary',
   *   initial: 'bcdsc',
   *   district: 'Eastern',
   *   region: 'KLN',
   *   band: 'Band 1'
   * }
   * *&#47;
   */
  async fetchSchoolByID(schoolID) {
    return this._request({
      method: 'GET',
      url: `/schools/${schoolID}`
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching class data of a school.
   *
   * @param {string} schoolID - Classes of school ID.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.grade] - Filter class by grade.
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   * @param {string} [options.sortBy] - Sort classes by attirbute, only accept `year`.
   *
   * @returns {Promise<module:RestfulObject~Class[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch classes of a school
   * const schoolID = 'xxx';
   * const classes = await mlClient.fetchClassesBySchoolID(schoolID, {
   *   // All following parameters are optional
   *   grade: 'P1'
   * });
   *
   * console.log(classes.length); // 2
   * console.log(classes);
   * &#47;*
   * [
   *   {
   *     classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: '1A',
   *     nameChi: '1A',
   *     grade: 'P1',
   *     subject: null,
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   },
   *   {
   *     classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: '2A',
   *     nameChi: '2A',
   *     grade: 'P2',
   *     subject: null,
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   }
   * ]
   * *&#47;
   */
  async fetchClassesBySchoolID(schoolID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          grade: {
            type: 'string'
          },
          year: {
            type: 'string'
          },
          sortBy: {
            type: 'string'
          }
        }
      },
      'fetchClassesBySchoolID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/schools/${schoolID}/classes`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical class data of a school.
   *
   * @param {string} schoolID - Classes of school ID.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.grade] - Filter class by grade.
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is ALL academic year if not specified.
   * @param {string} [options.sortBy] - Sort classes by attirbute, only accept `year`.
   *
   * @returns {Promise<module:RestfulObject~Class[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch classes of a school
   * const schoolID = 'xxx';
   * const classes = await mlClient.fetchHistoricalClassesBySchoolID(schoolID, {
   *   // All following parameters are optional
   *   grade: 'P1'
   * });
   *
   * console.log(classes.length); // 1
   * console.log(classes);
   * &#47;*
   * [
   *   {
   *     classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: '1A',
   *     nameChi: '1A',
   *     grade: 'P1',
   *     subject: null,
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   }
   * ]
   * *&#47;
   *
   * @example
   * // fetch classes of a school, sort by year
   * const schoolID = 'xxx';
   * const classesByYear = await mlClient.fetchHistoricalClassesBySchoolID(schoolID, {
   *   // All following parameters are optional
   *   sortBy: 'year'
   * });
   *
   * console.log(classesByYear);
   * &#47;*
   * [
   *   {
   *     year: '2017-2018',
   *     classes: [...class]
   *   },
   *   {
   *     year: '2018-2019',
   *     classes: [...class]
   *   },
   *   {
   *     year: '2019-2020',
   *     classes: [...class]
   *   },
   * ]
   * *&#47;
   */
  async fetchHistoricalClassesBySchoolID(schoolID, options) {
    return this.fetchClassesBySchoolID(schoolID, {
      year: 'ALL',
      ...options
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching class data by id.
   *
   * @param {string} classID - Class ID to fetch.
   *
   * @returns {Promise<module:RestfulObject~Class>}
   *
   * @throws {APIError}
   *
   * @example
   * // fetch class
   * const classID = '****';
   * const class = await mlClient.fetchClassByID(classID);
   *
   * console.log(class);
   * &#47;*
   * {
   *   classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *   nameEng: '1A',
   *   nameChi: '1A',
   *   grade: 'P1',
   *   subject: null,
   *   schoolID: 'xxx',
   *   year: '2019-2020'
   * }
   * *&#47;
   */
  async fetchClassByID(classID) {
    return this._request({
      method: 'GET',
      url: `/classes/${classID}`
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching class data of a student.
   *
   * @param {string} studentID - User ID of student.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   * @param {string} [options.sortBy] - Sort classes by attirbute, only accept `year`.
   *
   * @returns {Promise<module:RestfulObject~Class[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch classes of a school
   * const studentID = 'xxx';
   * const classes = await mlClient.fetchClassesByStudentID(studentID, {
   *   // All following parameters are optional
   *   year: 'ALL'
   * });
   *
   * console.log(classes.length); // 2
   * console.log(classes);
   * &#47;*
   * [
   *   {
   *     classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: '1A',
   *     nameChi: '1A',
   *     grade: 'P1',
   *     subject: null,
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   },
   *   {
   *     classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: '2A',
   *     nameChi: '2A',
   *     grade: 'P2',
   *     subject: null,
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   }
   * ]
   * *&#47;
   */
  async fetchClassesByStudentID(studentID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          grade: {
            type: 'string'
          },
          year: {
            type: 'string'
          },
          sortBy: {
            type: 'string'
          }
        }
      },
      'fetchClassesBySchoolID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/students/${studentID}/classes`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical class data of a student.
   *
   * @param {string} studentID - User ID of student.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is ALL academic year if not specified.
   * @param {string} [options.sortBy] - Sort classes by attirbute, only accept `year`.
   *
   * @returns {Promise<module:RestfulObject~Class[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch classes of a school, sort by year
   * const schoolID = 'xxx';
   * const classesByYear = await mlClient.fetchHistoricalClassesByStudentID(studentID, {
   *   // All following parameters are optional
   *   sortBy: 'year'
   * });
   *
   * console.log(classesByYear);
   * &#47;*
   * [
   *   {
   *     year: '2017-2018',
   *     classes: [...class]
   *   },
   *   {
   *     year: '2018-2019',
   *     classes: [...class]
   *   },
   *   {
   *     year: '2019-2020',
   *     classes: [...class]
   *   },
   * ]
   * *&#47;
   */
  async fetchHistoricalClassesByStudentID(studentID, options) {
    return this.fetchClassesByStudentID(studentID, {
      year: 'ALL',
      ...options
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching class data teached by a teacher.
   *
   * @param {string} userID - User ID of teacher.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.subject] - Filter class by subject.
   * @param {string} [options.class] - Filter class by class id.
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   * @param {string} [options.sortBy] - Sort classes by attirbute, only accept `year`.
   *
   * @returns {Promise<module:RestfulObject~TeacherClass[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch classes of a teacher
   * const userID = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
   * const classes = await mlClient.fetchTeacherClasses(userID, {
   *   // All following parameters are optional
   *   grade: 'P1'
   * });
   *
   * console.log(classes.length); // 2
   * console.log(classes);
   * &#47;*
   * [
   *   {
   *     classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: '1A',
   *     nameChi: '1A',
   *     grade: 'P1',
   *     subject: null,
   *     schoolID: 'xxx',
   *     teachingSubjects: ['Chinese'],
   *     year: '2019-2020'
   *   },
   *   {
   *     classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: '2A',
   *     nameChi: '2A',
   *     grade: 'P2',
   *     subject: null,
   *     schoolID: 'xxx',
   *     teachingSubjects: ['English'],
   *     year: '2019-2020'
   *   }
   * ]
   * *&#47;
   */
  async fetchTeacherClasses(userID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          subject: {
            type: 'string'
          },
          class: {
            type: 'string'
          },
          year: {
            type: 'string'
          },
          sortBy: {
            type: 'string'
          }
        }
      },
      'fetchTeacherClasses'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/teachers/${userID}/teaching_classes`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical class data teached by a teacher.
   *
   * @param {string} userID - User ID of teacher.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.subject] - Filter class by subject.
   * @param {string} [options.class] - Filter class by class id.
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is ALL academic year if not specified.
   * @param {string} [options.sortBy] - Sort classes by attirbute, only accept `year`.
   *
   * @returns {Promise<module:RestfulObject~TeacherClass[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch classes of a school, sort by year
   * const schoolID = 'xxx';
   * const classesByYear = await mlClient.fetchHistoricalTeacherClasses(userID, {
   *   // All following parameters are optional
   *   sortBy: 'year'
   * });
   *
   * console.log(classesByYear);
   * &#47;*
   * [
   *   {
   *     year: '2017-2018',
   *     classes: [...class]
   *   },
   *   {
   *     year: '2018-2019',
   *     classes: [...class]
   *   },
   *   {
   *     year: '2019-2020',
   *     classes: [...class]
   *   },
   * ]
   * *&#47;
   */
  async fetchHistoricalTeacherClasses(userID, options) {
    return this.fetchTeacherClasses(userID, {
      year: 'ALL',
      ...options
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching teacher data of a school.
   *
   * @param {string} schoolID - School ID of teachers.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.position] - Filter teacher by position.
   *
   * @returns {Promise<module:RestfulObject~Teacher[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch teachers of a school
   * const schoolID = 'xxx';
   * const teachers = await mlClient.fetchTeachersBySchoolID(schoolID, {
   *   // All following parameters are optional
   *   position: 'Teacher'
   * });
   *
   * console.log(teachers.length); // 2
   * console.log(teachers);
   * &#47;*
   * [
   *   {
   *     userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: 'Chan Tai Man',
   *     nameChi: '陳大文',
   *     position: 'Teacher',
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   },
   *   {
   *     userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: 'Chan Siu Man',
   *     nameChi: '陳小文',
   *     position: 'Teacher',
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   }
   * ]
   * *&#47;
   */
  async fetchTeachersBySchoolID(schoolID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          position: {
            type: 'string'
          }
        }
      },
      'fetchTeachersBySchoolID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/schools/${schoolID}/teachers`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching teacher data of a class.
   *
   * @param {string} classID - Class ID of teachers.
   *
   * @returns {Promise<module:RestfulObject~Teacher[]>}
   *
   * @throws {APIError}
   *
   * @example
   * // fetch teachers of a class
   * const classID = '****';
   * const teachers = await mlClient.fetchTeachersByClassID(classID);
   *
   * console.log(teachers.length); // 2
   * console.log(teachers);
   * &#47;*
   * [
   *   {
   *     userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: 'Chan Tai Man',
   *     nameChi: '陳大文',
   *     position: 'Teacher',
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   },
   *   {
   *     userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: 'Chan Siu Man',
   *     nameChi: '陳小文',
   *     position: 'Teacher',
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   }
   * ]
   * *&#47;
   */
  async fetchTeachersByClassID(classID) {
    return this._request({
      method: 'GET',
      url: `/classes/${classID}/teachers`
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching teacher data by id.
   *
   * @param {string} userID  - User ID of the teacher.
   *
   * @returns {Promise<module:RestfulObject~Teacher>}
   *
   * @throws {APIError}
   *
   * @example
   * // fetch a teacher
   * const userID = '****';
   * const teacher = await mlClient.fetchTeacherByID(userID);
   *
   * console.log(class);
   * &#47;*
   * {
   *   userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *   nameEng: 'Chan Siu Man',
   *   nameChi: '陳小文',
   *   position: 'Teacher',
   *   schoolID: 'xxx',
   *   year: '2019-2020'
   * }
   * *&#47;
   */
  async fetchTeacherByID(userID) {
    return this._request({
      method: 'GET',
      url: `/teachers/${userID}`
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching teacher panel data of a teacher.
   *
   * @param {string} userID  - User ID of the teacher.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.subject] - Filter teacher panel by subject.
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is current academic year if not specified.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~TeacherPanel[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch teacher panels of a teacher
   * const userID = '****';
   * const teacherPanels = await mlClient.fetchTeacherPanelsByTeacherID(userID, {
   *   // All following parameters are optional
   *   subject: 'Chinese'
   * });
   *
   * console.log(teacherPanels.length); // 1
   * console.log(teacherPanels);
   * &#47;*
   * [
   *   {
   *     teacherPanelID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     year: '2019-2020',
   *     subject: 'Chinese',
   *     schoolID: 'xxx
   *   }
   * ]
   * *&#47;
   */
  async fetchTeacherPanelsByTeacherID(userID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          subject: {
            type: 'string'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchTeacherPanelsByTeacherID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/teachers/${userID}/teacher_panels`, options)
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching historical teacher panel data of a teacher.
   *
   * @param {string} userID  - User ID of the teacher.
   * @param {Object} [options] - Optional parameters.
   * @param {string} [options.subject] - Filter teacher panel by subject.
   * @param {string} [options.year] - Query for specific academic year, the academic year data is formatted as `2015-2016`.
   *    Default is 'ALL'.
   *    Accept value `ALL` to query for all years.
   *
   * @returns {Promise<module:RestfulObject~TeacherPanel[]>}
   *
   * @throws {ValidationError}
   * @throws {APIError}
   *
   * @example
   * // fetch historical teacher panels of a teacher
   * const userID = '****';
   * const teacherPanels = await mlClient.fetchHistoricalTeacherPanelsByTeacherID(userID, {
   *   // All following parameters are optional
   *   subject: 'Chinese'
   * });
   *
   * console.log(teacherPanels.length); // 1
   * console.log(teacherPanels);
   * &#47;*
   * [
   *   {
   *     teacherPanelID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     year: '2018-2019',
   *     subject: 'Chinese',
   *     schoolID: 'xxx
   *   }
   * ]
   * *&#47;
   */
  async fetchHistoricalTeacherPanelsByTeacherID(userID, options) {
    this._validateOptions(
      options,
      {
        type: 'object',
        properties: {
          subject: {
            type: 'string'
          },
          year: {
            type: 'string'
          }
        }
      },
      'fetchHistoricalTeacherPanelsByTeacherID'
    );

    return this._request({
      method: 'GET',
      url: urlWithOptions(`/teachers/${userID}/teacher_panels`, { year: 'ALL', ...options })
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching student data of a class.
   *
   * @param {string} classID - Class ID of students.
   *
   * @returns {Promise<module:RestfulObject~Student[]>}
   *
   * @throws {APIError}
   *
   * @example
   * // fetch students of a class
   * const classID = '****';
   * const students = await mlClient.fetchStudentsByClassID(classID);
   *
   * console.log(students.length); // 2
   * console.log(students);
   * &#47;*
   * [
   *   {
   *     userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: 'Chan Tai Man',
   *     nameChi: '陳大文',
   *     classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     classNo: '02',
   *     grade: 'P1',
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   },
   *   {
   *     userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     nameEng: 'Chan Siu Man',
   *     nameChi: '陳小文',
   *     classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *     classNo: '02,
   *     grade: 'P1',
   *     schoolID: 'xxx',
   *     year: '2019-2020'
   *   }
   * ]
   * *&#47;
   */
  async fetchStudentsByClassID(classID) {
    return this._request({
      method: 'GET',
      url: `/classes/${classID}/students`
    });
  }

  /**
   * Creates a HTTP request to Middle Layer for fetching student data by id.
   *
   * @param {string} userID  - User ID of the student.
   *
   * @returns {Promise<module:RestfulObject~Student>}
   *
   * @throws {APIError}
   *
   * @example
   * // fetch a student
   * const userID = '****';
   * const student = await mlClient.fetchStudentByID(userID);
   *
   * console.log(student);
   * &#47;*
   * {
   *   userID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *   nameEng: 'Chan Tai Man',
   *   nameChi: '陳大文',
   *   classID: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
   *   classNo: '02',
   *   grade: 'P1',
   *   schoolID: 'xxx',
   *   year: '2019-2020'
   * }
   * *&#47;
   */
  async fetchStudentByID(userID) {
    return this._request({
      method: 'GET',
      url: `/students/${userID}`
    });
  }

  /**
   * @private
   */
  _validateOptions(options, schema, schemaID) {
    if (options == null) {
      return;
    }

    let validateOptions = this._schemasByID[schemaID];

    if (!validateOptions) {
      validateOptions = this.ajv.compile({
        $id: `http://example.com/schemas/${schemaID}.json`,
        additionalProperties: false,
        ...schema
      });
      this._schemasByID[schemaID] = validateOptions;
    }

    const valid = validateOptions(options);
    if (!valid) {
      throw new ValidationError(validateOptions.errors);
    }

    return;
  }

  /**
   * @private
   */
  async _request(config) {
    const mergedConfig = {
      baseURL: this.dataEndpoint,
      ...config,
      headers: config.headers || {}
    };

    if (this.apiKey != null) {
      mergedConfig.headers['X-OUPC-ML-API-KEY'] = this.apiKey;
    }

    if (this.accessToken != null) {
      mergedConfig.headers.Authorization = `Bearer ${this.accessToken}`;
    }

    if (this.language != null) {
      mergedConfig.headers['accept-language'] = `${this.language}`;
    }

    try {
      const resp = await this.axios.request(mergedConfig);
      return this._handleResponse(resp);
    } catch (error) {
      this._handleError(error);
    }
  }

  /**
   * @private
   */
  _handleResponse(resp) {
    return resp.data.data;
  }

  /**
   * @private
   */
  _handleError(error) {
    const data = error.response && error.response.data;
    if (data != null && data.error != null) {
      throw new APIError(data.error.code, data.error.message);
    } else {
      throw error;
    }
  }

  /**
   * functions below are oidc client integration
   */

  /**
   *
   * @external UserManager
   * @see {@link https://github.com/IdentityModel/oidc-client-js/wiki#usermanager}
   */

  /**
   * <p>
   * Link a {@link external:UserManager} to the
   * MiddleLayerClient instance.
   * </p>
   *
   * <p>
   * <bold>Effects:</bold>
   * </p>
   *
   * <ul>
   * <li>Automatically update {@link MiddleLayerClient#accessToken|accessToken} whenever user login and logout.</li>
   * <li>Inject the two new methods to {@link external:UserManager}</li>
   *   <ul>
   *   <li>{@link MiddleLayerClient#signinRedirectWithESASToken|signinRedirectWithESASToken}</li>
   *   <li>{@link MiddleLayerClient#signinPopupWithESASToken|signinPopupWithESASToken}</li>
   * </ul>
   *
   * @see MiddleLayerClient#unlinkOIDCUserManager
   *
   * @param {external:UserManager} userManager
   *
   * @example
   * import { UserManager } from 'oidc-client';
   * import { MiddleLayerClient } from '@oup/oupc-middle-layer-client';
   *
   * const userManager = new UserManager({ ... });
   * const mlClient = new MiddleLayerClient({ ... });
   *
   * mlClient.linkOIDCUserManager(userManager);
   *
   * @example
   * // after `linkOIDCUserManager` is called
   *
   * // Get ESAS token from query param `token` in the current
   * // window url to start the OAuth login flow with Middle Layer.
   * userManager.signinRedirectWithESASToken();
   *
   * // Get ESAS token from custom query param `esas_token` and start login.
   * userManager.signinRedirectWithESASToken({
   *   tokenKey: 'esas_token'
   * });
   */
  linkOIDCUserManager(userManager) {
    this.oidcUserManager = userManager;
    this.onOIDCUserLoaded = this.onOIDCUserLoaded.bind(this);
    this.onOIDCUserUnloaded = this.onOIDCUserUnloaded.bind(this);
    this.oidcUserManager.events.addUserLoaded(this.onOIDCUserLoaded);
    this.oidcUserManager.events.addUserUnloaded(this.onOIDCUserUnloaded);

    // inject iSolution SSO handler function to oidc UserManager
    userManager.ESASTokenPattern = 'esas-token:%s:';

    userManager.signinRedirectWithESASToken = this.signinRedirectWithESASToken.bind(this);
    userManager.signinPopupWithESASToken = this.signinPopupWithESASToken.bind(this);
  }

  /**
   * Unlink the linked {@link external:UserManager}
   * that was linked with {@link MiddleLayerClient#linkOIDCUserManager|linkOIDCUserManager}
   *
   * @see MiddleLayerClient#linkOIDCUserManager
   *
   */
  unlinkOIDCUserManager() {
    this.oidcUserManager.events.removeUserLoaded(this.onOIDCUserLoaded);
    this.oidcUserManager.events.removeUserUnloaded(this.onOIDCUserUnloaded);
    this.oidcUserManager = null;
  }

  /**
   * @private
   */
  onOIDCUserLoaded(user) {
    this.oidcUser = user;
    this.accessToken = user.access_token;
  }

  /**
   * @private
   */
  onOIDCUserUnloaded() {
    this.accessToken = null;
    this.oidcUser = null;
  }

  /**
   * This function is injected to a {@link external:UserManager} after calling
   * {@link MiddleLayerClient#linkOIDCUserManager|linkOIDCUserManager}.
   *
   * @example
   * import { UserManager } from 'oidc-client';
   * import { MiddleLayerClient } from '@oup/oupc-middle-layer-client';
   *
   * const userManager = new UserManager({ ... });
   * const mlClient = new MiddleLayerClient({ ... });
   *
   * mlClient.linkOIDCUserManager(userManager);
   *
   * // Start login flow with ESAS token extracted from window url
   * userManager.signinRedirectWithESASToken();
   *
   * @example
   * // after OAuth login finished and redirected to redirect_uri
   * const user = await userManager.signinRedirectCallback();
   *
   * // user.data would be the url when `signinRedirectWithESASToken` get called with token extracted.
   * // This allows the web app to continue to serve the specified resources for iSolution SSO flow
   * //
   * // e.g.
   * // When signinRedirectWithESASToken get called in `https://example.oupchina.com.hk/sso?token=xxx&resource=123`,
   * // `user.data` would be `https://example.oupchina.com.hk/sso?resource=123`.
   * console.log(user.data);
   *
   * @param {Object} [opts] - Optional parameters.
   * @param {string} [opts.tokenKey=token] - Query params key to get ESAS token.
   *
   */
  signinRedirectWithESASToken(opts) {
    return this._signinWithESASToken(this.oidcUserManager.signinRedirect, opts);
  }

  /**
   * <p>
   * This function is injected to a {@link external:UserManager} after calling
   * {@link MiddleLayerClient#linkOIDCUserManager|linkOIDCUserManager}.
   * </p>
   *
   * <p>
   * Simliary to {@link MiddleLayerClient#signinRedirectWithESASToken|signinRedirectWithESASToken}.
   * </p>
   *
   * @see {@link MiddleLayerClient#signinRedirectWithESASToken|signinRedirectWithESASToken}
   *
   * @param {Object} [opts] - Optional parameters.
   * @param {string} [opts.tokenKey=token] - Query params key to get ESAS token.
   */
  signinPopupWithESASToken(opts) {
    return this._signinWithESASToken(this.oidcUserManager.signinPopup, opts);
  }

  /**
   * @private
   */
  _signinWithESASToken(signinFunc, opts) {
    if (typeof window !== 'object' || typeof window.location !== 'object') {
      throw new Error(
        'missing window.location, signinRedirectWithESASToken and signinPopupWithESASToken MUST be called in browser'
      );
    }

    const { tokenKey = 'token' } = opts || {};

    const url = new URL(window.location);
    const esasToken = url.searchParams.get(tokenKey);
    url.searchParams.delete(tokenKey);

    return signinFunc.call(this.oidcUserManager, {
      login_hint: this.oidcUserManager.ESASTokenPattern.replace('%s', esasToken),
      data: url.toString()
    });
  }
}

export { ValidationError, APIError } from './error';
export { MiddleLayerClient };
