import React, { useEffect, useState, useRef } from 'react';
import * as Encode from '../../libs/libEncode';

import * as UI from '../../libs/libUI';

import { aTErrDiv1, aTErrTxt1, aTUIMust, BtnPopDev, clickUrl, PopA, preJS } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';

import { objVals, toAry, toObj, toStr } from '../../libs/libType';
import CpDropImg, { useDropImgState } from '../components/CpDropImg';
import { lj } from '../../libs/libFormat';
import { ReduxBind } from '../../saga/ReduxState';
import { toUniIdAry } from '../../consts/ATValidate';

const TabESetThumb = ReduxBind(props => {
    const {fields, setField, setTick, setESubjId, getMediaDLs, imgStates, lock, showEn, hasEn, hasCt} = props;
    const opts = toObj(props.opts);
    const fieldErrs = toObj(props.fieldErrs);
    const eset = toObj(props.eset); 
    const ESThumbs = toObj(eset.ESThumbs);

    const mediaIds = toUniIdAry(objVals(ESThumbs));
    const dls = getMediaDLs(mediaIds);
    const thumbDL = key => toStr(dls[ESThumbs[key]]?.dl); 
    
    

    const allow = !lock;

    //<div className="m8">{UI.Button0('test apis', clickTest, 'bt')}</div>
    return <div className="m8 p8">
        {aTErrDiv1(fieldErrs?.ESThumbs)}
        {hasEn?
        <div className="vat m8 w" style={{width:'350px'}}>
            <div className="m8" >En {aTUIMust}</div>
            <CpDropThumb {...{tkey:'e0', imgState:imgStates.e0, validate:validateDrop, allow, imgSrc:thumbDL('e0'), lbl:'Desktop:'}} />
            <CpDropThumb {...{tkey:'e1', imgState:imgStates.e1, validate:validateDrop, allow, imgSrc:thumbDL('e1'), lbl:'Tablet:'}} />
            <CpDropThumb {...{tkey:'e2', imgState:imgStates.e2, validate:validateDrop, allow, imgSrc:thumbDL('e2'), lbl:'Mobile:'}} />
        </div>
        :''}
        {hasCt?
            <div className="vat m8 w" style={{width:'350px'}}>
            <div className="m8" >繁中 {aTUIMust}</div>
            <CpDropThumb {...{tkey:'c0', imgState:imgStates.c0, validate:validateDrop, allow, imgSrc:thumbDL('c0'), lbl:'Desktop:'}} />
            <CpDropThumb {...{tkey:'c1', imgState:imgStates.c1, validate:validateDrop, allow, imgSrc:thumbDL('c1'), lbl:'Tablet:'}} />
            <CpDropThumb {...{tkey:'c2', imgState:imgStates.c2, validate:validateDrop, allow, imgSrc:thumbDL('c2'), lbl:'Mobile:'}} />
            </div>
        :''}
    </div>;
});
export default TabESetThumb; 

const CpDropThumb = props => {
    const { tkey, imgState, validate, allow, lbl } = props;
    const upSrc = (allow && imgState.data);
    const imgSrc = upSrc || props.imgSrc || '';
    
    const onDropImg = (file, data, err) => {
        
        imgState.update({file, data, err}); 
    };
    const onRmImg = e => onDropImg('', '', ''); 

    return <div className="box1 br6 m8">
        <div className="cCenter">
            <div className="m8 f14">{lbl}</div>
            <CpDropImg className='dropImg f14' imgClassName='dropImgIn'
                onDropImg={onDropImg} validate={validate} allow={allow} >
                {imgSrc
                    ? <img className='dropImgImg' style={{maxWidth:'100%'}} alt="thumb" src={imgSrc} />
                    : '(Drag Drop or Click to set Image)'}
            </CpDropImg>
            <div className="m8">
                {allow? UI.Button0('Delete', onRmImg, 'bd', 'p4', {}, !upSrc): ''}
                {aTErrDiv1(imgState.err)}
            </div>
        </div>
    </div>
};

const validateDrop = file => {
    //lj({ validateDrop: file });
    if (!file) return ('msg_missingDropFile');
    if (file.size > (3 * 1000 * 1000)) return ('msg_fileSizeE1000k');
    return '';
};
