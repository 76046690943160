import React, {useMemo, useEffect, useState} from "react";
import { ReduxBind } from "../../saga/ReduxState";
import { stopEvent } from "../../libs/libUI";
import { Button, CloseButton } from "react-bootstrap";
import CpIco from "../_components/CpIco";
import CpActionButton from "../_components/CpActionButton";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { lang2t, useUILang } from "../../AppExPf/utils/useUILang";
import CpSharedModal from "../_components/CpSharedModal";

import {QRCodeSVG} from 'qrcode.react';
import { toObj, toStr } from "../../libs/libType";
import { _pathAssign } from "../../consts/ATConstReact";
import { toIdStr } from "../../consts/ATValidate";
import { actBtnProps } from "./LayerAssignmentEnd";


export const useAssignmentShare = () => {
    const [shareAsm, setShareAsm] = useState();
    return {shareAsm, setShareAsm};
};

export const CpShareCTA = ReduxBind(props => { //base on ShareCTA
    const {dispatch, asm, reload, layout, ...btnProps} = props;
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {setShareAsm} = props.useAsmShare; 

    const clickAsmShare = e => { 
        
        stopEvent(e); setShareAsm({asm, reload}); 
    };
    return <CpActionButton title={t("assignment-cta-share")} iconPath={IconList.assignment.share}
        className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none ${layout === "list"? "py-0": ""}`} 
        onClick={clickAsmShare} {...actBtnProps(btnProps)} />;
});
//className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none ${layout === "in-list" ? "py-0" : ""}`} {...actBtnProps(btnProps)}
const LayerAssignmentShare = ReduxBind(props => { //base on DeleteCTA
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const domain = props.settings()?.account_origin;

    const [copied, setCopied] = useState(false);

    const {shareAsm: _shareAsm, setShareAsm} = props.useAsmShare; 
    const {asm: shareAsm, reload} = toObj(_shareAsm); 
    useEffect(() => { if(!shareAsm) setCopied(0); }, [shareAsm]);

    const ex = toObj(shareAsm?.Exer);
    const AName =  lang2t(shareAsm?.ALang, ex.ENameEn, ex.ENameCt);
    const assignId = toIdStr(shareAsm?.assignId);
    const link = domain + _pathAssign(assignId);

//    const domain = "http://localhost:3042";
//    const link = domain+process.env.PUBLIC_URL+_pathAssign(assignId);
    //const link = "https://exercise.oupchina.com.hk/fa43u5b43giafa43u5b43giafa43u5b%67&abc=43gi;&afa43u5b43giafa43u5b43gia";
    
    const clickClose = e => { stopEvent(e); setShareAsm(0); };
    const click2Copy = e => { stopEvent(e); navigator.clipboard.writeText(link); setCopied(true); };

    return <>
        <CpSharedModal show={shareAsm} scrollable={true}>
            <div className={"px-3 pt-4 bg-dim-100"}>
                <div className={"d-flex flex-column gap-4"}>
                    <div className={"d-flex flex-column gap-3 w-100"}>
                        <div className={"d-flex justify-content-between"}>
                            <span className={"d-flex justify-content-end opacity-0 pe-none"}><CloseButton onClick={clickClose}/></span>
                            <span className={"d-flex justify-content-center text-exercise-third-btn fs-1"}><CpIco src={IconList.assignment.share}/></span>
                            <span className={"d-flex justify-content-end"}><CloseButton onClick={clickClose}/></span>
                        </div>
                        <div className={"d-flex w-100 fs-4 semi-bold justify-content-center user-select-none text-center"}> {AName} </div>
                    </div>
                    <div className={"text-center"}>
                        <div className={"mb-2 user-select-none"}>{t("assignment-settings-share-your-assignment")}</div>
                        <CpQrCode {...{link,...props}}/>             
                        {/*<img src={"/static/img/demo/assignment/qr.png"}/>*/}
                    </div>
                    <div className={"d-flex align-items-center gap-3 text-truncate mb-4"}>
                        <span className={"d-flex flex-grow-1 border align-items-center gap-2 p-2 text-truncate bg-white"}>
                            <span className={"text-dim-400 fs-3"}><CpIco src={IconList.contentType.link}/></span>
                            <span className={"d-flex flex-column semi-bold text-truncate"}>
                                <span className={"fs-9 text-exercise-third-btn user-select-none"}>{t("assignment-settings-copy-this-link-to-share")}</span>
                                <span className={"overflow-auto"}>{link}</span>
                            </span>
                        </span>
                        <span className={"fs-6"} role={"button"} onClick={click2Copy}>
                            <CpIco src={copied? IconList.general.correct: IconList.general.copyText}/></span>
                        {/*<span className={"fs-6"} role={"button"} onClick={clickCopy2Share}><CpIco src={IconList.general.copyText}/></span>*/}
                    </div>
                </div>
            </div>
        </CpSharedModal>
    </>
});
export default LayerAssignmentShare;

export const CpQrCode = (props => {
    const {link} = props;
    return <QRCodeSVG
        value={link}
        size={128}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
        includeMargin={false}
        imageSettings={{
            src: process.env.REACT_APP_WEBROOT+"favicon.ico",
            x: undefined,
            y: undefined,
            height: 24,
            width: 24,
            excavate: true,
        }}/>    
});
