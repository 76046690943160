import React, { } from 'react';

import { ReduxBind } from '../../saga/ReduxState';

const PageDevQEdit = ReduxBind(props => {
    return <div className="PageDevQEdit w100 p4">
        <h4>PageDevQEdit</h4>
    </div>
});
export default PageDevQEdit;

