import React, { useEffect, useMemo, useState } from "react";

import { _AssignmentRoot, _pathAssign, _EPPathEass } from "../../consts/ATConstReact";
import { ReduxBind } from "../../saga/ReduxState";
import { preJS } from "../../AppExPf/AppUtil";
import { Button0, stopEvent } from "../../libs/libUI";
import { useTickAll } from "../../AppExPf/ATQtnEdit/PageATQtns";
import { toAry, toInt, toObj } from "../../libs/libType";
import { subState } from "../../AppExPf/utils/useChain";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { expfRoles } from "../../saga/auth.saga";
import { deepCopy } from "../../AppExPf/AppUtil";
import { BtnPopDev } from "../../AppExPf/AppUtil";
import DoEx from "../../AppExPf/ATExerEdit/DoEx";
import { LayerLocalMedia } from "../../AppExPf/ATMediaPool/LayerLocalMedia";
import { langIsEn } from "../../AppExPf/utils/useUILang";
import CpMsgbox from "../_components/CpMsgbox";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { _WST_SUBMIT, _AST_PUBLISHED } from "../../consts/ATConstsAssignment";
import { isQtnAllMarked } from "../EPAssign/TabTeacherAssignmentTabContainer";

const PageAsmMark = ReduxBind(props => { //base on poc AssignmentLandingPage 
    const { dispatch, saveMarks, isReme } = props;
    const { marking, setMarking, asm, Exer, qtns} = props;
    const [isThr, isStt, uRole] = expfRoles(props);
//console.log('PageAsmMark', props.SWMs, {isThr, marking, setMarking, asm, Exer, qtns, }, );

    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);
    const useCacheQtns = qids => qtns;

    // filter all not submitted
    const [SWMs, setSWMs] = useState(props.SWMs);
    useEffect(() => {
 //console.log('PageAsmMark effect', props.SWMs);
        if(props.SWMs !== SWMs) setSWMs(props.SWMs);
    }, [props.SWMs]);

    const [modified, setModified] = useState(0);
    const [swmIdx, setIdx] = useState(marking.swmIdx);
    const [visMsg, setVisMsg] = useState(0);
    const [onAddMedia, setOnAddMedia] = useState();

    const SWM = toObj(SWMs[swmIdx]);
    const aPub = asm?.AState === _AST_PUBLISHED;
    const { student, work, mark } = SWM;
    const marked = useMemo(() => isQtnAllMarked(mark), [mark]);

    const sortedSWMs = useMemo(()=>{
        const ret = toAry(SWMs).filter(swm => (swm?.work?.WState === _WST_SUBMIT))
        .map(swm => ({ swmIdx:swm.swmIdx, classNo:swm.classNo }))
        .sort((_s1, _s2) => toInt(_s1.classNo) - toInt(_s2.classNo));
//console.log('PageAsmMark sortedSWMs', {SWMs, ret});
        return ret;
    }, [SWMs]);
    
    const setSWMsub = key => func => setSWMs(vs => {
        const ret = [...vs];
        ret[swmIdx] = subState(key, func)(SWM);
        return ret;
    });
    const setMark = setSWMsub('mark');

    const ttlSWM = sortedSWMs.length;
    const nextSubWork = (shf) => {
        const check = shf + sortedSWMs.findIndex(swm => swm.swmIdx === swmIdx);
        const nIdx = ( check >= ttlSWM )? 0: (check < 0)? (ttlSWM-1): check;
        return sortedSWMs[nIdx].swmIdx;
    };

    const setTeMark = (key, obj) => setMark(subState(key, deepCopy(obj))); //setMark({...mark, [key]:deepCopy(obj)});

    const shiftMark = (shf, jump = 0) => e => {
        stopEvent(e);
        //setIdx(i => (i + shf + ttlSWM) % ttlSWM );
        const nextIdx = jump? shf : nextSubWork(shf);
        setIdx(nextIdx);
        //if (modified) { setShiftInfo({ vis: 1, shf: nextIdx }); } else { setIdx(nextIdx) };
    };

    const close = (e) => {
        stopEvent(e);
        if (aPub) {
            setMarking(0);
        } else if (modified) {
            setVisMsg(1)
        } else { 
            confirmClose(e); 
        };
    };

    const cancelClose = (e) => { stopEvent(e); setVisMsg(0); };
    const confirmClose = (e) => { stopEvent(e); setVisMsg(0); setMarking(0); };
    const submit = () => saveMarks(SWMs);

    const showEn = langIsEn(asm.ALang);
    const [hasEn, hasCt] = [showEn, !showEn];
    const ECtns = Exer?.ECtns;
    const [lock, preview, showPub, PVMode, doWhat, showResult] = [1, 0, 1, asm.AMode === 'SCROLL_MODE' ? 1 : 0, 'asmView', 1];
    // if asm published, switch teacher to student role, show readonly result
    const subProps = {
        SWMs, swmIdx, asm, Exer, isThr: aPub? 0: isThr, isStt: aPub? 1: isStt, uRole,
        showResult, doWhat, preview, PVMode, showPub, showEn, hasEn, hasCt, lock,
        setOnAddMedia, mediaDLs, getMediaDLs, addLocalMedias, dispatch, aPub, isReme,
        ECtns, qtns, useCacheQtns, setTeMark, shiftMark, marked, modified, setModified,
        sttInfo: student, close, submit, allStResp: work?.resp, allTeResp: mark?.resp, stdIdx: swmIdx,
    };

    //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only 
    const debugInfo = () => {
        return <><div className='flexRowStart' style={{ color: 'black', gap: 2 }}>
            {Button0('BACK', e => { stopEvent(e); setMarking(0); }, 'btnX')}
            {Button0('Mark Next', shiftMark(1), 'btnX')}
            {Button0('Mark Priv', shiftMark(-1), 'btnX')}
            {Button0('test set mark', e => { stopEvent(e); setMark(subState('test', t => 1 + toInt(t))); }, 'btsm')}
            <BtnPopDev txt='mark'>{preJS({ mark }, 3)}</BtnPopDev>
            <BtnPopDev txt='student'>{preJS({ student }, 3)}</BtnPopDev>
            <BtnPopDev txt='work'>{preJS({ work }, 3)}</BtnPopDev>
            <BtnPopDev txt='asm'>{preJS({ asm }, 3)}</BtnPopDev>
            <BtnPopDev txt='Exer'>{preJS({ Exer }, 3)}</BtnPopDev>
            <BtnPopDev txt='qtns'>{preJS({ qtns }, 3)}</BtnPopDev>
            <BtnPopDev txt='SWMs'>{preJS({ SWMs }, 3)}</BtnPopDev>
            <BtnPopDev txt='subProps'>{preJS({ subProps }, 3)}</BtnPopDev>
        </div>
        <div className='flexRowStart'>{preJS({ marking, swmIdx, ttlSWM })}</div>
        </>
    };
    //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only //DEBUG only 

    return <>
        <CpMsgbox vis={visMsg} closeMsg={confirmClose} cancelMsg={cancelClose} iconPath={IconList.general.alert}
            title={"warning.warning_tips"} confirm={"confirm"}
            msg={"warning.warning_confirm_without_saving_marking"} />
        <DoEx {...{...subProps, debugInfo}} />
        <LayerLocalMedia clickClose={() => setOnAddMedia(0)} {...onAddMedia} isAT={0} />
    </>
});
export default PageAsmMark;

/*
        <CpMsgbox vis={shiftInfo.vis} closeMsg={confirmShift} cancelMsg={cancelShift} iconPath={IconList.general.alert}
            title={"warning.warning_tips"} confirm={"confirm"}
            msg={"warning.warning_confirm_without_saving_marking"} />
*/