// multi choice (_MCS) answer choice vvvvvvvvvvvvvvvvvvvvvvvvvvvv
export const __MCAC_ACG = 'ACG'; //grid
export const __MCAC_ACL = 'ACL'; //list
export const __MCAC_ACB = 'ACB'; //label
export const __MCACTypes = {
    [__MCAC_ACG]:'Grid',
    [__MCAC_ACL]:'List',
    [__MCAC_ACB]:'Label',
};

export const __UIMCACTypes = {
    [__MCAC_ACG]:'grid',
    [__MCAC_ACL]:'list',
    [__MCAC_ACB]:'label',
};

// svg icon path
export const __MCACIcons = {
    [__MCAC_ACG]:'presentation/gridMode',
    [__MCAC_ACL]:'presentation/listMode',
    [__MCAC_ACB]:'presentation/labelMode',
};
export const __MCACcodes = [__MCAC_ACG, __MCAC_ACL, __MCAC_ACB];
export const __MCACcodeName = (ACCode) => __MCACTypes[ACCode];
export const __UIMCACcodeName = (ACCode) => __UIMCACTypes[ACCode];
export const __MCACcodeIcon = (ACCode) => __MCACIcons[ACCode];
// multi choice - answer choice ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

// multi choice (_ACG, _ACL) answer input type vvvvvvvvvvvvvvvvvvvvvvvvvvv
export const __MCAT_ATG = 'ATG'; //general
//export const __MCAT_ATE = 'ATE'; //equation
export const __MCAT_ATI = 'ATI'; //image
export const __MCATTypes = {
    [__MCAT_ATG]:'General',
    //[__MCAT_ATE]:'Equation',
    [__MCAT_ATI]:'Image',
};
export const __UIMCATTypes = {
    [__MCAT_ATG]:'editor.general',
    //[__MCAT_ATE]:'editor.equation',
    [__MCAT_ATI]:'editor.image',
};
export const __MCATIcons = {
    [__MCAT_ATG]:'inputType/generalEditor',
    //[__MCAT_ATE]:'inputType/equationEditor',
    [__MCAT_ATI]:'inputType/imageEditor',
};
//export const __MCATcodes = [__MCAT_ATG, __MCAT_ATE, __MCAT_ATI];
export const __MCATcodes = [__MCAT_ATG, __MCAT_ATI];
export const __MCATcodeName = (ATCode) => __MCATTypes[ATCode];
export const __UIMCATcodeName = (ATCode) => __UIMCATTypes[ATCode];
export const __MCATcodeIcon = (ATCode) => __MCATIcons[ATCode];
// multi choice answer input type ^^^^^^^^^^^^^^^^^^^^^^^^^^^

// multi choice table (_MCT) answer choice vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
export const __MCTT_TTC = 'TTC'; //checkbox
export const __MCTT_TTR = 'TTR'; //radio
export const __MCTT_TTB = 'TTB'; //List
export const __MCTTTypes = {
    [__MCTT_TTC]:'CheckBox',
    //[__MCTT_TTR]:'Radio',
    [__MCTT_TTB]:'List',
};
export const __UIMCTTTypes = {
    [__MCTT_TTC]:'checkbox',
    //[__MCTT_TTR]:'Radio',
    [__MCTT_TTB]:'list',
};
//export const __MCTTcodes = [__MCTT_TTC, __MCTT_TTR, __MCTT_TTB];
export const __MCTTcodes = [__MCTT_TTC, __MCTT_TTB];
export const __MCTTcodeName = (TTCode) => __MCTTTypes[TTCode];
export const __UIMCTTcodeName = (TTCode) => __UIMCTTTypes[TTCode];
// multi choice table answer answer choice ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

// multi choice table (_MCT) scoring method vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
/*
export const __MCTS_PRW = 'PRW'; //per row
export const __MCTS_PQU = 'PQU'; //per question

export const __MCTSScoreTypes = {
    [__MCTS_PRW]:'Per row',
    [__MCTS_PQU]:'Per question',
}
export const __MCTSScoreCodes = [__MCTS_PRW, __MCTS_PQU];
export const __MCTScoreName = (ScCode) => __MCTSScoreTypes[ScCode];
*/
// multi choice table (_MCT) scoring method ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^