
const ChartSingleRange = (props) => {
    const minWidth = 1.5
    const lineWidth = 2.6 //css: $lineWidth = 1.3 * 2
    const edge = 10
    const shiftBubbleLeft = 25
    const shiftBubbleRight = 10
    const {line = [], range} = props
    const {min = 0, minPercentage = 0, max = 0, maxPercentage = 0, total = 0} = range || {}

    const rangeData = () => {
        let width = maxPercentage - minPercentage
        let left = minPercentage - 0
        let indicatorShiftStyle = ""

        if (width === 0) {
            left = Math.max(left - minWidth / 2, 0);
            left = Math.min(left, 100 - minWidth);
        } else if (width < minWidth) {
            left -= minWidth / 2
            left = Math.max(left, 0);
        }

        //if max and min !== 0 but width ===0, set width = minWidth
        if (maxPercentage !== 0 && minPercentage !== 0) {
            width = Math.max(width, minWidth)
        }

        let indicatorLeftFormular = left + width / 2
        let indicatorLeft = `${indicatorLeftFormular}%`

        //if indicator: 0 - 10 || 90 - 100, shift the bubble
        if ((minPercentage <= edge || maxPercentage >= 100 - edge) && maxPercentage !== minPercentage && width < edge) {
            if (minPercentage <= edge) {
                indicatorLeft = `calc(${indicatorLeftFormular}% + ${shiftBubbleRight}px)`
                indicatorShiftStyle = "shiftRight"
            }
            if (maxPercentage >= 100 - edge) {
                indicatorLeft = `calc(${indicatorLeftFormular}% - ${shiftBubbleLeft}px)`
                indicatorShiftStyle = "shiftLeft"
            }
        }

        if (range !== undefined && minPercentage === 0 && maxPercentage === 0) {
            indicatorLeft = `calc(${minWidth / 2}%)`
            indicatorShiftStyle = ""
        }

        return {
            width: `${width}%`,
            left: `${left}%`,
            indicatorLeft,
            indicatorShiftStyle
        }
    }

    const shiftLine = (linePercentage) => {
        //if max and min !== 0 but width ===0, center the line in visual
        if (maxPercentage !== 0 && minPercentage !== 0 && maxPercentage - minPercentage < minWidth) {
            return `calc(${(linePercentage + minWidth / 2 + linePercentage - minWidth / 2) / 2}% - ${lineWidth}px)`
        } else {
            return `${linePercentage}%`
        }
    }

    const renderIndicator = (min, max) => {
        let result = `${min}`
        if (max !== min) {
            result += ` - ${max}`
        }
        return result
    }

    return <>
        <div className={"single-range-chart mb-5"}>
            {range !== undefined && <div className={"range-bar"} style={rangeData()}/>}
            {Array.isArray(line) === true && line.map((li, idx) => {
                // if (li.value === 0) return ""
                return <div className={`line`} key={idx} style={{
                    borderColor: li.color,
                    left: `${shiftLine(li.value)}`
                }}></div>
            })}
            {range !== undefined && <div className={`range-indicator-container`} style={{
                left: `${rangeData().indicatorLeft}`
            }}>
                <div className={`range-indicator semi-bold px-2 rounded-light text-nowrap ${rangeData().indicatorShiftStyle}`}>{renderIndicator(min, max)}</div>
            </div>}
            {total !== 0 && <>
                <div className={"range-bar-indicator semi-bold start-0"}>0</div>
                <div className={"range-bar-indicator semi-bold end-0"}>{total}</div>
            </>}
        </div>
        {/*<div>Debug Left:{rangeData().left}</div>*/}
    </>
};

const ChartSingleRangeV1 = (props) => {
    const minWidth = 1.5;
    const lineWidth = 1;
    const edge = 10;
    const shiftBubbleLeft = 25;
    const shiftBubbleRight = 10;
    const {line = [], range} = props;
    const {min = 0, minPercentage = 0, max = 0, maxPercentage = 0, total = 0} = range || {};

    const rangeData = () => {
        let width = maxPercentage - minPercentage;
        let left = minPercentage - 0;
        let indicatorShiftStyle = "";

        if (width === 0) {
            left = Math.max(left - minWidth / 2, 0);
            left = Math.min(left, 100 - minWidth);
        } else if (width < minWidth) {
            left -= minWidth / 2;
        };

        //if max and min !== 0 but width ===0, set width = minWidth
        if (maxPercentage !== 0 && minPercentage !== 0) {
            width = Math.max(width, minWidth);
        };

        let indicatorLeftFormular = left + width / 2;
        let indicatorLeft = `${indicatorLeftFormular}%`;

        //if indicator: 0 - 10 || 90 - 100, shift the bubble
        if ((minPercentage <= edge || maxPercentage >= 100 - edge) && maxPercentage !== minPercentage && width < edge) {
            if (minPercentage <= edge) {
                indicatorLeft = `calc(${indicatorLeftFormular}% + ${shiftBubbleRight}px)`;
                indicatorShiftStyle = "shiftRight";
            };
            if (maxPercentage >= 100 - edge) {
                indicatorLeft = `calc(${indicatorLeftFormular}% - ${shiftBubbleLeft}px)`;
                indicatorShiftStyle = "shiftLeft";
            };
        };

        return {
            width: `${width}%`,
            left: `${left}%`,
            indicatorLeft,
            indicatorShiftStyle
        };
    };

    const shiftLine = (linePercentage) => {
        //if max and min !== 0 but width ===0, center the line in visual
        if (maxPercentage !== 0 && minPercentage !== 0 && maxPercentage - minPercentage < minWidth) {
            return `calc(${(linePercentage + minWidth / 2 + linePercentage - minWidth / 2) / 2}% - ${lineWidth}px)`;
        } else {
            return `${linePercentage}%`;
        };
    };

    const renderIndicator = (min, max) => {
        let result = `${min}`;

        if (max !== min) {
            result += ` - ${max}`;
        };
        return result;
    };

    return <><div className={"single-range-chart mb-5"}>
            {!(min === 0 && max === 0) && <div className={"range-bar"} style={rangeData()}/>}
            {Array.isArray(line) === true && line.map((li, idx) => {
                if (li.value === 0) return "";
                return <div className={`line`} key={idx} style={{
                    borderColor: li.color,
                    left: `${shiftLine(li.value)}`
                }}></div>
            })}
            {!(min === 0 && max === 0) && <div className={`range-indicator-container`} style={{
                left: `${rangeData().indicatorLeft}`
            }}>
                <div className={`range-indicator semi-bold px-2 rounded-light text-nowrap ${rangeData().indicatorShiftStyle}`}>{renderIndicator(min, max)}</div>
            </div>}

            {total !== 0 && <>
                <div className={"range-bar-indicator semi-bold start-0"}>0</div>
                <div className={"range-bar-indicator semi-bold end-0"}>{total}</div>
            </>}
        </div>
        {/*<div>Debug Left:{rangeData().left}</div>*/}
    </>
};

export default ChartSingleRange;

const ChartSingleRange_old = (props) => { // base on poc SingleRangeChart.tsx
    const {line = [], range} = props;
    const {min = 0, minPercentage = 0, max = 0, maxPercentage = 0, total = 0} = range || {};

    const rangeData = () => {
        const width = maxPercentage - minPercentage;
        const left = minPercentage - 0;
        return {
            width: `${width}%`,
            left: `${left}%`
        };
    };
    
    return <div className={"single-range-chart mb-5"}>
        <div className={"range-bar"} style={rangeData()}>
            {Array.isArray(line) === true && line.map((li, idx) => {
                if (li.value === 0) return "";
                return <div className={`line`} key={idx} style={{
                    borderColor: li.color,
                    left: `${li.value}%`
                }}></div>
            })}
            {(min !== 0 && max !== 0) &&
                <div className={"range-indicator semi-bold px-2 rounded-light text-nowrap"}>{min} - {max}</div>}
        </div>
        {total !== 0 && <>
            <div className={"range-bar-indicator semi-bold start-0"}>0</div>
            <div className={"range-bar-indicator semi-bold end-0"}>{total}</div>
        </>}
    </div>
};
