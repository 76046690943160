import React, { useMemo } from 'react';
import * as SQT from '../../consts/ATSysQType';

import CpQDoMCQ from './CpQDoMCQ';
import CpQDoFIB from './CpQDoFIB';
import CpQDoOPE from './CpQDoOPE';
import CpQDoLBD from './CpQDoLBD';
import CpQDoDraw from './CpQDoDraw';
import { autoId, } from '../AppUtil';
import { toStr } from '../../libs/libType';
import { rmMediaSrc } from '../../libs/libHtml';


/*
// Only CpATQtnDo use CpQDo*s ------ ------ ------ ------ 
CpQDoOPE / CpQDoMCQ / CpQDoFIB.... <- 
	CpATQtnDo <- // only 3 users CpATQtnDo ------ ------ ------ ------
		PageATQtns
		TabQtnEdit -> (CpATQtnSingle / CpATQtnDo / CpATQtnPreview) // use direct use 3 Cp, indiriect use 
		CpATQtnSingle

CpATQtnSingle <- // only direct + indirect users for CpATQtnSingle ------ ------ ------ ------
	DoEx
	TabExerContent
	TabExerContent -> ViewExCtnQ
	TabQtnEdit
	TabExerEdContent -> TabQtnEdit -> CpATQtnPreview -> CpATQtnSingle
	TabTeacherAssignmentReport -> TabQuestionPerformace -> CpATQtnDisplayOnly -> CpATQtnSingle

DoEx <- // only 5 users for DoEx ------ ------ ------ ------
	CpExerciseEditPrevieCTA
	PageAsmMark
	PageAsmWork
	PageAssignments
	TabExercise

TabExerEdContent <- // only 2 users ------ ------ ------ ------
	TabTeacherAssignmentTabContainer
	PageExerciesEdit 
*/

const CpATQtnDo = props => {
    const { fullQ, Q, doWhat, isThr, isStt, showResult, PVMode, scrollRef=null, overflow=false } = props;

    const myKey = useMemo(() => autoId(),[fullQ]);

    if (!Q) return <div>QuestionDo No Question To Show or Edit.</div>;
    Q.qData = rmMediaSrc(toStr(Q.qData));

    const qt = fullQ?.SQType || '';
    const preview = (doWhat === "preview");
    const asmView = doWhat === "asmView";
    const asmEdit = doWhat === "asmEdit";
    const asmAll = (asmView || asmEdit);
        
    const isStudent = (isStt || preview) && (!isThr); // preview as student view
    const isTeacher = isThr;
    const isAT = (!isStt && !isTeacher); //userA or userB or sysadmin

    const doEdit = (props?.editable || (doWhat === "edit") || preview || asmEdit) 
        && (!showResult) && (!isTeacher);
    const doView = doWhat === "view";
 
    const editable = doEdit;
    const fullPage = (props?.fullPage);

    const isSingle = (editable && (PVMode ===undefined || PVMode ===0));
    const isList = (PVMode ===1 || PVMode ===2);

    const containerClass = 'tabDoQContent ' + ((preview || asmAll) ? " Preview " : (editable ? " Editable " : " ReadOnly unclickable usel ")
            + (fullPage?' fullPage ':'')) + (isSingle?' single ':'') + (overflow?' Overflow':'');

    const DoQtn =
        (qt === SQT.__SYSQSubType_MCS)?CpQDoMCQ:
        (qt === SQT.__SYSQSubType_MCT)?CpQDoMCQ:
        (qt === SQT.__SYSQSubType_FIB)?CpQDoFIB:
        (qt === SQT.__SYSQSubType_LBT)?CpQDoLBD:
        (qt === SQT.__SYSQSubType_LBD)?CpQDoLBD:
        (qt === SQT.__SYSQSubType_OED)?CpQDoDraw:
        (qt === SQT.__SYSQSubType_OEG)?CpQDoOPE:
        (qt === SQT.__SYSQSubType_OEE)?CpQDoOPE: 
        (qt === SQT.__SYSQSubType_POL)?CpQDoOPE:
        (props => <div>
            <h1>Question Do</h1>
            Question Do Unknown Question Type: '{qt}'.
        </div>);

    //Q={rmFullQCorrAns(fullQ).QEn}
    return <React.Fragment key={myKey}><DoQtn {...props}  {...{containerClass, editable, preview, asmView, asmEdit, fullPage, doEdit,
        doView, isTeacher, isStudent, isStt, isAT, isSingle, isList, showResult}}/>
    </React.Fragment>
};
export default CpATQtnDo;
