import { useEffect, useState } from 'react';
import { _WebRoot } from '../../consts/ATConstReact';
import { _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, _ATMetaType_EX, _ATMetaType_GR, _ATMetaType_QT, _ATMetaType_TB } from '../../consts/ATMetaTypes';
import { apiCallLoad_ } from '../../libs/awsFuncs';
import { arrayAddOrDelete_ } from '../../libs/libArray';

import { objKeys, toAry, toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';
import { useStateGST } from '../../saga/globalState.saga';
import { ReduxBind } from '../../saga/ReduxState';
import { ATHr, clickUrl, preJS } from '../../AppExPf/AppUtil';
import LayerMediaPool from '../../AppExPf/ATMediaPool/LayerMediaPool';
import Ckeditor5Base from '../../AppExPf/components/ckeditor5/Ckeditor5Base';
import { CpTypeMetaTrees } from '../../AppExPf/components/CpTypeMetaTree';
import { asyncDL, getMediaData, useMediaCache, web2Base64 } from '../../AppExPf/utils/useMediaCache';
import { buildMetaTree } from '../../AppExPf/utils/utilWalkTree';
import PageDevCK from './PageDevCK';
import PageDevMP1 from './PageDevMP1';
import PageDevQEdit from './PageDevQEdit';
import PageDevS3 from './PageDevS3';
import { Buffer } from 'buffer';


const baseUrl = _WebRoot + 'dev';
const PageDev = ReduxBind(props => {
    const { dispatch } = props;
    const dev = props.match?.params?.dev || '';
    const pageMap = {
        's3': PageDevS3,
        'dlimg': PageDevDLImg,
        'MTree': PageDevMtree,
        'media pool 1': PageDevMP1,
        'media pool': PageMPool,
        'ck (MPool)': PageCKMPool,
        'ck (FIB)': PageDevCK,
        'qedit': PageDevQEdit,
        'youtube': PageDevYoutube,
        'img tag': PageImgCrossSite,
        //update: PageUpdateAdd,
    }
    const Page = pageMap[dev]; 
    if(Page) return <Page {...{props}}/>;

    return <div>
        <h1>PageDev</h1>
            {objKeys(pageMap).map(k => <span key={k}> {UI.Button0(k, clickUrl(dispatch, baseUrl+'/'+k))} </span> )}
    </div>;
});
export default PageDev;

const PageDevDLImg = ReduxBind(props => {
    const src = '/img/67byte.png';

    const [packs, setPacks] = useState([]);

    //https://developer.mozilla.org/en-US/docs/Web/API/Response

    const _fetch = async () => {
        const response = await fetch(
            src,//`https://www.wiris.net/demo/editor/render.svg`,
            {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                //responseType: 'json'
            }
        )
        console.log('_fetch',{response});
        return await response.arrayBuffer();
    };

    const log1 = b => ' dec: '+b+', hex: '+ b.toString(16).toUpperCase()+', bin: '+ b.toString(2).toUpperCase();

    useEffect(() => {
        console.log ('VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV');
        const func = async () => {
            console.log('PageDevDLImg effect()');
            
            const fraw = await _fetch();
            console.log('fraw:', fraw.byteLength, fraw);
            const fbuf = Buffer.from(fraw, 'binary');
            const fbufStr64 = fbuf.toString('base64');
            
            const raw = await getMediaData(src);

            const bufs = 'ascii,base64,hex,latin1,utf8,utf16le,binary,ucs-2,utf-8,utf-16le'.split(',').map( enc => {
                const buf = Buffer.from(raw, enc);
                const bufStr64 = buf.toString('base64');
                console.log ('^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^');
                for(var i = 0; i < buf.length; i++) console.log(enc+' buf '+i+' '+log1(buf[i]));
                return 'data:image/png;base64,' + bufStr64;
            });
            const packs = [
                ...bufs,
                'data:image/png;base64,' + await web2Base64(src), 
                'data:image/png;base64,' + fbufStr64,
            ];
            setPacks(packs);
            //await _axios();
            console.log ('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
        };
        func();
    },[]);

    return <div>
        <h4>PageDevDLImg</h4>
        67byte.png html load: <span style={{border:'1px solid #f00', padding:'4px', margin:'4px' }}><img src="/img/67byte.png" /></span>
        <hr/>
        packs: {toAry(packs).map((s,i) => 
            <span key={i} style={{border:'1px solid #f00', padding:'4px', margin:'4px' }}><img src={s}/></span>
        )}
        <hr/>
        src: {src}
        <hr/>
        should begin with (hex): 89 50 4E 47 0D 0A 1A 0A....<br/>
        https://evanhahn.com/worlds-smallest-png/<br/>
        https://adevait.com/nodejs/convert-node-js-buffer-to-string<br/>
        https://nodejs.org/api/buffer.html#static-method-bufferfromstring-encoding<br/>

        <img src={"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4QAqRXhpZgA"+
        "ASUkqAAgAAAABADEBAgAHAAAAGgAAAAAAAABHb29nbGUAAP/bAIQAAwICAwICAwMDAwQDAwQFC"+
        "AUFBAQFCgcHBggMCgwMCwoLCw0OEhANDhEOCwsQFhARExQVFRUMDxcYFhQYEhQVFAEDBAQFBAUJBQU"+
        "JFA0LDRQUFBQUExQPFBQUFBQUFBQUFBQUFBQUEBUUFBQVDxQUFA4UFBQVFBEVFBQTFBQUERQU/8A"+
        "AEQgAFAAUAwERAAIRAQMRAf/EABoAAAEFAQAAAAAAAAAAAAAAAAgEBQYHCQP/xAAnEAABAwMEAQMFAAA"+
        "AAAAAAAABAgMFBBEhAAYHEmETIjEII0FCof/EABkBAAIDAQAAAAAAAAAAAAAAAAQGAwUHAv/EACwRAA"+
        "EDAwIDBgcAAAAAAAAAAAECAxEABCExUQUGEkFhweHw8RMiMnGBsdH/2gAMAwEAAhEDEQA/AM6GuRZOa"+
        "plxjVGyh2oQWkuNEhQxm3m2NVhtUNHrJwKPDql/KBVkO/SDPDa9DKRM9QyMm+EOCLbSUOtNFQBeKrmyR"+
        "83tnqbXItpbb5nZLpbeaKUyROudojt98VZq4QtKAptYJ1inWqjEsVC2i428WyUF1o3Qu2OyTYYPyNGJV"+
        "1CRiuYjFRSf4KmuERszcM49RvOzdIJOgj2qhtai0W0KBc6LUW7esgWUkEnsAD1JDOi3evVFgCJ7Z8Kq"+
        "SWmEhwKk7R40SXB3M8NOcPbm2puGoVB1VLQ1b7zTTQSiqZKlOKSwv9VdlC4JTg4CsqTn/HuWb3h9wi4t"+
        "09SCpIB1IOAOoffAORvGlMVpxVm5a+C4YUAZ79Tj1/aGyOl9xGjbK32XV2ytbIyfFrY8/nW0K5aszmFD"+
        "8+RpUTfORSrdG5ZvccjGtzM7LTbVBQKYo25Svdq00rVh9toOKV6aPan2psMDGg+GmHFK2FDOfTXCnYQa"+
        "ZN09u1yQrOQQR/dPgSClJ7/36mgN6WvrU2vrcqsPk6kccKVECpUiRX//2Q=="} />

    </div>;
});

const PageDevYoutube = ReduxBind(props => {
    return <div>
        <h4>test embed youtube</h4>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/f5FWc7wS_aQ" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>
        <hr/>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/joAeFGLzQZE" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>
        <hr/>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/YIZCano1dP0" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>
    </div>;
}); 

const PageDevMtree = ReduxBind( props => {
    const dispatch = props.dispatch;
    const [opens, setOpens] = useStateGST(props, 'QFindTreOps', []);
    const setOpen = (id, o) => e => { UI.stopEvent(e); setOpens(os => arrayAddOrDelete_(os, id, o)) };  

    const [metaTicks, setMetaticks] = useStateGST(props, 'QFindMTTicks', []);
    const setMetaTick = mid => on => setMetaticks(ts => arrayAddOrDelete_(ts, mid, on));

    const [typeMetaSets, setTypeMetaSets] = useStateGST(props, 'QFindTyMetaSets', 0);
    const [] = useState();

    const onLoadMS = (res, err) => { 
        const mss = buildMetaTree(res.metaSets, res.metas);
        const tms = {};
        Object.entries(mss).forEach( ([msid, ms]) => { 
            const mt = ms.MSType;
            tms[mt] = {...tms[mt]||{}, [msid]:ms}; 
        } );
        
        setTypeMetaSets(tms);
    };
    useEffect(() => {
        const init = !typeMetaSets;
        if (init) apiCallLoad_(dispatch, '/getATMetaOpts', onLoadMS, {});
    }, []);

    const para = {ticks:metaTicks, setTick:setMetaTick, opens, setOpen};
    return <div> 
        <h1>PageDev1</h1>
        {typeMetaSets && <>
            <CpTypeMetaTrees typeName="Textbook" metaSets={typeMetaSets[_ATMetaType_TB]} {...para} />
            <ATHr />
            <CpTypeMetaTrees typeName="Curriculum" metaSets={typeMetaSets[_ATMetaType_CU]} {...para}  />
            <ATHr />
            <CpTypeMetaTrees typeName="Question Category" metaSets={typeMetaSets[_ATMetaType_QT]} {...para} />
            <div>//SYSQTYPE</div>
            <CpTypeMetaTrees typeName="Grades" metaSets={typeMetaSets[_ATMetaType_GR]} {...para} />
            <ATHr />
            <CpTypeMetaTrees typeName="Copyright" metaSets={typeMetaSets[_ATMetaType_CO]} {...para} />
            <CpTypeMetaTrees typeName="Difficulty" metaSets={typeMetaSets[_ATMetaType_DI]} {...para} />
            <CpTypeMetaTrees typeName="Examination Types" metaSets={typeMetaSets[_ATMetaType_EX]} {...para} />
        </>}
        <ATHr /><ATHr /><ATHr />
        {typeMetaSets && <>
        {Object.entries(typeMetaSets).map(([mstype, metaSets]) => 
            <CpTypeMetaTrees typeName={mstype} metaSets={metaSets} {...para}/>
        )}
        </>}
    </div>;
});


const dynamicCKs = [ 'ck1', 'ck2', 'dck3'];
export const PageCKMPool = ReduxBind( props => {
    const [ onAddMedia, setOnAddMedia ] = useState(0);
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, props.dispatch);

    const [ ckDatas, setCKDatas ] = useState({}); // map of Data
    const setCKData = ckid => data => setCKDatas(datas => ( {...datas, [ckid]:data } ) );

    const ckImageConfig = {
        setOnAddMedia,
        updateMediaID: arr => { console.log("media id arr return from ckeditor:", arr); },
        callMediaPool: () => { return {mediaID:'md0001', dl:'/img/picEn.jpg'}; },
    };
   
    return <div>
        {dynamicCKs.map(id => <div key={id} >
            <Ckeditor5Base key={id} id={id} style={cssCK} editorType="question"     
                data={ckDatas[id]} setData={setCKData(id)} debug={true} showStatus={true} ckImageConfig={ckImageConfig} noDragDrop={true} /> 
        </div> )}
        <LayerMediaPool clickClose={()=>setOnAddMedia(0)} {...onAddMedia} {...{mediaDLs, getMediaDLs}} />
    </div>
});
const cssCK = {width:'100%', padding:'16px', margin:'16px'};

// ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### #####
const PageMPool = ReduxBind( props => {
    const {dispatch} = props;
    const [onAddMedia, setOnAddMedia] = useState(0);
    const [ mediaDLs, getMediaDLs, ] = useMediaCache(props, dispatch);    
    
    const [medias, setMedias] = useState([]);

    const onLoad = (res, err) => setMedias(res.medias);
    const reload = () => apiCallLoad_(props.dispatch, '/getATMedias', onLoad, {});
    useEffect(() => { reload(); }, []);

    const clickPopEx = on => e => { UI.stopEvent(e); 
        setOnAddMedia({onAdd:(medias => {
            console.log(medias);
            setOnAddMedia(0); //stop receiveMedia
        }),maxMedia:10}); 
    };

    const clickDL = mediaId => e => { UI.stopEvent(e); asyncDL(dispatch, getMediaDLs, mediaId) };

    return <div> 
        <h1>PageMPool</h1>
        LayerMediaPool should be shown.                
        {'' && UI.ScreenMask(<>
            <div className="screenMask2"></div>
            <div className="LayerMPool">
             <h1>Media Pool</h1>
        </div></>)}
        <div>{UI.Button0('pop' , clickPopEx(1), 'btnPop')}</div>
        <LayerMediaPool clickClose={()=>setOnAddMedia(0)} {...{onAddMedia, mediaDLs, getMediaDLs}} />
        {UI.Button0('DL_', clickDL('123'), 'bm')}

        <div>{toAry(medias).map(m => 
            <div key={m.mediaId} className="box1 m4 p4">
                {preJS(m,3)}
                {UI.Button0('DL', clickDL(m.mediaId), 'bm')}
            </div>
        )}</div>

    </div>;

});

const PageImgCrossSite = ReduxBind( props => {
    const {dispatch} = props;
    const [onAddMedia, setOnAddMedia] = useState(0);
    const [ mediaDLs, getMediaDLs ] = useMediaCache(props, dispatch);

    //const [mID, setMID] = useState("UPurXx7BsknyddYPY3zUph");
    const [mID, setMID] = useState();
    const [mSrc, setMSrc] = useState(s3imgSrc);
    const [infoTxt, setInfoTxt] = useState('');
    const [displayTxt, setDisplayTxt] = useState('');

    useEffect(()=>{ if (mID && getMediaDLs) getMediaDLs([mID]); },[mID]);

    useEffect(()=>{ 
        if (mediaDLs && mediaDLs[mID]) { setMSrc(mediaDLs[mID].dl); };
    },[mediaDLs]);

    useEffect(()=>{
        if (infoTxt) {
            const dd = new Date();
            setDisplayTxt(displayTxt + '\n' + dd.toString() + ':' + infoTxt);
            var infoContent = document.getElementById('infoContent');
            infoContent.scrollTop = infoContent.scrollHeight;
        };
    },[infoTxt]);

    const doAddBG = (e) => { UI.stopEvent(e);
        if (setOnAddMedia) {
            setOnAddMedia({onAdd:(medias => {
                const ms = toAry(medias);
                
                if (ms.length > 0) {
                    const src = toStr(ms[0].dl);
                    const mediaId = toStr(ms[0].mediaId);
                    setMID(mediaId);
                    if (src !== '') setMSrc(src);
                };
                setOnAddMedia(0);
            }),maxMedia:1, mimes:['image']});
        };
    };

    const screenInfo = (txt) => { setInfoTxt(txt); };
    const loadOK = (e, id) => { screenInfo('loaded img '+id) };
    const loadError = (e, id) => { screenInfo('Error img '+id) };
    const boldTxt = (txt) => <span className="bold">{txt}</span>
    const imgWH = {width:"200px", height:"200px"};
    
    return <div className="flexColStart">
        <span className="f20 bold">{"clear browser image cache to test"}</span>
        <div className="flexRowStart">{UI.Button0('choose image', (e)=>doAddBG(e), 'addImg')}{"require login?"}</div>
        <div className="flexRowStart">{boldTxt('img ID:')}{mID}</div>
        {boldTxt('img Src:')}
        <div className="flexRowStart" style={{border:"1px solid grey",wordBreak:"break-all"}}>{mSrc}</div>
        <textarea id='infoContent' style={{width:'100%', minHeight:'100px', maxHeight:'100px', overflow:'auto', 
            border:'1px solid black'}} defaultValue={displayTxt}></textarea>
        {/*<img style={{ display: "none" }} src={mSrc} onError={(e)=>loadError(e, 1)} onLoad={(e)=>loadOK(e, 1)} alt='image 1'/>*/}
        <p></p>{boldTxt("s3 image, with cross-site attribute")}
        <div className="flexRowCenter">
            <img className='LDARImg' src={mSrc} style={imgWH} onError={(e)=>loadError(e, 1)} onLoad={(e)=>loadOK(e, 1)} alt='img s3-1' crossOrigin='anonymous'/>
            <img className='LDARImg' src={mSrc} style={imgWH} onError={(e)=>loadError(e, 2)} onLoad={(e)=>loadOK(e, 2)} alt='img s3-2' crossOrigin='anonymous'/>
        </div>
        <p></p>{boldTxt("s3 image, NO cross-site attribute")}
        <div className="flexRowCenter">
            <img className='LDARImg' src={mSrc} style={imgWH} onError={(e)=>loadError(e, 3)} onLoad={(e)=>loadOK(e, 3)} alt='img s3-3' />
            <img className='LDARImg' src={mSrc} style={imgWH} onError={(e)=>loadError(e, 4)} onLoad={(e)=>loadOK(e, 4)} alt='img s3-4' />
        </div>
        <p></p>{boldTxt("web image, with cross-site attribute (" + webImg1 +")")}
        <div className="flexRowCenter">
            <img className='LDARImg' src={webImg1} style={imgWH} onError={(e)=>loadError(e, 5)} onLoad={(e)=>loadOK(e, 5)} alt='img web-5' crossOrigin='anonymous'/>
            <img className='LDARImg' src={webImg1} style={imgWH} onError={(e)=>loadError(e, 6)} onLoad={(e)=>loadOK(e, 6)} alt='img web-6' crossOrigin='anonymous'/>
        </div>        
        <p></p>{boldTxt("web image, NO cross-site attribute (" + webImg1 +")")}
        <div className="flexRowCenter">
            <img className='LDARImg' src={webImg1} style={imgWH} onError={(e)=>loadError(e, 7)} onLoad={(e)=>loadOK(e, 7)} alt='img web-7'/>
            <img className='LDARImg' src={webImg1} style={imgWH} onError={(e)=>loadError(e, 8)} onLoad={(e)=>loadOK(e, 8)} alt='img web-8'/>
        </div>        
        <p></p>{boldTxt("web image, NO cross-site attribute (" + webImg2 +")")}
        <div className="flexRowCenter">
            <img className='LDARImg' src={webImg2} style={imgWH} onError={(e)=>loadError(e, 9)} onLoad={(e)=>loadOK(e, 9)} alt='img web-9'/>
            <img className='LDARImg' src={webImg2} style={imgWH} onError={(e)=>loadError(e, 10)} onLoad={(e)=>loadOK(e, 10)} alt='img web-10'/>
        </div>        
        <LayerMediaPool clickClose={()=>setOnAddMedia(0)} {...onAddMedia} {...{mediaDLs, getMediaDLs}} />
    </div>
});

// time limited test s3 url
const s3imgSrc = `https://expdev1-oupc-exercise-platform-web-bucket.s3.ap-east-1.amazonaws.com/media/oup/UPurXx7BsknyddYPY3zUph.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIARBAKEOGAJEOB43WO%2F20240813%2Fap-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240813T232019Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWFwLWVhc3QtMSJIMEYCIQDJb%2B1i3IKYVzZqS8Duw51h852ziDAezkg%2Bfw%2BflGmpIAIhALczVnW3wNOaA05NPNoCfR7HbKI2lgtvwUDINaSy1D4%2BKrIDCK%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMMDcwODg4MjIzMTA0IgxR1VSda6CTQy55GPkqhgNPoNfB%2Fg6v3%2BBiW92VY08u%2Fo7ZqY1C4arlG019dl6vVLk%2FtB6xm%2FAYaW5Fy8TTjYrRKh8c%2ByKNo9h5uzJAcjxGRy%2FKcMR95JnztxLfwF62VaSSn4WN8spQ6HwCr4xy9ZWW3kGuoxIs2dTIWU7%2F3L9c2GEwnUcfJa%2FuYzjg4qGW9wfvKMXgZqwwm5s6bm1DC%2BkFKJ5ljggmHFWcfVs%2FI2PIUAVnl2thfyIXWGWNwgshaSJ9L6XQ%2FRsT1yHoebTRVb5lwP1EWob%2BedKghoknEGWGiHrp3P%2FysmAY8A8Wk2Rgq1M4McHbk2aeKfttSWRtHl%2FopZu5qVCthPO95juzsPWtQ2APaDj8A3NVbDhcEqtE%2F%2FJ2h1eCTqJKm2DVCa1n3vcjHtiLMZO9YGmenLRUFHVhUUtAcub3AtMFaTi9c4Rl0gjUBp8e4FgxY5cZSCCzXtgB05LlU3zA%2BbMY%2BNKdSJz7LH2qBUS15MqG2%2BqB1NH%2BuI3V7IdJdSDOz1Rzvz77DiQ%2FV7rBamsw4KPvtQY6nAHpGmQLXzykLP%2FqWBRgsca4qISNjidVDuJ72Jpv5hbNbXVMMpt1k9vwf6DeJZh8ydkTCiq23RJx4s6QigAXWx8TX5PxYX%2BYwqLqMJZVGwocEJf1Km9SvaDYBb6msb9FQ%2BtrnlG5bJZuHHhUCpfV5eLGLmfDMs8G4y0zIlu%2BayVphSu5oYbIgz6kLPomGUMXrtxdnhwgoJVoBKc9XU4%3D&X-Amz-Signature=d7ff547c6a78ba8f30fbbd3ec9b50cd54557e5879a1dd031bb0da0b8724a8f87&X-Amz-SignedHeaders=host&response-content-type=image%2Fjpg`;
const webImg1 = `https://www.w3schools.com/html/pic_trulli.jpg`;
const webImg2 = `https://www.w3schools.com/html/img_chania.jpg`;