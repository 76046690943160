import React, { useEffect, useMemo, useState, useRef } from 'react';
import { QP_P, QP_PP } from '../../consts/ATConsts';
import { toUniIdAry } from '../../consts/ATValidate';
import { toAry, toStr, toInt, toObj, isAry, minVal, maxVal, min_max, toPercent1 } from '../../libs/libType';
import * as UI from '../../libs/libUI';
import { deepCopy } from '../AppUtil';
import CpATQtnHeader from '../qtnDo/CpATQtnHeader';
import CpATQtnSingle from '../qtnDo/CpATQtnSingle';
import { calculateScore } from '../../consts/ATQtnScore';
import {
    UIDisplayName, _ExCtType_CK, _ExCtType_Img, _ExCtType_Lnk, _ExCtType_Mp3, _ExCtType_Qtn,
    _ExCtType_Txt, _ExCtType_Video
} from '../../consts/ATValidateEcnts';
import { CpECtnViews } from './TabExerContent';
import { useUILang } from '../utils/useUILang';
import CpAssignStdFilter from '../../AppExPFUser/EPAsmWork/CpAssignStdFilter';
import { stQtnAllInfo, oneStAnsQtnStatus, remeAddWorkAnsCorr } from '../../consts/ATQtnScore';
import { markState } from '../../AppExPFUser/EPAsmWork/utilAsmWork';
import { __SYSQSubType_POL, __SYSQType_POL, __SYSQSubType_OED, __RESP_FILE, __RESP_IMAGE, __RESP_DRAW } from '../../consts/ATSysQType';
import { timeStampNowGMT } from '../../libs/libTime';
import { ReduxBind, debugMode } from '../../saga/ReduxState';
import { Button0 } from '../../libs/libUI';
import { showQtnDrawEx, showFrontQtnDrawEx, hasOverlapDraw } from '../../consts/ATSysQType';
import { unpackQtn } from '../../consts/wirisConfig';
import { qHasCorrect } from '../../consts/ATValidateQ';
import { clearNoUseWiris } from '../components/ckeditor5/Ckeditor5Base';
import { ECtnUniQIds, exerUniQIds } from '../../consts/ATValidateExer';

import { popAlertToast } from '../components/CpPopup';

const QcanDoEx = q => q && (q.schoolId || ((q.drpu === QP_PP) && (q.dp === QP_P)));

export const flattenECtns = (ECtns, qtns) => {
    const tmp = [];
    let QIndex = 0;

    toAry(ECtns).forEach((ECtn, idx) => {
        if (ECtn.type === _ExCtType_Qtn) {
            const qids = toUniIdAry(toStr(ECtn.QIds).split(','));
            //const qtns = useCacheQtns(qids);
            qids.forEach((qid, ii) => {
                if (QcanDoEx(qtns[qid])) {
                    const dupQ = deepCopy(qtns[qid]);
                    dupQ.QId = qid;
                    dupQ.type = _ExCtType_Qtn;
                    dupQ.originalIdx = idx;
                    dupQ.displayIdx = ++QIndex;
                    if (isAry(dupQ?.QEn?.qData) || isAry(dupQ?.QCt?.qData)) {
                        tmp.push(unpackQtn(dupQ));
                    } else {
                        tmp.push(dupQ);
                    };
                };
            });
        } else tmp.push(ECtn);
    });

    return tmp;
};

const DoEx = ReduxBind(props => {
    const loading = props._saga?.loading?.count;

    const { PVMode, showEn, isThr = 0, isStt = 0, uRole, submit, replaceECtn, dispatch,
        doWhat = 'preview', noSave, preview, mediaDLs, getMediaDLs, addLocalMedias, setOnAddMedia,
        qtns, useCacheQtns, allStResp, allTeResp, setTeMark, debugInfo = undefined, hasTimeLimit = 0,
        gotoAtx = -1, setGotoAtx, stdIdx = 0, stMark, modified, setModified, hideHeader, isReme, answerVis,
        SWMs, swmIdx, aPub = 0, marked = 0, timeUsed = 0, remainTime = 0, chgQSave, aHints } = props;
    const [t] = useUILang();
    const [atIdx, setAtIdx] = useState(0);

    const [localSR, setLocalSR] = useState([]); // if no parent student resp, need a local copy for preview submit
    const allStudentResp = allStResp || localSR;
    const setAllStudendResp = (asr) => { if (!allStResp) setLocalSR(asr); };

    const setStWork = props.setStWork || (x => 0);

    const allTeacherResp = allTeResp || [];

    const [showResult, setShowResult] = useState(props?.showResult || 0);
    const [showCorr, setShowCorr] =  useState(props?.showCorr || 0);
    const [allScore, setAllScore] = useState({});
    //const [totalScore, setTotalScore] = useState(0);
    //const [maxScore, setMaxScore] = useState(0);
    const [totalScoreStr, setTotalScoreStr] = useState("");
    const [canRender, setCanRender] = useState(true); // for submit result redraw
    const [startTime, setStartTime] = useState(props?.startTime || 0); // pass in start time
    const [endTime, setEndTime] = useState(0);
    const [saved, setSaved] = useState(0);
    const [rdECtn, setRdECtn] = useState(-1);
    const [vis, setVis] = useState(0); //for header
    const [canVis, setCanVis] = useState(0);
    //const [rdECtn, setRdECtn] = useState(-1);
    const [dummyHeight, setDummyHeight] = useState(60);
    const [bottomMargin, setBottomMargin] = useState(0);
    const [visDraw, setVisDraw] = useState(-1); // -1 no draw vis, ele index
    //const [overlayDraw, setOverlayDraw] = useState(-1);
    const [openState, setOpenState] = useState({overlayDraw:-1, hints:-1, ansKeys:-1, comment:-1});
    const [overBarReady, setOverBarReady] = useState(0);
    const dummyRef = useRef(null);
    const drawRef = useRef(null);
    const listScrollRef = useRef(null);
    const eleRef = useRef([]);
    const doExOverlayBar = useRef(null);
    //const _needDrawDeb = debugMode() && !preview;
    const _needDrawDeb = !preview;
    //useEffect(() => { return (() => { clearNoUseWiris(); }); }, []);
    useEffect(() => {
        let resize_ob;
        if (PVMode === 1 && listScrollRef.current) {
            resize_ob = new ResizeObserver(()=>{
                //console.log('resize_ob overLayDraw', overlayDraw);
                doCalScroll(onListScroll);
            });
            resize_ob.observe(listScrollRef.current);
        };
        return (() => {
            clearNoUseWiris();
            resize_ob && resize_ob.disconnect();
        });
    }, []);

    //useEffect( () => { console.log('totalScoreStr',totalScoreStr);}, [totalScoreStr] );
    useEffect( () => { if (saved && isThr)  popAlertToast(dispatch, 0, "thrSaved"); }, [saved]);

    //useEffect( () => { console.log('bottomMargin', bottomMargin); }, [bottomMargin]);

    const doCalScroll = func => { func && func(); };

    const ref = dummyRef.current;
    useEffect(() => { if (ref) setDummyHeight(ref.clientHeight); }, [vis, ref]);

    useEffect(() => {
        //const exBar = document.getElementById('doExOverlayBar');
        if (PVMode !== 1) return; // only for scroll
        const exBar = doExOverlayBar.current;
        //if (overlayDraw >= 0 && overBarReady) { // include idx 0
        if (exBar) {
            //while (exBar.firstChild) { exBar.removeChild(exBar.firstChild); };
            const cc = exBar.children;
            for (let ii=0; ii < cc.length; ii++) {
                if (cc[ii].id !== ('drawOverlay' + openState.overlayDraw)) cc[ii].remove();
            };
        };
        if (openState.overlayDraw >= 0) { // include idx 0
            //'drawOverlay'+idx
            const ele = document.getElementById('drawOverlay' + openState.overlayDraw);
            //console.log('overlay element (drawOverlay' + overlayDraw +'):', ele);
            if (ele && exBar) {
                exBar.appendChild(ele);
                onListScroll();
            };
        };
        //console.log('openState',openState);
    }, [openState]);
    //}, [overlayDraw, overBarReady]);

    useEffect(()=>{ 
        if (!showCorr) setAllQtnStatInfo();
    }, [swmIdx]); // recalculate total score if changed student

    const close = e => { const _close = props.close; _close && _close(e); };

    // teacher call only
    const shiftMark = (shf, jump = 0) => e => {
        const _shiftMark = props.shiftMark;
        _shiftMark && _shiftMark(shf, jump)(e);
        //setAllQtnStatInfo();
    };

    const asmAll = (doWhat === 'asmView' || doWhat === 'asmEdit');
    const needSetPlay = !showResult && !showCorr && (doWhat !== 'asmView');
    const pECtns = props.ECtns;
 
    const uniQIds = useMemo(() => ECtnUniQIds(pECtns), [pECtns]);
    useCacheQtns(uniQIds);

    const ECtns = useMemo(() => flattenECtns(pECtns, qtns), [pECtns, qtns, stdIdx]);
   
   const setPlayed = idx => { if (needSetPlay) setRdECtn(idx); };

    const _setRdECtn = (ii) => {
        if (needSetPlay && [_ExCtType_Img, _ExCtType_CK, _ExCtType_Txt, _ExCtType_Mp3, _ExCtType_Video].includes(ECtns[ii].type)) {
            const resp = toObj(allStudentResp[ii]);
            if (!resp.qDone) {
                allStudentResp[ii] = { ...resp, qDone: 1 };
                //allStudentResp[ii].qDone = 1;
                const asr = deepCopy(allStudentResp);
                setStWork('resp', asr, 1, ii); //just pass a dummy setter() to avorid err; 
                setAllStudendResp(asr);
            };
        };
    };
    useEffect(() => {
        const idx = toInt(toStr(rdECtn).replace('exCtnContent', ''));
        if (idx > -1) _setRdECtn(idx);
    }, [rdECtn]);

    useEffect(() => {
        //console.log({showResult,asmAll,showCorr});
        if (startTime === 0) setStartTime(timeStampNowGMT());
        if (showResult && asmAll && !showCorr) {
            if (isThr || isStt) setAllQtnStatInfo();
        };
        if (showCorr) setAllQtnStatInfoReme();
        let observer = 0;
        if (needSetPlay && PVMode === 1) {
            const options = {
                root: document.getElementById(PVMode === 1 ? 'exListCont' : 'exSingleCont'),
                rootMargin: "0px",
                //threshold: 1,
                threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            };
            observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    //entry.target.style.backgroundColor = intersecting ? "yellow" : "orange";
                    //const txt = `${Math.round(entry.intersectionRatio * 100)}%`
                    if (entry.intersectionRatio > 0.05) { // 5%
                        setRdECtn(entry.target.id);
                        observer.unobserve(entry.target);
                    };
                })
            }, options);

            // some items still loading, delay 500ms to start observe
            setTimeout(() => {
                ECtns.forEach((ECtn, idx) => {
                    if ((!ECtn.QId) && ([_ExCtType_Img, _ExCtType_CK, _ExCtType_Txt].includes(ECtn.type))) {
                        observer.observe(document.getElementById('exCtnContent' + idx));
                    }
                });
            }, 500);
        };
        if (isThr && !preview) {
            const firstMQ = ECtns.findIndex(ectn => (ectn.type === _ExCtType_Qtn && !ectn.autoMark));
            const ii = (firstMQ < 0)? 0: firstMQ;
            if (PVMode === 0) { setAtIdx(ii); }
            if (PVMode === 1) { setTimeout(() => { slideToQ(null, ii); }, 500); }
        };
        return () => { if (observer) observer.disconnect(); };
    }, []);

    useEffect(() => {
        if (gotoAtx >= 0) {
            setAtIdx(gotoAtx);
            if (PVMode === 1) slideToQ(null, gotoAtx);
            setGotoAtx(-1);
        };
    }, [gotoAtx]);

    useEffect(() => { setStWork('endTime', endTime, 0); }, [endTime]);

    // teacher ref mark, student ref work
    const ddList = useMemo(() => {
        let QIndex = 0;
        return ECtns.map((ECtn, idx) => {
            const [correct, wrong, attempt, partly, grey, answered, dim]
                = ['#4EAE84', '#e94929', '#3a6bb9', '#F27F0C', '#B4B4B4', '#5D52CE', '#c1c0e1'];
            let color = grey;
            //mcq-dim mcq-active mcq-correct mcq-incorrect mcq-attempted
            let variant = 'mcq-dim';
            if (ECtn.QId) { // is question
                if (!preview && (isThr || (isStt && (showResult || showCorr)))) {
                    const fullQ = ECtns[idx];
                    const mark = allTeacherResp[idx];
                    if (mark) {
                        if ((mark.isOP && !qHasCorrect(fullQ)) || fullQ?.SQType === __SYSQSubType_POL) {
                            if (mark.allMarked && mark.hasAnswered) {
                                color = attempt; variant = 'mcq-attempted';
                            };
                        } else if (mark.allMarked) {
                            if (mark.isRight) {
                                color = correct; variant = 'mcq-correct';
                            } else {
                                color = wrong; variant = 'mcq-incorrect';
                            };
                        };
                    };
                } else {
                    //if (isStt) {
                    const qstatus = oneStAnsQtnStatus(ECtns[idx], allStudentResp[idx], showEn);
                    // { mAnsCnt, aAnsCnt, mQtnCount, aQtnCount, allAnswered, isOP, opAttempt, hasAnswered };
                    if (qstatus.allAnswered) { color = answered; variant = 'mcq-active'; }
                    else if (qstatus.hasAnswered) { color = partly; variant = 'mcq-incorrect'; };
                };
                return { caption: ('Q' + (++QIndex)), click: () => gotoQ(idx), type: ECtn.type, color, variant }
            } else {
                const cap = ECtn ? t(UIDisplayName[ECtn.type]) : 'No Data';
                const respI = allStudentResp[idx];
                if (respI) {
                    color = (respI.qDone) ? answered : grey;
                    variant = (respI.qDone) ? 'mcq-active' : 'mcq-dim';
                };
                return { caption: cap, click: () => gotoQ(idx), type: ECtn.type, color, variant }
            };
        });
    }, [allStudentResp, allTeacherResp]);

    const dumpSelecter = useMemo(() => {
        return <div className={"flexRowStart flex-wrap justify-content-center justify-content-sm-start gap-3 "}>
            {ddList.map((ele, ii) => { return <div key={ii} className={'ATQtnHeaderBtn f13 '} /> })}
        </div>
    }
        //return <div key={ii} className={'ATQtnHeaderBtn f13 '} />
    , [ddList]);

    const gotoQ = (e, ii) => {
        UI.stopEvent(e);
        const check = maxVal(0, minVal(ECtns.length - 1, ii))
        isStt && chgQSave && chgQSave();
        if (needSetPlay && [_ExCtType_Img, _ExCtType_CK, _ExCtType_Txt].includes(ECtns[check].type)) {
            setRdECtn(check);
        };
        setAtIdx(check);
    };
    const slideToQ = (e, ii) => {
        UI.stopEvent(e);
        const ele = document.getElementById('ele' + ii);
        if (ele) ele.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    };
    const prevQ = e => gotoQ(e, atIdx - 1);
    const nextQ = e => gotoQ(e, atIdx + 1);

    const submitCorr = () => {
        if (preview) {
            setEndTime(timeStampNowGMT());
            setShowCorr(1);
            setCanRender(false); // for scroll mode
            calAllScore();       
            setTimeout(() => { setCanRender(true); }, 10);
        };
    };

    const submitQ = () => {
        setEndTime(timeStampNowGMT());
        if (!asmAll) {
            setShowResult(1);
            setCanRender(false); // for scroll mode
            calAllScore();
        };
        //if ((isThr || isStt) && asmAll) {
        if (isThr && asmAll) {
            setAllQtnStatInfo();
        };
        if (asmAll) {
            if (submit) {
                setSaved(1);
                setModified && setModified(0);
                submit();
            } else {
                close();
            };
        };
        setTimeout(() => { setCanRender(true); }, 10);
    };

//useEffect(() => { console.debug('DoEx Eff() allStudentResp', debugTrimResp(allStudentResp)); }, [allStudentResp])
    const collectStudentResp = (idx, resp) => {
        allStudentResp[idx] = resp;
        const asr = deepCopy(allStudentResp);
        setStWork('resp', asr, 1, idx);
        setAllStudendResp(asr);
    };

    const collectTeacherResp = (idx, resp) => {
        if (!resp) return;
        allTeacherResp[idx] = resp;
        setOneQtnStatInfo(idx);
        const atr = deepCopy(allTeacherResp);
        setTeMark && setTeMark('resp', atr);

        setModified && setModified(1);
        setSaved(0);
    };
    const collectScore = (idx, score) => {
        setAllScore({ ...allScore, ...{ [idx]: score } });
    };

    // call by teacher role only
    const setOneQtnStatInfo = (idx) => {
        const ECtn = ECtns[idx];
        const Q = (ECtn && (ECtn.type === _ExCtType_Qtn)) ? (showEn ? ECtn.QEn : ECtn.QCt) : undefined;
        const fullQ = Q ? (ECtn ? ECtn : undefined) : undefined;
        const thResp = allTeacherResp[idx];

        if (fullQ) {
            stQtnAllInfo(idx, fullQ, allStudentResp[idx], thResp, showEn, preview);
        };
    };

    const _setTotalScoreStr = (totalScore, maxScore) => {    
        //console.log("_setTotalScoreStr", totalScore, maxScore);    
        setTotalScoreStr( maxScore ? (isReme?(toPercent1(totalScore,maxScore)+"%"):totalScore): '' );
        //setTotalScoreStr((showResult && maxScore > 0) ? (isReme?(toPercent1(totalScore/maxScore*100)+"%"):totalScore): '');
    };

    const setAllQtnStatInfoReme = () => {
        if (stMark?.MStat) {
            //console.log("stMark", stMark);
            const { aScore, mScore, aQtnScore, mQtnScore } = stMark.MStat;
            _setTotalScoreStr(toInt(aScore + mScore), toInt(aQtnScore + mQtnScore));
        };
    };

    // must call on page init or during submit
    const setAllQtnStatInfo = () => {
        //console.log("setAllQtnStatInfo");
        let totalScore = 0;
        let maxScore = 0;
        let totalQtn = 0;
        let [tstScore, tmQtnScore, taQtnScore, tmAnsCnt, taAnsCnt,
            tmQtnCount, taQtnCount, tmCount, taCount,
            tmMarked, taMarked, tmScore, taScore, tallAnswered, tallMarked,
            tautoMark, thasAnswered]
            = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (let idx = 0; idx < ECtns.length; idx++) {
            const ECtn = ECtns[idx];
            const Q = (ECtn && (ECtn.type === _ExCtType_Qtn)) ? (showEn ? ECtn.QEn : ECtn.QCt) : undefined;
            const fullQ = Q ? (ECtn ? ECtn : undefined) : undefined;
            const thResp = allTeacherResp[idx];

            if (fullQ) {
                const { stScore, mQtnScore, aQtnScore, mAnsCnt, aAnsCnt,
                    mQtnCount, aQtnCount, mCount, aCount,
                    mMarked, aMarked, mScore, aScore, allAnswered, allMarked,
                    isOP, opAttempt, isAutoMark, hasAnswered }
                    = stQtnAllInfo(idx, fullQ, allStudentResp[idx], thResp, showEn, preview);
                tstScore += toInt(stScore);
                tmCount += toInt(mCount);
                taCount += toInt(aCount);
                tmScore += toInt(mScore);
                taScore += toInt(aScore);
                tmQtnScore += toInt(mQtnScore);
                taQtnScore += toInt(aQtnScore);
                tmAnsCnt += toInt(mAnsCnt);
                taAnsCnt += toInt(aAnsCnt);
                tmQtnCount += toInt(mQtnCount);
                taQtnCount += toInt(aQtnCount);
                tmMarked += toInt(mMarked);
                taMarked += toInt(aMarked);
                tallAnswered += toInt(allAnswered);
                thasAnswered += toInt(hasAnswered);
                tallMarked += toInt(allMarked);
                totalScore += toInt(aScore) + toInt(mScore);
                // polling 0 mark
                maxScore = maxScore + ((toInt(mQtnScore) + toInt(aQtnScore)) || 0);
                if (isAutoMark) tautoMark++;
                totalQtn++;
            };
        };


        //setTotalScore(totalScore);
        //setMaxScore(maxScore);
        _setTotalScoreStr(totalScore, maxScore);
        // sum of answer info within this question
        const MStat = {
            stScore: tstScore, mQtnScore: tmQtnScore, aQtnScore: taQtnScore, mAnsCnt: tmAnsCnt, aAnsCnt: taAnsCnt,
            mQtnCount: tmQtnCount, aQtnCount: taQtnCount, mCount: tmCount, aCount: taCount,
            mMarked: tmMarked, aMarked: taMarked, mScore: tmScore, aScore: taScore,
            allAnswered: tallAnswered, allMarked: tallMarked, totalQtn: totalQtn,
            totalAutoMQ: tautoMark, hasAnswered: thasAnswered
        };

        const ms = markState(MStat);

        const _mark = isThr ? (SWMs[swmIdx]?.mark) : stMark; // swmIdx - teacher only
        if (_mark) {
            _mark.MStat = MStat;
            _mark.MState = ms;
        };

        if (isThr && setTeMark) {
            setTeMark('MStat', MStat);
            setTeMark('resp', allTeacherResp);
            setTeMark('MState', ms);
        };
    };

    // preview use
    const calAllScore = () => {
        let total = 0;
        let max = 0;
        for (let idx = 0; idx < ECtns.length; idx++) {
            const ECtn = ECtns[idx];
            const Q = (ECtn && (ECtn.type === _ExCtType_Qtn)) ? (showEn ? ECtn.QEn : ECtn.QCt) : undefined;
            const fullQ = Q ? ECtn : undefined;
            const _max = (fullQ?.SQType === __SYSQSubType_POL ? 0 : (fullQ?.score || 0));

            const tmp = calculateScore(Q, fullQ, (asmAll || preview), allStudentResp[idx], allTeacherResp[idx]);
            total = total + (tmp || 0);
            max = max + _max;

            if (debugMode()) {
                remeAddWorkAnsCorr(fullQ, allStudentResp[idx], allTeacherResp[idx], showEn);
                console.log("allStudentResp "+idx,allStudentResp[idx]);
            };
        };
        //setTotalScore(total);
        //setMaxScore(max);
        _setTotalScoreStr(total, max);
    };

    const onListScroll = () => {
        //console.log('0',hasOverlapDraw,overlayDraw,PVMode);
        //console.log('onListScroll here !!! 1');
        if (!hasOverlapDraw || openState.overlayDraw < 0 || PVMode !== 1) return;
        //console.log('1');
        const xxx = listScrollRef.current;
        const yyy = eleRef.current[openState.overlayDraw];
        const tb = doExOverlayBar.current;
        
        if (yyy && xxx && tb) {
            //console.log('2');
            const topExtra = 76;
            const cc = xxx.getBoundingClientRect();
            const bb = yyy.getBoundingClientRect();
            const tt = tb.getBoundingClientRect();
            const deltaTop = bb.y - cc.y + topExtra;
            const deltaBottom = cc.bottom - (bb.y + tt.height) - topExtra;
            tb.style.left = (bb.x + (bb.right - bb.x - tb.width)) + 'px';
            if (deltaTop < 0) {
                tb.style.top = cc.y + 8 + 'px';
            } else if (deltaBottom < 0) {
                tb.style.top = (cc.bottom - tt.height) + 'px';
            } else {
                tb.style.top = bb.y + topExtra + 'px';
            };
        };
        //console.log('onListScroll here !!! 2');
    };    

    const mdProps = { dispatch, setOnAddMedia, mediaDLs, getMediaDLs, addLocalMedias };
    const jsxProps = {
        ...mdProps, close, setPlayed, idx: atIdx, PVMode, showEn, lock: 1, preview: 1,
        doWhat, ECtn: ECtns[atIdx], replaceECtn
    };
    //const totalScoreStrTxt = (showResult && maxScore>0)?(t('assignment-status-total-score')+': '+ totalScore + '/' + maxScore):'';
    //const totalScoreStr = (showResult && maxScore > 0) ? totalScore : '';
    //const totalScoreStr = (showResult && maxScore > 0) ? (isReme?(toPercent1(totalScore/maxScore*100)+"%"):totalScore): '';


    const keyIdx = '' + atIdx + stdIdx;
    const markModified = (modified && isThr) ? 1 : 0;
    const markSaved = (saved && isThr) ? 1 : 0;
    const QOneQProps = {
        ...mdProps, close, back: close, gotoQ, nextQ, prevQ, submitQ, submitCorr,
        ddList, parentTime: startTime, showResult, showCorr, vis, setVis, dummyHeight,
        collectStudentResp, collectTeacherResp, collectScore,
        uRole, isThr, isStt, noSave, doWhat, close, showEn, PVMode, debugInfo,
        shiftMark, SWMs, swmIdx, aPub, marked, timeUsed, remainTime, setOverBarReady,
        setOpenState, openState, onListScroll, hasTimeLimit, aHints,
    };

    const noDrawQtn = (tt) => {
        if (tt) {
            return (tt === __SYSQSubType_OED && !showFrontQtnDrawEx);
            //return (tt === __SYSQSubType_OED && !showFrontQtnDrawEx && isThr);
            //return (tt === __SYSQSubType_OED && ((!showQtnDrawEx) || (!showFrontQtnDrawEx && isStt)));
        } else {
            return false;
        };
    };

    //console.log("doEx answerVis", answerVis);
    if (PVMode === 0) { // slide
        const Q = (ECtns[atIdx] && (ECtns[atIdx].type === _ExCtType_Qtn)) ? (showEn ? ECtns[atIdx].QEn : ECtns[atIdx].QCt) : undefined;
        const fullQ = Q ? (ECtns[atIdx] ? ECtns[atIdx] : undefined) : undefined;
        const jsx = () => {
            if ((ECtns[atIdx] && (ECtns[atIdx].type === _ExCtType_Qtn)) && Q === undefined) {
                return cpNoQtn(atIdx, ECtns[atIdx].QId, loading);
            } else {
                const View = CpECtnViews[ECtns[atIdx]?.type];
                return View && <View key={atIdx} {...jsxProps} />
            }
        };
        //console.log('allStudentResp[atIdx]', atIdx, allStudentResp[atIdx]);
        return <><CpATQtnSingle key={keyIdx} noContent={noDrawQtn(fullQ?.SQType)} {...{
            ...QOneQProps, Q, fullQ, jsx: jsx(), idx: atIdx, hideHeader, isReme, needDrawDeb:_needDrawDeb, answerVis,
            endStr: (showResult || showCorr || answerVis) ? totalScoreStr : '', modified: markModified, saved: markSaved, bottomMargin, setBottomMargin,
            CstudentResp: allStudentResp[atIdx], CteacherResp: allTeacherResp[atIdx], displayIdx: ECtns[atIdx]?.displayIdx, totalQtn: ECtns.length,
            dummyHeader: <div style={{ height: 0, width: '100%', overflow: 'hidden' }}>
                <div ref={dummyRef} className='ATQtnDummyHeaderSlide'>{dumpSelecter}</div>
            </div>,
        }} />       
        </>;
    };

    const withFilterBar = (isThr || aPub);
    //console.log('ECtns', ECtns);
    //console.log({swmIdx, allStudentResp});
    if (PVMode === 1) { // scroll
        const _bMargin = (openState.ansKeys < 0 && openState.hints < 0 && openState.comment < 0) ? 0: bottomMargin;
        //console.log("_bMargin", _bMargin);
        //paddingBottom: (_bMargin - (withFilterBar ? 60 : 0)) + 'px'
        return <div id='exListCont' className='DoEx ATExerPreVList' style={{
            paddingTop: (vis ? (dummyHeight + 60) : 60) + 'px',
            paddingBottom: (_bMargin) + 'px'
        }}>
            <CpATQtnHeader PVMode={PVMode} caption={t('question-selector')} idx={atIdx} ddList={ddList} goBack={close} showResult={showResult} showCorr={showCorr} hideHeader={hideHeader} answerVis={answerVis}
                gotoQ={slideToQ} doSubmit={submitQ} doCorr={submitCorr} close={close} endStr={(showResult || showCorr || answerVis) ? totalScoreStr : ''} showScore={0} saved={markSaved} vis={vis} setVis={setVis} hasTimeLimit={hasTimeLimit}
                preview={preview} parentTime={startTime} timeUsed={timeUsed} remainTime={remainTime} isThr={isThr} noSave={noSave} debugInfo={debugInfo} modified={markModified} />
            {false && Button0('fabric canvas', e => setCanVis(canVis ? 0 : 1), 'btnCanvas', '', {})}
            {canRender ? <div ref={listScrollRef} className="flexColStart" onScroll={onListScroll}
                style={{ position: 'relative', height: '100%', overflow: 'auto', paddingBottom:'50px' }}>
                {ECtns.map((ECtn, idx) => {
                    const id = 'ele' + idx;
                    if (ECtn.type === _ExCtType_Qtn) { // question
                        const Q = (ECtn && (ECtn.type === _ExCtType_Qtn)) ? (showEn ? ECtn.QEn : ECtn.QCt) : undefined;
                        const fullQ = Q ? (ECtn ? ECtn : undefined) : undefined;
                        //console.log('allStudentResp[idx]', idx, allStudentResp[idx]);
                        return Q ? <div id={id} key={keyIdx + idx} ref={(el) => eleRef.current[idx] = el} className='flexRowCenter rlpEx'>
                            <CpATQtnSingle key={keyIdx + idx} noContent={noDrawQtn(fullQ?.SQType)} {...{
                                ...QOneQProps, idx, Q, fullQ, scrollRef: listScrollRef, gotoQ: slideToQ,needDrawDeb:_needDrawDeb,
                                CstudentResp: allStudentResp[idx], CteacherResp: allTeacherResp[idx], displayIdx: ECtn.displayIdx,
                                canVis, bottomMargin, setBottomMargin, isReme
                            }} /></div> : cpNoQtn(keyIdx, ECtn.QId, loading);
                    } else { // execrise view
                        const View = CpECtnViews[ECtn.type];
                        return <div id={id} key={keyIdx + idx} className="flexRowCenter rlp10"><View key={keyIdx} {...{ ...jsxProps, idx, ECtn }} /></div>
                    }
                })}
                {(hasOverlapDraw && PVMode === 1) ? <div id='doExOverlayBar' ref={doExOverlayBar} className="drawOverlayBarEx"></div> : ''}                
            </div> : ''}
            {withFilterBar ? <div className='ATQtnSingleBottomRow'>
                <div className='flexRowCenter'><CpAssignStdFilter {...{ shiftMark, SWMs, swmIdx, isReme, marked}} /></div>
            </div> : ''}
            <div style={{ height: 0, width: '100%', overflow: 'hidden' }}>
                <div ref={dummyRef} className='ATQtnDummyHeaderSlide'>{dumpSelecter}</div>
            </div>        
        </div>
    };
    if (PVMode === 2) { //frontend show exercise list
        let displayIdx = 0;
        return <div key={'list2' + (showEn ? 'En' : 'Ct')} className="DoEx flexColStart unclickable">
            {ECtns.map((ECtn, idx) => {
                const id = 'ele' + idx;
                if (ECtn.type === _ExCtType_Qtn) { // question
                    const Q = (ECtn && (ECtn.type === _ExCtType_Qtn)) ? (showEn ? ECtn.QEn : ECtn.QCt) : undefined;
                    const fullQ = Q ? (ECtn ? ECtn : undefined) : undefined;
                    displayIdx++;
                    return Q ? <div id={id} key={idx} className='flexRowCenter rlp10'>
                        <CpATQtnSingle noContent={noDrawQtn(fullQ.SQType)} {...mdProps} {...{
                            Q, fullQ, doWhat: 'listView', idx, showEn, needDrawDeb:_needDrawDeb,
                            close, back: close, PVMode, showResult: false, mini: 1, displayIdx, showQNo: 1
                        }} />
                    </div>
                        : cpNoQtn(idx, ECtn.QId, loading);
                } else { // execrise view
                    const View = CpECtnViews[ECtn.type];
                    return <div id={id} key={idx} className="flexRowCenter rlp10"><View key={idx} {...{ ...jsxProps, idx, ECtn, mini: 1 }} /></div>
                }
            })}</div>
    };

    return <div>unknown PVMode {PVMode}</div>
});
export default DoEx;

const cpNoQtn = (key, QId, loading) =>
    <div key={key} className='flexRowCenter'>
        <div className='ATExEditQContainer rlp10' style={{ maxWidth: '1024px' }}>
            {loading ? '' : 'No ' + toStr(QId) + ' Question Data'}
        </div>
    </div>;