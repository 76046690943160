import React, {useEffect, useMemo } from "react";
import { aTErrDiv1, preJS } from "../../AppExPf/AppUtil";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { maxVal, minVal, objKeys, toInt, toObj, toStr } from "../../libs/libType";

import { CpSettingModalHeader, CpSettingModalSection, CpSettingModalTitle
    ,CpSettingModalTabContainer, CpSettingModalText } from "../_components/CpSettingModalTabCps";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { SIZE, CHECKBOX_VARIANT, PresentationMode } from "../_components/CpExPFConst";
import CpDropdown, { ary2DropdownItem, obj2DropdownItem } from "../_components/CpDropdown";
import CpIco from "../_components/CpIco";
import { exerHasMark } from "./Tags/TagMarkingTag";
import { _ARS_CLOSE_RESET, _ARS_CLOSE_SUBMIT, _ARS_CLOSE_RESUME } from "../../consts/ATConstsAssignment";
import CpCheckboxComponent from "../_components/CpCheckboxComponent";
import CpSwitchComponent from "../_components/CpSwitchComponent";

const TabAssignmentSettingBehaviour = (props => { // base on poc AssignmentSettingBehaviour 
    const {vis, fields, setField, fieldErrs, showEn} = props;
    
    const porpE = props.Exer;
    const Exer = porpE;  //tobon 4, please please please clear tobon that is over
    const {ELangEn, ELangCt, EModeScroll, EModeSlide, } = Exer; //tobon 4 , jira 9361 919
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);

    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();
    
    const fs = toObj(fields);
    const [AMode, setAMode] = [fs.AMode, setField('AMode')]; //useState(PresentationMode.SCROLL_MODE)
    const [ALang, setALang] = [fs.ALang, setField('ALang')];
    
    const [hints, setHints] = [fs.AHints, setField('AHints')];
    const [ARand, setARand] = [fs.ARand, setField('ARand')];
    const [AResume, setAResume] = [fs.AResume, setField('AResume')];
    
    const [passingGrade, setPassingGrade] = [fs.APass, setField('APass')];
    const handlePassingGrade = () => { setPassingGrade(prev => !prev) }

    const options = useMemo(() => obj2DropdownItem( {p:t("assignment-settings-options-percentage"), m: t("assignment-settings-options-marks"), }), [isUIEn]);
    const [passUnit, setPassUnit] = [fs.passUnit, setField('passUnit')];
    const pu = options[passUnit]? passUnit: objKeys(options)[0];
    useEffect(()=>{ if(pu !== passUnit) setPassUnit(pu); }, [pu]); // toBon 4  When any insdie [] change

    const [passValue, setPassValue] = [fs.passValue, setField('passValue')];
    const {exitEdPassVal, changePassVal, maxPassVal} = useEditPassValue(pu, aScore+mScore, passValue, setPassValue);

    useEffect(()=>{ if(!ALang) setALang(ELangEn? 'en': 'ct'); }, [ELangEn, ELangCt]);
    useEffect(() => { if(!AMode) setAMode(EModeScroll? PresentationMode.SCROLL_MODE: PresentationMode.SLIDE_MODE); }, [EModeScroll, EModeSlide]);

    const AEn = ALang === 'en';
    const allLang = ELangEn && ELangCt;
    if (!vis)  return <></>;
    return <CpSettingModalTabContainer {...{className: 'TabAssignmentSettingBehaviour', submitting: 0}} >
        <CpSettingModalHeader>{t("assignment-settings-exercise-behaviour")}:</CpSettingModalHeader>
        <CpSettingModalSection>
{preJS({ELangEn, ELangCt, ALang, AEn, showEn,})}
        <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-exercise-language")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column flex-md-row gap-3 gap-md-5 align-items-start align-items-md-center flex-wrap ms-3 ms-lg-0"}>
                {(ELangEn)?
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={() => setALang('en')}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={AEn} checkIcon={false} 
                        className={"exercise-radiobox" + (!allLang?" disable-option ":"")} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-english")}</CpSettingModalText>
                </span>:''}
                {(ELangCt)?
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={() => setALang('ct')}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={!AEn} checkIcon={false} 
                        className={"exercise-radiobox" + (!allLang?" disable-option ":"")} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-chinese")}</CpSettingModalText>
                </span>:''}
            </div>
        </CpSettingModalSection>
        <CpSettingModalSection>
{preJS({EModeScroll, EModeSlide, hasA, hasM})}
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-exercise-mode")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column flex-md-row gap-3 gap-md-4 align-items-start align-items-md-center flex-wrap ms-3 ms-lg-0"}>
                {(EModeScroll)?
                <span role={"button"}
                      className={`d-flex flex-column gap-2 justify-content-center align-items-center p-3 uiAnimation border rounded-light  ${AMode === PresentationMode.SCROLL_MODE? "text-exercise-third-btn border-exercise-third-btn" : "text-dim-350"}`}
                      onClick={() => setAMode(prev => PresentationMode.SCROLL_MODE)} style={{width: "130px"}}>
                    <span className={"fs-3"}><CpIco src={IconList.presentation.assessmentMode}/></span>
                    <span className={"fs-8 semi-bold"}><CpSettingModalText>{t("assignment-settings-scroll-mode")}</CpSettingModalText></span>
                </span>:''}
                {(EModeSlide)?
                <span role={"button"}
                      className={`d-flex flex-column gap-2 justify-content-center align-items-center p-3 uiAnimation border rounded-light  ${AMode === PresentationMode.SLIDE_MODE? "text-exercise-third-btn border-exercise-third-btn" : "text-dim-350"}`}
                      onClick={() => setAMode(prev => PresentationMode.SLIDE_MODE)} style={{width: "130px"}}>
                        <span className={"fs-3"}><CpIco src={IconList.presentation.practiceMode}/></span>
                        <span className={"fs-8 semi-bold"}><CpSettingModalText>{t("assignment-settings-slide-mode")}</CpSettingModalText></span>
                </span>:''}
            </div>
            {aTErrDiv1(fieldErrs.exMode) /* tobon 4 display Error next to the xeact input field */}
        </CpSettingModalSection>
        <CpSettingModalSection hideBorder={true}>
{preJS({AHints:hints})}
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-hints")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column flex-md-row gap-3 gap-md-5 align-items-start align-items-md-center flex-wrap ms-3 ms-lg-0"}>
                {/*
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"}
                      onClick={() => setHints(prev => true)}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={hints === true}
                                        checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-show")}</CpSettingModalText>
                </span>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"}
                      onClick={() => setHints(prev => false)}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={hints === false}
                                        checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-hidden")}</CpSettingModalText>
                </span>
                */}
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"}
                      onClick={() => setHints(1)}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={hints}
                                        checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-show")}</CpSettingModalText>
                </span>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"}
                      onClick={() => setHints(0)}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={!hints}
                                        checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-hidden")}</CpSettingModalText>
                </span>
            </div>
        </CpSettingModalSection>
        <CpSettingModalHeader>{t("assignment-settings-anti-cheating")}: </CpSettingModalHeader>
        <CpSettingModalSection>
{preJS({ARand})}
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-shuffle-choices")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column flex-md-row gap-3 gap-md-5 align-items-start align-items-md-center flex-wrap ms-3 ms-lg-0"}>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={() => setARand(0)}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={!ARand} checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-not-random")}</CpSettingModalText>
                </span>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"} onClick={() => setARand(1)}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={ARand} checkIcon={false} className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-random")}</CpSettingModalText>
                </span>
            </div>
        </CpSettingModalSection>
        <CpSettingModalSection hideBorder={true}>
{preJS({AResume})}
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-student-close-the-app-without-submission")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column flex-md-row gap-3 gap-md-5 align-items-start align-items-md-center flex-wrap ms-3 ms-lg-0"}>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"}
                      onClick={() => setAResume(_ARS_CLOSE_RESUME)}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={AResume===_ARS_CLOSE_RESUME} checkIcon={false}
                                        className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-allow-resume")}</CpSettingModalText>
                </span>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"}
                      onClick={() => setAResume(_ARS_CLOSE_SUBMIT)}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={AResume===_ARS_CLOSE_SUBMIT} checkIcon={false}
                                        className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-count-as-submitted")}</CpSettingModalText>
                </span>
                <span role={"button"} className={"d-flex justify-content-center align-items-center gap-3"}
                      onClick={() => setAResume(_ARS_CLOSE_RESET)}>
                     <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checked={AResume===_ARS_CLOSE_RESET} checkIcon={false}
                                        className={"exercise-radiobox"} size={SIZE.XXXS} />
                    <CpSettingModalText>{t("assignment-settings-reset")}</CpSettingModalText>
                </span>
            </div>
        </CpSettingModalSection>
        <CpSettingModalHeader>{t("assignment-settings-grading")}:</CpSettingModalHeader>
        <CpSettingModalSection hideBorder={true}>
{preJS({APass: passingGrade, passValue, passUnit, maxPassVal})}
            <CpSettingModalTitle style={{width: "300px"}}>{t("assignment-settings-passing-grade")}:</CpSettingModalTitle>
            <div className={"d-flex flex-column gap-2 flex-wrap ms-3 ms-lg-0"}>
                <span className={"d-flex gap-2 semi-bold user-select-none"} role={"button"} onClick={handlePassingGrade}>
                    <CpSwitchComponent variant={"exercise-third-btn pe-none"} size={SIZE.XS} readOnly={true} checked={passingGrade} 
                    label={t("assignment-settings-enable")}/>
                </span>
                {aTErrDiv1(fieldErrs.passGrade)}{aTErrDiv1(fieldErrs.value2Pass)}{aTErrDiv1(fieldErrs.range2Pass)}
                {passingGrade? 
                <span className={"d-flex flex-wrap align-items-center gap-2"}>
                    <input type={"number"} className={`border  ${passingGrade ? "bg-white" : "bg-dim-100"}`} style={{width: "4rem", height: "34px"}} 
                        onBlur={exitEdPassVal} onChange={changePassVal} 
                        value={toStr(passValue)} min={0} max={maxPassVal} disabled={!passingGrade}/>
                    <span className={"d-flex align-items-center"} style={{width: "150px"}}>
                        <CpDropdown className="w100" {...{className:`${passingGrade? "": "bg-dim-100 pe-none"}`, items:options, idx:passUnit, cb:setPassUnit, minItem:1}}/>
                    </span>
                </span>: ''}
            </div>
            {preJS({maxPassVal},3)}
        </CpSettingModalSection>
    </CpSettingModalTabContainer>
});
export default TabAssignmentSettingBehaviour;

export const useEditPassValue = (passUnit, EScore, passValue, setPassValue) => {
    const maxPassVal = useMemo( () => ((passUnit === 'p')? 100: (EScore)), [passUnit, EScore]); 

    const _setPassVal = v => setPassValue(toInt(maxVal(0, minVal(toInt(v), maxPassVal))));

    const changePassVal = e => _setPassVal(e?.currentTarget?.value); 
    const exitEdPassVal = () => _setPassVal(passValue);

    useEffect(() => { exitEdPassVal(); }, [maxPassVal]);
    return {exitEdPassVal, changePassVal, maxPassVal};
};