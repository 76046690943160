import React, {useState, useEffect, useMemo} from "react";
import {Container} from "react-bootstrap";
import { useUILang } from "../../AppExPf/utils/useUILang";

import CpDropdown, { obj2DropdownItem } from "../_components/CpDropdown";

import { debugMode } from "../../saga/ReduxState";
import { BtnDev, BtnPopDev, preJS, preJSSort, autoId } from "../../AppExPf/AppUtil";
import { ReduxBind } from "../../saga/ReduxState";
import { isAry, objEntries, objKeys, objVals, strCmp, toAry, toInt, toNum, toObj, toStr } from "../../libs/libType";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { useSortList } from "../EPAssign/TabTeacherAssignmentStatus";
import { useCaches } from "../../AppExPf/utils/useCaches";

import ChartMetadataRadialGroup from "./_component/ChartMetadataRadialGroup";
import ChartProgressByMonth from "./_component/ChartProgressByMonth";
import ChartSubjectOverview from "../EPDashboard/ChartSubjectOverview";

import ChartAssignmentProgress from "./_component/ChartAssignmentProgress";
import { useProgressMonth } from "./CpStudentReport";

import CpChartIndicatorGuideline from "./_component/CpChartIndicatorGuideline";

import ChartClassPerformanceDetails from "./_component/ChartClassPerformanceDetails";
import { toClsDetailData1v2 } from "./data/clsDetailData1";
import { toOverData1a, toOverData1c, } from "./data/overData1";
import { toOverData2a,  } from "./data/overData2";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";
import { toClsMetaData1,  } from "./data/clsMetaData";
import { toGradeMetaData1,  } from "./data/gradeMetaData";
import { toStuAsmData,  } from "./data/stuAsmData";
import { toAsmData,  } from "./data/overData3";
import { ChartMetadataProgressMath } from "./_component/ChartMetadataProgress";
import { stopEvent } from "../../libs/libUI";
import { toStuMetaData2, toStuMetaDataL2, } from "./data/stuMetaData";
import { xapiPost } from "../EPLibrary/PageLibrary";
import CpMJX from "../_components/CpMJX";
import { getTeacherName } from "../../AppExPf/ATExerEdit/TabExerContent";
import { toUniIdAry } from "../../consts/ATValidate";
import { rpTime_keyStg } from "./PageReportTeacher";
import { ChartMetadataKeyStage } from "./_component/ChartMetadataKeyStage";
import { RT_detail, RT_meta } from "./PageReports";
//const ClassPerformanceDetailsChart = () => {};
//import { expfRoles } from "../../saga/auth.saga";
//import { getTransMap } from "../../libs/libTransMap";

export const toGradeClassNameEnts = (yc2d, y, g, ut) => {
    const cEs = objEntries(yc2d?.[y]).map(([cid, c]) => (c.grade === g) && [cid, ut(c.nameEng, c.nameChi)] ).filter(c => c);
    cEs.sort((a, b) => strCmp(a[1], b[1]));
    return cEs;
};
export const toClassNames = (yc2d, ut) => {
    const cEnt2d = objVals(yc2d).map(classs => objEntries(classs).map(([cid, c]) => [cid, ut(c.nameEng, c.nameChi)] ));
    return Object.fromEntries( [].concat(...cEnt2d));
};
export const usePreLoadRptMsets = (opts, useTagMSets) => {
    const {rMSIds, rJOpts, rMSets} = toObj(opts);
    const MSetIds = useMemo(() => toUniIdAry(toAry(rMSets).map(s => s.metaSetId)), [rMSets]); 
//console.log('usePreLoadRptMsets()', {MSetIds, rMSets} );
    useTagMSets(MSetIds);
};

const CpTeacherReport = ReduxBind(props => { //base on poc TeacherReport
    const { dispatch } = props;
    const [t, uiEn, UILang, setUILang, ut, t2, t3] = useUILang();

    const [ cache2d, useGetCache ] = useCaches(props);
    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 
    usePreLoadRptMsets(props.opts, useTagMSets);

    const [rptKeyStage, setRptKeyStage] = useState();
    const [rptDatas, setRptDatas] = useState();

    const useSL = useSortList({EName:'asc'});

    const {fields, setFields, setField, opts, setOpts} = props.rptOpts;
    const {year, grade, gradeClsIds, cls, stu, } = toObj(fields);

    const {yearItems, y, gradeItems, g, classItems, c, oneCls, studentItems, st} = props.rptClsOpts;

    const yc2d = useGetCache('yearClass2d', '*');
    const clsNames =  useMemo(() => toClassNames(yc2d, ut), [yc2d, uiEn]);
    const gdClsEnts =  useMemo(() => toGradeClassNameEnts(yc2d, y, g, ut), [yc2d, y, g, uiEn]);

    const {
        subjDDI, MTypeDDI, MSetDDI, ML1DDI, rptDDI, rpTimeDDI,
        r, j, mt, ms, l1, rti, canKS,
    } = props.rptCtnOpts;
    const isKeyStage = (rti === rpTime_keyStg);

    const [ m, setRptM ] = useState();
    const rptMDDI = useMemo(() => monthDDI(t), [uiEn]);
    const rptM = rptMDDI[m]? m: objKeys(rptMDDI)[0];

    const [progDDI, prog, setProg, lineBarType] = useProgressMonth(uiEn, t);

    //const [rptOvers, setRptOvers] = useState();
    //const [rptOverStus, setRptOverStus] = useState();
    //const [rptOverQtns, setRptOvetQtns] = useState();
    //const [rptStuQtns, setRptStuQtns] = useState();
    //const [rptStuMetas, setRptStuMetas] = useState();
    
	const thrNames = useGetCache('teachers', '*'); 
    const _getTeacherName = id => getTeacherName(id, thrNames, ut, t);


    const reload = async (caller) => {
        const args = { rpt:r, year:y, gradeClsIds, g, cls:c, stu:st, MSubj:j, MType:mt, MSet:ms, rptTime:rti, caller };
        const [res, err]  = await asyncApiCallLoad_(dispatch, '/getReports', args);
        //if(isAry(res?.rptOvers)) setRptOvers(toAry(j && res.rptOvers));
        //if(isAry(res?.rptOverStus)) setRptOverStus(toAry(j && res.rptOverStus));
        //if(isAry(res?.rptOverQtns)) setRptOvetQtns(toAry(j && res.rptOverQtns));
        //if(isAry(res?.rptStuQtns)) setRptStuQtns(toAry(j && res.rptStuQtns));
        //if(isAry(res?.rptStuMetas)) setRptStuMetas(toAry(j && res.rptStuMetas));
        setRptDatas({...rptDatas, ...res?.datas});
        setRptKeyStage({...toObj(rptKeyStage), ...toObj(res?.keyStage)});
        setOpts( o => ({...toObj(o), ...toObj(res?.rptOpts)}) );
    };
    useEffect(() => { reload('ThrRpt'); }, [y, gradeClsIds, c, st, r, j, mt, ms, rti]);
    useEffect(() => { 
        if(!(y && g && gradeClsIds?.length)) return; // loading params not real rpt
        xapiPost(dispatch, 'xRpt', { y, gradeClsIds, g, c, st, r, j, mt, ms, rti, prog});        
    }, [y, gradeClsIds, c, st, r, j, mt, ms, rti, prog]);
    
    const [pickCls, pickStu] = [c && (c !== '*'), st && (st !== '*')]; 
    const allClassesCase = !(pickCls);
    const selectedClassCase = (pickCls && (!pickStu));
    const selectedStudentCase = (pickCls && pickStu);

    const [rviewDetail, rviewMeta] = [r === RT_detail, r === RT_meta];//report.type.value === ReportType.CLASS_DETAIL;
    const rviewOver = !(rviewMeta || rviewDetail);

	const _clsStudents = useGetCache('classStudents', pickCls? c: ''); 
    const clsStuEnts = useMemo(() => sortClsStuEnts(_clsStudents), [_clsStudents]);
    const clsStudents = toObj(_clsStudents);
    const stuInf = _clsStudents?.[st];
    const stuName = ut(stuInf?.nameEng, stuInf?.nameChi);

    const {overData1a_, stuOverDataA_, overData2a_, stuOverDataB, overData3_, stuAsmData_, 
        /*clsDetailData_,*/  clsDetailData_v2,  clsMetaData_, gradeMetaData_, stuMetaData_,
        clsMetaDataKS_, stuMetaDataKS_,
    } = useMemo(() => toObj(rptDatas), [rptDatas]);

    const overData1a = useMemo(() => toOverData1a(overData1a_, clsNames), [overData1a_, clsNames]);
    const stuOverDataA = useMemo(() => toOverData1c(stuOverDataA_, st, stuName), [stuOverDataA_, stuName, st, uiEn]);

    const overData2a = useMemo(() => toOverData2a(overData2a_, clsNames), [overData2a_, clsNames]);

    const gradeAsmData = useMemo(() => toAsmData(overData3_, clsNames, 'a', _getTeacherName), [overData3_, clsNames, thrNames]);
    const clsAsmData = useMemo(() => toAsmData(overData3_, clsNames, 'sc', _getTeacherName), [overData3_, clsNames, thrNames]);
    const stuAsmData = useMemo(() => toStuAsmData(stuAsmData_, st, _getTeacherName), [stuAsmData_, thrNames]);

    const d1Msets = useTagMSets(objKeys(gradeMetaData_?.gradeMsets));
    const gradeMetaData1 = useMemo(() => toGradeMetaData1(gradeMetaData_, gdClsEnts, j, mt, ms, d1Msets, ut), 
        [gradeMetaData_, gdClsEnts, j, mt, ms, tagMSets, uiEn]);

    const cd1Msets = useTagMSets(objKeys(clsMetaData_?.clsMsets));
    const clsMetaData1 = useMemo(() => toClsMetaData1(clsMetaData_, clsStudents, j, mt, ms, cd1Msets, ut), [clsMetaData_, _clsStudents, j, mt, ms, tagMSets, uiEn]);

    const stMSets = useTagMSets([ms]);
    //const stuMetaDataL1 = useMemo(() => toStuMetaDataL1(stuMetaData_, ms, tagMSets, ut), [stuMetaData_, ms, tagMSets, uiEn ]);
    const stuMetaDataL2 = useMemo(() => toStuMetaDataL2(stuMetaData_, ms, stMSets, ut), [stuMetaData_, ms, tagMSets, uiEn ]);
    const stuMetaData2 = useMemo(() => toStuMetaData2(clsMetaData_, st, j, mt, ms, stMSets, ut), [clsMetaData_, j, mt, ms, tagMSets, uiEn ]);

    //const clsDetailData1v1 = useMemo(() => pickCls && toClsDetailData1(clsDetailData_, clsStudents, ut), [pickCls, clsDetailData_, _clsStudents, uiEn]);
    const clsDetailData1 = useMemo(() => pickCls && toClsDetailData1v2(clsDetailData_v2, rptM, clsStudents, ut), [pickCls, clsDetailData_v2, rptM, clsStudents, uiEn]);

    const clickM = e => { stopEvent(e); setField('rpt')(RT_meta); };    

    return <>
    {debugMode()?
    <div className='flexRowStart' style={{flexWrap:"wrap"}}>
    <BtnDev txt='Data Thr Rpt'>
        <BtnPopDev txt="rptKeyStage">{preJSSort({rptKeyStage}, 3)}</BtnPopDev>

        <BtnPopDev txt='overData1a'>{preJSSort(overData1a,3)}</BtnPopDev> 
        <BtnPopDev txt='overData2a_'>{preJSSort(overData2a_,3)}</BtnPopDev>
        <BtnPopDev txt='overData2a'>{preJSSort(overData2a,3)}</BtnPopDev>
        <BtnPopDev txt='gradeAsmData'>{preJSSort(gradeAsmData,3)}</BtnPopDev>
        <BtnPopDev txt='clsAsmData'>{preJSSort(clsAsmData,3)}</BtnPopDev>

        <BtnPopDev txt='stuOverDataA_'>{preJSSort(stuOverDataA_,3)}</BtnPopDev>
        <BtnPopDev txt='stuOverDataA'>{preJSSort(stuOverDataA,3)}</BtnPopDev>
        <BtnPopDev txt='stuOverDataB'>{preJSSort(stuOverDataB,3)}</BtnPopDev>
        <BtnPopDev txt='stuAsmData'>{preJSSort(stuAsmData,3)}</BtnPopDev>

        <BtnPopDev txt='clsDetailData1'>{preJSSort(clsDetailData1,3)}</BtnPopDev>

        <BtnPopDev txt='stuMetaData_'>{preJSSort(stuMetaData_,3)}</BtnPopDev>
        <BtnPopDev txt='stuMetaDataL2'>{preJSSort(stuMetaDataL2,3)}</BtnPopDev>
        <BtnPopDev txt='stuMetaData2'>{preJSSort(stuMetaData2,3)}</BtnPopDev>

        <BtnPopDev txt='clsMetaData_'>{preJSSort(clsMetaData_,3)}</BtnPopDev>
        <BtnPopDev txt='clsMetaData1'>{preJSSort(clsMetaData1,3)}</BtnPopDev>

        <BtnPopDev txt='gradeMetaData_'>{preJSSort(gradeMetaData_,3)}</BtnPopDev>
        <BtnPopDev txt='gradeMetaData1'>{preJSSort(gradeMetaData1,3)}</BtnPopDev>
    </BtnDev> 
    {preJS( { r, j, mt, ms, l1, rti, rptM, c, st } )}
    </div>
    :''}
    {rviewDetail? <Container fluid={true} className={"CpTeacherReport fade-in-container"}>
            <ChartClassPerformanceDetails {...{...clsDetailData1, rptM, setRptM, rptMDDI}} /> {/* ClassPerformanceDetailsData ClassPerformanceDetailsChartType*/ }
        </Container>:''}
    {rviewMeta? <Container fluid={true} className={"CpTeacherReport fade-in-container h-100"}>
        {allClassesCase? <ChartMetadataProgressMath key={autoId()} {...{...gradeMetaData1, msets:d1Msets, selectedStudentCase, id:'thr_allcls_1y_gradeMetaData1'}} />:'' /*MetadataProgressByAllClassesTeacherData MetadataProgressChartType*/ }
        {(!selectedClassCase)? '' 
            :isKeyStage? <ChartMetadataKeyStage {...{st, clsStuEnts, rptKeyStage, clsMetaDataKS_, msets:cd1Msets, ms, rptClsKs:1, id:'thr_1cls_keyStg_clsMetaDataKS_'}} />
            :<ChartMetadataProgressMath {...{...clsMetaData1, msets:cd1Msets, selectedStudentCase, id:'thr_1cls_1y__clsMetaData1'}} /> /* MetadataProgressChartType */ }
        {(!selectedStudentCase)? ''
            :isKeyStage? <ChartMetadataKeyStage {...{st, clsStuEnts, rptKeyStage, stuMetaDataKS_, msets:cd1Msets, ms, rptClsKs:0, id:'thr_1stu_keyStg'}} />
            :<ChartMetadataProgressMath {...{...stuMetaData2, msets:stMSets, selectedStudentCase, id:'thr_1stu_1yr'}} /> /* MetadataProgressByStudentSharedData MetadataProgressChartType*/ }
{/*
(report.type.value === ReportType.METADATA) && <Container fluid={true} className={"fade-in-container"}>
{allClassesCase === true &&
    <MetadataProgressChart {...MetadataProgressByAllClassesTeacherData as MetadataProgressChartType}/>}
{selectedClassCase === true &&
    <MetadataProgressChart {...MetadataProgressBySelectedClassTeacherData as MetadataProgressChartType}/>}
{selectedStudentCase === true &&
    <MetadataProgressChart {...MetadataProgressByStudentSharedData as MetadataProgressChartType}/>}
*/}
        </Container>:''}
    {rviewOver? 
        <Container fluid={true} className={"CpTeacherReport fade-in-container"}>
            <div className="report-result d-flex felx-wrap flex-column flex-lg-row align-items-stretch gap-3">
                {/*Left top*/}
                <div className={`report-progress-col result-col-left result-col-top ${selectedStudentCase? "selected-student-col": "class-col"}`}>
                    <div className={"border rounded-light p-3 overflow-hidden h-100"}>
                        <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3"}>
                            <h4 className={"m-0"}>{t("report-subject-overview")}</h4>
                        </div>
                        <div className={"flex-grow-1 h-100 d-flex align-items-center"}>
                            {/*Depends on your data and program design, you may no need to show three component in the same time.*/}
                            {allClassesCase? <ChartSubjectOverview key={autoId()} {...overData1a} />: '' /* SubjectOverviewChartType */ }
                            {selectedClassCase? <ChartSubjectOverview key={autoId()} {...overData1a} />: '' /* SubjectOverviewChartType */ }
                            {selectedStudentCase? <ChartSubjectOverview key={autoId()} {...stuOverDataA} />: '' /* SubjectOverviewChartType shared to student*/}
                        </div>
                    </div>
                </div>
                {/*Right top*/}
                <div className={`report-progress-col result-col-right result-col-top ${selectedStudentCase? "selected-student-col": "class-col"}`}>
                    <div className={"d-flex flex-column border rounded-light h-100 p-3"}>
                        {/*<div className={"d-flex justify-content-between flex-wrap align-items-center mb-3 h-35"}>*/}
                        <div className={"d-flex justify-content-between flex-wrap align-items-start gap-3 gap-lg-0"}>
                            <h4 className={"m-0"}>{t(isKeyStage? "report-progress-by-year": "report-progress-by-month")}</h4>
                            <div className="d-flex flex-column align-items-end gap-2">
                                <span className={"option-progress-by-month"}>
                                    <CpDropdown className="w100" items={progDDI} idx={prog} cb={setProg}/>
                                </span>
                                {selectedStudentCase?<CpChartIndicatorGuideline avg={false} passing={false} />:""}
                            </div>
                        </div>
                        {allClassesCase? <ChartProgressByMonth key={autoId()} {...{lineBarType, isKeyStage, rptKeyStage, ...overData2a, }}/>:'' /* ProgressByMonthChartType */}
                        {selectedClassCase? <ChartProgressByMonth key={autoId()} {...{lineBarType, isKeyStage, rptKeyStage, ...overData2a, }}/>:'' /* ProgressByMonthBySelectedClassTeacherData}/>:'' ProgressByMonthChartType */}
                        {selectedStudentCase? <ChartProgressByMonth key={autoId()} {...{lineBarType, isKeyStage, rptKeyStage, ...stuOverDataB, }} />:'' /*ProgressByMonthByStudentSharedData*/ }
                    </div>
                </div>
            </div>
            <div className="report-result d-flex flex-column flex-lg-row align-items-stretch gap-3 mt-3">
                {/*Left bottom*/}
                {selectedStudentCase?
                    <div className="report-progress-col result-col-left result-col-bottom">
                        <div className={"border rounded-light h-100 p-3"}>
                            <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3"}></div>
                            <div className={"d-flex flex-column gap-2"}>
                                <div className={"d-flex gap-2 flex-column flex-xxl-row"}>
                                    <div className={"w-100"}><CpMJX dynamic={true}>
                                        <CpDropdown className="w100" items={MTypeDDI} idx={mt} cb={setField('MType')} /*metadataOptions*/ />
                                    </CpMJX></div>
                                    <div className={"w-100"}><CpMJX dynamic={true}>
                                        <CpDropdown className="w100" items={MSetDDI} idx={ms} cb={setField('MSet')} /*items={metadataSetOptions}*/ />
                                    </CpMJX></div>
                                </div>
                                <ChartMetadataRadialGroup /*MetadataRadialGroupChart*/ label={t("report-overall-score-percentage")} 
                                    {...{...stuMetaDataL2, clickM}} /*{...MetadataRadialGroupData as MetadataRadialGroupChartType} */ />
                            </div>
                        </div>
                    </div>:''
                }
                {/*Right bottom*/}
                <div className="report-progress-col result-col-right result-col-bottom">
                    <div className={"d-flex flex-column border rounded-light h-100 p-3"}>
                        <div className={"d-flex justify-content-between flex-wrap align-items-center "}>
                            <h4 className={"m-0"}>{t("report-assignments-progress")}</h4>
                            <CpChartIndicatorGuideline key={autoId()} {...{}} />
                        </div>
                        <div className={"flow-container"}>
                            {allClassesCase?
                                <ChartAssignmentProgress key={autoId()} {...{useSL, ...gradeAsmData}} />:'' /* AssignmentProgressByAllClassesTeacherData AssignmentProgressChartType*/ }
                            {selectedClassCase?
                                <ChartAssignmentProgress key={autoId()} {...{useSL, ...clsAsmData}} />:'' /* AssignmentProgressBySelectedClassTeacherData as AssignmentProgressChartType*/ }
                            {selectedStudentCase?
                                <ChartAssignmentProgress key={autoId()} {...{useSL, ...stuAsmData}} />:'' /*as AssignmentProgressByStudentSharedData AssignmentProgressChartType*/}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        :''
        }
    </>;
});
export default CpTeacherReport;

export const sortClsStuEnts = clsStus => objEntries(clsStus).sort((a, b) => toInt(a[1].classNo) - toInt(b[1].classNo));  


//mb-3 h-35
const monthDDI = (t) => obj2DropdownItem({
    '*': t("schema:all"),
    m_1: t("schema:calendar.month.1"),
    m_2: t("schema:calendar.month.2"),
    m_3: t("schema:calendar.month.3"),
    m_4: t("schema:calendar.month.4"),
    m_5: t("schema:calendar.month.5"),
    m_6: t("schema:calendar.month.6"),
    m_7: t("schema:calendar.month.7"),
    m_8: t("schema:calendar.month.8"),
    m_9: t("schema:calendar.month.9"),
    m_10: t("schema:calendar.month.10"),
    m_11: t("schema:calendar.month.11"),
    m_12: t("schema:calendar.month.12"),
});
