export const asyncLoadFileBlob = async file => asyncLoadFile(file, 2);

export const asyncLoadFile = async (file, type) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        if(type===1){
            reader.readAsBinaryString(file);
        }else if( type === 2){
            reader.readAsDataURL(file);
        }else if( type === 3){
            reader.readAsArrayBuffer(file);
        }else {
            reader.readAsText(file);
        }
    });
};

/*
export const asyncLoadAudio = async (file) => {
    const audio = document.createElement('audio');
    var reader = new FileReader();
    reader.onload = () => {
        audio.src = reader.result;
        audio.addEventListener('loadedmetadata', function(){
            //debugLog("The duration of the song is of: " + duration + " seconds");
        },false);
    };
    reader.readAsDataURL(file);
};
*/
