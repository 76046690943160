import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as UI from '../../libs/libUI';
import * as FIBC from "../../consts/ATQtnAnsTypeFIB";
import { toStr, toAry, toFloat } from '../../libs/libType';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import CpATAnsDD from './CpATAnsDD';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import {ResultAnsBlk} from './CpQDoResultAnsBlk';
import CpFIBManualMark from './CpFIBManualMark';
import { debugMode } from '../../saga/ReduxState';
import { __FIBA_EQU } from '../../consts/ATQtnAnsTypeFIB';

const CpQDoFIB = (props) => {
    const { fullQ, Q, mediaDLs, getMediaDLs } = props;
    const { containerClass, editable, asmView, doEdit, 
        isTeacher, isStudent, isStt, isList, scrollRef,
        showResult, showCorr,
        setStudentResp, setTeacherResp, studentResp, teacherResp} = props;    

    //const showResult = 0; //props.showResult;
    //const showCorr = 1;

    const domQuesRef = useRef(null);
    const inputFieldsRef = useRef([]);
    const widthFieldsRef = useRef([]);
    const inputResultRef = useRef([]); // append result DD to answer

    const stResp = studentResp?.qresp;
    const stCorr = studentResp?.corr;
    const stRespMath = studentResp?.math3Str;
    const thResp = teacherResp?.qresp;
    const [myAns, setMyAns] = useState(stResp || {}); // store what user answer data only
    const [thCorr, setThCorr] = useState(thResp || {}); // store what user answer data only
    const [afterInit, setAfterInit] = useState(0);
    const afterTypeSet = useRef(0);

    const autoMark = fullQ?.autoMark || 0;

    const caseSensitive = fullQ?.caseSensitive || 0;
    const qData = Q?.qData || "";
    const qAnswers = Q?.qAnswers || [];
    
    const myID = 'ansDo'+fullQ.QId;//+uuidv4();
    const inputElePos = {};
    const edProps = {mediaDLs, getMediaDLs};    
    
    useEffect(()=>{
        //insertAnswerComponent();
        //setAfterInit(1);
    },[]);
    
    useEffect(()=>{
        if (afterInit && isStudent && setStudentResp && !showResult) {
            if (Object.keys(myAns).length > 0) {
                /*todo*/
                setStudentResp({...studentResp, qresp:{...myAns}});
            };
        };
    },[myAns]);

    useEffect(()=>{
        if (isTeacher && setTeacherResp && afterInit) {
            if (Object.keys(thCorr).length > 0)
                setTeacherResp({...teacherResp, qresp:{...thCorr}});
        };
    },[thCorr]);    

    const correctClick = (oupansid, cor) => {
        setThCorr({...thCorr, ...{[oupansid]:cor}})
    };

    const ansSetValue = (oupansid, v, ansType) => {
        if (!doEdit) return;
        /*todo*/
        setMyAns({...myAns, ...{[oupansid]:v}});
    };

    const setDDAns = (oupansid, v) => {
        if (!doEdit) return;
        setMyAns({...myAns, ...{[oupansid]:v}});
    };

    const getInputEle = (ans, ii) => {
        const correctAns = autoMark || ans?.correctAns || 0;
        const ansType = ans.atype?ans.atype:FIBC.__FIBA_TXT;
        
        const showCK = (ansType === FIBC.__FIBA_ADV || ansType === FIBC.__FIBA_EQU);
        const eqCheck = ansType === FIBC.__FIBA_EQU;

        const adjWidth = (w) => {
            const factor = isStt?16:1;
            const variation = isStt?(0.9):1;
            const tmp = Math.ceil(w * 1.2 / variation);
            const minW = 50/factor;
            return (tmp<minW?minW:tmp)+(40/factor);
        };

        // for student, need to check isStt, not isStudent(include preview)
        const dWidth = toFloat(ans?.dWidth);
        const exStyle = isStt?{ width: (showCK && dWidth===0)?'auto':(adjWidth(dWidth) +'em'), maxWidth:'100%' }:{};
        
        const exReadStyle = {};
        
        if (!ans.qvalue) return <div>no answer</div>;

        const studentAns = (showResult || showCorr || asmView)?toStr(stResp?stResp[ans.oupansid]:'').trim():'';
        const ansCorr = (showCorr && stCorr) ? stCorr[ans.oupansid] : 0;
        const stMathStr = stRespMath?stRespMath[ans.oupansid]:[];

        let displayStr = toStr(ans.qvalue[0].data).trim();
        if (!displayStr) displayStr = (showCK?'<p> </p>':' ');

        const setWidth = (cref, w) => {
            if (cref) {
                const newWidth = adjWidth(w);
                cref.style.width = (showCK && w===0)?'auto':(newWidth+'px');
                cref.style.maxWidth = '100%';
            };
        };
        
        const readOnlyAns = () => {
            return showCK?<QEditorReadOnly {...props} data={displayStr} {...edProps} exStyle={exReadStyle}/>
            :<span>{displayStr}</span>
        };

        const _thCorr = thResp?(thResp.hasOwnProperty(ans.oupansid)?(thResp[ans.oupansid]===1?1:0):undefined):undefined;
        const mCorrect = ((showResult || isTeacher) && !autoMark)?_thCorr:-1;
        return <div key={'ans'+ii} ref={(el)=>inputFieldsRef.current[ii]=el}
        className='ansInputContainerBorder' style={{width:'auto'}}>
            {(showResult || showCorr || asmView)?<ResultAnsBlk {...props} {...edProps} 
                {...{mCorrect, correctAns, autoMark, showCK, eqCheck, studentAns, stMathStr, edProps,
                    caseSensitive, scrollRef, showCorr, ansCorr}} 
                menuStyle={{width:'auto !important'}}
                idx={ii} arr={ans.qvalue} appendResultInfo={appendResultInfo} setResultRef={(el=>inputResultRef.current[ii]=el)}/>
            :(<div className='ansInputContainer' style={exStyle} ref={(el)=>widthFieldsRef.current[ii]=el} >
            {doEdit?editor(toStr(myAns[ans.oupansid]), (v)=>ansSetValue(ans.oupansid, v, ansType), ansType, ii, doEdit)
                :readOnlyAns()}
            </div>)}
            {(isTeacher && !autoMark)?<CpFIBManualMark correct={_thCorr} correctClick={(cor)=>correctClick(ans.oupansid, cor)} />:''}
            {(doEdit && !showResult && !isStt)?<GetMaxWidthOfElements idx={ii} arr={ans.qvalue} setWidth={(w)=>setWidth(widthFieldsRef.current[ii],w)} ck={showCK}/>:''}
            {false?<span style={{border:'1px solid orange'}}>
                {widthFieldsRef.current[ii]?(widthFieldsRef.current[ii].style.width):'?'}</span>:''}
        </div>;
    };

    const getDropDownEle = (ans, ii) => {
        if (!ans.qvalue) return <div>no answer</div>;
        const ansOpts = [];
        if ((!showCorr) && (doEdit || (ans.qvalue.length===0)))
            ansOpts.push({id:-1, correct:0, jsx:(isHead=false)=>
                <div className={'ck-content EditorReadOnly '+isHead?'':'ATDDAnsOption'}
                style={{padding:'0 10px'}}>{'---'}</div>});
        ans.qvalue.forEach((an, kk)=>{
            const displayStr = toStr(an.data)?toStr(an.data):'<p/>';
            ansOpts.push({id:kk, correct:an.correct, jsx:()=><QEditorReadOnly {...props} 
                data={displayStr} {...edProps} exStyle={{backgroundColor:'transparent',
                padding:'0px 10px 0px 10px'}}/>});
        });

        const stAns = (stResp && (stResp[ans.oupansid]>=0))?stResp[ans.oupansid]:-1;
        const ansCorr = (showCorr && stCorr) ? stCorr[ans.oupansid] : 0;
        const isCorrect = (stAns>=0) && (ans.qvalue[stAns].correct || ansCorr);
        const correctData = ans.qvalue.find(ans=>{return ans.correct})?.data;
        const ansVal = (showResult || showCorr)?stAns:myAns[ans.oupansid];
        return <div key={'ans'+ii} ref={(el)=>inputFieldsRef.current[ii]=el} 
            className='ansInputContainerDDBorder'>
            <CpATAnsDD key={'ans'+ii} opts={ansOpts} sel={ansVal} refIndex={ii} editable={editable && !asmView} asmView={asmView}
            rowStyle={{padding:'12px 5px', margin:0}} headerStyle={asmView?((isTeacher||showResult||showCorr)?{}:{backgroundColor:'rgb(232, 230, 250)', border:'1px #5d52ce solid'}):{}}
            setSel={(v)=>setDDAns(ans.oupansid, v)} posCheck={true} showResult={showResult || isTeacher} showCorr={showCorr}
            domQuesRef={scrollRef} ansCorr={ansCorr} />

            {(showResult && !isCorrect)?<div className='ansDisplayBlock' style={{overflowX:'auto', marginLeft:'5px'}}>
            <QEditorReadOnly {...props} data={correctData?correctData:'<p> </p>'} {...edProps} exStyle={{backgroundColor:'transparent',
            padding:'0px 10px 0px 10px'}}/></div>:''}
        </div>;
    };

    const insertAnswerComponent = () => {
        if (domQuesRef.current) {
            let allAns = domQuesRef.current.getElementsByClassName("oupanswer");
            if (allAns.length > 0) {
                Array.from(allAns).forEach((ans, ii)=>{
                    const qid = ans.getAttribute('oupansid');   
                    ans.replaceWith(inputFieldsRef.current[inputElePos[qid]]);
                    if (showResult || showCorr) appendResultInfo(ii);
                });
            } else {
                //console.log('no answer found', myID);
            };
        } else {console.error("domQuesRef.current is undefined.")};
    };

    const appendResultInfo = (idx) => {
        if (inputFieldsRef.current[idx] && inputResultRef.current[idx]) {
            //console.log("append !!");
            inputFieldsRef.current[idx].insertAdjacentElement("afterend",inputResultRef.current[idx]);
        };        
    };

    const createInputElements = () => {
        const result = qAnswers.map((ans, ii)=>{
            inputElePos[ans.oupansid]=ii;
            return (ans.qtype === FIBC.__FIBT_TXT)?getInputEle(ans,ii):getDropDownEle(ans,ii);
        });
        return result;
    };

    const cb = () => {
        if (domQuesRef.current && !afterTypeSet.current) {
            //console.log('fib callback ', myID);
            afterTypeSet.current = 1;
            insertAnswerComponent();
            setAfterInit(1);    
            /*
            let allAns = domQuesRef.current.getElementsByClassName("oupanswer");
            if (allAns.length > 0) {
                console.log('answer found', myID);
            } else {
                console.log('no answer found', myID);
            };
            */
        };
    };

    return <div className={containerClass + (isList?' list':'')}>
        <div className='tabDoQContent2' id="mathDisplay">
            <div id={myID} key={myID} className='tabDoQContent3 f18' ref={domQuesRef}>
                <QEditorReadOnly {...props} data={qData} {...edProps} callBack={cb} />
            </div>
        </div>
        <div style={{width:0,height:0,overflow:'hidden'}}>{qAnswers && createInputElements()}</div>
    </div>;
};

export default CpQDoFIB;

const inputBoxFIB = (ii=0, edType, data="", setData) => {
    const inputEle = (edType === "number"?UI.EpInputNum:UI.EpInputTxt0);
    return inputEle(data, setData, edType+ii," ansFIBInputBox ",{});
};

const editor = (data, setData, type, ii, editable) => {
    const edType = (type === FIBC.__FIBA_EQU?"equation":
            type === FIBC.__FIBA_ADV?"inputAnswerAdv":
            type === FIBC.__FIBA_TXT?"text":
            "number"
        );
    return <React.Fragment key={edType+ii}>
        {(edType==="equation"||edType==="inputAnswerAdv")?<Ckeditor5Base editorType={edType} needDebounce={true}
        data={data} setData={setData} enable={editable} debug={false} showStatus={false}/>
        :inputBoxFIB(ii,edType,data,setData)}
    </React.Fragment>;
};
//{(edType==="equation"||edType==="inputAnswerAdv")?<Ckeditor5Base editorType={edType} 
const checkWidthDiv = (data, ii, ck) => {
    return ck?<div key={'chk'+ii} className={"ck-content CheckEditorReadOnly "}
    dangerouslySetInnerHTML={{__html:data}}/>:<span key={'chk'+ii}>{data}</span>;
};
//style={{top:'100px',overflow:'auto', maxHeight:'1000px', height:'auto'}}
const GetMaxWidthOfElements = (props) => {
    const myRef = useRef(null);
    const arr = toAry(props?.arr);
    useEffect(()=>{
        // check here
        const {setWidth} = props;
        if (myRef.current && setWidth) setWidth(myRef.current.clientWidth);
    },[myRef.current]);
    return <div ref={myRef} key={'cw'+props.idx} className='checkWidthCont'>
        {arr.map((ans, ii)=>{
            return checkWidthDiv(toStr(ans.data), ii, props.ck);
        })}
    </div>
};
