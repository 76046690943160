import React, {useContext, useEffect, useState} from "react";
import { useFields } from "../../AppExPf/utils/useEditDraftPub";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";
import { langIsEn, useUILang } from "../../AppExPf/utils/useUILang";
import { toAry, toInt, toObj, toStr } from "../../libs/libType";
import { ReduxBind } from "../../saga/ReduxState";
import TabExerEdContent from "../EPExercise/TabExerEdContent";
import TabExerEdDetail from "../EPExercise/TabExerEdDetail";
import TabTeacherAssignmentStatus from "./TabTeacherAssignmentStatus";
import TabTeacherAssignmentSettings from "./TabTeacherAssignmentSettings";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import {TabAsmNextStep, TabExerNextStep} from "../../AppExPf/ATExerEdit/TabExerNextStep";
import TabTeacherAssignmentReport from "../EPReport/TabTeacherAssignmentReport";
import { _pathEcol } from "../../consts/ATConstReact";
import { _pathAssign } from "../../consts/ATConstReact";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { _AST_PENDING, _WST_SUBMIT } from "../../consts/ATConstsAssignment";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { stopEvent } from "../../libs/libUI";
import PageAsmMark from "../EPAsmWork/PageAsmMark";
import { deepCopy } from "../../AppExPf/AppUtil";
import { exerHasMark } from "./Tags/TagMarkingTag";
import { markMapMedias } from "../../consts/AValidateMark";
import { upLocalMedias } from "../EPExercise/LayerEMediaUp";
import { packMark } from "../../consts/wirisConfig";

const TabTeacherAssignmentTabContainer = ReduxBind(props => {     //base on poc TeacherAssignmentTabContainer
    const {dispatch, asmId, act, works, marks, qtns, useCacheQtns, remeAsms, reload} = props; 
    const {showExPV, loadPVRemeAsm, markReme, loadMarkReme} = props;

    const [t, uiEn, lang, setLang, ut] = useUILang();

    const _asm = props.asm;
    const asm = toObj(_asm);
    const ex = toObj(asm.Exer);
    const showEn = langIsEn(asm.ALang);

    const [ cache2d, useGetCache ] = useCaches(props);
    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);

    const initTab = (act === 'report')?'report':'status';
    const [tab, setTab] = useState(initTab);
    
    useEffect(() => { (act === 'report') && setTab('report'); }, [act]);
    const [rt, setRType] = useState();


    const handleTab = goTab => {
        setTab(goTab);
        const urltab = (goTab === 'report') && goTab;
        props.dispatch(urlPush_Replace(_pathAssign(asmId, urltab))); //window.history.pushState({}, "", _WebRoot+"admin");// redurect
    };

    const asmMarking = useAsmMarking(dispatch, asm, ex, works, marks, useGetCache, cache2d, getMediaDLs, addLocalMedias); //to do shave marking with reme
    const {SWMs, marking, setMarking, saveMarks} = asmMarking;

    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts] = useFields(); 
    useEffect(() => { setFields(deepCopy(ex)); }, [_asm]);

    if(marking){
        const _setM = m => { setMarking(m); if(!m) reload(); };
        return <PageAsmMark {...{asm, Exer:ex, qtns, SWMs, marking, setMarking:_setM, saveMarks, }} />
    };

    const tabProps = {lock:1, showEn, asm, works, marks, ex, qtns, useCacheQtns, tagMSets, useTagMSets, remeAsms,
        mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias, reload, asmMarking,
        showExPV, loadPVRemeAsm, markReme, loadMarkReme};
    const nextProps = {...tabProps, setTab, setRType, setField, setFields, lock:0 };

    const dynamicContainerStyle = (tab === "status" || tab === 'report')? "": "container-md";
    const cssVis = (markReme.marking || showExPV)? {display:'none'}: {}; 
    return <>
        <div style={cssVis} className={"container-fluid"} ><div className={"row"}><div className={"d-flex flex-column gap-3"}>
            <div className={"d-flex justify-content-end gap-2"}><div className={"d-flex flex-wrap justify-content-between w-100"}>
                <span className={"d-flex gap-2 semi-bold fs-6 align-items-center"} style={{overflow:"visible"}}>
                    <div className={"exercise-edit-tab d-flex gap-3"}>{[
                        {tab: "content", title: t("exercise-edit-tab-content"), },
                        {tab: "details", title: t("exercise-edit-tab-details"), },
                        {tab: "settings", title: t("assignment-edit-tab-settings"), },
                        {tab: "status", title: t("assignment-edit-tab-status"), },
                        {tab: "report", title: t("assignment-edit-tab-report"), },
                        {tab: "next-steps", title: t("exercise-edit-tab-next-steps"), }
                    ].map(item => <div key={item.tab} role={"button"} className={`tab-item text-nowrap ${item.tab === tab? "active" : ""}`} 
                        onClick={() => handleTab(item.tab)}>{item.title}</div>)}</div>
                    {/*<Tab items={} cb={setTab}/> */}</span>
            </div></div>
        </div></div></div>
        <div  style={cssVis} className={`container-fluid ${dynamicContainerStyle} mt-3`}><div className={"row"}><div className={"col-12"}>{
            (tab === "content")? <TabExerEdContent {...tabProps} />: // <ExerciseEditContentPage assignmentLevel={true}/>
            (tab === "details")? <TabExerEdDetail {...tabProps} />: // <ExerciseEditDetailsPage/>
            (tab === "settings")? <TabTeacherAssignmentSettings {...tabProps} />: //tobon let me port this please   
            (tab === "status")? <TabTeacherAssignmentStatus {...tabProps} />: /*'tobon you please port <TeacherAssignmentStatus/>'*/
            (tab === "report")? <TabTeacherAssignmentReport {...{...tabProps, rt, setRType}} />: /*'tobon you please port <TeacherAssignmentReport/>', 'the page is empty at this moment'*/
            (tab === "next-steps")? <TabAsmNextStep {...nextProps} />: //<ExerciseNextStepsPage/>
            'what the tab?!'}
        </div></div></div>
    </>
});
export default TabTeacherAssignmentTabContainer;

export const useAsmMarking = (dispatch, asm, ex, works, marks, useGetCache, cache2d, getMediaDLs, addLocalMedias) => { //base on poc TeacherAssignmentStatus 
    const [marking, setMarking] = useState(); 
    const [SWMs, setSWMs] = useState([]); 
    
    const a = toObj(asm);
    const Exer = toObj(ex);
    const classId = toStr(a.classId);
    const students = useGetCache('classStudents', classId);

    const saveMarks = async (saveSWMs) => {
        setSWMs(saveSWMs);
        const ms = toAry(saveSWMs).map(swm => swm.mark);
        const ems = ms;//.filter(m => m.isEdited);

        // map resp drawing data
        for (let ii = 0; ii < ems.length; ii++) {
            const mark = ems[ii];
            const [nilw, mids, drawBlobs] = markMapMedias(mark, {}, addLocalMedias);
            const mediaMap = await upLocalMedias(dispatch, mids, getMediaDLs, ()=>{}, drawBlobs);
            const [mapMark, nilm, nilBlobs] = markMapMedias(mark, mediaMap, addLocalMedias);
            ems[ii] = mapMark;
        };
        const [res, err] = await asyncApiCallLoad_(dispatch, '/putMarks', {marks:ems.map(packMark)});
    };

    const clickMark = swmIdx => e => {
        console.log('clickMark', swmIdx);
        stopEvent(e); setMarking({swmIdx})};
    
    useEffect(() => setSWMs(toSWMs(asm, Exer, works, marks, students)), [asm, ex, works, marks, students]);

    //const onLoadWorks = (res, err) => { setWorks(Object.fromEntries(toAry(res?.works).map(w => [w.userId, w]))); };
    return {classId, students, SWMs, marking, clickMark, setMarking, saveMarks, };
};

export const toSWMs = (asm, Exer, works, marks, students) => {
    const ss = toObj(students);
    const ws = toObj(works);
    const ms = toObj(marks);
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(asm?.Exer);

    const ret = toAry(asm?.studentIds).map((sid, swmIdx) => {
        const [student, work, mark] = [toObj(ss[sid]), toObj(ws[sid]), toObj(ms[sid])];
        const { studentId, classNo, nameChi, nameEng } = student;
        const { dateMod, MUseMS, WState, startTime, endTime, dateSub, msUsed} = work;
        const { MAScore, MAQtn, MMScore } = mark;
        // MStat fields definition pls ref to ATQtnScore.js
        const MStat = toObj(mark?.MStat);
        const WSubmit = (WState === _WST_SUBMIT)? 1: 0;
        //const marked = (MAQtn || MMQtn)? 1: 0;
        const marked = isQtnAllMarked(mark);
        const MMQtn = MStat?.mQtnCount > 0;
        const aPend = ((asm?.AState) === _AST_PENDING);
        return {
            swmIdx, student, work, mark,
            studentId, classNo, nameChi, nameEng,
            dateMod, MUseMS,
            MAScore, MAQtn, MMScore, MMQtn, aPend,
            WSubmit, marked, nil:0,
            startTime, endTime, dateSub, msUsed, mScore: toInt(MStat.mScore), aScore: toInt(MStat.aScore),
            mQtnScore: mScore, aQtnScore: aScore
        };
    });

    //console.log('toSWMs', {ss, ws, ms, ret}, [asm?.studentIds]);
    return ret;
};

export const isQtnAllMarked = (_mark) => {
    const MStat = toObj(_mark?.MStat);
    return MStat.totalQtn === MStat.allMarked;
};

/*
const Tab = (props => {
    const {items, cb} = props;
    const [tab, setTab] = useState(items[0].tab);
    const handleTab = (tab) => {
        setTab(tab);
        if (cb) cb(tab);
    };
    return <div className={"exercise-edit-tab d-flex gap-3"}>{items.map(item => <div key={item.id} role={"button"} 
    className={`tab-item text-nowrap ${item.tab === tab ? "active" : ""}`} onClick={() => handleTab(item.tab)}>{item.title}</div>)}</div>
});
*/