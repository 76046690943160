import React, { useState} from "react";
import {useUILang } from "../../AppExPf/utils/useUILang";


import { _WST_SUBMIT, _QST_SUB_WRONG, _QST_SUB_CORR, _QST_SUB_ATT, _QST_SUB_NOTMARK, _QST_SUB_NOTATT } from "../../consts/ATConstsAssignment";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";
import { autoId, BtnDev, preJS } from "../../AppExPf/AppUtil";
import { _ATMetaTypeByCode, _ATMetaType_SU } from "../../consts/ATMetaTypes";

import CpMJX from "../_components/CpMJX";
import { ExpandBtn, } from "./TabPerformanceMetadataSetsChart";
import { isObj, objEntries, toAry, toInt, toObj, toPercent1, toPercentF, toStr, } from "../../libs/libType";
import { RemeLvSame } from "../../consts/ATValidateReme";
import { ReduxBind } from "../../saga/ReduxState";
import { stopEvent } from "../../libs/libUI";
import { exerHasMark } from "../EPAssign/Tags/TagMarkingTag";
import CpActionButton from "../_components/CpActionButton";
import { IconList } from '../../consts/ATIconListToUsePoc';
import TagRemeLv, { TagRemeLvs } from "../../AppExPf/ATExerEdit/TagRemeLv";
import CpChartMiniBar from "./CpChartMinibar";

const ChartStuAsmReme = ReduxBind(props => { //PerformanceMetadataSetsChart
    const [t, uiEn, lang, setUILang, ut, t2] = useUILang();
    const {dispatch, myUId, loadRemeAsmDo} = props;
    const { rptMTree, metaAsms, stuMStats } = props;
    const { remeLv } = props;
    const lvSame = (remeLv === RemeLvSame);

    const clickRemePV = rAsmId => e => {stopEvent(e); loadRemeAsmDo(rAsmId);};
    return <>
    <table className={'ChartStuAsmReme report performance-metadata-sets-chart follow-up-exercise-chart'}>
        <thead><tr>
            <th className={`text-center question-no-th ${lang} sticky-header header-qno`}>
                {/*<div className={"ms-auto me-0"}><span>{t('curriculum')}</span></div>*/}
                <div className={"ms-auto me-0"}><span>{""}</span></div>
            {/*</th><th className={`correctly-th`} style={{minWidth:'100px'}}>*/}
            </th><th className={`ps-3 wider no-of-studnet-follow-up-th ${lang}`} style={{minWidth:'100px'}}>
                <div><span>{t("report-follow-up-no-of-attempts")}</span></div>
            {/*</th><th className={`text-center ${lang}`} style={{minWidth:'200px'}}>*/}
            </th><th className={`ps-3 wider no-of-studnet-follow-up-th ${lang}`} style={{minWidth:'200px'}}>
                <div><span>{t("report-follow-up-average-score-assignment")}</span></div>
            {/*</th><th className={`text-center ${lang}`} style={{minWidth:'200px'}}>*/}
            </th><th className={`ps-3 wider no-of-studnet-follow-up-th ${lang}`} style={{minWidth:'200px'}}>
                <div><span>{t("report-follow-up-score")}</span></div>
            </th>
            <th className={"w-100"}></th>
        </tr></thead>
        <tbody>{toAry(rptMTree).map((rptMNode, key) => <CpStuRptRemeRow {...{key, myUId, metaAsms, rptMNode, t, lvSame, stuMStats, clickRemePV}}/> )}</tbody>
    </table></>;
});

export default ChartStuAsmReme;


const CpStuRptRemeRow = props => {
    const indent = toInt(props.indent); 
    const {myUId, metaAsms, rptMNode, t, lvSame, stuMStats, } = props
    const { clickRemePV, clickRemeMark } = props;
    const {id, SMId, title, children} = toObj(rptMNode);

    const [open, setOpen] = useState(true)
    const handleOpen = () => { setOpen(prev => !prev)};

    const rAsm = metaAsms[SMId];
    const rAsmId = toStr(rAsm?.assignId);
    const {passValue, APass, passUnit, remeSMId} = toObj(rAsm);
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(rAsm?.Exer);
    const eMark = toInt(aScore)+ toInt(mScore);
    const {work, mark, sumWMs} = toObj(rAsm);
    const myWMs =toObj(sumWMs?.[myUId]);
    const {marked, submitted, uMark, _attemp} = myWMs;
    const uPass =  toPercentF(uMark, eMark) >= passValue; //stuPassAsm(asm, mark);

    const status = submitted? ((marked||1)? (APass? (uPass? 'p': 'f' ): 'a') : 'm') :'';
  
    const hasR = rAsm? true: '';
    const clickReme = clickRemePV(rAsmId);
    const sameM = hasR && stuMStats[remeSMId];
    const sameUMark = toInt(sameM?.uMark);
    const sameQMark = toInt(sameM?.qMark);
    const remeLvs = objEntries(rAsm?.remeLvStus).filter(lu => toAry(lu[1]).includes(myUId)).map(lu => lu[0]);

//<td>{preJS(rAsm?.remeLvStus)}</td></tr><tr>
    return <><tr id={'smid-'+SMId} className='CpStuRptRemeRow'>
        <td className={`sticky-header header-qno`}>
            <div style={{paddingLeft: `${indent}rem`}} className={"d-flex justify-content-between gap-3 semi-bold"}>
                <div className={"overflow-auto py-2"}>
                    <span className={`d-flex gap-2 ${children.length === 0 ? "ps-4" : ""}`}>
                        {children.length > 0 && <ExpandBtn open={open} onClick={() => handleOpen()}/>}
                        <div>
                        <CpMJX key={autoId()} dynamic={true}><span>{title}</span></CpMJX>
                        {hasR? <div className="d-flex"><TagRemeLvs {...{remeLvs}}/></div>: ''}
                        </div>
                    </span>
                    <div className={"d-flex gap-0 ps-4"}></div>
                </div>
                <div><div className={"field d-flex justify-content-end align-items-center px-3 text-nowrap"}>
                    {hasR? <BtnStuReme {...{id: 'reme-'+rAsmId, submitted, uPass, onClick:clickReme, t}} />: ''}
                </div></div>
            </div>
        </td>
        <td><Cp1Num {...{vis: hasR, val: toInt(_attemp), type: "metadata"}} /></td>
        <td><CpPercentBar {...{vis: sameM, val: toPercent1(sameUMark, sameQMark), type:'p'}} /></td>
        {//<td><CpPercentNum {...{vis: sameM, val: sameUMark, ttl: sameQMark, status:'a', type: "metadata"}} /></td>
        }<td><CpPercentBar {...{vis: hasR, val: toPercent1(submitted && uMark, eMark), type: status, hasPass:1, passValue}} /></td>
        {//<td><CpPercentNum {...{vis: hasR, val: (submitted && uMark), ttl: eMark, status, type: "metadata"}} /></td>
        }<td className={"w-100"}></td>
    </tr>
    {open && children.map((rptMNode, key) => <CpStuRptRemeRow {...{...props, key, rptMNode, indent:indent + 1}}/> )}
</>};

const Cp1Num = props => {// { id: string, type: "metadata" | "question" }) => {
    const {vis, val} = props;
    return <div className={"Cp1Num field d-flex justify-content-center align-items-center semi-bold"}>{vis? toStr(val): ''}</div>
};

const CpPercentNum = props => {// { id: string, type: "metadata" | "question" }) => {
    const {vis, val, ttl} = props;
    return vis? <div className={"CpPercentNum field d-flex justify-content-center align-items-center semi-bold"}>{toPercent1(val, ttl)}%</div>
        :<div className={"field"}></div>
};

const BtnStuReme = (props) => {
    const {id, onClick, submitted, uPass, t, } = props;
    const title = t(submitted? (uPass? 'assignment-cta-view': 'assignment-cta-retry'): 'assignment-cta-start'); 
    const iconPath = submitted? (uPass? IconList.assignment.preview: IconList.assignment.retry): IconList.assignment.start;  
    return <CpActionButton className={`CpRemeBtn btn exercise-action-btn gap-2 rounded semi-bold user-select-none text-nowrap`}
        {...{id, onClick, title, iconPath}}></CpActionButton>;
};

const CpPercentBar = props => {
    const {vis, val, passValue, hasPass} = props;
    const type = toStr(props.type);
    const fill = val;// 70; //fill rate
    const lines = (hasPass || passValue) && [{pos: passValue, color:'#ffa7a7', width:2} ];
    const txt = toStr(val)+'%';
    //const type = 'p'; //fill color type ie. f p s m n ....//['.correctness-bar, .p-bar.average-bar, .avg-bar
    const txt1 = ''; //txt in fill middle
    const point = ''; //{pos:40, txt:'point', type: 'n/p/f...};


    if(!vis) return <div className="field"></div>;
    return <div className="field"><div className={`minibar-container d-flex mb-4`}>
        <div>
            <span className={`bg-value value-bottom semi-bold text-nowrap ${1? "": "opacity-0"}`}>{txt}</span>
            <div className={`correctness-average-bar ${type}-bar`} style={{width: fill+`%`}}>
                <span className={`bg-value value-top semi-bold text-nowrap ${1? "": "opacity-0"}`}>{txt}</span>
            </div>
            {isObj(point)? 
                <div className={`indicator-value semi-bold ${point.type}-bar px-2 rounded-light text-nowrap`} style={{left: point.pos`%`}}>{point.txt}</div>
            :''}
            {toAry(lines).map((l, i) => {
                const left = toStr(l.pos)+'%'; 
                const w = l.width || 2;
                const color = toStr(l.color) || '#000' 
                return <div key={i} style={{position:'absolute', top:0, bottom:0, left, width:0, borderLeft:w+'px dotted '+color}} />
            })}
        </div>
    </div></div>;
};

const CpPercentBarX = props => {
    const {type, status, vis, val} = props;
    return vis
        ? <div className="field"><CpChartMiniBar {...{type, status, marks: {percentage: val}}} /></div>
        : <div className="field"></div>;
};

