import React, {useMemo} from 'react';
import { objEntries, objKeys, strCmp, toAry, toObj, toStr } from '../../libs/libType';

import {
    _ATMetaTransByCode,
    _ATMetaTypeByCode, _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, _ATMetaType_ET, _ATMetaType_EX,
    _ATMetaType_GE, _ATMetaType_GR, _ATMetaType_LA, _ATMetaType_ME, _ATMetaType_OT, _ATMetaType_QT, _ATMetaType_SK, _ATMetaType_SU, _ATMetaType_TA, _ATMetaType_TB, _ATMetaType_VA
} from '../../consts/ATMetaTypes';

import QEditorReadOnly from "./ckeditor5/CpQEditorReadOnly";
import AnsKeyContent from "./CpAnsKeyContent";
import CpActionButton from "../../AppExPFUser/_components/CpActionButton";
import { useUILang } from "../utils/useUILang";
import { mapTMSets, } from "../ATQtnEdit/PageATQtns";
import { preJS, autoId } from '../AppUtil';
import { CpAsmQtnMetaEdCTA } from '../../AppExPFUser/EPExercise/CpMetadataEditorCTA';
import { _AST_PUBLISHED } from '../../consts/ATConstsAssignment';
import { toIdStr, toUniIdAry } from '../../consts/ATValidate';
import CpMJX from '../../AppExPFUser/_components/CpMJX';
import { splitSMId, splitSMIdPairStr } from '../../consts/ATValidateMetaSet';

const CpQViewExt = props => {
    const { lock, asm, reload, fullQ, showEn, tagMSets, useTagMSets} = props;
    const [t, isUIEn, UILang, setUILang] = useUILang();
    const Q = fullQ && ((showEn? fullQ.QEn: fullQ.QCt) ||fullQ.Q);
    const AKText = toAry(Q?.AKText);
    const AKFile = toAry(Q?.AKFile);
    const AKLink = toAry(Q?.AKLink);  
    //const typeTags = toObj(props?.fullQ?.TMSets);

    const QId = toIdStr(fullQ?.QId);
    const canEditM = asm && (asm.AState !== _AST_PUBLISHED) && QId && fullQ && (fullQ.schoolId) && (fullQ.userId);
    const metaDs = fullQ?.QDisplayMetas;
    const editAsmQM = {asm, QId, metaDs, reload}; 
    const metaEditProps = {...props, editAsmQM, variant:"taggings-primary _exercise-third-btn_"};
    return <div className={"CpQViewExt d-flex flex-column gap-4 my-2 bg-white rounded-light px-2 py-3 w-100"}>
    <div className={"semi-bold text-decoration-underline"}>{t("hints")}:</div>
    <div><QEditorReadOnly {...props} data={Q?.ansHints} /></div>
    <div className={"semi-bold text-decoration-underline"}>{t("answer-key")}:</div>
    <div><AnsKeyContent {...{...props, AKText, AKFile, AKLink}}/></div>
    <div className={"d-flex gap-2 align-items-center justify-content-between"}>
        <span className={"semi-bold text-decoration-underline "}>{t("taggings")}:</span>
        {canEditM? <span><CpAsmQtnMetaEdCTA {...metaEditProps} /></span>: ''}
    </div>
    <CpQTagMeta {...{fullQ, showEn, tagMSets, useTagMSets}} />
    </div>
};
export default CpQViewExt;

export const QListMSTs = [ 
_ATMetaType_SU, _ATMetaType_QT, _ATMetaType_DI, _ATMetaType_TB, _ATMetaType_CU, _ATMetaType_GR, _ATMetaType_EX,
_ATMetaType_GE, _ATMetaType_CO, _ATMetaType_ET, _ATMetaType_SK, _ATMetaType_VA, _ATMetaType_LA, _ATMetaType_TA,
_ATMetaType_OT, _ATMetaType_ME,
]; 

export const CpQTagMeta = props => {
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const {fullQ, showEn, tagMSets, useTagMSets} = props;

    const QSMIds = fullQ?.QDisplayMetas;
    const SMs = useMemo(() => toUniIdAry(QSMIds).map(splitSMId), [QSMIds]);
    const MSIds = useMemo(() => toUniIdAry(SMs.map( sm => sm[0])), [SMs]);
    const tagmss = useTagMSets(MSIds);
    const [typeTags, SMId2d] = useMemo(() => mapTMSets(SMs, tagmss), [SMs, tagMSets]); 

    //const [typeTags] =  useMemo(() => touseTagMSets(useTagMSets, toAry(fullQ?.QDisplayMetas).map(splitSMIdPairStr)), [fullQ, tagMSets,]);

    return <>
    <table className={`CpQTagMeta table-fit-column p-3 w-100`}>
    <tbody className={"align-top"}>
        {QListMSTs.map(mst => [mst, typeTags[mst]]).filter(x => x[1]).map(([mst, mss]) => {
            //const tmsets = >useTagMSets(objKeys(mss));
            const msetxs = objEntries(mss).sort(([i,a],[j,b])=>strCmp(i, j)).map(([msid, mids]) => {
                const mms = tagMSets[msid]?.metas;
                if(!mms){
                    console.error({t: mst, msid, mids});
                    return '';
                }
                const sorted = toAry(mids).map(mid => [mid, mms[mid]]).filter(im => (im[1] && im[1].pub))
                    .sort((a, b) => (a[1].rnum - b[1].rnum) || strCmp(a[1].metaId, b[1].metaId))
                    .map(([mid, m]) => <MName key={mid} {...{msid, mid, m, showEn}}/>); 
                return sorted.length? sorted: '';
            }).filter(jsx => jsx);

            return msetxs.length? 
                <tr key={mst} className={"d-inline-block d-md-table-row"}>
                <td className={"d-inline-block d-md-table-cell semi-bold text-dim-400"}>{t2(_ATMetaTransByCode(mst), showEn)} </td>
                <td className={""}><div className={"d-flex flex-wrap gap-2"}>{msetxs}</div></td>
                </tr>:'';
        }).filter(jsx => jsx)
    }</tbody>
    </table>
    </>;
};

const _lt = (en, e, c) => ((en && e)? e: (c || e));
const MName = ({msid, mid, m, showEn, }) => {
    const title = <CpMJX dynamic={true} key={autoId()} >{(toStr(m.LVNum)+' '+toStr(_lt(showEn, m.nameEn, m.nameCt))).trim()}</CpMJX>;
    //return <div className='b1 m2 p2'>{preJS(m,3)}</div>
    return <CpActionButton {...{key: mid, id: (msid+','+mid), title}} 
        className={"CpMName badge-btn badge-card-action-primary semi-bold rounded pe-none"}/>
};
