import { isStr, toAry, toStr } from "../libs/libType";
import { _MaxLenTodo } from "./ATConsts";

export const ansNumPattern = /^[-+$]?[0-9]*\.?[0-9]*[%]?/g;
// common Noremalize ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
//Trimmed , Non Empty, No repeat, String Array
const validIdLen = s => (s && (s.length <= _MaxLenTodo));  
const validId = s => validIdLen(s)?s:'';  
export const toUniIdSet = a => new Set(toAry(a).map(t => toStr(t).trim()).filter(validIdLen)); //ary to ary
export const toUniIdAry = a => Array.from(toUniIdSet(a)); //ary to ary
export const toIdAry = a => Array.from(a.map(t => toStr(t).trim()).filter(validIdLen)); //ary to ary
export const toUniIdsStr = s => Array.from(new Set(toStr(s).split(',').map(t => t.trim()).filter(validIdLen))).join(', '); //Str>Ary>Str
export const ary2UniStr = s => Array.from(new Set(s.map(t => t.trim()).filter(validIdLen))).join(','); //Str>Ary>Str
export const toIdStr = s => validId(toStr(s).trim()); //todo use for all load id exscape invalid chars 
export const str2UniIdAry = s => toUniIdAry(toStr(s).split(','));
export const toUniId2dStrs = s => toUniIdAry(toStr(s).split(';').map(splittrimjoin2)).join(';');


export const splittrimjoin2 = s2 => {
    const ab = toStr(s2).split(',');
    const [a, b] = [ab[0].trim(), toStr(ab[1]).trim()]; 
    return a && b && (a + ',' + b);
};

export const validESASIDStr = i => {
    const is = i.split(',').map(i => i.trim()).filter(i => i);
    const vis = is.filter(i => !validESASID(i));
    
    return (vis.length)? 'Invalid ESAS Series Codes: '+vis.join(', ')
    :(!is.length)? 'Missing ESAS Series Codes'
    :''; 
};
export const validESASID = i => {
    const i2 = i.replace(/[|]/g, '');
    return (i2 === i)?1:0;
};

// common ShortHands ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
export const validFields = (fields, fieldErrs) => ({
    set: (key, err) => { fieldErrs[key] = err; },
    setIf: (key, err) => { if(err) fieldErrs[key] = err; },
    setIfErr: (key, validator) => { setIf(fieldErrs, key, validator(fields[key])); },
    fieldErrs
}); 
export const setIf = (fieldErrs, key, err) => { if(err) fieldErrs[key] = err; };
export const setIfErr = (fields, fieldErrs, key, validator) => { setIf(fieldErrs, key, validator(fields[key])); };

// common validators ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
export const validMinMax = (str, min, max, prefix) => {
    return ((!isStr(str))|| (str.length > max) || (str.length < min))?
        (prefix + ' should be '+min+' to '+max+' characters'):
        '';  
};

export const validIdCharsMinMax = (str, min, max, prefix) => {
    return ((!isStr(str)) || (str.length > max) || (str.length < min) || (!idCharsOnly(str)))?
        (prefix + ' should be '+min+' to '+max+' alpha-numberic or underscore'):
        '';  
};

export const idCharsOnly = s => {
    //var a = 'Test123*** TEST';
    //var b = a.replace(/[^a-z0-9]/gi,'');
    //b = Test123TEST
    const s2 = s.replace(/[a-zA-Z0-9_]/g, '');
    return s2?0:1;    
};