//import { isObj } from "../libs/libType";
import { isAry, toAry, toStr } from "../libs/libType";
import { toUniIdAry } from "./ATValidate";
import { deepCopy } from "../AppExPf/AppUtil";
import { _WST_RESUME, _ARS_CLOSE_SUBMIT } from "./ATConstsAssignment";
import { str2Blob } from "../libs/libType";
import { autoId } from "../AppExPf/AppUtil";
import { localDrawId } from "../AppExPf/utils/useMediaCache";
import { mapDrawMedia } from "./AValidateWork";
//import { addRespDraw } from "../AppExPf/utils/utilQtnAns";
//import { mapTagAtt } from "../libs/libHtml";

export const markMapMedias = (_mark, mMap, addLocalMedias) => {
    const mSrcs = [];
    const drawBlobs = {};
    const mapM = src => { 
        src && mSrcs.push(src); 
        return (src && mMap[src]) || src;
    };
    /*
    const mapCK = ckhtml => {
        const [ckmedias, chhtmlOut] = mapTagAtt(ckhtml, 'oupid', mMap);
        mSrcs.push(...ckmedias);
        return chhtmlOut;
    };
    */
    const mark = deepCopy(_mark);
    if (isAry(mark?.resp) && mark.resp.length > 0) {
        mark.resp = toAry(mark.resp).map((rr, idx) => {
            const respType = rr?.respType;
            if (respType === 'd') { // drawing
                //mapDrawMedia(rr, mapM, mMap, drawBlobs); 
                mapDrawMedia(rr, mapM, drawBlobs); 
/*                    
                if (rr.qrespS3Id === '' ) rr.qrespS3Id = localDrawId(idx); // data Changed Assign a new Id
                if (toStr(rr.qrespS3Id).startsWith('draw_')){ // is Local Draw Id, 
                    if(!drawBlobs[rr.qrespS3Id]){ //always store the newest
                        const bb = str2Blob(rr?.qresp);
                        drawBlobs[rr.qrespS3Id] = {
                            file: bb,
                            size: bb.size,
                            name: rr.qrespS3Id,
                        };
                    }
                }
                rr.qrespS3Id = mapM(rr.qrespS3Id);
                rr.qresp = '';
*/
                _mark.resp[idx] = {...rr}; //Hack...
            }; 
            return rr;
        });
    };
    addLocalMedias(drawBlobs, 1);
    return [mark, toUniIdAry(mSrcs), drawBlobs];
};
