import {ReactNode} from "react";
import i18n from "../translate/i18n";

const t = i18n.t;

interface Point {
    id: string
    title: string;
}

export interface xItemPoint extends Point {
}

export interface DataPoint extends Point {
    referenceId: string
    value: number;
    color: string
}

export interface CoordinateData extends DataPoint {
    x: number,
    y: number,
    points?: number[]
}

export const LineBarType = {
    LINE: "LINE",
    BAR: "BAR"
}
export type LineBarType = typeof LineBarType[keyof typeof LineBarType]

export const LineBarValueType = {
    PERCENTAGE: "PERCENTAGE",
    VALUE: "VALUE"
}
export type LineBarValueType = typeof LineBarValueType[keyof typeof LineBarValueType]

export const ProgressByMonthType = {
    AVERAGE_SCORE: "AVERAGE_SCORE",
    NO_OF_ASSIGNMENT: "NO_OF_ASSIGNMENT"
}

export type ProgressByMonthType = typeof ProgressByMonthType[keyof typeof ProgressByMonthType]

// export const ProgressByYearType = {
//     AVERAGE_SCORE: "AVERAGE_SCORE",
//     NO_OF_ASSIGNMENT: "NO_OF_ASSIGNMENT"
// }

// export type ProgressByYearType = typeof ProgressByYearType[keyof typeof ProgressByYearType]


const Status = {
    p: "p", //pass
    f: "f",//fail
    a: "a",//attempt, no correctness(Open-end), polling, completed
    n: "n",//null
    m: "m",//manual, correctness(Open-end)
    s: "s",//submitted, but no attempted(Open-end)
    e: "e",//empty
    z: "z"//zero, for not attempted but must show data
}
export type Status = typeof Status[keyof typeof Status]


interface Ratio {
    "value": number,
    "total": number
}

interface BaseData {
    referenceId?: string,
    student?: StudentData[]
}


interface BaseQuestion {
    id: string,
    no: number
}

interface Question extends BaseQuestion {
    marks: number,
    correctness: number,
    numCorrectly?: {
        value: number
    },
    averageScore?: {
        value: number,
        percentage: number
    }
}


// ==== General type in Chart ==== //
export interface StudentData {
    id: string,
    value?: number,
    status: Status
}

export interface Student {
    id: string,
    no: string
    name: string
    submitted: boolean
    score?: {
        value: number,
        total: number,
        percentage?: number,
        status: Status
    }
}

export interface Marks {
    value?: number,
    percentage?: number
    total?: number
}

export interface Range {
    min: number
    minPercentage: number
    max: number
    maxPercentage: number
    total: number
}

export interface DistributionRange {
    value?: number
    minPercentage?: number
    maxPercentage: number
    status: Status
}

// title type
// "text"|"uploadfile"|"image"|"drawing"|"hyperlink" | "label"
// "A" ....
export interface Option {
    id: string,
    groupId?: string
    title: string,
    marks?: Marks,
    failMarks?: Marks
    status: Status,
    student: StudentData[]
}

export interface QuestionPerformanceTypeData {
    id: string,
    questionType: "multiple-choice" | "fill-in-the-blanks" | "label-the-diagram" | "open-ended" | "polling",
    matchTable?: "single" | "multiple"
    markingType?: "auto-marked" | "manual-marked",
    averageScore?: Marks
    correctness?: Marks
    options?: Option[]
    systemOptionTitle?: boolean, // true = translate, false = user title
    showOptionFail?: boolean,
}

export interface Data extends BaseData {
    numCorrectly?: Ratio,
    isCorrectness: boolean,
    correctness: number,
    averageScore: number,
}

export interface DataFollowUpTeacher extends BaseData {
    numAssigned?: Ratio,
    numMastered?: Ratio,
    averageOriginal?: number,
    averageFollowUp?: number,
}

export interface DataFollowUpStudent extends BaseData {
    numAttempted?: number,
    averageScore?: number,
    averageFollowUp: {
        scoreRange: DistributionRange[]
        passingScore: Marks
    },
}

export interface Metadata {
    id: string,
    title: string,
    question: {
        total: number
        list?: Question[]
    },
    children?: Metadata[]
}

export interface MetadataFollowUp {
    id: string,
    title: string,
    preview?: boolean,
    studentStatus?: "start" | "retry" | "view",
    followUpExercise?: FollowUpExerciseStatus[];
    children?: MetadataFollowUp[]
}

export type FollowUpExerciseStatus = "complete" | "uncompleted" | "same" | "pre" | "pro";

const ProgressType = {
    a: "a", //All Classes
    sc: "sc",//Selected Class
    s: "s",//Student
}

export type ProgressType = typeof ProgressType[keyof typeof ProgressType]

export interface MetadataRadial {
    id: string,
    title?: string
    score: Marks
}

export interface MetadataProgress {
    id: string,
    titleIndex?: string,
    title?: string,
    answered?: number,
    scoreRange?: DistributionRange
    averageScore: Marks,
    classAverageScore?: Marks
    referenceId?: string
    children?: MetadataProgress[], //metadata sub node
    subAverageScore?: MetadataProgress[] //4A, 4B, 01 Eric, etc....
}

export interface MetadataProgressItemType {
    id: string,
    title: string
    no?: string
}

export interface PerformanceOverviewData {
    averageScore?: Marks
    submitted?: Marks
    manualMarked?: Marks
    passingRate?: Marks
    passingScore?: Marks
    scoreRange?: Range
}

// ==== Chart ==== //
export interface DonutType {
    line?: {
        color: string
        value: number
    }[],
    title?: {
        color: string
        value: ReactNode | string | number
    },
    subTitle?: {
        color: string
        value: ReactNode | string | number
    }
    segments?: {
        color: string
        value: number
    }[],
    setting?: {
        ringColor?: string
    }
}

export interface SingleRangeChartType {
    line?: {
        color: string,
        value: number
    }[]
    range?: Range
}

export interface QuestionCorrectnessAndScoreChartType {
    data?: {
        id: string,
        no: number,
        marks: Marks,
        status: Status
    }[]
}

export interface PerformanceOverviewType {
    student: Student[]
    overview: PerformanceOverviewData
}

export interface QuestionPerformanceType {
    student: Student[]
    question: Question[]
    data: QuestionPerformanceTypeData[]
}

export interface PerformanceDetailChartType {
    student: Student[]
    question: Question[]
    data?: Data[]
}

export interface PerformanceMetadataSetsChartType {
    student?: Student[]
    metadata: Metadata[]
    data?: Data[]
}

export interface FollowUpExerciseChartTypeTeacher {
    student?: Student[]
    metadata: MetadataFollowUp[]
    data?: DataFollowUpTeacher[]
}

export interface FollowUpExerciseChartTypeStudent {
    student?: Student[]
    metadata: MetadataFollowUp[]
    data?: DataFollowUpStudent[]
}

export interface SubjectOverviewChartType {
    teacher?: {
        overallScore: Marks,
        data: MetadataRadial[]
    }
    student?: {
        id: string,
        title: string
        attempted: {
            record: Marks,
            status: Status
        }[],
        overallScore: Marks,
        averageScore: Marks
    }
}

export interface ProgressByMonthChartType {
    items?: {
        id: string,
        title: string
    }[],
    type: ProgressType,
    data: {
        referenceId?: string
        month: string,
        progress?: Marks //Teacher Progress Report: (Bar; Assignment=>All Classes/Selected Class)
        averageScore: Marks //Teacher / Student Progress Report: (Line; Average=>All Classes/Selected Class/Selected Student)
        overview?: { //Student Progress Report: (Bar; Assignment=>Selected Student)
            status: Status
            value: number
        }[]
    }[]
}

// export interface ProgressByYearChartType {
//     items?: {
//         id: string,
//         title: string
//     }[],
//     type: ProgressType,
//     data: {
//         referenceId?: string
//         year: string,
//         progress?: Marks //Teacher Progress Report: (Bar; Assignment=>All Classes/Selected Class)
//         averageScore: Marks //Teacher / Student Progress Report: (Line; Average=>All Classes/Selected Class/Selected Student)
//         overview?: { //Student Progress Report: (Bar; Assignment=>Selected Student)
//             status: Status
//             value: number
//         }[]
//     }[]
// }

export interface AssignmentProgressChartType {
    type: ProgressType
    data: {
        id: string
        language: "en" | "zh"
        title: string
        assignedBy: {
            id: string,
            name: string
        }
        className?: string,
        resultPublish: number
        submitted?: {
            value: number
            total: number
        }
        scoreRange: DistributionRange[]
        averageScore: Marks
        passingScore?: Marks
    }[]
}

export interface ClassPerformanceDetailsChartType {
    student: Student[],
    data: {
        referenceId: string,
        assignment: {
            score: Marks,
            status: Status
        }[],
        scoreRange: DistributionRange
        averageScore: Marks
        classAverageScore: Marks
    }[]
}

export interface MetadataSetLevel {
    id: string
    title: string
    overallScore: Marks
    child: MetadataRadial[]
}

export interface MetadataRadialGroupChartType {
    data: MetadataSetLevel[]
}

export interface MetadataProgressChartType {
    items?: MetadataProgressItemType[],
    id: string,
    titleIndex?: string,
    title: string,
    type: ProgressType,
    data?: MetadataProgress[]
}

// Whole Key Stage Chart

export const SCHOOL_YEARS = {
    1: '2021-22',
    2: '2022-23',
    3: '2023-24',
} as const;

export type SchoolYearKey = keyof typeof SCHOOL_YEARS;

export interface YearlyScore {
    year: SchoolYearKey;
    scorePercentage: number;
}

export const getColorForYear = (year: SchoolYearKey): string => {
    return YEAR_COLORS[year] || '#000000';
};

export interface WholeKeyStageMetadataProgress {
    id: string;
    titleIndex?: string,
    title?: string;
    averageScore?: YearlyScore[];
    classAverages?: YearlyScore[];
    subAverageScore?: WholeKeyStageMetadataProgress[];
    children?: WholeKeyStageMetadataProgress[];
    referenceId?: string;
}

export interface WholeKeyStageMetadataProgressChartType {
    items?: MetadataProgressItemType[];
    titleIndex?: string,
    id: string;
    title: string;
    type: ProgressType;
    data?: WholeKeyStageMetadataProgress[];
    studentInfo?: MetadataProgressItemType;
}

// ==== Color ==== //
export const DONUT_DEFAULT_SEGMENT_COLOR = "#4b97e5"
export const DONUT_NO_ATTEMTED_COLOR = "#909090"
export const DONUT_ATTEMPTED_COLOR = "#1655BB"
export const DONUT_INCORRECT_COLOR = "#ee6161"
export const DONUT_CORRECT_COLOR = "#4EAE84"
export const DONUT_TEXT_COLOR = "#767676"
export const DONUT_TEXT_DIM_COLOR = "#EBEBEB"
export const DONUT_RING_COLOR = "#EBEBEB"
export const DONUT_AVG_LINE_COLOR = "#006fbb"
export const DONUT_PASSING_LINE_COLOR = "#ffa7a7"
export const DONUT_BODY_COLOR = "#000"

const CHART_COLORS = [
    "#64a3a3",
    "#fd7f6f",
    "#ffb55a",
    "#bd7ebe",
    "#b2e061",
    // "#ffee65",
    "#72a8d3",
    "#beb9db",
    "#be8585",
    "#c5c95d",
    "#e785b7",
    "#8bd3c7",
    "#ff8f42",
    "#b9ccdb",
    "#fdcce5",
    "#ffeb7f",
    "#cc8ac0",
    // "#9c8ec3",
    // "#f4a4c3",
    // "#7ac5cd",
    // "#d8e661",
];

export const getChartColor = (index: number, loop = true): string => {
    if (index < 0) {
        return CHART_COLORS[0]
    }
    if (loop) {
        return CHART_COLORS[index % CHART_COLORS.length];
    } else {
        if (index < CHART_COLORS.length) {
            return CHART_COLORS[index];
        } else {
            return CHART_COLORS[CHART_COLORS.length - 1];
        }
    }
};

export const YEAR_COLORS = {
    1: CHART_COLORS[2], // '#ffb55a' (e.g. 2021-22)
    2: CHART_COLORS[1], // '#fd7f6f' (e.g. 2022-23)
    3: CHART_COLORS[0], // '#64a3a3' (e.g. 2023-24)
} as const;

export const getProgressStatusColor = (status: Status) => {
    switch (status) {
        case "p":
            return DONUT_CORRECT_COLOR
        case "f":
            return DONUT_INCORRECT_COLOR
        case "a":
            return DONUT_ATTEMPTED_COLOR
        case "s": // not exist in this case
        case "m": // not exist in this case
        case "n":
        default:
            return DONUT_NO_ATTEMTED_COLOR
    }
}

// === Radial Chart ====//
export const MAX_RADIAL_RING = 6

// === Line/Bar Chart ====//
export const schoolYearByMonth = () => {
    return [
        {
            id: "month-009",
            title: t("schema:calendar.month.9"),
            value: "9",
        },
        {
            id: "month-010",
            title: t("schema:calendar.month.10"),
            value: "10",
        },
        {
            id: "month-011",
            title: t("schema:calendar.month.11"),
            value: "11",
        },
        {
            id: "month-012",
            title: t("schema:calendar.month.12"),
            value: "12",
        },
        {
            id: "month-001",
            title: t("schema:calendar.month.1"),
            value: "1",
        },
        {
            id: "month-002",
            title: t("schema:calendar.month.2"),
            value: "2",
        },
        {
            id: "month-003",
            title: t("schema:calendar.month.3"),
            value: "3",
        },
        {
            id: "month-004",
            title: t("schema:calendar.month.4"),
            value: "4",
        },
        {
            id: "month-005",
            title: t("schema:calendar.month.5"),
            value: "5",
        },
        {
            id: "month-006",
            title: t("schema:calendar.month.6"),
            value: "6",
        },
        {
            id: "month-007",
            title: t("schema:calendar.month.7"),
            value: "7",
        },
        {
            id: "month-008",
            title: t("schema:calendar.month.8"),
            value: "8",
        }
    ]
}
export const getSchoolMonthIdByValue = (value: string = "") => {
    return schoolYearByMonth().find(month => month.value === value)?.id || ""
}

// === Line/Bar Chart ====//
// export const schoolYearByYear = () => {
//     return [
//         {
//             id: "year-001",
//             title: "2021-2022",
//             value: "2021-2022",
//         },
//         {
//             id: "year-002",
//             title: "2022-2023",
//             value: "2022-2023",
//         },
//         {
//             id: "year-003",
//             title: "2023-2024",
//             value: "2023-2024",
//         }
//     ]
// }
// export const getSchoolYearIdByValue = (value: string = "") => {
//     return schoolYearByMonth().find(month => month.value === value)?.id || ""
// }


export const getValueRange = (props: { minValue?: number, maxValue: number, stepValue: number }) => {
    const {minValue = 0, maxValue, stepValue} = props
    const array = [];
    let currentValue = minValue;

    while (currentValue <= maxValue) {
        array.push(currentValue);
        currentValue += stepValue;
    }

    return array.reverse();
}

export const getMaxValue = (data: DataPoint[], indicatorNum: number = 10, percentage: boolean = true) => {
    let maxValue = 100
    let stepValue = 10

    if (percentage) {
        const percentageMaxValue = 100
        const percentageStep = 10
        maxValue = percentageMaxValue
        stepValue = percentageStep
    } else {
        if (data.length) {

            const groups: { [id: string]: number } = {};
            data.forEach((item) => {
                const groupId = item.referenceId;
                const value = item.value;

                if (groups[groupId] === undefined) {
                    groups[groupId] = 0;
                }

                groups[groupId] += value;
            });

            let highestGroup = '';
            let highestSum = -Infinity;

            for (const groupId in groups) {
                const groupSum = groups[groupId];

                if (groupSum > highestSum) {
                    highestGroup = groupId;
                    highestSum = groupSum;
                }
            }

            // if there is no enough range(baseStepValue) or the too small need to add more buffer indicators
            stepValue = Math.ceil(highestSum / indicatorNum)


            maxValue = stepValue * indicatorNum
        }
    }

    return {maxValue, stepValue, valueRange: getValueRange({maxValue, stepValue})}
    // return { groupId: highestGroup, sum: highestSum };
}

// ==== Sorting ==== //
export const SORT_ASC = "SORT_ASC"
export const SORT_DESC = "SORT_DESC"