import { Fragment, useEffect, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useCtnLang, useUILang } from "../../AppExPf/utils/useUILang";
import { aryMap, objEntries, objKeys, objMapObj, objVals, strCmp, toAry, toInt, toObj, toStr } from "../../libs/libType";
import { ReduxBind } from "../../saga/ReduxState";
import CpDropdown, { ary2DropdownItem, obj2DropdownItem } from "../_components/CpDropdown";

import { useTickAll } from "../../AppExPf/ATQtnEdit/PageATQtns";
import { QP_P, QP_PP } from "../../consts/ATConsts";
import { stopEvent, EpInputTxt0 } from "../../libs/libUI";
import Checkbox from "../../poc/screens/pages/included/Checkbox";
import { _ATMetaTypeByCode, __ATMetaTypes } from "../../consts/ATMetaTypes";
import CpEpTypeMSetTrees from "../_components/CpEpTypeMSetTrees";
import DatePicker from "../../poc/custom-component/DatePicker";
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpAccordionItem from "../_components/CpAccordionItem";
import CpCheckbox from "../_components/CpCheckbox";
import { _EPSECT_school } from "../AppRouteEP";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { preJS } from "../../AppExPf/AppUtil";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { strCmpTrimLow } from "../EPAssign/TabTeacherAssignmentStatus";
import { __SYSQType_FIB, __SYSQType_LBD, __SYSQType_MCQ, __SYSQType_OPE, __SYSQType_POL } from "../../consts/ATSysQType";

const LayerExerFilter = ReduxBind(props =>  { // base on poc <ExerciseTabletListFilter>
    const { dispatch, sect, ESharerIds, defSort, ESTagMSets, EShareIds, useFs} = props;
    const myUId = props.userId();// 'teacher'; //searchParams.get("role") || "teacher"

    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs] = useFs; 
    const [metas, setMeta] = [toAry(fields.metas), setTickAry('metas')];
    const [opens, setOpen] = useTickAll([]);

    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(UILang);

    const [ cache2d, useGetCache ] = useCaches(props);
    const thrNames = useGetCache('teachers', '*'); 

    const s = fields?.sort;
    const sortItems = useMemo(() => getSortItems(defSort, t), [defSort, uiEn]); 
    const sort = sortItems[s]? s: objKeys(sortItems)[0]; 
    useEffect(() => { if(s !== sort) setField('sort')(sort); }, [sort, s]); 

    const sectSchool = (sect === _EPSECT_school);  
    const [eShares, setEShare] = [fields.eShares, setTickAry('eShares')];
    const clickShare = (id, on) => e => { stopEvent(e); setEShare(id)(on); }; 
 
    const sharerEnts = useMemo(() => toAry(ESharerIds)
        .filter(i => (i !== myUId))
        .map(i => [i, tname(ut, i, thrNames[i])])
        .sort(([i,n], [j,m]) => strCmpTrimLow(n, m))
        , [thrNames, EShareIds]);
    //const sharerEnts = [['1', 'aaa'], ['2', 'bbb'], , ['3', 'ccc']];

    const [activeKey, setActiveKey] = useState(0)
    const handleActive = id => setActiveKey(prev => (prev === id) ? "" : id);
    const act0 = (activeKey === 0);
    const act1 = (activeKey === 1);
    const act = (activeKey !== "");
    const inpProps = {fields, setField, setTick};
    const {findEx} = props; 
    const type = findEx? 'searchExercises': 'searchQuestion';

    const _eShares = toAry(eShares);
    const shareItem = (id, name, lone) => {
        const on = _eShares.includes(id);
        return <div key={id} onClick={clickShare(id, !on)} 
            className={`d-flex justify-content-start overflow-auto text-wrap ms-3 my-2 gap-2 ${lone? "border-bottom pb-2" : ""}`} >
            <Checkbox readOnly={true} checked={on}/>
            <div role={"button"} className={`d-flex gap-1 user-select-none checkbox-exercise-third-btn`}>{name}</div>
        </div>;
    };
    return <div className={`exercise-filter d-flex flex-column w-100 h-100 ${act? "justify-content-between": ""}`}>
        <span role={"button"} onClick={() => handleActive(0)}
            className={`${findEx? "bg-exercise-third-btn text-light": "bg-exercise-third-btn-hover-light text-exercise-third-btn"
            } ${type} w-100 py-1 px-3 d-flex semi-bold fs-5 accordion-button ${act0? "collapse": "collapsed"}`}
            >{t("filters")}</span>
        <div className={`h-100 pt-2 overflow-auto  ${act0? "flex-grow-1": "d-none"}`}>
            <Accordion defaultActiveKey={['0']} alwaysOpen className={`exercise-subfilter d-flex flex-column gap-2`}>
            {sectSchool? <>
                <CpAccordionItem id={"c0"} title={t("shared-by")} headerSize={"fs-6"}>
                    {shareItem(myUId, t('used-by-me'), sharerEnts.length)}
                    {sharerEnts.map(([id, name]) => shareItem(id, name, 0))}
                </CpAccordionItem>
                <div className={"mx-2 split-line"}></div>
            </>: ''}
                <CpEpTypeMSetTrees {...{FilterMSets:ESTagMSets, ct, opens, setOpen, metas, setMeta}} /> 
                <CpAccordionItem id={"c1"} title={t("mark-range")} headerSize={"fs-6"}><CpRangeFilter {...inpProps} key1='m1' key2='m2' /></CpAccordionItem>
                <div className={"mx-2 split-line"}></div>
                <CpAccordionItem id={"c2"} title={t("no-of-question")} headerSize={"fs-6"}><CpRangeFilter {...inpProps} key1='q1' key2='q2' /></CpAccordionItem>
                <div className={"mx-2 split-line"}></div>
                <CpAccordionItem id={"c3"} title={t("exercise-title")} headerSize={"fs-6"}><CpExerciseTitleFilter {...inpProps} id='ename' /></CpAccordionItem>
                <div className={"mx-2 split-line"}></div>
                <CpAccordionItem id={"c4"} title={t("question-keywords")} headerSize={"fs-6"}><CpQuestionKeywordsFilter {...inpProps} /></CpAccordionItem>
                <div className={"mx-2 split-line"}></div>
                <CpAccordionItem id={"c5"} title={t("marking-types")} headerSize={"fs-6"}><CpMarkingTypesFilter {...inpProps} /></CpAccordionItem>
                <div className={"mx-2 split-line"}></div>
                <CpAccordionItem id={"c6"} title={t("usage")} headerSize={"fs-6"}>
                    <div className={"d-flex justify-content-start overflow-auto text-wrap ms-3 my-2 gap-1"}><CpUsageFilter {...inpProps}/></div>
                </CpAccordionItem>
            </Accordion></div>
        {findEx? <>
        <span role={"button"} onClick={() => handleActive(1)} className={`${findEx? "searchExercises": ""
            } bg-exercise-third-btn w-100 py-1 px-3 d-flex text-light semi-bold fs-5 accordion-button ${act1? "collapse": "collapsed"}`}>
            {t("sorting")}</span>
            <div className={`overflow-auto  ${act1? "flex-grow-1": "d-none"}`}>
                <div className={`d-flex flex-column`}><div className={"p-3"}>
                    <span className={"semi-bold"}>{t("by")}:</span>
                    <CpDropdown className="w100" {...{items:sortItems, idx:sort, cb:setField('sort')}} />
                </div></div>
            </div>
        </>:''}
    </div>
});
export default LayerExerFilter;




export const CpUsageFilter = (props => {
    const [t, isUIEn, UILang, setUILang] = useUILang();
    return <div className={"d-flex flex-column w-100 overflow-hidden gap-3 p-3 semi-bold fs-7"}>
{/*        
        <CpShareCheckboxItem {...{...props, id:'exclude', title:t("exclude")}} />
        <div className={"ms-4 d-flex flex-column gap-3"}>
            <CpShareCheckboxItem {...{...props, id:'useBy', title:`${t("used-by")}:`}} />
            <div className={"ms-4 d-flex flex-row gap-5"}>
                <CpShareCheckboxItem {...{...props, id:'useMe', title:t("used-by-me")}} />
                <CpShareCheckboxItem {...{...props, id:'useSchool', title:t("used-by-my-school")}} />
            </div>
        </div>
        <div className={"ms-4 d-flex flex-column gap-3"}>
            <CpShareCheckboxItem {...{...props, id:'userange', title:`${t("used-within")}:`}}/>
            <div className={"ms-4 d-flex flex-column gap-3"}>
                <span>{t("from")}
                    <DatePicker title={t("datepicker-select-a-day")} iconPath={IconList.general.calendar} enableTime={false} sticky={true}/>
                </span>
                <span>{t("to")}
                    <DatePicker title={t("datepicker-select-a-day")} iconPath={IconList.general.calendar} enableTime={false} sticky={true}/>
                </span>
            </div>
        </div>
        <div className={"ms-4 d-flex flex-column gap-3"}>
            <CpShareCheckboxItem {...{...props, id:'usecount', title:t("used-times")+':'}} />
            <div className={"ms-4 d-flex flex-row gap-3 justify-content-between"}>
                <CpRangeFilter {...props} key1='use1' key2='use2' />

                <input type={"number"} className={"w-100 border"}/>
                <span>-</span>
                <input type={"number"} className={"w-100 border"}/>

            </div>
        </div>
*/}        
        <CpShareCheckboxItem {...{...props, id:'like', title:t("only-show-liked-by-me")}} />
    </div>
});

export const inpputNum0 = (val, setter, id, className='', style={}, extraProps={}) => {
    return (<input {...{...extraProps}} name={id} id={id} className={className} style={style} type="number"
    onChange={(e) => {
        let tmp = toInt(e.currentTarget.value);
        if (tmp < 0) tmp = 0;
        setter(tmp);
    }} value={toStr(val)} />);
};

export const CpRangeFilter = (props) => { //base on poc MarkingRangeFilter, NoOfQuestionFilter 
    const {fields, setField, key1, key2} = props;
    return <div className={"d-flex justify-content-between w-100 overflow-hidden gap-2 p-3"}>
        {inpputNum0(fields[key1], setField(key1), 'im1','w-100 border', {})}
        <span>-</span>
        {inpputNum0(fields[key2], setField(key2), 'im1','w-100 border', {})}
    </div>
}

export const CpQuestionKeywordsFilter = (props => {
    const {fields, setField} = props;
    return <div className={"d-flex justify-content-between w-100 overflow-hidden gap-2 p-3"}>
        {EpInputTxt0(fields?.QKw, setField('QKw'), 'tqkw', 'border w-100 p-2' )}
    </div>
});

const CpQTCheckbox = (props => { //base on pc ShareCheckboxItem  
    const {title, id, setTick ,fields, SQTypes, setSQTypes} = props;
    const on = toAry(SQTypes).includes(id);
    const onClick = e => setSQTypes(id)(e.currentTarget.checked);

    return <div role={"button"} className={"d-flex gap-2"}>
        <Checkbox checked={on} onChange={onClick}/><span onClick={onClick} >{title}</span>
    </div>;
});

export const CpSQTypesFilter = (props => { //base on poc MarkingTypesFilter  
    const [t] = useUILang();
    return <div className={"d-flex flex-column w-100 overflow-hidden gap-2 p-3 fs-7"}>
        <CpQTCheckbox title={t("at-setting.question-type-menu.mcq")} {...props} id={__SYSQType_MCQ}/>
        <CpQTCheckbox title={t("at-setting.question-type-menu.fib")} {...props} id={__SYSQType_FIB}/>
        <CpQTCheckbox title={t("at-setting.question-type-menu.label-the-diagram")} {...props} id={__SYSQType_LBD}/>
        <CpQTCheckbox title={t("at-setting.question-type-menu.open-ended")} {...props} id={__SYSQType_OPE}/>
        <CpQTCheckbox title={t("at-setting.question-type-menu.polling")} {...props} id={__SYSQType_POL}/>
    </div>
});

const CpShareCheckboxItem = (props => { //base on pc ShareCheckboxItem  
    const {title, id, setTick ,fields} = props;
    const on = fields[id];
    const handleCheck = e => setTick(id)(e.currentTarget.checked);

    return <div role={"button"} className={"d-flex gap-2"}>
        <Checkbox checked={on} onChange={handleCheck}/>
        <span onClick={e => { stopEvent(e); setTick(id)(!on); }}>{title}</span>
    </div>;
});

export const CpMarkingTypesFilter = (props => { //base on poc MarkingTypesFilter  
    const [t] = useUILang();
    return <div className={"d-flex flex-column w-100 overflow-hidden gap-2 p-3 fs-7"}>
        <CpShareCheckboxItem title={t("marking-types-options-with-auto")} {...props} id={'onlyA'}/>
        <CpShareCheckboxItem title={t("marking-types-options-with-manual")} {...props} id={'onlyM'}/>
        <CpShareCheckboxItem title={t("marking-types-options-with-auto-manual")} {...props} id={'only2'}/>
    </div>
});

export const CpExerciseTitleFilter = (props => { //base on poc ExerciseTitleFilter
    const {id, fields, setField} = props;
    return <div className={"d-flex justify-content-between w-100 overflow-hidden gap-2 p-3"}>
        {EpInputTxt0(fields[id], setField(id), 'i'+id, 'border w-100 p-2', {}, '')}
    </div>
    //return <div className={"d-flex justify-content-between w-100 overflow-hidden gap-2 p-3"}><div className={"border w-100 p-2"} contentEditable={true}></div></div>
});

const trimMeta = _m => {
    const { addLogin, modUid, dateMod, modLogin, addUid, dateAdd, dp, drpu, ...m} = toObj(_m);
    return m;
};

export const trimMSet = _ms => {
    const { addLogin, modUid, dateMod, modLogin, addUid, dateAdd, dp, drpu, MSVerId, MSVer, 
        tree2, heads,
        MSNameEn, MSNameCt, MSLangEn, MSLangCt, ...ms} = toObj(_ms);
    if(MSLangEn) ms.MSNameEn = MSNameEn || '(unnamed)';
    if(MSLangCt) ms.MSNameCt = MSNameCt || '(unnamed)';
    if((drpu !== QP_PP) && (dp !== QP_P)){ ms.pubErr = (dp + '_' + drpu); }
 
    //const heads = aryMap(ms.heads, trimMSetHead);
    const metas = objMapObj(_ms.metas, ([mid, _m]) => [mid, trimMeta(_m)]);
    return {...ms, metas, };
};
const sortMeta = (([a, x], [b, y]) => ((toInt(x.rnum) - toInt(y.rnum)) || strCmp(a, b)));
export const sortBranchs = metas => {
    const mRoot = [], MBranchs = {};

    const sortms = objEntries(metas).sort(sortMeta); 
    sortms.forEach(([mid, m]) => {
        if(m.pMeta){
            MBranchs[m.pMeta] = [...toAry(MBranchs[m.pMeta]), mid];
        }else{
            mRoot.push(mid);
        }
    });
    return [mRoot, MBranchs];
};
const walkSetPMeta = (tree, myId, metas) => {
    objEntries(tree).map(([cid, ccids]) => {
        if(metas[cid])
            metas[cid].pMeta = myId;
        walkSetPMeta(ccids, cid, metas);
    });
};
const buildTagMeta = _ms => {
    walkSetPMeta(_ms.tree2, '', toObj(_ms.metas));
    const [mRoot, MBranchs] = sortBranchs(_ms.metas);
    return {...trimMSet(_ms), mRoot, MBranchs};
};

export const buildTagMetaEnts = tagMetas => objEntries(tagMetas).map(([msid, ms]) => [msid, buildTagMeta(ms)]);
export const buildTagMetas = tagMetas => Object.fromEntries(buildTagMetaEnts(tagMetas)); 

export const MSetGroupType = (MSetEnts) => {
    const tms = {};
    toAry(MSetEnts).forEach( ([msid, ms]) => { 
        if(!(toAry(ms.mRoot).length)) return;
        const mt = ms.MSType;
        tms[mt] = {...tms[mt]||{}, [msid]:ms}; 
    } );
    return tms;
};

const getSortItems = (defSort, t) => {
    const sorts = {
        new: t("sorting-options-new"), old: t("sorting-options-old"),
        a: t("sorting-options-stroke-up"),  z: t("sorting-options-stroke-down"),
        mmore: t("sorting-options-marks-up"), mless: t("sorting-options-marks-down"),
        qmore: t("sorting-options-question-up"), qless: t("sorting-options-question-down"),
        like: t("sorting-options-favourite")
    };
    return obj2DropdownItem( defSort? { def: t("default-sequence"), ...sorts }: sorts );
};

const tname = (ut, tid, thr) => (thr && ut(thr.nameEng, thr.nameChi)) || tid;  
