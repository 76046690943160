
import { put, call, select, /*, all, takeLeading, takeEvery, takeLatest */ } from 'redux-saga/effects'
import { Semaphore } from "await-semaphore";

import { HttpPost } from "../libs/libHttp";
import { toAry, toInt, toStr } from '../libs/libType';
import { auth_login_state } from './auth.saga';

import * as mw from '../libs/libMidWare';
import libSessionId from '../libs/libSessionId';
import { gst_merge } from './globalState.saga';

const apiURL = process.env.REACT_APP_GATEWAY_URL + '/api';
export function* apiSaga(path, callback, formObj, formFile, cbWIP) {
    var res = undefined;
    var err = undefined;
    try {
        const apiPath = apiURL+path;
        //lj({apiSaga:{apiPath, formObj}})

        const mwUser = yield call(mw.getCurrentUser);
        
        //const t3 = (toStr(mwUser?.id_token)+'...').split('.');
        //const id_token = t3[0] + '.' + t3[1] + '.' + t3[2]; 
        
        const id_token = toStr(mwUser?.id_token);
        const access_token = toStr(mwUser?.access_token);
        //lj({apiSaga_muUser:mwUser});
        
        const rng = 'rng_'+ toInt(Math.random()*999999);

        const device = yield select(state => state?.gst?.device);
        const sessionId = libSessionId.load();

        const extraHeaders = sessionId?{Authorization: 'Bearer '+sessionId}:{};

        //yield awaitFetchSlot(); //wait outside Semaphore, to allow cancel
        const fetched = yield awaitFetchAPI(apiPath, 
            reorder({id_token, access_token, ...formObj, act:path, rng, sessionId, device}), 
            formFile, cbWIP, extraHeaders);
        //lj({apiSaga:fetched});
        res = fetched.res && fetched.res.data;
        err = fetched.err;
        
        //lj({apiSaga_err: err});
        //lj({apiSaga_res: res});

        if(err){
            yield put({type:'SETMAJORERROR', error:err}); //bug dead loop when api call err!!!!!
        } 
        //lj({apiSaga_data:data});
        const auth = res?.auth;
        yield put(auth_login_state(auth, res));
        const gsts = res?.gsts;
        if(gsts) yield put(gst_merge(gsts));

        //lj({apiSaga_callback: callback});
        
        const errs = toAry(res?.errs);
        if(errs.length){
            //alert('API Error:'+errs.join('\n'));
            console.error("apiSaga", errs);
        } 
        
        if(callback)
            yield call(callback, res, err);
    } catch(_error) {
        console.error(_error);
        err = _error;
    }
    return {res, err}; //return response/Error to API Caller
};
// ==== Saga Supporting Services ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====  
const sem = new Semaphore(1);
/*
const awaitFetchSlot = async () => {
    try{
        //lj('awaitFetchSlot 1 web pack 5 bug 1');
        const p = process;
        //lj('awaitFetchSlot 1 web pack 5 bug 2');
        const release = await sem.acquire(); // ensure serialized api call 
        //lj('awaitFetchSlot 1 web pack 5 bug 3');
        release();
    }catch(e){
        console.error(e);
    }
};
*/
const awaitFetchAPI = async (apiPath, formObj, formFile, cbWIP) => {
    //lj('awaitFetchAPI 1:'+apiPath);
    var res = undefined;
    var err = undefined;
    const release = await sem.acquire(); // ensure serialized api call 
    try{
        //lj('awaitFetchAPI 2'+apiPath);
        //await new Promise( r => { setTimeout(r, 5000) } );//Delay all api call for debug (simulate Slow network)
        res =  await HttpPost(apiPath, false, formObj, formFile, cbWIP, {});
        //lj('fetched '+apiPath);
        //lj('fetched res',res);
    }catch(e){
        err = e;
    }finally{
        release();
    }
    return {res, err};
};

const reorder = (f) => {
    const { id_token, access_token, act, rng, sessionId, ...remain } = f;
    return { ...remain, sessionId, act, rng, id_token, access_token};
};
  
  