import React, { useMemo } from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { strCmpTrimLow, useSortList } from "../EPAssign/TabTeacherAssignmentStatus";
import { preJS } from "../../AppExPf/AppUtil";
import { exerQIds } from "../../consts/ATValidateExer";
import { _WST_SUBMIT, _MST_MARKED,
    _QST_SUB_WRONG, _QST_SUB_CORR, _QST_SUB_ATT, _QST_SUB_NOTMARK, _QST_SUB_NOTATT } from "../../consts/ATConstsAssignment";
import { __SYSQSubType_POL, __SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_OED } from "../../consts/ATSysQType";
import { objEntries, toAry, toInt, toObj, toStr } from "../../libs/libType";
import { toIdStr, toUniIdAry } from "../../consts/ATValidate";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";
//import QuestionBox from "../../poc/screens/shared/includes/chart/shared/QuestionBox";
import CpQuestionBox from "./CpQuestionBox";
import CpValueStatusBox from "./CpValueStatusBox";
import { BtnPopDev } from "../../AppExPf/AppUtil";
import { exerHasMark } from "../EPAssign/Tags/TagMarkingTag";
import { toRptFix } from "../EPReports/PageReports";
import { asmPassMark } from "../EPReports/data/dataUtil";
import { qHasCorrect } from "../../consts/ATValidateQ";

const TabPerformanceDetailChart = (props => { //PerformanceDetailChart
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {asm, Exer, qtns, works, marks} = props;

    const students = toObj(props.students);

    const [ QSorts, setQSorts, addQSort, flipQSort] = useSortList({"qnum":"asc"});
    const [ USorts, setUSorts, addUSort, flipUSort] = useSortList({"clsno":"asc"});

    const _stuStats = useMemo(() => { return statStudent(asm, Exer, qtns, students, works, marks ) }, [props.students]);
    const stuStats = useMemo(() => sortS(_stuStats, USorts), [_stuStats, USorts]);

    const _clsQStats = useMemo(() => { return statQtn(Exer, qtns, _stuStats ) }, [qtns, _stuStats]);
    const clsQStats = useMemo(() => sortQ(_clsQStats, QSorts), [_clsQStats, QSorts]);
    
    //const {student, question, data} = PerformanceDetailData;
    return <>
    <div className='flexRowStart'>
{preJS(QSorts)}
{preJS(USorts)}
        <BtnPopDev txt='qStats'>{preJS({qStats: clsQStats}, 3)}</BtnPopDev>
        <BtnPopDev txt='sStats'>{preJS({sStats: stuStats}, 3)}</BtnPopDev>
    </div>
    <table className={"report performance-detail-chart "}>
        <thead>
            {TR1('RoAp', QSorts, flipQSort, t("report-correctness-attempted-percentage"),
                clsQStats.map(q =><td key={q.qnum} className={"data-field semi-bold"}>{disV(q.RoAp)}</td>))}
            {TR1('RoA', QSorts, flipQSort, <>{t("report-performance-correctly")}{t("coordinating_conjunction.or")}{t("report-attempted")}</>,
                clsQStats.map(q => <td key={q.qnum} className={"data-field semi-bold"}>{disV(q.RoA)}</td> ))}
            {TR1('avgMp', QSorts, flipQSort, t("report-performance-average-percentage"),
                clsQStats.map(q => <td key={q.qnum} className={"data-field semi-bold"}>{disV(q.avgMp)}</td> ))}
            {TR1('avgM', QSorts, flipQSort, t("report-average"),
                clsQStats.map(q =><td key={q.qnum} className={"data-field semi-bold"}>{disV(q.avgM)}</td>))}
            {TR1('qnum', QSorts, flipQSort, t("report-question-no"),
                clsQStats.map(q => <td key={q.qnum} className={"data-field"}>
                    <div className={"d-flex justify-content-center semi-bold"}><CpQuestionBox num={1+q.qnum} miniLayout={true}/></div>
            </td>))}
            <tr>
                <td className={"semi-bold bottom-border space sticky-header header-no"}>
                    <div className={"d-flex justify-content-center align-items-center gap-2 user-select-none"} role={"button"} >
                        <span onClick={flipUSort('clsno')}>{t("report-student-no")}</span>
                        <CpSortingIcon {...{sorts:USorts, addSort:addUSort, flip:flipUSort, sortKey:'clsno'}} />
                    </div>
                </td>
                <td className={"semi-bold bottom-border space sticky-header header-name"}>
                    <div className={"d-flex justify-content-center align-items-center gap-2 user-select-none"} role={"button"} onClick={flipUSort('name')}>
                        <span>{t("report-student")}</span>
                        <CpSortingIcon {...{sorts:USorts, addSort:addUSort, flip:flipUSort, sortKey:'name'}} />
                    </div>
                </td>
                <td className={"semi-bold right-border bottom-border space sticky-header header-score"}>
                    <div className={"d-flex justify-content-end align-items-center gap-2 user-select-none"} role={"button"} >
                        <span onClick={flipUSort('mark')}>{t("report-score")}</span>
                        <CpSortingIcon {...{sorts:USorts, addSort:addUSort, flip:flipUSort, sortKey:'mark', horizon:0}} />
                    </div>
                </td>
                {clsQStats.map(q => <td key={q.qnum} className={"data-field bottom-border semi-bold"}>{q.score}</td>)}
                <td className={"w-100  bottom-border"}></td>
            </tr>
        </thead>
        <tbody>{stuStats.map(s => <StudentData key={s.studentId} {...{s, qStats: clsQStats}}/>)}</tbody>
    </table></>
});
export default TabPerformanceDetailChart;

const TR1 = (sortKey, sorts, flip, txt, tds) => {
    return <tr><td className={"semi-bold right-border space sticky-header header-colspan"} colSpan={3}>
        <div className={"d-flex justify-content-end align-items-center text-end gap-3 user-select-none"} role={"button"} onClick={flip(sortKey)}>
            <span>{txt}</span>
            <CpSortingIcon {...{sorts, flip, sortKey, horizon:1}} />
        </div>
        </td>{tds}<td className={"w-100"}></td>
    </tr>
};


export const SortingArrows = (props) => {
    const {sorts, sortKey, flip } = props;
    
    const s = sorts[sortKey];
    const [asc, desc] = [s === 'asc', s === 'desc'];
    return <span className={"d-flex flex-column gap-0 justify-content-center fs-8"} onClick={flip(sortKey)}>
        <span role={"button"} className={asc? "" : "opacity-25"}><CpIco src={IconList.general.asc}/></span>
        <span role={"button"} className={desc? "" : "opacity-25"}><CpIco src={IconList.general.desc}/></span>
    </span>;
};

export const CpSortingIcon = (props) => { //SortingIcon 
    const {sorts, sortKey, flip, horizon } = props;
    
    const s = sorts[sortKey];
    const [asc, desc] = [s === 'asc', s === 'desc'];
    return <span key={sortKey+asc+desc} role={"button"} className={`text-dim-250 fs-9 ${horizon? "report-sort-horizontal" : ""}`} onClick={flip(sortKey)}>
        <span className={`${asc? "text-body-color" : ""}`}><CpIco src={IconList.general.asc}/></span>
        <span className={`${desc? "text-body-color" : ""}`}><CpIco src={IconList.general.desc}/></span>
    </span>;
};

const StudentData = (props) => {
    const { qStats}  = props;
    const { studentId, name, clsno, worked, marked, subed, mark, totalMark, marks, pass } = toObj(props.s);     
    const dim = !(worked && marked && subed);
    const scoreStyle = dim? "text-dim-350": (pass? "text-tag-success": "text-tag-danger");
    return <><tr>
        <td className={`text-center semi-bold ${dim? "text-dim-350": ""} sticky-header header-no`}>{clsno}</td>
        <td className={`text-center semi-bold ${dim? "text-dim-350": ""} sticky-header header-name`}>{name}</td>
        <td className={`text-center semi-bold ${dim? "text-dim-350": ""} sticky-header header-score`}>
            <span className={`${scoreStyle}`}>{subed?mark:'-'}</span> / {totalMark}</td>
        {
        toAry(qStats).map((q, i) => { 
            const m = toObj(marks[q.qnum]);
            return <td key={i}><CpValueStatusBox studentData={{value:m.score,status:m.status}} subed={subed} type={"question"}/></td>})
        //return <td key={i}><ValueStatusBox m={m} type={"question"}/></td>})
        //marks.map((m, i) => { return <td key={i}><ValueStatusBox m={m} type={"question"}/></td>})
        }
    </tr></>;
};

const ValueStatusBox = (props => {
    const {m} = props;
    // f-red submit, wrong
    // p-green submit, correct
    // s-dark grey submit, attempt, ope
    // m-deepRed submit, not marked
    // n-light Grey submit, not attepmt, ope
    //const status = ['f','p','s','m','n'][inc % 5]; 
    /*
    const status = Object.keys(m).length>0?(m.isOP?(m.opAttempt?_QST_SUB_ATT:_QST_SUB_NOTATT)
        :(m.isRight?_QST_SUB_CORR:(m.subButNoMark?_QST_SUB_NOTMARK:_QST_SUB_WRONG))):'';
    */
    //return <div className={`field field-marks text-center`}></div>
    //return <div className={`field field-marks text-center status-n`}></div>
    const scoreDisplay = (m.subButNoMark?'':m.score);
    return <div className={`field field-marks d-flex justify-content-center align-items-center status-${m.status} semi-bold`}>
        {m.status!==''?scoreDisplay:''} {(m.subButNoMark)?<CpIco src={IconList.general.alert}/>:''}
    </div>
});
//return <div className={`field field-marks d-flex justify-content-center align-items-center status-${status} semi-bold`}></div>
export const statStudent = (asm, Exer, qtns, students, works, marks) => {
    
    const cQIds = Exer.ECtns.map(c => (c.type === _ExCtType_Qtn) && toIdStr(c.QIds));
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);
    const EScore = aScore + mScore;
    const pMark = asmPassMark(asm.passUnit, asm.passValue, EScore);  
    // filter submitted student only   
    /*
    return toUniIdAry(asm.studentIds).filter(sid=>{
        const w = toObj(works[sid]);
        return (w?.WState) === _WST_SUBMIT;
    }).map((sid) => {    */
    return toUniIdAry(asm.studentIds).map((sid) => {
        const [w, m, s]  = [works[sid], marks[sid], students[sid]];
        
        if(!s) return;
        
        const MStat = toObj(m?.MStat);

        const worked = w.hasOwnProperty('resp')? 1: 0;
        const subed = w && (w.WState === _WST_SUBMIT);
        const marked = subed && (m?.MState === _MST_MARKED)?1:0;
        const mark = toInt(MStat?.mScore) + toInt(MStat?.aScore);
        const totalMark = EScore;
        // m=mark, assign each question status from mark
        const ms = toAry(m?.resp).map((m, idx) => {
            if(!cQIds[idx]) return;
            const qtn = qtns[cQIds[idx]];
            const {aScore =0, mScore =0, isRight =0, allMarked =0, isOP =0, opAttempt =0, isAutoMark =0, hasAnswered =0} = toObj(m);
           
            const subButNoMark = subed && !allMarked;            
            const status = ansState(qtn, subed, hasAnswered, allMarked, isRight);
            /*
            const status = subed?((isOP?(opAttempt?_QST_SUB_ATT:_QST_SUB_NOTATT)
            :(isRight?_QST_SUB_CORR:(subButNoMark?_QST_SUB_NOTMARK:_QST_SUB_WRONG)))):'';
            */
            return [(toInt(aScore) + toInt(mScore)), isRight, allMarked, isOP, opAttempt,
                subButNoMark, status, hasAnswered, isAutoMark]; 
        }).filter(o=>o).map(s => {return {score:s[0],isRight:s[1],allMarked:s[2],isOP:s[3],opAttempt:s[4],
                subButNoMark:s[5],status:s[6],hasAnswered:s[7],isAutoMark:s[8]}});

        const name = s.nameEng;
        const clsno = s.classNo;
        return {studentId:sid, name, clsno, worked, subed, marked, mark, totalMark,
            marks:ms, pass:(mark>=pMark)}; 
    }).filter(s=>s);
    //return toAry(works).map()
};


export const ansState = (qtn, subed, hasAnswered, allMarked, isRight) => {
    const correctness = qHasCorrect(qtn);
        /*
        0 submitted
        4 has attempted and not all marked = _QST_SUB_NOTMARK
        3 no correctness and all marked = _QST_SUB_ATT
        2 has correctness and all marked
            correct = _QST_SUB_CORR
            wrong = _QST_SUB_WRONG
        1 not attempted = _QST_SUB_NOTATT
        priority: 0(4 > 3 > 2 > 1)
        */
    return (subed)// && hasAnswered)
        ?allMarked
            ?correctness
                ?isRight? _QST_SUB_CORR: _QST_SUB_WRONG
                :_QST_SUB_ATT
            :_QST_SUB_NOTMARK
        : _QST_SUB_NOTATT;
};

const statQtn = (Exer, qtns, _sStats ) => {
    const sts = toAry(_sStats);
    const subs = sts.filter(s => s.subed);
    const subcnt = subs.length;
    
    return exerQIds(Exer).map((QId, qnum) => {
        const qtn = toObj(qtns[QId]);
        const score = toInt(qtn.score); 
        const hasCorr = qHasCorrect(qtn);
        
    /*
    special case for:
    1. open-end, with no correctness
        Correcness/Attempted% = how many student marked by teacher / total student submitted
    2. polling
        Correcness/Attempted% = how many studend attempted this question / total student submitted
    */
        const sum = {a:0, marked:0, r:0, m:0, };//mstr:''};  
        sts.forEach(st => {
            const {clsno, worked, subed, marked,} = toObj(st);
            const m = toObj(st.marks[qnum]);
            
            if (worked && subed && marked && m.status) { // has mark
                const atted = 1;//(m.hasAnswered)? 1: 0;  
                const marked = (atted && m.allMarked)? 1: 0; 
                    

                sum.a += (atted? 1: 0); //attemp count
                sum.marked += (marked? 1: 0);  //marked count
    
                sum.r += ((marked && hasCorr && m.isRight)? 1: 0); // right count
                sum.m += (marked? toInt(m.score): 0); //
                //if(marked) sum.mstr+= toInt(m.score)+',';
            };
        });
        
        
        const RoA  = hasCorr? sum.r: sum.a;
        const RoAp = toPercent(RoA, hasCorr? sum.marked: subcnt, 1);// + ' '+sum.mstr+'/'+sum.marked;
        const avgM = toRate(sum.m, sum.marked);
        const avgMp = toPercent(sum.m, sum.marked * score);
        
        const statQtnRet = { QId, qnum, score, 
            RoA, RoAp, avgM, avgMp, };
        
        
        return statQtnRet;  
    });
};

const toRate = (val, ttl) => ttl? toRptFix(val / ttl): '(--)';
const toPercent = (val, ttl) => ttl? toRptFix(val * 100 / ttl): '(--)';
//const toRate = (val, ttl, dig) => ttl? toFix(val / ttl, dig): '(--)';
//const toPercent = (val, ttl, dig) => ttl? toFix(val * 100 / ttl, dig): '(--)';


const disV = (v) => toStr(v); // isNaN(v)?'--':v;}
/*
const statQtn_org = (Exer, qtns, works, marks, _sStats ) => {
    
    const subs = objVals(works).filter(w => w.WState === _WST_SUBMIT);
    const subcnt = subs.length;
    const pcent = x => subcnt? (Math.round(x * 100) / (subcnt * 100)): '(--)';
    const ss = toObj(_sStats);
   
    return exerQIds(Exer).map((QId, qnum) => {
        const score = qtns[QId]?.score; 
        const sum = {r:0, m:0};  
        // marks is an object
        const mkeys = Object.keys(marks);
        //toAry(marks).forEach(ms => {
        mkeys.forEach(mkey =>{
            const m = toObj(marks[mkey]);
            //const m = toObj(ms[qnum]);
            sum.r += toInt(m.right);
            sum.m += toInt(m.mark);
        })
        const [right, mark] = [sum.r, sum.m];
        const rightp = pcent(right); 
        const markp = pcent(mark); 
        return { QId, qnum, right, rightp, mark, markp, qnum, score }  
    });
};
*/

const sortQ = (qState, QSorts) => {
    const ss = objEntries(QSorts); 
    const ary = toAry(qState, ss);
    ary.sort((a,b) =>
        sortq(a, b, ss[0]) || sortq(a, b, ss[1]) || sortq(a, b, ss[2]) || 
        sortq(a, b, ss[3]) || sortq(a, b, ss[4]) );
    return ary;
};
const sortq = (a, b, kv) => {
    if(!kv) return;
    const [key, so] = kv;
    const isAsc = so !== 'desc';
    //if(key === 'name') return isAsc? strCmpTrimLow(a.name, b.name): strCmpTrimLow(b.name, a.name);
    const ret = isAsc? a[key] - b[key]: b[key] - a[key];
    
    return ret;
};

const sortS = (_sStats, USorts) => {
    const ss = objEntries(USorts); 
    
    const ary = toAry(_sStats);
    ary.sort((a,b) => sortu(a, b, ss[0]) || sortu(a, b, ss[1]) || sortu(a, b, ss[2]));
    
    return ary;
};
const sortu = (a, b, kv) => {
    
    if(!kv) return;
    const [key, so] = kv;
    const isAsc = so !== 'desc';
    //if(key === 'name') return isAsc? strCmpTrimLow(b.name, a.name): strCmpTrimLow(a.name, b.name); 
    if(key === 'name') return isAsc? strCmpTrimLow(a.name, b.name):strCmpTrimLow(b.name, a.name); 
    const ret = isAsc? a[key] - b[key]: b[key] - a[key];
    
    return ret;
};

