import { isAry, toInt, toStr } from "../libs/libType";

export const normailizeFilter = (fs) => {
    return {
        d1: fs.d1,
        d2: fs.d2,

        q1: fs.q1?1:0,
        qgroup: fs.qgroup?1:0,

        langEn: fs.langEn?1:0,
        langCt: fs.langCt?1:0,
        langAll: fs.langAll?1:0,
         
        bm: fs.bm?1:0, 
        usrme: fs.usrme?1:0, 
        usr: fs.usr?1:0,
        s1: reqInt(fs.s1), 
        s2: reqInt(fs.s2),
        uLoginStr: toStr(fs.uLoginStr).trim(),
        
        QId: toStr(fs.QId).trim(),
        kw: toStr(fs.kw).trim(),
        metaIds: isAry(fs.metaIds)?fs.metaIds:[], 
        SQTs:  isAry(fs.SQTs)?fs.SQTs:[],

        qdraft: fs.qdraft?1:0,
        qr2p: fs.qr2p?1:0,
        qpub: fs.qpub?1:0,
        qunpub: fs.qunpub?1:0,

        sortQ: toStr(fs.sortQ),
    };
};
const reqInt = (_i) => {
    const i = toStr(_i).trim();
    return i && toInt(i);
};
