import React, {useEffect} from 'react';
import { ReduxBind } from '../saga/ReduxState';
import { _AssignmentRoot, _CollectionRoot, _ExerciseRoot, _LibraryRoot, _WebRoot } from '../consts/ATConstReact';

import { Button0 } from '../libs/libUI';
import { clickUrl } from '../AppExPf/AppUtil';

import { routesMap, routeUse } from '../libs/libRouter';

import PageRedirect from './PageRedirect';
import PageDashboards from './EPDashboard/PageDashboards';
import PageLogin from './PageLogin';
import PageLoginX from './PageLoginx';

import PageLibrary from './EPLibrary/PageLibrary';
import PageReport from './EPReports/PageReports';
import PageAssignments from './EPAssign/PageAssignments';
import { expfRoles } from '../saga/auth.saga';
import { urlPush_Replace } from '../saga/urlPush.saga';
import PageDev from '../App/AppDev/PageDev';
import PageHome from './PageHome';

export const _EPSECT_oup = 'EPSECT_oup';
export const _EPSECT_school = 'EPSECT_school';
export const _EPSECT_my = '_EPSECT_my';
export const _EPSECT_ecol = '_EPSECT_ecol';

const stage = process.env.REACT_APP_CFG;

const AppRouteEP = ReduxBind((props) => {
  
  const dispatch = props.dispatch;
  //debugMode() && lj('AppRoute_Render() call'); 

  const logined = props.isLogined();
  const [isThr, isStt, uRole ] = expfRoles(props);
  const lang = props.lang();
  const path = window.location && window.location.pathname;

  useEffect(() => { dispatch({type:"BANNERVIS", vis:true}); }, []);
  
  const route = routesMap(path, [
    [_WebRoot + 'noentitlement', PageLogin, {noEnt:1}],

    isThr && [_LibraryRoot + '/school/:EId', PageLibrary, {sect:_EPSECT_school}],
    isThr && [_LibraryRoot + '/school', PageLibrary, {sect:_EPSECT_school}],
    isThr && [_LibraryRoot + '/oup/:cLang/:ESetId/:EId/:ExAct', PageLibrary, {sect:_EPSECT_oup}],
    isThr && [_LibraryRoot + '/oup/:cLang/:ESetId/:EId', PageLibrary, {sect:_EPSECT_oup}],
    isThr && [_LibraryRoot + '/oup/:cLang/:ESetId', PageLibrary, {sect:_EPSECT_oup}],
    isThr && [_LibraryRoot + '/oup/:cLang', PageLibrary, {sect:_EPSECT_oup}],
    isThr && [_LibraryRoot + '/oup', PageLibrary, {sect:_EPSECT_oup}],
    isThr && [_LibraryRoot, PageLibrary, {sect:_EPSECT_oup} ],

    isThr && [_CollectionRoot + '/:EColId/:EId/:ExAct', PageLibrary, {sect:_EPSECT_ecol}],
    isThr && [_CollectionRoot + '/:EColId/:EId', PageLibrary, {sect:_EPSECT_ecol}],
    isThr && [_CollectionRoot + '/:EColId', PageLibrary, {sect:_EPSECT_ecol}],
    isThr && [_CollectionRoot, PageLibrary, {sect:_EPSECT_ecol}],
   
    isThr && [_ExerciseRoot + '/:EId/:ExAct', PageLibrary, {sect:_EPSECT_my}],
    isThr && [_ExerciseRoot + '/:EId', PageLibrary, {sect:_EPSECT_my}],
    isThr && [_ExerciseRoot, PageLibrary, {sect:_EPSECT_my}],

    [_AssignmentRoot + '/:asmId/reme/:rAsmId', PageAssignments, {}],
    [_AssignmentRoot + '/:asmId/:act', PageAssignments, {}],
    [_AssignmentRoot + '/:asmId', PageAssignments, {}],
    [_AssignmentRoot, PageAssignments, {a:100}],

    [_WebRoot + 'report', PageReport, {src:'school'}],

    //[_WebRoot + 'home', PageHome, {}],
    [_WebRoot + 'home', PageDashboards, {}],
    //[_WebRoot, isThr?PageLibrary:isStt?PageAssignments:PageLogin, {src:_EPSECT_oup}],

    [_WebRoot + 'dev/mw', PageHome],
    [_WebRoot + 'dev/:dev', PageDev],
    [_WebRoot + 'dev', PageDev],

    [_WebRoot, PageDashboards, {}],
  ].filter(r => r));
  const page = (route)?routeUse(route):'';//<PageATBody/>;

  useEffect(() => {
    if((!logined) || page) return;
    const newurl = isThr? _LibraryRoot: _AssignmentRoot
    dispatch(urlPush_Replace(newurl));
  }, [page]);

  const maintenanceMode = props.maintenanceMode();
  if(maintenanceMode){
    const mpURL = (lang==='Ct')? maintenanceMode[1]: maintenanceMode[0];
    if(mpURL !== path)
      window.location.replace(mpURL); //(webRoot+'maintenance.html');
    return '';
  }

  const dummyLogin = (props._saga?.gst?.dummyLogin);
  if(!(logined || dummyLogin)) {
    if(path.startsWith(_WebRoot+'redirect')) return <PageRedirect />;
    if(path === (_WebRoot+'login')) return <PageLogin />;

    
    if(('local' === stage) && (_WebRoot !== path)){
      return <PageLoginX />;
    }else if(['dev','uat'].includes(stage) && path.startsWith(_WebRoot+'dummylogin')){
      return <PageLoginX />;
    } 
    return <PageRedirect />;
  }

  const licenseAgree = props._saga?.gst?.licenseAgree;
  if(licenseAgree) return <PageLogin {...{licenseAgree, logined}}/>  

  return page || <Page404 />;
});
export default AppRouteEP;

const Page404 = ReduxBind(props => {
  const {dispatch} = props;

  return<div className="p8 m8 tac">
    <div className="w0 hvh vam"></div>
    <div className="w vam">
      <h1>Exercise Platform Redirecting...</h1>
      <div className="p8 m8">{Button0('Back to Main Page', clickUrl(dispatch, _WebRoot), 'btngohome')}</div>
    </div>
  </div>;
});