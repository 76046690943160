import React, { useEffect, useState } from "react";
import { toStr } from "../../libs/libType";

const CpChartMiniBar = (props) => { // base on poc MiniBarChart.tsx
    const {range, marks, failMarks, type, status, showFail = false, indicator = "inside", showSubIndicator = false} = props;
    const {percentage = 0, value = 0} = marks || {}
    const {percentage: failPercentage = 0, value: failValue = 0} = failMarks || {};
    const layoutStyle = failMarks === undefined ? "default" : "segment";

    const [shiftInfo, setShiftInfo] = useState({delta:0, style:""});
    const shiftBubble = () => {
        let delta = '0';
        let style = '';        
        const chkv = Math.round((marks?.value / marks?.total) * 100);
        const chks = toStr(marks?.value);
        if (chks.length >= 4 && chkv > 96) {
            style = chks.indexOf(".") ? "shiftBubble2":"shiftBubble";
        };
        return {delta, style};
    };

    //useEffect(()=>{ console.log({shiftInfo}); }, [shiftInfo]);
    useEffect(()=>{
        setShiftInfo(shiftBubble());
    },[]);
        
    return <div className={`minibar-container d-flex ${layoutStyle === "default" && showFail && status !== "n"? "showFail": ""} ${showSubIndicator? "mb-4" : ""}`}>
        {(marks === undefined)? '': 
        <div>
            <span className={`bg-value value-bottom semi-bold text-nowrap ${indicator && status !== "n"? "": "opacity-0"}`}>{percentage}%</span>
            <div className={`correctness-average-bar ${type}-bar`}
                style={{width: `${percentage}%`}}>
                    <span className={`bg-value value-top semi-bold text-nowrap ${indicator && status !== "n"? "": "opacity-0"}`}>{percentage}%</span>
                {layoutStyle === "segment" && <div className={"d-flex justify-content-center h-100 align-items-center ps-1 text-white semi-bold fs-8"}>{value}</div>}
            </div>
            {layoutStyle === "segment" && showFail && 
                <div className={`correctness-average-bar f-bar`}
                     style={{ width: `${failPercentage}%`, left: `${percentage}%` }}>
                        <span className={`bg-value value-top semi-bold text-nowrap ${indicator && status !== "n" ? "" : "opacity-0"}`}>{percentage}%</span>
                    <div className={"d-flex justify-content-center h-100 align-items-center text-end ps-1 text-white semi-bold fs-8"}>{failValue}</div>
                </div>
            }
            {layoutStyle === "default" && showSubIndicator &&
                <div className={`indicator-value semi-bold ${status}-bar px-2 rounded-light text-nowrap ` + shiftInfo.style}
                style={{left: `${percentage}%`}}>{marks?.value}</div>}
            {showSubIndicator && <>
                    <div className={`indicator-range semi-bold text-nowrap text-dim-400 fs-10 start-0`}
                        style={{marginLeft: 0}}>{0}</div>
                    <div className={`indicator-range semi-bold text-nowrap text-dim-400 fs-10 end-0`}
                        > {marks?.total}</div>
            </>}
        </div>
        }
    </div>
};

export default CpChartMiniBar;

const CpChartMiniBar_org2 = (props) => { // base on poc MiniBarChart.tsx
    const {range, marks, failMarks, type, status, showFail = false, indicator = "inside", showSubIndicator = false} = props;
    const {percentage = 0, value = 0} = marks || {}
    const {percentage: failPercentage = 0, value: failValue = 0} = failMarks || {};
    const layoutStyle = failMarks === undefined ? "default" : "segment";
   
    return <div className={`minibar-container d-flex ${layoutStyle === "default" && showFail && status !== "n"? "showFail": ""} ${showSubIndicator? "mb-4" : ""}`}>
        {(marks === undefined)? '': 
        <div>
            <span className={`bg-value value-bottom semi-bold text-nowrap ${indicator && status !== "n"? "": "opacity-0"}`}>{percentage}%</span>
            <div className={`correctness-average-bar ${type}-bar`}
                style={{width: `${percentage}%`}}>
                    <span className={`bg-value value-top semi-bold text-nowrap ${indicator && status !== "n"? "": "opacity-0"}`}>{percentage}%</span>
                {layoutStyle === "segment" && <div className={"d-flex justify-content-center h-100 align-items-center ps-1 text-white semi-bold fs-8"}>{value}</div>}
            </div>
            {layoutStyle === "segment" && showFail && 
                <div className={`correctness-average-bar f-bar`}
                     style={{ width: `${failPercentage}%`, left: `${percentage}%` }}>
                        <span className={`bg-value value-top semi-bold text-nowrap ${indicator && status !== "n" ? "" : "opacity-0"}`}>{percentage}%</span>
                    <div className={"d-flex justify-content-center h-100 align-items-center text-end ps-1 text-white semi-bold fs-8"}>{failValue}</div>
                </div>
            }
            {layoutStyle === "default" && showSubIndicator &&
                <div className={`indicator-value semi-bold ${status}-bar px-2 rounded-light text-nowrap`}
                    style={{left: `${percentage}%`}}>{marks?.value}</div>}

            {showSubIndicator && <>
                    <div className={`indicator-range semi-bold px-2 text-nowrap text-dim-400 fs-10 start-0`}
                         style={{left: `${0}%`}}>{0}</div>
                    <div className={`indicator-range semi-bold px-2 text-nowrap text-dim-400 fs-10 end-0`}
                         style={{left: `${100}%`}}>{marks?.total}</div>
            </>}
        </div>
        }
    </div>
};

const CpChartMiniBar_org = (props) => { // base on poc MiniBarChart.tsx
    const {marks, type, status, showFail = false, indicator = "inside", showSubIndicator = false} = props;
    const {percentage = 0} = marks || {};

    return <div className={`minibar-container d-flex ${showFail && status !== "n" ? "showFail" : ""} ${showSubIndicator? "mb-4" : ""}`}>
        {marks && <div>
            <span className={`bg-value value-bottom semi-bold text-nowrap ${indicator && status !== "n" ? "" : "opacity-0"}`}>{percentage}%</span>
            <div className={`correctness-average-bar ${type}-bar`} style={{width: `${percentage}%`}}>
                <span className={`bg-value value-top semi-bold text-nowrap ${indicator && status !== "n" ? "" : "opacity-0"}`}>{percentage}%</span>
            </div>
            {showSubIndicator && <div className={`indicator-value semi-bold ${status}-bar px-2 rounded-light text-nowrap`}
                style={{left: `${percentage}%`}}>{marks?.value} / {marks?.total}</div>}
        </div>
        }
    </div>
};
