import { useEffect, useState } from 'react';
import { ATDo_DelQDraft, ATDo_QClone, ATDo_QDelUnPub, ATDo_QDraftR2P, ATDo_QPub, ATDo_Qtn, ATDo_QUnpub, _ATCanDo } from '../../consts/ATRoleRights';
import { apiCall, apiCallLoad_ } from '../../libs/awsFuncs';
import { arrayAddOrDelete_ } from '../../libs/libArray';
import { toAry, toInt, toObj, toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';
//import * as MD from "../libs/libMathDisplay";

import { _ATRoot } from '../../consts/ATConstReact';
import { langCodeCt, langCodeEn, QP_D, QP_DD, QP_DR, QP_P, QP_PP, QP_PU } from '../../consts/ATConsts';
import { _ATSYSQTypeFullName } from '../../consts/ATSysQType';

import { ATErrsDiv, ATErrsDiv2, aTUITime, BtnPopDev, clickConfirm, clickUrl, preJS, QDPCol, QDPTxt, safeEn } from '../AppUtil';

import { ATPaging, usePagingGST } from '../utils/ATPaging';

//import CpQEditor from './editors/CpQEditor';

import { cpATIcoBtn_, iconButton, svgIcon2 } from '../components/CpATIcoBtn';
import { popAlert } from '../components/CpPopup';
import CpATQtnDo from '../qtnDo/CpATQtnDo';

import LayerATQtnFilter, { useStateGSTQFTs } from './LayerATQtnFilter';

import * as SQT from '../../consts/ATSysQType';
import { useStateGST } from '../../saga/globalState.saga';
import { ReduxBind } from '../../saga/ReduxState';
import { urlPush_Replace } from '../../saga/urlPush.saga';
import CpQViewExt from '../components/CpQViewExt';
import { useMediaCache } from '../utils/useMediaCache';
import { buildMetaTree} from '../utils/utilWalkTree';
import { buildTagMetaEnts, MSetGroupType } from '../../AppExPFUser/EPLibrary/LayerExerFilter';
import { useTagMSetCache } from '../utils/useTagMSetCache';
import { rmQtnMediaSrcs } from '../../consts/qtnMedias';
import { unpackQtn } from '../../consts/wirisConfig';
import { useUILang } from '../utils/useUILang';

export const useQFilterCache = props => {
  const [qids, _setQids] = useStateGST(props, 'QFilterQIds');
  const [qtns, setQtns] = useStateGST(props, 'QFilterQs');
  const [BkMks, setBkMks] = useStateGST(props, 'QFilterBMarks',);
  const [qMSets, setQMSets] = useStateGST(props, 'QFilterMSets',);
  return [qids, _setQids, qtns, setQtns, setQMSets, BkMks, setBkMks, qMSets];   
};
export const useMSetCache = props => {
  const [typeMetaSets, setTypeMetaSets] = useStateGST(props, 'QFindTyMetaSets', );
  return [typeMetaSets, setTypeMetaSets];
};

export const useTickAll = Ids => {
  
  const [tickAll, _setTickAll] = useState(0);
  const [ticks, setTicks] = useState([]);
  const setTick = id => on => { 
    setTicks(ts => arrayAddOrDelete_(ts, id, on)); (!on) && _setTickAll(0); };
  const setTickAll = t => { 
    
    _setTickAll(t); setTicks(t? Ids :[]); };
  const clearTicks = () => { setTicks([]); _setTickAll(0); };
  return [ticks, setTick, setTicks, tickAll, setTickAll, clearTicks];
}; 

const PageATQtns = ReduxBind((props) => {
  const dispatch = props.dispatch;
  const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
  const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
  const letQtn = _ATCanDo(role, ATDo_Qtn);

  const [QFTs, setQFTs] =  useStateGSTQFTs(props);
  const [visQFTs, setvisQFTs] = useState(0);

  const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 1);

  const [typeMetaSets, setTypeMetaSets] = useMSetCache(props);
  const [qids, _setQids, qtns, setQtns, setQMSets, BkMks, setBkMks] = useQFilterCache(props);
  const [err2d, setErr2d] = useState({});
  const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 

  const qCnt = toAry(qids).length;
  const paging = usePagingGST(props, 'QFilterPagine', qCnt, 10, 1);
  const { page, rpp, totalRow, totalPage, res1, res2 } = paging;

  const PageQIds = toAry(qids).slice(res1 - 1, res2);
  const hasQIds = (toAry(qids).length > 0);

  const [ticks, setTick, setTicks, tickAll, setTickAll, clearTicks] = useTickAll(PageQIds);
  const tickSet = new Set(toAry(ticks));
  const tickOn = QId => tickAll || tickSet.has(QId);

  const onLoadQFTs = (res, err) => setQids(res?.qids);
  const loadQFTs = (qfts, onLoad) => apiCallLoad_(dispatch, '/getQIds', onLoad, { qfts });
  const reloadQFTs = () => loadQFTs(QFTs, onLoadQFTs);

  const reloadPage = (fe2d) => { hasQIds && loadQtns(PageQIds); setErr2d(toObj(fe2d)); };
  const onPub = (res, err) => reloadPage(res?.fe2d); 

  const reloadMS = () => apiCallLoad_(dispatch, '/getATMetaOpts', onLoadMS, {});

  const onLoadMS = (res, err) => { 
    setTypeMetaSets(MSetGroupType( buildTagMetaEnts( buildMetaTree(toAry(res?.metaSets), toAry(res?.metas)))));
    reloadQFTs();
  };
  useEffect(() => {
    if(letQtn){
      const init = !typeMetaSets;
      if (init) reloadMS();
    }else{
      dispatch(urlPush_Replace(_ATRoot));
    }
  }, []);
    
  useEffect(() => { console.log({res1, res2, qids}); reloadPage(); }, [qids, res1, res2]);

  const onLoad = (res, err) => {
    if (err) popAlert(dispatch, 0, err);
    if(res){
      const QIds = toAry(res.qids);
      const drafts = toAry(res.qtnDrafts).map(q => rmQtnMediaSrcs(unpackQtn(q)));
      const qMsDrafts = toAry(res.qMsDrafts);
      const pubs = toAry(res.qtns).map(q => rmQtnMediaSrcs(unpackQtn(q)));
      const qMs = toAry(res.qMs);
      const qMSets = toObj(res.MSets); 
      const merged = mergePubsDrafts2(QIds, drafts, qMsDrafts, pubs, qMs, qMSets);
      setQtns(merged);
      setQMSets(qMSets);
      setBkMks(toAry(res.BkMks).map(qbm => qbm.QId));
    }
    clearTicks();
  };
  const loadQtns = (QIds) => { apiCallLoad_(dispatch, '/getATQtns', onLoad, { QIds }); }

  const clickOpt = UI.stopEventThen(e => setvisQFTs(1));
  const clickCloseOpt = UI.stopEventThen(e => setvisQFTs(0));
  const setQids = (qs) => {
    console.log('setQids', {qs});
    const sortQ = toAry(qs);//sortQids(qs);
    const page = paging.setPaging({ totalRow: sortQ.length, page: 1 });
    setQtns();
    _setQids(sortQ);
    
    //loadQtns(sortQ.slice(page.res1 - 1, page.res2));
  }

  const saveBm = (QId, toOn) => {
    setBkMks(BMs => arrayAddOrDelete_(BMs, QId, toOn));
    apiCall(dispatch, toOn ? '/putATQBookMark' : '/deleteATQBookMark', () => { }, { QId });
  };
  
  const letR2P = _ATCanDo(role, ATDo_QDraftR2P);
  const letPub = _ATCanDo(role, ATDo_QPub);
  const letUnpub = _ATCanDo(role, ATDo_QUnpub);
  const letClone = _ATCanDo(role, ATDo_QClone);
  const letDelete = _ATCanDo(role, ATDo_DelQDraft) || _ATCanDo(role, ATDo_QDelUnPub);

  const clickR2P = letR2P && clickConfirm(dispatch, 'Confirm Request to Publish', () => apiCallLoad_(dispatch, '/putATQtnRequest2P', onPub, { ticks }));
  const clickPub = letPub && clickConfirm(dispatch, 'Confirm Publish', () => apiCallLoad_(dispatch, '/putATQtnPublish', onPub, { ticks }));
  const clickUnpub = letUnpub && clickConfirm(dispatch, 'Confirm Unpublish', () => apiCallLoad_(dispatch, '/putATQtnUnpublish', onPub, { ticks }));
  const clickDelete = letDelete && clickConfirm(dispatch, 'Confirm Delete', () => apiCallLoad_(dispatch, '/deleteATQtns', onPub, { ticks }));
  const clickReload = e => { UI.stopEvent(e); reloadMS(); };
  
  //todo {letClone? cpATIcoBtn_('general/copy', 'Duplicate', (tickSet.size === 1) && clickUrl(dispatch, _ATRoot + 'question/new/' + ticks[0])): ''}
  const loading = 0;//(props._saga?.loading?.count);
  return  <div className="flexColStart w100 p8" style={{position:'relative', height:'100%'}}>
    <div className='flexColStart w100' style={{overflow:'auto', padding:'0', height:'100%',
      maxHeight:'100%', position:'relative', marginBottom:'50px'}}> 
      <div className="f16">Questions / Questions</div>
      <div className="adminToolsContainer"><div style={{display:"flex"}}> 
        <div className="adminTools1">
          {letQtn? cpATIcoBtn_('labelDiagram/add', 'new', clickUrl(dispatch, _ATRoot + 'question/new')): ''}
          {letQtn? cpATIcoBtn_('general/edit', 'Edit', (tickSet.size === 1) && clickUrl(dispatch, _ATRoot + 'question/edit/' + ticks[0])): ''}
          {letClone? cpATIcoBtn_('general/copy', 'Duplicate', (tickSet.size === 1) && clickUrl(dispatch, _ATRoot + 'question/new/' + ticks[0])): ''}
          {letDelete? cpATIcoBtn_('general/delete', 'Delete', tickSet.size && clickDelete): ''}
          {letR2P? cpATIcoBtn_('general/request', 'Request to Publish', tickSet.size && clickR2P): ''}
          {letPub? cpATIcoBtn_('general/publish', 'Publish', tickSet.size && clickPub): ''}
          {letUnpub? cpATIcoBtn_('general/unpublish', 'Unpublish', tickSet.size && clickUnpub): ''}        
          {letQtn? cpATIcoBtn_('general/reload', 'Reload', clickReload): ''}
        </div>
        <div className="adminTools2">
          <BtnPopDev txt="QFTs">{preJS({QFTs},3)}</BtnPopDev>
          {cpATIcoBtn_('general/filter', 'Filter', clickOpt)}
        </div>
      </div></div>
      <div><label>{UI.CheckBox0(tickAll, setTickAll, 'chkAll')} Select Whole Page</label></div>

      <div className="flexColStartFit" style={{flex:1, padding:"8px 20px 0px 10px",margin:"auto"}}>
        {loading? 'LOADING...':
          (qtns?.length) ?
          qtns.map((q, i) => <CpQView key={"qview"+q.QId} idx={i} {...{...props,
            t, q, tickOn, setTick, BkMks, saveBm, mediaDLs, getMediaDLs, tagMSets, useTagMSets, errs: err2d[q.QId]}} />) :
          <div>No Results</div>
        }
      </div>

      <div className='f14 flexRowEnd' style={{ position:'fixed', bottom:0, right:0, paddingRight:'4px',
        alignItems:'center', height: "50px", borderTop:'1px solid lightgrey', backgroundColor:'white'}}>
        <div className='flexRowStartFit'> Results: {res1} - {res2} of {totalRow} </div>
        <ATPaging {...{ paging }} />
      </div>
    </div>
    {visQFTs ? <LayerATQtnFilter close={clickCloseOpt} {...{ setQids, typeMetaSets, QFTs, setQFTs, loadQFTs, onLoadQFTs }} /> : ''}
  </div>;
});
export default PageATQtns;

/*
      <div className='f14 w100 fxRow' style={{ position:'absolute', bottom:0, alignItems:'center', height: "50px", }}>
        <div className='vam m8 fxGrow'> Results: {res1} - {res2} of {totalRow} </div>
        <div className='vam m8'> <ATPaging {...{ paging }} /></div>
      </div>
*/
//const smallTxt = txt => <span style={{ fontSize: '11px', lineHeight: '1px' }} >{txt}</span>
const _sqt = qt => qt && ((qt.slice(0, 5)) === 'SQST_' ? qt : ('SQST_' + qt));
const sqtName = sqt => sqt ? (_ATSYSQTypeFullName(sqt) || ('Type (' + toStr(sqt) + ')?')) : '(missing Question type)';

const noop = () => {};
const CpQView = props => {
  const {t, q: Qpd, tickOn, setTick, BkMks, saveBm, mediaDLs, getMediaDLs, tagMSets, useTagMSets, errs } = props;
  const [p, d] = [Qpd.pub, Qpd.draft];

  const [ext, setExt] = useState(0);
  const [view, setView] = useState(d ? QP_D : QP_P);
  const [QLang, setQLang] = useState(langCodeEn);
  const safeSetView = v2 => setView(v => (!d) ? QP_P : (!p) ? QP_D : v2);
  const clickView = v => UI.stopEventThen(e => safeSetView(v));

  const showPub = p && (view === QP_P);
  const fullQ = showPub ? p : d || p || d;

  const [hasEn, hasCt] = [fullQ?.QLangEn, fullQ?.QLangCt ];  
  const showEn = safeEn(QLang !== langCodeCt, hasEn, hasCt);

  const Q = fullQ && ((showEn? fullQ.QEn: fullQ.QCt) ||fullQ.Q);
  const QId = Qpd.QId;
  const BM = BkMks.includes(QId); //because it will refresh
  const clickBM = UI.stopEventThen(e => saveBm(QId, !BM));

  const CKkey = QId + toStr(fullQ?.drpu) + (showEn?'_E':'_C');
  const showScore = (fullQ?.SQType !== SQT.__SYSQSubType_POL);

  return <div key={'qv_' + CKkey + ''} className="ATQtnsListContainer">
    {fullQ?<>
    <div className='flexRowBetween f14'>
      <div>{UI.CheckBox0(tickOn(QId), setTick(QId), 'chkQ' + QId)} QID: {QId}</div>
      <div>BookMark: {UI.CheckBox0(BM, clickBM, 'cbbm' + QId)}</div>
    </div>
    <div className='ATQtnsListContent'>
    <div className='ATQtnsListHead' style={{ backgroundColor: QBarColor(fullQ) }}>
      <div className="flexRowStartFit">
        {hasEn ? BtnViewQLang(showEn, 'En', UI.stopEventThen( e => setQLang(langCodeEn))): ''}
        {hasCt ? BtnViewQLang(!showEn, '中', UI.stopEventThen( e => setQLang(langCodeCt))) : ''}
        <div className='ATQtnsListTxt f12'>{sqtName(_sqt(fullQ.SQType))}</div>
        {showScore?<div className='ATQtnsListTxt f12'>{'Marks: ' + toInt((fullQ.score) || (Q?.qscore))}</div>:''}
        <BtnPopDev key={'d1' + QId} txt='Q Core' vis={0} >{ preJS(Q, 3)}</BtnPopDev>
        <BtnPopDev key={'d2' + QId} txt={'Full Question' + (showPub ? '(Publish)' : '(Draft)')} >{preJS(fullQ, 3)}</BtnPopDev>
        <BtnPopDev key={'d3' + QId} txt='Pub & Dradf' >{preJS(Qpd, 3)}</BtnPopDev>
      </div>
      <div className="flexRowEndFit">
        {d ? BtnViewQ(d, 0, clickView(QP_D), !showPub) : ''}
        {p ? BtnViewQ(p, 1, clickView(QP_P), showPub) : ''}
      </div>
    </div>
    <div className='ATQtnsListQuestion'>
    {ATErrsDiv2(errs, '', t)}
    <CpATQtnDo key={CKkey} PVMode={2} {...{...props, Q, fullQ, editable:false, setFullQ:noop, setQ:noop }} />
    </div>
    {ext?<CpQViewExt {...{fullQ, showEn, tagMSets, useTagMSets}} />:''}
    <div className='flexRowBetween f14' style={{ color: QBarTxtColor(fullQ), backgroundColor: QBarColor(fullQ)}}>
        <div className='flexRowStartFit' style={{paddingLeft:"10px"}}>{aTUITime(fullQ?.dateMod) + " " + toStr(fullQ?.modLogin)}</div>
        <div>{iconButton("","general/moreHorizontal",showPub?"white":"#2995cd","",e=>{ UI.stopEvent(e); setExt(!ext);})}</div>
    </div>
    </div>
      </>:
      <div className='flexRowStartFit' style={{paddingLeft:"10px"}}>No Result: {QId}</div>
    }
  </div>
};

export const QBarColor = q => (q.dp === QP_P)? (q.drpu === QP_PP? '#34653c': '#800' ): (q.drpu === QP_DD? '#eee': '#FD8' );
export const QBarTxtColor = q => (q.dp === QP_P)? '#fff': '#800';

//const cssBQLV = {border:'1px solid #000',fontSize:'11px', minWidth:'26px', textAlign:'center', borderRadius:'4px', margin:'0 2px', padding:'6px 0'}; 
const BtnViewQLang = (on, txt, onClick) => {
  return <div className={'ATQtnsListTxt f12'+ (on?'':' clickable')} style={{backgroundColor:on?'#6bf':'#def'}} 
  onClick={on?undefined:onClick}>{txt}</div>;
}

const BtnViewQ = (q, isPub, onClick, sel) => {
  const [r2p, unp] = [q.drpu === QP_DR, q.drpu === QP_PU];
  const txt = QDPTxt(isPub, r2p, unp) + ': ' + q.QVer;
  const css = {backgroundColor: QDPCol(isPub, r2p, unp)};
  return <div className='flexRowCenterFit f12'>
      {sel ? svgIcon2('player/start','#2995cd'):<div style={{width:"16px"}}></div>}
      <div className='ATQtnsListDPBtn clickable' style={css} onClick={onClick}>{txt}</div><br />    
  </div>;
};

const _mapQ = qs => Object.fromEntries(qs.map(q => [q.QId, q]));
const _addIf = (obj, key, val) => { if(obj && val) obj[key] = val; };

/*
const touseTagMSets = (useTagMSets, metas) => _mapMSets(useTagMSets, metas); //todo move to useEffect for tagMsets
const _mapMSets = (useTagMSets, metas) => {
  const ms = toAry(metas);
  const msids = toUniIdAry(ms.map( m => m.metaSetId));
  const tmsets = useTagMSets(msids);
  //console.log('_mapMSets', ms, msids, tmsets) 
  const TMSets = {};
  const MSetMs = {};
  ms.forEach(m => {
    const {metaSetId, metaId, display} = m;
    //if(!display) return;
    const MS = tmsets[metaSetId];
    if(!MS) return;
    const mms = MSetMs[metaSetId];
    if(mms){
      mms.push(metaId);
    }else{
      const newMMS = [metaId];
      MSetMs[metaSetId] = newMMS;
      const t = MS.MSType;
      TMSets[t] = {...toObj(TMSets[t]), [metaSetId]:newMMS};
    }
  });  
  return [TMSets];
};
*/
export const mapTMSets = (SMs, tagmsets) => {
  const TMSets = {};
  const SMId2d = {};
  SMs.forEach(m => {
     const [msid, mid] = toAry(m);
     if(!msid || !mid) return;
     const MS = tagmsets[msid];
     if(!MS) return;
     const t = MS.MSType;
     if(!t) return
     const mms = SMId2d[msid];
     if(mms){
        mms.push(mid);
     }else{
        const newMMS = [mid];
        SMId2d[msid] = newMMS;
        TMSets[t] = {...toObj(TMSets[t]), [msid]:newMMS};
     }
  });
  return [TMSets, SMId2d];
};



const mergePubsDrafts2 = (QIds, drafts, qMsDrafts, pubs, qMs, qMSets) => {
  const [ QDs, QPs ] = [ _mapQ(drafts), _mapQ(pubs) ];
  const ret = QIds.map((QId, idx) => { 
    const [ draft, dMetas, pub, pMetas, ] = [ QDs[QId], qMsDrafts[idx], QPs[QId], qMs[idx] ];
    _addIf(draft, 'metas', dMetas);
    _addIf(pub, 'metas', pMetas);
    const dp = {QId};
    _addIf(dp, 'draft', draft);
    _addIf(dp, 'pub', pub);
    return dp;
  });
  
  return ret;
};
