import React, {useEffect, useMemo, useState, useRef} from "react";
import {Button} from "react-bootstrap";

import { _AssignmentRoot, _pathAssign, _EPPathEass } from "../../consts/ATConstReact";
import { ReduxBind } from "../../saga/ReduxState";
import { BtnPopDev, preJS, autoId, deepCopy, preJSUnsort } from "../../AppExPf/AppUtil";
import { debugMode } from "../../saga/ReduxState";
import DoEx from "../../AppExPf/ATExerEdit/DoEx";
import CpIco, { CpLangIco } from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { lang2t, langIsEn, useUILang } from "../../AppExPf/utils/useUILang";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { LayerLocalMedia } from "../../AppExPf/ATMediaPool/LayerLocalMedia";
import { ScreenMask, stopEvent } from "../../libs/libUI";
import { toObj, toStr, toInt, minVal, toAry, isAry, toPercent1, objKeys, toUniAry, objEntries, isFunc } from "../../libs/libType";
import { toUniIdAry } from "../../consts/ATValidate";
import { iconButton } from "../../AppExPf/components/CpATIcoBtn";
import { getTeacherName } from "../../AppExPf/ATExerEdit/TabExerContent";
import { useCaches } from "../../AppExPf/utils/useCaches";
import CpSharedModal from "../_components/CpSharedModal";
import CpAssignmentEndingPage, { ProgressElement } from "./CpAssignmentEndingPage";
import { expfRoles } from "../../saga/auth.saga";
import { asyncApiCall_ } from "../../libs/awsFuncs";
import { __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_FIB, 
    __SYSQSubType_LBT, __SYSQSubType_LBD, __SYSQSubType_OEG, 
    __SYSQSubType_OEE, __SYSQSubType_POL } from "../../consts/ATSysQType";
import {QAS_ANSWERED, QAS_PARTLY, QAS_NOT,
    _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED, _AST_WAIT_RESULT,
    _WST_SUBMIT, _WST_RESUME, _ARS_CLOSE_RESET, _ARS_CLOSE_SUBMIT, _ARS_CLOSE_RESUME } from "../../consts/ATConstsAssignment";
import { wQAnsState } from "./utilAsmWork";
import { hasErr } from "../../AppExPf/utils/useEditDraftPub";
import LayerUpMedia, { localMedias, upEMedias, upLocalMedias } from "../EPExercise/LayerEMediaUp";
import { debugTrimDraw, debugTrimResp, workMapMedias,} from "../../consts/AValidateWork";
import { EPUITime3 } from "../_components/CpEpTabHead";
import { useASHBEffect } from "../../AppExPf/utils/useAutoSave";
import { ECtnsCountInfo } from "./utilAsmWork";
import { getT2bSrt, getT2bEnd } from "../EPAssign/CpAssignmentList";
import { exerHasMark } from "../EPAssign/Tags/TagMarkingTag";
import { flattenECtns } from "../../AppExPf/ATExerEdit/DoEx";
import { timeStampNowGMT } from '../../libs/libTime';
import { TagCountdownTag, isExpired } from "../EPAssign/Tags/TagCountdownTag";
import TagWorkSubmitTag from "../EPAssign/Tags/TagWorkSubmitTag";
import { addWorkMath3Str } from "../../AppExPf/utils/utilQtnAns";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";
import { xapiPost } from "../EPLibrary/PageLibrary";
import { getTransMap } from "../../libs/libTransMap";
import { packWork } from "../../consts/wirisConfig";
import { exerUniQIds } from "../../consts/ATValidateExer";
import { asmIsReme } from "../../consts/EpAssigmentErr";
import { TagRemeLvs, TagStuRemePass } from "../../AppExPf/ATExerEdit/TagRemeLv";
import CpMJX from "../_components/CpMJX";
import { splitSMId } from "../../consts/ATValidateMetaSet";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";
import { asm2PScore, stuPassAsm, stuPassValue } from "../EPAssign/CardStudentAssignmentCard";
//export const BlockAS = (process.env.REACT_APP_CFG !== 'local');
//export const newAutoSave = (process.env.REACT_APP_CFG === 'local');

export const BlockAS = !(['local', 'dev', 'uat', 'offline'].includes(process.env.REACT_APP_CFG));
export const newAutoSave = (['local', 'dev', 'uat', 'offline'].includes(process.env.REACT_APP_CFG));

export const ViewEx_NoMark = 1;
export const ViewEx_Mark = 2;
export const viewEx_Corr = 3;

const PageAsmWork = ReduxBind(props => { //base on poc AssignmentLandingPage 
    const { dispatch, answerVis } = props;
    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();
    const [isThr, isStt, uRole ] = expfRoles(props);
    const myUId = props.userId();

    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 

    const { asm, qtns, useCacheQtns } = props;
    const { Exer, assignId, remeSMId } = toObj(asm);
    const isReme = asmIsReme(asm);
    //const remeLvs = toAry(isReme && (asm?.remeLvs || [RemeLvSame, RemeLvPre, RemeLvPro])) //fake for old broken data
    const remeLvs = toUniIdAry(isReme? (
        isThr? asm.remeLvs:
        objEntries(asm.remeLvStus).filter(lu => toAry(lu[1]).includes(myUId)).map(lu => lu[0])
    ):[]); 
    
    const hasRemeRule = toAry(Exer?.ERemes).length;
    const hasReme = hasRemeRule > 0;

    const [ cache2d, useGetCache ] = useCaches(props);
    const thrNames = useGetCache('teachers', '*'); 
    const teacherName = getTeacherName(asm.userId, thrNames, ut, t);

    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);
    const [onAddMedia, setOnAddMedia] = useState();
    const [afterInit, setAfterInit] = useState(0);
    
    const [work, _setWork] = useEffectStateObj(props.work);
    const setWork = (valOrFun, caller) => {
        _setWork(w => isFunc(valOrFun)? valOrFun(w): valOrFun );
    };
    const [mark, setMark] = useEffectStateObj(props.mark);

    const uniQIds = useMemo(() => exerUniQIds(Exer), [Exer]);
    useCacheQtns(uniQIds);

    const ECtns = Exer?.ECtns;
    const fQtns = useMemo(() => flattenECtns(ECtns, qtns), [ECtns, qtns]);

    const [doEx, setDoEx] = useState(0);
    const [viewOnly, setViewOnly] = useState(0);

    const [leavePopVis, setLeavePopVis] = useState(false);
    const [askSubmit, setAskSubmit] = useState(0);
    const [asmTimeUp, setAsmTimeUp] = useState(0);
    const [startTime, setStartTime] = useState(work.startTime);
    const [remainTime, setRemainTime] = useState(0);
    const [timer, setTimer] = useState(null);

    const dataModified = useRef(0);

    const [minSaveUI, setMinSaveUI] = useState(0);
    const [saving, setSaving] = useState(0);

    const [gotoAtx, setGotoAtx] = useState(-1);

    const showEn = langIsEn(asm.ALang);
    const myWState = work?.WState;
    const myAState = asm?.AState;
    const myMStat = toObj(mark?.MStat);
    const myScore = toInt(myMStat?.mScore) + toInt(myMStat?.aScore);
    const qAnswered = toInt(myMStat?.hasAnswered);
    const _attTimes = toInt(asm.attTimes);

    const {EQcnt, EScore} = toObj(Exer);
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);

    const attTimes = (asm.attempt)? toInt(asm.attTimes): (hasM? 1: t('unlimited'));
    const QtnScore = toInt(mScore + aScore);

    const aExpired = isExpired(asm);
    const aPublished = myAState === _AST_PUBLISHED;
    const aWaitResult = myAState === _AST_WAIT_RESULT;
    const aInProgress = myAState === _AST_IN_PROGRESS;
    const wSub = myWState === _WST_SUBMIT;
    const wResume = myWState === _WST_RESUME;
    const closeReset = asm?.AResume === _ARS_CLOSE_RESET;
    const closeResume = asm?.AResume === _ARS_CLOSE_RESUME;
    const closeSub = asm?.AResume === _ARS_CLOSE_SUBMIT;    
    const canRetry = (!hasM) && ((_attTimes === 0) || (_attTimes > toInt(props?.work?._attemp)));
    const remeShowScore = isReme; //answerVis;
    const showScore = remeShowScore || aPublished || (wSub && ((asm?.showScores) === "sub"))
        || (aExpired && wSub && ((asm?.showScores) === "dead"))
        || (aExpired && !hasM && ((asm?.showScores) === "dead"));
    const showView = (!showScore) && (((aPublished || aExpired || aWaitResult) && !wSub) 
        || (wSub && (aWaitResult || (aInProgress && !canRetry)))
        || (!(aPublished || aExpired) && wSub && canRetry)
        );
    const showStartWorkBtn = !aPublished && !aWaitResult && (!myWState || ((canRetry && wSub) || wResume) );

    const passValue = useMemo(() => stuPassValue(asm, myUId), [asm, myUId]);
    const usrPass = useMemo(() => stuPassAsm(asm, mark), [asm, mark]); 
    
    const [ectnCnt, QtnCnt] = useMemo(()=>{ return ECtnsCountInfo(ECtns); },[ECtns]);
    const noEdit = ((aPublished || aExpired) && !wSub) || (wSub && aInProgress && !canRetry)
        || (aPublished && wSub);

    const hasTimeLimit = (asm?.limitMin)? 1: 0;        
    const defaultDone = Array.from({ length: ectnCnt } , () => ({qDone:0}));   
    
    const [remeMSId, remeMId ] = splitSMId(remeSMId);
    const mset = useTagMSets([remeMSId])[remeMSId];
    const meta = toObj(mset?.metas?.[remeMId]);
    const metaName = (toStr(meta.LVNum) + ' ' + lang2t(asm.ALang, meta.nameEn, meta.nameCt)).trim();
    //const metaName = testMathStr0;
    const prepareWorkData = () => {    
//console.error('prepareWorkData()', {aPublished, aExpired, wSub, closeReset, defaultDone});
        if (!aPublished && !aExpired) {
            //TODO: dont dirrect update work.xxx call setWork
            if (wSub) { // retry
                const _att = (work?._attemp || 0) + 1;
                work._attemp = _att; 
                work.resp = defaultDone;
                work.msUsed = 0;
            } else {
                if (closeReset) {
                    work.resp = defaultDone;
                } else {
                    if (!work.hasOwnProperty('resp')) work.resp = defaultDone;
                };
            };
        };
    };

    useEffect(()=>{
        setAfterInit(1);
        return () => {
            stopASHB();
            stopTimer();
        };
    },[]);

    const stopTimer = () => { if (timer) { clearTimeout(timer); } };

    const clickStartWork = e => {
        stopEvent(e);
        prepareWorkData();
        
        const st = timeStampNowGMT();
        let timeUsed = toInt(work?.msUsed) || toInt(work?.timeUsed) || 0;
        //const asmExpired = (myAsm?.timeEnd)?((toInt(myAsm?.time2beEnd) + bufferTime) >= now):0;
        const buffereTime = 0;
        //const hasTimeLimit = (asm?.limitMin)?1:0;
        const exTimeRemain = (asm?.timeEnd)?(toInt(asm?.time2beEnd) - st):0;
        const _minsToGo = toInt(asm?.minsToGo) * 60 * 1000;
        const minsToGo = (hasTimeLimit && exTimeRemain)?minVal(exTimeRemain, _minsToGo)
            :(hasTimeLimit && !exTimeRemain)?_minsToGo
            :exTimeRemain;
        const remainTime = minsToGo - timeUsed;
        let normal_tu = 0;
        if (hasTimeLimit && exTimeRemain) {
            if (_minsToGo > exTimeRemain) { normal_tu = 1; }
        } else if (hasTimeLimit && !exTimeRemain) {
            normal_tu = 1;
        };
        work.timeUpType = normal_tu;
        //console.log("time info", {hasTimeLimit,exTimeRemain,remainTime,minsToGo,timeUsed});
        if (hasTimeLimit || (exTimeRemain)) {
            //timeUsed = toInt(work?.msUsed) || toInt(work?.timeUsed) || 0;
            //setTimeUsed(timeUsed);
            if (timeUsed >= minsToGo ) {
                setAsmTimeUp(1);
                return;
            } else {
                setTimer(setTimeout( () => {
                    setAsmTimeUp(1);
                }, remainTime))                    
                //}, minsToGo - timeUsed))
            };
        };        
        setStartTime(st-timeUsed);
        setRemainTime(remainTime);
        //debugMode() && startASHB(); // todo: start heart beat and auto save for debug only
        dataModified.current = 1;
        forceAS('start'); // all cases has a start work record
        if (!closeReset) startASHB(); // close reset no heart beat
        setDoEx(1);
    };
    
    const clickViewEx = e => { stopEvent(e);
        xapiPost(dispatch, 'openAsm', {assignId, isReme});
        setWork(props.work,'clickViewWork is it really needed?');
        setViewOnly(isReme?viewEx_Corr:ViewEx_NoMark);
    };    
    const clickViewExMark = e => { stopEvent(e);
        xapiPost(dispatch, 'openAsm', {assignId, isReme});
        setWork(props.work,'clickResultWork is it really needed?');
        setViewOnly(ViewEx_Mark);
    };      
    const chkQtnState = () => {
        const result = [];
        const isEn = langIsEn(asm.ALang);
        const QonlyECtns = (_ECtns) => {
            const tmp = [];
            let eleIdx = 0;
            toAry(_ECtns).forEach((_ECtn) => {
                if (_ECtn.type ===_ExCtType_Qtn) {
                    const qids = toUniIdAry(toStr(_ECtn.QIds).split(','));
                    qids.forEach((qid) => {
                        tmp.push({qid,eleIdx});
                        eleIdx++;
                    });
                } else { eleIdx++; };
            });
            return tmp;
        };
        const ques = qtns; 
        const checkQ = (qID, count, idx) => {
            const qtn = ques[toStr(qID).trim()];
            const status = wQAnsState(qtn, work.resp[idx], isEn);
            //work.resp[idx].qDone = (status === QAS_ANSWERED)?1:0;
            work.resp[idx] = {...toObj(work.resp[idx]), qDone:((status === QAS_ANSWERED)?1:0)};
            result.push({title: count+1, status: status, edx:idx});
        };
        const Qonly = QonlyECtns(ECtns);
        Qonly.forEach( (qq, idx) => checkQ(qq.qid, idx, qq.eleIdx));
        return result;
    };
    //assignment-submitted-message
    const _doWhat = ([_AST_PENDING, _AST_WAIT_RESULT, _AST_IN_PROGRESS].includes(myAState))?'asmEdit'
        :[_AST_PUBLISHED, _AST_WAIT_RESULT].includes(myAState)?'asmView'
        :'';
    // role(student, teacher) + doWhat(asmView, asmEdit) + showResult(1,0)
    const [lock, preview, showPub, PVMode, doWhat, showResult, noSave, aHints] = //[1, 0, 1, 0, 'asmEdit']; 
        [1, 0, 0, (asm.AMode==='SCROLL_MODE')?1:0, _doWhat, asm?.AState===_AST_PUBLISHED, _doWhat!=='asmEdit', asm?.AHints];
        //[1, 0, asm.AMode==='SCROLL_MODE'?1:0, 0, 'asmView', asm?.AState===_AST_PUBLISHED, 1];
   
    const stopTsetETime = (st=0, resetR=0) => { if (st) { stopTimer(); }; };

    const onMediaUploaded = (mediaMap) => {
//console.log('onMediaUploaded', {mediaMap});
        setWork(_work => {
            const [work, ] = workMapMedias(_work, mediaMap, addLocalMedias);
            return work;
        }, 'onMediaUploaded back to work');
    };

    const [autoSaving, setAutoSaving] = useState('');
    const {aState, whySaveWk, saveWorkId} = toObj(autoSaving);
       const asyncSaveEff = async () => {
        await _saveWork(aState, whySaveWk, saveWorkId, 0);
    };

    if(newAutoSave){
        useEffect(() => {
            if(!saveWorkId){ return; } 
//console.log('Saving Effect', {...autoSaving, work});
            asyncSaveEff();
        },[saveWorkId]);
    }

    const startSaveWork = async (aState, whySaveWk, blocking) => {
        const saveWorkId = autoId();
//console.log('startSaveWork() started..', {blocking, newAutoSave, BlockAS, aState, whySaveWk, saveWorkId});
        if((!blocking) && newAutoSave){
            setAutoSaving({aState, whySaveWk, saveWorkId});
        }else{
            setAutoSaving(0); //stop auto saving
            return await _saveWork(aState, whySaveWk, saveWorkId, blocking);
       }
    }
    const stepSaveWk = (aState, whySaveWk, saveWorkId, blocking) => {
//console.log('stepSaveWk() ...', {aState, whySaveWk, saveWorkId, blocking});
        setAutoSaving(as => {
            const cont = (as?.saveWorkId === saveWorkId);
//console.log('stepSaveWk() :', {cont, aState, whySaveWk, saveWorkId, as});
            return cont? {aState, whySaveWk, saveWorkId:autoId()}: as;
        }); // only set save if not saving;
    }

    const _saveWork = async (aState, whySaveWk, saveWorkId) => {
        const [normalSave, isBack, isTimeUp, isAutoSave] = 
            [aState === 'normal_submit', aState === 'back_click', aState === 'timeup_submit', aState === 'auto_save', ];
//console.log('_saveWork', {saveWorkId, isAutoSave, isBack, isTimeUp});
        if(isBack && closeReset) {
            stopTsetETime(1, 0);
            return;
        };
        const workFinal = isTimeUp || normalSave || (isBack && closeSub);
        if (workFinal){
            stopTsetETime(1, 1);
        } else if (isBack && closeResume) {
            stopTsetETime(1, 0);
        } else if (isAutoSave) {
            stopTsetETime(0, 0);
        };
        setMinSaveUI(isAutoSave);
        work.isTimeUp = isTimeUp;
        work.WState = workFinal? _WST_SUBMIT: _WST_RESUME
        setWork({...work}, ['perpare_save_work', saveWorkId]);
        await addWorkMath3Str(fQtns, work.resp, showEn);
//console.log('work.resp', work.resp);

        const [workToSave, errs] = normalizeWIP(work);
        if (hasErr(errs)){ console.error({errs}); return 'error before upload ex'; } 

        const save_full = async () => {
//console.error('save_full');
            //await saveWorkAndMedias(dispatch, work, mark, getMediaDLs, whySaveWk, setUpMsg);
            setSaving(1);
            const mediaMap = await saveWIPMedias(dispatch, workToSave, getMediaDLs, addLocalMedias, setUpMsg);
//console.error('save_full mMap', mediaMap);
            if(objKeys(mediaMap).length){
                setWork(_work => {
                    const [mapWork, ] = workMapMedias(_work, mediaMap, addLocalMedias);
                    //if (isAutoSave) setS3IdBackToEx(mapWork?.resp); 
                    return mapWork;
                }, 'full Save Work after Medias Up');
            }
            const putWorkerrs = await callApiPutWork(dispatch, workToSave, isReme, whySaveWk, mediaMap, addLocalMedias);
            setSaving(0);
            if (hasErr(putWorkerrs)){ console.error({putWorkerrs}); return 'error while upload ex'};
            if(isBack && props.reload){ props.reload(); } // reload only for doEx back click
        };

        const save_step = async () => {
//console.error('save_step');
            setSaving(1);
            const mediaMap = await saveWIPMedia1(dispatch, workToSave, getMediaDLs, addLocalMedias, setUpMsg);
            if(mediaMap){
                 onMediaUploaded(mediaMap); // only upload first media
                 if(newAutoSave){
                    stepSaveWk(aState, 'step save work', saveWorkId);
                    return 'Save Step';
                 }
            } 
            const putWorkerrs = (!mediaMap) &&  await callApiPutWork(dispatch, workToSave, isReme, whySaveWk, {}, addLocalMedias); // no local media, save  
            setSaving(0);
            if (hasErr(putWorkerrs)){ console.error({putWorkerrs}); return 'error while upload MOrEx'};
            if(isBack && props.reload){ props.reload(); } // reload only for doEx back click
        };

        const saveErr = ((!isAutoSave) || BlockAS)? await save_full(): await save_step();
        if(saveErr) console.error({saveErr});
    };
       
    const doMySave = async (whySaveWk) => { 
        if (dataModified.current) {
            dataModified.current = 0;
            await startSaveWork('auto_save', whySaveWk, 0); // work state set to resume
        }else{
            console.error('auto save called without modified');
        };
    };
    const callApiHB = async (t) => { asyncApiCall_(dispatch, '/putWork', {heartBeat: work.assignId, isReme}, {}); };

    const {startASHB, stopASHB, debounceSave, forceAS} = useASHBEffect(doMySave, callApiHB);

    const chgQSave = () => { if ((!noEdit) && dataModified.current) { forceAS('changed'); }; };

    const leavePopCancel = () => { setLeavePopVis(0) };
    const leavePopYes = async () => {
        stopASHB();
        setLeavePopVis(0);
        await startSaveWork('back_click', 'back_click', 1);
        setDoEx(0);
    };

    const setStWork = (key, obj, debounce=1, idx=-1) => {
        if (noEdit || idx < 0) return;
        
        if (afterInit) {
            dataModified.current = 1;
            if(debounce) debounceSave(); // restart ASHB
            setWork(_work => ( {..._work, [key]: deepCopy(obj)} ), ['setStWork (DOEX answer change?)',key, idx, {obj}] );
        };
    };

    const setTeMark = (key, obj) => { setMark({...mark, [key]:deepCopy(obj)}); };

    const [upMsg, setUpMsg] = useState();
    const wSubmit = async (doWhat='normal_submit') => {
        stopASHB();
        await startSaveWork(doWhat, 'workSubmit', 1);
        setDoEx(0);
    };

    const clickBack = () => {
        stopASHB();
        if (asm.AState === _AST_PUBLISHED) {
            const backRpt = props.backToRpt;
            backRpt && backRpt();
            return;
        };
        const cb = props.clickBack;
        cb && cb();
    };

    const doClose = () => {
        if (viewOnly) { setViewOnly(0); clickBack(); };
        if (_doWhat !== 'asmEdit') { stopASHB(); setDoEx(0); } else { setLeavePopVis(1); }
    };

    // endpage -> pageWork.gotoQ -> set gotoAtx -> doEx setAtIdx(gotoAtx) -> atIdx -> header idx -> doEx.gotoQ
    const gotoQ = (idx) => { setAskSubmit(0); setGotoAtx(idx); };

    const closeAskSubmit = backToTop => { setAskSubmit(0); if (backToTop) clickBack();  };

    const remeInf = {isReme, hasRemeRule, remeLvs, remeSMId, remeMId };
    const exresp = toAry(work?.resp).map(r => r?.qresp || r );
    const debugInfo = () => {
        return <div className='flexRowCenter' style={{ color: 'black', gap: 2, flexWrap:'wrap'}}>
            <BtnPopDev txt="remeInf">{preJSUnsort(remeInf, 3)}</BtnPopDev>
            <BtnPopDev txt="asm">{preJS(asm, 3)}</BtnPopDev>
            <BtnPopDev txt="Exer">{preJS(Exer, 3)}</BtnPopDev>
            <BtnPopDev txt='qtns'>{preJS(qtns, 3)}</BtnPopDev>
            <BtnPopDev txt='work'>{preJS(work, 3)}</BtnPopDev>
            <BtnPopDev txt='workresp'>{preJS(debugTrimResp(work?.resp), 3)}</BtnPopDev>
            <BtnPopDev txt='mark'>{preJS(mark, 3)}</BtnPopDev>
            <BtnPopDev txt='meta'>{preJS(meta, 3)}</BtnPopDev>
            <BtnPopDev txt='mset'>{preJS(mset, 3)}</BtnPopDev>
            {false && preJS(exresp, 3)}
        </div>
    };
    const hideHeader = (askSubmit || asmTimeUp);
    const [hasEn, hasCt] = [showEn, !showEn];
    const pvProps = {close:doClose, submit:()=>{setAskSubmit(1)}, chgQSave,
        isThr, isStt, uRole, doWhat, preview, PVMode, showPub, showEn, hasEn, hasCt, lock, 
        ECtns, qtns, useCacheQtns, showResult, noSave, aHints, hideHeader, isReme, answerVis,
        /*opts, setOpts,*/ setOnAddMedia, mediaDLs, getMediaDLs, addLocalMedias, dispatch, hasTimeLimit,
        work, allStResp:work?.resp, setStWork, allTeResp:mark?.resp, stMark:mark, setTeMark, startTime, remainTime,
        gotoAtx, setGotoAtx};

    // just show student answer, showResult = 0
    // show student answer correctness check (with teacher manual mark/comments), showResult = 1
    const propsForViewOnly = 
        {...pvProps, lock:1, preview:0, showPub:0, PVMode:(asm.AMode==='SCROLL_MODE')?1:0, 
        doWhat:'asmView', showResult:0, noSave:1};

    if(viewOnly > 0) return <><DoEx {...propsForViewOnly} showCorr={viewOnly === viewEx_Corr? 1: 0} showResult={viewOnly === ViewEx_Mark? 1: 0}/></>;
    //{doEx?<div className={"container-fluid"} style={{height:"100vh"}}>
    if(doEx || askSubmit || asmTimeUp) return <> 
        {doEx?<div className="PageAsmWork flexColStart min-vh-100" style={{height:"100vh"}}>
            <DoEx {...pvProps} />
            <LayerLocalMedia clickClose={()=>setOnAddMedia(0)} {...onAddMedia} isAT={0} /></div>
        :''}
        <LayerAskQuitWork {...{t, asm, leavePopVis, leavePopCancel, leavePopYes}} />
        {(askSubmit || asmTimeUp)?<CpAssignmentEndingPage asmTimeUp={asmTimeUp} gotoQ={gotoQ}
            {...{dispatch, wSubmit, respInfo: chkQtnState(), close:closeAskSubmit, hasReme, isReme }} />:''}        
        {saving? 
            minSaveUI?<CpNonBlockProgress {...{head:'Saving Assignment', upMsg}} />
            :<LayerUpMedia {...{head:'Saving Assignment', upMsg, mini:minSaveUI, transBD: minSaveUI}} />
            //ScreenMask(<LayerUpMedia {...{head:'Saving Assignment', upMsg, mini:minSaveUI, transBD:minSaveUI }} />, {backgroundColor:'rgba(255,255,255,70%)'})
        :''}
    </>;
/*
        <LayerUpMedia {...{head:'Saving Assignment', upMsg:{msg:'Uploading Question...', ttl:5, idx:2}}} mini={1} transBD={1}/>
        {ScreenMask(<CpMediaModal show={1} progress={100} miniLayout={true} transBD={1} />, {backgroundColor:'rgba(255,255,255,70%)'}):''}
*/
    const exName = langIsEn(lang)? (Exer.ENameEn || Exer.ENameCt): (Exer.ENameCt || Exer.ENameEn);
    const asm2 = {...asm, work};
    return <div className='PageAsmWork ATQtnSingleContainer3 sequenceListAnimation'>
        <div className='ATQtnHeader'><div className='flexRowBetween'>
            <div className='flexRowStartFit f20'>
                {iconButton('', 'general/previous', 'white', 'transparent', clickBack, true, {transform:'scale(1.5)'})}
            </div>                
            <div/>
        </div></div>        
        {debugInfo()}

        <div className={"d-flex gap-4 flex-column flex-wrap w100 py-5"}>
            <div className={"d-flex gap-3 flex-column"}>
                {/* follow up color #FFF0EC  {t("assignment-follow-up-exercise")}  */}
                <div className={"fs-2 semi-bold text-preview-primary text-center"}>
                    <div className="flexRowCenter gap-2" style={{flexWrap:"wrap"}}>
                    {isReme? <>{t("assignment-follow-up-exercise")}
                    {/*isReme?<span className="workFollowName"><CpMJX>{"work follow meta name"}</CpMJX></span>:""*/}
                    <span className="workFollowName"><CpMJX>{metaName}</CpMJX></span></> :""}
                    {!isReme? exName: ""}
                    </div>
                </div>
                <div className={"d-flex w-100 justify-content-center gap-2"}>
                    <span style={{width: "1.5rem", height: "1.5rem"}}
                        className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn"}>
                        {CpLangIco(langIsEn(asm.ALang))}
                    </span>
                    {isReme? <TagStuRemePass {...{t, fill:0, pass:usrPass}} />:''}
                    <TagCountdownTag asm={asm2}/>
                    <TagWorkSubmitTag {...{asm, work}}/>       
                    {isReme? <TagRemeLvs {...{remeLvs}}/>: ''}
                    {/*<TagStuProgressTag AState={asm.AState} WState={work.WState} hasM={hasM}/>
                    <TagMarkingTag {...{asm}}/>*/}
                </div>
            </div>
            <div className={"d-flex gap-3 flex-column justify-content-center text-center"}>
                <table className={"text-center w-100"}><tbody>
                    {(showStartWorkBtn && asm?.AResume)?<tr><td colSpan={2} className={"text-center"}>
                        <div className={"d-flex justify-content-center"}>
                        <div className={"message-box rounded-light semi-bold warning"}>
                            { closeReset ? t('warning.warning_reset')
                                :closeResume ? t('warning.warning_resume')
                                :closeSub ? t('warning.warning_count_as_submit')
                                :''}
                        </div>
                        </div>
                    </td></tr>:''}
                    {trow(t(getTransMap("assignment-assigned-by", isStt)),
                        <span className={" d-flex align-items-center gap-2"}><CpIco src={IconList.general.avatar}/>{teacherName}</span>)}
                    {trow(t("assignment-start"),  getT2bSrt(asm))}
                    {trow(t("assignment-due"),  getT2bEnd(asm))}
                    {trow(t("assignment-time-limit"), ((asm?.limitMin)?(asm.minsToGo+' '+(asm.minsToGo>1?t('unit.mins'):t('unit.min'))):'--'))}
                    {trow(t("assignment-no-of-questions"), QtnCnt)}
                    {trow(t("assignment-attempted"), (work._attemp - (wSub? 0: 1)) + ' / ' + attTimes)}
                    {trow(t("assignment-last-submission"), ((work?.dateSub)?EPUITime3(work.dateSub):"--"))}
                </tbody></table>
            </div>
            {/*for dim: text-dim-350*/}
            <span className={"d-flex gap-5 justify-content-center w-100"}>
                <span className={`d-flex flex-column align-items-end align-items-md-center`}>
                    <span className={`semi-bold fs-8`}>{t(isReme?"assignment-mastery-score":"assignment-answered")}</span>
                    {isReme?<span className="f24 semi-bold" >{toInt(passValue)}%</span>:
                    <><span className={`d-flex align-items-end gap-2 semi-bold`}>
                        <span className={`fs-4 line-height-normal`}>{qAnswered}</span>
                        <span>/</span>
                        <span>{QtnCnt}</span>
                    </span></>}
                </span>
                <span className={"vr d-none d-md-block"}/>
                    <span className={`d-flex flex-column align-items-end align-items-md-center`}>
                    <span className={`semi-bold fs-8 text-nowrap`}>{t(isReme?"assignment-follow-up-score":"assignment-score")}</span>
                    <span className={`d-flex align-items-end gap-2 semi-bold`}>
                        <span className={`fs-4 line-height-normal`}>
                            {showScore?(isReme?(toPercent1(myScore, QtnScore)+"%") :myScore) : (isReme?"0%":"0")}</span>
                        <span>/</span>
                        <span>{isReme? "100%": QtnScore}</span>
                    </span>
                </span>
            </span>
            {(debugMode() && wSub)? <span className={"text-center w-100"}>
                <Button onClick={clickViewEx} className={"btn px-5"}>{t("debug view")}</Button>
                <Button onClick={clickViewExMark} className={"btn px-5"}>{t("debug Result")}</Button>                
            </span>:''}

            <div className="flexRowCenterFit" style={{gap:"2rem"}}>
                {isReme?<>
                    {wSub && (!usrPass)? <span className={"text-center"}>
                        <Button onClick={clickStartWork} className={"PAW-BTN-RETRY btn btn-lg btn-preview-primary px-5"}>{t("assignment-cta-retry")}</Button>
                    </span>: ''}
                    <span className={"text-center"}>{wSub 
                        ?<Button onClick={usrPass? clickViewExMark: clickViewEx} className={"PAW-BTN-VIEW btn btn-lg btn-preview-primary px-5"}>{t("assignment-cta-view")}</Button>
                        :<Button onClick={clickStartWork} className={"PAW-BTN-START btn btn-lg btn-preview-primary px-5"}>{t("assignment-cta-start")}</Button>
                    }</span>
                </>: <>
                    {showView? <span className={"text-center"}>
                        <Button onClick={clickViewEx} className={"PAW-BTN-VIEW btn btn-lg btn-preview-primary px-5"}>{t("assignment-cta-view")}</Button>
                    </span>:''}
                    {showScore? <span className={"text-center"}>
                        <Button onClick={clickViewExMark} className={"PAW-BTN-RES btn btn-lg btn-preview-primary px-5"}>{t("assignment-cta-result")}</Button>
                    </span>:''}            
                    {showStartWorkBtn? <span className={"text-center"}>
                        <Button onClick={clickStartWork} className={"PAW-BTN-START btn btn-lg btn-preview-primary px-5"}>
                            {t((wResume && !closeReset)? "assignment-cta-resume": wSub? "assignment-cta-retry": "assignment-cta-start")}
                        </Button>
                    </span>:''}
                </>}
            </div>
        </div>
    </div>
});
export default  PageAsmWork;

const LayerAskQuitWork = props => {
    const {t, asm, leavePopVis, leavePopCancel, leavePopYes} = props; 

    const closeOrBackMsg = (asm?.AResume === _ARS_CLOSE_RESET)? t("assignment-reset-leave-message")
        : (asm?.AResume === _ARS_CLOSE_RESUME)? t("assignment-resume-leave-message")
        : t("assignment-count-as-submitted-leave-message");

    return <CpSharedModal show={leavePopVis} scrollable={false} overflowbody={"false"} 
        footer={<div className={"d-flex gap-2 justify-content-center w-100"}>
            <Button variant="gray-body-color w-100 border" onClick={leavePopCancel}>{t("cancel")}</Button>
            <Button variant="exercise-third-btn btn-block w-100" onClick={leavePopYes}>{t("yes")}</Button>
        </div>}>
        <div className={"px-3 pt-4 bg-dim-100 rounded-top"}>
            <div className={"d-flex flex-column gap-4"}>
                <div className={"d-flex flex-column align-items-start gap-3 mb-4 px-3"}>
                    <span className={"semi-bold"}>
                        {closeOrBackMsg}<br/>
                        {t("assignment-leave-message")}
                    </span>
                </div>
            </div>
        </div>
    </CpSharedModal>
};

const trow = (head, body) => <tr>
    <td className={"text-end w-50 text-preview-primary semi-bold fs-5 pe-3 py-2"}>{head}</td>
    <td className={"text-start w-50 fs-5 ps-3 py-2"}>{body}</td>
</tr>;

const useEffectStateObj = (init) => {
    const [val, setVal] = useState(toObj(init)); //set state with init val
    useEffect(() => setVal(toObj(init)), [init]); // auto update state if init val change 
    return [val, setVal]; //return val and setter as standard use State    
};

const normalizeWIP = _work => {
    const opts = {};
    const trace = () => {};
    const work = NormalizeWork(_work, opts );
    const errs = validateWork(work, opts, trace);
    return [work, errs];
};

const saveWIPMedias = async (dispatch, work, getMediaDLs, addLocalMedias, setUpMsg) => {
    const [nilw, mids, drawBlobs] = workMapMedias(work, {}, addLocalMedias);
    const mediaMap = await upLocalMedias(dispatch, mids, getMediaDLs, setUpMsg, drawBlobs);
    return mediaMap;
};

const saveWIPMedia1 = async (dispatch, work, getMediaDLs, addLocalMedias, setUpMsg) => {
    const [nilw, mids, drawBlobs] = workMapMedias(work, {}, addLocalMedias);
    const lms = localMedias(mids, getMediaDLs);
    const filesAndDraws =  {...lms, ...drawBlobs};
    const local1 = objEntries(filesAndDraws)[0];
//if(local1) console.log('saveWIPMedia1 up', local1);
//console.log('saveWIPMedia1 ', {mids, lms, drawBlobs});
    return local1 && await upEMedias(dispatch, Object.fromEntries([local1]), setUpMsg);
};

const callApiPutWork = async (dispatch, _work, isReme, caller, mediaMap, addLocalMedias) => {
    const [work, nilm, nilBlobs] = workMapMedias(_work, mediaMap, addLocalMedias);
    const [res, err] = await asyncApiCall_(dispatch, '/putWork', {caller, isReme, work: packWork(work)});
    if(err) console.error('saveWIP', err);      
    return err;
};

const CpNonBlockProgress = props => {
    const _upMsg = toObj(props.upMsg);
    const msg = toStr(_upMsg.msg);
    const ttl = Math.max(1, toInt(_upMsg.ttl));
    const idx = toInt(_upMsg.idx);
    return <div style={{ zIndex:99999999, position:'fixed', bottom:'12px', right:'8px', 
        backgroundColor:'#fff', borderRadius:'6px', margin:'0', padding:'8px 24px', boxShadow:'0 0 1px 1px rgba(0, 0, 0, 0.2)'}}>
        <div>{msg}: ( {idx} / {ttl} )</div>
        <ProgressElement {...{progress: Math.floor((idx - 0.5) * 100 / ttl), miniLayout:1}} />
    </div>
};

const NormalizeWork = (w, opt, trace) => { return w; };
const validateWork = (w, opt, trace) => { return {}; };

const logResp = resp => isAry(resp)? resp.map(r => console.log(r)): console.log(resp);                     
const debugResp = resps => toAry(resps).map(r => logResp(r?.qresp || r));

const testMathStr0 = 'test meta string test meta string test meta string';
const testMathStr1 = '<p>hello<math xmlns="http://www.w3.org/1998/Math/MathML"><mfenced><mtable><mtr><mtd><mn>1</mn></mtd><mtd><mn>2</mn></mtd></mtr><mtr><mtd><mn>1</mn></mtd><mtd><mn>2</mn></mtd></mtr><mtr><mtd><mn>1</mn></mtd><mtd><mn>2</mn></mtd></mtr><mtr><mtd><mn>1</mn></mtd><mtd><mn>2</mn></mtd></mtr><mtr><mtd><mn>1</mn></mtd><mtd><mn>2</mn></mtd></mtr><mtr><mtd><mn>1</mn></mtd><mtd><mn>2</mn></mtd></mtr></mtable></mfenced></math></p>';
const testMathStr = '<p>hello world apple <math xmlns="http://www.w3.org/1998/Math/MathML"><msqrt><mi>a</mi><mi>a</mi></msqrt><msqrt><mi>a</mi><mi>a</mi></msqrt></math></p>';