import React, { useEffect, useState, useRef } from 'react';
import * as SQT from '../../consts/ATSysQType';
import * as MCAC from '../../consts/ATQtnAnsTypeMCQ';
import * as UI from '../../libs/libUI';
import { deepCopy } from '../AppUtil';
import { iconButton } from '../components/CpATIcoBtn';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import CpATImage from '../components/CpATImage';
import MCChk from '../components/CpMCTblChk';
import { _ansClass } from '../../AppExPFUser/EPExercise/constQtnTemplate';
import { toAry } from '../../libs/libType';

const shuffleArray = (array) => {    
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    };
};

const resetqCorrect = (arr, shuffle=false,resp=undefined) => {
    //console.log('mcq arr, shuffle, resp:',arr, shuffle, resp);
    const result = deepCopy(arr);
    const checkChooseGeneral = (reultArr, cii) => {
        return toAry(reultArr).findIndex((x)=>{return x.includes(","+cii)})>=0;
    };    
    const stResult = resp?.qresp || undefined;
    const sequence = resp?.sequence || undefined;

    result.forEach((ans,ii)=>{
        if (stResult && checkChooseGeneral(stResult,ii)) {ans.correct=1}
        else ans.correct = 0;
        ans.orgIdx=ii;
    });

    if (shuffle) {
        if (sequence) {
            
            const tmp = [];
            sequence.forEach(si=>{tmp.push(result[si]);});
            return tmp;
        } else {
        
        shuffleArray(result);
    }};
    
    return result;
};

const resettCorrect = (arr, resp=undefined) => {
    
    const result = deepCopy(arr);
    const qresp = resp?.qresp || undefined;
    const checkChooseMC = (qresp, cii, ckk) => {
        return qresp.findIndex((x)=>{return(x===(cii+","+ckk))})>=0;
    };
    result.forEach((row,ii)=>{
        row.forEach((col,kk)=>{
            if (qresp && checkChooseMC(qresp,ii,kk)) {col.correct=1}
            else {col.correct=0;}
        });
    });
    return result;
};  

const extractqChoose = (arr) => {
    const qresp = [];
    const sequence = [];
    arr.forEach((ans,ii)=> {
        if (ans.correct) qresp.push(ii+","+ans.orgIdx);
        sequence.push(ans.orgIdx);
    });
    if (qresp.length > 0) return {qresp,sequence}
    else return {sequence};
};

const extracttChoose = (arr) => {
    const qresp = [];
    arr.forEach((row,ii)=> {
        row.forEach((col,kk)=>{ if(col.correct) qresp.push(ii+","+kk)})
    });
    if (qresp.length > 0) return {qresp}
    else return {};
};

const CpQDoMCQ = (props) => {
    const { Q, fullQ, mediaDLs, getMediaDLs } = props;
    const { containerClass, editable, preview, asmView, asmEdit, doEdit,  
            isStudent, showResult, showCorr, mini=0, setStudentResp, studentResp } = props;
    const [afterInit, setAfterInit] = useState(0);
    const [scrollArrows, setScrollArrows] = useState({left:false, right:false});
    const refTansDiv = useRef(null); // for control scrolling

    const mcChoice = fullQ?.SQType?fullQ?.SQType:SQT?.__SYSQSubType_MCS;
    const isMCStandard = mcChoice === SQT.__SYSQSubType_MCS;
    const ansChoice = fullQ?.ansChoice?fullQ?.ansChoice:(mcChoice===SQT?.__SYSQSubType_MCS?MCAC?.__MCAC_ACG:MCAC?.__MCTT_TTC);
    const shuffleAnswer = ((mcChoice===SQT?.__SYSQSubType_MCS) && 
        (ansChoice !== MCAC?.__MCAC_ACB) && (fullQ?.shuffleAnswer?fullQ?.shuffleAnswer:0));
    const orgqAnswers = deepCopy(Q?.qAnswers||[]);
    const orgtAnswers = deepCopy(Q?.tAnswers||[]);
    const doFlag = preview || asmView || asmEdit;
    const [myAnsArr, setMyAnsArr] = useState((doFlag && isMCStandard)?resetqCorrect(orgqAnswers,shuffleAnswer,studentResp):orgqAnswers);
    const [mytAnsArr, setMytAnsArr] = useState((doFlag && !isMCStandard)?resettCorrect(orgtAnswers,studentResp):orgtAnswers);
    const stResp = studentResp?.qresp;
    const stCorr = studentResp?.corr;
    const multiAnswer = fullQ?.multiAnswer?fullQ?.multiAnswer:0;   
    
    const qData = Q?.qData || "";
    const ansClass = ansChoice? _ansClass[ansChoice] : _ansClass['ACG'];
    const edProps = {mediaDLs, getMediaDLs};

    useEffect(()=>{
        checkScrollArrow();
        setAfterInit(1);
    },[]);
    
    useEffect(()=>{
        if (afterInit && isStudent && setStudentResp && !showResult) {
            
            const qresp = extractqChoose(myAnsArr); // mc { qrest, sequence }
            const tresp = extracttChoose(mytAnsArr); // table { qrest }
            const extInfo = isMCStandard?{...qresp}:{...tresp};
            setStudentResp({...studentResp, ...extInfo});
        };
    },[ myAnsArr, mytAnsArr]);

    const ddClick = (e, ii) => {
        UI.stopEvent(e);
        if (!doEdit) return;
        
        if (fullQ.multiAnswer) myAnsArr[ii].correct = ((myAnsArr[ii].correct )? 0 : 1);
        else myAnsArr.forEach((ans, jj) => { ans.correct = (ii === jj ? 1 : 0) });
        
        setMyAnsArr([...myAnsArr]);       
    };

    const tAnsRowHeadClick = (e,ii) => {
        UI.stopEvent(e);
        if (!doEdit) return;
        if (fullQ.multiAnswer) mytAnsArr[ii][0].correct = mytAnsArr[ii][0].correct ? 0 : 1;
        else mytAnsArr.forEach((row, jj) => { row[0].correct = (ii === jj ? 1 : 0) });
        setMytAnsArr([...mytAnsArr]);
    };

    const setCellCorrect = (v,ii,kk) => {
        if (!fullQ.multiAnswer) mytAnsArr[ii].forEach((ans)=>{ans.correct=false});
        mytAnsArr[ii][kk].correct = v;
        setMytAnsArr([...mytAnsArr]);
    };

    const setRadioCorrect = (v, ii, kk) => {
        mytAnsArr[ii][0].correct = v;
        setMytAnsArr([...mytAnsArr]);
    };

    const checkScrollArrow = () => {
        
        let right = false, left = false;
        const rr = refTansDiv.current;
        if (rr) {
            if (rr.scrollLeft > 0) left = true;
            if ((rr.scrollLeft + rr.clientWidth) < rr.scrollWidth) right = true;
            setScrollArrows({left, right});            
        };
    };

    const scrollTDiv = (delta) => {
        const factor = 200;
        const rr = refTansDiv.current;
        if (rr) {
            rr.scrollLeft = rr.scrollLeft + (delta * factor);
        };
    };     

    // mc standard
    const customRender = (obj, ii) => {
        let exClass = (obj.correct? " Sel" : "") + (doFlag?" preview":"");  
        if (showResult || showCorr) {
            /*
            const choose = stResp && stResp.findIndex((x)=>{
                return x.includes(ii+",");
            })>=0;
            */
            const chooseIndex = stResp ? stResp.findIndex((x)=>{ return x.includes(ii+","); }) : -1;
            const choose = chooseIndex >= 0;
            if (showCorr) {
                if (choose) {
                    if (stCorr[chooseIndex]) { exClass = ' ansCorrect' }
                    else exClass = ' ansWrong';
                };                
            } else {
                if (orgqAnswers[obj.orgIdx].correct) {
                    exClass = ' ansCorrectNotChosen';
                    if (choose) exClass = ' ansCorrect';
                } else if (choose) exClass = ' ansWrong';
            };
        };

        if (ansChoice === MCAC.__MCAC_ACB) {
            return <div className={"f32 bold DDQtnLabel " + exClass}
                    onClick={(e) => ddClick(e, ii)}>
                {String.fromCharCode(65 + ii)}
            </div>;
        }; 
        
        return <>
            <table key={"DDtb" + ii} className={"DDcontentTbl" + (editable ? "" : " unclickable")}
                onClick={(e) => ddClick(e, ii)}>
                <tbody><tr>
                    <td className={"DDtd1"+exClass + (exClass===' ansCorrectNotChosen'?'2':'')}>
                        <div className="flexColCenter DDCharHead">{String.fromCharCode(65 + ii)}</div>
                    </td>
                    <td className={"DDtd2"+exClass} style={{borderLeft:'none'}}>
                        <div className={"DDQtnEditRow"}>
                            {obj.type===MCAC.__MCAT_ATI?<CpATImage id={"img"+ii} media={obj.data} 
                                editable={false} {...edProps}/>:
                            <QEditorReadOnly {...props} data={obj.data} {...edProps}/>}
                        </div>
                    </td>
                </tr></tbody>
            </table></>
    };

    // mc table
    const tableAnsRender = (_arr) => {
        const _tblHead = (row, ii) => {
            return <tr key={'tr'+ii}>
                {row.map((cell, kk)=>{
                    const index = ii+'-'+kk;
                    if (ii===0 && kk===0 && ansChoice === MCAC.__MCTT_TTB) 
                        return <th className={'ATQtnTableAnsth1 fixedCol' + (scrollArrows.left?' rightShadow':'')} key={'th'+index} style={{border:'none'}}></th>; // top left cell, empty
                    return <th className={'ATQtnTableAnsth2 ' + (kk===0?' fixedCol':'') + (scrollArrows.left?' rightShadow':'')} key={'th'+index}><div className='ATQtnTableAnsCell'>
                        <QEditorReadOnly {...props} data={cell.data} noBackground={true} {...edProps}/>
                    </div></th>
                })}
            </tr>;
        };

        const _tblRow = (row, ii) => {
            return <tr key={'tr'+ii}>
                {row.map((cell, kk)=>{                    
                    const index = ii+'-'+kk;
                    const firstCol = (kk===0);
                    // char start from 65 - 1, row 1
                    let exClass = (cell.correct ? " Sel" : "") + (doFlag?" preview":"");
                    if (firstCol && ansChoice === MCAC.__MCTT_TTB) { 
                        if (showResult || showCorr) {              
                            //const choose = stResp && stResp.findIndex((x)=>{return(x===(ii+","+kk))})>=0;
                            const chooseIndex = stResp ? stResp.findIndex((x)=>{return(x===(ii+","+kk))}) : -1;
                            const choose = chooseIndex >= 0;
                            //console.log({showCorr, ii, kk, chooseIndex, choose});
                            if (showCorr) {
                                if (choose) {
                                    if (stCorr[chooseIndex]) { exClass = ' ansCorrect' }
                                    else exClass = ' ansWrong';
                                };
                            } else {
                                if (orgtAnswers[ii][kk].correct) {
                                    exClass = ' ansCorrectNotChosen';
                                    if (choose) exClass = ' ansCorrect';
                                } else if (choose) exClass = ' ansWrong';
                            };
                        };                        
                        return <td className={'ATQtnTableAnsLabel fixedCol' + (scrollArrows.left?' rightShadow':'')}
                            key={'td'+index} onClick={(e) => tAnsRowHeadClick(e, ii)}>
                            <div className='flexRowCenter'>
                                <div className={"flexColCenter AnsLabelCharHead " +exClass}>
                                    {String.fromCharCode(64 + ii)}</div>
                            </div>
                        </td>;
                    };
                    let tickChk = cell.correct;
                    let chkColor = (doFlag?'#5d52ce':'#4eae84');
                    let chkColor2 = chkColor;
                    let dotted = false;
                    if ((showResult || showCorr) && (ansChoice === MCAC.__MCTT_TTC)) {
                        //const choose = stResp && stResp.findIndex((x)=>{return(x===(ii+","+kk))})>=0;
                        const chooseIndex = stResp ? stResp.findIndex((x)=>{return(x===(ii+","+kk))}) : -1;
                        const choose = chooseIndex >=0;   
                        //console.log({showCorr, ii, kk, chooseIndex, choose});
                        if (showCorr) {
                            if (choose) {
                                if (stCorr[chooseIndex]) { chkColor = '#4eae84'; chkColor2 = chkColor; dotted = false; }
                                else { chkColor = '#e94929'; chkColor2 = chkColor; }
                            };
                        } else {
                            if (orgtAnswers[ii][kk].correct) {
                                tickChk = true;
                                dotted = true;
                                chkColor = 'rgba(194,219,198,1)';
                                chkColor2 = '#4eae84';
                                if (choose) {chkColor = '#4eae84'; chkColor2 = chkColor; dotted = false;}
                            } else if (choose) {chkColor = '#e94929'; chkColor2 = chkColor;} 
                        };
                    };
                    return <td className={'ATQtnTableAnstd ' + (firstCol&&(ansChoice!==MCAC.__MCTT_TTB)?' fixedCol':'') + (scrollArrows.left?' rightShadow':'')} key={'td'+index}
                        style={(firstCol&&(ansChoice!==MCAC.__MCTT_TTB)?{backgroundColor:'white', zIndex:100}:{})}>
                    <div className='ATQtnTableAnsCell'>
                        {(firstCol || ansChoice === MCAC.__MCTT_TTB) && <QEditorReadOnly {...props} data={cell.data} noBackground={true} {...edProps}/>}
                        {!firstCol && ansChoice === MCAC.__MCTT_TTC && 
                            MCChk(tickChk,(v)=>setCellCorrect(v,ii,kk),'ttchk'+index,doEdit,chkColor,chkColor2,dotted,multiAnswer)}
                        {!firstCol && ansChoice === MCAC.__MCTT_TTR && UI.radio1(kk+"",(v)=>setRadioCorrect(v,ii,kk),row[0].correct,'qrow'+ii,doEdit)}
                    </div></td>})
                }
            </tr>;    
        };

        const _content = (arr) => {
            if (Array.isArray(arr)) {
                return arr.map((row, ii)=>{
                    const colCount = row.length;
                    
                    if (ii===0) return _tblHead(row, ii);
                    else return _tblRow(row, ii);
                });
            } else return <div></div>;
        };

        return <div className='ATQtnTableContainer' ref={refTansDiv} onScroll={checkScrollArrow}>
            <table className='ATQtnTableAns'><tbody>
                {_content(_arr)}
            </tbody></table>
        </div>
    };

    const miniStyle = mini?{gridTemplateColumns:'100%'}:{};
    return <div className={containerClass}>
        <div className='tabDoQContent2' id="mathDisplay">
            <div className='tabDoQContent3'>
                <QEditorReadOnly {...props} data={qData} {...edProps}/>
            </div><p/>
            {(mcChoice===SQT?.__SYSQSubType_MCS) && <div className={"DDTopContainer " + ansClass} style={miniStyle}>
                {myAnsArr.map((obj, ii) => {
                    return <div key={"qtdo" + ii} className={"DDoptionContainer " + (obj.correct?" Sel":"")}>
                        {customRender(obj, ii)}
                    </div>
                })}
            </div>}
            {(mcChoice===SQT?.__SYSQSubType_MCT) && <div className='ATQtnTableContainer'
                style={{padding:'0 20px 0 20px'}}>{tableAnsRender(mytAnsArr)}
                {scrollArrows.right&&<div className='rightTArrow'>{scrollBtn('general/scrollRight',()=>scrollTDiv(1))}</div>}
                {scrollArrows.left&&<div className='leftTArrow' >{scrollBtn('general/scrollLeft',()=>scrollTDiv(-1),false)}</div>}
            </div>}
        </div>
    </div>;
};

export default CpQDoMCQ;

const scrollBtn = (icon, cb, right=true) => {
    return iconButton("", icon, "black", "#cfcfcf", cb, true,
    {}, { height:"50px", padding: "5px 0 5px 0", borderRadius:right?"6px 0 0 6px":"0 6px 6px 0"});
};
