import React, {useMemo, useEffect, useState} from "react";
import {Button, CloseButton} from "react-bootstrap";

import CpShareOffcanvas from "../_components/CpShareOffcanvas";
import { useUILang } from "../../AppExPf/utils/useUILang";
import CpIco from "../_components/CpIco";
import { IconList } from '../../consts/ATIconListToUsePoc';
import {
    _ExCtType_CK, _ExCtType_Img, _ExCtType_Lnk, _ExCtType_Mp3, _ExCtType_Qtn,
    _ExCtType_Txt, _ExCtType_Video
} from '../../consts/ATValidateEcnts';
import { showDrawQOptThr } from "../../consts/ATSysQType";
import { toAry } from "../../libs/libType";

const CpCreateQuestionContainer = (props) => { // base on poc CreateQuestionCTA.tsx
    const {show, onClose, createQtn} = props;
    const [t, isUIEn, language , setLang, ut, t2, t3] = useUILang();

    const questionTypes = useMemo(() => mkQTypeOpts(t), [language]);

    const [tab, setTab] = useState(questionTypes[0].id);

    const questions = useMemo(() => mkQOpts(tab, t, language), [language, tab]);

    const [selected, setSelected] = useState("");    
    useEffect(() => { setSelected("") }, [tab]);

    const clickNewQ = () => { createQtn(selected); onClose(); };
    const handleClose = () => onClose();
    const doDblClick = (id) => { createQtn(id); onClose(); };
    
    return <CpShareOffcanvas className={"CpCreateQuestionContainer offcanvas-mw-90"} show={show} placement={"end"} disableClose={true} 
        title={<div className={"d-flex justify-content-between align-items-center w-100 p-3 bg-dim-100 fs-5 user-select-none"}>
            <span>{t("exercise-edit-create-select-a-question-type")}</span>
            <CloseButton onClick={() => handleClose()}/>
        </div>} 
        footer={<div className={"d-flex gap-2 justify-content-center"}>
            <Button variant="gray-body-color w-100 border" onClick={handleClose}>{t("cancel")}</Button>
            <Button
                variant={`${selected? "exercise-third-btn": "dim-400 pe-none"} semi-bold w-100 d-flex gap-2 align-item-center`}
                onClick={clickNewQ}><span>{t("exercise-edit-create")}</span>
            </Button>
        </div>}>
        <div key={'cqc'} className={"d-flex gap-3 p-3 h-100 overflow-hidden"}>
            <div className={"create-question-tab-menu d-flex flex-column gap-3 overflow-y"}>
                {questionTypes.map((questionType, idx) => <QuestionTab key={idx} id={questionType.id}
                    active={tab === questionType.id} title={questionType.title} 
                    iconPath={questionType.iconPath} role={"button"}
                    onClick={() => setTab(questionType.id)}/>)}
            </div>
            <div className={"vr"}/>
            <div className={"create-question-tab-container overflow-y"}>
                {questions.map(question => {
                    return <div key={question.id} className={"d-flex flex-wrap px-3 mb-3 float-start"}>
                        <QuestionCard active={selected === question.id} question={question}
                            role={"button"} onClick={() => setSelected(question.id)} onDoubleClick={() => doDblClick(question.id)} />
                    </div>
                })}
            </div>
        </div>
    </CpShareOffcanvas>
};
export default CpCreateQuestionContainer;

const QuestionTab = (props) => {
    const {id, active, title, iconPath, ...tabProps} = props;
    return <span key={id} className={`d-flex gap-2 align-items-center uiAnimation user-select-none mx-2 p-3 ${active ? "border-start border-3 border-exercise-third-btn shadow" : ""}`} {...tabProps}>
        <span><CpIco src={iconPath}/></span>
        <span className={"d-none d-md-block text-nowrap"}>{title}</span>
    </span>
};

const QuestionCard = (props) => {
    const {active, question, ...CardProps} = props;
    const {title, group, iconPath} = question;
    return <div className={`sequenceListAnimation question-card d-flex flex-column rounded-light overflow-hidden border border-2 user-select-none uiAnimation mb-3 ${active ? "border-exercise-third-btn shadow" : ""}`} {...CardProps}>
            <span className={"p-3 bg-exercise-third-btn-hover-light text-exercise-third-btn semi-bold text-truncate d-flex gap-3"}>
                <span className={"d-flex d-md-none"}>{group}</span>
                <span className={"d-flex d-md-none"}>-</span>
                <span className={"text-truncate"}>{title}</span>
            </span>
        <span className={"p-3 thumbnail"}><CpIco src={iconPath}/></span>
    </div>
};
    
const mkQTypeOpts = t => [
    {id: "multiple-choice", title: t("multiple-choice"), iconPath: IconList.questionType.multipleChoice},
    {id: "fill-in-the-blanks", title: t("fill-in-the-blanks"), iconPath: IconList.questionType.fillInTheBlanks},
    {id: "label-the-diagram", title: t("label-the-diagram"), iconPath: IconList.questionType.labelTheDiagram},
    {id: "open-ended", title: t("open-ended"), iconPath: IconList.questionType.openEnded},
    {id: "polling", title: t("polling"), iconPath: IconList.questionType.polling}
];

const mkQOpts = (tab, t, language) => {
    return toAry({
        "multiple-choice": [
            {   id: "mcq-single-answer",
                title: t("mcq-single-answer"),
                iconPath: IconList.questionType.thumbnail[language].mcqSingleAnswer,
                group: t("multiple-choice") },
            {   id: "mcq-multiple-answers",
                title: t("mcq-multiple-answers"),
                iconPath: IconList.questionType.thumbnail[language].mcqMultipleAnswer,
                group: t("multiple-choice") },
            {   id: "match-table-single-answer",
                title: t("match-table-single-answer"),
                iconPath: IconList.questionType.thumbnail[language].matchTableSingleAnswer,
                group: t("multiple-choice") },
            {   id: "match-table-multiple-answers",
                title: t("match-table-multiple-answers"),
                iconPath: IconList.questionType.thumbnail[language].matchTableMultipleAnswer,
                group: t("multiple-choice") },
            {   id: "match-table-single-answer-2",
                title: t("match-table-single-answer-2"),
                iconPath: IconList.questionType.thumbnail[language].matchTableSingleAnswer2,
                group: t("multiple-choice") },
            {   id: "match-table-multiple-answers-2",
                title: t("match-table-multiple-answers-2"),
                iconPath: IconList.questionType.thumbnail[language].matchTableMultipleAnswer2,
                group: t("multiple-choice") },        
            ],
        "fill-in-the-blanks": [
            {   id: "fib-automark",
                title: t("fib-automark"),
                iconPath: IconList.questionType.thumbnail[language].fibAutoMark,
                group: t("fill-in-the-blanks") },
            {   id: "fib-manual-mark",
                title: t("fib-manual-mark"),
                iconPath: IconList.questionType.thumbnail[language].fibManualMark,
                group: t("fill-in-the-blanks") },
            ],
        "label-the-diagram": [
            {   id: "label-the-diagram-text-field",
                title: t("label-the-diagram-text-field"),
                iconPath: IconList.questionType.thumbnail[language].labTheDiagramTextField,
                group: t("label-the-diagram") },
            {   id: "label-the-diagram-drop-down",
                title: t("label-the-diagram-drop-down"),
                iconPath: IconList.questionType.thumbnail[language].labTheDiagramDropDown,
                group: t("label-the-diagram") },
            ],
        "open-ended": [
            {   id: "open-ended-general",
                title: t("open-ended-general"),
                iconPath: IconList.questionType.thumbnail[language].openEndedGeneral,
                group: t("open-ended") },
            /*{   id: "open-ended-short-answer",
                title: t("open-ended-short-answer"),
                iconPath: IconList.questionType.thumbnail[language].openEndedShortAnswer,
                group: t("open-ended") },*/
            {   id: "open-ended-essay-single-option",
                title: t("open-ended-essay-single-option"),
                iconPath: IconList.questionType.thumbnail[language].openEndedEssay,
                group: t("open-ended") },
            (showDrawQOptThr) && { id: "open-ended-canvas-drawing",
                title: t("open-ended-canvas-drawing"),
                iconPath: IconList.questionType.thumbnail[language].openEndedCanvasDrawing,
                group: t("open-ended") },
            ].filter(x => x),
        "polling": [
            {   id: "polling-single-options",
                title: t("polling-single-options"),
                iconPath: IconList.questionType.thumbnail[language].pollingSingle,
                group: t("polling") },
            {   id: "polling-multiple-options",
                title: t("polling-multiple-options"),
                iconPath: IconList.questionType.thumbnail[language].pollingMultiple,
                group: t("polling") },
            ]
    }[tab]);
};
