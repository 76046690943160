import React, { useState, useEffect, useRef, useMemo } from 'react';
import Ckeditor5Base from './ckeditor5/Ckeditor5Base';
import CpATPullDownMenu from './CpATPullDownMenu';
import DDAnswerContainer from './CpMCAnswers';
import CpValueAdder from './CpValueAdder';
import ATSwitch from './Switch0';
import { Button, CloseButton } from 'react-bootstrap';
import CpActionButtonComponent from '../../AppExPFUser/_components/CpActionButtonComponent';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { SIZE } from '../../AppExPFUser/_components/CpExPFConst';
import * as FIBC from "../../consts/ATQtnAnsTypeFIB";
import * as UI from '../../libs/libUI';
import { svgIcon2, iconButton } from '../components/CpATIcoBtn';
import { preJS, deepCopy } from '../AppUtil';
import { popAlert, popConfirm } from '../components/CpPopup';
import { toInt, toStr } from '../../libs/libType';
import { useUILang } from '../utils/useUILang';
import { debugMode } from '../../saga/ReduxState';
import { calTxtLen, calMathLen } from '../../libs/libAnsCompare';
import CpMJX from '../../AppExPFUser/_components/CpMJX';

const CpAnswerDetail = props => {
    const {dispatch, ansOK, setConfirmAns, editable, Q, index, updateScore, close, deleteThisAns, hasCorrectAns,
        setUpFlag, autoMark, scoreMethod, mediaDLs, getMediaDLs} = props;
    const [ t, uiEn, UILang, setUILang, ut, t2, t3 ] = useUILang();
    const [ans, setAns] = useState(deepCopy(ansOK));
    
    //const [ansValue, setAnsValue] = useState({value:null,index:-1});
    const edProps = {mediaDLs, getMediaDLs};
    
    const correctAnsDisable = (autoMark || ans.qtype===FIBC.__FIBT_DRD);
    const correctAns = (autoMark || ans.qtype===FIBC.__FIBT_DRD)? 1: ( ans.correctAns === 0 ? 0 : 1);
    const atype = ans.qtype===FIBC.__FIBT_DRD? FIBC.__FIBA_ADV: (ans.atype? ans.atype: FIBC.__FIBA_TXT);
    const scoreEditable = ((!scoreMethod) || (scoreMethod === FIBC.__FIBS_PBK))? 1: 0;
    const score = (scoreMethod === FIBC.__FIBS_PQU)? 0: toInt(ans.score?ans.score:1);
    const [qvalueType,setqvalueType] = useState(atype);
    //const [testv, setTestv] = useState('');

    useEffect(()=>{
        
        setAns(deepCopy(ansOK));
    }, [ansOK]);

    const isAnsAllBlank = () => {
        const found = ans.qvalue.findIndex( qq => {
            return toStr(qq.data).trim() !== "";
        });
        return (found < 0);
    };

    useEffect(()=>{
        if (qvalueType !== atype) {
            if (isAnsAllBlank()) {
                _setqvalueType(qvalueType);
            } else {
                popConfirm(dispatch, 0, t('warning.warning_change_answer_type'), () => {
                    _setqvalueType(qvalueType);                
                    //setTimeout(()=>{setSelected({id:changeSel.id, index:changeSel.index})},10);
                });
            };
        };
    },[qvalueType]);
    /*
    useEffect(()=>{
        if (ans.qvalue[ansValue.index] && (ans.qvalue[ansValue.index].data !== ansValue.value)) {
            ans.qvalue[ansValue.index].data = ansValue.value;
            setAns({...ans});
        };
    },[ansValue.value]);
    */
    
    const setScore = score => setAns({...ans, score});
    const setCorrectAns = correctAns => setAns({...ans, correctAns});
    const setqtype = qtype => setAns({...ans, qtype});

    const setqvalueData = (d, ii) => {
        // function pass to ckeditor will keep the same ans array in init, pass parameter here
        // set state, useEffect to update value
        // lead to another issue, max set effect update reached !!
        
        // don't update here, ans will keep old value by ckeditor
        
        if (ans.qvalue[ii]) ans.qvalue[ii].data = d;
        setAns({...ans});        
        //ans.qvalue[ii].data = d;
        //setAnsValue({value:d, index:ii});
    };

    const _setqvalueType = (t) => {
        //if (t===FIBC.__FIBA_EQU) ans.qvalue.forEach((qq, ii)=>{ qq.data=""; qq.type=t});
        ans.qvalue.forEach((qq, ii)=>{ qq.data=""; qq.type=t});
        ans.atype = t;
        setAns({...ans});
    };

    const deleteAns = (ii) => {
        if (ans.qvalue.length <= 1) return;
        ans.qvalue.splice(ii, 1); setAns({...ans});
    };

    const removeAns = (e) => { UI.stopEvent(e);
        deleteThisAns();
        close();
    };

    const addAlternative = () => {
        //ans.qvalue.push({data:'', type:FIBC.__FIBA_TXT});
        ans.qvalue.push({data:'', type:atype});
        setAns({...ans});
    };

    const setOver = ii => {};
    
    const ddClick = (e, ii) => {
        UI.stopEvent(e);
        ans.qvalue.forEach((ans, jj) => { ans.correct = (ii === jj ? 1 : 0) });
        setAns({...ans});
    };    
//inputKey
    //d => setqvalueData(d, ii)
    
    const customRender = (obj, ii, qtype=FIBC.__FIBT_DRD) => {      
        const edType = (atype === FIBC.__FIBA_EQU?"equation": atype === FIBC.__FIBA_ADV?"inputKeyInline": atype === FIBC.__FIBA_TXT?"text": "number" );
        //const editor = (type, ii) => {
        const editor = () => {
            return <React.Fragment key={edType+ii}>
            {(edType==="equation"||edType==="inputKeyInline")?<Ckeditor5Base {...props} editorType={edType} data={obj.data} setData={d => setqvalueData(d, ii)}
            enable={editable} debug={false} showStatus={false} {...edProps} setUpFlag={setUpFlag}/>
            :inputBoxFIB(ii, edType, obj.data, d => setqvalueData(d, ii))}
            </React.Fragment>;
        };

        // for testing calculate width function
        const tmpData = toStr(obj.data);
        const calLen = Math.ceil((tmpData !== '')?(['text','number'].includes(edType)?calTxtLen(obj.data):calMathLen(obj.data)):100);

        // {editor(atype, ii)}
        return <table key={"DDtb" + ii} className={"FIBAnsDetailTbl" + (editable ? "" : " unclickable")} style={{marginTop:(qtype===FIBC.__FIBT_TXT)?"10px":""}}>
                <tbody><tr>
                    <td className={"FIBAnsDetailTd "}>
                        {(qtype === FIBC.__FIBT_DRD) && <div className="FIBAnsDetailHead "
                            onClick={(e) => ddClick(e, ii)}>
                            <div className="flexColCenter" 
                                style={{width:"20px",height:"20px",borderRadius:"50%", 
                                backgroundColor:(obj.correct?"#4EAE84":"#e2e2e2")}}>
                                {svgIcon2('status/correct','white')}
                            </div>
                        </div>}
                        <div className="FIBAnsDetailRow gap-2" key={"QEdit" + ii}>
                            {editor()}
                            <div className='DDQtnEditBtnFIB' style={{alignSelf:'flex-end'}}>
                                {false && editable && <PopMenuBtn type={atype} setAnsType={(t)=>setqvalueType(t, ii)}/>}
                                {editable && <CpActionButtonComponent variant={"borderless-at-secondary-dim-dark p-2"}
                                    size={SIZE.SM} icon={<CpIco src={IconList.general.trash}/>}
                                    onClick={() => deleteAns(ii)}/>} 
                            </div>
                        </div>
                    </td>
                </tr>
                {(false && debugMode())?<tr><td><div className='f16' style={{whiteSpace: 'nowrap', width:(calLen)+'em', border:'1px solid grey'}}><CpMJX dynamic={true} >{tmpData}</CpMJX></div></td></tr>: ''}</tbody>
            </table>;
    };    

    const atOpts = [
        { id: FIBC.__FIBT_TXT, jsx: () => <div>{t(FIBC.__UIFIBTcodeName(FIBC.__FIBT_TXT))}</div> },
        { id: FIBC.__FIBT_DRD, jsx: () => <div>{t(FIBC.__UIFIBTcodeName(FIBC.__FIBT_DRD))}</div> },
    ];

    const cancelChange = (e) => { UI.stopEvent(e);
        setAns(deepCopy(ansOK));
        close();
    };

    const confirmChange = (e) => { UI.stopEvent(e);
        if (ans.qtype === FIBC.__FIBT_DRD) {
            
            const hasCorrect = ans.qvalue.some(ans => ans.correct);
            if (!hasCorrect) {
                popAlert(props.dispatch, 0, t('warning.warning_pls_choose_one_answer'));
                return;
            };
        };
        ans.score = score;
        setConfirmAns(deepCopy(ans));
        close();
    };    
    /*
   "answer_detail"
  "answer_text_field"
  "answer_dropdown"
    */
   //<div>{t('answer_detail').replace('%s',index)} Answer {index} Detail ({FIBC.__FIBTcodeName(ans.qtype)})</div>
    return <div key={"ansD"+index} className='FIBAnsDetailContainer'>
        <div className="flexRowBetween"  style={{color:"#2995cd",borderBottom:'1px solid lightgrey'}}>
            <div className='flexRowStartFit'>
                <div>{t('answer_detail').replace('%s',index)} ({t(FIBC.__FIBTcodeName(ans.qtype))})</div>
                <CpActionButtonComponent variant={"transparent-at-secondary-dim-dark"}
                    icon={<CpIco src={IconList.general.trash} className={"fs-4"}/>}
                    size={SIZE.SM} onClick={removeAns}/>                    
            </div>
            {false && <div className='flexRowStartFit'>{t('type-of-answer')+':'}
                <CpATPullDownMenu sel={ans.qtype} setSel={setqtype} opts={atOpts}
                    containerStyle={{ width: "120px" }} right={true}
                    menuStyle={{ justifyContent: "flex-end" }} headerStyle={{ width: "120px", justifyContent: "center" }} />
            </div>}
            <CloseButton onClick={close}/>
        </div><div style={{marginBottom:"5px"}}/>
        
        <div className='FIBAnsDetailContent'>
            <div className="ansDetailHead">
                <div className="ansDetail1">
                    <div className="ansDetailScore">
                        <div style={{marginRight:'5px'}}>{t('marks')+':'}</div>
                        {scoreEditable?<CpValueAdder value={score} setValue={setScore} min={1}/>
                        :<div style={{border:"1px solid lightgrey", padding:"2px 4px 2px 4px"}}>{score}</div>}
                    </div>

                    <div className='flexRowStartFit aDscoreType1'>
                        <div style={{marginRight:'5px', whiteSpace:"nowrap", color:(ans.qtype!==FIBC.__FIBT_DRD?'black':'grey')}}>{t('inputType')+':'}</div>
                        <PopMenuBtn type={atype} setAnsType={(t)=>setqvalueType(t)} enable={ans.qtype!==FIBC.__FIBT_DRD} />
                    </div>
                </div>
                <div className='ansDetail2 gap-3' style={{paddingRight:'5px'}}>
                    <div className='flexRowStartFit aDscoreType2'>
                        <div style={{marginRight:'5px', whiteSpace:"nowrap", color:(ans.qtype!==FIBC.__FIBT_DRD?'black':'grey')}}>{t('inputType')+':'}</div>
                        <PopMenuBtn type={atype} setAnsType={(t)=>setqvalueType(t)} enable={ans.qtype!==FIBC.__FIBT_DRD} />
                    </div>
                    {hasCorrectAns?<div className='flexRowStartFit'><div style={{marginRight:'5px', color:correctAnsDisable?'grey':'black'}}>
                        {t('at-setting.fib.correct-answer')+':'}</div>
                    <ATSwitch id='SWCorrectAns' leftText='' rightText='' disabled={correctAnsDisable}
                    selected={correctAns} leftValue={0} rightValue={1}
                    setter={setCorrectAns} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>:''}
                </div>
            </div>            
            {ans.qtype===FIBC.__FIBT_TXT ? ans.qvalue.map((obj, ii)=>{ return customRender(obj, ii, ans.qtype)}):
                <DDAnswerContainer dataArr={ans.qvalue} setDataArr={()=>{}} editable={editable}
                customRender={customRender} setOver={setOver} onClick={ddClick} ansChoice={ans.qtype} />}
        </div>      

        <div className='flexRowBetween px-1 py-1' style={{marginTop:"5px", borderTop:'1px solid lightgrey'}}>
            <div className={"d-flex justify-content-center"}>
                <Button variant="transparent-at-primary border w-100 semi-bold" onClick={addAlternative}>
                    {ans.qtype===FIBC.__FIBT_TXT?t('at-setting.fib.add-an-alternative-answer'):t('at-setting.fib.add-an-option')}
                </Button>            
            </div>
            <div className={"d-flex mt-sm-0 gap-2 justify-content-center"}>
                <Button variant="transparent-at-primary border w-100 semi-bold" onClick={cancelChange}>
                    {t('cancel')}
                </Button>                
                <Button variant="at-primary w-100 semi-bold" onClick={confirmChange}>
                    {t('save')}
                </Button>                
            </div>
        </div>                
    </div>;
};

export default CpAnswerDetail;

const inputBoxFIB = (ii=0, edType, data="", setData) => {
    const inputEle = (edType === "number"?UI.EpInputNum:UI.EpInputTxt0);
    return <div className="flexRowStart f16" style={{height:"100%"}}>
        {inputEle(data, setData, edType+ii," f16 ", {border:"none", width:"100%", outline:"none"})}
    </div>;
};
//        <input type="text" onChange={(e) => {setData(e.currentTarget.value)}} value={data}/>
//const popMenuBtn = (type, setAnsType, enable=true) => {
const disableColor = '#b4b4b4';
const PopMenuBtn = (props) => {
    const {type, setAnsType, enable=true} = props;
    const [t] = useUILang();
    const exCSS = enable?{}:{borderColor:disableColor, color:disableColor, pointerEvents:'none'};
    const iconColor = enable?'black':disableColor;
    const atOpts = FIBC.__FIBAcodes.map((id, ii)=>{
        return {
            id: id, 
            jsx: (sel = false, head=false) => <div className='flexRowStart'>
            <CpIco src={toIconList[FIBC.__FIBAcodeIcon(id)]} width={'1.4em'} height={'1.4em'}/>
            {!head && <div style={{ marginLeft: "5px" }}>{t(FIBC.__UIFIBAcodeName(id))}</div>}
            </div>
        };
    });
    return <CpATPullDownMenu sel={type} setSel={setAnsType} opts={atOpts}
        containerStyle={{ width: "120px" }} right={true}
        menuStyle={{ justifyContent: "flex-end", ...exCSS}} headerStyle={{ height:"33px", justifyContent: "center", ...exCSS }} />
};

const toIconList = {
    'inputType/generalEditor':IconList.inputType.generalEditor,
    'inputType/numberEditor':IconList.inputType.numberEditor,
    'inputType/equationEditor':IconList.inputType.equationEditor,
    'inputType/maxEditor':IconList.inputType.maxEditor,
};

/*
export const __FIBAIcons = {
    [__FIBA_TXT]:'inputType/generalEditor',
    [__FIBA_NUM]:'inputType/numberEditor',
    [__FIBA_EQU]:'inputType/equationEditor',
    [__FIBA_ADV]:'inputType/maxEditor', 
};
*/

/*
        return {
            id: id, 
            jsx: (sel = false, head=false) => <div className='flexRowStart'>
            {svgIcon2(FIBC.__FIBAcodeIcon(id), sel ? iconColor : iconColor, { width: "20px"})}
            {!head && <div style={{ marginLeft: "5px" }}>{t(FIBC.__UIFIBAcodeName(id))}</div>}
            </div>
        };


        iconButton("", "general/trash", "white", "#749ab3", () => { deleteAns(ii) }, true,
            { transform: "scale(1)", width:"20px" }, { width:"35px",height:"35px",marginLeft:"5px",borderRadius:"8px"})}


        <div className='flexRowBetween' style={{marginTop:"5px", borderTop:'1px solid lightgrey'}}>
            {iconButton(ans.qtype===FIBC.__FIBT_TXT?t('at-setting.fib.add-an-alternative-answer'):t('at-setting.fib.add-an-option'), "labelDiagram/add", "#2995cd", "white", addAlternative, true,
                {}, { width: "fit-content", border:"1px solid lightgray", padding: "6px 10px 6px 10px" })}
            <div className='flexRowEndFit'>
                {UI.colorButton('cancelbtn',t('cancel'),cancelChange,'transparent','100px',{color:'#2995cd', border:'1px solid lightgray'})}
                {UI.colorButton('okbtn',t('save'),confirmChange,'transparent','100px',{color:'#2995cd', marginLeft:'5px',border:'1px solid lightgray'})}
            </div>
        </div>       


        <div className='FIBAnsDetailContent'>
            <div className="flexRowBetween">
                <div className='flexRowEndFit'><div style={{marginRight:'5px'}}>{t('score')+':'}</div>
                {scoreEditable?<CpValueAdder value={score} setValue={setScore} min={1}/>
                :<div style={{border:"1px solid lightgrey", padding:"2px 4px 2px 4px"}}>{score}</div>}
                </div>
                <div className='flexRowEndFit' style={{paddingRight:'5px'}}>
                    <div style={{margin:'0 5px 0 5px', color:(ans.qtype!==FIBC.__FIBT_DRD?'black':'grey')}}>Input type:</div>
                    <PopMenuBtn type={atype} setAnsType={(t)=>setqvalueType(t)} enable={ans.qtype!==FIBC.__FIBT_DRD} />
                    {hasCorrectAns?<><div style={{margin:'0 5px 0 10px', color:correctAnsDisable?'grey':'black'}}>
                        {t('at-setting.fib.correct-answer')+':'}</div>
                    <ATSwitch id='SWCorrectAns' leftText='' rightText='' disabled={correctAnsDisable}
                    selected={correctAns} leftValue={0} rightValue={1}
                    setter={setCorrectAns} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></>:''}
                </div>
            </div>            
            {ans.qtype===FIBC.__FIBT_TXT ? ans.qvalue.map((obj, ii)=>{ return customRender(obj, ii, ans.qtype)}):
                <DDAnswerContainer dataArr={ans.qvalue} setDataArr={()=>{}} editable={editable}
                customRender={customRender} setOver={setOver} onClick={ddClick} ansChoice={ans.qtype} />}
        </div>   


                <DDAnswerContainer dataArr={ans.qvalue} setDataArr={setqvalue} editable={editable}
                customRender={customRender} setOver={setOver} onClick={ddClick} ansChoice={ans.qtype} />
*/