import { deepCopy } from "../libs/libHtml";
import { isAry, isObj, toAry, toInt, toStr } from "../libs/libType";
import * as MCAC from "./ATQtnAnsTypeFIB";
import * as MCQ from "./ATQtnAnsTypeMCQ";
import { __SYSQSubType_FIB, __SYSQSubType_LBD, __SYSQSubType_LBT, __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_OED, __SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_POL } from "./ATSysQType";
import { ansNumPattern } from "./ATValidate";
import { ansSumScore } from "./ATValidateQ";

//const emptyCanvasStr = `{\"version\":\"5.2.1\",\"objects\":[],\"background\":\"transparent\"}`;


export const normalizeQBody = (fullQ, _Q, opts) => {
    if(!isObj(_Q)) return 0;
    const SQType = fullQ.SQType;
    const isImp = fullQ.isImp || opts?.isImp;
    const isAT = fullQ.isAT || opts?.isAT;
    
    const Q = deepCopy(_Q);
    
    if(Q.qData) Q.qData = Q.qData.replace(/\n\r|\n|\r\n/gi, '<br/>'); //to move to unpack/pack qbody

    if([__SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_POL].includes(SQType)) {
        isAry(Q.qAnswers) && Q.qAnswers.forEach((ans) => { 
            if (ans.type !== MCQ.__MCAT_ATI) // check if not image
                ans.data = ans.data.replace(/\n\r|\n|\r\n/gi, '<br/>'); 
        });
    };
    
    if([__SYSQSubType_FIB, __SYSQSubType_LBD, __SYSQSubType_LBT].includes(SQType)) {            
        isAry(Q.qAnswers) && Q.qAnswers.forEach((ans) => {
            ans.qvalue.forEach((ans2) => {
                ans2.data = ans2.data.replace(/\n\r|\n|\r\n/gi, '<br/>');
            });
        });
    };

    if([__SYSQSubType_FIB, __SYSQSubType_LBT].includes(SQType)){
        isAry(Q.qAnswers) && Q.qAnswers.forEach((ans) => {
            if (ans.atype === MCAC.__FIBA_NUM) {
                (ans.qvalue) && ans.qvalue.forEach((opt) => {
                    if (opt.data) opt.data = toStr(opt.data).match(ansNumPattern)[0];
                });
            };
        });
    };

    return Q;
};

export const validEditATQBody = (fullQ, opts, track, errs) => {
    track('-- validEditATQBody --');
    
    const SQType = fullQ.SQType;
    const isImp = fullQ.isImp || opts?.isImp;
    const isAT = fullQ.isAT || opts?.isAT;

    const validateQLang = (Q, _langTxt, pfx) => {
        const setE = (k, v) => errs.set(pfx + k, v);
        const langTxt = _langTxt === 'En'? 'languages.en':'languages.zh';
        const etl = errTxt => isAT? ['(%s) %s',langTxt, errTxt]: [errTxt]; //err txt lang
        //const etl = errTxt => ['(%s) %s',langTxt, errTxt];

        if(!Q) setE('Lang', 'missing language question data ('+_langTxt+')');

        if(!Q.qData && (SQType !== __SYSQSubType_LBT && SQType !== __SYSQSubType_LBD)) 
            setE('qData', etl('error.error_at_no_question_body'));


        // at least two answers options
        if(SQType === __SYSQSubType_MCS){
            const arr = toAry(Q.qAnswers);
            if(arr.length < 2) setE('qAnswers', etl('error.error_at_no_two_correct_answers'));

            if(arr.findIndex(ans => { return ans.correct; }) < 0) 
                setE('qAnswers', etl('error.error_at_no_correct_answer'));
        };

        // for table, at least two answers options, 0 as header, so length need to be 3
        // use first row to check column, at least two, 0 as label
        if(SQType === __SYSQSubType_MCT){
            const arr = toAry(Q.tAnswers);
            // first row (header not count)            

            const atLeastOneAnswerforEachRow = arr.findIndex((row,ii) => { // not for mc label question
                return (ii>0 && (row.findIndex(col => col.correct) < 0));
            }) > 0;
            const atLeastOneAnswer = arr.findIndex((row, ii) => {
                return (ii>0 && (row.findIndex(col => col.correct) >= 0));
            }) > 0;
            if((arr.length < 3) || (arr[0] && (arr[0].length < 2))) 
                setE('tAnswers', etl('error.error_at_no_two_correct_answers'));

            if(!atLeastOneAnswer) setE('tAnswers', etl('error.error_at_no_correct_answer'));
        };

        if([__SYSQSubType_FIB, __SYSQSubType_LBD, __SYSQSubType_LBT].includes(SQType)){
            const arr = toAry(Q.qAnswers);
            // at least one correct answer for each dropdown 
            const atLeastOneCorrectMissing = arr.findIndex((ans, ii) => {
                return ((ans.qtype===MCAC.__FIBT_DRD) && (ans.qvalue.findIndex(opt => opt.correct ) < 0));
            }) >= 0;
            if (atLeastOneCorrectMissing) console.error('Import CSV LBQ Errr', arr);
            if (atLeastOneCorrectMissing) setE('qAnswers', etl('error.error_at_no_correct_answer'));

            const anyAnswerBlank = arr.findIndex((ans, ii) => {
                return(ans.qvalue.findIndex( opt => toStr(opt.data).trim()==='')>=0 );
            }) >= 0;
            if (anyAnswerBlank) setE('qAnswersBlank', etl('error.error_at_no_select_correct_answer'));
            if (!arr.length) setE('qAnswers', etl('error.error_at_no_answer_in_question'));
        };

        if([__SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_LBD,
            __SYSQSubType_OED, __SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_POL,].includes(SQType)){
            // todo check fib and lbt at least one correct answer option for drop down answer type before save
            const showHints = (Q?.showAnsHints)? 1 :0;
            const showKeys = (Q?.ansKeys)? 1: 0;
            if (showHints && (removeTag(toStr(Q?.ansHints).trim()) === '')) {
                setE('qHints', etl('error.error_hints_enable_empty'));
            };
            if (showKeys) {
                const kt = toAry(Q?.AKText);
                const kf = toAry(Q?.AKFile);
                const kl = toAry(Q?.AKLink);
                const hasText = (kt.length>0 && (removeTag(toStr(kt[0]?.text).trim()) !== ''));
                const hasFile = (kf.length>0 && (toAry(kf[0]?.file).length>0));
                const hasLink = (kl.length>0 && (toStr(kl[0]?.URL).trim() !==''));
                if (!hasText && !hasFile && !hasLink) {
                    setE('qKeys', etl('error.error_ans_keys_enable_empty'));
                };
            };
        };

        if ((!isImp) && (SQType === __SYSQSubType_OED)) {
            const tmp = toStr(Q?.qDrawData);
            if ((!tmp || Q?.qDrawIsBlank ) && !toStr(Q?.qDrawS3Id) && !toStr(Q?.BGMediaID)) {
            //if ((!tmp || tmp === emptyCanvasStr || Q?.qDrawIsBlank ) && !toStr(Q?.qDrawS3Id) && !toStr(Q?.BGMediaID)) {
                setE('qKeys', etl('error.error_at_no_background_drawing'));
            };
        };
    };
    fullQ.QLangEn && validateQLang(fullQ?.QEn, 'En', 'Q_E_');
    fullQ.QLangCt && validateQLang(fullQ?.QCt, '繁中', 'Q_C_');

    // LBT ?
    // for FIB and LBD, compare both en and chi sum up answer options score must be the same
    const scoreM = fullQ?.scoreMethod || MCAC.__FIBS_PBK;
    if (fullQ.QLangEn && fullQ.QLangCt && (scoreM === MCAC.__FIBS_PBK)) {
        
        if ([__SYSQSubType_FIB, __SYSQSubType_LBD, __SYSQSubType_LBT].includes(SQType)) {
            const enScore = ansSumScore(toAry(fullQ?.QEn?.qAnswers));
            const ctScore = ansSumScore(toAry(fullQ?.QCt?.qAnswers));
            
            if (enScore !== ctScore) errs.set('qAnswers', 'En and 繁中 answers must has same total score value');
        };
    };
    //if(!(fs.QLangCt || fs.QLangEn)) errs.Q_Lang = 'Missing Language';
    //errs.setIf('Q_Lang', (!(fs.QLangCt || fs.QLangEn)) && 'Missing Language');
};

const removeTag = (str) => { return str.replace(/<[^>]*>/g, ''); };