import {useEffect, useRef, useState} from "react";
import { IconList } from '../../../consts/ATIconListToUsePoc';
import CpIco from "../../_components/CpIco";
import { toInt, toStr } from "../../../libs/libType";

const defaultTipWidth = 500;
const mobileWidth = 555; //576 - 20
const wPadding = 20;
const CpTips = (props) => { // base on poc Tips.tsx
    const {bodyStyle={}, children, exSpace=0, fixedWidth=0, autoPos=0} = props;
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState("start");
    const [bodyWidth, setBodyWidth] = useState(defaultTipWidth);
    const [tipLeft, setTipLeft] = useState(0);

    const preventTrigger = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    //useEffect(()=>{ console.log({tipLeft, bodyWidth}); },[tipLeft, bodyWidth]);

    useEffect(() => {
        if (containerRef.current) {
            const eRect = containerRef.current.getBoundingClientRect();
            const windowWidth = window.innerWidth;
            if (autoPos) {
                const dww = windowWidth - wPadding;
                //const _exSpace = (exSpace && (dww > 576 && dww < 768)) ? exSpace : 0;
                let maxTipWidth = fixedWidth ? fixedWidth : defaultTipWidth;
                const _exSpace = (dww > mobileWidth && ((dww - exSpace) < maxTipWidth)) ? exSpace : 0;
                //console.log({dww, _exSpace});
                //console.log('contentRef', contentRef.current.clientWidth);
                                
                if (fixedWidth && (fixedWidth < dww) && (dww <= mobileWidth)) {
                    setTipLeft(toInt((dww - fixedWidth) / 2 - eRect.left));
                    setBodyWidth(fixedWidth);
                } else {
                    if ((eRect.left + maxTipWidth) > dww) {
                        if (maxTipWidth <= (dww - wPadding - _exSpace)) {
                            setTipLeft(toInt(dww - maxTipWidth - eRect.left + _exSpace));
                            setBodyWidth(maxTipWidth);
                        } else {
                            setTipLeft(toInt(0 - eRect.left + wPadding + _exSpace));
                            setBodyWidth(dww - wPadding - 4 - _exSpace);
                        };
                    } else {
                        setTipLeft(0);
                        setBodyWidth(maxTipWidth);
                    };
                };
            } else {
                if (eRect.left < (windowWidth - eRect.right)) {
                    setPosition("start");
                } else {
                    setPosition("end");
                };
            };
        };
    }, [containerRef.current, open, exSpace, autoPos]);

    //console.log("info", toStr(tipLeft)+"px");
    const exStyle1 = autoPos?{border:"none", left:(toStr(tipLeft)+"px"), padding:0}:{};
    const exStyle2 = autoPos?{...bodyStyle, width:"max-content", maxWidth:bodyWidth+"px", padding:0}:{};
    return <div className={"tips-container"} ref={containerRef}>
        <div onClick={preventTrigger} onMouseEnter={() => handleOpen()} onMouseLeave={() => handleClose()}>
            <CpIco src={IconList.general.tips} />
        </div>
        <div className={"tips-content shadow " + (autoPos?"":(position+"-0 ")) + (open ? " active ":"")}
            style={exStyle1}>
            <div ref={contentRef} className={`tips-body`} style={exStyle2}>
                {children}
            </div>
        </div>
    </div>
}

export default CpTips;

/*
style={{...exStyle, ...contentStyle}} >

<span className={"tips-container"} ref={containerRef}>
        <span onClick={preventTrigger} onMouseEnter={() => handleOpen()} onMouseLeave={() => handleClose()}>
            <CpIco src={IconList.general.tips} />
        </span>

        <div className={`tips-content shadow ${position}-0 ${1 ? `active` : ""}`} style={{border:"none !important", left:"-100px !important"}}>
            <div className={`tips-body`} style={{...bodyStyle}}>
            {children}
        </div>
        </div>
    </span>
*/