import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import * as UI from '../../libs/libUI';
import {asyncLoadFileBlob} from '../../libs/libFile';

const validImgFile = async (file) => {
    if (!file) return 0;
    try{
        await new Promise( (resolve, reject) => {
            const image = new Image();
            image.crossorigin = "anonymous";
            image.onload = function() { resolve (true); };
            image.onerror = function() { reject (false); };
            const URL = window.URL || window.webkitURL;
            image.src = URL.createObjectURL(file);
        });
        return 1;
    }catch(e){console.error(e)};
    return 0;
};

export const validateDropImg = file => {
    if (!file) return 'msg_missingDropFile';
    if (file.size > (3 * 1024 * 1024)) return 'msg_fileSize3M';
    return '';
};   

export const useDropImgState = () => {
    const [state, setState] = useState({}); //old saved Url, (clear to remove record)
    const update = (delta /*url, data, file, err*/) => {setState(s => ({ ...s, ...delta }) )};
    const reset = () => { setState({});};
    //return {url, setUrl, file, setFile, data, setData, err, setErr};
    return { ...state, update, reset };
};

export const CpDropImg = props => {
    const {onDropImg, validate, allow} = props;
    const onDrop = async files => {
        const file = allow && files && files.length && files[0];
        if(!file) return;
        const validErr = validate && validate(file);
        if(validErr)
            return onDropImg(0, 0, validErr);
        if(!(await validImgFile(file)))
            return onDropImg(0, 0, ('msg_invalidImage'));
        const data = await asyncLoadFileBlob(file);
        if(!data)
            return onDropImg(0, 0, ('msg_failLoadImage'));
        onDropImg(file, data);
    }
    const {getInputProps, getRootProps/*, isDragActive*/} = useDropzone({onDrop});
    const DropCfg = allow?getRootProps():{...getRootProps(), onClick:UI.stopEvent};

    //<div>{isDragActive?'Dragging':'idle'}</div>
    return <div className={props.className} {...DropCfg}>
        <input {...getInputProps()} />
        {props.children}
    </div>;
};
export default CpDropImg;