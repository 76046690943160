import { useMemo } from "react";
import { lang2t, langIsEn, useUILang } from "../../AppExPf/utils/useUILang";
import CpAssignToTag from "../_components/CpAssignToTag";
import CpIco, { CpLangIco } from "../_components/CpIco";
import { toInt, toObj } from "../../libs/libType";
import { GetTeacherActionButton, GetTeacherButtonMore } from "./CardTeacherAssignmentCard";
import { getT2bSrt, getT2bEnd } from "../EPAssign/CpAssignmentList";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { _pathAssign } from "../../consts/ATConstReact";
import { ReduxBind } from "../../saga/ReduxState";
import { TagAsmMarkingTag, exerHasMark } from "./Tags/TagMarkingTag";
import { TagThrProgressTag } from "./Tags/TagThrProgressTag";

const CpTeacherAssignmentSummary = ReduxBind(props => {     //base on poc TeacherAssignmentSummary
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {markReme, showExPV} = props;
    const {yearClass2d, dispatch} = props;
    const asm = toObj(props.asm);
    const Exer = toObj(asm.Exer);
    const Stat = toObj(asm.Stat);

    const AName =  lang2t(asm.ALang, Exer.ENameEn, Exer.ENameCt);
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);

    const WSubmits = toInt(Stat.submit);
    const WTtl = toInt(Stat.ttl);
    const WMarks = toInt(Stat.mark);

    const AStartTime = getT2bSrt(asm);
    const AEndTime = getT2bEnd(asm);

    const onRmAsm = () => dispatch(urlPush_Replace(_pathAssign('')));
    // layout = "list" <--- always narrow button for teacher action button, poc use in-list
    const btnProps = {...props, t, asm, onRmAsm}; 
    
    return <div className={"p-3"} style={(markReme.marking || showExPV)? {display:'nanoe'}: {}} >
        <div className={`border rounded-light bg-dim-100`}>
            <div className={`d-flex flex-row justify-content-between gap-2 w-100 px-3 py-2`}>
                    <span className={"d-flex flex-column justify-content-between"}>
                        <span className={"d-flex justify-content-center align-items-center rounded fs-3 bg-exercise-secondary-btn mt-1"}
                            style={{width: "1.8rem", height: "1.8rem"}}>
                            {CpLangIco(langIsEn(asm.ALang))}                            
                        </span>
                    </span>
                <span className={"d-flex flex-column justify-content-between flex-grow-1"}>
                        <span className={"mb-2"}>
                            <div className={"semi-bold d-flex flex-nowrap justify-content-between align-items-start mt-1 mb-2"}>
                                <span className={`d-flex fs-5 fade-in-container flex-grow-1 mb-1`}>{AName} </span>
                                <div className={`d-flex flex-column justify-content-end align-items-center ps-3 ps-md-0 bg-dim-100 gap-2`}>
                                    <span className={`d-flex flex-nowrap gap-2 p-1`}>
                                        <GetTeacherActionButton {...btnProps} />
                                        <GetTeacherButtonMore {...btnProps} />
                                    </span>
                                </div>
                            </div>
                            <div className={"d-flex justify-content-between align-items-start gap-1 gap-md-3"}>
                                <span className={`fs-8`}>
                                    <span className={`d-flex flex-column flex-md-row gap-2`}>
                                    <span className={"d-flex justify-content-start align-items-center pe-1"}>
                                        <span className={"semi-bold pe-2"}>{t("assignment-info-start")}:</span>
                                        <span className={`fade-in-container text-nowrap`}>{/*formatedDateTime(date.start)*/}{AStartTime}</span>
                                    </span>
                                    <span className={"me-3"}>
                                        <span className={"semi-bold pe-2"}>{t("assignment-info-end")}:</span>
                                        <span className={`fade-in-container text-nowrap`}>{/*formatedDateTime(date.end)*/}{AEndTime}</span>
                                    </span>
                                    </span>
                                    <span className={`d-flex flex-wrap gap-2 mt-2`}>
                                        <TagThrProgressTag AState={asm.AState} hasM={hasM}/><CpAssignToTag {...{yearClass2d, asm}} /><TagAsmMarkingTag {...{asm}}/>
                                    </span>
                                </span>
                                <span className={`d-flex flex-column flex-md-row gap-3`}>
                                    <span className={`d-flex flex-column align-items-end align-items-md-center`}>
                                        <span className={`semi-bold fs-8`}>{t("assignment-info-submission")}</span>
                                        <span className={`d-flex align-items-end gap-2 semi-bold`}>
                                            <span className={`fs-4 line-height-normal ${''? "": "text-tag-success"}`}> {WSubmits}</span>
                                            <span>/</span>
                                            <span>{WTtl}</span>
                                        </span>
                                    </span>
                                    {hasM? <><span className={"vr d-none d-md-block"}/>
                                        <span className={`d-flex flex-column align-items-end align-items-md-center`}>
                                        <span className={`semi-bold fs-8 text-nowrap`}>{t("assignment-info-manual-marked")}</span>
                                        <span className={`d-flex align-items-end gap-2 semi-bold`}>
                                            <span className={`fs-4 line-height-normal ${ ""? "": "text-tag-success"}`}>{ WMarks}</span>
                                            <span>/</span>
                                            <span>{WSubmits}</span>
                                        </span>
                                    </span></>: ''}
                                </span>
                            </div>
                        </span>
                    </span>
            </div>
        </div>
    </div>
});

export default CpTeacherAssignmentSummary;