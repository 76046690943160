import { isAry, isObj, isStr, nestStripEmpty, objEntries, toAry, toObj, toStr } from "../libs/libType";
import { QP_P, QP_PP } from "./ATConsts";
import { toIdAry, toUniIdAry } from "./ATValidate";
import { exerUniQIds } from "./ATValidateExer";

export const _ExCtType_Qtn = 'q'; //questions
export const _ExCtType_Txt = 't'; //txt
export const _ExCtType_Img = 'i'; //image
export const _ExCtType_CK = 'ck'; //ck 
export const _ExCtType_Video = 'v'; //vidoe
export const _ExCtType_Mp3 = 'a'; //audio
export const _ExCtType_Lnk = 'l'; //link

export const DisplayName = {
    [_ExCtType_Qtn]: 'Question', 
    [_ExCtType_Txt]: 'Section',
    [_ExCtType_Img]: 'Image',
    [_ExCtType_CK]: 'Text', 
    [_ExCtType_Video]: 'Video',
    [_ExCtType_Mp3]: 'Audio',
    [_ExCtType_Lnk]: 'Hyperlink',
};

export const UIDisplayName = {
    [_ExCtType_Qtn]: 'question', 
    [_ExCtType_Txt]: 'at-setting.content-type-menu.section',
    [_ExCtType_Img]: 'at-setting.content-type-menu.image',
    [_ExCtType_CK]: 'at-setting.content-type-menu.text', 
    [_ExCtType_Video]: 'at-setting.content-type-menu.video',
    [_ExCtType_Mp3]: 'at-setting.content-type-menu.audio',
    [_ExCtType_Lnk]: 'at-setting.content-type-menu.hyperlink',
};

//const prefix = 'Exercise Media ';
export const validEditECnts = (exer, opts, track, errs) => {
    track('-- validEditECnts --');

    const chkEn = exer.ELangEn?1:0;
    const chkCt = exer.ELangCt?1:0;

    // en, ct exer.ECtns[].en / .ct
    const validateECtnLang = (ctnErrs, i, ctype, ectn, lang) => {
        
        //if (!(((lang==='en') && chkEn) || ((lang==='ct') && chkCt))) return;
        const setE = (i, k, v) => (ctnErrs['ECnts_' + lang + '_' + k] = v);

        //const prefix = DisplayName[ctype]+' ('+(lang==='en'?'En':'繁中')+')';
        const mDis = UIDisplayName[ctype];
        const langTxt = lang==='en'?'languages.en':'languages.zh';
        
        const displayTitle = ectn.displayTitle || 0;
        const align = toStr(ectn.align); // left, center, right
        const title = toStr(ectn.title);
        const desc = toStr(ectn.desc);
        const isUpload = (ctype===_ExCtType_Mp3?toStr(ectn.audioType)
            :(ctype===_ExCtType_Video?toStr(ectn.videoType):''))==='ul';
        const url = toStr(isUpload?ectn.mediaId:ectn.url);
        const pttitle = toStr(ectn?.pt?.title);
        const catitle = toStr(ectn?.ca?.title);
        const ptdesc = toStr(ectn?.pt?.desc);
        const cadesc = toStr(ectn?.ca?.desc);
        const pturl = toStr(isUpload?(ectn?.pt?.mediaId):(ectn?.pt?.url));
        const caurl = toStr(isUpload?(ectn?.ca?.mediaId):(ectn?.ca?.url));

        //if([_ExCtType_Img, _ExCtType_Lnk, _ExCtType_Mp3, _ExCtType_Video].includes(ctype)){
        if([_ExCtType_Img, _ExCtType_Lnk,].includes(ctype)){
            if (displayTitle && !title)
                setE(i, 'title', ['%s(%s)%s %s',mDis,langTxt,''+(i+1),'error.error_at_no_title']);
                //prefix+(i+1)+' "Title" cannot be blank when "Display Title" is enabled.');
            //if (displayTitle && !desc)
            //    setE(i, 'desc', prefix+(i+1)+' "Description" cannot be blank when "Display Title" is enabled.');
        }else if([_ExCtType_Txt].includes(ctype)) {
            if (!title) setE(i,'title', ['%s(%s)%s %s',mDis,langTxt,''+(i+1),'error.error_at_no_title']);
        };
        // CKtext allow title blank
        //}else if([_ExCtType_CK, _ExCtType_Txt].includes(ctype)) {
        if(ctype === _ExCtType_CK) {
            const ckData = toStr(ectn.ckData);
            //error.error_at_no_content
            if (!ckData) setE(i, 'ckData', ['%s(%s)%s %s',mDis,langTxt,''+(i+1),'error.error_at_no_content']);
        //}else if(ctype === _ExCtType_Txt) { // section desc allow blank
        //    if (!desc) setE(i, 'desc', prefix+(i+1)+' "Content" cannot be blank.');
        }else if(ctype === _ExCtType_Img) {
            const img = toStr(ectn?.img?.oupid);
            if (!img) setE(i, 'oupid', ['%s(%s)%s %s',mDis,langTxt,''+(i+1),'error.error_at_no_image']);
        }else if(ctype === _ExCtType_Lnk) {
            if (!url) setE(i, 'url', ['%s(%s)%s %s',mDis,langTxt,''+(i+1),'error.error_at_no_url']);
        }else if([_ExCtType_Mp3, _ExCtType_Video,].includes(ctype)) {
            //const mediaType = ctype===_ExCtType_Mp3?'Audio':'Video';
            if (lang !=='en') {
                //const passpt = ((displayTitle && pttitle && ptdesc) || (!displayTitle)) && pturl;
                //const passca = ((displayTitle && catitle && cadesc) || (!displayTitle)) && caurl;
                const passpt = ((displayTitle && pttitle) || (!displayTitle)) && pturl;
                const passca = ((displayTitle && catitle) || (!displayTitle)) && caurl;

                if (!(passpt || passca)) {
                    if (displayTitle && (!(pttitle && catitle))) 
                        setE(i, 'title', ['%s(%s)%s %s',mDis,langTxt,''+(i+1),'error.error_at_no_title']);
                    //if (displayTitle && ((pturl && (!ptdesc)) || (caurl && (!cadesc))))
                    //    setE(i, 'desc', prefix+(i+1)+' "Description" cannot be blank when "Display Title" is enabled.');
                    if (!pturl && !caurl) setE(i, 'url', ['%s(%s)%s %s',mDis,langTxt,''+(i+1),
                        ctype===_ExCtType_Mp3?'error.error_at_no_audio':'error.error_at_no_video']);
                }
            } else {
                if (displayTitle && !title) setE(i, 'title', ['%s(%s)%s %s',mDis,langTxt,''+(i+1),'error.error_at_no_title']);
                //if (displayTitle && !desc) setE(i, 'desc', prefix+(i+1)+' "Description" cannot be blank when "Display Title" is enabled.');            
                if (!url) setE(i, 'url', ['%s(%s)%s %s',mDis,langTxt,''+(i+1),
                    ctype===_ExCtType_Mp3?'error.error_at_no_audio':'error.error_at_no_video']);
            };
        };
        /*
                    if (displayTitle && !pttitle) setE(i, 'pttitle', prefix+'(普)'+(i+1)+' "Title" cannot be blank when "Display Title" is enabled.');
                    if (displayTitle && !ptdesc) setE(i, 'ptdesc', prefix+'(普)'+(i+1)+' "Description" cannot be blank when "Display Title" is enabled.');
                    if (!pturl) setE(i, 'pturl', prefix+'(普)'+(i+1)+' "'+mediaType+' URL" cannot be blank.');

                    if (displayTitle && !catitle) setE(i, 'catitle', prefix+'(粤)'+(i+1)+' "Title" cannot be blank when "Display Title" is enabled.');
                    if (displayTitle && !cadesc) setE(i, 'cadesc', prefix+'(粤)'+(i+1)+' "Description" cannot be blank when "Display Title" is enabled.');
                    if (!caurl) setE(i, 'caurl', prefix+'(粤)'+(i+1)+' "'+mediaType+' URL" cannot be blank.');            

        */
    };

    toAry(exer.ECtns).forEach((c, i) => {
        //if(0 && c.type)
        //    setE(i, 'EcntType', 'Content Block '+(i+1)+', everything is wrong');
        if(c.type !== _ExCtType_Qtn) { 
            const ctnErrs = {};
            
            chkEn && c.en && validateECtnLang(ctnErrs, i, c.type, c.en, 'en');
            chkCt && c.ct && validateECtnLang(ctnErrs, i, c.type, c.ct, 'ct');
            const _ctnErrs = nestStripEmpty(ctnErrs);
            exer.ECtns[i].errs = _ctnErrs;            
            errs.setIf('Ctns_'+i, _ctnErrs);
        };
    });
    
    const qerrs = ExQsErrs(exer, exerUniQIds(exer), opts);
    errs.setIf('qtns', qerrs);
    
};

export const ExQsErrs = (ex, eqids, opts) => {
    
    //error.error_at_questions_no_published
    const qtns = opts.qtns;
    const [ect, een, escroll, eslide] = [ex.ELangCt, ex.ELangEn, ex.EModeScroll, ex.EModeSlide];
    const qerrs = Object.fromEntries(eqids
        .map(QId => {
            const q = qtns[QId];
            const { dp, drpu, QLangCt: qct, QLangEn: qen, QModeScroll: qscroll, QModeSlide: qslide, schoolId, userId } = toObj(q);
            const err = (q?.QId)?[ 
                ((!schoolId) && ((dp !== QP_P) || (drpu !== QP_PP))) && 'not published',
                ((ect && (!qct)) || (een && (!qen))) && 'Language mismatch',
                ((escroll && (!qscroll)) || (eslide && (!qslide))) && 'Mode mismatch',
            ].filter(e => e).join(', '):
            ' ID is invalid';//' not exists'; 
            return err && [QId, ('Question "'+QId+'": '+err)]; 
        })
        .filter(pair => pair)
    );
    
    return qerrs;
};

const ECLangImg = i => { 
    const {imgs, title, desc, img, ...extra} = i;
    const oupid = img?.oupid; 
    const ret = {...extra, img:{oupid}, title, desc}; 
    
    return ret; 
};
export const normalizeECtn = (c, hasE, hasC) => {
    
    if(!isObj(c)) return c;
    const type = c.type;
    if(type === _ExCtType_Img){
        const {en, ct, ...ret} = c;
        if(hasE && isObj(en)) ret.en = ECLangImg(en);
        if(hasC && isObj(ct)) ret.ct = ECLangImg(ct);
        return ret;
    }else if(type === _ExCtType_CK){
        const {en, ct, ...ret} = c;
        if(hasE && en) ret.en = en;
        if(hasC && ct) ret.ct = ct;
        return ret;
    }
    return c;
};

export const SplitECtnsQs = ECtns => {
    return [].concat(...(toAry(ECtns)).map(c => {
        const qidstr = c.QIds;
        if(!isStr(qidstr)) return [c];
        //return qidstr.split(',').map(QIds => ({...c, QIds}));
        return toIdAry(qidstr.split(',')).map(QIds => ({...c, QIds}));
    }));
};
export const fixeECnts = ex => isObj(ex)? {...ex, ECtns:SplitECtnsQs(ex.ECtns)}: ex;
