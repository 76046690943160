import {CloseButton, Modal, ModalProps} from "react-bootstrap";
import React, {ReactNode} from "react";

interface Props extends ModalProps {
    title?: string
    handleClose?: (e: React.SyntheticEvent) => void
    children: ReactNode
    header?: ReactNode
    footer?: ReactNode
    overflowBody?: boolean
}

const SharedModal = (props: Props) => {
    const {title = "", handleClose = undefined, header, footer, children, overflowBody = true, ...modalProps} = props
    const onBackdropClick = handleClose === undefined ? () => {
    } : handleClose
    const handleExit = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    return <span className={"d-none"} onClick={handleExit}>
        <Modal className={""} centered
               onBackdropClick={onBackdropClick} {...modalProps}>
        {handleClose && <Modal.Header className={`bg-dim-100 border-0 my-2`}>
            <Modal.Title as={"h5"}>
                {title}
            </Modal.Title>
            <div className={"d-flex justify-content-center align-items-center gap-3"}>

                <CloseButton className={"text-dim-400"} onClick={handleClose
                }/>
            </div>

        </Modal.Header>}
            {header}
            <Modal.Body
                className={`p-0 ${overflowBody === true ? "overflow-auto " : ""} border-0`}
                style={{zIndex: 1}}>
            {children}
        </Modal.Body>
            {footer && <Modal.Footer className={`pt-2 pb-2 bg-dim-100 border-0`}>
                {footer}
            </Modal.Footer>}
    </Modal>
    </span>
}

export default SharedModal