import React, { useEffect, useState, useRef } from 'react';
import * as UI from '../../libs/libUI';
import * as SQT from '../../consts/ATSysQType';
import * as FIBC from "../../consts/ATQtnAnsTypeFIB";
import { toAry, toStr } from '../../libs/libType';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import CpATAnsDD from './CpATAnsDD';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import {AnsDD, ResultAnsSingleBlk} from './CpQDoResultAnsBlk';
import CpLDCanvas, {addAnswerBlock, selObj} from "../components/CpLDCanvas";
import { getUUID } from '../../AppExPFUser/_components/CpGetUUID';

const CpQDoLBD = (props) => {
    const { fullQ, Q, mediaDLs, getMediaDLs } = props;
    const { containerClass, editable, asmView, doEdit, isStudent, isList, showResult, showCorr, idx, mini=0,
        setStudentResp, studentResp, scrollRef } = props;

    const [selected, setSelected] = useState({id:-1, index:0});
    const [afterInit, setAfterInit] = useState(0);
    const stResp = studentResp?.qresp;
    const stCorr = studentResp?.corr;
    const stRespMath = studentResp?.math3Str;
    const [myAns, setMyAns] = useState(stResp || {}); // store what user answer data only
    const [redraw, setRedraw] = useState(0);
    const [myCV, setMyCV] = useState(null);
    const myMediaID =toStr(Q?.BGMediaID);
    const [myBG, setMyBG] = useState(null);

    const ansRef = useRef([]);
    const insideDraw = useRef(false);

    const caseSensitive = fullQ?.caseSensitive || false;
    const qData = Q?.qData || "";
    const ansArr = Q?.qAnswers || [];
    const LDMode = Q?.LDMode || 'ftw';
    const myID = 'ansDo'+(doEdit?'edit':'read')+fullQ.QId+(idx||0);
    const defaultAnsType = (fullQ.SQType===SQT.__SYSQSubType_LBD)?FIBC.__FIBT_DRD:FIBC.__FIBT_TXT;
    
    const edProps = {mediaDLs, getMediaDLs};

    const ansIndex = (id) => ansArr.findIndex((obj)=>{return obj.oupansid === id});

    useEffect(()=>{
        if (myMediaID !== '' && getMediaDLs) getMediaDLs([myMediaID]);
        setAfterInit(1);
    },[]);

    useEffect(()=>{
        if (afterInit && isStudent && setStudentResp && !showResult) {
            if (Object.keys(myAns).length > 0)
                /*todo*/
                setStudentResp({...studentResp, qresp:{...myAns}});            
        };
    },[myAns]);

    useEffect(()=>{ 
        if (mediaDLs && mediaDLs[myMediaID]) {
            setMyBG(mediaDLs[myMediaID].dl);
        };
    },[mediaDLs]);

    useEffect(()=>{
        if (ansRef.current && selected.id>=0) {
            ansRef.current[ansIndex(selected.id)].focus();
        };
    },[selected]);

    useEffect(()=>{
        if (!myBG) setMyCV(null);
    },[myBG]);

    useEffect(()=>{
        renderCanvAnsBlock();
    },[myCV]);    

    useEffect(()=>{
        if (!insideDraw.current) {
            insideDraw.current = true;
            renderCanvAnsBlock();
            insideDraw.current = false;
        };
    },[redraw]);    
      
    const onResize = (val) => {
        setRedraw(val)
    };

    const renderCanvAnsBlock = () => {
        if (ansArr && myCV) {
            myCV.clear();
            ansArr.forEach((aa, ii)=>{
                addAnswerBlock(myCV, aa.oupansid, aa, ''+(ii+1), true)
            });
        };
    };
    
    const selectAns = (ii) => {
        setSelected({id:ii, index:0});
    };    
    
    const ansSetValue = (oupansid, v, ansType) => {
        if (!doEdit) return;
        /*todo*/
        setMyAns({...myAns, ...{[oupansid]:v}});
    };

    const setDDAns = (oupansid, v) => {
        if (!doEdit) return;
        setMyAns({...myAns, ...{[oupansid]:v}});
    };

    const getTextInput = (ans, ii) => {
        const ansType = ans.atype?ans.atype:FIBC.__FIBA_TXT;
        const exStyle = (ansType === FIBC.__FIBA_ADV || ansType === FIBC.__FIBA_EQU)?{}:{};
        
        const exReadStyle = {};
        
        const showCK = (ansType === FIBC.__FIBA_ADV || ansType === FIBC.__FIBA_EQU);
        const eqCheck = ansType === FIBC.__FIBA_EQU;

        if (!ans.qvalue) return <div>no answer</div>;

        const studentAns = (showResult || showCorr || asmView)?toStr(stResp?stResp[ans.oupansid]:'').trim():'';     
        const ansCorr = (showCorr && stCorr) ? stCorr[ans.oupansid] : 0;
        const stMathStr = stRespMath?stRespMath[ans.oupansid]:[];

        let displayStr = toStr(ans.qvalue[0].data).trim();
        if (!displayStr) displayStr = (showCK?'<p> </p>':' ');

        return <div key={'ans'+ii}
            className='ansInputContainerBorderLD' ref={(el)=>ansRef.current[ii]=el}
             style={{...exStyle, border:'none'}}>
            {(showResult || showCorr || asmView)?<ResultAnsSingleBlk {...{asmView, showResult, showCorr, props, showCK, eqCheck, studentAns,
                stMathStr, edProps, caseSensitive, scrollRef, ansCorr}} idx={ii} arr={ans.qvalue}/>
            :(doEdit?editor(myAns[ans.oupansid], (v)=>ansSetValue(ans.oupansid, v, ansType), ansType, ii, editable)
                :<QEditorReadOnly {...props} data={ans.qvalue[0].data} {...edProps} 
                exStyle={exReadStyle}/>)
            }
        </div>;
    };

    const getDDInput = (ans, ii) => {
        if (!ans.qvalue) return <div>no answer</div>;
        const ansOpts = [];
        // no -1 option for no edit
        if ((!showCorr) && (doEdit || ans.qvalue.length === 0))
            ansOpts.push({id:-1, correct:0, jsx:(isHead=false)=>
                <div className={'ck-content EditorReadOnly '+isHead?'':'ATDDAnsOption'}
                style={{padding:'0px 10px'}}>{'---'}</div>});
        ans.qvalue.forEach((an, kk)=>{
            const displayStr = toStr(an.data)?toStr(an.data):'<p/>';
            ansOpts.push({id:kk, correct:an.correct, jsx:()=><QEditorReadOnly {...props} 
                data={displayStr} {...edProps} exStyle={{backgroundColor:'transparent',
                padding:'0px 10px 0px 10px'}}/>});
        });
        const stAns = (stResp && (stResp[ans.oupansid]>=0))?stResp[ans.oupansid]:-1;
        //const stAns = stResp?(stResp[ans.oupansid]>=0?stResp[ans.oupansid]:-1):-1;
        const ansCorr = (showCorr && stCorr) ? stCorr[ans.oupansid] : 0;
        //const isCorrect = (stAns>=0) && (ans.qvalue[stAns].correct);
        //const correctData = ans.qvalue.find(ans=>{return ans.correct})?.data;
        const ansVal = (showResult || showCorr || asmView)?stAns:myAns[ans.oupansid];
        return <CpATAnsDD ref={(el)=>ansRef.current[ii]=el} key={'ans'+ii} opts={ansOpts} editable={editable && !asmView}
            sel={ansVal} refIndex={ii} rowStyle={{padding:'12px 5px', whiteSpace:'pre-wrap', overflowX:'auto', margin:0}}
            showResult={showResult} showCorr={showCorr} menuStyle={{width:'100%'}} asmView={asmView}
            headerStyle={{width:'100%', padding:'0px 2px 0 2px', overflowX:'auto'}} setSel={(v)=>setDDAns(ans.oupansid, v)}
            posCheck={false} ansContClass={'w100'} domQuesRef={scrollRef} ansCorr={ansCorr}
        />;
    };

    const onRAnsClick = (e, oupansid) => {
        selObj(myCV,'AnsText',oupansid); 
        myCV.requestRenderAll();
    };

    const genResultBottomRow = (_arr) => {
        const arr = toAry(_arr);
        if (arr.length <=0) return <div></div>;
        const result = [];
        if (defaultAnsType===FIBC.__FIBT_TXT) {
            arr.map((ans,ii)=>{
                const ansType = ans.atype?ans.atype:FIBC.__FIBA_TXT;
                const showCK = (ansType === FIBC.__FIBA_ADV || ansType === FIBC.__FIBA_EQU);
                result.push(<div key={'rb'+ii} className='flexRowStartFit' style={{padding:'10px', maxWidth:'100%'}}
                    onClick={(e)=>onRAnsClick(e,ans.oupansid)}>
                    <div>{(ii+1)+'.'}</div>
                    <AnsDD correctIndex={-1} {...{showCK, props, edProps}} arr={ans.qvalue} idx={ii}/>
                </div>);
            })
        } else {
            arr.map((ans,ii)=>{
                const correctData = ans.qvalue.find(ans=>{return ans.correct})?.data;
                result.push(<div key={'rb'+ii} className='flexRowStartFit' style={{padding:'10px', maxWidth:'100%'}}
                    onClick={(e)=>onRAnsClick(e,ans.oupansid)}>
                    <div>{(ii+1)+'.'}</div>
                    <div className='ansDisplayBlock' style={{marginLeft:'5px'}}>
                    <QEditorReadOnly {...props} data={correctData?correctData:'<p> </p>'} {...edProps} 
                    exStyle={{backgroundColor:'transparent', padding:'0px 10px 0px 10px'}}/>
                </div></div>);
            });
        };
        return result;
    };

    const miniStyleTop=mini?{flexDirection:'column'}:{};
    const miniStyleLayer=mini?{minWidth:'100%',maxWidth:'100%'}:{};
    return <div className={containerClass + (isList?' list':'')}>
        <div className='tabDoQContent2' id="mathDisplay">
            <div id={'DoQ'+myID} className='tabDoQContent3'>
                <QEditorReadOnly {...props} data={qData} {...edProps}/>
            </div>
            {myBG?<div className='LDCanvasAnsTop' style={{marginTop:'10px', backgroundColor:'white', ...miniStyleTop}}>
                <div className='LDCanvasLayer' style={miniStyleLayer}>
                    <div style={{margin:'auto',width:(LDMode==='ftw'?'100%':'auto')}}>
                        <CpLDCanvas setCanvas={setMyCV} selectAns={selectAns} onResize={onResize}
                        BGImgSrc={myBG} mode={LDMode} id={myID}/>
                    </div>
                </div>
                <div className='LDAnsLayer f18' style={{overflow:'visible'}}>
                    {ansArr.length>0 ? ansArr.map((ans, ii)=>{
                        
                        const ansType = ans.atype?ans.atype:FIBC.__FIBA_TXT;
                        const showCK = (ansType === FIBC.__FIBA_ADV || ansType === FIBC.__FIBA_EQU);
                        const correctData = ans.qvalue.find(ans=>{return ans.correct})?.data;
                        const active = ansIndex(selected.id)===ii;
                        //const correctIndex = ans.qvalue.findIndex((item)=>{return item.correct?(item.correct):false});
                        const exStyle = (defaultAnsType===FIBC.__FIBT_TXT && !showResult && !showCorr && !asmView)?{borderBottom:'1px solid grey'}:{};
                        return <div key={'ans'+ii} className='flexRowStart' style={{padding:'5px'}}>
                            <div className='flexRowEnd' style={{minWidth:'10%', maxWidth:'10%',
                                marginRight:'5px',color:active?'#ec8008':'black'}}>{ii+1}{'.'}</div>
                            <div className='LDAnsBlock' style={exStyle} onClick={(e)=>onRAnsClick(e,ans.oupansid)}>
                                {defaultAnsType===FIBC.__FIBT_TXT?getTextInput(ans, ii):getDDInput(ans,ii)}
                                {showResult?(<div className='LDAnsBlockAns'>
                                    {defaultAnsType===FIBC.__FIBT_TXT?
                                    <AnsDD correctIndex={-1} {...{showCK, props, edProps}} arr={ans.qvalue} idx={ii}
                                        menuStyle={{width:'100%', marginTop:'2px'}}/>
                                    :<div className='ansDisplayBlock' style={{width:'100%', marginTop:'2px'}}>
                                    <QEditorReadOnly {...props} data={correctData?correctData:'<p> </p>'} {...edProps} 
                                    exStyle={{backgroundColor:'transparent', padding:'0px 10px 0px 10px'}}/>
                                    </div>}
                                    </div>
                                ):''}
                            </div>
                        </div>})
                    :''}
                </div>
            </div>:''}
            {showResult?<div className='LDResultBottom f18'>{genResultBottomRow(ansArr)}</div>:''}
        </div>
    </div>;    
};

export default CpQDoLBD;

const inputBoxFIB = (ii=0, edType, data="", setData) => {
    const inputEle = (edType === "number"?UI.EpInputNum:UI.EpInputTxt0);
    return inputEle(data, setData, edType+ii," ansFIBInputBox ",{});
};

const editor = (data, setData, type, ii, editable) => {
    const edType = (type === FIBC.__FIBA_EQU?"equation":
            type === FIBC.__FIBA_ADV?"inputAnswerAdv":
            type === FIBC.__FIBA_TXT?"text":
            "number"
        );
    return <React.Fragment key={edType+ii}>
        {(edType==="equation"||edType==="inputAnswerAdv")?<Ckeditor5Base editorType={edType} needDebounce={true}
        data={data} setData={setData} enable={editable} debug={false} showStatus={false}/>
        :inputBoxFIB(ii,edType,data,setData)}
    </React.Fragment>;
};
