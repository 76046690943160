//emulate react route functions

import { toObj } from "./libType";

export const routesMap = (path, routes) => {
    //lj('routesMap', path, routes);
    const cnt = routes.length;
    const paths = path.split('/');
    
    for(var i = 0; i < cnt; i++){
        const params = _routeParams(routes[i][0], paths);
        if(params)
            return {route:routes[i], params};
        //if(path.startsWith(routes[i][0])) return routes[i];
    }
    return false;
};

const _gobalRoute = {};
export const routeGet = () => {
    
    return toObj(_gobalRoute.match);
}
export const routeUse = ({route, params}, url) => {
    const Page = route[1];
    const match = {url, params:{...params, ...toObj(route[2])}};
    return <Page match={match}/>;
}

const _routeParams = (route, paths) => {
    const routes = route.split('/');
    if(routes.length !== paths.length)
        return false;

    const params = {};
    const step = routes.length-1;
    
    
    for(var j = 0; j <= step; j++){
        const routeJ = routes[j];
        const pathJ = paths[j];
        if(routeJ[0] === ':'){
            const key = routeJ.substring(1);
            params[key] = decodeURI(pathJ);
        }else{
            
            if(!( (routeJ === pathJ) || ((j===step) && pathJ.startsWith(routeJ)) )){
                //lj('_routeParams Break:', routeJ, paths[j]);
                return false;
            }
        }
    } 
    //lj({_routeParams:params});
    return params;
};