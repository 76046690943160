//Assigment Status
export const _AST_PENDING = 'aPen';    //"not-yet-started": ={"not-yet-started"}/>
export const _AST_IN_PROGRESS = 'aIP'; //"in-progress": ={"in-progress"}/>
export const _AST_WAIT_RESULT = 'aWR'; //"result-pending": ={"pending-result"}/>
///use HasM && wSub export const _AST WAIT_MARK = 'aWM'; // "pending-manual-mark":={"pending-manual-mark"}/>
export const _AST_PUBLISHED = 'aPU'; // "result-published":={"result-published"}/>

export const _WST_SUBMIT = 'wSub';
export const _WST_RESUME = 'wResume';

// Assignment question state, follow poc
export const QAS_ANSWERED = 'answered'; //Question Answer State
export const QAS_PARTLY = 'partly'; //Question Answer State
export const QAS_NOT = 'not'; //Question Answer State

export const _MST_MARKED = 'mark';
export const _MST_MARKED_PARTLY = 'partly';
export const _MST_MARKED_NONE = 'none';

/*
Grey - Did not attempted that question
Orange - Attempted but not yet marked
Green - Question has correctness and it marked as “Correct”
Red - Question has correctness and it marked as “Wrong”
Blue - Question does not have correctness and it is marked
*/
export const _QST_SUB_WRONG = 'f';    // f-red submit, wrong
export const _QST_SUB_CORR = 'p';     // p-green submit, correct
export const _QST_SUB_ATT = 's';      // s-dark grey submit, attempt, ope
export const _QST_SUB_NOTMARK = 'm';  // m-deepRed submit, not marked
export const _QST_SUB_NOTATT = 'n';   // n-light Grey submit, not attepmt, ope


export const _ARS_CLOSE_SUBMIT = 'submitted'; //asm resume
export const _ARS_CLOSE_RESET = 'reset'; //asm resume
export const _ARS_CLOSE_RESUME = 'resume'; //asm resume