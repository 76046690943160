import React, { useState, useEffect } from 'react'
import * as UI from '../../libs/libUI';
import { ReduxBind } from '../../saga/ReduxState';
import { asyncApiCall_, s3DownloadPrivate, s3UploadPrivate } from '../../libs/awsFuncs';
import { LayerLocalMedia } from '../../AppExPf/ATMediaPool/LayerLocalMedia';
import { preJS, readUploadBinBuf } from '../../AppExPf/AppUtil';
import { toObj, toStr, str2Blob, objVals } from '../../libs/libType';
import { asyncDL, draw2Blob, localDrawId, mediaUpFile, useMediaCache } from '../../AppExPf/utils/useMediaCache';
import { loading_add } from '../../saga/loading.saga';
import { HttpPostMP } from '../../libs/libHttp';
import { fileExt } from '../../libs/libFormat';
import { upLocalMedias } from '../../AppExPFUser/EPExercise/LayerEMediaUp';

const URL = window.URL || window.webkitURL;

const PageDevS3 = ReduxBind(props => {
    const { dispatch } = props;

    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch);
    const [onAddMedia, setOnAddMedia] = useState();

    const [data, setData] = useState();
    const [file, setFile] = useState();
    const [s3Id, setS3Id] = useState();
    const [drawData, setDrawData] = useState('');

    const loadfiles = async fs => {
        const f = fs?.[0]?.file;
        console.log('loadfiles', f);
        const rr  = await readUploadBinBuf(f);
        console.log(rr);
        const [binBuf, err] = rr;
        setData(binBuf);
        setFile(f);
    } 

    const clickMedia = e => { UI.stopEvent(e);
        setOnAddMedia({
            maxMedia: 1, 
            onAdd:(medias => {
                loadfiles(medias);
                setOnAddMedia(0);
            }), 
        }); 
    };


    const onUp = r => {
        console.log({onUp:r});
        setS3Id(r);
    };
    const clickUp = async e => {
        const testId = '_test/testfile';
        const ret = await mediaUpFile(dispatch, {testId},  file, 'upload' );
        onUp(ret);
    };
    const clickUpBuf = async e => {
        if(!(file && data)) return;
        const testId = ['_test/testfile', fileExt(file.name)].join('.');
        const size = 9999 + data.length;
        const aryBuf = toArrayBuffer(data);
        console.log({data, aryBuf, size});
        const blob = new Blob([aryBuf], { type: "application/octet-stream" });
        const fields = {name: testId, size, testId} 
        const ret = await mediaUpFile(dispatch, fields, blob, 'upload' );
        onUp(ret);
        //mediaUpBinBuf(dispatch, {testId}, testId, data, 'upload');
    };
    const clickDL = e => { asyncDL(dispatch, getMediaDLs, s3Id); };

    const clickUpDraw = async e => {
        const blob = str2Blob(drawingData);
        const testId = 'testDrawing.draw';
        const size = 9999 + blob.size;
        const fields = {name: testId, size, testId};
        const ret = await mediaUpFile(dispatch, fields, blob, 'upload' );
        //const ret = await media@@@UpData(dispatch, {}, testId, blob, 'upload' );
        //const ret = await media@@@UpFile(dispatch, {}, blob, 'upload' );
        onUp(ret);
    };

    const clickUpDrawLocal = async () => {
        const drawId = localDrawId('upLocalDraw');
        const drawBlobs = { [drawId]: draw2Blob(drawId, drawingData) }; 
        const mm = addLocalMedias(drawBlobs, 1);
        const [ms] = objVals(mm);
        const checkAdded = getMediaDLs([drawId]);
        console.log(ms, checkAdded);
        const ret = await upLocalMedias(dispatch, [ms.mediaId], getMediaDLs, ()=>{}, drawBlobs); 
        console.log(ret);
    };

    const clickDLDraw = async e => {
        const obj = await asyncDL(dispatch, getMediaDLs, s3Id, 1);
        setDrawData(obj && JSON.stringify(obj));
    };

    return (<div className="PageDevS3">
        <h1>Network Test Page</h1>
        <div>Test All Network Functions, Ajax Call, AWS API Call, S3 Public/Private Upload/Download</div>

        {UI.Button0('select File', clickMedia, 'bam')}
        <LayerLocalMedia clickClose={()=>setOnAddMedia(0)} {...{...onAddMedia}}/>
        <hr/>
        {data ? <div>{data.toString('utf8')}</div>: ''}
        {file ? <div>{preJS(file)}</div> :''}
{/*
        <div><h2>S3 private Upload</h2><CpS3PrivateUp /><br/></div>
        <div><h2>S3 private Download</h2><CPS3PrivateDown /><hr/></div>
*/}
        {UI.Button0('upload File', clickUp, 'bam')}
        {UI.Button0('upload Buff', clickUpBuf, 'bam')}
        <hr/>

        {UI.Button0('download', clickDL, 'bam')}
        S3ID: '{toStr(s3Id)}'
        <hr/>
        <div>{drawingData}</div>
        {UI.Button0('upload preset draw data (fabric data -> blob -> s3)', clickUpDraw, 'bam')}
        {UI.Button0('download draw (s3 url -> blob data -> text(UTF-8))', clickDLDraw, 'bam')}
        {UI.Button0('upload preset draw data (fabric data -> locaMediaCache)', clickUpDrawLocal, 'bam')}
        <div>{drawData}</div>
        <hr/>
    </div>);
});
export default PageDevS3;

function toArrayBuffer(buffer) {
    const arrayBuffer = new ArrayBuffer(buffer.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < buffer.length; ++i) {
      view[i] = buffer[i];
    }
    return arrayBuffer;
  }

export const mediaUpBinBuf = async (dispatch, getFields, filename, binBuf, loadFlag='MediaUp') => {
    const up = async () => {
        try{
            //const [name, size] = [filename, binBuf.length+1];
            const name = 'filenameCanHasChinense';
            const str = 'bin str base 64';
            const str64 = String.from(str, 'base64');
            const size = str64.length;
            console.log({name, str, str64, size});

            const apiFields = { name, size, ...getFields};
   
            const [r1, e1] = await asyncApiCall_(dispatch, '/getATMed@@@iaUplink', apiFields );  
            const {mediaId, mediaLink} = toObj(r1);
            const {url, fields} = mediaLink||{}; 
            
            if(!(mediaId && mediaLink && url && fields)){
                console.error({r1, e1});
                return '';
            }
            //const onUpProgress = (prog, total) => {console.log({prog, total}); };
            const file = 'Content-Disposition: form-data; name="file"; filename="'+name+'"\n\r'+
            'Content-Type: image/jpeg; '+'\n\r'+
            'Content-Transfer-Encoding: base64'+'\n\r'+
            '\n\r'+
            str64;
            fields.file = file;
            const resUp = await HttpPostMP(url, false, fields, {file});//, onUpProgress);

            
    
            const [r2, e2] = await asyncApiCall_(dispatch, '/putATMediaUpDone', {mediaId} );  
            if(e2) console.log({r2, e2});

            return mediaId;
        }catch(e){
            console.error(e);
        }
        return '';
    };

    loadFlag && dispatch(loading_add(loadFlag, 1)); 
    const upResult = await up();
    loadFlag && dispatch(loading_add(loadFlag, -1));
    return upResult;
};


const useState_ = (init) => {
    const [val, set] = useState(init);
    return {val, set};
};
const _CpS3PrivateUp = (props) => {
    const files = useState_(false);
    const errFiles = useState_('');
    const errButton = useState_('');
    const [output, setOutput] = useState('');
    const onUpload = async (e) => {
        console.log('onUpload', files);
        if(files.val.length !== 1)
            setOutput('Files must set one file');
        const upResult = s3UploadPrivate(props.dispatch, files.val[0]);
        setOutput(JSON.stringify(upResult));
    }
    return <>
    <div>{UI.FRow('File', UI.File0(files.set), errFiles.val)}</div>
    <div>{UI.FRow('', UI.Button0('Upload', onUpload), errButton.val)}</div>
    <div>{output}</div>
    </>;
};
const CpS3PrivateUp = ReduxBind(_CpS3PrivateUp);

const _CPS3PrivateDown = (props) => {
    const [s3Img, setS3Img] = useState(false);
    const onLoad = async () => {
        if(!s3Img){
            const imgUrl = await s3DownloadPrivate(props.dispatch, 'testdir/testfile.txt');
            setS3Img(imgUrl);
        }
    }
    useEffect(()=>{ onLoad(); });
    return <>
        <div>
            {s3Img?(<img alt="the private" src={s3Img}/>):'Loading S3 Img'}<br/>
            (a direct (presigned download) link to private S3 file)
        </div>
        <div>{UI.FRow('', UI.Button0('Download a private file', ()=>{'console.log'}), 'noErr')}</div>
    </>;
};
const CPS3PrivateDown = ReduxBind(_CPS3PrivateDown);

const drawingData="{\"version\":\"5.2.0\",\"objects\":[{\"type\":\"path\",\"version\":\"5.2.0\",\"originX\":\"left\",\"originY\":\"top\",\"left\":347,\"top\":109.15,\"width\":22,\"height\":41.01,\"fill\":null,\"stroke\":\"#000000\",\"strokeWidth\":4,\"strokeDashArray\":null,\"strokeLineCap\":\"round\",\"strokeDashOffset\":0,\"strokeLineJoin\":\"round\",\"strokeUniform\":false,\"strokeMiterLimit\":10,\"scaleX\":1,\"scaleY\":1,\"angle\":0,\"flipX\":false,\"flipY\":false,\"opacity\":1,\"shadow\":null,\"visible\":true,\"backgroundColor\":\"\",\"fillRule\":\"nonzero\",\"paintFirst\":\"fill\",\"globalCompositeOperation\":\"source-over\",\"skewX\":0,\"skewY\":0,\"erasable\":true,\"selectable\":true,\"evented\":true,\"path\":[[\"M\",371,111.14599389648437],[\"Q\",371,111.14999389648438,370.5,111.14999389648438],[\"Q\",370,111.14999389648438,370,111.64999389648438],[\"Q\",370,112.14999389648438,370,112.64999389648438],[\"Q\",370,113.14999389648438,369.5,114.14999389648438],[\"Q\",369,115.14999389648438,368,116.64999389648438],[\"Q\",367,118.14999389648438,367,119.14999389648438],[\"Q\",367,120.14999389648438,366,121.64999389648438],[\"Q\",365,123.14999389648438,364.5,124.64999389648438],[\"Q\",364,126.14999389648438,362.5,129.14999389648438],[\"Q\",361,132.14999389648438,359.5,134.64999389648438],[\"Q\",358,137.14999389648438,357,139.14999389648438],[\"Q\",356,141.14999389648438,354.5,143.64999389648438],[\"Q\",353,146.14999389648438,352,147.64999389648438],[\"Q\",351,149.14999389648438,350.5,150.14999389648438],[\"Q\",350,151.14999389648438,349.5,151.64999389648438],[\"L\",348.996,152.15399389648437]]}],\"background\":\"white\"}";
