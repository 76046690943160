// All the logic please refer to figma
import React, {useState, useEffect, useMemo} from "react";
import ScrollContainer from "../_components/CpScrollContainer";
import PageAssignmentEmptyContent from "./PageAssignmentEmptyContent";
import CpTeacherAssignmentSummary from "./CpTeacherAssignmentSummary";
import TabTeacherAssignmentTabContainer, { toSWMs, useAsmMarking } from "./TabTeacherAssignmentTabContainer";
import PageAsmWork from "../EPAsmWork/PageAsmWork";
import { ReduxBind, debugMode } from "../../saga/ReduxState";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { _pathAssign } from "../../consts/ATConstReact";
import { BtnDev, BtnPopDev, preJS } from "../../AppExPf/AppUtil";
import { maxVal, objEntries, objKeys, toAry, toObj, toStr } from "../../libs/libType";
import { initMark, initWork } from "./PageAssignments";
import PageAssignmentStudent from "./PageAssignmentStudent";
import { unpackMark, unpackQtn, unpackWork } from "../../consts/wirisConfig";
import DoEx from "../../AppExPf/ATExerEdit/DoEx";
import PageAsmMark from "../EPAsmWork/PageAsmMark";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { langIsEn } from "../../AppExPf/utils/useUILang";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { strCmpTrimLow } from "./TabTeacherAssignmentStatus";
import { toUniIdAry } from "../../consts/ATValidate";

const PageAssignment = ReduxBind(props => {  //base on poc AssignmentEditPage
    const subProps = props;
    const {asm, asmId, isThr, report, loading} = props;
    
    return ( <ScrollContainer className={"PageAssignment exercise-edit-scroll-container h-100 d-flex flex-column w-100 overflow-auto " + ((isThr || report)? " scroll-padding": "")}>
        {asm? <>
            <BtnDev txt='PageAssignment'>
                <BtnPopDev txt="asms">{preJS(props.asms, 3)}</BtnPopDev>
                <BtnPopDev txt="asm">{preJS(props.asm, 3)}</BtnPopDev>
                <BtnPopDev txt="Exer">{preJS(props.Exer, 3)}</BtnPopDev>
                <BtnPopDev txt='qtns'>{preJS(props.qtns, 3)}</BtnPopDev>
                <BtnPopDev txt='works'>{preJS(props.works, 3)}</BtnPopDev>
                <BtnPopDev txt='marks'>{preJS(props.marks, 3)}</BtnPopDev>
                <BtnPopDev txt='remeAsms'>{preJS(props.remeAsms, 3)}</BtnPopDev>
            </BtnDev>
            {isThr
                ? <PageAssignmentThr key={asmId} {...subProps} /> 
                : <PageAssignmentStudent key={asmId} {...subProps} />
            }
        </>
        : loading? ''
        : <PageAssignmentEmptyContent key={asmId} {...subProps} />
        }
    </ScrollContainer>);
});
export default PageAssignment;


export const loadAssignment = async (dispatch, assignId, isReme) => {
	const [res, err] = await asyncApiCallLoad_(dispatch, '/getAssignment', {assignId, isReme});
	const a = toObj(res?.asm);
	const e = toObj(a.Exer);
	return [ a, e, 
		qMap(toAry(res?.qtns).map(q => unpackQtn(q))),
		initWork(unpackWork(res?.work), a, e), //forTeacher
		initMark(unpackMark(res?.mark), a, e), //forTeacher
		Object.fromEntries(toAry(res?.works).map(w => [w.userId, initWork(unpackWork(w), a, e)])), // forStudent
		Object.fromEntries(toAry(res?.marks).map(m => [m.userId, initMark(unpackMark(m), a, e)])), // forStudent
		toAry(res?.remeAsms), res?.answerVis
	];
};

const qMap = qtns => Object.fromEntries(toAry(qtns).map(q => [q.QId, unpackQtn(q)]));

const PageAssignmentThr = ReduxBind(props => {  //base on poc AssignmentEditPage
    const { dispatch, report } = props;
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);
    const [ cache2d, useGetCache ] = useCaches(props);

    const [showExPV, setShowExPV] = useState(0);
    const loadPVRemeAsm = async rAsmId => {
        const [a, e, qtns, w, m, ws, ms, ras] = await loadAssignment(dispatch, rAsmId, 1);        
        setShowExPV(mkPropsForPV(props, a, qtns, setShowExPV, mediaDLs, getMediaDLs, addLocalMedias));
    }; 

    const [markReme, setMarkReme] = useState(0);
   
    const setMarking = marking => setMarkReme(mr => (mr && marking)? {...mr, marking}: 0); //update marking idx or clear all
    const setSWMs = SWMs => setMarkReme(mr => mr? {...mr, SWMs}: 0);
    const saveMarks = async (saveSWMs) => { setSWMs(saveSWMs); console.log('saveMarks no save mark for reme'); };

    const { asm, Exer, qtns, works, marks, remeStu1, SWMs, marking} = toObj(markReme);
    const students = useGetCache('classStudents', toStr(asm?.classId));

    useEffect(() => { //console.log('PageAssignmentThr effect', {asm, Exer, works, marks, students});
        if(!(students && asm && Exer && works && marks)) return;
//console.log('PageAssignmentThr eff()', {markReme});
        const SWMs  = toSWMs(asm, Exer, works, marks, students);
        const swmIdx = maxVal(0, toAry(SWMs).findIndex(swm => (swm?.work?.userId === remeStu1)));
        setMarkReme({asm, Exer, qtns, works, marks, SWMs, marking:{swmIdx}});
    }, [asm, Exer, works, marks, students]);
   
    const loadMarkReme = async (rAsmId, remeStu1, remeLv) => {
        const [asm, Exer, qtns, w, m, _works, marks, ras] = await loadAssignment(dispatch, rAsmId, 1);        
        const lvStus = toUniIdAry(asm?.remeLvStus?.[remeLv]);
        const works = Object.fromEntries(objEntries(_works).filter(w => lvStus.includes(w[0])));
        setMarkReme({asm, Exer, qtns, works, marks, remeStu1});
    };

	const subProps = {...props, students, markReme, loadMarkReme, showExPV, loadPVRemeAsm};
return <>
        {/*markReme? <PageAsmMark {...{asm, Exer, qtns, SWMs, marking, setMarking, saveMarks, ...markReme, isReme:1}} />*/}
		{((markReme?.SWMs) && (markReme?.marking))? <PageAsmMark {...{setMarking, saveMarks, ...markReme, isReme:1}} />
    	:showExPV? <DoEx {...showExPV} />
		:''}
        <CpTeacherAssignmentSummary {...subProps}/>
        <TabTeacherAssignmentTabContainer {...subProps}/>
    </>;
});

const mkPropsForPV = (props, pvAsm, qtns, setShowExPV, mediaDLs, getMediaDLs, addLocalMedias) => {
//console.log('mkPropsForPV', pvAsm);
    if(!pvAsm) return 0;
    return {...props, preview:1, showResult:0, showPub:1, lock:1, isThr:0, 
        PVMode: (pvAsm.AMode === "SCROLL_MODE")? 1: 0,
        showEn: langIsEn(pvAsm.ALang),
        ECtns:toAry(pvAsm.Exer?.ECtns), 
        qtns, useCacheQtns: () => qtns, 
        close: () => { setShowExPV(0); },
        setOnAddMedia: () => { alert('Front Preview Not Support Media Pool'); }, 
        mediaDLs, getMediaDLs, addLocalMedias,
        aHints: pvAsm?.AHints
    };
};

export const sortWorkStudents = (students, works, marks) => {
    return objEntries(students)
    .sort(([a0,a1],[b0,b1])=>strCmpTrimLow(a1?.classNo, b1?.classNo) || strCmpTrimLow(a1?.nameEng, b1?.nameEng))
    .map(([sid, student]) => {
        const [work, mark] = [works?.[sid], marks?.[sid]];
        return work && {student, work, mark};
    })
    .filter(s => s);
};

