import React, {useEffect, useMemo, useState} from "react";
import { clickUrl, preJS } from "../../AppExPf/AppUtil";
import { useFields } from "../../AppExPf/utils/useEditDraftPub";
import { langIsEn, useUILang } from "../../AppExPf/utils/useUILang";
import { _AssignmentRoot, _pathAssign, _EPPathEass } from "../../consts/ATConstReact";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { isObj, objKeys, toAry, toInt, toObj, toStr, toUniAry } from "../../libs/libType";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { SIZE } from "../_components/CpExPFConst";

import { ReduxBind } from "../../saga/ReduxState";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import PageEXPFLayout from "../PageEXPFLayout";
import CpActionButton from "../_components/CpActionButton";
import { ent2DropdownItem } from "../_components/CpDropdown";
import CpHeaderTitle from "../_components/CpHeaderTitle";
import CpIco, { CpLangIco } from "../_components/CpIco";
import ScrollContainer from "../_components/CpScrollContainer";
import CpShareOffcanvas from "../_components/CpShareOffcanvas";
import PageAssignment, { loadAssignment } from "./PageAssignment";
import { CpAssignmentList } from "./CpAssignmentList";
import CpAssignmentListFilter, { AssignmentFilterYearClassDropdown, CpAssignmentFilterClassDropdown, CpAssignmentFilterSortingDropdown, CpAssignmentFilterStatusDropdown, CpAssignmentFilterSubjectDropdown, CpAssignmentFilterYearDropdown, filterAsms, mkASortItem, mkAStateItems, sortAsms } from "./CpAssignmentListFilter";
import LayerAssignmentChange from "./LayerAssignmentDeadline";
import LayerAssignmentDelete from "./LayerAssignmentDelete";
import LayerAssignmentEnd from "./LayerAssignmentEnd";
import LayerAssignmentPublish from "./LayerAssignmentPublish";
import LayerAssignmentShare  from "./LayerAssignmentShare";
import { expfRoles } from "../../saga/auth.saga";
import { useYearClass } from "./TabAssignmentSettingClass";
import CpActionButtonComponent from "../_components/CpActionButtonComponent";

//import AssignmentReportPage from "../../poc/screens/pages/included/assignment/AssignmentReportPage";
import { toUniIdSet } from "../../consts/ATValidate";
import { CpAssignOffCanvas, } from "./CpExerciseEditAssignCTA";
import { stopEvent } from "../../libs/libUI";
import { langTxt } from "../EPLibrary/PageLibrary";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import DoEx from "../../AppExPf/ATExerEdit/DoEx";
import { usePaging } from "../../AppExPf/utils/ATPaging";
import CpPagination from "../_components/CpPagination";
import { useAsmDo } from "../EPDashboard/PageDashboards";
import { getTransMap } from "../../libs/libTransMap";
import { unpackMark, unpackWork } from "../../consts/wirisConfig";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";

// All the logic please refer to figma
const PageAssignments = ReduxBind(props => { //base on poc AssignmentTeacher
    const { dispatch, _saga } = props;
    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 

    const [isThr, isStt, uRole ] = expfRoles(props);
    const [doPV, setDoPV] = useState(0);

    const params = toObj(props.match?.params);
    const {asmId, act} = toObj(params);
    const report = (act === 'report');

    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const {fields, setFields, setField, opts, setOpts, 
        y, c, j, st, so,
        year, classId, AMSubjId, AState, ASort,
        AStateItems, ASortItems, subjItems, yearItems, classItems, yearClassDDI} = useFindAsmsOpts(isStt, isThr, uiEn, t, ut);

    const [allAsms, setAllAsms] = useState([]);
    const jAsms = useMemo(() => (process.env.REACT_APP_CFG === 'local')? toAry(allAsms): pickAsmsByMSubj(allAsms, AMSubjId), [allAsms, AMSubjId]);
    const fAsms = useMemo(() => filterAsms(isStt, jAsms, AState), [isStt, jAsms, AState]);
    const sAsms = useMemo(() => sortAsms(fAsms, ASort), [fAsms, ASort]); 
    const ttlRow = sAsms.length;

    const paging = usePaging(ttlRow, 10, 1);
    const { page, rpp, totalRow, totalPage, res1, res2, setPaging } = paging;
    useEffect(() => { setPaging(paging) }, [sAsms] );

    const asms = useMemo(() => sAsms.slice(res1-1, res2), [sAsms, res1, res2]);

    const loadOpts = async () => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getAssignmentOpts', {withSubj:1});
        setOpts(os => ( {...toObj(os), ...toObj(res?.opts) } ));
    };
    useEffect(() => { loadOpts(); }, []);

    useEffect(() => { if(!asmId) reload(); }, [c, j, asmId]);

    const reload = async (asmId) => {
        if(!fields || !fields.classId)  return;
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getAssignments', {AFilter:fields, asmId});
        setAllAsms( fixAsms(
            toAry(res?.asms), 
            toAry(res?.works).map(unpackWork), 
            toAry(res?.marks).map(unpackMark),
        ) );
    };

    const clickExitAsm = clickUrl(dispatch, _pathAssign('',''));

    const [listView, setListView] = useState(1);
    const renderlist = ((!asmId) && (!report) && listView);

    const {useAsmEnd, useAsmDead, useAsmEdit, useAsmPub, useAsmDel, useAsmShare, asmDo} = useAsmDo(dispatch);

    const tabProps = { ...props, tagMSets, useTagMSets, doPV, setDoPV,
        t, uiEn, UILang, setUILang, ut, 
        yearClassDDI, yearItems, year, classItems, classId, subjItems, AMSubjId, AStateItems, AState, ASortItems, ASort, y, c, st, so, 
        opts, clickBack: clickExitAsm, reload,
        listView, setListView, fields, setFields, setField, useAsmDead, useAsmEdit, useAsmEnd, useAsmDel, useAsmPub, useAsmShare, asmDo, // tobon 3 1 add here to pass to sub elements
        asms, allAsms, asmId, act}; 
    const usePagAsm = usePageAssignments(tabProps, 0);
//):<CpHeaderTitle animation={true} title={t(getTransMap("sidemenu-my-assignments",isStt))} iconPath={IconList.menu.assignment} />}
    return <PageEXPFLayout sideIdx={_AssignmentRoot}
        menuTitle={asmId 
            ?(isThr
                ?<CpHeaderTitleAsm {...usePagAsm} /> //${t("assignment-cta-reports")}`} 
                :<CpHeaderTitle iconPath={IconList.general.previous} animation={true} onClick={clickExitAsm} 
                    title={`${t("sidemenu-assignments")} - ${t("assignment-cta-details")}`} /> //
            ):<CpHeaderTitle {...{animation: true, iconPath: IconList.menu.assignment, 
                title: t(isThr? "sidemenu-assignments": getTransMap("sidemenu-my-assignments", isStt)) }} />}
        menuActionBar={asmId 
            ?(isThr
                ?<CpTeacherAssignmentMenuActionBar {...usePagAsm} />
                :<CpOpenAssignmentCTA {...usePagAsm} />
            ):<CpAssignmentTabListFilterCTA {...tabProps} />}
        header=''//'headerheaderheader'
        subBody=''//'subBodysubBody'
        footer={asmId? '': <CpPagination {...{paging}} />}>
{/*
        {preJS({AState, ASort})}
        {preJS(fields)}
        {preJS(sAsms.map(a => a.assignId), 3)}
        {preJS(asms.map(a => a.assignId), 3)}
        className={"scroll-container h-100 d-flex flex-column w-100 overflow-auto overflow-y-md-hidden"}
*/}
        <ScrollContainer x={renderlist? 1: 0} y={renderlist? 3.5: 0} 
            className={"scroll-container vh-100 d-flex flex-column w-100 overflow-auto overflow-y-md-hidden"}>
            {asmId
            ?(doPV? '': <PageAssignment {...usePagAsm}/>)
            :<><CpAssignmentListFilter {...tabProps} />
                {listView
                    ?<CpAssignmentList {...tabProps} />
                    :<CpAssignmentList {...tabProps} /> //<CpAssignmentColumn {...tabProps} />
                }
            </>}
            <LayerAssignmentChange {...{useAsmDead}} />
            <LayerAssignmentEnd {...{useAsmEnd}} />
            <CpAssignOffCanvas {...{useAsmEdit}} />{ /* Change Settings */}
            <LayerAssignmentDelete {...{useAsmDel}} />
            <LayerAssignmentPublish {...{useAsmPub}} />
            <LayerAssignmentShare {...{useAsmShare}} />
        </ScrollContainer>
    </PageEXPFLayout>;
});
export default PageAssignments;

const usePageAssignments = (props, isReme) => {
    const {dispatch, asmId, act} = props; 
    const [isThr, isStt, uRole ] = expfRoles(props);

    const [_asm, setAsm] = useState();
    const asm = (_asm?.assignId === asmId) && _asm;
    const Exer = asm?.Exer;
    const [qtns, setQtns] = useState(); //should not need setQtnt
    const useCacheQtns = qids => qtns;

    const [works, setWorks] = useState();
    const [work, setWork] = useState();
    const [marks, setMarks] = useState();
    const [mark, setMark] = useState();
	const [remeAsms, setRemeAsms] = useState([]);

    const reload = async () => {
        if(asmId){
            const [a, e, qs, w, m, ws, ms, ras] = await loadAssignment(dispatch, asmId, isReme);
            setAsm(a);
            setQtns(qs);
            setWork(w);
			setMark(m);
		    setWorks(ws);
            setMarks(ms);
			setRemeAsms(ras);
        }
    };
    useEffect(() => { if(asmId) reload(); }, [asmId]);

    const clickExitAsm = clickUrl(dispatch, _pathAssign('',''));
    //const backToRpt = dispatch(urlPush_Replace(_pathAssign(asm.assignId, 'report')));
    const backToRpt = clickUrl(dispatch, _pathAssign(asmId, 'report'));

    const report = (act === 'report');
    const loading = props._saga?.loading?.count;
    const subProps = {...props, isThr, isStt, uRole, backToRpt,
        asmId, asm, Exer, qtns, useCacheQtns, work, mark, works, marks, remeAsms, clickBack: clickExitAsm, reload, report, loading};
    return subProps;
};

const useFindAsmsOpts = (isStt, isThr, uiEn, t, ut) => {
    const [opts, setOpts] = useState({});
    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs] = useFields(); 
    const f = toObj(fields);
    const [y, c, j, st, so] = [toStr(f.year), toStr(f.classId), toStr(f.AMSubjId), toStr(f.AState), toStr(f.ASort)];

    const AStateItems = mkAStateItems(isStt, t);
    const AState = AStateItems[st]? st: objKeys(AStateItems)[0];

    const ASortItems = mkASortItem(t);
    const ASort = ASortItems[so]? so: objKeys(ASortItems)[0];

    const subjItems = useMemoSubjItems(opts?.subjMetas, 1, uiEn, ut, t);
    const AMSubjId = subjItems[j]? j:  objKeys(subjItems)[0];

    const [yearItems, year, classItems, classId, yearClassDDI] = useYearClass(opts?.yearClass2d, y, c, uiEn, ut, isThr);

    useEffect(() => { 
        if((year !== y) || (classId !== c) || (AMSubjId !== j ) || (AState !== st) || (ASort !== so)) 
            setFields(fs => ( {...toObj(fs), year, classId, AMSubjId, AState, ASort} ) );
    }, [year, classId, AMSubjId, AState, ASort]);

    return {fields, setFields, setField, opts, setOpts,
        y, c, j, st, so, 
        year, classId, AMSubjId, AState, ASort,
        AStateItems, ASortItems, subjItems, yearItems, classItems, yearClassDDI, };
};


const CpHeaderTitleAsm = props => {
    const ex = toObj(props.Exer);
    const asm = toObj(props.asm);
    const showEn = langIsEn(asm.ALang);
    return <CpHeaderTitle style={{width: "1.5rem", height: "1.5rem"}} animation={true}
        titleIconPath={<><span className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn"}>
            {CpLangIco(showEn)}
        </span></>}
        title={langTxt(showEn, ex.ENameEn, ex.ENameCt)} iconPath={IconList.general.previous}  onClick={props.clickBack} />;
};

const CpAssignmentTabListFilterCTA = ReduxBind(props => { //base on poc AssignmentTabListFilterCTA
    const [isThr, isStt, uRole ] = expfRoles(props);
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const {yearClassDDI, yearItems, year, classItems, classId, subjItems, AMSubjId, AStateItems, AState, ASortItems, ASort } = props;
    const {opts, listView, fields, setField, setFields} = props;
    //const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts] = useFields(); 

    const [open, setOpen] = useState(false);
    const [activeKey, setActiveKey] = useState("0");

    const [storeFields, setStoreFields] = useState(fields);

    const clickPopFindAOpts = () => {
        setStoreFields(fields);
        setOpen(true);
    };
    const clickCloseFindAOpts = () => {
        // cancel, restore fields
        setFields(storeFields);
        setOpen(false);
    };
    const clickFindAsms = () => setOpen(false);
    const clickTab = id => setActiveKey(prev => (prev === id)? "": id);
    const filterCount = '';

    return <>
        <span className={"position-relative d-block d-lg-none"}>
            <CpActionButtonComponent variant={"gray-exercise-third-btn"} size={SIZE.XS} onClick={() => clickPopFindAOpts()}
                icon={<CpIco src={IconList.general.filterAdvance}/>} />
            {filterCount? <small className={"filter-count bg-danger badge rounded-pill position-absolute fs-10 text-light pe-none user-select-none"}>{filterCount}</small>:''}
        </span>
        <CpShareOffcanvas show={open} placement={"end"} disableHeader={true} disableFooter={true}>
            <div className={"d-flex flex-column justify-content-between h-100 overflow-hidden"}>
                <div className={"overflow-auto h-100"}>
                    <div className={`exercise-filter d-flex flex-column w-100 h-100 ${activeKey !== "" ? "justify-content-between" : ""}`}>
                      <span role={"button"} onClick={() => clickTab("1")}
                            className={`bg-exercise-third-btn text-light searchExercises w-100 py-1 px-3 d-flex semi-bold fs-5 accordion-button collapse`}>{t("search-questions-search-from")}</span>
                        <div className={`d-flex flex-column p-3 gap-3 d-lg-none h-100 pt-3 overflow-auto flex-grow-1}`}>
                            {isStt? <span className={`d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
{/*<span className={"d-flex align-items-center semi-bold"}>{t("year")}:</span><span className={`d-flex`}><CpDropdown className="w100" items={yearItems}/></span>*/}
                                <span className={`d-flex`}><AssignmentFilterYearClassDropdown {...{yearClassDDI, classId, cb:setField('classId') }} /></span>
                            </span> :''}
                            {isThr? <span className={`d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
{/*<span className={"d-flex align-items-center semi-bold"}>{t("year")}:</span><span className={`d-flex`}><CpDropdown className="w100" items={yearItems}/></span>*/}
                                <span className={`d-flex`}><CpAssignmentFilterYearDropdown {...{yearItems, year, cb:setField('year') }} /></span>
                            </span> :''}
                            {isThr? <span className={`d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
{/*<span className={"d-flex align-items-center semi-bold"}>{t("class")}:</span><span className={`d-flex`}><CpDropdown className="w100" items={classItems}/></span>*/}
                                <span className={`d-flex`}><CpAssignmentFilterClassDropdown {...{classItems, classId, cb:setField('classId')}} /></span>
                            </span> : ''}
                            <span className={`d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center`}>
{/*<span className={"d-flex align-items-center semi-bold"}>{t("subject")}:</span><span className={`d-flex`} style={{width: "280px"}}><CpDropdown className="w100" items={subjectItems}/></span>*/}
                                <span className={`d-flex`} style={{width: "280px"}}><CpAssignmentFilterSubjectDropdown {...{subjItems, AMSubjId, cb:setField('AMSubjId')}} /></span>
                            </span>
                            {listView? <CpAssignmentFilterStatusDropdown {...{AStateItems, AState, cb:setField('AState')}} />: ''}  {/*<AssignmentFilterStatusDropdown />*/}
                            {isStt? '': <CpAssignmentFilterSortingDropdown  {...{ASortItems, ASort, cb:setField('ASort')}} />}                                                        {/*<AssignmentFilterSortingDropdown />*/}
                        </div>
                    </div>
                </div>
                <div className={"d-flex flex-column mt-3 mt-sm-0 p-3 gap-2 justify-content-center"}>
                    <div className={"d-flex flex-row gap-2 justify-content-between align-items-center"}>
                        <span className={"d-flex"}>
                            <CpActionButton title={t("cancel")} hiddenText={false} onClick={clickCloseFindAOpts}
                                className={"text-dim-400 gap-2 rounded semi-bold user-select-none btn"} />
                        </span>
                        <span className={"d-flex gap-2"}>
{/*<ActionButton title={t("clear")} hiddenText={false} className={"btn-outline-dim-400 gap-2 rounded semi-bold user-select-none btn"} /> */}
                            <CpActionButton title={t("search")} hiddenText={false} onClick={clickFindAsms}
                                className={"btn-exercise-third-btn gap-2 rounded semi-bold user-select-none btn"} />
                        </span>
                    </div>
                </div>
            </div>
        </CpShareOffcanvas>
    </>
});

export const pickAsmsByMSubj = (asms, MSubjId) => {
    const allJ = (!MSubjId) || (MSubjId === '*');
    return allJ? toAry(asms): toAry(asms).filter(a => {
        const { EMetaDisplays, EMSubjId } = toObj(a?.Exer);
        const jSet = toUniIdSet([...toAry(EMetaDisplays), EMSubjId]);
        return jSet.has(MSubjId);
    });
};

export const fixAsms = (asms, works, marks) => {
    const ws = Object.fromEntries(toAry(works).map(w => [w.assignId, w]));
    const ms = Object.fromEntries(toAry(marks).map(w => [w.assignId, w]));

    return toAry(asms)
    //.filter(a => a.Exer) 
    .map(asm => {
        const Exer = toObj(asm?.Exer);
        const fullList = {          //fill up the missing fields to become full list
            //...samplieList, 
            ...toObj(asm), 
        };
        const {assignId} = fullList;
        const S = toObj(fullList.Stat);;
        const [ttl, submit, mark] = [toInt(S.ttl), toInt(S.submit), toInt(S.mark)]; 
        const notsub = ttl - submit; //toInt(_Stat.total) - toInt(_Stat.subed) + 1; 
        return {
            ...toObj(fullList), 
            Stat: {...S, ttl, submit, mark, notsub,},         //add the extra fields in needed
            work: initWork(ws[assignId], asm, Exer),
            mark: initMark(ms[assignId], asm, Exer),
        };
    })
};

const groupRemeByAsm = remes => {
//console.log('groupRemeByAsm()', remes);
    const reme2d = {};
    toAry(remes).forEach(r => {
        const {pAsmId} = r;
        if(!pAsmId) return;
        const rs = toAry(reme2d[pAsmId]);
        rs.push(r);
        reme2d[pAsmId] = rs;
    });
    return reme2d;
};


export const initWork = (w, a, e) => {
    if(!isObj(w)) return w;
    //const resp = toAry(w.resp);
    return {...w};
};

/*
setMark({resp:ECtns.map(()=>{
    return {aCount:0, mCount:0, mScore:0, aScore:0}
}), ...mark});
*/
export const initMark = (m, a, e) => {
    const r = toAry(m?.resp);
    const resp = toAry(e?.ECtns).map( (e, i) => ( { aCount:0, mCount:0, mScore:0, aScore:0, ...toObj(r[i]) } ));
    return {...m, resp};
};

export const useMemoSubjItems = (subjMetas, hasAll, uiEn, ut, t) => useMemo(() => {
    const subopts = toAry(subjMetas).map(s => [s.metaSetId+','+s.metaId, ut(s.nameEn, s.nameCt)]);
    return ent2DropdownItem(hasAll? [['*', t('all')], ...subopts]: subopts);
}, [subjMetas, uiEn]);

const CpTeacherAssignmentMenuActionBar = (props) => { //poc TeacherAssignmentMenuActionBar
    return <span className={"CpTeacherAssignmentMenuActionBar d-flex gap-2 align-items-center"}>
        <CpReloadCTA {...props} />
        {/*<CpShareCTA {...{...props, layout:"in-detail"}} /> */}
        <CpPreviewCTA {...props} /></span>;
};

const CpReloadCTA = (props => { //poc ReloadCTA
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const onClick = e => {stopEvent(e); props.reload(); };
    return <CpActionButton title={t("assignment-cta-reload")} iconPath={IconList.assignment.reload}
        className={`CpReloadCTA btn exercise-action-btn gap-2 rounded semi-bold user-select-none`}
        onClick={onClick} hiddenText={true}></CpActionButton>;
});
const CpPreviewCTA = (props => { //poc PreviewCTA 
    const {asm, qtns, useCacheQtns, dispatch, doPV, setDoPV} = props;
    const [ t ] = useUILang();
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);

    const setOnAddMedia = () => {alert('Front Preview Not Support Media Pool')};
    const onClick = e => {stopEvent(e); props.reload(); setDoPV(1); };
   
    // for preview, set isThr to 0, skip load student filter at the bottom bar
    const pvProps = {...props, preview:1, showResult:0, showPub:1, lock:1, isThr:0,  
        close: e => {stopEvent(e); setDoPV(0); },
        PVMode: (asm?.AMode === "SCROLL_MODE")? 1: 0, 
        showEn: langIsEn(asm?.ALang),
        ECtns: toAry(asm?.Exer?.ECtns),
        qtns, useCacheQtns, setOnAddMedia, mediaDLs, getMediaDLs, addLocalMedias,
        aHints: asm?.AHints
    };

    return doPV?<DoEx {...pvProps}/>
        :<CpActionButton title={t("assignment-cta-preview")} iconPath={IconList.assignment.preview}
        className={`CpPreviewCTA btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none text-nowrap`}
        onClick={onClick} hiddenText={true}></CpActionButton>;
});

const CpOpenAssignmentCTA = ReduxBind(props => {
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const [isThr, isStt, uRole ] = expfRoles(props);
    const {asmId, dispatch} = props;
    const onClick = clickUrl(dispatch, _pathAssign(asmId,'')); 
    return <CpActionButton title={t(getTransMap("assignment-cta-open-assignment",isStt))} iconPath={IconList.assignment.open}
        className={`CpOpenAssignmentCTA btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none text-nowrap`}
        onClick={onClick} hiddenText={true}>
    </CpActionButton>;
});
