import React, { useEffect, useState, useMemo } from 'react';
//import {Dropdown} from "react-bootstrap";
import CpDropdown, { obj2DropdownItem } from './CpDropdown';
import { _WebRoot, _ATRoot } from '../../consts/ATConstReact';

import { useUILang } from '../../AppExPf/utils/useUILang';
import CpIco from './CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { SIZE } from './CpExPFConst';
import ActionButtonComponent from './CpActionButtonComponent';
import { maxVal, minVal, toInt, toStr } from '../../libs/libType';


//const rppList = obj2DropdownItem( {1:1, 2:2, 10:10, 50:50, 100:100});


const CpPagination = (props) => { // base on poc Pagination.tsx
    const [t] = useUILang();

    const { miniLayout, paging, poolSchool, poolMy, poolOup } = props;

    const { page, rpp, totalRow, totalPage, res1, res2, setPaging } = paging;
    
    const [pEdit, setpEdit] = useState(page);
    useEffect(() => {setpEdit(page);}, [page]);
    const exitPEdit = e => {
        const p = maxVal(1, minVal(totalPage, toInt(e?.currentTarget?.value) || toInt(e?.target?.value) || page));
        
        setpEdit(p)
        setPaging({page:p});
    }

    const setRpp = rpp => {
        
        setPaging({rpp});
    }
    const clickPrev = e  => setPaging({page:page-1});
    const clickNext = e  => setPaging({page:page+1});
    const rppList = useMemo(()=>obj2DropdownItem( {10:10, 25:25, 50:50, 100:100}), []);
    
    //style={{position:"fixed", bottom:0, right:0, paddingLeft:(miniLayout?"":"80px !important")}}>
    //style={{position:"fixed", bottom:0, right:0, paddingLeft:"80px !important"}}
    return ( //<> {preJS(paging)}
    <div className={"CpPagination sub-navbar d-flex flex-column flex-md-row justify-content-center justify-md-content-between align-items-center w-100 px-3 py-2 semi-bold fs-8"}
        style={{position:"sticky", bottom:0}}>
        <div className={`${miniLayout? "d-none d-lg-flex": "d-none d-md-flex"
            } justify-content-between justify-content-md-start align-items-center w-100 mb-2 mb-md-0`}>
            <div className={`w-100 text-truncate d-flex flex-wrap gap-2 align-items-center`}>
                <span>{t("results")}:</span>
                <span>{res1}-{res2}<span> of {totalRow}</span></span>
            </div>
        </div>

        <div className={`w-100 text-center text-md-end d-flex justify-content-between ${miniLayout? "justify-content-lg-end": "justify-content-md-end"
            } align-items-center gap-3`}>

    <div className={"d-flex justify-content-center align-items-center gap-2"}>
        <span className={"d-flex align-items-center"} style={{width: "3.2rem"}}>
        <CpDropdown className="w100" idx={rpp} cb={setRpp} items={rppList} position={"top"} className={"min-width-none"}/> 
        </span>
        <span className={`${miniLayout? "d-none": "d-none d-md-block"} text-nowrap`}>{t("results-per-page")}</span>
        <span className={`${miniLayout? "d-block": "d-block d-md-none"} text-nowrap`}>{t(poolOup?"per-question":"per-exercise")}</span>
    </div>
            <div className={`d-flex justify-content-between align-items-center gap-1 gap-md-2`}>
                <ActionButtonComponent variant={"color-sidenav-primary"} onClick={clickPrev} 
                    size={SIZE.XS} icon={<CpIco src={IconList.general.previousPage} />}/>
                    <span className={"d-flex align-items-center gap-1 gap-md-2"}>
                    
                    <input type="text" size="3" maxLength="6"  
                    className="tac border border-sidenav-primary px-1 py-1 d-none d-md-block"
                        onBlur={exitPEdit} value={toStr(pEdit)} onChange={e => setpEdit(e.currentTarget.value) } />

                    <span className={"d-block d-md-none"}>{res1}-{res2}</span>
                    <span>/</span>
                    <span className={"d-none d-md-block"}>{totalPage}</span>
                    <span className={"d-block d-md-none"}>{totalRow}</span>
                    </span>
                <ActionButtonComponent variant={"color-sidenav-primary"} onClick={clickNext}  
                    size={SIZE.XS} icon={<CpIco src={IconList.general.nextPage} />}/>
            </div>
        </div>
    </div>
    ); //</>
};
export default CpPagination;

//import DropdownComponent from '../../poc/screens/schema/components/custom/form/dropdown/DropdownComponent';
/*
const CpPagination = (props) => {
    const [t] = useUILang();
    return <div className={"sub-navbar d-flex flex-column flex-md-row justify-content-center justify-md-content-between align-items-center w-100 px-3 py-2 semi-bold"}>
        <div className={"d-flex justify-content-between justify-content-md-start align-items-center w-100 mb-2 mb-md-0 d-none d-md-block"}>
            <div className={"w-100 text-truncate"}>{t("results")}: 1000-1025 of 1500</div>
        </div>
        <div className={"w-100 text-center text-md-end d-flex justify-content-between justify-content-md-end align-items-center gap-3"}>
            <CpPerpageDropdown/>
            <div className={"d-flex justify-content-between align-items-center gap-1 gap-md-2"}>
                <ActionButtonComponent variant={"color-sidenav-primary"} size={SIZE.XS} icon={<CpIco src={IconList.general.previousPage}/>}/>
                <span className={"d-flex align-items-center gap-1 gap-md-2"}>
                    <span className={"border border-sidenav-primary px-3 py-1 d-none d-md-block"} contentEditable={true} suppressContentEditableWarning={true}>40</span>
                    <span className={"d-block d-md-none"}>1000-1025</span>
                    <span>/</span>
                    <span className={"d-none d-md-block"}>60</span>
                    <span className={"d-block d-md-none"}>1500</span>
                </span>
                <ActionButtonComponent variant={"color-sidenav-primary"} size={SIZE.XS} icon={<CpIco src={IconList.general.nextPage}/>}/>
            </div>
        </div>
    </div>
};
export default CpPagination;

const CpPerpageDropdown = (props) => {
    const [t] = useUILang();
    const pageList = [25, 50, 100];
    return <div className={"d-flex justify-content-center align-items-center gap-2"}>
        <DropdownComponent size={SIZE.SM}>
            <Dropdown.Toggle variant={"outline-sidenav-primary with-bg-white gap-2 justify-content-between"}>
                <span>{pageList[0]}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu variant={"outline-sidenav-primary border-radius-0"} className={"border-sidenav-primary"}>
                {pageList.map((item, idx) => <Dropdown.Item eventKey={item} key={idx}>{item}</Dropdown.Item>)}
            </Dropdown.Menu>
        </DropdownComponent>
        <span className={"d-none d-md-block text-nowrap"}>{t("results-per-page")}</span>
        <span className={"d-block d-md-none text-nowrap"}>{t("per-page")}</span>
    </div>
};
*/
