import React, {useContext} from "react";
import {AccordionContext, useAccordionButton} from "react-bootstrap";
import CpIco from "../../AppExPFUser/_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";

const CpExerciseAccordionOpenCTA = ({children, eventKey, callback}) => { // poc ExerciseAccordionOpenCTA.tsx
    const {activeEventKey} = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <span role="button" onClick={decoratedOnClick}
            className={"d-flex justify-content-between align-items-center text-wrap w-100"}>
            <span className={"text-wrap fs-8"}>{children}</span>
            <span className={"fs-5"}><CpIco src={isCurrentEventKey ? IconList.general.arrowUp : IconList.general.arrowDown}/></span>
        </span>
    );
};

export default CpExerciseAccordionOpenCTA;