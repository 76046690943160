import React, {useEffect, useRef, useState  } from 'react';

import * as UI from '../../libs/libUI';
import { isAry, toAry, toStr, tryParseJson,  } from '../../libs/libType';
import { apiCallLoad_, s3UploadPrivate, } from '../../libs/awsFuncs';
import { iconButton } from '../components/CpATIcoBtn';
import { ReduxBind } from '../../saga/ReduxState';
import CpTabHead from '../components/CpTabHead';
import { arrayAddOrDelete } from '../../libs/libArray';
import TabMediaDown from './TabMediaDown';
import TabMediaUp from './TabMediaUp';
import { SizeScreenMask } from '../AppUtil';

const LayerMediaPool = ReduxBind(props => {
    const {dispatch, clickClose, onAdd, mediaDLs, getMediaDLs, maxMedia, mimes, mexts} = props;
    const [tab, setTab] = useState(1);
    const [tickSjs, setTickSjs] = useState([]);
    const [ATSubjects, setATSubjects] = useState([]);
    const [mySjIds, setMySjIds] = useState([]);
    const [init, setInit] = useState(0);

    useEffect( ()=>{ 
        if(onAdd && (!init)){
            setInit(1);
            apiCallLoad_(dispatch, '/getMediaOpts', onOpts, {}); 
        } 
    }, [onAdd]);

    const setTickSj = sid => on => setTickSjs(ts => arrayAddOrDelete(ts, sid, on)); 
    const onOpts = (res, err) => {
        setATSubjects(res?.ATSubjects);
        setMySjIds(res?.mySjIds);
    };
    const clickTab = t => e => { UI.stopEvent(e); setTab(t); };  

    if(!onAdd) return '';
    const mft = mexts? mexts.join(', ', mexts): mimes? mimes.join(', '): 'All Types';
    //return UI.ScreenMask(<>  
    //className={"modal-xl"} 
    //<Modal size={'xl'} centered show animation={true} enforceFocus={false}>         
    return SizeScreenMask(<>
        <div className="LayerMPool">
            <div className="flexColCenter" style={{width:'100%', height:'100%'}} >
                <div className="bold fxRow" style={{width:'100%'}}>
                    <div className="fxGrow tal">Add media ( {mft} ) </div>
                    <div>{iconButton('','general/close','#b4b4b4','transparent',clickClose,true,{transform:"scale(2)"})}</div>
                </div>
                {CpTabHead(tab, clickTab, ['Upload', 'pool'], [])}
                {(tab === 0)?
                    <TabMediaUp {...{ATSubjects, mySjIds, tickSjs, setTickSj}} />:
                    <TabMediaDown {...{ATSubjects, mySjIds, clickClose, onAdd, mediaDLs, getMediaDLs, maxMedia, mimes, mexts}} />}
            </div>
        </div></>,true,'xl',true)
    //</Modal>
    //</>);
});
export default LayerMediaPool;

