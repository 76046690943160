import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { asyncApiCallLoad_, asyncApiCall_ } from '../../libs/awsFuncs';
import { fileExt, humanFileSize, StrShorten } from '../../libs/libFormat';
import { objEntries, objKeys, objVals, toAry, toInt, toObj, toStr } from '../../libs/libType';
import * as UI from '../../libs/libUI';

import { ReduxBind } from '../../saga/ReduxState';
import { aTErrTxt1, ATUICheckBox0, aTUIMust, CpATBtn, Loader, preJS, autoId, readUploadText } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';

//import TabQMC from './TabQMC';
import { ATDo_QImp, _ATCanDo } from '../../consts/ATRoleRights';
import { __SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_MCS, __SYSQSubType_OED, __SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_POL, expf2, hasDrawQ } from '../../consts/ATSysQType';
import { validEditATQtnV2 } from '../../consts/ATValidateQ';
import { arrayAddOrDelete, arrayForEachAsync } from '../../libs/libArray';
import { downloadUrl } from '../../libs/libDownload';
import { urlPush_Replace } from '../../saga/urlPush.saga';
import CpATSubjectTickList from '../components/CpATSubjectTickList';
import { subState } from '../utils/useChain';
import { hasErr, useEditDraftPub } from '../utils/useEditDraftPub';
import { mediaUpFile } from '../utils/useMediaCache';
import { ckImgsSetQ, QImportCSV } from './utilQImport';

import 'bootstrap/dist/css/bootstrap.min.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { toUniIdAry } from '../../consts/ATValidate';
import { packQtn } from '../../consts/wirisConfig';
import { useUILang } from '../utils/useUILang';

const ImportQTypeNames = {
[__SYSQSubType_MCS]: 'Multiple Choose ',
//[__SYSQSubType_MCT]: 'Multiple Choose Table',
[__SYSQSubType_FIB]: 'Fill in the Blanks',
[__SYSQSubType_LBT]: 'Label Diagram (Text/DropDown)',
//[__SYSQSubType_LBD]: 'Label Diagram - Drop Down', 
[__SYSQSubType_OED]: 'Open Ended - Drawing', 
[__SYSQSubType_OEG]: 'Open Ended - General', 
[__SYSQSubType_OEE]: 'Open Ended - Essay', 
[__SYSQSubType_POL]: 'Polling',
};
const ImportQTemplates = {
  [__SYSQSubType_MCS]: 'mc',
  //[__SYSQSubType_MCT]: 'Multiple Choose Table',
  [__SYSQSubType_FIB]: 'fib',
  [__SYSQSubType_LBT]: 'ltd',
  //[__SYSQSubType_LBD]: 'Label Diagram - Drop Down', 
  [__SYSQSubType_OED]: 'drawing', 
  [__SYSQSubType_OEG]: 'open_ended', 
  [__SYSQSubType_OEE]: 'essay', 
  [__SYSQSubType_POL]: 'polling',
};

//File Queue Item Steps
const _queuePending = 0; //queued to upload 
const _queueUploading = 1; //uploading
const _queueDeleting = 2; //queued to remove
const _queueUploaded = 3; //Uploaded to Git

const [maxCsv, maxMedia] = [1, 1000];
const [maxFSize] = [5 * 1024 * 1024];


export const useKeyList = () => {
  const [items, setItems] = useState({});
  const addItem = i => setItems(is => ({ ...is, [autoId()]:i }) );
  const delItem = key => setItems(is => Object.fromEntries(Object.entries(is).filter(([k, v]) => k !== key ))); 
  const [keys, values] = [Object.keys(items), Object.values(items)];
  return [items, setItems, addItem, delItem, keys, values];
};

const PageATQImport = ReduxBind((props) => {
  const { dispatch } = props;
  const [t, uiEn, UILang, setUILang, ut] = useUILang();
  const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
  const canQImp = _ATCanDo(role, ATDo_QImp);

  const [impRet, setImpRet] = useState(); 
  const [saving, _setSaving] = useState();
  const setSaving = (msg, ttlQ, doneQ, ttlM, doneM) => _setSaving({msg, ttlQ, doneQ, ttlM, doneM});
  const unsetSaving = () => _setSaving();
  
  const [mLnks, setMLnks] = useKeyList();

  const [ csvErrs, setCsvErrs ] = useState([]);
  const [ test, setTest] = useState({});
  const [ csvs, setCsvs, addCsv, delCsv, csvKeys, csvItems ] = useKeyList();
  const [ medias, setMedias, addMedia, delMedia, mediaKeys, mediaItems ] = useKeyList();
  const [ errs, setErrs, addErr, delErr, errKeys, errItems, ] = useKeyList();

  const [fields, setFields, setField,  setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts, draft, setDraft, pub, setPub] = useEditDraftPub();
  const setTickSj = sid => on => setFields(subState('SubjIds', ts => arrayAddOrDelete(toAry(ts), sid, on))); 
  const SQType = (fields?.SQType) || __SYSQSubType_MCS;

  const ATSubjects = opts?.ATSubjects;
  const mySjIds = opts?.mySjIds;
  
  const csv1 = objVals(csvs)[0];
  const lock = 0;

  const reload = async () => {
    const [res, err] = await asyncApiCallLoad_(dispatch, '/getQImportOpts', {});
    setOpts(o => ( {...toObj(o), ATSubjects:res?.ATSubjects, mySjIds:res?.mySjIds} ) );
  };

  const queueExists = name => Object.values(medias).some(i => i.name === name);
  const validUpdateItem = i => ''; //todo
  
  const onDrop = addFiles => {
    
    addFiles.forEach( addFile => {
        const item = fileToItem(addFile);
        const err = validUpdateItem(item);
        
        if(err){
            addErr(err);
        }else{
            if('csv' === fileExt(item.name)){
              setCsvs(ms => ( { ...toObj(ms), csv1: item } ) );  
            }else{
                if(Object.keys(medias).length >= maxMedia){
                    addErr(item.name+': too many Media files, max:'+maxMedia);
                }else if(queueExists(item.name)){
                    addErr(item.name+': please remove old one, before re-upload');
                }else{
                    setMedias(ms => ( { ...toObj(ms), [autoId()]: item } ) );  
                }
            }
        }
    });
  };

  useEffect(() => { canQImp? reload(): dispatch(urlPush_Replace(_ATRoot)) }, [] );
 
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  const dropProps = getRootProps();
  const DropCfg = lock? {...dropProps, onClick:UI.stopEvent} : dropProps;

  const unUp = (deleter, k) => lock? undefined: (e => { UI.stopEvent(e); deleter(k); });

  
  const clickUp = e => { UI.stopEvent(e); clickUpC(); }; 
  const clickUpC = async () => {
    setSaving('Checking Input Files');
    do{
      const [fs, fes] = validFields(fields);
      if(!csv1) fes.csv1 = 'Please Upload CSV'
      setFields(fs);
      setFieldErrs(fes);
      if(hasErr(fes)) break;
      
      const impOpts = {...toObj(opts), isAT:1, isImp:1};
      const csvRes = await loadCheckQtnCSV(fs, fes, impOpts, csv1);
      const { hcMC, headErrs, qrows, qs, csvErrs, rowErrs, qmedias, qErrs } = csvRes;
      
      const [qMs, upMs, missMs] = checkMissMedia(qmedias, medias);

      if(missMs.length)
        csvErrs.push('Missing Media: '+missMs.join(', '));

      if(!hasErr(headErrs) ){
        await checkQIDs(dispatch, csvRes, 1);
      }

      const mixerrs =  [...csvErrs, ...objEntries(rowErrs).map( ([r, e]) => ('Row '+r.split('_')[1]+': '+e ) ) ];
      setCsvErrs( mixerrs );
      setTest( csvRes );

      if((!qs.length) || (mixerrs.length))
        break;
        
      const updateM = (ttlM, doneM) => setSaving('Uploading Medias', qs.length, 0, ttlM, doneM);
      const newMLs = await upMedias(dispatch, medias, setMedias, updateM);
      const MLs = { ...toObj(mLnks), ...newMLs };
        
      setMLnks(MLs);
      const errMiss2 = reCheckMissMedia(qMs, MLs);
      if(errMiss2){
        csvErrs.push(errMiss2);
        break;
      }
      
      if(fakeM) {
        const fms = ['Ny2EH3jYK1ZoBs38JUGbsX','Da5uGTJuRXiSkN3oGKN9fM','AhVsZ9uBVahwuUdDs2sLSE','TJP2d71mAdKfAY8ekwxppV'];
        qMs.forEach((name, i) => {
          MLs[name] || ( MLs[name] = { name, size: "99999", upid: fms[i % 4]} );
        });
        
      }

      const totalMedia = objKeys(medias).length;
      const updateQ = (ttlq, doneQ) => { 
        
        setSaving('Uploading Question', ttlq, doneQ, totalMedia, totalMedia); };
      const ir = await asyncImportQs(dispatch, qs, MLs, updateQ);
      //debugMode() ?setTest(ir): 
      setImpRet(ir);

    }while(0);
    unsetSaving();
  };
  

  if(impRet){ 
    const clickReset = e => { UI.stopEvent(e);  setCsvErrs([]); setTest({}); setCsvs({}); setMedias({}); setErrs({}); setFieldErrs({}); setImpRet(0); };
    const qok = impRet.filter(ir => (ir.res?.state === 'ok'));
    const qerr = impRet.filter(ir => (ir.res?.state !== 'ok'));
    const mapId = s => s.length? 
    s.map(s => {
      const id = s.q.QId;
      return <div key={id} className="w m4 f12">{id}</div>
    }):
    <div className="m4 f12">(no question)</div>;
    return <div className="atpage">
      <div className="atpagehead f16">Questions / Batch Import </div>
      <div className="atrow"><div className="fieldhead f14" ><b>Question(s) Imported:</b> </div>{mapId(qok)}</div>
      <div className="atrow"><div className="fieldhead f14" ><b>Question(s) Rejected:</b> </div>{mapId(qerr)}</div>
      {CpATBtn('Import More', 'be', clickReset, lock)}
    </div>;
  }; 
//      {UI.Button0('Import more', clickReset, 'be')}
  
  const clickDL = e => { UI.stopEvent(e); downloadUrl('/ATTemplates/'+(expf2?'':'imp_q_v2/')+'template_import_q'+toStr(ImportQTemplates[SQType])+'.csv'); };
  const {qrows, qs, errqs, qmedias, hcMC, ..._test} = toObj(test);
  return <div className="atpage atrow" >
    <div className="atpagehead f16">Questions / Batch Import</div>
    <div className="atsect f14">
      <div className="atrow">
        <div className="trow"><div className="atfieldhead">Language version(s) {aTUIMust} </div></div>
        <div className="trow">
          <div className="w m4">
              <label>{ATUICheckBox0(fields.LangEn, setTick('LangEn'), 'chkEn', lock)}En</label>{aTErrTxt1(fieldErrs.LangEn, t)}
          </div><div className="w m4">
              <label>{ATUICheckBox0(fields.LangCt, setTick('LangCt'),  'chkCt', lock)}繁中</label>{aTErrTxt1(fieldErrs.LangCt, t)}
          </div>
          {aTErrTxt1(fieldErrs.Langs, t)}
        </div>
      </div>
      <div className="trow">
        <div className="trow"><div className="atfieldhead">Enable for subject(s) in AT {aTUIMust} </div></div>
        <div className="trow">
          {CpATSubjectTickList(ATSubjects, mySjIds, toAry(fields.SubjIds), setTickSj, lock) }
          {aTErrTxt1(fieldErrs.SubjIds, t)}
        </div>
      </div>
      <div className="trow"><div className="atfieldhead">System Question Type {aTUIMust}</div></div>
      <div className="trow">
        {lock
          ?<div>{ImportQTypeNames[SQType]}</div>  
          :<select value={SQType} onChange={ e => { UI.stopEvent(e); setField('SQType')(e.currentTarget.value); } } >
            {objEntries(ImportQTypeNames)
                .filter(([k, v]) => ( hasDrawQ() || (k !== __SYSQSubType_OED) ))
                .map(([id, val]) => (<option key={id} value={id}>{val}</option>))
            }
          </select>
        }    
        {CpATBtn('Download Template', 'btt', clickDL, lock,) }
      </div>
      <div className="trow">
        <div><div className="atfieldhead">CSV & Media Files {aTUIMust}:</div></div>
        {aTErrTxt1(fieldErrs.csv1, t)}
      </div>
      <div className="trow tal p8 box1 br8" {...DropCfg}>
      <div className="atrow">{
          lock? <i><b>Not Allowed to add more files to Closed Update Lot</b></i>:
          isDragActive ? "Drop files here to upload...": "Drag & drop, or click to add files"
        }</div>
      <div className="atrow"><b>CSV Files:</b> <small>( max: <b>{maxCsv}</b> files, max <b>{maxFSize}</b> byte per file )</small></div>
        <div className="atrow" >{ (csvKeys.length < 1)?'(csv file)'
            :objEntries(csvs).map( ([k,v]) => <CpFileCard key={k} file={v} onClickX={unUp(delCsv, k)} /> )
        }</div>
        <div className="atrow"><b>Media Files:</b> <small>( max: <b>{maxMedia}</b> files, max <b>{maxFSize}</b> byte per file )</small></div>
        <div className="atrow">{ (mediaKeys.length < 1)?'(media files)'
            :objEntries(medias).map( ([k, v]) => <CpFileCard key={k} file={v} onClickX={unUp(delMedia, k)} /> )
        }</div>
        <input {...getInputProps()} />
      </div>
      {toAry(csvErrs).map((e, i) => <div key={i} >{aTErrTxt1(e)}</div> ) }
      <div className="trow">{CpATBtn('Verify CSV and Upload', 'bu', clickUp, lock) }</div>
{/*
      preJS(medias, 3)
*/}
      <div>{objEntries(errs).map(([key, err]) => <ErrCard {...{key, err, onClickX:unUp(delErr, key)}} />)}</div>
    </div>
    <CpSavingMask {...{saving}} />
  </div>;
});
export default PageATQImport;

//<div><div className="atfieldhead">CSV File {aTUIMust}:</div></div>
//<div><div className="atfieldhead">Media Files {aTUIMust}:</div>{cpATIcoBtn_('general/publish', '', UI.stopEvent)}</div>

export const CpSavingMask = ({saving}) => {
  return (!saving)? '':
  <div className="screenMaskLoader" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={UI.stopEvent} >
    <div className='batchImportSavingTop'>
      <div className='batchImportContent'>
        <span className='f18 semi-bold'>Batch Import Status</span><p/>
        {Loader('50px', {display:'inline-block', verticalAlign:'middle'})}<p/>
        <h1>{toStr(saving.msg)}</h1>
        <span className='f16'>{'Question(s):'+toInt(saving.doneQ)+'/'+toInt(saving.ttlQ)}</span>
        {saving.ttlQ?<><div style={{width:'80%'}}>
          <ProgressBar animated variant="info" now={Math.floor((saving.doneQ/saving.ttlQ)*100)}/>
        </div></>:''}
        <p/>
        {saving.ttlM?<><span className='f16'>{'Media(s):'+toInt(saving.doneM)+'/'+toInt(saving.ttlM)}</span>
        <div style={{width:'80%'}}>
          <ProgressBar animated variant="info" now={Math.floor((saving.doneM/saving.ttlM)*100)}/>
        </div></>:''}
      </div>
    </div>
  </div>;
};

const cssATFCardName = {display:'inline-block', minWidth:'80px', maxWidth:'400px', margin:'0 8px 4px 4px', overflowWrap:'break-word',};
export const CpFileCard = props => {
  const f = props.file;
  const pc = f.progress; //Math.floor(Math.random()*100);
  const clickX = props.onClickX;
  const xBtn = ((f.step === _queuePending) || (f.step === _queueUploaded))
    ?(clickX? UI.Button0('X', clickX, 'btnx', 'br12', {}): '')
    :Loader('26px');
  return (<div className="w box1 m4 p4 br8">
      <div className="UpCardBarBg"><div className="UpCardBarFg" style={{width:''+pc+'%'}} ></div></div>
      <div style={{position:'relative', zIndex:1}}><div>
        <div style={cssATFCardName}>{StrShorten(f.name, 52, 23, 23, '...')}</div>
        <div className="w">{xBtn}</div>
      </div></div>
  </div>)
};

const ErrCard = (props) => {
  const clickX = props.onClickX;
  const xBtn = clickX? UI.Button0('X', clickX, 'btnx', 'br12', {}): '';
  return (<div className="w box1 m4 p4 br8">
      <div className="w cf00" style={{margin:'0 8px 0 4px', overflowWrap:'break-word',}}>{props.err}</div>
      <div style={{float:'right', margin:'0'}}>{xBtn}</div>
  </div>)
};

const validFields = fields => {
  const fes = {};
  const f = toObj(fields);
  const fs = {
    LangEn: f.LangEn? 1: 0,
    LangCt: f.LangCt? 1: 0,
    SubjIds: toAry(f.SubjIds),
    SQType: f.SQType || __SYSQSubType_MCS,
  };
  if(!(fs.LangEn || fs.LangCt)) fes.Langs = 'Please Select Import Language';
  if(!(fs.SubjIds.length)) fes.SubjIds = 'Please Select Subjects';
  // SQType
  return [fs, fes];
};

const loadCheckQtnCSV = async (fields, fieldErrs, opts, csv1, ) => {
  const [csvTxt, err] = await readUploadText(csv1.input);
  const [qrows, qmedias, headErrs, hcMC] = await QImportCSV(csvTxt, fields, opts); 
  const csvErrs = [...headErrs];
  const rowErrs = {};
  const [qs, errqs] = [[], []];
  toAry(qrows).forEach( (qrow, idx) => {
    const [rownum, q, qerr] = [idx+2, ...qrow, ];
    const verrs = validEditATQtnV2(q, opts, []);
    const rowerrs = [...objVals(qerr), ...objVals(verrs)];
    const rowerrStr = rowerrs.join('; ').trim();
    q.imprownum = (idx+2);
    if(rowerrStr){ 
      rowErrs['ImpRow_'+rownum] = rowerrStr;
      errqs.push(q);
    }else{
      qs.push(q);
    } 
  });
  
  return {hcMC, headErrs, qrows, qs, errqs, csvErrs, rowErrs, qmedias};
};

const asyncImportQs = async (dispatch, qs, MLnks, update) => {
  const impRet = [];
  
  await arrayForEachAsync(qs, async (q, idx) => {
    update(qs.length, idx+1);
    const imgQ = ckImgsSetQ(q, MLnks);
    const fields = packQtn({...imgQ, isNew:0, isImp:1});
    const [res, err] = 
      //debugMode()?[imgQ, 'err']:
      (await asyncApiCall_(dispatch, '/putATQtnDraft', { fields, doPub:0, doR2p:0 }));
    impRet.push({err, res, q});
  });
  
  return impRet;
};

export const fileToItem = (f) => ({ input: f, error: '', step: _queuePending, progress: 0, name: toStr(f && f.name), size: humanFileSize(f.size)});

const fakeM = 0;
const upMedias = async (dispatch, medias, setmedias, update) => {
  const mlnks = {};
  const ms = objEntries(medias);
  await arrayForEachAsync(ms, async ([mk, m], idx) => {
    update(ms.length, idx+1);
    const {input, step, propgress, name, size} = m;
    const file = input;
    const save = file && name && size && (step === _queuePending); 
    const testId = 'QImport_'+name;
    const fields = { name, size, testId };
    const upid = save  && await mediaUpFile(dispatch, fields, file, '');
    if(upid){
      const key = toStr(name).toLowerCase(); 
      mlnks[key] = {name, size, upid: toStr(upid), key};
      m.step = _queueUploaded;
      m.progress = 100;
      setmedias({...medias});
    }
  });
   return mlnks;
};

const checkMissMedia = (qmedias, medias) => {
  const uniFNSet = ary => new Set(ary.map(n => toStr(n).trim()).filter(n => n)); 
  const qMs = Array.from(uniFNSet(toAry(qmedias)));
  const upMset = uniFNSet(objVals(medias).map(m => toStr(m.name).trim().toLowerCase()));
  const upMs = Array.from(upMset);
  const missMs = fakeM? []: qMs.filter(qM => !upMset.has(qM));
  
  return [qMs, upMs, missMs];
};

const reCheckMissMedia = (qMs, MLs) => {
  const upSet = new Set(objKeys(MLs));
  const missUps = fakeM? []: qMs.filter(m => !upSet.has(m));
  return missUps.length? ('Missing Upload Media: '+missUps.join(', ')): '';
};

export const checkQIDs = async (dispatch, csvRes, noRep ) => {
  const {qs, errqs} = toObj(csvRes);
  const allQs = [ ...toAry(qs), ...toAry(errqs) ];
  const checkQIds = toUniIdAry(allQs.map(q => q?.QId));

  const [res, err] = await asyncApiCallLoad_(dispatch, '/getQImportOpts', {checkQIds});
  const exists = toUniIdAry(res?.checkQIds);
  
  const rowErrs = csvRes.rowErrs;
  if(noRep){
    const dupQs = allQs.filter(q => exists.includes(q.QId) );
    dupQs.forEach(q => {
      const k = 'ImpRow_'+ q.imprownum;
      const v = [ 'Question ID ('+q.QId+') already created', toStr(rowErrs[k]) ].filter(t => t).join(', '); 
      rowErrs[k] = v;
    });
  }else{
    const missQs = allQs.filter(q => !exists.includes(q.QId) );
    missQs.forEach(q => {
      const k = 'ImpRow_'+ q.imprownum;
      const v = [ 'Question ID ('+q.QId+') not exist', toStr(rowErrs[k]) ].filter(t => t).join(', '); 
      rowErrs[k] = v;
    });
  }
};
