import React, {createRef, useEffect, useMemo, useRef, useState} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { stopEvent } from "../../libs/libUI";
import { toObj, toInt, isFunc } from "../../libs/libType";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpIco from "./CpIco";

//import DatePickerPanel from "../../poc/custom-component/DatePickerPanel";
import CpDatePickerPanel from "./CpDatePickerPanel";
//import { getMonthInNumeric, getMonthName, getTimes, getTwoDigitDate, getTwoDigitMonth } from "../../poc/helper/date";
import { getMonthInNumeric, getMonthName, getTimes, getTwoDigitDate, getTwoDigitMonth } from "./CpDate";
import { formatDate, formatTimeStampHK, timeStampGMT2Gb, timeStampGMTToHk, timeStampNowGMT, timestampGMTfromHK } from "../../libs/libTime";
import { preJS } from "../../AppExPf/AppUtil";

//const validDateHour = t => new Date(t? (toInt(t)): (timeStampNowGMT()));
const validDateHour = t => t? toInt(t): timeStampNowGMT();

const CpDatePicker = (props => {
    const [t] = useUILang();
    const {
        disable = false,
        showEmpty = false,
        cb,
        iconPath = "",
        title = "",
        behind2min = 0,
        enableOption = true,  enableTime = true, placeholder = true, allowSameDay = true, fromDateTime = "", sticky = false,
    } = props;

    const datePickerPanelRef = createRef();

    const inTS = props.dateTime;
    const gmtTime = validDateHour(inTS) - 1 + (((behind2min && !inTS)? 60: 30) * 60 * 1000);
    const hkTime = timeStampGMTToHk(gmtTime);
    const YMDHIS = formatDate(hkTime, 'Y,M,D,H,m');
    const [year, M, day, hour, mi] = YMDHIS.split(',');
    const hm = (toInt(hour) * 2) + ((toInt(mi) >= 30)? 1: 0);

    const timeIn = useMemo(() => ({ year, month: getMonthName(toInt(M) - 1), date: day, time: enableTime? getTimes()[hm] : "" }), [inTS]); 
    const [formatedDateTime, setFormatedDateTime] = useState(timeIn);
    useEffect(() => setFormatedDateTime(timeIn), [inTS]);
    const pickTime = (t) => {
        
        setFormatedDateTime(t);
    }

    const formatedTime = formatedDateTime.time;
    const formatedDate = useMemo( () =>  getTwoDigitDate(formatedDateTime.year)+
        '/'+getTwoDigitMonth(getMonthInNumeric(formatedDateTime.month))+'/'+formatedDateTime.date, [formatedDateTime]);

    useEffect( () => { 
        const outTS = (new Date(formatedDate + ' ' + formatedTime + ' GMT+0800').getTime());
        
        if(outTS !== inTS) cb(outTS);
    } , [formatedDateTime]);

    const handleOptions = e => { stopEvent(e);
        if (enableOption && datePickerPanelRef.current) datePickerPanelRef.current.setShowPanel(prev => !prev);
    };
    
    useEffect(() => {
        if ((datePickerPanelRef.current) && (disable || (!enableOption))) datePickerPanelRef.current.setShowPanel(false);
    }, [disable, enableOption]);

    //{preJS({inTS, hm, gmtTime, YMDHIS, year, M, day, hour, mi},3)}
    return <div className={"date-picker position-relative"}>
        <div className={`date-picker-container d-flex gap-3 align-items-center justify-content-between border px-3 py-2 user-select-none ${disable ? "disable pe-none" : (enableOption ? "show-option" : "pe-none")}`}
             role={enableOption? "button": "none"} onClick={handleOptions} >
            <span className={"d-flex justify-content-center align-items-center gap-3"}>
                {iconPath? <div className={"icon fs-5"}><CpIco src={iconPath}/></div>: ''}
                <div className={"d-flex flex-column pe-0 pe-md-3"}>
                {title? <div className={"date-display-title fs-9 semi-bold"}>{title}</div>: ''}
                    <div className={"d-flex gap-0 gap-md-2 flex-column flex-md-row justify-content-start align-items-md-center"}>
                    {<div className={`date-display semi-bold fs-7 ${showEmpty? "opacity-0": ""}`}>
                        {formatedDate}</div>}
                    {placeholder && !formatedDate &&
                        <div className={"date-display-placeholder semi-bold fs-7"}>{t(enableTime? "datepicker-placeholder-with-time" : "datepicker-placeholder")}</div>}
                    {formatedTime && enableTime &&
                        <div className={`time-display semi-bold fs-7 ${showEmpty? "opacity-0": ""}`}>{formatedTime}</div>}
                </div>
            </div>
            </span>
            <span className={`fs-5 text-dim-400 ${(enableOption && !disable)? "": "opacity-0"}`}><CpIco src={IconList.general.arrowDown}/></span>
        </div>
        <CpDatePickerPanel {...{ref:datePickerPanelRef, formatedDateTime, setFormatedDateTime:pickTime, 
            fromDateTime, enableTime, allowSameDay, sticky}} />
    </div>
});

export default CpDatePicker;