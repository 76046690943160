import React, { useRef } from 'react';
import ATSwitch from '../components/Switch0';
import CpATPullDownMenu from '../components/CpATPullDownMenu';
import CpValueAdder from '../components/CpValueAdder';
import CpQtnTypeMenu from '../components/CpQtnTypeMenu';
import { toInt } from '../../libs/libType';
import { aTErrTxt1} from '../AppUtil';
import * as SQT from '../../consts/ATSysQType';
import * as MCAC from '../../consts/ATQtnAnsTypeMCQ';
import { svgIcon2 } from '../components/CpATIcoBtn';
import { useUILang } from '../utils/useUILang';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';
//import IconGroup from '../../poc/screens/schema/components/custom/form/icon/IconGroup';
//import IconComponent from '../../poc/screens/schema/components/custom/icon/IconComponent';
//import DropdownComponent from '../../poc/screens/schema/components/custom/form/dropdown/DropdownComponent';
//import { Dropdown } from 'react-bootstrap';
//import { SIZE } from '../../AppExPFUser/_components/CpExPFConst';

export const toIconList = {
    'presentation/gridMode':IconList.presentation.gridMode,
    'presentation/listMode':IconList.presentation.listMode,
    'presentation/labelMode':IconList.presentation.labelMode,
};

export const MTSetting_MCQ = (props) => {
    const { Q, fullQ, editable, clearQAnswers, setShowEditor, showPub, fieldErrs, pub, dispatch, isAT=1 } = props
    const [ t, uiEn, UILang, setUILang, ut, t2, t3 ] = useUILang();

    const prevAnsChoice = useRef(fullQ.ansChoice);

    const SQType=fullQ.SQType||SQT.__SYSQSubType_MCS;
    const ansChoice = fullQ.ansChoice||Q.ansChoice||(SQType===SQT.__SYSQSubType_MCS?MCAC.__MCAC_ACG:MCAC.__MCTT_TTC);
    const multiAnswer = toInt(fullQ.multiAnswer);
    const shuffleAnswer = toInt(fullQ.shuffleAnswer);
    const score = toInt(fullQ.score);
    const allowTeaCopyEdit = toInt(fullQ.allowTeaCopyEdit);
    
    const _setFullQ = (k) => (v) => props.setFullQ(k,v);

    const _setMCTansChoice = (ac) => {
        if (prevAnsChoice.current !== ac) {
            clearQAnswers(SQType, false); // clear qAnswers, tAnswer only
        };
        prevAnsChoice.current = ac;
        _setFullQ('ansChoice')(ac);
    };

    //{svgIcon2(MCAC.__MCACcodeIcon(id), sel ? "black" : "black", { width: "16px" })}
    /*
        <div className='flexRowStart'>
            <CpIco src={toIconList[MCAC.__MCACcodeIcon(id)]} width={'1.25em'} height={'1.25em'}/>
            <div style={{ marginLeft: "5px" }}>{t(MCAC.__UIMCACcodeName(id))}</div>    
        </div>
    */
    const acOpts = MCAC.__MCACcodes.map((id, ii)=>{
        
        return { id: id, 
            jsx: (sel = false) => <div className='flexRowStart gap-1 '>
                <CpIco src={toIconList[MCAC.__MCACcodeIcon(id)]} width={'1.2em'} height={'1.2em'}/>
                <div style={{ marginLeft: "5px" }}>{t(MCAC.__UIMCACcodeName(id))}</div>    
            </div>
        };
    });

    const ttOpts = MCAC.__MCTTcodes.map((id,ii) => {
        return {
            id: id,
            jsx: (sel = false) => <div className='flexRowStart'>           
            <div style={{ marginLeft: "5px" }}>{t(MCAC.__UIMCTTcodeName(id))}</div>
            </div>
        };
    });

    const radioTAnswer = ansChoice===MCAC.__MCTT_TTR;
    const disableShuffle = (ansChoice ===MCAC.__MCAC_ACB || SQType === SQT.__SYSQSubType_MCT);

    return <div key='mcqSet' className={'VMenuTab ' + (editable?'':' unclickable')}>
        <div className="vstack gap-3 minBreakpoint-xs"> 
            <CpQtnTypeMenu {...{pub, dispatch}} sel={SQType} setSel={_setFullQ('SQType')} clearQAnswers={clearQAnswers} setShowEditor={setShowEditor}/>
            <div className={'flexRowBetween ' + (radioTAnswer?' unclickable':'')}>
                <div style={{color:radioTAnswer?'grey':'black'}}>{t('multiple-answers')}</div>
                <div><ATSwitch id='SWmulitAns' leftText='' rightText='' disabled={radioTAnswer}
                    selected={multiAnswer} leftValue={0} rightValue={1}
                    setter={_setFullQ('multiAnswer')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
            </div>
            <div className={'flexRowBetween' + (disableShuffle?' unclickable':'')}>
                <div style={{color:disableShuffle?'grey':'black'}}>{t('shuffle-answer-choices')}</div>
                <div><ATSwitch id='SWshuffleAnswer' leftText='' rightText='' disabled={disableShuffle}
                    selected={shuffleAnswer} leftValue={0} rightValue={1}
                    setter={_setFullQ('shuffleAnswer')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
            </div>
            <div className='flexRowBetween'>
                <div>{t('at-setting.fib.totalMark')}</div>
                <CpValueAdder value={score} setValue={_setFullQ('score')} min={1}/>
            </div>
            {showPub?'':aTErrTxt1(fieldErrs?.score)}
            <div className='flexRowBetween'>
                <div>{t('answer-choices-as')}</div>
                {(SQType === SQT.__SYSQSubType_MCS) &&      
                <CpATPullDownMenu sel={ansChoice} setSel={_setFullQ('ansChoice')} opts={acOpts}
                    containerStyle={{ width: "100px", top:'35px' }} right={true}
                    menuStyle={{ justifyContent: "flex-end" }} headerStyle={{ justifyContent: "center" }} />}
                {(SQType === SQT.__SYSQSubType_MCT) && 
                <CpATPullDownMenu sel={ansChoice} setSel={_setMCTansChoice} opts={ttOpts}
                    containerStyle={{ width: "110px", top:'35px' }} right={true}
                    menuStyle={{ justifyContent: "flex-end" }} headerStyle={{ justifyContent: "center" }} />}
            </div>
            {isAT?<div className='flexRowBetween'>
                <div>{t3('qtn-opt-allow-teacher-to-copy-edit')}</div>
                <div><ATSwitch id='SWTeaAllowCopy' leftText='' rightText='' disabled={false}
                    selected={allowTeaCopyEdit} leftValue={0} rightValue={1}
                    setter={_setFullQ('allowTeaCopyEdit')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
            </div>:''}
        </div>
    </div>
};

export default MTSetting_MCQ;

