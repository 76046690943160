import React, { useMemo, useState, useEffect } from 'react';
import * as UI from '../../libs/libUI';
import { ATErrsDiv, ATUILabel, ATUIText } from '../AppUtil';

import { _ATSYSQTypeFullName } from '../../consts/ATSysQType';
import { toUniIdAry, toUniIdsStr } from '../../consts/ATValidate';
import { ExQsErrs } from '../../consts/ATValidateEcnts';
import { toStr } from '../../libs/libType';
import { cpATIcoBtn_ } from '../components/CpATIcoBtn';
import CpATQtnSingle from '../qtnDo/CpATQtnSingle';
import { useUILang } from '../utils/useUILang';
import { ReduxBind } from '../../saga/ReduxState';

const ViewExCtnQ = ReduxBind(props => {
    const {ex, opts, lock, showEn, idx, ECtn, replaceECtn, qtns, useCacheQtns, tagMSets, useTagMSets, MSets } = props;
    const [ t ] = useUILang();
    const [edit, setEdit] = useState(0);
    const [QEd, setQEd] = useState('');

    const qidstr = toStr(ECtn.QIds);
    const qids = toUniIdAry(qidstr.split(','));

    const _l = props._saga?.loading?.count;
    const [l, setL] = useState(true);
    useEffect(() => { setL(_l) }, [_l]); 
    const loading = l || _l; // hold 1 cycle to hide loading effect

    useCacheQtns(qids); 
    const errs = useMemo(() => {
        return (!loading) && ex && ExQsErrs(ex, qids, {...opts, qtns})
    }, [loading, qidstr, qtns, ] );

    const clickEdit = (!lock) && (e => { UI.stopEvent(e); 
        setQEd(toUniIdsStr(ECtn.QIds));
        setEdit(1); 
    });
    const clickEnd = save => (!lock) && (e => { UI.stopEvent(e); 
        if(save) replaceECtn(c => ({...c, QIds:toUniIdsStr(QEd)}));
        setEdit(0); 
    });

    const exStyle = lock?{paddingTop:0}:{};
    return <div key={'qu'+idx} className='ViewExCtnItemQtn' style={exStyle}>
        <div className='ViewExCtnItemLockContent'>
            <div className='flexRowStart f14'>
            {lock?'':<>{ATUILabel(t('question')+' ID(s)','qids',true,100)}{ATUIText(edit?QEd:qidstr, setQEd, 'tq', (lock || !edit))}</>}
            {lock?'':(
                edit?<>{cpATIcoBtn_('general/confirm', '', clickEnd(1))}{cpATIcoBtn_('general/discard', '', clickEnd(0))}</>
                    :cpATIcoBtn_('general/edit', '', clickEdit, {transform:'scale(0.8)'})
            )}</div>
            {loading? '': <div className="tal">{ATErrsDiv(errs)}</div>}
            {qids.map((qid, ii)=>{
                const fullQ = qtns[qid];
                const Q = showEn?fullQ?.QEn:fullQ?.QCt;
                
                const key = qid+'_'+ii;
                return (fullQ && Q)?
                    <CpEQView key={key} {...{...props, qid, ii, fullQ, Q, tagMSets, useTagMSets, MSets, showEn}}/>:
                    <div key={key} className='p8 mb8 box1 br8'>{loading?'':'No '+toStr(qid)+' Question Data'}</div>;
            })}
        </div>
    </div>
});
export default ViewExCtnQ; 

const noop=()=>{};
const CpEQView = props => {
    const { showEn, fullQ, Q } = props; 
    return <CpATQtnSingle Q={Q} fullQ={fullQ} doWhat={'listView'} idx={props?.idx} showEn={showEn}
        {...props} close={noop} back={noop} PVMode={2} showResult={false}
        displayIdx={props?.displayIdx}/>
};

