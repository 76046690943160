import React from "react";
import { formatDate, timeStampGMTToHk } from "../../libs/libTime";

import { stopEvent } from "../../libs/libUI";


export const EPUITime = (timeStampGMT, format='DD/MM/YYYY HH:mm:ss') => timeStampGMT? formatDate(timeStampGMTToHk(timeStampGMT), format): '--';
export const EPUITime2 = (timeStampGMT, format='DD/MM/YYYY HH:mm') => timeStampGMT? formatDate(timeStampGMTToHk(timeStampGMT), format): '--';
export const EPUITime3 = (timeStampGMT, format='DD/MM/YYYY hh:mm TT') => timeStampGMT? formatDate(timeStampGMTToHk(timeStampGMT), format): '--';

export const CpEpTabHead = props => {
    const {tab, setTab, tabs} = props;
    const clickTab = i => e => { stopEvent(e); setTab(i); };
    return <div className={"exercise-edit-tab d-flex gap-3"}>{tabs.map((tabName, idx) => 
        <div key={idx} role={"button"} className={`tab-item ${tab === idx? "active": ""}`} onClick={clickTab(idx)}>{tabName}</div>)
    }</div>;
};
