import {CloseButton, Modal, Offcanvas} from "react-bootstrap";

const CpShareOffcanvas = (props) => { // base on poc ShareOffcanvas.tsx
    const {
        title = "",
        handleClose,
        disableHeader = false,
        disableFooter = false,
        disableClose = false,
        children,
        header,
        footer,
        footerXPadding = 3,
        footerYPadding = 3,
        ...offcanvasProps
    } = props;
    return <Offcanvas {...offcanvasProps} enforceFocus={false}>
        {disableHeader === false && <Offcanvas.Header className={"p-0"}>
            <Offcanvas.Title as={"h5"} className={"w-100"}>{title}</Offcanvas.Title>
            <div className={"d-flex justify-content-center align-items-center gap-3"}>
                {disableClose === false && <CloseButton className={"text-dim-400"} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (handleClose) handleClose();
                }}/>}
            </div>
        </Offcanvas.Header>}
        <Offcanvas.Body className={"p-0 scroll-container"}>{children}</Offcanvas.Body>
        {disableFooter === false &&
            <Modal.Footer className={`px-${footerXPadding} py-${footerYPadding} bg-dim-100 border-0`}>
                {footer}
            </Modal.Footer>}
    </Offcanvas>;
};
export default CpShareOffcanvas;