import {MAX_RADIAL_RING} from "../type/ChartType";

export const groupByNumberArray = (data: any[], groupNumber: number) => {
    return data.reduce((acc: any[][], val: any, index: number) => {
        const groupIndex = Math.floor(index / groupNumber);
        acc[groupIndex] = acc[groupIndex] || [];
        acc[groupIndex].push(val);
        return acc;
    }, []);
}

export const distributeData = (total: number, splitLength: number) => {
    if (total <= splitLength) return [total];

    let maxLength = Math.ceil(total / splitLength);
    const result = [];
    let value = Math.ceil(total / maxLength);
    let remaining = total;

    while (remaining > 0) {
        const chunk = Math.min(value, remaining, splitLength);
        result.push(chunk);
        remaining -= chunk;

        if (remaining <= maxLength - result.length && remaining > 0) {
            value = remaining;
            result.push(value);
            remaining = 0;
        } else {
            value = Math.ceil(remaining / (maxLength - result.length));
        }
    }

    return result;
}

export const splitArrayBySizes = (arr: any[], sizes: number[]): any[][] => {
    const result: any[][] = [];
    let start = 0;

    for (const size of sizes) {
        result.push(arr.slice(start, start + size));
        start += size;
    }

    if (start < arr.length) {
        result.push(arr.slice(start));
    }

    return result;
}

export function groupData<T>(data: T[], splitLength = MAX_RADIAL_RING): T[][] {
    let result: T[][] = [];

    if (data.length) {
        const split = distributeData(data.length, splitLength);
        result = splitArrayBySizes(data, split);
    }

    return result;
}

