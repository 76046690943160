import React, { useEffect, useState, useRef } from 'react';

import { objEntries, objKeys, toAry, toInt, toObj, toStr, objVals } from '../../libs/libType';
import TabMediaUp, { getFileList } from './TabMediaUp';
import { ReduxBind } from '../../saga/ReduxState';
import { stopEvent } from '../../libs/libUI';
import { useMediaCache } from '../utils/useMediaCache';
import { maxMediaSizeByExt, maxMediaSizeByType, mediaTypeByExt, validToUpload } from '../../consts/MimeTypes';
import { fileExt, humanFileSize } from '../../libs/libFormat';
import { popAlert } from '../components/CpPopup';
import { useUILang } from '../utils/useUILang';

export const LayerLocalMedia = ReduxBind(props => {
    const {clickClose, onAdd, maxMedia, mimes, mexts, dispatch, isAT=1} = props; 
    const [ t ] = useUILang();
    const {_MaxMediaSize} = toObj(props.settings());
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);

    const refFile = useRef();
    const curFile = refFile.current;
    useEffect(()=>{
        
        if(onAdd && curFile) curFile.click();
    }, [onAdd, curFile])
    
    const errType = () => {
        if (mimes) {
            return mimes.includes('doc') ? 'doc'
            :(mimes.includes('audio') && mimes.includes('video')) ? 'audio_video'
            :mimes.includes('audio') ? 'audio'
            :mimes.includes('video') ? 'video'
            :mimes.includes('image') ? 'image'
            :mimes.includes('drawing') ? 'drawing'
            :'all';
        } else return 'all';
    };

    const onFile = async e => { stopEvent(e);
        const picks = toAry(getFileList(e));
        const fErrs = f => {
            const name = f?.name;
            const ext = toStr(fileExt(name)).toLowerCase(); 
            const type = mediaTypeByExt(ext); 
            const size = f?.size;
            
            if(mexts && !mexts.includes(ext)) return 'Invalid File type: '+name;
            if(mimes && !mimes.includes(type)) return 'Invalid File type: '+name;
            const maxSize = maxMediaSizeByType(_MaxMediaSize, 0, type);
            if(!maxSize) return name+' invalid file type';
            if(size > maxSize) return name+' exceed size limit: '+humanFileSize(maxSize);
        
            return '';
        };

        const chks = picks.map(f => [fErrs(f), f]); 

        const rejects = chks.map(([e, f]) => e && f && f.name).filter(f => f);
        const errmsg = rejects.length && 'Invalid File(s): '+rejects.join(', ');
        //if(errmsg) popAlert(dispatch, 0, errmsg);
        if(errmsg) popAlert(dispatch, isAT?0:1, t(_errMsg[errType()]));

        const allAdds = chks.map(([e, f])=> (!e) && f).filter(f => f);//.filter(([err, f]) => !err).map();  
        const adds = allAdds.slice(0, maxMedia || 1);
        
        if(adds.length){
            const newMs = addLocalMedias(adds, 0);
            if (onAdd) onAdd(objVals(newMs));
        }

        if (refFile.current) refFile.current.value = "";
    };
    const para = { multiple: (maxMedia>1) };

    ///todo limit per mime, ext //input type="file" accept=".xls, text/plain,..." accept is mix CSV of mime + ext   
    return <input type="file" {...para} id="f1" ref={refFile} onChange={onFile} style={{display:'none'}} />
});

const _errMsg = {
    'audio_video':'warning.warning_video_audio_files',
    'audio':'warning.warning_audio_file',
    'video':'warning.warning_video_file',
    'image':'warning.warning_image_file',
    'doc':'warning.warning_doc_file',
    'all':'warning.warning_all_files',
};

