import {HTMLAttributes} from "react";
import IconComponent from "../../schema/components/custom/icon/IconComponent";

interface Props extends HTMLAttributes<HTMLElement> {
    title?: any
    tooltipTitle?: string
    iconPath?: string
    iconSize?: string
    hiddenText?: boolean
    allowWrap?: boolean
}

const ActionButton = (props: Props) => {
    const {
        title = "",
        tooltipTitle = title,
        iconPath = "",
        iconSize = "fs-6",
        hiddenText = false,
        className,
        allowWrap = false,
        ...actionButtonProps
    } = props
    const style = `action-btn d-flex justify-content-center align-items-center ${className}`

    // const renderTooltip = (props: any) => (
    //     <Tooltip className={`d-block d-lg-none`} {...props}>
    //         {tooltipTitle}
    //     </Tooltip>
    // );

    return <span role={"button"} className={style} {...actionButtonProps}>
        {iconPath !== "" && <span className={iconSize}><IconComponent src={iconPath}/></span>}
        {title !== "" &&
            <span
                className={`title fs-8 ${allowWrap ? "" : "text-nowrap"} ${hiddenText ? "d-none d-md-block" : ""}`}>{title}</span>}
    </span>

    // return <OverlayTrigger
    //     placement="bottom"
    //     delay={{show: 250, hide: 400}}
    //     overlay={renderTooltip}
    // >
    //     <span role={"button"} className={style} {...actionButtonProps}>
    //     {iconPath !== "" && <IconComponent src={iconPath}/>}
    //     {title !== "" && <span className={"title"}>{title}</span>}
    // </span>
    //
    // </OverlayTrigger>
}

export default ActionButton