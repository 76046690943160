import React, {useState} from "react";
import {Button} from "react-bootstrap";
import * as UI from '../../libs/libUI';

import { useUILang } from "../../AppExPf/utils/useUILang";
import { stopEvent } from "../../libs/libUI";
import CpIco from "../_components/CpIco";

import { IconList } from "../../consts/ATIconListToUsePoc";
import CpSharedModal from "../_components/CpSharedModal";
import SharedModalHeader from "../_components/CpSharedModalHeader";
import { CpSettingModalSection, CpSettingModalTabContainer, CpSettingModalTitle } from "../_components/CpSettingModalTabCps";
import CpColorSelect from "../_components/CpColorSelect";

const CpCollectionNewThumb = (props => { // base on poc ExerciseCollectionNewThumbnail
    const { setParaNew } = props;
    const [t] = useUILang();

    const [open, setOpen] = useState(false);
    const clickOpen = open => e => { stopEvent(e); setOpen(open); };
    const clickClose = clickOpen(0);

    const handleCollection = e => { UI.stopEvent(e); setParaNew({EColtype:"new"}); }; //setModalTitle(t("collection"));
    
    return <>
        {/* <span role={"button"} className={"margin-top-03 card-collection-btn"} onClick={clickOpen(1)}><CpIco src={IconList.general.add}/></span>  "Old method"*/}
        <span className={"library-list-thumbnail exercise-list-new-thumbnail position-relative d-flex justify-content-center align-items-center flex-column"}
            role={"button"} onClick={handleCollection} >
            <div className={"text-dim-400 fs-2"}><CpIco src={IconList.general.add}/></div>
            <div className={"text-dim-400 fs-5"}>{t("add-new-collection")}</div>
        </span>
        <CpSharedModal show={open} scrollable={true}
            header={<SharedModalHeader title={t("new-collection")} iconPath={IconList.general.collection} handleClose={clickClose}/>} 
            footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
                <Button variant="gray-body-color w-100 border" onClick={clickClose}>{t("cancel")}</Button>
                <Button variant={`exercise-third-btn btn-block w-100`} onClick={clickClose}> {t("create")}</Button>
            </div>}>
            <CpSettingModalTabContainer gap={3}>
                <CpSettingModalSection>
                    <CpSettingModalTitle>{t("collection-title")}:</CpSettingModalTitle>
                    <input placeholder={t("please-enter-new-collection-name")} className={"w-100 border"}/>
                </CpSettingModalSection>
                <CpSettingModalSection hideBorder={true}>
                    <CpSettingModalTitle>{t("collection-colour")}:</CpSettingModalTitle>
                    <div className={"d-flex flex-wrap gap-2 w-100"}><CpColorSelect /></div>
                </CpSettingModalSection>
            </CpSettingModalTabContainer>
        </CpSharedModal>
    </>
});
export default CpCollectionNewThumb;
