import React, { useState, useEffect } from 'react';

import * as UI from '../libs/libUI';
import { apiCallLoad } from '../libs/awsFuncs';

import { ReduxBind } from '../saga/ReduxState';
import { urlPush_Replace } from '../saga/urlPush.saga';
import { lj } from '../libs/libFormat';
import { aTErrDiv1, aTErrTxt1, aTUIMust, BtnPopDev, clickUrl, preJS } from './AppUtil';
import { toStr, toObj, toAry } from '../libs/libType';
import { normalizeEditATUser, validEditATUser } from '../consts/ATValidateATUser';
import { _ATRoot, _ATRoleNames } from '../consts/ATConstReact';
import { _ATAllRoles, _ATRoleApprover, _ATRoleRBasic } from '../consts/ATConsts';
import CpATIcoBtn, { cpATIcoBtn_ } from './components/CpATIcoBtn';
import CpATSubjectTickList from './components/CpATSubjectTickList';
import { arrayAddOrDelete, arrayAddOrDelete_ } from '../libs/libArray';
import { hasErr } from './utils/useEditDraftPub';
import { ATDo_User, _ATCanDo } from '../consts/ATRoleRights';

const backPath = _ATRoot + 'accounts';

const PageATAccountEdit = ReduxBind((props) => {
	const dispatch = props.dispatch;
	const ATUserId = (props.match?.params?.ATUserId) || '';
	const isNew = ATUserId? 0: 1;
	
	//const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
	//const letUser = _ATCanDo(role, ATDo_User);

	const [ATSubjects, setATSubjects] = useState([]);
	const [mySjIds, setMySjIds] = useState([]);
	const [fields, setFields] = useState({ ATUserId, editPass: isNew, pass: '', pass2: '', role: '_ATRoleRBasic', USubjIds:[]});
	const [fieldErrs, setFieldErrs] = useState({});

	const setTickSj = sid => on => setFields(fs => ( {...fs, USubjIds: arrayAddOrDelete_(fs.USubjIds, sid, on) } ) );

	useEffect(() => { apiCallLoad(dispatch, '/getATUser', onLoad, { ATUserId }); }, []);

	const onLoad = (res, err) => {
		if (res) {
			setATSubjects(toAry(res.ATSubjects));
			setMySjIds(toAry(res.mySjIds));
			setFields({ ...(fields || {}), ...(toObj(res.ATUser)) });
			setFieldErrs(toObj(res.fieldErrs));
		}
	};

	const setATLogin = (v) => { setFields(f => ({ ...(f || {}), ATLogin: v.slice(0, 100) })) };
	const setEditPass = (v) => { 
		setFields(f => ({ ...(f || {}), editPass: (isNew || v)?1:0, pass:'', pass2:'' }))
	};
	const setPass = (v) => { setFields(f => ({ ...(f || {}), pass: v.slice(0, 100) })) };
	const setPass2 = (v) => { setFields(f => ({ ...(f || {}), pass2: v.slice(0, 100) })) };
	const setRole = (v) => { setFields(f => ({ ...(f || {}), ATRole: v.slice(0, 20) })) };
	
	const clickCancel = clickUrl(dispatch, backPath);

	const onPutATUser = (res, err) => {
		//lj(res);
		//if (err) window.alert(JSON.stringify(err));
		const fes = res?.fieldErrs;
		if(fes)
			setFieldErrs(fes);
		if((res?.state) !== 'retry')		
			dispatch(urlPush_Replace(backPath));
	};

	const clickSaveATUsr = (e) => {
		UI.stopEvent(e);
		const fs = normalizeEditATUser(fields);
		const fieldErrs = validEditATUser(fs);
		setFields(fs);
		setFieldErrs(fieldErrs);
		if (!hasErr(fieldErrs)) {
			apiCallLoad(dispatch, '/putATUser', onPutATUser, { ...fs, isNew, ATUserId });
		}
	};

	return <div className="PageATAccountEdit adminAccountTop">
		<div className="f16">Admin / Accounts / {isNew ? 'New' : 'Edit'}</div>
		<div className="adminEditToolsContainer"><div style={{display:"flex"}}>
			<div className="adminTools1">
				<CpATIcoBtn onClick={clickSaveATUsr} src="general/save" txt='Save' />
				<CpATIcoBtn onClick={clickCancel} src="general/cancel" txt='Cancel' />
			</div>
		</div></div>

		<div className="inputInfoContainer">
			<table className="inputInfoTable f14"><tbody>
				<tr>
					<td className="accountInputLabel">Login ID{aTUIMust}</td>
					<td><div>{isNew ?
						<>
						{UI.EpInputTxt0(fields.ATLogin, setATLogin, 'txtUser', '', { width: "220px" }, 'Please enter Login ID here')} 
						{aTErrDiv1(fieldErrs?.ATLogin)}
						</>:
						<b>{fields.ATLogin}</b>
						}</div>
					</td>
					<td>{(fields?.editPass) ?
						<div>
							<div>Password{aTUIMust}{UI.Pass0(fields.pass, setPass, 'txtPass', '', {}, 'input password')}</div>
							{aTErrDiv1(fieldErrs?.pass)}
						</div> :
						<div>Password (Not Show)
							{UI.Button0('Reset Password', e => setEditPass(1), 'btnEP', '', {})}
						</div>
					}</td>
					<td>{(fields?.editPass) ?
						<div>
							<div>Confirm Password{aTUIMust}{UI.Pass0(fields.pass2, setPass2, 'txtPass2', '', {}, 'input password again')}</div>
							{(isNew) ? '' : UI.Button0('No Reset', e => setEditPass(0), 'btnCEP', '', {})}
							{aTErrDiv1(fieldErrs?.pass2)}
						</div> : <></>
					}</td>
				</tr>
				<tr>
					<td className="accountInputLabel">Roles{aTUIMust}</td>
					<td>
						<label>{UI.radio0(_ATRoleRBasic, setRole, fields.ATRole, 'rgRoleBasic', false, '', {})}
							{_ATRoleNames[_ATRoleRBasic]}</label>
						<label>{UI.radio0(_ATRoleApprover, setRole, fields.ATRole, 'rgRoleApprover', false, '', {})}
							{_ATRoleNames[_ATRoleApprover]}</label>
					</td>
				</tr>
				<tr>
					<td></td><td>{aTErrTxt1(fieldErrs?.ATRole)}</td>
				</tr>
			</tbody></table>
		</div>
		<div>
			<div className="f14">Access subject(s){aTUIMust}{aTErrTxt1(fieldErrs?.USubjIds)}</div>
			<div className="subjectItemContainer">{CpATSubjectTickList(ATSubjects, mySjIds, fields.USubjIds, setTickSj)}</div>
		</div>
	</div>;
});

export default PageATAccountEdit;
