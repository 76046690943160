import React, { useEffect, useState, useRef } from 'react';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import {  toAry, toObj, toStr, toInt, objVals } from '../../libs/libType';
import { asyncDL, asyncGetDraw,  } from '../utils/useMediaCache';
//import DrawComponent from './CpQDoDrawTools';
//import DrawComponentV2 from './CpQDoDrawToolsV2';
import DrawComponentV5 from './CpQDoDrawToolsV5';
import { __MediaExts, __MediaTypes, validToUpload, } from '../../consts/MimeTypes';
import { useUILang } from '../utils/useUILang';
import { popAlert } from '../components/CpPopup';
import { byteToMB, validDrawSize } from '../utils/utilQtnAns';
import { isLocalMedia } from '../utils/useMediaCache';
import { __RESP_DRAW, showQtnDrawPreview, showQtnDrawEx } from '../../consts/ATSysQType';
import { useResize } from './CpATQtnSingle';
import { debugTrimDraw } from '../../consts/AValidateWork';

const CpQDoDraw = (props) => {
    const { fullQ, Q, mediaDLs, getMediaDLs, addLocalMedias, dispatch} = props;
    const { containerClass, editable, preview, asmView, asmEdit, doEdit, isTeacher, isStudent, PVMode,
        showResult, mini=0, needDrawDeb=0, setStudentResp, setTeacherResp, studentResp, teacherResp, idx, resizeCB } = props;
    const [ t ] = useUILang();

    const qData = Q?.qData || "";
    const qDrawS3Id = Q?.qDrawS3Id || 0;

    const edProps = {mediaDLs, getMediaDLs };
    const doFlag = preview || asmView || asmEdit;

    const [afterInit, setAfterInit] = useState(0);
    const [drawReady, setDrawReady] = useState(0);
    const myID = 'ansDo'+(doEdit?'edit':'read')+fullQ.QId+(idx||0);

    useEffect(()=>{
        setAfterInit(1);
    },[]);  

    const _resizeCB = (data) => {
        //console.log('cb op cksize', data );
        resizeCB && resizeCB(data);
    };
    
    const ckContentRef = useRef(null);
    // don't remove useResize
    const ckSize = useResize(ckContentRef.current, _resizeCB); 

    const setStResponse = (v) => {
        if (afterInit && drawReady && isStudent && setStudentResp) {
            //console.log('update st response');
            const drawS3Id = { respType:__RESP_DRAW, qrespS3Id:"" };
            setStudentResp({...studentResp, qresp:v, ...drawS3Id});
        };
    };

    const setTeResponse = (v) => {
        if (afterInit && drawReady && isTeacher && setTeacherResp) {
            //console.log('update te response');
            const drawS3Id = { respType:__RESP_DRAW, qrespS3Id:"" };
            setTeacherResp({...teacherResp, qresp:v, ...drawS3Id});
        };
    };
    
    //const miniStyle = mini?{gridTemplateColumns: '100%'}:{};
    const contentDisplayOnly = showResult || asmView || isTeacher;
    return <div className={containerClass}>
        <div className='tabDoQContent2' id="mathDisplay">
            <div id={myID} ref={ckContentRef} className='tabDoQContent3' style={{marginBottom:'30px'}}>
                <QEditorReadOnly {...props} data={qData} {...edProps}/>
            </div>
            <div className='qtnOPDoContainer2'>
                <div className='flexRowStart f18' style={{margin:'0 0 10px 5px'}}>
                    <div>{contentDisplayOnly?t('openended.preview.student_response'):''}</div>
                </div>
                {/*{(showQtnDrawPreview || (showQtnDrawEx && isStudent))? */}
                {(showQtnDrawPreview || showQtnDrawEx)?
                    <DrawContent {...props} setDrawReady={setDrawReady} setResponse={isTeacher?setTeResponse:setStResponse}/>
                    :<span>under developing</span>}
            </div>
        </div>
    </div>;
};

export default CpQDoDraw;

const DrawContent = (props => {
    const {showResult, isAT, isTeacher, isStudent, doEdit, studentResp, teacherResp, setResponse, idx, showEn,
        setOnAddMedia, mediaDLs, addLocalMedias, getMediaDLs, dispatch, preview, PVMode, setDrawReady, needDrawDeb=0 } = props;

    const Q = toObj(props.Q);
    
    const [t] = useUILang(); 
    const [doDrawReset, setDoDrawReset] = useState(0);
    const [drawCanvas, setDrawCanvas] = useState(0);
    const [drawResponse, setDrawResponse] = useState(null);

    const isTeacherDraw = (isTeacher || (isStudent && showResult));

    const [drawData, setDrawData] = useState(isTeacherDraw?(teacherResp?.qresp):(studentResp?.qresp));
    const [bgData, setBgData] = useState(isTeacherDraw?(studentResp?.qresp):null);

    const qDrawData = toStr(Q?.qDrawData);
    const [qtnData, setQtnData] = useState(preview?qDrawData:null);
    
    const [myBG, setMyBG] = useState(null);
    
    const LDMode = Q?.LDMode || 'ftw';
    //const hasBG = myBG?1:0;

    // there must has question draw
    // has or not question BG
    // Q Draw | student draw | teacher mark
    // load Q BG -> load Q draw -> BG -> Draw
    const waitQBG = Q?.BGMediaID || 0;
    const waitQDraw = Q?.qDrawS3Id || 0;
    const waitBG = isTeacherDraw?(studentResp?.qrespS3Id):0;
    const waitDraw = isTeacherDraw?(teacherResp?.qrespS3Id):(studentResp?.qrespS3Id);
    
    const drawInit = useRef(waitDraw);
    //const noRespYet = isTeacherDraw?(!isTeacherDraw.hasOwnProperty('qresp')):(!studentResp.hasOwnProperty('qresp'));

    const myMediaID = toStr(Q?.BGMediaID);
    const qDrawS3Id = toStr(Q?.qDrawS3Id);
    const drawDataID = toStr(isTeacherDraw?(teacherResp?.qrespS3Id):(studentResp?.qrespS3Id));
    const bgDataID = toStr(isTeacherDraw?(studentResp?.qrespS3Id):null);
    
    const myBGReady = myMediaID ? (myBG?1:0):1;
    const drawDataReady = (myBGReady && !qDrawS3Id) || (myBGReady && (qDrawS3Id?(qtnData?(isTeacherDraw?
        ((waitDraw?(drawData?1:0):1)&&(waitBG?(bgData?1:0):1))
        :(waitDraw?(drawData?1:0):1))
        :0):(preview?1:0)));
    //console.log('DoDraw1: drawDataReady, qDrawS3Id, qtnData, isTeacherDraw, waitDraw, te S3Id, st S3Id',
    //drawDataReady, qDrawS3Id, qtnData, isTeacherDraw, waitDraw, teacherResp?.qrespS3Id, studentResp?.qrespS3Id);
    //console.log('DoDraw2 isAT isTeacher, isStudent, preview, qDrawData:', isAT, isTeacher, isStudent, preview, props.Q?.qDrawData);

    const getLocalURL = async (url) => {
        const bb = await fetch(url).then(r => r.blob());
        return bb;
    };

    const updateQtnData = async () => {
        if (!qDrawS3Id || qtnData) return;
        const qdata = await asyncGetDraw(dispatch, getMediaDLs, qDrawS3Id);
        //console.log("updateQtnData: qdata", qdata);
        setQtnData(qdata);
    };

    const updateQtnDataLocal = async (url) => {
        //console.log('updateQtnDataLocal url:', url);
        const bb = await getLocalURL(url);
        const fr = new FileReader();
        fr.onload = () => {
            //console.log("updateQtnDataLocal: fr.result", fr.result);
            setQtnData(fr.result);
            //console.log("tt:", tt);
        };        
        fr.onerror = () => {
            //console.log("fr error: ", fr.error);
        };        
        //fr.readAsArrayBuffer(bb); 
        fr.readAsBinaryString(bb);
        //const tt = fr.readAsText(bb);
    };

    const updateDrawData = async () => {
        if(!drawDataID || isLocalMedia(drawDataID) || drawData) return;
        const ddata = await asyncGetDraw(dispatch, getMediaDLs, drawDataID);
        drawInit.current = true;
        if (isTeacherDraw) {
            teacherResp.qresp = ddata;
        } else { studentResp.qresp = ddata;}
        setDrawData(ddata);     
    };

    const updateBgData = async () => {
        if (!bgDataID || bgData) return;
        const bdata = await asyncGetDraw(dispatch, getMediaDLs, bgDataID);
        studentResp.qresp = bdata;
        setBgData(bdata);         
    };

    useEffect(()=>{
        if (myMediaID !== '' && getMediaDLs) getMediaDLs([myMediaID]);
        //if (qDrawS3Id !== '' && !isLocalMedia(qDrawS3Id) && getMediaDLs) getMediaDLs([qDrawS3Id]);
    },[]);

    /*
    useEffect(()=>{
        console.log('qtnData ',qtnData);
    },[qtnData]);
    */

    useEffect(()=>{
        if (drawDataReady) {
            //console.log('draw Ready');
            setDrawReady && setDrawReady(1);
        };
    },[drawDataReady]);

    useEffect(()=>{ 
        //console.log('check draw local');
        if (mediaDLs) {
            if (!qtnData && isLocalMedia(qDrawS3Id) && mediaDLs[qDrawS3Id]) {
                setQtnData(mediaDLs[qDrawS3Id].raw);
            };
            if (mediaDLs[myMediaID]) {
                setMyBG(mediaDLs[myMediaID].dl);
            };
            /*
            if (qDrawS3Id && mediaDLs[qDrawS3Id]) {
                console.log("check qDrawS3Id raw:",mediaDLs[qDrawS3Id]);
            };
            */
        };
    },[mediaDLs]);    

    useEffect(()=>{
        if (drawData) {
            if (!drawInit.current && drawDataReady) {
                //console.log('draw update answer !');
                setResponse(drawData);
            } else { drawInit.current = false; }
        };
    },[drawData]);

    useEffect(()=>{
        const qtnDataLoaded = qtnData?1:0;
        if (qDrawS3Id && !isLocalMedia(qDrawS3Id) && !qtnDataLoaded) updateQtnData();
    },[qDrawS3Id]);

    useEffect(()=>{
        const drawLoaded = isTeacherDraw?((teacherResp?.qresp)?1:0):((studentResp?.qresp)?1:0);
        if (drawDataID && !drawLoaded) updateDrawData();
    },[drawDataID]);

    useEffect(()=>{
        const bgLoaded = (studentResp?.qresp)?1:0;
        if (bgDataID && !bgLoaded) updateBgData();
    },[bgDataID]);

    useEffect(()=>{ // local check
        if (drawResponse) { // need rollback one step, don't update draw data back directly
            //console.log('drawResponse effect', {drawCanvas, isTeacher, draw:debugTrimDraw(drawResponse), });
            const [isValid, drawSize] = (!preview && drawCanvas) ? validDrawSize(drawCanvas,isTeacher?'te':'st'):[1,0];
            //const [isValid, drawSize] = (drawCanvas) ? validDrawSize(drawCanvas,isTeacher?'te':'st'):[1,0];
            if (isValid) { 
                //setDrawResp(drawResponse);
                setDrawData(drawResponse);
                setDoDrawReset(0);
            } else { 
                //warning.warning_drawing_total_size_student
                //warning.warning_drawing_total_size_teacher
                //t('').replace("%s", string)
                setDoDrawReset(1);
                popAlert(dispatch, 1, t(isTeacher?'warning.warning_drawing_total_size_teacher'
                :'warning.warning_drawing_total_size_student').replace("%s", byteToMB(drawSize)));
            };    
        };
    },[drawResponse]);

    const _setDrawResponse = v => { if (v) setDrawResponse(v); };
    
    let _drawData, _bgData;
    if (isTeacher || (isStudent && showResult)) {
        _drawData = teacherResp?.qresp || drawData;
        _bgData = studentResp?.qresp || bgData;
    } else {
        _drawData = studentResp?.qresp || drawData;
        _bgData = undefined;
    };
    
    return <React.Fragment key='doDraw'>{drawDataReady?<DrawComponentV5 {...{dispatch, isTeacher, showResult,
        preview, doEdit:(doEdit||isTeacher), idx, needDrawDeb}} setResponse={_setDrawResponse} DLBtn={PVMode!==2}
        drawData={_drawData} bgData={_bgData} bgData0={qtnData} BGImgID={myMediaID} BGImgSrc={myBG} LDMode={LDMode} bgBtn={0}
        doReset={doDrawReset} setDoReset={setDoDrawReset} setParentCanvas={setDrawCanvas} />:'loading'}</React.Fragment>;
});






