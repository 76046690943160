import {HTMLProps} from "react";
import IconComponent from "../../../../schema/components/custom/icon/IconComponent";
import {IconList} from "../../../../../static/icons/IconList";
import {SORT_ASC, SORT_DESC} from "../../../../../type/ChartType";

interface Props extends HTMLProps<HTMLSpanElement> {
    type: string
    sort: { type: string, direction: string }
    rotate?: boolean
}

const SortingIcon = (props: Props) => {
    const {type, sort, rotate = false, ...sortingIconProps} = props
    return <span role={"button"}
                 className={`text-dim-250 fs-9 ${rotate ? "report-sort-horizontal" : ""}`} {...sortingIconProps}>
        <span className={`${sort.type === type && sort.direction === SORT_ASC ? "text-body-color" : ""}`}><IconComponent
            src={IconList.general.asc}/></span>
        <span
            className={`${sort.type === type && sort.direction === SORT_DESC ? "text-body-color" : ""}`}><IconComponent
            src={IconList.general.desc}/></span>
    </span>
}

export default SortingIcon