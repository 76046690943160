import ATSwitch from '../components/Switch0';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import {useDropzone} from 'react-dropzone';
import * as UI from '../../libs/libUI';
import { toAry, toObj, toStr, objVals } from '../../libs/libType';
import { svgIcon2, iconButton } from '../components/CpATIcoBtn';
import { useUILang } from '../utils/useUILang';
import { validToUpload } from '../../consts/MimeTypes';
import { fileExt } from '../../libs/libFormat';
import { __MediaExts } from '../../consts/MimeTypes';
import { popAlert } from '../components/CpPopup';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';
import FileExtensionIcon from '../components/CpFileExtensionIcon';
import { SIZE } from '../../AppExPFUser/_components/CpExPFConst';

export const __AnsKey_FIL = 'FIL';
export const __AnsKey_TXT = 'TXT';
export const __AnsKey_LNK = 'LNK';

export const MTHintAnswerKey = (props) => {
    const { dispatch, setQ, Q, editable, setUpFlag, setOnAddMedia, mediaDLs, getMediaDLs, addLocalMedias, isAT } = props
    const [ t ] = useUILang();
    const { _MaxMediaSize } = toObj(props.settings());
    const AKText = Q.AKText?Q.AKText:[];
    const AKFile = Q.AKFile?Q.AKFile:[];
    const AKLink = Q.AKLink?Q.AKLink:[];

    const updateQ = (func,v=undefined) => {func && func(v)};
    const setHints = (v) => {
        setQ('showAnsHints')(v);
    };
    
    const addText = () => {updateQ(setQ('AKText'), [...AKText, {text:''}])};
    const addFile = (e) => {
        if (AKFile.length>0) {doAddEdit(e,0);} 
        else updateQ(setQ('AKFile'), [...AKFile, {file:[]}])
    };
    const addLink = () => {updateQ(setQ('AKLink'), [...AKLink, {URL:'',caption:''}])};

    const setKeyText = (v, ii) => {
        AKText[ii].text = v;
        updateQ(setQ('AKText'),[...AKText]);
    };

    const setKeyFile = (f, ii) => {
        AKFile[ii].file = [...AKFile[ii].file, ...f];
        updateQ(setQ('AKFile'),[...AKFile]);
    };

    const setLinkURL     = (v,ii) => setKeyLink({URL:v}, ii);
    const setLinkCaption = (v,ii) => setKeyLink({caption:v}, ii);
    const setKeyLink = (link, ii) => {
        if (link) AKLink[ii] = { ...AKLink[ii], ...link};
        updateQ(setQ('AKLink'),[...AKLink]);
    };
    const delOneFile = (ii, kk) => {
        AKFile[ii].file.splice(kk,1);
        updateQ(setQ('AKFile'),[...AKFile]);
    };
    const delKey = (type, ii) => {
        switch (type) {
            case 'T':
                AKText.splice(ii,1);
                updateQ(setQ('AKText'),[...AKText]);
                break;
            case 'F':
                AKFile.splice(ii,1);
                updateQ(setQ('AKFile'),[...AKFile]);
                break;
            case 'L':
                AKLink.splice(ii,1);
                updateQ(setQ('AKLink'),[...AKLink]);                
                break;
            default:                              
        };
    };

    const ck_updateMediaID = (arr) => {getMediaDLs([...arr])};
    const ckImageConfig = {
        setOnAddMedia,
        updateMediaID: ck_updateMediaID,
    };
    const edProps = {mediaDLs, getMediaDLs};

    const doAddEdit = (e,ii) => { UI.stopEvent(e);
        if (setOnAddMedia) {
            setOnAddMedia({onAdd:(medias => {
                const ms = toAry(medias);
                
                if (ms.length > 0) {
                    setKeyFile(ms.map(mm=>{
                        return {mediaId:mm.mediaId, size:mm.size, name:mm.name, ext:mm.ext};
                    }), ii);
                };
                setOnAddMedia(0); //stop receiveMedia
            }),maxMedia:10}); //, mimes:['image','video','audio']
        };
    };    

    const maxFile = 100; // no limit for answer key files
    const onDrop = async (addFiles) => {
        const free = 100;
        let anyError = 0;     
        const validFs = (free > 0) && toAry(addFiles).filter(f => {
            const hasError = UpFileErrs(f, __MediaExts, _MaxMediaSize);
            if (hasError) anyError = 1;
            return !hasError;
        }).slice(0, free);
        if (anyError) popAlert(dispatch, 1, t('warning.warning_all_files'));
        if(!(validFs?.length)) return;
        
        const newMs = addLocalMedias(validFs, 0);
        const ms = objVals(newMs);
        if (ms.length > 0) {
            setKeyFile(ms.map(mm=>{
                return {mediaId:mm.mediaId, size:mm.size, name:mm.name, ext:mm.ext};
            }), 0); // 0, only one file box now
        };
    };    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop}); 

    const renderFiles = (obj, ii) => {
        return <div className='flexColStart' style={{width:'98%'}}>
            {obj.file.map((md, kk)=>{
                return <div key={'ff'+kk} className='kekFileItem f12'>
                    <div className='flexRowStartFit gap-1'>
                    <FileExtensionIcon name={md.ext} myStyle={{width:'24px',minWidth:'24px'}}/>{md.name}
                    </div>
                    {iconButton('','general/trash','#b4b4b4','transparent',()=>delOneFile(ii, kk),
                    true,{width:"15px"},{padding:"5px"})}
                </div>
            })}
        </div>
    };

    const keyBox = (type, obj, ii) => {
        return <div key={'key'+type+ii} className='objContainer'>
                {type === 'T' && 
                    <Ckeditor5Base {...props} editorType={"inputKey"} data={obj.text} setData={(v)=>setKeyText(v,ii)}
                    enable={true} debug={false} showStatus={false} setUpFlag={setUpFlag} containerStyle={{border:"1px solid lightgrey"}}/>}

                {(type === 'F' && isAT)?<><div className='kekFileDrop' style={{padding:'5px 10px 5px 10px'}}
                        onClick={(e)=>doAddEdit(e,ii)}>
                        <CpIco src={IconList.inputType.uploadFileEditor} width={25} height={25} />
                        <div className='semi-bold' style={{whiteSpace:'pre-wrap'}}>Click to browse</div>
                    </div>
                    {renderFiles(obj, ii)}
                    </>:''}

                 {(type === 'F' && !isAT)?<><div className={'kekFileDrop ' + dropClass} {...getRootProps()}>
                        <input {...getInputProps()}/>
                        <div className='justify-content-center align-items-center text-at-primary-dim p-5 hover-effect  vstack gap-2 minBreakpoint-xs'>
                            <div className='fs-1 mb-3'>
                                <CpIco src={IconList.inputType.uploadFileEditor} />
                            </div>
                            <span className='semi-bold text-center'>{t('drop.message')}</span>
                        </div>                        
                    </div>
                    {renderFiles(obj, ii)}</>:''}

                {type === 'L' && <>
                <div className='flexRowStart f12 semi-bold' style={{padding:"4px 0 4px 0"}}>{t('url')}</div>
                {UI.EpInputTxt0(obj.URL,(v)=>setLinkURL(v,ii),'URL'+ii,'',{border:'1px solid lightgray', marginBottom:'5px', width:'100%'})}
                <div className='flexRowStart f12 semi-bold' style={{padding:"4px 0 4px 0"}}>{t('caption')}</div>
                {UI.EpInputTxt0(obj.caption,(v)=>setLinkCaption(v,ii),'URL'+ii,'',{border:'1px solid lightgray',width:'100%'})}
                <div>
                </div></>}

                <div className='flexRowBetween'><div></div>
                    {iconButton('','general/trash','#b4b4b4','transparent',()=>delKey(type, ii),true,{width:"15px"},{padding:"6px"})}
                </div>
            </div>;
    };

    const renderAnswerKeys = () => {
        let allAnsKeys = [];
        allAnsKeys.push(AKText.map((obj,ii) => { return keyBox('T',obj,ii); }));
        allAnsKeys.push(AKFile.map((obj,ii) => { return keyBox('F',obj,ii); }));
        allAnsKeys.push(AKLink.map((obj,ii) => { return keyBox('L',obj,ii); }));
        return allAnsKeys;
    };

    const KeysThreeButtons = () => {
        const iconBtn = (icon, cb, enable=true) => {return iconButton('',icon,enable?'white':'#b4b4b4','#0C8CE9',
            cb,true,{},{width:'30px',margin:'0 5px 0 5px',backgroundColor:'#0C8CE9'},enable)
        };
        return <div className='keysThreeButtons'>
            {iconBtn('contentType/text',addText,AKText.length<=0)}
            {iconBtn('contentType/file',addFile)}
            {iconBtn('contentType/link',addLink)}
        </div>;
    };

    return <>
        <div key='haSet' className={'VMenuTab ' + (editable?'':' unclickable')}>
            <div className="vstack gap-3 minBreakpoint-xs"> 
                <div className='flexColStart'>
                <div className='flexRowBetween' style={{marginBottom:"5px"}}>
                    <div>{t('hints')}</div>
                    <div><ATSwitch id='SWHints' leftText='' rightText='' disabled={false}
                        selected={Q.showAnsHints?1:0} leftValue={0} rightValue={1}
                        setter={setHints} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
                </div>
                {(Q.showAnsHints || Q.ansHints) && <Ckeditor5Base  {...props} editorType={"inputKey"} data={Q.ansHints} setData={setQ('ansHints')}
                        enable={true} debug={false} showStatus={false} containerStyle={{border:"1px solid "+bColor}}
                        ckImageConfig={ckImageConfig} {...edProps} setUpFlag={setUpFlag}/>}
                </div>
                <div className='flexRowBetween' style={{marginBottom:"5px"}}>
                    <div>{t('answer-key')}</div>
                    <div><ATSwitch id='SWAnsKeys' leftText='' rightText='' disabled={false}
                        selected={Q.ansKeys} leftValue={0} rightValue={1}
                        setter={setQ('ansKeys')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
                </div>
                <div className='flexColCenter'>
                    <KeysThreeButtons/>
                </div>                
                {renderAnswerKeys()}
            </div>
        </div>
    </>
};
export default MTHintAnswerKey;
//<div style={{ width: "90%" }}>
const dropClass = 'dropzone-component border-at-primary-dim position-relative flex-grow-1 rounded ';
const bColor = 'rgba(222,226,230,1)';
const fColor = 'rgba(116,154,179,1)';

export const UpFileErrs = (f, ValidExts, _MaxMediaSize) => {
    if(!f){ 
        return 'missing File';
    }else if(!ValidExts.includes(toStr(fileExt(f.name)).trim().toLowerCase())){
        return 'Invalid File Type';
    }
    return validToUpload(f, 0, _MaxMediaSize);
}; 
/* original, use media pool (AT and frontend)
                {type === 'F' && <><div className='kekFileDrop' onClick={(e)=>doAddEdit(e,ii)}>
                    {svgIcon2('inputType/uploadFileEditor','#b4b4b4',{paddingTop:'5px',width:'20px',height:'20px'},'#b4b4b4')}                    
                    <div style={{padding:'0 10px 5px 10px',whiteSpace:'pre-wrap'}}>Click to browse</div>
                    </div><div className='flexColStart' style={{width:'95%'}}>
                        {obj.file.map((md, kk)=>{
                            return <div key={'ff'+kk} className='kekFileItem f12'>
                                <div className='flexRowStartFit'>
                                {svgIcon2('file/extension','black')}{md.name}
                                </div>
                                {iconButton('','general/trash','#b4b4b4','transparent',()=>delOneFile(ii, kk),
                                true,{width:"15px"})}
                            </div>
                        })}
                    </div></>}

                {(type === 'F' && isAT)?<><div className='kekFileDrop' onClick={(e)=>doAddEdit(e,ii)}>
                        {svgIcon2('inputType/uploadFileEditor','#b4b4b4',{paddingTop:'5px',width:'20px',height:'20px'},'#b4b4b4')}                    
                        <div style={{padding:'0 10px 5px 10px',whiteSpace:'pre-wrap'}}>Click to browse</div>
                    </div>
                    <div className='flexColStart' style={{width:'95%'}}>
                        {obj.file.map((md, kk)=>{
                            return <div key={'ff'+kk} className='kekFileItem f12'>
                                <div className='flexRowStartFit'>
                                {svgIcon2('file/extension','black')}{md.name}
                                </div>
                                {iconButton('','general/trash','#b4b4b4','transparent',()=>delOneFile(ii, kk),
                                true,{width:"15px"})}
                            </div>
                        })}
                    </div></>:''}

                 {(type === 'F' && !isAT)?<><div className='kekFileDrop ' {...getRootProps()}>
                        <input {...getInputProps()}/>
                        {svgIcon2('inputType/uploadFileEditor','#b4b4b4',{paddingTop:'5px',width:'20px',height:'20px'},'#b4b4b4')}                    
                        <div style={{padding:'0 10px 5px 10px',whiteSpace:'pre-wrap'}}>{t('drop.message')}</div>
                    </div>
                    <div className='flexColStart' style={{width:'95%'}}>
                        {obj.file.map((md, kk)=>{
                            return <div key={'ff'+kk} className='kekFileItem f12'>
                                <div className='flexRowStartFit'>
                                {svgIcon2('file/extension','black')}{md.name}
                                </div>
                                {iconButton('','general/trash','#b4b4b4','transparent',()=>delOneFile(ii, kk),
                                true,{width:"15px"})}
                            </div>
                        })}
                    </div></>:''}                    
*/