import React, { useState, useRef} from 'react';
import { useEffect } from 'react';
import * as UI from '../../libs/libUI';
import { toAry, toObj, toStr } from '../../libs/libType';
import { iconButton } from '../components/CpATIcoBtn';
import { asyncDL } from '../utils/useMediaCache';
import { localDownload } from '../../libs/libDownload';
// before using ckeditor, now change to standard img tag
// data changed from {type:'ATI', data:''} to {type:'ATI', oupid:'', url:''}
const CpATImage = (props) => {
    const {type, id, media, editable, setData, style, noZoom, allowDownload,
        mediaDLs, getMediaDLs, onAddMedia, setOnAddMedia} = props;
    const [vis, setVis] = useState(false);

    // need to use media.url as init, for dummy AT preview local upload
    const [myURL, setMyURL] = useState(media.url); //no s3 outdate cache
    //const [myURL, setMyURL] = useState(null);

    const refFile = useRef(null);
    const refImg = useRef(null);

    const myMediaID =toStr(media.oupid);
    const myStyle = toObj(style);
    const allowZoom = noZoom?(!noZoom):1;
    const myID = toStr(id);

    /*
    useEffect(()=>{
        if (refImg.current && myMediaID !== '' && getMediaDLs) getMediaDLs([myMediaID]);
    },[refImg.current]);
    useEffect(()=>{ 
        if (refImg.current && mediaDLs && mediaDLs[myMediaID]) {
            refImg.current.setAttribute('src', mediaDLs[myMediaID].dl);
            setMyURL(mediaDLs[myMediaID].dl);
        };
    },[refImg.current, mediaDLs]);

    */
    /*
    useEffect(()=>{
        if (myMediaID !== '' && getMediaDLs) getMediaDLs([myMediaID]);
    },[]);
    */
    useEffect(()=>{
        if (myMediaID !== '' && getMediaDLs) {
            const obj = getMediaDLs([myMediaID]);
            if (obj && obj[myMediaID]) setMyURL(obj[myMediaID].dl);
        };
    },[myMediaID]);

    useEffect(()=>{ 
        if (mediaDLs && mediaDLs[myMediaID]) {    
            
            setMyURL(mediaDLs[myMediaID].dl);
        };
    },[mediaDLs]);
    

    // media pool or simple url image
    // pop up layer - enlarge img view
    const zoom = (e, flag) => { UI.stopEvent(e); setVis(flag); };

    const downloadFile = (mediaId, f) => {
        if ((!mediaId) && f) { localDownload(f, f.name); return};
        const dispatch = props?.dispatch;
        dispatch && asyncDL(dispatch, getMediaDLs, mediaId);
    };

    const onFile = (e) => {
        //const files =  (e?.target?.files) || (e?.currentTarget?.files);        
    };

    // {oupid, url}
    const _setData = (oupid, url) => {
        
        if (setData) setData({oupid:oupid, url:url});
        setMyURL(url);
    };

    // call media pool, get one image
    // return value and setURL
    const doAddEdit = e => { UI.stopEvent(e);
        if (setOnAddMedia) {
            setOnAddMedia({onAdd:(medias => {
                
                const ms = toAry(medias);
                if (ms.length > 0) {
                    const src = toStr(ms[0].dl);
                    const mediaId = toStr(ms[0].mediaId);
                    _setData(mediaId, src);
                };
                setOnAddMedia(0); //stop receiveMedia
            }),maxMedia:1, mimes:['image']});
        };
    };

    const preventDrag = (e) => { UI.stopEvent(e); return false;};
    
    
    return <div id={'img'+myID} key={'img'+myID} className='CpATImageCont'>
        <input type="file" multiple id="f1" ref={refFile} onChange={onFile} style={{display:'none'}} />
        <div className='CpATImageToolsRT'>
            {false && toolBtn('general/edit',e=>refFile.current.click(e),'1.0')}
            {editable?toolBtn('general/edit',doAddEdit,'1.0'):''}
            {allowZoom && myURL && toolBtn('general/zoomIn',e=>zoom(e, true),'1.0')} 
        </div>
        <img ref={refImg} src={myURL} alt='' onDragStart={preventDrag} className='CpATImage' style={myStyle}/>
        {vis?<LayerImageZoom myURL={myURL} close={(e)=>zoom(e, false)} 
        downloadFile={()=>downloadFile(myMediaID, media?.file)} allowDownload={allowDownload}/>:''}
    </div>;
};
export default CpATImage;
/*
        {editable?<div className='CpATImageToolsRD'>
            {false && toolBtn('general/trash',e=>refFile.current.click(),'1.0')} 
        </div>:''}
*/
const toolBtn = (icon, cb, scale, iconColor='white', btnStyle={}) => {
    return iconButton('',icon,iconColor,'transparent',cb,true,
        {transform:"scale("+scale+")",width:"18px",height:"20px"},
        {marginLeft:"5px",width:"35px", backgroundColor:"rgba(200,200,200,0.6)", ...btnStyle});
};

const LayerImageZoom = props => {
    const {myURL, close, downloadFile, allowDownload} = props;
    const clickClose = e => {UI.stopEvent(e); close();};
    const clickDL = e => {UI.stopEvent(e); downloadFile();}
    return UI.ScreenMask(<>
        <div className='flexColCenter'>
            <div className="CPATPopContent">
                <div className='flexColStart' style={{borderRadius:"8px", overflow:"auto"}}>
                    <div className='flexRowBetween' style={{backgroundColor:'#31279d'}}><div></div>
                        <div className='flexRowEndFit' style={{minWidth:'70px'}}>
                        {allowDownload && iconButton('','draw/download','white','transparent',clickDL,
                        true,{transform:'scale(0.8)'},{marginRight:'5px',padding:'0'})}
                        {toolBtn('general/close', clickClose, '1.5', 'white', {backgroundColor:'transparent'})}
                        </div>
                    </div>
                    <div style={{width:'100%', maxWidth:'100%', padding:'5px'}}>
                    <img src={myURL} alt='' style={{width:'100%', maxWidth:'100%', objectFit:'contain'}}/>
                    </div>
                </div>
            </div>
        </div>
    </>);
};