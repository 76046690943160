import { isAry, isObj, objEntries, toAry } from "./libType";

export const asyncFilter = async (promise, func) => ((await promise) || []).filter(i => func(i));
export const asyncMap = async (promise, func) => ((await promise) || []).map(i => func(i));

export const ArrayFromOjbect = o => isObj(o) && Object.values(o);
export const ObjectFromArray = (array, key) => {
    if(!isAry(array)) return;
    const o = {};
    array.forEach(i => { o[i[key]] = i; } );
    return o;
};

export const arrayAddOrDelete_ = (ary, val, isAdd) => arrayAddOrDelete(toAry(ary), val, isAdd);
export const arrayAddOrDelete = (ary, val, isAdd) => {
    if(ary.includes(val) === isAdd) // no change
        return ary; //return original array
    return isAdd?
        [...ary, val]: //add
        ary.filter(v => (v !== val)); // delete
};

//find first match index in Array
export const indexInArray = (ary, check) => {
    const len = (ary && isAry(ary) && ary.length) || 0;
    for(var i = 0; i < len; i++) if(check(ary[i])) return i;
    return -1;
};
export const existInArray = (ary, check) => (indexInArray(ary, check) !== -1);

export const findInArray = (ary, check) => {
    return isAry(ary) && ary.find(check);
    //const len = (ary && isAry(ary) && ary.length) || 0;
    //for(var i = 0; i < len; i++) if(check(ary[i])) return ary[i];
    //return false;
};

//Return new array with filtered 
export const removeFromArray = (ary, check) => {
    const ret = [];
    const len = (ary && isAry(ary) && ary.length) || 0;
    for(var i = 0; i < len; i++) if(!check(ary[i])) ret.push(ary[i]);
    return ret;
};

//Add Element to the input Array, if not exists
export const addToArray = (ary, check, item) => {
    if(!ary || !isAry(ary))
        return;
    if(!existInArray(ary, check))
        ary.push(item);
};

// chunkArray(array[n*x+y], n) => array[x+1][n]
export const chunkArray = (originalArray, size) => {
    const array = [...originalArray];
    const results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
};
export const Array2ObjectFunc = (ary, keyFunc) => isAry(ary) && Object.fromEntries(ary.map(i => [keyFunc(i), i]));
export const Array2Object = (ary, key) => isAry(ary) && Object.fromEntries(ary.filter(i => isObj(i)).map(i => [i[key], i]));
export const Obj2KVs = (o) => objEntries(o).map( ([key, val]) => ({key, val}) );

export const ArrayShuffle = (a) => {
    for (var i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        if(j !== i) [a[i], a[j]] = [a[j], a[i]]; //if not self, swap 
    }
    return a;
};

export const arrayForEachAsync = async (a, f) => {
    const l = a.length;
    for(var i = 0; i < l; i++) await f(a[i], i);
};

export const MapAsyncParallel = async (a, f) => {
    return Promise.all(a.map( i => f(i)));
};

export const objectForEachAsync = async (o, f) => {
    await arrayForEachAsync(Object.keys(o), (k, i) => f(o[k], k, i) );
};
