export const __langEn = {
    language: 'Language',
    login: 'Login',
    loginID: 'Login ID',
    password: 'Password',
    logout: 'Logout',
};

export const __langCt = {
    ...__langEn,
    language: '言語',
    login: '登入',
    loginID: '登入 ID',
    password: '密碼',
    logout: '登出',
};

