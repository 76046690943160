import { isFunc, toAry, toObj } from "../../libs/libType";


export const useChainFunc = (reBlockFunc) => {
    const _retModCpBlks = modFunc => reBlockFunc(block => {
        const cp = [...toAry(block)]; modFunc(cp); return cp; } 
    );
    const addEContent = block => reBlockFunc( blocks => [...toAry(blocks), block] ); 
    const replaceContent = (idx, funcOrVal) => _retModCpBlks( blocks => {
        //const b = blocks[idx];
        //const bb =  retFuncOrVal(funcOrVal, b);
        blocks[idx] = retFuncOrVal(funcOrVal, blocks[idx]);
    } );
    const deleteContent = idx => _retModCpBlks( blocks => blocks.splice(idx, 1) );
    const moveBlock = (idx, newIdx) => _retModCpBlks( blocks => blocks.splice(newIdx, 0, ...blocks.splice(idx, 1)) );
    const insertBlock = (idx, block) => _retModCpBlks( blocks => blocks.splice(idx, 0, block));
    return [addEContent, replaceContent, deleteContent, moveBlock, insertBlock];
};

export const useChain = (setFields, key) => {
    const reBlockFunc = func => setFields( fs => { return { ...toObj(fs), [key]: func(fs?.[key]) }; });
    return  useChainFunc(reBlockFunc);
};

/*
export const useChain_V1 = (setFields, key) => {
    const insertBlock = (idx, block) => setFields(fs => {
        const blocks = [...toAry(fs?.[key])];
        blocks.splice(idx, 0, block);
        return {...toObj(fs), [key]: blocks};
    });
    const moveBlock = (idx, newIdx) => setFields(fs => {
        const blocks = [...toAry(fs?.[key])];
        const seg = blocks.splice(idx, 1);
        blocks.splice(newIdx, 0, ...seg);
        return {...toObj(fs), [key]: blocks};
    });
    const addEContent = block => setFields(fs => ( {...toObj(fs), [key]: [...toAry(fs?.[key]), block]} ) );
    const deleteContent = idx => setFields(fs => {
        const blocks = [...toAry(fs?.[key])];
        blocks.splice(idx, 1); 
        return {...toObj(fs), [key]: blocks};
    });
    const _replace = (fs, i, funcOrVal) => {
        const blocks = [...toAry(fs?.[key])];
        blocks[i] = retFuncOrVal(funcOrVal, blocks[i]);
        return {...fs, [key]: blocks};
    };
    const replaceContent = (idx, funcOrVal) => setFields(fs => _replace(fs, idx, funcOrVal));  
    return [addEContent, replaceContent, deleteContent, moveBlock, insertBlock];
}
*/

export const retFuncOrVal = (funcOrVal, curVal) => isFunc(funcOrVal)? funcOrVal(curVal): funcOrVal
export const subState = (key, FunOrVal) => obj => {
    const o = toObj(obj);
    const v = o[key];
    const v2 = retFuncOrVal(FunOrVal, v);
    return (v2 === v)? obj: {...o, [key]:v2}; 
};
