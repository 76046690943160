import {MathJax} from "better-react-mathjax";
import { isAry, toObj } from "../../libs/libType";
import { useEffect, useMemo, useState } from "react";
import { autoId } from "../../AppExPf/AppUtil";

const CpMJX = _props => { 
    const {children, cb, dynamic=true, ...props} = toObj(_props);
    const id = useMemo(() => autoId(), []);

    const [c, setC] = useState();
    useEffect(() => {
        const t = setTimeout( () => setC(children), 0);
        return () => clearTimeout(t);
    }, [children]);

    useEffect(()=>{
        cb && cb(); // for fib question
    },[]);

    const empty = !c || (isAry(c) && (!c.length));
    //console.log('CpMJX', {id}, children);
    return empty? '': <MathJax dynamic={dynamic} {...props}>{c}</MathJax>;
    //return <MathJax {...props}>{c}</MathJax>;
}

export default CpMJX;

