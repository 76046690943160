import { isAry, isObj, maxVal, toAry, toInt, toObj, toStr } from "../libs/libType";
import { _MaxLenMetaSetId, _MaxLenTodo, _MinLenMetaSetId, _MinLenTodo } from "./ATConsts";
import { _ATMetaTypeCodes, _ATMetaTypeReport, _ATMetaTypeReportSubj } from "./ATMetaTypes";
import { toIdStr, toUniId2dStrs, toUniIdAry, toUniIdsStr, validFields, validIdCharsMinMax, validMinMax } from "./ATValidate";

export const normalizedEditSubjSetting = (fields, opts, trace) => {
    //console.log({opts});
    const fs = toObj(fields);
    const metaId = toIdStr(fs.metaId);
    const metaSetId = toIdStr(fs.metaSetId);
    const ret = {
        //...fs,
        SMId: metaSetId && metaId && (metaSetId + ',' + metaId),
        metaId,
        metaSetId,

        numQ: maxVal(0, toInt(fs.numQ)),

        isNew: fs.isNew? 1: 0,
    };
    //console.log('normalizedEditATMetaSet'); console.log(ret);
    return ret;
};

const validId = str => validIdCharsMinMax(toStr(str), _MinLenMetaSetId , _MaxLenMetaSetId, 'ID');

export const validEditSubjSetting = (fs, opts, trace) => {
    const track = o => trace && trace.push(o);
    track('-- validEditATMetaSet --');

    const v = validFields(fs, {});
    v.setIf('metaId', validId(fs.metaId)?'ID should be within '+_MinLenMetaSetId+' - '+_MaxLenMetaSetId+' characters (Alphanumeric or Underscore).':'');
    //v.setIf('numQ', (!(fs.MSLangCt || fs.MSLangEn)) && 'Missing Language');

    track('-- validEditATMetaSet end --');
    return v.fieldErrs;
};

