import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpActionButton from "../_components/CpActionButton";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { ReduxBind } from "../../saga/ReduxState";
import { downloadBase64 } from "../../libs/libDownload";
import { formatTimeStampHK, timeStampNowGMT } from "../../libs/libTime";
import { LoadIco } from "../../AppExPf/AppUtil";


const CpTeacherDownloadExcelCTA = ReduxBind(props => { // base on poc TeacherDownloadExcelCTA.tsx
    const { dispatch, assignId } = props;
    const [ t ] = useUILang();
    const [show, setShow] = useState(false);
    
    const clickDL = async () => {
        //setShow(prev => true); await delay(3000);
        downloadFile();
        //await delay(1000); setShow(prev => false);

        //const [res, err] = await asyncApiCallLoad_(dispatch, '/getAssignmentReport', {assignId} );
        //const rps = toObj(res?.rps);
        //const {overQtn, stuQtn} = rps;
        //const stuMap = res?.stuMap;
        //const rows = toRptExc1(overQtn, stuQtn, stuMap);
    };

    const downloadFile = async () => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getAssignmentReport', {assignId} );
        const fileData = res.fileData; //base64
        if(fileData){
            const timeStr = formatTimeStampHK(timeStampNowGMT(), 'YYYY-MM-DD_HH.mm.ss');
            downloadBase64(fileData, 'AssignmentReport_'+timeStr+'.xlsx');
        } 
    };

    return <><CpActionButton title={t("download-excel")} iconPath={IconList.file.excel} hiddenText={true}
            className={`btn excel-btn text-nowrap gap-2 rounded-light semi-bold user-select-none`}
            onClick={clickDL}>
        </CpActionButton>

        <Modal show={show} className={"justify-content-center"} backdrop="static"
               centered dialogClassName="loading-modal">
            <div className={"loading-animate bg-white rounded-light border shadow"}>
                {LoadIco('60px')}
            </div>
        </Modal>
    </>
});

export default CpTeacherDownloadExcelCTA;