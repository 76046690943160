export const PAGE = {
    "EXERCISE_PAGE": "EXERCISE_PAGE",
    "SAVED_COLLECTION_SEARCH_PAGE": "SAVED_COLLECTION_SEARCH_PAGE",
    "EXERCISE_EDIT_PAGE": "EXERCISE_EDIT_PAGE",

    "LIBRARY_PAGE": "LIBRARY_PAGE",
    "LIBRARY_SEARCH_PAGE": "LIBRARY_SEARCH_PAGE",

    "ASSIGNMENT_LIST": "ASSIGNMENT_LIST",
    "ASSIGNMENT_EDIT_PAGE": "ASSIGNMENT_EDIT_PAGE",
    "ASSIGNMENT_REPORT": "ASSIGNMENT_REPORT",

    "TEACHER_REPORT": "TEACHER_REPORT",
    "STUDENT_PROGRESS_REPORT": "STUDENT_PROGRESS_REPORT",

    "DASHBOARD": "DASHBOARD"
}

export const TAB = {
    "EXERCISE_MY_EXERCISE": "EXERCISE_MY_EXERCISE",
    "EXERCISE_SAVED_COLLECTION": "EXERCISE_SAVED_COLLECTION",
    "LIBRARY_OUPC": "LIBRARY_OUPC",
    "LIBRARY_MY_SCHOOL": "LIBRARY_MY_SCHOOL",

    "ASSIGNMENT_LIST": "ASSIGNMENT_LIST",
    "ASSIGNMENT_REPORT": "ASSIGNMENT_REPORT",
    "ASSIGNMENT_RESULTS": "ASSIGNMENT_RESULTS",
    "ASSIGNMENT_NEXT_STEPS": "ASSIGNMENT_NEXT_STEPS",
    "ASSIGNMENT_FOLLOW_UP_EXERCISE": "ASSIGNMENT_FOLLOW_UP_EXERCISE",
}

export enum FIRST_ACTION {
    "OPEN_ASSIGN" = "OPEN_ASSIGN",
    // "SWITCH_TO_EDIT_MODE": "SWITCH_TO_EDIT_MODE",
    // "SWITCH_TO_PREVIEW_MODE": "SWITCH_TO_PREVIEW_MODE"
}