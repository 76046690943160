import {createContext, Dispatch, ReactNode, useReducer} from "react";

export enum Pool {
    DEFAULT_QUESTION_POOL = "DEFAULT_QUESTION_POOL",
    SCHOOL_SHARED_EXERCISES = "SCHOOL_SHARED_EXERCISES",
    MY_EXERCISES = "MY_EXERCISES"
}

type SearchStateType = {
    openFilter: boolean,
    pool: Pool.DEFAULT_QUESTION_POOL | Pool.SCHOOL_SHARED_EXERCISES | Pool.MY_EXERCISES
}

export enum SearchActionKind {
    OPEN_FILTER = 'OPEN_FILTER',
    UPDATE_POOL = 'UPDATE_POOL',
}

interface SearchActionType {
    type: SearchActionKind;
    payload?: any;
}

const SearchState: SearchStateType = {
    openFilter: false,
    pool: Pool.DEFAULT_QUESTION_POOL
}

const SearchContext = createContext<{
    state: SearchStateType;
    dispatch: Dispatch<SearchActionType>;
}>({
    state: SearchState,
    dispatch: () => null
})

const SearchReducer = (state: SearchStateType, action: SearchActionType): SearchStateType => {

    switch (action.type) {
        case SearchActionKind.OPEN_FILTER:
            let openState = action.payload === false ? false : !{...state}.openFilter
            return {...state, openFilter: openState}
            break;
        case SearchActionKind.UPDATE_POOL:
            return {...state, pool: action.payload}
            break;
        default: {
            return state
        }
    }
}

const SearchProvider = (props: { children: ReactNode }) => {
    const {children} = props
    const [state, dispatch] = useReducer(SearchReducer, SearchState)
    const value = {state, dispatch}

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
}

export {SearchProvider, SearchContext}