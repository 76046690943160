import { useEffect, useState, useMemo} from 'react';

import { ReduxBind } from '../saga/ReduxState';

import { apiCallLoad, apiCallLoad_ } from '../libs/awsFuncs';
import { objKeys, strCmp, toObj, toStr } from '../libs/libType';
import * as UI from '../libs/libUI';
import { aTUITime, clickConfirm, clickUrl, dpDisplay, langDisplay, mergePubsDrafts } from './AppUtil';

import { _ATRoot } from '../consts/ATConstReact';
import { _ATMetaTypeByCode } from '../consts/ATMetaTypes';
import { ATDo_Meta, _ATCanDo } from '../consts/ATRoleRights';
import { urlPush_Replace } from '../saga/urlPush.saga';
import { useTickAll } from './ATQtnEdit/PageATQtns';
import { cpATIcoBtn_, svgIcon2 } from './components/CpATIcoBtn';

const selColor = "#0C8CE9";

const toDo = UI.stopEventThen( e => alert('TBC') );

const PageATMetas = ReduxBind((props) => { 
	const dispatch = props.dispatch;
	const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
    const canMeta = _ATCanDo(role, ATDo_Meta); 
	
	const [metaSets, setMetaSets] = useState();
	
	const msids =  objKeys(metaSets);
	const sortIds = useMemo(()=> {
		
		const sorts = msids.map( id => {
			const {draft:d, pub:p} = metaSets[id]; 
			return toObj(d? d: p);
		}); 
		sorts.sort((a, b) => (b.dateMod - a.dateMod) || strCmp(a.metaSetId, b.metaSetId));
		
		return sorts.map(s => s.metaSetId); 
	}, [metaSets]);


	const [ticks, setTick, setTicks, tickAll, setTickAll, clearTicks] = useTickAll(msids);
	const onLoad = (res, err) => {
		const pubs = res?.data?.metaSets || [];
		const drafts = res?.data?.metaSetDrafts || []; 
		//lj('ageATMetasLoad',{pubs}, {drafts});
		const merged = mergePubsDrafts(pubs, drafts, 'metaSetId'); 
		//lj('merged',merged);
		setMetaSets( merged );
		clearTicks();
	};


	const reload = () => apiCallLoad( dispatch, '/getATMetaSets', onLoad, {}); 
	useEffect(() => { canMeta? reload(): dispatch(urlPush_Replace(_ATRoot)); }, [] );
	
	const clickNew = canMeta && clickUrl(dispatch, _ATRoot + 'meta/new');
	const clickEdit = canMeta && clickUrl(dispatch, _ATRoot + 'meta/edit/'+ticks[0]);
    const clickClone = canMeta && clickUrl(dispatch, _ATRoot + 'meta/new/'+ticks[0]);;
	const clickDelele = canMeta && clickConfirm(dispatch, 'Please confirm delete', () => apiCallLoad_( dispatch, '/deleteATMetaSets', onLoad, {ticks}) );
	const clickPub = canMeta && clickConfirm(dispatch, 'Please confirm publish', () => apiCallLoad_( dispatch, '/putATMetaSetsPublish', onLoad, {ticks}));
	const clickUnpub = canMeta && clickConfirm(dispatch, 'Please confirm unpublish', () => apiCallLoad_( dispatch, '/putATMetaSetsUnpublish', onLoad, {ticks}));
	const clickReload = e => { UI.stopEvent(e); reload(); };

	const cntTick = ticks?.length || 0;
	return <div className="PageATMetas adminAccountTop">
		<div className="flexColStart w100 p8" style={{height:'100%'}}>
    	{/*<div className='w100' style={{overflow:'auto', marginBottom:'50px'}}> */}
		<div className='w100' style={{overflow:'auto'}}>
		<div className="f16">Admin / Metadata Sets</div>
		<div className="adminToolsContainer"><div style={{display:"flex"}}>
			<div className="adminTools1">
				{canMeta && cpATIcoBtn_('labelDiagram/add', 'New', clickNew)}
				{canMeta && cpATIcoBtn_('general/edit', 'Edit', (cntTick===1) && clickEdit)}
				{canMeta && cpATIcoBtn_('general/copy', 'Duplicate', (cntTick===1) && clickClone)}
				{canMeta && cpATIcoBtn_('general/delete', 'Delete', (cntTick) && clickDelele)}
				{canMeta && cpATIcoBtn_('general/publish', 'Publish', (cntTick) && clickPub)}
				{canMeta && cpATIcoBtn_('general/unpublish', 'Unpublish', (cntTick) && clickUnpub)}
				{cpATIcoBtn_('general/reload', 'Reload', clickReload)}
			</div>
			<div className="adminTools2" onClick={toDo} style={{color:selColor}}>
				{svgIcon2("general/filter","#2995cd")}Filter: ({msids && msids .length})
			</div>
		</div></div>
			<div className="metaItemRowHeader f14">
			<div className="metaItem1">{UI.CheckBox0(tickAll, setTickAll, 'chkall')}All</div>
			<div className="metaItem2">Title</div>
			<div className="metaItem3">Code</div>
			<div className="metaItem4">Type</div>
			<div className="metaItem5">Languages</div>
			<div className="metaItem6">Version</div>
			<div className="metaItem7">Last Modified</div>
        </div>            
        <div className="metaListContainer">
			{ sortIds.map(msid => (metaSetItem2(msid, metaSets[msid].pub, metaSets[msid].draft, ticks, setTick ))) }             
        </div>		
	</div>
	</div></div>
});
export default PageATMetas;

const metaSetItem2 = (msid, pub, draft, ticks, setTick ) => {
	const ms = draft?draft:pub;
	const on = ticks?.includes(msid);
	const _key = msid+'_'+(on?'Y':'N');
    return <label key={'meta_'+_key} style={{width:'100%'}}>
		<div className="itemRowBlk">
		<div className="adminItemRow">
        <div className="metaItem1">
			{UI.CheckBox0(on, setTick(msid), 'chk'+_key)}
        </div>
        <div className="metaItem2 f15">
			<div>{ms.MSNameEn}</div><div>{ms.MSNameCt}</div>
		</div>
		<div className="metaItem3 f15"> {ms?.metaSetId}</div>
        <div className="metaItem4 f15"> {_ATMetaTypeByCode(ms?.MSType)}</div>
        <div className="metaItem5 f15">{langDisplay(ms?.MSLangEn, ms?.MSLangCt)}</div>
        <div className="metaItem6 f15">{dpDisplay(draft?.MSVer, draft?.drpu, pub?.MSVer, pub?.drpu)}</div>
		<div className="metaItem7 f15">
			<div>{aTUITime(ms.dateMod)}</div><div>{ms.modLogin}</div>
		</div>
    </div></div></label>;
};


