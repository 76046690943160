/**
 * The built in Error object.
 * @external Error
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error|Error}
 */

/**
 * ValidationError thrown when the function input parameters is invalid.
 *
 * @extends {external:Error}
 */
export class ValidationError extends Error {
  constructor(errors) {
    super(errors.length === 1 ? errors[0].message : 'multiple validation error');
    this.name = this.constructor.name;

    /**
     * Underlying JSON Schema errors.
     *
     * @type {external:Error[]}
     */
    this.errors = errors;
  }
}

/**
 * APIError thrown when error returned by Data API
 *
 * @extends {external:Error}
 *
 * @example
 * import { APIError } from '@oup/oupc-middle-layer-client';
 *
 * try {
 *   await mlClient.fetchEntitlements();
 * } catch (error) {
 *   if (error instanceOf APIError) {
 *     // handle this as APIError
 *     console.log(error.code);
 *     console.log(error.message);
 *   }
 * }
 */
export class APIError extends Error {
  constructor(code, message) {
    super(message);
    this.name = this.constructor.name;

    /**
     * Error code
     *
     * @type {string}
     */
    this.code = code;
  }
}
