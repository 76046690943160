import { Button } from "react-bootstrap";

import { CpSettingModalSection, CpSettingModalTabContainer, CpSettingModalTitle } from "../_components/CpSettingModalTabCps";
import CpSharedModal from "../_components/CpSharedModal";
import SharedModalHeader from "../_components/CpSharedModalHeader";

import { IconList } from "../../consts/ATIconListToUsePoc";
import { ReduxBind } from "../../saga/ReduxState";
import { stopEvent } from "../../libs/libUI";
import { preJSSort } from "../../AppExPf/AppUtil";
import { toIdStr } from "../../consts/ATValidate";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";

export const LayerExlDel = ReduxBind(props => {
    const {dispatch, t, para, setPara, reload} = props;
    const EId = toIdStr(para?.EId);
    const onDel = para?.onDel;

    const clickClosee = e => {stopEvent(e); setPara(); };
    const clickDel = async e => {
        const [sr, se] = await asyncApiCallLoad_(dispatch, '/deleteExer', {EId} ); 
        onDel? onDel(): reload('deleteExer'); 
        clickClosee(e);
    };

    const gap = 4;// 3;
    const vis = para;
    return <CpSharedModal show={vis} scrollable={true} 
        header={<SharedModalHeader handleClose={clickClosee} title={t("delete")} iconPath={IconList.general.trash} />} 
        footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
            <Button onClick={clickClosee} variant="gray-body-color w-100 border"> {t("cancel")} </Button>
            <Button onClick={clickDel} variant={"danger btn-block w-100"}>{t("delete")}</Button>
        </div>} >
        <CpSettingModalTabContainer gap={gap}>
            <div className={"mb-3"}><CpSettingModalTitle>{t("delete-message").replace('%s',t('delete-items.exercise'))}</CpSettingModalTitle></div>
        </CpSettingModalTabContainer>
    </CpSharedModal>;
});

