import React, {Dispatch,forwardRef,SetStateAction,useEffect,useImperativeHandle,useLayoutEffect,
    useMemo,useRef,useState} from "react";
import {days,getMonthInNumeric,getMonthName,getTimes,getTodayDateTime,
    monthNameType,monthType, monToTrans, dayToTrans} from "./CpDate";
import CpIco from "./CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { useUILang } from "../../AppExPf/utils/useUILang";
import CpActionButton from "./CpActionButton";
import {Button, CloseButton} from "react-bootstrap";
import { stopEvent } from "../../libs/libUI";
import { toStr } from "../../libs/libType";
import { getUUID } from "./CpGetUUID";

const DATE_MODE = "Date mode";
const MONTH_MODE = "Month mode";
const YEAR_MODE = "Year mode";

const CpDatePickerPanel = forwardRef((props, ref) => { // base on poc DatePickerPanel.jsx
    const [ t ] = useUILang();
    const { formatedDateTime, setFormatedDateTime, fromDateTime, 
        allowSameDay = true, enableTime = true, sticky = false } = props;
    const {date, month, year, time} = formatedDateTime;
    const [showPanel, setShowPanel] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(month);
    const [currentYear, setCurrentYear] = useState(year);
    const datePickerPanelRef = useRef(null);
    const dates = useMemo(() => {
        let selectedDate = new Date(`${currentMonth} 01, ${currentYear}`).getDay();
        let numberOfDays = new Date(
            Number(currentYear),
            Number(getMonthInNumeric(currentMonth)) + 1,
            0
        ).getDate();

        let items = [];
        for (let i = 0; i < selectedDate; i++) {
            items.push("");
        };
        for (let i = 1; i <= numberOfDays; i++) {
            items.push(i.toString());
        };
        return items;
    }, [currentMonth, currentYear]);

    const myID = useMemo(()=>{
        return getUUID();
    },[]);

    const times = getTimes();
    const yearList = useMemo(() => {
        return [...Array(15).keys()].map(num => year - 10 + num).sort()
    }, []);

    const monthList = useMemo(() => {
        return ([...new Array(12)].map((empty, idx) => getMonthName(idx.toString())));
    }, []);
    const [mode, setMode] = useState(DATE_MODE);

    const reduceMonth = () => {
        const month = getMonthInNumeric(currentMonth) - 1;
        if (month < 0) {
            setCurrentMonth("December");
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(getMonthName(month.toString()));
        };
    };

    const addMonth = () => {
        const month = getMonthInNumeric(currentMonth) + 1;
        if (month > 11) {
            setCurrentMonth("January");
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(getMonthName(month.toString()));
        };
    };

    const handleLeavePanel = (e) => {
        stopEvent(e);
        if (showPanel && datePickerPanelRef.current) {
            const withinBoundaries = e.composedPath().includes(datePickerPanelRef.current);
            if (withinBoundaries) {
                
            } else {
                
            };
            setShowPanel(false);
        };
    };

    const checkPickedDate = (date) => {
        const isExistDate = [...dates].includes(date);
        const updatedDate = (isExistDate ? parseInt(date) : 1);
        setFormatedDateTime(prev => ({...prev, date: updatedDate}));
    };
    const handlePickDate = (date) => {
        if (date) {
            checkPickedDate(date);
            setFormatedDateTime(prev => ({...prev, year: currentYear, month: currentMonth}));
            //setShowPanel(false);
            if(!enableTime) {
                setShowPanel(false);
            };
        };
    };

    const handlePickTime = (time) => {
        setFormatedDateTime(prev => ({...prev, time}));
        //setShowPanel(false);
        if(!enableTime) {
            setShowPanel(false);
        };        
    };

    const switchMode = newMode => setMode((mode === newMode)? DATE_MODE: newMode);

    const handleMonth = (month) => (e) => {
        stopEvent(e);
        setCurrentMonth(month);
        switchMode(mode);
    };

    const handleClose = () => {
        setShowPanel(false);
    };    

    const handleYear = (year) => (e) => {
        stopEvent(e);
        setCurrentYear(year);
        switchMode(mode);
    };

    const handleToday = () => {
        const today = getTodayDateTime();
        setFormatedDateTime(prev => ({
            ...prev, year: today.year, month: today.month, date: today.date
        }));
        setCurrentMonth(today.month);
        setCurrentYear(today.year);
    };

    //"calTime"+toStr(t).replaceAll(":","")
    const scrollToTime = () => {
        if (time) {
            const tt = toStr(time).replaceAll(":","")
            const ele = document.getElementById(myID+"CT"+tt);
            if (ele) {
                ele.scrollIntoView({ behavior: 'instant', block: "nearest", inline: "nearest" });
            };
        };
    };

    useEffect(() => {
        if (showPanel) {
            document.addEventListener('click', handleLeavePanel);
            handlePanelPosition();
            scrollToTime();
        };
        return () => {
            document.removeEventListener('click', handleLeavePanel);
        };
    }, [showPanel]);

    useLayoutEffect(() => {
        checkPickedDate(date.toString());
    }, [dates]);

    const handlePanelPosition = () => {
        if (datePickerPanelRef.current) {
            const parent = datePickerPanelRef.current.parentElement;
            if (parent) {
                const pa = parent.getBoundingClientRect();
                const dp = datePickerPanelRef.current.getBoundingClientRect();
                const leftMenu=100;
                const winWidth = window.innerWidth;
                const showL = ((pa.left - leftMenu) > (winWidth - pa.right))?0:1;
                

                let delta = '0px';
                if (showL && ((pa.left+dp.width+20)>winWidth)) {
                    // shift left more if exceed screen to the right
                    delta = '-20px'; // 20px the vertical scroll bar width
                };

                if (showL) {
                    datePickerPanelRef.current.style.left = delta;
                } else {
                    datePickerPanelRef.current.style.right = delta;
                };
            };
        };
    };

    const handlePanelPosition_org = () => {
        if (datePickerPanelRef.current) {
            const parent = datePickerPanelRef.current.parentElement;
            if (parent) {
                // Todo: Redo
                const isFloatLeft = window.innerWidth / 2 - (parent.getBoundingClientRect().left + parent.getBoundingClientRect().width / 2) > 0;
                //This is a sample, may need to update later
                if (window.innerWidth > datePickerPanelRef.current.getBoundingClientRect().width) {
                    if (isFloatLeft) {
                        datePickerPanelRef.current.style.left = "0px";
                    } else {
                        datePickerPanelRef.current.style.right = "0px";
                    };
                };
            };
        };
    };

    const getActiveDateStyle = (activeDate) => {
        if (fromDateTime) {
            const {year: fromYear, month: fromMonth, date: fromDate} = fromDateTime;
            const from = new Date(`${fromMonth} ${fromDate}, ${fromYear}`);
            const selected = new Date(`${currentMonth} ${activeDate}, ${currentYear}`);
            if (allowSameDay) {
                if (from > selected) {
                    return "text-dim-350";
                };
            } else {
                if (from >= selected) {
                    return "text-dim-350";
                };
            };
        };
        return activeDate === date.toString() && month.toString() === currentMonth && year === currentYear ? "active" : "";
    };

    useImperativeHandle(ref, () => ({
        setShowPanel
    }));

    return <div className={`date-picker-overlay ${sticky ? "sticky" : "px-3 px-md-0"} ${showPanel ? "" : "d-none"}`}>
        <div
            className={`date-picker-panel ${enableTime ? "with-time" : ""} d-flex flex-wrap my-0 my-md-2 user-select-none border overflow-hidden`}
            ref={datePickerPanelRef} onClick={stopEvent} style={{left:'10px !important'}}>
            <div className={"calendar-date-container d-flex flex-column overflow-hidden"}>
                <div
                    className="calendar-top pt-2 pb-1 d-flex w-100 flex-column justify-content-between align-items-center bg-dim-100">
                    <div className="d-flex w-100 flex-row justify-content-between align-items-center semi-bold px-3">
                        {enableTime ? <span className={"px-3 d-block d-md-none"}></span>:''}
                        <span role={"button"}
                              className={`d-flex justify-content-start calendar-previous p-1 ${mode === DATE_MODE ? "" : "text-dim-350  pe-none"}`}
                              onClick={() => reduceMonth()}><CpIco src={IconList.general.previousCalendar}/></span>
                        <span role={"button"} className={"d-flex justify-content-center calendar-year border p-1"}
                              onClick={() => switchMode(YEAR_MODE)}>{currentYear}</span>
                        <span className={"d-flex justify-content-center"}>-</span>
                        <span role={"button"} className={"d-flex justify-content-center calendar-month border p-1"}
                              onClick={() => switchMode(MONTH_MODE)}>{t(monToTrans(currentMonth))}</span>
                        <span role={"button"}
                              className={`d-flex justify-content-end calendar-next p-1 ${mode === DATE_MODE ? "" : "text-dim-350 pe-none"}`}
                              onClick={() => addMonth()}><CpIco src={IconList.general.nextCalendar}/></span>
                        {enableTime ? <CloseButton className={"d-block d-md-none"} onClick={() => handleClose()}/>:''}
                    </div>

                    {mode === DATE_MODE && <div className={"calendar-weeks d-grid w-100 semi-bold"}>
                        {days.map((ele) => (
                            <div key={ele}
                                 className={"d-flex justify-content-center align-items-center py-2"}>{t(dayToTrans(ele))}</div>
                        ))}
                    </div>}
                </div>
                <div className="calendar-bottom d-flex justify-content-center w-100 flex-grow-1">
                    {mode === DATE_MODE && <>
                        <div className={"calendar-dates d-grid w-100"}>
                            {dates.map((ele, idx) => (
                                <div key={`${currentYear}-${currentMonth}-${idx}`} role={ele ? "button" : "none"}
                                     className={`calendar-day d-flex justify-content-center align-items-center py-2 ${getActiveDateStyle(ele)}`}
                                     onClick={() => handlePickDate(ele)}>
                                    {ele}
                                </div>
                            ))}
                        </div>
                    </>}
                    {mode === MONTH_MODE && <div className={"calendar-months d-grid w-100 semi-bold"}>
                        {monthList.map(month => <div key={month} role={"button"}
                                                     className={`calendar-month d-flex justify-content-center align-items-center p-2 m-2 sequenceListAnimation fs-8 ${month === currentMonth ? "active" : ""}`}
                                                     onClick={handleMonth(month)}>{t(monToTrans(month))}</div>)}
                    </div>
                    }
                    {mode === YEAR_MODE && <div className={"calendar-years d-grid w-100 flex-grow-1 semi-bold"}>
                        {yearList.map(year => <div key={year} role={"button"}
                                                   className={`calendar-year d-flex justify-content-center align-items-center p-2 m-2 sequenceListAnimation fs-8 ${year === currentYear ? "active" : ""}`}
                                                   onClick={handleYear(year)}>{year}</div>)}
                    </div>
                    }
                </div>
                {mode === DATE_MODE && <div className={""}>
                    <CpActionButton title={t("datepicker-today")}
                                  className={"exercise-action-btn gap-2 rounded semi-bold user-select-none py-1 m-3"}
                                  hiddenText={false} onClick={() => handleToday()}/>
                </div>}
            </div>
            {enableTime && <>
                <div className={"vr d-none d-md-block"}></div>
                <div className={"calendar-time-container d-flex flex-column h-100 flex-grow-1 overflow-hidden"}>
                    <div className={"semi-bold text-center py-2 bg-dim-100"}>{t("datepicker-time")}</div>
                    <div className={"d-flex flex-column w-100 h-100 overflow-auto"}>

                        {times.map(t => <div role={"button"} key={t} id={myID+"CT"+toStr(t).replaceAll(":","")}
                                             className={`calendar-time w-100 p-2 text-center ${t === time ? "active" : ""}`}
                                             onClick={() => handlePickTime(t)}>{t}</div>)}
                    </div>
                </div>
            </>}
        </div>
    </div>
});
export default CpDatePickerPanel;