import React, {useEffect, useState, useRef} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { objEntries, strCmp, toAry, toInt, toObj, toStr } from "../../libs/libType";

import CpIco from "./CpIco";
//import { autoScrollByDropdown } from "../../poc/helper/autoScrollByDropdown";
import { autoScrollByDD } from "../../AppExPf/components/autoScrollByDD";

export const obj2DropdownItemT = (t, ts) => Object.fromEntries(objEntries(ts).map(([k, _t]) => [k, {t:t(_t)}]));
export const obj2DropdownItem = src => Object.fromEntries(objEntries(src).map(([k, t]) => [k, {t}]));

export const ent2DropdownItem = ents => Object.fromEntries(toAry(ents).map(([k, t]) => [k, {t}]));
export const ary2DropdownItem = src => Object.fromEntries(toAry(src).map((t, k) => [toStr(k), {t}]));

const strCmpTrimLow = (a, b) => strCmp(toStr(a).trim().toLowerCase(), toStr(b).trim().toLowerCase() );
export const sortEnt = (kvs) => {
    const ret = toAry(kvs);
    ret.sort((a, b) => strCmpTrimLow(a[1], b[1]));
    return ret;
};

const CpDropdown = (props => {
    const [t] = useUILang();
    const {
        id, minItem = 0, 
        enable= 1, idx, cb, iconPath= "", className= "", grouping, position= "bottom",
        optionStyle= {maxHeight: "400px", overflow: "auto"}, scrollTo= 1,
    } = props;
    const placeHolder = toStr(props.placeHolder);
    const items = toObj(props.items);
    //const items = debugMode()? Object.fromEntries([...objEntries(props.items), ['fake', {t:'fake'}]]): toObj(props.items);

    const [focus, setFocus] = useState(false);
    const dropdownRef = useRef(null);
    const modalRef = useRef(null);
    const [curDD, curModal] = [dropdownRef?.current, modalRef?.current]; 
    const itemLength = objEntries(items).length;
    const lock = (itemLength) <= minItem;
    const noItem = itemLength === 0;
    const _enable = enable && !lock;

    useEffect(() => {
        if(scrollTo && focus && curModal?.scrollIntoView) curModal.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });

        const autoScroll = props.autoScroll || '.scroll-container'; //{"#test-scroll-container"
        if (scrollTo && focus ) {
            //autoScrollByDropdown({container: autoScroll, dropdown: dropdownRef, options: modalRef})
            autoScrollByDD({container: autoScroll, dropdown: dropdownRef, options: modalRef})
        };
    }, [focus, modalRef, modalRef.current]);

    const handleOpen = (e) => {
        const target = e.target;
        if (curDD?.contains && !curDD.contains(target)) {
            //
        } else {
            setFocus(prev => !prev);
        };
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (curDD?.contains && !curDD.contains(event?.target)) {
                setFocus(false);
            }
        };

        //Found that there is not work with modal using handleClickOutside
        const modalElement = document.querySelector('.modal');
        if (modalElement) {
            modalElement.addEventListener('click', handleClickOutside);
        };

        document.addEventListener('click', handleClickOutside);

        if (dropdownRef.current) {
            let found = curDD?.closest(".sequenceListAnimation")
            if (found) {
                if (focus) {
                    found.style.position = "relative";
                    found.style.zIndex = "1";
                } else {
                    found.style.removeProperty("position")
                    found.style.removeProperty("z-index")
                };
            };
            //if (focus && scrollTo) dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
        };

        return () => {
            document.removeEventListener('click', handleClickOutside);
            if (modalElement) {
                modalElement.removeEventListener('click', handleClickOutside);
            };
        };
    }, [dropdownRef, focus]);

    const renderDropdown = (items) => {
        return objEntries(items).map(([key, _item], ii) => {
            const item = toObj(_item);
            const ilock = item.lock;
            const style = (grouping && item.groupLast)? "group": "";
            const onClick = e => {cb && cb(key);};   //item.lock? undefined: ( e => { cb && cb(key); } );
            return <li onClick={ilock? undefined: onClick} key={key+ii} id={key} className={style + (ilock? ' disable': '')} role="button" >{item.t}</li>
        });
    };    
    const _listJsx = () => <ul ref={modalRef} className={`dropdown-content ${position}`} style={optionStyle}>{renderDropdown(items)}</ul>;

    return (
        <div {...{id, onClick: handleOpen, ref: dropdownRef}} className={"simple-dropdown " + className + (_enable?'':' unclickable ')} >
            <input {...{id, type: "text",  readOnly: true}} className={`dropdown-toggle ${(focus) ? "focus" : ""}`} />
            {noItem?<div onClick={undefined} key={"noItem"} id={"noItem"} className={'dropdown-text disable'} role="button" >{t("report-no-filter")}</div>
            :<div id={idx} className="dropdown-text overflow-hidden text-truncate ">{iconPath? <CpIco src={iconPath}/>: toStr(items[idx]?.t || placeHolder)}</div>}
            {focus && _listJsx()}
        </div>
    ) 
});





const CpDropdownV1 = (props) => { //base on poc Dropdown.tsx
    const {items = {}, idx, cb, iconPath, className, grouping, position='bottom', enable=1,
        optionStyle = {maxHeight:"400px", overflow:"auto"}} = props;
    const click = key => e => { cb(key); };
    //const onMouseDown = (e) => { e.preventDefault() };    onMouseDown={onMouseDown} 
    
    //{preJS({enable, idx, items},3)}
    return <div className={"simple-dropdown " + className + (enable?'':' unclickable')}>
        <input className="dropdown-toggle" type="text" readOnly={true}/>
        <div className="dropdown-text overflow-hidden text-truncate">{iconPath? <CpIco src={iconPath}/>: toStr(items[idx]?.t)}</div>
        <ul className={"dropdown-content "+position} style={optionStyle}>{objEntries({...items}).map(([key, item],ii) => {
            const onClick = item.lock? undefined: click(key);
            const style = (grouping && item.groupLast)? "group": "";
            return <li key={key+ii} role="button" onClick={onClick} className={style + (onClick? '': ' disable')} >{item.t}</li>
        })}</ul>
    </div>;
};

export default CpDropdown;

export const CpDropdownTest = (props) => { //base on poc Dropdown
    const [t] = useUILang();
    const items =  {
        a1: { t: 'a1', groupLast: 0, }, a2: { t: 'a2', groupLast: 1, },
        b1: { t: 'b1', groupLast: 0, }, b2: { t: 'b2', groupLast: 1, },
        c1: { t: 'c1', groupLast: 0, }, d1: { t: 'd1', groupLast: 0, }
    };
    const [idx, cb] = useState('a1');
    return <CpDropdown className="w100" {...{items, idx, cb, grouping:1, className:'more-dropdown'}} />;
};


