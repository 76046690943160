import { YearlyScore } from "../../../../type/ChartType";

export enum Position {
    Top = 'top',
    Left = 'left',
    Right = 'right'
}

interface CalculatePositionsOptions {
    MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX: number;
    THREE_SCORES_THRESHOLD: number;
}

const groupBy = <T,>(array: T[], predicate: (value: T) => string | number) =>
    array.reduce((acc, value) => {
        (acc[predicate(value)] ||= []).push(value);
        return acc;
    }, {} as { [key: string]: T[] });

export const calculatePositions = (
    scores: YearlyScore[],
    options: CalculatePositionsOptions
): Map<number, Position> => {
    const { MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX, THREE_SCORES_THRESHOLD } = options;
    const positionMap = new Map<number, Position>();
    const sortedScores = [...scores].sort((a, b) => a.scorePercentage - b.scorePercentage);

    // First pass: determine initial positions
    const handleInitialPositions = (): void => {
        sortedScores.forEach((score, index) => {
            if (index === 0 ||
                score.scorePercentage - sortedScores[index - 1].scorePercentage >= MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX) {
                positionMap.set(score.year, Position.Top);
            } else {
                const prevPosition = positionMap.get(sortedScores[index - 1].year);
                if (prevPosition === Position.Top || prevPosition === Position.Left) {
                    if (sortedScores.length === THREE_SCORES_THRESHOLD && score.year === 3 && (sortedScores[0].scorePercentage === sortedScores[1].scorePercentage)) {
                        positionMap.set(score.year, Position.Left);
                    } else {
                        positionMap.set(score.year, Position.Right);
                    }
                } else {
                    positionMap.set(score.year, Position.Left);
                }
            }
        });
    };

    // Second pass: handle special cases
    const handleSpecialCases = (): void => {
        const groups = groupBy(sortedScores, score => score.scorePercentage);
        Object.values(groups).forEach(group => {
            if (group.length === THREE_SCORES_THRESHOLD) {
                positionMap.set(group[0].year, Position.Left);
                positionMap.set(group[1].year, Position.Top);
                positionMap.set(group[2].year, Position.Right);
            }
        });

        // Handle case where three scores are very close to each other
        for (let i = 0; i < sortedScores.length - 2; i++) {
            const score1 = sortedScores[i];
            const score2 = sortedScores[i + 1];
            const score3 = sortedScores[i + 2];

            if (
                score2.scorePercentage - score1.scorePercentage <= MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX &&
                score3.scorePercentage - score2.scorePercentage <= MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX
            ) {
                positionMap.set(score1.year, Position.Left);
                positionMap.set(score2.year, Position.Top);
                positionMap.set(score3.year, Position.Right);
                i += 2; // Skip the next two iterations since we've handled these scores
            }
        }
    };

    // Special case 1: Yellow and Green close, Red higher
    // Special case 2: Yellow and Red close, Green higher
    const handleThreeScoresCase = (): boolean => {
        if (scores.length !== 3) return false;

        const [year1, year2, year3] = scores.sort((a, b) => a.year - b.year);
        const isYellowGreenClose = Math.abs(year1.scorePercentage - year3.scorePercentage) < MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX;
        const isRedHigher = year2.scorePercentage > year1.scorePercentage && year2.scorePercentage > year3.scorePercentage;
        const isRedNotClose = Math.abs(year2.scorePercentage - year1.scorePercentage) >= MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX &&
            Math.abs(year2.scorePercentage - year3.scorePercentage) >= MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX;

        if (isYellowGreenClose && isRedHigher && isRedNotClose) {
            if (year3.scorePercentage > year1.scorePercentage) {
                positionMap.set(year1.year, Position.Left);
                positionMap.set(year3.year, Position.Top);
            } else {
                positionMap.set(year1.year, Position.Top);
                positionMap.set(year3.year, Position.Left);
            }
            positionMap.set(year2.year, Position.Top);
            return true;
        }

        const isFirstTwoClose = Math.abs(year1.scorePercentage - year2.scorePercentage) < MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX;
        const isThirdHigher = year3.scorePercentage > year1.scorePercentage && year3.scorePercentage > year2.scorePercentage;
        const isThirdNotClose = Math.abs(year3.scorePercentage - year2.scorePercentage) >= MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX &&
            Math.abs(year3.scorePercentage - year1.scorePercentage) >= MIN_PERCENTAGE_DIFFERENCE_FOR_SCORE_BOX;

        if (isFirstTwoClose && isThirdHigher && isThirdNotClose) {
            if (year1.scorePercentage < year2.scorePercentage) {
                positionMap.set(year1.year, Position.Left);
                positionMap.set(year2.year, Position.Top);
            } else {
                positionMap.set(year1.year, Position.Top);
                positionMap.set(year2.year, Position.Left);
            }
            positionMap.set(year3.year, Position.Top);
            return true;
        }

        return false;
    };

    handleInitialPositions();
    handleSpecialCases();
    handleThreeScoresCase();

    return positionMap;
};