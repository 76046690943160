
import {Container} from "react-bootstrap";
import React, {useContext, useMemo, useState, useEffect} from "react";
import { isEn2Lang, useCtnLang, useUILang, validLang } from "../../AppExPf/utils/useUILang";
import CpDropdown, { ent2DropdownItem, obj2DropdownItem } from "../_components/CpDropdown";
import { _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED, _AST_WAIT_RESULT, _WST_SUBMIT,
    _QST_SUB_WRONG, _QST_SUB_CORR, _QST_SUB_ATT, _QST_SUB_NOTMARK, _QST_SUB_NOTATT } from "../../consts/ATConstsAssignment";
import { useMemoCurr, useMSetEntsByJ, useTypeByMSetEmts, useRptMSet, useMemoMSetEnts } from "./TabTeacherAssignmentReport";
import { objEntries, objKeys, objVals, strCmp, toAry, toInt, toObj, toStr, toUniAry } from "../../libs/libType";
import { _ATMetaTransByCode, _ATMetaTypeByCode } from "../../consts/ATMetaTypes";
import { mkEQSumFake } from "../../AppExPf/ATExerEdit/TabExerSetting";
import { pickSMByRemeLv, remeLv2SMIds } from "../../AppExPf/ATExerEdit/TabExerNextStep";
import { RemeLvALL, RemeLvPre, RemeLvPro, RemeLvSame } from "../../consts/ATValidateReme";
import { toIdStr, toUniIdAry } from "../../consts/ATValidate";
import ChartStuAsmReme from "./ChartStuAsmReme";
import { MSetSubTree, rptMName } from "../EPReports/data/dataUtil";
import { useMetaRAsmsByMSid } from "./TabStudentAssignmentReportResult";
import { splitSMId, splitSetMetaIds2D } from "../../consts/ATValidateMetaSet";
import { BtnDev, preJS, preJSUnsort } from "../../AppExPf/AppUtil";
import CpChartIndicatorGuideline, { CpChartIndicatorGuidelineX } from "../EPReports/_component/CpChartIndicatorGuideline";

const TabStuAsmRptReme = (props) => {
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const { myUId, asm, Exer, qtns, work, mark, remeAsms, qStats} = props;
    const {tagMSets, useTagMSets } = props;

    const EQSum = useMemo(() => mkEQSumFake(Exer, qtns), [Exer, qtns]);
    const [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore, EQMetas, EQMetaPres, EQMetaPros, minQs, ] = toAry(EQSum);

    const allSMId2d = useMemo(() => splitSetMetaIds2D(toUniIdAry(EQMetas)), [Exer]);
    const allMSets = useTagMSets(objKeys(allSMId2d));
    const stuMStats = useMemo(() => mkStuMStats(allMSets, qStats, qtns), [allMSets, qStats, qtns]); 

    //No User Reme By Q const [SMIdPairs, remeMSIds] = useMemo(() => pickSMByRemeLv(RemeLvALL, EQMetas, EQMetaPres, EQMetaPros), [EQSum]);

    //BSet By My Remes ==== All J / All Mset / All Lv 
    const {remeMSIds} = useMemo(() => {
        const remeSMIds = toUniIdAry(toAry(remeAsms).map(r => r.remeSMId));
        const remeSMPairs = remeSMIds.map(smid => splitSMId(smid));
        const remeMSIds = toUniIdAry(remeSMPairs.map(sm => sm[0]));
        return {remeSMIds, remeSMPairs, remeMSIds};
    }, [remeAsms]); //SM by Reme (all j, all mtype, all mset, all lv)
    const [remeMSets, remeMSetEnts] = useMemoMSetEnts(tagMSets, useTagMSets, remeMSIds); //Reme Mset 

    ////MSet Tpye By Reme Mset  ======
    const [remeMType, setRemeMType, typeMSets, RMSTypeDDI] = useTypeByMSetEmts(remeMSetEnts, uiEn, t); //Reme MSet Type 
    const [remeMSId, setRemeMSId, typeMSetEnts, RMSetDDI] = useRptMSet(remeMType, remeMSetEnts, uiEn, ut); //Reme MSet 1 Type

    // Splite Mset Reme To LVs =======
    const msPfx = toIdStr(remeMSId)+',';
    //No Use QSum --- const SMIdsByPfx = SMIds => toUniIdAry(SMIds).filter(i => i.startsWith(msPfx));
    //No Use QSum --- const [rSameSMIds, rPreSMIds, rProSMIds] = useMemo(() => [EQMetas, EQMetaPres, EQMetaPros].map(SMIdsByPfx), [EQSum, remeMSId]);
    const msetMixRemes = useMemo(() => toAry(remeAsms).filter(r => toStr(r.remeSMId).startsWith(msPfx)), [remeAsms, remeMSId]);
    const myLv2Remes = lv => msetMixRemes.filter(r => toAry(r.remeLvStus?.[lv]).includes(myUId));
    const msetLvReme2d = useMemo(() => Object.fromEntries([RemeLvSame, RemeLvPre, RemeLvPro].map(lv => [lv, myLv2Remes(lv)]), [msetMixRemes]));

    // Build LVs Opts By MSet Reme =======
    const remeLvEnts = useMemo(() => [  
        msetMixRemes.length && [RemeLvALL , t('assignment-status-option-follow-up-all')],
        (msetLvReme2d[RemeLvSame]).length && [RemeLvSame, t('assignment-status-option-follow-up-same')],
        (msetLvReme2d[RemeLvPre]).length && [RemeLvPre, t('assignment-status-option-follow-up-pre')],
        (msetLvReme2d[RemeLvPro]).length && [RemeLvPro, t('assignment-status-option-follow-up-pro')],
    ].filter(e => e), [msetMixRemes, uiEn]);
    const RLv2DDI = useMemo(() => ent2DropdownItem(remeLvEnts), [remeLvEnts]);
    const [rlv, setRemeLv] = useState();
    const remeLv = RLv2DDI[rlv]? rlv: remeLvEnts[0]?.[0];

    //  Reme By 1 MSet By 1 Lv ======= 
    const msetLvRemes = toAry((remeLv === RemeLvALL)? msetMixRemes: msetLvReme2d[remeLv]);  
    const metaAsms = useMemo(() => Object.fromEntries(msetLvRemes.map(r => [r.remeSMId, r])), [msetLvRemes]);
    const remeMIds = useMemo(() => toUniIdAry(objKeys(metaAsms).map(i => splitSMId(i)[1])), [metaAsms]);

    // Mset.Lv.Remes to Tree =======
    const mset = remeMSets?.[remeMSId];
    const {metas, tree2, ..._mset} = toObj(mset);
    const rptMTree = useMemo(() => MTreeForRemes(mset, remeMIds, ut), [mset, remeMIds, uiEn]);

    const chartProps = {...props, myUId, remeAsms, metaAsms, rptMTree, remeLv, stuMStats};
    return <div className="TabStuAsmRptReme report-result d-flex flex-column flex-xl-row align-items-stretch gap-3 mt-3">
        <div className="report-result-col result-col-right result-col-bottom">

            <div className={"border rounded-light h-100 p-3"}>

                <div className={"d-flex flex-column gap-2 flex-wrap mb-3"}>
                    <h4 className={"m-0"}>{t("report-follow-up-exercises")}</h4>
                    <div className={"d-flex gap-2 flex-wrap align-items-start justify-content-between"}>
                    <span className={"d-flex gap-2 flex-wrap flex-column flex-md-row"}>
                        <span className={"d-flex align-items-center semi-bold"}>{t("filters")}:</span>
                        <span style={{ width: "250px" }} className={"d-flex w-sm-100"}>
                            <CpDropdown id="DDremeMType" className="w100" {...{items:RMSTypeDDI, idx:remeMType, cb:setRemeMType }} />
                        </span>
                        <span style={{ width: "250px" }} className={"d-flex w-sm-100"}>
                            <CpDropdown id="DDremeMSId" className="w100" {...{items:RMSetDDI, idx:remeMSId, cb:setRemeMSId }} />
                        </span>
                    </span>
                    <span className={"gap-3 align-items-center"}>
                        <span className={`d-flex flex-column flex-md-row gap-2 align-items-start align-items-md-center`}>
                            <span className={"d-flex align-items-center semi-bold"}>{t("question-scope")}:</span>
                            <span className={`d-flex`} style={{width: "200px"}}>
                                <CpDropdown id="DDremeLv" className="w100" {...{items:RLv2DDI, idx:remeLv, cb:setRemeLv}} />                                
                            </span>
                        </span>                        
                    </span>
                    </div>
                </div>
                <div className={"d-flex flex-column gap-2 flex-wrap mb-3"}>
                    <CpChartIndicatorGuidelineX {...{passed:1, failed:1, master:1 }} />
                </div>
{preJSUnsort({remeMType, remeMSId, remeLv})}        
{preJSUnsort({remeMIds})}
<BtnDev txt="_mset">{preJS({_mset}, 3)}</BtnDev>
<BtnDev txt="mset.tree2">{preJS({tree2}, 3)}</BtnDev>
<BtnDev txt="mset.metas">{preJS({metas}, 3)}</BtnDev>
<BtnDev txt="rptMTree">{preJS({rptMTree}, 3)}</BtnDev>
<BtnDev txt="metaAsms_keys">{preJS({metaAsms_keys: objKeys(metaAsms)}, 3)}</BtnDev>
<BtnDev txt="metaAsms">{preJS({metaAsms}, 3)}</BtnDev>
{toAry(remeAsms).sort((a,b)=>strCmp(a.remeSMId, b.remeSMId)).map((r, i) => {
    return <BtnDev key={i} txt={ (1+i)+' '+r.remeSMId+' '+toUniIdAry(r.remeLvs).sort().join(', ')} >{preJS(r, 3)}</BtnDev>
})}
                <div className={"overflow-x mt-3"}><ChartStuAsmReme {...chartProps} /></div>
            </div>
        </div>
    </div>;
};
export default TabStuAsmRptReme;


export const MTreeForRemes = (mset, mids, ut) => {
    const msid = toIdStr(mset?.metaSetId);
    const metas = toObj(mset?.metas);
    //const mids = toUniIdAry(toAry(SMIds).map(i => splitSMId(i)[1])); 
    const [MRoot, MBranchs] = MSetSubTree(metas, mids, 1); 
//console.log('MTreeForRemes()', {mids, MRoot, MBranchs});
    const mkNode = mid => {
        const SMId = msid+','+mid;
        const children = mkNodes(MBranchs[mid]);
        const vis = children.length || mids.includes(mid); 
        return vis && {
            id: 'm*'+SMId,
            SMId,           
            title: rptMName(metas[mid], ut, mid),
            children,            
        };
    };
    const mkNodes = ids => toUniIdAry(ids).map(mkNode).filter(n => n);
    const rptMTree = mkNodes(MRoot);
    return rptMTree;
};
    
const mkStuMStats = (allMSets, qStats, qtns) => {
//console.log('useSumQMetaMarks', {allMSets});
    const MQIdxs = {};
    const addQidx2M = (SMId, q) => {
        const [msid, mid] = splitSMId(SMId);
        if(!(msid && mid)) return; 
        const m = allMSets[msid]?.metas?.[mid];
        if(!m) return;
        const s = MQIdxs[SMId] || [];
        if(!s.includes(q.qIdx)){
            s.push(q.qIdx);
            if(m.pMeta) addQidx2M(msid+','+m.pMeta, q)
        } 
        MQIdxs[SMId] = s;
    };

    qStats.forEach(q => { toUniIdAry(q.qMetas).forEach(SMId => addQidx2M(SMId, q)) });
    const stuMStats = Object.fromEntries(objEntries(MQIdxs).map(([smid, qidxs]) => {
        const s = {qCnt:0, uRight:0, qMark:0, uMark:0};
        qidxs.forEach(i => {
            const qs = qStats[i];
            if(!qs) return;
            const q = qtns[qs.qid];
            if(!q) return;
            s.qCnt++;
            if(qs.right) s.uRight++;
            s.qMark += q.score; 
            s.uMark += qs.mark; 
        });
        return [smid, s];
    }));
console.log('useStuMStats', {stuMStats});        
    return stuMStats;
};

/*
                <div className={"d-flex flex-column gap-2 flex-wrap mb-3"}>
                    <h4 className={"m-0"}>{t("report-follow-up-exercises")}</h4>
                    <div className={"d-flex gap-2 flex-wrap"}>
                        <span style={{width: "250px"}} className={"d-flex w-sm-100"}>
                            <CpDropdown id="DDremeMType" className="w100" {...{items:RMSTypeDDI, idx:remeMType, cb:setRemeMType }} /></span>
                        <span style={{width: "250px"}} className={"d-flex w-sm-100"}>
                            <CpDropdown id="DDremeMSId" className="w100" {...{items:RMSetDDI, idx:remeMSId, cb:setRemeMSId }} /></span>
                        <span style={{width: "280px"}} className={"d-flex w-sm-100"}>
                            <CpDropdown id="DDremeLv" className="w100" {...{items:RLv2DDI, idx:remeLv, cb:setRemeLv}} /></span>
                    </div>
                </div>
*/