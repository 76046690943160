// base on poc /type/Align.tsx
export const ALIGN = { START:"start", END:"end"};

// base on poc /type/PresentationMode.tsx
export const PresentationMode = {
    SLIDE_MODE: "SLIDE_MODE",
    SCROLL_MODE: "SCROLL_MODE",
    LIST_MODE: "LIST_MODE",
    COLUMN_MODE: "COLUMN_MODE"
};

// base on poc /type/SIZE.tsx
export const SIZE = { XXXS:"xxxs", XXS:"xxs", XS:"xs", SM:"sm", MD:"md", LG:"lg" };

// base on poc /type/Content/FormVariant.tsx
export const FORM_VARIANT = {
    SOLID:"solid",
    BORDER:"border",
    BORDERLESS:"border-less",
    PLAIN:"plain"
};

export const CHECKBOX_VARIANT = {
    ROUND:"round",
    SQUARE:"square",
    ROUND_BORDER:"round-border",
    SQUARE_BORDER:"square-border",
    ROUND_BORDER_DASHED:"round-border-dashed",
    SQUARE_BORDER_DASHED:"square-border-dashed"
};

// base on poc /type/PAGE.tsx
export const PAGE = {
    "EXERCISE_PAGE": "EXERCISE_PAGE",
    "SAVED_COLLECTION_SEARCH_PAGE": "SAVED_COLLECTION_SEARCH_PAGE",
    "EXERCISE_EDIT_PAGE": "EXERCISE_EDIT_PAGE",

    "LIBRARY_PAGE": "LIBRARY_PAGE",
    "LIBRARY_SEARCH_PAGE": "LIBRARY_SEARCH_PAGE",

    "ASSIGNMENT_LIST": "ASSIGNMENT_LIST",
    "ASSIGNMENT_EDIT_PAGE": "ASSIGNMENT_EDIT_PAGE",
    "ASSIGNMENT_REPORT": "ASSIGNMENT_REPORT",

    "TEACHER_REPORT": "TEACHER_REPORT",
    "STUDENT_PROGRESS_REPORT": "STUDENT_PROGRESS_REPORT"
}

export const TAB = {
    "EXERCISE_MY_EXERCISE": "EXERCISE_MY_EXERCISE",
    "EXERCISE_SAVED_COLLECTION": "EXERCISE_SAVED_COLLECTION",
    "LIBRARY_OUPC": "LIBRARY_OUPC",
    "LIBRARY_MY_SCHOOL": "LIBRARY_MY_SCHOOL",

    "ASSIGNMENT_LIST": "ASSIGNMENT_LIST",
}

export const FIRST_ACTION = {
    "OPEN_ASSIGN":"OPEN_ASSIGN",
    // "SWITCH_TO_EDIT_MODE": "SWITCH_TO_EDIT_MODE",
    // "SWITCH_TO_PREVIEW_MODE": "SWITCH_TO_PREVIEW_MODE"
};


// base on poc Assignment.tsx
export const MARKING_TYPE = {
    AUTO_MARKED:"auto-marked",
    MANUAL_MARKED:"manual-marked"
};

export const AssignmentProgress = {
    NOT_YET_STARTED:"not-yet-started",
    IN_PROGRESS:"in-progress",
    RESULT_PUBLISHED:"result-published",
    RESULT_PENDING:"result-pending",
    PENDING_MANUAL_MARK:"pending-manual-mark"
};

export const ASSIGNMENT_SETTING_TAB = {
    CLASS_STUDENT:"class-student",
    TIME:"time",
    BEHAVIOUR:"behaviour"
};
