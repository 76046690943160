import { useState } from 'react';

import { toAry, toObj } from '../../libs/libType';


import { apiCallLoad, apiCallLoad_ } from '../../libs/awsFuncs';
import { clickConfirm, mergePubsDrafts } from '../AppUtil';
import { useTickAll } from '../ATQtnEdit/PageATQtns';

export const useDraftPubList = (idKey, dispatch) => {
	const [list, setList] = useState({});
	const [err2d, setErr2d] = useState({});
	const ids = Object.keys(toObj(list));

	const [ticks, setTick, setTicks, tickAll, setTickAll, clearTicks] = useTickAll(ids);
	const cntTick = ticks?.length || 0;

	//const setTick = id => add => { setTicks(ts => arrayAddOrDelete_(ts, id, add)); (!add) && setTickAll(0); };
	const clickTickAll = on => { setTickAll(on); setTicks(on? toAry(ids): []); };

	const onLoad = (res, err) => {
		setList( mergePubsDrafts(toAry(res?.pubs), toAry(res?.drafts), idKey) );
		clearTicks();
	};
	const onPub = (res, err) => {
		setErr2d(toObj(res.fe2d));
		onLoad(res, err);
	};
	const confirmCall = (msg, api, cb, form) => clickConfirm(dispatch, msg, () => {
		setErr2d({});
		apiCallLoad_(dispatch, api, cb, form);
	});
	return [list, setList, toObj(err2d), setErr2d, ticks, cntTick, setTicks, setTick, tickAll, setTickAll, clickTickAll, onLoad, onPub, confirmCall, ids];
};

