import { useEffect, useState } from 'react';
import { _WebRoot } from '../../consts/ATConstReact';
import { _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, _ATMetaType_EX, _ATMetaType_GR, _ATMetaType_QT, _ATMetaType_TB } from '../../consts/ATMetaTypes';
import { apiCallLoad_, asyncApiCallLoad_ } from '../../libs/awsFuncs';

import { isAry, toAry, toObj } from '../../libs/libType';
import * as UI from '../../libs/libUI';
import { ReduxBind } from '../../saga/ReduxState';
import { ATHr, clickUrl, preJS } from '../../AppExPf/AppUtil';
import { addMath3StrToFullQ, addMaxAnsWidthToFullQ } from '../../AppExPf/utils/utilQtnAns';
import { deepCopy } from '../../AppExPf/AppUtil';

const PagePatch = ReduxBind(props => {
    const { dispatch } = props;
    const [converted, setConverted] = useState({});
    const [conCnts, setConCnts] = useState(0);
    const [conCntw, setConCntw] = useState(0);
    const [conQid, setConQid] = useState('');

    const [patchState, _setPatchState] = useState();
    const upPatchState = delta => _setPatchState(s => ({...toObj(s), ...toObj(delta)}));
    const {qidx, qids, err} = toObj(patchState);
    
    const [errs, _setErrs] = useState([]);
    const addErr = err => _setErrs(es => [...toAry(es), err]); 

    const [started, setStarted] = useState();

    const [qtn, setQtn] = useState();

    // clickstart -> onStart -> upPatchState(qidx) 
    // 1. -> useEffect(qidx) 
    // 2. -> loadQ -> onLoadQ -> setQtn -> useEffect(qtn)
    // 3. -> clickSaveQM -> onSaveQ -> upPatchState(qidx) -> 1.

    const onStart = (res, err) => {
        const state = toObj(res?.state);
        const qids = toAry(state.qids);
        //_setPatchState(qids);
        upPatchState({qidx:0, qids});
    };
    const clickStart = async (e) => { 
        UI.stopEvent(e); 
        //setStarted(1);
        await apiCallLoad_(dispatch, '/getPatchState', onStart, {step:'start'}); 
    };

    // clickStart() -> loop clickSaveQM()

    const len = isAry(qids)? qids.length: 0;
    useEffect(() => {
        if(qidx < len){
            loadQ(qids[qidx]);
        }
    }, [qidx, qids])

    useEffect(() => {
        if (qtn) {
            setConQid(conQid+', '+qtn.QId);
            clickSaveQM();
        };
    }, [qtn]);

    const onLoadQ = (res, err) => {
        if(err) addErr(err);
        setQtn(res.qtn);
    }
    const loadQ = async qid => { await apiCallLoad_(dispatch, '/getPatchState', onLoadQ, {step:'qtn', qid}); };

    const onSavePatchQ = (res, err) => {
        if(err){
            addErr(err);
        } else {
            upPatchState({qidx:(qidx+1)});
        };
    };

    /*
    const clickSaveQM_three_mathStr = async e => { UI.stopEvent(e); 
        const QId = toObj(qtn);
        const ret = await addMath3StrToFullQ(qtn);
        if (ret) setConCnt(conCnt+1);
        setConverted(deepCopy(qtn));
        await apiCallLoad_(dispatch, '/getPatchState', onSaveQ, {step:'saveQtn', QId}); 
    };*/

    const clickSaveQM = async e => { UI.stopEvent(e); 
        //const QId = toObj(qtn);
        const rets = await addMath3StrToFullQ(qtn);
        if (rets) setConCnts(conCnts+1);
        const retw = addMaxAnsWidthToFullQ(qtn);
        if (retw) setConCntw(conCntw+1);        
        setConverted(deepCopy(qtn));
        await apiCallLoad_(dispatch, '/getPatchState', onSavePatchQ, {step:'saveQtn', qtn}); 
    };    

    const boxStyle1 = {border:'1px solid lightgrey',width:'100%', alignSelf:'center',
        maxHeight:'30%', overflow:'auto'};
    const boxStyle2 = {...boxStyle1, flex:1, maxHeight:'60%', height:'60%'};
    return <div className='PagePatch flexRowStart' style={{width:'100%',maxHeight:'100vh',gap:'5px'}}>
        <div className='flexColStart' style={{width:'48%'}}>
            <div className='flexRowCenter'><h1>PagePatch</h1></div>
            <div style={boxStyle1}>
                {preJS({len, qidx},3)}
            </div>
            <div className='flexRowCenter f18 bold' style={{wordBreak: 'break-all'}}>
                <span>{'next QTN: '}</span><span style={{color:'green'}}>{qtn?.QId}</span></div>
            <div style={boxStyle2}>
                <div>{preJS(qtn,3)}</div>
            </div>
            <div className='flexRowCenter' style={{margin:'5px',gap:'5px'}}>
                {false && UI.Button0('Save Qtn Math', clickSaveQM, 'bs')}
                {started
                    ? 'Started Running Patch...'
                    : UI.Button0('Start Patching (update all questions)', clickStart, 'bs')
                }</div>
            <div className='flexRowCenter'>{preJS(errs,3)}</div>
        </div>
        <div className='flexColStart' style={{width:'48%'}}>
            <div className='flexRowCenter f18 bold' style={{wordBreak: 'break-all'}}>
                <span>{'QTN checked: '}</span><span style={{color:'blue'}}>{converted?.QId}</span></div>
            <div style={boxStyle2}>
                {preJS(converted,3)}
            </div>
            <div className='flexRowCenter'><h4>{'QTN (FIB/LBT) math count: '+ conCnts}</h4></div>
            <div className='flexRowCenter'><h4>{'QTN (FIB) width count: '+ conCntw}</h4></div>
            <div className='flexRowCenter f12' style={{wordBreak: 'break-word'}}>{conQid}</div>
        </div>
    </div>;
});

export default PagePatch;

/*
    return <div className='tac p4 m4'>
        <h1>PagePatch</h1>
        <div className='tal p4 m4'>
            {preJS({len, qidx},3)}
        </div>
        <div className='p4 m4 box1' >
            <h4>QTN</h4>
            <div>{preJS(qtn,3)}</div>
        </div>
        {UI.Button0('Save Qtn Math', clickSaveQM, 'bs')}
        <hr/>
        {started
            ? 'Started Running Patch...'
            : UI.Button0('Start Patching', clickStart, 'bs')
        }
        {preJS(errs,3)}
    </div>;
*/
