import React, {  } from 'react';

import {Provider} from 'react-redux'
import {createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects'

import { /*watchAuthSaga,*/ authReducer } from "../saga/auth.saga";
import { watchApiCBSaga, apiCBReducer } from "../saga/apiCB.saga";
import { watchUrlPushSaga, urlPushReducer } from "../saga/urlPush.saga";
import { loadingReducer } from "../saga/loading.saga";
import { globalStateReducer } from "../saga/globalState.saga";
import { langReducer } from "../saga/lang.saga";
import {MathJaxContext} from "better-react-mathjax";
import AppLayout from './AppLayout';
import { ReduxState } from '../saga/ReduxState';
import CpCookieBanner from '../AppExPFUser/_components/CpCookieBanner';

//const Reducer = ReduxState;//combineReducers({ReduxState})
const Reducer = combineReducers({
  apiCB: apiCBReducer,
  loading: loadingReducer,
  urlPush: urlPushReducer,
  lang: langReducer,
  auth: authReducer,
  gst: globalStateReducer,
  ReduxState: ReduxState,
});

const sagaMiddleware = createSagaMiddleware();

const _store = createStore(
  Reducer, 
  compose(
    applyMiddleware(
      sagaMiddleware, 
    ),
  )
);
function* rootSaga() {
  yield all([
    //fork(watchAuthSaga),
    fork(watchApiCBSaga),
    fork(watchUrlPushSaga),
  ]);
};
sagaMiddleware.run(rootSaga); //(ReduxSaga1);

window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', process.env.REACT_APP_GA_ID);

/*
lj({TheREACT_APP_WEBROOT:process.env.REACT_APP_WEBROOT});
lj({TheREACT_APP_CFG:process.env.REACT_APP_CFG});
lj({process});
*/

const AppExPf = ((props) => {
  return (
    <MathJaxContext>
    <Provider store={_store}>
        <AppLayout />
        <CpCookieBanner />
    </Provider>
    </MathJaxContext>
  );
});
export default AppExPf;