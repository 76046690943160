import { useMemo } from "react";
import CpATQtnSingle from "./CpATQtnSingle";
import { getUUID } from "../../AppExPFUser/_components/CpGetUUID";

export const CpATQtnDisplayOnly = (props) => {
    // require Q, fullQ, useMedia
    const { exClass='', exStyle={}, fullQ={}, Q={}, mini=0 } = props;
    const myID = useMemo(()=>getUUID(),[fullQ,Q]);
    return <div key={'qtnDis'+myID} className={'flexColCenterFit ' + exClass} style={{backgroundColor:'#eff1fd', ...exStyle}}>
        <CpATQtnSingle {...props} doWhat={'view'} editable={0} PVMode={2} displayIdx={0} idx={myID}
        showQMark={1} mini={mini}/>
    </div>;
};
