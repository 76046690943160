
import { toStr } from '../libs/libType';
import { _ATRoleRBasic, _ATRoleApprover, _ATRoleSuper} from './ATConsts'

export const _ATRoleNames = {
    [_ATRoleSuper] :"SysADmin",
    [_ATRoleApprover] :"Approver",
    [_ATRoleRBasic] : "Basic",
};

export const _WebRoot = process.env.REACT_APP_WEBROOT;
export const __ATRoot = _WebRoot+'internal/at';
export const _ATRoot = __ATRoot+'/';
export const __AdminRoot = _WebRoot+'internal/admin'
export const _AdminRoot = __AdminRoot+'/';

export const _EXPFRoot = _WebRoot+'home/';

export const _LibraryRoot = _WebRoot + 'library';
export const _ExerciseRoot = _WebRoot + 'myexercise';
export const _CollectionRoot = _WebRoot + 'mycollection';
export const _AssignmentRoot = _WebRoot + 'assignment';
export const _ReportRoot = _WebRoot + 'report';

const st = a =>toStr(a).trim();
const p = (a, b) => a + (a && b && ('/'+b));

export const _pathOupEx = (clang, ESetId, EId) =>  p(_LibraryRoot + '/oup', p(st(clang),  p(st(ESetId), st(EId))));
export const _pathEcol = EColId => p(_CollectionRoot, EColId);
export const _pathAssign = (asmId, view) =>  p(_AssignmentRoot, p(st(asmId), st(view)));
export const _pathReport = () => _ReportRoot;//p(_ReportRoot, p(st(asmId), st(view)));

export const _pathMyExer = (EId) =>  p(_ExerciseRoot, st(EId));
