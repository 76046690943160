import React, { useEffect, useState } from 'react';
import * as UI from '../../libs/libUI';
import { svgIcon2, iconButton } from '../components/CpATIcoBtn';

export const MTKeywords = (props) => {
    const { setQ, Q, editable } = props
    const [value, setValue] = useState("");
    const keywords = Q.keywords?Q.keywords:[];

    const updateQ = (func, d) => { func && func(d) };
    const keyExist = (v) => keywords.includes(v);
    const deleteKey = (ii) => {
        keywords.splice(ii, 1);
        updateQ(setQ('keywords'), [...keywords]);
    };
    const addKey = (v) => {
        
        updateQ(setQ('keywords'), [...keywords, v]);
        setValue('');
    };
    const addClick = (e) => {
        UI.stopEvent(e);
        
        if (value.trim() !== "") {
            if (!keyExist(value)) addKey(value);
        };
    };

    const renderKeyword = (key, ii) => {
        return <div key={key+ii} className='quesKeywordItem'>
            <div style={{marginLeft:'4px'}}>{key}</div>
            <div className='flexRowCenterFit clickable' onClick={()=>deleteKey(ii)}>{svgIcon2('general/discard', '#2995cd')}</div>
        </div>
    };

    return <>
        <div key='kwSet' className={'VMenuTab ' + (editable?'':' unclickable')}>
            <div className='quesKeyWord'>
                {UI.EpInputTxt0(value, setValue, "kwid", "", { flex: 1, border: "none", outline: "none" })}
                {iconButton("", "labelDiagram/add", "white", "#2995cd",
                    addClick, true, {}, { borderRadius: "0px 5px 5px 0px" })}
            </div>
            <div className='quesKeyWordList'>
                {keywords.map((key, ii)=>{
                    return renderKeyword(key, ii);
                })}
            </div>
        </div>
    </>
};

export default MTKeywords;