import {Form} from "react-bootstrap";
import {useMemo} from "react";
import { PREFIX } from "./CpPrefix";
import { SIZE } from "./CpExPFConst";

const CpSwitchComponent = (props) => {  // base on poc SwitchComponent.tsx
    const {size = SIZE.MD, variant = "", className = "", ...switchProps} = props;
    const switchStyle = useMemo(() => {
        const gapStyle = (size === SIZE.XS || size === SIZE.SM || size === SIZE.MD) ? "gap-2" : "gap-3";
        return [
            `switch-component`,
            `d-flex`,
            `justify-content-center`,
            `align-items-center`,
            gapStyle,
            `${variant ? `cus-switch-${variant}` : ""}`,
            PREFIX.SWITCH + size,
            className].filter(item => item).join(' ')
    }, []);

    
    return <Form.Check type="switch" className={switchStyle} readOnly={true} {...switchProps} />
};

export default CpSwitchComponent;