const CpCheckbox = (props) => { // base on poc Checkbox.tsx
    const {disableOnClick = false, onChange:_onChange, checked:_checked, partial = false, ...checkboxProps} = props;

    const onChange = e => { _onChange(e); };
    const checked = _checked;

    return <span className={"simple-checkbox position-relative"}>
        <label className={`control control--checkbox ${disableOnClick ? "pe-none" : ""}`}>
        <input type="checkbox" {...{...checkboxProps, checked, onChange}} />
        <div className={`control__indicator ${partial ? "partial" : ""}`}></div>
    </label>
    </span>
};
export default CpCheckbox;