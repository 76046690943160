import { timeStampNowGMT } from "../libs/libTime";
import { toObj, toStr, toInt, isObj} from "../libs/libType";
import { _MaxLenTodo } from "./ATConsts";
import { _ARS_CLOSE_RESET } from "./ATConstsAssignment";
import { toIdStr, toUniIdAry, validFields, validMinMax } from "./ATValidate";

export const normalizeAsm = (fields, opts, trace) => {
    //const track = o => trace && trace.push(o);
    //track('-- normalizeAsm --');

    const fs = toObj(fields);
    
    return {
        assignId: toIdStr(fs.assignId),

        //worker
        year: toIdStr(fs.year),
        classId: toIdStr(fs.classId),
        studentIds: toUniIdAry(fs.studentIds),
        
        //Time Setting
        timeStart: fs.timeStart? 1: 0,
        timeEnd: fs.timeEnd? 1: 0, 
        time2beStart: fs.time2beStart?toInt(fs.time2beStart):timeStampNowGMT(), 
        time2beEnd: fs.time2beEnd?toInt(fs.time2beEnd):timeStampNowGMT(), 

        limitMin: fs.limitMin? 1: 0, 
        minsToGo: toInt(fs.minsToGo), 
        attempt: fs.attempt? 1: 0,
        attTimes: toInt(fs.attTimes), 
        
        //Result Setting
        pubResults: !fs.pubResults?0:1, 
        showResults: toStr(fs.showResults), 
        showScores: toStr(fs.showScores), 
        //showAnswers: toStr(fs.showAnswers), 

        //Content Ex
        EId: toIdStr(fs.EId),
        EQIds: toUniIdAry(fs.EQIds),
        Exer: isObj(fs.Exer)? fs.Exer: '',

        //Content Mis
        ALang: toStr(fs.ALang), 
        AMode: toStr(fs.AMode),
        AHints: fs.AHints? 1: 0, 
        ARand: fs.ARand? 1: 0, 
        AResume: fs.AResume? toStr(fs.AResume) : _ARS_CLOSE_RESET, 
        APass: fs.APass? 1: 0,  
        passValue: toInt(fs.passValue), 
        passUnit: toStr(fs.passUnit),  

        AState: toIdStr(fs.AState),
        datePub: toInt(fs.datePub),

        //Editing
        isNew: fs.isNew? 1: 0,
        edited: fs.edited? 1: 0,
    };
};

export const validateAsm = (fs, opts, trace, fields) => {
    const track = o => trace && trace.push(o);
    track('-- validEditAsm --');
 
    const v = validFields(fs, {});
    //Settings
    //v.setIf('EId', validMinMax(fs.EId, 1, _MaxLenTodo, 'Exercise ID'));
    //v.setIf('EQIds', (!fs.EQIds.length) && 'Missing Questions');
    v.setIf('classId', validMinMax(fs.classId, 1, _MaxLenTodo, 'classId ID'));
    v.setIf('studentIds', (!(fs.studentIds.length)) && 'Missing Students');
    v.setIf('endTimeXb4Str', fs.timeStart && (fs.time2beStart > fs.time2beEnd) && fs.timeEnd && 'End time must be later than Start time');
    v.setIf('endTimeXb4Now',  (timeStampNowGMT() > fs.time2beEnd) && fs.timeEnd && 'End time must be later than Current time');
    v.setIf('timeLimit', ((fs.limitMin && !fs.minsToGo)) && 'Missing Time Limit mins');
    v.setIf('mins2Go', ((fs.limitMin && fs.minsToGo < 0)) && 'value must be greater than 0');
    v.setIf('timesAtt', ((fs.attempt && !fs.attTimes)) && 'Missing No. of Attempt');
    v.setIf('times2Att', ((fs.attempt && fs.attTimes < 0)) && 'value must be greater than 0');
    v.setIf('exMode', (!(fs.AMode)) && 'Missing EX Mode');
    //v.setIf('passGrade', ((fs.APass && !(fs.passValue || fs.passUnit))) && 'Missing Passing Grade value');    
    v.setIf('value2Pass', ((fs.APass && (fs.passValue < 0))) && 'pass value must be not less than 0');
    v.setIf('range2Pass', ((fs.APass && (fs.passUnit === 'p') && fs.passValue > 100)) && 'pass value should between 0 to 100%');
    //v.setIf('testing', ('testing - Missing Students - testing'));

    track('-- validEditAsm end --');
    return v.fieldErrs;
};

export const asmIsReme = asm => (asm.pAsmId || asm.remeSMId)? 1: 0;
