import React from 'react';
import { toObj, toStr } from '../../libs/libType';
import { subState } from '../utils/useChain';
import ATSwitch from '../components/Switch0';
import VMenu from '../components/CpVMenu';
import { iconButton } from '../components/CpATIcoBtn';
import {_optType, _alignClass, DisplayTitle, ContentSize, RadioOptions, SpaceL} from './ViewExOptions';
import { _ExCtType_CK, _ExCtType_Img, _ExCtType_Lnk, _ExCtType_Mp3, _ExCtType_Qtn, 
    _ExCtType_Txt, _ExCtType_Video } from '../../consts/ATValidateEcnts';
import { useUILang } from '../utils/useUILang';

const LnkSetting = props => {
    const { showEn, ECtn, replaceECtn } = props; 
    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
    const lang = showEn?'en':'ct';    
    const displayTitle = ec.displayTitle?ec.displayTitle:0;
    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));

    return <div className='VMenuTab'>
        <div style={{ width: "96%" }}><SpaceL/>
            <DisplayTitle val={displayTitle} field={'displayTitle'} cb={setTmpField} /><SpaceL/>
        </div>
    </div>
};

const AudioSetting = props => {
    const { showEn, ECtn, replaceECtn } = props; 
    const [ t ] = useUILang();

    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
    const lang = showEn?'en':'ct';    
    const displayTitle = ec.displayTitle?ec.displayTitle:0;
    const audioType = toStr(ec.audioType);
    const audioUI = toStr(ec.audioUI);
    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));
    
    return <div className='VMenuTab'>
        <div style={{ width: "96%" }}><SpaceL/>
            <div className='flexRowStart'>{t('at-setting.exercise.sourceType')+':'}</div><SpaceL/>
            <RadioOptions val={audioType} field={'audioType'} cb={setTmpField} types={_optType.audio}/><SpaceL/>
            <DisplayTitle val={displayTitle} field={'displayTitle'} cb={setTmpField} /><SpaceL/>
            <div className='flexRowStart'>{t('at-setting.exercise.audioInterface')+':'}</div><SpaceL/>
            <RadioOptions val={audioUI} field={'audioUI'} cb={setTmpField} types={_optType.audioUI}/><SpaceL/>
        </div>
    </div>
};


const VideoSetting = props => {
    
    const { isAT, showEn, ECtn, replaceECtn } = props; 
    const [ t ] = useUILang();

    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
    const lang = showEn?'en':'ct';    
    const displayTitle = ec.displayTitle?ec.displayTitle:0;
    const videoType = toStr(ec.videoType || 'yt');
    const myWidth = toStr(ec.width);
    const myHeight = toStr(ec.height);
    const myAlign = toStr(ec.align);

    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));

    return <div className='VMenuTab'>
        <div style={{ width: "96%" }}><SpaceL/>
            <div className='flexRowStart'>{t('at-setting.exercise.sourceType')+':'}</div><SpaceL/>
            <RadioOptions val={videoType} field={'videoType'} cb={setTmpField} types={_optType.video}/><SpaceL/>
            <DisplayTitle val={displayTitle} field={'displayTitle'} cb={setTmpField} /><SpaceL/>
            <ContentSize v1={myWidth} v2={myHeight} f1={'width'} f2={'height'} cb={setTmpField} /><SpaceL/>
            <div className='flexRowStart'>{t('at-setting.exercise.layout')}</div><SpaceL/>
            <RadioOptions val={myAlign} field={'align'} cb={setTmpField} types={_optType.align}/><SpaceL/>
            {isAT?<div style={{border:'1px solid grey', wordWrap:'break-word', userSelect:'text'}}>
            For testing Sample URL:
            <div><span style={{color:'red'}}>Upload:</span><span style={{color:'blue'}}>http://localhost:3000/media/sample.mp4</span></div>
            <div><span style={{color:'red'}}>Brightcove:</span><span style={{color:'blue'}}>https://players.brightcove.net/6144772950001/default_default/index.html?videoId=6292873807001</span></div>
            </div>:''}     
        </div>
    </div>
};

const TxtSetting = props => {
    const { showEn, ECtn, replaceECtn } = props; 
    const [ t ] = useUILang();
    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
     
    const lang = showEn?'en':'ct';    
    const myAlign = toStr(ec.align);
    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));

    return <div className='VMenuTab'>
        <div style={{ width: "96%" }}><SpaceL/>
            <div className='flexRowStart'>{t('at-setting.exercise.layout')}</div><SpaceL/>
            <RadioOptions val={myAlign} field={'align'} cb={setTmpField} types={_optType.align}/>
        </div>
    </div>
};

const ImgSetting = props => {
    const { showEn, ECtn, replaceECtn } = props; 
    const [ t ] = useUILang();

    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
    const lang = showEn?'en':'ct';    
    const displayTitle = ec.displayTitle?ec.displayTitle:0;

    const enlarge = ec.enlarge?ec.enlarge:0; 
    const myWidth = toStr(ec.width);
    const myHeight = toStr(ec.height);
    const myAlign = toStr(ec.align);

    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));

    return <div className='VMenuTab'>
        <div style={{ width: "96%" }}><SpaceL/>
            <DisplayTitle val={displayTitle} field={'displayTitle'} cb={setTmpField} /><SpaceL/>
            <div className='flexRowBetween'>
                <div>{t('at-setting.exercise.clickToEnlarge')}</div>
                <div><ATSwitch id='enlarge' leftText='' rightText='' disabled={false}
                selected={enlarge} leftValue={0} rightValue={1}
                setter={setTmpField('enlarge')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
            </div><SpaceL/>      
            <ContentSize v1={myWidth} v2={myHeight} f1={'width'} f2={'height'} cb={setTmpField} /><SpaceL/>
            <div className='flexRowStart'>{t('at-setting.exercise.layout')}</div><SpaceL/>
            <RadioOptions val={myAlign} field={'align'} cb={setTmpField} types={_optType.align}/>
        </div>
    </div>
};

const NoSetting = (props) => { return <div></div>};
const myMenu = {
    [_ExCtType_Lnk]: LnkSetting,
    [_ExCtType_Mp3]: AudioSetting,
    [_ExCtType_Video]: VideoSetting,
    [_ExCtType_Img]: ImgSetting,
    [_ExCtType_Txt]: TxtSetting,
    [_ExCtType_Qtn]: NoSetting,
    [_ExCtType_CK]: NoSetting,
};

const ViewExCtnSetting = (props) => {
    const {vis, setVis, whoami, showEn} = props;
    const [ t ] = useUILang();
    if (whoami===_ExCtType_CK || whoami===_ExCtType_Qtn) return '';

    const MyJsx = myMenu[whoami];
    const menuArr = [{ name: t('setting'), jsx: <MyJsx {...props}/> },];

    let bc = ' navsidebackR' + (vis?'':' d-none');
    let b = ' navsideR'+ (vis?'':' invis');
    return <React.Fragment key={whoami+showEn}>
        <div className='ExOptionsTop'>
        <VMenu arr={menuArr} width={"300px"} height={"100%"} exClass={'f16'} expands={[0]}/>
        </div>
        <div className='' >
            <div className={bc} onClick={()=>setVis(0)} />
            <nav className={b} style={{paddingBottom:'50px', minWidth:'300px'}}> 
                <div className='flexRowEnd'>{iconButton('','general/close','grey','transparent',
                    ()=>setVis(0),true,{width:'30px',height:'30px'},{padding:0})}</div>
                <VMenu arr={menuArr} width={"300px"} height={"100%"} exClass={'f16'} expands={[0]}/>
            </nav>
        </div>
    </React.Fragment>
};

export default ViewExCtnSetting;
