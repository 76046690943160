import React, { useEffect, useState } from 'react';

import * as UI from '../../libs/libUI';
import { isAry, strCmp, toAry, toStr, } from '../../libs/libType';
import { apiCallLoad_, asyncApiCallLoad_, } from '../../libs/awsFuncs';

import { ReduxBind } from '../../saga/ReduxState';
import CpATSubjectCombo from '../components/CpATSubjectCombo';
import { extToMime } from '../../consts/MimeTypes';
import { ATPaging, usePaging } from '../utils/ATPaging';
import { BtnPopDev, preJS, aTUIMust, aTUITime, clickConfirm } from '../AppUtil';
import { arrayAddOrDelete } from '../../libs/libArray';
import { _ATRoleApprover } from '../../consts/ATConsts';
import CpATIcoBtn, { cpATIcoBtn_ } from '../components/CpATIcoBtn';
//import { toComparators } from 'semver';
import { iconButton } from '../components/CpATIcoBtn';
import { asyncDL } from '../utils/useMediaCache';
import { humanFileSize } from '../../libs/libFormat';

const TabMediaDown = ReduxBind(props => {
    const { dispatch, ATSubjects, mySjIds, onAdd, clickClose, mediaDLs, getMediaDLs, maxMedia, mimes, mexts} = props;
    const myLogin = toStr(props?._saga?.auth?.ATUser?.ATLogin);
    const isApprover = (_ATRoleApprover === props?._saga?.auth?.ATUser?.ATRole);
    

    const mySjs = ATSubjects.filter(s => mySjIds.includes(s.ATSubjectId));

    const [allMedias, setAllMedias] = useState([]);
    const [medias, setMedias] = useState([]);
    const [pageMedias, setPageMedias] = useState([]);

    const mediaCnt = toAry(medias).length;
    const paging = usePaging(mediaCnt, 5, 1); 
    const { page, rpp, totalRow, totalPage, res1, res2, setPaging } = paging;
    useEffect(() => { setPaging(paging) }, [mediaCnt] );

    const [sid, setSid] = useState();
    const [by, setBy] = useState(0);
    const [sort, setSort] = useState();
    const [txt, setTxt] = useState('');

    const [ticks, setTicks] = useState([]);
    const setTick = id => on => setTicks(ts => {
        return (maxMedia === 1)?
            (on?[id]:[]):
            ((!maxMedia) || (!on) || (maxMedia > ts.length))? 
                arrayAddOrDelete(ts, id, on): 
                ts
    });

    const mediaIds = toAry(pageMedias).map(m => m.mediaId);
    getMediaDLs(mediaIds);

    const reFilter = all => {
        const fall = sortMedia(filterMedia(all, sid, by, txt, myLogin, mexts, mimes), sort);
        setMedias(fall);
        paging.setPaging({page: 1});
    };

    const onLoad = (res, err) => setAllMedias(toMediaAry(res?.medias));
    const reload = () => apiCallLoad_(dispatch, '/getATMedias', onLoad, {});
    useEffect(() => { reload(); }, []);

    useEffect(() => { setPageMedias(medias.slice(res1 - 1, res2)); }, [medias, res1, res2]);
    useEffect(() => { setTicks([]); }, [pageMedias]);
    useEffect(() => { reFilter(allMedias); }, [allMedias, sid, by, sort]);

    const clickDel = mid => clickConfirm(dispatch, 'Confirm delete media', async () => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/deleteATMedias', {mediaIds:[mid]});
        const dmids = new Set(toAry(res.delMedias));
        const all = allMedias.filter(m => !dmids.has(m.mediaId));
        setAllMedias(all);
    });

    const clickReload = e => { UI.stopEvent(e); reload(); }; 
    const clickFind = e => { UI.stopEvent(e); reFilter(allMedias); };
    const tickCnt = ticks.length;
    const clickAdd = e => { onAdd(mWithD(allMedias, mediaDLs, ticks)); clickClose(e); }

    //<div>{preJS({ sid, by, sort, txt })}</div>
    //<div>{preJS(myLogin,3)}</div>
    //<div>{preJS(pageMedias[0],3)}</div>
    return <>
        <div className="fxGrow w100" style={{overflow:"auto"}}>
            <div className='flexRowStart f14' style={{marginBottom:"5px"}}><div>Select File(s){aTUIMust}</div></div>
            <div className='flexRowStart f14' style={{flexWrap:'wrap', marginBottom:"5px"}}>
                <div className='flexRowStartFit m4'>Subject: {CpATSubjectCombo(mySjs, sid, setSid, 0)}<div style={{width:"10px"}}/></div>
                <div className='flexRowStartFit m4'>By: {UI.Combo0(by, setBy, [[0, 'All'], ['me', 'Me']])}<div style={{width:"10px"}}/></div>
                <div className='flexRowStartFit m4'>Sort: {UI.Combo0(sort, setSort, [
                    ['dd', 'Upload Date (Descending)'], ['da', 'Upload Date (Ascending)'],
                    ['na', 'File Name (0-9, A-Z)'], ['nd', 'File Name (Z-A, 9-0)'],
                    ['sd', 'File Size (Descending)'], ['sa', 'File Size (Ascending)'],
                ])}<div style={{width:"10px"}}/></div>
                <div className='flexRowStartFit m4'>{UI.EpInputTxt0(txt, setTxt, 'txt', '', {marginRight:'5px'}, 'Please enter file name / title to search')}{'  '}
                {UI.Button0('Find', clickFind, 'btnfind',)}</div>
                {cpATIcoBtn_('general/reload', 'Reload', clickReload)}
            </div>
            <div className='flexRowBetween f14' style={{marginBottom:"5px"}}>
                <div>Results ( Total: {medias.length} )</div><div>Selected: {tickCnt}</div>
            </div>
            <div className='medFileList'>
                {toAry(pageMedias).map((m, i) => <CpMediaItem key={m.mediaId} {...{dispatch, getMediaDLs, m, ticks, setTick, clickDel, isApprover, myLogin }} />)}
            </div>
        </div>
        <ATPaging {...{ paging }} />
        <div className='flexRowEnd '>
            {UI.colorButton('medAdd', 'Add', clickAdd, '#2995cd', '100px', { marginLeft: "5px" }, '', tickCnt)}
        </div>
    </>
});
export default TabMediaDown;

const CpMediaItem = (props => {
    const { m, ticks, setTick, clickDel, isApprover, myLogin, dispatch, getMediaDLs} = props;
    const { mediaId, size, name, ext, dateMod} = m || {};
    const userLogin = (m.userLogin || m.useLogin); 
    
    const on = ticks.includes(mediaId);
    const flip = setTick(mediaId); 
    const mime = toStr(extToMime(ext));
    const s3dl = getMediaDLs([mediaId])[mediaId]?.dl;
    const candel = isApprover || (myLogin === userLogin);
    const clickDel_ = (candel && clickDel(mediaId)) || undefined;
    const clickDL = e => { UI.stopEvent(e); asyncDL(dispatch, getMediaDLs, mediaId); }; 

    return <div onClick={e => flip(!on)} className={'mediaItem '+('subjectItem'+(on? 'Sel': ''))}>
        <div className='flexRowBetween ' >
            <div className="fxGrow tal" >
                {UI.CheckBox0(on, flip, 'chk' + mediaId, 0, 'w vam m4')}
                <div className="w vam">{cpATIcoBtn_('draw/download', '', clickDL)}</div>
            </div>
            <div className="w vam">{cpATIcoBtn_('general/trash', '', clickDel_, {height:'18px'})}</div>
        </div>
        <div className='mediaItemImgBox w100'>
            {/*(mime.startsWith('image') && s3dl)? <img className='mediaItemImg' alt='media' src={s3dl} crossOrigin='anonymous'/>: ''*/}
            {(mime.startsWith('image') && s3dl)? <img className='mediaItemImg' alt='media' src={s3dl}/>: ''}
        </div>
        <div className='f12' style={{width:'100%', margin:'auto', wordBreak:'break-word', overflow:'auto'}}>
            <div><b>{name}</b></div>
        </div>
        <div className="f10">ID: <b>{mediaId}</b></div>
        <div className='flexRowBetween f10'>
            <div className="f10">By: <b>{userLogin}</b></div>
            <div>{mime}</div>
        </div>
        <div className='flexRowBetween f10'>
            <div>{aTUITime(dateMod)}</div>
            <div>{humanFileSize(size)}</div>
        </div>
    </div>
});

const filterMedia = (medias, sid, by, txt, myLogin, mexts, mimes) => {
    
    
    const chkMime = ext => {
        const mm = toStr(extToMime(ext));
        return mimes.some(mi => mm.startsWith(mi));
    };
    const extset = mexts && (new Set(mexts));
    const chkMExts = ext => extset && (extset.has(toStr(ext).toLowerCase()));
    
    const tlc = toStr(txt).trim().toLowerCase();
    const [nome, nosid, notxt ] = [(by !== 'me'), !sid, !tlc];
    
    
    const fts = medias.filter(m => (
        (nome || (m.useLogin === myLogin) ||(m.userLogin === myLogin) ) &&
        (nosid || toAry(m.ATSubjs).includes(sid)) &&
        (notxt || m.nameLC.includes(tlc)) && 
        ((!mexts) || chkMExts(m.ext)) &&
        ((!mimes) || chkMime(m.ext))
    ));
    
    return fts;
};

const sorters = {
    nd: (a, b) => (strCmp(a.nameLC, b.nameLC) || (b.dateMod - a.dateMod)),
    na: (a, b) => (-strCmp(a.nameLC, b.nameLC) || (a.dateMod - b.dateMod)),
    sa: (a, b) => ((a.size - b.size) || (b.dateMod - a.dateMod)),
    sd: (a, b) => ((b.size - a.size) || (b.dateMod - a.dateMod)),
    dd: (a, b) => ((b.dateMod - a.dateMod) || strCmp(a.nameLC, b.nameLC)),
    da: (a, b) => ((a.dateMod - b.dateMod) || -strCmp(a.nameLC, b.nameLC)),
};
const sortMedia = (medias, sort) => medias.sort(sorters[sort] || sorters.dd);


const mWithD = (allMedias, loaded, ticks) => {
    const MT = allMedias.filter(m => ticks.includes(m.mediaId));
    return MT.map(m => ({ ...m, dl: loaded[m.mediaId]?.dl }));
};

const toMediaAry = ms => toAry(ms).map(m => ({...m, nameLC:toStr(m.name).trim().toLowerCase() }));
