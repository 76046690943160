import { __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_FIB, 
    __SYSQSubType_LBT, __SYSQSubType_LBD, __SYSQSubType_OEG, 
    __SYSQSubType_OEE, __SYSQSubType_POL } from "../../consts/ATSysQType";
import {QAS_ANSWERED, QAS_PARTLY, QAS_NOT, _WST_SUBMIT, _AST_PUBLISHED,
    _MST_MARKED, _MST_MARKED_PARTLY, _MST_MARKED_NONE} from "../../consts/ATConstsAssignment";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";
import { isAry, toAry, toObj, } from "../../libs/libType";
import { daysDiff_2min, } from "../../libs/libTime";
import { timeStampNowGMT } from '../../libs/libTime';
import { asmIsReme } from "../../consts/EpAssigmentErr";

export const wQAnsState = (fullQ, stResp, isEn=1) => {
    const qtnType = fullQ?.SQType;
    const qtn = isEn?(fullQ?.QEn):(fullQ?.QCt);
    let status = QAS_NOT;
    //if (Object.hasOwn(toObj(stResp),'qresp')) {
    if (toObj(stResp).hasOwnProperty('qresp')) {        
        if ([__SYSQSubType_FIB,__SYSQSubType_LBT,__SYSQSubType_LBD].includes(qtnType)) {
            const totalAns = (qtn?.qAnswers)?qtn.qAnswers.length:0;
            status = (Object.keys((stResp?.qresp||{})).length === totalAns)? QAS_ANSWERED: QAS_PARTLY;
        } else {
            if (isAry(stResp.qresp)) { status = (stResp.qresp.length > 0)? QAS_ANSWERED: QAS_NOT; }
            else { status = QAS_ANSWERED; }
        }
    };
    return status;
};

export const ECtnsCountInfo = (ECtns) => {
    let totalEctn = 0;
    let totalQ = 0;
    ECtns.forEach((ectn)=>{
        if (ectn.type === 'q') {
            const tmp = ectn.QIds.split(',');
            if (tmp.length > 1) {
                totalEctn = totalEctn + tmp.length;
                totalQ = totalQ + tmp.length;
            } else { totalEctn++; totalQ++;}
        } else totalEctn++;
    });
    return [totalEctn, totalQ];
};

export const isThrMarked = (thrResp) => {
    if (thrResp) {
        return thrResp.hasOwnProperty('mMark');
        //return Object.hasOwn(thrResp,'mMark');
    } else return 0;
};

export const isCorrSet = (thrResp) => {
    if (thrResp) {
        return thrResp.hasOwnProperty('mCorrect');
        //return Object.hasOwn(thrResp,'mCorrect');
    } else return 0;
};

export const markState = (mm) => {
    if ((mm.totalQtn>0) && (mm.allMarked === mm.totalQtn)) {
        return _MST_MARKED;
    } else if ((mm.totalQtn>0) && (mm.allMarked < mm.totalQtn)) {
        return _MST_MARKED_PARTLY;
    } else if ((mm.totalQtn>0) && (mm.allMarked === 0)) {
        return _MST_MARKED_NONE;
    };
    return _MST_MARKED_NONE;
};

export const isNotAttempted = (asm, work) => {
    const today = timeStampNowGMT();
    const daysRemain = daysDiff_2min(today, asm?.time2beEnd);
    const hasDue = asm?.timeEnd;
    const notAttempt = hasDue && daysRemain < 0 && (work?.WState !==_WST_SUBMIT);
    return notAttempt;
};


export const qResps = (resps, ex) => {
    return toAry(resps).filter((m, ii) => ex.ECtns[ii].type === _ExCtType_Qtn);
};

export const showScore = (asm, work) => {
    const aPub = (asm?.AState) === _AST_PUBLISHED;
    const wSub = (work?.WState) === _WST_SUBMIT;    
    return wSub && 
        (aPub 
        || asmIsReme(asm)
        || ((asm?.showScores) === 'sub')
        || (((asm?.showScores) === 'att') && (asm?.attempt) && ((asm?.attTimes) <= work?._attemp))
        || (((asm?.showScores) === 'dead') && (asm?.timeEnd) && (asm?.time2beEnd <= timeStampNowGMT()))
    );
}; //    wSub && (((asm?.showScores) === 'pub' && aPub) 

export const filterSubedSWMs = (SWMs) => {
    // swms = [{work:{...}, mark:{...},{}]
    const swms = toAry(SWMs);
    return swms.filter(swm=>{
        return (swm?.work?.WState) === _WST_SUBMIT;
    });
};