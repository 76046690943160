import {useRef, useState} from "react";
import CpIco from "./CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";

const ScrollContainer = (props) => { // base on poc ScrollContainer.tsx
    const {children, y = 0.8, x = 1.6, ...scrollProps} = props;
    const ref = useRef(null);
    const [show, setShow] = useState(false);

    const handleScrollToTop = () => {
        if (ref.current) {
            ref.current.scrollTo({top: 0, behavior: 'smooth'});
        };
    };
    const handleScroll = (e) => {
        if (e.target.scrollTop !== 0) { setShow(true);
        } else { setShow(false);}
    };

    return <div ref={ref} onScroll={handleScroll} {...scrollProps}>
        {children}
        {show === true && <span role={"button"}
            className={`scroll-to-top position-fixed end-0 bottom-0 rounded-circle bg-dim-400 d-flex justify-content-center align-items-center p-3 fs-4 shadow-400`}
            style={{margin: `${y}rem ${x}rem`}} onClick={() => handleScrollToTop()}>
            <CpIco src={IconList.general.scrollTop}/>
        </span>}
    </div>
};

export default ScrollContainer;