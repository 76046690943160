import React, {useState, useEffect} from 'react'
import {toStr, toObj} from './libType';
import { IconList } from '../consts/ATIconListToUsePoc';
import { ansNumPattern } from '../consts/ATValidate';
import "@dotlottie/player-component";
import { Loader } from '../AppExPf/AppUtil';
import Ckeditor5Base from '../AppExPf/components/ckeditor5/Ckeditor5Base';
import { useMediaCache } from '../AppExPf/utils/useMediaCache';
import LayerMediaPool from '../AppExPf/ATMediaPool/LayerMediaPool';
import { CpMediaModal } from '../AppExPFUser/EPAsmWork/CpAssignmentEndingPage';
// e.g. path = 'svg/add.svg'
export const imgSrc = path => (process.env.REACT_APP_WEBROOT + path);

//const tick_grey = imgSrc('svg/english/tick_grey.svg');
//const tick_green = imgSrc('svg/english/tick_green.svg');
//const tick_mastered = imgSrc('svg/english/tick_mastered.svg');
//const iconAlert = imgSrc('svg/english/icon_alert.svg');
//const iconTickOrange = imgSrc('svg/english/icon_tick_orange.svg');
const cbChecked = imgSrc('svg/checkbox_checked.svg');
const cbCheckedDisabled = imgSrc('svg/checkbox_checked_disabled.svg');
const cbUnchecked = imgSrc('svg/checkbox_unchecked.svg');
const cbUncheckedDisabled = imgSrc('svg/checkbox_disabled.svg');
const radioOn = imgSrc('svg/radio_on.svg');
const radioOff = imgSrc('svg/radio_off.svg');


// ===== ===== ===== Forms ===== ===== ===== ===== ===== ===== ===== ===== =====
export function File0(setter, id, className='', style={}){
    return (<input name={id} id={id} className={className} style={style} type="file"
    onChange={(e) => {setter(e.currentTarget.files) }} />);
}
export const Pass0 = (val, setter, id, className='', style={width:'100%'}, placeHolder='') => {
    return (<input name={id} id={id} className={className} style={style} type="password"
    onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val)} placeholder={placeHolder} />);
};
export const Text0 = (val, setter, id, className='', style={width:'100%'}, placeHolder='') => {
    return (<input name={id} id={id} className={className} style={style} type="text"
    onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val)} placeholder={placeHolder} />);
};

export const Text1 = (val, setter, onKeyPress, id, className='', style={width:'100%'}, placeHolder='') => {
    return (<input name={id} id={id} className={className} style={style} type="text"
    onKeyPress={onKeyPress} onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val)} placeholder={placeHolder} />);
};

export const TextInt = (val, setter, id, className='', style={width:'100%'}, onBlur=()=>{}) => {
    const filterNum = (v) => {return toStr(v).replace(/\D/g,'')};
    return (<input tabIndex={-1} name={id} id={id} className={className} style={style} type="text" placeholder={"--"}
    onBlur={onBlur} onChange={(e) => {setter(filterNum(e.currentTarget.value))}} value={filterNum(val)}/>);
};

export const TextNum = (val, setter, id, className='', style={width:'100%'}, placeHolder='') => {
    //const filterNum = (v) => {return toStr(v).replace(/\D/g,'')};
    const filterNum = (v) => {return toStr(v).match(ansNumPattern)[0]};
    ///^[-+$]?[0-9]*\.?[0-9]*[%]?/g
    return (<input name={id} id={id} className={className} style={style} type="text"
    //onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val).replace(/\D/g,'')} placeholder={placeHolder} />);
    onChange={(e) => {setter(filterNum(e.currentTarget.value))}} value={filterNum(val)} placeholder={placeHolder} />);
};

export const TextArea0 = (val, setter, id, className='', style={width:'100%'}) => {
    return (<textarea name={id} id={id} className={className} style={style}
    onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val)} />);
};
export function Select0(selected, setter, opts, id, className='', style={width:'100%'}){
    return (<select name={id} id={id} className={className} style={style}
    onChange={(e) => {setter(e.currentTarget.value) }} value={selected}>
    {Object.keys(opts).map((k, i)=>{ return <option key={k} value={k}>{opts[k]}</option> } )}
    </select>);
};
export function CheckBox0(checked, setter, id, disabled=false, className='', style={}){
    return (<span><input key={checked? 1: 0} name={id} type="checkbox" onClick={stopEvent}
        {...{id, className, style, ...(checked && {checked:1}), ...(disabled && {disabled:1}), }}
        onChange={stopEventThen(e => {setter(e.currentTarget.checked) })} /></span>);
};
export function radio0(val, setter, selected, id, disabled=false, className='', style={}){
    return (<span><input key={val + ((val === selected)?1:0)} name={id} type="radio" onClick={stopEvent} 
        {...{id, className, style, value: val, ...(val === selected && {checked:1}), ...(disabled && {disabled:1}) }}
        onChange={(e) => {setter(e.currentTarget.value) }} /></span>);
};
export const Button0 = (label, clickCB, id, className='', style={}, disabled = false) => {
    return (<button {...{name:id, id, style, className: (className||'uiBtn'), onClick: clickCB||undefined, 
        ...(disabled && {disabled:1}) }}>{label}</button>);
};

// Common Box  ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
export const Combo0 = (val, setter, opts) => <Combo0JSX {...{val, setter, opts}} />;
const Combo0JSX = ({val, setter, opts}) => {
    return <select tabIndex={1} value={val}  onChange={ e => { stopEvent(e); setter(e.currentTarget.value); }}>
        {opts.map(o => {
            const id = o[0];
            return <option key={'subj_' + id} value={id} >{o[1]}</option>;
        })}   
    </select>;
};

// Common Buttons ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
export const Button_ = (src, clickCB, id, size, className='clickable', style={}) => {
    if(size) style = {...style, width:size, height:size};
    return <img onClick={clickCB} className={className} style={style} id={id} alt="add" src={src} />
};
export const AddButton = (clickCB, id, size, className='clickable', style={}, icon = false) => {
    return Button_(icon?icon:(process.env.REACT_APP_WEBROOT+'svg/add.svg'),
        clickCB, id, size, className, style);
};
export const SaveButton = (clickCB, id, size, className='clickable', style={}) => {
    return Button_(process.env.REACT_APP_WEBROOT+'svg/save.svg',
        clickCB, id, size, className, style);
};
export const TrashButton = (clickCB, id, size, className='clickable', style={}) => {
    return Button_(process.env.REACT_APP_WEBROOT+'svg/trash.svg',
        clickCB, id, size, className, style);
};
/*
export const XButton = (clickCB, id, size, className='UpCardXBtn', style={}) => {
    return Button0('X', clickCB, id, className, size?{...style, width:size, height:size}:style);
};
*/
export const XButton = (clickCB, id, size, className='UpCardXBtn', style={}, icon=false) => {
    return Button_(icon?icon:(process.env.REACT_APP_WEBROOT+'svg/delete.svg'),
        clickCB, id, size, className, size?{...style, width:size, height:size}:style);
    //return Button0('X', clickCB, id, className, size?{...style, width:size, height:size}:style);
};

export const XButton2 = (clickCB, id, size, className='UpCardXBtn', style={}) => {
    return Button_(process.env.REACT_APP_WEBROOT+'svg/close_black.svg',
        clickCB, id, size, className, size?{...style, width:size, height:size}:style);
};

// Other UI Elements ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
export const FRow = (labelText, formInput, errMsg) => (
    <div className="row mb-2">
        <div className="col-12 col-sm-4 col-md-3 text-left text-sm-right">{labelText}</div>
        <div className="col-12 col-sm-8 col-md-9 text-left"><div>{formInput}</div>
        {errMsg?(<div><font color="red">{errMsg}</font></div>):''}</div>
    </div>
);
export function label(txt, forId, className='', style={fontWeight:900}){
    return (<label htmlFor={forId} className={className} style={style} >{txt}</label>);
};
export const ScreenMask = (children=false, exStyle={}, vis=1, onClick=false) => {
    return <div className="screenMask" style={{
        display: vis?'block':'none',
        backgroundColor:'rgba(0, 0, 0, 0.5)', ...exStyle}}>
        {/*<div style={{position:'absolute', width:'20%', height:'20%', backgroundColor:'#ccc'}} onClick={onClick || stopEvent}></div>*/}
        <div className="sequenceListAnimation" style={{position:'absolute', width:'100%', height:'100%'}}>
        {children}
        </div>
    </div>
};
export const ScreenMaskLoader = (size, exStyle={}, vis=1, onClick=false) => {
    return <div className="screenMaskLoader" style={{ display: vis?'block':'none',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', ...exStyle}}
        onClick={onClick || stopEvent}>
        {/*<div className="screenMaskLoader2"></div>*/}
        {Loader(size, {display:'inline-block', verticalAlign:'middle'})}
        {/*<CpMediaModal show={1} progress={100} miniLayout={true}/>*/}
    </div>;
};
/*
export const Loader = (size, style = {}) => {
    const iu = process.env.REACT_APP_WEBROOT+'img/loading.svg';
    return <img alt="loading" className="spin" style={{...style, width:size, height:size}} src={iu} />
};*/

export const stopEventThen = func => e => {
    stopEvent(e);
    func(e);
};

export const stopEvent = (e) => {
    e && e.preventDefault && e.preventDefault();
    e && e.stopPropagation && e.stopPropagation();
};

export const PageBar = (props) => {
    const {current, total, cb} = props;
    const [p, setP] = useState(current);
    const [tp, setTP] = useState(current);
    const first = (current <= 1);
    const last = (current >= total)
    if(p === ''){
        setP(current);
        setTP(current);
    }
    const _cb = (e, v) => {
        setP('');
        cb(e, v);
    };
    return (<div>
    {Button0('Prev', first?stopEvent:(e => _cb(e, current-1)), 'btnPrev', 'uiBtn mr-2', {}, first)}
    {Text0(tp, setTP, 'txtPage', '', {width:'60px', display:'inline-block'})} / {total} Pages
    {Button0('Go', e => _cb(e, tp), 'btnGo', "uiBtn mx-2")}
    {Button0('Next', last?stopEvent:(e => _cb(e, current+1)), 'btnPrev', 'uiBtn ', {}, last)}
    </div>);
}

/*  e.g.
    {PercentHalfCircle(30, "display text", 150, 10, "#FF1122", "#661122", true)}
    {PercentHalfCircle(20, "display text", 200, 20)}
*/
export const PercentHalfCircle = (number, content=<div></div>, diameter = 200, barWidth = 20,
    fgColor = "#FFFFFF", bgColor = "#111166", clockwise=true, round=true) => {
    const radius = Math.round(diameter / 2);
    const circumference = Math.PI * diameter;

    const percentLength = Math.round(number / 200 * circumference); // /2 /100 = /200

    const containerW = diameter + barWidth;
    const containerH = radius + barWidth / (round?1:2);
    const cPoint = radius + (barWidth / 2);

    const sda = "" + percentLength + " " + (circumference-percentLength);
    const sdo = "" + (clockwise ? circumference/2 : percentLength);

    const sdaBG = "" + circumference/2 + " " + circumference/2;
    const sdoBG = "" + circumference/2;

    //lj("number: percentLength circumference sdo:", percentLength, circumference, sdo);
    return (
        <div className="percentHalfCircleContainer"
            style={{width: containerW + "px", height: containerH + "px" }}>
            <div className="percentHalfCircleBody"
                style={{ height: containerH + "px", width: containerW + "px"}}>
                <svg>
                    <circle cx={cPoint} cy={cPoint} r={radius} style={{
                        fill: "none", stroke: bgColor, strokeWidth: barWidth,
                        strokeDasharray: sdaBG, strokeDashoffset: sdoBG, strokeLinecap: (round ? "round":"butt"),
                    }}></circle>
                    {number !== 0 && <circle cx={cPoint} cy={cPoint} r={radius} style={{
                        fill: "none", stroke: fgColor, strokeWidth: barWidth,
                        strokeDasharray: sda, strokeDashoffset: sdo, strokeLinecap: (round ? "round":"butt"),
                    }}></circle>}
                </svg>
            </div>
            <div className="percentHalfCircleContent">{content}</div>
        </div>
    );
};

export const progressBar = (index, total, fgColor, bgColor, text = '', textClass = '') => {
    const width = Math.round(index / total * 100) + "%";
    return <div id="progressBar" className="progressBar" style={{backgroundColor:bgColor}}>
        { text && <div className={textClass} style={{zIndex:3}} >{text}</div> }
        <div className="progressBarFg" style={{width:width, backgroundColor:fgColor}}></div>
    </div>
};

export const ProgressTimeBar = (props) => {
    const {totalTime} = props;
    const [myState, setMyState] = useState('idle');
    const [toTime, setToTime] = useState(0);
    const [timer, setTimer] = useState(null);

    useEffect(() => { return () => {if (timer) clearInterval(timer);} }, []);

    useEffect(() => {
        if (toTime === totalTime && myState !== 'idle' && props.state === 'stop') {
            if (timer) clearInterval(timer);
            setMyState('idle');
            setTimer(null);
            return;
        };
        const ss = (tt) => (tt+1)>totalTime ? 0: (tt+1);
        if (props.state === 'start' && myState === 'idle') {
            setToTime(0);
            setMyState('playing');
            setTimer(setInterval(()=>{setToTime(ss);}, 1000));
        } else if (props.state === 'stop' && myState === 'playing') {
            if (timer) clearInterval(timer);
            setTimer(setInterval(()=>{setToTime(ss);}, 1));
        } else if (props.state === 'idle') {
            if (timer) clearInterval(timer);
        };
    }, [toTime, props.state]);

    const percent = toTime > 0 ? Math.round(toTime/totalTime * 100)+"%" : 0;
    //lj("time, percent, state:", toTime, percent, myState);
    return <div className="progressTimeBarBG" style={{...props.style}}>
        <div className="progressTimeBar" style={{left:percent}}></div>
    </div>;
};
const SelectButton = (props) => {
    const {id, className, style, onClick, cb, enable} = props;
    const size = "25px";
    const checked = props.checked?true:false;
    const myClick = (e) => { stopEvent(e); onClick(!checked) };
    return <div name={id} id={id} className={(enable?"clickable ":"unclickable ") + className} style={{...style}} onClick={myClick}>
        <img alt="" src={enable?(checked?(cb?cbChecked:radioOn):(cb?cbUnchecked:radioOff))
            :(checked?(cb?cbCheckedDisabled:cbCheckedDisabled):(cb?cbUncheckedDisabled:cbUncheckedDisabled))} style={{width:size, height:size}}></img>
    </div>
};
export const CheckBox1 = (checked, onClick, id, enable=true, className='', style={}) => {
    return <SelectButton cb id={id} checked={checked} onClick={onClick} className={className} style={style} enable={enable}/>
};
export const radio1 = (checked, setter, id, enable=true, className='', style={}) => {
    return <SelectButton id={id} checked={checked} onClick={setter} className={className} style={style} enable={enable}/>
};
export const colorButton = (id, text, cb, color, width, myStyle = {}, className="", enable=true) => {
    return(<div id={id} onClick={cb} className={"colorButton usel " + className + (enable?" clickable":" unclickable")}
        style={{backgroundColor:color, width:width, ...myStyle}}>
    {text}</div>);
};
/*
export const iconButton = (caption="caption", icon="", fontColor="#FFFFFF", bgColor="#AAAAAA", iconColor="", onClick, leftIcon=true, btnClass="", fontClass="", iconStyle={}, buttonStyle={}, enable=true) => {
    const onClick_ = (enable && onClick) || undefined;
    const enable_ = onClick_ && enable;
    return <div id={"iB1"+caption} className={"iconButton " + (enable_? " clickable ": " unclickable ") + btnClass}
        style={{color: fontColor, backgroundColor: enable_? bgColor: "#A7A7A7", ...buttonStyle}} onClick={onClick_ }>
        {icon && leftIcon && svgIcon("iBsvg", icon, iconColor, "", iconStyle)}
        <div id={"iB2"+caption} className={" usel " + fontClass}>{caption}</div>
        {icon && !leftIcon && svgIcon("iBsvg", icon, iconColor, "", iconStyle)}
    </div>
};
*/
/* e.g. inside ATIconList.jsx
    menu: {
        home: 'menu/home.svg',
    => path = "menu/home"
*/
const _color = {white:"ATSVGWhite", black:"ATSVGBlack", dimblue:"ATSVGDimBlue", lightblue:"ATSVGLightBlue",
enable:"ATSVGEnable", disable:"ATSVGDisable",
prevBG:"ATSVGPrevBG",prevPrimary:"ATSVGPrevPrimary",prevSecondary:"ATSVGPrevSecondary",
prevSecondaryDark:"ATSVGPrevSecondaryDark",prevSecondaryLight:"ATSVGPrevSecondaryLight",prevBGNav:"ATSVGPrevBGNav",
mcqDefault:"ATSVGMcqDefault", mcqActive:"ATSVGMcqActive", mcqCorrect:"ATSVGMcqCorrect", 
mcqIncorrect:"ATSVGMcqIncorrect",mcqAttempted:"ATSVGMcqAttempted",mcqDim:"ATSVGMcqDim",
drag:"ATSVGDrag",
none:""};
export const svgIcon = (id="", path, color="black", myClass="", myStyle={}, overColor=undefined) => {
    //const myRef = useRef(null);
    const pp = path.split("/");
    const onMouseOver = (e) => {
        stopEvent(e);
        if (overColor) e.target.className = overColor;
    };
    const onMouseLeave = (e) => {stopEvent(e); e.target.className=_color[color]};
    return <img id={id} className={'oupSVGIcon ' + myClass + ' ' + _color[color]} 
        onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}  draggable={false} style={myStyle} alt=""
        src={process.env.REACT_APP_WEBROOT + 'static/icon/' + IconList[pp[0]][pp[1]]} />;
};

export const centerBox = (left, right) => {
	return <div className='flexRowCenter'>
		<div className='flexRowEnd' style={{minWidth:'49%', maxWidth:'49%'}}>{left}</div>
		<div style={{margin:'1px',backgroundColor:'grey',height:'20px', minWidth:'1px',maxWidth:'1px'}}></div>
		<div className='flexRowStart' style={{minWidth:'49%', maxWidth:'49%'}}>{right}</div>
	</div>;
};

///////////////////////////////////////////////////////////////////////////////////
// data-gramm="false"
const noAutoFill = {autoComplete:"off",autoCapitalize:"off",autoCorrect:"off",
    spellCheck:false, 'data-gramm':false};

export const EpInputTxt0 = (val, setter, id, className='', style={width:'100%'}, placeHolder='') => {
    return (<input name={id} id={id} className={className} style={style} type="text"
    onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val)} placeholder={placeHolder} 
    {...noAutoFill} />);
};

export const EpInputTxt1 = (val, setter, onKeyPress, id, className='', style={width:'100%'}, placeHolder='') => {
    return (<input name={id} id={id} className={className} style={style} type="text"
    onKeyPress={onKeyPress} onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val)} placeholder={placeHolder}
    {...noAutoFill} />);
};

export const EpInputInt = (val, setter, id, className='', style={width:'100%'}, onBlur=()=>{}) => {
    const filterNum = (v) => {return toStr(v).replace(/\D/g,'')};
    return (<input tabIndex={-1} name={id} id={id} className={className} style={style} type="text" placeholder={"--"}
    onBlur={onBlur} onChange={(e) => {setter(filterNum(e.currentTarget.value))}} value={filterNum(val)}
    {...noAutoFill} />);
};

export const EpInputNum = (val, setter, id, className='', style={width:'100%'}, placeHolder='') => {
    //const filterNum = (v) => {return toStr(v).replace(/\D/g,'')};
    const filterNum = (v) => {return toStr(v).match(ansNumPattern)[0]};
    ///^[-+$]?[0-9]*\.?[0-9]*[%]?/g
    return (<input name={id} id={id} className={className} style={style} type="text"
    //onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val).replace(/\D/g,'')} placeholder={placeHolder} />);
    onChange={(e) => {setter(filterNum(e.currentTarget.value))}} value={filterNum(val)} placeholder={placeHolder} 
    {...noAutoFill} />);
};

export const EpInputArea0 = (val, setter, id, className='', style={width:'100%'}) => {
    return (<textarea name={id} id={id} className={className} style={style}
    onChange={(e) => {setter(e.currentTarget.value) }} value={toStr(val)} 
    {...noAutoFill} />);
};

const emptyFunc = ()=>{};
export const TextCK0 = (props, key="unique_Key", val, setVal=emptyFunc, lock, ckImageConfig, containerStyle={}) => {
    return <React.Fragment key='respCK'><Ckeditor5Base {...props} editorType={"simpleCK"} data={val} setData={(v)=>setVal(v)} enable={!lock} 
        debug={false} {...{key, ckImageConfig, containerStyle}} />
    </React.Fragment>
};

