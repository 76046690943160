import React, { useState, useEffect, useRef } from 'react';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import CpAnswerDetail from '../components/CpAnswerDetail';
import * as FIBC from "../../consts/ATQtnAnsTypeFIB";
import { deepCopy } from '../AppUtil';
import { debugMode, ReduxBind } from '../../saga/ReduxState';
import { popConfirm } from '../components/CpPopup';
import { toInt } from '../../libs/libType';
import { useUILang } from '../utils/useUILang';
import { ansSumScore } from '../../consts/ATValidateQ';
import { getFIBAnsID } from '../../libs/libAnsCompare';
//import Ckeditor5BaseV2 from '../components/ckeditor5/Ckeditor5BaseV2';

const cssClass = {
    'atview': ' atView', // at preview
    'atedit': ' atEdit', // at edit
    'teview': ' teView', // teacher preview
    'teedit': ' teEdit', // teacher edit
};

const ___anssample='<span class="oupanswer" oupansid="4" qtype="TXT" qvalue="" qindex=1><span style=background-color:#0C8CE9;color:white;> &nbsp;1 </span> blank </span>'

const CpQEditorFIB = ReduxBind( props => {
    const { isAT, dispatch, setQ, setFullQ, fullQ, Q, afterInit, editable, setUpFlag,
        setOnAddMedia, mediaDLs, getMediaDLs } = props;
    const [ t, uiEn, UILang, setUILang, ut, t2, t3 ] = useUILang();
    const qData = Q?.qData || "";
    
    const [ansArr, setAnsArr] = useState(Q?.qAnswers || []);
    
    const [answer, setAnswer] = useState(undefined);

    const [selected, setSelected] = useState({id:-1, index:0}); // selected ans id
    const [changeSel, setChangeSel] = useState({id:-1, index:0}); // temp hold select change for checking
    const [keyIndex, setKeyIndex] = useState(getMaxValue(ansArr, 'oupansid')); 

    const refCK = useRef(null);

    const updateQ = (func, d) => { func && func(d); }

    //const updateScore = () => setFullQ('score', ansSumScore(ansArr));
    const updateScore = () => {
        if (fullQ.scoreMethod === FIBC.__FIBS_PQU) return;
        setFullQ('score', ansSumScore(ansArr));
    };    

    useEffect(()=>{
        if (fullQ.scoreMethod === FIBC.__FIBS_PQU) {
            if (!fullQ.score) setFullQ('score',1);
            ansArr.forEach((ans) => {ans.score=0});
            //setFullQ('score',1);
            setAnsArr([...ansArr]);
        } else {
            ansArr.forEach((ans) => {
                
                if (!ans.score) ans.score = 1;
                //ans.score=(toInt(ans.score)?1:toInt(ans.score))
            });
            updateScore(); }
    }, [fullQ.scoreMethod]);

    useEffect(() => { 
        setAnswer((selected.id !== -1) ?getAnsData(selected.id):undefined);
    },[selected]);

    useEffect(()=>{
        if (selected.id !== -1 && changeSel.id !== selected.id) {
            popConfirm(dispatch, 0, t('warning.warning_change_answer_blank'), () => {
                setAnswer(undefined);
                // force answerDetail redraw fix ckeditor cacehed ans
                setTimeout(()=>{setSelected({id:changeSel.id, index:changeSel.index})},10);
                //setSelected({id:changeSel.id, index:changeSel.index});
            });
        } else {
            setSelected({id:changeSel.id, index:changeSel.index});
        }
    },[changeSel]);

    useEffect(()=>{
        if(selected.id !== -1 && getAnsData(selected.id) === undefined) setSelected({id:-1, index:0});
        if(afterInit) {
            updateQ(setQ("qAnswers"), [...ansArr]);
            updateScore();
        };
    }, [ansArr]);

    const setAnswerData = ans => {
        const index = ansArr.findIndex((obj)=>{return obj.oupansid === selected.id});
        if (index >= 0) {
            ansArr[index] = deepCopy(ans);
            updateScore();
            const ckanswer = refCK.current.plugins.get('cpCkAnswer');
            ckanswer && ckanswer.updateAnswer(ans);
        };        
    };

    const deleteThisAns = () => {
        if (selected.id >= 0) {
            const ckanswer = refCK.current.plugins.get('cpCkAnswer');
            ckanswer && ckanswer.updateAnswer(undefined, selected.id);
            const index = ansArr.findIndex((obj)=>{return obj.oupansid === selected.id});
            if (index >= 0) {
                ansArr.splice(index, 1);      
                setAnsArr([...ansArr]);
                updateScore();
            };
        };
    };

    const getAnsData = id => ansArr.find( obj => (obj.oupansid === id));

    const ckAnswerConfig = {
        newAnswer: (oupansid, qtype = FIBC.__FIBT_TXT) => {
            const qv = qtype === FIBC.__FIBT_TXT?[{data:"",type:FIBC.__FIBA_TXT}]
                :[{data:"",type:FIBC.__FIBA_TXT},{data:"",type:FIBC.__FIBA_TXT}];
            return {oupansid, qtype, correctAns:1, qvalue:qv};
        },
        onClick: (editor, id, index) => {setChangeSel({id:id, index:index})},
        allDone: (arr, index) => {           
            if (arr) {
                const result = [];
                arr.forEach((item) =>{
                    //if (!item.score) {item.score=1;}
                    if (!item.deleted) { delete item['deleted']; result.push(item);}
                });
                //if (afterInit) { setAnsArr(deepCopy(result)); setKeyIndex(index); };
                setAnsArr(deepCopy(result));
            };
            setKeyIndex(index);
        },
        getAnsArr: () => ansArr,
        getKeyIndex: () => keyIndex,
        updateQAnswers: arr => updateQ(setQ("qAnswers"), [...arr]),
    };    

    const ck_updateMediaID = arr => getMediaDLs([...arr]); 
    const ckImageConfig = { setOnAddMedia, updateMediaID: ck_updateMediaID, };
    const edProps = {isAT, mediaDLs, getMediaDLs};

    const _checkID = () => {};

    const myClass = cssClass[editable ? 'atedit' : 'atview'];
    return <>
        <div className={'tabQContent ' + (editable ? "" : "ReadOnly") + myClass}>
            {(false && debugMode())?<button onClick={_checkID}>check id</button>:''}
            <Ckeditor5Base {...props} editorType="questionFIB" data={qData} setData={setQ("qData")}
                enable={editable} setEditor={refCK} debug={false} showStatus={true}
                ckAnswerConfig={ckAnswerConfig} onEditorReady={()=>{}} noDragDrop={true}
                ckImageConfig={ckImageConfig} {...edProps} setUpFlag={setUpFlag} />
            <p></p>  
            {answer && <CpAnswerDetail ansOK={answer} index={selected.index} setConfirmAns={setAnswerData}
                editable={editable} updateScore={updateScore} close={()=>setSelected({id:-1, index:0})}
                deleteThisAns={deleteThisAns} {...edProps} autoMark={fullQ.autoMark} scoreMethod={fullQ.scoreMethod}
                dispatch={props.dispatch} hasCorrectAns={true} />}
        </div>
    </>;
});
export default CpQEditorFIB;

export const getMaxValue = (arr, fieldName) => {
    let max = 0;
    if (arr.length > 0) {
        arr.forEach((obj)=>{ if (max < obj[fieldName]) max = obj[fieldName];});
    };
    return max;
};


