import React, {useState} from "react";
import { ReduxBind } from "../../saga/ReduxState";
import { stopEvent } from "../../libs/libUI";
import { Button, CloseButton } from "react-bootstrap";
import CpIco from "../_components/CpIco";
import CpActionButton from "../_components/CpActionButton";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { lang2t, useUILang } from "../../AppExPf/utils/useUILang";
import CpSharedModal from "../_components/CpSharedModal";
import { _AST_WAIT_RESULT } from "../../consts/ATConstsAssignment";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { toIdStr } from "../../consts/ATValidate";
import { objKeys, toObj } from "../../libs/libType";

export const useAssignmentEnd = () => {
    const [endAsm, setEndAsm] = useState();
    return {endAsm, setEndAsm};
}

export const CpEndAssignmentCTA = ReduxBind(props => { //base on EndAssignmentCTA
    const {dispatch, reload, asm, useAsmEnd, ...btnProps} = props;
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {setEndAsm} = useAsmEnd; 

    const clickAsmEnd = e => { stopEvent(e); setEndAsm({asm, reload}); };
    return <CpActionButton title={t("assignment-cta-end-assignment")} iconPath={IconList.assignment.endAssignment}
        className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={clickAsmEnd} {...actBtnProps(btnProps)} />;
});

const LayerAssignmentEnd = ReduxBind(props => { //base on EndAssignmentCTA
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {dispatch, useAsmEnd} = props; 
    const {endAsm:_endAsm, setEndAsm} = useAsmEnd; 
    const {asm:endAsm, reload} = toObj(_endAsm); 

    const assignId = toIdStr(endAsm?.assignId);
    const ex = toObj(endAsm?.Exer);
    const AName =  lang2t(endAsm?.ALang, ex.ENameEn, ex.ENameCt);

    const clickEndAsm = async e => { stopEvent(e); 
        const [res, err] = await asyncApiCallLoad_(dispatch, '/putAssignmentUpdate', { assignId, end:1 });
        reload(); //onEndAsm(endAsm);
        setEndAsm(0);
    };
    const clickClose = async e => { stopEvent(e); setEndAsm(0); }

    return <>
        <CpSharedModal show={endAsm} scrollable={false}
            footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
                <Button variant="gray-body-color w-100 border" onClick={clickClose}>{t("cancel")}</Button>
                <Button variant="exercise-third-btn btn-block w-100" onClick={clickEndAsm}>{t("confirm")}</Button>
            </div>}>
            <div className={"px-3 pt-4 bg-dim-100 rounded-top"}>
                <div className={"d-flex flex-column gap-4"}>
                    <div className={"d-flex flex-column gap-3 w-100"}>
                        <div className={"d-flex justify-content-between"}>
                        <span className={"d-flex justify-content-end opacity-0 pe-none"}><CloseButton onClick={clickEndAsm}/></span>
                            <span className={"d-flex justify-content-center text-exercise-third-btn fs-1"}>
                                <CpIco src={IconList.assignment.endAssignment}/></span>
                            <span className={"d-flex justify-content-end"}><CloseButton onClick={clickClose}/></span>
                        </div>
                        <div className={"d-flex justify-content-center text-exercise-third-btn fs-4 semi-bold"}>
                            {t("assignment-cta-end-assignment")}
                        </div>
                        <div className={"d-flex w-100 fs-4 semi-bold justify-content-center user-select-none text-center"}>
                            {AName}
                        </div>
                    </div>

                    <div className={"d-flex flex-column align-items-start gap-3 mb-4 px-3"}>
                        {t("assignment-end-assignment-message")}
                    </div>
                </div>
            </div>
        </CpSharedModal>
    </>
});
export default LayerAssignmentEnd;

export const actBtnProps = p => ({});

