import { Buffer } from 'buffer';

//https://stackoverflow.com/questions/2897619/using-html5-javascript-to-generate-and-save-a-file

export const downloadUrl = (url, pop=1, force=1) => {
    const a = document.createElement('a');
    a.href = url;
    pop && (a.target = '_blank');
    const forceName = (force===1)? '': force;
    //forceName && (a.setAttribute('download', forceName)); //a.download = '';//true;
    force && (a.setAttribute('download', forceName)); //a.download = '';//true;
    a.click();
    a.remove();
};

export const fAKEdownload = (content, filename, contentType) => {
    const blob1 = content2Blob(content, contentType);
    aDownload(blob1, filename);
};
export const downloadBase64 = (base64DataStr, fileName, contentType) => {
    const buf = Buffer.from(base64DataStr, 'base64');
    fAKEdownload(buf, fileName, contentType);//
};
export const localDownload = (fileOrBlob, filename) =>{
    aDownload(fileOrBlob, filename);
};
const content2Blob = (content, contentType) => {
    return new Blob([content], {'type':contentType || 'application/octet-stream'});
};
const aDownload = (fileOrBlob, filename) => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(fileOrBlob);
    a.download = filename;
    a.click();
    a.remove();
};

//const dlx = () => <a download="My-FileName.txt" href="data:application/octet-stream,HELLO-WORLDDDDDDDD">Click here</a>;