import React, {useEffect, useState} from "react";
import { Button } from "react-bootstrap";
import DoEx from "../../AppExPf/ATExerEdit/DoEx";
import { useUILang } from "../../AppExPf/utils/useUILang";
import CpActionButton from "./CpActionButton";
import {CpSettingModalTitle, CpSettingModalSection, CpSettingModalTabContainer} from "./CpSettingModalTabCps";
import SharedModalHeader from "./CpSharedModalHeader";
import { deepCopy } from "../../AppExPf/AppUtil";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { ReduxBind } from "../../saga/ReduxState";
import CpIco from "./CpIco";
import CpSharedModal from "./CpSharedModal";

const CpExerciseEditPreviewCTA = ReduxBind(props => { //base on ExerciseEditPreviewCTA 
    const {dispatch, ex, setVisExPv:_setVisExPv, showEn, qtns, useCacheQtns, mediaDLs, getMediaDLs, setOnAddMedia, addLocalMedias, } = props;

    const [t] = useUILang();
    const [opts, setOpts] = useState({});

    const [visExPv, setVisExPv, PVMode, setPVMode, visExPvOpts, setVisExPvOpts] = usePreviewSelect(0, 1);
    useEffect(()=>{ if (_setVisExPv) { _setVisExPv(visExPv? 1: 0); } }, [visExPv]);
    const handleClose = () => setVisExPvOpts(0);
    const confirmPresentationMode = () => { setVisExPv(1); handleClose(); };

    //const setOnAddMedia = () => {alert('Front Preview Not Support Media Pool')};
    const [ECtns, hasEn, hasCt] = [ex.ECtns, ex.ELangEn, ex.ELangCt]; 
    const [lock, preview, showPub] = [1, 1, 1];
    
    const doPreview = () => {
        if (ex.EModeScroll && ex.EModeSlide) {
            setVisExPvOpts(1);
        } else {
            setVisExPvOpts(0);
            setPVMode(ex.EModeScroll? 1: 0);
            setVisExPv(1);
        };
    };

    const pvProps = {close:()=>setVisExPv(0), preview, PVMode, showPub, showEn, hasEn, hasCt, lock, ECtns, qtns, useCacheQtns, 
        opts, setOpts, setOnAddMedia, mediaDLs, getMediaDLs, addLocalMedias, dispatch,
    };

    return <>
        {visExPv? <DoEx {...pvProps} />:''}
        <CpActionButton id="CpExerciseEditPreviewCTA" title={t("preview")} iconPath={IconList.general.preview} onClick={() => doPreview()}
            className={"btn exercise-action-btn gap-2 rounded semi-bold user-select-none"} hiddenText={false} />
        <CpSharedModal scrollable={true} show={visExPvOpts} 
            header={<SharedModalHeader title={t("preview")} iconPath={IconList.general.preview} handleClose={handleClose}/>} 
            footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
                <Button variant="gray-body-color w-100 border" onClick={handleClose}>{t("cancel")}</Button>
                <Button variant="exercise-third-btn btn-block w-100" onClick={confirmPresentationMode}>{t("open")}</Button>
            </div>}>
            <CpSettingModalTabContainer>
                <CpSettingModalSection hideBorder={true}>
                    <CpSettingModalTitle>{t("please-select-exercise-mode-to-preview")}:</CpSettingModalTitle>
                    <div className={"d-flex flex-wrap gap-2 gap-sm-5 justify-content-center align-items-center fs-1 w-100"}>
                        <span role={"button"} style={{width: "150px"}} onClick={() => setPVMode(1)} 
                              className={`d-flex flex-column align-items-center gap-2 p-3 uiAnimation border rounded-light  ${
                                (PVMode===1)? "text-exercise-third-btn border-exercise-third-btn" : "text-dim-350"}`}>
                            <CpIco src={IconList.presentation.assessmentMode}/>
                            <span className={"fs-6 semi-bold user-select-none"}>{t("assignment-settings-scroll-mode")}</span>
                        </span>
                        <span role={"button"} style={{width: "150px"}} onClick={() => setPVMode(0)} 
                              className={`d-flex flex-column align-items-center gap-2 p-3 uiAnimation border rounded-light  ${
                                (PVMode===0)? "text-exercise-third-btn border-exercise-third-btn" : "text-dim-350"}`}>
                            <CpIco src={IconList.presentation.practiceMode}/>
                            <span className={"fs-6 semi-bold user-select-none"}>{t("assignment-settings-slide-mode")}</span>
                        </span>
                    </div>
                </CpSettingModalSection>
            </CpSettingModalTabContainer>
        </CpSharedModal>
    </>
});
export default CpExerciseEditPreviewCTA;


const usePreviewSelect = (mode, pv, sel) => {
    const [PVMode, setPVMode] = useState(0);
    const [visExPvOpts, setVisExPvOpts] = useState(0);
    const [visExPv, setVisExPv] = useState(0);
    return [visExPv, setVisExPv, PVMode, setPVMode, visExPvOpts, setVisExPvOpts];
};
