export const tryParseJson = (json) => { try { return JSON.parse(json); }catch(e) { }; return false; };
export const deepCopy = (src) => tryParseJson(JSON.stringify(src));

const srcAttrib=' src=';
export const rmSrc = src => {
    const qou = src[0]; //("ulink" rest...) 
    const [str0, delSrc, ...imgtail] = src.split(qou); //['', ulink, ...rest]
    return [str0, '', ...imgtail].join(qou); // remove  //['', ", ", ....]
};
export const rmTagsSrc = (ckhtml, tagname) => {
    const tag = '<'+tagname;
    const [seg1, ...segs] = ckhtml.split(tag+' '); 
    const fixed = segs.map(seg => {
        const [tagbody, ...afterTags] = (' '+seg).split('>'); // split by (>) 
        const [taghead, ...srcs] = tagbody.split(srcAttrib); // split first (>) by ( src=)   
        const trimtag = [taghead, ...srcs.map(rmSrc)].join(srcAttrib); //re join by ( src=)
        return [trimtag, ...afterTags].join('>'); //re join by (>)
    });
    return [seg1, ...fixed].join(tag);
};
export const rmMediaSrc = ckhtml => rmTagsSrc(rmTagsSrc(rmTagsSrc(ckhtml, 'img'), 'video'), 'audio');

const testRmTagsSrc = () => {
    const s ='<p> img <img class="res" oupid="video" src="a">'+
    '<imgg src="!"></p><img class="r" style="nosrc" oupid="img" notsrc="1">'+
    '<img nosrc"n"/><iframe src="youtube" style="left:0;" /></div>'+
    '<img class="manysrc" src="b" style="width:25%;" oupid="NW" src="c" />'+
    "<img cls'm' src='b' st='w:2' oupid='N' />"+
    '';
    
};
//testRmTagsSrc();


export const mapAtt = (att, attMap) => {
    if(!att) return ['',''];
    const mayQou = att[0]; //("ulink" rest...) 
    const isQou =  ['"',"'"].includes(mayQou); 
    const qou = isQou? mayQou: ' '; // qou is only 'single' 'double' 'space' 
    const [nil, mapsrc, ...tail] = ((isQou?'': ' ')+att).split(qou); // "att" x="y" a="b" >> ['', att, ' x="y" a=', b="...  or 'att x="y" a="b"... >> [att, x="y", a="b" ]
    const found = attMap[mapsrc];
    const mapRet = (found || found === '')? found: mapsrc;
    const q = isQou? qou: '"';
    const tstr = [...tail].join(qou);
    return [mapsrc, q+mapRet+q+ ((isQou || !(tail.length))?  '': ' ') + tstr];////'{'+qou+'|'+tstr+'}'];
};

export const mapTagAtt = (ckhtml, attName, attMap) => {
    const cut = ' '+attName+'=';
    const oldAtts = new Set();
    const [seg1, ...tags] = ckhtml.split('<'); 
    const fixTags = tags.map(tag => {
        const [tagbody, ...afterTags] = (tag).split('>'); // 
        const [taghead, ...atts] = tagbody.split(cut); // ' t="a" n="1" t="b" n="2"... => ['', "a" n="1", "b" n=""]   
        const fixAtts = atts.map(a => { const [src, out] = mapAtt(a, attMap); oldAtts.add(src); return out; });
        const fixTag = [taghead, ...fixAtts].join(cut); 
        return [fixTag, ...afterTags].join('>'); //re join by (>)
    });
    return [Array.from(oldAtts), [seg1, ...fixTags].join('<')];
};
const testMapTagAtt = () => {
    const s = '<p> img <img cl="a" oup=x  oup=y  sr="a">'+
    '<im xoup=b sr="!" oup=a oup=z  x="y"  p=x></p><a cl="A" st="a" oup=a no="a">'+
    '<im px="a" oup="a b"/><fr oup="a"  oup="b" n=a s="le:0;" />a="b"</div>'+
    '<im oup="d" c="ma" oup=c sr="b" a="a" oup=b s="c" />'+
    '';
    const map = {a:'A', b:'B', c:'C'};
    //mapTagAtt(s, 'src', map)
    const [k, t] = mapTagAtt(s, 'oup', map);
};
//testMapTagAtt();

