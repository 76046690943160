import i18next from "i18next";
import {initReactI18next} from "react-i18next";

//Import all translation files
import en from "./json/en.json";
import zh from "./json/zh.json";
import tmp from "./json/tmp.json";


//---Using different namespaces
const resources = {
    en: {
        schema: en,
    },
    zh: {
        schema: zh,
    },
    tmp: {
        schema: tmp,
    },    
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", //default language
        fallbackLng: 'en'
    });

export default i18next;