import React, {useMemo} from "react";
import { toStr } from "../../../libs/libType";
import { _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED, _AST_WAIT_RESULT } from "../../../consts/ATConstsAssignment";
import { useUILang } from "../../../AppExPf/utils/useUILang";

export const TagThrProgressTag = (props) => { // base on poc ProgressTag.tsx
    const [t, lang] = useUILang();
    const {AState, hasM} = props;
    //const AState = asm?.AState;
    
    const item = useMemo(() => {
        return (!AState || (AState === _AST_PENDING))? {
            circle: "bg-tag-none",
            style: "bg-white border-tag-none text-tag-none",
            title: t("assignment-tag-not-yet-started")
        }: (AState === _AST_IN_PROGRESS)? {
            circle: "bg-tag-warning",
            style: "bg-white border-tag-warning text-tag-warning",
            title: t("assignment-tag-in-progress")
        }:(AState === _AST_PUBLISHED)?{
            circle: "bg-tag-success",
            style: "bg-white border-tag-success text-tag-success",
            title: t("assignment-tag-result-published")
        }:(AState === _AST_WAIT_RESULT)? {
            circle: "bg-tag-warning",
            style: "bg-white border-tag-warning text-tag-warning",
            title: t("assignment-tag-pending-result")
        }:'';
        /*(AState === '_AST_ WAIT_MARK')?{  // no more such state, use hasM + WST_SUBMITED + isStu + AIP
            circle: "bg-tag-warning",
            style: "bg-white border-tag-warning text-tag-warning",
            title: t("assignment-tag-pending-manual-mark")
        }:(AState === "paused")? { 
            circle: "bg-tag-none",
            style: "bg-white border-tag-none text-tag-none", 
            title: t("assignment-tag-paused")
        }:'';*/
    }, [lang, AState]);

    return item? <small
        className={`semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light ${item.style}`}><span
        className={` d-flex align-items-center ${item.circle} rounded-circle`}
        style={{width: "0.5rem", height: "0.5rem"}}></span>{item.title}
    </small>:
    'TagThrProgressTag '+toStr(AState);
};


/*
const CpThrProgressTag = (props) => { // base on poc ProgressTag.tsx
    const [t, isUIEn, lang] = useUILang();
    const {status} = props;
    const item = useMemo(() => {
        switch (status) {
            case "paused":
                return {
                    circle: "bg-tag-none",
                    style: "bg-white border-tag-none text-tag-none", title: t("assignment-tag-paused")
                }
                break;
            case "not-yet-started":
                return {
                    circle: "bg-tag-none",
                    style: "bg-white border-tag-none text-tag-none",
                    title: t("assignment-tag-not-yet-started")
                }
                break;
            case "in-progress":
                return {
                    circle: "bg-tag-warning",
                    style: "bg-white border-tag-warning text-tag-warning",
                    title: t("assignment-tag-in-progress")
                }
                break;
            case "pending-manual-mark":
                return {
                    circle: "bg-tag-warning",
                    style: "bg-white border-tag-warning text-tag-warning",
                    title: t("assignment-tag-pending-manual-mark")
                }
            case "pending-result":
                return {
                    circle: "bg-tag-warning",
                    style: "bg-white border-tag-warning text-tag-warning",
                    title: t("assignment-tag-pending-result")
                }
                break;
            case "result-published":
                return {
                    circle: "bg-tag-success",
                    style: "bg-white border-tag-success text-tag-success",
                    title: t("assignment-tag-result-published")
                }
                break;
        }
    }, [lang, status]);

    return <small
        className={`semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light ${item.style}`}><span
        className={` d-flex align-items-center ${item.circle} rounded-circle`}
        style={{width: "0.5rem", height: "0.5rem"}}></span>{item.title}
    </small>
};

export default CpThrProgressTag;
*/