export const _ATMetaType_GE = 'ge';
export const _ATMetaType_SU = 'su';
export const _ATMetaType_TB = 'tb';
export const _ATMetaType_CO = 'co';
export const _ATMetaType_ET = 'et';
export const _ATMetaType_QT = 'qt';
export const _ATMetaType_CU = 'cu';
export const _ATMetaType_GR = 'gr';
export const _ATMetaType_SK = 'sk';
export const _ATMetaType_VA = 'va';
export const _ATMetaType_DI = 'di';
export const _ATMetaType_LA = 'la';
export const _ATMetaType_EX = 'ex';
export const _ATMetaType_TA = 'ta';
export const _ATMetaType_OT = 'ot';
export const _ATMetaType_ME = 'me';

export const __ATMetaTypes = {
    [_ATMetaType_GE]: {code: 'ge', opt: '', name: 'General', trans: 'meta-general',},
    [_ATMetaType_SU]: {code: 'su', opt: 'd', name: 'Subject', trans: 'meta-subject',},
    [_ATMetaType_TB]: {code: 'tb', opt: 'o', name: 'Textbook Series', trans: 'meta-textbook-series',},
    [_ATMetaType_CO]: {code: 'co', opt: '', name: 'Copyright', trans: 'meta-copyright',},
    [_ATMetaType_ET]: {code: 'et', opt: '', name: 'Exercise Category', trans: 'meta-exercise-type',},
    [_ATMetaType_QT]: {code: 'qt', opt: '', name: 'Question Category', trans: 'meta-question-type',},
    [_ATMetaType_CU]: {code: 'cu', opt: 'o', name: 'Curriculum', trans: 'meta-curriculum',},
    [_ATMetaType_GR]: {code: 'gr', opt: '', name: 'Level/Grade', trans: 'meta-level-grade',},
    [_ATMetaType_SK]: {code: 'sk', opt: 'o', name: 'Skills', trans: 'meta-skills',},
    [_ATMetaType_VA]: {code: 'va', opt: 'o', name: 'Values', trans: 'meta-values',},
    [_ATMetaType_DI]: {code: 'di', opt: '', name: 'Difficulty', trans: 'meta-difficulty',},
    [_ATMetaType_LA]: {code: 'la', opt: '', name: 'Languages', trans: 'meta-languages',},
    [_ATMetaType_EX]: {code: 'ex', opt: '', name: 'Examination Types', trans: 'meta-examination-types',},
    [_ATMetaType_TA]: {code: 'ta', opt: 'o', name: 'Taxonomy', trans: 'meta-taxonomy',},
    [_ATMetaType_OT]: {code: 'ot', opt: '', name: 'Others', trans: 'meta-others',},
    [_ATMetaType_ME]: {code: 'me', opt: '', name: 'Media Languages', trans: 'meta-media-languages',},
};

export const _REME_METATYPES = [_ATMetaType_TB, _ATMetaType_CU, _ATMetaType_SK, _ATMetaType_VA];

export const _ATMetaTypes = () => Object.values(__ATMetaTypes);
export const _ATMetaTypeCodes = () => Object.keys(__ATMetaTypes);
export const _ATMetaTypeByCode = code => __ATMetaTypes[code]?.name;
export const _ATMetaTransByCode = code => __ATMetaTypes[code]?.trans;
export const _ATMetaTypeReport = code => __ATMetaTypes[code]?.opt;
export const _ATMetaTypeReportSubj = code => (__ATMetaTypes[code]?.opt === 'd');


