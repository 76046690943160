import { useUILang } from "../../AppExPf/utils/useUILang";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";

const CpFIBManualMarkWithText = (props) => {
    const {id, isCorrect, setCorrect} = props;
    const [t] = useUILang();
    const correct = t('manual-mark.correct');
    const incorrect = t('manual-mark.incorrect');
    const attempted = t('manual-mark.attempted');

    const _onClick = (e,ss) => {
        
        setCorrect && setCorrect(e,ss);
    };

    return <div className='flexRowFit' style={{flexShrink:0}}>
    <span className="fib-manual-mark-group-with-text" data-for={id}>
        <span role="button" className={"fib-manual-mark-with-text correct d-inline-flex justify-content-center "+
            " gap-2 align-items-center semi-bold user-select-none " + (isCorrect===undefined?'':(isCorrect?'checked':''))}
            data-status="correct" onClick={(e)=>_onClick(e,1)}>
            <CpIco src={IconList.status.correct}/>
            <span className={"indicator-name"}>{correct}</span>
        </span>

        <span role="button" className={"fib-manual-mark-with-text incorrect d-inline-flex justify-content-center "+
            " gap-2 align-items-center semi-bold user-select-none " + (isCorrect===undefined?'':(isCorrect?'':'checked'))}
            data-status="incorrect" onClick={(e)=>_onClick(e,0)}>
            <CpIco src={IconList.status.incorrect}/>
        <span className={"indicator-name"}>{incorrect}</span>
        </span>
    </span>
    </div>
};

export default CpFIBManualMarkWithText;