export const PresentationMode = {
    SLIDE_MODE: "SLIDE_MODE",
    SCROLL_MODE: "SCROLL_MODE",
    LIST_MODE: "LIST_MODE",
    COLUMN_MODE: "COLUMN_MODE"
}

export interface AssignmentListPresentationMode {
    LIST_MODE: "LIST_MODE",
    COLUMN_MODE: "COLUMN_MODE"
}