import React, {useEffect, useRef, useState} from "react";

import {Container, Nav, Navbar} from "react-bootstrap";
import {sanitize} from "dompurify";

import { SIZE, ALIGN, CHECKBOX_VARIANT } from "./_components/CpExPFConst";
import { IconList } from "../consts/ATIconListToUsePoc";
import CpActionButtonComponent from "./_components/CpActionButtonComponent";
import * as UI from '../libs/libUI';
import { redirectSignin } from '../libs/libMidWare';
import { ReduxBind } from '../saga/ReduxState';
import { gst_set } from "../saga/globalState.saga";
import { appLogout, asyncApiCallLoad_ } from "../libs/awsFuncs";
import { useUILang } from "../AppExPf/utils/useUILang";
import CpIco from "./_components/CpIco";
import { _WebRoot } from "../consts/ATConstReact";
import { toObj, toStr } from "../libs/libType";
import { urlPush_Replace } from "../saga/urlPush.saga";
import { webAppVer } from "../libs/libAppVer";
import { langTxt } from "./EPLibrary/PageLibrary";

import OXFORD_LOGO from "../poc/static/img/frontend/oxford.svg"
import KEYS_LOGO from "../poc/static/img/frontend/keys.svg"
import CpCheckboxComponent from "./_components/CpCheckboxComponent";

const PageLogin = ReduxBind(props => {
    const {dispatch, licenseAgree, logined} = props;
    const [t, uiEn] = useUILang();

    const {noEnt} = toObj(props.match?.params);
   
    const [checked, setChecked] = useState(0)
    const [scrollToBottom, setScrollToBottom] = useState(1);

    const agreementRef = useRef(null);
    const [input, setInput] = useState(0);

    const path = window?.location?.pathname;
    const search = window?.location?.search;

    const openLearn = () => {
        const url = uiEn?'https://digital.oupchina.com.hk/oep/en/learn-more'
            :'https://digital.oupchina.com.hk/oep/zh/learn-more';
        window.open(url, '_blank');
    };

    const scrollEvent = event => {
        const element = event.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            setScrollToBottom(true); //Don't need to detect to scroll bottom
        }
    };
    const cur = agreementRef.current;
    useEffect(() => {
        if (cur){ cur.addEventListener("scroll", scrollEvent); }
        return () => { if (cur) cur.removeEventListener("scroll", scrollEvent); }
    }, [cur]);

    const clickOupLogin = e => { UI.stopEvent(e);
        setInput(2);
        redirectSignin(uiEn, props.settings(), {state:{path, search}});
    };
    const clickdecline = e => { UI.stopEvent(e);
        appLogout(dispatch, uiEn, props.settings());
    };
    const clickGoLogin = e => { UI.stopEvent(e);
        dispatch(urlPush_Replace(_WebRoot + 'login'));
        appLogout(dispatch, uiEn, props.settings());
    };
    
    const renderLogin = () => { return <>
        {(!logined) && <div
            className={"d-flex flex-column justify-content-center align-items-center my-5 gap-3 text-light cta-container"}>
            <CpActionButtonComponent variant={"login-btn-color"} title={t("login")}
                                    className={"btn-login px-5 py-1 rounded-pill bold fs-4"}
                                    onClick={clickOupLogin}/>
            <CpActionButtonComponent variant={"login-learn-more-btn-color text-light px-4"}
                                    icon={<CpIco src={IconList.general.arrowRight}/>}
                                    className={"btn-learn-more semi-bold"}
                                    onClick={openLearn}
                                    iconAlign={ALIGN.END} size={SIZE.SM}>{t("learn-more")}</CpActionButtonComponent>
        </div>}
    </>};
    const renderHeaderBrand = () => { return <div className={"d-flex flex-row justify-content-center gap-3"}>
            <a href={"#"} className={"keys-brand-logo"}><CpIco src={"oup_logo_color.svg"} path={"static/img/"}/></a>
            <div className={"vr"}></div>
            <a href={"#"} className={"oup-brand-logo"}><CpIco src={"keys_logo_color.svg"} path={"static/img/"}/></a>
        </div>
    }

    const clickAgree = (!(checked && scrollToBottom && licenseAgree))
    ? undefined
    : (async e => { UI.stopEvent(e); 
        const [res, err] = await asyncApiCallLoad_(dispatch, '/putPrefs', {prefs:{agree: licenseAgree.Ver}},  )
        dispatch(gst_set('licenseAgree', 0)); 
    });
    const agreeTxt = toStr(licenseAgree && (langTxt(uiEn, licenseAgree.En, licenseAgree.Ct)) || t("license-agreement-content"));   
    const renderAgreement = () => { return <>
        <div className={"w-100"}><h4>{t("license-agreement")}</h4></div>
        <div className={"bg-white border px-3 py-3 overflow-auto flex-grow-1 mb-3"} style={{height: "100px"}}
                dangerouslySetInnerHTML={{__html: sanitize(agreeTxt)}} ref={agreementRef}>
        </div>
        <div className={"d-flex justify-content-start w-100 gap-3 mb-3"}>
            <CpCheckboxComponent variant={CHECKBOX_VARIANT.SQUARE} checked={checked}
                                className={"agreement-checkbox"} size={SIZE.SM}
                                onClick={() => setChecked(prev => !prev)}></CpCheckboxComponent>
            <span role={"button"} onClick={() => setChecked(prev => !prev)}>{t("license-agreement-checkbox")}</span>
        </div>
        <div className={"d-flex w-100 justify-content-end gap-3 mb-5"}>
            <CpActionButtonComponent variant={`${checked && scrollToBottom ? "login-btn-color" : "dim"}`}
                className={`btn-accept px-3 py-2 text-light border rounded bold text-center ${checked && scrollToBottom ? "" : ""}`} 
                title={t("accept")} onClick={clickAgree} />
            <CpActionButtonComponent className={`btn-decline px-3 py-2 border rounded text-center bold`} 
                title={t("decline")} variant={"gray-body-color"} onClick={clickdecline}/>
        </div>
    </>}

    if(input === 2)
        return <div className="hvh"><center  className="h100">
            <div className="w0 h100 vam"></div>
            <div className="w vam">
                <div>{t("logging-in")}...</div>
            </div>
        </center></div>;

    return <div className={`page-section ${licenseAgree? "agreement" :"login-bg"} d-flex flex-column w-100 vh-100 overflow-${logined? "hidden": "auto"}`}>
        <Navbar expand="lg"><Container fluid={true}>
                <Nav>{(licenseAgree)? renderHeaderBrand(): ''}</Nav>
                <Nav className="justify-content-end"><Nav.Link><CpSwitchLanguage /></Nav.Link></Nav>
        </Container></Navbar>
        <main
            className={`login-section ${licenseAgree? "agreement" : ""} d-flex flex-column flex-grow-1 ${logined? "overflow-auto" : ""} p-3`}>
            <div className={"d-flex flex-column justify-content-between align-items-center flex-grow-1"}>
                <span className={"d-lg-none d-block"}></span>
                {licenseAgree? renderAgreement():         <span className={`logo d-flex d-column justify-content-between my-4`}><CpIco src={"logo.svg"} path={"static/img/"}/></span>}
                {licenseAgree? '': 
                    noEnt? 
                    <CpNoEntitlement {...{t, close: clickGoLogin}}/>: 
                    renderLogin()
                }
                <CpLoginFooter {...{agree: licenseAgree, t, uiEn}}/>
            </div>
        </main>        
    </div>
});
export default PageLogin;


const CpNoEntitlement = ({t, close}) => { 
    return <div className={"no-entitlement d-flex flex-column justify-content-center align-items-center m-0 p-3 rounded"}>
        <div className={"d-flex flex-row w-100 justify-content-end align-items-end fs-4"}>
            <span role={"button"} onClick={close}><CpIco src={IconList.general.close}/></span>
        </div>
        <div className={"d-flex flex-column justify-content-center align-items-center px-4 pb-3"}>
            <div className={"text-danger mb-3 fs-2"}> <CpIco src={IconList.general.alert}/> </div>
            <span className={"semi-bold"}>{t("no-entitlement")}</span>
        </div>
    </div>
};

const CpRenderBrand = () => { return <div className={"brand-container d-flex flex-row justify-content-center align-items-center gap-5 mt-3 mt-md-5 mb-4"}>
    <a href={"#"} className={"oup-brand-logo"}><img src={OXFORD_LOGO}/></a>
    <a href={"#"} className={"keys-brand-logo"}><img src={KEYS_LOGO}/></a>
</div>};
const CpLoginFooter = props => {
    const {agree, t, uiEn} = props; 
    
    const lnks = uiEn? footlnksEn: footlnksCt;
    return <div className={"w-100"}>
        {(agree)? "": CpRenderBrand()}
        <small className={`d-flex flex-column justify-content-center ${agree? "flex-md-row  justify-content-md-between": ""} align-items-center align-items-md-start copyright`}>
            <div className={"d-flex flex-wrap text-light"}>  {t("copyright")} </div>
            <div className={"d-flex flex-wrap text-light gap-1"}>
                <a href={t("legal-notice-link")} target={"_blank"} className={"text-light text-decoration-none"}>{t("legal-notice")}</a>
                <span>|</span>
                <a href={lnks[1]} target={"_blank"} className={"text-light text-decoration-none"}>{t("cookie-policy")}</a>
                <span>|</span>
                <a href={t("privacy-policy-link")} target={"_blank"} className={"text-light text-decoration-none"}>{t("privacy-policy")}</a>
                <span>|</span>
                <a href={t("license-link")} target={"_blank"} className={"text-light text-decoration-none"}>{t("license")}</a>
                <span>|</span>
                <span className={""}>{webAppVer}</span>
            </div>
        </small>
    </div>;
};
const footlnksEn = [
    'https://global.oup.com/privacy?cc=hk',
    'https://global.oup.com/cookiepolicy/?cc=hk',
    'https://www.oupchina.com.hk/en/legal-notice?_ga=2.191712042.1600065310.1574758148-29872655.1571018788',
    'https://www.oupchina.com.hk/en/license',
];
const footlnksCt = [
    'https://fdslive.oup.com/www.oup.com/pdf/legal/privacy_language/chinesetrad.pdf',
    'https://fdslive.oup.com/www.oup.com/pdf/legal/cookie_language/chinesetrad.pdf',
    'https://www.oupchina.com.hk/zh/legal-notice?_ga=2.191712042.1600065310.1574758148-29872655.1571018788',
    'https://www.oupchina.com.hk/zh/license',
];

const CpSwitchLanguage = () => {
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const changeLanguage = e => { UI.stopEvent(e); setUILang(uiEn? "zh" : "en"); };
    return <>
        <CpActionButtonComponent variant={"login-learn-more-btn-color rounded-circle"}
            className={"switch-language-top d-flex justify-content-center align-items-center semi-bold fs-8"}
            onClick={changeLanguage}
            >{t(uiEn? 'language-zh': 'language-en')}</CpActionButtonComponent>
    </>
};
