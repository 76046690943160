import { toStr } from "./libType";

const screenSize = () => {
    try {
        return [window.screen?.width, window.screen?.height, ]
    }catch(e) {};
    return [0, 0];
}
const viewportSize = () => {
    try{
        return [
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
            window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight,
        ]
    }catch(e){};
    return [0, 0];
}
const hasTouch = () => {
    try{
        const tocuhEvent = ('ontouchstart' in window);
        const hasTouch = (navigator?.maxTouchPoints > 0);
        const hasMSTouch = (navigator?.msMaxTouchPoints > 0);
        const isTouchDevice = tocuhEvent || hasTouch || hasMSTouch ;
        
        return isTouchDevice? 1: 0;
    }catch(e){};
    return 0; 
}

export const getBrowserDevice = () => {
    const [sw, sh] = screenSize();
    const [vw, vh] = viewportSize();
    const t = hasTouch();
    
    const userAgent = toStr(navigator.userAgent);
    const device = {sw, sh, vw, vh, hasTouch:t, userAgent}; 
    return device;
    //document.getElementById("message").textContent = message;
    //document.getElementById("userAgent").textContent = "User Agent: " + userAgent;
    //document.addEventListener("DOMContentLoaded", function() 
};
