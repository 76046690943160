import { validLang } from "../../../AppExPf/utils/useUILang";
import { maxVal, objEntries, objVals, toAry, toFloat, toInt, toObj, toStr } from "../../../libs/libType";

export const toStuAsmData = (stuAsmData_, stu, _getTeacherName) => {
  const data = toAry(stuAsmData_).map((r,i) => {
    const {
      teacherId, APass, passP, avgMp,
      ALang, datePub,
      maxP, status
    } = r;
    const ret = {
      language: validLang(ALang),
      assignedBy: _getTeacherName(teacherId),
      averageScore: {percentage: avgMp},
      scoreRange: [{maxPercentage: maxP, status}],
      resultPublish: toInt(toInt(datePub)/1000),
      ...r
    }
    if(APass) ret.passingScore = {percentage: passP};
    return ret;
  });
  const stuAsmData = { type: "s", data }; 
  
  //return AssignmentProgressByStudentSharedData; 
  return stuAsmData;
};
  
const AssignmentProgressByStudentSharedData = {
    "type": "s",
    "data": [
      {
        "id": "a-001",
        "language": "en",
        "title": "Senior Secondary New Century Mathematics (2nd Edition) Book 6 Chapter 7 Revision Exercises",
        "resultPublish": 1683613978,
        "scoreRange": [
          {
            "maxPercentage": 44.9,
            "status": "f"
          }
        ],
        "averageScore": {
          "percentage": 61.3
        },
        "passingScore": {
          "percentage": 40
        }
      },
      {
        "id": "a-002",
        "language": "en",
        "title": "Senior Secondary New Century Mathematics (2nd Edition) Book 6 Chapter 7 Revision Exercises",
        "resultPublish": 1683613978,
        "scoreRange": [
          {
            "maxPercentage": 52.2,
            "status": "p"
          }
        ],
        "averageScore": {
          "percentage": 34.7
        },
        "passingScore": {
          "percentage": 44.7
        }
      },
      {
        "id": "a-003",
        "language": "en",
        "title": "Senior Secondary New Century Mathematics (2nd Edition) Book 6 Chapter 7 Revision Exercises",
        "resultPublish": 1683613978,
        "scoreRange": [
          {
            "maxPercentage": 33.9,
            "status": "f"
          }
        ],
        "averageScore": {
          "percentage": 61.3
        },
        "passingScore": {
          "percentage": 40
        }
      },
      {
        "id": "a-004",
        "language": "en",
        "title": "Senior Secondary New Century Mathematics (2nd Edition) Book 6 Chapter 7 Revision Exercises",
        "resultPublish": 1683613978,
        "scoreRange": [
          {
            "maxPercentage": 44.9,
            "status": "f"
          }
        ],
        "averageScore": {
          "percentage": 61.3
        }
      },
      {
        "id": "a-005",
        "language": "en",
        "title": "SMaths Mock Test - 2023/Nov",
        "resultPublish": 1683613978,
        "scoreRange": [
          {
            "maxPercentage": 76.5,
            "status": "a"
          }
        ],
        "averageScore": {
          "percentage": 58.1
        }
      }
    ]
  };