import Command from '@ckeditor/ckeditor5-core/src/command';

export default class CpCkAnswerCommand extends Command {
    execute(options = {}) {
        const { command, value, createAnswer} = options;
        const model = this.editor.model;
        
        switch (command) {
            case "insert":
                model.change(writer => {
                    model.insertContent(createAnswer(writer,value));
                });
                break;
            case "update": // no use right now
                break;
            default:
        }
    };
};
