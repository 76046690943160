import React, { useRef, useEffect, useState } from 'react';
import * as UI from "../../../libs/libUI";
import Ckeditor5Base from './Ckeditor5Base';
import "../../../css/extra.scss";
import * as FIBC from "../../../consts/ATQtnAnsTypeFIB";
import { preJS, autoId } from '../../AppUtil';
import { useMediaCache } from '../../utils/useMediaCache';
import CpMJX from '../../../AppExPFUser/_components/CpMJX';

//const presetData = "Please input text";
//https://www.youtube.com/watch?v=aEZw6KFTm6s
const presetData = '';
//const presetData = '<p>hello&nbsp;<math xmlns="http://www.w3.org/1998/Math/MathML"><msqrt><mi>a</mi><mi>a</mi></msqrt></math>&nbsp;world</p><p><math class="wrs_chemistry" xmlns="http://www.w3.org/1998/Math/MathML"><mrow><mo>→</mo><mo>⇌</mo></mrow></math>apple&nbsp;<math xmlns="http://www.w3.org/1998/Math/MathML"><msqrt><mi>b</mi><mi>b</mi></msqrt></math></p><p>&nbsp;</p><p>&nbsp;</p>';
//const presetData = '<p><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mstack charalign="center" stackalign="right"><msrow><mi>a</mi><mi>a</mi></msrow><msrow><mo>+</mo><mi>b</mi><mi>b</mi></msrow><msline>&nbsp;</msline><msrow><mi>c</mi><mi>c</mi></msrow></mstack><annotation encoding="application/vnd.wiris.mtweb-params+json">{"language":"en"}</annotation></semantics></math></p>';
//const presetData = '<p><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mstack stackalign="right" charalign="center"><msrow><mi>a</mi><mi>a</mi></msrow><msrow><mo>+</mo><mi>b</mi><mi>b</mi></msrow><msline>&nbsp;</msline><msrow><mi>c</mi><mi>c</mi></msrow></mstack><annotation encoding="application/vnd.wiris.mtweb-params+json">{"language":"en"}</annotation></semantics></math></p>';
//const presetData = '<p>Editor 1 Please input&nbsp;<span oupansid="oupAns" qorder="0" qtype="text" qvalue="answer xxx" class="oupanswer ck-widget"><span style="color:hsl(30, 75%, 60%);">answer xxx</span></span>&nbsp;text</p><p><math xmlns="http://www.w3.org/1998/Math/MathML"><msqrt><mi>a</mi><mi>a</mi></msqrt>{"language":"en"}</math></p>';
//const presetData = '<p>Editor 1 Please input&nbsp;<span oupansid="oupAns" class="oupanswer"><span style="color:hsl(30, 75%, 60%);">answer 1234</span></span>&nbsp;text</p><p><math xmlns="http://www.w3.org/1998/Math/MathML" class="wrs_chemistry"><mfenced><mtable><mtr><mtd><mi>aa</mi></mtd><mtd><mi>bb</mi></mtd></mtr><mtr><mtd><mi>cc</mi></mtd><mtd><mi>dd</mi></mtd></mtr></mtable></mfenced></math></p>';
//const presetData = '<p>Please input&nbsp;<span oupansid="oupAns" class="answer oup"><span class="answerContent"><span style="color:hsl(30, 75%, 60%);">answer x</span></span></span>&nbsp;text</p><p><math xmlns="http://www.w3.org/1998/Math/MathML" class="wrs_chemistry"><mfenced><mtable><mtr><mtd><mi>aa</mi></mtd><mtd><mi>bb</mi></mtd></mtr><mtr><mtd><mi>cc</mi></mtd><mtd><mi>dd</mi></mtd></mtr></mtable></mfenced></math></p>';
//const presetData = '<p>Please input text</p><p><math xmlns="http://www.w3.org/1998/Math/MathML" class="wrs_chemistry"><mfenced><mtable><mtr><mtd><mi>aa</mi></mtd><mtd><mi>bb</mi></mtd></mtr><mtr><mtd><mi>cc</mi></mtd><mtd><mi>dd</mi></mtd></mtr></mtable></mfenced></math></p>';
//const presetData = '<p>Please input&nbsp;<span oupansid="oupAns" class="answer oup" stype="color:hsl(30, 75%, 60%);">answer x</span>&nbsp;text</p><p><math xmlns="http://www.w3.org/1998/Math/MathML" class="wrs_chemistry"><mfenced><mtable><mtr><mtd><mi>aa</mi></mtd><mtd><mi>bb</mi></mtd></mtr><mtr><mtd><mi>cc</mi></mtd><mtd><mi>dd</mi></mtd></mtr></mtable></mfenced></math></p>';
const str_latex = "$$\\psi=-\\frac{1}{\\varphi}$$";
const str_asciiMath = "$$n\\cdot90^\\circ\\pm\\theta$$";
const str_asciiMath2 = "hello \\(f\\left(x\\right)\\) world";

const initArray = ["id0","id1","id2","id3","id4","id5","id6"];

const defaultAnsArr = [
    {oupansid:0, qtype:"text", qvalue:"hello world"},
    {oupansid:1, qtype:"combo", qvalue:"apple"},
    {oupansid:2, qtype:"combo", qvalue:"black adam"},
];

const getMaxValue = (arr, fieldName) => {
    let max = 0;
    if (arr.length > 0) {
        arr.forEach((obj)=>{ if (max < obj[fieldName]) max = obj[fieldName];});
    };
    return max;
};

const PageTest = (props) => {
    const [testv, setTestv] = useState("init value");

    const [qData, setQData] = useState(presetData);
    const [onAddMedia, setOnAddMedia] = useState(0);
    const dispatch = props.dispatch;
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch);
    const mdProps = {mediaDLs, getMediaDLs, onAddMedia, setOnAddMedia};
    const quesEditor = useRef(null);
    const answerEditor = useRef(null);
    const equationEditor = useRef(null);

    const domQuesRef = useRef(null);

    //const [ansArr, setAnsArr] = useState([]);
    //const [keyIndex, setKeyIndex] = useState(0);
    //const [ansIndex, setAnsIndex] = useState(undefined);
    const [ansArr, setAnsArr] = useState(defaultAnsArr);
    const [answer, setAnswer] = useState(undefined);
    const [selected, setSelected] = useState(-1); // selected ques id
    const [keyIndex, setKeyIndex] = useState(getMaxValue(defaultAnsArr, 'oupansid'));
    const [myMedia, setMyMedia] = useState({oupid:'', url:''});
    // no use now
    const [sequence, setSequence] = useState(initArray||[]);

    let mounted = 1;  useEffect(() => { return (() => {mounted = 0; }); }, []); // Avoid Async Call Back Error

    useEffect(() => {
        console.log("testv: ", testv);
        //insertAnswerComponent();
    }, [testv]);

    useEffect(() => {
        console.log("ansArr updated !!: ", ansArr);
        //insertAnswerComponent();
    }, [ansArr]);

    useEffect(() => {
        console.log("selected updated !!: ", selected);
        //insertAnswerComponent();
        setAnswer(getAnsData(selected));
    }, [selected]);

    useEffect(() => {
        
        insertAnswerComponent();
    }, [qData]);    

    useEffect(() => {
        
    }, [selected]);

    useEffect(() => {
        
    }, [sequence]);

    useEffect(() => {
        
    }, [answer]);
   
    const replaceEleClick = (e) => {
        console.log("replaceEleClick !!", e);       
    };

    const testvChange = (e, oupansid) => {
        console.log("oupansid value got updated:", oupansid, e.currentTarget.value);
    };

    const insertAnswerComponent = () => {
        if (domQuesRef.current) {
            
            let allAns = domQuesRef.current.getElementsByClassName("oupanswer");
            if (allAns.length > 0) {
                // must convert to array
                // don't use HTMLCollection directly, it will sync as the element got replaced
                Array.from(allAns).forEach((ans, ii)=>{
                    
                    
                    
                    const el = document.createElement('input');
                    el.setAttribute('id', "ansID-"+ans.getAttribute('oupansid'));
                    el.setAttribute('class', 'insertEle');
                    el.setAttribute('type', 'text');
                    el.addEventListener('change', (e)=>testvChange(e, ans.getAttribute('oupansid')));
                    el.setAttribute('value', ans.getAttribute('qvalue'));
                    el.setAttribute('placeholder', 'input here');
                    el.addEventListener('click', replaceEleClick);
                    console.log("el:", el);
                    ans.replaceWith(el);
                    //ans.appendChild(testEle);
                });
            };
        } else {console.log("domQuesRef.current is undefined.")};
    };

    const updateAnsArray = (value) => {
        const tmp = getAnsData(selected);
        tmp && (tmp.qvalue = value);
    };

    const setAnsValue = (value) => {
        
        if (answer) {
            const tmp = {...answer, qvalue:value};
            updateAnsArray(value);
            const ckanswer = quesEditor.current.plugins.get('cpCkAnswer');
            ckanswer && ckanswer.updateAnswer(tmp);
            setAnswer(tmp);
        };
    };

    const getAnsData = (id) => {return ansArr.find((obj)=>{return obj.oupansid === id})};
    
    const ck_ansClick = (editor, id) => {
        setSelected(id);
    };

    const ck_newAnswer = (oupansid, qtype=FIBC.__FIBT_TXT,qvalue="blank") => {
        return {oupansid:oupansid, qtype:qtype, qvalue:qvalue};     
    };

    const allDone = (arr, index) => {
        setAnsArr([...arr]);
        setKeyIndex(index);
    };

    const ck_allDone = (arr, index) => {
        console.log("update index and ansArr:", index, arr);
        allDone(arr, index);
        //ansArr.length = 0;
    }; 

    const ck_getAnsArr = () => { return ansArr; };
    const ck_getKeyIndex = () => { return keyIndex; };
    const ckAnswerConfig = {
        newAnswer: ck_newAnswer,
        onClick: ck_ansClick,
        allDone: ck_allDone,
        getAnsArr: ck_getAnsArr,
        getKeyIndex: ck_getKeyIndex,
    };
   
    const onEditorReady = (editor) => {
    };

    const quesGetData = (e) => {
        const raw = quesEditor.current.getData();
        
        //const clean = cleanData(raw);
        setQData(raw);
        
    };
    //let inputValue = ansIndex?ansArr[ansIndex].qvalue:"";
    return <div style={cssPageTest}>
        {false && preJS({ansArr}, 3)}
        <p><b>question editor</b></p>
        <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
            <div className="flexColStart" style={{width:"70%"}}>
                <Ckeditor5Base {...props} editorType="testlag" data={qData} myClass=""
                enable={true} setEditor={quesEditor} 
                 onEditorReady={onEditorReady}  debug={true}/>
                <div className="flexRowStart" style={{padding:"5px"}}>
                    <div className="flexRowStart">{"answer id: " + selected}</div>
                    <div className='flexRowStart'>value:{UI.EpInputTxt0(answer?answer.qvalue:"", setAnsValue)}</div>
                </div>
                <button type="button" onClick={quesGetData}>Get Answer Editor Data</button>  
                <button type="button" onClick={()=>ck_ansClick(undefined,0)}>test click</button>  
                <div style={{border:"1px solid grey"}}>{qData}</div>
            </div>
            <div className="flexColStart" style={{width:"30%"}}>
                <CpMJX dynamic={true} key={autoId()}><div className="ck-content" ref={domQuesRef} style={{border:"1px solid grey", width:'100%'}} dangerouslySetInnerHTML={{__html: qData}} /></CpMJX>
            </div>
        </div>     
    </div>;
};
const cssPageTest={ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", width: "100%", padding: "10px 10px 100px 10px"};

export default PageTest;


const testData001 = '';