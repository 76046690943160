import {Form, FormCheckProps} from "react-bootstrap";
import {SIZE, Size} from "../../../../../../type/Size";
import {useMemo} from "react";
import {PREFIX} from "../../../../../../type/Prefix";

interface Props extends Omit<FormCheckProps, "size"> {
    size?: Size
    variant?: string
}

const SwitchComponent = (props: Props) => {
    const {size = SIZE.MD, variant = "", className = "", ...switchProps} = props

    const switchStyle = useMemo(() => {
        const gapStyle = (size === SIZE.XS || size === SIZE.SM || size === SIZE.MD) ? "gap-2" : "gap-3"

        return [
            `switch-component`,
            `d-flex`,
            `justify-content-center`,
            `align-items-center`,
            gapStyle,
            `${variant ? `cus-switch-${variant}` : ""}`,
            PREFIX.SWITCH + size,
            className].filter(item => item).join(' ')
    }, [])

    return <Form.Check
        type="switch"
        className={switchStyle}
        {...switchProps}
    />
}

export default SwitchComponent