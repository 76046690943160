import {useEffect, useState, useMemo, useRef} from "react";
//import { debounce } from "./useWinResize";
import { secondToHHMMSS, timeStampNowGMT } from "../../libs/libTime";
import { maxVal, minVal } from "../../libs/libType";

export const fabricDebonce = 2500; // fabric debouce interval
export const ckDebounce = 2500; // ckeditor debouce interval
export const hbIntv = 15000; // heart beat interval
export const asIntv = 5000; // auto save interval

// auto save only, no heart beat
export const useAutoSaveEffect = saveCB => {
    const [timer, setTimer] = useState(undefined);

    const _save = () => { saveCB(); };

    const doDebounceSave = debounce(_save, timer, setTimer, 5000);
    
    const stopDebounceSave = () => {
        if (timer) clearTimeout(timer);
    };

    return {doDebounceSave, stopDebounceSave};
};

const debounce = (func, timer, setTimer, delayMS=400) => {
    return event => {
        
        if (timer) clearTimeout(timer);
        setTimer(setTimeout(func, delayMS, event));
    };
};

// auto save + heart beat
export const useASHBEffect = (saveCB, hbCB) => {
    const [timeHB, setTimeHB] = useState(0);
    const [timeAS, setTimeAS] = useState(0);
    const [timer, setTimer] = useState();
    const [saveFlag, setSaveFlag] = useState('');

    useEffect(() => {
        if(saveFlag) setSaveFlag('');        
        if(saveFlag === 'chk') chkSave();            
        if(saveFlag === 'AS') saveCB(); 
        if(saveFlag === 'HB') hbCB(); 
    }, [saveFlag]);

    const stopTimer = () => {if(timer){ clearTimeout(timer); setTimer(0); }; };
    const chkSave = () => {
        const now = timeStampNowGMT();
        const doAS = timeAS && (timeAS <= now);
        const doHB = (!doAS) && timeHB && (timeHB <= now);
        const nextAS = doAS? 0: timeAS;
        const save = doAS? 'AS': doHB? 'HB': '';
        const nextHB = save? (now + hbIntv) :timeHB 
        const saveIntv = minVal((nextAS || asIntv), nextHB - now);  
        const nextIntv = minVal(minVal(hbIntv, asIntv), saveIntv);

        if(doAS) setTimeAS(0);
        if(save) setTimeHB(now + hbIntv);
        if(save) setSaveFlag(save);

        startTimer(100 + maxVal(0, nextIntv));
    };
    const startTimer = (timeout) => {
        stopTimer();
        setTimer(setTimeout(() => { setSaveFlag('chk'); }, timeout));
    };
    const stopASHB = () => {
        stopTimer();
    } 
    const debounceSave = () => setTimeAS(timeStampNowGMT() + asIntv); // re schedule next auto save
    const startASHB = () => {
        setTimeAS(0);
        setTimeHB(timeStampNowGMT() + hbIntv);
        startTimer(minVal(hbIntv, asIntv));
    }
    const forceAS = (whySaveWk) => { 
        setTimeAS(0); 
        saveCB(whySaveWk); 
    };
    return {startASHB, stopASHB, debounceSave, forceAS};
};

export const useASHBEffect_v1 = (saveCB, hbCB) => {
    const timer = useRef(null);
    const lastT = useRef(null);
    const debounceShift = useRef(asIntv);

    useEffect(()=>{
        return () => {
            
            if (timer.current) {
                
                clearInterval(timer.current);
            };
        };
    },[]);

    useEffect(()=>{}, [timer.current]);
    useEffect(()=>{}, [lastT.current]);

    const _ashb = () => {
        const now = timeStampNowGMT();
        const timePass = now - lastT.current;

        const hb = timePass >= hbIntv;
        const as = timePass >= debounceShift.current;
        if (as) {
            saveCB && saveCB(now);
            debounceShift.current = asIntv;
            lastT.current = now; // update lastT after auto save
        } else if (hb) {
            hbCB && hbCB(now);
        };
    };
    
    const startASHB = () => {
        if (timer.current) clearInterval(timer.current);
        lastT.current = timeStampNowGMT();
        timer.current = setInterval(_ashb,5000);
    };
    
    const stopASHB = () => {
        if (timer.current) {
            
            clearInterval(timer.current);
        };
    };

    const debounceSave = () => {
        // data edit again before autosave, postpone save time check
        const now = timeStampNowGMT();
        const timePass = now - lastT.current;
        debounceShift.current = debounceShift.current + timePass;
    };

    return {startASHB, stopASHB, debounceSave};
};
