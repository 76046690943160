import { deepCopy, mapTagAtt, rmMediaSrc } from "../libs/libHtml";
import { isAry, toAry, toObj } from "../libs/libType";
import { __MCAT_ATG, __MCAT_ATI } from "./ATQtnAnsTypeMCQ";
import { toUniIdAry } from "./ATValidate";

export const rmQtnMediaSrcs = fullQ => {
  const {QEn, QCt, ...retQ} = deepCopy(fullQ);
  return { ...retQ, 
      QEn: qBodyLangRmS3(retQ.QLangEn, QEn),
      QCt: qBodyLangRmS3(retQ.QLangCt, QCt),
  };
};
const qBodyLangRmS3 = (hasQLang, qLang) => {
  if(!hasQLang) return {};

  const retQL = deepCopy(qLang);
  const {qData, qAnswers, AKFile} =  retQL;

  if(qData) retQL.qData = rmMediaSrc(retQL.qData);

  if(isAry(qAnswers)){
      qAnswers.forEach(o => {
          const t = o.type;
          if(t === __MCAT_ATG){
              // o.src = '';
          }else if (t === __MCAT_ATI){
              o.data = {...toObj(o.data), url:''};
          }
      });
  }

  const akfs = AKFile?.[0]?.file;
  if(akfs){
      retQL.AKFile[0].file.forEach(f => { 
          f.src = '';
      });
  }

  return retQL;
};

export const qtnMapMedias = (fullQ, mMap, draw2Blob, localDrawId) => {
  const drawBlobs = {};
  const drawUtil = {draw2Blob, localDrawId};
  const [QEn, qEnMap] = qBodyLangMapMedia('en', fullQ.QLangEn, fullQ.QEn, mMap, drawBlobs, drawUtil); 
  const [QCt, qCtMap] = qBodyLangMapMedia('ct', fullQ.QLangCt, fullQ.QCt, mMap, drawBlobs, drawUtil);

  const retFullQ = {...deepCopy(fullQ), QEn, QCt};
  const qMediaId = toUniIdAry( [...toAry(qEnMap), ...toAry(qCtMap)] );
  
  return [retFullQ, qMediaId, drawBlobs];
};
  
const qBodyLangMapMedia = (lang, hasLang, inQLang, mMap, drawBlobs, drawUtil) => { //return [outQ:ang, outmMap]
  if(!(hasLang && inQLang)) return [inQLang, []];

  const {draw2Blob, localDrawId} = drawUtil;
  const mSrcs = [];
  const mapM = src => { 
      src && mSrcs.push(src); 
      return (src && mMap[src]) || src;
  };
  
  const outQLang = deepCopy(inQLang);
  const {qData, qAnswers, tAnswers, AKFile, BGMediaID, qDrawS3Id, qDrawData, ...noMap} = outQLang;
  
  const [mmqd, qData2] = mapTagAtt(qData, 'oupid', mMap);
  outQLang.qData = qData2;
  mSrcs.push(...mmqd);

  if(isAry(qAnswers)){
      qAnswers.forEach(o => {
          const t = o.type;
          if(t === __MCAT_ATG){ // input type general, with CKeditor
              const [mmo, data2] = mapTagAtt(o.data, 'oupid', mMap);
              o.data = data2;
              mSrcs.push(...mmo);
          }else if (t === __MCAT_ATI){ // input type image
              o.data.oupid = mapM(o.data.oupid);
          }
      });
  }
  // for mctable tAnswers data, only do row 0, all col (header) and row > 0, all col 0
  if(isAry(tAnswers)){
    tAnswers.forEach((r, ii) => { // row
      if (isAry(r)) {
        if (ii===0) {
          r.forEach((c, jj) => { // col
            const [mmo, data2] = mapTagAtt(c.data, 'oupid', mMap);
            c.data = data2;
            mSrcs.push(...mmo);
          });
        } else {
          const [mmo, data2] = mapTagAtt(r[0].data, 'oupid', mMap);
          r[0].data = data2;
          mSrcs.push(...mmo);
        };
      };
    });
  };
  const akfs = AKFile?.[0]?.file;
  if(akfs){
      outQLang.AKFile[0].file.forEach(f => { f.mediaId = mapM(f.mediaId); });
  }

  if (BGMediaID) { // for LDB image
    outQLang.BGMediaID = mapM(BGMediaID); 
  };

  if(qDrawData && (!qDrawS3Id)){ // for OPD draw
    outQLang.qDrawS3Id = localDrawId('mapDraw'); // assign id to new draw
    drawBlobs[outQLang.qDrawS3Id] = draw2Blob(outQLang.qDrawS3Id, qDrawData); //move to drawBlobs to upload
    outQLang.qDrawData = ''; //clear mapped local draw  
  } 
  outQLang.qDrawS3Id = mapM(outQLang.qDrawS3Id); //map id anyway, regareless local or not

  const QLangMediaId = toUniIdAry(mSrcs);
  
  return [outQLang, QLangMediaId];
};
