import React from 'react';
import { toObj, toStr } from '../../libs/libType';
import { subState } from '../utils/useChain';
import {exerTitle, exerBlock, viewExLabel, exATUIText, exTextArea, popA, prefixURL} from './ViewExCommon';
import { useUILang } from '../utils/useUILang';

const ViewExCtnLnk = props => {
    const {lock, showEn, ECtn, replaceECtn, PVMode, idx, setPlayed=()=>{}} = props; 
    const [ t ] = useUILang();
    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
    const lang = showEn?'en':'ct';
    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));
    const displayTitle = ec.displayTitle?ec.displayTitle:0;
    const url = prefixURL(toStr(ec.url));
    const title = toStr(ec.title);
    const desc = toStr(ec.desc);
    if(lock || PVMode >=0) {
        return <div className={'ViewExCtnItemLock f18 ' + (PVMode!==1?' Single':' List')}>
            <div id={'exCtnContent'+idx} className='ViewExCtnItemLockContent'>
                {displayTitle?<div className='flexRowStart semi-bold'>
                    {PVMode===1?exerTitle(title):title}</div>:''}
                {popA('url'+ECtn.idx, url, url, ()=>{ setPlayed(idx); })}
                <div className='flexRowStart'>{PVMode>=0?exerBlock(desc):desc}</div>
            </div>
        </div>;
    };

    return <div className='ViewExCtnItem'>
        <p/>
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.hyperlink.title'),'title')}
            {exATUIText(ec.title, setTmpField('title'), 'vid')}
        </div>
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.hyperlink.desc'),'desc')}{exTextArea(ec.desc, setTmpField('desc'), 'desc')}
        </div>
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.hyperlink.url'),'url')}{exATUIText(ec.url, setTmpField('url'), 'url')}
        </div>            
    </div>
};
export default ViewExCtnLnk; 
