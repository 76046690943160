import { objEntries, toAry } from "../../libs/libType";

//Generic Tree Walk Funciton 
export const walkTreeFunc = (list, branch, id, lv, func) => {
    Object.entries(branch).forEach( ([key, val]) => {
        const subBranch = func(list, val, key, lv+1, id);
        subBranch && walkTreeFunc(list, subBranch, key, lv+1, func);
    });
};
export const walkTreeUpFunc = (params, node, func) => {
    const parentNode = func(params, node); //work on the node, and get parent Node
    parentNode && walkTreeUpFunc(params, parentNode, func); //contiune if has Parent Node
};

//==============================================================================================================
// Meta Tree Build Function
export const buildMetaTree = (metaSets, metas ) => {
    const MSs = {};
    metaSets.forEach(s => { 
        s.metas = {}; //store for metas
        MSs[s.metaSetId] = s; //addToMap
    });
    metas.forEach(m => { MSs[m.metaSetId] && (MSs[m.metaSetId].metas[m.metaId] = m); } ); //add Meta to Set
    metaSets.forEach(s => walkTreeFunc(s.metas, s.tree, '', 0, hookMetaTree) ); // Hook Tree to Metas
    return MSs;
};
const hookMetaTree = (list, branch, id, lv, pId) => {
    list[id] = {...(list[id]||{}), childs:branch, lv, p:pId};
    return branch;
};

