import { DONUT_RING_COLOR } from "./ChartTypeConst";

const ChartDonut = (props) => { // base on poc DonutChart.tsx
    const {line = [], title, subTitle, segments = [], setting} = props;
    const {ringColor = DONUT_RING_COLOR} = setting || {};
    const r = 15.91549430918954;
    const strokeWidth = 3.5;
    const baseDeg = 25;

    const newSegments = segments.map((segment, idx) => {
        let sum = 0;
        for (let i = 0; i <= idx; i++) {
            sum += segments[i].value;
        };
        return {...segment, value: sum};
    }).reverse();

    return <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
        <circle className="donut-hole" cx="20" cy="20" r={r} fill="#fff"></circle>
        <circle className="donut-ring" cx="20" cy="20" r={r} fill="transparent"
                strokeWidth={strokeWidth} stroke={ringColor}></circle>

        {Array.isArray(newSegments) === true && newSegments.map((segment, idx) => 
        <circle key={idx}
            className="donut-segment"
            cx="20" cy="20"
            r={r}
            fill="transparent"
            stroke={segment.color}
            strokeWidth={strokeWidth}
            strokeDasharray={`${segment.value} ${100 - segment.value}`}
            strokeDashoffset="0%" transform={`rotate(-90)`}></circle>
        )};

        {line !== undefined && Array.isArray(line) === true && line.map((li, idx) => <g className="marks"
                                                                                        stroke={li.color} key={idx}>
            <line x1={r - strokeWidth / 2} y1="0" x2={r + strokeWidth / 2} y2="0" strokeDasharray="0.3"
                  strokeWidth={0.3}
                  style={{transform: `rotate(calc(${((li.value - 25) / baseDeg) * 90}deg))`}}/>
        </g>)

        }

        {title !== undefined && <g className="ChartDonut donut-text donut-text-1" fill={title.color}>
            <text y="50%" transform="translate(0, 2)">
                <tspan x="50%" textAnchor="middle" className="donut-percent">{title.value}</tspan>
            </text>
            {subTitle !== undefined && <text y="60%" transform="translate(0, 2)" fill={subTitle.color}>
                <tspan x="50%" textAnchor="middle" className="donut-data">{subTitle.value}</tspan>
            </text>}
        </g>}
    </svg>
};

export default ChartDonut;