import React, { useState, useCallback, useEffect } from "react";
import SwitchComponent from "../../../schema/components/custom/form/switch/SwitchComponent";
import { SIZE } from "../../../../type/Size";
import { useTranslation } from 'react-i18next';

interface Props {
  onScoreToggle: (isVisible: boolean) => void;
  onClassAverageToggle: (isVisible: boolean) => void;
  scoreVisible: boolean;
  classAverageVisible: boolean;
}

const AverageScoreVisibilityToggle: React.FC<Props> = ({
  onScoreToggle,
  onClassAverageToggle,
  scoreVisible,
  classAverageVisible
}) => {
  const { t } = useTranslation(["schema"]);
  const [enableScoreVisible, setEnableScoreVisible] = useState(scoreVisible);
  const [enableClassAverageVisible, setEnableClassAverageVisible] = useState(classAverageVisible);

  useEffect(() => {
    setEnableScoreVisible(scoreVisible);
  }, [scoreVisible]);

  useEffect(() => {
    setEnableClassAverageVisible(classAverageVisible);
  }, [classAverageVisible]);

  const handleScoreToggle = useCallback(() => {
    const newValue = !enableScoreVisible;
    setEnableScoreVisible(newValue);
    onScoreToggle(newValue);
  }, [enableScoreVisible, onScoreToggle]);

  const handleClassAverageToggle = useCallback(() => {
    const newValue = !enableClassAverageVisible;
    setEnableClassAverageVisible(newValue);
    onClassAverageToggle(newValue);
  }, [enableClassAverageVisible, onClassAverageToggle]);

  return (
    <div className="score-visibility-toggle">
      <span
        className="d-flex gap-2 semi-bold user-select-none"
        role="button"
        onClick={handleScoreToggle}
      >
        <SwitchComponent
          variant="exercise-third-btn pe-none"
          size={SIZE.XS}
          checked={enableScoreVisible}
          readOnly={true}
          label={`${t("report-view-score")}:`}
        />
      </span>
      <span
        className="d-flex gap-2 semi-bold user-select-none"
        role="button"
        onClick={handleClassAverageToggle}
      >
        <SwitchComponent
          variant="exercise-third-btn pe-none"
          size={SIZE.XS}
          checked={enableClassAverageVisible}
          readOnly={true}
          label={`${t("report-view-class-average-score")}:`}
        />
      </span>
    </div>
  );
};

export default AverageScoreVisibilityToggle;