import React, { useState, useMemo } from "react";
import { useUILang } from "../../../AppExPf/utils/useUILang";
import TableHeaderSortingButton from "../../../poc/screens/shared/includes/chart/shared/TableHeaderSortingButton";
import { CpOptionsContainer } from "../PageReportTeacher";
import { obj2DropdownItem } from "../../_components/CpDropdown";
import { objEntries, objKeys, objVals, toAry } from "../../../libs/libType";
import DividedBarChart from "../../../poc/screens/shared/includes/chart/DividedBarChart";
import CpTableHeaderSortingButton from "./CpTableHeaderSortingButton";
import { strCmpTrimLow, useSortList } from "../../EPAssign/TabTeacherAssignmentStatus";
import { CpSortingIcon } from "../../EPReport/TabPerformanceDetailChart";
import CpTips from "./CpTips";

import ReportLegend from "../../../poc/screens/shared/includes/chart/legend/ReportLegend";
import { LegendType } from "../../../poc/type/LegendType";
import { expfRoles } from "../../../saga/auth.saga";
import { getTransMap } from "../../../libs/libTransMap";

const ChartClassPerformanceDetails = (props) => { //poc ClassPerformanceDetailsChart
    const [isThr, isStt, uRole ] = expfRoles(props);
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const { rptM, rptMDDI, setRptM } = props;

    const [ sorts, setSorts, addSort, flipSort] = useSortList({no:'asc'});
    const _data = props.data;
    const data = useMemo(() => {
        
        return toAry(_data);
    }, [_data, sorts]);

    const _stu = props.student;
    const student = useMemo(() => sortStus(_stu, sorts), [_stu, sorts]);

    const renderData = () => student.map(stu => {
        const {id, no, name} = stu
        const studentData = data.find(d => d.referenceId === id)
        const {assignment = [], scoreRange, averageScore, classAverageScore} = studentData || {}
        const total = assignment.reduce((acc, curr) => acc + (curr.score?.value ?? 0), 0);

        let prevPercentage = 0;
        const assignmentSegments = assignment.map(assign => {
            const {score} = assign;
            const {percentage = 0} = score;
            const segment = {
                value: assign.score.value,
                minPercentage: prevPercentage,
                maxPercentage: prevPercentage + percentage,
                status: assign.status
            };
            prevPercentage += percentage;
            return segment;
        });
        
        // issue EX-1382, all scoreRange status change to uc
        const scoreSegments = [{
            minPercentage: scoreRange?.minPercentage,
            maxPercentage: scoreRange?.maxPercentage,
            status: "uc" //scoreRange?.status
        }];

        return <tr key={id}>
            <td>{no}</td>
            <td>{name}</td>
            <td>{total}</td>
            <td><DividedBarChart segments={assignmentSegments}/></td>
            <td><DividedBarChart segments={scoreSegments} averageScore={classAverageScore}
                statusScore={averageScore} status={scoreRange?.status || "n"}/>
            </td>
            <td className={`${scoreRange?.status ? "" : "text-status-n"}`}>                
                {typeof averageScore?.percentage === "number" && averageScore?.percentage >= 0 ? averageScore?.percentage : "-"}%
            </td>
        </tr>
    });
     

    const CpTableHeaderSortingButton = (props) => { //TableHeaderSortingButton 
        const { sortKey } = props;
        const { title, type, tips} = props
        const onClick = sortKey? flipSort(sortKey): undefined;
        return <span onClick={onClick} role={sortKey? "button": "none"} 
            className="CpTableHeaderSortingButton d-flex justify-content-center align-items-center gap-2 user-select-none" >
            {title}
            {tips? <CpTips>{tips}</CpTips>: ''}
            {(sortKey)? <CpSortingIcon {...{sorts, addSort, flip:flipSort, sortKey}} />: ''}
        </span>
    };
    return <>
        <div><CpOptionsContainer className={"ChartClassPerformanceDetails option-period"} title={t("period")}  options={rptMDDI} cbItem={setRptM} activeItem={rptM} /></div>
        <div className={"flow-container scroll-padding"}>
            <table className={"report report-table-progress-chart class-progress-chart semi-bold"}>
                <thead><tr>
                    <th className={"col-class-no"}><span>
                        <CpTableHeaderSortingButton {...{title:t("report-student-no"), type:"report-student-no", sortKey:'no'}}/>
                    </span></th><th className={"col-student-name"}><span>
                        <CpTableHeaderSortingButton {...{title:t("report-student"), type:"report-student", sortKey:'name'}}/>
                    </span></th><th className={"col-submitted"}><span>
                        <CpTableHeaderSortingButton {...{title:t("report-assigned"), type:"report-assigned", sortKey:'_sub'}}/>
                    </span></th><th className={"col-assignments-score"}><span>
                        <CpTableHeaderSortingButton {...{title:t(getTransMap("report-assignments-score",isStt)), type:"report-assignments-score", sortKey:'', tips:<ReportLegend legend={LegendType.COL_ASSIGNMENTS}/>}}/>
                    </span></th><th className={"col-score-range"}><span>
                        <CpTableHeaderSortingButton {...{title:t("report-score-range"), type:"report-score-range", sortKey:'', tips:<ReportLegend legend={LegendType.COL_SCORE_RANGE}/>}}/>
                    </span></th><th className={"col-average-score"}><span>
                        <CpTableHeaderSortingButton {...{title:t("report-average-score-percentage"), type:"report-average-score-percentage", sortKey:'_avgMp', }}/>
                    </span></th>
                </tr>
                </thead>
                <tbody>{renderData()}</tbody>
            </table>
        </div>
    </>
};
export default ChartClassPerformanceDetails;


const sortStus = (_stu, sorts) => {
    const ss = objEntries(sorts); 
    
    const ret = [...toAry(_stu)];
    ret.sort( (a, b) => 
        sort1(a, b, ss[0]) || sort1(a, b, ss[1]) || sort1(a, b, ss[2]) || 
        sort1(a, b, ss[3]) || sort1(a, b, ss[4]) || sort1(a, b, ss[5]) ||
        sort1(a, b, ss[6]) || (ss[7]&&sort1(a, b, ss[7])) || (ss[8]&&sort1(a, b, ss[8])));
    
    return ret; 
};

const sort1 = (a, b, kv) => {
    if(!kv) return;
    const [key, so] = kv;
    const isAsc = so !== 'desc';
    //if (isAsc && (b[key] === -1)) return 0;
    //if (!isAsc && (a[key] === -1)) return 0;
    if(['no', 'name'].includes(key)) return (isAsc? 1: -1) * strCmpTrimLow(a[key], b[key]);
    return isAsc? a[key] - b[key]: b[key] - a[key];
};



