import {createContext, Dispatch, ReactNode, useReducer} from "react";
import {StudentAssignment, TeacherAssignment} from "../type/Assignment";


type AssignmentStateType = {
    assignment: TeacherAssignment | StudentAssignment | { active: boolean }
}

export enum AssignmentActionKind {
    INIT_ASSIGNMENT = 'INIT_ASSIGNMENT',
    CHANGE_DEADLINE = 'CHANGE_DEADLINE',
    END_ASSIGNMENT = 'END_ASSIGNMENT',
    DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT'
}

interface AssignmentActionType {
    type: AssignmentActionKind;
    payload?: any;
}

const AssignmentState: AssignmentStateType = {
    assignment: {active: false}
}

const AssignmentContext = createContext<{
    state: AssignmentStateType;
    dispatch: Dispatch<AssignmentActionType>;
}>({
    state: AssignmentState,
    dispatch: () => null
})

const AssignmentReducer = (state: AssignmentStateType, action: AssignmentActionType): AssignmentStateType => {
    const {assignment} = state
    switch (action.type) {
        case AssignmentActionKind.INIT_ASSIGNMENT:
            return {...state, assignment: action.payload}
            break;
        case AssignmentActionKind.CHANGE_DEADLINE:
            return {...state, assignment: action.payload}
            break;
        case AssignmentActionKind.END_ASSIGNMENT:
            return {...state, assignment: action.payload}
            break;
        case AssignmentActionKind.DELETE_ASSIGNMENT:
            console.log("DELETE_ASSIGNMENT")
            return {...state, assignment: {active: false}}
            break;
        default: {
            return state
        }
    }
}

const AssignmentProvider = (props: { children: ReactNode }) => {
    const {children} = props
    const [state, dispatch] = useReducer(AssignmentReducer, AssignmentState)
    const value = {state, dispatch}

    return <AssignmentContext.Provider value={value}>{children}</AssignmentContext.Provider>
}

export {AssignmentProvider, AssignmentContext}