import React, {useRef, useEffect, useState, useMemo} from "react";
import { isAry, toAry, toStr } from "../../../libs/libType";
import { stopEvent } from "../../../libs/libUI";
import { groupData } from "../../../poc/helper/groupByNumberArray";
import RadialChart from "../../../poc/screens/shared/includes/chart/RadialChart";
import { IconList } from '../../../consts/ATIconListToUsePoc';
import CpActionButton from "../../_components/CpActionButton";
import useClickOutside from "../../../poc/hooks/useClickOutside";
import { preJS, autoId } from "../../../AppExPf/AppUtil";
import CpIco from "../../_components/CpIco";
import { useUILang } from "../../../AppExPf/utils/useUILang";
import CpMJX from "../../_components/CpMJX";


const ChartMetadataRadialGroup = (props => { //poc MetadataRadialGroupChart
    const [ t ] = useUILang();
    const { clickM } = props;
    const { label } = props;
    const _d = props.data;

    const data = toAry(_d);
const [groupedData, sumDataLength, titleList] = useMemo(() => {
        const groupedData = data.map(metadataLevel => groupData(metadataLevel.child));
        const sumDataLength = groupedData.reduce((a, b) => a + b.length, 0);
        const titleList = data.flatMap((metadataLevel) => {
            const {id, title} = metadataLevel;
            if (toAry(metadataLevel.child).length) {
                return Array(groupData(metadataLevel.child).length).fill({id, title});
            } else {
                return [{id, title}];
            }
        });
        
        return [groupedData, sumDataLength, titleList];
},[_d]);

    const [slide, setSlide] = useState(0);

    useEffect(()=>{ setSlide(0); }, [_d]);

    const KEY = useMemo(() => autoId(), [_d]);
    return ( //<RadialChartProvider>
        <>
        <CpRadialChartHeader key={KEY+'A'} {...{titleList, slide, setSlide, clickM}} />
        <div className={"radial-chart-container d-flex align-items-center max-size-chart-500 max-width m-auto"}>
            <CpRadialChartSlider key={KEY+'B'} {...{dataLength:sumDataLength, slide, setSlide}} >
                {groupedData.map((gData, idx) => {
                    return gData.map((metadataLevel, idx2) => {
                        const {overallScore} = data[idx]
                        return <section key={idx2}><RadialChart
                            label={label} overallScore={overallScore} data={metadataLevel}
                            baseIndex={idx2 === 0 ? 0 : gData.slice(0, idx2).reduce((acc, cur) => acc + cur.length, 0)}/>
                        </section>
                    })
                })}
                {!sumDataLength && <section><RadialChart label={t('no-data')} /></section>}
            </CpRadialChartSlider>
        </div>
        </>
    );//</RadialChartProvider>

});
export default ChartMetadataRadialGroup;

const CpRadialChartHeader = (props => { //poc RadialChartHeader
    const { titleList, clickM } = props
    const {slide, setSlide} = props;

    const [open, setOpen] = useState(false);
    const handleOpen = e => { stopEvent(e); setOpen(prev => !prev); };
    const handleClose = () => { open && setOpen(false); };

    const ref = useClickOutside(handleClose);

    const jumpToSlide = s => { setSlide(s); handleClose(); };
    const menuList = useMemo(() => {
        const result = [];
        let index = 0;
        for (const obj of titleList) {
            const existingObj = result.find((item) => (item.id === obj.id));
            if (existingObj) {
                existingObj.title = obj.title;
            } else {
                const newObj = { id: obj.id, title: obj.title, index: index, };
                result.push(newObj);
            }
            index++;
        }
        
        return result;
    }, [titleList]);

    const MenuContainer = ({children}) => {
        return <div className={"d-flex position-relative"}>
            <span> <CpActionButton onClick={handleOpen} iconPath={IconList.general.menu} 
                className={`btn btn-dim-400 text-nowrap gap-2 rounded-light semi-bold user-select-none`}/></span>
            <span className={`radial-chart-menu-container ${open? "": "d-none"}`} ref={ref}>
            {children}
        </span>
        </div>
    };

    const MenuItem = ({item, active}) => {
        return <div><span className={`radial-chart-menu-item ${active? "active": ""}`} onClick={() => jumpToSlide(item.index)}> 
            <CpMJX key={autoId()} dynamic={true} >{item.title}</CpMJX> 
        </span></div>;
    };

    return <span className="d-flex gap-3">
        <MenuContainer>
            {menuList.map(item => <MenuItem key={item.id} active={item.id === titleList[slide]?.id} item={item}/>)}
        </MenuContainer>
        <span className={"d-flex gap-2"}>
            <span className="d-flex semi-bold fs-5"><CpMJX key={autoId()} dynamic={true} >{ toStr(titleList?.[slide]?.title) }</CpMJX></span>
            <span>
                <CpActionButton iconPath={IconList.general.search} onClick={clickM}
                    className={`btn btn-color-light-dim-400 text-nowrap gap-2 rounded-light semi-bold user-select-none border-dim-250`}/>
            </span>
        </span>
    </span>
});

const CpRadialChartSlider = (props => {
    const {dataLength, children} = props;
    const {slide, setSlide} = props;

    const slideRef = useRef(null);
    const cur = slideRef.current; 

    const scrollToNextItem = move => {
        
        const newIndex = slide + move;
        if ((newIndex >= 0) && (newIndex <= (dataLength * 2 - 1))) {
            setSlide(newIndex);
            if (cur) {
                cur.scrollLeft = cur.clientWidth * newIndex;
            }
        }
    };
    const jumpTo = index => { if (cur)  scrollToNextItem(index - slide); };
    useEffect(() => { jumpTo(slide); }, [cur, slide]);

    const over1 = dataLength > 1;
    return <>
        {over1 &&
            <span className={`radial-chart-btn prev fs-5 ${slide === 0 ? "d-none" : ""}`}>
            <span role={"button"} className={"border rounded-light py-2 px-1"} onClick={() => scrollToNextItem(-1)}>
                <CpIco src={IconList.general.previousPage}/>
            </span>
        </span>}
        <div className={`radial-chart-slider`} ref={slideRef} >{children}</div>
        {over1 &&
            <span className={`radial-chart-btn next fs-5 ${slide === dataLength - 1 ? "d-none" : ""}`}>
            <span role={"button"} className={"border rounded-light py-2 px-1"} onClick={() => scrollToNextItem(1)}>
                <CpIco src={IconList.general.nextPage}/>
            </span>
        </span>}
        {over1 && <span className={"radial-chart-dot-container"}>{
            [...Array(dataLength)].map((length, idx) => {
                return <span key={idx} role={"button"} className={`radial-chart-dot-btn ${slide === idx ? "active" : ""}`}
                    onClick={() => jumpTo(idx)}></span>
            })
        }</span>}
    </>;
});
