import PREFIXVariable from "../static/scss/module/_prefix.module.scss"

export const PREFIX = {
    BTN: PREFIXVariable['btnPrefix'],
    BTN_BREAKPOINT: PREFIXVariable['btnBreakPointPrefix'],
    DROPDOWN: PREFIXVariable['dropdownPrefix'],
    DROPDOWN_BREAKPOINT: PREFIXVariable['dropdownBreakPointPrefix'],
    SWITCH: PREFIXVariable['switchPrefix'],
    SWITCH_BREAKPOINT: PREFIXVariable['switchBreakPointPrefix'],
    CHECKBOX: PREFIXVariable['checkboxPrefix'],
    CHECKBOX_BREAKPOINT: PREFIXVariable['checkboxBreakPointPrefix'],
    RADIO: PREFIXVariable['radioPrefix'],
    RADIO_BREAKPOINT: PREFIXVariable['radioBreakPointPrefix'],
    INPUT: PREFIXVariable['inputPrefix'],
    INPUT_BREAKPOINT: PREFIXVariable['inputBreakPointPrefix']
}
