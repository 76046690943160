import SVG, {Props as SVGProps} from 'react-inlinesvg';

interface Props extends SVGProps {
    path?: string
}

const header = "poc/static/icon/"
const IconComponent = (props: Props) => {
    const {src = "", path = "", width = null, height = null, title = null} = props

    const config = Object.fromEntries(Object.entries({width, height, title}).filter(([_, v]) => v != null));
    return <div className={"svg-icon"}>
        <SVG src={`${path || header}${src}`} {...config} className={"d-flex"}/>
    </div>
}

export default IconComponent