import IconComponent from "../../../../schema/components/custom/icon/IconComponent";
import {IconList} from "../../../../../static/icons/IconList";
import {ReactNode, useEffect, useRef, useState} from "react";

interface Props {
    children: ReactNode
}

const Tips = (props: Props) => {
    const {children} = props
    const containerRef = useRef<HTMLElement>(null)
    const [open, setOpen] = useState(false)
    const [position, setPosition] = useState("start")

    const preventTrigger = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(prev => !prev)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (containerRef.current) {

// Get the element's bounding rectangle
            const elementRect = containerRef.current.getBoundingClientRect();

// Get the window's width
            const windowWidth = window.innerWidth;

// Check if the element is more to the left or to the right
            if (elementRect.left < windowWidth - elementRect.right) {
                setPosition("start")
            } else {
                setPosition("end")
            }
        }
    }, [containerRef.current, open]);

    return <span className={"tips-container"} ref={containerRef}>
        <span className={"tips-icon"} onClick={preventTrigger} onMouseEnter={() => handleOpen()}
              onMouseLeave={() => handleClose()}>
            <IconComponent src={IconList.general.tips}></IconComponent>
        </span>


        <div className={`tips-content shadow ${position}-0 ${open ? `active` : ""}`}>
            <div className={`tips-body`}>
            {children}
        </div>
        </div>
    </span>
}

export default Tips