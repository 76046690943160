import React, {useEffect, useRef, useState  } from 'react';

import * as UI from '../../libs/libUI';
import { isAry, toStr, tryParseJson,  } from '../../libs/libType';
import { apiCallLoad_, s3UploadPrivate, } from '../../libs/awsFuncs';

import { ReduxBind } from '../../saga/ReduxState';
import CpTabHead from './CpTabHead';
import { arrayAddOrDelete } from '../../libs/libArray';
import { fileExt } from '../../libs/libFormat';
import { preJS } from '../AppUtil';
import { HttpPostMP, objToFormData } from '../../libs/libHttp';

const CpATSubjectComboJSX = ({ATSubjects, sid, setSid, lock}) => {
    return <select value={sid}  onChange={UI.stopEventThen( e => setSid(e.currentTarget.value))}  >
        <option value=''>All</option>
        {isAry(ATSubjects) ? ATSubjects.map(s => {
            const id = s.ATSubjectId;
            return <option key={'subj_' + id} value={id}>{s.ATSubjectName}</option>
        }):''}   
    </select>;
}; 
const CpATSubjectCombo = (ATSubjects, sid, setSid, lock) => <CpATSubjectComboJSX {...{ATSubjects, sid, setSid, lock }} />
export default CpATSubjectCombo;