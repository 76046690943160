import { toAry, toInt, toStr } from "../../libs/libType";

import { _ATSysQTypeGroup, __SYSQSubType_FIB, __SYSQSubType_LBD, __SYSQSubType_LBT, __SYSQSubType_MCS, __SYSQSubType_OED, __SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_POL, expf2 } from '../../consts/ATSysQType';
import { toIdStr, toUniIdAry, toUniIdsStr } from "../../consts/ATValidate";


import { __FIBS_PBK, __FIBS_PQU, __FIBT_DRD, __FIBT_TXT } from '../../consts/ATQtnAnsTypeFIB';
import { __MCAC_ACB, __MCAC_ACG, __MCAC_ACL, __MCAT_ATG, __MCAT_ATI } from '../../consts/ATQtnAnsTypeMCQ';
import { fileExt } from "../../libs/libFormat";
import { csvToRows } from "../PageATMetaEdit";
import { ckImgsSet, mapFIBB, optsToLangAns, setIf, toBGImg, toQData } from "./utilQImportType";

export const QImportCSV = async (csvStr, _fields, _opts) => {
    const opts = { ..._fields, SQType: (_fields.SQType||__SYSQSubType_MCS), };
    //const opts = { SubjIds: [ "sj1", "ss6"], LangEn: 1, LangCt: 1, SQType: (_fields.SQType||__SYSQSubType_MCS), };

    //__SYSQSubType_MCT __SYSQSubType_LBT  
    const isQTypes = [__SYSQSubType_MCS, __SYSQSubType_FIB, __SYSQSubType_LBT, 
        __SYSQSubType_OED, __SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_POL].map(t => ((opts.SQType === t)? 1: 0));

    if(0){
        const {rows, headRow} = dummyData();
        loadQImpRows(opts, rows, headRow, [], []);
    }

    const rows = csvToRows(toStr(csvStr).trim());
    const headRow = rows.splice(0,1)[0];
    const [hcMC, headErrs] = loadHeadRow(opts, headRow, isQTypes);
    
    const he = Object.keys(headErrs).length;
    const [qrows, medias] = he? []: loadQImpRows(opts, rows, hcMC, isQTypes, []);
    
    return [qrows, medias, headErrs, hcMC]; 
};

const loadHeadRow = (opts, headRow, isQTypes) => {
    const [isMC, isFIB, isLBD, isOED, isOPE, isESS, isPOL] = isQTypes; //expf2
    const hcType = 
        isMC? headcfgMC():
        isFIB? headcfgFIB():
        isLBD? headcfgLBD():
        isOED? headcfgOPD():
        isOPE? headcfgOPE():
        isESS? headcfgESS():
        isPOL? headcfgPOL():
        {};
    const hc = { ...headcfg(), ...hcType, };
    if(!isPOL) hc.score = m('Total marks');

    const hclfun = (isLBD || isOED)? hcLangLBD: hcLang;
    const hclangs = { en: hclfun('EN'), ch: hclfun('CH'), };
    const hcopts = 
        (isMC || isPOL)?  Object.fromEntries(['a','b','c','d','e','f','g','h','i','j'].map(key => [key, hcOpt(key)])):
        isFIB? Object.fromEntries(['a','b','c','d','e','f','g','h','i','j'].map((key, i) => [key, hcFIBOpt(key, i+1)])):
        isLBD? Object.fromEntries(['a','b','c','d','e','f','g','h','i','j'].map((key, i) => [key, hcFIBOpt(key, i+1)])):
        {};
    
    const hcall = mkflat({}, [hc, ...Object.values(hclangs), ...Object.values(hcopts)]);
    
    maphead(hcall, headRow);
    const headErrs = [];
    pushIf(headErrs, chkRepeatHead(hcall));
    const hcMC = {hcall, hc, hclangs, hcopts};
    const checker = checkQHead(opts, hcMC, isQTypes);
    pushIf(headErrs, checker.errStr());
    
    return [hcMC, headErrs];
};


const setCorr = A => { if(A){ A.correct = 1; return 0; }else{ return 1; } };
const toMCCorrAns = (corrans, LangEn, EAs, LangCt, CAs, errs, hc) => {
    const cas = toUniIdAry(toStr(corrans).split(';'));
    const [ vos, eos ] = [ [], [] ];
    cas.forEach( a => {
        const la = a.toLowerCase();
        const ee = (LangEn && setCorr(EAs[la])) 
        const ec = (LangCt && setCorr(CAs[la]));
        (ee || ec) && eos.push(a);
    });
    eos.length && (errs[corrans] = 'unknown '+hc.key+': '+eos.join(', '));
    
    //return (vos.length > 1)? 1: 0;
    return (cas.length > 1)? 1: 0;
};
const LBDQTYPEMAP = {
    blank: __FIBT_TXT,
    dropdown: __FIBT_DRD,
};
//LBDQTYPEMAP[toStr(m('Ans type')).trim().toLowerCase()]
const loadQImpRows = (opts, rows, {hcall, hc, hclangs, hcopts}, isQTypes, medias) => {
    const [isMC, isFIB, isLBD, isOED, isOPE, isESS, isPOL] = isQTypes; //expf2
    const {SQType, SubjIds, LangEn, LangCt} = opts;
    
    const qrows = rows.map((row, rowidx) => {
        const q = _maprow(row, hc);
        const qlangs = _maprow2D(row, hclangs); 
        const qopts = _maprow2D(row, hcopts);
        const errs = {};

        const QSQT = 
            (LBDQTYPEMAP[toStr(q.anstype).toLowerCase()] === __FIBT_TXT)? __SYSQSubType_LBT:
            (LBDQTYPEMAP[toStr(q.anstype).toLowerCase()] === __FIBT_DRD)? __SYSQSubType_LBD:
            SQType;

        const _Q = {
            ...toQLangs(LangEn, LangCt, q.lang, errs), 
            QId: toIdStr(q.qid),
            SQType: QSQT,
            SQTGroup: _ATSysQTypeGroup(QSQT),
            QATSubjIds: SubjIds,
            ...toQModes(q.exmode, errs), 
            
            score: toInt(q.score), 
    
            QMetaIDStr: toUniIdsStr(q.meta), 
            QMetaPreIDStr: toUniIdsStr(q.metaPre),  
            QMetaProIDStr: toUniIdsStr(q.metaPro),  
                
            allowTeaCopyEdit: toYN(q.tcopy, 'tcopy', errs, hc.tcopy.key), // tcopy: m('Allow teacher copy/edit'), fs.allowTeaCopyEdit?1:0,
            isNew : 1,
            isAT: 1,
        };

        //mc
        hc.layout && (_Q.ansChoice = toAnsCoice(q.layout, 'layout', errs, hc.layout.key)); //mc, pol
        hc.shuffle && (_Q.shuffleAnswer = toMCShuffle(q.shuffle, 'shuffle', errs, hc.shuffle.key, _Q.ansChoice)); //mc, pol

        //fib, lbd
        hc.automark && (_Q.autoMark = toYN(q.automark, 'automark', errs, hc.automark.key)); //fib 
        //hc.case && (_Q.caseSensitive = toYN(q.case, 'case', errs, hc.case.key)); //fib, lbd
        hc.case && (_Q.caseSensitive = QSQT===__SYSQSubType_LBD?0:toYN(q.case, 'case', errs, hc.case.key)); //fib, lbd
        hc.scoremethod && (_Q.scoreMethod = toScoreMethod(q.scoremethod, 'scoremethod', errs, hc.scoremethod.key)); //fib, lbd
        
        hc.hascorr && (_Q.correctness = toYN(q.hascorr, 'hascorr', errs, hc.hascorr.key)); //mc, ess
        hc.respText && (_Q.respText = toYN(q.respText, 'respText', errs, hc.respText.key)); //poll
        hc.respFile && (_Q.respFile = toYN(q.respFile, 'respFile', errs, hc.respFile.key)); //poll
        hc.respImage && (_Q.respImage = toYN(q.respImage, 'respImage', errs, hc.respImage.key)); //poll
        hc.respDrawing && (_Q.respDrawing = toYN(q.respDrawing, 'respDrawing', errs, hc.respDrawing.key)); //poll
        hc.respURL && (_Q.respURL = toYN(q.respURL, 'respURL', errs, hc.respURL.key)); //poll
        /*
        hc.respText && (_Q.respText = toQStr(q.respText, 'respText', errs, hc.respText.key)); //poll
        hc.respFile && (_Q.respFile = toQStr(q.respFile, 'respFile', errs, hc.respFile.key)); //poll
        hc.respImage && (_Q.respImage = toQStr(q.respImage, 'respImage', errs, hc.respImage.key)); //poll
        hc.respDrawing && (_Q.respDrawing = toQStr(q.respDrawing, 'respDrawing', errs, hc.respDrawing.key)); //poll
        hc.respURL && (_Q.respURL = toQStr(q.respURL, 'respURL', errs, hc.respURL.key)); //poll
        */
//        hc.maxFile && (_Q.maxFile = (QSQT===__SYSQSubType_OEG&&!_Q.respFile)?0:toQInt(q.maxFile, 'maxFile', errs, hc.maxFile.key)); //poll
        hc.maxFile && (_Q.maxFile = ((QSQT === __SYSQSubType_OEG) && !_Q.respFile && !_Q.respImage)?0:toQInt(q.maxFile, 'maxFile', errs, hc.maxFile.key)); //poll, open-end-general
        hc.wordLimit && (_Q.wordLimit = ((QSQT === __SYSQSubType_OEG) && !_Q.respText)? 0: toQInt(q.wordLimit, 'wordLimit', errs, hc.wordLimit.key));

        const qDataCanBlk = isLBD;
        const Qxx = (l, ql, hcl) => {
            
            const qxx = {};
            qxx.keywords = toUniIdAry(ql.kw.split(';')); //kw: m('Keywords ('+l+')'),
            qxx.qData = toQData(ql.body, 'body', medias, qDataCanBlk, errs, hcl.body.key); //body: m('Question body ('+l+')'),
            qxx.ansHints = toStr(ql.hint); //m('Hint ('+l+')'),
            qxx.showAnsHints = (qxx.ansHints === '')? 0: 1;
            const akt = ql.akeytype.toLowerCase(); //( akeytype: m('Ans key type ('+l+')'),
            const ak = ql.akey;
            const [isAKT, isAKF, isAKL] = [akt === 'txt', akt === 'file', akt === 'url'];
            qxx.AKText = isAKT? [{text:ak}]: [];                     
            qxx.AKFile = isAKF? [{file:[{name:ak, size:99999, ext:'todo', mediaId:'todo'}]}]: [];
            qxx.AKLink = isAKL? [{URL:ak, caption:ak}]: [];
            qxx.ansKeys = (isAKT || isAKF || isAKL)? 1: 0;               
            if(isAKF) medias.push(ak.trim().toLowerCase());
            if(qxx.ansKeys){
                if(!ak) errs['_q_ak_'+l] = 'missing '+hcl.akey.key; 
            }else{
                if(ak) errs['_q_akt_'+l] = 'Invalid '+hcl.akeytype.key+' ('+ql.akeytype+')'; 
            }
            if(isLBD){
                qxx.LDMode = 'ftw';
            }
            if(isLBD || isOED) qxx.BGSrc = 'null';
            if(isLBD) qxx.BGMediaID = toBGImg(ql.bg, 'bg', medias, errs, hcl.bg.key);
            if(isOED) qxx.BGMediaID = ql.bg? toBGImg(ql.bg, 'bg', medias, errs, hcl.bg.key): '';
            
//           "AKText": [ { "text": "<p>text here</p>" } ],
//            "AKFile": [{ "file": [{ 
//                "mediaId": "RCoV7R3j2P6w6AK6t4ytna", "size": 156332, "name": "sample.mp3", "ext": "mp3" },] }],
//            "AKLink": [{ "URL": "www.nba.com", "caption": "nba" }]
            return qxx;
        };
        const QEn = LangEn && Qxx('en', qlangs.en, hclangs.en);
        const QCt = LangCt && Qxx('ch', qlangs.ch, hclangs.ch);

        

        const [AEs, ACs] =  optsToLangAns(LangEn, LangCt, qopts, hcopts, isQTypes, medias, errs);
        LangEn && (QEn.qAnswers = Object.values(AEs));
        LangCt && (QCt.qAnswers = Object.values(ACs)); 

        isMC && (_Q.multiAnswer = toMCCorrAns(q.corrans, LangEn, AEs, LangCt, ACs, errs, hc.corrans));
        hc.allowmulti && (_Q.multiAnswer = toYN(q.allowmulti, 'allowmulti', errs, hc.allowmulti.key)); //pol
        
        if(isESS){_Q.respText=1};
        if(isLBD){
            const lbqType = QSQT===__SYSQSubType_LBD? __FIBT_DRD:__FIBT_TXT;
            LangEn && (QEn.qAnswers.forEach((ans)=>{ans.qtype=lbqType}));
            LangCt && (QCt.qAnswers.forEach((ans)=>{ans.qtype=lbqType})); 
        };

        if(!isMC){
            
            _Q.score = _Q.score || 
                sumAry(toAry(QEn?.qAnswers).map(o => toInt(o.score))) || 
                sumAry(toAry(QCt?.qAnswers).map(o => toInt(o.score))); 
            if(LangEn && QEn) QEn.qData = mapFIBB(QEn.qData, QEn.qAnswers);
            if(LangCt && QCt) QCt.qData = mapFIBB(QCt.qData, QCt.qAnswers);
        }
        
        const Q = {..._Q, QEn, QCt};
        
        return [Q, errs];
    });

    
    return [qrows, medias];
};

const checkQHead = (opts, hcMC, isQTypes) => {
    const {LangEn, LangCt} = opts;
    const {hc, hclangs, hcopts} = hcMC;
    const [isMC, isFIB, isLBD, isOED, isOPE, isESS, isPOL] = isQTypes; //expf2
    const checker = mkMustHaveChecker();
    
    checker.checkList(hc);
    LangEn && checker.checkList(hclangs.en);
    LangCt && checker.checkList(hclangs.ch);
    const checkOpt = os => {
        checker.check(os.atype);
        LangEn && checker.check(os.ae);
        LangCt && checker.check(os.ac);
    };
    if(isMC){
        checkOpt(hcopts.a);
        checkOpt(hcopts.b);
    }
    return checker;
};

export const _maprow = (row, cols) => Object.fromEntries(Object.entries(cols).filter(([k,c]) => c.col.length )
    .map( ([k,c]) => [k, toStr(row[c.col[0]]).trim() ])); 
const _maprow2D = (row, cols2d) => Object.fromEntries(Object.entries(cols2d)
    .map(([key1, cols]) => [key1, _maprow(row, cols)]));

const toMCShuffle = (val, key, errs, errLabel, ansChoice) => {
    if(ansChoice === __MCAC_ACB){
        if(val) 
            errs[key] = 'Invalid Label '+errLabel+': "'+val+'" expect empty for Label MC';
        return 0;
    }
    return toYN(val, key, errs, errLabel)
};

const validAnsChos = { list:  __MCAC_ACL, grid:  __MCAC_ACG, label: __MCAC_ACB, };
const toAnsCoice = (cho, key, errs, errLabel) => {
    const ret = validAnsChos[cho.toLowerCase()];
    if(!ret) errs[key] = 'Invalid "'+errLabel+'" ('+cho+')';
    return ret; 
};

const toQModes = (str, errs) => {
    const ret = { QModeSlide:0, QModeScroll:0};
    const unk = []
    toUniIdAry(toStr(str).split(';')).forEach(L => {
        const l = L.trim().toLowerCase();
        if(l === 'scroll'){
            ret.QModeScroll = 1;
        }else if(l === 'slide'){
            ret.QModeSlide = 1;
        }else{
            unk.push(L);
        }
    });
    setIf(errs, 'exmode', [
        (s => s && 'Invalid EX mode: '+s)(unk.join(', ')),
        (!(ret.QModeScroll || ret.QModeSlide)) && 'missing EX mode',
    ].filter(e => e).join(', '));
    return ret;
};
const toYN = (v, key, errs, errLabel) => {
    const yn = toStr(v).trim().toLowerCase();
    setIf(errs, key, (yn !== 'y') && (yn !== 'n') && 'Expect "'+errLabel+'" ('+v+') to be Y/N');
    return yn === 'y'? 1: 0;;
};

const SCOREMETHODMAP = {
    perblank: __FIBS_PBK,
    perq: __FIBS_PQU,
};
const toScoreMethod = (v, key, errs, errLabel) => {
    const s = toStr(v).trim();
    const sm = SCOREMETHODMAP[s.toLowerCase()];
    setIf(errs, key, (!sm) && 'Invalid "'+errLabel+'" ('+s+')');
    return sm;
};
const toQInt = (v, key, errs, errLabel) => {
    const val = toInt(v);
    setIf(errs, key, (!val) && 'Invalid '+errLabel+': "'+val+'"');
    return val;
};
const toQStr = (v, key, errs, errLabel) => {
    const val = toStr(v);
    setIf(errs, key, (!val) && 'Invalid '+errLabel+': "'+val+'"');
    return val;
};

const validLang = (e, c) => (e && c)?'EN; CH': e? 'EN': c? 'CH': '';
const toQLangs = (LangEn, LangCt, lstr, errs) => {
    const ret = { QLangEn:0, QLangCt:0};
    const unk = []
    toUniIdAry(toStr(lstr).split(';')).forEach(L => {
        const l = L.toLowerCase();
        if(l === 'en'){
            ret.QLangEn = 1;
        }else if(l === 'ch'){
            ret.QLangCt = 1;
        }else{
            unk.push(L);
        }
    });
    
    
    const e1 = (s => s && ('Invalid Languages: '+s))(unk.join(', '));
    const e2 = ((ret.QLangEn!==LangEn) || (ret.QLangCt!==LangCt)) && ('expected language: '+validLang(LangEn, LangCt)+' got "'+lstr+'"'); 
    
    setIf(errs, 'lang', [e1, e2].filter(e => e).join(', '));
    return ret;
};

export const pushIf = (ary, item) => (item && ary.push(item));
export const doIf = (func, v) => (v && func(v));


export const mkMustHaveChecker = () => {
    const miss = [];
    const check = hc => hc.col.length || miss.push(hc.key);
    const checkList = hcs => Object.values(hcs).forEach(check);
    const errStr = () => doIf(s => 'Missing Columns: '+s, miss.join(', '));
    return { miss, check, checkList, errStr };
}

export const chkRepeatHead = hcall => {
    const rhstr = Object.values(hcall).filter(h => h.col.length > 1).map(h => h.key).join(', ');
    return  rhstr && 'Repeated Columns: '+rhstr;
};

const _addif = (c, i) => c && c.col.push(i);
export const maphead = (hc, headrow) => {
    headrow.forEach((h, i) => _addif(hc[h.trim().toLowerCase()], i));
};

const m = key => ({key, col:[], });

///Qunextion Sys Type  ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### #####
const headcfg = () => {
    const ret = {
        qid: m('QID'),
        lang: m('Language'), 
        exmode: m('Ex mode'), 
        tcopy: m('Allow teacher copy/edit'),
        meta: m('Metadata'),
    };
    return expf2? {...ret, metaPre: m('Metadata (Prior)'), metaPro: m('Metadata (Advanced)'),}: ret;
};

const headcfgMC = () => ({
    layout: m('Ans choice layout'), 
    shuffle: m('Shuffle ans'), 
    corrans: m('Corr ans'),
});
const headcfgFIB = () => ({
    automark: m('Auto-mark'),
    case: m('Case sensitive'), 
    scoremethod: m('Scoring method'),
});

const headcfgLBD = () => ({
    anstype: m('Ans type'),
    case: m('Case sensitive'), 
    scoremethod: m('Scoring method'),
});
const headcfgOPD = () => ({
    hascorr: m('Has correctness'),
});
const headcfgOPE = () => ({
    hascorr: m('Has correctness'),
    respText: m('Response - Text'),
    respFile: m('Response - File upload'),
    respImage: m('Response - Image'),
    respDrawing: m('Response - Drawing'),
    respURL: m('Response - URL'),
    maxFile: m('Max. file of upload'),
    wordLimit: m('Word limit'),
});
const headcfgESS = () => ({
    hascorr: m('Has correctness'),
    wordLimit: m('Word limit')
});
const headcfgPOL = () => ({
    layout: m('Ans choice layout'),
    allowmulti: m('Allow multiple options'),
    shuffle: m('Shuffle ans'), 
});

///Qunextion Language  ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### #####
const hcLang = l => ({
    kw: m('Keywords ('+l+')'),
    body: m('Question body ('+l+')'),
    hint: m('Hint ('+l+')'),
    akeytype: m('Ans key type ('+l+')'),
    akey: m('Ans key ('+l+')'),
}); // EN/CH
const hcLangLBD = l => ({
    ...hcLang(l),
    bg: m('Question image ('+l+')'),
}); // EN/CH


///Answer Options ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### ##### #####
const hcFIBOpt = (o, num) => ({
    atype: m('Ans '+num+' type'), 
    ain: m('Ans '+num+' input'),
    ae: m('Ans '+num+' (EN)'),  
    ac: m('Ans '+num+' (CH)'),
    amark: m('Ans '+num+' mark'),
}); // A..D max 4 opts 
const hcOpt = o => ({
    atype: m('Ans '+o+' type'), 
    ae: m('Ans '+o+' (EN)'),  
    ac: m('Ans '+o+' (CH)'),
}); // A..J max 10 opts 


export const mkflat = (ret, colsAry) => {
    colsAry.forEach(cols => Object.values(cols).forEach(col => {
        ret[col.key.trim().toLowerCase()] = col;
    })); 
    
    return ret;
};

const dummyData = () => ({
    //const headRow = 'QID | Language | Ans choice layout | QID | Allow teacher copy/edit |  Ans choice layout | Ans a (CH)'.split('|'); 
    headRow: 'QID | Language | Ans choice layout | Allow teacher copy/edit |  Ans a (CH)'.split('|'), 
    rows: [
       ' Qid1 | En,Ct | grid | 1 | xx'.split('|'),
       ' Qid2 | En | table | 0 | yy'.split('|'),
   ]
});

const sumAry = vs => { 
    
    var r = 0; vs.forEach(v => {r += v;}); return r; 
};

const toMLnkId = (f, mediaIds) => {
    const o = mediaIds[f.toLowerCase()];
    const oid = o && o.upid; 
    if(!oid) console.error('toMLnkId', f, mediaIds);
    return oid || f;
};
export const ckImgsSetQ = (q, MLnks) => {
    
    const MML = l => l? toMLnkId(l, MLnks): '';
    const doQLan = QL => {
        if(!QL) return;
        const {qData, qAnswers, AKFile} = QL;
        QL.qData = ckImgsSet(qData, MLnks);
        setIf(QL, 'BGMediaID', MML(QL.BGMediaID));
        //QL.qAnswer...
        if(qAnswers){
            //if mc...
            QL.qAnswers = qAnswers.map(o => {
                const t = o.type;
                if(t === __MCAT_ATG){
                    o.data = ckImgsSet(o.data, MLnks);
                }else if (t === __MCAT_ATI){
                    o.data.oupid = MML(o.data.oupid);
                }
                return o;
            }) 
        }
        const akfs = AKFile?.[0]?.file;
        if(akfs){
            QL.AKFile[0].file = akfs.map(f => {
                const o = MLnks[toStr(f.name).toLowerCase()];
                const ak = {name:o.name, size:o.size, ext:fileExt(o.name), mediaId:o.upid}; 
                if(!o) console.error('ckImgAK', {f, o, ak});
                return ak; 
            });
        }
        return QL;
    };
    doQLan(q?.QEn);
    doQLan(q?.QCt);
    return q;//{...q, QEn: doQLan(q.QEn), QCt: doQLan(q.QCt)};
};

