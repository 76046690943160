import {useMemo} from "react";
import { useUILang } from "../../../AppExPf/utils/useUILang";
import { isNotAttempted } from "../../EPAsmWork/utilAsmWork";
import { _WST_SUBMIT } from "../../../consts/ATConstsAssignment";

const TagWorkSubmitTag = (props) => { // base on poc SubmitTag.tsx + AttemptedTag.tsx
    const [t, lang] = useUILang();
    const {asm, work} = props;

    const item = useMemo(() => {
        return isNotAttempted(asm, work)? 
            {style: "bg-white border-tag-danger text-tag-danger", title: t("assignment-tag-not-attempted")}
        :(work?.WState ===_WST_SUBMIT)? 
            {style: "bg-white border-tag-text text-tag-text", title: t("assignment-tag-submitted")}
        : 
            {style: "bg-white border-tag-none text-tag-none", title: t("assignment-tag-not-submitted")};
    }, [lang, asm, work]);

    return <small className={`semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light ${item.style}`}>
        {item.title}
    </small>
};

export default TagWorkSubmitTag;