import React from 'react';
import { SCHOOL_YEARS, SchoolYearKey, getColorForYear } from "../../../../type/ChartType";

const AverageScoreProgressLegend: React.FC = () => {
    return (<div className="average-score-progress-legend">
        {Object.entries(SCHOOL_YEARS).map(([key, value]) => (
            <div key={key} className="legend-item">
                <span className="color-box" style={{ backgroundColor: getColorForYear(Number(key) as SchoolYearKey) }}></span>
                <span className="year-label">{value}</span>
            </div>
        ))}
    </div>);
}
export default AverageScoreProgressLegend;