export const Status = {
    p: "p", //pass
    f: "f",//fail
    a: "a",//attempt, no correctness(Open-end), polling
    n: "n",//null
    m: "m",//manual, correctness(Open-end)
    s: "s"//submitted, but no attempted(Open-end)
};

// ==== Color ==== //
export const DONUT_DEFAULT_SEGMENT_COLOR = "#4b97e5";
export const DONUT_NO_ATTEMTED_COLOR = "#909090";
export const DONUT_ATTEMPTED_COLOR = "#1655BB";
export const DONUT_INCORRECT_COLOR = "#ee6161";
export const DONUT_CORRECT_COLOR = "#4EAE84";
export const DONUT_TEXT_COLOR = "#767676";
export const DONUT_TEXT_DIM_COLOR = "#EBEBEB";
export const DONUT_RING_COLOR = "#EBEBEB";
export const DONUT_AVG_LINE_COLOR = "#006fbb";
export const DONUT_PASSING_LINE_COLOR = "#ffa7a7";
export const DONUT_BODY_COLOR = "#000"

// ==== Sorting ==== //
export const SORT_ASC = "SORT_ASC";
export const SORT_DESC = "SORT_DESC";

// base on poc type/Assignment
export const AssignmentProgress = {
    NOT_YET_STARTED:"not-yet-started",
    IN_PROGRESS:"in-progress",
    RESULT_PUBLISHED:"result-published",
    RESULT_PENDING:"result-pending",
    PENDING_MANUAL_MARK:"pending-manual-mark"
};

export const CHART_COLORS = [
    "#64a3a3",
    "#fd7f6f",
    "#ffb55a",
    "#bd7ebe",
    "#b2e061",
    // "#ffee65",
    "#72a8d3",
    "#beb9db",
    "#be8585",
    "#c5c95d",
    "#e785b7",
    "#8bd3c7",
    "#ff8f42",
    "#b9ccdb",
    "#fdcce5",
    "#ffeb7f",
    "#cc8ac0",
    // "#9c8ec3",
    // "#f4a4c3",
    // "#7ac5cd",
    // "#d8e661",
];

export const getChartColor = (index, loop) => {
    if (index < 0) { return CHART_COLORS[0] };
    if (loop) {
        return CHART_COLORS[index % CHART_COLORS.length];
    } else {
        if (index < CHART_COLORS.length) {
            return CHART_COLORS[index];
        } else {
            return CHART_COLORS[CHART_COLORS.length - 1];
        };
    };
};

export const getProgressStatusColor = (status) => {
    switch (status) {
        case "p":
            return DONUT_CORRECT_COLOR
            break;
        case "f":
            return DONUT_INCORRECT_COLOR
            break;
        case "a":
            return DONUT_ATTEMPTED_COLOR
            break;
        case "s": // not exist in this case
        case "m": // not exist in this case
        case "n":
        default:
            return DONUT_NO_ATTEMTED_COLOR
            break;
    };
};

// === Radial Chart ====//
export const MAX_RADIAL_RING = 6;

export const LineBarType = {
    LINE: "LINE",
    BAR: "BAR"
};

export const LineBarValueType = { 
    PERCENTAGE: "PERCENTAGE",
    VALUE: "VALUE"
};

export const ProgressByMonthType = {
    AVERAGE_SCORE: "AVERAGE_SCORE",
    NO_OF_ASSIGNMENT: "NO_OF_ASSIGNMENT"
};

// === Line/Bar Chart ====//
export const schoolYearByMonth = t => [
    { id: "month-009", title: t("schema:calendar.month.9"), value: "9", },
    { id: "month-010", title: t("schema:calendar.month.10"), value: "10", },
    { id: "month-011", title: t("schema:calendar.month.11"), value: "11", },
    { id: "month-012", title: t("schema:calendar.month.12"), value: "12", },
    { id: "month-001", title: t("schema:calendar.month.1"), value: "1", },
    { id: "month-002", title: t("schema:calendar.month.2"), value: "2", },
    { id: "month-003", title: t("schema:calendar.month.3"), value: "3", },
    { id: "month-004", title: t("schema:calendar.month.4"), value: "4", },
    { id: "month-005", title: t("schema:calendar.month.5"), value: "5", },
    { id: "month-006", title: t("schema:calendar.month.6"), value: "6", },
    { id: "month-007", title: t("schema:calendar.month.7"), value: "7", },
    { id: "month-008", title: t("schema:calendar.month.8"), value: "8", }
];

export const getSchoolMonthIdByValue = (value = "", t) => {
    return schoolYearByMonth(t).find(month => month.value === value)?.id || "";
};

export const getValueRange = (props) => {
    const {minValue = 0, maxValue, stepValue} = props;
    const array = [];
    let currentValue = minValue;

    while (currentValue <= maxValue) {
        array.push(currentValue);
        currentValue += stepValue;
    };

    return array.reverse();
};

export const getMaxValue = (data, indicatorNum = 10, percentage = true) => {
    let maxValue = 100;
    let stepValue = 10;

    if (percentage) {
        const percentageMaxValue = 100;
        const percentageStep = 10;
        maxValue = percentageMaxValue;
        stepValue = percentageStep;
    } else {
        if (data.length) {
            const groups = {};
            data.forEach((item) => {
                const groupId = item.referenceId;
                const value = item.value;
                if (groups[groupId] === undefined) { groups[groupId] = 0; };
                groups[groupId] += value;
            });

            let highestGroup = '';
            let highestSum = -Infinity;

            for (const groupId in groups) {
                const groupSum = groups[groupId];

                if (groupSum > highestSum) {
                    highestGroup = groupId;
                    highestSum = groupSum;
                };
            };

            // if there is no enough range(baseStepValue) or the too small need to add more buffer indicators
            stepValue = Math.ceil(highestSum / indicatorNum);
            maxValue = stepValue * indicatorNum;
        };
    };

    return {maxValue, stepValue, valueRange: getValueRange({maxValue, stepValue})}
    // return { groupId: highestGroup, sum: highestSum };
};

export const ROLE = { // base on poc ROLE
    TEACHER:"TEACHER",
    "STUDENT":"STUDENT"
};

export const ReportType = { // base on poc ReportType
    OVERVIEW: "overview",
    CLASS_DETAIL: "class-detail",
    METADATA: "metadata"
};

export const ReportTargetType = { // base on poc ReportTargetType
    ALL_CLASSES: "all-classes",
    SELECTED_CLASS: "selected-class",
    SELECTED_STUDENT: "selected-student"
}