import {LegendType} from "../../../../../type/LegendType";
import i18n from "../../../../../translate/i18n";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    legend: LegendType
}

const ReportLegend = (props: Props) => {
    const {t} = useTranslation(['schema']);
    const {legend} = props
    const lang = i18n.language === "en" ? "en" : "zh"
    const getLegend = useMemo(() => {
        switch (legend) {
            case LegendType.REPORT_ALL_CLASSES:
                return {
                    fileName: "all-classes",
                    size: 600
                }
                break;
            case LegendType.REPORT_SELECTED_CLASS:
                return {
                    fileName: "selected-class",
                    size: 600
                }
                break;
            case LegendType.REPORT_SELECTED_STUDENT:
                return {
                    fileName: "selected-student",
                    size: 600
                }
                break;
            case LegendType.COL_ASSIGNMENTS:
                return {
                    fileName: "col-assignments",
                    size: 600
                }
                break;
            case LegendType.COL_SCORE_RANGE:
                return {
                    fileName: "col-score-range",
                    size: 600
                }
                break;
            case LegendType.KEY_STAGE_REPORT_SELECTED_CLASS:
                return{
                    fileName: "key-stage-selected-class",
                    size: 600
                }
                break;
            case LegendType.KEY_STAGE_REPORT_SELECTED_STUDENT:
                return{
                    fileName: "key-stage-selected-student",
                    size: 600
                }
                break;

            default:
                return {
                    fileName: "",
                    size: 0
                }
        }

    }, [legend])

    return <div className={"d-flex flex-column gap-1 align-items-start"}>
        <u className={"semi-bold"}>{t("guideline")}</u>
        <img src={`../static/img/legend/${getLegend.fileName}-${lang}.png`} width={`${getLegend.size}px`}/>
    </div>
}

export default ReportLegend