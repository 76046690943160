import React, {useEffect, useMemo, useState, useContext} from "react";

import CpShareOffcanvas from "../_components/CpShareOffcanvas";
import { useUILang, useCtnLang, isEn2Lang } from "../../AppExPf/utils/useUILang";
import ActionButtonComponent from "../_components/CpActionButtonComponent";
import CpIco from "../_components/CpIco";
import { SIZE } from "../_components/CpExPFConst";
import { IconList } from "../../consts/ATIconListToUsePoc";
import CpDropdown, { ary2DropdownItem, ent2DropdownItem, obj2DropdownItem } from "../_components/CpDropdown";

import { subjMetaCombo } from "../EPAssign/TabAssignmentSettingClass";
import { useFields } from "../../AppExPf/utils/useEditDraftPub";
import { nestSolid, objEntries, objKeys, toAry, toObj } from "../../libs/libType";

import { useTickAll } from "../../AppExPf/ATQtnEdit/PageATQtns";
import { useResizeEffect } from "../../AppExPf/utils/useWinResize";
import { CpQuestionKeywordsFilter, CpRangeFilter, CpSQTypesFilter,  } from "../EPLibrary/LayerExerFilter";

import CpEpTypeMSetTrees from "../_components/CpEpTypeMSetTrees";
import { Accordion, Button, CloseButton, Collapse } from "react-bootstrap";
import CpAccordionItem from "../_components/CpAccordionItem";
import CpSearchQuestionContentContainer from "./CpSearchQuestionContentContainer";
import { stopEvent, EpInputTxt0 } from "../../libs/libUI";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { ReduxBind } from "../../saga/ReduxState";
import { useExLikes } from "../EPExercise/CpExerciseCard";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { strCmpTrimLow } from "../EPAssign/TabTeacherAssignmentStatus";

/*
- full - top:
    - pool:     (nul)
    - school:   by, sort, kw        <open Filter
    - my:       sort, kw            <open Filter
- full - Side:
    - pool:     filter
    - school / my: Ex List
- mob - top drop:
    subjs; pools; langs  |
        - pool:   /filter
        - school: by, sort, kw/     <open Filter
        - my: sort, kw              <open Filter 
*/

const CpSearchQuestionContainer = ReduxBind(props => { // base on poc SearchQuestionContainer.tsx
    const {dispatch, show, addedQIds, onAddQ, exitFindQ, subjMetas, ex} = props;

    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(isEn2Lang(props.showEn));

    const [ cache2d, useGetCache ] = useCaches(props);

    const thrNames = useGetCache('teachers', '*'); 

    const exlikes = useExLikes(dispatch);
    const { eLikes, setELikes, loadELikes} = exlikes;

    const useFs = useFields();
    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts] = useFs;

    const [qOptVis, setQOptVis] = useState(false); // need boolean
    useEffect( () => { loadELikes(); }, []);

    const subjItems = useMemo( () => subjMetaCombo(subjMetas, ut), [subjMetas, uiEn]);
    const bys = useMemo(() => ent2DropdownItem( [['*','all'], ...sortTeacherNames(thrNames, ut)]), [thrNames, uiEn]);
    
    const pools = findQPoolDDI(t);
    const sorts = findQSortDDI(t);
    const {QMSubjId, pool, by, sort} = toObj(fields);
    const {j, p, b, s} = validateFindQ(subjItems, QMSubjId, pools, pool, bys, by, sorts, sort);

    useEffect( () => { if((j !== QMSubjId) || (p!==pool) || (b!==by) || (s!==sort)){
        setFields(fs => ( {...toObj(fs), QMSubjId:j, pool:p, by:b, sort:s} ) );
    };}, [p, b, s, j]);    

    const load = async (caller) => {
        const { ELangEn, ELangCt, EModeScroll, EModeSlide, EId } = toObj(ex);
        //todo: bugfix findQuestion remove metas that dont include in tick tree
        const [res, err] = await asyncApiCallLoad_(dispatch, '/findQuestions', ({caller, QFilters: {...fields, skipEId:EId, ELangEn, ELangCt, EModeScroll, EModeSlide}}));
        const {QSetTagMsets, QIds, EIds} = toObj(res);
        setOpts(os => ({...toObj(os), QSetTagMsets, QIds, EIds }) );
    };
    useEffect( () => { 
        const doLoad = (pool && show && !qOptVis);
        if(doLoad) load('FQEff');
    }, [QMSubjId, pool, by, sort, show]);

    const clickFindQ = e => { stopEvent(e); load('clickFQ'); };
    const hideFindQOpts = () => setQOptVis(false);
    const handleOpenFilter = () => { setQOptVis(!qOptVis);};

    //return <><span role="button" {...buttonProps} onClick={() => handleOpen()}>{children}</span>
    //show={stateExercise.openSearchQuestion} placement={"end"}

    const [poolOup, poolSchool, poolMy] = poolTypes(p); 
    const subProps = { clickFindQ, clickFindQ, hideFindQOpts, addedQIds, onAddQ, exlikes, showEn,
        qOptVis, subjItems, bys, j, p, b, s, useFs, poolSchool, poolMy, poolOup};
    
    const handleClose = e => {stopEvent(e); exitFindQ(); }; 
    return <CpShareOffcanvas className={"CpSearchQuestionContainer offcanvas-mw-90 exercise-search-filter-container"}
        {...{placement:"end", footerXPadding:0, footerYPadding:1, disableHeader:false, disableFooter:true, disableClose:true, show, handleClose}}
        title={<div className={"d-flex justify-content-between justify-content-md-end justify-content-lg-between align-items-center w-100 p-3 bg-dim-100 fs-5 user-select-none"}>
            <span className={"text-truncate d-block d-sm-block d-md-none d-lg-block"}>{t("search-questions")}</span>
            <span className={"d-flex gap-3 align-items-center"}>
                <span className={"d-none d-md-flex gap-2 fs-6"}>
                    <span className={"d-flex"} style={{minWidth: "280px"}}><CpDropdown className="w100" {...{items:subjItems, idx:j, cb:setField('QMSubjId')}} /></span>
                    <span className={"d-flex"} style={{minWidth: "220px"}}><CpDropdown className="w100" {...{items:pools, idx:p, cb:setField('pool')}} /></span>
                </span>
                <span className={"position-relative d-block d-md-none"}>
                    <ActionButtonComponent variant={"gray-exercise-third-btn"} size={SIZE.XS} icon={<CpIco src={IconList.general.filterAdvance}/>}
                        onClick={handleOpenFilter} />
                    <small className={"filter-count bg-danger badge rounded-pill position-absolute fs-10 text-light pe-none user-select-none"}>3</small>
                </span>
                <CloseButton onClick={handleClose} />
            </span>
        </div>}   
        >
        <div className={`scroll-container h-100 d-flex flex-column w-100 overflow-auto overflow-y-md-hidden position-relative ${qOptVis? "filter-backdrop": ""}`}>
            <div className={`d-none ${(poolSchool || poolMy)? "d-md-flex": ""} align-items-center gap-2 justify-content-between bg-dim-200 px-3 py-2`}>
                <span className={"d-flex align-items-center gap-2"}>
                    {poolSchool? <span className={"d-flex align-items-center gap-2"}>
                        <span className={"semi-bold text-nowrap"}>{t("search-questions-created-by")}:</span>
                        <CpDropdown className="w100" {...{items:bys, idx:b, cb:setField('by'), className:"semi-bold"}} />
                    </span>: ''}
                    {(poolSchool || poolMy)? <span className={"d-flex  align-items-center gap-2 search-question-sort"}>
                        <span className={"semi-bold text-nowrap"}>{t("search-questions-sort")}:</span>
                        <CpDropdown className="w100" {...{items:sorts, idx:s, cb:setField('sort'), className:"semi-bold"}} />
                    </span>: ''}
                </span>
                {(poolSchool || poolMy)? <span className={"d-flex gap-2 align-items-center"}>
                    <span className={"semi-bold text-nowrap"}>{t("search-questions-keyword")}:</span>
                    {EpInputTxt0(fields?.QKw, setField('QKw'), 'tqkw', 'w-100 border', {} )}
                    <span role={"button"} className={"text-exercise-third-btn"} onClick={clickFindQ} >
                        <CpIco src={IconList.general.search}/>
                    </span>
                </span>: ''}
            </div>
            <CpSearchQuestionFilter {...subProps} />
            <CpSearchQuestionContentContainer {...subProps} />
        </div>
    </CpShareOffcanvas>
    //</>
});
export default CpSearchQuestionContainer;

const CpSearchQuestionFilter = (props) => {
    const { qOptVis, hideFindQOpts} = props;
    const [t, lang] = useUILang();

    useResizeEffect((w, h) => { hideFindQOpts(); });

    const [inFields, inSetFields, inSetField, inSetTick, inSetTickAry, inFieldErrs, inSetFieldErrs, inOpts, inSetOpts] = props.useFs; 
    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts] = useFields(); 
    useEffect(() => { if(qOptVis) setFields(inFields) }, [qOptVis])
    const useFs = [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, inOpts, inSetOpts];

    const clearFindQOpts = e => { stopEvent(e); clearFindQFields(setFields, fields); };
    const clickFindQ = e => {
        inSetFields(fields);
        hideFindQOpts();
        props.clickFindQ(e);
    }
    return <>
        <Collapse in={qOptVis}>
            <div><div className={`exercise-search-filter w-100 h-100 uiAnimation bg-white ${qOptVis? "d-flex": "d-none"} flex-column `}>
                <div className={"h-100 overflow-auto overflow-y-md-hidden d-flex flex-wrap flex-column flex-grow-1"}>
                    <LayerExerFilterQ {...{type:"searchQuestion", ...props, useFs}}/>
                </div>
                <div className={"d-flex gap-2 justify-content-center w-100 p-3"}>
                    <Button variant="gray-body-color w-100 border" onClick={clearFindQOpts}>{t("clear")}</Button>
                    <Button variant={`exercise-third-btn-hover-light text-exercise-third-btn btn-block w-100`} onClick={clickFindQ}>{t("search")}</Button>
                </div>
            </div></div>
        </Collapse>
    </>
};

export const LayerExerFilterQ = /*ReduxBind*/(props =>  { // base on poc <ExerciseTabletListFilter> trimed exer find part
    const [t, isUIEn, UILang, setUILang] = useUILang();
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(UILang);

    const { dispatch, qOptVis, setQOptVis,  eset, exers, ESEMetaDisplays, subjItems, bys, useFs, } = props;
    const pools = findQPoolDDI(t);
    const sorts = findQSortDDI(t);

    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts] = useFs;
    const { QMSubjId, pool, by, sort } = toObj(fields);
    const {j, p, b, s} = validateFindQ(subjItems, QMSubjId, pools, pool, bys, by, sorts, sort);

    const [poolOup, poolSchool, poolMy] = poolTypes(pool); 

    const [SQTypes, setSQTypes] = [toAry(fields.SQTypes), setTickAry('SQTypes')];
    const [metas, setMeta] = [toAry(fields.metas), setTickAry('metas')];
    const [opens, setOpen] = useTickAll([]);

    const [activeKey, setActiveKey] = useState(1);
    const handleActive = id => setActiveKey(prev => (prev === id) ? "" : id);
    const act0 = (activeKey === 0);
    const act1 = (activeKey === 1);
    const act = (activeKey !== "");
    useResizeEffect((w, h) => { (!qOptVis) && setActiveKey(0 /*(w >= 768)? "0": "1"*/) });
    useEffect(() => { qOptVis && setActiveKey(1); },  [qOptVis]);

    //   ES."ESMetaFilters": [ "ms1,0", "ms1,2",  "mssu1,0", "mssu1,2", "mssu2,0",]
    const inpProps = {fields, setField, setTick};

    const findEx = 0, findQ = 1;
    
    const type =  findQ? 'searchQuestion': 'searchExercises'; 

    return <div className={`exercise-filter d-flex flex-column w-100 h-100 ${act? "justify-content-between": ""}`}>
        <span role={"button"} onClick={() => handleActive(1)}
            className={`bg-exercise-third-btn-hover-light text-exercise-third-btn ${type} w-100 py-1 px-3 d-flex d-md-none semi-bold fs-5 accordion-button ${
                act1? "collapse": "collapsed"}`}>{t("search-questions-search-from")}</span>
        <div className={`d-flex d-md-none h-100 pt-2 overflow-auto  ${act1? "flex-grow-1" : "d-none"}`}>
            <Accordion defaultActiveKey={[0,1,2,3,4,5,6,7,8,9]} alwaysOpen className={`exercise-subfilter d-flex flex-column gap-2 p-3 w-100`}>
                <CpDropdown className="w100" {...{items: subjItems, idx: j, cb: setField('QMSubjId'), className: "semi-bold" }} />
                <CpDropdown className="w100" {...{items: pools, idx: p, cb: setField('pool'), className: "semi-bold" }} />

                {(poolMy || poolSchool)? <div className={"mx-2 my-3 d-flex justify-content-center semi-bold"}></div>: ''}

                {(poolSchool)? <>
                    <span className={"semi-bold"}>{t("search-questions-created-by")}:</span>
                    <CpDropdown className="w100" {...{items: bys, idx: b, cb: setField('by'), className: "semi-bold" }} />
                </>: ''}
                {(poolMy || poolSchool)? <>
                    <span className={"semi-bold"}>{t("search-questions-sort")}:</span>
                    <CpDropdown className="w100" {...{items: sorts, idx: s, cb: setField('sort'), className: "semi-bold" }} />
                </>: ''}
                {(poolMy || poolSchool)?
                    <span className={"d-flex gap-2 align-items-start flex-column"}>
                        <span className={"semi-bold text-nowrap"}>{t("search-questions-keyword")}:</span>
                        {EpInputTxt0(fields?.QKw, setField('QKw'), 'tqkw', 'w-100 border', {} )}
                    </span>: ''}  
            </Accordion>
        </div>
        {poolOup?<><span role={"button"} onClick={() => handleActive(0)}
            className={`${findEx? "bg-exercise-third-btn text-light": "bg-exercise-third-btn-hover-light text-exercise-third-btn"
            } ${type} w-100 py-1 px-3 d-flex semi-bold fs-5 accordion-button ${act0? "collapse": "collapsed"}`} >{t("filters")}</span>
            <div className={`h-100 pt-2 overflow-auto  ${act0? "flex-grow-1": "d-none"}`}>
            <Accordion defaultActiveKey={[0,1,2,3,4,5,6,7,8,9]} alwaysOpen className={`exercise-subfilter d-flex flex-column gap-2`}>
{/* expf2 filter Question */}
                <CpEpTypeMSetTrees {...{FilterMSets: opts?.QSetTagMsets, ct, opens, setOpen, metas, setMeta}} /> 

                <CpAccordionItem id={0} title={t("system_question_type")} headerSize={"fs-6"}><CpSQTypesFilter {...{...inpProps, SQTypes, setSQTypes}} /></CpAccordionItem>
                <div className={"mx-2 split-line"}></div>

                <CpAccordionItem id={0} title={t("mark-range")} headerSize={"fs-6"}><CpRangeFilter {...inpProps} key1='m1' key2='m2' /></CpAccordionItem>
                <div className={"mx-2 split-line"}></div>
                <CpAccordionItem id={1} title={t("question-keywords")} headerSize={"fs-6"}><CpQuestionKeywordsFilter {...inpProps} /></CpAccordionItem>
{/*
                <div className={"mx-2 split-line"}></div>
                <CpAccordionItem id={"3"} title={t("marking-types")} headerSize={"fs-6"}><CpMarkingTypesFilter {...inpProps} /></CpAccordionItem>
*/}
            </Accordion></div>
        </>: ''}
    </div>
});

const langItems = t => obj2DropdownItem({ en: t("assignment-settings-english"), ct: t("assignment-settings-chinese"), });

const findQPoolDDI = t => ({
    oupq: { t: t("search-questions-options-default-question-pool"), groupLast: 1, }, 
    schoolq: { t: t("search-questions-options-school-shared-exercises"), groupLast: 1, },
    myq: { t: t("search-questions-options-my-exercises"), groupLast: 0, }, 
}); 

const findQSortDDI = t => obj2DropdownItem({
    n: t("sorting-options-create-new"), o: t("sorting-options-create-old"),
    a: t("sorting-options-stroke-down"), z: t("sorting-options-stroke-up"),
    like: t("sorting-options-favourite"),
    //an: t("sorting-options-assigned-new"), ao: t("sorting-options-assigned-old"),
});
const validateFindQ = (subjItems, QMSubjId, pools, pool, bys, by, sorts, sort) => {
    const j = subjItems[QMSubjId]? QMSubjId: objKeys(subjItems)[0];
    const p = pools[pool]? pool: objKeys(pools)[0];
    const b = bys[by]? by: objKeys(bys)[0];
    const s = sorts[sort]? sort: objKeys(sorts)[0];
    return {j, p, b, s};
};

export const clearFindQFields = (setFields, inFields) => {
    const {QMSubjId, pool, sort} = toObj(inFields);
    setFields({QMSubjId, pool, sort});
}
const poolTypes = p => [p === 'oupq', p === 'schoolq', p === 'myq'];

const sortTeacherNames = (thrNames, ut) => {
    const tname = ([tid, t]) => [tid, (t? (ut(t.nameEng, t.nameChi) || tid): '')];  
    const ents = objEntries(thrNames).map(tname);
    ents.sort((a,b) => strCmpTrimLow(a[1], b[1]));
    return ents;
};


