import React, {useContext, useState} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { stopEvent } from "../../libs/libUI";
import { ReduxBind } from "../../saga/ReduxState";
import LayerExNew from "../EPExercise/LayerExNew";

const CpCreateExerciseCTA = ReduxBind(props => { // base on poc CreateExerciseCTA
    const { goPopExer, subjMetas } = props;
    const [t] = useUILang();
    const [newSrc, setNewSrc] = useState();
    const clickCreate = e => {stopEvent(e); setNewSrc({})};

    return <>
    <span role={"button"} onClick={clickCreate}
          className={"exercise-create-btn d-flex justify-content-center align-items-center gap-1 user-select-none"} >
        <span className={"semi-bold d-flex mx-4 text-nowrap fs-6"}>{t("create-new-exercise")}</span>
    </span>
    {newSrc? <LayerExNew {...{goPopExer, newSrc, setNewSrc, isCopy:0, subjMetas }} />: '' }
    </>
});

export default CpCreateExerciseCTA;