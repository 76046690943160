import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import { toAry, toObj, toStr } from '../../../libs/libType';

export default class CpCkInsertImage extends Plugin {
    static get pluginName() {
        return 'CpCkInsertImage';
    };

    _createImg(writer, id = 'oupImageID', src = "", width = "25%") {
        //const img = writer.createElement('inlineImage', { oupid: id, src: src, width: width, crossorigin:"anonymous"});
        const img = writer.createElement('inlineImage', { oupid: id, src: src, width: width });
        return img;
    };

    init() {
        
        this.config = this.editor.config.get('CpCkInsertImage') || {};
        this._setFunc();
        this._addViewsAndEvent();
        this._addSchemeAndConverters();

        this.requestMediaPoolUpdate = false;
        this.updatedURL = [];
    };

    _setFunc() {
        if (typeof this.config.updateMediaID === 'function') {
            this.updateMediaID = this.config.updateMediaID;
        };
    };

    _addViewsAndEvent() {
        const editor = this.editor;
        const { ui: { componentFactory }, } = editor;
        const config = toObj(editor.config.get('CpCkInsertImage'));
        
        const setOnAddMedia = config.setOnAddMedia;
        const uit = config.uit;
        
        componentFactory.add('CpCkInsertImage', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: uit('insert-image'), //'Insert image',
                icon: imageIcon,
                tooltip: true
            });

            view.on("execute", () => {
                const pp = editor.model.document.selection.getFirstPosition();
                
                setOnAddMedia && setOnAddMedia({onAdd:(medias => {
                    
                    const ms = toAry(medias);
                    if (ms.length) {
                        editor.model.change(writer => {
                            ms.forEach(m => {
                                
                                const src = toStr(m.dl);
                                const mediaId = toStr(m.mediaId);
                                //const mime = extToMime(m.ext);
                                //const name = m.name;
                                //const imageElement = writer.createElement('imageInline', { src, oupid:mediaId, width: '25%', crossorigin:'anonymous' });
                                const imageElement = writer.createElement('imageInline', { src, oupid:mediaId, width: '25%' });
                                
                                editor.model.insertContent(imageElement, pp, "end");
                                writer.setSelection( imageElement, 'in' );
                            });
                        });
                    }
                    setOnAddMedia(0);
                    editor.focus();
                }), maxMedia:10, mimes:['image']});
                editor.focus();
            });

            return view;
        });


        this.editor.model.document.on('change:data', event => {
            this.onChange();
        },{ priority: 'high' });
    };

    _addSchemeAndConverters() {
        const { schema } = this.editor.model;
        const imageUtils = this.editor.plugins.get('ImageUtils');

        schema.extend('imageInline', { allowAttributes: ['oupid', 'crossorigin'] });
        schema.extend('imageBlock', { allowAttributes: ['oupid', 'crossorigin'] });

        const conversion = this.editor.conversion;
        conversion.for('downcast')
            .add(downcastImageAttribute(imageUtils, 'imageInline', 'oupid'));
        conversion.for('downcast')
            .add(downcastImageAttribute(imageUtils, 'imageInline', 'crossorigin'));            
        conversion.for('downcast')
            .add(downcastImageAttribute(imageUtils, 'imageBlock', 'oupid'));
        conversion.for('downcast')
            .add(downcastImageAttribute(imageUtils, 'imageBlock', 'crossorigin'));
            
        conversion.for('upcast')
            .attributeToAttribute({
                view: {
                    name: 'img',
                    key: 'oupid'
                },
                model: 'oupid'
            });
    };

    updateImages(mdImages = {}) {
        
        this.onChange(mdImages ? mdImages : {});
    };

    onChange(retImages = []) {
        this.editor.model.change(writer => {
            const root = this.editor.model.document.getRoot();
            const range = writer.createRangeIn(root);
            
            let pEle = [];
            let mdIDarr = [];

            for (const value of range.getWalker()) {
                
                if ((value.item.name === 'imageInline' || value.item.name === 'imageBlock') && value.type === 'elementStart') {
                    
                    pEle.push({ item: value.item, type: value.item.name });
                    const cid = value.item.getAttribute('oupid');
                    if (!this.updatedURL[cid]) {
                        this.updatedURL[cid]=false;
                        mdIDarr.push(cid);
                    };
                };
            };

            if (pEle.length === 0) return;
            const allDone = Object.keys(this.updatedURL).findIndex((id)=>{return !this.updatedURL[id]}) < 0;
            
            if (allDone) return;
            const _updateImg = (mdImages={}) => {
                if (mdImages && Object.keys(mdImages).length > 0) { 
                    pEle.forEach((obj, ii) => {
                        const id = obj.item.getAttribute("oupid");
                        const mediaInfo = mdImages[id];
                        if (mediaInfo) {
                            this.updatedURL[id]=true;
                            writer.setAttribute('src', mediaInfo.dl, obj.item);
                        };
                    });
                    pEle = [];
                };
            };

            if (!this.requestMediaPoolUpdate && this.updateMediaID) {
                const result = this.updateMediaID([...mdIDarr]);
                _updateImg(result);
                this.requestMediaPoolUpdate = true;
            };

            _updateImg(retImages);
        });
    };
};

function downcastImageAttribute(imageUtils, imageType, attributeKey) {
    return dispatcher => {
        dispatcher.on(`attribute:${attributeKey}:${imageType}`, converter);
    };

    function converter(evt, data, conversionApi) {
        if (!conversionApi.consumable.consume(data.item, evt.name)) {
            return;
        }

        const viewWriter = conversionApi.writer;
        const element = conversionApi.mapper.toViewElement(data.item);
        const img = imageUtils.findViewImgElement(element);

        viewWriter.setAttribute(data.attributeKey, data.attributeNewValue || '', img);
    };
};

