import React, { useEffect, useMemo, useState, } from 'react';
import { useUILang } from '../utils/useUILang';

import { _ATRoot } from '../../consts/ATConstReact';

import { CpSettingModalTabContainer, CpSettingModalTitle, } from '../../AppExPFUser/_components/CpSettingModalTabCps';
import CpDropdown, { ent2DropdownItem, obj2DropdownItemT } from '../../AppExPFUser/_components/CpDropdown';
import { atErrNoDot, atErrDot,  ATUIText,  atWarnNoDot, BtnPopDev, preJS, BtnDev, } from '../AppUtil';
import { CpEPTagMetaList } from '../../AppExPFUser/EPExercise/CpExerciseCard';
import { langTxt } from '../../AppExPFUser/EPLibrary/PageLibrary';
import { inpputNum0 } from '../../AppExPFUser/EPLibrary/LayerExerFilter';
import { aryLen, nestSolid, objKeys, toAry, toInt, toObj, toStr } from '../../libs/libType';
import { _ATMetaTransByCode } from '../../consts/ATMetaTypes';
import { normalizeRemeRule, RemeLvPre, RemeLvPro, RemeLvSame, validateRemeRule } from '../../consts/ATValidateReme';
import { CHECKBOX_VARIANT, SIZE } from '../../AppExPFUser/_components/CpExPFConst';
import { stopEvent } from '../../libs/libUI';
import CpCheckboxComponent from '../../AppExPFUser/_components/CpCheckboxComponent';
import { CpSettingModalText } from '../../AppExPFUser/_components/CpSettingModalTabCps';
import { ReduxBind } from '../../saga/ReduxState';
import { AddTitle, useQCntRemes, } from './CpExReme';

// follow poc
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpExerciseAccordionOpenCTA from '../components/CpExerciseAccordionOpenCTA';
import { toUniIdAry } from '../../consts/ATValidate';
import { splitSMId } from '../../consts/ATValidateMetaSet';
import { TagMetaReme, TagMetaRemeNo, TagMetaRemeYes } from './TagRemeLv';
import CpShareOffcanvas from '../../AppExPFUser/_components/CpShareOffcanvas';
import { isDupReme } from '../../consts/ATValidateExer';
import CpTips from '../../AppExPFUser/EPReports/_component/CpTips';
import { atWarnDot } from '../AppUtil';
import { fakeIsMobile } from '../utils/useWinResize';
import { isMobileScreen } from '../../poc/helper/getWidnowWidth';
import { hasErr } from '../utils/useEditDraftPub';
import { atErrsDiv } from '../AppUtil';

const LayerEditReme = ReduxBind(props => {
    const {dispatch, lock, setREx, setOnAddMedia, showEn, idx, isAdd, isAT} = props;
    const {ERemes, edReme, edRIdx, remeQCnts,} = toObj(props);
    const REx = toObj(props.REx);

    const {tagMSets, useTagMSets, TMSets, MTDDIs} = props;

    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const ct = (e, c) => langTxt(showEn, e, c);

    const [fieldErrs, setFieldErrs] = useState({});
    const fes = toObj(fieldErrs);

    const setKV = key => val => {setREx({...REx, [key]:val})};
    const setMSetId = setKV('MSetId');
    const setRemeLv = setKV('rmLv');

    const avgDDIs = useMemo(() => obj2DropdownItemT(t, markAorB), [uiEn]);

    const msid = REx.MSetId; 
    const mst = toStr(tagMSets[msid]?.MSType || objKeys(TMSets)[0]);
    const setMst = t => { if(t) setREx({...REx, MSetId: toStr(objKeys(TMSets[t])[0])}); };
    
    const MSDDIs = useMemo(() => ent2DropdownItem(objKeys(TMSets?.[mst])
        .map(i => tagMSets[i])
        .filter(s => s)
        .map(s => [s.metaSetId, ct(s.MSNameEn, s.MSNameCt)])),
    [msid, mst, tagMSets, showEn]);
    
    const mset = useTagMSets([msid])[msid];
    const remeQNum = toInt(props.remeQNum);

    const remeLVANs = toObj(useQCntRemes(remeQNum, msid, mset, props.EQSum, remeQCnts));
//console.log('remeLVANs', remeLVANs);
    const sameMIds = toUniIdAry(remeLVANs[RemeLvSame]?.smids).map(i => splitSMId(i)[1]);
    const lockSame = aryLen(remeLVANs[RemeLvSame]?.smids)? 0: 1;
    const lockPre = aryLen(remeLVANs[RemeLvPre]?.A_smids)? 0: 1;
    const lockPro = aryLen(remeLVANs[RemeLvPro]?.A_smids)? 0: 1;
    const A_RemeMIds = toUniIdAry(remeLVANs[REx?.rmLv]?.A_smids).map(i => splitSMId(i)[1]);
    const N_RemeMIds = toUniIdAry(remeLVANs[REx?.rmLv]?.N_smids).map(i => splitSMId(i)[1]);

    //const [mids, remeMIds, A_RemeMIds, sameMIds] = useQCntLvRemes(remeQNum, REx?.rmLv, msid, mset, props.EQSum, remeQCnts); 
    //const N_RemeMIds = useMemo(() => toUniIdAry(remeMIds).filter(mid => !A_RemeMIds.includes(mid)), [A_RemeMIds, remeMIds]);

    useEffect(() => {
        const i = (!msid) && objKeys(TMSets?.[mst])[0]; 
        if(i) setMSetId(i);
    }, [mst, msid, mset, TMSets]);

    const ruleNameF = showEn? 'ruleNameEn': 'ruleNameCt';
    const _MSTName = mset && t2(_ATMetaTransByCode(mset.MSType), showEn);
    const _MSName = mset && ct(mset?.MSNameEn, mset?.MSNameCt);

    const errDup = useMemo(() => toAry(ERemes).some((b, i) => (i !== idx) && isDupReme(REx, b)), [ERemes, REx]);
    const noSave = errDup;

    const clickSaveRR = e => {
      stopEvent(e);
      const r = normalizeRemeRule(REx);
      const fes = validateRemeRule(r, {showEn});
      if(!hasErr(fes)){
        clickSaveEx();
        return;
      }
      setFieldErrs(fes);
    };

    //console.log('LayerEditReme', {});
    //<div>{preJS({_MSTName, _MSName, msid, mst, TMSets, REx, }, 3)}</div>
    const accordionEx = <div className={"LayerEditReme rounded-light border bg-white mb-3"}>
      <Accordion defaultActiveKey={isMobileScreen()? "": "0"}>
        <div className="d-flex justify-content-between align-items-center gap-3 p-2 semi-bold w-100 bg-dim-100 text-exercise-third-btn"
          style={{ borderRadius: "4px" }}>
          <CpExerciseAccordionOpenCTA eventKey="0">
            {t("exercise-edit-tab-details-exercise-detail-follow-up-exercise")}
          </CpExerciseAccordionOpenCTA>
        </div>
        <Accordion.Collapse eventKey="0"><div>
          <hr className={"mt-0 mb-2 border-dim-400"} />
          <div className="d-flex flex-column flex-wrap gap-2 justify-content-between px-2 pb-2 fs-8">
            <div className="text-decoration-underline">
              {t("exercise-edit-tab-details-available-exercise-follow-up-exercise")}
            </div>
            <div className="d-flex flex-wrap gap-2 ">
                {aryLen(A_RemeMIds)? '': <span className="fs-8" style={{color:"#545454"}}>{t('exercise-edit-tab-details-not-follow-up-categories-follow-up-exercise')}</span>}
                <CpEPTagMetaList {...{ct, msid, mids:A_RemeMIds, useTagMSets, tagMSets, CpTag:TagMetaRemeYes}} />
            </div>
            {(REx.rmLv === RemeLvSame)?<>
              <div className="text-decoration-underline">
                {t("exercise-edit-tab-details-not-available-exercise-follow-up-exercise")}
              </div>
              <div className="d-flex flex-wrap gap-2 ">
                  {aryLen(N_RemeMIds)? '': <span className="fs-8" style={{color:"#545454"}}>{t('exercise-edit-tab-details-not-follow-up-categories-follow-up-exercise')}</span>}
                  <CpEPTagMetaList {...{ct, msid, mids:N_RemeMIds, useTagMSets, tagMSets, CpTag:TagMetaRemeNo}} />
              </div>
            </>:''}
          </div>
        </div></Accordion.Collapse>
      </Accordion>
    </div>

    const showErr = Object.keys(toObj(fes)).length > 0;
    const {clickCloseEx, clickSaveEx} = props;
    return <CpShareOffcanvas className={"LayerEditReme offcanvas-mw-90"} {...{show: true, placement: "end", disableHeader: false, disableClose: true, handleClose: clickCloseEx}} 
      title={<AddTitle {...{title: t(isAdd? "exercise-edit-tab-next-steps-add-follow-up-exercise": "exercise-edit-tab-next-steps-edit-follow-up-exercise"), 
        icon: isAdd? IconList.general.add: IconList.general.edit, 
        handleClose: clickCloseEx}} >
          <BtnPopDev txt='dev'>{preJS({idx, isAdd, REx}, 3)}</BtnPopDev>
          <BtnPopDev txt='fes'>{preJS({fes}, 3)}</BtnPopDev>
      </AddTitle>}
      footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
          <Button {...{variant:'gray-body-color w-100 border', onClick: clickCloseEx, }}>{t("cancel")}</Button>
          <Button {...{variant:'exercise-third-btn btn-block w-100', onClick: clickSaveRR, disabled: noSave, }} >{t(isAdd? "add": "update")}</Button>
      </div>} 
      >
      <CpSettingModalTabContainer>
          {showErr?atErrsDiv(fes, '', t):''}
          <CpSettingModalTitle variant="LayerEditReme gap-3 d-flex">
            {t("exercise-edit-tab-next-steps-rule-name")}:
            <span style={{ width: "400px" }}>
              {ATUIText(REx?.[ruleNameF], setKV(ruleNameF), 'txtTE',lock,'',"w-100 border mb-3 m-0")}
            </span>
          </CpSettingModalTitle>
{/*atErrNoDot(fes[ruleNameF], t)*/}
          <Row>
            <Col lg={6}>
            <div className={"semi-bold text-exercise-third-btn fs-5 w-100 mb-3"}>
              {t("exercise-edit-tab-next-steps-when")}
            </div>
            <div className={"border p-3 d-flex flex-column gap-3 w-100"}>
              <div className={"d-flex gap-2 flex-column"}>
                <span className={"d-flex gap-2 align-items-center semi-bold pb-1"}>
                  {t("exercise-edit-tab-details-metadata-follow-up-exercise")}:
                  <CpTips autoPos={1} exSpace={80}>{atWarnDot("warning.warning_metadata_info",t)}</CpTips>
                </span>
                <span style={{ width: "200px" }}>
                  <CpDropdown {...{className:"w", placeHolder:_MSTName, items:MTDDIs, idx:mst, cb:setMst}} />
                </span>
              </div>
              <div className={"d-flex gap-2 flex-column"}>
                <span className={"semi-bold pb-1"}>{t("exercise-edit-tab-details-metadata-set-follow-up-exercise")}:</span>
                <span className="exercise-metadata-set">
                  <CpDropdown {...{className:"w", placeHolder:_MSName, items:MSDDIs, idx:msid, cb:setMSetId}} />
                </span>
{/*atErrNoDot(fes.MSetId, t)*/}
              </div>
              <div className={"d-flex gap-2 flex-column mb-3"}>
                <span className={"semi-bold pb-1"}>
                  {t("exercise-edit-tab-details-assessed-categories-follow-up-exercise")}:
                </span>
                <div className="d-flex flex-wrap gap-2 fs-8 ">
                    {aryLen(sameMIds)? '': t('exercise-edit-tab-details-not-assessed-categories-follow-up-exercise')}
                    <CpEPTagMetaList {...{ct, msid, mids: sameMIds, useTagMSets, tagMSets, CpTag:TagMetaReme}} />
                </div>
              </div>
              <div className={"d-flex gap-2 flex-column"}>
                <span className={"semi-bold pb-1"}>
                  {t("exercise-edit-tab-details-attempted-score-follow-up-exercise")}:
                </span>
                <span className="d-flex align-items-center gap-2">
                  <span className="d-flex" style={{ width: "200px" }}>
                    {/* exercise-edit-tab-details-attempted-score-higher-follow-up-exercise  */}
                    {/* exercise-edit-tab-details-attempted-score-lower-follow-up-exercise  */}
                    <CpDropdown className="w70" items={avgDDIs} idx={REx.rmAB} cb={setKV('rmAB')} />
                  </span>
                  <span style={{ width: "100px" }}>
                    {inpputNum0(REx.rmMark, setKV('rmMark'), 'im1','w-75 border', {}, {min:0, max:100} ) }{t("%")}
                    {/*<input className={"w-50 border"} value={""} /> {t("%")}*/}
                  </span>
                </span>
{/*atErrNoDot(fes.rmMark, t)*/}
                </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className={"semi-bold text-exercise-third-btn fs-5 w-100 mb-3 mt-3 mt-lg-0"}>
              {t("exercise-edit-tab-next-steps-action")}    
            </div>
            <div className={"border p-3 d-flex flex-column gap-3 w-100"}>
              <div className={"d-flex gap-2 align-items-center"}>
                <span className={"semi-bold pb-1"}>
                  {t("exercise-edit-tab-next-steps-provide-the-student-follow-up-exercise")}:
                </span>
              </div>
              <div className={"d-flex gap-2 align-items-center"}>
                <span className={"semi-bold pb-1"}>{t("exercise-edit-tab-details-no-of-questions-follow-up-exercise")}:</span>
                <span className="pb-1">{remeQNum}</span>
              </div>
              <div className={"d-flex gap-2 flex-column"}>
                <span className={"d-flex gap-2 align-items-center semi-bold pb-1"}>
                  {t("exercise-edit-tab-details-question-scope-follow-up-exercise")}:
                  <CpTips autoPos={1} exSpace={80}>{atWarnDot("warning.warning_question_scope_info",t)}</CpTips>
                </span>
                <BtnDev txt='remeLVANs'>{preJS({remeLVANs},3)}</BtnDev>                  
                <div className={"d-flex flex-column gap-2 flex-wrap ms-3 ms-lg-0"}>
                  <CpRadio {...{lock:lockSame, val:RemeLvSame, idx:REx.rmLv, cb:setRemeLv, txt:t('exercise-edit-tab-details-question-scope-same-follow-up-exercise')}} />                    
                  {atErrNoDot(errDup && (REx.rmLv === RemeLvSame) && 'error.error_at_repeated_question_scope', t)}
                  {atWarnNoDot(lockSame && 'warning.warning_no_available_exercise', t)}
                  {(REx.rmLv === RemeLvSame)? accordionEx:''}
                  <CpRadio {...{lock:lockPre, val:RemeLvPre, idx:REx.rmLv, cb:setRemeLv, txt:t('exercise-edit-tab-details-question-scope-pre-follow-up-exercise')}} />
                  {atErrNoDot(errDup && (REx.rmLv === RemeLvPre) && 'error.error_at_repeated_question_scope', t)}
                  {atWarnNoDot(lockPre && 'warning.warning_no_available_exercise', t)}
                  {(REx.rmLv === RemeLvPre) ? accordionEx:''}
                  <CpRadio {...{lock:lockPro, val:RemeLvPro, idx:REx.rmLv, cb:setRemeLv, txt:t('exercise-edit-tab-details-question-scope-pro-follow-up-exercise')}} />
                  {atErrNoDot(errDup && (REx.rmLv === RemeLvPro) && 'error.error_at_repeated_question_scope', t)}
                  {atWarnNoDot(lockPro && 'warning.warning_no_available_exercise', t)}
                  {(REx.rmLv === RemeLvPro) ? accordionEx:''}
                </div>
              </div>
              <div className={"d-flex gap-2 flex-column"}>
                <span className={"d-flex gap-2 align-items-center semi-bold pb-1"}>
                  {t("exercise-edit-tab-details-mastery-score-follow-up-exercise")}:
                  <CpTips autoPos={1} fixedWidth={424} exSpace={80}>{atWarnDot("warning.warning_mastery_score_info",t)}</CpTips>
                </span>
                <span style={{ width: "100px" }}>
                    {inpputNum0(REx.rmPass, setKV('rmPass'), 'im1','w-75 border mb-3', {}, {min:0, max:100} ) }{t("%")}
                </span>
{/*atErrNoDot(fes.rmPass, t)*/}
              </div>
            </div>
          </Col>
        </Row>        
        </CpSettingModalTabContainer>
    </CpShareOffcanvas>;
});
export default LayerEditReme; 

//export const markAorB = { a:'higher-than-or-equal-to', b:'lower-than-or-equal-to', };
export const markAorB = { a:'exercise-edit-tab-details-attempted-score-higher-follow-up-exercise',
  b:'exercise-edit-tab-details-attempted-score-lower-follow-up-exercise', };

const CpRadio = props => {
    const {idx, val, cb, txt, lock} = props;
    const onClick = lock? undefined: e => {stopEvent(e); cb(val);} 

    return <span {...{role: "button", onClick, className: "CpRadio d-flex align-items-center gap-3", }} >
        <CpCheckboxComponent {...{checked:(idx===val), lock, className:"exercise-radiobox", 
          checkIcon: false, size: SIZE.XXXS, variant: CHECKBOX_VARIANT.ROUND_BORDER, }} />
        <CpSettingModalText {...{showDim:lock}} >{txt}</CpSettingModalText>
    </span>;
};


