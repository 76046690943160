import React, {} from 'react';

import * as UI from '../../libs/libUI';
import { isAry } from '../../libs/libType';
//import { apiCallLoad_, s3UploadPrivate, } from '../../libs/awsFuncs';
//import { ReduxBind } from '../../saga/ReduxState';
//import CpTabHead from '../components/CpTabHead';
//import { arrayAddOrDelete } from '../../libs/libArray';
//import { fileExt } from '../../libs/libFormat';
//import { preJS } from '../AppUtil';
//import { HttpPostMP, objToFormData } from '../../libs/libHttp';

const CpATSubjectTickListJSX = ({ATSubjects, mySjIds, ticks, setTick, lock=0}) => {
    return <div className='subjectItemContainer'>{
    isAry(ATSubjects) && isAry(ticks) && ATSubjects
    .map(s => {
        const sid = s.ATSubjectId;
        return [ticks.includes(sid), mySjIds.includes(sid), s];
    })
    .filter( ([on, my, s]) => (on || my) )
    .map(([on, my, s]) => {
        const sid = s.ATSubjectId;
        const [setter, txt] = [setTick(sid), ' '+s.ATSubjectName];
        
        return <ATTickItem key={sid} {...{on, setter, txt, lock: (lock||(!my))  }} />;
    })
    }</div>;
}; 
const CpATSubjectTickList = (ATSubjects, mySjIds, ticks, setTick, lock=0) => <CpATSubjectTickListJSX {...{ATSubjects, mySjIds, ticks, setTick, lock }} />
export default CpATSubjectTickList;

export const ATTickItem = ({on, setter, txt, lock}) => {
    const cls = (lock? '': 'clickable ')+'subjectItem'+(on? 'Sel': '');
    const click = ((!lock) && setter)? (e => { UI.stopEvent(e); setter(!on); }) : undefined;
    return <div className={cls} onClick={click} ><label>{UI.CheckBox0(on, click, 'chk'+txt, lock)} {txt} </label></div>;
}; 
export const aTTickItem = (on, setter, txt, lock) => <ATTickItem {...{on, setter, txt, lock}} />;

