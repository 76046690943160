import React, { useRef, useEffect, useState } from 'react';
import * as UI from "../../libs/libUI";
//import { v4 as uuidv4 } from 'uuid';
//import { fabric } from 'fabric-with-gestures';
//import { fabric } from 'fabric';
import { useFabric } from './CpDrawingCanvas';
import "../../App.css";

/*
Event object fired on mousedown
    _onTouchStart: function(e) {
      e.preventDefault();
      if (this.mainTouchId === null) {
        this.mainTouchId = this.getPointerId(e);
      }
      this.__onMouseDown(e);
      this._resetTransformEventData();
      var canvasElement = this.upperCanvasEl,
          eventTypePrefix = this._getEventPrefix();
      addListener(fabric.document, 'touchend', this._onTouchEnd, addEventOptions);
      addListener(fabric.document, 'touchmove', this._onMouseMove, addEventOptions);
      // Unbind mousedown to prevent double triggers from touch devices
      removeListener(canvasElement, eventTypePrefix + 'down', this._onMouseDown);
    },
*/

//fabric.current.Canvas.prototype._onTouchStart
const initFabricFunc = () => {
    if (window.fabric) {
        //console.log('initFabricFunc CpLDCanvas');
        const fabric = window.fabric;
        var defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
        fabric.util.object.extend(fabric.Canvas.prototype, {
            _onTouchStart: function (e) {
                var target = this.findTarget(e);
                // if allowTouchScrolling is enabled, no object was at the
                // the touch position and we're not in drawing mode, then
                // let the event skip the fabricjs canvas and do default
                // behavior
                if (this.allowTouchScrolling && !target && !this.isDrawingMode) {
                    // returning here should allow the event to propagate and be handled
                    // normally by the browser
                    return;
                }
                // otherwise call the default behavior
                defaultOnTouchStartHandler.call(this, e);
            }
        });

        /*
        fabric.Canvas.prototype._onTouchStart = (e) => {
            if (!this.allowTouchScrolling || this.getActiveObject()) {
                e.preventDefault && e.preventDefault();
            };
        
            if (this.mainTouchId === null) {
                this.mainTouchId = this.getPointerId(e);
            };
        
            this.__onMouseDown(e);
            this._resetTransformEventData();
        
            const canvasElement = this.upperCanvasEl;
            const eventTypePrefix = this._getEventPrefix();
        
            fabric.util.addListener(document, 'touchend', this._onTouchEnd, {passive: false});
            fabric.util.addListener(document, 'touchmove', this._onMouseMove, {passive: false});
        
            // Unbind mousedown to prevent double triggers from touch devices
            fabric.util.removeListener(canvasElement, eventTypePrefix + 'down', this._onMouseDown);
        };
        */

        // setup for answer block drawing, circle, line and text block
        if (!fabric.AnsLine) {
            fabric.AnsLine = fabric.util.createClass(fabric.Line, {
                type: 'AnsLine',
                initialize: function (points, options) {
                    options || (options = {});
                    this.callSuper('initialize', points, options);
                    this.set('oupansid', options.oupansid || '');
                },
                toObject: function () {
                    return fabric.util.object.extend(this.callSuper('toObject'), {
                        oupansid: this.get('oupansid'),
                    });
                },
                _render: function (ctx) {
                    this.callSuper('_render', ctx);
                },
            });
            fabric.AnsLine.fromObject = function (object, callback) {
                //return fabric.Object._fromObject('AnsLine', object, callback, 'points');
                function _callback(instance) {
                    delete instance.points;
                    callback && callback(instance);
                };
                const options = fabric.util.object.clone(object, true);
                options.points = [object.x1, object.y1, object.x2, object.y2];
                return fabric.Object._fromObject('AnsLine', options, _callback, 'points');
            }; 
        };       

        if (!fabric.AnsCircle) {
            fabric.AnsCircle = fabric.util.createClass(fabric.Circle, {
                type: 'AnsCircle',
                initialize: function (options) {
                    options || (options = {});
                    this.callSuper('initialize', options);
                    this.set('oupansid', options.oupansid || '');
                },
                toObject: function () {
                    return fabric.util.object.extend(this.callSuper('toObject'), {
                        oupansid: this.get('oupansid'),
                    });
                },
                _render: function (ctx) {
                    this.callSuper('_render', ctx);
                },
            });
            fabric.AnsCircle.fromObject = function (object, callback) {
                return fabric.Object._fromObject('AnsCircle', object, callback);
            };        
        };

        if (!fabric.AnsRect) {
            fabric.AnsRect = fabric.util.createClass(fabric.Rect, {
                type: 'AnsRect',
                // initialize can be of type function(options) or function(property, options), like for text.
                // no other signatures allowed.
                initialize: function (options) {
                    options || (options = {});
                    this.callSuper('initialize', options);
                    this.set('label', options.label || '');
                    this.set('oupansid', options.oupansid || '');
                },
                toObject: function () {
                    return fabric.util.object.extend(this.callSuper('toObject'), {
                        label: this.get('label'),
                        oupansid: this.get('oupansid'),
                    });
                },
                _render: function (ctx) {
                    this.callSuper('_render', ctx);

                    ctx.font = '20px Helvetica';
                    ctx.fillStyle = '#333';
                    ctx.fillText(this.oupansid + ":" + this.label, -this.width / 2, -this.height / 2 + 20);
                }
            });
            fabric.AnsRect.fromObject = function (object, callback) {
                return fabric.Object._fromObject('AnsRect', object, callback);
            };    
        };    

        if (!fabric.AnsText) {
            fabric.AnsText = fabric.util.createClass(fabric.Textbox, {
                type: 'AnsText',
                // initialize can be of type function(options) or function(property, options), like for text.
                // no other signatures allowed.
                initialize: function (text, options) {
                    options || (options = {});
                    this.callSuper('initialize', text, options);
                    this.set('oupansid', options.oupansid || '');
                    this.set('showTextBoxBorder', options.showTextBoxBorder || false);
                    this.set('textboxBorderColor', options.textboxBorderColor || '');
                    this.padding = 5;
                },
                toObject: function () {
                    return fabric.util.object.extend(this.callSuper('toObject'), {
                        oupansid: this.get('oupansid'),
                        showTextBoxBorder: this.get('showTextBoxBorder'),
                        textboxBorderColor: this.get('textboxBorderColor'),
                    });
                },
                _renderBackground: function (ctx) {
                    if (!this.backgroundColor) {
                        return;
                    }
                    var dim = this._getNonTransformedDimensions();
                    ctx.fillStyle = this.backgroundColor;

                    ctx.fillRect(
                        -dim.x / 2 - this.padding,
                        -dim.y / 2 - this.padding,
                        dim.x + this.padding * 2,
                        dim.y + this.padding * 2
                    );
                    // if there is background color no other shadows
                    // should be casted
                    this._removeShadow(ctx);
                },

                _render: function (ctx) {
                    this.callSuper('_render', ctx);
                    if (this.showTextBoxBorder) {
                        const w = this.width + this.padding * 2,
                            h = this.height + this.padding * 2,
                            x = (-this.width / 2) - this.padding,
                            y = (-this.height / 2) - this.padding;
                        ctx.beginPath();
                        ctx.moveTo(x, y);
                        ctx.lineTo(x + w, y);
                        ctx.lineTo(x + w, y + h);
                        ctx.lineTo(x, y + h);
                        ctx.lineTo(x, y);
                        ctx.closePath();
                        var stroke = ctx.strokeStyle;
                        ctx.strokeStyle = this.textboxBorderColor;
                        ctx.stroke();
                        ctx.strokeStyle = stroke;
                    };
                }
            });
            fabric.AnsText.fromObject = function (object, callback) {
                return fabric.Object._fromObject('AnsText', object, callback, 'text');
            };
        };
    };
};
////////////////////////////////////////////////////////////////////////////////

const CpLDCanvas = (props) => {
    //const {BGLoaded} = props; // check for background image loaded
    // mode = fit to width(container width), fit to height(img original height)
    const { BGImgSrc, mode, id, selectAns, updateAnsInfo, setVis, setCanvasInfo, onResize,
        onDblClick, returnInfo } = props;
    const [BGInfo, setBGInfo] = useState({ loaded: false, width: 0, height: 0 });
    const [sel, setSel] = useState(null);
    const myID = id ? id : 'myID';
    //const myContainer = useRef(null);
    const myParent = useRef(null);
    const myCanvas = useRef(null);
    //const created = useRef(false);
    const myLastImgSrc = useRef('');
    const aspectRatio = useRef(1);
    const containerID = 'canvasBaseCont' + myID;
    const isScriptReady = useFabric();

    const resizeCanvas = () => {
        if (myParent.current) {
            const width = myParent.current.clientWidth;
            const height = Math.round(width * aspectRatio.current);
            myParent.current.style.height = height + 'px';
            //setCanvasInfo({width:width, height:height});

            if (myCanvas.current) {
                myCanvas.current.setHeight(height);
                myCanvas.current.setWidth(width);
                /*
                myCanvas.current.setDimensions({ width: "100%", height: "100%" }, {
                    cssOnly: true,
                });
                myCanvas.current._initRetinaScaling();
                myCanvas.current.calcOffset();
                myCanvas.current.requestRenderAll();
                */
                if (onResize) onResize(width + height);
            };
        };
    };

    /*
    const setMyCanvas = (width, height) => {
        const { setCanvas } = props;
        const canvas = newCanvas(myID, width, height);
        myCanvas.current = canvas;
        if (typeof setCanvas === 'function') setCanvas(canvas);
    };
    */

    useEffect(() => {
        //console.log("loaded 1 " + BGInfo.loaded + ",2 " + myParent.current + ",3 " + myCanvas.current + ",4 " + isScriptReady );
        if (BGInfo.loaded && myParent.current && myCanvas.current === null && isScriptReady) {
            //created.current = true;
            const { setCanvas } = props;
            //const cp = document.getElementById(containerID);
            const cp = myParent.current;
            const width = cp ? cp.clientWidth : 400;
            const height = cp ? cp.clientHeight : 300;

            aspectRatio.current = height / width;

            const canvas = new window.fabric.Canvas(myID, {
                //backgroundColor: 'yellow',
                //selectionColor: 'blue',
                width: width,
                height: height,
                selection: false,
                //controlsAboveOverlay: true,
                allowTouchScrolling: true
            });

            if (canvas) {

                myCanvas.current = canvas;
                if (typeof setCanvas === 'function') setCanvas(canvas);
                /*
                canvas.on('mouse:down', (opt) => {
                    var evt = opt.e;
                    if (evt.altKey) {
                        canvas.isDragging = true;
                        canvas.selection = false;
                        canvas.lastPosX = evt.clientX;
                        canvas.lastPosY = evt.clientY;
                    }
                });
                canvas.on('mouse:move', (opt) => {
                });
                */

                const logTxt = (xx) => { returnInfo && returnInfo(xx); };
                /*
                canvas.on('mouse:down', (opt) => {
                    logTxt("mouse down", opt.target?.type);
                    if (!opt.target || (opt.target && !(opt.target.type ==='AnsText' || opt.target.type === 'AnsCircle'))) {
                        logTxt("mouse:down prevent default? ", opt.e.preventDefault ? 'preventDefault':'none');
                        opt.e.preventDefault && opt.e.preventDefault();
                        return;
                    };
                });
                */

                canvas.on('object:moving', (opt) => {

                    canvas.isDragging = true;
                });

                canvas.on('mouse:up', (opt) => {
                    //logTxt('mouse:up');
                    // on mouse up we want to recalculate new interaction
                    // for all objects, so we call setViewportTransform
                    /*
                    if (!opt.target || (opt.target && !(opt.target.type ==='AnsText' || opt.target.type === 'AnsCircle'))) {
                        logTxt("mouse:up prevent default? ", opt.e.preventDefault ? 'preventDefault':'none');
                        opt.e.preventDefault && opt.e.preventDefault();
                        return;
                    };*/
                    /*
                    if (canvas.isDragging) {
                        canvas.isDragging = false;
                        //return;
                    };
                    */
                    canvas.setViewportTransform(canvas.viewportTransform);
                    //this.selection = true;
                    if (opt.target && (opt.target.type === 'AnsText' || opt.target.type === 'AnsCircle')) {
                        const name = opt.target.type === 'AnsText' ? 'rect' : 'cir';
                        /*const width = myParent.current.clientWidth;
                        const height = Math.round(width * aspectRatio.current);*/
                        const width = myCanvas.current.getWidth();
                        const height = Math.round(width * aspectRatio.current);


                        if (updateAnsInfo)
                            updateAnsInfo({
                                oupansid: opt.target.oupansid,
                                [name]: { top: (opt.target.top / height), left: (opt.target.left / width) }
                            });

                        if (onDblClick && !(canvas.isDragging)) onDblClick(opt.target.oupansid);
                        /*updateAnsInfo({oupansid:opt.target.oupansid,
                            [name]:{top:opt.target.top, left:opt.target.left}});*/
                        //updateAnsInfo(opt.target.oupansid,{rect: {top:opt.target.top, left:opt.target.left}});
                    };
                    canvas.isDragging = false;
                });
                canvas.on('selection:cleared', (opt) => {

                    if (opt.deselected) setAnsColor(opt.deselected[0], false);
                    if (selectAns) {
                        //updateAnsBlock(sel);
                        selectAns(-1);
                    };
                    setSel(null);
                });
                canvas.on('selection:updated', (opt) => {
                    setAnsColor(opt.deselected[0], false);
                    if (opt.selected[0].type === 'AnsText' || opt.selected[0].type === 'AnsCircle') {
                        setSel(opt.selected[0]);
                        setAnsColor(opt.selected[0], true);
                        if (selectAns) selectAns(opt.selected[0].oupansid);
                    } else {
                        if (selectAns) {
                            //updateAnsBlock(sel);
                            selectAns(-1);
                        };
                        setSel(null);
                    };
                    canvas.isDragging = false;
                });
                canvas.on('selection:created', (opt) => {
                    if (opt.selected[0].type === 'AnsText' || opt.selected[0].type === 'AnsCircle') {

                        setAnsColor(opt.selected[0], true);
                        setSel(opt.selected[0]);
                        if (selectAns) selectAns(opt.selected[0].oupansid);
                    };
                    canvas.isDragging = false;
                });
                canvas.on('mouse:dblclick', (opt) => {

                    /*
                    if (opt.target && (opt.target.type==='AnsText' || opt.target.type=== 'AnsCircle')) {
                        if (onDblClick) onDblClick(opt.target.oupansid);
                    };
                    */
                });
            };
        };
        resizeCanvas();
    }, [BGInfo.loaded, myCanvas.current, isScriptReady, myParent.current]);

    useEffect(() => { resizeCanvas(); }, [mode])

    useEffect(() => {
        window.addEventListener('resize', resizeCanvas);
        return () => {
            if (myCanvas.current) {
                myCanvas.current.dispose();
                myCanvas.current = null;
            };
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [])

    useEffect(() => {
        if ((myLastImgSrc.current !== '') && (BGImgSrc !== myLastImgSrc.current)) {
            if (myCanvas.current) {
                myCanvas.current.dispose();
                myCanvas.current = null;
            };
            setBGInfo({ loaded: false, width: 0, height: 0 });
        };
        myLastImgSrc.current = BGImgSrc;
    }, [BGImgSrc]);

    const handleARImgLoaded = (e) => {
        UI.stopEvent(e);
        setBGInfo({ loaded: true, width: e.target.width, height: e.target.height });
    };

    useEffect(()=>{
        //console.log('LD Canvas ', isScriptReady);
        if (isScriptReady && myParent.current) initFabricFunc();
    },[isScriptReady, myParent.current]);

    const loadErr = (e) => {
        console.log("load img error", JSON.stringify(e));
    };
    // use img to control canvas size
    // img -> LDCanvasContainer -> LDCanvasBaseCont
    const exStyle = BGInfo.loaded ? (mode === 'ftw' ? { width: '100%' }
        : { width: BGInfo.width + 'px', height: BGInfo.height + 'px' })
        : {};
    return isScriptReady?<div id={'ctr' + myID} key={'ctr' + myID} className='LDCanvasContainer' style={mode === 'fth' ? exStyle : {}}>
        <img style={{ display: "none" }} src={BGImgSrc} onError={(e=>loadErr(e))} onLoad={(e) => handleARImgLoaded(e)} alt='LD bg image 1'/>
        <img className='LDARImg' src={BGImgSrc} style={mode === 'ftw' ? exStyle : {}} alt='LD bg image 2'/>
        {BGInfo.loaded ? <div id={containerID} ref={myParent} className='LDCanvasBaseCont'>
            <canvas id={myID}></canvas>
        </div>:"loading"}
    </div>:<></>;
};

export default CpLDCanvas;
/*
fabric.Image.fromURL('http://fabricjs.com/assets/honey_im_subtle.png', function(img, isError) {
   img.set({width: canvas.width, height: canvas.height, originX: 'left', originY: 'top'});
   canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
});
*/

export const delAnsBlock = (canvas, id) => {
    canvas.remove(selObj(canvas, 'AnsText', id), selObj(canvas, 'AnsCircle', id), selObj(canvas, 'AnsLine', id));
};

// select an object by oupansid
export const selObj = (canvas, type, id) => {
    const objs = canvas.getObjects(type);
    const obj = objs.find(oo => { return oo.oupansid === id });
    if (obj) canvas.setActiveObject(obj);
    return obj;
};

export const deleteObjects = (canvas) => {
    //const activeObject = canvas.getActiveObject(),
    const activeObjects = canvas.getActiveObjects();

    if (activeObjects) {
        activeObjects.forEach((object) => {
            canvas.remove(object);
            //setActiveObject(object);
            canvas.discardActiveObject();
        });
    };
};

export const zoom = (cav, delta) => {
    if (cav) {
        let zoom = cav.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        const center = cav.getCenter();
        //cav.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        cav.zoomToPoint({ x: center.top, y: center.left }, zoom);
        //cav.setZoom(zoom);            
    };
};

export const setBGImg = (url, cv) => {
    window.fabric.Image.fromURL(url, (img, isError) => {
        if (img) {
            const asp = img.height / img.width;
            const zoom = img.width / cv.width;
            const center = cv.getCenter();
            const wzoom = (cv.width / img.width);
            const hzoom = (cv.height / img.height);
            const finalZoom = Math.min(wzoom, hzoom);
            img.set({
                scaleX: finalZoom,
                scaleY: finalZoom,
                top: center.top,
                left: center.left,
                originX: 'center',
                originY: 'center'
            });
            //img.set({width: cv.width, height: cv.height, originX: 'left', originY: 'top'})
            //img.set({width: img.width, height: img.width * asp, originX: 'left', originY: 'top'})
            //img.set({width: img.width, height: img.height * asp, originX: 'left', originY: 'top'})
            cv.setBackgroundImage(img, cv.renderAll.bind(cv));
        };
    });
};


const updateLine = (line, obj1, obj2) => {
    if (!line || !obj1 || !obj2) return;
    const p1 = obj1.getCenterPoint();
    const p2 = obj2.getCenterPoint();

    line.set('x1', p1.x);
    line.set('y1', p1.y);
    line.set('x2', p2.x);
    line.set('y2', p2.y);
    line.setCoords();
};

const setAnsColor = (ar, isSel = false) => {

    if (ar && (ar.type === 'AnsText')) {
        ar.set('fill', isSel ? '#5d52ce' : 'white');
        ar.set('backgroundColor', isSel ? 'white' : '#5d52ce');
    };
};

const cavAnsClick = (e, cb = () => { }) => {

    //const activeObjects = canvas.getActiveObjects();
    //textboxBorderColor
    e.target.set('fill', '#5d52ce');
    e.target.set('backgroundColor', 'white');
    e.target.canvas.requestRenderAll();
    cb(e.target.oupansid);
    //borderColor: 'black',
};

//const attachEvent = (li, ar, ci, cb) => {
const ciR = 10;
const arT = 5;
const arL = 15;
const attachEvent = (cav, li, ar, ci, lock) => {
    if (lock) return;
    const width = cav.getWidth();
    const height = cav.getHeight();

    ar.on('moving', (e) => {
        if ((ar.top - 5) < 0) ar.top = 5;
        if ((ar.top + 24) > height) ar.top = height - 24;
        if ((ar.left + 15) > width) ar.left = width - 15;
        if ((ar.left - 5) < 0) ar.left = 5;
        updateLine(li, ar, ci);
    });
    ar.on('resizing', (e) => updateLine(li, ar, ci));
    //ar.on('mouseup', (e) => cb(ar.oupansid, {rect: {top:ar.top, left:ar.left}}));
    // check by canvas selection
    //ar.on('mousedown', (e) => cavAnsClick(e, cb));
    ci.on('moving', (e) => {
        if ((ci.top) < 0) ci.top = 0;
        if ((ci.top + 10) > height) ci.top = height - 10;
        if ((ci.left + 10) > width) ci.left = width - 10;
        if ((ci.left) < 0) ci.left = 0;
        updateLine(li, ar, ci)
    });
    //ci.on('mouseup', (e) => cb(ci.oupansid, {cir: {top:ci.top, left:ci.left}}));
};

const setControls = (li, ar, ci, lock) => {
    ar.set('hasControls', false);
    ar.set('lockRotation', true);
    ar.set('lockScalingY', true);
    ar.set('lockMovementX', lock);
    ar.set('lockMovementY', lock);

    ci.set('hasControls', false);
    ci.set('lockRotation', true);
    ci.set('lockMovementX', lock);
    ci.set('lockMovementY', lock);

    li.set('lockMovementX', true);
    li.set('lockMovementY', true);
    li.set('hasControls', false);
};
// id start from 1
//export const addAnswerBlock = (cav, id, ans = {}, cb=()=>{}, _top = 10, _left = 10) => {
//addAnswer(cav, aa.oupansid, aa, ''+(ii+1))
const _top = 0.1;
const _left = 0.1;
export const addAnswerBlock = (cav, id, ans = {}, _txt = '', lock = false) => {
    if (!cav || !ans) return;
    let myKey = id;
    let txt = (_txt !== '') ? _txt : ('' + id);
    //if (myKey === -1) {myKey = getNewKey(); txt = ''+myKey};
    /*
    const ar = new window.fabric.AnsRect({
        width: 100, height: 30, left: 50, top: 0,
        fill: 'white', stroke: 'black',
        label: 'answer',
        oupansid: newKey,
        //hasControls: enabled?true:false,
        //lockRotation: true,
    })
    */

    const _rleft = ans.rect ? ans.rect.left : _left;
    const _rtop = ans.rect ? ans.rect.top : _top;
    const ar = new window.fabric.AnsText(txt, {
        top: _rtop * cav.getHeight(), left: _rleft * cav.getWidth(),
        fill: 'white', fontSize: 16,
        backgroundColor: '#5d52ce', borderColor: 'black',
        fontFamily: 'arial', textAlign: 'left',
        oupansid: myKey,
        showTextBoxBorder: true,
        textboxBorderColor: '#5d52ce',
        editable: false,
        hoverCursor: lock ? "pointer" : "move",
        //hasControls: enabled?true:false,
        //lockRotation: true,
    })

    const _cleft = ans.cir ? ans.cir.left : _left;
    const _ctop = ans.cir ? ans.cir.top : (_top + 0.04);
    const ci = new window.fabric.AnsCircle({
        radius: 5,
        fill: lock ? 'transparent' : 'lightgreen',
        //stroke: 'lightgreen', fill:'rgba(0,0,0,0)',
        //scaleY: 0.5,
        top: _ctop * cav.getHeight(), left: _cleft * cav.getWidth(),
        //hasControls: enabled?true:false,
        //lockRotation: true,            
        oupansid: myKey,
        hoverCursor: lock ? "default" : "move",
    });

    const li = new window.fabric.AnsLine(
        [0, 0, 0, 0],
        { stroke: 'black', oupansid: myKey, selectable: false, hoverCursor: "default" },
    );
    updateLine(li, ar, ci);

    setControls(li, ar, ci, lock);
    //attachEvent(li, ar, ci, cb);
    attachEvent(cav, li, ar, ci, lock);
    cav.add(li, ar, ci);
};


/*
const newCanvas = (myID, width, height) => {
    const canvas = new window.fabric.Canvas(myID, {
        backgroundColor: 'rgb(100,100,200, 0.1)',
        selectionColor: 'blue',
        width: width,
        height: height,
    });

    if (canvas) {
        
        canvas.on('mouse:down', function (opt) {
            var evt = opt.e;
            if (evt.altKey) {
                this.isDragging = true;
                this.selection = false;
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
            };
        });
        canvas.on('mouse:move', function (opt) {
            if (this.isDragging) {
                var e = opt.e;
                var vpt = this.viewportTransform;
                vpt[4] += e.clientX - this.lastPosX;
                vpt[5] += e.clientY - this.lastPosY;
                this.requestRenderAll();
                this.lastPosX = e.clientX;
                this.lastPosY = e.clientY;
            };
        });
        canvas.on('mouse:up', function (opt) {
            // on mouse up we want to recalculate new interaction
            // for all objects, so we call setViewportTransform
            this.setViewportTransform(this.viewportTransform);
            this.isDragging = false;
            this.selection = true;
        });
        canvas.on('selection:cleared', function (opt) {
            console.error('selection:cleared:', opt);
        });
        canvas.on('selection:created', function (opt) {
            console.error('selection:created:', opt);
        });
    };

    return canvas;
};
*/