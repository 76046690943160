import React, {useState} from "react";
import { ReduxBind } from "../../saga/ReduxState";
import { stopEvent } from "../../libs/libUI";
import { Button, CloseButton } from "react-bootstrap";
import CpIco from "../_components/CpIco";
import CpActionButton from "../_components/CpActionButton";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { lang2t, langIsEn, useUILang } from "../../AppExPf/utils/useUILang";
import CpSharedModal from "../_components/CpSharedModal";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { toObj, toStr } from "../../libs/libType";
import { toIdStr } from "../../consts/ATValidate";
import { actBtnProps } from "./LayerAssignmentEnd";

import { _pathAssign } from "../../consts/ATConstReact";

export const useAssignmentDel = () => {
    const [delAsm, setDelAsm] = useState();
    return {delAsm, setDelAsm};
};

export const CpDeleteCTA = ReduxBind(props => { //base on DeleteCTA
    const {dispatch, asm, onRmAsm, useAsmDel, ...btnProps} = props;
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const { setDelAsm} = useAsmDel; 
    
    const clickAsmDel = e => { stopEvent(e); setDelAsm({asm, onRmAsm}); };
    return <CpActionButton title={t("assignment-cta-delete")} iconPath={IconList.assignment.delete}
        className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={clickAsmDel} {...actBtnProps(btnProps)} />;
});

const LayerAssignmentDelete = ReduxBind(props => { //base on DeleteCTA
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {dispatch, useAsmDel} = props; 
    const {delAsm:_delAsm, setDelAsm} = toObj(useAsmDel); 
    const {asm:delAsm, onRmAsm} = toObj(_delAsm); 
    
    const assignId = toIdStr(delAsm?.assignId);
    const ex = toObj(delAsm?.Exer);
    const AName =  lang2t(delAsm?.ALang, ex.ENameEn, ex.ENameCt);
    
    const clickDelete = async e => { stopEvent(e); 
        
        const [res, err] = await asyncApiCallLoad_(dispatch, '/deleteAssignment', {assignId});
        onRmAsm(); //onDelAsm(delAsm);
        setDelAsm(0);
    };
    const clickClose = async e => { stopEvent(e); setDelAsm(0); };

    return <>
        <CpSharedModal show={delAsm} scrollable={true} footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
                <Button variant="gray-body-color w-100 border" onClick={clickClose}>{t("cancel")}</Button>
                <Button variant="danger btn-block w-100" onClick={clickDelete}>{t("delete")}</Button>
            </div>}>
            <div className={"px-3 pt-4 bg-dim-100"}>
                <div className={"d-flex flex-column gap-4"}>
                    <div className={"d-flex flex-column gap-3 w-100"}>
                        <div className={"d-flex justify-content-between"}>
                            <span className={"d-flex justify-content-end opacity-0 pe-none"}><CloseButton onClick={clickClose}/></span>
                            <span className={"d-flex justify-content-center text-exercise-third-btn fs-1"}>
                                <CpIco src={IconList.assignment.delete}/></span>
                            <span className={"d-flex justify-content-end"}><CloseButton onClick={clickClose}/></span>
                        </div>
                        <div className={"d-flex w-100 fs-4 semi-bold justify-content-center user-select-none  px-3 text-center"}>{AName}</div>
                    </div>
                    <div className={"mb-3 px-3"}>{t("assignment-delete-message")}</div>
                </div>
            </div>
        </CpSharedModal>
    </>
});
export default LayerAssignmentDelete;

