// base on poc Prefix.tsx -> _prefix.module.scss -> poc/static/scss/custom-variable/_prefix.scss
// e.g. poc: Prefix.BTN -> btnPrefix -> btn-prefix -> 'cus-btn-'
export const PREFIX = {
    BTN:'cus-btn-',
    BTN_BREAKPOINT: 'cus-btn-breakpoint-',
    DROPDOWN: 'cus-dropdown-',
    DROPDOWN_BREAKPOINT: 'cus-dropdown-breakpoint-',
    SWITCH: 'cus-switch-',
    SWITCH_BREAKPOINT: 'cus-switch-breakpoint-',
    CHECKBOX: 'cus-checkbox-',
    CHECKBOX_BREAKPOINT: 'cus-checkbox-breakpoint-',
    RADIO: 'cus-radio-',
    RADIO_BREAKPOINT: 'cus-radio-breakpoint-',
    INPUT: 'cus-input-',
    INPUT_BREAKPOINT: 'cus-input-breakpoint-',
};
