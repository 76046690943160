import React from 'react';
import { _ATRoot } from '../../consts/ATConstReact';
import * as UI from "../../libs/libUI";

import { ReduxBind } from '../../saga/ReduxState';
import { clickUrl } from '../AppUtil';

const menuIcon = UI.imgSrc('svg/menu-2.svg');
const alertMsgIcon = UI.imgSrc('svg/alertMsg.svg');

const toDo = () => { alert("to Do") };

const CpTopBar = ReduxBind(props => {
    const {setVisLB, dispatch} = props;   
    const clickATHome = clickUrl(dispatch, _ATRoot);

    //<img src={UI.imgSrc('svg/expand_down.svg')} className="topBarIcon2" alt="" onClick={toDo}/>
    return (<div className="ATTopBar">
        <div className="ATTopBar1">
            <img src={menuIcon} className="topBarIcon" alt="" onClick={()=>setVisLB()} />
            <div className="f20"><span onClick={clickATHome}><b>Authoring Tools</b></span></div>
        </div>
        <div className="ATTopBar2">
            <img src={alertMsgIcon} className="topBarIcon" alt="" onClick={toDo}/>
            <div className="f16">User: <b> {(props?._saga?.auth?.ATUser?.ATLogin) || '(guest)'} </b> </div>
        </div>
    </div>);
});

export default CpTopBar;

/*
        {UI.Button0("TBBtn1", e=>{}, "TBBtn1", "TopBarBtn", {})}
        {UI.Button0("TBBtn2", e=>{}, "TBBtn2", "TopBarBtn", {})}
*/
