import React, {useState} from 'react';

import {ReduxBind} from '../saga/ReduxState';

import * as UI from '../libs/libUI';

import { _WebRoot } from '../consts/ATConstReact';
import PageEXPFLayout, { useLayout } from './PageEXPFLayout';
import { useStateGST } from '../saga/globalState.saga';
import { preJS2, autoId } from '../AppExPf/AppUtil';
import { nestSolid, toAry, toInt, toObj } from '../libs/libType';
import { asyncApiCallLoad_ } from '../libs/awsFuncs';
import { useTickAll } from '../AppExPf/ATQtnEdit/PageATQtns';
import { PreJSX } from '../AppExPf/AppUtil';
import { useMediaCache } from '../AppExPf/utils/useMediaCache';
import { toUniIdAry } from '../consts/ATValidate';
import { HKDayDiff, formatTimeStamp, formatTimeStampHK, timeStampGMTToHk, timeStampNowGMT, timestampGMTfromHK } from '../libs/libTime';
import CpDatePicker from './_components/CpDatePicker';
import { IconList } from '../consts/ATIconListToUsePoc';
import CpExpireTag from './_components/CpExpireTag';

const PageHome = ReduxBind( props => {
    const { dispatch } = props;

    const [opens, setOpen, setTicks, tickAll, setTickAll, clearTicks] = useTickAll([]);
    const clickOpen = id => e => {UI.stopEvent(e); setOpen(id)(!opens.includes(id)); };

    const [ mediaDLs, getMediaDLs, localDLs, addLocalMedias] = useMediaCache(props, dispatch, 0);
    const [onAddMedia, setOnAddMedia] = useState();
    const [newMedia, setNewMedia] = useState('');
    const clickAddMedia = e => { UI.stopEvent(e);
        setOnAddMedia({onAdd:(medias => {
            /*
            const ms = toAry(medias);
            if (ms.length > 0) {
                const src = toStr(ms[0].dl);
                const mediaId = toStr(ms[0].mediaId);
                setData(mediaId, src);
            };
            */
            setOnAddMedia(0); //stop receiveMedia
            window.alert(JSON.stringify(medias));
            setNewMedia(medias);
        }),maxMedia:1, mimes:['video']});
    };


    const [visLB, setVisLB, clickLB] = useLayout(props);
    const [loginDev, setLoginDev] = useStateGST(props, 'loginDev', {});
    const [devML, setdevML] = useStateGST(props, 'devML', {});
    
    const reload = async () => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getEntitlement', {});
        const { t, auth, errs, ...mlres } = toObj(res); 
        setdevML(mlres);
    };
    
    const preJS = (o, indent, key) => <PreJSX key={key||autoId()} o={o} indent={indent} />;

    const loadMLAPI = () => { if(!nestSolid(devML)) reload(); };
    //useEffect(() => { loadMLAPI(); }, []);

    const click = async e => { UI.stopEvent(e); reload(); };

    const expDiv = (id, body) => <><div className='p4' >
        {UI.Button0(id, clickOpen(id), 'b'+id)}
        <div style={{display :opens.includes(id)? 'block': 'none'}} >{body} </div>
    </div></>;

    const [debug, setDebug] = useState();
    const [pickTime, setPickTime] = useState();
    const clickLoad = async () =>  {
        const [res, err] = [];
        const localGMT = timeStampNowGMT();
        const dbg = toObj(res?.debug);
        setPickTime(toInt(dbg.svrTime*1000));

        const client_HktStr = formatTimeStampHK(localGMT, 'YYYY/MM/DD HH:mm:ss');

        setDebug({
            ...dbg,
            client_Now: toInt(Date.now()/1000), 
            client_GMT: toInt(localGMT/1000),
            client_GMTGMTStr: formatTimeStamp(localGMT, 'YY MM DD - HH mm ss'), 
            client_GMTLOCStr: formatTimeStamp(localGMT, 'YY MM DD - HH mm ss', 1), 
            client_HKTGMTStr: formatTimeStampHK(localGMT, 'YY MM DD - HH mm ss'),
            client_Offset: (new Date().getTimezoneOffset()),
            client_HktStr: client_HktStr,
            client_HktStr_nozone__GMT: toInt(new Date(client_HktStr).getTime() / 1000),
            client_HktStr_addzone_GMT: toInt(timestampGMTfromHK(new Date(client_HktStr + ' UTC').getTime())/1000),
            });
    };
    const pickCmp = hr => {
        const ts = (pickTime + (hr*60*60*1000));
        return {
            diff_Hr: hr + ' hour later',
            HKT_str: formatTimeStampHK(ts, 'YY MM DD - HH mm ss'),
            diffDay: HKDayDiff(pickTime, ts),
        };
    };
    const DiffTag = hr => {
        const p = pickCmp(hr)
        return <div className="box1 p4">
            {preJS(p, 3)}
            <CpExpireTag days={p.diffDay}/>
        </div>;
    };

    const debugTime = () => { return <div className="box1 p4 m4">
        <h4>Debug Time</h4>
        {UI.Button0('reload debug', clickLoad, 'bld', '')}
        {preJS(debug, 3)}
        {preJS({pickTime: toInt(pickTime/1000), outFMTHK: formatTimeStampHK(pickTime), outHKFMT: formatTimeStamp(timeStampGMTToHk(pickTime))}, 3)}
        <CpDatePicker {...{dateTime:pickTime, cb:setPickTime, 
            title:'Debug PickTime', iconPath:IconList.general.calendar, disable:0, showEmpty:0, behind2min:1 }} />
        {DiffTag(26)}
        {DiffTag(14)}
        {DiffTag(0)}
        {DiffTag(-14)}
        {DiffTag(-26)}
    </div>};

    const DevML = () => {
        const { auth, oupCfg, access_token, fetchUserInfo, fetchTeachersBySchoolID, 
            fetchEntitlementsBySchoolID,
            fetchEntitlementsByUserID, fetchEntitlementsByUserID_me,
            fetchHistoricalEntitlementsBySchoolID,
            fetchSchoolEntitlements,
            fetchStudentsByClassID,
            fetchHistoricalClassesByStudentID, fetchHistoricalClassesByStudentIDTrim,
            fetchHistoricalTeacherClasses, fetchHistoricalTeacherClassesTrim,
            session, ..._devML } = toObj(devML);
        const {user, teachClasss, ..._session} = toObj(session);
        //{UI.Button0('Add Local Media', clickAddMedia, 'balm')}

        return <>
            {UI.Button0('Call Middle Layer APIs', loadMLAPI, 'bld', '')}
            {expDiv('oupCfg', preJS2(oupCfg, 3, 1))}
            {expDiv('access_token', preJS2(access_token, 3, 1))}
            {expDiv('fetchUserInfo', preJS2(fetchUserInfo, 3, 1))}

            {expDiv('fetchEntitlementsBySchoolID'+lenTxt(fetchEntitlementsBySchoolID), preJS2(fetchEntitlementsBySchoolID, 3, 1))}
            {expDiv('fetchEntitlementsBySchoolID trimEntitle', preJS2(trimEntitle(fetchEntitlementsBySchoolID), 3, 1))}
            {expDiv('fetchHistoricalEntitlementsBySchoolID'+ lenTxt(fetchHistoricalEntitlementsBySchoolID), preJS2(fetchHistoricalEntitlementsBySchoolID, 3, 1))}
            {expDiv('fetchHistoricalEntitlementsBySchoolID trimEntitle', preJS2(trimEntitle(fetchHistoricalEntitlementsBySchoolID), 3, 1))}
            {expDiv('fetchEntitlementsByUserID', preJS2(fetchEntitlementsByUserID, 3, 1))}
            {expDiv('fetchEntitlementsByUserID_me', preJS2(fetchEntitlementsByUserID_me, 3, 1))}

<h3>Student</h3>
            {expDiv('fetchHistoricalClassesByStudentID', preJS2(fetchHistoricalClassesByStudentID, 3, 1))}
            {'' && expDiv('fetchHistoricalClassesByStudentIDTrim', preJS2(fetchHistoricalClassesByStudentIDTrim, 3, 1))}
<h3>Teacher</h3>
            {expDiv('fetchHistoricalTeacherClasses', preJS2(fetchHistoricalTeacherClasses, 3, 1))}
            {'' && expDiv('fetchHistoricalTeacherClassesTrim', preJS2(fetchHistoricalTeacherClassesTrim, 3, 1))}

<h3>Others</h3>
            {expDiv('fetchTeachersBySchoolID', preJS2(fetchTeachersBySchoolID, 3, 1))}
            {'' && expDiv('session.teachClasss', preJS2(teachClasss, 3, 1))}

            {expDiv('fetchHistoricalTeacherClasses', preJS2(fetchHistoricalTeacherClasses, 3, 1))}

            {expDiv('fetchStudentsByClassID todo', preJS2(fetchStudentsByClassID, 3, 1))}

            {expDiv('_session', preJS2(_session, 3, 1))}
            {expDiv('user', preJS2(user,3, 1))}
            {expDiv('devML', preJS2(_devML, 3, 1))}
            {expDiv('auth', preJS2(auth, 3, 1))}
        </>;
    }

    const LoginDev = () => {
        const {res, err} = toObj(loginDev); 
        const {t, errs, auth, user, userInfo, xUser, xapiUser, 
            teachClasss, ClassHistory, 
            TSchoolEntitle, entitle, settings, gsts, session,
            ...dev} = loginDev;
        return expDiv('loginDev', <>
            {preJS2(dev, 3)}
            {preJS2(session, 3)}
            {preJS2({err})}
            {preJS2({errs})}
            {preJS2({userInfo}, 3)}
            {preJS2({user}, 3)}
            {preJS2({xapiUser}, 3)}
            {preJS2({xUser}, 3)}
        </>); 
    };
    const {auth, errs, mlrss} = toObj(devML);

    return <PageEXPFLayout sideIdx='home'
            menuTitle={<></>}
            menuActionBar={<></>} 
            header={<></>}
            subBody={<></>}
            footer={<></>}
        >
        <div style={{overflow:'auto'}} >
            <div>PageHome</div>
            <div className="p8">{UI.Button0('Reload', click, 'b1')}</div>
{/*
            <ul>{[
'Teach School Year History all',
'Teach School Year Current',
'Study School Yea History allr',
'Study School Year Current',
            ].map((s,i) => (<li key={i}>{s}</li>))}
            </ul>
*/}
            {devML? DevML(): ''}
            {loginDev? LoginDev(): ''}
        </div>
    </PageEXPFLayout>;
});
export default PageHome;

const DummyText = props => {
    return <div className="m8 p8 br8 box1">
        DummyText, DummyText, DummyText, DummyText, DummyText, DummyText, DummyText, <br/>
        DummyText, DummyText, DummyText, DummyText, DummyText, DummyText, DummyText, <br/>
        DummyText, DummyText, DummyText, DummyText, DummyText, DummyText, DummyText, <br/>
        DummyText, DummyText, DummyText, DummyText, DummyText, DummyText, DummyText, <br/>
    </div>;
};

const lenTxt = a => ' ('+toAry(a).length+')';

const trimEntitle = _ent => {
    const ent = toAry(_ent);
    return {
        schoolIds: toUniIdAry(ent.map(e => e.schoolID)).sort(),
        classIds: toUniIdAry(ent.map(e => e.classID)).sort(),
        code:  toUniIdAry(ent.map(e => e.code)).sort(),
    };
};