import React, { useEffect, useState, useMemo} from 'react';

import { ReduxBind } from '../saga/ReduxState';

import * as UI from '../libs/libUI';

import { _ATRoot } from '../consts/ATConstReact';
import { _ATMetaTypeByCode } from '../consts/ATMetaTypes';
import { ATDo_Meta, _ATCanDo } from '../consts/ATRoleRights';
import { isObj, toAry, toObj, toStr } from '../libs/libType';
import CpATIcoBtn from './components/CpATIcoBtn';
import { preJS, aTErrTxt1, aTUIMust, clickUrl } from './AppUtil';
import { subState } from './utils/useChain';
import { apiCallLoad, apiCallLoad_ } from '../libs/awsFuncs';
import { urlPush_Replace } from '../saga/urlPush.saga';
import { normalizedEditSubjSetting, validEditSubjSetting } from '../consts/ATValidateSubjSetting';
import { hasErr, normalizeEdit } from './utils/useEditDraftPub';
import { walkTreeFunc } from './utils/utilWalkTree';

const backPath = _ATRoot + 'subjectfollowups'; 

const PageATSubRemeEdit = ReduxBind((props) => { 
	const { dispatch } = props;
	const role = toStr(props._saga?.auth?.ATUser?.ATRole);
	const canMeta = _ATCanDo(role, ATDo_Meta); 

	const SMId = toStr(props.match?.params?.SMId);
    const isNew = !SMId;//: 1; // new create, or exist edit

    const [fields, setFields] = useState({SMId, isNew});
    const [fieldErrs, setFieldErrs] = useState({});
	const [opts, setOpts] = useState({});

    const setField = key => val => setFields(subState(key, val));

    const {subjMsets} = toObj(opts);
    const metaSetId = fields?.metaSetId; // input MSetId
    const metaId = toStr(fields?.metaId);
    
    const [mset, validMids] = useMemo(() => {
        const mjs = toAry(subjMsets);
        const mset = mjs.find(m => (m.metaSetId === metaSetId)) || mjs[0]; 
//console.log('ms memo', {mjs, metaSetId, mset});
 
        const validMids = [];
        const metasToRows = (result, branch, id, lv, pId) => {result.push(id); return branch;};
        if(mset) walkTreeFunc(validMids, mset.tree, '', 0, metasToRows);

        const ret = [mset, validMids];
        return ret;
    }, [metaSetId, subjMsets]);
    useEffect(() => { if((!metaSetId) && mset) setFields(subState('metaSetId', mset.metaSetId)); }, [metaSetId, mset]);

    const setMetaId = metaId => setFields(fs => ({...fs, metaId}));

	const onLoadSSet = (res, err) => {
		if(!isObj(res)) return;
        const {SSet, fieldErrs, auth, errs, ..._opts} = toObj(res);
        setOpts(os => ({...os, ..._opts}));
        setFields(fs => ({...toObj(fs), ...toObj(SSet)}));
        setFieldErrs(fes => ({...toObj(fes), ...toObj(fieldErrs)}) );
	};
	useEffect(() => { canMeta? apiCallLoad_(dispatch, '/getSubjSetting', onLoadSSet, {SMId}): dispatch(urlPush_Replace(_ATRoot)); }, [])

    const onSave = (res, err) => {
        const {SSet, fieldErrs, state} = toObj(res);
        if(fieldErrs) setFieldErrs(fieldErrs);
        if(SSet) setFields(SSet);
        if(state !== 'retry')
            dispatch(urlPush_Replace(backPath));
    };

    const normalize = () => normalizeEdit(fields, opts, [], normalizedEditSubjSetting, validEditSubjSetting, setFields, setFieldErrs);
	const clickSaveReme = canMeta && (e => { UI.stopEvent(e);
        const [SSet, fieldErrs] = normalize();
//console.log(SSet, fieldErrs);
        if(hasErr(fieldErrs)) return;
		apiCallLoad_(dispatch, '/putSubjSetting', onSave, {SSet});
	});

    const clickCancel = clickUrl(dispatch, backPath);

	return <div className="PageATSubRemeEdit adminSubjectTop">
        <div className="f16"> Subject / Follow Up Exercise/ {isNew? 'New': 'Edit'}</div>
        <div className="adminEditToolsContainer"><div style={{display:"flex"}}><div className="adminTools1">
            <CpATIcoBtn onClick={clickSaveReme} src="general/save" txt='Save' />
            <CpATIcoBtn onClick={clickCancel} src="general/cancel" txt='Cancel' />                    
        </div></div></div>      
        <div className="inputInfoContainer"><table className="inputInfoTable f14"><tbody><tr>
            <td className="subjSettingInputLabel">Subject metadata Set ID{aTUIMust}</td>
            <td>{metaSetId}</td>
        </tr><tr>
            <td className="subjSettingInputLabel">Subject metadata ID{aTUIMust}</td>
            <td>{isNew?UI.EpInputTxt0(metaId, setMetaId, 'txtSubJd', '', {width:"200px"}, 'Please enter Subject metadata ID here'):metaId}</td>
        </tr><tr>
            <td></td>
            <td>{aTErrTxt1(fieldErrs?.metaId)}</td>
        </tr><tr>
            <td className="subjSettingInputLabel">Number of questions in follow-up exercise{aTUIMust}</td>
            <td>{UI.EpInputTxt0(fields.numQ, setField('numQ'), 'txtSubName', '', {width:"250px"}, 'Please enter Number of questions in follow-up exercise here')}</td>
        </tr><tr>
            <td></td>
            <td>{aTErrTxt1(fieldErrs?.numQ)}</td>
        </tr></tbody></table></div>
        {preJS({fields}, 3)}
        {preJS({validMids})}
        {preJS({opts}, 3)}
    </div>;
});
export default PageATSubRemeEdit;


