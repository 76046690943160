import { useState } from "react";
import * as UI from '../../libs/libUI';

const CpFIBManualMark = (props) => { // base on poc FIBManualMark.tsx
    const {correct=undefined, correctClick} = props;
    const [isCorrect, setIsCorrect] = useState(correct);
    const onClick = (e, cor) => {
        UI.stopEvent(e);
        setIsCorrect(cor);
        correctClick && correctClick(cor); // 1 for correct, 0 for incorrect
    };

    return <div className="fib-manual-mark-group" data-for="${id}" 
        style={{display:'flex', flexDirection:'row'}}>
    <span role="button" className={"fib-manual-mark correct" + 
        (isCorrect===undefined?'':(isCorrect?' checked':''))}
        onClick={(e)=>onClick(e,1)} data-status="correct"></span>
    <span role="button" className={"fib-manual-mark incorrect" + 
        (isCorrect===undefined?'':(isCorrect?'':' checked'))}
        onClick={(e)=>onClick(e,0)} data-status="incorrect"></span>
    </div>
};

export default CpFIBManualMark;


