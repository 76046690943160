import React, { useEffect, useState } from 'react';
import {Accordion} from "react-bootstrap";

import { arrayAddOrDelete } from '../../libs/libArray';
import * as UI from '../../libs/libUI';
import { ReduxBind } from '../../saga/ReduxState';


const VMenu = ReduxBind( props => {
    const { arr, width, height, expands, exStyle={}, exClass } = props;
    const [expanded, setExpanded] = useState(expands||[]);
    const [sel, setSel] = useState(undefined);

    useEffect(()=>{
      /*
      if (expanded.length>0) {
        
        expanded.forEach((ex)=>{
          
          doExpand(ex);
        });
      };
      */
    },[]);

    useEffect(()=>{
      
      //doExpand(sel);
    },[sel]);

    const doExpand = (ii) => {
      if (ii>=0) {
        const growDiv = document.getElementById('VMContent'+ii);
        if (growDiv) {
          if (growDiv.clientHeight) { 
            growDiv.style.height = 0;
          } else {
            growDiv.style.height = growDiv.scrollHeight+'px';
          };
        } else console.error("div not found ", ii);;
      };
      setSel(undefined);
    };

    /*
    const transitionEnd = (e,ii) => {
      UI.stopEvent(e);
      if (expanded.includes(ii)) {
        const growDiv = document.getElementById('VMContent'+ii);
        if (growDiv) growDiv.style.height = "auto";
      };
    };
    const expandClick = (id, on) => e => {
      UI.stopEvent(e); setExpanded(es => arrayAddOrDelete(es, id, on));
      //setSel(id);
    };
    */

    const css = {width}; //{width:'100%'}: {width:'350px'};
    return (
      <div key={'vmenu'} className={`scroll-container bg-white at-setting at-setting-mode-static px-0 pb-4 h-100 overflow-auto`} style={css} >
    <Accordion defaultActiveKey={[0,1,2,3,4]} alwaysOpen>
      {arr && arr.map((tab, ii) => {
        //const open = expanded.includes(ii); 
        
        return <Accordion.Item key={ii} eventKey={ii}>
          <Accordion.Header className={"border-bottom border-at-secondary-dim semi-bold"}>{tab.name}</Accordion.Header>
          <Accordion.Body> {tab.jsx}</Accordion.Body>
        </Accordion.Item>
      })}
    </Accordion>
      </div>
    ); 
/*
    return <div className={'VMenuContainer ' + (exClass?exClass:'f14')} 
      style={{width:width?width:"100%", height:height?height:"100%", ...exStyle}}>
      {arr && arr.map((tab, ii) => {
        const open = expanded.includes(ii); 
        
        return <div key={'vh' + ii}>
          <div className={'VMenuHead' + (open? "expanded": "")} onClick={expandClick(ii, !open)}>
            <div>{tab.name}</div>
            <div>{open? "-": "+"}</div>
          </div>
          {open && <div id={'VMContent'+ii} className='VMenuContent'>{tab.jsx}</div>}
        </div>
      })}
    </div>;
  */
  });

  export default VMenu;
  // {open && <div id={'VMContent'+ii} className='VMenuContent' onTransitionEnd={(e)=>transitionEnd(e,ii)}>{tab.jsx}</div>}