import SVG from 'react-inlinesvg';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { toObj } from '../../libs/libType';

const header = "/static/icon/"
export const icoSrc = ico => `${header}${ico}`;

const CpIco = props => { //base on <IconComponent />
    const {src = "", path = "", width = null, height = null, title = null, } = props
    const style = toObj(props.style);
    const config = Object.fromEntries(Object.entries({width, height, title}).filter(([k, v]) => v));
    const isrc = `${path || header}${src}`;
    //const isrc =  'static/icon/general/previous.svg';
    //const isrc =  'static/icon/menu/library.svg';
    
    return <div className="svg-icon" ><SVG {...{src: isrc, ...config, className:"d-flex", style, onError: e => {}}}/></div>
}
export default CpIco;

export const CpLangIco = (isEn) => <CpIco src={IconList.language[(isEn?'en': 'chi')]}/>;
//console.error('CpIco SVG:',`${path || header}${src}`,error.message)