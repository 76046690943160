import React, { useEffect, useState, } from 'react';

import { apiCallLoad_ } from '../../libs/awsFuncs';
import { errMsg } from '../../libs/libFormat';
import { isObj, toAry, toObj, toStr, } from '../../libs/libType';
import * as UI from '../../libs/libUI';

import { ReduxBind } from '../../saga/ReduxState';
import { aTErrDiv1, ATErrsDiv, aTErrTxt1, aTUIMust, BtnPopDev, clickConfirm, clickUrl, preJS } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';
import { QP_P, QP_D, } from '../../consts/ATConsts'
import { urlPush_Replace } from '../../saga/urlPush.saga';

//import TabQMC from './TabQMC';
import { cpATIcoBtn_ } from '../components/CpATIcoBtn';
import CpTabHead from '../components/CpTabHead';
import { normalizedEditATQSet, QSetPubErrs, validEditATQSet } from '../../consts/ATValidateQSet';
import CpDraftPubBtns, { PubDraftStates } from '../components/CpDraftPubBtns';
import { detectIsNew, normalizeEdit, useEditDraftPub } from '../utils/useEditDraftPub';
import TabQSetGeneral from './TabQSetGeneral';
import TabQSetQId from './TabQSetQIds';
import TabQSetMeta from './TabQSetMeta';
import { ATDo_QSet, _ATCanDo } from '../../consts/ATRoleRights';
import { makeClickSavePub } from '../ATQtnEdit/PageATQtnEdit';
import { useTagMSetCache } from '../utils/useTagMSetCache';
import { unpackQtn } from '../../consts/wirisConfig';

const backPath = _ATRoot + 'questionsets';

const PageATQSetEdit = ReduxBind((props) => {
  const { dispatch } = props;
  const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 

  const role = toStr(props?._saga?.auth?.ATUser?.ATRole);

  const [fields, setFields, setField, setTick, setTickAry, draftErrs, setFieldErrs, opts, setOpts, draft, setDraft, pub, setPub, pubErrs, setPubErrs] = useEditDraftPub();
  const [isNew, QSetId] = detectIsNew(props.match?.params, pub, draft, 'QSetId');

  useEffect(() => { QSetId && dispatch(urlPush_Replace( _ATRoot + 'questionset/edit/'+QSetId)) }, [QSetId]);

  const [tab, setTab] = useState(0);

  // Devined States ====
  const [view, setView] = useState(QP_P);
  const {hasDraft, saved, r2p, pubed, unpubed, showPub, lock } = PubDraftStates(view, draft, pub, 'QSVer'); 
  const qset = showPub? pub : fields;
  const fieldErrs = draftErrs;

  // Editing Setter
  const setTickSj = setTickAry('QSSubjIds');
  const setFilter = setTickAry('QSMetaFilters');


  const onLoadQSet = (res, err) => {
    const { fieldErrs, ATSubjects, mySjIds, QSQs, QSMSs } = toObj(res);
    const [draft, pub] = [unpackQtn(res?.draft), unpackQtn(res?.pub) ]; 
    setPub(pub);
    setDraft(draft);
    setFields(toObj(draft));
    setFieldErrs(toObj(fieldErrs));
    const opts = QSLoadOpts(ATSubjects, mySjIds, QSQs, QSMSs); 
    setOpts(opts);
    //QSetPubErrs(pub, )
    //setPubErrs({todo:'QSetPubErrs'});
    //normalizeEdit(pub, opts, [], normalizedEditATQSet, validEditATQSet, 0, setPubErrs);
    setView(v => ((!pub)? QP_D: (!draft)? QP_P: v));
  };
  const onLoadQSetDraft = (res, err) => {
    if (res) {
      const [d] = [unpackQtn(res.draft)];
      d.QSQIds = toAry(pub?.QSQIds);
      d.QSMSIds = toAry(pub?.QSMSIds);
      setDraft(d);
      setFields(toObj(d));
      setFieldErrs({});
      setView(d? QP_D: QP_P);
      //setMetaList(res.metaList); DummyMetas);
    }
  };
  const back = () => dispatch(urlPush_Replace(backPath));
  const onDelete = (res, err) => { back(); } 
  const onPutQSetDraft = (res, err) => {
    if ((res?.state) === 'ok') {
      onLoadQSet(res, err);
    } if ((res?.state) === 'retry') {
      const fes = res?.fieldErrs;
      if (fes) setFieldErrs(fes);
    } else {
      back();
    }
  };
  
  useEffect(() => { apiCallLoad_(dispatch, '/getATQSet', onLoadQSet, { QSetId, isNew }); }, []);
 
  //UI Actions
  const safeSetView = v => e => { UI.stopEvent(e); setView((!pub) ? QP_D : (!hasDraft)? QP_P: v) };

  const normalize = () => normalizeEdit(fields, opts, [], normalizedEditATQSet, validEditATQSet, setFields, setFieldErrs);
  const saveQSetDraft = (doPub, doR2p, fields) => apiCallLoad_(dispatch, '/putATQSetDraft', onPutQSetDraft, { fields, doPub, doR2p });
  const clickSaveATQSet = makeClickSavePub(dispatch, normalize, saveQSetDraft);

  const canQSet = _ATCanDo(role, ATDo_QSet);
  const letClone = canQSet;
  const letPub = canQSet;
  const letUnpub = canQSet;
  const letDelete = canQSet;

  const clickClone = letClone && pub && UI.stopEventThen(e => apiCallLoad_(dispatch, '/getATQSetDraft', onLoadQSetDraft, { QSetId }));
  const clickPublish = letPub && (unpubed) && clickConfirm(dispatch, 'Please confirm publish', () => apiCallLoad_(dispatch, '/putATQSetsPublish', onLoadQSet, { QSetId }));
  const clickSavePublish = letPub && (hasDraft) && clickSaveATQSet(1, 0); 
  const clickUnpublish = letUnpub && pubed && clickConfirm(dispatch, 'Please confirm unpublish', () => apiCallLoad_(dispatch, '/putATQSetsUnpublish', onLoadQSet, { QSetId }));
  const clickDelete = letDelete && hasDraft && clickConfirm(dispatch, 'Please confirm delete', () => isNew? back(): apiCallLoad_(dispatch, '/deleteATQSets', onDelete, { QSetId }));

  const tapTab = i => UI.stopEventThen(e => setTab(i));
  const [tab0, tab1] = [tab===0, tab===1];
  const tabKey = (showPub?'Pu':'Dr')+QSetId;

  const tabProps = {setTick, setTickSj, fieldErrs, fields, setField, setFields, filter: toAry(qset?.QSMetaFilters), setFilter, opts, setOpts, qset, showPub, lock, tagMSets, useTagMSets};
  return <div className="adminQuesTop" >
    <div className="f16">Questions / Question Sets / {isNew ? 'New' : 'Edit'} </div>
      {ATErrsDiv(fieldErrs)}
    <div className="adminToolsContainer"><div style={{display:"flex"}}>
      <div className="adminTools1">
        {showPub ? <>
          {letPub && cpATIcoBtn_('general/publish', 'Publish', (!hasDraft) && clickPublish)}
          {letUnpub && cpATIcoBtn_('general/unpublish', 'Unpublish', clickUnpublish)}
          {letClone && cpATIcoBtn_('general/copy', 'Duplicate as Draft', clickClone)}
        </> : <>
          {cpATIcoBtn_('general/save', 'Save', clickSaveATQSet(0, 0))}
          {cpATIcoBtn_('general/cancel', 'Cancel', clickUrl(dispatch, backPath))}
          {letDelete && cpATIcoBtn_('general/delete', 'Delete', clickDelete)}
          {letPub && cpATIcoBtn_('general/publish', 'Save & Publish', clickSavePublish)}
        </>}       
      </div>     
      <div className='flexRowStart' style={{width:"auto"}}>
      </div>
      <div className="adminTools2">
        <CpDraftPubBtns {...{saved, draft, showPub, pub, click:safeSetView, verKey:'QSVer' }} />
      </div>
    </div></div>
    {CpTabHead(tab, tapTab, [<>Settings {aTUIMust}</>, <>Questions {aTUIMust}</>, <>MetaData {aTUIMust}</>], <>
      <BtnPopDev txt='qset'>{preJS(qset, 3)}</BtnPopDev>
      <BtnPopDev txt='opts'>{preJS(opts, 3)}</BtnPopDev>
      <BtnPopDev txt='errs'>{preJS(fieldErrs, 3)}</BtnPopDev>
      {preJS({isNew, QSetId, canQSet, hasDraft, saved, r2p, unpubed, pubed, showPub, lock })}
      </>)}
    {
      tab0 ?<TabQSetGeneral key={tabKey+'G'} {...tabProps} />:
      tab1 ?<TabQSetQId key={tabKey+'Q'} {...tabProps} />:
      <TabQSetMeta key={tabKey+'M'} {...tabProps} />
    }
  </div>;
});
export default PageATQSetEdit;

const QSLoadOpts = (ATSubjects, mySjIds, QSQs, _QSMSs) => {
  const QSMSs = Object.fromEntries(_QSMSs.map(m => [m.metaSetId, m]));
  return {ATSubjects, mySjIds, QSQs, QSMSs};
};

export const getQSetQs = (qsqids, qsqs) => { return toAry(qsqids).map(i => qsqs[i]).filter(q => q); };
