import React, { } from 'react';
import CpATUploadButton from '../components/CpATUploadBtn';

import * as UI from '../../libs/libUI';

import { aTErrDiv1, aTUIMust, aTUITime, CpATBtn, mergePubsDrafts, preJS, QDPCol2, 
    QDRCol, QDRTxt, QPUCol, QPUTxt,langDisplay, aTWarnDiv1 } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';

//import TabQMC from './TabQMC';
import { maxVal, objKeys, objSort, toAry, toInt, toObj, toStr } from '../../libs/libType';
import { rowsToCsv } from '../PageATMetaEdit';
import { apiCallLoad_ } from '../../libs/awsFuncs';
import { ReduxBind } from '../../saga/ReduxState';
import { downloadUrl, fAKEdownload } from '../../libs/libDownload';
import { toUniIdAry } from '../../consts/ATValidate';
import { csv2Ids } from '../ATQSetEdit/TabQSetQIds';
import { QDPBtn } from '../components/CpDraftPubBtns';
import { ESEErrs } from '../../consts/ATValidateESet';
import { svgIcon2 } from '../components/CpATIcoBtn';

const TabESetExers = ReduxBind(props => {
    const {dispatch, setTick, setFields, setField, opts, setOpts, showPub, lock} = props; 
    const fieldErrs = toObj(props.fieldErrs);
    const eset = toObj(props.eset); 
    const ESEIds = toAry(eset.ESEIds);
    const Es= toObj(opts.Es);
    const eerrs = Object.fromEntries(ESEIds.map(i => [i, ESEErrs(eset, Es[i]?.pub)]));
 
    const onLoadESEs = eids => (res, err) => {
        const Es = mergePubsDrafts(res?.EPubs, res?.EDrafts, 'EId');
        const ESMSs = toObj(res?.ESMSs);
        const loadEids = objKeys(Es);
        const ESEIds = eids.filter(i => loadEids.includes(i));
        setField('ESEIds')(ESEIds);
        setOpts(o => ( {...o,  draftEMSets: {...o.draftMSets, ...ESMSs}, Es: {...o.Es, ...Es} } ) );
    };
    const loadCSV = (txt, err) => {
        if(err) console.error(err);
        const EIds = csv2Ids(txt);
        apiCallLoad_(dispatch, '/getATESetEs', onLoadESEs(EIds), {EIds} );
    };
    const downloadCSV = e => { UI.stopEvent(e); 
        const rows = [['Exercise Ids'], ...(toUniIdAry(ESEIds).map(i => [i]))];
        fAKEdownload(rowsToCsv(rows), 'ExerciseSet_'+toStr(eset.ESetId)+(showPub?'':'_draft')+'.csv', 'text/csv');
    };
    const donwloadTemplate = e => { UI.stopEvent(e); downloadUrl('/ATTemplates/exercise_set_template.csv'); };

    return <>
        <div>Included Exercises {aTUIMust} </div>
        <div className="flexRowEnd">
            {lock? '': <CpATUploadButton text='Upload Exercise ID' onLoad={loadCSV} />}
            {CpATBtn('Export Exercise IDs', 'btnDL', downloadCSV )}
            {CpATBtn('Download Template', 'btnDLTmp', donwloadTemplate )}
        </div>
        {aTErrDiv1(fieldErrs.ESEids)}
        <div className="execItemRowHeader f14">
            <div className="w m8" style={{width:'32px'}}> </div>
    		<div className="execItem1">Seq.</div>
			<div className="execItem2">Exercise ID</div>
			<div className="execItem3">Exercise Title</div>
			<div className="execItem4">Languages</div>
			<div className="execItem5">No. of Questions</div>
            <div className="execItem6">Total Marks</div>
			<div className="execItem7">Status</div>
        </div>        
		<div className="metaListContainer">
        {ESEIds.map(EId => Es[EId]).filter(e => e).map((ex, i) => <ESEItem2 key={i} {...{i, ex, err: eerrs[ex.EId]}} />)}        
        </div>	        
        </>
});
export default TabESetExers; 

export const eqcnt = e => e? ((toInt(e.aQCnt) + toInt(e.mQCnt)) || toInt(e.EQCnt)): '';  
export const escore = e => e? ((toInt(e.aScore) + toInt(e.mScore) ) || toInt(e.EScore) ): '';
const ESEItem2 = props => {
    const {i, ex, err} = props;
    const {pub:p, draft:d} = ex;
    const pd = p || d;
    
    return <div key={ex.EId} style={{width:'100%'}}><div className="itemRowBlk">
		<div className="adminItemRow f12">
        <div className="w m8" style={{width:'32px'}}>{err? svgIcon2('general/alert', '#bb0'): ''} </div>
        <div className="execItem1">{1+i}</div>
		<div className="execItem2">{pd.EId}</div>
		<div className="execItem3">{pd.ENameEn}<br/>{pd.ENameCt}</div>
		<div className="execItem4">
			{d? langDisplay(d.ELangEn, d.ELangCt):(p? langDisplay(p.ELangEn, p.ELangCt): '')}
		</div>
        <div className="execItem5">{UI.centerBox(eqcnt(d), eqcnt(p))}</div>
        <div className="execItem6">{UI.centerBox(escore(d), escore(p))}</div>
        <div className="execItem7">{UI.centerBox(d? DPDisplay(d): '', p? DPDisplay(p): '')}</div>
        </div>
        {aTWarnDiv1(err && 'Exercise '+ex.EId+': '+err)}
    </div></div>;
};
const DPDisplay = q => { return (q ? <div>{QDPBtn(q, 'EVer', UI.stopEvent, 0)}</div> : ''); };

export const newerDate = (date1, date2) => (date1 && date2)? maxVal(date1, date2): (date1 || date2);
