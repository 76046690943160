import { useEffect, useMemo, useState } from 'react';
import { ReduxBind } from "../../saga/ReduxState";

import {stopEvent} from '../../libs/libUI';
import { _ATRoot } from '../../consts/ATConstReact';

import { eNextIsFile, exerMapNextMedia } from '../../consts/ATValidateExer';
//--------------------------------------------------------
import {Button, Col, Container, Row} from "react-bootstrap";
import SharedModalHeader from '../../AppExPFUser/_components/CpSharedModalHeader';
import { CpSettingModalTitle, CpSettingModalSection, CpSettingModalTabContainer } from '../../AppExPFUser/_components/CpSettingModalTabCps';
import { useUILang } from '../utils/useUILang';
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpIco from '../../AppExPFUser/_components/CpIco';
import FileExtensionIcon from '../components/CpFileExtensionIcon';
import { asyncDL } from '../utils/useMediaCache';
import { prefixURL } from './ViewExCommon';
import CpSharedModal from '../../AppExPFUser/_components/CpSharedModal';
import { getTeacherName } from './TabExerContent';
import LayerEditRule, { _ERule_Conditions, aveAorB, passOrNot } from './LayerEditRule';
import { toObj, toStr } from '../../libs/libType';
import { normalizeNextRule, validateNextRule } from '../../consts/ATValidateNext';
import { popAlertToast } from '../components/CpPopup';

const EDIT_ACTION = 'EDIT_ACTION';
const REMOVE_ACTION = 'REMOVE_ACTION';

const copyNext = ENext => ({...ENext});

const CpExStep = ReduxBind(props => {
    const myUId = props.userId();
    const {dispatch, isAT, lock, showEn, useGetCache, cache2d, getMediaDLs, index, idx, ex,  
        ENx, addEContent, replaceContent, clickDelI, beforeUpdate, setFieldErrs} = props;
    const [t, uiEn, UILang, setUILang, ut, t2, t3] = useUILang();
    
    const [cp, ca] = [ENx.passt, ENx.avgt];
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState("");
    //const [fieldErrs, setFieldErrs] = useState({});
    const [isEdit, isDel] = [action === EDIT_ACTION, action === REMOVE_ACTION];

    const [tData, setTData] = useState();
    const dlf = eNextIsFile(ENx);
    const mediaId = eNextIsFile(ENx) && (showEn? ENx.mediaEn: ENx.mediaCt);  
    const media = toObj(mediaId && getMediaDLs([mediaId])[mediaId]);
    const ruleNameDis = showEn? ENx.ruleNameEn: ENx.ruleNameCt;
    const lnkDis = showEn? ENx.lnkEn: ENx.lnkCt;
    const NameDis = showEn? ENx.nameEn: ENx.nameCt;

    const enuid = ENx.modUid;
    const thrNames = useGetCache('teachers', '*'); 
    const owner = isAT? enuid:  (getTeacherName(enuid, thrNames, ut, t) || t("oupc-name"));

    const handleClose = () => { setOpen(false); }
    
    const clickPopEdit = e => { stopEvent(e); setFieldErrs({}); setAction(EDIT_ACTION); setTData({...copyNext(ENx), modUid:myUId}); setOpen(true); }
    const clickPopDelete = e => { stopEvent(e); setAction(REMOVE_ACTION); setOpen(true); }

    /*
    const beforeUpdate = (e) => {
        stopEvent(e);
        const r = normalizeNextRule(tData);
        const fes = validateNextRule(r, {showEn});
        if (!hasErr(fes)) { 
            clickUpdate();
            return;
        };
        setFieldErrs(fes);
    };
    */
    const clickUpdate = () => { 
        //stopEvent(e);
        replaceContent(idx, tData);
        setOpen(false);
    };
    
    const clickDelete = e => { clickDelI(idx)(e); setOpen(false); };
    const clickCopy = e => {stopEvent(e);
        addEContent(copyNext(ENx));
        //new msg
        popAlertToast(dispatch, 0, "copyNextRes");
    };

    const handleLink = url => window.open(url, '_blank');
    const downloadFile = (mediaId, f) => asyncDL(dispatch, getMediaDLs, mediaId);
  
    return <div key={'step'+idx} className={"CpExStep d-flex flex-column w-100 border rounded-light user-select-none sequenceListAnimation"}>
        <div className={"semi-bold fs-6 d-flex justify-content-between px-3 py-2 bg-dim-100"}>
            <span className={"d-flex gap-2 text-truncate"}>
                <span>{index}.</span><span className={"text-truncate"}>{ruleNameDis}</span>
            </span>
            {lock? '': <span className={"d-flex fs-6"}>
                <span role={"button"} className={"btn btn-borderless-exercise-third-btn"}
                    onClick={clickPopEdit}><CpIco src={IconList.general.edit}/></span>
                <span role={"button"} className={"btn btn-borderless-exercise-third-btn"}
                    onClick={clickCopy}><CpIco src={IconList.general.copy}/></span>
                <span role={"button"} className={"btn btn-borderless-exercise-third-btn"}
                    onClick={clickPopDelete}><CpIco src={IconList.general.trash}/></span>
            </span>}
        </div>
        {/*<Container fluid={true} className={"pt-3 pb-5 px-3"}>*/}
        <Container fluid={true} className={"p-3"}>
            <Row><Col xs={12} lg={5}>
                <div className={"d-flex flex-column align-items-start gap-3 w-100"}>
                    <div className={"semi-bold text-exercise-third-btn fs-5"}>{t("exercise-edit-tab-next-steps-when")}</div>
                    <div className={`semi-bold text-lowercase d-flex flex-wrap ${showEn ? "gap-2" : ""}`}>
                        <span>{t(_ERule_Conditions[(ENx.att?ENx.att:'-')])}</span>
                        {(ENx.att === 'y' && cp)?(<span>{t3('exercise-verb-and-student')}{t3(passOrNot[ENx.pass])}</span>):''}
                        {(ENx.att === 'y' && ca)?(<span>{t3('exercise-verb-and-student-score-is')}{t(aveAorB[ENx.avg])}</span>):''}
                    </div>
                </div></Col>
                <Col xs={12} lg={2}><div className={"d-flex h-100 flex-column align-items-center justify-content-center py-5"}>
                    <div className={"align-items-center gap-3 d-none d-lg-flex fs-3 text-exercise-third-btn"}>
                        <CpIco src={IconList.general.nextStepRight}/>
                    </div>
                    <div className={"d-flex align-items-center gap-3 d-lg-none fs-3 text-exercise-third-btn"}>
                        <CpIco src={IconList.general.nextStepBottom}/>
                    </div>
                </div></Col>
                <Col xs={12} lg={5}>
                <div className={"d-flex flex-column align-items-start gap-3 w-100"}>
                    <div className={"semi-bold text-exercise-third-btn fs-5"}>{t("exercise-edit-tab-next-steps-action")}</div>
                    <div className={"semi-bold text-lowercase"}>{t("exercise-edit-tab-next-steps-provide-the-student")}</div>
                    {<div className={"semi-bold d-flex align-items-center gap-2 border px-3 py-2 rounded-light btn btn-gray-exercise-third-btn w-100"}
                        role={"button"} onClick={() => dlf? downloadFile(mediaId): handleLink(prefixURL(lnkDis))}>
                        <span className={"fs-3"}>{dlf? <FileExtensionIcon name={toStr(media?.ext)}/>: <CpIco src={IconList.file.link}/>}</span>
                        <span className={"text-truncate"}>{NameDis}</span>
                    </div>}
                </div></Col>
            </Row>
        </Container>
        <div className={"d-flex gap-2 semi-bold px-3 py-2 bg-dim-100 fs-8"}>
            <span className={"d-none d-md-block"}>{t("exercise-edit-by")} :</span>
            <span className={"d-flex align-items-center gap-2 text-truncate"}>
                <span className={"fs-8"}><CpIco src={owner === t("oupc-name") ? IconList.brand.oupc : IconList.general.avatar}/></span>
                <span key={owner} className={"text-truncate"}>{owner}</span>
            </span>
        </div>
        {(isEdit || isDel)?
        <CpSharedModal className={(isEdit? "modal-lg" : "")} show={open} scrollable={true} 
            header={
                isEdit? <SharedModalHeader {...{title: t("exercise-edit-tab-next-steps-edit-rule"), iconPath: IconList.general.edit, handleClose}} />:
                isDel? <SharedModalHeader {...{title: t("exercise-edit-tab-next-steps-delete-rule"), iconPath: IconList.general.trash, handleClose}} />:
            ''}
            footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
                <Button variant="gray-body-color w-100 border" onClick={handleClose}>{t("cancel")}</Button>
                {isEdit && <Button variant={`exercise-third-btn btn-block w-100`} onClick={(e)=>beforeUpdate(e, clickUpdate, tData)}>{t("update")}</Button>}
                {isDel && <Button variant={`danger btn-block w-100`} onClick={clickDelete}>{t("delete")}</Button>}
            </div>} >
            <CpSettingModalTabContainer gap={3}><CpSettingModalSection>
                    {/*{isDel && <CpSettingModalTitle>{t("delete-message").replace('%s',t('delete-items.next-step'))}</CpSettingModalTitle>}*/}
                    {isDel && <CpSettingModalTitle>{t("warning.warning_delete_rule")}</CpSettingModalTitle>}
                    {isEdit && <LayerEditRule {...props} ENx={tData} setENx={setTData}/>}
            </CpSettingModalSection></CpSettingModalTabContainer>
        </CpSharedModal>        
        :''}         
    </div>    
});
export default CpExStep; 

