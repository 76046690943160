import { __SYSQSubType_FIB, __SYSQSubType_LBD, __SYSQSubType_LBT, __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_OED, __SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_POL } from "../../consts/ATSysQType";

const sampleQLang = (mode) => ({
    "qAnswers": mode?[{"data": "","type": "ATG","correct": 0},{"data": "","type": "ATG","correct": 0}]:[],
    "tAnswers": mode?[[{"data": "","type": "TTC","correct": 0,"width": "","height": ""},
                  {"data": "","type": "TTC","correct": 0,"width": "","height": ""}],
                  [{"data": "","type": "TTC","correct": 0,"width": "","height": ""},
                  {"data": "","type": "TTC","correct": 0,"width": "","height": ""}],
                  [{"data": "","type": "TTC","correct": 0,"width": "","height": ""},
                  {"data": "","type": "TTC","correct": 0,"width": "","height": ""}],]:[],
    "qData": "",
    "ansHints": "",
    "ansKeys": 0,
    "AKText": [],
    "AKLink": [],
    "AKFile": []
});

export const _ansClass = {
   'ACG': 'Grid', //grid
   'ACL': 'List', //list
   'ACB': 'Label', //label
};

const qtn_common = (mode) => ({
   isNew: 1,
    shuffleAnswer: 0,
    maxFile: 5,
    score: 1,
    QFilterMetas: [],
    QDisplayMetas: [],
    QMetaPres:[],
    QMetaPros:[],
    autoMark: 1,
    ansChoice: "ACG",
    multiAnswer: 0,
    scoreMethod: "PBK",
    correctness: 0,   
    caseSensitive: 0,
    wordLimit: 0,
    allowTeaCopyEdit: 1,
    respText: 1,
    respURL: 0,
    respFile: 0,    
    respImage: 0,
    respDrawing: 0,
    QEn: sampleQLang(mode),
    QCt: sampleQLang(mode)
 });

export const defaultQtnData = {
   "mcq-single-answer":{...qtn_common(1), SQType: __SYSQSubType_MCS, multiAnswer:0},
   "mcq-multiple-answers":{...qtn_common(1), SQType: __SYSQSubType_MCS, multiAnswer:1},
   "match-table-single-answer":{...qtn_common(1), SQType: __SYSQSubType_MCT, multiAnswer:0, ansChoice: "TTC"},
   "match-table-multiple-answers":{...qtn_common(1), SQType: __SYSQSubType_MCT, multiAnswer:1, ansChoice: "TTC"},
   "match-table-single-answer-2":{...qtn_common(1), SQType: __SYSQSubType_MCT, multiAnswer:0, ansChoice: "TTB"},
   "match-table-multiple-answers-2":{...qtn_common(1), SQType: __SYSQSubType_MCT, multiAnswer:1, ansChoice: "TTB"},
   "fib-automark":{...qtn_common(0), SQType: __SYSQSubType_FIB, autoMark:1},
   "fib-manual-mark":{...qtn_common(0), SQType: __SYSQSubType_FIB, autoMark:0},
   "label-the-diagram-text-field":{...qtn_common(0), SQType: __SYSQSubType_LBT,},
   "label-the-diagram-drop-down":{...qtn_common(0), SQType: __SYSQSubType_LBD,},
   "open-ended-canvas-drawing":{...qtn_common(0), SQType: __SYSQSubType_OED, autoMark:0, },
   "open-ended-general":{...qtn_common(0), SQType: __SYSQSubType_OEG, autoMark:0, },
   "open-ended-short-answer":{...qtn_common(0), SQType: __SYSQSubType_OEG, autoMark:0,},
   "open-ended-essay-single-option":{...qtn_common(0), SQType: __SYSQSubType_OEE, autoMark:0,},
   "polling-single-options":{...qtn_common(1), SQType: __SYSQSubType_POL, multiAnswer:0, ansChoice: "ACG"},
   "polling-multiple-options":{...qtn_common(1), SQType: __SYSQSubType_POL, multiAnswer:1, ansChoice: "ACG"},  
};

const mc_general = {
    "SQType": "SQST_MCS",
    "QVer": "1.0",
    "dp": "d",
    "drpu": "D",
    "isAT": 1,
    "isNew": 1,
    "QId": "",
    "QEn": {
       "qAnswers": [
          {
             "data": "",
             "type": "ATG",
             "correct": 0
          },
          {
             "data": "",
             "type": "ATG",
             "correct": 0
          }
       ],},
    "QCt": {},
    "QLangEn": 1,
    "score": 1
 };
 
 const mc_matchTable = {
    "SQType": "SQST_MCT",
    "QVer": "1.0",
    "dp": "d",
    "drpu": "D",
    "isAT": 1,
    "isNew": 1,
    "QId": "",
    "QEn": {
       "tAnswers": [
          [
             {
                "data": "",
                "type": "TTC",
                "correct": 0,
                "width": "",
                "height": ""
             },
             {
                "data": "",
                "type": "TTC",
                "correct": 0,
                "width": "",
                "height": ""
             }
          ],
          [
             {
                "data": "",
                "type": "TTC",
                "correct": 0,
                "width": "",
                "height": ""
             },
             {
                "data": "",
                "type": "TTC",
                "correct": 0,
                "width": "",
                "height": ""
             }
          ],
          [
             {
                "data": "",
                "type": "TTC",
                "correct": 0,
                "width": "",
                "height": ""
             },
             {
                "data": "",
                "type": "TTC",
                "correct": 0,
                "width": "",
                "height": ""
             }
          ]
       ],
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": []
    },
    "QLangEn": 1,
    "score": 1,
    "ansChoice": "TTC"
 };

 const fib={
    "SQType": "SQST_FIB",
    "QVer": "1.0",
    "dp": "d",
    "drpu": "D",
    "isAT": 1,
    "isNew": 1,
    "QId": "",
    "QEn": {
       "qAnswers": [],
       "tAnswers": [],
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": []
    },
    "QCt": {
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "qAnswers": [],
       "tAnswers": []
    },
    "QLangEn": 1,
    "score": 0,
    "ansChoice": "TTC"
 }; 

 const lbd_textinput = {
    "SQType": "SQST_LBT",
    "QVer": "1.0",
    "dp": "d",
    "drpu": "D",
    "isAT": 1,
    "isNew": 1,
    "QId": "",
    "QEn": {
       "qAnswers": [],
       "tAnswers": [],
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QCt": {
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "qAnswers": [],
       "tAnswers": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QLangEn": 1,
    "score": 0,
    "ansChoice": "TTC",
    "respText": 1,
    "respImage": 0,
    "respFile": 0,
    "respDrawing": 0,
    "respURL": 0,
    "wordLimit": 1,
    "maxFile": 1
 };

 const lbd_dropdown = {
    "SQType": "SQST_LBD",
    "QVer": "1.0",
    "dp": "d",
    "drpu": "D",
    "isAT": 1,
    "isNew": 1,
    "QId": "",
    "QEn": {
       "qAnswers": [],
       "tAnswers": [],
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QCt": {
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "qAnswers": [],
       "tAnswers": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QLangEn": 1,
    "score": 1,
    "ansChoice": "TTC",
    "respText": 1,
    "respImage": 0,
    "respFile": 0,
    "respDrawing": 0,
    "respURL": 0,
    "wordLimit": 1,
    "maxFile": 1
 };

 const op_general = {
    "SQType": "SQST_OEG",
    "QVer": "1.0",
    "dp": "d",
    "drpu": "D",
    "isAT": 1,
    "isNew": 1,
    "QId": "",
    "QEn": {
       "qAnswers": [],
       "tAnswers": [],
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QCt": {
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "qAnswers": [],
       "tAnswers": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QLangEn": 1,
    "score": 1,
    "ansChoice": "TTC",
    "respText": 1,
    "respImage": 0,
    "respFile": 0,
    "respDrawing": 0,
    "respURL": 0,
    "wordLimit": 1,
    "maxFile": 1
 };

 const op_essay = {
    "SQType": "SQST_OEE",
    "QVer": "1.0",
    "dp": "d",
    "drpu": "D",
    "isAT": 1,
    "isNew": 1,
    "QId": "",
    "QEn": {
       "qAnswers": [],
       "tAnswers": [],
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QCt": {
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "qAnswers": [],
       "tAnswers": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QLangEn": 1,
    "score": 1,
    "ansChoice": "TTC",
    "respText": 1,
    "respImage": 0,
    "respFile": 0,
    "respDrawing": 0,
    "respURL": 0,
    "wordLimit": 1,
    "maxFile": 1
 };

 const polling = {
    "SQType": "SQST_POL",
    "QVer": "1.0",
    "dp": "d",
    "drpu": "D",
    "isAT": 1,
    "isNew": 1,
    "QId": "",
    "QEn": {
       "qAnswers": [
          {
             "data": "",
             "type": "ATG",
             "correct": 0
          },
          {
             "data": "",
             "type": "ATG",
             "correct": 0
          }
       ],
       "tAnswers": [],
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QCt": {
       "qData": "",
       "ansHints": "",
       "ansKeys": 0,
       "AKText": [],
       "AKLink": [],
       "AKFile": [],
       "qAnswers": [
          {
             "data": "",
             "type": "ATG",
             "correct": 0
          },
          {
             "data": "",
             "type": "ATG",
             "correct": 0
          }
       ],
       "tAnswers": [],
       "BGSrc": null,
       "LDMode": null
    },
    "QLangEn": 1,
    "score": 0,
    "ansChoice": "ACG",
    "respText": 1,
    "respImage": 0,
    "respFile": 0,
    "respDrawing": 0,
    "respURL": 0,
    "wordLimit": 1,
    "maxFile": 1
 };