import React, { useEffect, useState, useRef } from 'react';

import * as UI from '../../libs/libUI';

import { aTErrDiv1, aTErrTxt1, ATUICheckBox0, aTUIMust, BtnPopDev, clickUrl, PopA, preJS } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';

//import TabQMC from './TabQMC';
import CpATSubjectTickList from '../components/CpATSubjectTickList';
import { ATUIText, ATUILabel } from '../AppUtil';
import { toAry, toObj, toStr } from '../../libs/libType';

const TabESetSetting = (props => {
    const {fields, setField, setTick, setESubjId, lock} = props;
    const opts = toObj(props.opts);
    const ATSubjects = toAry(opts.ATSubjects);
    const mySjIds = toAry(opts.mySjIds);
    
    const fieldErrs = toObj(props.fieldErrs);
    const eset = toObj(props.eset); 

    return <>
    <div className='TabESetSetting flexRowBetween f14'>
        <div className='w50'>
            <div className='fxRow'>
            {ATUILabel('Exercise Set ID','qsi',true,120)}
            {ATUIText(eset.ESetId, setField('ESetId'), 'tesi', lock||(!(fields.isNew)),'Please enter Set ID here','',{width:'60%'})} 
            </div>
            {aTErrDiv1(fieldErrs.ESetId)}
        </div>
        <div className='w50'>
            <div className='fxRow'>
            {ATUILabel('ESAS Series Codes','esc',true,140)}
            {ATUIText(eset.ESASStr, setField('ESASStr'), 'tsea', lock,'Please enter ESAS Series Code here (use "," as separator)','',{width:'60%'})} 
            </div>
            {aTErrDiv1(fieldErrs.ESASStr)}
        </div>
    </div><hr className='hrat'/>
    <div className=''>
        <div>Supported Languages{aTUIMust}
            <label className="w m4">{ATUICheckBox0(eset.ESLangEn, setTick('ESLangEn'), 'chkEn', lock)}En</label>
            <label className="w m4">{ATUICheckBox0(eset.ESLangCt, setTick('ESLangCt'),  'chkCt', lock)}繁中</label>
            {aTErrTxt1(fieldErrs?.ESLang)}
        </div>
    </div><hr className='hrat'/>
    <div className='f14'>
        <div className="fxRow">
            {ATUILabel('Set Name (En)','sne',true,120)}{ATUIText(eset.ESNameEn, setField('ESNameEn'), 'tne', lock, 'Please enter Exercise Set Name here')} 
        </div>
        {aTErrDiv1(fieldErrs.ESNameEn)}
    </div>
    <div className='f14'>
        <div className="fxRow">
            {ATUILabel('Set Name (繁中)','snc',true,120)}{ATUIText(eset.ESNameCt, setField('ESNameCt'), 'tnc', lock, 'Please enter Exercise Set Name here')} 
        </div>
        {aTErrDiv1(fieldErrs.ESNameCt)}
    </div>
    <hr className='hrat'/>
    <div className='f14'>{ATUILabel('Enable for Subject(s) in AT','efsa',true,200)}</div>
    <div className='subjectItemContainer f14'>
        {CpATSubjectTickList( ATSubjects, mySjIds, toAry(eset.ESSubjIds), setESubjId, lock) }
        {aTErrTxt1(fieldErrs.ESSubjIds)}
    </div>
    <hr className='hrat'/>
    <div className='flexRowStart f14'>{ATUILabel('Keyword(s) (En)','kse',false,120)}{ATUIText(eset.ESKwEn, setField('ESKwEn'), 'tke', lock, 'use "," as separator')} </div>
    <div className='flexRowStart f14'>{ATUILabel('Keyword(s) (繁中)','ksc',false,120)}{ATUIText(eset.ESKwCt, setField('ESKwCt'), 'tke', lock, 'use "," as separator')} </div>
    </>;
});
export default TabESetSetting; 


