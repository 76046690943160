import { autoId } from "../../../AppExPf/AppUtil";
import { isAry, maxVal, minVal, objEntries, objKeys, toAry, toFaction1, toFactionF, toFix1, toFloat, toInt, toObj, toStr } from "../../../libs/libType";
import { debugMode } from "../../../saga/ReduxState";
import { MSetSubTree, rptMetaFullTree, rptMetaSubTree, rptMName, sortRptMs, } from "./dataUtil";

export const toStuMetaData_ = (rptOverQtns, rptStuQtns, stu) => {
  if(!stu) return {};
  
  
  const aqs = Object.fromEntries(toAry(rptOverQtns).map(q => [q.assignId, q]));
    const msStat = {};
    toAry(rptStuQtns).forEach(sq => {

        const { assignId, stuQtn2d } = sq;
        const aq = aqs[assignId]?.QStat;
        const st = stuQtn2d?.[stu];

        if(!(isAry(aq) && isAry(st))) return;
        aq.forEach((q, idx) => {
            const {ans, markp} = st[idx]; 
            objEntries(q.metas).forEach(([msid, mids]) => {
                const mstats = msStat[msid] || {};
                mids.forEach(mid => {
                    const sum = mstats[mid] || {cnt:0, markp:0, minp:100, maxp:0, list:[]};
                    sum.cnt++;
                    sum.minp = minVal(sum.minp, markp);
                    sum.maxp = maxVal(sum.maxp, markp);
                    sum.markp += markp;
                    sum.list.push(markp);
                    mstats[mid] = sum;
                });
                msStat[msid] = mstats;
            });

        });
    });

    return msStat;
};

export const toStuMetaDataL2 = (stuMetaData_, ms, tagMSets, ut) => {
  const msets = tagMSets; //>((useTagMSets(((msids);
  const mset = toObj(msets[ms]);
  const metas = toObj(mset?.metas);

  const mname = mid =>  rptMName(metas[mid], ut, mid);
  
  const mSums = stuMetaData_?.[ms];
  const mids = objKeys(mSums);
  const [MRoot, MBranchs] = MSetSubTree(metas, mids, 1);
 
  const lv1s = {};
  const data2d = MRoot.map(L1id => {
    lv1s[ms+','+L1id] = mname(L1id);

    const lv2s = toAry(MBranchs[L1id]);
    return lv2s.map(L2id => {
      const lv3s = toAry(MBranchs[L2id]);
      const child = lv3s.map(L3id => {
        const {cnt, markp} = toObj(mSums[L3id]);
        return {
          id: L3id, 
          title: mname(L3id), 
          score: { percentage: toFaction1(markp, cnt) },
        };
      });
      const {cnt, markp} = toObj(mSums[L2id]);
      return {
        id: L2id, 
        title: mname(L2id), 
        overallScore: { percentage: toFaction1(markp, cnt) },
        child
      };
    });
  });
  const data = [].concat(...data2d);
  const ret = { data, lv1s }; //MetadataRadialGroupData
  
  //return ret;
  return debugMode()? MetadataRadialGroupData: ret;
};

const MetadataRadialGroupData = {
  lv1s:{
    'ml-01':"Binomials \\(\\binom{n}{k}\\)",
    'ml-02':"Physics Formulas $$E=mc^2$$",
    
    "my_skills_01,CRI_THINK": "Critical Thinking",
    "my_skills_01,COMM": "Communication",
    "my_skills_01,LATEX": "Binomials \\(\\binom{n}{k}\\)",
    "my_skills_01,LATEX_B": "Physics Formulas $$E=mc^2$$",
    "my_skills_01,LATEX_I": "Maths Formulas \\(a^2 + b^2 = c^2\\)"
  },
  "data": [{
    "id": "ml-01",
    "title": "Compulsory Part",
    "overallScore": { "percentage": 78.3 },
    "child": [
      {"id": "m-001-01", "title": "Number and Algebra Strand", "score": { "percentage": 80 } },
      {"id": "m-001-02", "title": "Measure, Shape and Space Strand", "score": { "percentage": 30 } },
    ]
  },{
    "id": "ml-02",
    "title": "Module 1",
    "overallScore": { "percentage": 45.5 },
    "child": [{
        "id": "m-002-01","title": "Number and Algebra Strand","score": { "percentage": 80 }
      },{
        "id": "m-002-02","title": "Measure, Shape and Space Strand", "score": { "percentage": 30 }
      },{
        "id": "m-002-03", "title": "Data Handling Strand", "score": { "percentage": 90 }
      },{
        "id": "m-002-04", "title": "Number and Algebra Strand", "score": { "percentage": 80 }
      },{
        "id": "m-002-05", "title": "Measure, Shape and Space Strand Measure, Shape and Space Strand", "score": {"percentage": 30 }
      },{
        "id": "m-002-06", "title": "Data Handling Strand Data Handling Strand", "score": { "percentage": 90 }
      },{
        "id": "m-002-07", "title": "Number and Algebra Strand Number and Algebra Strand", "score": { "percentage": 80 }
      }]
  }]
};

/*
const toStuMetaData2Trim = (stuMetaData_, msid, tagMSets, ut) => {
  const msids = objKeys(stuMetaData_);
  const msets = tagMSets; //>useTagMSets(((msids);
  const d2d = objEntries(stuMetaData_).filter(s => s[0] === msid).map(([msid, mSums]) => {
      const { mset, mids, metas, MRoot, MBranchs } = rptMetaFullTree(msets, msid);//rptMetaSubTree(msets, msid, objKeys(mSums));
      
      const makeNode = mid => {
          const msum = toObj(mSums[mid]);
          const {cnt, markp, minp, maxp} = msum;
          const avgP = toFaction1(markp, cnt);
          const mret = {
              _cnt: cnt,
              _markp: markp,
              _minp: minp,
              _maxp: maxp,
              id: msid+','+mid,
              title: rptMName(metas[mid], ut, mid),
              answered: cnt,
              scoreRange: {
                  minPercentage: toFix1(minp),//minp, //(tmp % 2)? avgP: 0,//toFloat(avgP)-1,
                  maxPercentage: toFix1(maxp),//maxp, //(tmp % 3)? avgP: 100,//toFloat(avgP)+1,
                  status: (avgP<50)? 'f': 'p',//"f"
              },
              averageScore: { percentage: toFix1(avgP) },
              classAverageScore: { percentage: toFix1(avgP) }, // todo 2023-06-13
          };
          if(MBranchs[mid]?.length) mret.children = (MBranchs[mid]).map(makeNode);
          return mret;
      }
      return MRoot.map(makeNode);
  });
  const mset = toObj(msets[msid]);


  const data = [].concat(...d2d);
  const stuMetaData2 = {
      id: "*MSTop",
      title: ut(mset.MSNameEn, mset.MSNameCt) || msid,
      type: "s",
      data
  };
    return stuMetaData2;
  //return MetadataProgressByStudentSharedData;
};
*/
/*
export const toStuMetaData2X = (clsMetaData_, stu, _j, _mtype, _msid, tagMSets, ut) => {
  const msets = tagMSets; //>toObj(useTagMSets((([_msid]));
  const mset = toObj(msets[_msid]);

  const loop1 = (msid, mSums) => {
    const { mset, mids, metas, MRoot, MBranchs } = rptMetaFullTree(msets, msid);//rptMetaSubTree(msets, msid, objKeys(mSums));
    const cMset = toObj(clsMetaData_?.clsMsets?.[_msid]); 

    const makeNode = mid => {
      const csum = toObj(cMset[mid]);
      const clsAvgp = toFactionF(csum.markp, csum.cnt);
      const msum = toObj(mSums[mid]);
      const {cnt, markp, minp, maxp} = msum;
      const avgP = toFactionF(markp, cnt);
      const below = (clsAvgp > avgP); 
      const m = metas[mid];
      const mret = {
        _cnt: cnt,
        _markp: markp,
        _minp: minp,
        _maxp: maxp,
        id: msid+','+mid,
        title: rptMName(m, ut, mid),
        answered: cnt,
        scoreRange: {
            minPercentage: toFix1(minp),//minp, //(tmp % 2)? avgP: 0,//toFloat(avgP)-1,
            maxPercentage: toFix1(maxp),//maxp, //(tmp % 3)? avgP: 100,//toFloat(avgP)+1,
            status: below? 'f': 'p',//"f"
        },
        averageScore: { percentage: toFix1(avgP) },
        classAverageScore: { percentage: toFix1(clsAvgp) }, // todo 2023-06-13
        _rnum: toInt(m?.rnum)
      };
      if(MBranchs[mid]?.length) mret.children = sortRptMs((MBranchs[mid]).map(makeNode));
      return mret;
    };
    return sortRptMs(MRoot.map(makeNode));
  };
  const sMset = toObj(clsMetaData_.stuMset2d?.[stu]?.[_msid]);
  const data = loop1(_msid, sMset);

  const stuMetaData2X = {
    id: _msid, //"*MSTop",
    title: ut(mset.MSNameEn, mset.MSNameCt),//"M-Set-Type",
    type: "s",
    data
  };
  return stuMetaData2X;
};
*/

export const toStuMetaData2 = (clsMetaData_, stu, _j, _mtype, _msid, tagMSets, ut) => {
  const clsMsets = toObj(clsMetaData_?.clsMsets?.[_msid]); 
  const stuMetaData_ = toObj(clsMetaData_?.stuMset2d?.[stu]);

//console.log('toStuMetaData2()a', {clsMetaData_, stu});

  //const msids = objKeys(stuMetaData_);
  const msets = toObj(tagMSets); //>toObj(useTagMSets(((msids));

  const loop1 = (msid, mSums) => {
      //const { mset, mids, metas, MRoot, MBranchs } = rptMetaFullTree(msets, msid);//
      const { mset, mids, metas, MRoot, MBranchs } = rptMetaSubTree(msets, msid, objKeys(mSums));

//console.log('toStuMetaData2()b', {msid, mset, mids, metas, MRoot, MBranchs});

      const makeNode = mid => {
          const csum = clsMsets[mid];
          const clsAvgP = csum && toFactionF(csum.markp, csum.cnt);
          const msum = mSums[mid];
          const {cnt, markp, minp, maxp} = toObj(msum);
          const avgP = toFactionF(markp, cnt);
          const m = metas[mid];
          const mret = msum? {
            _cnt: cnt,
            _markp: markp,
            _minp: minp,
            _maxp: maxp,
            id: msid+','+mid,
            title: rptMName(m, ut, mid),
            answered: cnt,
            scoreRange: {
                //minPercentage: toFix1(minp),//minp, //(tmp % 2)? avgP: 0,//toFloat(avgP)-1,
                //maxPercentage: toFix1(maxp),//maxp, //(tmp % 3)? avgP: 100,//toFloat(avgP)+1,
                status: (clsAvgP > avgP)? 'f': 'p',
            },
            averageScore: { percentage: toFix1(avgP) },
            _rnum: toInt(m?.rnum),
          }:{
            id: msid+','+mid,
            title: rptMName(m, ut, mid),
            _rnum: toInt(m?.rnum),
          };
          if(csum) mret.classAverageScore = { percentage: toFix1(clsAvgP) }; // todo 2023-06-13 
          if(MBranchs[mid]?.length) mret.children = sortRptMs(toAry(MBranchs[mid]).map(makeNode));
          return mret;
      };
      return sortRptMs(MRoot.map(makeNode));
  };
  //const d2d = objEntries(stuMetaData_).map(([i,s]) => loop1(i, s));
  //const data = [].concat(...d2d);
  const data = loop1(_msid, toObj(stuMetaData_[_msid]));

  const mset = toObj(msets[_msid]);
  const stuMetaData2 = {
      id: _msid, //"*MSTop",
      title: ut(mset.MSNameEn, mset.MSNameCt),//"M-Set-Type",
      type: "s",
      data
  };
 
  //const stuMetaData2X = toStuMetaData2X(clsMetaData_, stu, _j, _mtype, _msid, tagMSets, ut);
//console.log('stuMetaData2', stuMetaData2);
  return stuMetaData2;
  //return MetadataProgressByStudentSharedData;
};

//2023-06-13 updated: added 'classAverageScore'
const MetadataProgressByStudentSharedData = {
  "id": "m-001",
  "title": "Curriculum",
  "type": "s",
  "data": [
    {
      "id": "m-001",
      "title": "Compulsory",
      "answered": 103,
      "scoreRange": {
        "minPercentage": 20,
        "maxPercentage": 80,
        "status": "p"
      },
      "averageScore": {
        "percentage": 35
      },
      "classAverageScore": {
        "percentage": 30
      },
      "children": [
        {
          "id": "m-001-01-01",
          "title": "Number and Algebra Strand",
          "answered": 50,
          "scoreRange": {
            "minPercentage": 20,
            "maxPercentage": 80,
            "status": "p"
          },
          "averageScore": {
            "percentage": 35
          },
          "classAverageScore": {
            "percentage": 30
          },
          "children": [
            {
              "id": "m-001-01-01-01",
              "title": "1. Quadratic equation in one unknown",
              "answered": 30,
              "scoreRange": {
                "minPercentage": 20,
                "maxPercentage": 80,
                "status": "f"
              },
              "averageScore": {
                "percentage": 35
              },
              "classAverageScore": {
                "percentage": 30
              },
              "children": [
                {
                  "id": "m-001-01-01-01-01",
                  "title": "1.1 solve quadratic equations by the factor method",
                  "answered": 10,
                  "scoreRange": {
                    "minPercentage": 20,
                    "maxPercentage": 80,
                    "status": "f"
                  },
                  "averageScore": {
                    "percentage": 35
                  },
                  "classAverageScore": {
                    "percentage": 30
                  }
                },
                {
                  "id": "m-001-01-01-01-01",
                  "title": "1.1 solve quadratic equations by the factor method",
                  "answered": 10,
                  "averageScore": {
                    "percentage": 35
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "m002",
      "title": "Module 1",
      "answered": 103,
      "scoreRange": {
        "minPercentage": 20,
        "maxPercentage": 80,
        "status": "p"
      },
      "averageScore": {
        "percentage": 35
      },
      "classAverageScore": {
        "percentage": 30
      }
    },
    {
      "id": "m003",
      "title": "Module 2",
      "answered": 103,
      "scoreRange": {
        "minPercentage": 20,
        "maxPercentage": 80,
        "status": "f"
      },
      "averageScore": {
        "percentage": 35
      },
      "classAverageScore": {
        "percentage": 30
      }
    }
  ]
};


export const toStuMetaDataL1 = (stuMetaData_, ms, tagMSets, ut) => {
  const msids = objKeys(stuMetaData_);
  const msets = tagMSets; //>useTagMSets(((msids);
  const mset = toObj(msets[ms]);
  const metas = toObj(mset?.metas);
 
  const mname = mid => rptMName(metas[mid], ut, mid);

  const mSums = stuMetaData_[ms];
  const mids = objKeys(mSums);
  const [MRoot, MBranchs] = MSetSubTree(metas, mids, 1);
  
   const data = MRoot.map(lv1Id => {
    const lv2s = toAry(MBranchs[lv1Id]);
    const child = lv2s.map(l2id => {
      const {cnt, markp} = toObj(mSums[l2id]);
      return {
        id: l2id, 
        title: mname(l2id), 
        score: { percentage: toFaction1(markp, cnt) },
      };
    });
    const {cnt, markp} = toObj(mSums[lv1Id]);
    return {
      id: lv1Id, 
      title: mname(lv1Id), 
      overallScore: { percentage: toFaction1(markp, cnt) },
      child
    };
  });

  const stuMetaData1 = { data, } //MetadataRadialGroupData
  return stuMetaData1;
};
