import { useUILang } from "../../AppExPf/utils/useUILang";

const CpQuestionCorrectnesAndScores = (props) => { // base on poc QuestionCorrectnesAndScores.tsx
    const [t] = useUILang();
    const {data = []} = props;
    return <div className={"report d-flex flex-wrap gap-2"}>
        {data.map(d => <div key={d.id} className={`question-cube question-${d.status} semi-bold px-2 pt-1 pb-2 rounded-light`}>
            <span className={"pe-3 fs-8"}>{t("q")}{d.no}</span>
            <div className={"d-flex gap-1 justify-content-end align-items-end fs-4 line-height-normal"}><span
                className={"status-text fs-2 line-height-normal"}>{d.marks.value}</span><span
                className={"fs-2"}>/</span><span className={"total"}>{d.marks.total}</span></div>
        </div>)}
    </div>
};

export default CpQuestionCorrectnesAndScores;