//import Amplify, {API} from 'aws-amplify';

import * as mw from './libMidWare';
import libSessionId from './libSessionId';
import {HttpPostMP} from './libHttp';
import * as convert from './libType';

import { APICB_every } from '../saga/apiCB.saga';
import { auth_login_state } from '../saga/auth.saga';
import { loading_add } from '../saga/loading.saga';
import { gst_clearAll } from '../saga/globalState.saga';

import { logMode } from '../saga/ReduxState';

const RDX = {}; //import * as RDX from './ReduxAction';

/*
Amplify.configure({
  Auth: {
  mandatorySignIn: false,
  //region: REACT_APP_COGNITO_REGION,
  //userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  //identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
  //userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
}, 
Storage: {
  //region: REACT_APP_S3_REGION,
  //bucket: REACT_APP_S3_BUCKET,
  //identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
},
API: {endpoints: [{
  name: "oupcardaws",
  region: REACT_APP_GATEWAY_REGION,
  endpoint: REACT_APP_GATEWAY_URL,
},]}
});
export const AwsPost = async (url, useCookie, formObj, formFiles, onUploadProgress) => {
  //return new Promise(resolve => {debuglog('AwsPost disabled!!!!'); resolve(); });
  //return API.post("oupcardaws", "/api/", {body: {...formObj, }, withCredentials: true, onUploadProgress});
  return API.post("oupcardaws", "/api/", {body: {...formObj, },  onUploadProgress});
}

const onUploadProgress = (progressEvent) => {
  const fullLength = progressEvent.lengthComputable 
  ? progressEvent.total 
  : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
  //debugLog("AwsPost onUploadProgress", progressEvent.loaded, '/', fullLength);
};
*/
//const pathLogout = process.env.REACT_APP_WEBROOT;
export const appLogout = async (dispatch, en, settings, cb) => {
  const onLogout = async (res, err) => {
    libSessionId.clear();
    dispatch(gst_clearAll());
    dispatch(auth_login_state({signedOut:1}));
    const ml_lout_ret = await mw.safeSignoutRedirect(en, settings);
    console.log({ml_lout_ret}, JSON.stringify(ml_lout_ret));

    //dispatch(push(pathLogout)); dispatch(push(pathLogout));
    if(cb) cb(res, err);
  };
  //if(!(await mw.safeSignoutRedirect()))
  apiCallLoad(dispatch, '/logout', onLogout, {});
  //await mw.safeSignoutRedirect(settings);
};

export const asyncApiCall = async (dispatch, act, form = {}, files = {}, callbackWIP = false) => {
  return new Promise(resolve => apiCall(dispatch, act, (res, err) => resolve([res, err]), form, files, callbackWIP));
};  
export const asyncApiCall_ = async (dispatch, act, form = {}, files = {}, callbackWIP = false) => {
  return new Promise(resolve => apiCall_(dispatch, act, (res, err) => resolve([res, err]), form, files, callbackWIP));
};  
export const asyncApiCallLoad = async (dispatch, act, form = {}, files = {}, callbackWIP = false) => {
  return new Promise(resolve => apiCallLoad(dispatch, act, (res, err) => resolve([res, err]), form, files, callbackWIP));
};  
export const asyncApiCallLoad_ = async (dispatch, act, form = {}, files = {}, callbackWIP = false) => {
  return new Promise(resolve => apiCallLoad_(dispatch, act, (res, err) => resolve([res, err]), form, files, callbackWIP));
};  

export const apiCallLoad_ = async (dispatch, act, callback = false, form = {}, files = {}, callbackWIP = false) => { 
  const warpCB = async (res, err) => {
    if (logMode()) console.info('apiCallLoad_Res: '+act, res);
    if(callback) await callback(res, err);
  };
  if (logMode()) console.info('apiCallLoad_: '+act, form);
  return apiCallLoad(dispatch, act, warpCB, form, files, callbackWIP); 
}
export const apiCallLoad = async (dispatch, act, callback = false, form = {}, files = {}, callbackWIP = false) => { 
  dispatch(loading_add(act, 1)); // manage loading
  const warpCB = async (res, err) => {
    if(err) console.error('apiCallLoad: '+ act, JSON.stringify(err));
    try{
      if(callback) await callback(res, err);
      dispatch(loading_add(act, -1)); // manage loading
    }catch(e){ console.error(e); };
  };
  return dispatch(APICB_every(act, warpCB, form, files, callbackWIP));
};

export const apiCall_ = async (dispatch, act, callback = false, form = {}, files = {}, callbackWIP = false) => {
  const warpCB = async (res, err) => {
    if (logMode()) console.info('apiCall_res: '+act, res);
    if(callback) await callback(res, err);
  };
  if (logMode()) console.info('apiCall_: '+act, form);
  return dispatch(APICB_every(act, warpCB, form, files, callbackWIP));
};
export const apiCall = async (dispatch, act, callback = false, form = {}, files = {}, callbackWIP = false) => {
  const warpCB = async (res, err) => {
    if(err) console.error('apiCallLoad: '+ act, JSON.stringify(err));
    try{
      if(callback) await callback(res, err);
    }catch(e){ console.error(e); };
  };
  return dispatch(APICB_every(act, warpCB, form, files, callbackWIP));
  //return _httpCall(dispatch, 'post', '/api'+act, act, callback, form, files, callbackWIP);
};

//export const restCall = async (dispatch, method, path, act, callback = false, form = {}, files = {}, callbackWIP = false) => {
//  return _httpCall(dispatch, 'post', '/api/', act, callback, form, files, callbackWIP);
//};

export const _httpCall = async (dispatch, method, path, act, callback = false, form = {}, files = {}, callbackWIP = false) => {
  if(Object.keys(files).length !== 0){
      throw(new Error("files should load into form array as base64 string, for AWS API CALL!!!"));
    }
    const _callback = (response, err) => {
      if(err){
        //RDX.SetMajorError(dispatch, err); //bug dead loop when api call err!!!!!
      } 
      const data = response;
      if(callback){
        callback(response, err); //CallBack Directly
        //RDX.Callback(dispatch, () => {callback(response, err)}); //CallBack like use RDX, like SetState
      }
      if(data){
        if(data.sessionId)
            libSessionId.save(data.sessionId);
        //libSessionId.save("my_hacking_session_id_0123456789ABCD");
        //RDX.SetUid(dispatch, data.uid);
        //if(data.user) RDX.SetUser(dispatch, data.user);
      }
    }
    var access_token = '';
    var id_token = '';
    try{
      const mwUser = await mw.getCurrentUser();
      if(mwUser){
        id_token = mwUser.id_token;
        access_token = mwUser.access_token;
      }
    }catch(e){ console.error(e); }
    const rng = 'rng_'+ convert.toInt(Math.random()*999999);
    //RDX.Ajax(dispatch, apiUrl, true, method, {...form, act}, files, _callback);
    const sessionId = libSessionId.load();
    const extraHeaders = sessionId?{Authorization: 'Bearer '+sessionId}:{};
    RDX.Aws(dispatch, path, true, method, reorder({id_token, access_token, ...form, act, rng, sessionId}), files, _callback, callbackWIP, extraHeaders);
};

const reorder = (f) => {
  const { id_token, access_token, act, rng, sessionId, ...remain } = f;
  return { ...remain, sessionId, act, rng, id_token, access_token};
};

/*
https://dev-bucket-lambda.s3.us-east-2.amazonaws.com/testdir/testfile.txt?
response-content-disposition=attachment
&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAEaDmFwLXNvdXRoZWFzdC0xIkYwRAIgXIDUecTuHNq6jIsVpxKu8AnnR2Tbvzg2ZyWvunbB5bQCIG%2FMJJdm6Mf8M4nTRmboLSTl3yxdKoaicSp4G2mcREfCKo0CCEoQABoMMzA3MTQ3MzQ0MDczIgykNUn0Ra3u5kRNRLEq6gEuZz6Y14WcbJL4BWLAmFeW8sNPqHwVVVNMk3sNH98g4sfBOHQyla7n9njkrODT%2FJADstIS%2BDJZBdCM99y4pUSe5xxD%2Bz%2F7fhv8NkSOI9RBpsnCaCIbWk%2F0wYF0epNsGEFR%2BRcQ67zv2%2FRXzq%2FGSEf93x0jxgXNbxE4doSdQA%2FyJRyEDWrmweRzzqH5ZCAQmBvQvfNjP8ESso1%2FwRpx5WMSfb2PYqkBbINRnDTTbgBQD0FEbVRo%2F1m3TYg2DX7VoXfVGhNNtBMd7se6hXvkMZZfz5b6uqfiZuVTX9F%2F%2BzF1dw7cYPEbAndf6QEwv82f7wU6gwOMRzXiN3RaicEltkGts3tfB4xrgKbjyU4rkPrHPvdyOxbg3rQUriufz8ukPQvTIkzF5KWA0o8pJOx6oZ2TqNsmjstCTNUftza4HSRX4nP02yr5LmNINm8I0qa6IaVs%2B%2BucD7meQQkbiRZJte1yhZ1K%2FDX6Yw7L33hiEQ0Dx35p2gVbroPQxqtz9icGnm9LPsvo%2BpsSAOXWttyjG0ULxiNorkwYKf0LXR%2FxdK3Z0dpkKpD%2BxKfZ6LD8Fcwl1Xbn3UBIEf26U3sTvYZloSMKU%2FdB6YD4sy8l7SN6%2FIwHkACp9cWqBC%2FTaE9ow2VYT5mMrX5t9ZUTCXnOFlrxxwAUcT6R10kiTMThDJgv89tbzJlleNvO3%2FXU4eG%2BUh9Qowc6dQmWq8EB4b878g9ovSgrbR80T9vji9PPcvYbuowJ1Tp%2FHpGRInz2pFBoCvpe6nAXR5J%2FpOGpnNTozqSEwmbdT1RQ3P2OkbR%2FZCgC0GmWW0VpE8tUiuToaVfEHSKqyNzAwTnrkQQ%3D
&X-Amz-Algorithm=AWS4-HMAC-SHA256
&X-Amz-Date=20191204T171703Z
&X-Amz-SignedHeaders=host
&X-Amz-Expires=300
&X-Amz-Credential=ASIAUPA3IRDEVUTGTYXI%2F20191204%2Fus-east-2%2Fs3%2Faws4_request
&X-Amz-Signature=8050df085d7b3079b16621533bbf35197de672622b638854eac7cb5feb99b8b3
*/
export const s3DownloadPrivate = async(dispatch, upFile, formFields) => {
  //debugLog('s3DownloadPrivate()', upFile);
  return new Promise((resolve, reject) => {
      const apiCB = async (response, err) => {
          if((!err) && (response) && (response.body)){ 
              //debugLog("s3DownloadPrivate s3", {response});
              resolve (response.body);
          }else{
              //debugLog("s3DownloadPrivate CB err", {response, err});
              reject(err || response);
          } 
      };
      apiCallLoad(dispatch, '/download', apiCB, formFields);
  });
};

export const s3UploadPrivate = async(dispatch, upFile, fromFields) => {
  //debugLog('s3UpLoadPrivate()', upFile);
  return new Promise((resolve, reject) => {
      const apiCB = async (response, err) => {
          const {url, fields} = response.postUrl;
          //debugLog("S3UpPrivate s3", {url, fields});
          const result = await HttpPostMP(url, false, fields, {file:upFile});
          //debugLog("S3UpPrivate CB result", {result});
          resolve (result); 
      };
      apiCallLoad(dispatch, '/upload', apiCB, fromFields);
  });
};

