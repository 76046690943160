import { forwardRef, MouseEvent, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useWindowSize } from "../../poc/hooks/useWindowSize";
import { Offcanvas } from "react-bootstrap";

const CpPopoverComponent = (props, ref) => { // base on poc PopoverComponent
    const {
        children,
        scroll = false,
        backdrop = true,
        title = "",
        content = "",
        fixedPosition = false,
        placement = "bottom",
        vis=0, setVis=()=>{},
        exClass="",
        setBottomMargin=0,
        ...offcanvasProps
    } = props;
    //const [showOffcanvas, setShowOffCanvas] = useState(false);
    const [afterInit, setAfterInit] = useState(0);

    const target = useRef(null);
    const mainConatiner = document.getElementsByClassName("main-container")[0];
    const windowSize = useWindowSize();

    const handleClose = () => { setVis(0); }; //setShowOffCanvas(false);

    const handleShowEvent = (e) => {
        e.preventDefault()
        e.stopPropagation()
        //setShowOffCanvas(prev => !prev)
        setVis(prev => !prev);
    };

    const expandBackdropSpace = (selector) => {
        const height = selector.clientHeight;
        if (mainConatiner) {
            mainConatiner.style.paddingBottom = `${height - 16 * 4}px` //-4rem
        };
    };

    const collapseBackdropSpace = () => {
        if (mainConatiner) {
            mainConatiner.style.removeProperty('padding-bottom');
        };
    };

    useEffect(()=>{setAfterInit(1)},[]);

    useEffect(() => {
        if (windowSize.width) {
            if (vis) { setVis(1) } else { setVis(0); };
        };
    }, [windowSize]);

    useEffect(()=>{
        let resize_ob;
        if (vis && afterInit && setBottomMargin) {
            const ele = document.getElementsByClassName(exClass)[0];
            //const ele = document.getElementById(id);
            if (ele) {
                resize_ob = new ResizeObserver(()=>{
                    const hh = ele.getBoundingClientRect().height;
                    console.log('ob setBottomMargin: ', hh);
                    setBottomMargin && setBottomMargin(hh);
                });
                resize_ob.observe(ele);
            } else {
                console.log('ele not found ', exClass);
            };
        };
        return () => {
            //setBottomMargin && setBottomMargin(0);
            resize_ob && resize_ob.disconnect();
        };        
    }, [vis, afterInit]);

    useImperativeHandle(ref, () => ({ handleShowEvent }));
    return <>
        <div ref={target} onClick={handleShowEvent}>{children}</div>
        <Offcanvas show={vis} onHide={handleClose} placement={placement} scroll={scroll} backdrop={backdrop}
                   onEntered={expandBackdropSpace} onExit={collapseBackdropSpace}
                   className={"popover-component h-fit-content " + exClass} {...offcanvasProps}>
            {title !== "" && <Offcanvas.Header closeButton className={"fs-8 py-1"}>
                <Offcanvas.Title as={"h6"}>
                    <span className={"offcanvas-title font-weight-bold"}>{title}</span>
                </Offcanvas.Title>
            </Offcanvas.Header>}
            <Offcanvas.Body as={"div"} className={"flex-wrap"}>
                {content}
            </Offcanvas.Body>
        </Offcanvas>
    </>
};

export default forwardRef(CpPopoverComponent);
