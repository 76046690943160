import {useMemo, useState} from "react";

import ActionButtonComponent from "./CpActionButtonComponent";
import CpIco from "./CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { isEn2Lang, useCtnLang, useUILang } from "../../AppExPf/utils/useUILang";
import { SIZE } from "./CpExPFConst";
import BadgeComponent from "./CpBadgeComponent";
import { LayerMetadataEditor } from "../EPExercise/CpMetadataEditorCTA";
import { objEntries, objKeys, strCmp, toAry, toObj, toStr } from "../../libs/libType";
import { stopEvent } from "../../libs/libUI";
import { QListMSTs } from "../../AppExPf/components/CpQViewExt";
import { mapTMSets, } from "../../AppExPf/ATQtnEdit/PageATQtns";
import { preJS, } from "../../AppExPf/AppUtil";
import { rptMName } from "../EPReports/data/dataUtil";
import CpMJX from "./CpMJX";
import { splitSMId, } from "../../consts/ATValidateMetaSet";
import { toUniIdAry } from "../../consts/ATValidate";

const CpMetadataModalComponent = (props => { // base on poc  MetadataModalComponent.tsx
    const {fullQ, setFullQ, tagMSets, useTagMSets} = props;
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(isEn2Lang(props.showEn));

    const [vis, setVis] = useState(false);
    const clickVis = e => { stopEvent(e); setVis(1); };
        
    const {buttonVariant = "at-primary", buttonSize = SIZE.XS, hiddenMetadata = false, customButton = ""} = props;

    const saveMetaDs = (sj, ms) => { setFullQ('QMSubjId', sj); setFullQ('QDisplayMetas', ms); };

    return <>
        {preJS({showEn})}
        <div className={`d-flex justify-content-center`}>
            {customButton
                ?<span onClick={clickVis}>{customButton}</span>
                :<ActionButtonComponent variant={buttonVariant} className={"semi-bold"} gap={0} title={t('add-metadata')}
                    icon={<CpIco src={IconList.general.add}/>} size={buttonSize} onClick={clickVis}/>}
        </div>
        <div className={`${hiddenMetadata? "d-none" : "d-flex"} flex-row flex-wrap gap-1 ${1? "mt-3" : ""}`}>
                <CpQTagBadges {...{showEn, ct, fullQ, tagMSets, useTagMSets, onClick:clickVis}} />
        </div>

        {vis? <LayerMetadataEditor {...{vis, showEn, ct, setVis, 
            multiJ:1, tagMSets, useTagMSets, metaDs: fullQ.QDisplayMetas, saveMetaDs, 
            buttonVariant, variant:"exercise-third-btn"}} />
        :''}
    </>;
});
export default CpMetadataModalComponent;

const sortm = ([i,a],[j,b]) => (a.rnum - b.rnum) || strCmp(i, j);

const CpQTagBadges = (props => {
    const {ct, fullQ, tagMSets, useTagMSets, onClick} = props;
    const mtitle = m => m? <CpMJX>{rptMName(m, ct, '...')}</CpMJX>: '';

    const QSMIds = fullQ?.QDisplayMetas;
    const SMs = useMemo(() => toUniIdAry(QSMIds).map(splitSMId), [QSMIds])
    const MSIds = useMemo(() => toUniIdAry(SMs.map( sm => sm[0])), [SMs]);
    const tagmss = useTagMSets(MSIds);
    const [typeTags, SMId2d] = useMemo(() => mapTMSets(SMs, tagmss), [SMs, tagMSets]); 
    //const [typeTags] = touseTagMSets(useTagMSets, toAry(fullQ?.QDisplayMetas).map(splitSMIdPairStr));

    const mtitle5 = useMemo(() => {
        const mts = [].concat(...QListMSTs.map(t => {
            const mss = typeTags[t];
            //const tmsets = >>useTagMSets(((objKeys(mss));
            const m2d = objEntries(mss).map(([msid, mids]) => {
                const mms = toObj(tagMSets[msid]?.metas);
                const sorted = toAry(mids).map(mid => [mid, toObj(mms[mid])] ).filter(([i, m]) => m?.pub)
                    .sort(sortm)
                    .map(([i, m]) => [msid+','+i , mtitle(m)]);
                return sorted;
            });

            return [].concat(...m2d);
        }));

        return (mts.length < 6)? mts: [...mts.slice(0, 5), ['...','...']];
    }, [typeTags]);

    return <>{mtitle5.map(([k, t]) => {
        return <BadgeComponent key={k} {...{className:"text-badge", variant:"color-light-badge", title:t, onClick}} />
    })}</>;
});

