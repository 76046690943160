import { isAry, isObj, toAry, toInt, toObj, toStr } from "../libs/libType";
import { _MaxLenIds, _MaxLenQId, _MinLenQId } from "./ATConsts";
import { __MCAT_ATG, __MCAT_ATI } from "./ATQtnAnsTypeMCQ";
import { _ATSysQTypeCodes, _ATSysQTypeGroup, __SYSQSubType_FIB, __SYSQSubType_LBD, __SYSQSubType_LBT, __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_OED, __SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_POL } from "./ATSysQType";
import { splittrimjoin2, toIdStr, toUniIdAry, toUniIdsStr, validFields, validIdCharsMinMax, validMinMax } from "./ATValidate";
import { __FIBS_PBK } from "./ATQtnAnsTypeFIB";
import { normalizeQBody, validEditATQBody } from "./ATValidateQBody";

const validQId = str => validIdCharsMinMax(toStr(str), _MinLenQId , _MaxLenQId, 'Question ID');

const normalizeQSubj = (inp, atsids) => {
    if(!isAry(inp)) return [];
    const inpT = inp.map(i => toStr(i).trim()).filter(i => i);
    return isAry(atsids)? (inpT.filter(i => atsids.includes(i))): inpT;
};
const normalizedSQT = (t, SQTCs) => {
    const tts = toStr(t).trim();
    return (isAry(SQTCs) && SQTCs.includes(tts))? tts: ((SQTCs.length && SQTCs[0]) || tts);
};

const validMDisplays = (canShow, metas) => canShow? toUniIdAry(metas).filter(m => canShow.includes(m)): metas;

const normalizeMetaIds = MetaIDStr => toUniIdAry(toStr(MetaIDStr).split(';').map(splittrimjoin2));
const unionIds = (aIds, bIds) => {
    const ary = toUniIdAry(aIds);
    return toUniIdAry(bIds).filter(i => ary.includes(i)); 
};

export const normalizedEditATQtnV2 = (fields, opts, trace) => {
    //console.log({opts});
    const track = o => trace && trace.push(o);
    
    track('-- normalizedEditATQtnV2 --');

    const fs = toObj(fields);
    track({fs});

    const atsids = isAry(opts?.ATSubjects)? (opts.ATSubjects.map(s => s.ATSubjectId)): [];
    track({atsids});
    const QATSubjIds = normalizeQSubj(fs.QATSubjIds, atsids);
    track({QATSubjIds});

    const SQTCs = _ATSysQTypeCodes();
    track({SQTCs});
    const SQType = normalizedSQT(fs.SQType, SQTCs);
    track({SQType});

    const Q = toObj(fs.Q); //todo
    const [QLangEn, QLangCt] = [fs.QLangEn?1:0, fs.QLangCt?1:0, ];

    let SQScore = toInt(fs.score);
    if ([__SYSQSubType_MCS, __SYSQSubType_MCT].includes(SQType) && (SQScore < 1)) SQScore = 1;
    if (SQType===__SYSQSubType_POL) SQScore = 0;
    if ([__SYSQSubType_FIB, __SYSQSubType_LBD, __SYSQSubType_LBT].includes(SQType) && (fs?.scoreMethod === __FIBS_PBK)) {
        const tmpQ = toObj(QLangEn?(fs.QEn):(fs.QCt)); // just calcuate from QEn or QCt, will validate by validateQBody
        SQScore = ansSumScore(toAry(tmpQ?.qAnswers));        
    };
    
    const qByThr = toIdStr(fs.schoolId) && toIdStr(fs.userId);

    const QMetaIDStr = qByThr? '': toStr(fs.QMetaIDStr).trim();
    const QMetaPreIDStr = qByThr? '': toStr(fs.QMetaPreIDStr).trim();  
    const QMetaProIDStr = qByThr? '': toStr(fs.QMetaProIDStr).trim();  

    const fullQ = {
        //...fs,
        //...fields,
        QLangEn,
        QLangCt,

        QId: toStr(fs.QId).trim(),
        SQType,
        SQTGroup: _ATSysQTypeGroup(SQType),

        QATSubjIds,
        
        QModeSlide: fs.QModeSlide?1:0,
        QModeScroll: fs.QModeScroll?1:0,
        
        QMetaIDStr, QMetaPreIDStr, QMetaProIDStr,  

        QDisplayMetas:  validMDisplays(opts.mDisp?.draftMetaCanShows, toUniIdAry(fs.QDisplayMetas)), 
        QMetaPres: qByThr? []: unionIds(normalizeMetaIds(QMetaPreIDStr), validMDisplays(opts.mPre?.draftMetaCanShows, (toUniIdAry(fs.QMetaPres)))), 
        QMetaPros: qByThr? []: unionIds(normalizeMetaIds(QMetaProIDStr), validMDisplays(opts.mPro?.draftMetaCanShows, (toUniIdAry(fs.QMetaPros)))),

        QMSubjId: toIdStr(fs.QMSubjId),
        //QFilterMetas: toUniIdAry(fs.QFilterMetas), 

        score: SQScore, 

        autoMark: toAutoMark(SQType, (fs.autoMark?1:0)),
        caseSensitive: fs.caseSensitive?1:0,
        scoreMethod: toStr(fs.scoreMethod),
        
        multiAnswer: fs.multiAnswer?1:0,
        shuffleAnswer: fs.shuffleAnswer?1:0,
        allowTeaCopyEdit: fs.allowTeaCopyEdit?1:0,
        ansChoice: toStr(fs.ansChoice),

        correctness: fs.correctness?1:0,
        respFile: fs.respFile?1:0,
        respImage: fs.respImage?1:0,
        respDrawing: fs.respDrawing?1:0,
        respURL: fs.respURL?1:0,
        respText: fs.respText?1:0,
        maxFile: toInt(fs.maxFile, 1), 
        wordLimit: toInt(fs.wordLimit, 1),

        userId: toIdStr(fs.userId),
        schoolId: toIdStr(fs.schoolId),

        isEdited: 1,
        isNew: fs.isNew?1:0,
        isCopy: fs.isCopy?1:0, 
        isAT: fs.isAT?1:0, 
        isImp: fs.isImp?1:0,
        cpQId: toIdStr(fs.cpQId),
    };
        if(QLangEn) fullQ.QEn = normalizeQBody(fullQ, toObj(fs.QEn||Q), opts); //todo
        if(QLangCt) fullQ.QCt = normalizeQBody(fullQ, toObj(fs.QCt||Q), opts); //todo
    return fullQ;
};

export const validEditATQtnV2 = (fs, opts, trace) => {
    const track = o => trace && trace.push(o);
    track('-- validEditATQtnV2 --');

    const _ATSCs = _ATSysQTypeCodes();
    track({_ATSCs});

    const errSQT = t => isAry(_ATSCs) && (!_ATSCs.includes(t));

    const v = validFields(fs, {});

    const isAT = fs?.isAT || opts?.isAT;
    const doPub = fs?.doPub || opts?.doPub;
        
    //console.log(validEditATQtnV2, fs );
    v.setIf('QLang', (!(fs.QLangCt || fs.QLangEn)) && 'Missing Language');
    
    //error.error_at_invalid_total_marks
    if (fs.SQType!==__SYSQSubType_POL) {
        //v.setIf('score', (fs.score < 1) && 'Score must be positive');
    };

    if(isAT){
        v.setIf('QId', validIdCharsMinMax(toStr(fs.QId), _MinLenQId , _MaxLenQId, 'Question ID')?
            'error.error_question_id_50_limit':'');
        v.setIf('QATSubjIds', (!(fs.QATSubjIds?.length)) && 'Missing Subject');

        if(doPub) v.setIf('QMetaIDStr', validMinMax(fs.QMetaIDStr, 1 , _MaxLenIds, 'Metadata Codes'));
        //v.setIf('QMetaPreIDStr', validMinMax(fs.QMetaPreIDStr, 0 , _MaxLenIds, 'Pre Metadata Codes'));
        //v.setIf('QMetaProIDStr', validMinMax(fs.QMetaProIDStr, 0 , _MaxLenIds, 'Pro Metadata Codes'));
    }

    v.setIf('SQType', errSQT(fs.SQType) && 'Invalid System Question Type');

    v.setIf('QMode', (!(fs.QModeSlide || fs.QModeScroll)) && 'Missing exercise mode'); 

    
    //QDisplayMetas: toUniIdAry(fs.QDisplayMetas), 
    //QFilterMetas: toUniIdAry(fs.QFilterMetas), 

    validEditATQBody(fs, opts, track, v);

    track('-- validEditATQtnV2 end --');
    return v.fieldErrs;
};

// polling is auto mark
const autoMQTs = [__SYSQSubType_MCS, __SYSQSubType_MCT, 
    __SYSQSubType_LBT, __SYSQSubType_LBD, __SYSQSubType_POL];
export const toAutoMark = (qtype, autom) => {
    return (__SYSQSubType_FIB === qtype) ? (autom? 1: 0) //fib > baseon AutoMark Field
    :autoMQTs.includes(qtype)? 1 // types must auto
    :0; //other types must hand
};
export const qAutoMark = fulQ => toAutoMark(fulQ.SQType, fulQ.autoMark);

export const toCorrectness = (qtype, corr) => {
    return (([__SYSQSubType_OED, __SYSQSubType_OEG, __SYSQSubType_OEE].includes(qtype) && (corr))
        || !([__SYSQSubType_OED, __SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_POL].includes(qtype))
        )?1:0;
};

export const qHasCorrect = qtn => {
    return (([__SYSQSubType_OED, __SYSQSubType_OEG, __SYSQSubType_OEE].includes(qtn?.SQType) && (qtn?.correctness))
        || !([__SYSQSubType_OED, __SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_POL].includes(qtn?.SQType))
        )?1:0;
};

export const ansSumScore = ansArr => {
    let total = 0;
    ansArr.forEach(ans => { total += toInt(ans?.score || 1); });
    return total;
};
