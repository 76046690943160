
/*
Keep trace of 'Loading'(i.e: awaiting API call return)
Tracking/Changing "Loading states", [redux] Only, does not need [Saga]

actions:
    loading_set('login', 1); // before calling login api, any value 
    loading_unset('login'); // after login api return, (re-enable login buttion)

application:
    display 'loading UI' according to what is Loading 
    e.g show loading indicator (when loading 'contnet')
    e.g enable/disable login button (when loading 'login')

state: examples:
{ loading:0, list:{} } // not loading anything
{ loading:3, list:{ putTodo_id1:1, getTodo_id2:1, getTodos:1} } // 3 loading, queued or parallel
*/

//import { takeEvery } from 'redux-saga/effects'

// ==== ==== Types; Type of Dispatch/Put Actions ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== 
const LOADING_SET = 'LOADING_SET'; //command
const LOADING_ADD = 'LOADING_ADD'; //command
const LOADING_UNSET = 'LOADING_UNSET'; //command

// ==== ==== reducer; to update State per action ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
const initState = {count:0, list:{}}; // 'Loading' list
export const loadingReducer = (state = initState, action) => {
    switch (action.type) {
        case LOADING_SET:{
            //lj({LOADING_SET:action});
            if(state.list[action.key] === action.value) 
                return state;
            const list = { ...state.list, [action.key]:action.value };
            return { count: Object.keys(list).length, list};
        }
        case LOADING_UNSET:{
            //lj({LOADING_UNSET:action});
            if(Object.keys(state.list).indexOf(action.key) < 0) 
                return state;
            const list = {...state.list};
            delete list[action.key];
            return { count: (state.count-1), list };
        }
        case LOADING_ADD:{
            //lj({LOADING_ADD:action});
            const v = action.value + (state.list[action.key]||0);
            const list = { ...state.list, [action.key]:v }; 
            if(!v) delete list[action.key];
            return { count: Object.keys(list).length, list};
        }
        default:
            return state;
    }
};

// ==== ==== action; to be dispatch() By UI / put() by Services ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const loading_add = (key, value) => { return {type: LOADING_ADD, key, value}; };
export const loading_set = (key, value) => { return {type: LOADING_SET, key, value}; };
export const loading_unset = (key) => { return {type: LOADING_UNSET, key}; };

// ==== ==== saga; watcher saga watch for action / action saga call() service, put() action= === ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
// export function* watchAuthSaga() {
//     yield takeEvery(LOAD_SET, loadSetSaga);
//     yield takeEvery(LOAD_UNSET, loadUnsetSaga);
// };
// export function* loadSetSaga(action) {};
// export function* loadUnsetSaga(action) {};

// ==== ==== supporting services: to be call by saga ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====






