import React, { useEffect, useState, useRef } from 'react';

import { apiCallLoad_, asyncApiCallLoad_ } from '../../libs/awsFuncs';
import { errMsg } from '../../libs/libFormat';
import { isObj, toAry, toObj, toStr} from '../../libs/libType';
import * as UI from '../../libs/libUI';

import { ReduxBind } from '../../saga/ReduxState';
import { aTErrDiv1, ATErrsDiv2, aTErrTxt1, ATUICheckBox0, aTUIMust, autoId, BtnPopDev, clickConfirm, clickUrl, deepCopy, PopDL, preJS, } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';
import { langCodeEn, langCodeCt, QP_P, QP_D } from '../../consts/ATConsts'
import * as SQT from '../../consts/ATSysQType';
import { urlPush_Replace } from '../../saga/urlPush.saga';
import { popAlert, popConfirm } from '../components/CpPopup';
import { normalizedEditATQtnV2, validEditATQtnV2 } from '../../consts/ATValidateQ';
//import TabQMC from './TabQMC';
import { cpATIcoBtn_ } from '../components/CpATIcoBtn';
import CpTabHead from '../components/CpTabHead';
import { ATDo_DelQDraft, ATDo_QClone, ATDo_QDelUnPub, ATDo_QDraftR2P, ATDo_QPub, ATDo_QUnpub, _ATCanDo } from '../../consts/ATRoleRights';
import CpDraftPubBtns, { PubDraftStates } from '../components/CpDraftPubBtns';
import { hasErr, normalizeEdit, useEditDraftPub, useLangView } from '../utils/useEditDraftPub';
import { timeStampNowGMT } from '../../libs/libTime';
import { draw2Blob, localDrawId, useMediaCache } from '../utils/useMediaCache';
import TabGeneral from './TabQtnGeneral';
import TabMeta from './TabQtnMeta';
import TabQtnEdit from './TabQtnEdit';
import { subState } from '../utils/useChain';
import { splittrimjoin2, toIdStr, toUniIdAry } from '../../consts/ATValidate';
import { QImportCSV } from '../ATQImport/utilQImport';
import CpATUploadButton from '../components/CpATUploadBtn';
import LayerMediaPool from '../ATMediaPool/LayerMediaPool';
import { LayerLocalMedia } from '../ATMediaPool/LayerLocalMedia';
import { mRows2CanShows, mSets2Rows } from '../components/CpMetaTickList';
import { addMath3StrToAns, addMathMaxAnsWidth } from '../utils/utilQtnAns';
import { useUILang } from '../utils/useUILang';
import { str2Blob } from '../../libs/libType';
import { upLocalMedias } from '../../AppExPFUser/EPExercise/LayerEMediaUp';
import { packQtn, unpackQtn } from '../../consts/wirisConfig';
import { qtnMapMedias } from '../../consts/qtnMedias';
import LayerUpMedia from '../../AppExPFUser/EPExercise/LayerEMediaUp';
import { splitSMId } from '../../consts/ATValidateMetaSet';

const backPath = _ATRoot + 'questions';

export const loadOptsDrafMSet = draftMetaSets => {
  const draftMSetRows = mSets2Rows(draftMetaSets);
  const draftMetaCanShows = mRows2CanShows(draftMSetRows); 
  return {draftMSetRows, draftMetaCanShows};  
};


const PageATQtnEdit = ReduxBind((props) => {
  const { dispatch } = props;
  const [ t ] = useUILang();
  const role = toStr(props?._saga?.auth?.ATUser?.ATRole);

  const [fields, setFields, setField,  setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts, draft, setDraft, pub, setPub] = useEditDraftPub();
  const setQSId = setTickAry('QATSubjIds');
  const setDisplay = setTickAry('QDisplayMetas');
  const setMetaPre = setTickAry('QMetaPres');
  const setMetaPro = setTickAry('QMetaPros');
  const setFilter = setTickAry('QFilterMetas');

  const params = toObj(props?.match?.params);
  const QId = toIdStr( ((pub || draft)?.QId) || params.QId);
  const impEdit = 0;//params.import? 1: 0;
  const isNew = (impEdit || draft?.isNew || params.isNew || (!QId))? 1 : 0;
  useEffect(() => { (!impEdit) && QId && dispatch(urlPush_Replace( _ATRoot + 'question/edit/'+QId)) }, [QId]);

  const isAT = 1;
  const LayerMedia = isAT? LayerMediaPool :LayerLocalMedia;
  const [onAddMedia, _setOnAddMedia] = useState(0);
  const setOnAddMedia = oam => {
    _setOnAddMedia(oam);
  };

  const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 1);
  const [LSel, setLSel] = useState(isNew && (!impEdit));
  const [tab, setTab] = useState(0);
  const [QPV, setQPV] = useState(0);

  // then Choose Pub/Draft 
  const [view, setView] = useState(QP_P);
  const {hasDraft, saved, r2p, pubed, unpubed, showPub, lock } = PubDraftStates(view, draft, pub, 'QVer'); 
  const editable = !(QPV || lock);
  const showQPrev = QPV? 1: 0;
  const qtn = showPub ? pub : fields;
  const [lang, setLang, clickLang, showEn, hasEn, hasCt, safeLang] = useLangView(qtn?.QLangEn, qtn?.QLangCt, langCodeEn);

  const [upMsg, setUpMsg] = useState('');
  const [saving, setSaving] = useState(0);

  // then Choose QBody per Lang
  const [QLang, QKey] = showEn? ['En', 'QEn']: ['Ct', 'QCt'];
 
  const Q = (qtn && qtn[QKey]) || {}; //new 
  const setQ = key => valFunc => setFields(subState(QKey, subState(key, valFunc)));
  const setFullQ = (key, valFunc) => setFields(subState(key, valFunc));
 
  const QErrs = (fieldErrs?.[QKey]) || (fieldErrs?.Q) || {};

  //Load Media
  const mediaIds = toAry(Q?.refreshMediaIdURL);
  const hits = getMediaDLs(mediaIds);
  
  const safeSetView = v => UI.stopEventThen(e => setView((!pub)? QP_D: (!hasDraft)? QP_P: v));
  const normalizeQEdit = (doPub, doR2p) => normalizeEdit(fields, {...opts, doPub, doR2p}, [], normalizedEditATQtnV2, validEditATQtnV2, setFields, setFieldErrs);
  const back = () => dispatch(urlPush_Replace(backPath));
  const onLoad = (res, err) => {
    if (err) popAlert(dispatch, 0, errMsg(err));
    const r = toObj(res);
    const { fieldErrs, ATSubjects, mySjIds, 
      draftMetaSets, draftMSetPres, draftMSetPros, 
      pubMetaSets, pubMSetPres, pubMSetPros, } = r;
    const [pub, draft] = [unpackQtn(r.pub), unpackQtn(r.draft)];
//console.log('qedi onLoad impEdit', {impEdit, fieldErrs, res, err});
    if(!impEdit){
      setDraft(draft);
      setFields(toATQDraft(draft));
      setPub(pub)
      setFieldErrs(fieldErrs);
    }
    const mDisp = loadOptsDrafMSet(draftMetaSets);
    const mPre = loadOptsDrafMSet(draftMSetPres);
    const mPro = loadOptsDrafMSet(draftMSetPros);
    const pubMSetRows = mSets2Rows(pubMetaSets);
    const pubMSetPreRows = mSets2Rows(pubMSetPres);
    const pubMSetProRows = mSets2Rows(pubMSetPros);

    setOpts({ ATSubjects, mySjIds, mDisp, mPre, mPro, pubMSetRows, pubMSetPreRows, pubMSetProRows });
    setView(v => ((!pub)? QP_D: (!draft)? QP_P: v));
  };

  const onLoadDraft = (res, err) => {
    if (err) popAlert(dispatch, 0, errMsg(err));
    if (res) {
      const d = unpackQtn(res.draft);
      setDraft(d);
      const mDisp = loadOptsDrafMSet(res.draftMetaSets);
      const mPre = loadOptsDrafMSet(res.draftMSetPres);
      const mPro = loadOptsDrafMSet(res.draftMSetPros);
      setOpts(opts => ({...opts, mDisp, mPre, mPro}));
      setFields(toATQDraft(d));
      setFieldErrs({});
      setView(d? QP_D: QP_P);
      //setMetaList(res.metaList); DummyMetas);
    }
  };
  const onDelete = (res, err) => { back(); }; 
  const onPutATQDraft = (res, err) => {
    if ((res?.state) === 'ok') {
      onLoad(res, err);
    } if ((res?.state) === 'retry') {
      const fes = res?.fieldErrs;
      if (fes) setFieldErrs(fes);
    } else {
      back();
    }
  };
  useEffect(() => { apiCallLoad_(dispatch, '/getATQtn', onLoad, { QId, isNew }); }, []);
  
  const tapShowQPreV = e => { UI.stopEvent(e); setQPV(!QPV); };
  const tabQCpLang = fromEn => UI.stopEventThen( e => {
    if(showPub) return; // only copy for draft
    popConfirm(dispatch, 0, 'Copy TO '+(fromEn?'Chinese':'English')+' ?', () => {
      if(fromEn){ fields.QCt = deepCopy(fields.QEn); }else{ fields.QEn = deepCopy(fields.QCt); } 
      clickLang(fromEn?langCodeCt:langCodeEn);
    });
  } );

  const save = async (doPub, doR2p, fields) => {
    setSaving(1);
    const draft = await preSaveQtn(dispatch, fields, getMediaDLs, addLocalMedias, setUpMsg);
    setField(draft);
    apiCallLoad_(dispatch, '/putATQtnDraft', onPutATQDraft, { fields: packQtn(draft), doPub, doR2p });
    setSaving(0);
  };
  const clickSaveATQtn = makeClickSavePub(dispatch, normalizeQEdit, save);

  const letR2P = _ATCanDo(role, ATDo_QDraftR2P); 
  const letPub = _ATCanDo(role, ATDo_QPub);
  const letUnpub = _ATCanDo(role, ATDo_QUnpub);
  const letClone = _ATCanDo(role, ATDo_QClone);
  const letDelete = (_ATCanDo(role, ATDo_DelQDraft) || _ATCanDo(role, ATDo_QDelUnPub));
  
  const clickEndQtnEd = clickUrl(dispatch, backPath);
  const clickClone = letClone && pub && UI.stopEventThen(e => apiCallLoad_(dispatch, '/getATQtnDraft', onLoadDraft, { QId }));
  const clickPublish = letPub && unpubed && clickConfirm(dispatch, 'Please confirm publish', () => apiCallLoad_(dispatch, '/putATQtnPublish', onLoad, { QId }));
  const clickSaveR2P = letR2P && hasDraft && (!r2p) &&  clickSaveATQtn(0, 1);
  const clickSavePublish = letPub && hasDraft && clickSaveATQtn(1, 0);
  const clickUnpublish = letUnpub && pubed && clickConfirm(dispatch, 'Please confirm unpublish', () => apiCallLoad_(dispatch, '/putATQtnUnpublish', onLoad, { QId }));
  const clickDelete = letDelete && hasDraft && clickConfirm(dispatch, 'Please confirm delete', () => isNew? back(): apiCallLoad_(dispatch, '/deleteATQtns', onDelete, { QId }));
  
  /*
  const clickQMetas = hasDraft && (!showPub) && ( async e => { UI.stopEvent(e);
    const [res, err] = await asyncApiCallLoad_(dispatch, '/getATQtnMetas', { pubOnly:0, QMetaIDStr: fields.QMetaIDStr,  });
    const [res, err] = await asyncApiCallLoad_(dispatch, '/getATQtnMetas', { pubOnly:0, QMetaIDStr: 'xx,;,xx; , ;,;; ms1,NCMATH2E_4A_1_1.2;ms1,NCMATH2E_4A_1_1.3_C;'+
    //  'ms1,NCMATH2E_4A_2_2.1_C;ms2,SEC_KS3_SenSec_S6;'+ 'ms2,PRI_KS1_JunPri_P3;ms1,SEC_KS3_JunSec_S3', });
      if (err) console.error(err);
      const loadmset = loadOptsDrafMSet(res?.draftMetaSets);
      const oCanSee = toAry(opts.draftMetaCanShows);
      const nCanSee = toAry(loadmset.draftMetaCanShows);  
      const adds = nCanSee.filter(c => !oCanSee.includes(c));
      setFields(subState('QDisplayMetas', od => [...toAry(od).filter(o => nCanSee.includes(o)), ...adds] ));
      setOpts(opts => ({ ...opts, ...loadmset}));
  });
  */
  const _mkClickQMetas = (QMetaIDStr, mKey, optKey, autoTag) => hasDraft && (!showPub) && ( async e => { UI.stopEvent(e);
    const [res, err] = await asyncApiCallLoad_(dispatch, '/getATQtnMetas', { pubOnly: 0, QMetaIDStr });
    if (err) console.error(err);
    const loadmset = loadOptsDrafMSet(res?.draftMetaSets); //draftMSetRows: {…}, draftMetaCanShows: (9) […] }
console.log({autoTag, QMetaIDStr, mKey, optKey, loadmset});

    const oCanSee = toAry(opts[optKey]?.draftMetaCanShows);
    const nCanSee = toAry(loadmset.draftMetaCanShows);  
    const adds = nCanSee.filter(c => !oCanSee.includes(c));
    const strIds = toUniIdAry(toStr(QMetaIDStr).split(';').map(splittrimjoin2));
    console.log({strIds, adds, QMetaIDStr, oCanSee, nCanSee});
    if(autoTag){
      setFields(subState(mKey, od => [...toAry(od).filter(o => nCanSee.includes(o)), ...adds] ));
    }else{
      setFields(subState(mKey, [...nCanSee.filter(i => strIds.includes(i))] ));
    }
    setOpts(opts => ({ ...opts, [optKey]:loadmset}));
  });
  const clickQMetas = _mkClickQMetas(fields.QMetaIDStr, 'QDisplayMetas', 'mDisp', 1);
  const clickQMetaPres = _mkClickQMetas(fields.QMetaPreIDStr, 'QMetaPres', 'mPre', 0);
  const clickQMetaPros = _mkClickQMetas(fields.QMetaProIDStr, 'QMetaPros', 'mPro', 0);
   

  const tapTab = i => UI.stopEventThen(e => setTab(i));
  const [tab0, tab1] = [tab===0, tab===1];
  const tabKey = (showPub?'Pu':'Dr')+(QPV?'Pv':'Ed')+QLang;
  
  const loadCSV = impEdit && ( async (txt, err) => {
    const [qrows, qmedias, headErrs, hcMC] = await QImportCSV(txt, fields, opts); 
    const qrow = toAry(toAry(qrows)[0]);
    const [q, qerr] = [toObj(qrow[0]), toObj(qrow[1])];
    const errs = validEditATQtnV2(q, opts, []);
    setFieldErrs({...qerr, ...errs});
    setDraft(q); 
    setFields(toATQDraft(q)); 
    setPub(q);
  });
 
  return <div className="adminQuesTop" >
    <div className="f16">Questions / Questions / {isNew ? 'New' : 'Edit'} 
      {(showPub && hasDraft) ? '': impEdit?
        <div style={{fontSize:'12px', color:'#a00'}}>{Object.entries(toObj(fieldErrs)).map(([k,v]) => <div key={k}><b>{k} : </b>{v}</div>)}</div> :
        ATErrsDiv2(fieldErrs,'',t)}
    </div>
    {LSel?<ViewSetLang {...{fields, setTick, ekey:'QLangEn', ckey:'QLangCt', setLSel, clickBack: clickEndQtnEd}} />:<>
      <div className="adminToolsContainer"><div style={{display:"flex"}}> 
        <div className="adminTools1">
        {impEdit? <CpATUploadButton text='Upload Question ID' onLoad={loadCSV} />: '' }
        {showPub ? <>
          {letPub && cpATIcoBtn_('general/publish', 'Publish', (!hasDraft) && clickPublish)}
          {letUnpub && cpATIcoBtn_('general/unpublish', 'Unpublish', clickUnpublish)}
          {letClone && cpATIcoBtn_('general/copy', 'Duplicate as Draft', clickClone)}
        </> : <>
          {cpATIcoBtn_('general/save', 'Save', clickSaveATQtn(0, 0))}
          {cpATIcoBtn_('general/cancel', 'Cancel', clickEndQtnEd)}
          {letPub && cpATIcoBtn_('general/publish', 'Save & Publish', clickSavePublish)}
          {letR2P && cpATIcoBtn_('general/request', 'Save & Request', clickSaveR2P)}
          {letDelete && cpATIcoBtn_('general/delete', 'Delete', clickDelete)}
        </>}       
        </div>
        <div className="adminTools2"><CpDraftPubBtns {...{saved, draft, showPub, pub, click:safeSetView, verKey:'QVer' }} /></div>
    </div></div>
    <div className="flexRowStart">Metadata Structure {aTUIMust}
        {hasEn? (showEn? <div className="metaLangSel">En</div>: UI.Button0('En', clickLang(langCodeEn), 'btnTLE', 'metaLangUnsel')): ''}
        {hasCt? ((!showEn)? <div className="metaLangSel">繁中</div> : UI.Button0('繁中', clickLang(langCodeCt), 'btnTLC', 'metaLangUnsel')): ''}
    </div>
    {showPub? '': aTErrTxt1(fieldErrs?.QLang, t)}
    {CpTabHead(tab, tapTab, [<>General {aTUIMust}</>, <>Metadata {aTUIMust}</>, <>Question Content {aTUIMust}</>], <>
      <BtnPopDev txt={'params'} >{preJS(props.match?.params,3)}</BtnPopDev>
      <BtnPopDev txt={'Q (Q'+QLang+')'} >{preJS(Q,3)}</BtnPopDev>
      <BtnPopDev txt='FullQ (qtn)'>{preJS(qtn,3)}</BtnPopDev>
      <BtnPopDev txt='draft'>{preJS(fields,3)}</BtnPopDev>
      <BtnPopDev txt='pub'>{preJS(pub,3)}</BtnPopDev>
      <BtnPopDev txt='fieldErrs'>{preJS(fieldErrs,3)}</BtnPopDev>
      <BtnPopDev txt='misc'>{preJS({hasDraft, impEdit, isNew, QId, showPub, showQPrev, showEn, saved, r2p, pubed, editable, QLang, QKey},3)}</BtnPopDev>
      <BtnPopDev txt='ref Media'>
        {preJS(Q.refreshMediaIdURL,3)}
        {preJS({now:timeStampNowGMT()})}
        {preJS(hits,3)}
      </BtnPopDev>
    </>)}
    {
      tab0 ?<TabGeneral key={tabKey} {...{ showPub, showQPrev, editable, showEn, lock, QLang, setField, setTick, setQSId, fields, fieldErrs, QId, opts, qtn }} />:
      tab1 ?<TabMeta key={tabKey} {...{ showPub, showQPrev, editable, showEn, lock, QLang, setField, setTick, setQSId, fields, fieldErrs, QId, opts, qtn, 
        clickQMetas, clickQMetaPres, clickQMetaPros, setDisplay, setMetaPre, setMetaPro, setFilter }} />:
      <TabQtnEdit key={tabKey} {...props} isAT={1}
        {...{ isNew, mediaDLs, getMediaDLs, setOnAddMedia, addLocalMedias,showPub, showQPrev, editable, showEn, lock, QLang, tabQCpLang, fieldErrs, pub, QId, setQ, Q, fullQ:qtn, setFullQ, QErrs, role, tapShowQPreV, }} />
    }
    </>}
    <LayerMedia clickClose={()=>setOnAddMedia(0)} {...{...onAddMedia, setOnAddMedia, mediaDLs, getMediaDLs}} />      
    {saving?<LayerUpMedia {...{head:'Saving Question', upMsg}}/>:''}
  </div>;
});
export default PageATQtnEdit;

export const clsQLang = on => on? "quesLangSel": "quesLangUnsel";

export const ViewSetLang = ({fields, setTick, ekey, ckey, setLSel, clickBack}) => {
  const [e, c] =  [fields?.[ekey], fields?.[ckey]];
  const canok = e || c;
  const clickOk = canok && ( e => {UI.stopEvent(e); canok && setLSel(0); } ); 
return <div><br/>
    <div className="atsect">
        <div className="atsecthead">Please Select Langauge(s)</div>
        <label className="w m4">{ATUICheckBox0(e, setTick(ekey), 'chkE')}En</label>
        <label className="w m4">{ATUICheckBox0(c, setTick(ckey), 'chkC')}繁中</label>
    </div>
    <div className="atsect">
      <div className="w">{UI.colorButton('btnOK', 'OK', clickOk, canok?"#0c8ce9":"#b4b4b4", undefined, {marginRight:"5px"}, '')}</div>
      <div className="w">{UI.colorButton('btnCancel', 'Cancel', clickBack, "#0c8ce9", undefined, {marginRight:"5px"}, '')}</div>
    </div>
  </div>;
};

export const makeClickSavePub = (dispatch, normalize, save) => (doPub, doR2p) => e => { UI.stopEvent(e);
  const [fields, fieldErrs] = normalize(doPub, doR2p);
  if (!hasErr(fieldErrs)){
    doPub? popConfirm(dispatch, 0, 'Please confirm publish', () => save(1, 0, fields))
    :doR2p? popConfirm(dispatch, 0, 'Please confirm Request to Publish', () => save(0, 1, fields))
    :save(0, 0, fields);
  } 
};

const toATQDraft = q => {return {...toObj(q), isAT:1}; };

// for AT one standalone question edit use, save to S3 directly
// need pass draw data as array of file (created by File, then use by URL.createObjectURL) to addLocalMedias


export const preSaveQtn = async (dispatch, qtn, getMediaDLs, addLocalMedias, setUpMsg = () => {}) => {
  const [QtoMap, qMedIds, drawBlobs] = qtnMapMedias(qtn, {}, draw2Blob, localDrawId);  // Map  local & draw to Upload
  addLocalMedias(drawBlobs);
  const mediaMap = await upLocalMedias(dispatch, [], getMediaDLs, setUpMsg, drawBlobs);

  const [Q, nilMedId, nilDrawBlobs] = qtnMapMedias(QtoMap, mediaMap, draw2Blob, localDrawId); // feed back uploaded MediaId

  if(Q.QLangEn){
    await addMath3StrToAns(Q, Q.QEn);
    addMathMaxAnsWidth(Q, Q.QEn);
  }
  if(Q.QLangCt){
    await addMath3StrToAns(Q, Q.QCt);
    addMathMaxAnsWidth(Q, Q.QCt);
  }

  return Q;
}
/*
//await saveQtnDraw(qtn, qtn?.QEn, dispatch, getMediaDLs, ()=>{});
//await saveQtnDraw(qtn, qtn?.QCt, dispatch, getMediaDLs, ()=>{});
//saveQtnDrawAsLocal(qtn, Q, _MaxMediaSize, addLocalMedias);

const saveQtnDrawAsLocal = async (fullQ, Q, _MaxMediaSize, addLocalMedias = false) => {
  //console.log('local: do draw save....');
  if (addLocalMedias && (fullQ?.SQType === SQT.__SYSQSubType_OED) && (Q?.qDrawData) && !(Q?.qDrawS3Id)) {
      //console.log('local: valid draw....');
      const drawID = localDrawId(fullQ.QId);
      const drawBlobs = { [drawID]: draw2Blob(drawID, Q?.qDrawData) };
      const mediaMap = addLocalMedias(drawBlobs, 1);

      if (Object.keys(mediaMap).length > 0) {
        Q.qDrawS3Id = Object.values(mediaMap)[0].mediaId;
        //console.log('local: draw saved:', Q.qDrawS3Id);
        // qDrawData will clear inside exer validate mapping function
        // must keep here for not yet save use
        // Q.qDrawData = '';            
    };
  } else {
      //console.log('invalid draw:', fullQ?.SQType, Q?.qDrawData, Q?.qDrawS3Id);
  };
};

// for frontend use, follow exercise progress save to media cache
const saveQtnDraw = async (fullQ, Q, dispatch, getMediaDLs, setUpMsg) => {
  //console.log('do draw save....');
  if ((fullQ?.SQType === SQT.__SYSQSubType_OED) && (Q?.qDrawData) && !(Q?.qDrawS3Id)) {
      //console.log('valid draw....');
      const drawID = localDrawId(fullQ.QId);
      const drawBlows = {[drawID]: draw2Blob(Q.qDrawData)};
      const mediaMap = await upLocalMedias(dispatch, [], getMediaDLs, setUpMsg, drawBlows);
      //console.log('mediaMap:', mediaMap);//Object.values(inputObject)[0]      
      if (Object.keys(mediaMap).length) {
          Q.qDrawS3Id = Object.values(mediaMap)[0];
          Q.qDrawData = '';            
      };
  } else {
      //console.log('invalid draw:', fullQ?.SQType, Q?.qDrawData, Q?.qDrawS3Id);
  };
};
*/

/*
import { upLocalMedias } from '../../AppExPFUser/EPExercise/LayerEMediaUp';
import { autoId } from '../AppUtil';
import { str2Blob } from '../../libs/libType';
*/

