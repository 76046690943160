import React, { useEffect, useState, useRef } from 'react';

import * as UI from '../../libs/libUI';
import { toAry, toObj, toStr } from '../../libs/libType';
import { svgIcon2, iconButton } from '../components/CpATIcoBtn';
import {BCPlayer, YTPlayer2, HTMLPlayer} from '../components/media/CpMediaPlayer';
import { subState } from '../utils/useChain';
import {_alignClass } from './ViewExOptions';
import {exerTitle, exerBlock, viewExLabel, exATUIText, exTextArea, popA, prefixURL} from './ViewExCommon';
import { useUILang } from '../utils/useUILang';

const _VPlayers = { ul:HTMLPlayer, yt:YTPlayer2, bc:BCPlayer, };
const _UIinterface = {btn:'audioButton', ply:'audioPlayer'};
const showPlayer = (type, url, ui, mini=0, idx, setPlayed = ()=>{}) => {
    const P = _VPlayers[type];
    return P?<P idx={idx} key={type} url={url} onSuccess={()=>{}} id={type+''+idx} 
        mode={_UIinterface[ui]} isAudio={true} mini={mini} setPlayed={()=>setPlayed(idx)} />:'No Audio';
};

const ViewExCtnAudio = props => {
    const {lock, showEn, ECtn, replaceECtn, setOnAddMedia, PVMode, setPlayed=()=>{},
        mediaDLs, getMediaDLs, mini=0, idx} = props;   
    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
    const ecca = toObj(ECtn?.ct?.ca);
    const ecpt = toObj(ECtn?.ct?.pt);
    const hasctca = ec.audioType==='ul'?(toStr(ECtn?.ct?.ca?.mediaId)):(toStr(ECtn?.ct?.ca?.url));
    const hasctpt = ec.audioType==='ul'?(toStr(ECtn?.ct?.pt?.mediaId)):(toStr(ECtn?.ct?.pt?.url));
    const [showCa, setShowCa] = useState(hasctca?1:0);
    const [myURL, setMyURL] = useState('');
    const myMediaID = toStr(ec.audioType==='ul'?(showEn?ec.mediaId:(showCa?(ec.ca?.mediaId):(ec.pt?.mediaId))):'');
    const lang = showEn?'en':'ct';
    
    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));
    const setCtCaTmpField = key => val => replaceECtn(subState('ct', subState('ca', subState(key,val))));
    const setCtPtTmpField = key => val => replaceECtn(subState('ct', subState('pt', subState(key,val))));    

    // for display
    const cturl = showCa?toStr(ECtn?.ct?.ca?.url):toStr(ECtn?.ct?.pt?.url);
    const cttitle = showCa?toStr(ECtn?.ct?.ca?.title):toStr(ECtn?.ct?.pt?.title);
    const ctdesc = showCa?toStr(ECtn?.ct?.ca?.desc):toStr(ECtn?.ct?.pt?.desc);

    const url = toStr(showEn?ec.url:cturl);
    const title = toStr(showEn?ec.title:cttitle);
    const desc = toStr(showEn?ec.desc:ctdesc);
    const audioType = toStr(ec.audioType);
    const audioUI = toStr(ec.audioUI) || 'ply';

    const displayTitle = ec.displayTitle?ec.displayTitle:0;  
    const edProps = {idx, mediaDLs, getMediaDLs, setOnAddMedia};

    useEffect(()=>{
        const obj2 = getMediaDLs && myMediaID && getMediaDLs([myMediaID])[myMediaID];
        const obj1 = getMediaDLs && ec.mediaId && getMediaDLs([ec.mediaId])[ec.mediaId];
        const obj = obj2 || obj1;
        if(obj) setMyURL(obj.dl);
    },[ ec.mediaId , mediaDLs, myMediaID ]);

    const langBtn = (icon, showCa, cb) => iconButton('',icon,showCa?'white':'black',showCa?'#ec8008':'grey',
        cb,true,{transform:'scale(1.2)',width:'24px',height:'24px'},{margin:'0 4px 5px 0px', padding:'2px'});     

    if(lock || PVMode >=0) {
        
        const exClass = (audioUI === 'btn'?'flexRowCenter':'flexRowStart');
        const _url = ec.audioType==='ul'?myURL:url;
        
        const CLangBtn = () => {return !showEn ? <div className='flexRowStartFit'>
            {hasctca && langBtn('language/can',showCa,()=>setShowCa(1))}
            {hasctpt && langBtn('language/pth',!showCa,()=>setShowCa(0))}</div>
            :<div></div>};

        return <div className={'ViewExCtnAudio ViewExCtnItemLock ' + (PVMode !==1? ' Single': ' List') + ' exAlignCenter'}>
            <div id={'exCtnContent' + idx} className='ViewExCtnItemLockContent'>
                {(audioUI === 'btn' && PVMode === 1)?<div className={'flexRowStart semi-bold '}>{exerTitle(title)}</div>:''}
                {(audioUI !== 'btn')? <div className='flexRowBetween'>
                    {(displayTitle && audioType !== 'ex')?<div className={'semi-bold ' + exClass} style={{width:'100%'}}>
                        {PVMode === 1? exerTitle(title): title}</div>: <div></div>}
                    {CLangBtn()}
                </div>:''}
                {audioType === 'ex'? exerBlock(<div className='flexColStart f18 '>
                    <div>{title}</div><p/>
                    <div>{popA('url' + ECtn.idx, prefixURL(_url), 'Click here to open hyperlink', () => { setPlayed(idx); })}</div>
                    <div>{desc}</div>
                </div>)
                :(audioUI !== 'btn'? <><div style={{width:'100%'}}>{_url && showPlayer(audioType,_url, audioUI, mini, idx, setPlayed)}</div>
                <div className={exClass} style={{width:'100%'}}>{PVMode >= 0? exerBlock(desc): desc}</div>
                </>: <div className='flexColCenter'>
                    <div className='flexColCenterFit' style={{width:'150px'}}>
                        <div className='flexRowStart'>{CLangBtn()}</div>
                        {_url && showPlayer(audioType,_url, audioUI, mini, idx, setPlayed)}
                    </div>
                    <div className={exClass} style={{width:'100%'}}>
                        {(PVMode >= 0 || lock)? exerBlock(<div className='flexColCenter'>
                            {(displayTitle && PVMode!==1)?<div className='semi-bold'>{title}</div>:''}
                            <div>{desc}</div></div>):desc}
                    </div>
                </div>)}
            </div>
        </div>;
    };

    const getMediaFromMD = (setData) => {
        if (setOnAddMedia) {
            setOnAddMedia({onAdd:(medias => {
                
                const ms = toAry(medias);
                if (ms.length > 0) {
                    const src = toStr(ms[0].dl);
                    const mediaId = toStr(ms[0].mediaId);
                    setData(mediaId, src);
                };
                setOnAddMedia(0); //stop receiveMedia
            }),maxMedia:1, mimes:['audio']});
        };        
    };    

    return <div className='ViewExCtnItem'>
        {lang==='en'?<AudioInput setTmpField={setTmpField} getMediaFromMD={getMediaFromMD} ec={ec} 
        audioType={audioType} audioUI={audioUI} showEn={showEn} {...edProps}/>
        :<><AudioInput setTmpField={setCtCaTmpField} getMediaFromMD={getMediaFromMD} ec={ecca}
        audioType={audioType} audioUI={audioUI} isCan={true} {...edProps}/>
        <AudioInput setTmpField={setCtPtTmpField} getMediaFromMD={getMediaFromMD} ec={ecpt} 
        audioType={audioType} audioUI={audioUI} isCan={false} {...edProps}/></>}
        </div>
};
export default ViewExCtnAudio;

const langIcon = (icon) => <div style={{backgroundColor:'#2995cd',borderRadius:'4px', margin:'5px 8px',
    width:'20px', height:'20px'}}>{svgIcon2(icon,'white',{width:'20px',height:'20px'},'white')}</div>;

const AudioInput = (props) => {
    const {idx, setPlayed, showEn, ec, setTmpField,audioType,audioUI,isCan,getMediaFromMD,mediaDLs, getMediaDLs} = props;
    const [ t ] = useUILang();
    const [myURL, setMyURL] = useState('');
    const [myMediaInfo, setMyMediaInfo] = useState({});
    const url = audioType === 'ul'? myURL: toStr(ec.url);
    const myMediaID = toStr(ec.mediaId);
    
    useEffect(()=>{
        if (ec.mediaId !== '' && getMediaDLs) {
            const obj = getMediaDLs([ec.mediaId]);
            if (obj && obj[ec.mediaId]) {
                setMyURL(obj[ec.mediaId].dl);
                setMyMediaInfo({name:obj[ec.mediaId].name, ext:obj[ec.mediaId].ext});
            };
        };
    },[ec.mediaId]);

    useEffect(()=>{ 
        if (mediaDLs && mediaDLs[myMediaID]) {            
            setMyURL(mediaDLs[myMediaID].dl);
            setMyMediaInfo({name:mediaDLs[myMediaID].name, ext:mediaDLs[myMediaID].ext});
        };
    },[mediaDLs]);    

    const setAudioData = (mediaId, src) => {
        setTmpField('mediaId')(null);
        setTimeout(()=>{setTmpField('mediaId')(mediaId);},10);
    };    

    return <><p/>
        {!showEn && <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.audio.language'),'lang')}{langIcon(isCan?'language/can':'language/pth')}
            {isCan?t('exercise.content.language.can'):t('exercise.content.language.pth')}
        </div>}
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.audio.caption'),'title')}{exATUIText(ec.title, setTmpField('title'), 'vid')}
        </div>
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.audio.desc'),'desc')}{exTextArea(ec.desc, setTmpField('desc'), 'desc')}
        </div>
        <div className='flexRowStart'>
            {viewExLabel(audioType==='ul'? t('exercise.content.audio.file'):t('exercise.content.audio.url'),'url')}
            {audioType==='ul'? <div className='flexRowBetween'>
                <div className='flexRowStart viewExInput' style={{minHeight: '35px', flex:1}}>{myMediaInfo.name}</div>
                {UI.colorButton('browse','Browse',()=>getMediaFromMD(setAudioData),
                    '#2995cd','80px',{marginLeft:'10px', color:'white'})}
            </div>
            :exATUIText(url, setTmpField('url'), 'url')}
        </div>
        {audioType === 'ex'? '': <div className='flexRowStart'>
            {viewExLabel('', 'blankSpace')}
            <div style={{width:'100%', margin:'4px 0 4px 0'}}>
                {url && showPlayer(audioType, url, audioUI, 0, idx, setPlayed)}
            </div>           
    </div>}</>;
};


