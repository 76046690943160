export enum EDITOR_MODE {
    CLASSIC = "classic",
    INLINE = "inline",
}

export enum EDITOR_TYPE {
    FULL = "full",
    EQUATION = "equation",
    ANSWER = "answer",
    CONTENT = "content",
    UPLOAD_IMAGE = "uploadImage",
    COMMENT = "comment"
}

export const EDITOR_CKTYPE = [EDITOR_TYPE.FULL, EDITOR_TYPE.ANSWER, EDITOR_TYPE.CONTENT, EDITOR_TYPE.EQUATION, EDITOR_TYPE.COMMENT]

const HOSTNAME = window.location.hostname;
const CKFINDER_URL = `http://${HOSTNAME}:2030`;

const UPLOAD_SERVER = `${CKFINDER_URL}/easy-image.php?HTTP_REFERER=${HOSTNAME}`;

export const EDITOR_TYPE_CONFIG = {
    FULL: {
        toolbar: {
            items: [
                'undo', 'redo',
                '|',
                'removeFormat',
                'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript',
                '|',
                'fontSize', 'fontColor',
                '|',
                'link', 'insertTable',
                '|',
                'uploadImage', 'mediaEmbed',
                '|',
                'bulletedList', 'numberedList',
                '|',
                'alignment',
                '|',
                'MathType'
            ],
            shouldNotGroupWhenFull: true
        },
        "uploadServer": UPLOAD_SERVER
    },
    EQUATION: {
        toolbar: {
            items: [
                'MathType'
            ]
        }
    },
    ANSWER: {
        toolbar: [
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript',
            '|',
            'uploadImage',
            '|',
            'alignment',
            '|',
            'MathType'
        ],
        "uploadServer": UPLOAD_SERVER
    },
    CONTENT: {
        toolbar: {
            items: [
                'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript',
                '|',
                'fontSize', 'fontColor',
                '|',
                'alignment',
                '|',
                'MathType'
            ],
            shouldNotGroupWhenFull: true
        }
    },
    COMMENT: {
        toolbar: [
            'comment'
        ],
        comments: {
            editorConfig: {}
        }
        // licenseKey: process.env.LICENSEKEY,
    }
}