function camelToUnderscore(str) {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export default function camelKeysToUnderscore(obj) {
  if (typeof obj !== 'object') {
    throw new Error('camelKeysToUnderscore: expect object');
  }

  for (let oldName in obj) {
    // check for the old property name to avoid a ReferenceError in strict mode.
    if (!obj.hasOwnProperty(oldName)) {
      continue;
    }

    // camel to underscore
    const newName = camelToUnderscore(oldName);

    // only process if names are different
    if (newName !== oldName) {
      obj[newName] = obj[oldName];
      delete obj[oldName];
    }

    // recursion
    if (typeof obj[newName] === 'object') {
      obj[newName] = camelKeysToUnderscore(obj[newName]);
    }
  }

  return obj;
}
