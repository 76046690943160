import React, {useEffect, useState, useMemo, Fragment} from "react";

import { useUILang } from "../../../AppExPf/utils/useUILang";
import CpIco from "../../_components/CpIco";
import { IconList } from '../../../consts/ATIconListToUsePoc';

import CpTableHeaderSortingButton from "./CpTableHeaderSortingButton";
import CpDividedBarChart from "./ChartDividedBar";
import { LegendType } from "../../../poc/type/LegendType";
import ReportLegend from "../../../poc/screens/shared/includes/chart/legend/ReportLegend";
import { useVisDetAll, useVisDetSub } from "../../EPExercise/PageExerciseEdit";
import { stopEvent } from "../../../libs/libUI";
import { toAry, toStr } from "../../../libs/libType";
import { Button } from "react-bootstrap";
import { expf2 } from "../../../consts/ATSysQType";
import CpMJX from "../../_components/CpMJX";
import CpActionButton from "../../_components/CpActionButton";
import { BtnPopDev, preJS } from "../../../AppExPf/AppUtil";

const ExpandBtn = props => <span role={"button"} className={`text-exercise-third-btn metadata-filter-toggle fs-5`} {...props}>
    <CpIco src={props.open? IconList.general.openNode: IconList.general.closeNode}/>
</span>;

const CpMetaProgessAverageScore = (props) => {
    const {
        allDVis2, type, rowData, referenceItems = [], index = 0,
        parentLine, sticky = {top: false, bottom: false}
    } = props;
    const {
        title,
        scoreRange,
        averageScore,
        classAverageScore,
        answered,
        referenceId,
        children = [],
        subAverageScore = []
    } = rowData;

    const averageSegments = [{...scoreRange}];

    const canOpen = !!(children.length && children[0].title);
    const [subOpen, setSubOpen] = useVisDetSub(allDVis2, !canOpen);
    const handleSubOpen = e => { stopEvent(e); setSubOpen(!subOpen); };

    return <>
        <div id={type+'~~'+referenceId} className={"CpMetaProgessAverageScore d-flex gap-2"}>
            <div className={`${type === "s"? "": (type === "a"? "col-average-score-title mini-title": "col-average-score-title")}`}>
                {subAverageScore.length > 0 && <>{
                    (type === "a" || type === "sc") && <><span className={"col-expand mt-2"}>
                        <span className={"col-header-expand"}><ExpandBtn open={subOpen} onClick={() => handleSubOpen()}/></span>
                    </span></>
                }</>}
                {type === "a" && (() => {
                    const classItem = referenceItems?.find(referenceItem => (referenceItem.id === referenceId));
                    if (subAverageScore.length === 0) {
                        return <><span className={"col-mini-class-no text-truncate"}> {classItem?.title}</span></>
                    }
                })()}
                {type === "sc" && (() => {
                    const studentItem = referenceItems?.find(referenceItem => (referenceItem.id === referenceId))
                    if (subAverageScore.length === 0) {
                        return <span className={"col-mini-student text-truncate"}>
                            <span className={"col-mini-student-no"}>{studentItem?.no}</span>
                            <span className={"col-mini-student-name ms-2"}>{studentItem?.title}</span>
                        </span>
                    }
                })()}
            </div>
            <CpDividedBarChart segments={averageSegments} statusScore={averageScore}
                             status={scoreRange?.status || "n"}
                             dotAverage={(type === "sc" || type === "s") && subAverageScore.length === 0}
                             parentLine={(type === "sc" || type === "s") ? parentLine || classAverageScore : undefined}
                             sticky={{
                                 ...sticky,
                                 bottom: sticky?.bottom || (subAverageScore.length > 0 || children.length > 0)
                             }}/>
            <span className={`col-mini-average-percentage mt-2 text-status-${scoreRange?.status}`}>
                        {typeof averageScore?.percentage === "number" && averageScore?.percentage >= 0 ? averageScore?.percentage : "-"}%</span>
        </div>

        {/*Student or Class*/}
        {subOpen? subAverageScore.map((rowData, idx) => {
            return <CpMetaProgessAverageScore {...{key: idx, type, rowData, referenceItems, index:(index + 1),
                                 parentLine: averageScore, sticky:{top: true, bottom: subAverageScore.length > idx}}} />
        }):''}
    </>
}

const CpMetaProgressRowData = (props) => {
    const [ t ] = useUILang();
    const {
        allDVis1, allDVis2, type, rowData, referenceItems = [], index = 0,
        parentLine, sticky = {top: false, bottom: false}
    } = props;

    const {selectedStudentCase} = props;
    //const selectedStudentCase = referenceItems.length === 0 
    //    || (report.class.value !== OptionType.ALL && report.student.value !== OptionType.ALL) 
    //    || userState.role === ROLE.STUDENT
    const { title, answered, referenceId, id, children = [], } = rowData

    const [ClickVisAllD, visAllDCmd, setVisAllD, visAllD, scanSub, setscanSub2, setOnP2, rescan2] = toAry(allDVis2);
    const canOpen = !!(children.length && children[0].title);
    const [open, setOpen] = useVisDetSub(allDVis1, !canOpen);
    const handleOpen = e => { stopEvent(e); setOpen(!open); rescan2();};

    return <>
        <div id={'ref__'+id} className={"CpMetaProgressRowData d-flex gap-2 w-100 gap-2"}>
            <div className={"col-fix-metadata mt-2"} style={{paddingLeft: `${index * 3}rem`}}>
                <span className={`${(index === 0)? "": "col-header-expand"} ${canOpen || (index !== 0)? "me-3": ""}`} style={{minWidth:"20px"}}>
                    {canOpen && <ExpandBtn open={open} onClick={() => handleOpen()}/>}
                </span>
                <span className={"text-wrap overflow-auto"}><CpMJX>{title}</CpMJX></span>
            </div>
            {selectedStudentCase && <div className={"col-fix-no-of-question-answered"}>
                {answered !== undefined ? `${answered} ${t("q")}` : ""}
            </div>}
            <div className={`col-fix-average-score-percentage-2 ${type === "sc"? "selected-class": ""} flex-column`}>
                <CpMetaProgessAverageScore {...props}/>
            </div>
        </div>

        {/*Metadata node*/}
        {open? children.map((child, idx) => {
            return <CpMetaProgressRowData  {...{key:idx, rowData:child, index:(index + 1), type, referenceItems, selectedStudentCase, allDVis1, allDVis2}} />
        }): ''}
    </>
}

const expBtnCls = 'btn-exercise-third-btn';
export const ChartMetadataProgressMath = props => { //poc MetadataProgressChart (v2)
    const [ t ] = useUILang();
    const {id, title, type, items = [], data} = props
    
//console.log('ChartMetadataProgressMath', {data, items});

    const allDVis1 = useVisDetAll(0, 'x 1');
    const [ClickVisAllD, visAllDCmd, setVisAllD, visAllD, scanSub] = allDVis1;
    const clickExp = e => { ClickVisAllD(); }   

    const hasSub = (type === "a" || type === "sc")
    const allDVis2 = useVisDetAll(0, 'x 02');
    const [ClickVisAllD2, visAllDCmd2, setVisAllD2, visAllD2, scanSub2] = allDVis2;
    const clickExp2 = e => { ClickVisAllD2(); }   

    const {selectedStudentCase} = props;

    const getLengend = useMemo(() => {
        return ( 'a' === type)? LegendType.REPORT_ALL_CLASSES
            :( 'sc' === type)? LegendType.REPORT_SELECTED_CLASS
            :/*( 's' === type)?*/LegendType.REPORT_SELECTED_STUDENT
    }, [type]);

    //{fakeRpt? 'ChartMetadataProgress' : ''}{'' && preJS({selectedStudentCase, data}, 3)}
    const exStyle = {padding:0, minHeight:"40px", paddingBottom:"5px"};
    return <>
<BtnPopDev txt="data">{preJS({data}, 3)}</BtnPopDev>
<BtnPopDev txt="item">{preJS({items}, 3)}</BtnPopDev>
        <div id={toStr(id)} className={"ChartMetadataProgressMath flow-container scroll-padding"} style={{overflow: "visible"}}>
            <table className={"report report-table-progress-chart metadata-progress-chart semi-bold "}>
                <thead><tr className="flexRowStart w-100" style={{padding:"0.825rem 0.825rem 0 0.825rem", alignItems:"center"}}>
                    <th className={"col-metadata flexRowCenter"} style={exStyle}>
                    {/*<th className={"col-fix-metadata "} style={{paddingLeft:0}}>*/}
                        <div className={"flexRowBetween"}>
                            {expf2?expf2ExpandBtn(visAllD, clickExp, t):''}
                            <div className="flexRowCenter"><CpTableHeaderSortingButton {...{title, type:"metadata", sorting:false}} /></div>
                        </div>
                    </th>
                    {selectedStudentCase && <th className={"col-no-of-question-answered flexRowCenter"} style={exStyle}><span>
                        <CpTableHeaderSortingButton {...{title:t("report-no-of-question-answered"), type:"report-no-of-question-answered", sorting:false}} />
                    </span></th>}
                    <th className={"col-average-score-percentage-2 flexRowCenter" } style={{...exStyle, paddingLeft:hasSub?"88px":"0"}}>
                    {/*<th className={"col-fix-average-score-percentage-2 flex-column"}>*/}
                        <div className={"flexRowBetween"}>
                            {(expf2 && hasSub)? expf2ExpandBtn(visAllD2, clickExp2, t) :''}
                            <div className="flexRowCenter">
                                <CpTableHeaderSortingButton {...{
                                    title: t("report-average-score-percentage"), 
                                    type: "report-average-score-percentage", 
                                    tips: <ReportLegend legend={getLengend}/>,
                                    sorting: false}}
                                    />
                            </div>
                        </div>
                    </th>
                </tr></thead>
                <tbody><tr><td colSpan={4 + (selectedStudentCase? 1: 0) + (hasSub? 1: 0)}>
                    {data?.map((d, key) => {
                        return <Fragment {...{key}}>
                            { /* preJS(d, 3) */}
                            <CpMetaProgressRowData {...{key, allDVis1, allDVis2, type, rowData: d, referenceItems: items, selectedStudentCase}} />
                        </Fragment>
                        })}
                </td></tr></tbody>
            </table>
        </div>
    </>;
};

const expf2ExpandBtn = (visAllD, clickExp, t) => {
    return <><div className="expf2ExpandBtn btn768Show">
    <CpActionButton title={t(visAllD? 'report-collapse-all': 'report-expand-all')} iconPath={visAllD? IconList.general.collapse: IconList.general.expand}
        className={`btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none text-nowrap`}
        onClick={clickExp} hiddenText={true}></CpActionButton>
    </div>
    <div className="expf2ExpandBtn btn768Hide"><ExpandBtn {...{open: visAllD, onClick: clickExp}}/></div>
    </>
};