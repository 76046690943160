import { validLang } from "../../../AppExPf/utils/useUILang";
import { maxVal, minVal, objEntries, toAry, toFix1, toFloat, toInt, toObj, toStr } from "../../../libs/libType";

export const toAsmData = (overData3_, clsNames, type, _getTeacherName) => { 
  const data = toAry(overData3_).map((r, i) => {
    const {
      teacherId, classId, EName, ALang, datePub, APass,  
      submit, ttl, pass, fail, 
      avgMp, passP, maxP, minP,
    } = r;
    const ret = {
      scoreRange: [
        APass && fail && { value: fail, minPercentage: toFix1(minP), maxPercentage: toFix1(minVal(maxP, passP)), status: "f" },
        APass && pass && { value: pass, minPercentage: toFix1(maxVal(minP, passP)), maxPercentage: toFix1(maxP), status: "p" },
        (!APass) && submit && { value: submit, minPercentage: toFix1(minP), maxPercentage: toFix1(maxP), status: "a" },
      ].filter(r => r),
      language: validLang(ALang),
      title: EName,
      className: toStr(clsNames[classId]),
      assignedBy: _getTeacherName(teacherId),
      resultPublish: datePub,
      averageScore: { percentage: toFix1(avgMp) },
      submitted: { value: submit, total: ttl },
      //sort fields
      ...r,
    };
    if(APass) ret.passingScore = { percentage: toFix1(passP) }
    return ret;
  });
  const overData3 = {type, data};
  
  //return AssignmentProgressByAllClassesTeacherData;
  return overData3;
};

const fakeD = {
  id: "FDXXXX",
  language: "en",
  title: "Fake Asm ",
  className: "1A",
  resultPublish: 1684128410377,
  submitted: { value: 1, total: 3 },
  scoreRange: [ {
      value: 1,
      minPercentage: 1.4,
      maxPercentage: 5.8,
      status: "f"
    }
  ],
  averageScore: { percentage: 15.4 },
  passingScore: { percentage: 5.7 },

  EName: "New by RS 20230503 1026PM (Nextss)",
  datePub: 1684128410377,
  maxP: 15.384615384615385,
  avgMp: 15.384615384615385,
  submit: 1,
};


const AssignmentProgressByAllClassesTeacherData = {
  "type": "a",
  "data": [
    {
      "id": "a-001",
      "language": "en",
      "title": "ZZZSenior Secondary New Century Mathematics (2nd Edition) Book 6 Chapter 7 Revision Exercises",
      "className": "4A",
      "resultPublish": 1683613978,
      "submitted": { "value": 31, "total": 32
      },
      "scoreRange": [
        {
          "value": 16,
          "minPercentage": 0,
          "maxPercentage": 0,
          "status": "f"
        },
        {
          "value": 26,
          "minPercentage": 100,
          "maxPercentage": 100,
          "status": "p"
        }
      ],
      "averageScore": {
        "percentage": 61.3
      },
      "passingScore": {
        "percentage": 40
      }
    },
    {
      "id": "a-001",
      "language": "en",
      "title": "Senior Secondary New Century Mathematics (2nd Edition) Book 6 Chapter 7 Revision Exercises",
      "className": "4A",
      "resultPublish": 1683613978,
      "submitted": {
        "value": 31,
        "total": 32
      },
      "scoreRange": [
        {
          "value": 5,
          "minPercentage": 33.8,
          "maxPercentage": 44.9,
          "status": "f"
        },
        {
          "value": 26,
          "minPercentage": 44.9,
          "maxPercentage": 82.9,
          "status": "p"
        }
      ],
      "averageScore": {
        "percentage": 61.3
      },
      "passingScore": {
        "percentage": 40
      }
    },
    {
      "id": "a-002",
      "language": "en",
      "title": "Senior Secondary New Century Mathematics (2nd Edition) Book 6 Chapter 7 Revision Exercises",
      "className": "4A",
      "resultPublish": 1683613978,
      "submitted": {
        "value": 30,
        "total": 34
      },
      "scoreRange": [
        {
          "value": 18,
          "minPercentage": 5.6,
          "maxPercentage": 22.2,
          "status": "f"
        },
        {
          "value": 12,
          "minPercentage": 22.2,
          "maxPercentage": 52.2,
          "status": "p"
        }
      ],
      "averageScore": {
        "percentage": 34.7
      },
      "passingScore": {
        "percentage": 44.7
      }
    },
    {
      "id": "a-003",
      "language": "en",
      "title": "Senior Secondary New Century Mathematics (2nd Edition) Book 6 Chapter 7 Revision Exercises",
      "className": "4A",
      "resultPublish": 1683613978,
      "submitted": {
        "value": 27,
        "total": 29
      },
      "scoreRange": [
        {
          "value": 10,
          "minPercentage": 10.6,
          "maxPercentage": 33.9,
          "status": "f"
        },
        {
          "value": 17,
          "minPercentage": 33.9,
          "maxPercentage": 70.2,
          "status": "p"
        }
      ],
      "averageScore": {
        "percentage": 61.3
      },
      "passingScore": {
        "percentage": 40
      }
    },
    {
      "id": "a-004",
      "language": "en",
      "title": "Senior Secondary New Century Mathematics (2nd Edition) Book 6 Chapter 7 Revision Exercises",
      "className": "4A",
      "resultPublish": 1683613978,
      "submitted": {
        "value": 32,
        "total": 32
      },
      "scoreRange": [
        {
          "value": 6,
          "minPercentage": 31.8,
          "maxPercentage": 44.9,
          "status": "f"
        },
        {
          "value": 26,
          "minPercentage": 44.9,
          "maxPercentage": 82.9,
          "status": "p"
        }
      ],
      "averageScore": {
        "percentage": 61.3
      }
    },
    {
      "id": "a-005",
      "language": "en",
      "title": "SMaths Mock Test - 2023/Nov",
      "className": "4A",
      "resultPublish": 1683613978,
      "submitted": {
        "value": 29,
        "total": 32
      },
      "scoreRange": [
        {
          "value": 29,
          "minPercentage": 41.3,
          "maxPercentage": 76.5,
          "status": "a"
        }
      ],
      "averageScore": {
        "percentage": 58.1
      }
    },
    {
      "id": "a-006",
      "language": "en",
      "title": "SMaths Mock Test - 2023/Nov",
      "className": "4B",
      "resultPublish": 1683613978,
      "submitted": {
        "value": 29,
        "total": 29
      },
      "scoreRange": [
        {
          "value": 29,
          "minPercentage": 41.3,
          "maxPercentage": 76.5,
          "status": "a"
        }
      ],
      "averageScore": {
        "percentage": 61.3
      }
    }
  ]
};