import DOMPurify from 'dompurify';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';

import { apiCallLoad, apiCallLoad_ } from '../libs/awsFuncs';
import { Array2Object, arrayAddOrDelete_ } from '../libs/libArray';
import { errMsg } from '../libs/libFormat';
import { isAry, objKeys, toAry, toObj, toStr } from '../libs/libType';
import * as UI from '../libs/libUI';

import { _ATMetaTypeByCode, _ATMetaTypeReport, _ATMetaTypeReportSubj, _ATMetaTypes } from '../consts/ATMetaTypes';

import { aTErrDiv1, ATErrsDiv, aTErrTxt1, ATUICheckBox0, aTUIMust, ATUIText, BtnPopDev, clickConfirm, clickUrl, CpATBtn, preJS } from './AppUtil';

import { ReduxBind } from '../saga/ReduxState';

import { _ATRoot } from '../consts/ATConstReact';
import { langCodeCt, langCodeEn, QP_D, QP_P } from '../consts/ATConsts';
import { normalizedEditATMetaSet, validEditATMetaSet } from '../consts/ATValidateMetaSet';

import { urlPush_Replace } from '../saga/urlPush.saga';
import { cpATIcoBtn_ } from './components/CpATIcoBtn';
import CpATUploadButton from './components/CpATUploadBtn';
import CpMetasTree from './components/CpMetasTree';
import { popAlert, popConfirm } from './components/CpPopup';
import { decodeMSTreeV2, encodeeMSTree, metaListPack, metaListUnpack } from './utils/AppUtilMSTree';

import { ATDo_Meta, _ATCanDo } from '../consts/ATRoleRights';
import { downloadUrl, fAKEdownload } from '../libs/libDownload';
import { useTickAll } from './ATQtnEdit/PageATQtns';
import CpATSubjectTickList from './components/CpATSubjectTickList';
import CpDraftPubBtns, { PubDraftStates } from './components/CpDraftPubBtns';
import { detectIsNew, hasErr, normalizeEdit, useEditDraftPub, useLangView } from './utils/useEditDraftPub';
import CpMJX from '../AppExPFUser/_components/CpMJX';
import { lang2t } from './utils/useUILang';

const reloadIcon = UI.imgSrc('svg/reloadBlue.svg');
const selColor = "#0C8CE9";
const backPath = _ATRoot + 'metas';

const PageATMetaEdit = ReduxBind((props) => {
    const dispatch = props.dispatch;
    const role = toStr(props._saga?.auth?.ATUser?.ATRole);
    const canMeta = _ATCanDo(role, ATDo_Meta); 

    const [csv, setCSV] = useState();
    const [metaListDraft, setMetaListDraft] = useState();
    const [metaListPub, setMetaListPub] = useState();
    const [ATSubjects, setAtSubjects] = useState([]);
    const [mySjIds, setMySjIds] = useState([]);
    
    const [draftRpt, setDraftRpt] = useState();
    const [pubRpt, setPubRpt] = useState();

    const [fields, setFields, setField,  setFieldTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts, draft, setDraft, pub, setPub] = useEditDraftPub();
    const [isNew, metaSetId] = detectIsNew(props.match?.params, pub, draft, 'metaSetId');

    const [view, setView] = useState(QP_P);
    const {hasDraft, saved, r2p, pubed, unpubed, showPub, lock, lockDraft } = PubDraftStates(view, draft, pub, 'MSVer'); 

    const mset = showPub? pub: fields;
    const [treeLang, setTreeLang, clickLang, showEn, hasEn, hasCt, safeLang] = useLangView(mset?.MSLangEn, mset?.MSLangCt, langCodeEn);

    const csvErrs = toAry(csv?.errs);
    const heads = showPub? (mset?.heads): (csv?.head);
    const tree = showPub? (mset?.tree): (csv?.tree); 
    const metalist = showPub? metaListPub: (csv?.list);

    const metaIds = objKeys(metalist);
    const treeTicks = useTickAll(metaIds, 1);
    const [ticks, setTick, setTicks, tickAll, setTickAll, clearTicks] = treeTicks;
    const flipTickAll = e => { UI.stopEvent(e); setTickAll(tickAll?0:1); }; 
    
    //MathJax.typeset()

    const setFieldChk = (key) => (val) => { setFields(fs => ({ ...(fs || {}), [key]: val ? 1 : 0 })) };
    const setMSSubjId = sid => isAdd => {
        setFields(fs => ({ ...(fs || {}), MSSubjIds:arrayAddOrDelete_(fs?.MSSubjIds, sid, isAdd) }));
    };
    const clidkTreeLang = l => e => { UI.stopEvent(e); setTreeLang(l) };

    const safeSetView = v => UI.stopEventThen( e => setView((!pub)? QP_D: (!hasDraft)? QP_P: v) );

    const normalizeMeta = () => normalizeEdit(fields, {draftRpt}, [], normalizedEditATMetaSet, validEditATMetaSet, setFields, setFieldErrs);

    const setLoadDraft = (d, ld) => {
        const {tree, heads} = toObj(d);
        const [list, stree] = metaListUnpack(ld, tree);  
        const _d = d && {...d, tree:stree};
        setDraft(_d);
        setFields(toObj(_d));
        setMetaListDraft(list);
        setCSV({heads, tree:stree, list, errs:[]});
    };
    const setLoadPub = (p, lp) => {
        const [list, stree] = metaListUnpack(lp, p?.tree);  
        setPub(p && {...p, tree:stree});
        setMetaListPub(list);
    }
    const loadViews = res => {
        if(res.ATSubjects) setAtSubjects(res.ATSubjects);
        if(res.mySjIds) setMySjIds(res.mySjIds); 
        const [p, lp, d, ld] = [res.metaSet, res.metaListPub, res.metaSetDraft, res.metaListDraft ];
        setLoadDraft(d, ld);
        setLoadPub(p, lp);
        setDraftRpt(formatRpt(res.draftRptMetaSets||[], res.draftRptMetas||[]))
        setPubRpt(formatRpt(res.pubRptMetaSets||[], res.pubRptMetas||[]))
        setView(v => ((!p)? QP_D: (!d)? QP_P: v));
    };
    const onLoad = (res, err) => {
        if (err) popAlert(dispatch, 0, errMsg(err));
        if (res) loadViews(res);
        //if(res.metaSetId) dispatch(urlPush_Replace(_ATRoot+'meta/edit/'+toStr(res.metaSetId))); 
    };
    const reload = () => apiCallLoad_(dispatch, '/getATMetaSet', onLoad, { isNew, metaSetId }, {}); 
    useEffect(() => { canMeta? reload(): dispatch(urlPush_Replace(_ATRoot)) }, [] );


    const back = () => dispatch(urlPush_Replace(backPath));
    const onDelete = (res, err) => { back(); }
    const onPutATMetaDraft = (res, err) => {
		if((res?.state) === 'ok'){		
            onLoad(res, err);
        }if((res?.state) === 'retry'){		
            const fes = res?.fieldErrs;
            if(fes) setFieldErrs(fes); 
        }else{
            reload(); 
        }
	};
    const onLoadDraft = (res, err) => {
        if (err) popAlert(dispatch, 0, errMsg(err));
        if (res){
            const d = res.metaSetDraft;
            setLoadDraft(d, res.metaListDraft);
            setDraftRpt(formatRpt(res?.draftRptMetaSets||[], res?.draftRptMetas||[]))
            setFieldErrs({});
            setView(d? QP_D: QP_P );
            //setMetaList(res.metaList); DummyMetas);
        } 
    };
    const onPubList = (res, err) => {
        if (err) popAlert(dispatch, 0, errMsg(err));
        const [p, lp] = [res?.metaSet, res?.metaListPub];
        setLoadPub(p, lp);
    };

    const onLoadRpt = (res, err) => {
        if(err) popAlert(dispatch, 0, errMsg(err));
        
        setDraftRpt(formatRpt(res?.draftRptMetaSets||[], res?.draftRptMetas||[]))
    };
    const clickLoadRpt = UI.stopEventThen( e => {
        const [fields, fieldErrs] = normalizeMeta();
        apiCallLoad_(dispatch, '/getATMetaRpt', onLoadRpt, { MSReports:fields.MSReports });
    });

    const loadCSV = (txt, err) => {
        err && setCSV({errs:[err]});
        if (!txt)
            return;
        const newCsv = decodeMSTreeV2(csvToRows(txt), metaListDraft, metaListPub); 
        
        setCSV(c => {
            const e = newCsv.errs;
            const errs = isAry(e)? e: ['import csv error']; 
            return (errs.length)? {...c, errs}: newCsv;
        });
    };
    const downloadCSV = e => { UI.stopEvent(e);
        const metas = showPub? metaListPub: metaListDraft;
        const rows = encodeeMSTree(mset.heads, mset.tree, metas); 
        fAKEdownload(rowsToCsv(rows), 'metadatas'+mset.metaSetId+'_'+(showPub?'':'draft')+'.csv', 'text/csv');
    };
    const donwloadTemplate = e => { UI.stopEvent(e); downloadUrl('/ATTemplates/template_import_matadata_set.csv'); };

    const save = (doPub, fields, heads, tree, list) => {
        apiCallLoad_(dispatch, '/putATMetaSetDraft', onPutATMetaDraft, { doPub, fields:{...fields, heads, tree}, metaList:metaListPack(list)});
    } 
    const clickSaveATMSet = doPub => e => { UI.stopEvent(e);
        const [fields, fieldErrs] = normalizeMeta();
        const {heads, tree, list} = toObj(csv);
        if (!hasErr(fieldErrs)) {
            doPub
                ?popConfirm(dispatch, 0, 'Please confirm save & publish', () => {save(doPub, fields, heads, tree, list)} )
                :save(doPub, fields, heads, tree, list);
        }
    };

    const clickClone = canMeta && pub && UI.stopEventThen( e => apiCallLoad_(dispatch, '/getATMetaSetDraft', onLoadDraft, { metaSetId }));
    const clickPubList = canMeta && UI.stopEventThen( e => apiCallLoad_(dispatch, '/putATMetasPublish', onPubList, { metaSetId, metaList:metaListPack(metaListPub) }));
    const clickPublish = canMeta && unpubed && clickConfirm(dispatch, 'Please confirm publish', () => apiCallLoad_(dispatch, '/putATMetaSetsPublish', onLoad, { metaSetId }));
    const clickSavePublish = canMeta && hasDraft && clickSaveATMSet(1);
    const clickUnpublish = canMeta && pubed && clickConfirm(dispatch, 'Please confirm unpublish', () => apiCallLoad_(dispatch, '/putATMetaSetsUnpublish', onLoad, { metaSetId }));
    const clickDelele = canMeta && hasDraft && clickConfirm(dispatch, 'Please confirm delete', () => apiCallLoad( dispatch, '/deleteATMetaSets', onDelete, {metaSetId}) );

    const canRpt = _ATMetaTypeReport(mset?.MSType); 
    const SubjRpt = _ATMetaTypeReportSubj(mset?.MSType);
    
    const metaChk = showPub && (metaId => on => {
        setMetaListPub(mlp => {
            const walk = id => {
                const m = mlp[id];
                if(!m) return;
                m.pub = on;
                on? walk(m.pMeta): Object.keys(m.childs).forEach(walk);
            };
            walk(metaId);
            return { ...mlp};
        });
    });

    return <div className="PageATMetaEdit adminAccountTop">
        <div className="f16">Metadata / Metadata Sets / {showPub? 'View published' : (isNew ? 'New draft' : 'modify draft')} </div>
        {preJS({showEn, hasDraft, saved, r2p, pubed, unpubed, showPub, lock, lockDraft })}
        <div className="adminToolsContainer"><div style={{display:"flex"}}>
            <div className="adminTools1">
            {showPub ? <>
                {cpATIcoBtn_('general/publish', 'Publish', (!hasDraft) && clickPublish)}
                {cpATIcoBtn_('general/unpublish', 'Unpublish', clickUnpublish)}
                {cpATIcoBtn_('general/copy', 'Duplicate as Draft', clickClone)}
            </> : <>
                {cpATIcoBtn_('general/save', 'Save', clickSaveATMSet(0))}
                {cpATIcoBtn_('general/cancel', 'Cancel', clickUrl(dispatch, backPath))}
                {cpATIcoBtn_('general/delete', 'Delete', clickDelele)}
                {cpATIcoBtn_('general/publish', 'Save & Publish', clickSavePublish)}
            </>}
            <BtnPopDev txt={"mset("+(showPub? "pub": "fields")+")"} >{preJS(mset, 3)}</BtnPopDev>
            <BtnPopDev txt={"csv"} >{preJS(csv, 3)}</BtnPopDev>
            <BtnPopDev txt="metalist">{preJS(metalist, 3)}</BtnPopDev>
            </div>
            <div className="adminTools2" style={{ color: selColor }}>
                <CpDraftPubBtns {...{saved, draft, showPub, pub:pub, click:safeSetView, verKey:'MSVer' }} />
            </div>
        </div></div>
        {showPub? '': ATErrsDiv(fieldErrs)}
        <div className="inputInfoContainer f14">
            <table className="inputInfoTable"><tbody>
                <tr>
                    <td>
                        <div>Set Code {aTUIMust} 
                            {ATUIText(mset?.metaSetId, setField('metaSetId'), 'tmsid', lock || (!mset?.isNew), 'Please Input Set Code')}
                        </div>
                        {showPub? '': aTErrDiv1(fieldErrs?.metaSetId)}
                    </td><td>
                        <div>Metadata Type {aTUIMust} 
                        {lockDraft? 
                            _ATMetaTypeByCode(mset?.MSType):
                            <select value={fields?.MSType} onChange={ e => { UI.stopEvent(e); setField('MSType')(e.currentTarget.value); } } >
                                {_ATMetaTypes().map(o => (<option key={o.code} value={o.code}>{o.name}</option>))}
                        </select>}
                        </div>
                        {lockDraft? '': aTErrDiv1(fieldErrs?.MSType)}
                    </td>
                </tr>
            </tbody></table>
        </div>
        <hr className='hrat'/>
        <div className="inputInfoContainer f14">
            <div>Supported Languages{aTUIMust}
                <div className="w m4">
                    <label>{ATUICheckBox0(mset?.MSLangEn, setFieldChk('MSLangEn'), 'chkEn', lockDraft)}En</label>{aTErrTxt1(fieldErrs?.MSLangEn)}
                </div><div className="w m4">
                    <label>{ATUICheckBox0(mset?.MSLangCt, setFieldChk('MSLangCt'), 'chkCt', lockDraft)}繁中</label>{aTErrTxt1(fieldErrs?.MSLangCt)}
                </div>
                {aTErrTxt1(fieldErrs?.MSLang)}
            </div>
            <table className="inputInfoTable f14"><tbody>
                <tr>
                    <td><div>Title: {ATUIText(mset?.MSNameEn, setField('MSNameEn'), 'txtEn', lock, 'Please enter Title here', '', {width: "300px"})}
                    </div></td>
                    <td><div>標題: {ATUIText(mset?.MSNameCt, setField('MSNameCt'), 'txtCt', lock, '請在此輸入標題', '', {width: "300px"})}
                    </div></td>
                </tr><tr>
                    <td>{aTErrTxt1(fieldErrs?.MSNameEn)}</td>
                    <td>{aTErrTxt1(fieldErrs?.MSNameCt)}</td>
                </tr>
            </tbody></table>
        </div>

        <hr className='hrat'/>
        <div className="f14">Enable for subject(s) {aTUIMust}</div>
            <div className="subjectItemContainer f14">
                {CpATSubjectTickList(ATSubjects, mySjIds, toAry(mset?.MSSubjIds), setMSSubjId, lock)}
            </div>
            {showPub? '': aTErrTxt1(fieldErrs?.MSSubjIds)}

        <hr className='hrat'/>
        {canRpt ?<>
            <div className="f14">{UI.CheckBox0(mset?.doReport ||SubjRpt, setFieldChk('doReport'), 'chkEn', SubjRpt||showPub)} Enable for Progress Report (Teacher & Student)</div>
            {SubjRpt?'':
                showPub ? <>
                    <div className="flexRowStart f14">Subject Metadata Code(s):<div>{toStr(pub?.MSReports)}</div></div>
                    <DivRpts rpt={pubRpt}/>
                </>:<>
                    <div className="flexRowStart f14">Subject Metadata Code(s) {aTUIMust}
                        {UI.EpInputTxt0(fields?.MSReports, setField('MSReports'), 'txtRpts', '', { width: "70%" }, 'Please enter Subject Metadata code(s) [ metaSetId,metaId;.... ] ' )}
                        <div className="adminToolItem clickable" onClick={clickLoadRpt}><img className="adminToolIcon" src={reloadIcon} alt="" /></div>
                    </div>
                    <DivRpts rpt={draftRpt}/>
                    <div>{aTErrTxt1(fieldErrs?.MSReports)}</div>
                </>
            }
        </>:''}
        <hr className='hrat'/>
        <div className="f14 m4">
            <div className="w">
                Metadata Structure {aTUIMust}
                {hasEn? (showEn? <div className="w metaLangSel">En</div>: UI.Button0('En', clidkTreeLang(langCodeEn), 'btnTLE', 'metaLangUnsel')): ''}
                {hasCt? ((!showEn)? <div className="w metaLangSel">繁中</div> : UI.Button0('繁中', clidkTreeLang(langCodeCt), 'btnTLC', 'metaLangUnsel')): ''}
            </div>
            <div className="floatr tar">
                {lock? CpATBtn('Publish Selected', 'btnDL', clickPubList) :<CpATUploadButton text='Upload structure' onLoad={loadCSV} />}
                {CpATBtn('Export structure', 'btnDL', downloadCSV )}
                {CpATBtn('Download Template', 'btnDLTmp', donwloadTemplate )}
            </div>
        </div>
        <div className="clearboth f14 m4">
            {ShowMetaHeads(heads, showEn)}
            {CpATBtn(tickAll?'Collapse All':'Expand All', 'btnTAll', flipTickAll, 0, 'floatr' )}
        </div>
        {showPub? '': csvErrs.map((e, i) => <div key={i}>{aTErrTxt1(e)}</div>)}
        {tree && metalist && <CpMetasTree {...{treeData: tree, dataArr: metalist, drawItem: drawItem(treeLang, metaChk), treeTicks}} />}
    </div >;
});
export default PageATMetaEdit;

const DivRpts = ({rpt}) => { 
    return rpt? <div>{rpt.map(r => (<div key={r.key}>
        {r.sNameCt+' | '+r.sNameEn+' - '+r.mNameCt+' | '+r.mNameEn} 
    </div>))}</div>: '';
};

const ShowMetaHeads = (heads, showEn) => {
    const names = toAry(heads).map(h => (showEn? h.nameEn: h.nameCt));
    const line = [];
    names.forEach( (n, i) => {
        line.push(<span key={i}>{n}</span>); 
        if(i < (names.length - 1)) 
            line.push(<span key={'gap'+i}>{' > '}</span>);
    });
    return <div className="w">Metadata Structure Header: {line}</div>;
}; 

const drawItem = (lang, checkCB) => (obj) => {
    const cleanData = (obj?.mathml) && DOMPurify.sanitize(obj.mathml, {
        ADD_TAGS: ['mstack', 'msrow', 'msline'],
        ADD_ATTR: ['charalign', 'stackalign']
    });
    const lvnum = toStr(obj.LVNum);
    const txt = lang2t(lang, obj.nameEn, obj.nameCt);
    const chk = checkCB? UI.CheckBox0(obj.pub, checkCB(obj.metaId), 'chk'+obj.metId, 0, '', {margin:'0 4px 0 0'}): '';
    const id = toStr(obj.metaSetId) + ',' + toStr(obj.metaId);

    return <div className="treeEleContent" id={id} style={(chk && obj.wasPub)? {backgroundColor:'#B7EFBA'}: {}}><CpMJX>
        <div id={id} >{chk}{lvnum+(lvnum && txt && ': ')+txt}</div>
        {cleanData && <div dangerouslySetInnerHTML={{ __html: cleanData }} />}
    </CpMJX></div>;
};

const csvParseOpts = { header: false };
export const csvToRows = csvStr => {
    const { data, errors, meta } = Papa.parse(csvStr, csvParseOpts);
    return data;
};

const formatRpt = (metaSets, metas) => {
    const mss = Array2Object(metaSets, 'metaSetId');
    return metas.map(m => {
        const mset = mss[m.metaSetId] || {};
        return { key:m.metaSetId+'-'+m.metaId,
            sNameCt: toStr(mset.MSNameCt).trim(), sNameEn: toStr(mset.MSNameEn).trim(),
            mNameCt: toStr(m.nameCt).trim(), mNameEn: toStr(m.nameEn).trim(),
        };
    });
}

const csvUnparseOpts = {
	quotes: false, //or array of booleans
	quoteChar: '"',
	escapeChar: '"',
	delimiter: ",",
	header: true,
	newline: "\r\n",
	skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
	columns: null //or array of strings
};
export const rowsToCsv = (rows) => {
    return '\uFEFF'+ Papa.unparse(rows, csvParseOpts);//    response = response.replace(/^\ufeff/, "");
};

