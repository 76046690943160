import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import WholeKeyStageMetadataProgressChart from './../../shared/includes/chart/WholeKeyStageMetadataProgressChart';
import { WholeKeyStageMetadataProgressChartType } from './../../../type/ChartType';

// Styles for ScoreInput
const styles = `
  .slider-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .year-label {
    width: 80px;
  }
  .styled-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  .styled-slider:hover {
    opacity: 1;
  }
  .styled-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  .styled-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  .styled-input {
    width: 60px;
    text-align: center;
  }
`;

interface ScoreInputProps {
    rowIndex: number;
    yearIndex: number;
    initialScore: number;
    updateScore: (rowIndex: number, yearIndex: number, newScore: number) => void;
}

const ScoreInput: React.FC<ScoreInputProps> = ({ rowIndex, yearIndex, initialScore, updateScore }) => {
    const [score, setScore] = useState(initialScore);

    useEffect(() => {
        updateScore(rowIndex, yearIndex, score);
    }, [score, rowIndex, yearIndex, updateScore]);

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setScore(parseFloat(e.target.value));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Math.min(100, Math.max(0, parseFloat(e.target.value) || 0));
        setScore(newValue);
    };

    return (
        <div className="slider-container">
            <span className="semi-bold year-label">Year {yearIndex + 1}:</span>
            <input
                className="styled-slider"
                type="range"
                step={0.1}
                min={0}
                max={100}
                value={score}
                onChange={handleSliderChange}
            />
            <input
                className="styled-input"
                type="number"
                step={0.1}
                min={0}
                max={100}
                value={score}
                onChange={handleInputChange}
            />
            <span>%</span>
        </div>
    );
};

interface WholeKeyStageChartElementProps {
    type: 'ks-report-selected-class' | 'ks-report-selected-student';
}

const WholeKeyStageChartElement: React.FC<WholeKeyStageChartElementProps> = ({ type }) => {
    const initialClassTeacherData = {
        "items": [
            { "id": "s-001", "no": "01", "title": "Eric Yau" },
            { "id": "s-002", "no": "02", "title": "Wing Tang" }
        ],
        "id": "m-001",
        "title": "DSE Maths",
        "type": "sc",
        "data": [
            {
                "id": "m-001",
                "title": "Compulsory Part",
                "averageScore": [
                    { "year": 1, "scorePercentage": 0 },
                    { "year": 2, "scorePercentage": 80.2 },
                    { "year": 3, "scorePercentage": 100 }
                ],
                "subAverageScore": [
                    {
                        "id": "m-001-01",
                        "averageScore": [
                            { "year": 1, "scorePercentage": 100 },
                            { "year": 2, "scorePercentage": 85.3 },
                            { "year": 3, "scorePercentage": 0 }
                        ],
                        "referenceId": "s-001"
                    }
                ],
                "children": [
                    {
                        "id": "m-001-01",
                        "title": "Number and Algebra Strand",
                        "averageScore": [
                            { "year": 1, "scorePercentage": 70.3 },
                            { "year": 2, "scorePercentage": 67.4 },
                            { "year": 3, "scorePercentage": 88.8 }
                        ],
                        "subAverageScore": [
                            {
                                "id": "m-001-01-01",
                                "averageScore": [
                                    { "year": 1, "scorePercentage": 65.5 },
                                    { "year": 2, "scorePercentage": 72.3 },
                                    { "year": 3, "scorePercentage": 90.2 }
                                ],
                                "referenceId": "s-001"
                            }
                        ]
                    }
                ]
            }
        ]
    };

    const initialStudentSharedData = {
        "id": "m-001",
        "title": "DSE Maths",
        "type": "s",
        "selectedStudentInfo": {
            "id": "s-001",
            "no": "01",
            "title": "Eric Yau"
        },
        "data": [
            {
                "id": "m-001",
                "title": "Compulsory Part",
                "averageScore": [
                    { "year": 1, "scorePercentage": 52.5 },
                    { "year": 2, "scorePercentage": 80.2 },
                    { "year": 3, "scorePercentage": 91.0 }
                ],
                "classAverages": [
                    { "year": 1, "scorePercentage": 50.0 },
                    { "year": 2, "scorePercentage": 75.5 },
                    { "year": 3, "scorePercentage": 85.0 }
                ],
                "children": [
                    {
                        "id": "m-001-01",
                        "title": "Number and Algebra Strand",
                        "averageScore": [
                            { "year": 1, "scorePercentage": 67.4 },
                            { "year": 2, "scorePercentage": 70.3 },
                            { "year": 3, "scorePercentage": 88.8 }
                        ],
                        "classAverages": [
                            { "year": 1, "scorePercentage": 0.0 },
                            { "year": 2, "scorePercentage": 68.0 },
                            { "year": 3, "scorePercentage": 100.0 }
                        ]
                    }
                ]
            }
        ]
    };

    const initialData = type === 'ks-report-selected-class' ? initialClassTeacherData : initialStudentSharedData;

    const [chartData, setChartData] = useState<WholeKeyStageMetadataProgressChartType>(() => {
        return initialData as WholeKeyStageMetadataProgressChartType;
    });

    const updateScore = useCallback((rowIndex: number, yearIndex: number, newScore: number) => {
        setChartData(prevData => {
            const newData = JSON.parse(JSON.stringify(prevData)); // Deep clone the previous data

            if (type === 'ks-report-selected-class') {
                if (newData.data && newData.data.length > 0) {
                    let dataItem;
                    if (rowIndex === 1) {
                        dataItem = newData.data[0].subAverageScore[0];
                    } else if (rowIndex === 2) {
                        dataItem = newData.data[0].children[0];
                    } else if (rowIndex === 3) {
                        dataItem = newData.data[0].children[0].subAverageScore[0];
                    } else {
                        dataItem = newData.data[0];
                    }

                    if (dataItem && dataItem.averageScore && dataItem.averageScore.length > yearIndex) {
                        dataItem.averageScore[yearIndex].scorePercentage = newScore;
                    }
                }
            } else if (type === 'ks-report-selected-student') {
                if (newData.data && newData.data.length > 0) {
                    let dataItem;
                    if (rowIndex === 1) {
                        dataItem = newData.data[0].children[0];
                    } else {
                        dataItem = newData.data[0];
                    }

                    if (dataItem && dataItem.averageScore && dataItem.averageScore.length > yearIndex) {
                        dataItem.averageScore[yearIndex].scorePercentage = newScore;
                    }
                }
            }
            return newData;
        });
    }, [type]);

    const memoizedChartData = useMemo(() => chartData, [chartData]);

    return (
        <Container fluid={true}>
            <style>{styles}</style>
            <Row className="mb-4">
                <Col xs={12}>
                    <h4>Edit Scores</h4>
                    <Row>
                        {(type === 'ks-report-selected-class' ? ['Compulsory Part', 'Compulsory Part (Student)', 'Number and Algebra Strand', 'Number and Algebra Strand (Student)'] : ['Compulsory Part', 'Number and Algebra Strand']).map((title, rowIndex) => (
                            <Col key={rowIndex} md={6} lg={3} className="mb-3">
                                <h5>{title}</h5>
                                {[0, 1, 2].map(yearIndex => {
                                    const initialScore = type === 'ks-report-selected-class'
                                        ? (rowIndex === 1
                                            ? chartData.data?.[0]?.subAverageScore?.[0]?.averageScore?.[yearIndex]?.scorePercentage ?? 0
                                            : (rowIndex === 2
                                                ? chartData.data?.[0]?.children?.[0]?.averageScore?.[yearIndex]?.scorePercentage ?? 0
                                                : (rowIndex === 3
                                                    ? chartData.data?.[0]?.children?.[0]?.subAverageScore?.[0]?.averageScore?.[yearIndex]?.scorePercentage ?? 0
                                                    : chartData.data?.[Math.floor(rowIndex / 2)]?.averageScore?.[yearIndex]?.scorePercentage ?? 0)))
                                        : (rowIndex === 0
                                            ? chartData.data?.[rowIndex]?.averageScore?.[yearIndex]?.scorePercentage ?? 0
                                            : chartData.data?.[0]?.children?.[0]?.averageScore?.[yearIndex]?.scorePercentage ?? 0);

                                    return (
                                        <ScoreInput
                                            key={yearIndex}
                                            rowIndex={rowIndex}
                                            yearIndex={yearIndex}
                                            initialScore={initialScore}
                                            updateScore={updateScore}
                                        />
                                    );
                                })}
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <WholeKeyStageMetadataProgressChart key={JSON.stringify(memoizedChartData)} {...memoizedChartData} />
                </Col>
            </Row>
        </Container>
    );
};

export default WholeKeyStageChartElement;