import React, { useState, useEffect, useRef } from 'react';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import DDAnswerContainer from '../components/CpMCAnswers';
import CpATPullDownMenu from '../components/CpATPullDownMenu';
import * as SQT from '../../consts/ATSysQType';
import * as MCAC from '../../consts/ATQtnAnsTypeMCQ';
import * as UI from '../../libs/libUI';
import { ReduxBind } from '../../saga/ReduxState';
import CpATImage from '../components/CpATImage';
import { svgIcon2, iconButton } from '../components/CpATIcoBtn';
import { useUILang } from '../utils/useUILang';
import CpActionButtonComponent from '../../AppExPFUser/_components/CpActionButtonComponent';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { SIZE } from '../../AppExPFUser/_components/CpExPFConst';
import { PopMenuBtn } from './CpQEditorMCQ';

const cssClass = {
    'atview': ' atView', // at preview
    'atedit': ' atEdit', // at edit
    'teview': ' teView', // teacher preview
    'teedit': ' teEdit', // teacher edit
    //'stview':' stView', // student view result
    //'stedit':' stEdit'  // student take the test
};
const _newAns = { data: "", type: MCAC.__MCAT_ATG, correct: 0 };
const CpQEditorOPE = ReduxBind( props => {
    const { isAT, Qpd, fullQ, setQ, setFullQ, afterInit, Q, QErrs, editable, setUpFlag,
        setOnAddMedia, mediaDLs, getMediaDLs} = props;
    const [ t ] = useUILang();
    const [checkOver, setCheckOver] = useState(-1);
    const [mode, setMode] = useState(editable ? 'atedit' : 'atview');
    const [myClass, setMyClass] = useState(editable ? 'atEdit' : 'atView');

    const [visOpt, setVisOpt] = useState(0);
    const ansEditor = useRef([]);
    //const [onAddMedia, setOnAddMedia] = useState(0);

    const qData = Q?.qData || "";
    const ansArr = Q?.qAnswers || (editable ? [{ ..._newAns },{ ..._newAns }] : []);
    const ansChoice = fullQ.ansChoice?fullQ.ansChoice:(MCAC.__MCAC_ACL);
    const refCK = useRef(null);
    const isNew = props.QId?1:0;
    const role = 'at'; // at, te

    const updateQ = (func, d) => { func && func(d) };

    useEffect(()=>{
        
    }, [fullQ.SQType]);

    useEffect(() => {
        setMyClass(cssClass[mode]);
        //setEditable(!(mode.substring(2,6) === 'view'));
    }, [mode])

    const onEditorReady = (editor) => { };

    const clickCloseOpt = UI.stopEventThen(e => setVisOpt(0));
    const clickOpt = UI.stopEventThen(e => setVisOpt(1));
    
    const clickReset = (e) => {
        UI.stopEvent(e);
        //updateQ(setQ("tAnswers"), [..._newTAns(ansChoice)]);
    };

    const newAns = () => {
        
        if (ansArr.length >= 10) return;
        updateQ(setQ("qAnswers"), [...ansArr, { ..._newAns }]);
    };

    const setAnsType = (t, ii) => {
        if (t === ansArr[ii].type) return;
        ansEditor.current[ii] = null; // reset editor ref
        // only keep data for general if answer type changed
        //if (t !== MCAC.__MCAT_ATG) ansArr[ii].data = "";
        ansArr[ii].data = "";
        ansArr[ii].type = t;
        
        updateQ(setQ("qAnswers"), [...ansArr]);
        //setAnsArr([...ansArr]);
    };

    const setAnsData = (data, ii) => {
        ansArr[ii].data = data;
        updateQ(setQ("qAnswers"), [...ansArr]);
        //setAnsArr([...ansArr]);
    };

    const deleteAns = (ii) => {
        if (ansArr.length <= 2) return;
        ansArr.splice(ii, 1);
        updateQ(setQ("qAnswers"), [...ansArr]);
        //setAnsArr([...ansArr]);
    };

    const setOver = (ii) => {
        setCheckOver(parseInt(ii));
    };

    const ddClick = (e, ii) => {
        UI.stopEvent(e);
        return;
        
        /*
        if (Q.multiAnswer) ansArr[ii].correct = ansArr[ii].correct ? 0 : 1;
        else ansArr.forEach((ans, jj) => { ans.correct = (ii === jj ? 1 : 0) });
        updateQ(setQ("qAnswers"), [...ansArr]);
        //setAnsArr([...ansArr]);
        */
    };

    //const ck_updateMediaID = (arr) => {updateQ(setQ("refreshMediaIdURL"),[...arr])};
    const ck_updateMediaID = (arr) => { return getMediaDLs([...arr]); };
    const ckImageConfig = {
        setOnAddMedia,
        updateMediaID: ck_updateMediaID,
    };
    const edProps = {isAT, mediaDLs, getMediaDLs, setOnAddMedia};
    /*    
    const ck_updateMediaID = (arr) => {updateQ(setQ("refreshMediaIdURL"),[...arr])};
    const ckImageConfig = {
        setOnAddMedia,
        updateMediaID: ck_updateMediaID,
    };
    */    

    const customRender = (obj, ii) => {   
        if (ansChoice === MCAC.__MCAC_ACB) {
            return <div className={"f32 bold DDQtnLabel" + (obj.correct? "Sel" : "")}
                    onClick={(e) => ddClick(e, ii)}>
                {String.fromCharCode(65 + ii)}
                { editable && <div className="DDQtnLabelTrash">
                    <CpActionButtonComponent variant={"gray-at-secondary-dim-dark p-2"} size={SIZE.XS}
                        icon={<CpIco src={IconList.general.trash}/>} onClick={() => deleteAns(ii)}/>
                </div>}
            </div>;
        };        

        const focusEditor = (ii) => {if (ansEditor.current[ii] !== null) ansEditor.current[ii].focus();};
        
        const editor = (edType, ii) => {
            return <React.Fragment key={edType+ii}>
                {edType === "image"?<CpATImage id={"img"+edType+ii} media={obj.data} {...edProps}
                editable={editable} setData={(d) => setAnsData(d, ii)} />:
                <Ckeditor5Base {...props} editorType={edType} data={obj.data} setData={(d) => setAnsData(d, ii)}
                enable={editable} debug={false} showStatus={false} ckImageConfig={ckImageConfig}
                setAnsEditor={(obj)=>ansEditor.current[ii]=obj} {...edProps} setUpFlag={setUpFlag}/>}
            </React.Fragment>;
        };

        return <>
            <table key={"DDtb" + ii} className={"DDcontentTbl" + (editable ? "" : " unclickable") 
                + (obj.correct? " Sel" : "")}>
                <tbody><tr>
                    <td className={"DDtd1" + (checkOver === ii ? "Over" : (obj.correct? "Sel" : "")) + myClass}
                        onClick={(e) => ddClick(e, ii)}>
                        <div className="flexColCenter DDCharHead">{String.fromCharCode(65 + ii)}</div>
                    </td>
                    <td className={"DDtd2 "}>
                        <div className="DDQtnEditRow" key={"QEdit" + ii} onClick={()=>focusEditor(ii)}>
                            {obj.type===MCAC.__MCAT_ATI?editor("image",ii):editor("inputAnswer",ii)}
                            <div className='DDQtnEditBtn gap-1'>
                                {(ansChoice !== MCAC.__MCAC_ACB) && editable && <PopMenuBtn obj={obj} setAnsType={(t)=>setAnsType(t, ii)} />}
                                {editable && <CpActionButtonComponent variant={"borderless-at-secondary-dim-dark p-2"} size={SIZE.SM}
                                 icon={<CpIco src={IconList.general.trash}/>} onClick={() => { deleteAns(ii) }}/>}
                            </div>
                        </div>
                    </td>
                </tr></tbody>
            </table>
        </>;
    };

    
    return <>
        <div className={'tabQContent ' + (editable ? "" : "ReadOnly") + myClass}>          
            <Ckeditor5Base {...props} editorType="question" data={qData} setData={setQ("qData")}
                enable={editable} setEditor={refCK} debug={false} showStatus={true}
                onEditorReady={onEditorReady} noDragDrop={true} ckImageConfig={ckImageConfig}
                {...edProps} setUpFlag={setUpFlag}/>
            <p></p>
            {(fullQ.SQType===SQT.__SYSQSubType_POL) && ansArr && <DDAnswerContainer dataArr={ansArr} 
                setDataArr={setQ("qAnswers")} editable={editable}
                customRender={customRender} setOver={setOver} onClick={ddClick} ansChoice={ansChoice} />}
            {editable && (fullQ.SQType===SQT.__SYSQSubType_POL) && <div className='flexRowCenterFit'><CpActionButtonComponent variant={"at-primary"} 
                className={"px-5"} icon={<CpIco src={IconList.general.add}/>} title={t('add-an-option')} 
                onClick={newAns}/></div>}
        </div>
    </>;
});
export default CpQEditorOPE;

// use html select, option
/*
const popMenuBtn = (obj, setAnsType, t) => {
    const atOpts = MCAC.__MCATcodes.map((id, ii)=>{
        
        return {
            id: id, 
            jsx: (sel = false, head=false) => <div className='flexRowStart'>
            <CpIco src={toIconList[MCAC.__MCATcodeIcon(id)]} width={'1em'} height={'1em'}/>
            {!head && <div style={{ marginLeft: "5px" }}>{t(MCAC.__UIMCATcodeName(id))}</div>}
            </div>
        };
    });
    return <CpATPullDownMenu sel={obj.type} setSel={setAnsType} opts={atOpts}
        containerStyle={{ width: "120px" }} right={true}
        menuStyle={{ justifyContent: "flex-end" }} headerStyle={{ width: "35px", height:"35px", justifyContent: "center" }} />
};

const toIconList = {
    'inputType/generalEditor':IconList.inputType.generalEditor,
    'inputType/imageEditor':IconList.inputType.imageEditor,
};
*/

/*
        popMenuBtn(obj, (t)=>setAnsType(t, ii), t)}
        {editable && iconButton("", "general/trash", "white", "#749ab3", () => { deleteAns(ii) }, true,
            { transform: "scale(1)",width:"20px" }, { width:"35px",height:"35px",marginLeft:"5px",borderRadius:"8px"})}

        iconButton(t('add-an-option'), "labelDiagram/add", "white", "#2995cd", newAns, true,
            {}, { width: "300px", marginTop: "20px", padding: "8px 10px 8px 10px" })}                

        return {
            id: id, 
            jsx: (sel = false, head=false) => <div className='flexRowStart'>
            {svgIcon2(MCAC.__MCATcodeIcon(id), sel ? "black" : "black", { width: "20px"})}
            {!head && <div style={{ marginLeft: "5px" }}>{t(MCAC.__UIMCATcodeName(id))}</div>}
            </div>
        };
*/