import React, { useRef, useEffect, useState } from 'react';
import { toAry, toInt, toObj, toStr } from '../../../libs/libType';
import {BCPlayer, YTPlayer, HTMLPlayer} from './CpMediaPlayer';

const PageAudioTest = (props) => {
    const {mediaDLs, getMediaDLs, setOnAddMedia} = props;
    //const [myURL, setMyURL] = useState('');
    //const [myMediaID, setMyMediaID] = useState('');
    const [myMediaInfo, setMyMediaInfo] = useState({});
    /*
    useEffect(()=>{
        if (myMediaID !== '' && getMediaDLs) {
            const obj = getMediaDLs([myMediaID]);
            if (obj && obj[myMediaID]) {
                setMyURL(obj[myMediaID].dl);
                //setMyMediaInfo({name:obj[ec.mediaId].name, ext:obj[ec.mediaId].ext});
            };
        };
    },[myMediaID]);

    useEffect(()=>{ 
        if (mediaDLs && mediaDLs[myMediaID]) {            
            setMyURL(mediaDLs[myMediaID].dl);
            //setMyMediaInfo({name:mediaDLs[myMediaID].name, ext:mediaDLs[myMediaID].ext});
        };
    },[mediaDLs]);*/

    const getMediaFromMD = () => {
        if (setOnAddMedia) {
            setOnAddMedia({onAdd:(medias => {
                
                const ms = toAry(medias);
                if (ms.length > 0) {
                    setMyMediaInfo({mediaId:toStr(ms[0].mediaId), name:toStr(ms[0].name),
                        src:toStr(ms[0].dl)})
                };
                setOnAddMedia(0); //stop receiveMedia
            }),maxMedia:1, mimes:['audio']});
        };        
    };       

    return <div className='flexColStart'>
        <button onClick={(e)=>getMediaFromMD()}>get audio</button>
        {(myMediaInfo?.name)?myMediaInfo.name:'no audio selected'}
        {(myMediaInfo?.src)?<HTMLPlayer key={'testhtml'} url={myMediaInfo.src} onSuccess={()=>{}} id={'testAudio'} 
        mode={'audioPlayer'} isAudio={true}/>:''}
    </div>
};

export default PageAudioTest;
