/*
//Production 
$ npm install oidc-client --save
$ npm install git+ssh://git@github.com:OUP/oupc-middle-layer-client.git --save

//Mock
$ npm install https://oupc-middle-layer-client-mock.s3-ap-southeast-1.amazonaws.com/oup-oidc-client-mock-v1.0.3.tgz --save
$ npm install https://oupc-middle-layer-client-mock.s3-ap-southeast-1.amazonaws.com/oup-oupc-middle-layer-client-mock-v1.0.1.tgz --save

"@oup/oupc-middle-layer-client-mock": "https://oupc-middle-layer-client-mock.s3-ap-southeast-1.amazonaws.com/oup-oupc-middle-layer-client-mock-v1.0.1.tgz",
"@oup/oidc-client-mock": "https://oupc-middle-layer-client-mock.s3-ap-southeast-1.amazonaws.com/oup-oidc-client-mock-v1.0.3.tgz",
*/
import { UserManager } from '@oup/oidc-client';
import { MiddleLayerClient } from '@oup/oupc-middle-layer-client';
import { getLang } from '../saga/lang.saga';
import { isStr, toStr } from '../libs/libType';
import { lj } from '../libs/libFormat';
//client_id: 'demo/esas-auth',
//apiKey: 'demo/esas-auth.s5Bcwkot8ASMkgZsmTJDSRKnWQjEOBUG',
//domain: 'http://localhost:3042/',
//endpoint: 'https://acqry9de03.execute-api.ap-southeast-1.amazonaws.com/nightly',


var mlClient = null;
var userManager = null;

const userLoaded = async (user) => {
    //lj('libMidWare.userLoaded', user);
    //lj('libMidWare.userLoaded', (new Date()).getTime());
    // This event is triggered when:  user login successfully, or access token renewal is sucessful
    // You will get a new access token in `user.access_token`, and a new id token in `user.id_token`
};
const userUnloaded = async () => {
    //lj('libMidWare.userUnloaded');
};

//const {REACT_APP_ACCOUNT_MLCLIENTID, REACT_APP_ACCOUNT_DATAENDPOINT, REACT_APP_ACCOUNT_AUTHENDPOINT, REACT_APP_ORIGIN} = process.env;
const getUserManager = (en, settings={}) => {
    const {account_origin, account_dataEndpoint, account_authEndpoint, account_mlClientId} = settings;
    

    const param = {
        authority: account_authEndpoint,
        response_type: 'code',
        scope: 'openid',
        client_id: account_mlClientId,
        //redirect_uri: REACT_APP_ORIGIN+'login',
        redirect_uri: account_origin+'/redirect',
        popup_redirect_uri: account_origin+'/redirect',
        //post_logout_redirect_uri: REACT_APP_ORIGIN+'logout-redirect',
        post_logout_redirect_uri: account_origin+'/logout',
        automaticSilentRenew: true,
        pauseSilentRenewIdleTimeout: 60 * 60 * 1000, //commit it just refresh idle or not
    };
    if(!en) param['ui_locales'] = 'zh-Hant';
    //lj('new UserManager()', {param, time:timeStampNowGMT()});
    userManager = new UserManager(param);
    //debugLog('UserManager-addUserLoaded... T=:'+timeStampNowGMT());
    userManager.events.addUserLoaded(userLoaded);
    //debugLog('UserManager-addUserUnloaded... T=:'+timeStampNowGMT());
    userManager.events.addUserUnloaded(userUnloaded);
    //debugLog('new MiddleLayerClient... T=:'+timeStampNowGMT());
    const mlParam = {
        dataEndpoint: account_dataEndpoint,
        authEndpoint: account_authEndpoint,
    };
    //lj('new MiddleLayerClient()', {param:mlParam, time:timeStampNowGMT()});
    mlClient = new MiddleLayerClient(mlParam);
    //debugLog('mlClient.linkOIDCUserManager... T=:'+timeStampNowGMT());
    mlClient.linkOIDCUserManager(userManager);
    //debugLog('return userManager... T=:'+timeStampNowGMT());
    return userManager;
};
//getUserManager();

export const redirectESASTokenSignin = (en, settings, state) => {
    const um = getUserManager(en, settings);
    //lj('signinRedirectWithESASToken()', {state, time:timeStampNowGMT()});
    um.signinRedirectWithESASToken({state});
};

export const redirectSignin = (en, settings, params) => {
    //lj('redirectSignin userManager... T=:'+timeStampNowGMT());
    const um = getUserManager(en, settings);
    //lj('redirectSignin userManager.signinRedirect... T=:'+timeStampNowGMT());
/*    const params = {
        state: new URL(window.location),// uncomment this if you need to keep state after the roundtrip, you would retrieve the state in user returned by signinRedirectCallback
        prompt:'none'//auto login  
    }*/
    um.signinRedirect(params);
    //lj('redirectSignin userManager.signinRedirect return T=:'+timeStampNowGMT());
};

export const safeSignoutRedirect = async (en, settings) => {
    // prevent race between signout and token renewal
    // see https://github.com/IdentityModel/oidc-client-js/issues/1046#issuecomment-636328361
    const um = getUserManager(en, settings);
    const u = await um.getUser();
    if(u){
        um.stopSilentRenew();
        await um.signoutRedirect();
    }  
    return u;
};
export const getCurrentUser = async () => {
    return userManager && userManager.getUser();
}
export const getUser = async (en, settings) => {
    return getUserManager(en, settings).getUser();
};
export const callbackSignin = async (settings) => {
    return new Promise((resolve, reject) => {
        getUserManager(settings).signinRedirectCallback()
        .then(user => resolve(user))
        .catch(err => reject(err));
    });
};

export const MLState2Path = (state) => {
    try{
        if(isStr(state)){
            const url = new URL(state);
            return url && url.pathname;
        }
        const path = state && state.path;
        if(!path) return '';
        const search = state && state.search;
        return path+toStr(search);
    }catch(e){ console.error(e); };
    return '';
};
