import React, { useEffect } from 'react';
import ATSwitch from '../components/Switch0';
import CpATPullDownMenu from '../components/CpATPullDownMenu';
import CpValueAdder from '../components/CpValueAdder';
import CpQtnTypeMenu from '../components/CpQtnTypeMenu';
import { toInt } from '../../libs/libType';
import { aTErrTxt1} from '../AppUtil';
//import * as UI from '../../libs/libUI';
import * as SQT from '../../consts/ATSysQType';
import * as MCAC from '../../consts/ATQtnAnsTypeMCQ';
//import { svgIcon2 } from '../components/CpATIcoBtn';
import { useUILang } from '../utils/useUILang';
import { toIconList } from './MTSettingMCQ';
import CpIco from '../../AppExPFUser/_components/CpIco';
import CpCheckboxComponent from '../../AppExPFUser/_components/CpCheckboxComponent';
import { SIZE, CHECKBOX_VARIANT } from '../../AppExPFUser/_components/CpExPFConst';

export const MTSetting_OPE = (props) => {
    const { fullQ, setFullQ, editable, clearQAnswers, setShowEditor, showPub, fieldErrs, pub, dispatch, isAT=1 } = props;
    const [ t, uiEn, UILang, setUILang, ut, t2, t3 ] = useUILang();

    const SQType = fullQ.SQType? fullQ.SQType: SQT.__SYSQSubType_OEG;
    const isEssay = SQType === SQT.__SYSQSubType_OEE;
    const correctness = toInt(fullQ.correctness);
    const ansChoice = fullQ.ansChoice?fullQ.ansChoice:MCAC.__MCAC_ACL;
    
    const respFile = fullQ.respFile?true:false;
    const respImage = fullQ.respImage?true:false;
    const respDrawing = fullQ.respDrawing?true:false;
    const respURL = fullQ.respURL?true:false;
    const respText = isEssay?true:(fullQ.respText?true:false);

    const maxFile = toInt(fullQ.maxFile);
    const wordLimit = toInt(fullQ.wordLimit);
    const multiAnswer = toInt(fullQ.multiAnswer);
    const shuffleAnswer = (ansChoice === MCAC.__MCAC_ACB)?0:toInt(fullQ.shuffleAnswer);
    const score = toInt(fullQ.score);
    const allowTeaCopyEdit = toInt(fullQ.allowTeaCopyEdit);

    const _setFullQ = (k) => (v) => setFullQ(k,v);
    const _setMaxFile = (v) => { if (v>10) v=10; _setFullQ('maxFile')(v);}

    useEffect(()=>{
        if (!respText && !respFile && !respImage && !respDrawing && !respURL) {
            setFullQ('respText', true);
        };
    },[respText, respFile, respImage, respDrawing, respURL]);

    // {svgIcon2(MCAC.__MCACcodeIcon(id), sel ? "black" : "black", { width: "16px" })}
    const acOpts = MCAC.__MCACcodes.map((id, ii) => {
        return {
            id: id, 
            jsx: (sel = false) => <div className='flexRowStart'>
            <CpIco src={toIconList[MCAC.__MCACcodeIcon(id)]} width={'1.2em'} height={'1.2em'}/>
            <div style={{ marginLeft: "5px" }}>{t(MCAC.__UIMCACcodeName(id))}</div>
            </div>
        };
    });    
    const exCSS = {color:isEssay?'grey':'black', gap:'5px', alignItems:'center'};
    const labelAnswer = ansChoice ===MCAC.__MCAC_ACB;

    /*
    const respData = [
        {id:'respText', trans:'text'},
        {id:'respFile', trans:'uploadfile'},
        {id:'respImage', trans:'image'},
        {id:'respDrawing', trans:'drawing'},
        {id:'respURL', trans:'hyperlink'},
    ];

    const getChecked = (id) => {
        return id==='respText'?respText
        :id==='respFile'?respFile
        :id==='respImage'?respImage
        :id==='respDrawing'?respDrawing
        :id==='respURL'?respURL
        :false;
    };
    */

    /*
    const renderResp = () => {
        return respData.map((rr, ii)=>{
            return <span key={'resp'+ii} role={"button"}
                className={`d-flex gap-3 ps-4 pb-1 align-items-center`}
                onClick={()=>{ _setFullQ(rr.id)(prev => !prev); }}>
                <CpCheckboxComponent variant={CHECKBOX_VARIANT.SQUARE_BORDER}
                className={"openended-response-checkbox"}
                checked={getChecked()} checkIcon={true} size={SIZE.XXS}
                />
                {t('at-setting.response-type.'+rr.trans)}
            </span>
        });
    };
    */

    const renderResp = (id, trans, resp, isEssay) => {
        return <span key={'resp'+id} role={"button"}
            className={"d-flex gap-3 ps-4 pb-1 align-items-center " + (isEssay?(" user-select-none unclickable " + (id==="respText"? "": " opacity-25")):"")}
            onClick={()=>{ _setFullQ(id)(prev => !prev); }}>
            <CpCheckboxComponent variant={CHECKBOX_VARIANT.SQUARE_BORDER}
            className={"openended-response-checkbox" + (resp?"":" unchecked ")}
            checked={resp} checkIcon={true} size={SIZE.XXS}/>
            {t('at-setting.response-type.'+trans)}
        </span>
    };

    return <div key='opeSet' className={'VMenuTab ' + (editable?'':' unclickable')}>
        <div className="vstack gap-3 minBreakpoint-xs">            
            <CpQtnTypeMenu {...{pub, dispatch}} sel={SQType} setSel={_setFullQ('SQType')} clearQAnswers={clearQAnswers} setShowEditor={setShowEditor}/>
            {(![SQT.__SYSQSubType_POL, SQT.__SYSQSubType_OED].includes(SQType)) && <>
            <div className='flexRowBetween'>
            <div style={{whiteSpace:'pre-wrap'}}>{t('at-setting.this-question-has-correctness')}</div>
            <div><ATSwitch id='SWcorrectness' leftText='' rightText='' disabled={false}
                    selected={correctness} leftValue={0} rightValue={1}
                    setter={_setFullQ('correctness')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
            </div>
            <div>{t('at-setting.type-of-student-response')}</div>
            {renderResp('respText','text',respText,isEssay)}
            {renderResp('respFile','uploadfile',respFile,isEssay)}
            {renderResp('respImage','image',respImage,isEssay)}
            {renderResp('respDrawing','drawing',respDrawing,isEssay)}
            {renderResp('respURL','hyperlink',respURL,isEssay)}
            <div className={'flexRowBetween ' + ((respFile || respImage)?'':' unclickable')} style={{color:(respFile || respImage)?'black':'lightgrey'}}>
                <div>{t('at-setting.max-load')}</div>
                <CpValueAdder value={maxFile} setValue={_setMaxFile} disable={!(respFile || respImage)} min={1}/>
            </div>
            <div className={'flexRowBetween' + (respText?'':' unclickable')} style={{color:respText?'black':'lightgrey'}}>
                <div>{t('at-setting.word-limit')}</div>
                <CpValueAdder value={wordLimit} setValue={_setFullQ('wordLimit')} disable={!respText} min={1}/>
            </div>
            <div className='flexRowBetween'>
                <div>{t('at-setting.fib.totalMark')}</div>
                <CpValueAdder value={score} setValue={_setFullQ('score')} min={1}/>
            </div>
            {showPub?'':aTErrTxt1(fieldErrs?.score)}            
            </>}
            {(SQType === SQT.__SYSQSubType_POL) && <>
                <div className='flexRowBetween'>
                <div>{t('polling-multiple-options')}</div>
                <div><ATSwitch id='SWmulitAns' leftText='' rightText='' disabled={false}
                    selected={multiAnswer} leftValue={0} rightValue={1}
                    setter={_setFullQ('multiAnswer')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
                </div>        
                <div className='flexRowBetween'>
                <div style={{color:labelAnswer?'grey':'black'}}>{t('shuffle-answer-choices')}</div>
                <div><ATSwitch id='SWshuffleAnswer' leftText='' rightText='' disabled={labelAnswer}
                    selected={shuffleAnswer} leftValue={0} rightValue={1}
                    setter={_setFullQ('shuffleAnswer')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
            </div>
            <div className='flexRowBetween'>
                <div>{t('answer-choices-as')}</div>
                <CpATPullDownMenu sel={ansChoice} setSel={_setFullQ('ansChoice')} opts={acOpts}
                    containerStyle={{ width: "100px", top:'35px' }} right={true}
                    menuStyle={{ justifyContent: "flex-end" }} headerStyle={{ width: "100px", justifyContent: "center" }} />
            </div> 
            </>}
            {(SQType === SQT.__SYSQSubType_OED) && <>
                <div className='flexRowBetween'>
                <div style={{whiteSpace:'pre-wrap'}}>{t('at-setting.this-question-has-correctness')}</div>
                <div><ATSwitch id='SWcorrectness' leftText='' rightText='' disabled={false}
                        selected={correctness} leftValue={0} rightValue={1}
                        setter={_setFullQ('correctness')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
                </div>
                <div className='flexRowBetween'>
                <div>{t('at-setting.fib.totalMark')}</div>
                    <CpValueAdder value={score} setValue={_setFullQ('score')} min={1}/>
                </div>                
            </>}
            {isAT?<div className='flexRowBetween'>
                <div>{t3('qtn-opt-allow-teacher-to-copy-edit')}</div>
                <div><ATSwitch id='SWTeaAllowCopy' leftText='' rightText='' disabled={false}
                    selected={allowTeaCopyEdit} leftValue={0} rightValue={1}
                    setter={_setFullQ('allowTeaCopyEdit')} width='40' fontSize='16' color='#2995cd' leftColor='#AFAFAF' /></div>
            </div>:''}
        </div>
    </div>
};

export default MTSetting_OPE;
