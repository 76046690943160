const createLineData = (props) => {
    const {xItems, data, startingPoint, xAxisMargin, valueRangeSize} = props;
    const lineData = {line: [], dot: []};

    let currentX = startingPoint;
    let currentIndex = 0;

    xItems.forEach((xItem, idx) => {
        const found = data.filter((item) => item.referenceId === xItem.id);

        if (!Array.isArray(lineData['line'][currentIndex])) {
            lineData['line'][currentIndex] = [];
            lineData['dot'][currentIndex] = [];
        };

        if (found) {
            found.map(f => {
                if (xItems.length - 1 > idx) {
                    //Last point no need to create a line
                    const nextXItem = xItems[idx + 1];
                    const foundNextPoint = data.find((item) => item.referenceId === nextXItem.id && item.id === f.id);
                    if (foundNextPoint) {
                        lineData['line'][currentIndex] = [...lineData['line'][currentIndex], {
                            x: currentX, y: f.value,
                            points: [currentX, f.value, currentX + xAxisMargin, foundNextPoint.value],
                            ...f
                        }];
                    };
                };

                lineData['dot'][currentIndex] = [...lineData['dot'][currentIndex], {
                    x: currentX,
                    y: f.value,
                    ...f
                }];
            });
        } else {
            if (lineData['line'][currentIndex].length > 0) { currentIndex++ };
        };
        currentIndex++
        currentX += xAxisMargin;
    });

    const line = lineData.line.filter(arr => arr.length > 0);
    const dot = lineData.dot.filter(arr => arr.length > 0);

    return {line, dot};
};

export const getPolyLine = (props) => {
    const {valueRangeSize, maxValue} = props
    const lineChartData = createLineData({...props});
    let result = {line: [], dot: []};

    for (let i = 0; i < lineChartData.line.length; i++) {
        const lines = lineChartData.line[i];
        for (let j = 0; j < lines.length; j++) {
            const {id, x, y, points = [], color, referenceId} = lines[j];
            result['line'].push([
                ["points", `${points[0]}, ${points[1] / maxValue * (valueRangeSize)}, ${points[2]}, ${points[3] / maxValue * (valueRangeSize)}`],
                ['fill', `none`],
                ['stroke', color || ""],
                ['stroke-width', `3`],
                ['group-item-data', `${id}`],
                ['group-x-data', `${referenceId}`],
                ['value', `${y}`]
            ]);
        };
    };

    for (let i = 0; i < lineChartData.dot.length; i++) {
        const dots = lineChartData.dot[i];
        for (let j = 0; j < dots.length; j++) {
            const {id, x, y, points = [], color, referenceId, value} = dots[j];
            result['dot'].push([
                ['cx', `${x}`],
                ['cy', `${y / maxValue * valueRangeSize}`],
                ['r', `5`],
                ['fill', `${color}`],
                ['value', `${value}`],
                ['group-item-data', `${id}`],
                ['group-x-data', `${referenceId}`],
                ['value', `${y}`]
            ]);
        };
    };
    return result;
};