/*export type monthType = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11"
export type monthNameType = "January" |
    "February" |
    "March" |
    "April" |
    "May" |
    "June" |
    "July" |
    "August" |
    "September" |
    "October" |
    "November" |
    "December"

export type dayType = "0" | "1" | "2" | "3" | "4" | "5" | "6"*/

// base on poc Date.jsx
export const getMonthName = (month) => {
    const modelMap = {
        "0": "January",
        "1": "February",
        "2": "March",
        "3": "April",
        "4": "May",
        "5": "June",
        "6": "July",
        "7": "August",
        "8": "September",
        "9": "October",
        "10": "November",
        "11": "December"
    };
    return modelMap[month];
};

export const monToTrans = (name) => {
    const transMap = {
    "January":"calendar.month.1",
    "February":"calendar.month.2",
    "March":"calendar.month.3",
    "April":"calendar.month.4",
    "May":"calendar.month.5",
    "June":"calendar.month.6",
    "July":"calendar.month.7",
    "August":"calendar.month.8",
    "September":"calendar.month.9",
    "October":"calendar.month.10",
    "November":"calendar.month.11",
    "December":"calendar.month.12"
    };
    return transMap[name];
};

export const getMonthInNumeric = (month) => {
    const modelMap = {
        January: "0",
        February: "1",
        March: "2",
        April: "3",
        May: "4",
        June: "5",
        July: "6",
        August: "7",
        September: "8",
        October: "9",
        November: "10",
        December: "11"
    };
    return Number(modelMap[month]);
};

export const getDay = (day) => {
    const modelMap = {
        "0": "Sun",
        "1": "Mon",
        "2": "Tue",
        "3": "Wed",
        "4": "Thu",
        "5": "Fri",
        "6": "Sat"
    };
    return modelMap[day];
};

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const dayToTrans = (day) => {
    const dayMap = {
        "Sun":"calendar.week.1",
        "Mon":"calendar.week.2",
        "Tue":"calendar.week.3",
        "Wed":"calendar.week.4",
        "Thu":"calendar.week.5",
        "Fri":"calendar.week.6",
        "Sat":"calendar.week.7"
    };
    return dayMap[day];
};

export const getTimes = () => {
    let x = 30; //minutes interval
    let times = []; // time array
    let tt = 0; // start time
    let ap = ['AM', 'PM'];
    for (let i = 0; tt < 24 * 60; i++) {
        let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        let mm = (tt % 60); // getting minutes of the hour in 0-55 format
        //times[i] = ("0" + ((hh == 12) ? 12 : hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + " " + ap[Math.floor(hh / 12)];
        times[i] = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2);
        tt = tt + x;
    };
    return times;
};

export const getTwoDigitDate = (date) => {
    if (date < 10) {
        return `0${date}`;
    };
    return date;
};
export const getTwoDigitMonth = (month) => {
    month += 1
    if (month < 9) {
        return `0${month}`;
    };
    return month;
};
export const getTodayDateTime = (addDay) => {
    let dateTime = new Date(new Date().setHours(0, 0, 0, 0));
    if (addDay !== undefined) {
        dateTime = new Date(dateTime.setDate(dateTime.getDate() + addDay));
    };
    return {
        year: dateTime.getFullYear(),
        month: getMonthName(dateTime.getMonth().toString()),
        date: dateTime.getDate(),
        time: getTimes()[(dateTime.getHours() * 2) + (dateTime.getMinutes() >= 30 ? 1 : 0)]
    };
};

export const formatedDateTime = (addDay) => {
    const datetime = getTodayDateTime(addDay);
    const {year, month, date, time} = datetime;
    return `${("0" + date).slice(-2)}/${("0" + getMonthInNumeric(month)).slice(-2)}/${year} ${time}`;
};