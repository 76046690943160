import React, {useEffect, useState, useMemo} from "react";
import {Button, CloseButton} from "react-bootstrap";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { stopEvent } from "../../libs/libUI";
import CpActionButton from "../_components/CpActionButton";

import CpShareOffcanvas from "../_components/CpShareOffcanvas";
import TabAssignmentSettingBehaviour from "./TabAssignmentSettingBehaviour";
import TabAssignmentSettingClass from "./TabAssignmentSettingClass";
import TabAssignmentSettingTime from "./TabAssignmentSettingTime";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { hasErr, normalizeEdit, useFields } from "../../AppExPf/utils/useEditDraftPub";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { ReduxBind } from "../../saga/ReduxState";
import { objEntries, objKeys, toAry, toObj, toStr } from "../../libs/libType";
import { ATErrsDiv, preJS, autoId, unuseMemo, BtnPopDev } from "../../AppExPf/AppUtil";
import { normalizeAsm, validateAsm } from "../../consts/EpAssigmentErr";
import CpSharedModal from "../_components/CpSharedModal";
import { CpQrCode } from "./LayerAssignmentShare";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { _WebRoot } from "../../consts/ATConstReact";
import { _pathAssign } from "../../consts/ATConstReact";
import { asmStatStarted, asmStateEnded } from "./TabTeacherAssignmentSettings";
import { _ARS_CLOSE_RESUME } from "../../consts/ATConstsAssignment";

export const CpAssignOffCanvas = ReduxBind(props => {
    const [t] = useUILang();

    const {dispatch, useAsmEdit} = props;
    const {editAsm, setEditAsm, onEditAsm} = useAsmEdit;
    const initTab = useAsmEdit.tab||'cls';
    const [tab, setTab] = useState(initTab);
    const asm = editAsm;
    const ex = asm?.Exer; 
    const Exer = toObj(ex);

    const vis = asm? 1: 0;
    const close = () => setEditAsm(0);

    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts] = useFields(); 

    const students = toObj(opts?.students);
    const allIds = objKeys(students); 
    const ticks = toAry(fields?.studentIds);
    const whole = useMemo(() => ticks.sort().join(',') === allIds.sort().join(','), [opts?.students, fields?.studentIds]);

    const classId = toStr(fields?.classId);

    const loadOpts = async (param) => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getAssignmentOpts', param);
        setOpts(os => ( {...toObj(os), ...toObj(res?.opts) } ));
    };

    useEffect(() => { asm && setFields(normalizeAsm(asm)); setFieldErrs({}); }, [asm]);
    useEffect(() => { vis && loadOpts({}); setTab(initTab); }, [vis]);
    useEffect(() => { vis && classId && loadOpts({classId}); }, [vis, classId]);
 
    const clickSaveAsm = async () => {
        const [asm, errs] = normalizeEdit(fields, opts, [], normalizeAsm, validateAsm, setFields, setFieldErrs);
        
        if(!hasErr(errs)){
            const [res, err] = await asyncApiCallLoad_(dispatch, '/putAssignment', {asm: {...asm, Exer:ex}});
            const newA = res?.asm;
            if(newA){
                if(onEditAsm) onEditAsm(newA);
                close();
            }else{
                setFieldErrs(toObj(res?.fieldErrs));
            } 
        }
    };

    const {studentIds, Exer:e, ...fs} = toObj(fields);
    const isNew = fs.isNew;
    const loading = 0;
    const canSave = !loading;

    //const pend = !asmStatStarted(asm);
    //const notEnd = !asmStateEnded(asm);
    const tabItems = unuseMemo(() => ([
        ['cls', t("assignment-settings-class-student")],
        ['time', t("assignment-settings-time")], 
        ['set', t("assignment-settings-behaviour")], 
    ]), [asm]);

    const subProps = {fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs, opts, setOpts, Exer, ticks}; 
    if(!vis) return '';
    return <CpShareOffcanvas className={"CpAssignOffCanvas offcanvas-mw-90"} show={vis} placement={"end"} disableClose={true} handleClose={close}
        title={<>
            <div className={"d-flex justify-content-between align-items-center w-100 p-3 bg-dim-100 fs-5 user-select-none"}>
                <span>{t("assignment-settings")}</span>
                <CloseButton onClick={close}/>
            </div>
            <div className={"d-flex gap-3 fs-6 semi-bold w-100 px-3 bg-dim-100 border-bottom border-2 border-dim"}>
                {tabItems.map(([key, lbl]) => 
                <div key={key} role={"button"} onClick={() => setTab(key)} 
                    className={`setting-tab-item position-relative pb-2 user-select-none ${tab === key? "active": "text-dim-350"}`}>
                    {lbl} {(key==='cls') && 
                        <span className={`text-light px-3 rounded-pill ${tab === key? "bg-exercise-third-btn": "bg-dim-350"}`}>
                        {whole? t("assignment-settings-all"): toAry(ticks).length }</span>}
                </div>)}
                <BtnPopDev txt="asm">{preJS({fs},3)}</BtnPopDev>
                <BtnPopDev txt="Exer">{preJS({e},3)}</BtnPopDev>
                <BtnPopDev txt="studentIds">{preJS({studentIds}, 3)}</BtnPopDev>
            </div>
{/*
            <div className={"d-flex gap-3 fs-6 semi-bold w-100 px-3 bg-dim-100 border-bottom border-2 border-dim"}>
                {tabItems.map((item) => 
                <div key={item.id} role={"button"}
                    className={`setting-tab-item position-relative pb-2 user-select-none ${tab === item.tab? "active": "text-dim-350"}`}
                    onClick={() => handleActive(item.tab)}>{item.title} {item.id === "1" &&
                        <span className={`text-light px-3 rounded-pill ${tab === item.tab? "bg-exercise-third-btn": "bg-dim-350"}`}>
                        {(checkList.length === studentList.length ? t("assignment-settings-all") : checkList.length)}</span>}
                </div>)}
            </div>
*/}
        </>} 
        footer={<div className={"d-flex gap-2 justify-content-center"}>
            <Button variant="gray-body-color border w-100 text-nowrap" onClick={close}>{t("cancel")}</Button>
            <Button variant={`${canSave? "exercise-third-btn": "dim-400 pe-none"} semi-bold d-flex gap-2 w-100 align-item-center text-nowrap`} onClick={clickSaveAsm}>
                {isNew? <span className={"fs-5"}><CpIco src={loading? IconList.general.loading: IconList.general.assign}/></span>: ''}
                <span>{t(isNew? "assign": "update")}</span>
            </Button>
        </div>} 
        >
        {ATErrsDiv(fieldErrs)}
        <TabAssignmentSettingClass {...{...subProps, vis:(tab==='cls')}} />
        <TabAssignmentSettingTime {...{...subProps, vis:(tab==='time')}} />
        <TabAssignmentSettingBehaviour {...{...subProps, vis:(tab==='set')}} />
        </CpShareOffcanvas>;
});

export const useAssignmentEdit = () => {
    const [editAsmTab, setEditAsmTab] = useState();
    const setEditAsm = (editAsm, onEditAsm, tab) => {
        setEditAsmTab({editAsm, onEditAsm, tab, });
    } 
    const {editAsm, onEditAsm, tab} = toObj(editAsmTab); 
    return {editAsm, onEditAsm, tab, setEditAsm};
};

export const CpChangeSettingsCTA = (props => {
    const {dispatch, asm, useAsmEdit, reload, ...btnProps} = props;
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const { setEditAsm, } = useAsmEdit; 

    const clickAsmEdit = e => { stopEvent(e); setEditAsm(asm, reload ); };
    return <CpActionButton title={t("assignment-cta-change-settings")} iconPath={IconList.assignment.changeSettings}
        className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={clickAsmEdit} />;
});

const CpExerciseEditAssignCTA = (props => {
    const {ex, assign, lock} = props;
    const [t] = useUILang();

    const [newAsm, setNewAsm] = useState();

    const onSaveAsm = asm => setNewAsm(asm);
    const useAsmEdit = useAssignmentEdit(onSaveAsm);
    const {editAsm, onEditAsm, tab, setEditAsm} = useAsmEdit; 

    const goNewAsm = () => setEditAsm(newAsmDraft(ex), onSaveAsm);

    useEffect(() => {assign && goNewAsm()}, [assign]); // auto New
    const clickAssign = e => { stopEvent(e); goNewAsm(); } //click New

    const CpAssignButton = <CpActionButton iconPath={IconList.general.assign} title={t("assign")} hiddenText={false} onClick={clickAssign}
            className={`btn ${lock? "bg-dim-400 text-white pe-none" : "btn-exercise-third-btn exercise-third-btn-shadow"}  
            gap-2 rounded semi-bold user-select-none`} />

    return <>{ /*AssignmentSettingContainer*/ }
        {CpAssignButton /* 'assign' Button */}
        {editAsm? <CpAssignOffCanvas {...{useAsmEdit}} />: ''}  
        {newAsm? <CpAssignModal {...{asm: newAsm, close: () => setNewAsm(0)}} />: ''}
    </>;
});
export default CpExerciseEditAssignCTA;

const CpAssignModal = ReduxBind(props => {
    const {dispatch, close} = props;
    const [t] = useUILang();

    const asm = toObj(props.asm);
    const link = (props.settings()?.account_origin) + _pathAssign(asm.assignId);

    const clickClose = e => { stopEvent(e); close(); };

    const [copied, setCopied] = useState();
    const click2Copy = e => { stopEvent(e); navigator.clipboard.writeText(link); setCopied(1); };
    const clickGoAssignment = e => { stopEvent(e); close(); dispatch(urlPush_Replace(link)); };

    return <CpSharedModal show={true} footer={<></>} scrollable={true}>
        <div className={"px-3 pt-4 bg-dim-100"}>
            <div className={"d-flex flex-column gap-4"}>
                <div className={"d-flex flex-column gap-3 w-100"}>
                    <div className={"d-flex justify-content-between"}>
                        <span className={"d-flex justify-content-end opacity-0 pe-none"}><CloseButton onClick={clickClose}/></span>
                        <span className={"d-flex justify-content-center text-exercise-third-btn fs-1"}><CpIco src={IconList.general.assign}/></span>
                        <span className={"d-flex justify-content-end"}><CloseButton onClick={clickClose}/></span>
                    </div>
                    <div className={"d-flex w-100 fs-4 semi-bold justify-content-center user-select-none"}>{t("assignment-settings-exercise-assigned-successfully")}</div>
                </div>
                <div className={"text-center"}>
                    <div className={"mb-2 user-select-none"}>{t("assignment-settings-share-your-assignment")}</div>
                    <CpQrCode {...{link}} /> {/*<img src={"/static/img/demo/assignment/qr.png"}/>*/}
                </div>
                <div className={"d-flex align-items-center gap-3 text-truncate"}>
                    <span className={"d-flex flex-grow-1 border align-items-center gap-2 p-2 text-truncate bg-white"}>
                        <span className={"text-dim-400 fs-3"}><CpIco src={IconList.contentType.link}/></span>
                        <span className={"d-flex flex-column semi-bold text-truncate"}>
                            <span className={"fs-9 text-exercise-third-btn user-select-none"}>{t("assignment-settings-copy-this-link-to-share")}</span>
                            <span className={"text-truncate"}>{link}</span>
                        </span>
                    </span>
                    <span className={"fs-6"} role={"button"} onClick={click2Copy}><CpIco src={copied? IconList.general.correct: IconList.general.copyText}/></span>
                </div>
                <div className={"d-flex justify-content-center align-items-center gap-2 text-exercise-third-btn fs-6 semi-bold user-select-none"}
                     role={"button"} onClick={clickGoAssignment}>
                    <span>{t("assignment-settings-go-to-assignment")}</span>
                    <CpIco src={IconList.general.next}/>
                </div>
            </div>
        </div>
    </CpSharedModal>
});

const newAsmDraft = Exer => {
    const {EId} = toObj(Exer);
    return {isNew:1, EId, Exer,
        AHints: 1,
        AResume: _ARS_CLOSE_RESUME, 
        APass:true, passUnit:'p', passValue:60};
};

