import React, { useState } from 'react';
import VMenu from '../components/CpVMenu';
import * as SQT from '../../consts/ATSysQType';
import { iconButton } from '../components/CpATIcoBtn';

import MTSettingMCQ from './MTSettingMCQ';
import MTSettingFIB from './MTSettingFIB';
import MTSettingLBD from './MTSettingLBD';
import MTSettingOPE from './MTSettingOPE';

import MTKeywords from './MTKeywords';
import MTHintAnswerKey from './MTHintAnswerKey';

import { useUILang } from '../utils/useUILang';
import CpMetadataModalComponent from '../../AppExPFUser/_components/CpMetadataModalComponent';
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpActionButtonComponent from '../../AppExPFUser/_components/CpActionButtonComponent';
import CpIco from '../../AppExPFUser/_components/CpIco';

const TabQtnOptions = (props) => {
    const {fullQ, Q, width, height, editable, isAT=true} = props;
    const [ t ] = useUILang();

    const [vis, setVis] = useState(0);

    if(!fullQ || !Q) return <div></div>;

    const qt = fullQ?.SQType || '';
    const menuArrUnknown = [{name:'unknown', jsx:<div>unknown options</div>}];

    const menuArrMCQ = [
        { name: t('setting'), jsx: <MTSettingMCQ {...props}/> },
        { name: t('tags'), jsx: <CpMetadataModalComponent {...props} /> },
        { name: t('keywords'), jsx: <MTKeywords {...props} /> },
        { name: t('hint-answer-key'), jsx: <MTHintAnswerKey {...props} /> },
    ];
    const menuArrFIB = [
        { name: t('setting'), jsx: <MTSettingFIB {...props}/> },
        { name: t('tags'), jsx: <CpMetadataModalComponent {...props} /> },
        { name: t('keywords'), jsx: <MTKeywords {...props} /> },
        { name: t('hint-answer-key'), jsx: <MTHintAnswerKey {...props} /> },
    ];    
    const menuArrLBD = [
        { name: t('setting'), jsx: <MTSettingLBD {...props}/> },
        { name: t('tags'), jsx: <CpMetadataModalComponent {...props} /> },
        { name: t('keywords'), jsx: <MTKeywords {...props} /> },
        { name: t('hint-answer-key'), jsx: <MTHintAnswerKey {...props} /> },
    ];  
    const menuArrOPE = [
        { name: t('setting'), jsx: <MTSettingOPE {...props}/> },
        { name: t('tags'), jsx: <CpMetadataModalComponent {...props} /> },
        { name: t('keywords'), jsx: <MTKeywords {...props} /> },
        { name: t('hint-answer-key'), jsx: <MTHintAnswerKey {...props} /> },
    ];  
    const optMenu = 
        ((qt === SQT.__SYSQType_MCQ)?menuArrMCQ:
        (qt === SQT.__SYSQSubType_MCS)?menuArrMCQ:
        (qt === SQT.__SYSQSubType_MCT)?menuArrMCQ:
        (qt === SQT.__SYSQSubType_FIB)?menuArrFIB:
        (qt === SQT.__SYSQType_LBD)?menuArrLBD:
        (qt === SQT.__SYSQSubType_LBT)?menuArrLBD:
        (qt === SQT.__SYSQSubType_LBD)?menuArrLBD:
        (qt === SQT.__SYSQType_OPE)?menuArrOPE:
        (qt === SQT.__SYSQSubType_OED)?menuArrOPE:
        (qt === SQT.__SYSQSubType_OEG)?menuArrOPE:
        (qt === SQT.__SYSQSubType_OEE)?menuArrOPE:
        (qt === SQT.__SYSQSubType_POL)?menuArrOPE:
        menuArrUnknown);

    if (isAT) optMenu.splice(1,1);
    
    let bc = ' navsidebackR' + (vis?'':' d-none');
    let b = ' navsideR'+ (vis?'':' invis');
    return <>
        <div className='QtnOptionsTop'>
            <VMenu arr={optMenu} width={width?width:"350px"} height={height?height:"100%"} expands={[0]}/>
        </div>
        <div className='QtnOptionsBtn'>
            <CpActionButtonComponent variant={"borderless-at-primary-dim"} title={t('setting')}
                icon={<CpIco src={IconList.general.setting}/>} onClick={()=>setVis(!vis)}/>
        </div>
        <div className='' >
            <div className={bc} onClick={()=>setVis(0)} />
            <nav className={b} style={{paddingTop:isAT?'60px':'0px', paddingBottom: '60px', minWidth:'350px'}}> 
                <div className='flexRowEnd'>{iconButton('', 'general/close', 'grey', 'transparent', ()=>setVis(0), true, {width:'30px', height:'30px'})}</div>
                <VMenu arr={optMenu} width={width?width:"350px"} height={height?height:"100%"} expands={[0]}/>
            </nav>
        </div>
    </>
};

export default TabQtnOptions;

//{iconButton(t('setting'), 'general/setting', '#749ab3', 'transparent', ()=>setVis(!vis), true, {width:'20px', height:'20px', marginRight:'5px'})}