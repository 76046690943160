import React from 'react';
import { __langEn, __langCt } from './lang.list';

/* ==============================================================================================
usage:
    lang_set(lang); //redux action, change UI Lang (En/Ct)
        set Lang is instance, no saga needed
    t2(txtcode); // convert text code to pure text of UI lang,
        i.e 't2('pleaselogin') => 'please login' / '請登入'
    t(txtcode); // convert text code to styled text div of UI lang,
        i.e 't('pleaselogin') => <div enCss >please login</div> / <div ctCss>請登入</div>
    langStyle(); // get css style of current UI lang
        i.e langStyle() => {fontFamily:en font...} / {fontFamily: cn font ...}
    langClass(); // get css class name of current UI lang
        i.e langClass() => ' fontEn' / ' fontCt';
*/

// ==== ==== Types; Type of Dispatch/Put Actions ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
const LANG_SET = 'LANG_SET'; //command

// ==== ==== reducer; to update State per action ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
var __lang = 'En';
var __langList = __lang==='En'?__langEn:__langCt;
const initState = __lang; // 'Loading' list
export const langReducer = (state = initState, action) => {
    switch (action.type) {
        case LANG_SET:
            __lang = (action.lang === 'Ct')?'Ct':'En';
            __langList = (__lang === 'Ct')?__langCt:__langEn;
            window.localStorage.setItem('Lang', __lang);
            return __lang;
        default:
            return state;
    }
};
export const getLang = () => __lang; //Direct get Lang, without Dispatcher/Saga

// ==== ==== action; to be dispatch() By UI / put() by Services ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const lang_set = (lang) => { return {type: LANG_SET, lang}; };

// ==== ==== saga; watcher saga watch for action / action saga call() service, put() action= === ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
// export function* watchAuthSaga() {
//     yield takeLatest(LANG_SET, langSetSaga);
// };
// export function* langSetSaga(action) { call api save user lang to backend};

// ==== ==== supporting Service  to be call by saga ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====

// ==== ==== Shortcut ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const langStyle = () => {
    return __lang==='Ct'
        ? {fontFamily:"'MHei HK Medium', Ubuntu, 'Encode Sans'"}
        : {fontFamily:"Ubuntu, 'MHei HK Medium', 'Encode Sans'"};
};
//export const langClass = () => { return __lang === 'En' ? ' fontEn' : ' fontCt'};
export const langClass = ss => {
    if (ss === 'fr') return __lang === 'En' ? ' fontEnFr':' fontCtFr';
    if (ss === 'so') return __lang === 'En' ? ' fontEnSo':' fontCtSo';
    if (ss === 'tr') return __lang === 'En' ? ' fontEnTr':' fontCtTr';
    return __lang === 'En' ? ' fontEn' : ' fontCt';
};
export const langClass2 = ss => {
    if (ss === 'fr') return ' fontEnFr';
    if (ss === 'so') return ' fontEnSo';
    if (ss === 'tr') return ' fontEnTr';
    return __lang === 'En' ? ' fontEn' : ' fontCt';
};
const fd = (t, className, replace) => {
    const result = (replace !== ''? t.replace('{r}', replace):t);
    return <div id={'t'+(++tCnt)} className={className} style={langStyle()}>{result}</div>;
}

//Display Short Hands
export const t = (k, className='', replace='') => fd(__langList[k] || k, className, replace);
export const t2 = (k) => (__langList[k] || k);
export const t3 = (k) => (__langList[k] || k);
var tCnt = 0;
/*
error () => { return <div></div> }
ok () => <div></div>

export const t = (k, className='', replace='') => {
    const t1 = __langList[k] || k;
    const t2 = (replace !== ''? t1.replace('{r}', replace):t);
    return <div id={'t'+(++tCnt)} className={className} style={langStyle()}>{t2}</div>;
};
*/

var __cardLangCode = 'En';
export const setCardLang = l => { __cardLangCode = (l === 'Ct') ? 'Ct' : 'En'; };
export const getCardLang = () => __cardLangCode;
export const cardFontStyle = en => {
  return __cardLangCode==='Ct'?(en?' cardFontEnCt':' cardFontCt'):(en?' cardFontEnEn':' cardFontEn');
};






