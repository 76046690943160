import React, {useState} from "react";
import { Button } from "react-bootstrap";
import * as UI from '../../libs/libUI';

import { clickUrl, preJS } from "../../AppExPf/AppUtil";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { _CollectionRoot, _WebRoot } from "../../consts/ATConstReact";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { toInt, toStr } from "../../libs/libType";
import { stopEvent } from "../../libs/libUI";
import CpColorSelect from "../_components/CpColorSelect";
import { CpSettingModalSection, CpSettingModalTabContainer, CpSettingModalTitle } from "../_components/CpSettingModalTabCps";
import CpSharedModal from "../_components/CpSharedModal";
import SharedModalHeader from "../_components/CpSharedModalHeader";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { ReduxBind } from "../../saga/ReduxState";
import CpIco from "../_components/CpIco";


const CpCollectionThumb = ReduxBind(props=> { //ExerciseCollectionThumbnail
    const {dispatch, idx, ECol, setParaNew, reload} = props;
    //const {backgroundColor, title, number} = collectionsThumbnailList[idx % 3];
    const {ECColor, ECBgColor, ECnt, ECName, code, EColId, userId, schoolId, ECType, dateMod, ...ec} = ECol;
    const css = {
        color: (ECColor || '#000'),
        backgroundColor: (ECBgColor || '#ddd'),
    };
    
    const enterCollection = clickUrl(dispatch, _CollectionRoot + '/' + EColId); 
    //dispatch({type: PageActionKind.UPDATE_PAGE, payload: PAGE.SAVED_COLLECTION_SEARCH_PAGE})

    const handleCollection = e => { UI.stopEvent(e); setParaNew({EColId, ECol, EColtype:"edit"}); }; //setModalTitle(t("collection"));

    const popProps = {dispatch, EColId, reload};
    return <span role={"button"} className={"library-list-thumbnail position-relative"} style={css} onClick={enterCollection}>
      <div className={"d-flex justify-content-between h-100 p-3"}>
          <span className={""}><h5 className={"text-wrap two-line"} style={css}>{toStr(ECName)}</h5></span>
          <span className={"d-flex flex-column justify-content-between align-items-end"}>
              <span className={"d-flex gap-3 fs-6 text-dim-400"}>
                  {/*   <CpCollectionEditCTA {...popProps} />  "old EDIT icon"*/}
                    <span role={"button"} className={`text-dim-400 card-common-btn card-collections-tool-btn`} onClick={handleCollection}>
                        <CpIco src={IconList.general.edit}/>
                    </span>
                  <CpCollectionDeleteCTA {...popProps} />
              </span>
              <span className={"mt-3"}><h4 className={"mb-0"}>{toInt(ECnt)}</h4></span>
          </span>
      </div>
    </span>;
});
export default CpCollectionThumb;

const CpCollectionDeleteCTA = (props => { //base on poc CollectionDeleteCTA  
    
    const { dispatch, EColId, reload } = props;
    const [t] = useUILang();
    const [open, setOpen] = useState(0);
    const handleOpen = e => { stopEvent(e); setOpen(1); };
    const handleClose = e => { stopEvent(e); setOpen(0); };

    const clickDel = async e => { stopEvent(e); 
        setOpen(0);
        const [res, err] = await asyncApiCallLoad_(dispatch, '/deleteECol', {EColId});
        
        reload();
    };

    return <>
        <span role={"button"} className={`text-dim-400 card-common-btn card-collections-tool-btn`} onClick={handleOpen}>
            <CpIco src={IconList.general.trash}/>
        </span>
        {open? 
        <CpSharedModal show={open}  scrollable={true} 
        header={        
            <SharedModalHeader title={t("delete")} iconPath={IconList.general.trash} handleClose={handleClose}/>
        } 
        footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
            <Button variant="gray-body-color w-100 border" onClick={handleClose}>{t("cancel")}</Button>
            <Button variant={`danger btn-block w-100`} onClick={clickDel}>{t("delete")}</Button>
        </div>}>
            <CpSettingModalTabContainer gap={4}>
                <div className={"mb-3"}><CpSettingModalTitle>{t("delete-message").replace('%s',t('delete-items.collection'))}</CpSettingModalTitle></div>
            </CpSettingModalTabContainer>
        </CpSharedModal>
        :''}
    </>
});
