const transMapStr = {
"sidemenu-my-assignments": "sidemenu-my-exercises",
"assignment-cta-open-assignment": "assignment-cta-open-exercise",
"assignment-assigned-by" :"assignment-exercise-by",
"report-assignment-overview": "report-exercise-overview",
"report-assignments-progress": "report-exercises-progress",
"report-assignment": "report-exercise",
"report-no-of-assignment": "report-no-of-exercise",
"report-assignments-score": "report-exercises-score",
"report-assigned-by": "report-exercise-by",
"view-all-assignments": "view-all-exercises",
"assignments-due-soon": "exercises-due-soon"
};

export const getTransMap = (key, isStt) => {
    return (transMapStr[key] && isStt)? transMapStr[key]: key;
};