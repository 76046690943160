import React, {useState, useMemo, useEffect} from "react";
import {Container} from "react-bootstrap";

//import SubjectOverviewByStudentSharedData
//    from "../../poc/screens/shared/includes/chart/sample-data/subjectOverviewByStudentSharedData.json";
import { IconList } from '../../consts/ATIconListToUsePoc';
import { useUILang } from "../../AppExPf/utils/useUILang";
import ScrollContainer from "../_components/CpScrollContainer";
import CpActionButton from "../_components/CpActionButton";
import { CpAssignmentList } from "../EPAssign/CpAssignmentList";
import ChartSubjectOverview from "./ChartSubjectOverview";
import { ReduxBind } from "../../saga/ReduxState";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { _pathAssign, _pathReport } from "../../consts/ATConstReact";
import { stopEvent } from "../../libs/libUI";
import { isAry, toAry, toObj, toStr } from "../../libs/libType";
import { preJS, autoId } from "../../AppExPf/AppUtil";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { toOverData1c } from "../EPReports/data/overData1";
//import SubjectOverviewChart from "../../poc/screens/shared/includes/chart/SubjectOverviewChart";
import { expfRoles } from "../../saga/auth.saga";
import { getTransMap } from "../../libs/libTransMap";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";
import { RT_over } from "../EPReports/PageReports";

const CpDashboardStudentBody = ReduxBind(props => { // base on poc DashboardStudentBody
    const { dispatch } = props;
    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch);
     
    const [isThr, isStt, uRole ] = expfRoles(props);
    const {year, classId, subjItems, AMSubjId, } = props;

    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const stu = props.userId();
    const useInf = toObj(props.user());
    const stuName = toStr(ut(useInf.nameEn, useInf.name)).trim();

    const { dueAsms, pubAsms} = props;
    const tabProps = { ...props, tagMSets, useTagMSets, 
        t, uiEn, UILang, setUILang, ut};

    const clickGoAsms = e => {stopEvent(e); dispatch(urlPush_Replace(_pathAssign())); };
    const clickGoRpts = e => {stopEvent(e); dispatch(urlPush_Replace(_pathReport())); };

    const [rptDatas, setRptDatas] = useState();
    const reload = async (caller) => {
        if(!(AMSubjId && classId && stu)) return;
        const args = { year, MSubj:AMSubjId, cls:classId, stu, rpt: RT_over, caller };
        const [res, err]  = await asyncApiCallLoad_(dispatch, '/getReports', args);
        setRptDatas({...rptDatas, ...res?.datas});
    };
    useEffect(() => { reload('dashStu'); }, [AMSubjId, classId, stu]);

    const {stuOverDataA_ } = useMemo(() => toObj(rptDatas), [rptDatas]);

    const stuOverDataA = useMemo(() => toOverData1c(stuOverDataA_, stu, stuName), [stuOverDataA_, stuName, stu, uiEn]);
    //const stuOverDataA = SubjectOverviewByStudentSharedData;

    return <ScrollContainer className={"scroll-container h-100 d-flex flex-column w-100 overflow-auto gap-3 pt-3 pt-lg-0"}>
            <Container fluid={true} className={"fade-in-container"}>
                <div className="dashboard-container flex-column flex-xl-row">
                    {/*Left top*/}
                    <div className={`dashboard-student-left`}>
                        <div className={"d-flex flex-column border rounded-light py-3 px-3 overflow-hidden"}>
                            <div className={"d-flex justify-content-between flex-wrap align-items-center"}>
                                <h4 className={"m-0"}>{t("report-subject-overview")}</h4>
                            </div>
                            <div className={"d-flex justify-content-center align-items-start"}>
                                <ChartSubjectOverview key={autoId()} {...stuOverDataA }/>
                                {/*<SubjectOverviewChart key={autoId()} {...stuOverDataA }/>*/}
                            </div>
                            <div className={"d-flex justify-content-end"}>
                                <CpActionButton title={t("view-my-progress")} iconPath={IconList.general.search}
                                    className={"btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none"}
                                    onClick={clickGoRpts}/>
                            </div>
                        </div>
                    </div>
                    {/*Right top*/}
                    <div className={`dashboard-student-right`}>
                        <div className={"d-flex flex-column border rounded-light py-3"}>
                            <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3 px-3 h-35"}>
                                <h4 className={"m-0"}>{t(getTransMap("assignments-due-soon",isStt))}</h4>
                                <CpActionButton className={"btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none"} 
                                    title={t(getTransMap("view-all-assignments",isStt))} iconPath={IconList.general.search} hiddenText={true}
                                    onClick={clickGoAsms}/>
                            </div>
                            {
                            /*//All not submitted, order: near to no due date*/
                            toAry(dueAsms).length
                                ?<CpAssignmentList {...{...tabProps, asms:dueAsms, onHr:1}} />
                                :<div className={"d-flex align-items-center justify-content-center w-100 p-4 text-dim-400 fs-5"}>{t("no-result")}</div>
                            }
                        </div>
                        <div className={"d-flex flex-column border rounded-light mt-3 mb-5 py-3"}>
                            <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3 px-3 h-35"}>
                                <h4 className={"m-0"}> {t("recently-published-results")}</h4>
                                <CpActionButton className={"btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none"}
                                    title={t(getTransMap("view-all-assignments",isStt))} iconPath={IconList.general.search} hiddenText={true} 
                                    onClick={clickGoAsms}/>
                            </div>
                            {
                            /*//All Result Published, order: published result date time(near to far)*/
                            toAry(pubAsms).length
                                ?<CpAssignmentList {...{...tabProps, asms:pubAsms, onHr:1}} />
                                :<div className={"d-flex align-items-center justify-content-center w-100 p-4 text-dim-400 fs-5"}>{t("no-result")}</div>
                            }
                        </div>
                    </div>
                </div>
            </Container>
        </ScrollContainer>
});

export default CpDashboardStudentBody;