import {useMemo} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { HKDayDiff, daysDiff_2min, formatDate } from "../../libs/libTime";
import { _WST_SUBMIT } from "../../consts/ATConstsAssignment";
import { timeStampNowGMT } from '../../libs/libTime';

const CpExpireTag = (props) => { // base on poc ExpireTag.tsx
    const [t, lang] = useUILang();
    const {days} = props;
    const item = useMemo(() => {
        if (days < 0) {
            return {style: "bg-white border-tag-danger text-tag-danger", title: t("assignment-tag-expired")}
        } else if (days === 0) {
            // "assignment-tag-due-today"
            return {style: "bg-white border-tag-danger text-tag-danger", title: t("assignment-tag-expire-today")}
        } else if (days >= 1 && days <= 8) {
            return {
                style: "bg-white border-tag-danger text-tag-danger",
                title: t("assignment-tag-expire-in-days").replace("%s", days.toString())
            };
        };
        return {style: "", title: ""};
    }, [lang, days]);

     return (days<=8)?<small className={`semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light ${item.style}`}>{item.title}
    </small>:'';
};
export default CpExpireTag;

export const getExpTag = (asm) => {
    
    const now = timeStampNowGMT();
    const due = asm?.time2beEnd;
    const hasDue = asm?.timeEnd;
    const isExpired = hasDue && ((due-now) < 0);
    const isYetSubm = (asm?.work?.WState !==_WST_SUBMIT);
    //const daysRemain = daysDiff_2min(now, due);
    const daysRemain2 = HKDayDiff(now, due);
    
    return (isYetSubm && hasDue )? (isExpired)? <CpExpireTag days={-1}/>:<CpExpireTag days={daysRemain2}/>:'';
};


export const isExpired = (asm) => asm?.timeEnd && daysDiff_2min(timeStampNowGMT(), asm?.time2beEnd) < 0;
//export const noAttempt = (asm) => asm?.timeEnd && daysDiff_2min(timeStampNowGMT(), asm?.time2beEnd) < 0 && (asm?.work?.WState !==_WST_SUBMIT);



/*
    const item = useMemo(() => {
        if (days < 0) {
            return {style: "bg-white border-tag-danger text-tag-danger", title: t("assignment-tag-expired")}
        } else if (days <= 1) {
            // "assignment-tag-due-today"
            return {style: "bg-white border-tag-danger text-tag-danger", title: t("assignment-tag-expire-today")};
        } else if (days <= 8) {
            return {
                style: "bg-white border-tag-danger text-tag-danger",
                title: t("assignment-tag-expire-in-days").replace("%s", days.toString())
            };
        };
        return {style: "", title: ""}
    }, [lang, days]);
*/