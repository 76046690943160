import React, {useMemo, useState, useEffect} from "react";
import {Container} from "react-bootstrap";

import { useUILang } from "../../AppExPf/utils/useUILang";
import { IconList } from '../../consts/ATIconListToUsePoc';
import { CpAssignmentList } from "../EPAssign/CpAssignmentList";
import ScrollContainer from "../_components/CpScrollContainer";
import CpActionButton from "../_components/CpActionButton";
import CpShortcutCTA from "./CpShortcutCTA";

import { groupData } from "../../poc/helper/groupByNumberArray";
import SubjectOverviewBySelectedClassTeacherData
    from "../../poc/screens/shared/includes/chart/sample-data/subjectOverviewBySelectedClassTeacherData.json";
import { stopEvent } from "../../libs/libUI";
import { ReduxBind } from "../../saga/ReduxState";
import { urlPush_Replace } from "../../saga/urlPush.saga";
import { _pathAssign, _pathReport } from "../../consts/ATConstReact";
import { toClassNames, } from "../EPReports/CpTeacherReport";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { isAry, toObj } from "../../libs/libType";
import { preJS, autoId } from "../../AppExPf/AppUtil";
import { useCaches } from "../../AppExPf/utils/useCaches";
import RadialChart from "../../poc/screens/shared/includes/chart/RadialChart";
import { toOverData1a } from "../EPReports/data/overData1";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";
import { RT_over } from "../EPReports/PageReports";

const CpDashboardTeacherBody = ReduxBind(props => { // base on poc DashboardTeacherBody
    const { dispatch, } = props;
    const { year, classId, AMSubjId } = props;
    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch);
    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const [ cache2d, useGetCache ] = useCaches(props);
    const yc2d = useGetCache('yearClass2d', '*');
    const clsNames = useMemo(() => toClassNames(yc2d, ut), [yc2d, uiEn]);

    const clickGoAsms = e => {stopEvent(e); dispatch(urlPush_Replace(_pathAssign())); };
    const clickGoRpts = e => {stopEvent(e); dispatch(urlPush_Replace(_pathReport())); };

    const [rptDatas, setRptDatas] = useState();
    const reload = async (caller) => {
        if(!(year && classId && AMSubjId )) return;
        const args = { year, cls: classId, MSubj: AMSubjId, rpt: RT_over, caller };
        const [res, err]  = await asyncApiCallLoad_(dispatch, '/getReports', args);
        setRptDatas({...rptDatas, ...res?.datas});
    };
    useEffect(() => { reload('dashThr'); }, [year, classId, AMSubjId]);

    const {overData1a_} = toObj(rptDatas);
    const [key1, ThrDashData1] = useMemo(() => [ autoId(),  toThrDashData1(overData1a_, clsNames, t) ], [overData1a_, clsNames, uiEn]);  

    const tabProps = { ...props, tagMSets, useTagMSets, t, uiEn, UILang, setUILang, ut};
    return <ScrollContainer className={"scroll-container h-100 d-flex flex-column w-100 overflow-auto gap-3 pt-3 pt-lg-0"}>
        <Container fluid={true} className={"fade-in-container"}>
            <div className="dashboard-container">
                {/*Left top*/}
                <div className={`dashboard-left`}>
                    <div className={"d-flex flex-column border rounded-light py-3 px-3 overflow-hidden h-100"}>
                        <div className={"d-flex justify-content-between flex-wrap align-items-center"}>
                            <h4 className={"m-0"}>{t("report-subject-overview")}</h4>
                            <CpActionButton className={"btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none"}
                                title={t("view-reports")} iconPath={IconList.general.search} hiddenText={true}
                                onClick={clickGoRpts} />
                        </div>
                        <div className={"flex-grow-1 d-flex justify-content-center align-items-center h-100"}>
                            <div className={"d-flex"} style={{width: "200px", height: "200px"}}>
                                {/*There is only selected class, no need all classes*/}
                                <RadialChart key={key1} {...ThrDashData1}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Right top*/}
                <div className={`dashboard-right`}>
                    <div className={"d-flex flex-column border rounded-light h-100 pt-3"}>
                        <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3 h-35"}>
                            <h4 className={"m-0 px-3"}>{t("shortcuts")}</h4>
                        </div>
                        <div className={"shortcuts-container"}>
                            <CpShortcutCTA title={t("create-new-exercise")} iconPath={IconList.general.request}
                                            type={"new-exercise"}/>
                            <CpShortcutCTA title={t("explore-exercise-library")} iconPath={IconList.menu.library}
                                            type={"explore-exercise-library"}/>
                            <CpShortcutCTA title={t("view-my-own-exercises")} iconPath={IconList.menu.myExercises}
                                            type={"view-my-own-exercises"}/>
                            <CpShortcutCTA title={t("check-assignments")} iconPath={IconList.menu.assignment}
                                            type={"check-assignments"}/>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        <Container fluid={true} className={"fade-in-container"}>
            <div className={`mb-5`}>
                <div className={"d-flex flex-column border rounded-light h-100 pt-3"}>
                    <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3 px-3"}>
                        <h4 className={"m-0"}>{t("assignments-due-soon")}</h4>
                        <CpActionButton className={"btn btn-exercise-third-btn gap-2 rounded semi-bold user-select-none"}
                            title={t("view-all-assignments")} iconPath={IconList.general.search} hiddenText={true} 
                            onClick={clickGoAsms}/>
                    </div>
                    <div className={"flex-grow-1 d-flex align-items-start pb-3"}>
                        <CpAssignmentList {...tabProps} noHr={1}/>
                        {/*//No result!!!!!!!*/}
                        {/*<div className={"d-flex align-items-center justify-content-center w-100 p-4 text-dim-400 fs-5"}>{t("no-result")}</div>*/}
                    </div>
                </div>
            </div>
        </Container>
    </ScrollContainer>
});

export default CpDashboardTeacherBody;

const toThrDashData1 = (overData1a_, clsNames, t) => {
    const overData1a = toOverData1a(overData1a_, clsNames);
    const {overallScore, data} = toObj(overData1a.teacher);    
    return {
        label: t("report-overall-average-score-percentage"),
        overallScore,
        data,
        setting: {
            radiusRangeBase: 20, centerValueLabelBase: 14,
            centerValueYBase: 43, centerTitleBase: 6,
            centerTitleYBase: 57, strokeTitleBase: 8
        } 
    };
};

const fakeData1 = (t) => ({
    label: t("report-overall-average-score-percentage"),
    overallScore: SubjectOverviewBySelectedClassTeacherData.teacher.overallScore,
    data: groupData(SubjectOverviewBySelectedClassTeacherData.teacher.data)[0],
    setting: {
        radiusRangeBase: 20, centerValueLabelBase: 14,
        centerValueYBase: 43, centerTitleBase: 6,
        centerTitleYBase: 57, strokeTitleBase: 8
    } 
});

