import { connect } from 'react-redux'
import { toObj, toStr } from '../libs/libType';

const cfg = process.env.REACT_APP_CFG;
const _debugMode = ['local','offline'].includes(cfg); // for display component
const _logMode = ['local','offline','dev','uat'].includes(cfg);
export const debugMode = () => _debugMode;
export const logMode = () => _logMode;

export const wrapReduxState = (saga) => {
    //lj({'wrapState()':redux});
    const auth = toObj(saga.auth);
    const state = toObj(saga.ReduxState);
    return {
        _saga: saga, //direct access to whole saga state
        //_state: state, //direct access to raw state
        // Wrapper functions
        userId: () => auth.uid,
        entitle: () => auth.entitle,
        user: () => auth.user,
        signedOut: () => (auth.signedOut),
        isLogined: () => (auth.uid),
        isTeacher: () => ((auth?.user?.role) === 'teacher'),
        userName: () => (toStr(auth?.user?.name)),
        userNameEn: () => (toStr(auth?.user?.nameEn)),
        
        maintenanceMode:() => (auth.maintenanceMode),
        maintenanceId:() => (auth.maintenanceId),
        maintenanceMsg:() => (auth.maintenanceMsg),

        lang: () => saga.lang,//getLang(),

        //Settings & User Limits
        settings: () => toObj(state?.settings),
        //majorError: () => (state.majorError),
        //majorLoading: () => (state.majorLoading),
    };
}
export const ReduxBind = (Comp) => connect(wrapReduxState)(Comp);

const INITIAL_STATE = {
    majorLoading: true,
    majorError: false,
    bannerVis:false,
    count: 0,
    apiPost: false,
    apiRet: false,
}
export const ReduxState = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SETSTATE':
            return action.callback(state);
        case 'SETAWSLOGIN':
            if(action.err || (!action.res))
                return state;
            const settings = toObj(action.res.settings);
            const entitle = action.res.entitle||0;
            //lj({setAwsLogin:action.res});
            //lj('SETAWSLOGIN', {settings, entitle})
            return {...state, settings, entitle, site:'endUser'};
        case 'SETAPIPOST':
            return {...state, ApiPost: action.form, ApiRet:'...waiting' };
        case 'SETAPIRET':
            return {...state, ApiRet: action.data };
        case 'SETMAJORLOADING':
            return {...state, majorLoading: action.loaded };
        case 'SETMAJORERROR':
            return {...state, majorError: action.error };
        case 'BANNERVIS':
            return {...state, bannerVis: action.vis};
        case 'AWS': //Handle By Saga
            return state;
        default:
            //lj('ReduxState() unknown action:',action.type);
            return state;
    }
};

