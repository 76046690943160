import React from 'react';
import { _ATSysQTypes, expf2 } from '../../consts/ATSysQType';
import { isObj, toAry, toObj, toStr } from '../../libs/libType';
import { aTErrTxt1, aTUIMust, ATUIText, BtnPopDev, preJS } from "../AppUtil";
import * as UI from '../../libs/libUI';
import { _ATMetaTypeByCode, _ATMetaTypes, _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, 
  _ATMetaType_GR, _ATMetaType_QT, _ATMetaType_SU, _ATMetaType_TB } from '../../consts/ATMetaTypes';
import { CpMetaTickList, mSets2Rows, mSetSplitMust } from '../components/CpMetaTickList';
import { useUILang } from '../utils/useUILang';

const TabQtnMeta = (props => {
  const { setField, setTick, setQSId, fields, fieldErrs, QId, opts, showPub, showEn, qtn, L, 
    clickQMetas, clickQMetaPres, clickQMetaPros, setDisplay, setMetaPre, setMetaPro, setFilter } = props
      
  const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
  const en = showEn;
  const qd = toAry(qtn?.QDisplayMetas);
  const qpre = toAry(qtn?.QMetaPres);
  const qpro = toAry(qtn?.QMetaPros);
  //const qf = qtn?.QFilterMetas || [];

  const [MSMusts, MSOpts] = mSetSplitMust(showPub? opts.pubMSetRows: opts.mDisp?.draftMSetRows);
  const [MPreMusts, MPreOpts] = mSetSplitMust(showPub? opts.pubMSetPreRows: opts.mPre?.draftMSetRows);
  const [MProMusts, MProOpts] = mSetSplitMust(showPub? opts.pubMSetProRows: opts.mPro?.draftMSetRows);

  const lock = showPub;
      //<BtnPopDev>{preJS({QMSMusts, QMSOpts},3)}</BtnPopDev>
      //<BtnPopDev>{preJS(opts,3)}</BtnPopDev>
      //{isObj(opts?.draftMetaSets) && <MetaSetLTreeList key='mstl' metaSets={metaSets} {...{ qtn: fields, setDisplay, setFilter }} />}
  
  //ms1, PRI_KS1_JunPri_P1;ms2, NCMATH2E_4B_7_7.2_A;
  return <div style={{fontSize:'12px'}}>
    <div>Metadata {aTUIMust}</div>
    <div>Metadata Code(s){aTUIMust}</div>
<BtnPopDev txt="preMeta">{preJS([qtn.QMetaIDStr, qd], 3)}</BtnPopDev>
    {_CpMetaStr(showPub, qtn.QMetaIDStr, fields?.QMetaIDStr, setField('QMetaIDStr'), clickQMetas, fieldErrs?.QMetaIDStr)} 
    <CpMetaTickList {...{lock, t2, en, MSMusts, MSOpts, tickLbls:['Display'], tickss:[qd], setterss:[setDisplay]}} />
    {expf2?<>
      <hr/>
      <div>Metadata Code(s) (Prior Knowledge)</div>
<BtnPopDev txt="preMeta">{preJS([qtn.QMetaPreIDStr, qpre], 3)}</BtnPopDev>
      {_CpMetaStr(showPub, qtn.QMetaPreIDStr, fields?.QMetaPreIDStr, setField('QMetaPreIDStr'), clickQMetaPres, fieldErrs?.QMetaPreIDStr)} 
      <CpMetaTickList {...{lock:1, autoTick:0, t2, en, MSMusts:MPreMusts, MSOpts:MPreOpts, tickLbls:['Display'], tickss:[qpre], setterss:[setMetaPre]}} />
      <hr/>
      <div>Metadata Code(s) (Advanced Knowledge)</div>
<BtnPopDev txt="preMeta">{preJS([qtn.QMetaProIDStr, qpro], 3)}</BtnPopDev>
      {_CpMetaStr(showPub, qtn.QMetaProIDStr, fields?.QMetaProIDStr, setField('QMetaProIDStr'), clickQMetaPros, fieldErrs?.QMetaProIDStr)} 
      <CpMetaTickList {...{lock:1, autoTick:0, t2, en, MSMusts:MProMusts, MSOpts:MProOpts, tickLbls:['Display'], tickss:[qpro], setterss:[setMetaPro]}} />
    </>:''}  
  </div>;
});
export default TabQtnMeta;

const _CpMetaStr = (lock, val, editVal, setter, onClick, err) => <CpMetaStr {...{lock, val, editVal, setter, onClick, err}}/> 
const CpMetaStr = props => {
  const {lock, val, editVal, setter, onClick, err} = props;
  return <>
    {lock?<>
        <div>{toStr(val)}</div>
    </>:<>
      <div>{ATUIText(toStr(editVal), setter, 'txtMC')}</div>
      <div>{UI.Button0('reload', onClick, 'btnlm')}</div>
      {aTErrTxt1(err)}
    </>}
  </> 
};

const walkTreeMetaChk = (rows, msid, metaSet, metas, childs, lv) => {
  Object.keys(childs).forEach(mid => {
    rows.push({ msid, mid, metaSet, meta: metas[mid], lv });
    walkTreeMetaChk(rows, msid, metaSet, metas, childs[mid], lv + 1);
  });
};
