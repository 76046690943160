import {useEffect, useRef} from "react";
import CpActionButtonComponent from "../_components/CpActionButtonComponent";
import { SIZE } from "../_components/CpExPFConst";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { EpInputInt } from "../../libs/libUI";
import { toInt } from "../../libs/libType";

const CpManualMarkMarking = (props) => { // base on poc ManualMarkMarking.jsx
    const {score=undefined, maxScore=0, setScore} = props;
    const [t] = useUILang();

    const _setScore = (v) => {
        const ii = toInt(v);
        setScore && setScore(ii > maxScore ? maxScore : ii );
    };

    const onBlur = () => {};

    return <div className='ATQtnSingleMarks'>
            <span>{t('at-setting.fib.marks')}:</span>
            {EpInputInt(score,_setScore,'mScore','',{textAlign: "center", width: "40px", padding:0, margin:0, outline: "none", border: "1px solid lightgray"},
            onBlur)}{" / "}{toInt(maxScore)}
    </div>
};

export default CpManualMarkMarking;