import React, { useMemo, useState } from "react";
import { BtnDev, preJS } from "../../AppExPf/AppUtil";
import { mkEQSum, mkEQSumFake } from "../../AppExPf/ATExerEdit/TabExerSetting";
import { isEn2Lang, useCtnLang, useUILang } from "../../AppExPf/utils/useUILang";
import { toAry, toObj, toStr } from "../../libs/libType";

import CpMetadataEditorCTA from "./CpMetadataEditorCTA";
import {CpSettingModalTitle, CpSettingModalSection, CpSettingModalTabContainer} from "../_components/CpSettingModalTabCps";
import { SIZE } from "../_components/CpExPFConst";
import { ReduxBind } from "../../saga/ReduxState";
import CpDropdown, { obj2DropdownItem } from "../_components/CpDropdown";
import { CpEPTagMetas } from "./CpExerciseCard";
import CpBadgeInputComponent from "../_components/CpBadgeInputComponent";
import { ary2UniStr } from "../../consts/ATValidate";
import { _MaxLenTodo } from "../../consts/ATConsts";
import { exerUniQIds } from "../../consts/ATValidateExer";

const TabExerEdDetail = ReduxBind(props => { //base on poc <ExerciseEditDetailsPage>
    const {ex, setField, fields, setFields, lock, tagMSets, useTagMSets, qtns, useCacheQtns, isOupEx, } = props;

    const [t, uiEn, UILang, setUILang] = useUILang();
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(isEn2Lang(props.showEn));

    const { ECtns, ENexts, EMetas, EKwCt, EKwEn, EMSubjId, EShare, dp, isNew, ..._ex } = toObj(ex);
    const [EKwCts, EKwEns] = [splitKWs(EKwCt), splitKWs(EKwEn)];

    const uniQIds = useMemo(() => exerUniQIds(ex), [ex]);
    useCacheQtns(uniQIds);
    const EQSum = useMemo(() => mkEQSumFake(ex, qtns), [ex, qtns]);   
    const [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore] = toAry(EQSum); 
    
    const shareOptions = obj2DropdownItem({ s0 :t("share-options-private"), s1: t("share-options-share"), });
    const [share, setShare] = [ex?.EShare? 's1': 's0', s => setField('EShare')(s==='s1'? 1: 0) ];

    //const keywords = showEn?EKwEns:EKwCts;
    const [keywords, setKeywds] = useState(showEn?EKwEns:EKwCts);

    const updateItems = (!lock) && (items => {
        // setFields ?
        //if (showEn) { setField('EKwEn')(items);}
        //else { setField('EKwCt')(items);}
        //;
        setKeywds(items);
        const keysString = ary2UniStr(items);
        (showEn)?setField('EKwEn')(keysString):setField('EKwCt')(keysString);
    });
    
    
    const metaEditProps = {...props, };
    return <CpSettingModalTabContainer paddingX={0}>
        {preJS({showEn, lock})}
        <CpSettingModalSection paddingX={0}>
            <CpSettingModalTitle>{t("exercise-edit-tab-details-overview")}:</CpSettingModalTitle>
            <div className={"d-flex flex-wrap w-100 gap-3 mb-3"}>
                <Overview title={t("exercise-edit-tab-details-total-questions")} {...{question: qids.length, marks: escore}} />                
                <Overview title={t("exercise-edit-tab-details-auto-marked-question")} {...{question: AQCnt, marks: AScore}} />
                <Overview title={t("exercise-edit-tab-details-manual-marked-questions")} {...{question: MQCnt, marks: MScore}} />
            </div>
        </CpSettingModalSection>

        <CpSettingModalSection paddingX={0}>
            <div className={"d-flex justify-content-between w-100"}>
                <CpSettingModalTitle>{t("exercise-edit-tab-details-taggings")}: </CpSettingModalTitle>
                {lock? '': <span><CpMetadataEditorCTA {...{...metaEditProps, variant:"exercise-third-btn"}} /></span>}
            </div>
            <CpEPTagMetas {...{ex, tagMSets, useTagMSets, ct, showEn}} />
{/*
        <table className={`table-fit-column p-3 w-100`}><tbody className={"align-top"}>
            {dummyMetadata.map((item, idx) => <tr key={idx} className={"d-inline-block d-md-table-row"}>
                <td className={"d-inline-block d-md-table-cell semi-bold text-dim-400"}>{item.title}</td>
                <td className={""}><div className={"d-flex flex-wrap gap-2"}>
                    {item.child.map((item, idx) => <ActionButton key={idx}
                        title={item} className={"badge-btn semi-bold rounded pe-none"}/>)}
            </div></td></tr>)}
        </tbody></table>            
*/}
        </CpSettingModalSection>

        <CpSettingModalSection paddingX={0}>
            <div className={"w-100"}>
                <CpSettingModalTitle>{t("exercise-edit-tab-details-keywords")}:</CpSettingModalTitle>
            </div>
            <CpBadgeInputComponent enableEdit={!lock} size={SIZE.XS} errorMessage={t('error-message.least1')}
                inputVariant={"exercise-third-btn"} inputStyle={"w-auto mb-0"} errorStyle={" "}
                badgeVariant={"color-light-exercise-third-btn"} badgeContainerStyle={"w-100"}
                badgeStyle={"text-exercise-third-btn"} lock={lock} defaultBadges={keywords} updateItems={updateItems}/>
            {preJS({EKwEn})}
            {preJS({EKwCt})}
        </CpSettingModalSection >

        {(isOupEx && lock)? preJS({isOupEx, lock}): <CpSettingModalSection hideBorder={true} paddingX={0}>
            <CpSettingModalTitle>{t("exercise-edit-tab-details-share-private")}:</CpSettingModalTitle>
            {preJS({EShare})}
            <div className={"d-flex flex-wrap w-100 gap-2"}>
                <span className={"semi-bold text-dim-400 me-3"}>{t("exercise-edit-tab-details-status")}:</span>
                {lock?
                    <span className={""}>{t(EShare? "share-options-share": "share-options-private")}</span>:
                    <span style={{width: "300px"}}><CpDropdown className="w100" {...{items:shareOptions, idx:share, cb:setShare }} /></span>
                }
            </div>
        </CpSettingModalSection>}
    </CpSettingModalTabContainer>
});
export default TabExerEdDetail;

const Overview = (props) => {
    const {title, question, marks} = props
    const [t] = useUILang();
    return <span className={"d-flex flex-column border gap-2 p-3"} style={{width: "300px"}}>
        <div className={"semi-bold"}>{title}</div>
        <div className={"d-flex justify-content-between gap-2"}>
            <small className={"semi-bold"}>{t("exercise-edit-tab-details-no-of-questions")}: {question}</small>
            <small className={"semi-bold"}>{t("exercise-edit-tab-details-marks")}: {marks}</small>
        </div>
    </span>
};
const splitKWs = (s) => Array.from(new Set(toStr(s).split(',').map(s => s.trim()).filter(s => s)));

/*
            <div key='kwen' className={"d-flex w-100 flex-wrap gap-2"}>
                {EKwEns.map((item, idx) => <ActionButton key={idx} title={item} className={"badge-btn semi-bold rounded"}/>)}
            </div>
            <div key='kwct' className={"d-flex w-100 flex-wrap gap-2"}>
                {EKwCts.map((item, idx) => <ActionButton key={idx} title={item} className={"badge-btn semi-bold rounded"}/>)}
            </div>
*/

