//import cookies from 'react-cookies';

const sessionKeyName = 'sessionId';
const storage = window.sessionStorage; // window.localStorage;  
var _s_e_s_s_i_d = '';
const LibSessionId = {
clear: () => {
    _s_e_s_s_i_d = '';
    storage.removeItem(sessionKeyName);
},
load: () => {
    //const v = cookies.load(sessionKeyName); 
    //const v = storage.getItem(sessionKeyName) || '';
    const v = _s_e_s_s_i_d;
    return v;
},
save: (newKey) => {
    if(newKey){
        _s_e_s_s_i_d = newKey;
        //storage.setItem(sessionKeyName, ''+newKey);
        //cookies.save(sessionKeyName, newKey, {SameSite:'strict', path: '/', maxAge: 86400});
    }
},    
};
export default LibSessionId;

/*
import {useCookies} from 'react-cookie';

const sessionKeyName = 'sessionId'; 
const [cookies, setCookie] = useCookies([sessionKeyName]);
const SessionLib = {
    key: () => sessionKeyName,
    loadFromStorage: () => {
    return cookies[this.key()];
},
UpdateStorage: (newKey) =>{
    if(newKey)
        setCookie(this.key(), newKey, {path: '/'});
},    
loadFromResponse: (response) =>{
    this.UpdateStorage(response[this.key()]);
},
AppendToRequest: (request) => {
    return {...request, [this.key()]:this.loadFromStorage()}
},
};
export default SessionLib;
*/