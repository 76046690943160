import React, { useEffect, useState } from 'react';
import * as UI from '../../libs/libUI';
import { toAry, toObj, toStr } from '../../libs/libType';
import { svgIcon2, iconButton } from '../components/CpATIcoBtn';
import { BCPlayer, YTPlayer, HTMLPlayer } from '../components/media/CpMediaPlayer';
import { subState } from '../utils/useChain';
import { _alignClass } from './ViewExOptions';
import { exerTitle, exerBlock, viewExLabel, exATUIText, exTextArea, popA, prefixURL} from './ViewExCommon';
import { useUILang } from '../utils/useUILang';

const _VPlayers = { ul:HTMLPlayer, yt:YTPlayer, bc:BCPlayer, };
const showPlayer = (type, url, isAT=1, idx, setPlayed=()=>{}) => {
    const P = _VPlayers[type];
    const noCheck = (!isAT) && (type==='ul');
    return P?<P idx={idx} key={type} url={url} onSuccess={()=>{}} id={type+''+idx} mode={'video'}
        isAudio={false} noCheck={noCheck} setPlayed={()=>setPlayed(idx)}/>
        :popA('url'+type, url, url, ()=>setPlayed(idx));
};

const ViewExCtnVideo = props => {
    const {lock, showEn, ECtn, replaceECtn, PVMode, isAT, setOnAddMedia, mediaDLs, getMediaDLs, idx, setPlayed=()=>{}} = props; 
    const [ t ] = useUILang();
    const ec = toObj(showEn? (ECtn?.en): (ECtn?.ct)); 
    const ecca = toObj(ECtn?.ct?.ca);
    const ecpt = toObj(ECtn?.ct?.pt);
    const hasctca = (ec.videoType==='ul')?(toStr(ECtn?.ct?.ca?.mediaId)):(toStr(ECtn?.ct?.ca?.url));
    const hasctpt = (ec.videoType==='ul')?(toStr(ECtn?.ct?.pt?.mediaId)):(toStr(ECtn?.ct?.pt?.url));
    const [showCa, setShowCa] = useState(hasctca?1:0);
    const [myURL, setMyURL] = useState('');
    const myMediaID = toStr(ec.videoType==='ul'?(showEn?ec.mediaId:(showCa?(ec.ca?.mediaId):(ec.pt?.mediaId))):'');
    const lang = showEn?'en':'ct';
    
    const setTmpField = key => val => replaceECtn(subState(lang, subState(key, val)));
    const setCtCaTmpField = key => val => replaceECtn(subState('ct', subState('ca', subState(key,val))));
    const setCtPtTmpField = key => val => replaceECtn(subState('ct', subState('pt', subState(key,val))));

    // for display
    const cturl = showCa?toStr(ECtn?.ct?.ca?.url):toStr(ECtn?.ct?.pt?.url);
    const cttitle = showCa?toStr(ECtn?.ct?.ca?.title):toStr(ECtn?.ct?.pt?.title);
    const ctdesc = showCa?toStr(ECtn?.ct?.ca?.desc):toStr(ECtn?.ct?.pt?.desc);

    const url = toStr(showEn?ec.url:cturl);
    const title = toStr(showEn?ec.title:cttitle);
    const desc = toStr(showEn?ec.desc:ctdesc);

    const videoType = toStr(ec.videoType);
    const myWidth = toStr(ec.width);
    const myHeight = toStr(ec.height);
    const myAlign = toStr(ec.align);
    const displayTitle = ec.displayTitle?ec.displayTitle:0;
    const edProps = {idx, mediaDLs, getMediaDLs, setOnAddMedia, isAT};

    useEffect(()=>{
        if (ec.mediaId !== '' && getMediaDLs) {
            const obj = getMediaDLs([ec.mediaId]);
            if (obj && obj[ec.mediaId]) {
                setMyURL(obj[ec.mediaId].dl);
            };
        };
    },[ec.mediaId]);

    useEffect(()=>{
        if (myMediaID !== '' && getMediaDLs) {
            const obj = getMediaDLs([myMediaID]);
            if (obj && obj[myMediaID]) {
                setMyURL(obj[myMediaID].dl);
            };
        };
    },[myMediaID]);    

    useEffect(()=>{ 
        if (mediaDLs && mediaDLs[myMediaID]) {            
            setMyURL(mediaDLs[myMediaID].dl);
        };
    },[mediaDLs]);    

    const langBtn = (icon, showCa, cb) => iconButton('',icon,showCa?'white':'black',showCa?'#ec8008':'grey',
        cb,true,{transform:'scale(1.2)',width:'24px',height:'24px'}, {margin:'0 2px 5px 2px', padding:'2px'});
    const showContent = (title, content, desc, _url, maxWidth='1024px') => {
        let exStyle={}, exClass='';
        if (myWidth) exStyle = {...exStyle, width:myWidth+'px'};
        if (myHeight) exStyle = {...exStyle, height:myHeight+'px'};
        if (myAlign) exClass = (_alignClass[myAlign]?_alignClass[myAlign]:_alignClass['center']);
        return <div className={'ViewExCtnItemLock ' + (PVMode!==1?' Single':' List')}>
            <div id={'exCtnContent'+idx} className='ViewExCtnItemLockContent' style={{maxWidth:maxWidth}}>
                <div className={(showEn?'flexRowStart ':'flexRowBetween ') + (showEn?exClass:'')}>
                    {(displayTitle && videoType!=='ex')?<div className={'flexRowStartFit semi-bold '}>
                    {PVMode===1?exerTitle(title):title}</div>:<div></div>}
                    {!showEn ? <div className='flexRowStartFit'>
                    {hasctca && langBtn('language/can',showCa,()=>setShowCa(1))}
                    {hasctpt && langBtn('language/pth',!showCa,()=>setShowCa(0))}</div>
                    :<div></div>}
                </div>
                {videoType==='ex'?exerBlock(<div className='flexColStart f18'>
                    <div className={'flexRowStart ' + exClass}>{title}</div><p/>
                    <div className={'flexRowStart ' + exClass}>{popA('url'+ECtn.idx, prefixURL(_url), t('exercise.content.link.click-here-to-open-hyperlink'), () => { setPlayed(idx); })}</div>
                    <div className={'flexRowStart ' + exClass}>{desc}</div>
                </div>):<>
                <div className={'flexRowStart ' + exClass}><div style={{width:'100%', ...exStyle,}}>{content}</div></div>
                <div className={'flexRowStart ' + exClass}>{PVMode>=0?exerBlock(desc):desc}</div></>}
            </div>
        </div>;
    };

    if(lock || PVMode >=0) {
        
        const _url = (ec.videoType==='ul')?myURL:url;
        return showContent(displayTitle?title:'',showPlayer(videoType,_url,isAT, idx,setPlayed),desc,_url);
    };

    const getMediaFromMD = (setData) => {
        if (setOnAddMedia) {
            setOnAddMedia({onAdd:(medias => {
                
                const ms = toAry(medias);
                if (ms.length > 0) {
                    const src = toStr(ms[0].dl);
                    const mediaId = toStr(ms[0].mediaId);
                    setData(mediaId, src);
                };
                setOnAddMedia(0); //stop receiveMedia
            }),maxMedia:1, mimes:['video']});
        };        
    };

    return <div className='ViewExCtnItem'>
        {lang==='en'?<VideoInput setTmpField={setTmpField} getMediaFromMD={getMediaFromMD} ec={ec} 
            videoType={videoType} showEn={showEn} {...edProps}/>
        :<><VideoInput setTmpField={setCtCaTmpField} getMediaFromMD={getMediaFromMD} ec={ecca} 
            videoType={videoType} isCan={true} {...edProps}/>
            <VideoInput setTmpField={setCtPtTmpField} getMediaFromMD={getMediaFromMD} ec={ecpt} 
            videoType={videoType} isCan={false} {...edProps}/></>}
    </div>
};
export default ViewExCtnVideo;

const langIcon = (icon) => <div style={{backgroundColor:'#2995cd',borderRadius:'4px', margin:'5px 8px',
    width:'20px', height:'20px'}}>{svgIcon2(icon,'white',{width:'20px',height:'20px'})}</div>;

const VideoInput = (props) => {
    const {idx, showEn, ec, setTmpField,videoType,isCan,getMediaFromMD,mediaDLs, getMediaDLs,isAT,setPlayed} = props;   
    const [ t ] = useUILang();
    const [myURL, setMyURL] = useState('');
    const [myMediaInfo, setMyMediaInfo] = useState({});
    const url = videoType==='ul'?myURL:toStr(ec.url);
    const myMediaID = toStr(ec.mediaId);
    
    useEffect(()=>{
        if (ec.mediaId !== '' && getMediaDLs) {
            const obj = getMediaDLs([ec.mediaId]);
            if (obj && obj[ec.mediaId]) {
                setMyURL(obj[ec.mediaId].dl);
                setMyMediaInfo({name:obj[ec.mediaId].name, ext:obj[ec.mediaId].ext});
            };
        };
    },[ec.mediaId]);

    useEffect(()=>{ 
        if (mediaDLs && mediaDLs[myMediaID]) {            
            setMyURL(mediaDLs[myMediaID].dl);
            setMyMediaInfo({name:mediaDLs[myMediaID].name, ext:mediaDLs[myMediaID].ext});
        };
    },[mediaDLs]);    

    const setVideoData = (mediaId, src) => {
        
        setTmpField('mediaId')(null);
        setTimeout(()=>{setTmpField('mediaId')(mediaId);},10);
    };
    return <><p/>
        {!showEn && <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.video.language'),'lang')}{langIcon(isCan?'language/can':'language/pth')}
            {isCan?t('exercise.content.language.can'):t('exercise.content.language.pth')}
        </div>}
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.video.caption'),'title')}{exATUIText(ec.title, setTmpField('title'), 'vid')}
        </div>
        <div className='flexRowStart'>
            {viewExLabel(t('exercise.content.video.desc'),'desc')}{exTextArea(ec.desc, setTmpField('desc'), 'desc')}
        </div>
        <div className='flexRowStart'>
            {viewExLabel(videoType==='ul'?t('exercise.content.video.file'):t('exercise.content.video.url'),'url')}
            {videoType==='ul'?<div className='flexRowBetween'>
                <div className='flexRowStart viewExInput' style={{minHeight:'35px',flex:1}}>{myMediaInfo.name}</div>
                {UI.colorButton('browse',t('browse'),()=>getMediaFromMD(setVideoData),
                    '#2995cd','80px',{margin:'0 10px', color:'white'})}
            </div>
            :exATUIText(url, setTmpField('url'), 'url')}
        </div>
        {videoType==='ex'?'':<div className='flexRowStart'>
            {viewExLabel('','blankSpace')}
            <div style={{width:'100%', margin:'4px 0 4px 0'}}>
                {url && showPlayer(videoType, url, isAT, idx, setPlayed)}
            </div>           
        </div>}</>;
};
