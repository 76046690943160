import React, { useState, useEffect, useRef } from 'react';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import CpAnswerDetail from '../components/CpAnswerDetail';

import * as SQT from '../../consts/ATSysQType';

import * as FIBC from "../../consts/ATQtnAnsTypeFIB";
import * as UI from '../../libs/libUI';
import { iconButton } from '../components/CpATIcoBtn';
import { toAry, toObj, toStr } from '../../libs/libType';

import { preJS, deepCopy } from '../AppUtil';
import { ReduxBind } from '../../saga/ReduxState';
import { toInt } from '../../libs/libType';
import { popAlert, popConfirm } from '../components/CpPopup';
import { useUILang } from '../utils/useUILang';
import { debugMode } from '../../saga/ReduxState';
import { ansSumScore } from '../../consts/ATValidateQ';
import { getMaxValue } from './CpQEditorFIB';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';

import CpLDCanvas, {addAnswerBlock, setBGImg, delAnsBlock, selObj} from "../components/CpLDCanvas";

const cssClass = {
    'atview': ' atView', // at preview
    'atedit': ' atEdit', // at edit
    'teview': ' teView', // teacher preview
    'teedit': ' teEdit', // teacher edit
};

const CpQEditorLBD = ReduxBind( props => {
    const { isAT, dispatch, fullQ, setQ, setFullQ, afterInit, Q, editable, setUpFlag,
        setOnAddMedia, mediaDLs, getMediaDLs } = props;
    const [ t, uiEn, UILang, setUILang, ut, t2, t3 ] = useUILang();
    const qData = Q?.qData || "";

    const [ansArr, setAnsArr] = useState(Q?.qAnswers || []);
    const [answer, setAnswer] = useState(undefined);
    const [infoTxt, setInfoTxt] = useState('');
    const [displayTxt, setDisplayTxt] = useState('');

    const [selected, setSelected] = useState({id:-1, index:0}); // selected ans id
    const [changeSel, setChangeSel] = useState({id:-1, index:0}); // temp hold select change for checking
    
    //const [selected, setSelected] = useState(-1); // selected ans id, index follow ansArr order
    const [keyIndex, setKeyIndex] = useState(getMaxValue(ansArr, 'oupansid')); 
    const [redraw, setRedraw] = useState(0);
    const [myCV, setMyCV] = useState(null);
    const myMediaID =toStr(Q?.BGMediaID);
    const [myBG, setMyBG] = useState(null);

    const [ansBlock, setAnsBlock] = useState({}); // for update the ansBlock movement info
    const [vis, setVis] = useState(false);
    //const [CVInfo, setCVInfo] = useState({width:0,height:0});

    const refCK = useRef(null); // ck editor
    //const urlRef = useRef(null); // background image
    const insideDraw = useRef(false);

    const ansIndex = (id) => ansArr.findIndex((obj)=>{return obj.oupansid === id});
    const updateQ = (func, d) => { func && func(d) };

    const updateScore = () => {
        if (fullQ.scoreMethod === FIBC.__FIBS_PQU) return;
        setFullQ('score', ansSumScore(ansArr));
    };
    
    const myID = 'LBDEdit'+fullQ.QId;
    const LDMode = Q.LDMode?Q.LDMode:'ftw';
    // follow SQType
    const prevSQType = useRef(fullQ.SQType);
    const defaultAnsType = fullQ.SQType===SQT.__SYSQSubType_LBD?FIBC.__FIBT_DRD:FIBC.__FIBT_TXT;

    useEffect(()=>{
        if (myMediaID !== '' && getMediaDLs) getMediaDLs([myMediaID]);
    },[]);

    useEffect(()=>{
        renderCanvAnsBlock();
    },[myCV]);

    useEffect(()=>{
        if (infoTxt) {
            const dd = new Date();
            setDisplayTxt(displayTxt + '\n' + dd.toString() + ':' + infoTxt);
            var infoContent = document.getElementById('infoContent');
            infoContent.scrollTop = infoContent.scrollHeight;
        };
    },[infoTxt]);

    useEffect(()=>{
        
        if (!insideDraw.current) {
            
            insideDraw.current = true;
            renderCanvAnsBlock();
            insideDraw.current = false;
        };
    },[redraw]);

    useEffect(()=>{ 
        if (mediaDLs && mediaDLs[myMediaID]) {
            
            setMyBG(mediaDLs[myMediaID].dl);
        };
    },[mediaDLs]);    

    useEffect(()=>{
        if (fullQ.scoreMethod === FIBC.__FIBS_PQU) {
            if (!fullQ.score) setFullQ('score',1);
            
            ansArr.forEach((ans) => {ans.score=0});
            //setFullQ('score',1);
            setAnsArr([...ansArr]);
        } else {
            
            ansArr.forEach((ans) => {
                
                if (!ans.score) ans.score = 1;
                //ans.score=(toInt(ans.score)?1:toInt(ans.score))
            });
            updateScore(); }
    }, [fullQ.scoreMethod]);

    /*
    useEffect(() => {
        setAnswer((selected.id !== -1) ?getAnsData(selected.id):undefined); 
        if (selected.id === -1) setVis(false);
    },[selected]);
    */
    useEffect(() => {
        if (changeSel.id !== -1) {
            if (answer && answer.oupansid !== changeSel.id && vis) {
                popConfirm(dispatch, 0, t('warning.warning_change_answer_blank'), () => { 
                    setVis(false);
                    setAnswer(getAnsData(changeSel.id));
                    setTimeout(()=>{setVis(true)},10); // force answerDetail redraw fix ckeditor cacehed ans
                }); 
            } else setAnswer(getAnsData(changeSel.id));
        } else {setAnswer(undefined); setVis(false);}
    },[changeSel]);

    useEffect(()=>{
        if (prevSQType.current !== fullQ.SQType) {
            
            if (myCV) myCV.clear();
            setAnsArr([]);
        };
    },[fullQ.SQType]);

    useEffect(()=>{
        if (!myBG) setMyCV(null);
    },[myBG]);

    useEffect(()=>{
        if(selected.id !== -1 && getAnsData(selected.id) === undefined) setSelected({id:-1, index:0});
        updateQ(setQ("qAnswers"), [...ansArr]);
    },[ansArr]);

    useEffect(()=>{
        if (afterInit) {
            const index = ansIndex(ansBlock.oupansid);
            
            
            ansArr[index] = {...ansArr[index], ...ansBlock};
            setAnsArr([...ansArr]);
        };
    },[ansBlock]);

//==canvas===============================================================
    const returnInfo = (txt) => {
        setInfoTxt(txt);
    };

    const setURL = (e, v) => {
        UI.stopEvent(e);
        //if (urlRef.current) setLDImgSrc(urlRef.current.value);
        //if (urlRef.current) setLDImgSrc(v);
        if (v) updateQ(setQ("BGSrc"), v);
    };

    const _setMyBG = (src) => {
        setMyBG(null);
        setTimeout(() => {
            setMyBG(src);
        }, 10);        
    };

    const doAddBG = (e) => { UI.stopEvent(e);
        if (setOnAddMedia) {
            setOnAddMedia({onAdd:(medias => {
                const ms = toAry(medias);
                
                if (ms.length > 0) {
                    const src = toStr(ms[0].dl);
                    const mediaId = toStr(ms[0].mediaId);
                    updateQ(setQ("BGMediaID"), mediaId);
                    if (src !== '') _setMyBG(src);
                };
                setOnAddMedia(0); //stop receiveMedia
            }),maxMedia:1, mimes:['image']});
        };
    };      
    
    const updateAnsBlockInfo = (data) => { setAnsBlock(data)};

    const onResize = (val) => {       
        setRedraw(val);
    };

    const renderCanvAnsBlock = () => {
        if (myCV) myCV.clear();
        if (ansArr) {
            ansArr.forEach((aa, ii)=>{
                addAnswerBlock(myCV, aa.oupansid, aa, ''+(ii+1))
            });
        };
    };
    
    const addLDAns = () => {
        //if (!LDImgSrc) return;
        if (!myCV) return;
        const key = keyIndex+1;
        
        addAnswerBlock(myCV, key,{oupansid:key, 
            rect: {top:0.1, left:0.1},
            cir: {top:0.18, left:0.1},           
        }, ''+(ansArr.length+1));

        ansArr.push({oupansid:key,qtype:defaultAnsType,qvalue:[{data:"",type:FIBC.__FIBA_TXT}],score:1});
        /*setAnsArr([...ansArr,
            {oupansid:key,qtype:defaultAnsType,qvalue:[{data:"",type:FIBC.__FIBA_TXT}],score:1}
        ]);*/
        updateScore();
        setAnsArr([...ansArr]);
        setKeyIndex(key);
    };
    //score:(fullQ.scoreMethod === FIBC.__FIBS_PQU?0:1),
    const selectAns = (ii) => {
        
        setSelected({id:ii, index:0});
    };
    
    const setLDMode = (v) => {
        updateQ(setQ("LDMode"), v);
        const bg = myBG;
        // force canvas redraw
        setMyBG(null);
        setTimeout(() => {
            setMyBG(bg);
        }, 10);  
    };
//=================================================================
    const getSideSelIndex = () => ansArr.findIndex((obj)=>{return obj.oupansid === selected.id});

    const getSelIndex = () => {
        return answer? ansArr.findIndex((obj)=>{return obj.oupansid === answer.oupansid}):-1;
    };

    const clearAllAnsBlock = () => {
        if (myCV) myCV.clear();
    };

    const setAnswerData = ans => {
        const index = getSelIndex();
        if (index >= 0) {
            ansArr[index] = deepCopy(ans);
            if (fullQ.scoreMethod !== FIBC.__FIBS_PQU) updateScore();
            setAnsArr([...ansArr]);
        };        
    };

    const deleteThisAns = () => {
        const index = getSelIndex();
        if (index >= 0) {
            
            ansArr.splice(index, 1);           
            setAnsArr([...ansArr]);
            updateScore();
            delAnsBlock(myCV, selected.id);
            setSelected({id:-1,index:0});
            setChangeSel({id:-1,index:0});
            renderCanvAnsBlock();                
        };
    };

    const closeAD = () => {
        setVis(false);
        setChangeSel({id:-1,index:0});
    };

    const onDblClick = (oupansid) => {setChangeSel({id:oupansid, index:0}); setVis(true);}
    const onRAnsClick = (oupansid) => {
        selObj(myCV,'AnsText',oupansid); myCV.requestRenderAll();
        onDblClick(oupansid);
    };    
    const getAnsData = id => ansArr.find( obj => (obj.oupansid === id));
    
    const ck_updateMediaID = arr => getMediaDLs([...arr]); 
    const ckImageConfig = { setOnAddMedia, updateMediaID: ck_updateMediaID, };
    const edProps = {isAT, mediaDLs, getMediaDLs};
    const myClass = cssClass[editable ? 'atedit' : 'atview'];
    
    //IconList.labelDiagram.fitHeight
    const hasBG = myCV?1:0;
    return <>
        <div className={'tabQContent ' + (editable ? "" : "ReadOnly") + myClass}>
            <Ckeditor5Base {...props} editorType="question" data={qData} setData={setQ("qData")}
                enable={editable} setEditor={refCK} debug={false} showStatus={true} setUpFlag={setUpFlag}
                ckImageConfig={ckImageConfig} onEditorReady={()=>{}} noDragDrop={true} {...edProps}/>
            <div className='LDCavBtnCont'>
                <div className='LDCavBtnContent'>
                    {cavBtn(t('insert-diagram'),IconList.labelDiagram.image, doAddBG,'LDToolBtn add ','LDMiniTxt',true)}
                    {vLine()}
                    {cavBtn(t('fit-to-width'),IconList.labelDiagram.fitWidth, (e) => setLDMode('ftw'),
                        'LDToolBtn other ' + (LDMode==='ftw'?' sel ':''), 'LDMiniTxt', hasBG)}
                    {cavBtn(t('actual-size'),IconList.canvasDrawing.actualSize, (e) => setLDMode('fth'), 
                        'LDToolBtn other ' + (LDMode==='fth'?' sel ':''), 'LDMiniTxt', hasBG)}
                    {vLine()}
                    {cavBtn(t('add-a-blank'),IconList.labelDiagram.add, addLDAns, 'LDToolBtn add ', 'LDAlwayTxt',hasBG)}
                </div>
            </div>            
            {(false && debugMode())?<textarea id='infoContent' style={{width:'100%', minHeight:'100px', maxHeight:'100px', overflow:'auto', 
                border:'1px solid black'}} defaultValue={displayTxt}></textarea>:''}            
            {myBG?<div className='LDCanvasAnsTop'>
                <div className='LDCanvasLayer'>
                    <div style={{margin:'auto',width:(LDMode==='ftw'?'100%':'auto')}}>
                        <CpLDCanvas setCanvas={setMyCV} selectAns={selectAns} onResize={onResize}
                        updateAnsInfo={updateAnsBlockInfo} setVis={()=>setVis(true)}
                        onDblClick={onDblClick} returnInfo={returnInfo}
                        BGImgSrc={myBG} mode={LDMode} id={myID}/>
                    </div>
                </div>
                <div className='LDAnsLayer'>
                    {ansArr.length>0 ? <SideAnsPanel ansArr={ansArr} onDblClick={onDblClick}
                        selIndex={getSideSelIndex()} onRAnsClick={onRAnsClick}/>
                    :''}
                </div>
            </div>:''}

            {vis && answer && <CpAnswerDetail ansOK={answer} index={ansIndex(answer.oupansid)+1} setConfirmAns={setAnswerData}
                editable={editable} updateScore={updateScore} close={closeAD}
                deleteThisAns={deleteThisAns} {...edProps} autoMark={fullQ.autoMark} scoreMethod={fullQ.scoreMethod}
                dispatch={props.dispatch} hasCorrectAns={false}/>} 
        </div>
    </>;
});
export default CpQEditorLBD;
/*
                    <button onClick={(e) => setURL(e,'/img/saber_lion.jpg')}>set lion</button>
                    <button onClick={(e) => setURL(e,'/img/redbull.jpg')}>set red bull</button>
                    <button onClick={(e) => setLDMode('ftw')}>fit to width</button>
                    <button onClick={(e) => setLDMode('fth')}>fit to height(original size)</button>
                    <button onClick={() => addLDAns()}>add answer</button>
                    <button onClick={() => deleteThisAns()}>delete selected</button>
*/
export const vLine = () => <div style={{ width:'1px', maxWidth:'1px', minHeight:'100%', backgroundColor:'lightgrey', 
    margin:'5px', color:'lightgrey', overflow:'hidden'}}>.</div>;

export const cavBtn = (txt, ico, cb, myClass, txtClass='LDMiniTxt', enable=true, iconColor="") => {
    const onClick_ = (enable && cb) || undefined; 
    const exStyle = iconColor?{color:iconColor}:{};
    return <div className={myClass + (enable?' clickable ':' disable unclickable ')} onClick={onClick_} style={exStyle}>
        <CpIco src={ico} width={'20px'} height={'20px'} /><span className={'semi-bold ' + txtClass}>{txt}</span>
    </div>
    //iconButton(txt,ico,color,'',cb,true, style,{}, enable);
};

const cavBtn_org = (txt, ico, cb, style,color='black',enable=true) => iconButton(txt,ico,color,'',cb,true, style,{}, enable);
const SideAnsPanel = (props) => {
    const {ansArr, selIndex, onRAnsClick, onDblClick} = props;
    return ansArr.map((ans, ii)=>{
        //ans.qvalue[].data
        const correctIndex = ans.qvalue.findIndex((item)=>{return item.correct?(item.correct):false});
        return <div key={'ans'+ii} className='flexRowStart' style={{padding:'5px'}}>
            <div className='flexRowEnd' style={{minWidth:'10%', maxWidth:'10%',marginRight:'5px',
                color:selIndex===ii?'#ec8008':'black'}}>{ii+1}{'.'}</div>
            <div className='LDAnsBlockEdit clickable' onClick={()=>onRAnsClick(ans.oupansid)}>
                <QEditorReadOnly data={ans.qvalue[(correctIndex<0)?0:correctIndex].data}/>
            </div>
        </div>;
    });
};
//<div className='LDAnsBlockEdit' onDoubleClick={()=>onDblClick(ans.oupansid)} onClick={()=>onRAnsClick(ans.oupansid)}>

/* org tool bar
            <div className='LDCavBtnCont'>
                <div className='LDCavBtnContent'>
                    {cavBtn('','labelDiagram/image', doAddBG, {transform:"scale(1.2)"})}
                    {cavBtn('','labelDiagram/fitWidth', (e) => setLDMode('ftw'), {transform:"scale(1.2)"},
                        LDMode==='ftw'?'#2995cd':'black')}
                    {cavBtn('','labelDiagram/fitHeight', (e) => setLDMode('fth'), {transform:"scale(1.2)"},
                        LDMode==='fth'?'#2995cd':'black')}
                    <div style={{maxWidth:'2px', minHeight:'100%', backgroundColor:'lightgrey', 
                        color:'lightgrey', overflow:'hidden'}}>.</div>
                    {cavBtn('Add a blank','labelDiagram/add', addLDAns, {transform:"scale(1.2)"},
                        myCV?'black':'#b4b4b4')}
                </div>
            </div>   
*/