const createBarData = (props) => {
    const {xItems, data, startingPoint, xAxisMargin} = props
    const barData = {bar: []};

    let currentX = startingPoint;
    let currentIndex = 0;

    xItems.forEach((xItem, idx) => {
        const found = data.filter((item) => item.referenceId === xItem.id);

        if (!Array.isArray(barData['bar'][currentIndex])) {
            barData['bar'][currentIndex] = [];
        };

        if (found) {
            found.map(f => {
                barData['bar'][currentIndex] = [...barData['bar'][currentIndex], {
                    x: currentX, y: f.value, ...f
                }];
            });
        } else {
            if (barData['bar'][currentIndex].length > 0) {
                currentIndex++;
            };
        };
        currentIndex++
        currentX += xAxisMargin;
    });
    const bar = barData.bar.filter(arr => arr.length > 0);
    return {bar};
};

export const getBar = (props) => {
    const {barStartingY, barWidth, valueRangeSize, maxValue} = props;  
    const barChartData = createBarData({...props});
    let result = {bar: []};
    for (let i = 0; i < barChartData.bar.length; i++) {
        const bars = barChartData.bar[i]
        for (let j = 0; j < bars.length; j++) {
            const {id, x, y, color, referenceId} = bars[j];
            const prevValues = bars.slice(0, j + 1);
            const sum = prevValues.reduce((acc, curr) => acc + curr.value, 0);
            result['bar'].push([
                ['x', `${x}`],
                ['y', `${barStartingY}`],
                ['width', `${barWidth}`],
                ['height', `${(sum) / maxValue * (valueRangeSize - barStartingY)}`],
                ['fill', `${color}`],
                ['text-anchor', `middle`],
                ['group-item-data', `${id}`],
                ['group-x-data', `${referenceId}`],
                ['value', `${y}`],
                ['sum', `${sum}`]
            ])
        };
    };
    result['bar'].reverse();
    return result;
};