import React, { useState } from 'react';
import { _ATRoot, _WebRoot } from '../../consts/ATConstReact';
import { _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, _ATMetaType_EX, _ATMetaType_GR, _ATMetaType_QT, _ATMetaType_TB } from '../../consts/ATMetaTypes';

import { ReduxBind } from '../../saga/ReduxState';

import { useMediaCache } from '../../AppExPf/utils/useMediaCache';
import { subState } from '../../AppExPf/utils/useChain';

import Ckeditor5Base from '../../AppExPf/components/ckeditor5/Ckeditor5Base';
import CpMJX from '../../AppExPFUser/_components/CpMJX';

//const cssCK = {width:'100%', padding:'16px', margin:'16px'};
//const baseUrl = _WebRoot + 'dev';
const presetData = '<p><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><msqrt><mi>a</mi><mi>a</mi></msqrt><annotation encoding="application/vnd.wiris.mtweb-params+json">{"toolbar":"<toolbar ref="quizzes" removelinks="true">&nbsp;</toolbar>"}</annotation></semantics></math>&nbsp;hello&nbsp;<math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mfenced><mtable><mtr><mtd><mi>a</mi></mtd><mtd><mi>b</mi></mtd></mtr><mtr><mtd><mi>c</mi></mtd><mtd><mi>d</mi></mtd></mtr></mtable></mfenced><annotation encoding="application/vnd.wiris.mtweb-params+json">{"toolbar":"<toolbar ref="quizzes" removelinks="true">&nbsp;</toolbar>"}</annotation></semantics></math></p>';

const PageDevCK = ReduxBind(props => {
    const [fullQ, _setFullQ] = useState({});
    const [Q, _setQ] = useState({});
    const [qData, setQData] = useState(presetData);

    const [onAddMedia, setOnAddMedia] = useState(0);
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, props.dispatch);
   
    const setFullQ = key => val =>  _setFullQ(subState(key, val));
    const setQ = key => val =>  _setQ(subState(key, val));
    const editable = true;
    
    const ckProps = {fullQ, setFullQ, Q, setQ, editable, setOnAddMedia, mediaDLs, getMediaDLs };

    return <div className='flexColStart' style={{padding:"10px"}}>
        <div className="flexColStartFit" style={{width:"100%"}}>
            <div>{'@wiris/mathtype-ckeditor5 (8.1.0)'}</div>
            <Ckeditor5Base {...ckProps} editorType="inputCKTest" data={qData} showStatus={true} myClass=""
            enable={true} setData={setQData} noDragDrop={true} debug={true}/>
        </div>
        <div style={{display:"flex", flexDirection:"row", width:"100%", color:'green'}}>
            <div className="flexColStart" style={{width:"70%"}}>
                <div style={{marginTop:'10px', }}>{'CKeditor.onChange() => CKeditor.getData()'}</div>
                <div style={{border:"2px solid green"}}>{qData}</div>
            </div>
            <div className="flexColStart" style={{width:"30%",}}>
                <div style={{marginTop:'10px'}}>{'MathJax>'}</div>
                <CpMJX><div className="ck-content" style={{border:"2px solid green", width:'100%'}} dangerouslySetInnerHTML={{__html: qData}} /></CpMJX>
            </div>
        </div>       
        <div style={{display:"flex", flexDirection:"row", width:"100%", color:'blue'}}>
            <div className="flexColStart" style={{width:"70%"}}>
                <div style={{marginTop:'10px'}}>{'Saved Data from CKeditor (a constant for reference)'}</div>
                <div style={{border:"2px solid blue"}}>{presetData}</div>   
            </div>
            <div className="flexColStart" style={{width:"30%",}}>
                <div style={{marginTop:'10px'}}>{'MathJax>'}</div>
                <CpMJX><div className="ck-content" style={{border:"2px solid blue", width:'100%'}} dangerouslySetInnerHTML={{__html: presetData}} /></CpMJX>
        </div></div></div>
});
export default PageDevCK;

