import { takeEvery, /*put, call , all, takeLeading, takeLatest*/ } from 'redux-saga/effects'
import { isAry } from '../libs/libType';
import { apiSaga } from './api.saga';

// ==== ==== Test 2 ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== 
/*
Test 2: 
A Task that call 3 Api Steps  
When a new task start, stop the working one (if exist) >> 
Stop if:
    - Complete all Steps 
    - Error on any step
    - A New Task Start Before current Task Complete (saga TakeLatest)
    - A New Task (with Cancel Flag) Start, (new Task Do Nothing because cancel) 
No Stop even if:
    - Change UI
    - Logout (but should API call should not success)

*/
// ==== ==== Types; Type of Dispatch/Put Actions ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== 
const APICB_EVERY = 'APICB_EVERY'; //command: execute all api calls
//const APICB_LATEST = 'APICB_LATEST'; //command: the newest apicall override previous api calls
//const APICB_LEADING = 'APICB_LEADING'; //command: no subsequence api call if one is running
const APICB_INF_ADD = 'APICB_INF_ADD'; //event (Append Status)
const APICB_INF_CLEAR = 'APICB_INF_CLEAR'; //event (Clear Status)

// ==== ==== reducer; to update State per action ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
const initState = {done:1, infs:[]}; //'done' means not running
export const apiCBReducer = (state = initState, action) => {
    switch (action.type) {
        case APICB_INF_ADD:
            if(isAry(action.inf))
                return {infs:[...state.infs, ...action.inf]};
            return {infs:[...state.infs, action.inf]};
        case APICB_INF_CLEAR:
            return {infs:[]};
        default:
            return state;
    }
};

// ==== ==== action; to be dispatch() By UI / put() by Services ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export const APICB_every = (act, callback, form, file, callbackWIP) => { 
    //lj({APICB_every:{act, callback}}); 
    return { type: APICB_EVERY, act, callback, form, file, callbackWIP}; 

};
//const APICB_inf_add = (inf) => { return {type:APICB_INF_ADD, inf}; };
//export const APICB_inf_clear = () => { return {type:APICB_INF_CLEAR}; };

// ==== ==== saga; watcher saga watch for action / action saga call() service, put() action= === ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
export function* watchApiCBSaga() {
    yield takeEvery(APICB_EVERY, ApiCBSaga); //new start will run, parallel with many running
    //yield takeLeading(APICB_LEADING, ApiCBSaga); //new start is ignored, until running one is finish
    //yield takeLatest(APICB_LATEST, ApiCBSaga); //new start will run, running one will be stop on next yield
};

export function* ApiCBSaga(action) {
    try{
        const { act, callback, form, file, callbackWIP } = action;
        do{ 
            //lj({func:'ApiCBSaga', action, callback}); 
            //yield put(loading_add('ApiCB', 1));
            /*const {res, err} =*/ yield apiSaga(act, callback, form, file, callbackWIP);
            
            //lj({test2RunSaga__: {res, err}});//, callback}});
            //if(callback) yield call(callback, res, err); //callback by apiSaga
        }while(0);
    }catch(e){
        console.error(e);
    }
    //yield put(loading_add('ApiCB', -1));
};

// ==== ==== supporting services: to be call by saga ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ==== ====






