import {Range} from "../type/ChartType"

export const getMiniRangeBar = (props: {
    minWidth?: number,
    edge?: number | undefined,
    shiftBubbleLeft?: number,
    shiftBubbleRight?: number
    range?: Range | undefined

    maxPercentage: number,
    minPercentage: number,
}) => {

    const {
        maxPercentage,
        minPercentage,
        minWidth = 1.5,
        edge = 10,
        shiftBubbleLeft = 25,
        shiftBubbleRight = 10,
        range
    } = props
    let width = maxPercentage - minPercentage
    let left = minPercentage - 0
    let indicatorShiftStyle = ""

    if (width === 0) {
        left = Math.max(left - minWidth / 2, 0);
        left = Math.min(left, 100 - minWidth);
    } else if (width < minWidth) {
        left -= minWidth / 2
        left = Math.max(left, 0);
    }


    //if max and min !== 0 but width ===0, set width = minWidth
    if (maxPercentage !== 0 && minPercentage !== 0) {
        width = Math.max(width, minWidth)
    }

    let indicatorLeftFormular = left + width / 2
    let indicatorLeft = `${indicatorLeftFormular}%`

    //if indicator: 0 - 10 || 90 - 100, shift the bubble
    if ((minPercentage <= edge || maxPercentage >= 100 - edge) && maxPercentage !== minPercentage && width < edge) {
        if (minPercentage <= edge) {
            indicatorLeft = `calc(${indicatorLeftFormular}% + ${shiftBubbleRight}px)`
            indicatorShiftStyle = "shiftRight"
        }
        if (maxPercentage >= 100 - edge) {
            indicatorLeft = `calc(${indicatorLeftFormular}% - ${shiftBubbleLeft}px)`
            indicatorShiftStyle = "shiftLeft"
        }


    }

    if (range !== undefined && minPercentage === 0 && maxPercentage === 0) {
        indicatorLeft = `calc(${minWidth / 2}%)`
        indicatorShiftStyle = ""
    }

    return {
        width: `${width}%`,
        left: `${left}%`,
        indicatorLeft,
        indicatorShiftStyle
    }
}