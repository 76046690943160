import React from 'react';
import ATSwitch from '../components/Switch0';
import { ATUIText } from '../AppUtil';
import { svgIcon2 } from '../components/CpATIcoBtn';
import MCChk from '../components/CpMCTblChk';
import { useUILang } from '../utils/useUILang';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';

const baseColor = '#2995cd';

export const _alignClass = { left: 'exAlignLeft', center: 'exAlignCenter', right: 'exAlignRight', };
export const DisplayTitle = (props) => {
    const {val, field, cb } = props;
    const [ t ] = useUILang();
    return <div className='flexRowBetween'>
    <div>{t('at-setting.exercise.displayTitle')}</div>
    <div><ATSwitch id='DisplayTitle' leftText='' rightText='' disabled={false}
    selected={val} leftValue={0} rightValue={1}
    setter={cb(field)} width='40' fontSize='16' color={baseColor} leftColor='#AFAFAF' /></div>
    </div>;
};

export const ContentSize = (props) => {
    const {v1, v2, f1, f2, cb } = props;
    const [ t ] = useUILang();

    const sizeInput = (val, key) => {
        return ATUIText(val, cb(key),key,false,'','',{margin:'2px 0px', textAlign:'center', width:'45px'
            ,border:'1px solid lightgrey'})
    };

    const unitpx = t('unit.px');

    return <div className='flexRowBetween'>
        <div>{t('at-setting.exercise.size')}</div>
        <div className='flexRowCenterFit' style={{verticalAlign:"bottom"}}>
            {sizeInput(v1, f1)}<span style={{whiteSpace:'nowrap', margin:'0 5px'}}>{unitpx}</span>
            <CpIco src={IconList.general.incorrect} />
            {sizeInput(v2, f2)}<span style={{whiteSpace:'nowrap', margin:'0 5px'}}>{unitpx}</span>
        </div>
    </div>;
};

export const _optType = { video:'V', audio:'A', audioUI:'U', align:'L' };
export const RadioOptions = (props) => {
    const {val, field, cb, types } = props;
    const [ t ] = useUILang();

    const _alignTypes = {
        left:  {caption: t('at-setting.exercise.leftAlign'), icon: 'general/alignmentLeft'}, 
        center:{caption: t('at-setting.exercise.centerAlign'), icon: 'general/alignmentCenter'},
        right: {caption: t('at-setting.exercise.rightAlign'), icon: 'general/alignmentRight'},
    };

    const _ATypes = {
        ul:{caption:t('at-setting.exercise.upload'), icon:'sourceType/upload'},
        yt:{caption:t('at-setting.exercise.youtube'), icon:'sourceType/youtube'},
        bc:{caption:t('at-setting.exercise.brightcove'), icon:'sourceType/brightcove'},
        ex:{caption:t('at-setting.exercise.externalLink'), icon:'sourceType/externalLink'},
    };

    const _VTypes = {
        ul:{caption:t('at-setting.exercise.upload'), icon:'sourceType/upload'},
        yt:{caption:t('at-setting.exercise.youtube'), icon:'sourceType/youtube'},
        bc:{caption:t('at-setting.exercise.brightcove'), icon:'sourceType/brightcove'},
        ex:{caption:t('at-setting.exercise.externalLink'), icon:'sourceType/externalLink'},
    };    

    const _UITypes = {
        btn:{caption:t('at-setting.exercise.inlineIcon'),icon:'presentation/restrictPlayerMode'},
        ply:{caption:t('at-setting.exercise.inlinePlayer'),icon:'presentation/fullControlPlayerMode'},
    }; 

    const doType = types === _optType.video ? _VTypes:
        types === _optType.align ? _alignTypes:
        types === _optType.audio ? _ATypes:
        types === _optType.audioUI ? _UITypes:
        {};

    return <div className='flexColStart' style={{paddingLeft:'20px'}}>
        {Object.entries(doType).map(([k, v]) => {
            
            return <div key={k} className='flexRowStart clickable' style={{padding:'0px 0px 20px 5px'}}
                onClick={()=>cb(field)(k)}>
                {MCChk(val===k,()=>cb(field)(k),k,true,baseColor,baseColor, false,false)}
                {svgIcon2(v.icon,baseColor,{margin:'0px 5px 0px 10px', width:'25px', height:'25px'})}{v.caption}
            </div>}
        )}
    </div>;
};

export const SpaceL = () => <div style={{width:'100%',minHeight:'15px'}}></div>;