import { useEffect, useState } from "react";
import { Button, CloseButton, OverlayTrigger, Popover } from "react-bootstrap";

import * as UI from '../../libs/libUI';

import { useUILang } from "../../AppExPf/utils/useUILang";

const CpColorSelect = (props) => { //base on ColorSelect
    const {idx, setIdx, color2D} = props;
    const [t] = useUILang();
    const [show, setShow] = useState(false);
    const [addShow, setAddShow] = useState(false);
    //useEffect(() => { return () => { setShow(false); setAddShow(false) }; }, []);

    const clickIdx = i => e => {
        UI.stopEvent(e);
        setIdx(i);
        setShow(0);
        setAddShow(0);
    };
    const clickX = e => { UI.stopEvent(e); setShow(false); setAddShow(false); };
    const handleColor = e => {
        UI.stopEvent(e);
        
    };

    const _idx = color2D[idx]? idx: 0;
    const color = color2D[_idx];

    const exist = 1;
    const popover = () => (<Popover>
        <Popover.Header as="h3">
            <div className={"d-flex justify-content-between align-items-center"}>
                <span>{t(exist? "edit-color": "update-color")}</span>
                <span><CloseButton onClick={clickX}></CloseButton></span>
            </div>
        </Popover.Header>
        <Popover.Body>
            <div className={"d-flex flex-column gap-2 w-100 align-items-start justify-content-center mb-3"}>
                <span className={"d-flex  gap-2 justify-content-between align-items-center w-100"}>
                    <label>{t("background-color")}:</label><input type={"color"} value={color?.bgcolor} onClick={handleColor}/>
                </span>
                <span className={"d-flex gap-2 justify-content-between align-items-center w-100"}>
                    <label>{t("text-color")}:</label><input type={"color"} value={color?.color} onClick={handleColor}/>
                </span>
            </div>
            <div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
                <Button variant="gray-danger w-100 border" onClick={clickX}> {t("remove")} </Button>
                <Button variant="exercise-third-btn w-100 border" onClick={clickX}>{t(exist? "add": "update")}</Button>
            </div>
        </Popover.Body>
    </Popover>);


    return <>
        {color2D.map((color, i) => <OverlayTrigger show={show && (i === _idx)} key={i} trigger="focus" placement="top"
            overlay={popover} rootClose><span role={"button"} onClick={clickIdx(i)}
            className={`d-flex justify-content-center align-items-center collection-color-picker ${i === _idx? "active": ""}`} >
            <span className={"color"} style={{backgroundColor: color.color}}>
                <span className={"color"} style={{backgroundColor: color.bgcolor}}></span>
            </span>
        </span>
        </OverlayTrigger>)}
        {false && <OverlayTrigger show={addShow} trigger="focus" placement="top" overlay={popover} rootClose>
            <span role={"button"} onClick={() => clickIdx(-1)}
                  className={`d-flex justify-content-center align-items-center collection-color-picker border-dim`}>
                <span className={" fs-8 user-select-none"}>+</span>
            </span>
        </OverlayTrigger>}
    </>
}
export default CpColorSelect;