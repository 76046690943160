import { ATUILabel, ATUIText } from '../AppUtil';
import * as UI from '../../libs/libUI';

export const exerTitle = (txt) => {return (txt!==''?<div className='ATExerTitle f16 semi-bold '>{txt}</div>:'')};
export const exerBlock = (content, myStyle={}) => 
    {return (content!==''?<div className='ATExerBlock' style={{...myStyle}}>{content}</div>:'')};
export const viewExLabel = (t1, t2) => {return ATUILabel(t1,t2,false,120,'ViewExLabel',{alignSelf:'flex-start'})};
export const exATUIText = (t, f, id) => ATUIText(t, f, id,false,'','viewExInput',{width:'100%',margin:0});
export const exTextArea = (t, f, id) => UI.EpInputArea0(t, f, id, 'viewExInput');
export const popA = (key, href, body, onClick=()=>{}) => <a key={key} onClick={onClick} target="_blank" href={href} rel="noopener noreferrer">{body}</a>;
export const prefixURL = (url) => {
    let result = url.trim();
    if (!result.startsWith('http://') && !result.startsWith('https://')) {
        result = 'https://'+result;
    };
    
    return result;
};