import {memo, useEffect, useMemo, useRef} from "react";
import { PREFIX } from "./CpPrefix";
import { toStr } from "../../libs/libType";

const CpCheckboxComponent = (props) => {
    const {
        //disabled, 
        lock = false,
        id = "",
        label = "",
        size = "",
        className = "",
        checked = false,
        indeterminate = false,
        variant = "",
        checkIcon = true,
        ...checkboxProps
    } = props;
    const ref = useRef(null);
//variant !== "" ? `checkbox-${variant}` : "",
    const checkboxStyle = useMemo(() => {
        return [
            className,
            PREFIX.CHECKBOX + size,
            variant !== "" ? `checkbox-${variant}` : "",
            checkIcon ? "checkicon" : "",
            checked ? "checked" : "",
            "p-0",
            "d-flex flex-row justify-content-start",
        ].filter(item => item).join(' ')
    }, [checked, className]);

    useEffect(() => {
        if (ref.current && indeterminate) { ref.current.indeterminate = indeterminate };
    }, [indeterminate]);

    useEffect(() => {
        if (ref.current) { ref.current.checked = checked };
    }, [checked]);

    return <div role={"button"} className={'CpCheckboxComponent ' + checkboxStyle + (lock? ' disable-option': '' )} {...checkboxProps} >
        <input ref={ref} type="checkbox" id={id} />
        <label htmlFor={id} className={'flex-wrap '+(label? "ms-2": "")} >
            {label? <div className={"flex-wrap"} >{toStr(label)}</div>: ''}
        </label>
    </div>
};

export default memo(CpCheckboxComponent);