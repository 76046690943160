import * as FIBC from './ATQtnAnsTypeFIB';
import { toObj, isAry, toAry, toStr, toInt } from '../libs/libType';
import { compareInt, compareStr, compareMath, compareMath2Str } from '../libs/libAnsCompare';
import { _MST_MARKED, _WST_SUBMIT } from "./ATConstsAssignment";
import { __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_FIB, __SYSQSubType_LBT,
    __SYSQSubType_LBD, __SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_POL,
    __RESP_TXT, __RESP_DRAW, __RESP_FILE, __RESP_IMAGE, __RESP_URL, __SYSQSubType_OED } from "./ATSysQType";
import { __MCTT_TTB, __MCTT_TTC } from './ATQtnAnsTypeMCQ';
import { _ExCtType_Qtn } from './ATValidateEcnts';
import { markState } from '../AppExPFUser/EPAsmWork/utilAsmWork';
import { __FIBT_TXT, __FIBT_DRD } from './ATQtnAnsTypeFIB';
import { deepCopy } from '../AppExPf/AppUtil';
import { getFIBAnsID } from '../libs/libAnsCompare';
import { qHasCorrect } from './ATValidateQ';
import { objEntries } from '../libs/libType';
/*
    stQtnAllInfo - allMarked = 1/0 (for this question all marked)
    getAllQtnStatInfo - MStat.allMarked = sum of stQtnAllInfo.allMarked

MStat {
    stScore: student got total score

    mAnsCnt: manual mark answers answered by student count
    aAnsCnt: auto mark answers answered by student count
        
    mQtnCount: total manual mark answers count 
    aQtnCount: total auto mark answers count

    mQtnScore: manual mark question total score
    aQtnScore: auto mark question total score

    mScore: student got total manual score
    aScore: student got total auto score

    mCount: student correct manual answer count
    aCount: student correct auto answer count
    
    mMarked: total manual answers marked by teacher
    aMarked: total auto answers marked by teacher
            
    allAnswered: count of questions are answered (all answers within a question)
    allMarked:  count of questions are marked

    totalQtn: total question count
            
    totalAutoMQ: total auto mark question count
    hasAnswered: count of questions has answered (any answers within a question)
}
*/

// idx just for display info check
// 
// (per question) question level statistic, per question, per student resp + per teacher resp
// call after doEx
export const stQtnAllInfo = (idx, fullQ, _studentResp, _teacherResp, showEn, preview=0) => {
    if (!fullQ) return; // non-question
    const SQType = fullQ?.SQType;
    const Q = showEn?(fullQ?.QEn):(fullQ?.QCt);
    if (!Q) return;
    const totalScore = toInt(fullQ?.score);
    const scoreMethod = fullQ?.scoreMethod || FIBC.__FIBS_PBK; // per blank
    const caseSensitive = fullQ?.caseSensitive || 0;
    const autoMark = fullQ?.autoMark || 0;
    const studentResp = toObj(_studentResp);
    const teacherResp = toObj(_teacherResp);    
    // student statistic
    //mAnsCnt, aAnsCnt = how many manual/automark student answered
    //mMarked, aMarked = how many answer marked manually/auto
    //mCount, aCount = student has correct manual/automark answser count
    //mScore, aScore = student got manual/automark total score

    // question statistic
    //mQtnCount, aQtnCount = total manual/automark answers inside the question
    //mQtnScore, aQtnScore = question max manual/automark score can get
    //isOP = is open question?
    //opAttempt = open question attempted ?
    let [stScore,   mQtnScore, aQtnScore, mAnsCnt, aAnsCnt,
         mQtnCount, aQtnCount, mCount,    aCount,  
         mMarked,   aMarked,   mScore,    aScore, allAnswered, allMarked, isOP, opAttempt,
         isQtn,     isAutoMark, hasAnswered]
        = [0,0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0, 0,0, 0,0,0];
    const qArr = toAry(Q.qAnswers);
    const tArr = toAry(Q.tAnswers);   
    const sqObj = toObj(studentResp?.qresp);
    const smObj = toObj(studentResp?.math3Str);
    const tqObj = toObj(teacherResp?.qresp);    
    
    if (SQType === __SYSQSubType_MCS) { // auto mark
        //studentResp.qresp:['1,2','2,3'...] 'shuffle index, original index'
        isQtn = 1; isAutoMark = 1;
        const sqArr = toAry(studentResp?.qresp);
        // if find any correct ans student not choose, then 0 mark
        // or student choose more than correct
        stScore = (qArr.findIndex((ans,ii)=>{
            return (ans.correct && (sqArr.findIndex(v=>{return v.includes(","+ii)})<0)
            )})>=0 || sqArr.findIndex(v=>{
                const index = v.split(',');
                return !qArr[index[1]].correct;})>=0)
            ?0:totalScore;
        
        //if (Object.hasOwn(studentResp,'qresp')) aAnsCnt = 1;
        if (studentResp.hasOwnProperty('qresp')) aAnsCnt = 1;
        [aQtnScore, aQtnCount, aMarked, aCount, aScore]
            = [totalScore, 1, 1, (stScore > 0? 1: 0), stScore];
    } else if (SQType === __SYSQSubType_MCT) { // auto mark
        isQtn = 1; isAutoMark = 1;
        const stArr = toAry(studentResp?.qresp);
        // if find any correct ans student not choose, then 0 mark
        // or student choose more than correct, row 0 not count
        
        stScore = (
            (tArr.findIndex((row,ii)=>{
            return (ii>0 && row.findIndex((col,kk)=>{
                return (col.correct && (stArr.findIndex( v => (v === ( ii + ',' + kk))) < 0))
            }) >= 0)}) > 0) ||
            (stArr.findIndex(v => {                       
                const index = v.split(',');
                return !tArr[index[0]][index[1]].correct;
            })>=0)
            )?0:totalScore;
        //if (Object.hasOwn(studentResp,'qresp')) aAnsCnt = 1;
        if (studentResp.hasOwnProperty('qresp')) aAnsCnt = 1;
        [aQtnScore, aQtnCount, aMarked, aCount, aScore]
            = [totalScore, 1, 1, (stScore > 0? 1: 0), stScore];            
    } else if ([__SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_LBD].includes(SQType)) {
        /*
        blank - qAnswers[].correctAns = 1/0
        drop down - must have qAnswers[].correctAns
        */            
        //    __SYSQSubType_FIB  // auto or manual mark
        //    __SYSQSubType_LBT: // auto mark, must have correctAns
        //    __SYSQSubType_LBD: // auto mark  must have correctAns
        
        //studentResp.qresp: {oupansid:value}            
        //(sqObj.hasOwnProperty('qresp'))
        // qAnswers[].qvalue[]
        // qAnswers[].qtype = txt or dropdown
        // qAnswers[].atype = txt, num, equation or adv
        //per blank or per question, loop all to count
        isQtn = 1; isAutoMark = autoMark;
        const perBK = (scoreMethod === FIBC.__FIBS_PBK);
        let total = 0; // sum total score
        let wfound = 0; // set to 1 if found any wrong answer
        //qArr.forEach(async (aopt)=>{
        mMarked = Object.keys(tqObj).length;
        for (let jj=0;jj<qArr.length;jj++) {
            const aopt = qArr[jj];
            const qType = aopt?.qtype || FIBC.__FIBT_TXT;
            const ansType = aopt?.atype || FIBC.__FIBA_TXT;
            const showCK = (ansType === FIBC.__FIBA_EQU);
            let correctIndex = -1;
            const aoptopt = aopt.qvalue;
            if (qType === FIBC.__FIBT_TXT) { // text, auto or manual mark
                if (!autoMark) { // check teacher resp correct
                    
                    mQtnCount++;
                    if (sqObj[aopt.oupansid]) mAnsCnt++; // answered
                    if (perBK) mQtnScore = mQtnScore + aopt.score;
                    if (tqObj[aopt.oupansid]) { // teacher mark this answer is correct
                        correctIndex = 1; // set any positive value
                        if (perBK) mScore = mScore + toInt(aopt.score);
                        mCount++;
                    } else { wfound = 1 };
                } else {
                    aQtnCount++;
                    aMarked++;
                    if (perBK) aQtnScore = aQtnScore + aopt.score;
                    if (sqObj[aopt.oupansid]) aAnsCnt++; // answered
                    let cfound = 0;
                    //console.log('aoptopt.length',aoptopt.length);
                    for (let ii=0;ii<aoptopt.length;ii++) {
                        if ( showCK ) { // use math3Str
                            if (preview) {
                                cfound = compareMath2Str(aoptopt[ii].data,sqObj[aopt.oupansid]);
                            } else {
                                cfound = compareMath(aoptopt[ii]?.math3Str,smObj[aopt.oupansid]);
                            };
                        } else { // use data
                            cfound = compareStr(aoptopt[ii].data,sqObj[aopt.oupansid],caseSensitive);
                        };                        
                        //cfound = await compareFunc(aoptopt[ii].data,sqObj[aopt.oupansid],caseSensitive, preview);
                        if (cfound) {
                            //console.log('cfound',ii,cfound);
                            correctIndex = ii;
                            if (perBK) aScore = aScore + toInt(aopt.score);
                            aCount++;
                        break;}
                    };
                    if (!cfound) wfound = 1;
                };
            } else { // dropdown, auto mark, can override by question automark flag to manual mark
                if (autoMark) {
                    aQtnCount++;
                    aMarked++;
                    if (perBK) aQtnScore = aQtnScore + aopt.score;
                } else {
                    mQtnCount++;
                    mMarked++;
                    if (perBK) mQtnScore = mQtnScore + aopt.score;
                };
                if (sqObj[aopt.oupansid]>=0) {
                    if ( autoMark ) { aAnsCnt++; } else { mAnsCnt++; }
                    if ( aoptopt[sqObj[aopt.oupansid]].correct ) {
                        correctIndex = sqObj[aopt.oupansid];
                        if (autoMark) {
                            if (perBK) aScore = aScore + toInt(aopt.score);
                            aCount++;
                        } else {
                            if (perBK) mScore = mScore + toInt(aopt.score);
                            mCount++;
                        };
                    } else { wfound = 1 };
                };
            };
            
            if (perBK && (correctIndex >=0)) total = total + toInt(aopt.score);
        };
        if (perBK) { stScore = total; }
        else {
            if (autoMark) { aQtnScore = totalScore; } else { mQtnScore = totalScore; };
            if (!wfound) {
                stScore = totalScore; 
                if (autoMark) { aScore = totalScore; } else { mScore = totalScore; };
            };
        };
    } else if ([__SYSQSubType_OED, __SYSQSubType_OEG, __SYSQSubType_OEE].includes(SQType)) {
        isQtn = 1; isAutoMark = 0;
        // manual mark, fullQ.correctness 1/0, show correct/incorrect button
        // for both OEG, OEE
        // correctness = 1, has correct flag by teacher, manual mark by teacher
        // correctness = 0, no correct flag, manual mark by teacher  
        // manual mark field "mMark": integer       
        // manual corretness field "mCorrect": 1/0
        //stScore = totalScore;
        //if (Object.hasOwn(teacherResp,'mMark') || Object.hasOwn(teacherResp,'mCorrect')) mMarked = 1;
        if (teacherResp.hasOwnProperty('mMark') || teacherResp.hasOwnProperty('mCorrect')) mMarked = 1;
        //stScore = (teacherResp?.mCorrect) ? (teacherResp?.mMark || 0):0;
        //if (Object.hasOwn(studentResp,'qresp')) opAttempt = 1;
        if (studentResp.hasOwnProperty('qresp')) opAttempt = 1;
        const correctOP = (teacherResp?.mCorrect)?1:0;
        
        stScore = toInt(teacherResp?.mMark);
        [mQtnScore, mAnsCnt, mQtnCount, mCount, mScore, isOP]
         = [totalScore, opAttempt, 1, correctOP, stScore, 1];
    } else if (SQType === __SYSQSubType_POL) { // no mark, polling = (isAutoMark = 1 and isOP = 1)
        isQtn = 1; isAutoMark = 1; aQtnCount = 1; aMarked = 1;
        stScore = 0; mQtnScore = 0; aQtnScore = 0;
        if (studentResp.hasOwnProperty('qresp')) { aAnsCnt = 1; }
    };
    allAnswered = ((aQtnCount + mQtnCount) === (aAnsCnt + mAnsCnt))?1:0;
    hasAnswered = ((aAnsCnt > 0) || (mAnsCnt > 0))?1:0; // answer any blank inside a question
    allMarked = ((aQtnCount + mQtnCount) === (aMarked + mMarked))?1:0;
    const stQtnAllInfo = {stScore,   mQtnScore, aQtnScore, mAnsCnt, aAnsCnt,
            mQtnCount, aQtnCount, mCount,    aCount,  
            mMarked,   aMarked,   mScore,    aScore, allAnswered, allMarked, isOP, opAttempt,
            isQtn,     isAutoMark, hasAnswered};
    

    // direct modify teacher resp
    teacherResp.aCount = toInt(aCount);
    teacherResp.mCount = toInt(mCount);
    teacherResp.aScore = toInt(aScore);
    teacherResp.mScore = toInt(mScore);
    teacherResp.isQtn = isQtn;
    teacherResp.isAutoMark = isAutoMark;
    
    teacherResp.isRight = (((mScore + aScore) === (mQtnScore + aQtnScore)) || teacherResp?.mCorrect)?1:0;
    teacherResp.allMarked = (mMarked === mQtnCount && aMarked === aQtnCount)?1:0;
    teacherResp.isOP = isOP;
    teacherResp.opAttempt = opAttempt;
    teacherResp.hasAnswered = hasAnswered;
//console.log('totalScore, mScore, aScore, mQtnScore, aQtnScore',totalScore, mScore, aScore, mQtnScore, aQtnScore);
//console.log('tr', teacherResp);
    return stQtnAllInfo;
};
//--------------------------------------------------------------------------------------------------------
export const qtnStAnsCnt = (fullQ, stResp, isEn=1) => {
    // check qresp exist
    // mcg, mct qresp = [ ... ]
    // fib, lib qresp = { ... }
    // ope, oee (respType + qresp) all exist
    const qtnType = fullQ?.SQType;
    const qtn = isEn?(fullQ?.QEn):(fullQ?.QCt);
    let [ansTotal, answered] = [0, 0];
    ansTotal = (qtn?.qAnswers)?qtn.qAnswers.length:0;
    if (toObj(stResp).hasOwnProperty('qresp')) {
        if ([__SYSQSubType_FIB,__SYSQSubType_LBT,__SYSQSubType_LBD].includes(qtnType)) {
            answered = Object.keys(toObj(stResp?.qresp)).length;
        } else if (isAry(stResp?.qresp)) {
            answered = stResp.qresp.length; }
        else {
            answered = 1;
        };
    };
    return {ansTotal, answered};
};

//--------------------------------------------------------------------------------------------------------
// answer level one question statistic, all students, all teachers
/*
resp.qDone: 1/0
work{ key:{ resp:[{qresp:...}...], WState:,  }}
mc: resp.qresp[0,0]
fib,lbd: resp.qresp{ansid:value, ...}
ope: resp.respType
     resp.qresp{ data ....}
polling: ?

mark{ key:{ resp:[{qresp:...},{},...] }}
      "resp": [
         {
            "aCount": 1,
            "mCount": 0,
            "mScore": 0,
            "aScore": 1,
            "isRight": 1,
            "allMarked": 1,
            "opAttempt": 0,
            "isOP": 0
            "qresp": ...
         },
*/

// create data for report use
// (sum) assignment's questions -> all assigned students
const getOupAns = (arr, oupid) => {
    return arr.find( ans => {
        return (ans.oupansid === oupid);
    });
};
export const qtnAnsStat = (_QIds, qtns, _works, _marks, showEn) => {
    const works = toObj(_works);
    const marks = toObj(_marks);
    const stKey = Object.keys(works);
    //const thKey = Object.keys(marks);
    
    const qtnStat = [];
    //toAry(QIds).map((QId, idx) => { // for every question
    const QIds = toAry(_QIds);
    for (let _idx=0; _idx < QIds.length; _idx++) { // for every question
        const QId = QIds[_idx].QId;
        const idx = QIds[_idx].idx;
        
        const fullQ = qtns[QId];
        const Q = showEn?(fullQ?.QEn):(fullQ?.QCt);    
        if (Q) {
            const SQType = fullQ?.SQType;
            //console.log('SQType',SQType);
            const ansChoice = fullQ?.ansChoice;
            const totalScore = toInt(fullQ?.score);
            const scoreMethod = fullQ?.scoreMethod || FIBC.__FIBS_PBK; // per blank
            const caseSensitive = fullQ?.caseSensitive || 0;
            const autoMark = fullQ?.autoMark || 0;
            const hasCorrectness = qHasCorrect(fullQ);
            const qArr = toAry(Q.qAnswers);
            const tArr = toAry(Q.tAnswers);
            const qData = toStr(Q.qData);
            // total submitted student
            const stWrong = []; // count per question, student do wrong
            const ansStat = [];
            let QCorrCnt = 0;
            let stgotTScore = 0;
            let QtotalAtt = 0;
            if (SQType === __SYSQSubType_MCS) { // auto mark
                //studentResp.qresp:['1,2','2,3'...] 'shuffle index, original index'
                qArr.forEach((ans, ii)=>{ // for every answer
                    let totalAtt = 0;
                    const stList = [];
                    stKey.forEach((id, jj)=>{ // for every student
                        //let wfound = 0;
                        const stWork= toObj(works[id]);
                        const sqArr = toAry(toAry(stWork?.resp)[idx]?.qresp); // is array
                        if (stWork.WState === _WST_SUBMIT) {
                            totalAtt++;
                            // student choose this ans
                            if (sqArr.findIndex(v=>{return v.includes(","+ii)}) >= 0) {
                                stList.push(id);
                                if (!ans.correct && !stWrong.includes(id)) stWrong.push(id);
                            } else if (ans.correct && !stWrong.includes(id)) {
                                stWrong.push(id);
                            };
                        };
                        //if (!wfound) stgotTScore = stgotTScore + totalScore;
                    });
                    
                    ansStat.push({isCorrAns:ans.correct?1:0, stList});
                    QtotalAtt = totalAtt; // will count every answer loop, don't care
                    stgotTScore = (totalAtt - stWrong.length) * totalScore;
                });
            } else if (SQType === __SYSQSubType_MCT) { // auto mark
                // for every answer
                tArr.forEach((row, rr) => { // row
                    if (rr > 0) { //ignore row 0
                        row.forEach((col, cc) => { // column
                            if ((ansChoice === __MCTT_TTC && cc > 0) //checkbox, ignore column 0
                                || (ansChoice === __MCTT_TTB && cc === 0)) { //list, just do column 0
                                let totalAtt = 0;
                                const stList = [];
                                const ansCorr = tArr[rr][cc].correct;
                                stKey.forEach((id, jj)=>{ // for every student
                                    const stWork= toObj(works[id]);
                                    const sqArr = toAry(toAry(stWork?.resp)[idx]?.qresp); // is array
                                    if (stWork.WState === _WST_SUBMIT) {
                                        totalAtt++;
                                        // student choose this ans
                                        if (sqArr.findIndex(v=>{return v===(rr+','+cc)}) >= 0) {
                                            stList.push(id);
                                            if (!ansCorr && !stWrong.includes(id)) stWrong.push(id);
                                        } else if (ansCorr && !stWrong.includes(id)) { // st not choose correct answer
                                            stWrong.push(id);
                                        };
                                    };
                                });  
                                ansStat.push({row:rr,col:cc,isCorrAns: ansCorr?1:0, stList});          
                                QtotalAtt = totalAtt;    
                                stgotTScore = (totalAtt - stWrong.length) * totalScore;
                            };
                        });
                    };
                });
            } else if ([__SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_LBD].includes(SQType)) {
                const perBK = (scoreMethod === FIBC.__FIBS_PBK);
                //const ansidArr = getFIBAnsID(qData);
                const isFIB = (SQType === __SYSQSubType_FIB);
                const _qArr = isFIB?getFIBAnsID(qData):qArr;
                //console.log('_qArr', _qArr);
                //for (let ii=0;ii<ansidArr.length;ii++) { // per answer
                //for (let ii=0;ii<qArr.length;ii++) { // per answer
                for (let ii=0;ii<_qArr.length;ii++) { // per answer                
                    //const ansid = _qArr[ii];
                    //const aopt = getOupAns(_qArr, ansid);
                    //const aopt = qArr[ii];
                    const _aopt = isFIB?getOupAns(qArr, _qArr[ii]):qArr[ii];
                    const qType = _aopt?.qtype || FIBC.__FIBT_TXT;
                    const ansType = _aopt?.atype || FIBC.__FIBA_TXT;
                    const showCK = (ansType === FIBC.__FIBA_EQU);
                    //const compareFunc = (showCK?compareMath:compareStr);
                    //console.log('qType, ansType', qType, ansType);
                    let correctIndex = -1;
                    const aoptopt = _aopt.qvalue;
                    let totalAtt = 0;
                    const corrList = [];
                    const wrongList = [];
                    if (qType === FIBC.__FIBT_TXT) { // text, auto or manual mark
                        if (!autoMark) { //txt, manual mark, check teacher resp correct        
                            stKey.forEach((id, jj)=>{ // for every student
                                const stWork= toObj(works[id]);
                                //const sqObj = toObj(toAry(stWork?.resp)[idx]?.qresp); // is obj
                                const thMark= toObj(marks[id]);
                                
                                const allMarked = thMark?.MState === _MST_MARKED;
                                const thResp = toObj(toAry(thMark?.resp)[idx]);
                                const tqObj = toObj(toAry(thMark?.resp)[idx]?.qresp); // is obj
                                //if ( (thResp?.allMarked) && stWork.WState === _WST_SUBMIT) {
                                if ( (allMarked) && stWork.WState === _WST_SUBMIT) {                                    
                                    totalAtt++;
                                    if (tqObj[_aopt.oupansid]) { // teacher mark correct
                                        if (perBK) stgotTScore = stgotTScore + _aopt.score;
                                        corrList.push(id);
                                    } else {
                                        wrongList.push(id);
                                        if (!stWrong.includes(id)) stWrong.push(id);
                                    };
                                };
                            });
                        } else { // txt, auto mark
                            //stKey.forEach((id, jj)=>{ // for every student
                            for (let jj=0; jj<stKey.length; jj++) {
                                const id = stKey[jj];
                                const stWork= toObj(works[id]);
                                const stResp = toAry(stWork?.resp)[idx];
                                const sqObj = toObj(stResp?.qresp); // is obj
                                const smObj = toObj(stResp?.math3Str);
                                let cfound = 0;
                                if (stWork.WState === _WST_SUBMIT) {
                                    totalAtt++;
                                    for (let kk=0; kk<aoptopt.length; kk++) {
                                        if ( showCK ) { // use math3Str
                                            cfound = compareMath(aoptopt[kk]?.math3Str,smObj[_aopt.oupansid]);
                                        } else { // use data
                                            //console.log("here compare", aoptopt[kk]?.data,sqObj[_aopt.oupansid]);
                                            cfound = compareStr(aoptopt[kk]?.data,sqObj[_aopt.oupansid],caseSensitive);
                                        };
                                        //cfound = await compareFunc(aoptopt[kk].data,sqObj[aopt.oupansid],caseSensitive);
                                        if (cfound) { correctIndex = kk; break;};
                                    };

                                    if (cfound) { // answer matched, correct
                                        corrList.push(id);
                                        if (perBK) stgotTScore = stgotTScore + _aopt.score;
                                    } else {
                                        wrongList.push(id);
                                        if (!stWrong.includes(id)) stWrong.push(id);
                                    };
                                };                                
                            };
                        }
                    } else {  // dropdown, auto mark
                        stKey.forEach((id, jj)=>{ // for every student
                            const stWork= toObj(works[id]);
                            const thMark= toObj(marks[id]);
                            const sqObj = toObj(toAry(stWork?.resp)[idx]?.qresp); // is obj
                            const allMarked = thMark?.MState === _MST_MARKED;    
                            const countToo = autoMark?1:allMarked; // manual mark, count if all marked
                            if (countToo && stWork.WState === _WST_SUBMIT) {
                                totalAtt++;
                                if ((sqObj[_aopt.oupansid]>=0) &&
                                    (aoptopt[sqObj[_aopt.oupansid]].correct)) {
                                    if (perBK) stgotTScore = stgotTScore + _aopt.score;
                                    corrList.push(id);
                                } else {
                                    wrongList.push(id);
                                    if (!stWrong.includes(id)) stWrong.push(id);
                                };
                            };
                        });
                    };
                    ansStat.push({corrList, wrongList}); 
                    QtotalAtt = totalAtt;   
                    if (!perBK) stgotTScore = (totalAtt - stWrong.length) * totalScore;           
                };
            } else if ([__SYSQSubType_OEG, __SYSQSubType_OEE].includes(SQType)) {
                // with correctness or not
                // text, drawing, upload file, upload img, url
                // mCorrect, mMark
                let totalAtt = 0;
                const respTypes = []; 
                if (fullQ?.respText) respTypes.push(__RESP_TXT);
                if (fullQ?.respFile) respTypes.push(__RESP_FILE);
                if (fullQ?.respImage) respTypes.push(__RESP_IMAGE);
                if (fullQ?.respDrawing) respTypes.push(__RESP_DRAW);
                if (fullQ?.respURL) respTypes.push(__RESP_URL);
                respTypes.forEach((rType)=>{
                    const corrList = [];
                    const wrongList = [];
                    const stList = [];
                    if (hasCorrectness) { // has correct wrong list
                        stKey.forEach((id, jj)=>{ // for every student
                            const stWork= toObj(works[id]);
                            const stResp = toObj(toAry(stWork?.resp)[idx]); // is obj
                            const thMark= toObj(marks[id]);
                            const allMarked = thMark?.MState === _MST_MARKED;
                            const thResp = toObj(toAry(thMark?.resp)[idx]); // is obj
                            
                            //if ((thResp?.allMarked) && stWork.WState === _WST_SUBMIT
                            if (allMarked && stWork.WState === _WST_SUBMIT 
                                && ((stResp?.respType) === rType)) {
                                totalAtt++;
                                if (thResp?.mCorrect) {
                                    corrList.push(id);
                                } else {
                                    wrongList.push(id);
                                    if (!stWrong.includes(id)) stWrong.push(id);
                                };
                                // open-end, correct or wrong still can get mMark score
                                stgotTScore = stgotTScore + toInt(thResp?.mMark);
                            };
                        }); 
                        ansStat.push({respType:rType, corrList, wrongList}); 
                    } else { // has choose the resp type list only
                        stKey.forEach((id, jj)=>{ // for every student
                            const stWork= toObj(works[id]);
                            const stResp = toObj(toAry(stWork?.resp)[idx]); // is obj
                            const thMark= toObj(marks[id]);
                            const allMarked = thMark?.MState === _MST_MARKED;
                            const tqResp = toObj(toAry(thMark?.resp)[idx]); // is obj
                            //if ((tqResp?.allMarked) && stWork.WState === _WST_SUBMIT
                            if (allMarked && stWork.WState === _WST_SUBMIT
                                && ((stResp?.respType) === rType)) {
                                totalAtt++;
                                stgotTScore = stgotTScore + toInt(tqResp?.mMark);
                                stList.push(id);
                            };
                        });
                        ansStat.push({respType:rType, stList}); 
                    };
                });
                QtotalAtt = totalAtt; // sum all respType attempt
            } else if (SQType === __SYSQSubType_OED) {
                let totalAtt = 0;
                const corrList = [];
                const wrongList = [];
                const stList = [];                
                if (hasCorrectness) { // has correct wrong list
                    stKey.forEach((id, jj)=>{ // for every student
                        const stWork= toObj(works[id]);
                        const stResp = toObj(toAry(stWork?.resp)[idx]); // is obj
                        const thMark= toObj(marks[id]);
                        const allMarked = thMark?.MState === _MST_MARKED;
                        const thResp = toObj(toAry(thMark?.resp)[idx]); // is obj                       

                        if (allMarked && stWork.WState === _WST_SUBMIT ) {
                            totalAtt++;
                            if (thResp?.mCorrect) {
                                corrList.push(id);
                            } else {
                                wrongList.push(id);
                                if (!stWrong.includes(id)) stWrong.push(id);
                            };
                            // open-end, correct or wrong still can get mMark score
                            stgotTScore = stgotTScore + toInt(thResp?.mMark);
                        };
                    }); 
                    ansStat.push({ corrList, wrongList}); 
                } else { 
                    stKey.forEach((id, jj)=>{ // for every student
                        const stWork= toObj(works[id]);
                        const stResp = toObj(toAry(stWork?.resp)[idx]); // is obj
                        const thMark= toObj(marks[id]);
                        const allMarked = thMark?.MState === _MST_MARKED;
                        const tqResp = toObj(toAry(thMark?.resp)[idx]); // is obj
                        //if ((tqResp?.allMarked) && stWork.WState === _WST_SUBMIT
                        if (allMarked && stWork.WState === _WST_SUBMIT) {
                            totalAtt++;
                            stgotTScore = stgotTScore + toInt(tqResp?.mMark);
                            stList.push(id);
                        };
                    });
                    ansStat.push({stList}); 
                };
                QtotalAtt = totalAtt; // sum all respType attempt                                
            } else if (SQType === __SYSQSubType_POL) { // no mark
                // just count options choose
                qArr.forEach((ans, ii)=>{ // for every answer
                    let totalAtt = 0;
                    const stList = [];
                    stKey.forEach((id, jj)=>{ // for every student
                        const stWork= toObj(works[id]);
                        const sqArr = toAry(toAry(stWork?.resp)[idx]?.qresp); // is array
                        if (stWork.WState === _WST_SUBMIT) {
                            totalAtt++;
                            // student choose this ans
                            if (sqArr.findIndex(v=>{return v.includes(","+ii)}) >= 0) {
                                stList.push(id);
                            };
                        };
                    });
                    ansStat.push({stList});
                    QtotalAtt = totalAtt;
                });
            };
            QCorrCnt = QtotalAtt - stWrong.length;
            qtnStat.push({ansStat, QtotalAtt, stgotTScore, QCorrCnt});
        };
    };
    return qtnStat;
};

// all qtn statistic for one exerise
// ECtns need to be flattened and is array of fullQ
// sample function: flattenECtns
// will directly update each TeacherResp by stQtnAllInfo
export const getAllQtnStatInfo = (ECtns, allTeacherResp, allStudentResp, showEn) => {
    //MStat { aMark, mMark }
    
    let totalScore = 0;
    let maxScore = 0;      
    let totalQtn = 0;  
    let [tstScore,  tmQtnScore, taQtnScore, tmAnsCnt, taAnsCnt,
        tmQtnCount, taQtnCount, tmCount,    taCount,  
        tmMarked,   taMarked,   tmScore,    taScore, tallAnswered, tallMarked,
        tautoMark, thasAnswered] 
       = [0,0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0, 0,0];
    for (let idx = 0; idx < ECtns.length; idx++) {
        const ECtn = ECtns[idx];          
        const Q = (ECtn && (ECtn.type === _ExCtType_Qtn))?(showEn?ECtn.QEn:ECtn.QCt):undefined;
        //const Q = (ECtn && (ECtn.type === _ExCtType_Qtn))?(showEn?ECtn.QEn:ECtn.QCt):undefined;
        const fullQ = Q?(ECtn?ECtn:undefined):undefined;
        const thResp = allTeacherResp[idx];
        
        if (fullQ) {
            
            const {stScore, mQtnScore, aQtnScore, mAnsCnt, aAnsCnt,
                   mQtnCount, aQtnCount, mCount,    aCount,  
                   mMarked,   aMarked,   mScore,    aScore, allAnswered, allMarked,
                   isOP, opAttempt, isAutoMark, hasAnswered} 
                   = stQtnAllInfo(idx,fullQ,allStudentResp[idx],thResp,showEn);

            tstScore += toInt(stScore);
            tmCount += toInt(mCount);
            taCount += toInt(aCount);
            tmScore += toInt(mScore);
            taScore += toInt(aScore);
            tmQtnScore += toInt(mQtnScore);
            taQtnScore += toInt(aQtnScore);
            tmAnsCnt += toInt(mAnsCnt);
            taAnsCnt += toInt(aAnsCnt);
            tmQtnCount += toInt(mQtnCount);
            taQtnCount += toInt(aQtnCount);
            tmMarked += toInt(mMarked);
            taMarked += toInt(aMarked);
            tallAnswered += toInt(allAnswered);
            thasAnswered += toInt(hasAnswered);
            tallMarked += toInt(allMarked);
            totalScore += toInt(aScore) + toInt(mScore);
            // polling 0 mark
            maxScore += ((toInt(mQtnScore) + toInt(aQtnScore)) || 0);     
            if (isAutoMark) tautoMark++;
            totalQtn++;     
        };
    };

    // allMarked = sum of allMarked for each qtn
    const MStat = { stScore: tstScore, mQtnScore: tmQtnScore, aQtnScore: taQtnScore, mAnsCnt: tmAnsCnt, aAnsCnt: taAnsCnt,
        mQtnCount: tmQtnCount, aQtnCount: taQtnCount, mCount: tmCount, aCount: taCount,  
        mMarked: tmMarked, aMarked: taMarked, mScore: tmScore, aScore: taScore,
        allAnswered: tallAnswered, allMarked: tallMarked, totalQtn: totalQtn,
        totalAutoMQ: tautoMark, hasAnswered: thasAnswered};
    const ms = markState(MStat);

    // use to set
    // mark.MStat = MStat
    // mark.MState = ms
    return {MStat, ms};
};


//--------------------------------------------------------------------------------------------------------
// for preview use only, question level score
export const calculateScore = (Q, fullQ, preview, studentResp, teacherResp, collectScore, setGetScore) => {
    // data: Q, SQType, totalScore, scoreMethod, caseSensitive, autoMark
    if (!Q || !fullQ) return; // non-question
    const SQType = fullQ?.SQType;
    const totalScore = toInt(fullQ?.score);
    const scoreMethod = fullQ?.scoreMethod || FIBC.__FIBS_PBK;
    const caseSensitive = fullQ?.caseSensitive || 0;
    const autoMark = fullQ?.autoMark || 0;

    let result = 0;
    const qArr = toAry(Q.qAnswers);
    const tArr = toAry(Q.tAnswers);
    
    switch (SQType) {
        case __SYSQSubType_MCS: // auto mark
            //studentResp.qresp:['1,2','2,3'...] 'shuffle index, original index'
            const sqArr = toAry(studentResp?.qresp);
            // if find any correct ans student not choose, then 0 mark
            // or student choose more than correct
            result = (qArr.findIndex((ans,ii)=>{
                return (ans.correct && (sqArr.findIndex(v=>{return v.includes(","+ii)})<0)
                )})>=0 || sqArr.findIndex(v=>{
                    const index = v.split(',');
                    return !qArr[index[1]].correct;})>=0)
                ?0:totalScore;
            break;
        case __SYSQSubType_MCT: // auto mark
            const stArr = toAry(studentResp?.qresp);
            // if find any correct ans student not choose, then 0 mark
            // or student choose more than correct, row 0 not count
            
            result = (
                (tArr.findIndex((row,ii)=>{
                return (ii>0 && row.findIndex((col,kk)=>{
                    return (col.correct && (stArr.findIndex(v=>{return v===(ii+','+kk)})<0))
                }) >=0)})>0) ||
                (stArr.findIndex(v=>{                       
                    const index = v.split(',');
                    
                    return !tArr[index[0]][index[1]].correct;
                })>=0)
                )?0:totalScore;
            break;
        case __SYSQSubType_FIB: // auto or manual mark
        case __SYSQSubType_LBT: // auto mark
        case __SYSQSubType_LBD: // auto mark  
            
            //studentResp.qresp: {oupansid:value}
            const sqObj = toObj(studentResp?.qresp);
            const perBK = (scoreMethod === FIBC.__FIBS_PBK);
            // qAnswers[].qvalue[]
            // qAnswers[].qtype = txt or dropdown
            // qAnswers[].atype = txt, num, equation or adv
            if (perBK) {//per blank
                
                let total = 0;
                //qArr.forEach(async (aopt)=>{
                for (let jj=0;jj<qArr.length;jj++) {
                    const aopt = qArr[jj];
                    const qType = aopt?.qtype || FIBC.__FIBT_TXT;
                    const ansType = aopt?.atype || FIBC.__FIBA_TXT;
                    const showCK = (ansType === FIBC.__FIBA_EQU);
                    //const compareFunc = (showCK?compareMath:compareStr);
                    let correctIndex = -1;
                    const aoptopt = aopt.qvalue;
                    if (qType === FIBC.__FIBT_TXT) { // text, auto or manual mark
                        for (let ii=0;ii<aoptopt.length;ii++) {
                            
                            let cfound = false;
                            if ( showCK ) { // use math3Str
                                cfound = compareMath2Str(aoptopt[ii].data,sqObj[aopt.oupansid]);
                            } else { // use data
                                cfound = compareStr(aoptopt[ii].data,sqObj[aopt.oupansid],caseSensitive);
                            };     

                            //const found = compareFunc(aoptopt[ii].data,sqObj[aopt.oupansid],caseSensitive,preview);
                            
                            if (cfound) {correctIndex = ii; break;}
                        };
                    } else { // dropdown, auto mark
                        
                        if ((sqObj[aopt.oupansid]>=0) && aoptopt[sqObj[aopt.oupansid]].correct) {
                            
                            correctIndex = sqObj[aopt.oupansid];
                        };
                        
                    };
                    
                    if (correctIndex >=0) total = total + toInt(aopt.score);
                };
                //});
                result = total;
            } else { //per question
                
                let found = false;
                for (let jj=0; jj<qArr.length;jj++) {
                    const aopt = qArr[jj];
                //(qArr.findIndex((aopt)=>{
                    
                    const qType = aopt?.qtype || FIBC.__FIBT_TXT;
                    const ansType = aopt.atype?aopt.atype:FIBC.__FIBA_TXT;
                    //const showCK = (ansType === FIBC.__FIBA_ADV || ansType === FIBC.__FIBA_EQU);
                    const showCK = (ansType === FIBC.__FIBA_EQU);
                    //const compareFunc = (showCK?compareMath:compareStr);
                    if (qType === FIBC.__FIBT_TXT) { // text, auto or manual mark
                        let correct = false;
                        for (let ii=0;ii<aopt.qvalue.length;ii++) {
                            if ( showCK ) { // use math3Str
                                correct = compareMath2Str(aopt.qvalue[ii].data,sqObj[aopt.oupansid]);
                            } else { // use data
                                correct = compareStr(aopt.qvalue[ii].data,sqObj[aopt.oupansid],caseSensitive);
                            };                            
                            //correct = await compareFunc(aopt.qvalue[ii].data,sqObj[aopt.oupansid],caseSensitive);
                            
                            if (correct) break;
                        };
                        if (!correct) found = true;
                    } else { // dropdown, auto mark
                        if ((sqObj[aopt.oupansid]===undefined) || (sqObj[aopt.oupansid]>=0 && !aopt.qvalue[sqObj[aopt.oupansid]].correct))
                            found = true;
                    };
                    if (found) break;
                };
                if (!found) result = totalScore;
            };                
            break;
        case __SYSQSubType_OEG: // manual mark
            // for both OEG, OEE
            // correctness = true, has correct flag by teacher, manual mark by teacher
            // correctness = false, no correct flag, manual mark by teacher            
            if (preview) result = totalScore;
            break;
        case __SYSQSubType_OEE: // auto or manual mark
            if (preview) result = totalScore;
            break;
        case __SYSQSubType_OED: // manual mark
            if (preview) result = totalScore;
            break;            
        case __SYSQSubType_POL: // no mark
            result = 0;
        default:
    };
    //return result;
    
    collectScore && collectScore(result);
    setGetScore && setGetScore(result);
    return result;
};

// one student, one qtn, ans status, no score calculation, just answer count
export const oneStAnsQtnStatus = (fullQ, _studentResp, showEn) => {
    if (!fullQ) return; // non-question
    const SQType = fullQ?.SQType;
    const Q = showEn?(fullQ?.QEn):(fullQ?.QCt);
    if (!Q) return;
    const autoMark = fullQ?.autoMark || 0;
    const studentResp = toObj(_studentResp);

    let [mAnsCnt, aAnsCnt, mQtnCount, aQtnCount,
         allAnswered, isOP, opAttempt, hasAnswered]
        = [0,0,0,0, 0,0,0,0];
    const qArr = toAry(Q.qAnswers);
    const sqObj = toObj(studentResp?.qresp);

    if ([__SYSQSubType_MCS, __SYSQSubType_MCT].includes(SQType)) { 
        if (studentResp.hasOwnProperty('qresp')) aAnsCnt = 1;
        aQtnCount = 1;
    } else if ([__SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_LBD].includes(SQType)) {
        for (let jj=0;jj<qArr.length;jj++) {
            const aopt = qArr[jj];
            const qType = aopt?.qtype || FIBC.__FIBT_TXT;
            if (qType === FIBC.__FIBT_TXT) { // text, auto or manual mark
                if (!autoMark) { // check teacher resp correct
                    mQtnCount++;
                    if (sqObj[aopt.oupansid]) mAnsCnt++; // answered
                } else {
                    aQtnCount++;
                    if (sqObj[aopt.oupansid]) aAnsCnt++; // answered
                };
            } else { // dropdown, auto mark, can override by question automark flag
                if (autoMark) { aQtnCount++; } else { mQtnCount++; }; 
                if (sqObj[aopt.oupansid]>=0) {
                    if (autoMark) { aAnsCnt++; } else { mAnsCnt++; };
                };
            };
        };
    } else if ([__SYSQSubType_OEG, __SYSQSubType_OEE, __SYSQSubType_OED].includes(SQType)) {
        if (studentResp.hasOwnProperty('qresp')) opAttempt = 1;
        [mAnsCnt, mQtnCount, isOP]
         = [opAttempt, 1, 1];
    } else if (SQType === __SYSQSubType_POL) { // no mark, polling = (isAutoMark = 1 and isOP = 1)
        aQtnCount = 1;
        if (studentResp.hasOwnProperty('qresp')) { aAnsCnt = 1; }
    };
    allAnswered = ((aQtnCount + mQtnCount) === (aAnsCnt + mAnsCnt))?1:0;
    hasAnswered = ((aAnsCnt > 0) || (mAnsCnt > 0))?1:0; // answer any blank inside a question

    return { mAnsCnt, aAnsCnt, mQtnCount, aQtnCount,
        allAnswered, isOP, opAttempt, hasAnswered };
};

export const rmFullQCorrAns = (fullQ) => {
    const _rmQCorrAns = ( QType, Q ) => {
       if (Q) {
          const qas = Q?.qAnswers;
          const tas = Q?.tAnswers;
          if (isAry(qas) && (QType !== __SYSQSubType_MCT)) {
             if (QType === __SYSQSubType_LBT) {
                qas.forEach((qa) => { qa.qvalue = [{data:"",math3Str:["","",""]}]; });
             } else if (QType === __SYSQSubType_LBD) {
                qas.forEach((qa) => {
                   const qvs = qa?.qvalue;
                   if (isAry(qvs)) { qvs.forEach((qv) => { qv.correct = 0; }); };
                });
             } else if (QType === __SYSQSubType_FIB) {
                qas.forEach((qa) => {
                   if ((qa?.qtype) === __FIBT_DRD) {
                      const qvs = qa?.qvalue;
                      if (isAry(qvs)) { qvs.forEach((qv) => { qv.correct = 0; }); };                  
                   } else {
                      qa.qvalue = [{data:"",math3Str:["","",""]}];
                   };
                });
             } else if (QType === __SYSQSubType_MCS) {
                qas.forEach((qa) => { qa.correct = 0; });
             }
          };
 
          if (isAry(tas) && (QType === __SYSQSubType_MCT)) {
             tas.forEach((row) => { 
                if (isAry(row)) {
                   row.forEach((col) => { col.correct = 0; });
                };
             });
          };
       };
    };
 
    const fQ = deepCopy(fullQ);
    if (fQ) {
       _rmQCorrAns(fQ?.SQType, fQ?.QEn);
       _rmQCorrAns(fQ?.SQType, fQ?.QCt);
    };
    return fQ;
};
/************************************************************************************/
// per qtn, per st resp
// _studentResp = work.resp
// fullQ and resp must in correct sequence (follow ectns), checked by caller
export const remeAddWorkAnsCorr = (fullQ, _studentResp, _teacherResp, showEn) => {
    if (!fullQ || !_studentResp) return;
    
    const SQType = fullQ?.SQType;
    const Q = showEn?(fullQ?.QEn):(fullQ?.QCt);
    if (!Q) return;
    const caseSensitive = fullQ?.caseSensitive || 0;
    const autoMark = fullQ?.autoMark || 0; // reme must automark
    //if (!autoMark) { console.log("not autoMark, return"); return; };
    const studentResp = toObj(_studentResp);
    const teacherResp = toObj(_teacherResp);

    const qArr = toAry(Q.qAnswers);
    const tArr = toAry(Q.tAnswers); //for __SYSQSubType_MCT
    //const tqObj = toObj(teacherResp?.qresp);    
    //console.log("SQType",SQType);
    if (SQType === __SYSQSubType_MCS) { // add corr to resp for mc
        //studentResp.qresp:['1,2','2,3'...] 'shuffle index, original index'       
        const sqArr = toAry(studentResp?.qresp); // array
        studentResp.corr = [];
        sqArr.forEach((v, ii) => {
            const si = toInt(v.split(",")[1]);
            studentResp.corr[ii] = (qArr[si].correct) ? 1:0;
        });
    } else if (SQType === __SYSQSubType_MCT) { // add corr to resp for mc 
        const sqArr = toAry(studentResp?.qresp); // array
        studentResp.corr = [];
        sqArr.forEach((v, ii) => {
            const si = v.split(",");
            studentResp.corr[ii] = (tArr[toInt(si[0])][toInt(si[1])].correct) ? 1:0;
        });        
    } else if ([__SYSQSubType_FIB, __SYSQSubType_LBT, __SYSQSubType_LBD].includes(SQType)) { // add corr to resp
        const sqObj = toObj(studentResp?.qresp);
        const smObj = toObj(studentResp?.math3Str);   
        const tqObj = toObj(teacherResp?.qresp); 
        studentResp.corr = {};
        for (const [key, value] of objEntries(sqObj)) {
            studentResp.corr[key] = 0;
            const qq = qArr.find((q) => { return toStr(q?.oupansid) === toStr(key); });
            //console.log("qArr, qq", {qArr, qq});
            if (qq) { 
                const qType = qq?.qtype || FIBC.__FIBT_TXT;
                const ansType = qq?.atype || FIBC.__FIBA_TXT;
                const showCK = (ansType === FIBC.__FIBA_EQU);   
                const aoptopt = qq?.qvalue;
                //console.log("aoptopt",aoptopt);
                //console.log("qType, ansType, showCK", {qType, ansType, showCK});
                if (qType === FIBC.__FIBT_TXT) {
                    if (autoMark) {
                        let cfound = 0;
                        for (let ii=0; ii<aoptopt.length; ii++) {
                            if (showCK) {
                                cfound = compareMath(aoptopt[ii]?.math3Str,smObj[key]);
                            } else {
                                const checkValue1 = isAry(value) ? value.join("") : value;
                                const tmp = aoptopt[ii].data;
                                const checkValue2 = isAry(tmp) ? tmp.join("") : tmp;
                                //console.log({checkValue1,checkValue2});
                                cfound = compareStr(checkValue1,checkValue2,caseSensitive);
                            };
                            if (cfound) {
                                studentResp.corr[key] = 1;
                                break;
                            };                        
                        };
                    } else { 
                        if (tqObj[key]) studentResp.corr[key] = 1;
                    };
                } else { // dropdown
                    //console.log("aoptopt[value]"+value, aoptopt[value]);
                    studentResp.corr[key] = aoptopt[value].correct ? 1:0;
                };
            };
        };
    } else if ([__SYSQSubType_OED, __SYSQSubType_OEG, __SYSQSubType_OEE].includes(SQType)) {
        // manual mark only
        studentResp.corr = (teacherResp?.mCorrect)? 1:0;
    } else if (SQType === __SYSQSubType_POL) {
        // POL no correctness
    };
};

/*
            } else { // dropdown, auto mark
                aQtnCount++;
                aMarked++;
                if (perBK) aQtnScore = aQtnScore + aopt.score;                        
                if (sqObj[aopt.oupansid]>=0) {
                    aAnsCnt++;
                    if ( aoptopt[sqObj[aopt.oupansid]].correct) {
                        correctIndex = sqObj[aopt.oupansid];
                        if (perBK) aScore = aScore + toInt(aopt.score);
                        aCount++;
                    } else { wfound = 1 };
                };
            };

            } else { // dropdown, auto mark
                if (autoMark) {
                    aQtnCount++;
                    aMarked++;
                    if (perBK) aQtnScore = aQtnScore + aopt.score;
                } else {
                    mQtnCount++;
                    mMarked++;
                    if (perBK) mQtnScore = mQtnScore + aopt.score;
                };
                if (sqObj[aopt.oupansid]>=0) {
                    if ( autoMark ) { aAnsCnt++; } else { mAnsCnt++; }
                    if ( aoptopt[sqObj[aopt.oupansid]].correct) {
                        correctIndex = sqObj[aopt.oupansid];
                        if (autoMark) {
                            if (perBK) aScore = aScore + toInt(aopt.score);
                            aCount++;
                        } else {
                            if (perBK) mScore = mScore + toInt(aopt.score);
                            mCount++;
                        };
                    } else { wfound = 1 };
                };
            };            

                if (autoMark) { aQtnCount++; aMarked++; 
                } else { mQtnCount++; mMarked++; };               
                if (perBK) { 
                    if (autoMark) { aQtnScore = aQtnScore + aopt.score;
                    } else { mQtnScore = mQtnScore + aopt.score; }
                };
                if (sqObj[aopt.oupansid]>=0) {
                    if ( autoMark ) { aAnsCnt++; } else { mAnsCnt++; };
                    if ( aoptopt[sqObj[aopt.oupansid]].correct) {
                        correctIndex = sqObj[aopt.oupansid];
                        if (perBK) {
                            if (autoMark) {
                                aScore = aScore + toInt(aopt.score);
                            } else { mScore = mScore + toInt(aopt.score); };
                        };
                        if (autoMark) { aCount++; } else { mCount++; };
                    } else { wfound = 1; };
                };            
*/