import React from 'react';
import { _ATSysQTypes } from '../../consts/ATSysQType';
import { _ATMetaTypeByCode, _ATMetaTypes, _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, 
  _ATMetaType_GR, _ATMetaType_QT, _ATMetaType_SU, _ATMetaType_TB, _ATMetaTransByCode } from '../../consts/ATMetaTypes';
import { aTUIMust, autoId } from '../AppUtil';
import * as UI from '../../libs/libUI';
import { objEntries, objKeys, objVals, toObj, toStr } from '../../libs/libType';
import { useUILang } from '../utils/useUILang';
import CpMJX from '../../AppExPFUser/_components/CpMJX';

export const whyHrInvisable = <div style={{margin:'8px 0 12px 0', height:'1px', backgroundColor:'#000'}}></div>;

export const CpMetaTickList = props => {
  const {lock, autoTick, en, MSMusts, MSOpts, tickLbls, tickss, setterss} = props;
  const {EMSubjId, setEMSubjId} = props;
  const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();

  const CpMustQMTRows = (mstypename, code, EMSubjId, setEMSubjId) => <CpQTypeMSRows key={code} {...{ en, tickss, setterss, lock, autoTick, must:1, 
    mstypename, MSets: MSMusts[code], EMSubjId, setEMSubjId
      }} />;
  const CpOptQMTRows = (code, MSets) => <CpQTypeMSRows key={code} {...{ en, tickss, setterss, lock, autoTick, 
      mstypename:t2(_ATMetaTransByCode(code), en), must:0, MSets, EMSubjId, setEMSubjId 
    }} />;
  
return <>
    <div className='CpMetaTickList fxRow'>
      <div style={QEdMTCol1}><b>Metadata Type</b></div>
      <div style={QEdMTLv}><b>Level</b></div>
      {tickLbls.map((l, i) => <div key={i} style={QEdMTColT}><b>{l}</b></div>)}
      {setEMSubjId? <div style={QEdMTColT} ><b>Default<br/>Subject</b></div>: ''}
      <div className="fxGrow"><b>Metadata</b></div>
    </div>
    <div className='f12'>
    {whyHrInvisable}
    {CpMustQMTRows('Question Category [qt]', _ATMetaType_QT)}
    {CpMustQMTRows('Subject(s) [su]', _ATMetaType_SU, EMSubjId, setEMSubjId)}
    {CpMustQMTRows('Textbook Series [tb]', _ATMetaType_TB)}
    {CpMustQMTRows('Curriculum [cu]', _ATMetaType_CU)}
    {CpMustQMTRows('Grade(s) [gr]', _ATMetaType_GR)}
    {CpMustQMTRows('Copyright(s) [co]', _ATMetaType_CO)}
    {CpMustQMTRows('Diffculty(s) [di]', _ATMetaType_DI )}
    {objEntries(MSOpts).map(([mstype, msets]) => CpOptQMTRows(mstype, msets)) }
    </div>
  </>;
};

//Rencer MetaSets ====== ====== ====== ====== ====== ====== ====== ======
const CpQTypeMSRows = ({mstypename, must, MSets, en, tickss, setterss, lock, autoTick, EMSubjId, setEMSubjId}) => {
  return <div key={mstypename} className="CpQTypeMSRows fxRow" style={{whiteSpace: "nowrap", margin:'0 0 12px 0'}}>
    <div style={QEdMTCol1}><b>{mstypename}</b>{must?aTUIMust:''}</div>
    <div className="CpQTypeMSRows fxGrow">
      {MSets && MSets.map( (mset, i) => <CpQTMSetRows key={i} {...{mset, en, tickss, setterss, lock, autoTick, EMSubjId, setEMSubjId}} />)}
    </div>
  </div>;
}; 
const CpQTMSetRows = ({mset, en, tickss, setterss, lock, autoTick, EMSubjId, setEMSubjId}) => {
  return <>{Object.values(mset.rows).map(row => CpQTMSMRows(mset, row, en, tickss, setterss, lock, autoTick, EMSubjId, setEMSubjId))}</>;
};
const CpQTMSMRows = (mset, row, en, tickss, setterss, _lock, autoTick, EMSubjId, setEMSubjId) => {
  const lv = toObj(mset.heads[row.lv]);
  const id = mset.metaSetId+','+row.metaId;
  const isJ = (setEMSubjId && (EMSubjId === id));
  const lock = _lock || autoTick || isJ; 
  
  return <div key={id} className="CpQTMSMRows fxRow" style={{margin:'0 0 4px 0'}}>
    <div style={QEdMTLv}>{langName(en, lv.nameEn, lv.nameCt, row.lv)}</div>
    {tickss.map((ts, i) => {
      const ticked = isJ || autoTick || ts.includes(id);
      const setter = lock? ()=>{} :setterss[i](id);
      return <div id={id} key={i} style={QEdMTColT}>{UI.CheckBox0(ticked, setter, 'chkD'+i+id, lock)}</div>;
    })}
    {setEMSubjId? <div id={id} style={QEdMTColT}>{setEMSubjId? UI.radio0(id, setEMSubjId, EMSubjId, "r"+id, _lock):' '}</div>: ''}
    <div id={id} className="CpQTMSMRows fxGrow"><CpMJX dynamic={true} key={autoId()}>{(toStr(row.LVNum)+' '+mname(en, row.nameEn, row.nameCt) ).trim()} </CpMJX></div>
  </div>;
};
const langName = (en, nameE, nameC, fallback) => (en && nameE) || nameC || nameE || fallback;
const mname = (en, e, c) => toStr((en && e) || c || e);

const QEdMTCol1 = {width:'140px'};
const QEdMTLv = {width:'140px'};
const QEdMTColT = {width:'75px'};

// Data Process
const QMSTMusts = [ _ATMetaType_QT, _ATMetaType_SU, _ATMetaType_TB, _ATMetaType_CU, _ATMetaType_GR, _ATMetaType_CO, _ATMetaType_DI,];  //_ATMetaType_EX, //_ATMetaType_OT,

export const mSetSplitMust = allQMS => {
  const [MSMusts, MSOpts] = [{}, {}];
  allQMS && objEntries(allQMS).forEach( ([mstype, qms]) => {
    (QMSTMusts.includes(mstype)? MSMusts: MSOpts)[mstype] = qms;
  });
  return [MSMusts, MSOpts];
}; 


export const flaten2D = aa => [].concat(...aa);
export const flaten3D = aaa => flaten2D(flaten2D(aaa));
export const mRows2CanShows = mrows => flaten3D(objVals(mrows).map(msets => msets.map( mset => 
        objKeys(mset.rows).map(mid => mset.metaSetId+','+mid))));

export const mSets2Rows = MSets => {
  const MSsPerTypes = {};
  objEntries(MSets).forEach(([metaSetId, MSet]) => {
    const {MSType, tree2, metas} = MSet;
    const [rows, ends] = MSet2Rows(MSet);
    MSsPerTypes[MSType] = [...MSsPerTypes[MSType]||[], {...MSet, metaSetId, rows, ends}];
  });
  return MSsPerTypes;
};

const MSet2Rows = MSet => {
  const rows = {}, ends = [];
  const tree2rows = (branch, lv, pid) => {
    objEntries(branch).forEach(([metaId, subMetas]) => {
      const chids = Object.keys(subMetas);
      if(!chids.length) 
        ends.push(metaId);
      rows[metaId] = ({metaId, ...MSet.metas[metaId], pid, chids});
      tree2rows(subMetas, lv+1, metaId);
    });
  };
  tree2rows(MSet.tree2, 0, '');
  return [rows, ends];
};
