import React from 'react';
import { YearlyScore, SCHOOL_YEARS, SchoolYearKey, getColorForYear } from "../../../../type/ChartType";
import { MetadataProgressItemType } from "../../../../type/ChartType";
import { useTranslation } from 'react-i18next';

interface Props {
    titleIndex?: string;
    title: string;
    parentTitleIndex?: string;
    parentTitle?: string;
    scores: YearlyScore[];
    classAverages?: YearlyScore[];
    isStudent: boolean; // For selected class case
    studentInfo?: MetadataProgressItemType; // For selected class case
    selectedStudentInfo?: MetadataProgressItemType; // For selected student case
    classAverageVisible: boolean;
}

const AverageScoreProgressPopupBox: React.FC<Props> = ({
    titleIndex,
    title,
    parentTitleIndex,
    parentTitle,
    scores,
    classAverages,
    isStudent,
    studentInfo,
    selectedStudentInfo,
    classAverageVisible
}) => {
    const { t } = useTranslation(["schema"]);

    const renderTitle = () => {
        let index: string | undefined;
        let titleText: string;

        if (selectedStudentInfo) {
            index = parentTitleIndex || titleIndex;
            titleText = parentTitle || title;
        } else {
            index = isStudent ? parentTitleIndex : titleIndex;
            titleText = isStudent ? (parentTitle || title) : title;
        }

        return (
            <>
                {index && <span className='title-index'><span className='index'>{index}</span></span>}
                <span>{titleText}</span>
            </>
        );
    };

    const renderStudentInfo = () => {
        if (selectedStudentInfo) {
            return <h4>{selectedStudentInfo.no} {selectedStudentInfo.title}</h4>;
        }
        if (isStudent && studentInfo) {
            return <h4>{studentInfo.no} {studentInfo.title}</h4>;
        }
        return <h4>{t('report-overall')}</h4>;
    };

    return (
        <div className="progress-chart-popup semi-bold">
            <div className='title-container'>
                <h3 className='title'>{renderTitle()}</h3>
            </div>
            {renderStudentInfo()}
            <hr/>
            <p>
                {t('report-performance-average-percentage')}
                {(selectedStudentInfo || isStudent) && (
                    <p className="class-average-label"> ({t('report-performance-class-average-percentage')})</p>
                )}
            </p>
            <ul>
                {Object.entries(SCHOOL_YEARS).map(([key, year]) => {
                    const score = scores.find(s => s.year === Number(key) as SchoolYearKey);
                    const classAverage = classAverages?.find(ca => ca.year === Number(key) as SchoolYearKey);
                    if (!score) return null;
                    return (
                        <li key={key}>
                            <span
                                className="color-box"
                                style={{ backgroundColor: getColorForYear(Number(key) as SchoolYearKey) }}
                            ></span>
                            <span className="year-label">
                                {year}
                            </span>
                            <span className="score">
                                {score.scorePercentage.toFixed(1)}%
                            </span>
                            {classAverage && (
                                <span className="class-average">
                                    ({classAverage.scorePercentage.toFixed(1)}%)
                                </span>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default AverageScoreProgressPopupBox;