import React, { useState, useEffect } from 'react';
//import {langClass} from '../saga/lang.saga';
import {stopEvent} from '../../libs/libUI';

const defaultColor='#FFA800'; // right color
const disabledColor = '#A9A9A9';
const defaultLeftColor='#747474';
const delta = 3;

const Switch0 = (props) => {
    const [selected, setSelected] = useState(null);
    const [ballSize, setBallSize] = useState(0);
    const [myStyle, setMyStyle] = useState({});
    const [offSet, setOffSet] = useState(0);
    const [borderRadius, setBorderRadius] = useState(0);
    const [width, setWidth] = useState(0);
    const [fontSize, setFontSize] = useState(0);
    const [inBallSize, setInBallSize] = useState(0);

    useEffect(()=>{
        const {myStyle, selected, leftValue} = props;
        const _width = props.width;
        const _fontSize = props.fontSize;
        const ballSize = _width * 0.5;

        setSelected(selected ? selected : leftValue);
        setBallSize(ballSize + 'px');
        setMyStyle({...(myStyle ? myStyle: {})});
        setOffSet((_width - ballSize)+'px');
        setBorderRadius((_width / 2)+'px');
        setWidth(_width + 'px');
        setFontSize(_fontSize + 'px');
        setInBallSize((ballSize - delta*2) + 'px');
    },[]);
    useEffect(()=>{
            setSelected(props.selected ? props.selected: props.leftValue);
        },[props.selected, props.leftValue, props.rightValue]
    );

    const handleClick = (e) => {
        if (!props.disabled) {
            stopEvent(e);
            const {setter, rightValue, leftValue} = props;
            let newSelected = (selected === leftValue ? rightValue:leftValue);
            
            setSelected(newSelected);
            if (typeof setter === 'function') {
                setter(newSelected);
            };
        }
    }

    const {id, leftText, rightText, color, rightValue, leftValue, leftColor} = props;
    
    return(
        <div id={id} className="switch0Container">
            {leftText && <div  id="_swl" style={{fontSize: fontSize, padding:'5px'}}>{leftText}</div>}
            <div id="_sw1" onClick={handleClick}
                className="switch0Body"
                style={{
                    backgroundColor:"white",
                    width:width, height:ballSize,
                    borderRadius: borderRadius,
                    border:"1px solid "+ (props.disabled ? disabledColor :(selected === leftValue ?(leftColor?leftColor:defaultLeftColor):(color?color:defaultColor))),
                    ...(myStyle ? myStyle: {})
                }}>
                <div id="_sw2" className="switch0Bar" style={{
                    left:(selected === rightValue)?offSet:0,
                    backgroundColor:"rgba(10,10,10,0)",
                    borderRadius: borderRadius,
                    width:ballSize, height:ballSize,
                    }}>
                    <div id="_sw3" className="switch0Ball" style={{
                        top:(delta-1) + 'px', left: (delta-1) + 'px',
                        borderRadius: borderRadius,
                        width:inBallSize, height:inBallSize,
                        backgroundColor:(props.disabled ? disabledColor :(selected === leftValue ?(leftColor?leftColor:defaultLeftColor):(color?color:defaultColor))),
                        }}></div>
                </div>
            </div>
            {rightText && <div id="_swr" style={{fontSize: fontSize, padding:'5px'}}>{rightText}</div>}
        </div>
    );
};
export default Switch0;
