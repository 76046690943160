import { t } from 'i18next';
import React, {useState} from 'react';
//import { useTranslation } from 'react-i18next';
import { useUILang } from '../../AppExPf/utils/useUILang';

import {toInt, toStr} from '../../libs/libType';
import {nowStr} from '../../libs/libTime';
import * as UI from '../../libs/libUI';

import { ReduxBind } from '../../saga/ReduxState';

const CpCookieBanner = ReduxBind((props) => {
    //const {t} = useTranslation(['schema']);
    const [ t, showEn ] = useUILang();
    const [state, setState] = useState(0);
    if(!state){
        setState(s => ( { 
            AcceptCookieEXPF: toInt(window.localStorage.getItem('AcceptCookieEXPF')),
            MaintenanceId: toInt(window.localStorage.getItem('MaintenanceId')), 
        } ) );
    } 
    const vis = props._saga?.ReduxState?.bannerVis;
    if(!vis) return false;

    //const ct = (props.lang() === 'Ct');
    const ct = !showEn;

    const maintenanceId = props.maintenanceId();
    const maintenanceMsg = (props.maintenanceMsg()||[])[ct?1:0];
    
    const acceptMaintenanceId = toInt(state&&state.MaintenanceId)
    const acceptMaintenance = (acceptMaintenanceId >= toInt(maintenanceId));
    const cookieVer = '230629';//nowStr('YYYYMMDD');
    const etaCookie = toStr(state?.AcceptCookieEXPF);
    const AcceptCookieEXPF = (cookieVer === etaCookie);
    

    
    if(AcceptCookieEXPF && acceptMaintenance){
        return '';
    }
    
    const _click = (e, key, value) => {
        //lj({cookieBanner:''+document.cookie});
        UI.stopEvent(e);
        setState(s => ( {...s, [key]:value} ) );
        //const exp10y = new Date(new Date().getTime() + (3650 * 24 * 3600 * 1000));
        //cookies.save('AcceptCookieEXPF', value, {expires: exp10y});
        window.localStorage.setItem(key, value);
    };
    const clickMaintenance = (e) => { _click(e, 'MaintenanceId', maintenanceId); };
    const clickCookie = (e) => { _click(e, 'AcceptCookieEXPF', cookieVer); };
    
    const txt = ct?
    '我們使用Cookies提升您使用我們網站的經驗，表示同意我們使用Cookies，請點選”繼續”或繼續使用我們的網站，您隨時可變更您Cookies的設定。':
    'We use cookies to enhance your experience on our website. By clicking "continue" or continue using our website, you accept the use of cookies. You can change the setting of cookies at any time.';
    const bTxt = ct?'繼續':'Continue';
    const bTxt2 = ct?'了解更多':'Find More';
    const lnks = (props.settings() || {}).ckUrls || [];
    const lnk = toStr(lnks[ct?1:0]);
    //'https://global.oup.com/cookiepolicy/?siteid=ae_hk_OUPC&lang=zh_TW&cc=hk':


    

    if(0){
        return <div style={{zIndex:10000, position:'fixed', bottom:0, left:0, right:0, }}>
        {acceptMaintenance?'':<div className='cookieBar' style={{backgroundColor:'#D0B000'}}>
            <div id='maintenanceTxt' className='cookieTxt'>{maintenanceMsg}</div>
            <div className='cookieLinks'>
                <div className='cookieLink' onClick={clickMaintenance}>{bTxt}</div>
            </div>
        </div>}
        {AcceptCookieEXPF?'':<div className='cookieBar' style={{backgroundColor:'#787878'}}>
            <div id='cookieTxt' className='cookieTxt'>{txt}</div>
            <div className='cookieLinks'>
                <div className='cookieLink' onClick={clickCookie}>{bTxt}</div>
                <a className='cookieLink' target="_blank" rel='noreferrer noopener' href={lnk} >{bTxt2}</a>
            </div>
        </div>}
        </div>
    }

    //return <div className="w100" style={{position:'fixed',left:0,bottom:0}} onClick={UI.stopEvent}>
    //<div className="w100 p8 cookies-agreement" style={{background:'#D0B000'}} >
    return <div className="w100" style={{position:'fixed',left:0,bottom:0,zIndex:1111111111}}>
        {acceptMaintenance? '':
        <div className="w100 p8 cookies-agreement" style={{background:'#f27f0c'}} >
            <div id='maintenanceTxt' className='cookieTxt'>{maintenanceMsg}</div>
            <div className={"position-relative d-flex flex-row justify-content-end gap-3"}>
                <u role={"button"} className={""} onClick={clickMaintenance}>{t("continue")}</u>
                <a href={t("find-more-link")} target={"_blank"}>{t("find-more")}</a>
            </div>
        </div>}
        {AcceptCookieEXPF? '':
        <div className="w100 p8 cookies-agreement" >
            <span>{t('cookies-agreement')}</span>
            <div className={"position-relative d-flex flex-row justify-content-end gap-3"}>
                <u role={"button"} className={""} onClick={clickCookie}>{t("continue")}</u>
                <a href={t("find-more-link")} target={"_blank"}>{t("find-more")}</a>
            </div>
        </div>}
    </div>
});
export default CpCookieBanner;