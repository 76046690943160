import React, {useMemo} from "react";
import { useUILang } from "../../../AppExPf/utils/useUILang";
import { toInt, toObj } from "../../../libs/libType";


export const exerHasMark = ex => {
    const e = toObj(ex);
    const [ aQCnt, mQCnt ] = [ toInt(e.aQCnt), toInt(e.mQCnt) ];
    const [ aScore, mScore ] = [ toInt(e.aScore), toInt(e.mScore) ];
    const hasA = (aQCnt || aScore);
    const hasM = (mQCnt || mScore);
    return {hasA, hasM, aQCnt, mQCnt, aScore, mScore};
};

export const TagAsmMarkingTag = (props) => {
    const [t, lang] = useUILang();
    const {asm} = props;
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(asm?.Exer);
    return [
        hasA? CpAutoMarkTag(): '',
        hasM? CpManMarkTag(): '',
    ];
};

export const CpAutoMarkTag = () => <MarkTag_ key='mat' status='auto-marked' style='border-tag-success text-tag-success' title='assignment-tag-auto-marked' />;  // base on poc MarkingTag.tsx
export const CpManMarkTag = () =>  <MarkTag_ key='mmt' status='manual-marked' style='border-tag-primary text-tag-primary' title='assignment-tag-manual-marked' />;  // base on poc MarkingTag.tsx
const MarkTag_ = (props) => { // base on poc MarkingTag.tsx
    const [t, lang] = useUILang();
    const {status} = props;
    const item = useMemo(() => {
        return (status === "auto-marked")? {style: `bg-white border-tag-success text-tag-success`, title: t("assignment-tag-auto-marked")}
            :(status === "manual-marked")? {style: `bg-white border-tag-primary text-tag-primary`, title: t("assignment-tag-manual-marked")}
            :{}
    }, [lang, status]);
    return <small
        className={`semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light ${item?.style}`}>{item?.title}
    </small>
};
/* old one
export const drawAsmMarkTags = (asm) => {
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(asm?.Exer);
    return [
        //'drawAsmMarkTags '+(hasA?'hasA, ':'no a, ') + (hasM? 'hasM, ': 'no M') + JSON.stringify({aQCnt, mQCnt, aScore, mScore}),
        hasA? CpAutoMarkTag(): '',
        hasM? CpManMarkTag(): '',
    ];
};

export const TagMarkingTag = (props) => { // base on poc MarkingTag.tsx
    const [t] = useUILang();
    const {style, title} = props;
    return <small className={`semi-bold px-2 d-flex text-nowrap justify-content-center align-items-center gap-2 fs-9 border border-2 px-2 rounded-light bg-white ${style}`}>
        {t(title)}
    </small>
};
*/