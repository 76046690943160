import React, { useEffect, useState, useRef, useMemo } from 'react';

import { nestSolid, objEntries, objKeys, objMapObj, toAry, toInt, toObj, toStr, toUniAry } from '../../libs/libType';
import { apiCallLoad_, asyncApiCallLoad_, asyncApiCall_ } from '../../libs/awsFuncs';
import { useStateGST } from '../../saga/globalState.saga';
import { toUniIdAry } from '../../consts/ATValidate';

const obj2d2ObjAry = o2d => objMapObj(o2d, ([k, obj]) => [k, objKeys(obj)]);
const join2d = (x2d, y2d) => {
    const [a2d, b2d] = [toObj(x2d),  toObj(y2d)];
    const ret =  {...a2d, ...objMapObj(b2d, ([p, is]) => [p, {...toObj(a2d[p]), ...toObj(is)}] ) };
    
    return {...a2d, ...objMapObj(b2d, ([p, is]) => [p, {...toObj(a2d[p]), ...toObj(is)}] ) };
};
const joinObjAry = (x2d, y2d) => {
    const [a2d, b2d] = [toObj(x2d),  toObj(y2d)];
    const ret = {...a2d, ...objMapObj(b2d, ([p, is]) => [p, toUniAry([...toAry(a2d[p]), ...toAry(is)]) ] ) };
    
    return {...a2d, ...objMapObj(b2d, ([p, is]) => [p, toUniAry([...toAry(a2d[p]), ...toAry(is)]) ] ) };
};
const _missCache = {id:'_missCache'};
const q2d2Miss = q2d => objMapObj(q2d, ([poolId, qids]) => [poolId, Object.fromEntries(qids.map(i => [i, _missCache])) ]  );

export const useCachesEffect = (props, dispatch) => {
    const [cache2d, setCache2d] = useStateGST(props, 'Cache2d', {});
    const [que2d, setQue2d] = useStateGST(props, 'CacheQue2d', {});
    const [load, setLoad] = useState(0);

    useEffect(() => {
        const onLoad = (res, err, q2d) => {
            const add2d = toObj(res?.cache2d);
            /*if( nestSolid(add2d) )*/setCache2d(c2d => {
                const miss2d = q2d2Miss(q2d); 
                return join2d(miss2d, join2d(c2d, add2d));
            });    
            const load2d = joinObjAry(q2d, obj2d2ObjAry(join2d(cache2d, add2d)));
            
            setQue2d(q2d => { 
                const ret = Object.fromEntries(
                objEntries(q2d).map( ([ pid, misss ]) => {
                    const loads = toAry(load2d[pid]); 
                    const realmiss = toAry(misss).filter(m => !loads.includes(m));
                    return realmiss.length? [pid, toUniAry(realmiss)]: 0;
                }).filter(kv => kv));
                
                return ret;  
            });
           setLoad(0);
        };
        
        if((!load) && nestSolid(que2d)){
            setLoad(1); 
            apiCallLoad_(dispatch, '/getCaches', (res, err) => onLoad(res, err, que2d), { que2d });
        };
    }, [JSON.stringify(que2d), load]);
};

//export const useMemoCache = (getCacheRet, cache2dInAry) => getCacheRet;
//export const useMemoCache = (getCacheRet, cache2dInAry) => useMemo(() => getCacheRet, cache2dInAry);

export const useCaches = (props) => {
/*
    const useGetCaches = (poolId, ids) => {
        console.error('useGetCaches() is not avaliable');
        const pool = toObj(cache2d?.[poolId]);
        const needs = toUniAry(ids);
        const hitEnts = needs.map(id => {const o = pool[id]; return o? [id, o]: 0;} ).filter(kv => kv);
        const hits = Object.fromEntries(hitEnts); 
        
        _queMiss(poolId, needs, hits);
        return Object.fromEntries(hitEnts.filter(([k,v]) => (v !== _missCache)));
    const _queMiss = (poolId, needs, hits) => {
        const ques = toAry(que2d?.[poolId]); 
        const miss = toUniAry(needs).filter(id => (!(hits[id] || ques.includes(id))));
        const missStr = miss.sort().join('|');
        useEffect(() => {
            if(miss.length) setQue2d(q2d => {
                const qs = objKeys(q2d?.[poolId]);
                const realMiss = miss.filter(m => !qs.includes(m));
                return realMiss.length? { ...q2d, [poolId]:[...qs, ...realMiss] }: q2d;
            });
        }, [missStr]);
    };
*/
    const [cache2d, setCache2d] = useStateGST(props, 'Cache2d', {});
    const [que2d, setQue2d] = useStateGST(props, 'CacheQue2d', {});

    const _que1Miss = (poolId, miss) => {
        useEffect(() => {
            if(miss) setQue2d(q2d => {
                const qs = objKeys(q2d?.[poolId]);
                const reallyMiss = miss && (!qs.includes(miss));
                return reallyMiss? { ...q2d, [poolId]:[...qs, miss] }: q2d;
            });
        }, [miss]);
    }
    const useGetCache = (poolId, _id) => {
        const id = toStr(_id);
        const pool = toObj(cache2d?.[poolId]);
        const val = pool[id];
        _que1Miss(poolId, val? '': id);
        //const ret = val; //val? {[id]:val}: {}; 
        return val; //useMemo(() => ret, [poolId, id, cache2d?.[poolId]]);
    };
    return [cache2d, useGetCache ];
};



