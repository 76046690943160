import React from "react";
import { 
    AssignmentFilterYearClassDropdown,
    CpAssignmentFilterClassDropdown,
    CpAssignmentFilterYearDropdown,
    CpAssignmentFilterSubjectDropdown, } from "../EPAssign/CpAssignmentListFilter";
import { ReduxBind } from "../../saga/ReduxState";
import { expfRoles } from "../../saga/auth.saga";

const CpDashboardFilter = ReduxBind(props => { // base on poc DashbordFilter
    const { opts, fields, setFields, setField, listView, setListView } = props;
    const {yearClassDDI, yearItems, year, classItems, classId, subjItems, AMSubjId, AStateItems, AState, ASortItems, ASort } = props;
    const [isThr, isStt, uRole ] = expfRoles(props);
    return <>
        {isStt? <AssignmentFilterYearClassDropdown {...{yearClassDDI, classId, cb:setField('classId') }} /> :''}
        {isThr? <CpAssignmentFilterYearDropdown {...{yearItems, year, cb:setField('year') }} /> :''}
        {isThr? <CpAssignmentFilterClassDropdown {...{classItems, classId, cb:setField('classId')}} /> :''}
        <CpAssignmentFilterSubjectDropdown {...{subjItems, AMSubjId, cb:setField('AMSubjId')}} />
    </>
});
export default CpDashboardFilter;
