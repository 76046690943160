import React, {useEffect, useRef, useState  } from 'react';

import * as UI from '../../libs/libUI';
import { isAry, toStr, toInt, tryParseJson, toObj,  } from '../../libs/libType';
import { apiCallLoad_, s3UploadPrivate, } from '../../libs/awsFuncs';
import { iconButton } from '../components/CpATIcoBtn';
import { ReduxBind } from '../../saga/ReduxState';
import CpTabHead from '../components/CpTabHead';
import { Array2ObjectFunc, arrayAddOrDelete } from '../../libs/libArray';
import { aTErrTxt1, preJS, aTUIMust, aTUITime } from '../AppUtil';
import { HttpPostMP, objToFormData } from '../../libs/libHttp';
import CpATSubjectTickList from '../components/CpATSubjectTickList';
import { extToMime, maxMediaSizeByExt, validToUpload, } from '../../consts/MimeTypes';
import CpATSubjectCombo from '../components/CpATSubjectCombo';
import { asyncLoadFile } from '../../libs/libFile';
import { useUILang } from '../utils/useUILang';
import { popAlert } from '../components/CpPopup';

const cssQFileInf = {display:'inline-block', textAlign:'left'};
const cssQFile = {margin:'4px', padding:'4px', borderRadius:'6px', border:'1px solid #ccc', display:'inline-block', fontSize:'11px'};
const cssQX = {display:'inline-block', margin:'0 0 0 6px', verticalAlign:'top'}
const cssQue = {padding:'4px', margin:'4px', border:'1px solid red', borderRadius:'4px'};

const TabMediaUp = ReduxBind(props => {
    const { dispatch, ATSubjects, mySjIds, tickSjs, setTickSj } = props; 
    const [ t ] = useUILang();
    const {_MaxMediaSize} = toObj(props.settings());

    const [ kws, setKws ] = useState('');
 
    const refFile = useRef();

    const [up2s, setUp2s ] = useState([]);
    const [ups, setUps ] = useState([]);
    const [upMsg, setUpMsg ] = useState([]);

    const [que, setQue] = useState([]);
    const clickDelQ = i => e => { UI.stopEvent(e); setQue(Q => ArrayRemoveAt(Q, i)) };

    const [errQue, setErrQue] = useState();

    const [upg, setUpg] = useState('');
    const [upg1, setUpg1] = useState('');
    
    const onUpProgress = (prog, total) => { 
        const [ip, it] = [toStr(prog), toStr(total)];
        setUpg1(ip === it?'':' [ ' + ip + ' / ' + it + ' ] '); 
    };

    const noUp = (!que?.length) || (!tickSjs?.length); 

    const setAll = (Q, U, E) => { setQue(Q); setUps(U); setErrQue(E); setUpMsg([]); setUpg('')};

    const onMarkDone = (up, res, err) => {
        
        setUps(U => ArrayRemoveAt(U, 0)) 
    };
    const onUpDone = (up, mediaId) =>  {
        
        apiCallLoad_(dispatch, '/putATMediaUpDone', (res, err) => onMarkDone(up, res, err), {mediaId} );  
    }
    const cbGetLink = async (up, res, err) => {
        const {mediaId} = res||{};
        await mediaUpLoadS3(res, up, onUpProgress);
        onUpDone(up, mediaId);
    };
    useEffect(()=>{
        const uplen = toInt(ups?.length);
        const up2Len = toInt(up2s?.length);
        if(!uplen){
            setUpg( up2Len? ('uploaded ' + up2Len + ' files'): '');
            return;
        }
        setUpg( up2Len? ('uploading ' + (1 + up2Len - uplen) + ' of '+ up2Len +' files'): '');

        const   up = ups[0];
        const form = {
            name: up.name,
            size: up.size,
            ATSubjs: tickSjs,
            kws,
            //const now = aTUITime(timeStampNowGMT(), 'DD-HHmmss');
            //const ext = fileExt(up.name).toLowerCase();
            //const key = 'tmp/reactupload'+now+'.'+ext;
        };
        apiCallLoad_(dispatch, '/getATMediaUplink', (res, err) => cbGetLink(up, res, err), form );  
        //getLink('tmp/temp1.txt');
    }, [ups]);


    const clickUp = e => { UI.stopEvent(e); if(noUp) return; setAll([], que, []); setUp2s(que); };
    const clickN = e => { UI.stopEvent(e); setAll([], [], []); };

    const onFile = async e => { UI.stopEvent(e);
        const qnd = QNoDup( [...(que||[]), ...getFileList(e)] );
        const [q, errs] = ValidateATUps(qnd, _MaxMediaSize);
        if (errs.length > 0) popAlert(dispatch, 0, t('warning.warning_all_files'));
        setErrQue(errs);
        setQue(q);
    };

    return <>
        <div className="fxGrow w100" style={{overflow:"auto"}}>
            <input type="file" multiple id="f1" ref={refFile} onChange={onFile} style={{display:'none'}} />
            <div className='flexRowBetween f14'><div>Upload File(s){aTUIMust}</div><div></div></div>
            <div className='flexRowBetween'>
                <div className="medFileSelBox">
                    {que.map((file, i) => <CpQFile key={i} {...{i, file, clickDelQ}} />)}
                </div>
                {iconButton('','background/uploadFile','#2995cd','transparent',e=>refFile.current.click()
                    ,true,{transform:"scale(1.5)"})}
            </div>
            <div className='flexRowBetween'>
                <div className='flexRowBetween f14' style={{color:"grey"}}>
                        <div>Supported file formats: .mp3, .png, . jpg, .svg, .mp4, .pdf, .pptx/ppt, .gif</div>
                        <div>File Size limit: 80 MB</div>
                </div>
                <div style={{width:"50px"}}></div>
            </div>
            {isAry(errQue) && errQue.map((e, i) => <div key={i}>{aTErrTxt1(e)}</div>) }
            {isAry(upMsg) && upMsg.map((e, i) => <div key={i}>{e}</div>) }
            <div>{upg1}</div>        
            <div>{upg}</div>
            <p/>
            <div className='medFileSubjects'>{CpATSubjectTickList(ATSubjects, mySjIds, tickSjs, setTickSj)}</div>
            <p/>
        </div>
        <div className='flexRowEnd '>
            {UI.colorButton('medCancel','Cancel',clickN,'#2995cd','100px',{},'')}
            {UI.colorButton('medAdd','Add',clickUp,'#2995cd','100px',{marginLeft:"5px"},'',!noUp)}
        </div>
    </>
});

const CpQFile = (props => {
    const {i, file, clickDelQ} = props;
    const {name, size, lastModified, type, webkitRelativePath} = file; 
    return <div style={cssQFile}>
        <div className='f14' style={cssQFileInf}>
            <div><b>{name}</b></div>
            <div>{aTUITime(lastModified)}</div>
            <div>{size} bytes</div>
        </div>
        <div style={cssQX}>{UI.Button0('X', clickDelQ(i))}</div>
        </div>
});

const mediaUpLoadS3 = async (res, up, onUpProgress) => {
    const mediaLink = res.mediaLink;
    const {url, fields} = mediaLink||{}; 
    const buf = await asyncLoadFile(up, 3);
    const buf2File = new File([buf], up.name);
    const result = await HttpPostMP(url, false, fields, {file: buf2File}, onUpProgress);
    
    return result;
};

export const getFileList = e => {
    const files =  (e?.target?.files) || (e?.currentTarget?.files);
    const len = files?.length;
    if(!len) return [];
    const ret = [];
    for(var f = 0; f < len; f++) ret.push(files.item(f));
    
    return ret;
};

const ValidateATUps = (Q, maxSizes) => {
    const [q, es] = [[], []];
    Q.forEach(f => {
        const e = validToUpload(f, 1, maxSizes);
        if(e) 
            es.push(e);
        else
            q.push(f);
    })
    return [q, es];
};
const QNoDup = Q => {
    return Object.values(Array2ObjectFunc(Q||[], f => f.name+'^'+f.lastModified+'^'+f.size));
};
const ArrayRemoveAt = (ary, i) => {
    return isAry(ary)?
        ary.filter( (val, idx) => (idx !== i) ):
        [];
};

export default TabMediaUp;

