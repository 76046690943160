import React, { useMemo, useEffect, useState } from 'react';

import * as UI from '../../libs/libUI';

import { aTErrDiv1, ATHr, aTUIMust, preJS } from '../AppUtil';

import { _ATRoot } from '../../consts/ATConstReact';

//import TabQMC from './TabQMC';
import { objVals, toAry, toObj, toStr } from '../../libs/libType';
import { ReduxBind } from '../../saga/ReduxState';
import { _ATMetaTypeByCode, _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, _ATMetaType_EX, _ATMetaType_GR, _ATMetaType_OT, _ATMetaType_QT, _ATMetaType_SU, _ATMetaType_TB } from '../../consts/ATMetaTypes';
import CpMetaLvTickList, { QSetMSetPerType } from '../components/CpMetaLvTickList';

const TabESetMeta = (props => {
  const {fieldErrs, opts, setTick, setFields, setField, display, setFilter, lock, showEn} = props; 
  
  const eset = toObj(props.eset);
  const {MSMusts, MSOpts} = QSetMSetPerType(objVals(eset?.ESMSets));

  const en = showEn;
  const filter = toAry(eset.ESMetaFilters);
  return <div style={{fontSize:'12px'}}>
    <div>Metadata {aTUIMust}</div>
    <div>Metadata Code(s){aTUIMust}</div>
    {aTErrDiv1(fieldErrs?.ESMetaFilters)}
    <CpMetaLvTickList {...{lock, en, MSMusts, MSOpts, tickLbls:['Filter'], tickss:[filter], setterss:[setFilter]}} />
  </div>;
});
export default TabESetMeta; 
