import React, { useEffect } from 'react';
import { _ATRoleNames, _ATRoot } from '../../consts/ATConstReact';
import { apiCallLoad } from '../../libs/awsFuncs';
import LibSessionId from '../../libs/libSessionId';
import { toStr } from '../../libs/libType';
import * as UI from "../../libs/libUI";
import { gst_clearAll } from '../../saga/globalState.saga';
import { ReduxBind } from '../../saga/ReduxState';
import { clickUrl } from '../AppUtil';

import MenuExpf from './CpExpfMenu';

const logoutIcon = UI.imgSrc('svg/logout.svg');

const CpLeftBar = ReduxBind(props => {
  const { dispatch, gotoPath, vis } = props;
  
  const onATLogout = (res, err) => {
    dispatch(gst_clearAll());
    LibSessionId.clear();
  };
  //const onAppLogout = (res,err) => apiCallLoad(dispatch, '/postATLogout', onATLogout, {}, {});
  //const clickATLogout = e => { UI.stopEvent(e); appLogout(dispatch, props.settings(), onAppLogout); };
  const clickATLogout = e => { UI.stopEvent(e); apiCallLoad(dispatch, '/postATLogout', onATLogout, {}, {}); };
  
  const go2 = path => clickUrl(dispatch, _ATRoot + path);
  let bc = ' navsideback' + (props.vis?'':' d-none');
  let b = 'ATLeftBarFlow navside'+ (props.vis?'':' invis');

  const myRole = toStr(props?._saga?.auth?.ATUser?.ATRole);
  return (<><div className={"ATLeftBar "} vis={vis?"Y":"N"}>
    <MenuExpf gotoPath={gotoPath} goFunc={go2} myRole={myRole} />
      <div className="leftBarlogout f14 clickable" onClick={clickATLogout} >
      <img src={logoutIcon} className="menuIcon" alt="" />
      Logout
    </div>
  </div>
  <div className='ATLeftBarFlowTop' >
    <div className={bc} onClick={props.clickX} />
    <nav className={b} style={{minWidth:'244px'}}>
     <MenuExpf gotoPath={gotoPath} goFunc={go2} myRole={myRole} />
      <div className="leftBarlogout f14 clickable" onClick={clickATLogout} >
      <img src={logoutIcon} className="menuIcon" alt="" />
      Logout
    </div>
  </nav></div>  
  </>);
});

export default CpLeftBar;

