import { toStr } from "../../libs/libType";


export const CpSettingModalText = (props) => { // base on poc SettingModalText.tsx
    const {children, showDim} = props;
    return <span className={"user-select-none" + (showDim?' text-dim-400':'')}>{children}</span>
};


export const CpSettingModalHeader = (props) => { // base on poc SettingModalHeader.tsx
    const {children} = props;
    return <h6 className={"user-select-none"}><u>{children}</u></h6>
};

export const CpSettingModalTitle = (props) => { // base on poc SettingModalTitle.tsx
    const {children, ...htmlprops} = props;
    return <span className={`semi-bold fs-6 user-select-none ${props.variant}`} {...htmlprops}>
        {children}
    </span>
};

export const CpSettingModalSection = (props) => { // base on poc SettingModalSection.tsx
    const {children, hideBorder = false, className = "", paddingX = 3} = props;
    return <>
        <div className={`d-flex flex-column flex-md-row flex-wrap gap-3 px-${paddingX} ${className}`}>
            {children}
        </div>
        <div className={`assignment-settings-border ${hideBorder ? "hide-border" : ""}`}/>
    </>
};

export const CpSettingModalTabContainer = (props) => { // base on poc SettingModalTabContainer.tsx
    const {children, layout = "column", enableAnimation = true, submitting, gap = 4, paddingX = 3} = props;
    const className = toStr(props.className);
    const id = toStr(props.id);
    return <div id={id} className={`${className} d-flex flex-${layout} flex-grow-1 px-${paddingX} mt-3 gap-${gap} ${enableAnimation? "fade-in-container": ""} ${submitting? "submitting pe-none": ""}`}>
        {children}
    </div>
};

export const CpSettingModalSubTitle = (props) => { // base on poc SettingModalSubTitle.tsx
    const {children, ...htmlprops} = props;
    return <small className={"user-select-none mb-2"} {...htmlprops}>
        {children}
    </small>
};