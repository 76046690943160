import { toStr, isAry, toObj, toAry } from "../libs/libType";
import { _MaxLenQId, _MinLenQId, _MaxLenIds, _MaxLenTodo } from "./ATConsts";
import { validIdCharsMinMax, validMinMax, validFields, toUniIdAry, toUniIdsStr, toIdStr, validESASIDStr, } from "./ATValidate";
import { QP_P, QP_PP } from "./ATConsts";


export const normalizedEditESet = (fields, opts, trace) => {
    //const track = o => trace && trace.push(o);
    //track('-- normalizedEditATQSet --');

    const fs = toObj(fields);
    
    return {
        //Settings
        ESetId: toIdStr(fs.ESetId),
        ESASStr: toUniIdsStr(fs.ESASStr),
        ESLangCt: fs.ESLangCt?1:0,
        ESLangEn: fs.ESLangEn?1:0,
        ESNameEn: toStr(fs.ESNameEn).trim(),
        ESNameCt: toStr(fs.ESNameCt).trim(),
        ESSubjIds: toUniIdAry(fs.ESSubjIds),
        ESKwEn: toUniIdsStr(fs.ESKwEn), //todo
        ESKwCt: toUniIdsStr(fs.ESKwCt), //todo

        ESEIds: toUniIdAry(fs.ESEIds), //EQIds...
        
        ESMSets: fs.ESMSets,
        ESMetaFilters: toUniIdAry(fs.ESMetaFilters), //todo
        
        ESThumbs: toObj(fs.ESThumbs), //todo

        isNew: fs.isNew?1:0,
    };
};

export const validEditESet = (fs, opts, trace) => {
    const track = o => trace && trace.push(o);
    track('-- validEditESet --');

    const v = validFields(fs, {});
    //Settings
    v.setIf('ESetId', (validIdCharsMinMax(fs.ESetId, _MinLenQId , _MaxLenQId, 'Exercise Set ID')?
        'The exercise set id should be within 2 - 50 characters (Alphanumeric or Underscore).':''));
    v.setIf('ESASStr', validESASIDStr(fs.ESASStr)); //validIdCharsMinMax(fs.ESASStr, _MinLenQId , _MaxLenIds, 'ESAS Series Codes'));

    v.setIf('ESLang', (!(fs.ESLangCt || fs.ESLangEn)) && 'Missing Language');

    v.setIf('ESNameEn', fs.ESLangEn && validMinMax(fs.ESNameEn, 1, _MaxLenTodo, 'Name (En)'));
    v.setIf('ESNameCt', fs.ESLangCt && validMinMax(fs.ESNameCt, 1, _MaxLenTodo, 'Name (緊)'));

    v.setIf('ESSubjIds', (!(fs.ESSubjIds?.length)) && 'Missing Subject'); 
   
    v.setIf('ESEids', (!(toAry(fs.ESEIds).length)) && 'Missing Exercises');
 
    track('-- validEditESet end --');
    return v.fieldErrs;
};

export const ESEErrs = (eset, e) => {
    const {ESLangEn:en, ESLangCt:ct} = eset;
    const err = e?([
            ((e.dp !== QP_P) || (e.drpu !== QP_PP)) && 'Not Published',
            ((en && !e.ELangEn) || (ct && !e.ELangCt)) && 'Language mismatch', 
        ].filter(e => e).join(', ')
        ): 'Not Published'; 
    return err;
};
