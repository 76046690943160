import { useUILang } from "../../AppExPf/utils/useUILang";
import React, {HTMLProps, useMemo, useState} from "react";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import {Offcanvas} from "react-bootstrap";
import CpCheckbox from "../_components/CpCheckbox";
import { SIZE, CHECKBOX_VARIANT } from "../_components/CpExPFConst";
import { CpSettingModalText } from "../_components/CpSettingModalTabCps";
import { getRandomNumber } from "../../poc/helper/getRandomNumber";
import { preJS } from "../../AppExPf/AppUtil";
import { toAry, toInt, toObj, toStr } from "../../libs/libType";
import { _MST_MARKED, _WST_SUBMIT } from "../../consts/ATConstsAssignment";
import CpCheckboxComponent from "../_components/CpCheckboxComponent";

const CpAssignStdFilter = (props => { // base on poc AssignmentFilter
    const {shiftMark, SWMs, swmIdx, isReme} = props;
    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();
    const _marked = props?.marked;
{/*
    const studentList = useMemo(() => {
        let list = t("schema:dummy.studentList", {returnObjects: true});
        const newlist = list.map(student => {
            return {...student, marked: (getRandomNumber(0, 2) ? true : false)}
        });
        return newlist;
    }, [])
*/}
    const [open, setOpen] = useState(false);
    const [markingState, setMarkingState] = useState({pending: !_marked, marked: isReme?1:_marked});

    const handleOpen = () => setOpen(1);
    const handleClose = () => setOpen(0);
    const handleClick = i => { 
        shiftMark(i, 1)(); // 1 = set swmIdx directly
        setOpen(0); 
    };

    const s = toObj(SWMs[swmIdx]?.student);
    const {classNo, nameEng, nameChi} = s;

    const {pending, marked} = markingState;
    /*
    const work = toObj(swm?.work);
    const mark = toObj(swm?.mark);
    return (work?.WState === _WST_SUBMIT) && (mark?.MState === _MST_MARKED);
    */
    const filtStus = useMemo(() => { 
        return toAry(SWMs).filter(swm => {
            const work = toObj(swm?.work);
            const mark = toObj(swm?.mark);
            const subed = work.WState === _WST_SUBMIT;
            const marked = mark.MState === _MST_MARKED;
            const getMarked = markingState.marked;
            const getPending = markingState.pending;
            return subed && ((getMarked && marked) || (getPending && !marked) || (getMarked && getPending));
            /*return pending? (swm.work?.WState === _WST_SUBMIT):
            marked? 'todo (swm.mark.MState === _MST_MARKED)':
            1;*/ 
        }).sort((_s1, _s2) => toInt(_s1.classNo) - toInt(_s2.classNo));
    }, [SWMs, pending, marked]);
    return <>
        <div className={"text-white text-center semi-bold d-flex align-items-center gap-2 justify-content-center"}>
            <span role={"button"} className={"btn btn-hover-dark-preview-primary"} onClick={shiftMark(-1)}>
                <CpIco src={IconList.general.previousPage}/>
            </span>
            <div className={'btn btn-hover-dark-preview-primary d-flex justify-content-center  align-items-center gap-2 text-truncate'}
                 role={"button"} onClick={handleOpen}>
                <span className={"text-exercise-third-btn"}><CpIco src={IconList.general.filter}/></span>
                <span className={"text-truncate"}>{toStr(classNo)} {ut(nameEng, nameChi)}</span>
            </div>
            <span role={"button"} className={"btn btn-hover-dark-preview-primary"} onClick={shiftMark(1)}>
                <CpIco src={IconList.general.nextPage}/>
            </span>
        </div>
        <Offcanvas show={open} onHide={handleClose} placement={"bottom"} scroll={true} backdrop={true} className={"popover-component h-fit-content"}>
            <Offcanvas.Header closeButton className={"fs-8 py-1"}>
                <Offcanvas.Title as={"h6"}><div className={"d-flex gap-2 align-items-center"}>
                    <CpIco src={IconList.general.avatar}/>
                    <span className={"offcanvas-title font-weight-bold"}>{t("assignment-settings-student-list")}</span>
                </div></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body as={"div"} className={"flex-wrap"}>
                {isReme?'':
                <div className={"d-flex flex-wrap gap-3 mb-3"}>
                    <span role={"button"} className={"d-flex gap-2 align-items-center semi-bold"}
                          onClick={() => setMarkingState(prev => ({...prev, pending: !prev.pending}))}>
                        <CpCheckboxComponent variant={CHECKBOX_VARIANT.SQUARE_BORDER} checked={markingState.pending}
                            checkIcon={true} className={"exercise-radiobox pe-none"} size={SIZE.XXXS}/>
                        <CpSettingModalText>{t("assignment-status-pending-to-mark")}</CpSettingModalText>
                    </span>
                    <span role={"button"} className={"d-flex gap-2 align-items-center semi-bold"}
                          onClick={() => setMarkingState(prev => ({...prev, marked: !prev.marked}))}>
                        <CpCheckboxComponent variant={CHECKBOX_VARIANT.SQUARE_BORDER} checked={markingState.marked}
                            checkIcon={true} className={"exercise-radiobox pe-none"} size={SIZE.XXXS}/>
                        <CpSettingModalText>{t("assignment-status-marked")}</CpSettingModalText>
                    </span>
                </div>}
                <div className={"d-flex w-100 flex-wrap gap-3 border insetShadow p-4"}>
{filtStus.length>0?
filtStus.map(swm => {
    return <CpStudentCheckBox {...{key: swm.swmIdx, swm, onClick:() => handleClick(swm.swmIdx), active:(swmIdx === swm.swmIdx), whole:0}} />;
}):<div className='flexRowCenter'>{t("no-student")}</div>
}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>;
});
export default CpAssignStdFilter;

const CpStudentCheckBox = (props) => {
    const {active, whole, onClick} = props;
    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();
    const swm = toObj(props.swm);
    const [swmIdx, s, w, m] = [swm.swmIdx, toObj(swm.student), toObj(swm.work), toObj(swm.mark)];
    const {classNo, nameEng, nameChi} = s;
    
    return <div {...{onClick, role: "button", style:{width: "300px"}, 
        className:`d-flex gap-3 text-truncate px-3 py-2 semi-bold border user-select-none student-checkbox-btn rounded-light ${active? `${whole? "all": "active"}`: ""}`}} 
        style={{alignItems:"center",width: "300px"}}>
        <CpCheckboxComponent variant={CHECKBOX_VARIANT.ROUND_BORDER} checkIcon={false}
            checked={active} className={"manual-mark-filter-checkbox"}
            size={SIZE.XXXS}
        />
        <span>{toStr(classNo)}</span>
        <span className={"text-truncate"}>{ut(nameEng, nameChi)}</span>
    </div>
};
//manual-mark-filter-checkbox cus-checkbox-xxxs checkbox-round-border checked p-0 d-flex flex-row justify-content-start
const StudentCheckBox = (props) => {
    const {student, active = false, whole = false, ...divProps} = props;
    const {no, name} = student;
    return <div role={"button"} 
    className={`d-flex gap-3 text-truncate px-3 py-2 semi-bold border user-select-none student-checkbox-btn rounded-light ${active ? `${whole ? "all" : "active"}` : ""}`} 
        {...divProps} style={{width: "300px"}}>
        <CpCheckbox checked={active} disableOnClick={true}></CpCheckbox>
        <span>{no}</span>
        <span className={"text-truncate"}>{name}</span>
    </div>
};


/*
studentList.filter(student => {
    if (markingState.pending || markingState.marked) {
        return (!student.marked && markingState.pending) || (student.marked && markingState.marked)
    };
    return false;
}).map(student => 
    <StudentCheckBox student={student} key={student.no} onClick={() => handleClick(student)} active={activeStudent.no === student.no} whole={false}/>)

filtStus.map(swm => {
    const work = toObj(swm?.work);
    const subed = work?.WState === _WST_SUBMIT;
    return subed?<CpStudentCheckBox {...{key: swm.swmIdx, swm, onClick:() => handleClick(swm.swmIdx), active:(swmIdx === swm.swmIdx), whole:0}} />:'';
})
*/
