import React, { useState, useEffect, useRef, forwardRef } from 'react';
import * as UI from "../../libs/libUI";
import { svgIcon2 } from '../components/CpATIcoBtn';
import { useUILang } from '../utils/useUILang';
import { autoScrollByDD } from '../components/autoScrollByDD';

const CpATAnsDDResult = forwardRef((props, ref) => {
    const { sel, opts, menuStyle, rowStyle, domQuesRef, mCorrect=0, showOptions=1} = props;
    const [show, setShow] = useState(false);
    const [showInfo, setShowInfo] = useState({showL:true,maxWidth:'360px'});
    const headRef = useRef(null);
    const contRef = useRef(null);

    const [ t ] = useUILang();

    useEffect(()=>{
        if (show && headRef.current && headRef.current.offsetParent) {
            
            
            const pa = headRef.current.offsetParent.getBoundingClientRect();
            const winWidth = window.innerWidth;
            const showL = ((pa.left > (winWidth - pa.right))?false:true);
            let maxWidth = (winWidth > 768)?Math.floor(winWidth*0.5):Math.floor(winWidth*0.75);
            if (showL && ((maxWidth + pa.left) > winWidth)) maxWidth = winWidth - pa.left - 5;
            if (!showL && ((winWidth - pa.right + maxWidth) > winWidth )) maxWidth = pa.right - 5;            
            setShowInfo({showL:showL, maxWidth:(maxWidth+'px')});
        };   
        
        if ( show && domQuesRef?.current && headRef.current && contRef.current ) {
            
            autoScrollByDD({container: domQuesRef, dropdown: headRef, options: contRef});
        } else {
            
        };

        /*
        if (contRef.current) {
            //contRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
            contRef.current.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
        };
        */        
    },[show]); 

    const getHead = () => {
        // if sel = -1 , selected blanked answer, or not correct answer
        const isCorrect = (mCorrect>0 || (sel>=0));
        const xx = (isCorrect)?opts.find((opt)=>{return opt.id === sel}):opts[0];
        return <div className='asnDDResultHead' style={{backgroundColor:isCorrect?'#E5F3ED':'white'}}>
            <div className='flexRowCenterFit' style={{overflowX:'auto'}}>{xx.jsx()}</div>    
            <div className='flexRowCenterFit'>
                {isCorrect?svgIcon2("general/correct","#4eae84",{marginLeft:"5px",
                    minWidth:'25px',minHeight:'25px',width:'25px',height:'25px'}):''}
                {(opts.length>1 && showOptions)?<div className='flexRowCenterFit'>
                    <div style={{maxwidth:'1px', minWidth:'1px', height:'30px',
                        border:'1px solid #4eae84', borderStyle:'none solid none none'}}></div>
                    {svgIcon2("general/moreVertical","#4eae84",
                        {minWidth:"20px",minHeight:"20px",width:"20px",height:"20px"})}
                </div>:''}
            </div>
        </div>
    };

    const onBlur = (e) => {
        UI.stopEvent(e);
        setShow(false);
    };
    const optClick = (e, id) => { setShow(false); };
    const clickShow = (e) => { if (showOptions) setShow(!show); };
    const onMouseDown = (e) => {
        e.preventDefault();
    };
    const createDDContent=()=>{
        const result = [];
        result.push(<div key='altrow' className='ATDDAnsrowResult f14' 
            style={{padding:'2px 15px', color:'grey'}}>{t('at-setting.fib.alternative-answer')}</div>);
        result.push(opts.map((opt, ii) => {
            const exStyle = ((sel>=0 || mCorrect)?(opt.id === sel?{display:'none'}:{}):(ii===0?{display:'none'}:{}));
            return <div key={"opt" + opt.id + ii} className='ATDDAnsrowResult ' tabIndex={0}
            onClick={(e)=>optClick(e, opt.id)} style={{...rowStyle, ...exStyle}}>{opt.jsx()}</div>}));
        return result;
    };

    return <div ref={ref} className='ATDDAnsMenu' tabIndex={0} onBlur={onBlur} 
            style={{backgroundColor:(sel>=0 || mCorrect>0)?'#E5F3ED':'white', ...menuStyle}}>
            <div ref={headRef} className={'ATDDAnsHeaderResult clickable '} style={{width:'100%'}} onClick={clickShow}>{getHead()}</div>
            {show && opts.length > 1 && (opts && opts.length > 0) && 
                <div className={'ATDDAnsContainerResult' + (showInfo.showL?"Left ":"Right ")}
                onMouseDown={onMouseDown} ref={contRef}
                style={{width:'max-content', maxWidth:showInfo.maxWidth}}>
                <div className='flexColStartFit'>
                {createDDContent()}
                </div>
            </div>}
        </div>;
});

export default CpATAnsDDResult;
