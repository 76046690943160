import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { aTErrDiv1, preJS, deepCopy } from "../../AppExPf/AppUtil";
import { useTickAll } from "../../AppExPf/ATQtnEdit/PageATQtns";
import { hasErr, useFields } from "../../AppExPf/utils/useEditDraftPub";
import { apiCall, apiCallLoad_, asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { strCmp, toAry, toObj, toStr } from "../../libs/libType";
import Checkbox from "../_components/CpCheckbox";

import { CpSettingModalSection, CpSettingModalTabContainer, CpSettingModalTitle } from "../_components/CpSettingModalTabCps";
import CpSharedModal from "../_components/CpSharedModal";
import SharedModalHeader from "../_components/CpSharedModalHeader";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { ReduxBind } from "../../saga/ReduxState";
import CpColorSelect from "../_components/CpColorSelect";
import { EpInputTxt0, stopEvent } from "../../libs/libUI";
import { ATErrsDiv } from "../../AppExPf/AppUtil";
import { toIdStr } from "../../consts/ATValidate";


export const LayerECollEdit = ReduxBind(props => {
    //const loading = props._saga?.loading?.count;
    const {t, para, setPara, dispatch, reload} = props;
    const vis = para;
    const {ESetId, EColId, ECol, EId} = toObj(para);
    const isNew = ECol? 0: 1;
    
    const close = e => {stopEvent(e); reset(); setPara(); };

    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs] = useFields(); 

    const [ECTicks, setECTick, setECTicks, ECtickAll, setECTickAll, clearECTicks] = useTickAll();
    const [ECols, setECols] = useState();

    const [tab, setTab] = useState(1);
    const tickTab = ((tab === 1) && EId)? 1: 0;

    const [colIdx, setColIdx] = useState(0);
    const _idx  = color2D[colIdx]? colIdx: 0;
    //const {color, bgcolor} = toObj(color2D[colIdx] || color2D[0]);

    const reset = () => { setFieldErrs({}); setFields({}); setTab(1); };
    const reloadECols = async () => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getECols', {EId});
        setECTicks(res?.ECEs?.[EId]);
        setECols(sortECols(res?.ECols));
        setColIdx(0);
    };
    
    const uAryECol = [];
    ECols?.forEach((col) => { 
        if(col.EColId === EColId) { uAryECol.push(col); }
     });
   
    useEffect(() => { if(vis && EId){ reset(); reloadECols();} }, [vis, ECol]);

    useEffect(() => { 
        setFields(eColDraft(ECol));
        setColIdx(findColIdx(color2D, ECol));
    }, [ECol]);

    const onSaveECol = (res, err) => {close(); reload && reload('EColEdit');};//
    const clickSaveECol = () => {  
        const [fs, fes] = validateEditEcol(fields, setField, setFieldErrs);
        const colors2 = color2D[_idx] || color2D[0];
        if(!hasErr(fes))
            apiCallLoad_(dispatch, '/putECol', onSaveECol, {ESetId, EId, ECol:{...fs, isNew, ...colors2}});
    };

    const clickSaveBM = () => apiCallLoad_(dispatch, '/putEBookMark', onSaveECol,  {EId, EColIds:ECTicks, type:'folder'});

    const clickSaveEcol = e => {stopEvent(e); tickTab? clickSaveBM(): clickSaveECol(); }

    const clsTab = hi => hi? " border-exercise-third-btn text-exercise-third-btn": "border-transparent text-dim-350"
    const EdEColTitle = t(EId?"save-to-collection": isNew? "new-collection": "existing-collections");
    return <CpSharedModal show={vis} scrollable={true}
        header={
            <SharedModalHeader handleClose={close} title={EdEColTitle} iconPath={IconList.general.collection} />
        } 
        footer={<div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
            <Button onClick={close} variant="gray-body-color w-100 border"> {t("cancel")} </Button>
            <Button onClick={clickSaveEcol} variant={"exercise-third-btn btn-block w-100"}>{t("save")}</Button>
        </div>}  >

        <CpSettingModalTabContainer gap={tab === 1? 4 : 3}>
            {EId? 
            <div className={"d-flex w-100 gap-3 user-select-none"}>
                <span role={"button"} onClick={() => setTab(1)}
                    className={`d-flex w-100 px-3 pb-2 justify-content-center gap-2 align-items-center border-bottom border-2 
                    uiAnimation text-nowrap text-truncate semi-bold ${clsTab(tab === 1)}`}>
                    {/*<CpIco src={IconList.general.circleCheck}/>*/}{t("existing-collections")}
                </span>
                <span role={"button"} onClick={() => setTab(2)}
                    className={`d-flex w-100 px-3 pb-2 justify-content-center gap-2 align-items-center border-bottom border-2 
                    uiAnimation text-nowrap text-truncate semi-bold ${clsTab(tab === 2)}`}>
                    {/*<CpIco src={IconList.general.circleCheck}/>*/}{t("new-collection")}
                </span>
            </div>
            :''}
            {tickTab? <>
                <CpSettingModalSection className={"flex-column"} hideBorder={true}>
                <CpSettingModalTitle> {t("please-select-collection-to-save")} </CpSettingModalTitle>
                <div className={"border p-3 bg-white d-flex flex-column gap-2 overflow-auto w-100"} style={{maxHeight: "200px"}}>
                    { toAry(ECols).map( (ECol, idx) => <CpFolderItem  key={idx} {...{idx, ECol, ECTicks:toAry(ECTicks), setECTick}} />) }
                </div>
            </CpSettingModalSection>
            </>:<>
                <CpSettingModalSection>
                    <CpSettingModalTitle>{t("collection-title")}: </CpSettingModalTitle>
                    {EpInputTxt0(fields?.ECName, setField('ECName'), 'tn', 'w-100 border', {}, '')}
                    {aTErrDiv1(fieldErrs?.ECName)}
                </CpSettingModalSection>
                <CpSettingModalSection hideBorder={true}>
                    <CpSettingModalTitle>{t("collection-colour")}: </CpSettingModalTitle>
                    <div className={"d-flex flex-wrap gap-2 w-100"}>
                        <CpColorSelect {...{color2D, idx:_idx, setIdx:setColIdx}}/>
                    </div>
                </CpSettingModalSection>
            </>
            }
        </CpSettingModalTabContainer>
    </CpSharedModal>;
});

const CpFolderItem = (props => {
    const { idx, ECol, ECTicks, setECTick } = props;
    const {EColId, ECColor, ECBgColor} = ECol;
    const on = ECTicks.includes(EColId);
    const click = e => { stopEvent(e); setECTick(ECol.EColId)(!on); };

    return <div role={"button"} onClick={click} className={"d-flex justify-content-start align-items-center gap-3"} 
        style={{ }}>
        <Checkbox checked={on} onClick={click} readOnly/>
        <span style={{backgroundColor:'#fff', color:'#000'}}
            className={"d-flex flex-grow-1 text-truncate fs-6 mt-1"}>{toStr(ECol.ECName)}</span>
    </div>
});
/*
        <span style={{backgroundColor:ECBgColor||'#fff', color:ECColor||'#000'}}
            className={"d-flex flex-grow-1 text-truncate fs-6 mt-1"}>{toStr(ECol.ECName)}</span>
*/
const sortECols = cs => [...toAry(cs)].sort((a,b) => strCmp(a.ECName, b.ECName) || (b.dateMode - a.dateMode));

const validateEditEcol = (_fs, setFs, setFES) => {
    const fs = {
        EColId: toIdStr(_fs?.EColId),
        ECName: toStr(_fs?.ECName).trim().slice(0, 50),
    };

    const fes = {}
    if(!_fs.ECName) fes.ECName = 'Missing Folder Name';

    setFs(fs);
    setFES(fes);
    return [fs, fes];
}
const eColDraft = ecol => {
    const {code, schoolId, userId, ECnt, dateMod, ...ret} = toObj(ecol);
    return deepCopy(ret);
};
const findColIdx = (color2D, ECol) => {
    const {ECColor, ECBgColor} = toObj(ECol);
    const idx = toAry(color2D).findIndex(c2 => (c2.color === ECBgColor) && (c2.bgcolor === ECColor)); 
    return (idx < 0)? 0: idx;        
}
const color2D_org = [
    { color: "#F2D7D4", bgcolor: "#CD6256", },
    { color: "#ECDFF1", bgcolor: "#B17CC5", },
    { color: "#D5E6F1", bgcolor: "#5298C7", },
    { color: "#D0F1EA", bgcolor: "#49CAB0", },
    { color: "#D3EEDE", bgcolor: "#51BD7E", },
    { color: "#FCF3CF", bgcolor: "#F4CF3E", },
    { color: "#FAE4D1", bgcolor: "#DC7632", }
];

const color2D = [
    { color: "#d32d41", bgcolor: "#f1d1d5", },
    { color: "#CD6256", bgcolor: "#F2D7D4", },
    { color: "#cb58a9", bgcolor: "#ffdaf4", },
    { color: "#B17CC5", bgcolor: "#ECDFF1", },
    { color: "#587ecb", bgcolor: "#daefff", },
    { color: "#5298C7", bgcolor: "#D5E6F1", },
    { color: "#488a99", bgcolor: "#e0eff2", },
    { color: "#49CAB0", bgcolor: "#D0F1EA", },
    { color: "#6ab187", bgcolor: "#dcf7e7", },
    { color: "#51BD7E", bgcolor: "#D3EEDE", },
    { color: "#F4CF3E", bgcolor: "#FCF3CF", },
    { color: "#dbae58", bgcolor: "#fbf3e2", },
    { color: "#DC7632", bgcolor: "#FAE4D1", },
    { color: "#535904", bgcolor: "#b3c100", },
    { color: "#a7a7a7", bgcolor: "#f1f1f1", },
    { color: "#484848", bgcolor: "#d5d5d5", },
    { color: "#1f3f49", bgcolor: "#cbd5d9", },
];