import React, { } from 'react';
import { _ATSysQTypes } from '../../consts/ATSysQType';
import { _ATMetaTypeByCode, _ATMetaTypes, _ATMetaType_CO, _ATMetaType_CU, _ATMetaType_DI, 
  _ATMetaType_GR, _ATMetaType_QT, _ATMetaType_SU, _ATMetaType_TB } from '../../consts/ATMetaTypes';
import { autoId } from '../AppUtil';
import { toObj, toStr, } from '../../libs/libType';
import CpMJX from '../../AppExPFUser/_components/CpMJX';


export const CpMetaLines = props => {
  const {en, MSMusts, MSOpts,} = props;
  const CpMustLines = (mstypename, code) => <CpTypeMSetLines key={code} {...{ mstypename, must:1, MSets: MSMusts[code], en}} />;
  const CpOptLines = (code, MSets) => <CpTypeMSetLines key={code} {...{mstypename:_ATMetaTypeByCode(code), must:0, MSets, en}} />;
return <>
    <div className='f12'>
    {CpMustLines('Question Type(s)', _ATMetaType_QT)}
    {CpMustLines('Subject(s)', _ATMetaType_SU)}
    {CpMustLines('Textbook(s)', _ATMetaType_TB)}
    {CpMustLines('Curriculum(s)', _ATMetaType_CU)}
    {CpMustLines('Grade(s)', _ATMetaType_GR)}
    {CpMustLines('Copyright(s)', _ATMetaType_CO)}
    {CpMustLines('Diffculty(s)', _ATMetaType_DI )}
    {Object.entries(MSOpts).map(([mstype, msets]) => CpOptLines(mstype, msets)) }
    </div>
  </>;
};

const cssMLHead = {margin:'4px 20px 4px 4px', minWidth:'120px', };
const cssMLBody = {margin:'4px'};

const CpTypeMSetLines = ({mstypename, must, MSets, en, }) => {
  return <div key={mstypename} className="fxRow" style={{margin:'0 0 12px 0'}}>
    <div style={cssMLHead}><b>{mstypename} </b> </div>
    <div className="fxGrow">
      {MSets && MSets.map( (mset, i) => <CpMSetLines key={i} {...{mset, en, }} />)}
    </div>
  </div>;
}; 
const CpMSetLines = ({mset, en, }) => {
  return <>{mset.ends.map((end, i) => <CpMTLine key={i} {...{mset, end, en, }} />)}</>;
};
const CpMTLine = ({mset, end, en, lock}) => {
  const name = (e, c) => toStr((en && e) || c || e);
  const rows = toObj(mset.rows);
  const mkChian = e => {
    const m = rows[e]; 
    const pid = m.pid; 
    return pid? [...mkChian(pid), m]: [m];
  }
  const chain = mkChian(end);
  //{preJS(chain)}
  return <div className=''>{
    chain.map((m,ii) => {
      const {pid, LVNum, nameEn, naemCt} = m;
      return <div key={ii} className="w p4"><CpMJX dynamic={true} key={autoId()} >
        {pid? <div className="w p4">{'>'}</div>: ''}{(toStr(LVNum)+' '+name(nameEn, naemCt)).trim()}
      </CpMJX></div>;
    })}
  </div>
};
