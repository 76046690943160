import React, {useContext, useEffect, useMemo, useState} from "react";

import { ReduxBind } from "../../saga/ReduxState";
import { isEn2Lang, useUILang } from "../../AppExPf/utils/useUILang";
import { objKeys, objVals, toAry, toObj, toStr } from "../../libs/libType";
import CpExEditToolBar from "../_components/CpExEditToolBar";
import { useChain } from "../../AppExPf/utils/useChain";
import * as UI from '../../libs/libUI';
import { deepCopy, preJS } from "../../AppExPf/AppUtil";
import CpIco from "../_components/CpIco";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { PresentationMode } from "../_components/CpExPFConst";
import CpExerciseBubble from "../_components/CpExerciseBubble";
import { _ExCtType_CK, _ExCtType_Img, _ExCtType_Lnk, _ExCtType_Mp3, _ExCtType_Qtn,
    _ExCtType_Txt, _ExCtType_Video } from '../../consts/ATValidateEcnts';
import { ContentBlock, ViewExCtn } from "../../AppExPf/ATExerEdit/TabExerContent";
import TabQtnEdit from "../../AppExPf/ATQtnEdit/TabQtnEdit";
import { LayerECtnEdit } from "../../AppExPf/ATExerEdit/TabExerContent";
import { CpECtnViews } from "../../AppExPf/ATExerEdit/TabExerContent";
import { subState } from "../../AppExPf/utils/useChain";
import { FullScreenMask } from "../../AppExPf/AppUtil";
import { getUUID } from "../../poc/helper/getUUID";
import { QP_P, QP_PP } from "../../consts/ATConsts";
import { normalizedEditATQtnV2, validEditATQtnV2 } from "../../consts/ATValidateQ";
import { hasErr, normalizeEdit } from "../../AppExPf/utils/useEditDraftPub";
import { defaultQtnData } from "./constQtnTemplate";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { toIdStr, toUniIdAry, toUniIdsStr } from "../../consts/ATValidate";
import { exerUniQIds, fixECtns } from "../../consts/ATValidateExer";
import { loading_set, loading_unset } from "../../saga/loading.saga";
import { xapiPost } from "../EPLibrary/PageLibrary";
import { jumpToEctn } from "../_components/CpExerciseBubble";

import { popAlertToast } from "../../AppExPf/components/CpPopup";

export const setEditQMask = (dispatch, mask, caller) => {
    
    dispatch(mask? loading_set('showQEdit', 1): loading_unset('showQEdit'));
};

const TabExerEdContent = ReduxBind(props => {//base on poc <ExerciseEditContentPage> 
    const myUId = props.userId();// 'teacher'; //searchParams.get("role") || "teacher"
    const { reload, lock, showEn, dispatch, setFields, asm, qtns, useCacheQtns, addNewQtn} = props;
    const { allDVis } = props;
    const [ t ] = useUILang();
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);

    //const {state, dispatch} = useContext(ExerciseContext)
    const [selectedContentId, setSelectedContentId] = useState(0);
    const [lastEdit, setLastEdit] = useState(-1);
    const [lastLoaded, setLastLoaded] = useState(-1);
    /* vvvvvvvvvvvvvvvvvv direct open edit content */
    const [visEd, setVisEd] = useState(0);
    const [ed, setEd] = useState();
    const [newC, setNewC] = useState({});
    /* ^^^^^^^^^^^^^^^^^^ direct open edit content */
    const [qtnModified, setQtnModified] = useState(0);

    // Exer, Ctns ////////////////////////////////////////////////   ////////////////////////////////////////////////   
    const isAT = 0;
    const ex = toObj(props.ex);
    const EId = toIdStr(ex.EId);
    const ECtns = useMemo(() => {
        const qids = toUniIdAry(objVals(qtns).map(q => q && q.QId));
        return fixECtns(ex.ECtns, qids);
    }, [ex, qtns]);

    const uniQids = useMemo(() => exerUniQIds(ex), [props.ex]);
    useCacheQtns(uniQids); //cache Questions 

    const content = useMemo(() => {
        let qnum = 0;
        return ECtns.map((ECtn, idx)=>{
            const isQ = (ECtn.type === _ExCtType_Qtn);
            return {id:idx, type:ECtn.type, questionNum: isQ? (++qnum): 0};
        });
    }, [ECtns]);
    const isLastECtn = (idx) => { return idx === (content.length - 1); };

    const [addEContent, replaceContent, deleteContent, moveBlock, insertBlock] = useChain(setFields, 'ECtns');
    const clickDelI = idx => e => { UI.stopEvent(e); deleteContent(idx);         
        //new msg
        popAlertToast(dispatch, 0, "delQ");
    };

    //const clickMoveI0 = (idx, newIdx) => e => {UI.stopEvent(e); moveBlock(idx, newIdx); };
    const clickMoveI = (idx, newIdx) => e => {UI.stopEvent(e);
        let tmp = newIdx;
        if (tmp < 0) { tmp = ECtns.length - 1; };
        if (tmp >= ECtns.length) { tmp = 0 };
        //moveBlock(idx, newIdx);
        moveBlock(idx, tmp);
    };    

    const clickInsI = idx => e => { UI.stopEvent(e); 
        const newCtn = makeCopyECtn(ECtns[idx], showEn, isAT, myUId, qtns, addNewQtn);
        newCtn && insertBlock(idx, newCtn ); 
        //new msg 
        popAlertToast(dispatch, 0, "copyQ");
    }; 

    // Add a Question ////////////////////////////////////////////////   ////////////////////////////////////////////////  
    const [editFullQ, setEditFullQ] = useState(); // point to new qu or edit qu
    const _setEditFullQ = (v) => {
        setQtnModified(1);
        setEditFullQ(v);
    };

    const {setFullQ, setQ} = useEditQ(_setEditFullQ, showEn);
    const [editECtnIdx, setEditCtnIdx] = useState(0);
    const [editQErrs, setEditQerrs] = useState({});

    const setEditECtnQ = async (idx, fullQ, fullQErrs) => { 
        setEditQMask(dispatch, 1, 'setEditECtnQ');
        setTimeout(() => _setEditECtnQ(idx, fullQ, fullQErrs), 1);
    };

    //useEffect(() => { if (lastLoaded >= 0) { console.log("last loaded !! ", lastLoaded); }; }, [lastLoaded]);
    //useEffect(()=>{ console.log('selectedContentId:', selectedContentId); },[selectedContentId]);

    const _setEditECtnQ = async (idx, fullQ, fullQErrs) => { setEditCtnIdx(idx); setEditFullQ(fullQ); setEditQerrs(fullQErrs); };
    const editQOpts = {};

    // for search Q
    const addedQIds = useMemo(() => exerUniQIds(ex), [ex, ECtns]);
    const onAddQ = async (QId, qFilters) => {
        addEContent(ContentBlock(_ExCtType_Qtn, QId));
        xapiPost(dispatch, 'addQ', {EId, QId, qFilters});
        //new msg
        popAlertToast(dispatch, 0, "addQ");
    };

    const onSaveQEdit = (newQId, newQtn) => {
        const [fq, fqes] = normalizeEdit(editFullQ, editQOpts, [], normalizedEditATQtnV2, validEditATQtnV2, setFullQ, setEditQerrs);
        const err = hasErr(fqes);
        if (!err) {
            addNewQtn(newQId, newQtn);
            //addEContent(ContentBlock(_ExCtType_Qtn, newQId));
            if (editECtnIdx >= 0){
                replaceContent(editECtnIdx, ContentBlock(_ExCtType_Qtn, newQId));
                //console.log("qtn edit save");
                setLastEdit(editECtnIdx);
            } else {
                //console.log("qtn add save");
                if (content && content.length > 0) setLastEdit(content.length);
                addEContent(ContentBlock(_ExCtType_Qtn, newQId));
            };
            setEditECtnQ();
        };
        setQtnModified(0);
    };
    const onCloseQEdit = () => {
        if ( editECtnIdx >=0 ) setLastEdit(editECtnIdx);
        setEditECtnQ(); setQtnModified(0);
    };
    const createQtn = qType => { setEditECtnQ(-1, initEditFullQ(newFullQ(qType), 0, myUId, showEn), {}); };
    const editQtn = (idx, ECtn) => {
        const baseQ = qtns[ECtn.QIds];//useCacheQtns([ECtn.QIds])[ECtn.QIds];
        const myQ = isMyQ(baseQ, myUId);
        setEditECtnQ(idx, myQ? baseQ: initEditFullQ(deepCopy(baseQ), myQ? 0: 1, myUId, showEn), {});
    };
    //////////////////////////////////////////////////

    const {EModeSlide, EModeScroll} = ex;
    const {SCROLL_MODE, SLIDE_MODE} = PresentationMode;
    const [presentationMode, setPresentationMode] = useState(EModeScroll? SCROLL_MODE: SLIDE_MODE);
    const isScroll = (presentationMode === SCROLL_MODE);
    const isSlide  = (presentationMode === SLIDE_MODE);

    useEffect(() => {
        if(!editFullQ) {
            setEditQMask(dispatch, 0, 'editFullQ-Effect');
            if ( lastEdit >= 0 ) {
                //console.log("back from q edit", lastEdit);
                if ( isSlide ) { setSelectedContentId(lastEdit);
                } else { setTimeout(() => { jumpToEctn(lastEdit); }, 800); };
            };
        };
    }, [editFullQ]);

    const handleSelectedContentId = (idx) => setSelectedContentId(idx);
    const handlePresentationMode = (pMode) => setPresentationMode(pMode);
    const setContent = (cc) => {}; // return a dragdropped result array, no use, handle by moveBlock
    const onDragEnd = (ev) => moveBlock(ev.oldIndex, ev.newIndex);

    useEffect(() => { 
        if (isSlide && !EModeSlide){ 
            setPresentationMode(SCROLL_MODE);
        }else if(isScroll && !EModeScroll){
            setPresentationMode(SLIDE_MODE);
        }
    }, [EModeSlide, EModeScroll]);

    const qtnDisplayIndex = useMemo(() => {
        let QIndex = 0;
        return ECtns.map(ECtn => (ECtn.type === _ExCtType_Qtn)? ++QIndex: 0 );
    },[ECtns]);

    /* vvvvvvvvvvvvvvvvvv direct open edit content */
    const newClose = save => {
        if (save) {
            const currentUser = props.userId();
            
            if (currentUser) ed.modUid = currentUser;
            if (ed.type === _ExCtType_Qtn) {
                const QArr = toStr(ed?.QIds).split(',');
                if (QArr.length > 1) { // change to insert all questions
                    QArr.forEach(qid => { addEContent(ContentBlock(_ExCtType_Qtn, qid)); });
                } else addEContent(ed); // change to insert
            } else addEContent(ed); // change to insert
        } else {setNewC({})};
        setVisEd(0);
    };
    const clickAdd = ctnType => e => {
        UI.stopEvent(e);
        //addEContent(ContentBlock(ctnType));
        setEd(ContentBlock(ctnType));
        setNewC({View:CpECtnViews[ctnType], type:ctnType});
        setVisEd(1);
    };
    const edProps = {...props, replaceECtn:setEd, ECtn:ed, vis:visEd, close:newClose};
    if(visEd) return <LayerECtnEdit {...edProps} {...newC}/>;
    /* ^^^^^^^^^^^^^^^^^^^^^^^^^^^ direct open edit content */

    const [ hasEn, hasCt ] = [ex.ELangEn, ex.ELangCt];
    const ctProps = {...props, dispatch, reload, allDVis, asm, ex, isAT, lock, showEn, hasEn, hasCt, mediaDLs, getMediaDLs, 
        isLastECtn, setLastLoaded, insertBlock, replaceContent, clickDelI, clickMoveI, clickInsI};

    const selectorPanel = <div className={"d-flex gap-3 align-items-center w-100 mb-2"}>
        <span id={"question-selector-container"}
                className={`d-flex ${ECtns.length > 0 ? "flex-column border-end flex-md-row px-3 ps-md-0" : ""} gap-3 flex-row doing mb-2`}>
            {(EModeScroll)?<span role={"button"}
                    className={`d-flex justify-content-center align-items-center rounded-circle number-indicator fs-5 ${
                    isScroll? "exercise-action-btn" : "bg-dim-100 text-dim-350"}`}
                    onClick={() => handlePresentationMode(SCROLL_MODE)}>
                <CpIco src={IconList.presentation.assessmentMode}/>
            </span>:''}
            {(EModeSlide)?<span role={"button"}
                    className={`d-flex justify-content-center align-items-center rounded-circle number-indicator fs-5 ${
                    isSlide? "exercise-action-btn" : "bg-dim-100 text-dim-350"}`}
                    onClick={() => handlePresentationMode(SLIDE_MODE)}>
                <CpIco src={IconList.presentation.practiceMode}/>
            </span>:''}
        </span>
        <span className={"d-flex flex-grow-1"}>
            <CpExerciseBubble handleSelectedContentId={handleSelectedContentId}
                editContent={{content, presentationMode, setContent}}
                mode={lock? 'read': 'edit'} selectedContentId={selectedContentId} onDragEnd={onDragEnd}
                enableActive={isSlide}/>
        </span>
    </div>;

    const slideECtn = isSlide && ECtns[selectedContentId];
    const coverQ = editFullQ?1:0;
    return <div className={"fade-in-container"}>
        {!coverQ?selectorPanel:''}
        {(!coverQ && isScroll)? 
        ECtns.map((ECtn, idx) =>{
            //if (ECtn.type === _ExCtType_Qtn) ++QIndex;
            return <div key={idx} className='flexRowCenter' id={'content-id-'+idx}>
                <ViewExCtn key={'vec'+idx} {...{...ctProps, editQtn, idx, ECtn}}
                displayIdx={qtnDisplayIndex[idx]} />
            </div>
        }):''}
        {(!coverQ && slideECtn)? 
        <div key={selectedContentId} className='flexRowCenter' id={'content-id-'+selectedContentId}>
            <ViewExCtn key={'slide'+selectedContentId} {...{...ctProps, editQtn, idx: selectedContentId, ECtn: slideECtn}}  
                displayIdx={qtnDisplayIndex[selectedContentId]} />
        </div>
        :''}
        {(lock || coverQ)? '': <div className={"col-12 p-3 mb-3 text-center"}>
            <CpExEditToolBar {...{...props, isAT, clickAdd, createQtn, addedQIds, onAddQ, }} />
        </div>}
        {editFullQ? FullScreenMask(<TabQtnEdit {...{...props, showQPrew: 1, tapShowQPreV: 1, 
            onSaveQEdit, onClose: onCloseQEdit, qtnModified, setQtnModified,
            isAT, editable:1, isNew:1, showPub:1, QLang:isEn2Lang(showEn), tabQCpLang:()=>{},
            setQ, Q:showEn? editFullQ.QEn: editFullQ.QCt, fullQ:editFullQ, setFullQ, fieldErrs:editQErrs}}
            />): ''}                    
    </div>
});
export default TabExerEdContent;

export const fakeUseNavigate = () => (s) => {
    
};

export const isOupQ = q => (q && (q.userId || q.schoolId || q.isNew || q.isEdited ))? 0: 1;
export const isMyQ = (q, myUId) => (q && (q.UserId === myUId))? 1: 0;

const makeCopyECtn = (ECtn, showEn, isAT, myUId, qtns, addNewQtn) => {
    const isQ = (ECtn?.type === _ExCtType_Qtn);
    const QId = isQ && toUniIdsStr(ECtn.QIds).split(',')[0]; 
    const qtn = isQ && qtns[QId];
    const oupQ = isQ && isOupQ(qtn);
    const copyQ = isQ && (!isAT) && (!oupQ);
    const newQ = copyQ && initEditFullQ(deepCopy(qtn), copyQ, myUId, showEn);
    newQ && addNewQtn(newQ.QId, newQ);

    
    return copyQ? ContentBlock(_ExCtType_Qtn, newQ.QId): deepCopy(ECtn);
} 

const newFullQ = qType => deepCopy( defaultQtnData[qType] );

const initEditFullQ = (fullQ, isCopy, myUId, showEn, ) =>{
    const ret = {...fullQ, 
        //qType, 
        QId: '*NewQ_'+getUUID(),
        QLangCt: showEn?0:1,
        QLangEn: showEn?1:0, //todo only new q 1 lang
        modUid: myUId, 
        userId: myUId, 
        modLogin: '',
        isAT: 0,
        isCopy,
        //isNew: 1, // only for NeqQuestion
        isEdited: 1,    
        dp: QP_P, drpu: QP_PP, //todo  useSchool ID/User ID    
        QVer: "1.0", 
        QModeSlide: 1, QModeScroll: 1, //todo  only new q 1mode 
    };
    if(isCopy) ret.cpQId = fullQ.QId;
    if(showEn){ ret.QCt = {}; }else{ ret.QEn = {}; }
    return ret;
};

const useEditQ = (setfullQs, showEn) =>{
    const QKey = showEn? 'QEn': 'QCt';
    const setQ = key => valFunc => {
        
        setfullQs(subState(QKey, subState(key, valFunc)))
    };
    const setFullQ = (key, valFunc) => setfullQs(subState(key, valFunc));
    return {setFullQ, setQ};
};

