import React, {useEffect, useRef, useMemo, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { ReduxBind } from "../../saga/ReduxState";
import CpIco from "../_components/CpIco";
import ChartDonut from "./ChartDonut";
import { CpSortingIcon } from "./TabPerformanceDetailChart";
import ChartMiniBar from "./CpChartMinibar";
import ChartSingleRange from "./ChartSingleRange";
import { strCmpTrimLow, useSortList } from "../EPAssign/TabTeacherAssignmentStatus";

import { objEntries, toAry, toInt, toObj, toFix, toFloat, objKeys } from "../../libs/libType";
import { preJS, preJSMAX } from "../../AppExPf/AppUtil";
import { IconList } from '../../consts/ATIconListToUsePoc';
import {DONUT_AVG_LINE_COLOR, DONUT_CORRECT_COLOR, DONUT_DEFAULT_SEGMENT_COLOR, 
    DONUT_INCORRECT_COLOR, DONUT_PASSING_LINE_COLOR, DONUT_TEXT_COLOR, DONUT_TEXT_DIM_COLOR, 
    PerformanceOverviewType, SORT_ASC, SORT_DESC} from "../../poc/type/ChartType";
import { _WST_SUBMIT, _MST_MARKED } from "../../consts/ATConstsAssignment";
import { exerHasMark } from "../EPAssign/Tags/TagMarkingTag";
import { _AST_PUBLISHED } from "../../consts/ATConstsAssignment";
import { toRptFix, toRptPer } from "../EPReports/PageReports";
import { asmPassRate, asmPassMark } from "../EPReports/data/dataUtil";
import { expfRoles } from "../../saga/auth.saga";
import { getTransMap } from "../../libs/libTransMap";

const mkSData = (_uStats) => {
    const ss = {sumScore: 0, sumFullScore: 0, subCnt: 0, allMarkedCnt: 0, 
        passCnt:0, maxScore:0, minScore:999999, avp:0, avps:''};

    _uStats.forEach((us, ii)=>{
        if (us.submitted) {
            ss.subCnt++;
            if(us.allMarked){
                const p = toRptPer(toInt(us.uMark) * 100, toInt(us.totalMark));
                ss.allMarkedCnt++;
                if(us.pass) ss.passCnt++;
                ss.sumFullScore += toInt(us.totalMark);
                ss.sumScore += toInt(us.uMark);
                ss.avps += p+', '
                ss.avp = ss.avp + p;
                if (ss.maxScore < us.uMark) ss.maxScore = us.uMark;
                if (ss.minScore > us.uMark) ss.minScore = us.uMark;
            };
        };
    });
    //ttl? toFix(100 * cnt / ttl, 1): 0
    
    const mkSDataRet =  {
        subCnt: ss.subCnt,
        allMarkedCnt: ss.allMarkedCnt,
        avp:ss.avp,
        avps:ss.avps,
        avgScoreP: toRptPer(ss.avp, ss.allMarkedCnt),
        sumFullScore: ss.sumFullScore,
        sumScore: ss.sumScore,
        passCnt: ss.passCnt,
        minScore: ss.subCnt? ss.minScore: 0,
        maxScore: ss.subCnt? ss.maxScore: 0,
    };
    return mkSDataRet;
};

const TabPerformanceOverview = ReduxBind(props => { // PerformanceOverview
    const [isThr, isStt, uRole ] = expfRoles(props);
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const tableRef = useRef(null);
    useEffect(() => { setheaderLeft(tableRef.current); }, [tableRef.current, lang]);

    const {asm, Exer, qtns, works, marks, fields, asmMarking} = props;
    const {classId, SWMs, marking, clickMark} = asmMarking;

    const students = toObj(props.students);
    const studentIds = toAry(asm?.studentIds);
    const cntStudent = studentIds.length;
    
    const [ USorts, setUSorts, addUSort, flipUSort] = useSortList({clsno:'asc'});

    useEffect(() => { setheaderLeft(tableRef.current); }, [tableRef.current, lang]);

    const {APass, passValue, passUnit} = toObj(props.fields);
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(asm?.Exer);
    const eScore = aScore + mScore;
    const pPercent = asmPassRate(passUnit, passValue, eScore);
    const pMark = asmPassMark(passUnit, passValue, eScore);     

    const _uStats = useMemo(() => mkUState(ut, asm, studentIds, students, works, marks, pMark, APass), [uiEn, asm, props.students, works, marks, pMark, APass]);
    const uStats = useMemo(() => sortU(_uStats, USorts), [_uStats, USorts]);
    const _sData = useMemo(() => mkSData(_uStats), [_uStats]); 
    
    const { avgScoreP, allMarkedCnt } =_sData;
    const avgScoreP1 = toRptFix(avgScoreP);    

    const aPub = asm?.AState === _AST_PUBLISHED;

    const submitted = { value: _sData.subCnt, total: cntStudent, percentage: toPercent(_sData.subCnt, cntStudent) };
    const manualMarked = { value: allMarkedCnt, total: _sData.subCnt, percentage: toPercent(allMarkedCnt, _sData.subCnt), };
    const passingRate = { value: _sData.passCnt, total: allMarkedCnt, percentage: toPercent(_sData.passCnt, allMarkedCnt) };
    const toScoreRange = (min, max, total) => ({ min, max, total, minPercentage: toPercent(min, total), maxPercentage: toPercent(max, total) });
    
    const scoreRange = allMarkedCnt && toScoreRange(_sData.minScore, _sData.maxScore, eScore);
    //const scoreRange = toScoreRange(0, 0, 3);
    const srd = scoreRangeData(scoreRange, avgScoreP1, APass, pPercent);
    
    const smallDonutData = [
        { className: "donut-submitted", data: submittedDonutData(t, submitted)}, 
        hasM && { className: "donut-manual", data: manualDonutData(t, manualMarked)}, 
        APass && { className: "donut-passing", data: passingDonutData(t, passingRate)}
    ].filter(donutData => donutData && (objKeys(donutData.data).length) );

    const smallCnt = smallDonutData.length;
    const smallCnt1 = smallCnt + 1;

    return <><Container fluid={true} className={"TabPerformanceOverview p-0"}>
        <Row className={"m-0"}>
            <Col xs={12} lg={5} xl={4} xxl={3} className={"ps-0 pe-0 pe-lg-2"}>
                <div className={"border rounded-light p-3"}>
                <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3"}>
                        <h4 className={"m-0"}>{t(getTransMap("report-assignment-overview", isStt))}</h4>
                        <div className={"d-flex d-lg-none gap-3 flex-wrap fs-8 semi-bold"}>
                            {allMarkedCnt? <span className={"sample-line"}>
                                <span className={"sample-avg-line"}></span>{t("report-avg-score-percentage")}</span>: ''}
                            {APass? <span className={"sample-line"}>
                                <span className={"sample-passing-line"}></span>{t("report-passing-score-percentage")}</span>: ''}
                        </div>
                        </div>
                    <Row>
                        {preJS({allMarkedCnt, avgScoreP, APass, pPercent})}
                        <Col xs={6} md={Math.floor(12 / smallCnt1)} lg={12}>
                            <div className={"performance-overview-donut donut-avg"}><ChartDonut {...averageDonutData(t, allMarkedCnt, avgScoreP, APass, pPercent)}/></div>
                        </Col>
                        {smallDonutData.map((donutData, idx) => {
                            return <Col xs={6} md={Math.floor(12 / smallCnt1)} lg={6} key={idx}
                                        className={`${(smallCnt - 1) === idx? ((smallCnt1 % 2) === 0? "offset-md-0 offset-lg-3": "offset-3 offset-md-0") : ""}`}>
                                <div className={`performance-overview-donut ${donutData.className}`}>
                                    <ChartDonut {...donutData.data}/>
                                </div>
                            </Col>
                        })}
                    </Row>
                    <Row><Col><h5 className={"my-3"}>{t("report-score-range")}</h5>
                    {preJS({scoreRange, avgScoreP1, APass, pPercent })}
                    <ChartSingleRange {...srd}/></Col></Row>
                </div>
            </Col>
            <Col xs={12} lg={7} xl={8} xxl={9} className={"mt-4 mt-lg-0 ps-0 ps-lg-2 pe-0"}>
                <div className={"border rounded-light p-3"}>
                    <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3"}>
                        <h4 className={"m-0"}>{t("report-performance")}</h4>
                        <div className={"d-flex gap-3 flex-wrap fs-8 semi-bold"}>
                            {avgScoreP ? <span className={"sample-line"}><span className={"sample-avg-line"}></span>{t("report-avg-score-percentage")}</span>:''}
                            {APass? <span className={"sample-line"}><span className={"sample-passing-line"}></span>{t("report-passing-score-percentage")}</span>: ''}
                        </div>
                    </div>
                    {preJS({USorts})}
                    <div className={"overflow-x"}>
                        <table className={"report performance-overview-chart semi-bold"} ref={tableRef}>
                            <thead><tr>
                                <th className={"sticky-header header-no"}>
                                    <div className={"d-flex justify-content-center align-items-center gap-2 user-select-none text-nowrap"}
                                        role={"button"} onClick={flipUSort('clsno')}>
                                        <span>{t("report-student-no")}</span>
                                        <CpSortingIcon {...{sorts:USorts, addSort:addUSort, flip:flipUSort, sortKey:'clsno'}}/>
                                    </div>
                                </th><th className={"sticky-header header-name"}>
                                    <div className={"d-flex justify-content-center align-items-center gap-2 user-select-none text-nowrap"}
                                        role={"button"} onClick={flipUSort('stuName')}>
                                        <span>{t("report-student")}</span>
                                        <CpSortingIcon {...{sorts:USorts, addSort:addUSort, flip:flipUSort, sortKey:'stuName'}}/>
                                    </div>
                                </th><th>
                                    <div className={"d-flex justify-content-center align-items-center gap-2 user-select-none"}
                                        role={"button"} onClick={flipUSort('uMark')}>
                                        <span>{t("report-score")}</span>
                                        <CpSortingIcon {...{sorts:USorts, addSort:addUSort, flip:flipUSort, sortKey:'uMark'}}/>
                                    </div>
                                </th><th className={"w-100"}>
                                    <div className={"d-flex justify-content-center align-items-center gap-2 user-select-none"}
                                        role={"button"} onClick={flipUSort('uMark')}>
                                        <span>{t("report-score-percentage")}</span>
                                        <CpSortingIcon {...{sorts:USorts, addSort:addUSort, flip:flipUSort, sortKey:'uMark'}}/>
                                    </div>
                                </th><th>
                                    <div className={"d-flex justify-content-center align-items-center gap-2 user-select-none"}>
                                        {t("report-action")}
                                    </div>
                                </th>
                            </tr></thead>
                            <tbody>{uStats.map(u => {
                                const {sid, clsno, stuName, submitted, status, uMark, swmIdx} = u;
                                const {s, w, m} = u; 
                                const onClick = clickMark(swmIdx);
                                //const disMark = submitted?uMark:0;
                                const type = (status==="p" || status==="n")? "correctness": (status==="f"? "fail": (status==="a"? "attempted": (status==="m"? "manual": "correctness")));
                                const scoreStyle = (status === "p")? "text-tag-success": (status === "f")? "text-tag-danger": (status === "m")? "text-dim-350": "";
                                return <tr key={sid}>
                                <td className={`text-center text-nowrap semi-bold ${submitted? "" : "text-dim-350"} sticky-header header-no`}>{clsno}</td>
                                <td className={`text-center text-nowrap semi-bold ${submitted? "" : "text-dim-350"} sticky-header header-name`}>{stuName}</td>
                                <td className={`text-center text-nowrap semi-bold score-field ${submitted? "" : "text-dim-350"}`}>
                                    <span className={`${scoreStyle}`}>{submitted? uMark: "-"}</span> / {eScore}
                                </td>
                                <td className={"field score-percentage-field position-relative"}>
                                    <ChartMiniBar type={type} marks={{percentage:toRptFix((eScore?(uMark/eScore*100):0)), value:uMark, total:eScore}} status={status}/>
                                    <div className={"average-line"} style={{left: `${avgScoreP1}%`}}></div>
                                    {APass? <div className={"passing-line"} style={{left: `${pPercent}%`}}></div>:''}
                                </td>
                                <td className={"field"}><div className={"d-flex mt-3 mt-sm-0 gap-2 justify-content-center w-100"}>
                                    <MarkingButton {...{status, onClick, aPub}} />
                                </div></td>
                            </tr>;
                            })}</tbody>
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
    </>;
});

// status === ""  not submitted
const MarkingButton = (props => {
    const {status, onClick, aPub} = props;
    return (status === "m" && !aPub)? <Button variant="gray-exercise-third-btn-hover fs-6" onClick={onClick}><CpIco src={IconList.general.edit}/></Button>:
        (status === "")? "": 
        <Button variant="gray-body-color fs-6" onClick={onClick}><CpIco src={IconList.general.search}/></Button>;
});


export default TabPerformanceOverview;

const sortu = (a, b, kv) => {
    if(!kv) return;
    const [key, so] = kv;
    const isAsc = (so !== 'desc');
    
    if(key === 'stuName') return isAsc? strCmpTrimLow(a.stuName, b.stuName):strCmpTrimLow(b.stuName, a.stuName);
    if(key === 'clsno') return isAsc? strCmpTrimLow(a.clsno, b.clsno):strCmpTrimLow(b.clsno, a.clsno);
    //if(key === 'stuName') return isAsc? strCmpTrimLow(b.stuName, a.stuName): strCmpTrimLow(a.stuName, b.stuName); 
    //if(key === 'clsno') return isAsc? strCmpTrimLow(b.clsno, a.clsno): strCmpTrimLow(a.clsno, b.clsno); 
    const ret = isAsc? a[key] - b[key]: b[key] - a[key];
    
    return ret;
};

const sortU = (_uStats, USorts) => {
    const ss = objEntries(USorts); 
    const ary = toAry(_uStats);
    ary.sort((a,b) => sortu(a, b, ss[0]) || sortu(a, b, ss[1]) || sortu(a, b, ss[2]) || sortu(a, b, ss[3]));
    
    
    return ary;
};
const setheaderLeft = ref => {
    if (ref) {
        const headers = ref.querySelectorAll('.sticky-header');
        headers.forEach(header => {
            const prev = header.previousSibling;
            if (prev) {
                const left = prev ? prev.offsetWidth : 0;
                header.style.left = `${left}px`;
            };
        });
    }
};

const averageDonutData = (t, markCnt, avgMp, APass, pMarkP) => {
    const data = {};
    if (markCnt) {
        data.segments = [{
            color: DONUT_DEFAULT_SEGMENT_COLOR,
            value: avgMp
        }];
    }
    data.line = [
        APass &&{color: DONUT_PASSING_LINE_COLOR, value: pMarkP}
    ].filter(l => l);
    data.title = {
        color: (markCnt)? DONUT_DEFAULT_SEGMENT_COLOR : DONUT_TEXT_DIM_COLOR,
        value: `${(markCnt)? avgMp: "-"}%`
    };
    data.subTitle = {
        color: DONUT_TEXT_COLOR,
        value: t("report-avg-score")
    };
    return data;
};

const scoreRangeData = (scoreRange, avgMp, APass, pMarkP) => { 
    
    const data = {line:[]};
    if (APass) data.line.push({color: DONUT_PASSING_LINE_COLOR, value: toFloat(pMarkP)});
    if (scoreRange) data.line.push({color: DONUT_AVG_LINE_COLOR, value: toFloat(avgMp)});
    if (scoreRange) data.range = scoreRange;
    return data;
};

const submittedDonutData = (t, submitted) => { const data = {};
if (submitted) {
    const hasSub = submitted.total;
    data.title = {
       color: (hasSub)? DONUT_DEFAULT_SEGMENT_COLOR : DONUT_TEXT_DIM_COLOR,
       value: <>
           <tspan>{(hasSub)? submitted.value : "-"}</tspan>
           <tspan fill={(hasSub)? DONUT_TEXT_COLOR : DONUT_TEXT_DIM_COLOR}>/{(hasSub)? submitted.total : "-"}</tspan>
       </>
   };
   //data.subTitle = { color: DONUT_TEXT_COLOR, value: t("report-result-published")
   data.subTitle = { color: DONUT_TEXT_COLOR, value: t("report-submitted")
   };
   data.segments = [{ color: DONUT_DEFAULT_SEGMENT_COLOR, value: submitted.percentage
   }];
}
return data;
};

const manualDonutData = (t, manualMarked) => { const data = {};
if (manualMarked) {
    const hassub = manualMarked.total;
    data.title = {
       color: (hassub)? DONUT_DEFAULT_SEGMENT_COLOR : DONUT_TEXT_DIM_COLOR,
       value: <>
           <tspan>{(hassub)? manualMarked.value : "-"}</tspan>
           <tspan fill={(hassub)? DONUT_TEXT_COLOR : DONUT_TEXT_DIM_COLOR}>/{(hassub)? manualMarked.total : "-"}</tspan>
       </>
   };
   data.subTitle = {
       color: DONUT_TEXT_COLOR,
       value: t("report-manual-marked")
   };
   data.segments = [{
       color: DONUT_DEFAULT_SEGMENT_COLOR,
       value: manualMarked?.percentage
   }];
};
return data;
};

const passingDonutData = (t, passingRate) => {
const data = {};
const hasTtl = passingRate.total;
if (passingRate) {
   data.title = {
       color: (hasTtl)? DONUT_CORRECT_COLOR : DONUT_TEXT_DIM_COLOR,
       value: <>
           <tspan>{(hasTtl)? passingRate.value : "-"}</tspan>
           <tspan fill={(hasTtl)? DONUT_TEXT_COLOR : DONUT_TEXT_DIM_COLOR}>/{(hasTtl)? passingRate.total : "-"}</tspan>
       </>
   };
   data.subTitle = {
       color: DONUT_TEXT_COLOR,
       value: t("report-passing-rate")
   };
   data.segments = [{
       color: DONUT_CORRECT_COLOR,
       value: passingRate?.percentage
   }];
   if (hasTtl) {
   //if (passingRate.total !== 0) {    
       data.setting = { ringColor: DONUT_INCORRECT_COLOR, };
   };
};

return data;
};

const mkUState = (ut, asm, studentIds, students, works, marks, pMark, aPass) => {
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(asm?.Exer);
    const totalMark = aScore + mScore;

    return studentIds.map((sid, swmIdx) => {
        const s = toObj(students[sid]);
        const [w, m] = [ toObj(works[sid]), toObj(marks[sid]) ];
        const MStat = toObj(m?.MStat);
        const submitted = w && (w.WState === _WST_SUBMIT);
        
        const uMark = submitted?(toInt(MStat?.mScore) + toInt(MStat?.aScore)):0;
        const pass = submitted?((uMark >= pMark)? 1: 0):0;
        const allMarked = (m?.MState === _MST_MARKED)?1:0;
        const status = submitted?(allMarked?(aPass?(pass?'p':'f'):'a'):'m'):'';
        //const allMarked = toInt(MStat?.mQtnCount) === toInt(MStat?.mMarked)?1:0;
        const {classNo, nameEng, nameChi} = s;
        const stuName = ut(nameEng, nameChi);
        return {s, w, m, sid, swmIdx, clsno:classNo, stuName, uMark, submitted, status, totalMark, allMarked, pass};
    });
};

const toPercent = (val, ttl) => ttl? toRptFix(val / ttl * 100): '';
//const toPercent = (val, ttl) => ttl? toFix(val / ttl * 100, 2): '';

