import React, {useState, useEffect, useMemo} from "react";
import { useFields } from "../../AppExPf/utils/useEditDraftPub";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { stopEvent } from "../../libs/libUI";

import CpActionButton from "../_components/CpActionButton";
import CpShareOffcanvas from "../_components/CpShareOffcanvas";
import ActionButtonComponent from "../_components/CpActionButtonComponent";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { SIZE } from "../_components/CpExPFConst";
import { ReduxBind } from "../../saga/ReduxState";
import LayerExerFilter from "./LayerExerFilter";
import CpIco from "../_components/CpIco";
import { isObj, toAry, toInt, toObj, toStr } from "../../libs/libType";
import { toUniIdAry } from "../../consts/ATValidate";

const CpExerciseTabletListFilterCTA = ReduxBind(props => { //base on poc <ExerciseTabletListFilterCTA />
    const {sect, ESharerIds, defSort, exFilters, setExFilters, ESTagMSets,} = props
    
    const useFs = useFields();
    const [fields, setFields, setField, setTick, setTickAry, fieldErrs, setFieldErrs] =  useFs; 

    useEffect(() => { 
        setFields(toObj(exFilters));
    }, [exFilters]);

    const [t] = useUILang();

    const [open, setOpen] = useState(false);

    const exFilterCnt = useMemo(() => countExFilter(exFilters, ESTagMSets), [exFilters, ESTagMSets] );

    const clickSearch = (filters, caller) => e => {
        stopEvent(e);
        setFields(filters);
        setExFilters(filters, caller); //filterExers(filters);
        setOpen(0);
    };
    const fProps = {sect, ESharerIds, defSort, ESTagMSets, useFs, findEx:1}//eset, eset, exers, ESEMetaDisplays, ESTagMSets, }
    return <>
        <span className={"position-relative"}>
            <ActionButtonComponent variant={"gray-exercise-third-btn"} size={SIZE.XS} icon={<CpIco src={IconList.general.filterAdvance}/>} onClick={() => setOpen(true)}/>
            {exFilterCnt ? <small className={"filter-count bg-danger badge rounded-pill position-absolute fs-10 text-light pe-none user-select-none"}>{exFilterCnt}</small>: ''}
        </span>
        <CpShareOffcanvas show={open} placement={"end"} disableHeader={true} disableFooter={true}>
            <div className={"d-flex flex-column justify-content-between h-100 overflow-hidden"}>
                <div className={"overflow-auto h-100"}>
                    <LayerExerFilter {...fProps} />
                </div>
                <div className={"d-flex flex-column mt-3 mt-sm-0 p-3 gap-2 justify-content-center"}>
                    <div className={"d-flex flex-row gap-2 justify-content-between align-items-center"}>
                        <span className={"d-flex"}>
                            <CpActionButton className={"text-dim-400 gap-2 rounded semi-bold user-select-none btn"}
                                title={t("cancel")} hiddenText={false} onClick={() => setOpen(false)} />
                        </span>
                        <span className={"d-flex gap-2"}>
                            <CpActionButton className={"btn-outline-dim-400 gap-2 rounded semi-bold user-select-none btn"}
                                title={t("clear")} hiddenText={false} onClick={clickSearch({}, 'clrEF')}/>
                            <CpActionButton className={"btn-exercise-third-btn gap-2 rounded semi-bold user-select-none btn"}
                                title={t("search")} hiddenText={false} onClick={clickSearch({...fields}, 'newEF')} />
                        </span>
                    </div>
                </div>
            </div>
        </CpShareOffcanvas>
    </>;
});
export default CpExerciseTabletListFilterCTA;

const countExFilter = (fs, ESTagMSets) => {
    if(!isObj(fs)) return 0;
    const {m1, m2, q1, q2, only2, onlyA, onlyM,
        ename, QKw, like, eShares } = fs;
    

    const msids = toUniIdAry(toAry(fs.metas).map(m => toStr(m).split(',')[0].trim()));//.filter(s => s);
    const mts = new Set(msids.map(i => ESTagMSets[i]?.MSType).filter(t => t));

    const onFitlers = [
        (toStr(q1)+toStr(q2)).trim(),
        (toStr(m1)+toStr(m2)).trim(),
        toInt(only2), 
        toInt(onlyA), 
        toInt(onlyM),
        toStr(ename).trim(),
        toStr(QKw).trim(),
        toAry(eShares).length,
        toInt(like),
    ].filter(f => f);

    return onFitlers.length + mts.size;
};
